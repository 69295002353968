import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import SecondaryCbFormDetails from "../CASA/SecondaryCbFormDetails";
import {tagForm} from "../WorkflowJsonFormRashed"
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import GridList from "@material-ui/core/GridList";

let csRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "grid": 12,
        "required": true,
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class SDCLocker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            loading: true,
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            getMappingCropImage: false,
            imageCropModal: false,
            getTagList: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    submitApprove = (event) => {
        event.preventDefault();
        let checker_approved = 'APPROVED';
        this.handleSubmit(checker_approved);
    };
    submitReturn = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let checker_approved = 'NOTAPPROVED';
            this.handleSubmit(checker_approved);
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitReturn}
                    > Return
                    </button>
                </div>

            )
        }
    };

    componentDidMount() {
        this.setState({
            loaderNeeded: false
        });
        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        let varValue = [];
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.checkerRemarks = null;

                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    response.data.map((data) => {
                        if (data.remarks !== 'undefined') {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        }
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                    .catch((error) => {
                        console.log(error)
                    });

                let inputData = this.getSearchvalue(response.data);
                let varValue = this.getSearchvalue(response.data);

                if (("siDate" in varValue)) {
                    inputData["siDate"] = (inputData["siDate"]).split(',')[0];
                    varValue["siDate"] = (varValue["siDate"]).split(',')[0];
                }

                if (("numberOfNominee" in varValue)) {
                    for (let i = 0; parseInt(varValue.numberOfNominee) > i; i++) {
                        let sl = i + 1;
                        if (inputData["dob1" + sl] && varValue["dob1" + sl]) {
                            inputData["dob1" + sl] = (inputData["dob1" + sl]).split(',')[0];
                            varValue["dob1" + sl] = (varValue["dob1" + sl]).split(',')[0];
                        }
                    }
                }

                this.setState({
                    getData: true,
                    varValue: varValue,
                    appData: this.getSearchvalue(response.data),
                    inputData: inputData,
                    showValue: true,
                    appId: this.props.appId,
                    loaderNeeded: true,
                    loading: false
                });
            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();
                        this.setState({
                            redirectLogin: true
                        })
                    }
                });
        }


        if (this.props.subServiceType === "Joint Account") {
            let tableArrayData = [];
            let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
            axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    //alert('success')
                    console.log(response.data);
                    response.data.map((data, index) => {
                        tableArrayData.push(this.createTaggingData(data));

                    });
                    this.setState({
                        getTagList: tableArrayData,

                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }

    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false

        })
    };
    tagCbDetailsModal = (event, cb) => {

        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb
        })

    };

    createTaggingData = (data) => {
        return (
            ["Customer " + data.customer, data.cbNumber, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer)}
            >View
            </button>]]


        )
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )
        }
    };

    handleSubmit = (checker_approved) => {
        this.state.inputData.checker_approval = checker_approved;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.checkerRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.checkerRemarks = undefined;

        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            var url = backEndServerURL + "/case/route/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                this.props.closeModal();
            })
                .catch((error) => {
                    console.log(error);
                });
        })
            .catch((error) => {
                console.log(error)
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };
    close = () => {
        this.props.closeModal();
    };

    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderNomineeForm = () => {
        if (this.state.getData) {
            var sl;
            let objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "required": true,
                        "onKeyDown": true,
                        "label": "Input CIF ID/INFORMATION ?",
                        "grid": 6,
                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "relationCode",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "relationCode",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "text",
                        "label": "D.O.B(YYYY-MM-DD)",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "guardianCode",
                        "enum": [],
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        "required": true,
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "multiline": true,
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "guardianCode",
                        "enum": [],
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        "required": true,
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)
            )

        }
    };
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };


    renderTagList = () => {

        if (this.state.getData && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <div>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer No", "CB Number", "View"]}

                            tableData={this.state.getTagList}
                            tableAllign={['left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        }

    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <SecondaryCbFormDetails tagForm={tagForm} customerNumber={this.state.tagCb}
                                                    appId={this.props.appId} tagCb={this.state.tagCb}
                                                    closeModal={this.tagCbDetailsModalClose}/>
                        </DialogContent>
                    </Dialog>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.returnJsonForm()}
                                <Grid item xs={6}>
                                    {this.renderNomineeNumber()}
                                </Grid>
                                {this.renderNomineeForm()}
                                <Grid item xs={12}>
                                    {this.renderTagList()}
                                </Grid>
                                <Grid item xs='12'>
                                    {this.renderRemarksData()}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                        <ThemeProvider theme={theme}>
                            {this.renderRemarks()}
                            <br/>
                            <br/>
                            <div>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'right',
                                    }}
                                    type='button' value='add more'
                                    onClick={this.submitApprove}
                                > Approve
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'right',
                                    }}
                                    type='button' value='add more'
                                    onClick={this.submitReturn}
                                > Return
                                </button>
                            </div>
                        </ThemeProvider>
                    </div>
                </div>
            </GridList>
        );


    }

}

export default withStyles(styles)(SDCLocker);
