import {Redirect} from "react-router-dom";
import React from "react";
import axios from "axios";


class Functions {
    static removeCookie() {
        /*  cookie.remove("spring_session");
          LocalstorageEncrypt.encryptStorageFunction.removeItem("username");
          LocalstorageEncrypt.encryptStorageFunction.removeItem("roles");
          LocalstorageEncrypt.encryptStorageFunction.removeItem("menus");*/
    }

    static sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };

    static redirectToLogin(state) {
        if (state.redirectLogin === true) {
            return (<Redirect to={{
                pathname: '/login',
            }}/>);
        }
    }

    static loginRedirection(error) {
        if (error.response && error.response.status === 452)
            window.location.replace("/#/login");
    }


    static convertToDate(dateString) {
        if (dateString === null || dateString === undefined) {
            return null;
        }

        let dateMonthYear = dateString.split(",")[0].trim();
        let seperatedDateMonthYear = dateMonthYear.split("/");
        let returnData = seperatedDateMonthYear[2] + "-" + seperatedDateMonthYear[1] + "-" + seperatedDateMonthYear[0];
        return returnData;
    }

    static postAPiCall(url, object) {
        axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            })
    }

    static getAPiCall(url, object) {
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            })
    }
    static errorObjectCheck  (error)  {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Internal System Error!"
                }
            } else {
                return "Internal System Error!"
            }
        } else {
            return "Internal System Error!"
        }
    };


}

export default Functions;

