import React from "react";
import {backEndServerURL} from "../Common/Constant";
import axios from "axios";
import Functions from "../Common/Functions";
import Table from "./Table/Table";
import Grid from "@material-ui/core/Grid";
import CardHeader from "./Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class FullSdnDedupeResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            CBGenerateModal: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},
            beneficiaryDedupData: {},
            mandateDedupDataFlag: false,


        };


    }

    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false
        })
    };
    CustomerModal = (uniqueId) => {
        this.setState({
            CustomerModal: true,
            IDENTIFICATION_NO: uniqueId
        })
    };

    radioButtonChange = (event) => {

        let variable = {};
        console.log(event.target.name);
        console.log(event.target.value);
        variable[event.target.name] = event.target.value;
        this.setState({
            getRadioButtonData: variable,
            getCustomerNumber: event.target.name.slice(-1)
        })


    };


    tagingModal = (index) => {
        this.setState({
            renderCumModalopen: true,
            tagingModalCbnumber: index
        })
    };

    taging = (index, data) => {

        let value = index + 1;
        if (data !== undefined) {
            this.props.getTaggingData("tagging" + value, data, value);
        } else {
            this.props.getTaggingData("tagging" + value, this.state.getRadioButtonData["tagging" + value], value);
        }


    };
    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Joint Account' || this.props.subServiceType === "MandateMaintenance" || this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                            value={customerId}/>, customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        } else if (((this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening"))) {
            return ([customerId, accountSource, customerName,


                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        } else {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else {
                return ([<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                                value={customerId}/>, customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            }
        }

    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        }


    };
    createTableproCompany = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {


        if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
            return ([customerId, accountSource, customerName,
                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])

        } else {
            return ([customerId, accountSource, customerName,
                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        }

    };
    createSdnTableData = (country, dob, matchType, name, type) => {


        return ([name, dob, matchType, type, country])


    };

    componentDidMount() {

        /*  this.setState({
              loading: true
          })*/


        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("llll");
                    console.log(response.data);
                    let objectForJoinAccount = [];
                    var sl;


                    this.setState({
                        getDedupData: response.data,
                        objectForJoinAccount: objectForJoinAccount,
                        getgenerateForm: true

                    });
                    console.log(response.data);
                    //if(this.state.getDedupData.beneficiaryindividualDedupData!=={}){
                    //"mandateDedupData": this.props.mandateindividualDedupData,

                    //}
                    if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
                        let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.individualDedupData;
                        axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                let beneficiaryDedupData = [];
                                let mandateDedupData = [];
                                if (response.data.beneficiaryDedupData.sdnResult !== null && Array.isArray(response.data.beneficiaryDedupData.sdnResult)) {

                                    response.data.beneficiaryDedupData.sdnResult.map((data) => {
                                        beneficiaryDedupData.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                        console.log(beneficiaryDedupData)
                                    });

                                }
                                if (response.data.mandateDedupData.sdnResult !== null && Array.isArray(response.data.mandateDedupData.sdnResult)) {

                                    response.data.mandateDedupData.sdnResult.map((data) => {
                                        mandateDedupData.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                        console.log(mandateDedupData)
                                    });

                                }
                                console.log(beneficiaryDedupData.length);

                                this.setState({

                                    beneficiaryDedupData: beneficiaryDedupData,
                                    mandateDedupData: mandateDedupData,
                                    beneficiaryDedupDataFlag: true,
                                    mandateDedupDataFlag: true,
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,

                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    beneficiaryDedupData: [],
                                    beneficiaryDedupDataFlag: true,
                                    mandateDedupData: [],
                                    mandateDedupDataFlag: true,
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,
                                })
                            })
                    }
                    if (this.props.subServiceType === "MandateMaintenance" || this.props.subServiceType === "Joint Account" || this.props.subServiceType === "Company Account") {

                        let tableArray = [];
                        let relatedTableArray = [];
                        let dedupSaveId = "";


                        let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.jointDedupData;
                        axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(this.state.getDedupData);
                                console.log("high....");
                                console.log(response.data.jointDedupData.sdnResult);
                                let jointDedupSearchTable = [];
                                if (response.data.jointDedupData.sdnResult !== null && Array.isArray(response.data.jointDedupData.sdnResult)) {
                                    var randomNumber = 0;
                                    response.data.jointDedupData.sdnResult.map((dedup, i) => {
                                        let customer = [];
                                        randomNumber = randomNumber + 1;
                                        dedup.map((data) => {
                                            customer.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                        });

                                        jointDedupSearchTable.push(customer);

                                    });


                                }
                                let beneficiaryDedupData = [];
                                let mandateDedupData = [];
                                if ((this.props.subServiceType !== "Joint Account" && this.props.subServiceType !== "Company Account") && this.props.subServiceType !== "MandateMaintenance") {
                                    if (response.data.beneficiaryDedupData.sdnResult !== null && Array.isArray(response.data.beneficiaryDedupData.sdnResult)) {

                                        response.data.beneficiaryDedupData.sdnResult.map((data) => {
                                            beneficiaryDedupData.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                            console.log(beneficiaryDedupData)
                                        });

                                    }
                                    if (response.data.mandateDedupData.sdnResult !== null && Array.isArray(response.data.mandateDedupData.sdnResult)) {

                                        response.data.mandateDedupData.sdnResult.map((data) => {
                                            mandateDedupData.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                            console.log(mandateDedupData)
                                        });

                                    }
                                }


                                this.setState({
                                    beneficiaryDedupData: beneficiaryDedupData,
                                    mandateDedupData: mandateDedupData,
                                    beneficiaryDedupDataFlag: true,
                                    mandateDedupDataFlag: true,
                                    jointSearchTableData: jointDedupSearchTable,
                                    relatedData: relatedTableArray,
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,

                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    beneficiaryDedupData: [],
                                    mandateDedupData: [],
                                    beneficiaryDedupDataFlag: true,
                                    mandateDedupDataFlag: true,

                                    jointSearchTableData: [],
                                    relatedData: [],
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,
                                })
                            });

                        tableArray = [];

                        dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.companyDedupData;
                        axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log("lko");
                                console.log(response.data);

                                if (response.data.companyDedupData.sdnResult !== null && Array.isArray(response.data.companyDedupData.sdnResult)) {

                                    response.data.companyDedupData.sdnResult.map((data) => {
                                        tableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                    });

                                }


                                this.setState({

                                    propritorshipData: tableArray,
                                    //relatedData: relatedTableArray,
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    //getsearchValue: object,
                                    loading: false,

                                })


                            })
                            .catch((error) => {

                                this.setState({
                                    propritorshipData: [],
                                    relatedData: [],
                                    searchTableData: true,
                                    searchTableRelatedData: true,
                                    loading: false,
                                })

                            });
                    } else {
                        let tableArray = [];
                        let relatedTableArray = [];

                        if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
                            let tableArray = [];

                            let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.individualDedupData;
                            axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log("lko");
                                    console.log(response.data);

                                    if (response.data.companyDedupData.sdnResult !== null && Array.isArray(response.data.companyDedupData.sdnResult)) {

                                        response.data.companyDedupData.sdnResult.map((data) => {
                                            tableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                        });

                                    }


                                    this.setState({

                                        propritorshipData: tableArray,
                                        //relatedData: relatedTableArray,
                                        searchTableData: true,
                                        searchTableRelatedData: true,
                                        //getsearchValue: object,
                                        loading: false,

                                    })


                                })
                                .catch((error) => {

                                    this.setState({
                                        propritorshipData: [],
                                        relatedData: [],
                                        searchTableData: true,
                                        searchTableRelatedData: true,
                                        loading: false,
                                    })

                                });

                        }
                        if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {

                            let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.individualDedupData;
                            axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log("lko");
                                    console.log(this.state.getDedupData.individualDedupData);
                                    console.log(response.data);

                                    if (response.data.individualDedupData.sdnResult !== null && Array.isArray(response.data.individualDedupData.sdnResult)) {

                                        response.data.individualDedupData.sdnResult.map((data) => {
                                            tableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));


                                        });

                                    }


                                    this.setState({

                                        dedupData: tableArray,
                                        //relatedData: relatedTableArray,
                                        searchTableData: true,
                                        searchTableRelatedData: true,

                                        loading: false,

                                    })


                                })
                                .catch((error) => {

                                    this.setState({
                                        propritorshipData: [],
                                        //relatedData: [],
                                        searchTableData: true,
                                        searchTableRelatedData: true,
                                        loading: false,
                                    })

                                });

                        } else {

                            let tableArray = [];
                            let relatedTableArray = [];

                            //set 02/02/1983, 00:00:00 to 1983-02-02 format

                            let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + this.state.getDedupData.individualDedupData;
                            axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data.individualDedupData);
                                    if (response.data.individualDedupData.sdnResult !== null && Array.isArray(response.data.individualDedupData.sdnResult)) {

                                        response.data.individualDedupData.sdnResult.map((data) => {
                                            tableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));


                                        });

                                    }


                                    this.setState({

                                        dedupData: tableArray,
                                        //relatedData: relatedTableArray,
                                        searchTableData: true,
                                        searchTableRelatedData: true,

                                        loading: false,

                                    })


                                })
                                .catch((error) => {

                                    this.setState({
                                        dedupData: [],
                                        relatedData: [],
                                        searchTableData: true,
                                        searchTableRelatedData: true,
                                        loading: false,
                                    })

                                });

                        }

                    }

                    console.log(response.data);
                    let varValue = response.data;
                    this.setState({
                        jointAccountCustomerNumber: response.data.jointAccountCustomerNumber,
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        showValue: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };


    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };


    closeModal = (account) => {

        this.setState({
            renderCumModalopen: false,
            generateAccountNo: "",

        })
    };
    closeModalNew = (account) => {

        this.setState({
            renderCumModalopenNew: false,
            CBGenerateModal: true,
            generateAccountNo: "",

        })
    };

    closeModalCBGenerate = (account) => {

        this.setState({
            CBGenerateModal: false,
        })
    };

    renderNewAccountOpeingForm = (index) => {

        this.setState({

            renderCumModalopenNew: true,
            generateAccountNo: "NEW",
            tagingModalCbnumber: index,

        })


    };
    /*renderJointSearchtabledata = () => {
        return (
            this.state.jointSearchTableData.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>Customer {index + 1}</h3>
                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["", "CB No", "Source", "Customer Name", "View"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'right',]}
                        />
                    </div>

                )
            })
        )


    };*/
    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };
    renderWithoutProprietorshipOnlyTable = (tableData) => {
        if (Array.isArray(tableData) && tableData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={tableData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };
    renderWithoutProprietorshipSearchtabledata = () => {
        return (
            this.state.jointSearchTableData.map((tableData, index) => {
                return (

                    <div>
                        <br/>


                        <center>
                            <Grid item xs={6}>
                                <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                            </Grid>
                        </center>
                        {this.renderWithoutProprietorshipOnlyTable(tableData)}


                    </div>

                )
            })
        )


    };

    searchTableSDNDataCompany = (style) => {

        if (Array.isArray(this.state.propritorshipData) && this.state.propritorshipData.length < 1 && (this.state.searchTableData && (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C' || this.props.subServiceType === "Company Account"))) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Company SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                    <br/>


                </div>

            )


        } else if (Array.isArray(this.state.dedupData) && (this.state.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C' || this.props.subServiceType === "Company Account"))) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Company SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.propritorshipData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )


        }


    };
    searchTableSDNData = (style) => {

        if (this.state.searchTableData && (this.props.subServiceType === "Company Account" || this.props.subServiceType === 'Joint Account' || this.props.subServiceType === "MandateMaintenance")) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>

                    {this.renderWithoutProprietorshipSearchtabledata()}
                    <br/>
                </div>


            )
        } else if (Array.isArray(this.state.dedupData) && this.state.dedupData.length < 1 && (this.state.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C'))) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                    <br/>


                </div>

            )


        } else if (Array.isArray(this.state.dedupData) && (this.state.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C'))) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )


        }


    };
    renderMandate = (mandateDedupData) => {
        if (Array.isArray(mandateDedupData) && mandateDedupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={mandateDedupData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };
    searchTableMandateSDNData = (style) => {
        if (this.state.searchTableData && this.state.mandateDedupDataFlag && this.state.getDedupData.mandate === "YES") {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Mandate SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    {this.renderMandate(this.state.mandateDedupData)}

                    <br/>


                </div>

            )


        }


    };
    renderBeneficiary = (beneficiaryDedupData) => {
        if (Array.isArray(beneficiaryDedupData) && beneficiaryDedupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={beneficiaryDedupData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };

    searchTableBeneficiarySDNData = (style) => {
        if (this.state.searchTableData && this.state.beneficiaryDedupDataFlag && this.state.getDedupData.beneficiary === "YES") {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Beneficial Owner SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    {this.renderBeneficiary(this.state.beneficiaryDedupData)}

                    <br/>


                </div>

            )


        }


    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


        return (

            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                <Grid item xs={12}>
                                    {this.searchTableSDNDataCompany()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.searchTableSDNData()}
                                </Grid>

                                <Grid item xs={12}>
                                    {this.searchTableMandateSDNData()}
                                </Grid>
                                <Grid item xs={12}>
                                    {this.searchTableBeneficiarySDNData()}
                                </Grid>


                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>
                {/*<GridContainer>*/}
                {/*    <GridItem xs={12} sm={12} md={12}>*/}
                {/*        <Card>*/}
                {/*            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>*/}

                {/*                {this.searchTableMandateSDNData()}*/}
                {/*            </div>*/}
                {/*        </Card>*/}
                {/*    </GridItem>*/}

                {/*</GridContainer>*/}
                {/*<GridContainer>*/}
                {/*    <GridItem xs={12} sm={12} md={12}>*/}
                {/*        <Card>*/}
                {/*            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>*/}

                {/*                {this.searchTableBeneficiarySDNData()}*/}
                {/*            </div>*/}
                {/*        </Card>*/}
                {/*    </GridItem>*/}

                {/*</GridContainer>*/}


            </div>

        )

    }


}

export default withStyles(styles)(FullSdnDedupeResult);
