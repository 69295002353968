import React, {Component} from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class IndividualTermDeposit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: false,
            accountTypeFlag: false,
            termDepositData: null,
            accountType: "",
            redirectLogin: false,
            lienNarrationList:[],
            getlienNarrationList:false
        }
    }

    componentDidMount() {
        this.setState({
            termDepositData: false
        })
        if (this.props.ACCOUNTNO !== undefined && this.props.type !== undefined) {
            let url = backEndServerURL + "/demographic/account/"+this.props.platform+"/" + this.props.type + "/" + this.props.ACCOUNTNO;

            let varValue = [];


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*
                       CUST_ID: "CB1401933"
                     FDDPSTYPE: "R"
                     FIXEDBALANCE: 0
                       INTPRFTPAID: null
                     INTPROACCURED: null
                       PAYMENTDATE: null
                      TAXPRFPAID: null
                      TINTPRFTPAID: null
                     TTINTPRFTPAID: null*/
                    console.log(response.data);
                    if (response.data !== null) {
                        varValue["FORACID"] = response.data.FORACID;
                        varValue["PRODUCTNAME"] = response.data.PRODUCTNAME;
                        varValue["TENOR"] = response.data.TENOR;
                        varValue["CURRENCY"] = response.data.CURRENCY;
                        varValue["MATURITY_AMOUNT"] = response.data.MATURITY_AMOUNT;
                        varValue["INTERESTRATE"] = response.data.INTERESTRATE;
                        varValue["BRANCHNAME"] = response.data.BRANCHNAME;
                        varValue["BALANCE"] = response.data.BALANCE;
                        varValue["INITIALOPENDATE"] = response.data.INITIALOPENDATE;
                        varValue["INTMATURUTYDATE"] = response.data.INTMATURUTYDATE;
                        varValue["MATURITYINSTRUCTION"] = response.data.MATURITYINSTRUCTION;
                        varValue["FIXEDBALANCE"] = response.data.FIXEDBALANCE;
                        varValue["PRINCIPALAMOUNT"] = response.data.PRINCIPALAMOUNT;
                        varValue["AUTO_RENEWAL_FLG"] = response.data.AUTO_RENEWAL_FLG;
                        varValue["CLOSE_ON_MATURITY_FLG"] = response.data.CLOSE_ON_MATURITY_FLG;


                        let lienNarrationList=[];
                        if(this.props.platform !== "ABABIL" && response.data.lienNarrationList !== undefined){
                            response.data.lienNarrationList.map((data)=>{
                                lienNarrationList.push(this.createTableLienNarration(data.lienAmmount,data.lienExpiryDate,data.lienRemarks,data.lienStartDate))
                            })
                        }
                        this.setState({
                            lienNarrationList:lienNarrationList,
                            getlienNarrationList:true
                        })

                        /*   let initialOpenDate = response.data.INITIALOPENDATE;
                           if (initialOpenDate !== null && initialOpenDate !== undefined) {

                               varValue["INITIALOPENDATE"] = initialOpenDate.split('T');
                           } else {
                               varValue["INITIALOPENDATE"] = initialOpenDate;
                           }

                           varValue["INTPRFTPAID"] = response.data.INTPRFTPAID;

                           varValue["TAXPRFPAID"] = response.data.TAXPRFPAID;
                           varValue["TINTPRFTPAID"] = response.data.TINTPRFTPAID;
                           varValue["TTINTPRFTPAID"] = response.data.TTINTPRFTPAID;
                           let immatureutyDate = response.data.INTMATURUTYDATE;
                           if (immatureutyDate !== null && immatureutyDate !== undefined) {
                               varValue["INTMATURUTYDATE"] = immatureutyDate.split('T');
                           } else {
                               varValue["INTMATURUTYDATE"] = immatureutyDate;
                           }


                           varValue["MATURITYINSTRUCTION"] = response.data.MATURITYINSTRUCTION;*/
                    } else {

                    }

                    this.setState({
                        varValue: varValue,
                        accountType: response.data.FDDPSTYPE,
                        accountTypeFlag: true,
                        termDepositData: true,

                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({termDepositData: true})

                });
        } else {

        }
    }

    renderTermDepositData = () => {
        if (this.state.termDepositData) {
            if(this.state.accountType === "T"){
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[" ", " "
                        ]}
                        tableData={[
                            ["Account number", this.state.varValue["FORACID"]],
                            ["Product Name", this.state.varValue["PRODUCTNAME"]],
                            ["Tenor", this.state.varValue["TENOR"]],
                            ["Currency", this.state.varValue["CURRENCY"]],
                            ["Principal Amount", this.state.varValue["PRINCIPALAMOUNT"]],
                            ["Interest Rate", this.state.varValue["INTERESTRATE"]],
                            ["Home Branch", this.state.varValue["BRANCHNAME"]],
                            ["Balance", this.state.varValue["BALANCE"]],
                            ["Open Date", this.state.varValue["INITIALOPENDATE"]],
                            ["Maturity Date", this.state.varValue["INTMATURUTYDATE"]],
                            ["Maturity Value", this.state.varValue["MATURITY_AMOUNT"]],
                            ["Auto Renewal Flag", this.state.varValue["AUTO_RENEWAL_FLG"]],
                            ["Close On Maturity Flag", this.state.varValue["CLOSE_ON_MATURITY_FLG"]],



                        ]}
                        tableAllign={['left', 'left']}
                    />
                )
            }
            else{
                return (
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[" ", " "
                        ]}
                        tableData={[
                            ["Account number", this.state.varValue["FORACID"]],
                            ["Product Name", this.state.varValue["PRODUCTNAME"]],
                            ["Tenor", this.state.varValue["TENOR"]],
                            ["Currency", this.state.varValue["CURRENCY"]],
                            ["Installment Amount", this.state.varValue["FIXEDBALANCE"]],
                            ["Interest Rate", this.state.varValue["INTERESTRATE"]],
                            ["Home Branch", this.state.varValue["BRANCHNAME"]],
                            ["Balance", this.state.varValue["BALANCE"]],
                            ["Open Date", this.state.varValue["INITIALOPENDATE"]],
                            ["Maturity Date", this.state.varValue["INTMATURUTYDATE"]],
                            ["Maturity Value", this.state.varValue["MATURITY_AMOUNT"]],
                            ["Auto Renewal Flag", this.state.varValue["AUTO_RENEWAL_FLG"]],
                            ["Close On Maturity Flag", this.state.varValue["CLOSE_ON_MATURITY_FLG"]],

                        ]}
                        tableAllign={['left', 'left']}
                    />

                )
            }

        } else {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)
        }
    }
    renderTermDepositData2 = () => {
        if (this.state.termDepositData) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Last Cycle Interest/Profit Paid", this.state.varValue["INTPRFTPAID"]],
                        ["Last Cycle Tax Deducted on Interest/Profit Paid", this.state.varValue["TAXPRFPAID"]],
                        ["Total Interest/Profit Paid", this.state.varValue["TINTPRFTPAID"]],
                        ["Total Tax Deducted on Interest/Profit Paid", this.state.varValue["TTINTPRFTPAID"]],
                        ["Initial Maturity Date", this.state.varValue["INTMATURUTYDATE"]],
                        ["Maturity Instruction", this.state.varValue["MATURITYINSTRUCTION"]],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)
        }
    }
    close = () => {
        this.props.closeModal()
    }
    renderHeaderTitle = () => {
        if (this.state.accountTypeFlag && this.state.accountType === "R") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} >DPS Summary<a><CloseIcon onClick={this.close}
                                                 style={{position: 'absolute', right: 10, color: "#000000"}}/></a></h4>
                </CardHeader>
            )
        } else if (this.state.accountTypeFlag && this.state.accountType === "T") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} >FDR Summary<a><CloseIcon onClick={this.close}
                                                 style={{position: 'absolute', right: 10, color: "#000000"}}/></a></h4>
                </CardHeader>
            )
        }
        else if (this.state.accountTypeFlag) {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} >Term Deposit Summery<a><CloseIcon onClick={this.close}
                                                          style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                    </h4>
                </CardHeader>
            )
        }
        else   {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <a><CloseIcon onClick={this.close}
                                                          style={{position: 'absolute', right: 10, color: "#000000"}}/></a>

                </CardHeader>
            )
        }
    };

    createTableLienNarration=(lienAmmount,lienExpiryDate,lienRemarks,lienStartDate)=>{
        return([lienAmmount,lienExpiryDate,lienRemarks,lienStartDate])
    }

    renderLienNarrotion=()=>{

        if(this.state.getlienNarrationList){
            return(
                <React.Fragment>
                    <br/>
                    <center><h3>Lien Amount with Narration</h3></center>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Lien Amount", "Lien Expiry Date","Lien Remarks","Lien Start Date"]}
                        tableData={this.state.lienNarrationList}
                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                    />
                </React.Fragment>
            )
        }
    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                {this.renderHeaderTitle()}

                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>


                                                <Grid item xs={12}>


                                                    <Paper className={classes.paper}>

                                                        {this.renderTermDepositData()}
                                                        {this.renderLienNarrotion()}
                                                    </Paper>

                                                </Grid>
                                                {/* <Grid item xs={6}>


                                                    <Paper className={classes.paper}>
                                                        {this.renderTermDepositData2()}

                                                    </Paper>

                                                </Grid>*/}

                                            </Grid>
                                        </Grid>
                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(IndividualTermDeposit);
