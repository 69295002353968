import React, {Component} from "react";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";


const jsonForFile = {
    varName: "fileUpload",
    type: "file",
    label: "Upload",
    accept: ".pdf",
    grid: 2,
    errorMessage: "Error"
};

const jsonForFileName = {
    varName: "fileName",
    type: "text",
    label: "File Name",
    grid: 2,
    required: true
};


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class DynamicFileAttachment extends Component {
    constructor() {
        super();
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            fileUploadData: {},
            inputData: {},
            showValue: false,
            varValue: {},
            cbNotFound: false,
            dynamicFile: [],
            loading: false
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {

    };


    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    float: 'left',
                    verticalAlign: 'left',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >+ New Attachment</button>
        )

    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            dynamicFile: [...prevState.dynamicFile, randomNumber],
        }));
    };

    removeClick(i, event) {
        event.preventDefault();
        let fileTypeFormExist = this.state.dynamicFile.indexOf(i);
        console.log(fileTypeFormExist);
        if (fileTypeFormExist > -1) {
            this.state.dynamicFile.splice(fileTypeFormExist, 1);
            this.updateComponent();
        }
    };

    dynamicFileTypeForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForFile));
        field.varName = "fileUpload" + i;
        return FileTypeComponent.restrictedFile(this.state, this.updateComponent, field);
    };

    dynamicFileNameForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForFileName));
        field.varName = "fileName" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
    };

    addFileTypeForm() {
        return this.state.dynamicFile.map((el, i) =>
            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicFileTypeForm(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicFileNameForm(el)
                    }
                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    };


    functionForFileUpload = () => {
        if (this.state.dynamicFile.length > 0 /*&& Object.keys(this.state.fileUploadData).length > 0*/ && this.props.submitButtonClick) {
            this.setState({
                loading: true
            });
            let uploadUrl = assetOpsBackEndServerURL + "/case/uploadWithFileName";
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            this.state.dynamicFile.map((i) => {
                formData.append("file", this.state.fileUploadData["fileUpload" + i]);
                formData.append("type", this.state.inputData["fileName" + i] !== undefined ? this.state.inputData["fileName" + i] : `file${i}`);
                formData.append("fileNames", this.state.inputData["fileName" + i] !== undefined ? this.state.inputData["fileName" + i] : `file${i}`);
            });

            axios({
                method: "post",
                url: uploadUrl,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.props.functionFoFile("success")
                    } else {
                        this.setState({
                            loading: false
                        });
                        this.props.functionFoFile("failed")
                    }
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                    this.props.functionFoFile("failed")
                });
        } else if (this.props.submitButtonClick) {
            this.props.functionFoFile("No File Found")
        }
    };


    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>

                    <br/>
                    <Grid item xs='12'>
                        {this.renderAddButtonShow()}
                    </Grid>
                    {this.addFileTypeForm()}
                    {this.functionForFileUpload()}
                    <br/>
                </React.Fragment>
            );
        }

    }
}

export default withStyles(styles)(DynamicFileAttachment);
