import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import LocalstorageEncrypt from "../../../JsonForm/LocalstorageEncrypt";

let jsonForm = [
    {
        varName: "customerName",
        label: "Customer Name",
        type: "text",
        grid: 3,
        readOnly: true,
    },
    {
        varName: "schemeCode",
        type: "autoCompleteValueReturn",
        label: "Scheme Code",
        grid: 3,
    }
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let accountMaintenanceSearch = [
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "required": true,
        "label": "Account No",
        "validation": "numeric",
        "grid": 2,

    }
];

class SchemeCodeUpdateCS extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cbNotFound: false,
            inputData: {},
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            loading: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            schemeCode: [],
            showForm: false,
            showValue: false,
            disabled: false,
            oldSchemeCode: "",
        }

    }


    copyJson = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };


    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.renderStartCase(event);
        }
    };

    renderStartCase = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(accountMaintenanceSearch, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                loading: true,
                getData: false,
                showForm: false
            });
            let getAccountUrl = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let branchSolId=(LocalstorageEncrypt.encryptStorageFunction.getItem("solId")).toString();
                    let customerSolId=(response.data.solId).toString();


                    if (branchSolId !== undefined && customerSolId!==undefined && branchSolId === customerSolId) {

                        if (response.data.schmCode === "SINST" || response.data.schmCode === "CINST" || response.data.schmCode === "WSINS") {
                            for (let item of jsonForm) {
                                item.readOnly = false
                            }
                        } else {
                            for (let item of jsonForm) {
                                item.readOnly = true
                            }
                        }
                        if (response.data.schmCode === "CINST") {
                            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode", response.data.schmCode);
                        } else {
                            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode", response.data.schmCode);
                        }
                        let inputData = this.state.inputData;
                        inputData.customerName = response.data.acctName;
                        inputData.currency = response.data.crncyCode;
                        inputData.oldSchemeCode = response.data.schmCode;
                        inputData.sol = response.data.solId;
                        setTimeout(() => {
                            this.setState({
                                oldSchemeCode: response.data.schmCode,
                                inputData: inputData,
                                varValue: inputData,
                                showValue: true,
                                getData: true,
                                showForm: true,
                                loading: false,
                            });
                        }, 3000)
                    } else {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: "This account for different sol!"
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    dynamicApiCall = (fieldName, apiType, schmCode) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = this.findByVarNameGetKeyValue(schmCode, response.data);
                this.state.inputData.schemeCode = data;
                this.state.varValue.schemeCode = data;
                this.forceUpdate();
                this.setState({
                    schemeCode: response.data
                });
                let jsonArray = jsonForm;
                for (let i = 0; i < jsonArray.length; i++) {
                    let jsonObject = jsonArray[i];
                    if (jsonObject.varName === "schemeCode") {
                        jsonForm[i].enum = response.data;

                    }
                }
            }).catch((error) => {
            console.log(error);
        })
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        console.log(value, getKeyValue);
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderSearchForm = (root) => {
        return (
            <Grid item xs={12}>
                <Grid container>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent, this.onKeyDownForDedup)
                        }
                        <button
                            style={{
                                marginTop: "18px",
                                height: '30px'
                            }}
                            onClick={this.renderStartCase}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Grid>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        // console.log(this.state.inputData.schemeCode["value"]);
        if (this.state.getData && (this.state.inputData.oldSchemeCode === "SINST" || this.state.inputData.oldSchemeCode === "CINST" || this.state.inputData.oldSchemeCode === "WSINS")) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>
                        <br/>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                            disabled={this.state.disabled}
                        >
                            Scheme Update
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                disabled: true
            });
            let postData = {...this.state.inputData, schemeCode: this.state.inputData.schemeCode.key};
            let url = backEndServerURL + "/account/changeScheme";
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                setTimeout(() => {
                    let getAccountUrl = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (this.state.oldSchemeCode === response.data.schmCode) {
                                this.setState({
                                    disabled: false,
                                    loading: false,
                                    cbNotFound: true,
                                    title: "Operation not successful!"
                                });
                            } else {
                                let input = {};
                                input.accountNumber = this.state.inputData.accountNumber;
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Update Successful!",
                                    alert: true,
                                    getData: false,
                                    showForm: false,
                                    inputData: input,
                                    varValue: {},
                                    disabled: false,
                                    routeCase: true,
                                    loading: false
                                });
                            }
                        }).catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            disabled: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        })
                    });
                },10000)
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    disabled: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })
            });
        }
    };


    requestForm = () => {
        if (this.state.getData === true && this.state.showForm === true) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Scheme Code Update</h4>
                </CardHeader>
                <CardBody>
                    {this.renderSearchForm(classes.root)}
                    {this.renderNotification()}
                    {this.requestForm()}
                    {this.handleSubmitButton()}
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(SchemeCodeUpdateCS);
