import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import ErrorModal from "../CommonComponent/ErrorModal";
import CircularProgress from "@material-ui/core/CircularProgress";

const jsonForm = [

    {
        "label": "Cbl Loan Ac No",
        "type": "text",
        "varName": "cblLoanAcNo",
        "grid": 4,
        "readOnly": true
    },
    {
        "label": "File Ref Number",
        "type": "text",
        "varName": "fileRefNum",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Customer Name With Other Bank",
        "type": "text",
        "varName": "customerNameOB",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Customer AC With Other Bank",
        "type": "text",
        "varName": "customerAcOB",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Other Bank Routing Number",
        "type": "text",
        "varName": "obRoutingNum",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Cbl Case Ac No",
        "type": "text",
        "varName": "cblCaseAcNo",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Installment Amount",
        "type": "text",
        "varName": "installmentAmount",
        "grid": 4,
        "readOnly": false
    },
    {
        "label": "Installment Start Date",
        "type": "date",
        "varName": "installmentStartDate",
        "grid": 4,
        "readOnly": false
    },
    {
        varName: "loanExpairDate",
        type: "date",
        label: "Loan Expair Date",
        grid: 4,
        errorMessage: "Error",
    },
    {
        varName: "installmentFequency",
        type: "text",
        label: "Installment Fequency",
        grid: 4,
        "readOnly": false
    },
    {
        varName: "cblCaseAcType",
        type: "text",
        label: "Cbl Case Ac Type",
        grid: 4,
        "readOnly": false
    },
    {
        varName: "disbursementDate",
        type: "date",
        label: "Disbursement Date",
        grid: 4,
        errorMessage: "Error",
    },
    {
        varName: "eftnUpdateDate",
        type: "date",
        label: "EFTN Update Date",
        grid: 4,
        errorMessage: "Error",
    },
    {
        varName: "emiDate",
        type: "number",
        label: "EMI Date",
        grid: 4,
        "readOnly": false
    },
    {
        varName: "bankName",
        type: "text",
        label: "Bank Name",
        grid: 4,
        "readOnly": false
    },
    // {
    //     varName: "nameLenght",
    //     type: "number",
    //     label: "Name Lenght",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "acLength",
    //     type: "number",
    //     label: "Ac Length",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "emiDateDiff",
    //     type: "text",
    //     label: "EMI Date Diff",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "emiBeforeRepricing",
    //     type: "text",
    //     label: "EMI Before Repricing",
    //     grid: 4,
    //     "readOnly": false
    // },
    {
        varName: "emiDate",
        type: "text",
        label: "EMI Date",
        grid: 4,
        "readOnly": false
    },
    // {
    //     varName: "emiDatediff",
    //     type: "number",
    //     label: "EMI Date Diff",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "diff",
    //     type: "text",
    //     label: "Diff",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "rePricingEmi",
    //     type: "text",
    //     label: "Re-Pricing EMI",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "nameLenght",
    //     type: "text",
    //     label: "Name Length",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "acLength",
    //     type: "text",
    //     label: "Ac Length",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "partial",
    //     type: "text",
    //     label: "Partial",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "closed",
    //     type: "text",
    //     label: "Closed",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "phase",
    //     type: "text",
    //     label: "Phase",
    //     grid: 4,
    //     "readOnly": false
    // },
    // {
    //     varName: "rePriced",
    //     type: "text",
    //     label: "Re-Priced",
    //     grid: 4,
    //     "readOnly": false
    // },
    {
        "label": "Status",
        "type": "select",
        "varName": "status",
        "grid": 4,
        "enum": [
            "Active",
            "InActive"
        ]
    },
    {
        varName: "remarks",
        type: "text",
        label: "Remarks",
        grid: 12,
        "readOnly": false
    },

];

class EFTNEdit extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            getData: false,
            fileUploadData: {},
            //      getDataArray:this.props.arrayValue,
            showValue: false,
            uploadModal: false,
            imageModel: false,
            autoComplete: {},
            secondColumnData: [],
            caseId: "",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            showData: true,
            supportDocumentArray: [],
            responseData: [],
            remarkValue: "",
            targetValue: "",
            viewModal: false,
            getRemarks: [],
            callCategory: "",
            getTargets: [],
            getImageLink: [],
            getImageBoolean: false,
            imageModalBoolean: false,
            imageName: [],
            callCategoryCheckResult: "",
            selectImage: "",
            message: "",
            err: false,
            loader: true,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue:
                {
                    cblLoanAcNo: this.props.data.cblLoanAcNo,
                    fileRefNum: this.props.data.fileRefNum,
                    customerNameOB: this.props.data.customerNameOB,
                    customerAcOB: this.props.data.customerAcOB,
                    obRoutingNum: this.props.data.obRoutingNum,
                    cblCaseAcNo: this.props.data.cblCaseAcNo,
                    installmentAmount: this.props.data.installmentAmount,
                    // acLength:this.props.data.acLength,
                    bankName: this.props.data.bankName,
                    // closed:this.props.data.closed,
                    cblCaseAcType: this.props.data.cblCaseAcType,
                    // diff:this.props.data.diff,
                    // emiBeforeRepricing:this.props.data.emiBeforeRepricing,
                    emiDate: this.props.data.emiDate,
                    // emiDateDiff:this.props.data.emiDateDiff,
                    installmentFequency: this.props.data.installmentFequency,
                    // nameLenght:this.props.data.nameLenght,
                    // partial:this.props.data.partial,
                    //phase:this.props.data.phase,
                    // rePriced:this.props.data.rePriced,
                    // rePricingEmi:this.props.data.rePricingEmi,
                    remarks: this.props.data.remarks,
                    status: this.props.data.status,
                    installmentStartDate: (this.props.data.installmentStartDate !== undefined) ? this.dateConverter(this.props.data.installmentStartDate).toString() : null,
                    loanExpairDate: (this.props.data.loanExpairDate !== undefined) ? this.dateConverter(this.props.data.loanExpairDate).toString() : null,
                    eftnUpdateDate: (this.props.data.eftnUpdateDate !== undefined) ? this.dateConverter(this.props.data.eftnUpdateDate).toString() : null,
                    disbursementDate: (this.props.data.disbursementDate !== undefined) ? this.dateConverter(this.props.data.disbursementDate).toString() : null,
                },

        }
    }

    dateConverter = (value) => {
        let dateString = value.substr(0, 10);
        let dataArray = dateString.split("-");
        return dataArray[1] + "/" + dataArray[2] + "/" + dataArray[0];
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        this.setState({
            getData: true,
            loader: false,
            showValue: true,
            //varValue: this.removeNullValue(this.props.data),
            inputData: this.removeNullValue(this.props.data),

            getValue: true,

        });

        console.log(this.state.varValue.installmentStartDate);
    }


    categoryCheck = () => {
        let url = backEndServerURL + "/checkSameCallCategory/" + this.state.inputData.callCategory + "/" + this.state.callCategory;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    callCategoryCheckResult: response.data
                })
            }).catch((error) => {

        })
    };

    updateHandler = (event) => {
        this.setState({
            loader: true
        });
        event.preventDefault();
        let inputData = this.state.inputData;
        if (inputData.installmentStartDate !== undefined) inputData.installmentStartDate = new Date(inputData.installmentStartDate);
        if (inputData.loanExpairDate !== undefined) this.state.inputData.loanExpairDate = new Date(this.state.inputData.loanExpairDate);
        if (inputData.eftnUpdateDate !== undefined) this.state.inputData.eftnUpdateDate = new Date(this.state.inputData.eftnUpdateDate);
        if (inputData.disbursementDate !== undefined) this.state.inputData.disbursementDate = new Date(this.state.inputData.disbursementDate);
        console.log(this.state.inputData);
        axios.put(backEndServerURL + "/eftn", this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    loader: false,
                    notificationType: "success",
                    notificationTitle: "Success",
                    notificationMessage: "Successfully Updated",
                    alert: true
                });
                this.props.closeModal();
            }).catch((error) => {
            console.log(error);
            this.setState({
                loader: false
            })
        })

    };
    cancelHandler = (event) => {
        event.preventDefault();
        this.props.closeModal();
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    updateComponent = () => {
        this.forceUpdate();

    };

    open = (event) => {
        event.preventDefault();
        this.setState({
            imageModel: true
        })
    };

    closeModal = () => {
        this.setState({
            imageModel: false
        })
    };
    closeErrorModal = () => {
        this.setState({
            viewModal: false
        })
    };

    openErrorModel = (data) => {
        this.setState({
            message: data,
            viewModal: true
        })

    };


    render() {
        //   console.log("headerValue "+this.props.headerValue)||this.props.headerValue === 'Forwarded'
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else {
            console.log("else");
            return (
                <div>
                    <React.Fragment>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >EFTN Edit <a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        {
                                            this.renderFileShow()
                                        }
                                    </ThemeProvider>
                                </Grid>
                                <Grid item xs='3' style={{marginTop: "20px"}}>
                                    {/*<center>*/}
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginRight: '10px',
                                            marginLeft: '10px'
                                        }}
                                        onClick={this.updateHandler}
                                    >
                                        Update
                                    </button>
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                        onClick={this.props.closeModal}

                                    >
                                        Cancel
                                    </button>
                                    {/*</center>*/}
                                </Grid>
                                {this.renderNotification()}
                            </CardBody>
                        </Card>
                    </React.Fragment>
                </div>
            );
        }
    }

    renderFileShow() {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        )

    }


    renderRemarksData = () => {


    };


    renderCallState() {
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Call Status<a><CloseIcon onClick={this.props.closeModal} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={3}>
                            <ThemeProvider theme={theme}>
                                {
                                    CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
                                }
                            </ThemeProvider>
                        </Grid>
                        <Grid item xs='3' style={{marginTop: "20px"}}>
                            {/*<center>*/}
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'middle',
                                    marginRight: '10px',
                                    marginLeft: '10px'
                                }}
                                onClick={this.updateHandler}
                            >
                                Save
                            </button>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'middle',
                                }}
                                onClick={this.props.closeModal}

                            >
                                Cancel
                            </button>
                            {/*</center>*/}
                        </Grid>

                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }} target={"_blank"}>Preview Document {index + 1}</Button>&nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    // viewMapping(event,data,index){
    //     console.log(data)
    //     return (
    //         <React.Fragment>
    //                 <a target={"_blank"} href={backEndServerURL + "/file/" + data}></a>
    //         </React.Fragment>
    //     )
    // }

    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.viewModal}>
                    <DialogContent>
                        <ErrorModal title={this.state.message} closeModal={this.closeErrorModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    }
}

export default EFTNEdit;
