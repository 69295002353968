import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label"
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import CheckList from "./CheckList";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }]
;
let bearer = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer Status/Customer Visited",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 3,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BMApproval2 extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: true,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false,
        labelData: [],
        objectForInitialNewNominee: [],
        customerDetails: {},
        disabled: false,
        cbNotFound: false,
        checkList: false,
        linkedCB: false,
        confirmationAlert: false,
        buttonType: ""
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return <><br/><PreviewMappingImage appId={this.props.appId} classes={classes}/></>
        }
    };

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    componentDidMount() {
        var remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        linkedCB: response.data.freeFlag2 === "linkedCB"
                    });
                    let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + response.data.accountNumber;
                    axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            let data = response.data;

                            if (response.status === 200) {
                                this.setState({
                                    customerDetails: data
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    // if (response.data.nomineeChange === true && parseInt(response.data.numberOfNominee) > 0) {
                    //
                    //     var sl;
                    //     let newSl;
                    //     let objectForNominee = [];
                    //     let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                    //     for (var i = 0; i < response.data.numberOfNominee; i++) {
                    //         newSl = i + 1;
                    //         sl = oldNominee + (i + 1);
                    //         objectForNominee.push(
                    //             {
                    //                 "varName": "Nominee " + i,
                    //                 "type": "title",
                    //                 "label": " New Nominee  " + newSl,
                    //                 "grid": 12,
                    //
                    //             },
                    //             {
                    //                 "varName": "nomineeName" + sl,
                    //                 "type": "text",
                    //                 "label": "Nominee Name",
                    //                 "grid": 2,
                    //                 "readOnly": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeDob" + sl,
                    //                 "type": "date",
                    //                 "label": "D.O.B",
                    //                 "grid": 2,
                    //                 "readOnly": true,
                    //             },
                    //             {
                    //                 "varName": "percentageNominee" + sl,
                    //                 "type": "text",
                    //                 "label": "Percentage %",
                    //                 "grid": 2,
                    //                 "readOnly": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeMinor" + sl,
                    //                 "type": "select",
                    //                 "label": "Nominee Minor",
                    //                 "enum": ["Yes", "No"],
                    //                 "grid": 2,
                    //                 "readOnly": true,
                    //             },
                    //             {
                    //                 "varName": "empty",
                    //                 "type": "empty",
                    //                 "label": "",
                    //                 "grid": 4,
                    //             },
                    //         )
                    //
                    //
                    //     }
                    //     this.setState({
                    //         objectForInitialNewNominee: objectForNominee,
                    //     })
                    //
                    // }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }

                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                            console.log(response.data);
                                            response.data.map((data) => {

                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    this.setState({
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appData: response.data,
                        getData: true,
                        showValue: true,
                        // loading: false
                    }, this.stopLoadingForLinkedCB);

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    stopLoadingForLinkedCB = () => {
        if (this.state.inputData.showLabel) {
            this.setState({
                loading: false
            })
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {

            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address Field 1",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address Field 2",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "City",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "city",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "State",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "state",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "label": "Country",
                        "grid": 3,
                        "readOnly": true,
                        "enumType": "country",
                        "enum": [],
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id No/Registration No.",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 3,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 3,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "guardianCode",
                        "enum": [],
                        "label": "Guardian Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "city",
                        "enum": [],
                        "readOnly": true,
                        "label": "City Code",
                        "grid": 3,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "state",
                        "enum": [],
                        "label": "State Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 3,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enumType": "country",
                        "enum": [],
                        "readOnly": true,
                        "label": "Country",
                        "grid": 3,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
            )

        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                disabled: true,
                loading: true,
                confirmationAlert: false
            });
            let data = this.state.buttonType;
            this.state.inputData.returnDataBm = true;
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.props.appId;
            axios.post(commentsUrl, {remark: this.state.inputData.bmRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
            var appId = this.props.appId;
            var approval = data;

            data === "APPROVED" ? approval = "APPROVE" : approval = "REJECT";

            let deferalUrl = backEndServerURL + "/deferral/approval";
            axios.post(deferalUrl, {
                appId,
                approval
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            if (this.state.inputData.cs_bearer === "both_approval" && data === "APPROVED") {
                this.state.inputData.cs_bearer = "call_center_approval";
            } else if (this.state.inputData.cs_bearer === "bm_approval" && data === "APPROVED") {
                this.state.inputData.cs_bearer = "NO";

            } else if (this.state.inputData.cs_deferal === "YES" && data === "APPROVED" && this.state.inputData.cs_bearer === "call_center_approval") {
                this.state.inputData.cs_bearer = "call_center_approval";

            } else if (data === "APPROVED") {
                this.state.inputData.cs_bearer = "NO";
            }
            this.state.inputData.bm_approval = data;
            this.state.inputData.cs_deferal = "NO";
            this.state.inputData.csDeferal = "NO";


            this.state.inputData.bmRemarks = undefined;
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        var url = backEndServerURL + "/case/route/" + this.props.appId;

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        title: "Successful!",
                                        notificationMessage: "Successfully Routed!",
                                        disabled: false,
                                        alert: true,
                                        loading: false
                                    });

                                    this.props.closeModal();
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    disabled: false,
                                    loading: false
                                });
                                console.log(error);

                            });
                    }

                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false
                    });
                    console.log(error)
                });
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();

        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bmRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            buttonType: data
        });

    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
                    }
                </React.Fragment>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)
            )
        }
    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table

                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />

                            <br/>

                        </div>
                    </div>
                    <br/>
                </>
            )
        }

    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>


            )
        }
    };

    viewCheckList = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    // renderIntialNewNomineeForm = () => {
    //     if (this.state.inputData.nomineeChange === true && this.state.objectForInitialNewNominee.length > 0) {
    //
    //         return (
    //             <>
    //                 <Grid item xs={12}>
    //                     <br/>
    //                 </Grid>
    //                 {
    //                     CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForInitialNewNominee, this.updateComponent)
    //                 }
    //                 <Grid item xs={12}>
    //                     <br/>
    //                 </Grid>
    //             </>
    //         )
    //
    //     }
    // };

    renderLabelAndSignature = () => {
        if (this.state.getData) {
            return (
                <>
                    <Label linkedCB={this.state.linkedCB} stopLoading={this.stopLoading} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber} style={{margin: "0 auto"}}
                           accountNumber={this.state.inputData.accountNumber}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <br/>
                    <Grid container spacing={0}>
                        <Grid item xs={1.5}>
                            {this.renderSignature()}
                        </Grid>
                    </Grid>
                    <br/>
                </>
            )
        } else {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={1.5}>
                        {this.renderSignature()}
                    </Grid>
                </Grid>
            )
        }
    };

    renderSignature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>
        }
    };

    renderBearerForm = () => {
        if (!this.state.loading) {
            return CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)

        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false
        })
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.checkList}>
                    <DialogContent>
                        <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                    </DialogContent>
                </Dialog>
                {this.renderLabelAndSignature()}
                <ThemeProvider theme={theme}>
                    <Grid container spacing={0}>

                        {this.renderNotification()}
                        {this.renderForm()}
                        {
                            this.renderExistingNomineeForm()
                        }
                        {this.renderBearerForm()}
                    </Grid>
                    <Grid item={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    {
                        this.viewAttachedImages()
                    }
                    {this.viewCheckList()}
                    {this.renderRemarks()}
                    <br/>
                    <div>
                        {this.renderSubmitButton()}
                    </div>
                </ThemeProvider>
            </div>
        )
    }

}

export default withStyles(styles)(BMApproval2);
