import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createMuiTheme} from "@material-ui/core/styles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import InstaPackRequisition from "./InstaPackRequisition";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    // {
    //     varName: "type",
    //     type: "select",
    //     label: "Type",
    //     grid: 2,
    //     enum: [
    //         "Finacle",
    //         "Ababil",
    //     ]
    // },
    // {
    //     varName: "customerType",
    //     type: "select",
    //     label: "Customer type",
    //     grid: 2,
    //     enum: [
    //         " Normal",
    //         "CityAlo",
    //         "CityAlo(Ababil)",
    //         "Priority",
    //         "Priority(Finacle)",
    //         "Priority(Ababil)",
    //         "Sapphire"
    //     ]
    // },
    {
        varName: "accountType",
        type: "select",
        label: "Account type",
        grid: 2,
        enum: [
            "SAVINGS",
            "CURRENT",
            "CITY ALO",
            // "Savings(Alo)",
            // "Savings(Ababil Alo)"
        ]
    },
    // {
    //     varName: "currency",
    //     type: "autoCompleteValueReturn",
    //     label: "Currency",
    //     enum: [],
    //     grid: 2
    // },
    // {
    //     varName: "branch",
    //     type: "autoCompleteValueReturn",
    //     label: "Branch",
    //     grid: 2,
    // },
];

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class CsInstaPackManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            searchItem: "",
            autoComplete: {},
            requisitionConfiguration: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            loading: true,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
            rowsPerPage: 100,
            allData: [],
            requisitionModal: false,
            loader: true,
            columns: [
                {title: "Case Id", field: "caseId"},
                {title: "Customer Name", field: "userName"},
                {title: "Branch Name", field: "branchName"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Account Type", field: "accountType"},
                {title: "Page of instapack", field: "pageofinstapack"},
                {title: "Quantity", field: "quantity"},
                {title: "Status", field: "status"},
                {title: "Maker", field: "maker"},
                {title: "Verifier ID", field: "verifier"},
            ],
            requisitionColumns: [
                {title: "Sol Id", field: "solId"},
                {title: "Branch Name", field: "branchName"},
                {title: "Stock Limit", field: "stockLimit"},
                {title: "Account Type", field: "accountType"},
                {title: "Scheme Code", field: "schemeCode"},
                {title: "Maker", field: "maker"},
                {title: "Verifier ID", field: "verifier"},
                {
                    title: 'Action', field: 'claimAction', render: rowData => (
                        <Button onClick={(e) => {
                            this.instaRequsition(e, rowData)
                        }} style={buttonStyle}>Requisition</Button>
                    )

                },
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
        });
        if (value === "All") {
            this.fetchDataFromAPI('All', 'getBranchInstaPackAll/page/0');
        } else if (value === "Send to Branch") {
            this.fetchDataFromAPI('Send to Branch')
        } else if (value === "Received by Branch") {
            this.fetchDataFromAPI('Received by Branch')
        } else if (value === 'New Requisition') {
            this.newRequisition("New Requisition")
        }
    };

    // dynamicApiCall = (apiType) => {
    //     let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
    //     axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
    //         .then((response) => {
    //             for (let form of SearchForm) {
    //                 if (form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch") {
    //                     form.enum = response.data
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // };

    instaRequsition = (e, rowData) => {
        e.preventDefault();
        let data = {...rowData.accountType};

        this.setState({
            requisitionModal: true,
            requisitionConfiguration: data
        })
    };

    instaChequeRequisition = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.requisitionModal}>
                <DialogContent className={classes.dialogPaper}>
                    <InstaPackRequisition branchConfiguration={this.state.requisitionConfiguration}
                                          closeModal={this.closeRequisitionModal}/>
                </DialogContent>
            </Dialog>
        )
    };

    closeRequisitionModal = () => {
        this.setState({
            requisitionModal: false
        })
    };


    componentDidMount() {
        // this.dynamicApiCall("branch");
        let inbox_url = backEndServerURL + "/getBranchInstaPackAll/page/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        userName: response.data.content[i].customerName,
                        branchName: response.data.content[i].branchName,
                        accountNumber: response.data.content[i].accountNumber,
                        accountType: response.data.content[i].accountType,
                        instapackDesign: response.data.content[i].chequebookDesign,
                        pageofinstapack: response.data.content[i].pageOfChequebook,
                        quantity: response.data.content[i].quantity,
                        status: response.data.content[i].status,
                        maker: response.data.content[i].initiatorId,
                        verifier: response.data.content[i].verifierId,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                setTimeout(() => {
                    this.setState({
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        allData: array,
                        responseData: responseArray,
                        loader: false,
                        loading: false
                    });
                }, 1000);
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    resetHandler = () => {
        window.location.reload();
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    searchData = (e, page, status) => {

        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }

        let inbox_url = backEndServerURL + "/filterBranchInstaPackInventory/" + status + "/" + page;

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        userName: response.data.content[i].customerName,
                        branchName: response.data.content[i].branchName,
                        accountNumber: response.data.content[i].accountNumber,
                        accountType: response.data.content[i].accountType,
                        instapackDesign: response.data.content[i].chequebookDesign,
                        pageofinstapack: response.data.content[i].pageOfChequebook,
                        quantity: response.data.content[i].quantity,
                        status: response.data.content[i].status,
                        maker: response.data.content[i].initiatorId,
                        verifier: response.data.content[i].verifierId,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Insta Cheque Book
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, 'All')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Deliverable Management (Insta Cheque Books)</h4>
                        </CardHeader>
                        <CardBody>
                            {
                                this.instaChequeRequisition()
                            }
                            <div className="deliverable">
                                <React.Fragment>
                                    <div>
                                        <Menu pointing>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Send to Branch"
                                                active={activeItem === "Send to Branch"}
                                                style={
                                                    activeItem === "Send to Branch"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Branch");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Received by Branch"
                                                active={activeItem === "Received by Branch"}
                                                style={
                                                    activeItem === "Received by Branch"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Received by Branch");
                                                }}
                                            />
                                            <Menu.Item
                                                name="New Requisition"
                                                active={activeItem === "New Requisition"}
                                                style={
                                                    activeItem === "New Requisition"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "New Requisition");
                                                }}
                                            />

                                        </Menu>

                                        {this.tableChoice()}

                                    </div>
                                </React.Fragment>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    newRequisition = (status) => {
        this.setState({
            activeItem: status,
            loader: true
        });
        let inbox_url = backEndServerURL + "/getBranchInstaPackConfiguration";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                if (response.data.length > 0) {
                    rowData = {
                        id: response.data.id,
                        solId: response.data.solId,
                        branchName: response.data.branchName,
                        stockLimit: response.data.stockLimit,
                        accountType: response.data.accountType,
                        schemeCode: response.data.schemeCode,
                    };
                    array.push(rowData);
                }
                this.setState({
                    allData: array,
                    responseData: responseArray,
                    activeItem: status,
                    totalRow: response.data.length,
                    loader: false
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    fetchDataFromAPI = (status, url) => {

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getBranchinstapackstatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            caseId: response.data.content[i].appId,
                            userName: response.data.content[i].customerName,
                            branchName: response.data.content[i].branchName,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].accountType,
                            instapackDesign: response.data.content[i].chequebookDesign,
                            pageofinstapack: response.data.content[i].pageOfChequebook,
                            quantity: response.data.content[i].quantity,
                            status: response.data.content[i].status,
                            maker: response.data.content[i].initiatorId,
                            verifier: response.data.content[i].verifierId,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        activeItem: status,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }

        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getBranchInstaPackAll/page/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getBranchinstapackstatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                caseId: response.data.content[i].appId,
                                userName: response.data.content[i].customerName,
                                branchName: response.data.content[i].branchName,
                                accountNumber: response.data.content[i].accountNumber,
                                accountType: response.data.content[i].accountType,
                                instapackDesign: response.data.content[i].chequebookDesign,
                                pageofinstapack: response.data.content[i].pageOfChequebook,
                                quantity: response.data.content[i].quantity,
                                status: response.data.content[i].status,
                                maker: response.data.content[i].initiatorId,
                                verifier: response.data.content[i].verifierId,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: 'All',
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDeliverToBranchToSendToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateinstapackstatus/Received by Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Send to Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForReceivedByBranchToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Received by Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Received by Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForReceivedByBranchToSold = (event, rowData, props) => {
        if (this.state.activeItem === "Received by Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Sold";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Received by Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };


    tableChoice() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Send to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDeliverToBranchToSendToBranch(event, rowData, props)}
                                            >
                                                Received by Branch
                                            </Button>

                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Branch')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Received by Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Received by Branch')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "New Requisition" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.requisitionColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Destroy')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(CsInstaPackManage);
