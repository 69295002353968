import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import SdProcessFlowMasterAdd from "./SdProcessFlowMasterAdd";
import BranchProcessFlowConfigureAdd from "../ProcessFlow/BranchProcessFlowConfigureAdd";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#142398",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};


class SdAndBranchProcessFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'null',
            branchProcessFlow: false,
            sdProcessFlow: false,
            SelectedData: "",
            branchProcessFlowData: [[" ", " "]],
            sdProcessFlowData: [[" ", " "]],
        }

    }

    closeModal = () => {
        this.setState({
            branchProcessFlow: false,
            sdProcessFlow: false,
        })
    };


    handleChangeSelected = (name, value) => {
        this.setState({
            SelectedData: value,
        });
        if (value === 'SdProcessFlow') {

            this.setState({
                selectedCategory: 'SdProcessFlow'
            })


        } else if (value === 'BranchProcessFlow') {
            this.setState({
                selectedCategory: 'BranchProcessFlow'
            });

        } else {
        }

    };
    renderSelectMenu = () => {
        return (
            <InputLabel>Process FLow Configure</InputLabel>,
                <Select name="select" value={this.state.SelectedData}
                        onChange={event => this.handleChangeSelected(event.target.name, event.target.value)}>
                    <MenuItem key="SdProcessFlow"
                              value="SdProcessFlow">SdProcessFlow</MenuItem>,
                    <MenuItem key="BranchProcessFlow"
                              value="BranchProcessFlow">BranchProcessFlow</MenuItem>
                </Select>
        )
    };

    render() {
        const {classes} = this.props;


        if (this.state.selectedCategory === 'SdProcessFlow') {


            return (
                <section>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>SD Process Flow Configure</h4>
                                </CardHeader>
                                <CardBody>

                                    <Grid item xs={this.props.grid}>
                                        <FormControl style={{minWidth: 300}}>

                                            {this.renderSelectMenu()}
                                        </FormControl>
                                    </Grid>

                                    <SdProcessFlowMasterAdd/>
                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        } else if (this.state.selectedCategory === 'BranchProcessFlow') {
            return (
                <section>


                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>Branch process Flow</h4>
                                </CardHeader>
                                <CardBody>

                                    <FormControl style={{minWidth: 300}}>
                                        {this.renderSelectMenu()}
                                    </FormControl>
                                    <BranchProcessFlowConfigureAdd/>

                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        } else {
            return (
                <section>

                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 className={classes.cardTitleWhite}>Branch process Flow</h4>

                                </CardHeader>
                                <CardBody>

                                    <FormControl style={{minWidth: 300}}>
                                        <InputLabel>Process FLow Configure</InputLabel>
                                        {this.renderSelectMenu()}

                                    </FormControl>
                                </CardBody>
                            </Card>
                        </GridItem>


                    </GridContainer>
                </section>
            );
        }
    }

}

export default withStyles(styles)(SdAndBranchProcessFlow);
