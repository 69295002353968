import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import {createMuiTheme} from "@material-ui/core/styles";
import axiosDownload from "../FunctionForFileDownload";
import loader from "../../../Static/loader.gif";
import {tabActive, tabInactive} from "../configuration";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import Notification from "../../NotificationMessage/Notification";
import FailedListModal from "../WelcomeLetter/FailedListModal";
import ConfirmationModal from "../../workflow/FundTransfer/ConfirmationModal";
import TableComponent from "../../workflow/CommonComponent/TableComponent";

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To date",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Branch Name",
        grid: 2
    },
    {
        varName: "innerOuter",
        type: "select",
        enum: [
            "IN",
            "OUT"
        ],
        label: "Branch Type",
        grid: 2
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        onKeyDown: true,
        grid: 2,
        enum: []
    },
    {
        varName: "productType",
        type: "select",
        enum: [],
        label: "Product Type",
        grid: 2,
        conditional: true,
        conditionalVarName: "showProductType",
        conditionalVarValue: true,
    },
    {
        varName: "currency",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Currency",
        grid: 2,
    },
];


class DebitCardManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: false,
            disabled: false,
            searchItem: "",
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            autoComplete: {},
            rowIdArray: [],
            confirmationAlert: false,
            selectedCount: 0,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            countAll: 0,
            countTranzwareCompleted: 0,
            countDeliveredToBranch: 0,
            countCallCenter: 0,
            countDestroyRequest: 0,
            allData: [],
            success: 0,
            failed: 0,
            failedList: [],
            loader: true,
            failedModal: false,
            mailModal: false,
            selectedRowIdArray: [],
            allColumns: [
                {title: "Sl", field: "sl"},
                {title: "Bom Approval Date", field: "bomApprovalDate"},
                {title: "Status", field: "status"},
                {title: "Source", field: "source"},
                {title: "Initiating Branch SOL ID", field: "initiatingBranchSolId"},
                {title: "Card to be Delivered SOL ID", field: "deliveryBranchSolId"},
                // {title: "Batch No", field: "batchNo"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "cardNumber"},
                {title: "Batch No", field: "batchNo"},
                {title: "Customer Name", field: "customerName"},
                {title: "Name on card", field: "nameOnCard"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Customer Type", field: "customerType"},
                {title: "CB No", field: "cbNumber"},
                {title: "Account Type", field: "accountType"},
                {title: "Account Currency Type", field: "accountCurrencyType"},
                {title: "RM Code", field: "rmCode"},
                // {title: "Source Branch/Sol id", field: "sourceBranchSolId"},
                {title: "CASA SOL ID", field: "casaSolId"},
                {title: "Emil id", field: "emailId"},
                {title: "Cell number", field: "cellNumber"},
                {title: "Card Type", field: "cardType"},
                {title: "Product Type", field: "productType"},
                {title: "CCEP Name/Campaign Name", field: "ccep"},
                {title: "Passport Number", field: "passportNumber"},
                {title: "Passport expiry date", field: "passportExpiryDate"},
                {title: "Passport Issue date", field: "passportIssueDate"},
                {title: "Passport Issue Place", field: "passportIssuePlace"},
                {title: "Tranzware Completed Date", field: "tranzwareCompletedDate"},
                {title: "Dispatch Date", field: "sendToCourierDate"},
                {title: "Courier Name", field: "courierName"},
                {title: "Branch Receiver ID", field: "branchReciverId"},
                {title: "Receiver Name", field: "branchReceiverName"},
                {title: "Receiver Date & Time", field: "branchReceiverDate"},
                {title: "Deliver User ID", field: "branchDeliveredId"},
                {title: "Name", field: "branchDeliveredName"},
                {title: "Date & Time", field: "branchDeliveredDate"},
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            pageNumber: 0
        });
        this.fetchDataFromAPI(value);
    };

    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let item of SearchForm) {
                    if (item.varName === "branchName") {
                        item.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getCardType() {
        let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataCardTypes";
        axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    if (form.type === "select" && form.varName === "cardType") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    functionForGetCurrency = () => {
        let branchUrl = backEndServerURL + "/finMasterData/currency";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "currency") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    closeFailedModal = () => {
        this.setState({
            failedModal: false
        });
        this.functionForNotificationMessage("Successful", `${this.state.success} Status Change Successful and ${this.state.failed} status change failed`);
    };

    functionForGetProductType = (data) => {
        if (data.varName === "cardType") {
            this.setState({
                loading: true
            });
            this.state.inputData.productType = "";
            this.state.inputData.showProductType = false;
            this.forceUpdate();
            let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/" + this.state.inputData.cardType;
            axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let form of SearchForm) {
                            if (form.type === "select" && form.varName === "productType") {
                                form.enum = response.data
                            }
                        }
                        this.state.inputData.showProductType = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Product Type Not Found!",
                        loading: false
                    });
                })
        }
    };


    componentDidMount() {
        this.getBranchAll();
        this.getCardType();
        this.functionForGetCurrency();
        this.fetchDataFromAPI("All");
        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);
        this.setState({
            currentDate: currentDate
        });
    }

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
            disabled: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    dateConverter = (value) => {
        if (value) {
            let dateString = value.split("T");
            let time = dateString[1];
            return dateString[0] + " " + time.split(".")[0]
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    mailNotification = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.mailModal}>
                <DialogContent className={classes.dialogPaper}>
                    <MailNotification closeModal={this.closeMailModal} processName="DEBIT CARD"/>
                </DialogContent>
            </Dialog>
        )
    };

    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false
            })
        } else {
            this.setState({
                mailModal: false,
                loader: true,
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/debitCardSendMail/Send to Courier";
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    if (response.status === 200) {
                        this.functionForNotificationMessage('Successful', "Mail Send Successful!");
                        let download_url = backEndServerURL + "/getDebitcardExcelResource/Send to Courier";
                        axiosDownload.downloadFile(download_url, 'POST', this.state.selectedRowIdArray, `Debit-Card-Emb-${this.state.currentDate}-Expone.xlsx`).then((result) => {
                            if (result) {
                                this.fetchDataFromAPI('Tranzware Completed')
                            }
                        }).catch((error) => {
                            this.fetchDataFromAPI('Tranzware Completed')
                        })

                    }
                })
                .catch(error => {
                    this.functionForNotificationMessage('Failed', "Mail Send Failed!");
                    this.setState({
                        loader: false
                    });
                });
        }
    };

    closeConfirmation = (value, input) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loader: true,
            });
            let single_url = backEndServerURL + "/updateDebitCardStatus/Decline";
            axios.post(single_url, this.state.rowIdArray, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    this.setState({
                        rowIdArray: [],
                    });
                    this.functionForNotificationMessage("Successful!", "Status Change Successful");
                    this.fetchDataFromAPI('New')
                })
                .catch(error => {
                    console.log(error);
                    this.functionForNotificationMessage("Failed!", "Status Change Failed");
                });
            // let postData = {};
            // postData.idList = this.state.rowIdArray;
            // postData.remarks = input.remarks;
            // let single_url = backEndServerURL + "/setRemarksDeclinesDebitCard";
            // axios.post(single_url, postData, {
            //     headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            // })
            //     .then(response => {
            //         this.setState({
            //             rowIdArray: [],
            //         });
            //         this.functionForNotificationMessage("Successful!", "Status Change Successful");
            //         this.fetchDataFromAPI('New')
            //     })
            //     .catch(error => {
            //         console.log(error);
            //         this.functionForNotificationMessage("Failed!", "Status Change Failed");
            //     });
        } else {
            this.setState({
                rowIdArray: [],
                confirmationAlert: false,
                loader: false,
            })
        }
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search Debit Card
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent,
                                    "",
                                    this.functionForGetProductType
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, 'All');
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Debit Card)</h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmation}
                                    title={this.state.title}
                                    // remarksAllow={true}
                                />
                            </DialogContent>
                        </Dialog>
                        {this.mailNotification()}
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.failedModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <FailedListModal titleHeader="Failed List"
                                                 closeModal={this.closeFailedModal}
                                                 failedList={this.state.failedList}/>
                            </DialogContent>
                        </Dialog>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>
                                        <Menu.Item
                                            name="All"
                                            active={activeItem === "All"}
                                            style={
                                                activeItem === "All"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "All");
                                            }}
                                        />
                                        <Menu.Item
                                            name="New"
                                            active={activeItem === "New"}
                                            style={
                                                activeItem === "New"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "New");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Decline"
                                            active={activeItem === "Decline"}
                                            style={
                                                activeItem === "Decline"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Decline");
                                            }}
                                        />
                                        <Menu.Item
                                            name="On Processing"
                                            active={activeItem === "On Processing"}
                                            style={
                                                activeItem === "On Processing"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "On Processing");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Failed"
                                            active={activeItem === "Failed"}
                                            style={
                                                activeItem === "Failed"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Failed");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Tranzware Completed"
                                            active={activeItem === "Tranzware Completed"}
                                            style={
                                                activeItem === "Tranzware Completed"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Tranzware Completed");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Send to Courier"
                                            active={activeItem === "Send to Courier"}
                                            style={
                                                activeItem === "Send to Courier"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Courier");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Deliver to Branch"
                                            active={activeItem === "Deliver to Branch"}
                                            style={
                                                activeItem === "Deliver to Branch"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Deliver to Branch");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Branch Received"
                                            active={activeItem === "Branch Received"}
                                            style={
                                                activeItem === "Branch Received"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Branch Received");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Delivered to Customer"
                                            active={activeItem === "Delivered"}
                                            style={
                                                activeItem === "Delivered"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Delivered");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Call Center"
                                            active={activeItem === "Send to Call Center"}
                                            style={
                                                activeItem === "Send to Call Center"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Call Center");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Destroy Request"
                                            active={activeItem === "Destroy Request"}
                                            style={
                                                activeItem === "Destroy Request"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy Request");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Destroy"
                                            active={activeItem === "Destroy"}
                                            style={
                                                activeItem === "Destroy"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy");
                                            }}
                                        />
                                    </Menu>
                                    {this.tableChoice()}
                                    {this.renderNotification()}
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    fetchDataFromAPI = (status, pageNumber) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, status)
        } else {
            this.setState({
                activeItem: status,
                loader: true,
            });
            let page = pageNumber || "0";
            let inbox_url;
            if (status === "All") {
                inbox_url = backEndServerURL + "/getDebitCardAll/page/" + page;
            } else {
                inbox_url = backEndServerURL + "/getDebitCardstatus/" + status + "/" + page;
            }

            axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            rowData = {
                                sl: i + 1,
                                id: response.data.content[i].id,
                                bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                                status: response.data.content[i].status,
                                batchNo: response.data.content[i].batchNo,
                                cardNumber: response.data.content[i].maskingDebitCardNumber,
                                clientId: response.data.content[i].clientId,
                                customerName: response.data.content[i].customerName,
                                nameOnCard: response.data.content[i].nameOnCard,
                                accountNumber: response.data.content[i].accountNumber,
                                customerType: response.data.content[i].customerType,
                                cbNumber: response.data.content[i].cbNumber,
                                accountType: response.data.content[i].accountType,
                                accountCurrencyType: response.data.content[i].currency,
                                rmCode: response.data.content[i].rmCode,
                                initiatingBranchSolId: response.data.content[i].initialSolId,
                                source: response.data.content[i].source,
                                sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                                casaSolId: response.data.content[i].casaSolId,
                                emailId: response.data.content[i].customerEmail,
                                cellNumber: response.data.content[i].customerMobile,
                                deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                                cardType: response.data.content[i].cardType,
                                productType: response.data.content[i].productType,
                                ccep: response.data.content[i].ccep,
                                passportNumber: response.data.content[i].passportNumber,
                                passportExpiryDate: response.data.content[i].passportExpiryDate,
                                passportIssueDate: response.data.content[i].passportIssueDate,
                                passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                                tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
                                sendToCourierDate: response.data.content[i].sendToCourierDate,
                                branchReceiverName: response.data.content[i].branchReceiverName,
                                branchReceiverId: response.data.content[i].branchReceiverId,
                                branchReceiverDate: response.data.content[i].branchReceiverDate,
                                branchDeliveredId: response.data.content[i].branchDeliveredId,
                                courierName: response.data.content[i].courierName,
                                branchDeliveredName: response.data.content[i].branchDeliveredName,
                                branchDeliveredDate: response.data.content[i].branchDeliveredDate,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                            loader: false,
                            disabled: false,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false,
                        disabled: false
                    })
                });
        }
    };

    handleChangePage = (pageNumber) => {
        let page;
        if(pageNumber === "-5"){
            page=pageNumber;
        }else{
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }


        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.activeItem, page)
        } else {
            this.fetchDataFromAPI(this.state.activeItem, page);
        }
    };

    actionForNewTab = (event, data) => {
        const {activeItem} = this.state;
        if ((activeItem === "New" || activeItem === "Failed") && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    disabled: true,
                    loader: true,
                });
                let single_url = backEndServerURL + "/debitCardCreateRequest";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI(this.state.activeItem);
                        this.functionForNotificationMessage('Successful', "Request is On Processing");
                    })
                    .catch(error => {
                        this.functionForNotificationMessage('Failed', error.response.data.message);
                        this.setState({
                            loader: false,
                            disabled: false
                        });
                        console.log(error);
                    });

            }
        }
    };

    actionForNewToCancel = (event, data) => {
        if (this.state.activeItem === "New" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    rowIdArray: rowIdArray,
                    title: "Do you want to cancel?",
                    confirmationAlert: true,
                });
            }
        }
    };

    actionForTranzwareCompleted = (event, data) => {
        if (this.state.activeItem === "Tranzware Completed" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    mailModal: true,
                    selectedRowIdArray: rowIdArray,
                });
            }
        }
    };

    actionForOnTransit = (event, data) => {
        if (this.state.activeItem === "Send to Courier" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }

            if (rowIdArray.length > 0) {
                let download_url = backEndServerURL + "/getDebitcardExcelResource/Send to Courier";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `Debit-Card-Emb-${this.state.currentDate}-Expone.xlsx`)
            }
        }
    };

    actionForOnTransitToSendToBranch = (event, data) => {
        if (this.state.activeItem === "Send to Courier") {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    allData: [],
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Deliver to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful");
                        this.fetchDataFromAPI('Send to Courier')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage('Failed', "Status Change Failed");
                        console.log(error);
                    });
            }
        }
    };

    actionForReturnToSendToDeliver = (event, data) => {
        if (this.state.activeItem === "Returned" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url = backEndServerURL + "/updateDebitCardStatus/Deliver to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful");
                        this.fetchDataFromAPI('Returned')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage('Failed', "Status Change failed");
                        console.log(error);
                    });
            }
        }
    };

    actionForReturnToDeliver = (event, data) => {
        if (this.state.activeItem === "Returned" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Returned')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage('Failed', "Status Change Failed");
                        console.log(error);
                    });
            }
        }
    };

    actionForReturnToSendToCallCenter = (event, data) => {
        if (this.state.activeItem === "Returned" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Send to Call Center";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Returned')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForReturnToDestroy = (event, data) => {
        if (this.state.activeItem === "Returned" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Returned')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequest = (event, data) => {
        if (this.state.activeItem === "Destroy Request" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequestToDeliver = (event, data) => {
        if (this.state.activeItem === "Destroy Request" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForSendToBranchToDeliver = (event, data) => {
        if (this.state.activeItem === "Branch Received" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url = backEndServerURL + "/updateDebitCardStatus/Delivered";
                axios.post(single_url, rowIdArray, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForSendToBranchToCallCenter = (event, data) => {
        if (this.state.activeItem === "Branch Received" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Send to Call Center";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForSendToBranchToDestroyRequest = (event, data) => {
        if (this.state.activeItem === "Branch Received" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url = backEndServerURL + "/updateDebitCardStatus/Destroy Request";
                axios.post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForCallCenterToDeliver = (event, data) => {
        if (this.state.activeItem === "Send to Call Center" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url = backEndServerURL + "/updateDebitCardStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to call center')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForCallCenterToSendToBranch = (event, data) => {
        if (this.state.activeItem === "Send to Call Center" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url = backEndServerURL + "/updateDebitCardStatus/Branch Received";
                axios.post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Call Center')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    actionForCallCenterToDestroyRequest = (event, data) => {
        if (this.state.activeItem === "Send to Call Center" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Call Center')
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        console.log(error);
                    });
            }
        }
    };

    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {
        let postData = this.removeNullValue(this.state.inputData);
        if (Object.keys(postData).length === 0) {
            return 0;
        }
        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                pageNumber: 0,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterDebitCard/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterDebitCard/" + status + "/" + page;
        }

        axios.post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        sl: i + 1,
                        id: response.data.content[i].id,
                        bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                        status: response.data.content[i].status,
                        batchNo: response.data.content[i].batchNo,
                        cardNumber: response.data.content[i].maskingDebitCardNumber,
                        clientId: response.data.content[i].clientId,
                        customerName: response.data.content[i].customerName,
                        nameOnCard: response.data.content[i].nameOnCard,
                        accountNumber: response.data.content[i].accountNumber,
                        customerType: response.data.content[i].customerType,
                        cbNumber: response.data.content[i].cbNumber,
                        accountType: response.data.content[i].accountType,
                        accountCurrencyType: response.data.content[i].currency,
                        rmCode: response.data.content[i].rmCode,
                        initiatingBranchSolId: response.data.content[i].initialSolId,
                        source: response.data.content[i].source,
                        sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                        casaSolId: response.data.content[i].casaSolId,
                        emailId: response.data.content[i].customerEmail,
                        cellNumber: response.data.content[i].customerMobile,
                        deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                        cardType: response.data.content[i].cardType,
                        productType: response.data.content[i].productType,
                        ccep: response.data.content[i].ccep,
                        passportNumber: response.data.content[i].passportNumber,
                        passportExpiryDate: response.data.content[i].passportExpiryDate,
                        passportIssueDate: response.data.content[i].passportIssueDate,
                        passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                        tranzwareCompletedDate: response.data.content[i].tranzwareCompletedDate,
                        sendToCourierDate: response.data.content[i].sendToCourierDate,
                        branchReceiverName: response.data.content[i].branchReceiverName,
                        branchReceiverId: response.data.content[i].branchReceiverId,
                        branchReceiverDate: response.data.content[i].branchReceiverDate,
                        branchDeliveredId: response.data.content[i].branchDeliveredId,
                        courierName: response.data.content[i].courierName,
                        branchDeliveredName: response.data.content[i].branchDeliveredName,
                        branchDeliveredDate: response.data.content[i].branchDeliveredDate,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    actionForNewToDownloadExcel = (event, data) => {
        if (this.state.activeItem === "New" && data) {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true,
                    disabled: true,
                });
                let download_url = backEndServerURL + "/getExcelResourceDebitCard/New";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `Debit-Card-Emb-${this.state.currentDate}-Expone.xlsx`).then((res) => {
                    this.setState({
                        loader: false
                    });
                }).catch((err) => {
                    this.setState({
                        loader: false
                    });
                })
            }
        }
    };

    downloadReport = (url, name) => {
        this.setState({
            loader: true,
        });
        let postData = this.removeNullValue(this.state.inputData);
        let download_url = backEndServerURL + "/" + url;
        axiosDownload.downloadFile(download_url, 'POST', postData, name).then((result) => {
            this.setState({
                loader: false
            })
        }).catch((error) => {
            this.setState({
                loader: false
            });
        })
    };

    functionForTranzwareReport = () => {
        this.downloadReport("GetRegularDebitCardTranzwareCompletedReport", "tranzware-completed-report.xlsx");
    };

    functionForFullHistoryReport = () => {
        this.downloadReport("GetRegularDebitCardFullHistoryReport", "branch-received.xlsx");
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };

    renderTable = () => {
        return (
            !this.state.loader && <TableComponent tableData={this.state.allData} tableColumns={this.state.allColumns}
                                                  loader={this.state.loader}
                                                  totalRow={this.state.totalRow} page={this.state.pageNumber}
                                                  whiteSpace={"wrap"}
                                                  selection={true} handleChangePage={this.handleChangePage}
                                                  rowsPerPage={this.state.rowsPerPage}
                                                  tableButton={this.renderTopLabelButton()}
            />
        )
    };

    renderTopLabelButton = () => {
        const {activeItem} = this.state;
        let buttonList = [{
            name: "Load All Data",
            triggerFunction: this.renderUrl
        }];
        switch (activeItem) {
            case "All":
                buttonList.push(
                    {
                        name: "Tranzware Completed Report",
                        triggerFunction: this.functionForTranzwareReport
                    },
                    {
                        name: "Full History Report",
                        triggerFunction: this.functionForFullHistoryReport
                    }
                );
                break;
            case "New":
                buttonList.push(
                    {
                        name: "Proceed",
                        triggerFunction: this.actionForNewTab
                    },
                    {
                        name: "Decline",
                        triggerFunction: this.actionForNewToCancel
                    },
                    {
                        name: "Download Excel",
                        triggerFunction: this.actionForNewToDownloadExcel
                    }
                );
                break;
            case "Failed":
                buttonList.push(
                    {
                        name: "Proceed",
                        triggerFunction: this.actionForNewTab
                    }
                );
                break;
            case "Tranzware Completed":
                buttonList.push(
                    {
                        name: "Send to Courier",
                        triggerFunction: this.actionForTranzwareCompleted
                    }
                );
                break;
            case "Send to Courier":
                buttonList.push(
                    {
                        name: "Download Excel",
                        triggerFunction: this.actionForOnTransit
                    },
                    {
                        name: "Send to Branch",
                        triggerFunction: this.actionForOnTransitToSendToBranch
                    }
                );
                break;
            case "Returned":
                buttonList.push(
                    {
                        name: "Deliver to Branch",
                        triggerFunction: this.actionForReturnToSendToDeliver
                    },
                    {
                        name: "Delivered",
                        triggerFunction: this.actionForReturnToDeliver
                    },
                    {
                        name: "Send to Call Center",
                        triggerFunction: this.actionForReturnToSendToCallCenter
                    },
                    {
                        name: "Destroy",
                        triggerFunction: this.actionForReturnToDestroy
                    }
                );
                break;
            case "Destroy Request":
                buttonList.push(
                    {
                        name: "Destroy",
                        triggerFunction: this.actionForDestroyRequest
                    },
                    {
                        name: "Delivered",
                        triggerFunction: this.actionForDestroyRequestToDeliver
                    }
                );
                break;
            case "Branch Received":
                buttonList.push(
                    {
                        name: "Delivered",
                        triggerFunction: this.actionForSendToBranchToDeliver
                    },
                    {
                        name: "Send to Call Center",
                        triggerFunction: this.actionForSendToBranchToCallCenter
                    },
                    {
                        name: "Destroy Request",
                        triggerFunction: this.actionForSendToBranchToDestroyRequest
                    }
                );
                break;
            case "Send to Call Center":
                buttonList.push(
                    {
                        name: "Delivered",
                        triggerFunction: this.actionForCallCenterToDeliver
                    },
                    {
                        name: "Deliver to Branch",
                        triggerFunction: this.actionForCallCenterToSendToBranch
                    },
                    {
                        name: "Destroy Request",
                        triggerFunction: this.actionForCallCenterToDestroyRequest
                    }
                );
                break;
            default:
                break;
        }

        return buttonList;
    };

    tableChoice() {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                {this.renderTable()}
            </>
        )
    }
}

export default withStyles(styles)(DebitCardManage);
