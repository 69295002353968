import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import VerifyMerchantPhoto from './VerifyMerchantPhoto'
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "./MerchantWaiverList";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";


const jsonForm = [
    {
        "label": "NID",
        "type": "select",
        "varName": "nidForFrm",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
    {
        "label": "Passport",
        "type": "select",
        "varName": "passportForFrm",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
    {
        "label": "Driving License",
        "type": "select",
        "varName": "drivingLicense",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
    {
        "label": "Other",
        "type": "select",
        "varName": "other",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
    {
        "label": "TIN Certificate",
        "type": "select",
        "varName": "tinCertificate",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
    {
        "label": "Trade License",
        "type": "select",
        "varName": "tradeLicense",
        "required":true,
        "grid": 6,
        "enum": [
            "Yes",
            "No"
        ]
    },
];

const partnershipForm = [
    {
        "label": "Partnership deed",
        "type": "select",
        "varName": "partnership Deed",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Partnership resolution letter",
        "type": "select",
        "varName": "partnershipResolutionLetter",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    }
];

const llcForm = [
    {
        "label": "Certificate of Incorporation",
        "type": "select",
        "varName": "certificateOfIncorporation",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Memorandum of Article",
        "type": "select",
        "varName": "memorandumOfArticle",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Article of Association",
        "type": "select",
        "varName": "articleOfAssociation",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Form XII",
        "type": "select",
        "varName": "formXII",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Board resolution letter",
        "type": "select",
        "varName": "boardResolutionLetter",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    }
];

const ngoCooperativeForm = [
    {
        "label": "Certificate of registration",
        "type": "select",
        "varName": "certificateOfRegistration",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Certificate of NGO Bureau",
        "type": "select",
        "varName": "certificateOfNgoBureau",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Certificate of Micro Credit Regulatory Authority",
        "type": "select",
        "varName": "certificateOfMicroCreditRegulatorAuthority",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Others",
        "type": "select",
        "varName": "ngoCooperativeOthers",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    }
];

const clubsCharityForm = [
    {
        "label": "Certificate of registration",
        "type": "select",
        "varName": "certificateOfRegistration",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "By-Laws or Constitution",
        "type": "select",
        "varName": "byLawsConstitution",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Board Resolution",
        "type": "select",
        "varName": "boardResolution",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    },
    {
        "label": "Others",
        "type": "select",
        "varName": "clubsCharityOthers",
        "grid": 6,
        "enum": [
            "Valid",
            "Invalid"
        ]
    }
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let frmRemarks = [
    {
        "varName": "frmRemarks",
        "type": "textArea",
        "required": true,
        "label": "Remarks",
        "grid": 12
    }];


class FrmApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            remarks: false,
            getRemarkList: [],
            getDocument: false,
            documentList: [],
            taskTitle: this.props.taskTitle,
            loading: true,
            disabled: false
        }
    }


    modalClose = () => {
        this.props.closeModal();
    };

    componentDidMount() {
        let allForms = jsonForm.concat(llcForm, partnershipForm, ngoCooperativeForm, clubsCharityForm);
        if (this.state.taskTitle === "frm_checker_approval" || this.state.taskTitle === "frm_approval_manager") {
            allForms.map((form) => {
                form.readOnly = true
            })
        } else {
            allForms.map((form) => {
                form.readOnly = false
            })
        }

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    response.data.frmRemarks = null;
                    let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            this.setState({
                                documentList: response.data,
                                getDocument: true
                            });

                            let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                            axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    if (response.data == '') {
                                        console.log('empty')
                                    } else {
                                        let deferealData = [];

                                        response.data.map((deferal) => {

                                            var status;
                                            if (deferal.status === "ACTIVE") {
                                                status = "Approved"
                                            } else if (deferal.status === "APPROVAL_WAITING") {
                                                status = "NOT APPROVED"
                                            } else {
                                                status = "Not Approved";
                                            }

                                            deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                                        });

                                        this.setState({
                                            getDeferalList: deferealData,
                                            defferalData: true
                                        })
                                    }

                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

                    axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let remarkData = [];
                            response.data.map((res) => {
                                remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))
                            });
                            this.setState({
                                getRemarkList: remarkData,
                                remarks: true
                            });
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    this.setState({
                        varValue: response.data,
                        inputData: response.data,
                        showValue: true,
                        getData: true,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }


    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.getRemarkList.length > 0) {
            return (
                    <>
                        <b  >Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </>

            )
        }
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        }
    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <br/>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };


    merchantTypeWiseForm = () => {
        if (this.state.getData) {
            // if (this.state.inputData.merchantType === "Sole Proprietorship") {
            //
            //     return CommonJsonFormComponent.renderJsonForm(this.state, proprietorshipForm, this.updateComponent)
            //
            // } else
            if (this.state.inputData.merchantType === "Limited Liability") {

                return CommonJsonFormComponent.renderJsonForm(this.state, llcForm, this.updateComponent)

            } else if (this.state.inputData.merchantType === "Partnership") {

                return CommonJsonFormComponent.renderJsonForm(this.state, partnershipForm, this.updateComponent)

            } else if (this.state.inputData.merchantType === "Others") {

                return (
                    <div>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, ngoCooperativeForm, this.updateComponent)
                        }

                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, clubsCharityForm, this.updateComponent)
                        }
                    </div>
                )

            }
        }
    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, frmRemarks, this.updateComponent)

        )
    };


    inboxCasePhoto = () => {
        if (this.state.getDocument) {
            return (<VerifyMerchantPhoto closeModal={this.props.closeModal}
                                         titleName="Mou Approval"
                                         documentList={this.state.documentList}/>)
        }

    };

    frmApprovalHandler = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });

            if (this.state.inputData.frmRemarks !== null) {


                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.frmRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            }

            let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = {
                ...this.state.inputData,
                ...(this.state.taskTitle === "frm_approval") && {frm_maker_approval: "APPROVED"},
                ...(this.state.taskTitle === "frm_checker_approval") && {frm_checker_approval: "APPROVED"},
                ...(this.state.taskTitle === "frm_approval_manager") && {frm_manager_approval: "APPROVED"},
            };
            postData.frmRemarks = undefined;

            axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                              //  notificationMessage: "Approved Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            });
                            this.modalClose();
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                            console.log(error)
                        })

                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                    console.log(error)
                });
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    returnHandler = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(frmRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });

            if (this.state.inputData.frmRemarks !== null) {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.frmRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
            let postData = {
                ...(this.state.taskTitle === "frm_approval") && {frm_maker_approval: "RETURN"},
                ...(this.state.taskTitle === "frm_checker_approval") && {frm_checker_approval: "RETURN"},
                ...(this.state.taskTitle === "frm_approval_manager") && {frm_manager_approval: "RETURN"},
            };
            postData.frmRemarks = undefined;

            axios.post(deferalCheckUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                                //notificationMessage: "Return Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            });
                            this.modalClose()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false
                            });
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                });
        }
    };

    declineHandler = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        });
        let closeurl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(closeurl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal();
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    title: "Failed!",
                    notificationMessage: "Case Close Failed!",
                    alert: true,
                    disabled: false
                });
            });

    };
    renderSDNDedupeResult = () => {
        if (  this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true} closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };
    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Grid Container spacing={0}>

                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                {   this.renderSDNDedupeResult()}
                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>

                                            <Grid item xs={8}>

                                                {this.inboxCasePhoto()}

                                            </Grid>

                                            <Grid item xs={4}>
                                                <GridList cellHeight={window.innerHeight} cols={1}>

                                                        {
                                                            this.renderFormWithData()
                                                        }

                                                        {
                                                            this.merchantTypeWiseForm()
                                                        }

                                                        {
                                                            this.renderDeferalApprovalTableData()
                                                        }

                                                        {this.functionForWaiver()}

                                                        {
                                                            this.renderRemarksTable()
                                                        }

                                                        {
                                                            this.renderRemarks()
                                                        }

                                                        {
                                                            this.renderNotification()
                                                        }
                                                        <Grid item xs={12}>
                                                            <button
                                                                className="btn btn-outline-primary btn-sm"
                                                                onClick={this.frmApprovalHandler}
                                                                disabled={this.state.disabled}
                                                            >
                                                                Approve
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button
                                                                className="btn btn-outline-primary btn-sm"
                                                                onClick={this.returnHandler}
                                                                disabled={this.state.disabled}
                                                            >
                                                                Return
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            {this.state.taskTitle === "frm_approval_manager" && <button
                                                                className="btn btn-outline-primary btn-sm"
                                                                onClick={this.declineHandler}
                                                                disabled={this.state.disabled}
                                                            >
                                                                Decline
                                                            </button>}
                                                        </Grid>
                                                 </GridList>
                                            </Grid>


                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>

                </Grid>

            );
        }
    }
}

export default withStyles(styles)(FrmApproval);