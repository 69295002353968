import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import Notification from "../../NotificationMessage/Notification";
import AccountInformation from './AccountHolder';
import CardInformation from './OtherCardDetails';
import loader from "../../../Static/loader.gif";
import ConfirmationModal from "./ConfirmationModal";
import FunctionForGetAcStatus from "../../workflow/CommonComponent/FunctionForGetAcStatus";
import SignatureButton from "../../workflow/Maintenance/SignatureButton";
import CommonApi from "../../workflow/Maintenance/common/CommonApi";
import Table from "../../Table/Table";
import Functions from "../../../Common/Functions";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let SearchForm = [
    {
        varName: "source",
        type: "select",
        label: "Source",
        grid: 2,
        enum: [
            "Finacle",
            "Ababil"
        ],
        required: true,
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        required: true,
        validation: "numeric"
    }
];
let populateForm = [
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        grid: 2,
        readOnly: true,
        multiline: true
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card",
        multiline: true,
        required: true,
        grid: 2,
    },
    {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerType",
        type: "text",
        label: "Customer Type",
        grid: 2,
        readOnly: true
    },
    {
        varName: "schemeCode",
        type: "text",
        label: "Scheme Code",
        grid: 2,
        readOnly: true
    },
    {
        varName: "cbNumber",
        type: "text",
        label: "CB Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        grid: 2,
        readOnly: true
    },
    {
        varName: "currency",
        type: "text",
        label: "Currency",
        grid: 2,
        readOnly: true
    },
    {
        varName: "rfcdConfirmation",
        type: "select",
        enum: [
            "RFCD",
            "Foreign Currency"
        ],
        label: "RFCD/ FCY Confirmation",
        grid: 2,
        conditional: true,
        conditionalVarName: "showRfcd",
        conditionalVarValue: true
    },
    {
        varName: "rmCode",
        type: "textApiCall",
        label: "RM Code",
        grid: 2,
        required: true
    },
    {
        varName: "rmName",
        type: "text",
        label: "RM Name",
        multiline: true,
        conditional: true,
        conditionalVarName: "showRmName",
        conditionalVarValue: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "casaSolId",
        type: "text",
        label: "CASA SOL ID",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerEmail",
        type: "text",
        label: "Email",
        multiline: true,
        readOnly: true,
        // required: true,
        grid: 2,
    },
    {
        varName: "customerMobile",
        type: "text",
        label: "Mobile",
        readOnly: true,
        required: true,
        grid: 2,
    },
    {
        varName: "deliveryType",
        type: "select",
        label: "Delivery Type",
        required: true,
        grid: 2,
        enum: ["Branch"]
    },

    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Delivery Branch Name",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Branch",
        grid: 2,
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        onKeyDown: true,
        grid: 2,
        enum: []
    },
    {
        varName: "productType",
        type: "select",
        enum: [],
        onKeyDown: true,
        label: "Product Type",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showProductType",
        conditionalVarValue: true,
    },
    {
        varName: "otherCardAvailed",
        type: "text",
        label: "Other card availed? (Y/N)",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "otherCard",
        conditionalVarValue: true,
    },
    {
        varName: "ccep",
        type: "text",
        label: "CCEP",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportNumber",
        type: "text",
        label: "Passport Number",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportExpiryDate",
        type: "date",
        label: "Passport Exp Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssueDate",
        type: "date",
        label: "Passport Issue Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssuePlace",
        type: "text",
        label: "Passport Issue Place",
        readOnly: true,
        multiline: true,
        grid: 2,
    },
    {
        varName: "applicationSource",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Application Source",
        required: true,
        grid: 2,
    },
];

const fileUpload = [
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        accept: ".pdf",
        grid: 2,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class DebitCardRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            autoComplete: {},
            appId: "",
            inputData: {},
            showValue: true,
            varValue: {},
            getData: false,
            cbNotFound: false,
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            notificationMessage: "",
            alert: false,
            accountModal: false,
            cardModal: false,
            loading: true,
            getImageLink: [],
            getImageBoolean: false,
            invalidRm: false,
            imageName: [],
            branch: [],
            selectedDate: {},
            confirmation: false,
            disabled: false,
            clientCardDetailsList: [],
            deliveryBranchList: [],
            otherCardAvailed: "",
            getRemarks: [],
            searchDataBoolean: false,


        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;
    };
    resetHandler = () => {
        window.location.reload();
    };



    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of populateForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branchName") {
                        form.enum = branch
                    }
                }
                this.setState({
                    deliveryBranchList: branch
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getAllApplicationSource() {
        let branchUrl = backEndServerURL + "/getDebitCardApplicationSource";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of populateForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "applicationSource") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    getCardType() {
        let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataCardTypes";
        axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of populateForm) {
                    if (form.type === "select" && form.varName === "cardType") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event);

        }

    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }
    };

    searchHandler = event => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                getData: false,
                err: false,
                errorArray: {},
                errorMessages: {},
            });

            let inputData = {...this.state.inputData};
            if (this.state.inputData.source === "Ababil") {
                CommonApi.ababilAccountDetails(inputData.accountNumber)
                    .then((response) => {
                        if (response.data.acctStatus !== "A") {
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: 'Account is not Active!'
                            })
                        } else {
                            inputData.accountNumber = response.data.acctNumber;
                            inputData.customerName = response.data.acctName;
                            inputData.solId = response.data.solId;
                            inputData.nameOnCard = response.data.acctName!==undefined?response.data.acctName.toUpperCase():"";
                            inputData.accountStatus = response.data.acctStatus;
                            inputData.accountType = response.data.accountType;
                            inputData.currency = response.data.crncyCode;
                            inputData.schemeCode = response.data.productCode;
                            inputData.cbNumber = response.data.cifId;
                            inputData.casaSolId = response.data.solId;
                            inputData.cardType = "CITY MAXX DEBIT CARD";
                            inputData.deliveryType = "Branch";
                            inputData.branchName = this.findByVarNameGetKeyValue(response.data.solId, this.state.deliveryBranchList);
                            inputData.occupationCode = "R";
                            inputData.customerAddress = response.data.addressLineOne;

                            // inputData.ccep = response.data.ccepcode;

                            if (response.data.crncyCode !== undefined) {
                                for (let item of populateForm) {
                                    if (item.varName === "rfcdConfirmation" && response.data.crncyCode !== "BDT") {
                                        item.required = true;
                                        inputData.showRfcd = true
                                    } else if (item.varName === "rfcdConfirmation" && response.data.crncyCode === "BDT") {
                                        item.required = false;
                                        inputData.showRfcd = false
                                    }
                                }
                            }

                            let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/CITY MAXX DEBIT CARD";
                            axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((pResponse) => {
                                    for (let form of populateForm) {
                                        if (form.type === "select" && form.varName === "productType") {
                                            form.enum = pResponse.data
                                        }
                                    }
                                    inputData.showProductType = true;
                                    let getCustomerType = backEndServerURL + "/getCustomerType/" + inputData.schemeCode + "/" + inputData.occupationCode;
                                    axios.get(getCustomerType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            if (response.status === 200) {
                                                if (response.data.debitCardAllowed === "NO") {
                                                    this.setState({
                                                        inputData: inputData,
                                                        varValue: this.copyJson(inputData),
                                                        loading: false,
                                                        cbNotFound: true,
                                                        title: `Debit Card Not Allowed for this Account(Scheme Code: ${inputData.schemeCode})!`
                                                    })
                                                } else {
                                                    let start_url = backEndServerURL + "/getCustomerDebitCards/" + inputData.cbNumber;
                                                    axios
                                                        .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                        .then(response => {
                                                            inputData.customerType = response.data.clientCardDetailsList.length > 0 ? "Existing Customer" : "New Customer";
                                                            this.setState({
                                                                clientCardDetailsList: response.data.clientCardDetailsList
                                                            });
                                                            CommonApi.getAbabilCbDetails(inputData.cbNumber).then((response) => {
                                                                inputData.customerMobile = response.data.mobileNumber;
                                                                inputData.customerEmail = response.data.email;

                                                                // if (response.data.PASSPORTBLOCK !== undefined) {
                                                                //     inputData.passportNumber = response.data.PASSPORTBLOCK.referenceNum;
                                                                //     inputData.passportIssuePlace = response.data.PASSPORTBLOCK.placeOfIssue;
                                                                //     inputData.passportIssueDate = this.convertDate(response.data.PASSPORTBLOCK.issueDt);
                                                                //     inputData.passportExpiryDate = this.convertDate(response.data.passportExpDt);
                                                                // }
                                                                inputData.constitutionType = response.data.constitutionCode;

                                                                if (response.status === 200) {
                                                                    this.setState({
                                                                        inputData: inputData,
                                                                        varValue: this.copyJson(inputData),
                                                                        getData: true,
                                                                        loading: false,
                                                                        searchDataBoolean: true
                                                                    })
                                                                }
                                                            })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({
                                                                        loading: false,
                                                                        cbNotFound: true,
                                                                        title: error.response.data.message
                                                                    })
                                                                });
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                            this.setState({
                                                                loading: false,
                                                                cbNotFound: true,
                                                                title: "Can't Fetch Existing Card!"
                                                            })
                                                        });
                                                }
                                            }

                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: 'Unable to Connect With Customer Type API!'
                                            });
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        cbNotFound: true,
                                        title: "Product Type Not Found!",
                                        loading: false
                                    });
                                });

                        }
                    })
                    .catch((error) => {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        })
                    });
            } else {
                CommonApi.getAccountDetails(inputData.accountNumber).then((response) => {
                    inputData.accountNumber = response.data.accountNumber;
                    inputData.customerName = response.data.customerName;
                    inputData.solId = response.data.solid;
                    inputData.nameOnCard = response.data.customerName;
                    inputData.accountStatus = response.data.accountStatus;
                    inputData.accountType = response.data.accountType;
                    inputData.customerAddress = response.data.address;
                    inputData.currency = response.data.acctCurrency;
                    inputData.schemeCode = response.data.schemeCode;
                    inputData.ccep = response.data.ccepcode;
                    inputData.cbNumber = response.data.cbNumber;
                    inputData.casaSolId = response.data.solid;
                    inputData.cardType = "CITY MAXX DEBIT CARD";
                    inputData.deliveryType = "Branch";
                    inputData.branchName = this.findByVarNameGetKeyValue(response.data.solid, this.state.deliveryBranchList);

                    if (response.data.acctCurrency !== undefined) {
                        for (let item of populateForm) {
                            if (item.varName === "rfcdConfirmation" && response.data.acctCurrency !== "BDT") {
                                item.required = true;
                                inputData.showRfcd = true
                            } else if (item.varName === "rfcdConfirmation" && response.data.acctCurrency === "BDT") {
                                item.required = false;
                                inputData.showRfcd = false
                            }
                        }
                    }

                    let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/CITY MAXX DEBIT CARD";
                    axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((pResponse) => {
                            for (let form of populateForm) {
                                if (form.type === "select" && form.varName === "productType") {
                                    form.enum = pResponse.data
                                }
                            }
                            inputData.showProductType = true;
                            FunctionForGetAcStatus.getAccountStatus(response.data.accountNumber).then((acStatus) => {
                                if (acStatus.status === "found" && acStatus.value === "A") {
                                    inputData.accountStatus = acStatus.value;
                                    let occupation = response.data.occupationcode ? response.data.occupationcode : "NA";
                                    let getCustomerType = backEndServerURL + "/getCustomerType/" + response.data.schemeCode + "/" + occupation;
                                    axios.get(getCustomerType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            if (response.status === 200) {
                                                if (response.data.debitCardAllowed === "NO") {
                                                    this.setState({
                                                        inputData: inputData,
                                                        varValue: this.copyJson(inputData),
                                                        loading: false,
                                                        cbNotFound: true,
                                                        title: `Debit Card Not Allowed for this Account(Scheme Code: ${inputData.schemeCode})!`
                                                    })
                                                } else {
                                                    let start_url = backEndServerURL + "/getCustomerDebitCards/" + inputData.cbNumber;
                                                    axios
                                                        .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                        .then(response => {
                                                            inputData.customerType = response.data.clientCardDetailsList.length > 0 ? "Existing Customer" : "New Customer";
                                                            this.setState({
                                                                clientCardDetailsList: response.data.clientCardDetailsList
                                                            });
                                                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                                                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((response) => {

                                                                    if (response.data.COMMPH1BLOCK !== undefined) {
                                                                        inputData.customerMobile = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                                                    }

                                                                    if (response.data.COMMEMLBLOCK !== undefined) {
                                                                        inputData.customerEmail = response.data.COMMEMLBLOCK.emailInfo;
                                                                    }

                                                                    if (response.data.PASSPORTBLOCK !== undefined) {
                                                                        inputData.passportNumber = response.data.PASSPORTBLOCK.referenceNum;
                                                                        inputData.passportIssuePlace = response.data.PASSPORTBLOCK.placeOfIssue;
                                                                        inputData.passportIssueDate = this.convertDate(response.data.PASSPORTBLOCK.issueDt);
                                                                        inputData.passportExpiryDate = this.convertDate(response.data.passportExpDt);
                                                                    }
                                                                    inputData.constitutionType = response.data.constitutionCode;

                                                                    if (response.status === 200) {
                                                                        this.setState({
                                                                            inputData: inputData,
                                                                            varValue: this.copyJson(inputData),
                                                                            getData: true,
                                                                            loading: false,
                                                                            searchDataBoolean: true
                                                                        })
                                                                    }
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({
                                                                        loading: false,
                                                                        cbNotFound: true,
                                                                        title: error.response.data.message
                                                                    })
                                                                });
                                                        })
                                                        .catch(error => {
                                                            console.log(error);
                                                            this.setState({
                                                                loading: false,
                                                                cbNotFound: true,
                                                                title: "Can't Fetch Existing Card!"
                                                            })
                                                        });
                                                }
                                            }

                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: 'Unable to Connect With Customer Type API!'
                                            });
                                        });
                                } else if (acStatus.status === "Not Found!" || (acStatus.status === "found" && acStatus.value !== "A")) {
                                    this.setState({
                                        loading: false,
                                        cbNotFound: true,
                                        title: 'Account is not Active!'
                                    });
                                } else {
                                    this.setState({
                                        loading: false,
                                        cbNotFound: true,
                                        title: acStatus.value.response.data.message
                                    });
                                }
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                cbNotFound: true,
                                title: "Product Type Not Found!",
                                loading: false
                            });
                        });

                })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        });
                    })
            }
        }

    };

    convertDate = (value) => {
        if (value && value !== -1) {
            let date = value.split('T')[0].split("-");
            return `${date[1]}/${date[2]}/${date[0]}`
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    closeAccountModal = () => {
        this.setState({
            accountModal: false,
            cardModal: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    componentDidMount() {
        this.getBranchAll();
        this.getAllApplicationSource();
        this.getCardType();
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let inputData = response.data;
                    this.setState({
                        varValue: this.copyJson(inputData),
                        inputData: inputData,
                        showValue: true,
                        getData: true,
                        appId: this.props.appId,
                        refresh: false,
                        loading: false,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        getImageLink: response.data,
                        getImageBoolean: true,
                        imageName: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })

            let remarksArray = [];

            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((data) => {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    });
                    console.log(remarksArray);
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                .catch((error) => {
                    console.log(error)
                });
        } else {
            let start_url="";
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CALL_CENTER_MAKER"){
                start_url = backEndServerURL + "/startCase/call_center_from_maker_start";
            }
            else{
                start_url = backEndServerURL + "/startCase/cheque_book_request_start";
            }
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        appId: response.data.id,
                        loading: false,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }


    saveHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(populateForm, this.state);
        let fileError = MyValidation.fileValidation(fileUpload[0], this.state);
        this.forceUpdate();

        if (error === true || fileError === true) {
            return 0;
        } else if (this.state.invalidRm) {
            this.setState({
                cbNotFound: true,
                title: 'Invalid RM Code!'
            });
            return 0;
        } else if (this.state.inputData.nameOnCard.length > 19) {
            this.setState({
                cbNotFound: true,
                title: 'Name on Card Not More Than 19 Character'
            });
            return 0;
        } else if (this.state.otherCardAvailed === "Y") {
            this.setState({
                cbNotFound: true,
                title: 'This Product Type already exist for this Account!'
            });
            return 0;
        } else {

            if (this.state.fileUploadData["fileUpload"] !== undefined) {
                let uploadUrl = backEndServerURL + "/case/upload";
                let formData = new FormData();
                formData.append("appId", this.state.appId);
                formData.append("file", this.state.fileUploadData["fileUpload"]);
                formData.append("type", "file");

                axios({
                    method: "post",
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        "content-type": "multipart/form-data"
                    }

                })
                    .then(response => {

                    })
                    .catch(error => {
                        this.setState({
                            title: 'warning',
                            notificationMessage: "Failed to Upload File!",
                            alert: true
                        });
                    });
            }
            if (this.state.inputData.constitutionType === "JNT") {
                this.setState({
                    confirmation: true
                })
            } else {
                this.functionForCaseRoute();
            }

        }

    };

    confirmationModal = (value) => {
        if (value === "ok") {
            this.functionForCaseRoute();
            this.setState({
                confirmation: false
            })
        } else {
            this.setState({
                confirmation: false
            })
        }
    };

    functionForCaseRoute = () => {
        this.setState({
            disabled: true
        });
        let postData = this.state.inputData;
        postData.category = "Debit Card";
        postData.subCategory = "Requisition";
        postData.serviceType = "Debit Card";
        postData.subServiceType = "Requisition";
        postData.customerName = this.state.inputData.customerName;
        postData.appId = this.state.appId;
        postData.call_center_maker_approval  = "APPROVED";
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
                Functions.loginRedirection(error);
            });

        this.state.inputData.csRemarks = undefined;
        let url = backEndServerURL + "/variables/" + this.state.appId;
        axios
            .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            if (response.status === 200) {
                                this.setState({
                                    getData: false,
                                    disabled: false,
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                });

                                if (this.props.appId !== undefined) {
                                    this.props.closeModal()
                                } else {
                                    setTimeout(function () {
                                        window.location.reload()
                                    }, 1000);
                                }
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                                title: "Failed!",
                                notificationMessage: "Failed to case route!",
                                alert: true,
                            });
                        });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    accountHolder = () => {
        if (this.state.inputData.accountType === 'Joint') {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginRight: "10px",
                        marginTop: "10px"
                    }}
                    onClick={this.accountHolderInformation}
                >
                    Account Holder
                </button>
            )
        }
    };

    accountHolderInformation = () => {
        this.setState({
            accountModal: true
        })
    };

    CardInformation = () => {
        this.setState({
            cardModal: true
        })
    };


    cardInformation = () => {
        this.setState({
            cardModal: true
        })
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Debit Card Requisition (<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""})
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Debit Card Requisition
                </h4>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document {index + 1}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    renderSearchForm = () => {
        if (this.props.appId === undefined) {
            return (
                <Grid item xs={12}>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            { this.state.searchDataBoolean===true? CommonJsonFormComponent.renderJsonForm(
                                this.state,
                               this.makeReadOnlyObjec(SearchForm) ,
                                this.updateComponent,
                                this.onKeyDownForDedup
                            ): CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                SearchForm,
                                this.updateComponent,
                                this.onKeyDownForDedup
                            ) }

                            <Grid item xs={7}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginTop: "18px",
                                        //marginLeft: "10px"
                                    }}
                                    onClick={this.searchHandler}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    style={{
                                        marginTop: "18px"
                                    }}
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Grid>
            )
        }
    };

    functionForGettingRmName = (event, data) => {
        if (data === "rmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.showRmName = false;
            this.forceUpdate();
            let getRmName = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.rmCode;
            axios.get(getRmName, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.rmName = response.data;
                    this.state.inputData.showRmName = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.copyJson(this.state.inputData),
                        invalidRm: false,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        invalidRm: true,
                        cbNotFound: true,
                        title: "Invalid RM Code!",
                        loading: false
                    });
                })
        }
    };

    functionForGetProductType = (data) => {
        if (data.varName === "cardType") {
            this.setState({
                loading: true
            });
            this.state.inputData.productType = "";
            this.state.inputData.showProductType = false;
            this.forceUpdate();
            let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/" + this.state.inputData.cardType;
            axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let form of populateForm) {
                            if (form.type === "select" && form.varName === "productType") {
                                form.enum = response.data
                            }
                        }
                        this.state.inputData.showProductType = true;
                        this.forceUpdate();
                        this.setState({
                            varValue: this.copyJson(this.state.inputData),
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Product Type Not Found!",
                        loading: false
                    });
                })
        } else if (data.varName === "productType") {
            this.setState({
                loading: true
            });
            this.state.inputData.otherCardAvailed = "";
            this.state.inputData.otherCard = false;
            this.forceUpdate();
            // let clientId = [];
            // for (let item of this.state.clientCardDetailsList) {
            //     clientId.push(item.clientId);
            // }
            let postData = {};
            // postData.clientIdList = clientId;
            postData.accountNumber = this.state.inputData.accountNumber;
            postData.productType = this.state.inputData.productType;
            let productUrl = backEndServerURL + "/isCustomerSameCardAvailed";
            axios.post(productUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.otherCardAvailed = response.data;
                    this.state.inputData.otherCardAvailed = response.data;
                    this.state.inputData.otherCard = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.copyJson(this.state.inputData),
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.otherCardAvailed = "N";
                    this.state.inputData.otherCard = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.copyJson(this.state.inputData),
                        loading: false
                    });
                })
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });

    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };


    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.getData) {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>
                            {
                                this.renderSearchForm()
                            }
                            <Grid container style={{marginTop: 20}}>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        populateForm,
                                        this.updateComponent,
                                        this.functionForGettingRmName,
                                        this.functionForGetProductType
                                    )}
                                    <Grid item xs={12}>
                                        {this.signature()}
                                    </Grid>
                                    {
                                        this.mappingPhoto()
                                    }
                                    <Grid item xs={12}></Grid>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        fileUpload,
                                        this.updateComponent
                                    )}
                                    <Grid item xs={12}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{
                                                marginRight: "10px",
                                                marginTop: "10px"
                                            }}
                                            onClick={this.cardInformation}
                                        >
                                            Other Cards details
                                        </button>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {this.renderRemarksData()}
                                    </Grid>

                                    <Grid item xs={12}>
                                        {this.renderRemarks()}
                                    </Grid>



                                </ThemeProvider>
                            </Grid>

                            {/*{*/}
                            {/*    this.accountHolder()*/}
                            {/*}*/}



                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cardModal}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CardInformation
                                        clientCardDetailsList={this.state.clientCardDetailsList}
                                        closeModal={this.closeAccountModal}
                                        cbNumber={this.state.inputData.cbNumber}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmation}>
                                <DialogContent className={classes.dialogPaper}>

                                    <ConfirmationModal
                                        closeModal={this.confirmationModal}
                                        title="Is the Joint account's mode of operation Anyone/ Either or Survivor?"
                                    />
                                </DialogContent>
                            </Dialog>

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    disabled={this.state.disabled}
                                    onClick={this.saveHandler}
                                >
                                    Submit
                                </button>
                                {this.props.appId !== undefined && <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    disabled={this.state.disabled}
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>}
                            </Grid>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.accountModal}>
                                <DialogContent className={classes.dialogPaper}>

                                    <AccountInformation
                                        closeModal={this.closeAccountModal}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Debit Card Requisition
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent,
                                        this.onKeyDownForDedup
                                    )}
                                    {/*&nbsp;*/}
                                    <Grid item xs={7}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            style={{
                                                marginTop: "18px"
                                            }}
                                            onClick={this.searchHandler}
                                        >
                                            Search
                                        </button>
                                    </Grid>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </ThemeProvider>
                            </Grid>


                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)
(
    DebitCardRequisition
)
;
