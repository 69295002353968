import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class OtherCardDetails extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            inputData: {},
            showValue: true,
            varValue: {},
            getData: false,
            cbNotFound: false,
            loading: true,
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            notificationMessage: "",
            alert: false,
            getAccountInformation: [],
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };


    componentDidMount() {

        if (this.props.clientCardDetailsList !== undefined) {
            let accountInformationArray = [];
            this.props.clientCardDetailsList.map((item) => {
                console.log(item);
                accountInformationArray.push(this.createAccountInformationTable(item.cardNoMasked, item.cardStatus,item.cardState,item.cardType));
            });
            this.setState({
                getAccountInformation: accountInformationArray,
                getData: true,
                loading: false
            })
        }
        else{
            this.setState({
                loading: false
            })
        }
    }

    createAccountInformationTable = (clientId,cardNumber,cardStatus,cardState,cardType) => {
        return (
            [clientId,cardNumber,cardStatus,cardState,cardType]
        )
    };

    renderAccountData = () => {
        if (this.state.getAccountInformation.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Card Number","Card Status","Card State","Card Type"]}
                        tableData={this.state.getAccountInformation}
                        tableAllign={['left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Other Cards Information
                                <a>
                                    <CloseIcon
                                        onClick={this.props.closeModal}
                                        style={{
                                            position: "absolute",
                                            right: 10,
                                            color: "#000000"
                                        }}
                                    />
                                </a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={3}>
                                <ThemeProvider theme={theme}>
                                    <Grid item xs={12} style={{margin: '15px 10px'}}>
                                        {
                                            this.renderAccountData()
                                        }
                                    </Grid>
                                </ThemeProvider>
                            </Grid>

                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)(OtherCardDetails);
