import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import '../../Static/css/login.css';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {Input} from 'antd';
import Button from "@material-ui/core/Button";
import {Dialog} from "@material-ui/core";
import Notification from '../NotificationMessage/Notification';
import UserAddEditDelete from "../AdminModule/UserAddEditDelete";
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});


class AssignUserRole extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            errorMessage: " ",
            datas: [],
            title: "",
            messgae: "Successfull!!",
            notificationFlag: false,
            type: "success",
            alert: false,
            redirectLogin: false,
        }
    }


    handleChange = (event) => {
        this.setState({
            [event.target.cbnumber]: event.target.value
        });
    };
    handleKeyDown = (event) => {

        if (event.key === 'Enter') {
            let searchText = event.target.value;


            let url = backEndServerURL + '/user/search/' + searchText;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let responseData = [];
                    if (Array.isArray(response.data))
                        responseData = response.data;
                    this.setState({
                        datas: responseData
                    })

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({errorMessage: "Not Found"});
                })
        } else {


        }
    };
    /*  handlesubmit = event => {

          event.preventDefault()

          axios.get(backEndServerURL + '/api/1.0/workflow/extrarest/cases/search/' + this.state.cbnumber, {
              headers: {
                  "Authorization": "Bearer " + cookie.load("accessToken")
              }
          })
              .then(response => {
                  let responseData = [];
                  if (Array.isArray(response.data))
                      responseData = response.data
                  this.setState({
                      datas: responseData
                  })
              })
              .catch(error => {
                  this.setState({ errorMessage: "Not Found" });

              })
      };



      handleDelete = (event, userUid) => {
          event.preventDefault();
          let url = backEndServerURL + "/api/1.0/workflow/group/" + this.state.groupUid + "/user/" + userUid;
          console.log(userUid);
          let authHeader = "Bearer " + cookie.load("accessToken");

          axios.delete(url,
              {headers: {"Authorization": authHeader}})
              .then((response) => {
                  console.log(response.data);
                  this.closeConfirmModal();
                  this.deleteNotification();
              })
              .catch((error) => {
                  console.log(error)
              });

      }*/
    notification = () => {
        if (this.state.alert) {

            return (<Notification stopNotification={this.stopNotification} title="Assigned User!!!"
                                  message="successfully Assigned!!"/>)
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    addUser = (event, roleId, username) => {


        event.preventDefault();
        let url = backEndServerURL + "/user/assignRole/?roleId=" + roleId + "&username=" + username;

        console.log(roleId, username);

        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                this.props.closeModal();
                if (response.data.status !== "UNSUCCESSFUL") {
                    this.setState({
                        alert: true
                    });
                }


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });


    };

    render() {

        const {classes} = this.props;
        const {Search} = Input;

        const {datas} = this.state;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <section>
                {this.notification()}
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    open={this.state.editUser}
                    onClose={this.closeModal}
                >
                    <UserAddEditDelete userUid={this.state.userUid} closeModal={this.closeModal}
                                       editCloseModal={this.editedModal}/>

                </Dialog>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Assign user</h4>

                            </CardHeader>
                            <CardBody>

                                <div className={classes.root}>

                                    <form onSubmit={this.handlesubmit}>
                                        <Search

                                            placeholder="input search text"

                                            style={{marginLeft: 550, width: 300}}
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                            onClick="submit"

                                        />

                                    </form>
                                    <br/><br/>

                                    <ReactNotification ref={this.notificationDOMRef}/>


                                    <center>
                                        <table className="table table-bordered table-definition mb-10">
                                            <thead style={{backgroundColor: "#FF5733", color: "#FFFFFF"}}>
                                            <tr>
                                                <th>User Name</th>
                                                <th>Assign</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                datas.map((group) => {
                                                    if (group.username !== '')
                                                        return (
                                                            <tr>
                                                                <td>

                                                                    {
                                                                        group.username
                                                                    }

                                                                </td>


                                                                <td>
                                                                    <Button
                                                                        style={{
                                                                            backgroundColor: "#FF5733",
                                                                            color: "#FFFFFF"
                                                                        }}
                                                                        onClick={(event) => this.addUser(event, this.props.id, group.username)}
                                                                    >Assign

                                                                    </Button>

                                                                </td>
                                                            </tr>
                                                        )

                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </center>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </section>

        );
    }
}

export default withStyles(styles)(AssignUserRole);
