import React, {Component} from 'react';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import {createMuiTheme} from '@material-ui/core/styles';
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import MyValidation from "../JsonForm/MyValidation";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = [
    {
        "varName": "fileName",
        "type": "file",
        "accept": ".pdf",
        "label": "Upload PDF File",
    },
    {
        "varName": "fileName1",
        "type": "file",
        "accept": ".xlsx,.xls",
        "label": "Upload Excel File",
    },
];

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#37b159',
            light: '#37b159',
            dark: '#37b159',
        },
    },

});

const table_style = {
    row: {
        border: "none",
        height: 10,
        "&:nth-of-type(odd)": {
            backgroundColor: "red"
        }
    },
};


class ExcelUpload extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: 'Generate',
            backgroundColor: 'red',
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            type: "",
            selectedCount: 0,
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {
        if (object.fileName == "" || object.fileName1 == "") {
            this.setState({
                type: "warning",
                title: "Warning!",
                notificationMessage: "File Missing",
                alert: true
            })
        } else {
            let error = MyValidation.fileValidation(jsonForm[0], this.state);
            let errorTwo = MyValidation.fileValidation(jsonForm[1], this.state);
            this.forceUpdate();
            if (error === true || errorTwo === true) {
                return 0;
            } else {
                const formData = new FormData();
                formData.append("file", object.fileName);
                formData.append("file", object.fileName1);

                axios({
                    method: 'post',
                    url: backEndServerURL + '/insurrenceLocker',
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                }).then((response) => {
                    this.setState({
                        type: "success",
                        title: "Successfully!!",
                        notificationMessage: "Successfully File Uploaded",
                        alert: true,
                    })

                }).catch((error) => {
                    this.setState({
                        type: "warning",
                        title: "Warning!",
                        notificationMessage: "Something Wrong",
                        alert: true
                    })
                })
            }

        }

    };

    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();

    };


    open = (data) => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        })
    };


    render() {

        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        console.log(this.state.inputData.callStatus);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Upload Insurance Coverage</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>

                                    <CardBody>
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                jsonForm,
                                                this.updateComponent
                                            )}

                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                onClick={this.getSubmitedForm}
                                            >
                                                Submit
                                            </button>
                                        </div>


                                        {
                                            this.renderNotification()
                                        }


                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div>


                    </div>


                </CardBody>


            </Card>
        );
    }


}

export default ExcelUpload;