import React, {Component} from 'react';
import axios from 'axios';
import FormSample from '../JsonForm/FormSample';
import {backEndServerURL} from "../../Common/Constant";
import Functions from "../../Common/Functions";
import Notification from "../NotificationMessage/Notification";

class DataCaptureCS extends Component {
    state = {
        appUid: "-1",
        message: "",
        appData: {},
        getData: false,
        getNewCase: false,
        varValue: [],
        caseId: "",
        title: "",
        notificationMessage: "",

        alert: false,
        accountDetailsModal: false
    };
    renderInboxCase = (() => {
        if (this.state.getData) {

            return (<FormSample showValue={true} varValue={this.state.varValue}
                                grid="6" buttonName="Submit" jsonForm={this.props.jsonForm}
                                onSubmit={this.handleSubmit}/>);
        } else if (this.state.getNewCase) {

            return (<FormSample showValue={true} varValue={this.state.varValue}
                                grid="6" buttonName="Submit" jsonForm={this.props.jsonForm}
                                onSubmit={this.handleSubmit}/>);
        } else {

        }
    });

    componentDidMount() {

        let varValue = [];
        if (this.props.app_uid !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.app_uid;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);

                    let varValue = response.data;

                    this.setState({
                        getData: true,
                        csSendTo: response.data.cs_send_to,
                        bomSendTo: response.data.bom_send_to,
                        varValue: varValue,
                        appData: response.data
                    });
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });


        } else {

            let url = backEndServerURL + "/startCase/cs_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        caseId: response.data.id,

                        getNewCase: true,
                        varValue: varValue

                    })
                })
                .catch((error) => {
                    console.log(error);
                })

        }

        /* varValue["cbNumber"] = this.props.searchValue.cbNumber;
           varValue["accountNumber"] = this.props.searchValue.accountNumber;
           varValue["nid"] = this.props.searchValue.nid;
           varValue["passport"] = this.props.searchValue.passport;
           varValue["customerName"] = this.props.searchValue.customerName;
           varValue["dob"] = this.props.searchValue.dob;
           varValue["email"] = this.props.searchValue.email;
           varValue["phone"] = this.props.searchValue.phone;
           varValue["tin"] = this.props.searchValue.tin;
           varValue["registrationNo"] = this.props.searchValue.registrationNo;
           varValue["nationality"] = this.props.searchValue.nationality;*/

        /*   varValue["nidOld"] =this.props.oldAccountData.nid;
           varValue["registrationNoOld"] =this.props.oldAccountData.registration;
           varValue["emailOld"] =this.props.oldAccountData.email;
           varValue["dobOld"] =this.props.oldAccountData.dob;
           varValue["phoneOld"] =this.props.oldAccountData.phone;
           varValue["passportOld"] = this.props.oldAccountData.passport;
           varValue["customerNameOld"] = this.props.oldAccountData.customerName;*/


    }

    handleChange = (event) => {
        event.target.name = event.target.value;

    };

    handleSubmit = (object) => {
        object.serviceType = this.props.serviceType;
        object.subserviceType = this.props.subserviceType;
        object.department = this.props.department;


        if (this.props.app_uid !== undefined) {
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.app_uid;
            axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    var url = backEndServerURL + "/case/route/" + this.props.app_uid;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true
                            });
                            this.props.closeModal()

                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.response.status === 452) {
                                Functions.removeCookie();

                                this.setState({
                                    redirectLogin: true
                                })

                            }
                        });
                })
                .catch((error) => {
                    console.log(error)
                });


        } else {
            let varValue = [];
            var variableSetUrl = backEndServerURL + "/variables/" + this.state.caseId;
            axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    var url = backEndServerURL + "/case/route/" + this.state.caseId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true
                            });
                            this.props.closeModal()

                        })
                        .catch((error) => {
                            console.log(error)
                        });

                })
                .catch((error) => {
                    console.log(error)
                });
        }


        /*  else {
              object.serviceType = this.props.serviceType;
              object.subserviceType = this.props.subserviceType;


              var putUrlS = backEndServerURL + "/##/" + this.state.appUid + "/variable";

              axios.put(putUrlS, object, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                  .then((response) => {
                      var routeUrl = backEndServerURL + "/##/" + this.state.appUid + "/route-case";

                      axios.put(routeUrl, {"execute_triggers": true}, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                          .then((response) => {

                              this.setState({message: "Case routed successfully"});
                          })
                          .catch((error) => {
                              console.log(error)
                          });
                  })
                  .catch((error) => {
                      console.log(error)
                  });
          }*/
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    render() {

        return (

            <div>
                {this.renderInboxCase()}
                {this.renderNotification()}
            </div>
        )
    }
}


export default DataCaptureCS;
