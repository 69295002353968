import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createMuiTheme} from "@material-ui/core/styles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import InstaCardConfiguration from "../InstaDebitCard/InstaDebitCardRequisition";
import withStyles from "@material-ui/core/styles/withStyles";
import Forward from "../Forward";
import SummaryReport from "./SendTo";
import loader from "../../../Static/loader.gif";


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "caseId",
        type: "text",
        validation: "numeric",
        label: "Case ID",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        validation: "numeric",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        grid: 2,
        enum: [
            "City Maxx Instant debit card",
            "CUP Instant debit card",
            "City Gem Instant debit card",
            "City Alo Instant Debit Card",
            "City MaxX Islamic instant debit card",
            "Visa City Alo Islamic Instant Debit Card",
            "Mastercard Islamic Instant Debit Card"
        ]
    },

];


class CsInstaDebitCard extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            summary: false,
            searchItem: "",
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "Deliver to Branch",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            forwardModal: false,
            forwardArray: [],
            loader: true,
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            allData: [],
            requisitionModal: false,
            requisitionConfiguration: {},
            columns: [
                {title: "Case ID", field: "caseId"},
                {title: "Product Type", field: "productType"},
                {title: "Branch Name", field: "branchName"},
                {title: "Quantity", field: "quantity"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "maskedCardNumber"},
                {title: "Status", field: "status"},
                {title: "Batch No", field: "batchNo"},
                {title: "SOL Id", field: "solId"},
                {title: "Date", field: "date"}
            ],
            requisitionColumns: [
                {title: "Sol Id", field: "solId"},
                {title: "Product Type", field: "productType"},
                {title: "Card Type", field: "cardType"},
                {
                    title: 'Action', field: 'claimAction', render: rowData => (
                        <Button onClick={(e) => {
                            this.instaCardRequsition(e, rowData)
                        }} style={buttonStyle}>Requisition</Button>
                    )

                },
            ],

        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0
        });
        if (value === "All") {
            this.fetchDataFromAPI('All', 'getBranchInstaDebitCardAll/page/0')
        } else if (value === "Forward") {
            this.fetchDataFromAPI('Forward', 'getForwardedInstaDebitCardFromThisBranch/0')
        } else if (value === 'New Requisition') {
            this.newRequisition("New Requisition")
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    dateConverter = (value) => {
        return value.substr(0, 10);
    };

    instaCardRequsition = (e, rowData) => {
        e.preventDefault();
        let data = {...rowData.productType};

        this.setState({
            requisitionModal: true,
            requisitionConfiguration: data
        })
    };

    instaCardRequisition = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.requisitionModal}>
                <DialogContent className={classes.dialogPaper}>
                    <InstaCardConfiguration branchConfiguration={this.state.requisitionConfiguration}
                                            closeModal={this.closeRequisitionModal}/>
                </DialogContent>
            </Dialog>
        )
    };

    renderSummaryModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.summary}>
                <DialogContent className={classes.dialogPaper}>
                    <SummaryReport closeModal={this.closeRequisitionModal}/>
                </DialogContent>
            </Dialog>
        )
    };

    closeRequisitionModal = () => {
        this.setState({
            requisitionModal: false,
            summary: false
        })
    };


    componentDidMount() {
        //inbox call
        this.fetchDataFromAPI("Deliver to Branch")
        // let inbox_url = backEndServerURL + "/getBranchInstaDebitCardAll/page/0";
        // axios
        //     .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        //     .then(response => {
        //         if (response.status === 200) {
        //             let array = [];
        //             let responseArray = [];
        //             let rowData;
        //             let responseRowData;
        //             for (let i = 0; i < response.data.content.length; i++) {
        //                 responseRowData = response.data.content[i].appId;
        //                 rowData = {
        //                     id: response.data.content[i].id,
        //                     caseId: response.data.content[i].appId,
        //                     productType: response.data.content[i].productType,
        //                     cardType: response.data.content[i].cardType,
        //                     clientId: response.data.content[i].clientId,
        //                     maskedCardNumber: response.data.content[i].maskedCardNumber,
        //                     branchName: response.data.content[i].branchName,
        //                     userName: response.data.content[i].customerName,
        //                     status: response.data.content[i].status,
        //                     remarks: response.data.content[i].remarks,
        //                     date: this.dateConverter(response.data.content[i].branchInitiatingDate),
        //                     batchNo: response.data.content[i].batchNo,
        //                     solId: response.data.content[i].solId,
        //                     quantity: response.data.content[i].quantity,
        //                 };
        //                 array.push(rowData);
        //                 responseArray.push(responseRowData);
        //             }
        //
        //             this.setState({
        //                 allData: array,
        //                 responseData: responseArray,
        //                 rowsPerPage: response.data.numberOfElements,
        //                 pageNumber: response.data.number,
        //                 totalRow: response.data.totalElements,
        //                 loader: false
        //             });
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    newRequisition = (status) => {
        this.setState({
            activeItem: status,
            loader: true
        });
        let inbox_url = backEndServerURL + "/getBranchInstaDebitCardConfiguration";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response.data);
                if (response.status === 200) {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.length; i++) {
                        responseRowData = response.data[i].id;
                        rowData = {
                            id: response.data[i].id,
                            solId: response.data[i].solId,
                            productType: response.data[i].productType,
                            cardType: response.data[i].cardType,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        activeItem: status,
                        totalRow: response.data.length,
                        loader: false
                    });
                }
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    fetchDataFromAPI = (status, url) => {

        if (this.state.searchItem !== "") {
            this.searchData(this, status)
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getCSInstaDebitCardStatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All' || status === 'Forward') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            rowData = {
                                id: response.data.content[i].id,
                                caseId: response.data.content[i].appId,
                                productType: response.data.content[i].productType,
                                cardType: response.data.content[i].cardType,
                                clientId: response.data.content[i].clientId,
                                maskedCardNumber: response.data.content[i].maskedCardNumber,
                                branchName: response.data.content[i].branchName,
                                userName: response.data.content[i].customerName,
                                status: response.data.content[i].status,
                                remarks: response.data.content[i].remarks,
                                date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                                batchNo: response.data.content[i].batchNo,
                                solId: response.data.content[i].solId,
                                quantity: response.data.content[i].quantity,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                            loader: false
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        // let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true,
                });
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getBranchInstaDebitCardAll/page/" + page;
                } else if (status === 'Forward') {
                    inbox_url = backEndServerURL + "/getForwardedInstaDebitCardFromThisBranch/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getCSInstaDebitCardStatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        if (response.status === 200) {
                            let array = [];
                            let responseArray = [];
                            let rowData;
                            let responseRowData;
                            for (let i = 0; i < response.data.content.length; i++) {
                                responseRowData = response.data.content[i].appId;
                                rowData = {
                                    id: response.data.content[i].id,
                                    caseId: response.data.content[i].appId,
                                    productType: response.data.content[i].productType,
                                    cardType: response.data.content[i].cardType,
                                    clientId: response.data.content[i].clientId,
                                    maskedCardNumber: response.data.content[i].maskedCardNumber,
                                    branchName: response.data.content[i].branchName,
                                    userName: response.data.content[i].customerName,
                                    status: response.data.content[i].status,
                                    remarks: response.data.content[i].remarks,
                                    date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                                    batchNo: response.data.content[i].batchNo,
                                    solId: response.data.content[i].solId,
                                    quantity: response.data.content[i].quantity,
                                };
                                array.push(rowData);
                                responseArray.push(responseRowData);
                            }

                            this.setState({
                                allData: array,
                                responseData: responseArray,
                                activeItem: this.state.activeItem,
                                rowsPerPage: response.data.numberOfElements,
                                pageNumber: response.data.number,
                                totalRow: response.data.totalElements,
                                loader: false
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    });
            }
        }

    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {
        // e.preventDefault();

        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterBranchInstaDebitCard/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                activeItem: status,
            });
            inbox_url = backEndServerURL + "/filterBranchInstaDebitCard/" + status + "/" + page;
        }

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        productType: response.data.content[i].productType,
                        cardType: response.data.content[i].cardType,
                        clientId: response.data.content[i].clientId,
                        maskedCardNumber: response.data.content[i].maskedCardNumber,
                        branchName: response.data.content[i].branchName,
                        userName: response.data.content[i].customerName,
                        status: response.data.content[i].status,
                        remarks: response.data.content[i].remarks,
                        date: this.dateConverter(response.data.content[i].branchInitiatingDate),
                        batchNo: response.data.content[i].batchNo,
                        solId: response.data.content[i].solId,
                        quantity: response.data.content[i].quantity,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search Debit Card
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        display: "block",
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem);
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Insta Debit Card)</h4>
                    </CardHeader>
                    <CardBody>
                        {this.instaCardRequisition()}
                        {this.renderSummaryModal()}
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>
                                        <Menu.Item
                                            name="All"
                                            active={activeItem === "All"}
                                            style={
                                                activeItem === "All"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "All");
                                            }}
                                        />


                                        <Menu.Item
                                            name="Deliver to Branch"
                                            active={activeItem === "Deliver to Branch"}
                                            style={
                                                activeItem === "Deliver to Branch"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Deliver to Branch");
                                            }}
                                        />


                                        <Menu.Item
                                            name="Branch Received"
                                            active={activeItem === "Branch Received"}
                                            style={
                                                activeItem === "Branch Received"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Branch Received");
                                            }}
                                        />
                                        {/*<Menu.Item*/}
                                        {/*    name="Forward"*/}
                                        {/*    active={activeItem === "Forward"}*/}
                                        {/*    style={*/}
                                        {/*        activeItem === "Forward"*/}
                                        {/*            ? tabActive*/}
                                        {/*            : tabInactive*/}
                                        {/*    }*/}
                                        {/*    onClick={e => {*/}
                                        {/*        this.headerSelect(e, "Forward");*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <Menu.Item
                                            name="Sold"
                                            active={activeItem === "Sold"}
                                            style={
                                                activeItem === "Sold"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Sold");
                                            }}
                                        />
                                        <Menu.Item
                                            name="New Requisition"
                                            active={activeItem === "New Requisition"}
                                            style={
                                                activeItem === "New Requisition"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "New Requisition");
                                            }}
                                        />

                                    </Menu>

                                    {/*<Segment>*/}
                                    {this.tableChoice()}
                                    {/*</Segment>*/}
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.forwardModal}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <Forward closeModal={this.closeForwardModal}/>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    actionForSendToBranchToBranchReceived = (event, rowData, props) => {
        if (this.state.activeItem === "Deliver to Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Branch Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Deliver to Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToCallCenter = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Send to Call Center";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstadebitcardstatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToForward = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    forwardArray: rowIdArray,
                    forwardModal: true
                })
            }
        }
    };

    actionForBranchReceivedToSummary = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            this.setState({
                summary: true
            })
        }
    };

    closeForwardModal = (value) => {

        if (value !== undefined) {
            this.setState({
                forwardModal: false
            });
            let single_url = backEndServerURL + "/forwardInstaDebitCardToAnotherBranch";
            let postData = {
                forwardTo: value.branchName.key,
                idList: this.state.forwardArray
            };

            axios.post(single_url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    this.fetchDataFromAPI('Branch Received')
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.setState({
                forwardModal: false
            });
        }
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };


    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Deliver to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Deliver to Branch')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToBranchToBranchReceived(event, rowData, props)}
                                            >
                                                Branch Received
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Branch Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Branch Received')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            {/*&nbsp;*/}
                                            {/*<Button*/}
                                            {/*    style={buttonStyle}*/}
                                            {/*    onClick={(event, rowData) => this.actionForBranchReceivedToForward(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Forward*/}
                                            {/*</Button>*/}
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToSummary(event, rowData, props)}
                                            >
                                                Summary Report
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "New Requisition" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.requisitionColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(CsInstaDebitCard);
