import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "./CasaCloserLabel";
import PreviewButton from "../PreviewButton";
import UploadComponent from "./UploadComponent";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

let requestType = [
    {
        "varName": "closerRequestName",
        "type": "select",
        "label": "Input Transfer Account Number",
        "grid": 3,
        "required": true,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ]
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "validation": "numeric",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "required": true,
        "label": "Account No",
        "validation": "numeric",
        "grid": 2,

    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];


let closerRequestForm = [

    {
        "varName": "inputAccountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "validation": "numeric",
        "grid": 3,
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
        "conditionalOrValue": true,
        "conditionalVarName": "closerRequestName",
        "conditionalOrList": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment'
        ],
    },
    {
        "type": "empty",
        "grid": 12
    }
];


class CloserMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;

    }

    get initialState() {
        return {
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            cbNotFound: false,
            searchTableData: false,
            CustomerModal: false,
            getDeferralList: [],
            bearerApproval: '',
            csBearer: '',
            inputData: {
                accountNumber: "",
            },
            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            routeCase: false,
            caseId: 0,
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            loading: true,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            maintenanceRequest: false,
            previewDocument: false,
            showForm: true
        }
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {

        this.setState({
            loading: false
        });
        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }

    }

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.renderStartCase(event);

        }

    };

    renderStartCase = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(accountMaintenanceSearch, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                loading: true,
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = response.data;
                    inputData.customerName = response.data.customerName;
                    inputData.solId = response.data.solid;
                    inputData.cbNumber = response.data.cbNumber;
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData)
                    });
                    this.caseStart();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };


    caseStart = () => {
        if (this.state.inputData["accountNumber"]) {
            let url = backEndServerURL + "/startCase/cs_maintenance";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                    this.setState({
                        caseId: response.data.caseId,
                        appId: response.data.id,
                        appData: response.data.inputData,
                        getNewCase: true,
                        showValue: true,
                        getData: true,
                        getDocument: true,
                        searchData: true
                    });
                })
                .catch((error) => {

                })
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData === true) {
            return (
                <>
                    <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
                    <br/>
                </>
            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
                            }
                            <Grid item xs={12}></Grid>
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    renderSearchForm = (root) => {
        return (
            <Grid item xs={12}>
                <Grid container>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent, this.onKeyDownForDedup)
                        }
                        <button
                            style={{
                                marginTop: "18px",
                                height: '30px'
                            }}
                            onClick={this.renderStartCase}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Grid>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            onClick={this.handleSubmitDraft}
                        >
                            Save
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(requestType, this.state);
        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();
        if (error === true || errorTwo === true) {
            return 0;
        } else {
            let data = this.state.inputData;
            if (this.props.appId === undefined) {
                data.cs_bearer = "bm_approval";
            }
            data.freeFlag3 = "(CASA Closure) Case ID: " + this.state.caseId;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.maintenanceRequest = this.state.maintenanceRequest;
            data.cs_deferal = "NO";
            data.category = "CASA Closer";
            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";
            let remarksData = {};
            remarksData.remark = this.state.inputData.csRemarks;
            remarksData.map = {...data, csRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    routeCase: true,
                    loading: false
                });
                if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                    this.props.closeModal();
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(requestType, this.state);
        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();
        if (error === true || errorTwo === true) {
            return 0;
        } else {
            let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
            let data = this.state.inputData;
            data.freeFlag3 = "(CASA Closure) Case ID: " + this.state.caseId;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.category = "CASA Closer";
            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Draft!",
                            alert: true
                        });
                        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                            this.props.closeModal();
                        } else {
                            setTimeout(function () {
                                window.location.reload()
                            }, 1000);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };


    signature = () => {
        if (this.state.searchData === true && this.state.getData) {
            return (
                <React.Fragment>
                    {
                        this.previewButton()
                    }
                    <br/>
                </React.Fragment>
            )
        }
    };

    requestForm = () => {
        if (this.state.getData && this.state.showForm) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, closerRequestForm, this.updateComponent, this.functionForAccountInfo)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    functionForAccountInfo = (event, data) => {
        if (data === "inputAccountNumber") {
            this.setState({
                loading: true,
                showForm: false
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.inputAccountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.state.varValue['inputAccountNumber'] = this.state.inputData.inputAccountNumber;
                        this.setState({
                            showForm: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.inputAccountName = response.data.customerName;
                        this.setState({
                            inputData: inputData,
                            varValue: this.removeNullValue(inputData),
                            loading: false,
                            showForm: true
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        showForm: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };


    renderMaintenanceRequest = () => {
        if (this.state.getData && this.props.match.params.maintenance === "casa") {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, requestType, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>

            )
        }
    };


    previewButton = () => {
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.state.appId} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.state.appId} classes={classes}/>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >CASA Closer Maintenance</h4>
                </CardHeader>
                <CardBody>
                    {this.renderSearchForm(classes.root)}
                    {this.renderNotification()}
                    {this.renderCustomerDetails()}
                    {this.signature()}
                    {this.renderMaintenanceRequest()}
                    {this.requestForm()}
                    {this.uploadFunction()}
                    {this.renderRemarks()}
                    {this.handleSubmitButton()}
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(CloserMaintenance);
