import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import FileMappingReadOnly from "../../CommonComponent/FileMappingReadOnlyAwf";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import {BMsecuredLoanClosingJsonForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "grid": 12
    }]
;


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BmFormSecuredLoanClosing extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {
            bm_approved: '',
            subServiceType: '',
        },
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        confirmAlert: false,
        actionType: "",
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: false,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false

    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };
    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   serviceType={this.props.serviceType}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        this.setState({
            loading: true
        });
        if (this.props.appId !== undefined) {

            let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    if (data.remarks !== 'undefined') {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    }
                });
                this.setState({
                    getRemarks: remarksArray
                })
            })
                .catch((error) => {
                    console.log(error)
                });


            let url = assetOpsBackEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {


                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    let inputData=response.data;
                    inputData.bmRemarks=undefined;
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(inputData),
                        inputData: this.removeNullValue(inputData),
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    handleSubmit = (data) => {
        console.log(this.state.inputData);
        let dependencyField = [];

        this.setState({
            loading: true
        });
        let  saveDataDeferralRemarksAndRoute={};
        if(this.state.inputData.bmRemarks!==undefined && this.state.inputData.bmRemarks!==null){
            saveDataDeferralRemarksAndRoute.remark=this.state.inputData.bmRemarks;
        }

        var appId = this.props.appId;
        var approval = data;

       // this.state.inputData.bm_approved = data;
        if(data==="NO"){
            this.state.inputData.bm_approved = data;
        }
        else{
            this.state.inputData.bm_approved = data;
        }
        if(this.state.inputData.apiButton == undefined && this.state.inputData.exciseDuty !=="0"){
            this.state.inputData.apiButton = "loanExciseDuty";
        }
        else if(this.state.inputData.apiButton === undefined && this.state.inputData.exciseDuty === "0"){
            this.state.inputData.apiButton = "loanFeeCollection";
        }

        saveDataDeferralRemarksAndRoute.map={...this.state.inputData};

        let variableSetUrl = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("Before case route in BM");
                console.log(this.state.inputData);
                var url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;

                axios.post(url,saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: response.data,
                            alert: true,
                            loading: false
                        });

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })

                    });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });


    };


    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, BMsecuredLoanClosingJsonForm, this.updateComponent)

            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)

            )
        }

    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmit") {

            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "handleReject") {

            this.handleSubmit("NO")
        }
    };
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(bmRemarks, this.state);
        if ( errorRemarks ===true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {

            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "handleReject") {
            let dependencyField = [];
            if (actionType === "handleReject") {
                dependencyField.push({

                    "varName": "bmRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "BM Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        //onClick={(event) => this.handleSubmit(event, "YES")}
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}

                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        //onClick={(event) => this.handleSubmit(event, "NO")}
                        onClick={(event) => this.handleCommon(event, "handleReject")}

                    >Return
                    </button>
                </div>


            )
        }
    };
    /*
        renderMultipleDebitCardForm = () => {


            if (this.state.getData) {

                var sl;
                let objectForDebitCard = [];
                for (var i = 0; i < this.state.inputData.numberOfDebitCard; i++) {
                    sl = i + 1;
                    objectForDebitCard.push(
                        {
                            "varName": "customer" + sl,
                            "type": "title",
                            "label": "Debit Card " + sl,
                            "grid": 12
                        },
                        {
                            "varName": "customerName" + sl,
                            "type": "text",
                            "readOnly": true,
                            "label": "Name On Card",
                            "grid": 2,


                        },

                        {
                            "varName": "cardType" + sl,
                            "type": "select",
                            "readOnly": true,
                            "label": "Card Type",
                            "grid": 2,
                            "enum": [
                                "VISA",
                                "MASTER",
                                "CITYMAXX",
                            ],


                        },
                        {
                            "varName": "debitCardDeliveryType" + sl,
                            "type": "select",
                            "readOnly": true,
                            "label": "Delivery Type",
                            "enum": [
                                "Courier",
                                "Branch"
                            ],
                            "grid": 2,


                        },

                        {
                            "varName": "debitRequestkBranch" + sl,
                            "type": "select",
                            "readOnly": true,
                            "label": "Branch Name",
                            "enum": [
                                "GULSHAN 1",
                                "MOTHIJHEEL 1",
                                "DHANMONDI",
                            ],
                            "grid": 2,
                            "conditional": true,
                            "conditionalVarName": "debitCardDeliveryType" + sl,
                            "conditionalVarValue": "Branch",

                        },
                    )
                }


                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, objectForDebitCard, this.updateComponent)
                )

            }
        };
    */
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Debit Card</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfDebitCard"]}
                            name="numberOfDebitCard"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                      closeModal={this.closeConfirmAlert}


                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                        {this.renderNotification()}
                        {this.renderForm()}
                        <Grid item xs={12}></Grid>

                        {this.mappingPhoto()}
                    </Grid>
                    <br/>


                    {this.renderRemarksData()}
                    <br/>
                    {this.renderFlowSUmmeryButton()}
                    <br/>

                    {this.renderRemarks()}
                    <br/>

                    <div>
                        {this.renderSubmitButton()}
                    </div>
                </ThemeProvider>

            </div>


        )
    }


}

export default withStyles(styles)(BmFormSecuredLoanClosing);
