import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import MyValidation from "../../JsonForm/MyValidation";
import ConfirmationModal from "./ConfirmationModal";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteModal from "./DeleteModal";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import CaseWiseFiles from "../Maintenance/common/CaseWiseFiles";
import CommonApi from "../Maintenance/common/CommonApi";
import CBNotFound from "../CASA/CBNotFound";

let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "grid": 12
    }];

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".pdf",
    "fileType": "multiple",
    "label": "Upload File",
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class VerifyChecker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            confirmationTitle: "",
            confirmationModal: false,
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            loading: true,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            fileUploadData: {},
            countUploadedFileArray: [],
            documentArray: [],
            deleteFile: false,
            fileName: "",
            cbNotFound: false
        };
    }

    createDocumentsTable = (sl, action) => {
        return (
            [sl, action]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariables(this.props.appId).then((response) => {
                let inputData = {...response.data};
                let jsonForm = this.props.commonJsonForm;
                for (let i = 0; i < jsonForm.length; i++) {
                    if (jsonForm[i].varName === "accountNumber") {
                        jsonForm[i].type = "text";
                        jsonForm[i].readOnly = true
                    }
                }
                this.functionForFileGet();
                CommonApi.getRemarkDetails(this.props.appId).then((remarksArray) => {
                    this.setState({
                        getRemarks: remarksArray
                    })
                }).catch((error) => {
                    console.log(error)
                });

                this.setState({
                    inputData: inputData,
                    varValue: this.emptyValueRemove(inputData),
                    getData: true,
                    showValue: true,
                    loading: false
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    }

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/e-advice/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let documentArray = [];
                response.data.map((data, index) => {
                    documentArray.push(this.createDocumentsTable(`Document ${index + 1}`, this.documentAction(data)))
                });
                this.setState({
                    countUploadedFileArray: response.data,
                    documentArray: documentArray
                });
                console.log(this.state.countUploadedFileArray)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    documentAction = (name) => {
        return (
            <>
                {this.state.varValue.hide_buttons !== "Y" && <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.deleteDocument(name)}
                >
                    <DeleteIcon/>
                </button>
                }
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(name)}
                >
                    <GetAppIcon/>
                </button>
            </>
        )
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    deleteDocument = (name) => {
        this.setState({
            deleteFile: true,
            confirmationTitle: "Are You Sure You Want to Delete this File!",
            fileName: name
        })
    };

    functionForFileDelete = () => {
        this.setState({
            loading: true,
            deleteFile: false,
        });
        let postData = {};
        postData.appId = this.props.appId;
        postData.fileName = this.state.fileName;

        let imageUrl = backEndServerURL + "/caseFile/delete";
        axios.post(imageUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.functionForFileGet();
                    this.setState({
                        fileName: "",
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Failed",
                    notificationMessage: "Something went wrong!"
                })
            });
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined" || clone[prop] === undefined || clone[prop] === null || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    handleSubmit = (event, data) => {
        event.preventDefault();
        let dependencyField = [];
        if (data === "RETURN") {
            dependencyField.push({
                "varName": "checkerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Checker Remarks",
                "grid": 12
            })
        }
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        let fileValidation = MyValidation.fileValidation(fileUpload, this.state);
        this.forceUpdate();
        if (error === true || fileValidation === true) {
            return 0;
        }

        if (data === "APPROVE") {
            this.setState({
                confirmationModal: true,
                confirmationTitle: "Are you sure you want to close it!"
            })
        } else {
            this.caseRoute(data)
        }

    };

    confirmation = (value) => {
        if (value === "ok") {
            this.caseRoute('CLOSED')
        }
        this.setState({
            confirmationModal: false,
            confirmationTitle: ""
        })
    };

    confirmationDelete = (value) => {
        if (value === "ok") {
            this.functionForFileDelete()
        } else {
            this.setState({
                deleteFile: false,
                fileName: ""
            })
        }
    };

    functionForFileUpload = () => {
        return new Promise((resolve, reject) => {
            if (this.state.fileUploadData.scanningFile !== undefined) {
                let error = MyValidation.fileValidation(fileUpload, this.state);
                this.forceUpdate();
                if (error === true) {
                    reject("error");
                    this.setState({
                        loading: false
                    });
                    return 0;
                }
                let formData = new FormData();
                formData.append("appId", this.props.appId);
                this.state.fileUploadData.scanningFile.map((item, index) => {
                    console.log(item, `e-advice${index}`);
                    formData.append("file", item);
                    formData.append("type", `e-advice`)
                });

                let fileUploadPath = backEndServerURL + "/case/upload";
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then(response => resolve("success"))
                    .catch(error => reject("error"))
            } else {
                resolve("success")
            }
        })
    };


    caseRoute = (data) => {
        this.setState({
            loading: true
        });
        this.functionForFileUpload().then((response) => {
            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.props.appId;
            axios.post(commentsUrl, {remark: this.state.inputData.checkerRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });

            this.state.inputData.checkerRemarks = undefined;
            this.state.inputData.hide_buttons = (data === "CLOSED" && (this.state.fileUploadData.scanningFile !== undefined || this.state.countUploadedFileArray.length > 0)) ? "Y" : "N";
            this.state.inputData.bond_checker_approval = data === "RETURN" ? "RETURN" : "APPROVE";

            let variableSetUrl = backEndServerURL + "/changeStatus/" + this.props.appId + "/" + data;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        if (data === "CLOSED" || data === "RETURN") {
                            if (data === "CLOSED") {
                                this.functionForSendMail()
                            }
                            let url = backEndServerURL + "/case/route/" + this.props.appId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.status === 200) {
                                        if (data === "CLOSED" && (this.state.fileUploadData.scanningFile !== undefined || this.state.countUploadedFileArray.length > 0)) {
                                            let url = backEndServerURL + "/changeInboxTab/" + this.props.appId + "/Certificate";
                                            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        this.setState({
                                                            loading: false,
                                                            title: "Successful!",
                                                            notificationMessage: "Successfully Routed!",
                                                            alert: true
                                                        });
                                                        this.props.closeModal()
                                                    }
                                                })
                                                .catch((error) => {
                                                    this.setState({
                                                        loading: false,
                                                        title: "Failed!",
                                                        notificationMessage: "Something went wrong!",
                                                        alert: true
                                                    });
                                                    console.log(error);
                                                });
                                        } else {
                                            this.setState({
                                                loading: false,
                                                title: "Successful!",
                                                notificationMessage: "Successfully Routed!",
                                                alert: true
                                            });
                                            this.props.closeModal()
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        } else {
                            this.setState({
                                loading: false,
                                title: "Successful!",
                                notificationMessage: "Status Change Successful!",
                                alert: true
                            });
                            this.props.closeModal()
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }).catch((error) => {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: "Failed to Upload E-advice!"
            })
        });
    };

    functionForSendMail = () => {
        return new Promise((resolve, reject) => {
            let postData = {};
            postData.mobileNumber = this.state.inputData.mobileNumber;
            postData.email = this.state.inputData.email;
            postData.type = "e-advice";
            let url = backEndServerURL + "/sendEInstrument/" + this.props.appId;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error));
        })
    };


    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )
        }
    };


    renderButton = () => {
        if (this.state.getData && this.state.varValue.hide_buttons !== "Y") {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "IN IBAS PROCESSING")}
                    >In IBAS Processing
                    </button>
                    {(this.state.inputData.selectTypeOfRequest !== "SANCHAYPATRA") && <>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                            }}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "BOND SCRIPT")}
                        >BOND Script
                        </button>
                    </>}
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVE")}
                    >Close
                    </button>
                </div>
            )
        }
    };


    renderRemarks = () => {
        if (this.state.getData && this.state.varValue.hide_buttons !== "Y") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };


    renderUploadFile = () => {
        if (this.state.getData && this.state.varValue.hide_buttons !== "Y") {
            return (
                <>
                    <Grid item xs={12}>
                        {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                    </Grid>
                    {this.state.fileUploadData.scanningFile !== undefined && this.state.fileUploadData.scanningFile.map((item, index) => {
                        return (
                            <Grid item xs={12}>
                                <p style={{color: "red"}}>{item.name}</p>
                            </Grid>
                        )
                    })}
                </>

            )
        }
    };

    renderPdfDownload = () => {
        if (this.state.countUploadedFileArray.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>File Uploaded Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Sl", "Action"]}
                            tableData={this.state.documentArray}
                            tableAllign={['left', 'right']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.props.appId} classes={classes} processName="Bond"/>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmationModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal title={this.state.confirmationTitle} closeModal={this.confirmation}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.deleteFile}>
                        <DialogContent className={classes.dialogPaper}>
                            <DeleteModal title={this.state.confirmationTitle} closeModal={this.confirmationDelete}/>
                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {this.renderNotification()}
                            {this.renderJsonFormFirst()}
                        </ThemeProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <br/>
                        {this.uploadFunction()}
                        <br/><br/>
                    </Grid>
                    <CaseWiseFiles appId={this.props.appId}/>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    <br/>
                    {this.renderPdfDownload()}
                    {this.renderUploadFile()}
                    <ThemeProvider theme={theme}>
                        {this.renderRemarks()}
                        <br/>
                    </ThemeProvider>
                    {this.renderButton()}
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(VerifyChecker);

