import React, {Component} from "react";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import {backEndServerURL} from "../../../Common/Constant";

class Empty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "Please Wait File Downloading......"
        }
    }

    componentDidMount() {
        let download_url = backEndServerURL + "/rvData/getFile/" + this.props.match.params.id;
        axiosDownload.downloadFile(download_url, 'GET', {}, `${this.props.match.params.id}.xlsx`).then((res) => {
            if (res) {
                this.setState({
                    message: "Download Successful!"
                })
            }
        }).catch((error) => {
            if (error) {
                this.setState({
                    message: "Download Failed!"
                })
            }
        })
    }

    render() {
        return (
            <div style={{"margin": "0 auto", "textAlign": "center"}}>
                <p>{this.state.message}</p>
            </div>
        )
    }
}

export default Empty;