import React, {Component} from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import loader from '../../../Static/loader.gif';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import FileMapping from "../CommonComponent/FileMapping";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

var arrayListMerchant = [];
const proprietorDocuments = [
    {
        "varName": "tradeLicense",
        "type": "checkbox",
        "label": "Trade License",
        "grid": 12,
    },
    {
        "varName": "nidOrPassport",
        "type": "checkbox",
        "label": "National ID or Passport",
        "grid": 12,
    },
    {
        "varName": "passportSizePhoto",
        "type": "checkbox",
        "label": "Photo",
        "grid": 12,
    },
    {
        "varName": "etin",
        "type": "checkbox",
        "label": "E-TIN",
        "grid": 12,
    },
    {
        "varName": "bankStatement",
        "type": "checkbox",
        "label": "Bank Statement",
        "grid": 12,
    },
   /* {
        "varName": "visitingCard",
        "type": "checkbox",
        "label": "Visiting Card",
        "grid": 12,
    },*/
    {
        "varName": "others",
        "type": "checkbox",
        "label": "Others",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm1",
        "type": "checkbox",
        "label": "Merchant application form 1",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm2",
        "type": "checkbox",
        "label": "Merchant application form 2",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm3",
        "type": "checkbox",
        "label": "Merchant application form 3",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm4",
        "type": "checkbox",
        "label": "Merchant application form 4",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm5",
        "type": "checkbox",
        "label": "Merchant application form 5",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm6",
        "type": "checkbox",
        "label": "Merchant application form 6",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm7",
        "type": "checkbox",
        "label": "Merchant application form 7",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm8",
        "type": "checkbox",
        "label": "Merchant application form 8",
        "grid": 12,
    }

];
const partnershipDocuments = [
    {
        "varName": "tradeLicense",
        "type": "checkbox",
        "label": "Trade License",
        "grid": 12,
    },
    {
        "varName": "nidOrPassport",
        "type": "checkbox",
        "label": "National ID or Passport for all partners",
        "grid": 12,
    },
    {
        "varName": "photoOfAllPartners",
        "type": "checkbox",
        "label": "Photo of all partners",
        "grid": 12,
    },
    {
        "varName": "partnershipDeed",
        "type": "checkbox",
        "label": "Partnership Deed",
        "grid": 12,
    },
    {
        "varName": "partnershipResolutaionLetter",
        "type": "checkbox",
        "label": "Partnership Resolution Letter",
        "grid": 12,
    },
    {
        "varName": "etinCertificatesOfPartners",
        "type": "checkbox",
        "label": "E-TIN Certificates of the partners",
        "grid": 12,
    },
    {
        "varName": "bankStatement",
        "type": "checkbox",
        "label": "Bank Statement",
        "grid": 12,
    },
   /* {
        "varName": "visitingCard",
        "type": "checkbox",
        "label": "Visiting Card",
        "grid": 12,
    },*/
    {
        "varName": "others",
        "type": "checkbox",
        "label": "Others",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm1",
        "type": "checkbox",
        "label": "Merchant application form 1",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm2",
        "type": "checkbox",
        "label": "Merchant application form 2",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm3",
        "type": "checkbox",
        "label": "Merchant application form 3",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm4",
        "type": "checkbox",
        "label": "Merchant application form 4",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm5",
        "type": "checkbox",
        "label": "Merchant application form 5",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm6",
        "type": "checkbox",
        "label": "Merchant application form 6",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm7",
        "type": "checkbox",
        "label": "Merchant application form 7",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm8",
        "type": "checkbox",
        "label": "Merchant application form 8",
        "grid": 12,
    }

];
const llcDocuments = [
    {
        "varName": "tradeLicense",
        "type": "checkbox",
        "label": "Trade License",
        "grid": 12,
    },
    {
        "varName": "memorandumCertificate",
        "type": "checkbox",
        "label": "Memorandum, Certificate Incorporation, Articles of Association & Form XII",
        "grid": 12,
    },
    {
        "varName": "certificateOfCommencement",
        "type": "checkbox",
        "label": "Certificate of commencement of Business (For Public Ltd Company)",
        "grid": 12,
    },
    {
        "varName": "photoOfTheAuthorizedPerson",
        "type": "checkbox",
        "label": "Photo (2 copies) of the authorized person and Directors having 20% or more shares",
        "grid": 12,
    },
    {
        "varName": "boardResolutionLetter",
        "type": "checkbox",
        "label": "Board Resolution Letter",
        "grid": 12,
    },
    {
        "varName": "nidOrPassport",
        "type": "checkbox",
        "label": "NID or Passport",
        "grid": 12,
    },
    {
        "varName": "etin",
        "type": "checkbox",
        "label": "E-TIN",
        "grid": 12,
    },
    {
        "varName": "eighteenBFromBangladeshBank",
        "type": "checkbox",
        "label": "18-B from Bangladesh Bank",
        "grid": 12,
    },
    {
        "varName": "permissionFromMinistryOfIndustry",
        "type": "checkbox",
        "label": "Permission from ministry of industry",
        "grid": 12,
    },
    {
        "varName": "certificateOfFillingWithRegisterJointStockCompany",
        "type": "checkbox",
        "label": "Certificate of filling with register Joint Stock Company",
        "grid": 12,
    },
    {
        "varName": "fromQA",
        "type": "checkbox",
        "label": "Form QA-22",
        "grid": 12,
    },
    {
        "varName": "bankStatement",
        "type": "checkbox",
        "label": "Bank Statement",
        "grid": 12,
    },
    /*{
        "varName": "visitingCard",
        "type": "checkbox",
        "label": "Visiting Card",
        "grid": 12,
    },*/
    {
        "varName": "others",
        "type": "checkbox",
        "label": "Others",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm1",
        "type": "checkbox",
        "label": "Merchant application form 1",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm2",
        "type": "checkbox",
        "label": "Merchant application form 2",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm3",
        "type": "checkbox",
        "label": "Merchant application form 3",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm4",
        "type": "checkbox",
        "label": "Merchant application form 4",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm5",
        "type": "checkbox",
        "label": "Merchant application form 5",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm6",
        "type": "checkbox",
        "label": "Merchant application form 6",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm7",
        "type": "checkbox",
        "label": "Merchant application form 7",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm8",
        "type": "checkbox",
        "label": "Merchant application form 8",
        "grid": 12,
    }

];
const othersDocuments = [
    {
        "varName": "certificateOfRegistration",
        "type": "checkbox",
        "label": "Certificate of registration",
        "grid": 12,
    },
    {
        "varName": "certificateOfNgoBureau",
        "type": "checkbox",
        "label": "Certificate of NGO Bureau or Other Regulatory",
        "grid": 12,
    },
    {
        "varName": "certificateOfMicroCredit",
        "type": "checkbox",
        "label": "Certificate of Micro Credit Regulatory Authority or Other regulatory",
        "grid": 12,
    },
    {
        "varName": "copyOfByLawsOrConstitution",
        "type": "checkbox",
        "label": "Copy of By-Laws or Constitution",
        "grid": 12,
    },
    {
        "varName": "listOfExecutiveBody",
        "type": "checkbox",
        "label": "List of executive body",
        "grid": 12,
    },
    {
        "varName": "memorandumAndArticleOfAssociation",
        "type": "checkbox",
        "label": "Memorandum & Article of Association",
        "grid": 12,
    },
    {
        "varName": "boardResolutionOrExtract",
        "type": "checkbox",
        "label": "Board Resolution or Extract",
        "grid": 12,
    },
    {
        "varName": "photo",
        "type": "checkbox",
        "label": "Photo",
        "grid": 12,
    },
    {
        "varName": "nidOrPassport",
        "type": "checkbox",
        "label": "NID or Passport",
        "grid": 12,
    },
    {
        "varName": "bankStatement",
        "type": "checkbox",
        "label": "Bank Statement",
        "grid": 12,
    },
  /*  {
        "varName": "visitingCard",
        "type": "checkbox",
        "label": "Visiting Card",
        "grid": 12,
    },*/
    {
        "varName": "others",
        "type": "checkbox",
        "label": "Others",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm1",
        "type": "checkbox",
        "label": "Merchant application form 1",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm2",
        "type": "checkbox",
        "label": "Merchant application form 2",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm3",
        "type": "checkbox",
        "label": "Merchant application form 3",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm4",
        "type": "checkbox",
        "label": "Merchant application form 4",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm5",
        "type": "checkbox",
        "label": "Merchant application form 5",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm6",
        "type": "checkbox",
        "label": "Merchant application form 6",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm7",
        "type": "checkbox",
        "label": "Merchant application form 7",
        "grid": 12,
    },
    {
        "varName": "merchantApplicatioForm8",
        "type": "checkbox",
        "label": "Merchant application form 8",
        "grid": 12,
    }


];

class MerchantIndexing extends Component {
    state = {
        getSplitFile: [],
        loader: null,
        inputData: {},
        varValue: {},
        dropdownSearchData: {},
        autoComplete: {},
        showValue: true,
        getData: false,
        checkList: false,
        checkData: {},
        scanFile: false,
        selectAllType: true,
        selectedDate: {},
        getCheckerList: [],
        checkerListShow: false,
        fileUploadData: {},
        errorArray: {},
        errorMessages: {},
        rerender: false,
        selectType: "",
        title: "",
        alert: false,
        getMappingAllImage: false,
        message: "",
        notificationMessage: "",
        uploadModal: false,
        arrayPush: false,
        selectAll: false,
        defaultSelect: false
    };

    constructor(props) {
        super(props);

        this.closeScan = this.closeScan.bind(this);

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {

        if (this.props.merchantType !== undefined) {
            this.setState({
                checkList: true
            });
        }

        if (this.props.merchantType === "Sole Proprietorship") {
            let obj = {
                tradeLicense: true,
                nidOrPassport: true,
                passportSizePhoto: true,
                etin: true,
                bankStatement: true,
                //visitingCard: true,
                others: true,
                merchantApplicatioForm1: true,
                merchantApplicatioForm2: true,
                merchantApplicatioForm3: true,
                merchantApplicatioForm4: true,
                merchantApplicatioForm5: true,
                merchantApplicatioForm6: true,
                merchantApplicatioForm7: true,
                merchantApplicatioForm8: true,
            };
            this.setState({
                inputData: obj,
                varValue: obj,
            })
        } else if (this.props.merchantType === "Limited Liability") {
            let obj = {
                tradeLicense: true,
                memorandumCertificate: true,
                certificateOfCommencement: true,
                photoOfTheAuthorizedPerson: true,
                boardResolutionLetter: true,
                nidOrPassport: true,
                etin: true,
                eighteenBFromBangladeshBank: true,
                permissionFromMinistryOfIndustry: true,
                certificateOfFillingWithRegisterJointStockCompany: true,
                fromQA: true,
                bankStatement: true,
                //visitingCard: true,
                others: true,
                merchantApplicatioForm1: true,
                merchantApplicatioForm2: true,
                merchantApplicatioForm3: true,
                merchantApplicatioForm4: true,
                merchantApplicatioForm5: true,
                merchantApplicatioForm6: true,
                merchantApplicatioForm7: true,
                merchantApplicatioForm8: true,
            };
            this.setState({
                inputData: obj,
                varValue: obj,
            })
        } else if (this.props.merchantType === "Partnership") {
            let obj = {
                tradeLicense: true,
                nidOrPassport: true,
                photoOfAllPartners: true,
                partnershipDeed: true,
                partnershipResolutaionLetter: true,
                etinCertificatesOfPartners: true,
                bankStatement: true,
                //visitingCard: true,
                others: true,
                merchantApplicatioForm1: true,
                merchantApplicatioForm2: true,
                merchantApplicatioForm3: true,
                merchantApplicatioForm4: true,
                merchantApplicatioForm5: true,
                merchantApplicatioForm6: true,
                merchantApplicatioForm7: true,
                merchantApplicatioForm8: true,
            };
            this.setState({
                inputData: obj,
                varValue: obj,
            })
        } else if (this.props.merchantType === "Others") {
            let obj = {
                certificateOfRegistration: true,
                certificateOfNgoBureau: true,
                certificateOfMicroCredit: true,
                copyOfByLawsOrConstitution: true,
                listOfExecutiveBody: true,
                memorandumAndArticleOfAssociation: true,
                boardResolutionOrExtract: true,
                photo: true,
                nidOrPassport: true,
                bankStatement: true,
                //visitingCard: true,
                others: true,
                merchantApplicatioForm1: true,
                merchantApplicatioForm2: true,
                merchantApplicatioForm3: true,
                merchantApplicatioForm4: true,
                merchantApplicatioForm5: true,
                merchantApplicatioForm6: true,
                merchantApplicatioForm7: true,
                merchantApplicatioForm8: true,
            };
            this.setState({
                inputData: obj,
                varValue: obj,
            })
        }

    }

    close = () => {
        this.props.closeModal();
    };
    closeScan = (data) => {
        arrayListMerchant = [];
        if (data) {
            this.props.closeModal();
        } else {
            this.setState({
                checkList: true,
                scanFile: false,
            })
        }
    };

    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({

            getMappingAllImage: false,

        })
    };

    submitModal = (data) => {
        if (data = "SUBMIT") {
            this.props.closeModal();
        }
    };

    renderMappingImageModal = () => {
        if (this.state.scanFile) {
            if (this.props.merchantType !== undefined) {
                return (
                    <FileMapping dropDownOption={arrayListMerchant}
                                 serviceType="Merchant"
                                 appId={this.props.appId}
                                 condition={true}
                                 dropDownConditionOption={arrayListMerchant}
                                 submitModal={this.submitModal}
                                 closeModal={this.closeScan}/>
                )
            }
        }
    };


    checkList = () => {


        if (this.state.checkList && (this.props.merchantType === "Sole Proprietorship")) {

            return (
                <div>

                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, proprietorDocuments, this.updateComponent)
                    }

                    <button
                        className="btn btn-outline-primary"
                        type='button'
                        onClick={this.okHandler}
                    >OK
                    </button>
                </div>
            )

        } else if (this.state.checkList && (this.props.merchantType === "Partnership")) {

            return (
                <div>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, partnershipDocuments, this.updateComponent)
                    }

                    <button
                        className="btn btn-outline-primary"
                        type='button'
                        onClick={this.okHandler}
                    >OK
                    </button>
                </div>
            )

        } else if (this.state.checkList && (this.props.merchantType === "Limited Liability")) {

            return (
                <div>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, llcDocuments, this.updateComponent)
                    }

                    <button
                        className="btn btn-outline-primary"
                        type='button'
                        onClick={this.okHandler}
                    >OK
                    </button>
                </div>
            )

        } else if (this.state.checkList && (this.props.merchantType === "Others")) {

            return (
                <div>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, othersDocuments, this.updateComponent)
                    }

                    <button
                        className="btn btn-outline-primary"
                        type='button'
                        onClick={this.okHandler}
                    >OK
                    </button>
                </div>
            )

        }

    };

    okHandler = () => {
        arrayListMerchant=[];
        this.setState({
            scanFile: true,
            checkList: false,
            checkData: this.state.inputData,
        });

        let indexList = this.props.merchantType === "Sole Proprietorship" ? proprietorDocuments : this.props.merchantType === "Limited Liability" ? llcDocuments : this.props.merchantType === "Partnership" ? partnershipDocuments : othersDocuments;

        for (let i = 0; i < indexList.length; i++) {
            if (this.state.inputData[indexList[i].varName] === true) {
                if (arrayListMerchant.includes({label: indexList[i].label}) === false) {
                    arrayListMerchant.push({label: indexList[i].label});
                }
            }
        }
    };


    handleChangeForSelectAll = () => {
        this.setState({
            selectAllType: !this.state.selectAllType
        });

        if (this.props.merchantType === "Sole Proprietorship") {
            this.setState((prevState) => {
                return {
                    inputData: {
                        tradeLicense: !prevState.inputData.tradeLicense,
                        nidOrPassport: !prevState.inputData.nidOrPassport,
                        passportSizePhoto: !prevState.inputData.passportSizePhoto,
                        etin: !prevState.inputData.etin,
                        bankStatement: !prevState.inputData.bankStatement,
                        //visitingCard: !prevState.inputData.visitingCard,
                        others: !prevState.inputData.others,
                        merchantApplicatioForm1: !prevState.inputData.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.inputData.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.inputData.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.inputData.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.inputData.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.inputData.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.inputData.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.inputData.merchantApplicatioForm8,
                    },
                    varValue: {
                        tradeLicense: !prevState.varValue.tradeLicense,
                        nidOrPassport: !prevState.varValue.nidOrPassport,
                        passportSizePhoto: !prevState.varValue.passportSizePhoto,
                        etin: !prevState.varValue.etin,
                        bankStatement: !prevState.varValue.bankStatement,
                        //visitingCard: !prevState.varValue.visitingCard,
                        others: !prevState.varValue.others,
                        merchantApplicatioForm1: !prevState.varValue.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.varValue.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.varValue.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.varValue.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.varValue.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.varValue.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.varValue.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.varValue.merchantApplicatioForm8,
                    }
                }
            })
        } else if (this.props.merchantType === "Limited Liability") {
            this.setState((prevState) => {
                return {
                    inputData: {
                        tradeLicense: !prevState.inputData.tradeLicense,
                        memorandumCertificate: !prevState.inputData.memorandumCertificate,
                        certificateOfCommencement: !prevState.inputData.certificateOfCommencement,
                        photoOfTheAuthorizedPerson: !prevState.inputData.photoOfTheAuthorizedPerson,
                        boardResolutionLetter: !prevState.inputData.boardResolutionLetter,
                        nidOrPassport: !prevState.inputData.nidOrPassport,
                        etin: !prevState.inputData.etin,
                        eighteenBFromBangladeshBank: !prevState.inputData.eighteenBFromBangladeshBank,
                        permissionFromMinistryOfIndustry: !prevState.inputData.permissionFromMinistryOfIndustry,
                        certificateOfFillingWithRegisterJointStockCompany: !prevState.inputData.certificateOfFillingWithRegisterJointStockCompany,
                        fromQA: !prevState.inputData.fromQA,
                        bankStatement: !prevState.inputData.bankStatement,
                        //visitingCard: !prevState.inputData.visitingCard,
                        others: !prevState.inputData.others,
                        merchantApplicatioForm1: !prevState.inputData.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.inputData.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.inputData.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.inputData.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.inputData.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.inputData.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.inputData.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.inputData.merchantApplicatioForm8,
                    },
                    varValue: {
                        tradeLicense: !prevState.varValue.tradeLicense,
                        memorandumCertificate: !prevState.varValue.memorandumCertificate,
                        certificateOfCommencement: !prevState.varValue.certificateOfCommencement,
                        photoOfTheAuthorizedPerson: !prevState.varValue.photoOfTheAuthorizedPerson,
                        boardResolutionLetter: !prevState.varValue.boardResolutionLetter,
                        nidOrPassport: !prevState.varValue.nidOrPassport,
                        etin: !prevState.varValue.etin,
                        eighteenBFromBangladeshBank: !prevState.varValue.eighteenBFromBangladeshBank,
                        permissionFromMinistryOfIndustry: !prevState.varValue.permissionFromMinistryOfIndustry,
                        certificateOfFillingWithRegisterJointStockCompany: !prevState.varValue.certificateOfFillingWithRegisterJointStockCompany,
                        fromQA: !prevState.varValue.fromQA,
                        bankStatement: !prevState.varValue.bankStatement,
                        //visitingCard: !prevState.varValue.visitingCard,
                        others: !prevState.varValue.others,
                        merchantApplicatioForm1: !prevState.varValue.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.varValue.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.varValue.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.varValue.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.varValue.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.varValue.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.varValue.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.varValue.merchantApplicatioForm8,
                    }
                }
            })
        } else if (this.props.merchantType === "Partnership") {
            this.setState((prevState) => {
                return {
                    inputData: {
                        tradeLicense: !prevState.inputData.tradeLicense,
                        nidOrPassport: !prevState.inputData.nidOrPassport,
                        photoOfAllPartners: !prevState.inputData.photoOfAllPartners,
                        partnershipDeed: !prevState.inputData.partnershipDeed,
                        partnershipResolutaionLetter: !prevState.inputData.partnershipResolutaionLetter,
                        etinCertificatesOfPartners: !prevState.inputData.etinCertificatesOfPartners,
                        bankStatement: !prevState.inputData.bankStatement,
                        //visitingCard: !prevState.inputData.visitingCard,
                        others: !prevState.inputData.others,
                        merchantApplicatioForm1: !prevState.inputData.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.inputData.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.inputData.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.inputData.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.inputData.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.inputData.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.inputData.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.inputData.merchantApplicatioForm8,
                    },
                    varValue: {
                        tradeLicense: !prevState.varValue.tradeLicense,
                        nidOrPassport: !prevState.varValue.nidOrPassport,
                        photoOfAllPartners: !prevState.varValue.photoOfAllPartners,
                        partnershipDeed: !prevState.varValue.partnershipDeed,
                        partnershipResolutaionLetter: !prevState.varValue.partnershipResolutaionLetter,
                        etinCertificatesOfPartners: !prevState.varValue.etinCertificatesOfPartners,
                        bankStatement: !prevState.varValue.bankStatement,
                        //visitingCard: !prevState.varValue.visitingCard,
                        others: !prevState.varValue.others,
                        merchantApplicatioForm1: !prevState.varValue.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.varValue.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.varValue.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.varValue.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.varValue.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.varValue.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.varValue.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.varValue.merchantApplicatioForm8,
                    }
                }
            })
        } else if (this.props.merchantType === "Others") {
            this.setState((prevState) => {
                return {
                    inputData: {
                        certificateOfRegistration: !prevState.inputData.certificateOfRegistration,
                        certificateOfNgoBureau: !prevState.inputData.certificateOfNgoBureau,
                        certificateOfMicroCredit: !prevState.inputData.certificateOfMicroCredit,
                        copyOfByLawsOrConstitution: !prevState.inputData.copyOfByLawsOrConstitution,
                        listOfExecutiveBody: !prevState.inputData.listOfExecutiveBody,
                        memorandumAndArticleOfAssociation: !prevState.inputData.memorandumAndArticleOfAssociation,
                        boardResolutionOrExtract: !prevState.inputData.boardResolutionOrExtract,
                        photo: !prevState.inputData.photo,
                        nidOrPassport: !prevState.inputData.nidOrPassport,
                        bankStatement: !prevState.inputData.bankStatement,
                        //visitingCard: !prevState.inputData.visitingCard,
                        others: !prevState.inputData.others,
                        merchantApplicatioForm1: !prevState.inputData.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.inputData.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.inputData.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.inputData.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.inputData.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.inputData.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.inputData.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.inputData.merchantApplicatioForm8,
                    },
                    varValue: {
                        certificateOfRegistration: !prevState.varValue.certificateOfRegistration,
                        certificateOfNgoBureau: !prevState.varValue.certificateOfNgoBureau,
                        certificateOfMicroCredit: !prevState.varValue.certificateOfMicroCredit,
                        copyOfByLawsOrConstitution: !prevState.varValue.copyOfByLawsOrConstitution,
                        listOfExecutiveBody: !prevState.varValue.listOfExecutiveBody,
                        memorandumAndArticleOfAssociation: !prevState.varValue.memorandumAndArticleOfAssociation,
                        boardResolutionOrExtract: !prevState.varValue.boardResolutionOrExtract,
                        photo: !prevState.varValue.photo,
                        nidOrPassport: !prevState.varValue.nidOrPassport,
                        bankStatement: !prevState.varValue.bankStatement,
                        //visitingCard: !prevState.varValue.visitingCard,
                        others: !prevState.varValue.others,
                        merchantApplicatioForm1: !prevState.varValue.merchantApplicatioForm1,
                        merchantApplicatioForm2: !prevState.varValue.merchantApplicatioForm2,
                        merchantApplicatioForm3: !prevState.varValue.merchantApplicatioForm3,
                        merchantApplicatioForm4: !prevState.varValue.merchantApplicatioForm4,
                        merchantApplicatioForm5: !prevState.varValue.merchantApplicatioForm5,
                        merchantApplicatioForm6: !prevState.varValue.merchantApplicatioForm6,
                        merchantApplicatioForm7: !prevState.varValue.merchantApplicatioForm7,
                        merchantApplicatioForm8: !prevState.varValue.merchantApplicatioForm8,
                    }
                }
            })
        }

    };


    functionForSelectAll = () => {
        return (
            <FormControlLabel
                control={<Checkbox checked={this.state.selectAllType} onChange={this.handleChangeForSelectAll}
                                   name="selectAll"/>}
                label="Select/Deselect All"
            />
        )
    };

    render() {
        const {classes} = this.props;

        if (this.state.scanFile) {
            return this.renderMappingImageModal()

        } else {
            return (
                <GridContainer>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loader}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                <h4 style={{color: "white"}} >Scanning File<a><CloseIcon onClick={this.close}
                                                               style={{
                                                                   position: 'absolute',
                                                                   right: 10,
                                                                   color: "#000000"
                                                               }}/></a>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <br/>

                                <ThemeProvider theme={theme}>
                                    {
                                        this.functionForSelectAll()
                                    }
                                    {
                                        this.checkList()
                                    }


                                </ThemeProvider>


                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }


    }

}

export default withStyles(styles)(MerchantIndexing);
