import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import GridItem from "../../../Grid/GridItem";
import CardHeader from "../../../Card/CardHeader";

import GridContainer from "../../../Grid/GridContainer";
import Functions from '../../../../Common/Functions';
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css'
import loader from "../../../../Static/loader.gif";
import Badge from "@material-ui/core/Badge";
import FileRequisitionProcessForm from "./FileRequisitionProcessForm";
import LocalstorageEncrypt from "../../../JsonForm/LocalstorageEncrypt";


let getAllBranchType = [];


let counter = 0;

function createData(index, fileRequisitionId, fileReferenceNo, applicantName, assetOpsNumber, accountNumber, purpose, departmentName, requisitionBy, requisitionDate) {


    return {
        id: index + 1,
        fileRequisitionId,
        fileReferenceNo,
        applicantName,
        assetOpsNumber,
        accountNumber,
        purpose,
        departmentName,
        requisitionBy,
        requisitionDate,
    };
}

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rowsCS = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {id: 'id', numeric: false, disablePadding: true, label: 'ID'},
    {id: 'fileReferenceNo', numeric: false, disablePadding: true, label: 'File Reference No.'},
    {id: 'applicantName', numeric: false, disablePadding: true, label: 'Applicant Name'},
    {id: 'assetOpsNumber', numeric: false, disablePadding: true, label: 'Asset Ops Number'},
    {id: 'accountNumber', numeric: false, disablePadding: true, label: 'Account Number'},
    {id: 'purpose', numeric: false, disablePadding: true, label: 'Purpose'},
    {id: 'departmentName', numeric: false, disablePadding: true, label: 'Department Name'},
    {id: 'requisitionBy', numeric: false, disablePadding: true, label: 'Requisition Initiator'},
    {id: 'requisitionDate', numeric: false, disablePadding: true, label: 'Requisition Date'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];

class TableContentHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;
        return (
            <TableHead>
                <TableRow style={{height: 30, backgroundColor: "#E8E4E3"}}>
                    {rowsCS.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={row.disablePadding ? 'none' : 'default'}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        <b> {row.label}</b>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this,
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

TableContentHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


const styles = theme => ({
    root: {
        width: '100%',
        border: '1'
        //marginTop: theme.spacing.unit * 2,
    },
    table: {
        minWidth: 1020,
    },
    tableCell: {
        padding: 3,
    },
    tableCells: {
        padding: 0,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    container: {
        overflow: 'auto',
        padding: '10px'
    },
    Tab: {
        flexDirection: "row-reverse"
    },
    left: {
        float: 'left',
        width: '200px'
    },

    right: {
        marginLeft: '210px'
    }
});

class FileRequisitionInitiatorInbox extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'id',
        selected: [],
        data: [],
        getInboxCaseData: [],
        page: 0,
        rowsPerPage: 25,
        renderModal: false,
        appUid: '',
        inboxModal: false,
        inboxModalSubmitted: false,
        serviceType: '',
        subserviceType: '',
        jointAccountCustomerNumber: '',
        taskTitle: '',
        freeFlag1: '',
        urgency: '',
        redirectLogin: false,
        selectTab: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        activeItem: 'Sent',
        backgroundColor: 'red',
        category: "",
        delStatus: "",
        subCategory: "",
        solId: "",
        loading: false,
        getCountInbox: 0,
        getCountReturn: 0,
        getCountSubmit: 0,
        getCountPending: 0,
        getCountGroupInbox: 0,
        getCountCertificateInbox: 0,
        getCountCompleteInbox: 0,
        fileReferenceNo: '',
        loanType: '',
        applicantName: '',
        assetOpsNumber: '',
        accountNumber: '',
        closingDate: '',
        location: '',
        trunkOrBoxNumber: '',
        status: '',
        customerCB: '',
        loanAccounts: '',
        NOCStatus: '',
        fileDestroyStatus: '',
        docType: '',
        source: '',
        deferralDuration: '',
        deferralDetails: '',
        docTypeEntryCapacity: '',
        sourceEntryCapacity: '',
        requisitionBy: '', requisitionDate: '', purpose: '', departmentName: '', fileRequisitionId: '',


        fileName: 'FileRequisitionInitiator',

    };

    componentDidMount() {


        this.setState({
            loading: true
        });

        const data = [];
        let url = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "sent";

        axios.get(url, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                response.data.map((message, index) => {

                    data.push(createData(index, message.fileRequisitionId, message.fileReferenceNo, message.applicantName, message.assetOpsNumber, message.accountNumber, message.purpose, message.departmentName, message.requisitionBy, message.requisitionDate));

                });

                this.setState({
                    getData: true,
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({order, orderBy});
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };


    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    viewCase = (fileRequisitionId, fileReferenceNo, applicantName, assetOpsNumber, accountNumber, purpose, departmentName, requisitionBy, requisitionDate) => {

        this.setState({
            inboxModal: true,
            fileRequisitionId: fileRequisitionId,
            fileReferenceNo: fileReferenceNo,

            applicantName: applicantName,
            assetOpsNumber: assetOpsNumber,
            accountNumber: accountNumber,
            purpose: purpose,
            departmentName: departmentName,
            requisitionBy: requisitionBy,
            requisitionDate: requisitionDate
        })

    };

    closeModal = () => {
        this.setState({
            inboxModal: false,
            inboxModalSubmitted: false,
            loading: true


        });
        let urls = "";
        if (this.state.activeItem === "Requisition") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "requisition";
        } else if (this.state.activeItem === "Sent") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "sent";
        } else if (this.state.activeItem === "Pending") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (this.state.activeItem === "Received by Initiator") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "received";
        } else if (this.state.activeItem === "In Archive") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returnReceived";

        } else if (this.state.activeItem === "In Archive After NOC") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returnReceivedNOC";

        } else if (this.state.activeItem === "Returned by Initiator") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returned";
        } else if (this.state.activeItem === "Group Inbox") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "ADC") {

                urls = backEndServerURL + '/workspaceClaimable';
            } else {
                urls = backEndServerURL + '/claimable';
            }

        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(response.data);
                response.data.map((message, index) => {

                    data.push(createData(index, message.fileRequisitionId, message.fileReferenceNo, message.applicantName, message.assetOpsNumber, message.accountNumber, message.purpose, message.departmentName, message.requisitionBy, message.requisitionDate));

                });

                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };


    renderInboxCase = () => {
        //alert("please view component Add")
        //Api Call

        console.log(this.state.category);

        return (
            <FileRequisitionProcessForm closeModal={this.closeModal}
                                        activeItem={this.state.activeItem}
                                        fileName={this.state.fileName}
                                        fileRequisitionId={this.state.fileRequisitionId}
                                        fileReferenceNo={this.state.fileReferenceNo}
                                        applicantName={this.state.applicantName}
                                        assetOpsNumber={this.state.assetOpsNumber}
                                        accountNumber={this.state.accountNumber}
                                        purpose={this.state.purpose}
                                        departmentName={this.state.departmentName}
                                        requisitionBy={this.state.requisitionBy}
                                        requisitionDate={this.state.requisitionDate}

            />
        )

    };
    updateComponent = () => {
        this.forceUpdate(


        )
    };


    handleItemClick = (e, {name, style}) => {
        this.setState({
            data: [],
            loading: true,
            getInboxCaseData: []
        });
        this.setState({
                activeItem: name,
                backgroundColor: style
            }
        );
        let urls = ""; //my 5 tab urls go here, the names
        if (name === "Requisition") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "requisition";
        } else if (name === "Sent") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "sent";
        } else if (name === "Pending") {
            urls = backEndServerURL + '/inbox/saved';
        } else if (name === "Received by Initiator") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "received";
        } else if (this.state.activeItem === "In Archive") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returnReceived";

        } else if (this.state.activeItem === "In Archive After NOC") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returnReceivedNOC";

        } else if (name === "Returned by Initiator") {
            urls = backEndServerURL + "/archiveFile/showStatusArchiveManager/" + "returned";
        } else if (name === "Group Inbox") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") == "ADC") {

                urls = backEndServerURL + '/workspaceClaimable';
            } else {
                urls = backEndServerURL + '/claimable';
            }

        }

        let data = [];
        axios.get(urls, {
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                console.log(name);
                console.log(response.data);


                response.data.map((message, index) => {

                    data.push(createData(index, message.fileRequisitionId, message.fileReferenceNo, message.applicantName, message.assetOpsNumber, message.accountNumber, message.purpose, message.departmentName, message.requisitionBy, message.requisitionDate));

                });


                this.setState({
                    data: data,
                    loading: false,
                    getInboxCaseData: data
                });


            })

            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };

    renderInboxTabShow = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("INBOX") !== -1) {
            return (
                <Menu.Item
                    name='Inbox'
                    active={this.state.activeItem === 'Inbox'}
                    style={this.state.activeItem === 'Inbox' ? {
                        backgroundColor: "red",
                        color: "white",

                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge badgeContent={this.state.getCountInbox} color="primary">
                        <div style={{marginTop: "8px"}}>
                            Inbox
                        </div>
                    </Badge>
                </Menu.Item>
            )
        }
    };
    renderRequisitionTabShow = () => {

        return (
            <Menu.Item
                name='Requisition'
                active={this.state.activeItem === 'Requisition'}
                style={this.state.activeItem === 'Requisition' ? {
                    backgroundColor: "red",
                    color: "white",

                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge badgeContent={this.state.getCountReturn} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Requisition
                    </div>
                </Badge>

            </Menu.Item>
        )

    };
    renderSentTabShow = () => {

        return (
            <Menu.Item
                name='Sent'
                active={this.state.activeItem === 'Sent'}
                style={this.state.activeItem === 'Sent' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge badgeContent={this.state.getCountPending} color="primary">
                    <div style={{marginTop: "8px"}}>
                        Sent
                    </div>
                </Badge>
            </Menu.Item>
        )

    };
    renderReceivedTabShow = () => {

        return (
            <Menu.Item
                name='Received by Initiator'
                active={this.state.activeItem === 'Received by Initiator'}
                style={this.state.activeItem === 'Received by Initiator' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Received by Initiator
                    </div>
                </Badge>

            </Menu.Item>
        )

    };
    handleChange = (name, value) => {
        this.setState({
            selectTab: value
        })
    };
    renderLockButton = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("GROUP LOCK") !== -1) {
            return (
                <Menu.Item
                    name='Group Inbox'
                    active={this.state.activeItem === 'Group Inbox'}
                    style={this.state.activeItem === 'Group Inbox' ? {
                        backgroundColor: "red",
                        color: "white"
                    } : {backgroundColor: "white", color: "black"}}
                    onClick={this.handleItemClick}
                >
                    <Badge color="primary">
                        <div style={{marginTop: "8px"}}>
                            Group Inbox
                        </div>
                    </Badge>

                </Menu.Item>
            )
        }
    };
    renderReturnedButton = () => {

        return (
            <Menu.Item
                name='Returned by Initiator'
                active={this.state.activeItem === 'Returned by Initiator'}
                style={this.state.activeItem === 'Returned by Initiator' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        Returned by Initiator
                    </div>
                </Badge>


            </Menu.Item>
        )


    };
    renderReturnReceivedButton = () => {

        return (
            <Menu.Item
                name='In Archive'
                active={this.state.activeItem === 'In Archive'}
                style={this.state.activeItem === 'In Archive' ? {
                    backgroundColor: "red",
                    color: "white"
                } : {backgroundColor: "white", color: "black"}}
                onClick={this.handleItemClick}
            >

                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        In Archive
                    </div>
                </Badge>
            </Menu.Item>
        )

    };
    renderTab = (classes) => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing>
                    {this.renderLockButton()}

                    {this.renderSentTabShow()}
                    {this.renderReceivedTabShow()}


                </Menu>


            </div>
        );
    };


    render() {
        const {classes} = this.props;
        const {data, order, orderBy, selected, rowsPerPage, page} = this.state;
        const emptyRows = 0;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>

                <Paper className={classes.root}>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>

                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h5 className={classes.cardTitleWhite}>Archive Team: File Requisition
                                    Process(Initiator)</h5>

                            </CardHeader>
                            <br/>
                            {this.renderTab(classes.Tab)}

                            <div className={classes.tableWrapper}>
                                <Table className={classes.table} aria-labelledby="tableTitle">
                                    <TableContentHead
                                        sty
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={this.handleSelectAllClick}
                                        onRequestSort={this.handleRequestSort}
                                        rowCount={data.length}
                                    />
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        fullScreen={true}
                                        open={this.state.inboxModal}>
                                        <DialogContent>

                                            {this.renderInboxCase()}
                                        </DialogContent>
                                    </Dialog>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>

                                    <TableBody>
                                        {stableSort(data, getSorting(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(n => {
                                                const isSelected = this.isSelected(n.id);
                                                return (
                                                    <TableRow
                                                        hover
                                                        /* onClick={event => this.handleClick(event, n.id)}*/
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.id}
                                                        onClick={() => this.viewCase(n.fileRequisitionId, n.fileReferenceNo, n.applicantName, n.assetOpsNumber, n.accountNumber, n.purpose, n.departmentName, n.requisitionBy, n.requisitionDate)}
                                                        selected={isSelected}
                                                    >
                                                        {/*  <TableCell className={classes.tableCells} align="left">

                                                            </TableCell>*/}
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.id}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell} align="left">
                                                            {n.fileReferenceNo}
                                                        </TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.applicantName}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.assetOpsNumber}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.accountNumber}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.purpose}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.departmentName}</TableCell>
                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.requisitionBy}</TableCell>


                                                        <TableCell className={classes.tableCell}
                                                                   align="left">{n.requisitionDate}</TableCell>


                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{height: 49 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />

                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );
    }


}

FileRequisitionInitiatorInbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileRequisitionInitiatorInbox);