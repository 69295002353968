import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import DebitCardCheckerSplit from "../DeliverableManagement/DebitCard/DebitCardCheckerSplit";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../Static/loader.gif";
import CBNotFound from "../workflow/CASA/CBNotFound";
import SignatureButton from "../workflow/Maintenance/SignatureButton";

let pdfForm = [

];
let Remarks = [
    {
        "varName": "remarksData",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class StampVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapListForStampType:[],
            mapListForQuantity:[],
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            signatureModal: false,
            documentList: [],
            selectedDate: {},
            getDocument: false,
            loading: true,
            cbNotFound: false,
            getData: false
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    getVariables() {
        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log("Variable data");
                console.log("Variable data");
                pdfForm=[];
              let  mapListForStampType=[];
              let  mapListForQuantity=[];
                let data=response.data;
                for (let i = 0; i <data.values.length; i++) {
                    let value = data.values[i].trim();
                    let StampType="stampType"+value;
                    let quantity="quantity"+value;

                    data[StampType]=response.data["stampType"+value];
                    data[quantity]=response.data["quantity"+value];
                    mapListForStampType.push(response.data["stampType"+value]);
                    mapListForQuantity.push(response.data["quantity"+value]);
                    console.log(mapListForStampType);
                    console.log(mapListForQuantity);
                    pdfForm.push(
                        {
                            "varName": "stampType"+value,
                            "type": "select",
                            "enum":[],
                            "readOnly":true,
                            "label": "Stamp Type",

                        },
                        {
                            "varName": "quantity"+value,
                            "type": "text",
                            "label": "Quantity",
                            "readOnly":true

                        }
                    )

                }
                console.log(data)
                this.setState({
                    mapListForStampType:mapListForStampType,
                    mapListForQuantity:mapListForQuantity,
                    varValue: this.copyJson(data),
                    appData: this.copyJson(data),
                    inputData: this.copyJson(data),
                    showValue: true,
                    getData: true,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        console.log("file");
        console.log(this.props.file);
        console.log(this.props.appId);
        if (this.props.appId !== undefined) {
            this.getVariables();
        } else {
            this.setState({
                getData: true
            });
        }
    }

    inboxCase = () => {
        return (
            <DebitCardCheckerSplit
                closeModal={this.props.closeModal}
                documentList={this.state.documentList}
                appId={this.props.appId}
                form={this.renderFormWithData}
            />
        );
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Remarks, this.updateComponent)

            )
        }

    };
    approveHandler = event => {
        event.preventDefault();
        console.log("in the method");
        this.setState({
            loading:true
        })
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, {bom_approval: "APPROVED",remarksData:this.state.inputData.remarksData}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let postData = this.state.inputData;
                postData.appId = this.props.appId;
                postData.mapListForStampType = this.state.mapListForStampType;
                postData.mapListForQuantity = this.state.mapListForQuantity;

                 let routeTaskUrl = backEndServerURL + "/stamprequest/" + this.props.appId;
                axios
                    .post(routeTaskUrl,postData,{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {

                        if (response.status === 200) {
                            this.caseRoute();
                        } else {
                            this.setState({
                                cbNotFound: true,
                                loading:false,
                                title: 'Unable to Save Cheque Book!'
                            });
                        }

                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            loading:false,
                            title: 'Unable to Save Cheque Book!'
                        });
                    });

            })
            .catch(error => {
                this.setState({
                    loading:false,
                });
                console.log(error);
            });
    };


    caseRoute = () => {
        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
        axios
            .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        alert: true,
                        loading:false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                    });
                    this.props.closeModal()
                }
            })
            .catch(error => {
                this.setState({
                    loading:false,
                })
                console.log(error);
            });
    };


    returnHandler = event => {
        event.preventDefault();

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, {bom_approval: "RETURN"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.caseRoute();
            })
            .catch(error => {
                console.log(error);
            });
    };

    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };
    closeModalCBNotFound = () => {
        this.setState({
            signatureModal: false,
            cbNotFound: false
        })
    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            const {classes, onClose} = this.props;
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


            )
        } else {
            return (
                <div>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                pdfForm,
                                this.updateComponent
                            )}
                            {/*<Grid item xs={12}>
                                {this.signature()}
                            </Grid>*/}
                            <Grid item xs={12}>
                                {this.renderRemarks()}
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>

                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>


                    <Grid item xs="3" style={{marginTop: "20px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.returnHandler}
                        >
                            Return
                        </button>
                    </Grid>
                    {
                        this.renderNotification()
                    }
                </div>
            );
        }
    }

}

export default withStyles(styles)(StampVerify);
