import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import CBNotFound from "../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
        required: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
        required: true
    },
];
let reportType = [
    {
        varName: "reportType",
        type: "select",
        enum: [
            "Card Cheque Creation Detail Report",
            "USER WISE CARD CHEQUE ISSUE REPORT",
            "LWF CARD SERVICES REPORT",
        ],
        label: "Report Type",
        grid: 2,
        required: true
    }
];


class CardServiceReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false,
            url: "",
            pageTitle: "",
        };
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    componentDidMount() {
        this.setState({
            pageTitle: "Download Report",
            loading: false
        })
    }

    handleSubmit = () => {
        let error = false;
        if (this.state.inputData.reportType === "USER WISE CARD CHEQUE ISSUE REPORT" || this.state.inputData.reportType === "Card Cheque Creation Detail Report") {
            error = MyValidation.defaultValidation(jsonForm, this.state);
            this.forceUpdate();
        }
        if (error === true) {
            return false
        } else {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            inputData.format = "xlsx";
            let url = "";
            if (this.state.inputData.reportType === "Card Cheque Creation Detail Report") {
                url = backEndServerURL + "/cardChequeCreationDetailsReport";
            } else if (this.state.inputData.reportType === "USER WISE CARD CHEQUE ISSUE REPORT") {
                url = backEndServerURL + "/userWiseCardChequeIssueReport";
            } else if (this.state.inputData.reportType === "LWF CARD SERVICES REPORT") {
                url = backEndServerURL + "/lwfCardServicesReport";
            }
            functionForFileDownload.downloadFile(url, "POST", inputData, "report.xlsx").then((response) => {
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    title: "Something Went Wrong!",
                    cbNotFound: true,
                })
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.requestType !== this.props.match.params.requestType) {
            window.location.reload()
        }
    }

    renderReportType = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(
                this.state,
                reportType,
                this.updateComponent
            )
        )
    };

    renderJsonForm = () => {
        if (this.state.inputData.reportType === "USER WISE CARD CHEQUE ISSUE REPORT" || this.state.inputData.reportType === "Card Cheque Creation Detail Report") {
            return (
                CommonJsonFormComponent.renderJsonForm(
                    this.state,
                    jsonForm,
                    this.updateComponent
                )
            )
        }
    };

    renderButton = () => {
        if (this.state.inputData.reportType === "Card Cheque Creation Detail Report" || this.state.inputData.reportType === "USER WISE CARD CHEQUE ISSUE REPORT" || this.state.inputData.reportType === "LWF CARD SERVICES REPORT") {
            return (
                <button
                    className="btn btn-outline-primary  btn-sm"
                    onClick={this.handleSubmit}
                    style={{
                        height: "25px",
                        marginTop: "19px"
                    }}
                >
                    Submit
                </button>
            )
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >{this.state.pageTitle}</h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderReportType()}
                                {this.renderJsonForm()}
                                &nbsp;&nbsp;
                                {this.renderButton()}
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(CardServiceReport);
