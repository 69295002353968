const {startReturnSubmission} = require("../../Common/Constant");

function makeReadOnlyObject(object) {

    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {

        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;


}
function listOfReturnSubmission(date) {
    /*     let currentDate=new Date();
         let dataList=[];
         for (let i = date; i < currentDate.getFullYear(); i++) {
             dataList.push(date+"-"+Number(date+1));
             i=date+1;
          }*/
    let dataList=[
        "2017-2018",
        "2018-2019",
        "2019-2020",
         "2020-2021",
        "2021-2022",
        "2022-2023",
    ];
    return dataList;


}
let day = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",

];

function makeGridObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        if (returnObjectVariables[i]["type"] === "title") {
            returnObjectVariables[i]["grid"] = 12;

        } else {
            returnObjectVariables[i]["grid"] = 6;

        }
    }
    return returnObject;


}

function makeGridObjectTwo(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        if (returnObjectVariables[i]["type"] === "title") {
            returnObjectVariables[i]["grid"] = 12;

        } else {
            returnObjectVariables[i]["grid"] = 2;

        }
    }
    return returnObject;


}

//#####################FDR#####################################################
//new

let CSJsonFormForFDRServiceNew = [
    /* {
         "varName": "fdValueDate",
         "type": "date",
         "required": true,
           "maxDate": true,
         "label": "FD Value Date",
         "grid": 2,
         "multiline": true
     },*/
    {
        "type": "title",
        "label": "FDR Information:",
        "grid": 12,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "dayAdd": 0,
        "maxDate": true,
        "grid": 2,
        "onKeyDown": true,
        "label": "FD Value Date",
        "required": true
    },


    {
        "varName": "accountType",
        "type": "select",
        "grid": 2,
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label": "Type of FDR Account",
        "conditional": true,
        "conditionalVarName": "accountTypeApiCall",
        "conditionalVarValue": true,

    },
    {
        "varName": "accountAcquisition",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Account Acquisition By",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountAcquisitionApiCall",
        "conditionalVarValue": true,

    },
    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "tenureTypeApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "label": "Tenure",
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "tenureApiCall",
        "conditionalVarValue": true,
    },


    {
        "varName": "autoRenewals",
        "type": "select",
        "enum": [],
        "label": "Auto Renewal ?",
        "grid": 2,
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "autoRenewalsApiCall",
        "conditionalVarValue": true,
    },

    {
        "varName": "maturity",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Maturity /Disposal Instruction",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturityApiCall",
        "conditionalVarValue": true,
    },

    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to","YES"],

    },
    {
        "varName": "repaymentAccount",
        "type": "textApiCall",
        "label": "Repayment Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "repaymentName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity","YES"],
    },

    {
        "varName": "amount",
        "type": "text",
        "wordFromAmount":true,
        "label": "FD Amount",
        "validation": "numeric",
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "amountWithWord",
        "type": "text",
        "label": "FD Amount",
        "readOnly": true,
         "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "amountWithWordApiFlag"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "multiline": true,
        "readOnly": true,
        "label": "FD Scheme Code",
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,
        "grid": 4,
    },
    {
        "varName": "interestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "preventialRateChange",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Preferential Rate Applicable",
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "label": "FD Maturity Date",
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "readOnly": true,
        "label": "E-TIN",
        "grid": 2,
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },

    {
        "varName": "taxWaiverChange",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Tax Waiver Applicable",
        "grid": 2,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Period Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required": true,
        "label": "RM Code",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "conditional": true,
        "conditionalVarName": "rmCodeApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },

    {
        "varName": "nomineeType",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
];
let CSJsonFormForFDRService = [
    /* {
         "varName": "fdValueDate",
         "type": "date",
         "required": true,
           "maxDate": true,
         "label": "FD Value Date",
         "grid": 2,
         "multiline": true
     },*/
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit Account Name",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly": true,
        "label": "Available Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitAccountNumberApiCall", "availableAmountShow"],
        "conditionalArrayValue": ["YES", true],
        "readonly": true
    },
    /*  {
          "varName": "accountRestriction",
          "type": "text",
          "readOnly": true,
          "label": "Account Restriction",
          "grid": 2,
          "multiline": true,
          "conditional": true,
          "conditionalVarName": "debitAccountNumberApiCall",
          "conditionalVarValue": "YES",

      },*/
    {
        "varName": "currency",
        // "type": "text",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Currency",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "FDR Information:",
        "grid": 12,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "dayAdd": 0,
        "grid": 2,
        "maxDate": true,
        "onKeyDown": true,
        "label": "FD Value Date",
        "required": true
    },


    {
        "varName": "accountType",
        "type": "select",
        "grid": 2,
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label": "Type of FDR Account",
        "conditional": true,
        "conditionalVarName": "accountTypeApiCall",
        "conditionalVarValue": true,

    },
    {
        "varName": "accountAcquisition",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Account Acquisition By",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountAcquisitionApiCall",
        "conditionalVarValue": true,

    },
    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "label": "Tenure Type",
        "required": true,
        "conditional": true,
        "conditionalVarName": "tenureTypeApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "label": "Tenure",
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "tenureApiCall",
        "conditionalVarValue": true,
    },


    {
        "varName": "autoRenewals",
        "type": "select",
        "enum": [],
        "label": "Auto Renewal ?",
        "grid": 2,
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "autoRenewalsApiCall",
        "conditionalVarValue": true,
    },

    {
        "varName": "maturity",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Maturity /Disposal Instruction",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturityApiCall",
        "conditionalVarValue": true,
    },

    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to","YES"],

    },
    {
        "varName": "repaymentAccount",
        "type": "textApiCall",
        "label": "Repayment Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "repaymentName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity","YES"],
    },
    {
        "varName": "amount",
        "type": "text",
        "wordFromAmount":true,
        "label": "FD Amount",
        "validation": "numeric",
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,

    },
    {
        "varName": "amountWithWord",
        "type": "text",
        "label": "FD Amount In Word",
        "readOnly": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "amountWithWordApiFlag"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "multiline": true,
        "readOnly": true,
        "label": "FD Scheme Code",
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,
        "grid": 4,
    },
    {
        "varName": "interestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "preventialRateChange",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "required":true,
        "label": "Preferential Rate Applicable",
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "label": "FD Maturity Date",
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN",
        "grid": 2,
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },

    {
        "varName": "taxWaiverChange",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Tax Waiver Applicable",
        "grid": 2,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Period Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required": true,
        "label": "RM Code",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "conditional": true,
        "conditionalVarName": "rmCodeApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },

    {
        "varName": "nomineeType",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
];

let CSJsonFormForFDRServiceForExistCbOnly = [

    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "FDR Information:",
        "grid": 12,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "dayAdd": 0,
        "grid": 2,
        "maxDate": true,
        "onKeyDown": true,
        "label": "FD Value Date",
        "required": true
    },


    {
        "varName": "accountType",
        "type": "select",
        "grid": 2,
        "required": true,
        "enum": [],
        "onKeyDown": true,
        "label": "Type of FDR Account",
        "conditional": true,
        "conditionalVarName": "accountTypeApiCall",
        "conditionalVarValue":true,

    },
    {
        "varName":"accountAcquisition",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label":"Account Acquisition By",
        "grid":2,
        "conditional": true,
        "conditionalVarName": "accountAcquisitionApiCall",
        "conditionalVarValue":true,

    },
    /////Tenure Type ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "tenureTypeApiCall",
        "conditionalVarValue":true,
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "label": "Tenure",
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "tenureApiCall",
        "conditionalVarValue":true,
    },


    {
        "varName": "autoRenewals",
        "type": "select",
        "enum": [],
        "label": "Auto Renewal ?",
        "grid": 2,
        "required": true,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "autoRenewalsApiCall",
        "conditionalVarValue":true,
    },

    {
        "varName": "maturity",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Maturity /Disposal Instruction",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturityApiCall",
        "conditionalVarValue":true,
    },

    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to","YES"],

    },
    {
        "varName": "repaymentAccount",
        "type": "textApiCall",
        "label": "Repayment Account",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "repaymentName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity","YES"],
    },

    {
        "varName": "amount",
        "type": "text",
        "wordFromAmount":true,
        "label": "FD Amount",
        "validation": "numeric",
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "amountWithWord",
        "type": "text",
        "label": "FD Amount In Word",
        "readOnly": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["fdrSchemeCodeApiCall", "amountWithWordApiFlag"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "fdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "multiline":true,
        "readOnly": true,
        "label": "FD Scheme Code",
        "required": true,
        "conditional": true,
        "conditionalVarName": "fdrSchemeCodeApiCall",
        "conditionalVarValue": true,
        "grid": 4,
    },
    {
        "varName": "interestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "preventialRateChange",
        "type": "select",
        "enum":[
            "Y",
            "N"
        ],
        "label": "Preferential Rate Applicable",
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "label": "FD Maturity Date",
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN",
        "grid": 2,
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },


    {
        "varName": "taxWaiverChange",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Tax Waiver Applicable",
        "grid": 2,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Period Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 2,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required": true,
        "label": "RM Code",
        "grid": 2,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "conditional": true,
        "conditionalVarName": "rmCodeApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },

    {
        "varName": "nomineeType",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account",
    },
];
let CSJsonFormForFDRServiceLast = [
    {
        "type": "title",
        "label": "Dependent Request Marking",
        "grid": 12,
    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    }, {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 2
    }, {
        "varName": "letterOfCredit",
        "type": "checkbox",
        "label": "Letter Of Credit",
        "grid": 2,

    },
];
let CSJsonFormForFDRServiceAllNew = CSJsonFormForFDRServiceNew.concat(CSJsonFormForFDRServiceLast);
let CSJsonFormForFDRServiceAllCbOnly = CSJsonFormForFDRServiceForExistCbOnly.concat(CSJsonFormForFDRServiceLast);
let CSJsonFormForFDRServiceAll = CSJsonFormForFDRService.concat(CSJsonFormForFDRServiceLast);
let CSJsonFormForCasaIndividualFdr = [
    {
        "type": "title",
        "label": "De-Dupe Data",
        "grid": 12,
    },
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "label": "EQM Ticket Number",
        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "agentBankingFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "showCBWithoutNTB",
        "conditionalVarValue": true,
        "multiline": true
    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "Customer Name",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "nid",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        //"validation": "nid",
        "label": "NID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,

    },
    {
        "varName": "smartCard",
        "type": "text",
        "errorMessage": "Error",
        "readOnly": true,
        //"validation": "smartCard",
        "label": "Smart Card",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "passport",
        "type": "text",
        "readOnly": true,
        "label": "Passport",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "readOnly": true,
        //"validation": "birthCertificateNumber",
        "label": "Birth Cer.",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "tin",
        "type": "text",
        "readOnly": true,
        "label": "E-Tin",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Designated Mobile Type *",
        "readOnly":true,
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Designated Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "phone",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Designated Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },
    // {
    //     "varName": "phone",
    //     "type": "text",
    //     "readOnly": true,
    //     "validation": "phone",
    //     "phonePrefix": true,
    //     "label": "Mobile Number",
    //     "grid": 2,
    // },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ",
        "readOnly": true,
        "validation": "email",
        "grid": 2,
        "multiline": true
    },
    {
        "varName": "dob",
        "type": "date",
        "readOnly": true,
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },

    {
        "varName": "nationality",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "label": "Nationality",
        "grid": 2,
    },


    {
        "type": "empty",
        "grid": 12,
    }, {
        "type": "title",
        "label": "CB Creation (Min. Data)",
        "grid": 12,
    },
    {
        "varName": "businessSegement",
        "type": "select",
        "enum": [
            "CONVENTIONAL",
            "ISLAMIC",
        ],
        "label": "Business Segement",
        "grid": 2,
    },
    {
        "varName": "customerStatus",
        "type": "select",
        "label": "Customer Status ",
        "grid": 2,
        "required": true,
        "errorMessage": "Error",
        "enum": [
            "Normal Customer",
            "Staff",
            "Priority",
        ],
    },
    {
        "varName": "customerTitle",
        "type": "select",
        "enum": [
            "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS.",
            "JOINT",
        ],
        "required": true,
        "label": "Customer Title ",
        "grid": 2,

    },
    {
        "varName": "shortName",
        "type": "text",
        "multiline": true,
        "validation": "string",
        "required": true,
        "label": "A/C Short Name",
        "grid": 2,

    },
    {
        "varName": "gender",
        "type": "select",
        "required": true,
        "enum": [
            "M",
            "F",
            "O",
        ],
        "label": "Gender",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "isDedupIndividual",
        "conditionalVarValue": true,
    },
    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 2,
    },
    {
        "varName": "staffIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator",
        "required":true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "staffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "required":true,
        asterisk: true,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Staff",
        "grid": 2,

    },
    {
        "varName": "SelectCommunicationAddress",
        "type": "select",
        "enum": [
            "Present",
            "Permanent",
            "Office",
        ],
        "required": true,
        "label": "Select Communication Address ",
        "grid": 2
    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "required": true,
        "label": "Communication Address 1",
        "grid": 3,
        "multiline": true
    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address 2",
        "grid": 3,
        "multiline": true
    },
    // {
    //     "varName": "mobileTypeIndividual",
    //     "type": "select",
    //     "enum": [
    //         "Local",
    //         "Overseas",
    //     ],
    //     // "required":true,
    //     "label": "Designated Mobile Type *",
    //     "readOnly":true,
    //     "grid": 2,
    // },
    // {
    //     "varName": "countryCodeIndividual",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "label": "Designated Mobile Country Code",
    //     "asteriks": true,
    //     "conditional": true,
    //     "readOnly":true,
    //     "conditionalVarName": "mobileTypeIndividual",
    //     "conditionalVarValue": "Overseas",
    //     "grid": 2,
    // },
    // {
    //     "varName": "designatedMobileNumber",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "Designated Mobile Number ",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "mobileTypeIndividual",
    //     "conditionalVarValue": "Overseas",
    //
    // },
    //
    //
    // {
    //     "varName": "phone",
    //     "type": "text",
    //     // "validation": "phone",
    //     "phonePrefix": true,
    //     "readOnly": true,
    //     "label": "Designated Mobile Number ",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "mobileTypeIndividual",
    //     "conditionalVarValue": "Local",
    //
    // },
    {
        "varName": "city",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "state",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division (State)",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "postalCode",
        "type": "text",
        "required": true,
        "validation": "numeric",
        "label": "Postal Code",
        "grid": 2,
        "multiline": true
    },

    {
        "varName": "country",
        "required": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 2,
    },
    {
        "varName": "mobileTypeIndividual2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Second Mobile Type",
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Second Mobile Country Code",
        "asteriks": true,
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "phone2",
        "type": "text",
        "validation": "phone",
        "phonePrefix": true,
        "label": "Mobile Number 2",
        // "required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Local",
        "grid": 2,


    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Mobile Number 2",
        //"required":true,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual2",
        "conditionalVarValue": "Overseas",
        "grid": 2,


    },
    {
        "varName": "currency",
        "label": "Currency",
        "required":true,
        "grid": 2,
        "type": "autoCompleteValueReturn",
        "enum": [],
    },
    {
        "varName": "priorityCenterCode",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        "errorMessage": "Error",
        "label": "Priority Center Code",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRMCode",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Code",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "monitoringRmCodeName",
        "type": "textApiCall",
        "errorMessage": "Error",
        //"validation":"rmCode", //no need
        "label": "Monitoring RM Name",
        "readOnly":true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["customerStatus", "monitoringRMCodeApiCall"],
        "conditionalArrayValue": ["Priority", true],
    },
    {
        "varName": "primaryCBHolderforPriority",
        "type": "text",
        "errorMessage": "Error",
        "label": "Primary CB Holder for Priority",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    {
        "varName": "relationshipwithPrimaryCBHolder",
        "type": "text",
        "errorMessage": "Error",
        "label": "Relationship with Primary CB Holder",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "customerStatus",
        "conditionalVarValue": "Priority",
    },
    // {
    //     "varName": "city",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "label": "City",
    //     "required": true,
    //     "grid": 2,
    // },
    // {
    //     "varName": "state",
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "label": "State",
    //     "required": true,
    //     "grid": 2,
    // },
    // {
    //     "varName": "postalCode",
    //     "type": "text",
    //     "required": true,
    //     "validation": "numeric",
    //     "label": "Postal Code",
    //     "grid": 2,
    //     "multiline": true
    // },
    //
    // {
    //     "varName": "country",
    //     "required": true,
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    //     "label": "Country",
    //     "grid": 2,
    // },
    // {
    //     "varName": "currency",
    //     "label": "Currency",
    //     "required": true,
    //     "grid": 2,
    //     "type": "autoCompleteValueReturn",
    //     "enum": [],
    // },
    // {
    //     "varName": "occupationCode",
    //     "type": "select",
    //     "enum": [],
    //     "label": "Occupation Code",
    //     "grid": 2,
    // },
    // {
    //     "varName": "relationshipwithPrimaryCBHolder",
    //     "type": "text",
    //     "errorMessage": "Error",
    //     "label": "Relationship with Primary CB Holder",
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "customerStatus",
    //     "conditionalVarValue": "Priority",
    // },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is NID/Smart Card Document Verified?",
        "grid": 2,

    },
    {
        "varName": "introducerID",
        "type": "textApiCall",
        "required": true,
        "label": "Introducer ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "varName": "introducerName",
        "type": "text",
        "multiline": true,
        "readOnly": true,
        "label": "Introducer Name",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIDApiCall", "nidVerified"],
        "conditionalArrayValue": ["YES", "NO"]
    },
    /* {
         "varName": "cityAlo",
         "type": "select",
         "enum":[
             "YES",
             "NO",
         ],
         "label": "City Alo",
         "grid": 2,
     },*/


];
let CSJsonFormForCasaIndividualFdrLast = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Debit Instruction",
        "grid": 12,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,

    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit Account Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "transactionID",
        "type": "text",
        "label": "Transaction ID/Cheque Number",
        "grid": 2,
    },
    {
        "varName": "transactionDate",
        "type": "date",
        "maxDate": true,
        "label": "Transaction Date",
        "grid": 2,
    },
    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly": true,
        "label": "Available Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitAccountNumberApiCall", "availableAmountShow"],
        "conditionalArrayValue": ["YES", true],

    },


    /*{
        "varName": "fdValueDate",
        "type": "date",
        "required": true,
        "label": "State",
        "grid": 2,
        "multiline": true
    },  {
        "varName": "schemeCode",
        "type": "text",
        "required": true,
        "label": "Scheme Code",
        "grid": 2,
    },*/

    {
        "varName": "currency",
        "label": "Currency",
        "grid": 2,
        "readOnly": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },

]
let BMJsonFormForCasaIndividualFdr = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualFdr.concat(CSJsonFormForCasaIndividualFdrLast, CSJsonFormForFDRServiceAllNew))));
let BOMJsonFormForCasaIndividualFdr = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaIndividualFdr.concat(CSJsonFormForCasaIndividualFdrLast, CSJsonFormForFDRServiceAllNew)))));

let CSJsonFormForCasaIndividualFdrReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObjectTwo((CSJsonFormForCasaIndividualFdr)))));

//new But Exist CB
let CSJsonFormForNewWithExistCB = [
    {
        "type": "title",
        "label": "CB Information",
        "grid": 12,
    },
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "label": "EQM Ticket Number",

        "grid": 2,
    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
     {
        "varName": "freeCode1SpecialTaxagentOutlet",
         "type": "autoCompleteValueReturn",
         "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "agentBankingFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "CB Name",
        "grid": 2,
        "multiline": true,


    },
    {
        "varName": "nid",
        "type": "text",
        "readOnly": true,
        "errorMessage": "Error",
        "label": "NID",
        "grid": 2,

    }, {
        "varName": "passport",
        "type": "text",
        "readOnly": true,
        "label": "Passport",
        "grid": 2,


    },
    {
        "varName": "registrationNo",
        "type": "text",
        "readOnly": true,
        "label": "Birth Certificate",
        "grid": 2,

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "readOnly": true,
        "label": "Driving License",
        "grid": 2,

    },
    //
    // /*  {
    //       "varName": "mobileNumber",
    //       "type": "text",
    //       "readOnly": true,
    //       "phonePrefix": true,
    //       "label": "Mobile Number",
    //       "grid": 2,
    //
    //   },*/
    {
        "varName": "mobileTypeIndividual",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        // "required":true,
        "label": "Mobile Type *",
        "readOnly":true,
        "grid": 2,
    },
    {
        "varName": "countryCodeIndividual",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Mobile Country Code",
        "asteriks": true,
        "conditional": true,
        "readOnly":true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",
        "grid": 2,
    },
    {
        "varName": "mobileNumber",
        "type": "text",
        "readOnly": true,
        "label": "Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "mobileNumber",
        "type": "text",
        // "validation": "phone",
        "phonePrefix": true,
        "readOnly": true,
        "label": "Mobile Number ",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "mobileTypeIndividual",
        "conditionalVarValue": "Local",

    },

    {
        "varName": "email",
        "type": "text",
        "readOnly": true,
        "label": "Email ",
        "grid": 2,
        "multiline": true,

    },
    {
        "varName": "communicationAddress1",
        "type": "text",
        "readOnly": true,
        "label": "Communication Address 1",
        "grid": 2,
        "multiline": true,

    },
    {
        "varName": "communicationAddress2",
        "type": "text",
        "readOnly": true,
        "label": "Communication Address 2",
        "grid": 2,
        "multiline": true,

    },
    {
        "varName": "city",
        "type": "autoCompleteValueReturn",
        "readOnly": true,
        "enum": [],
        "label": "City",
        "grid": 2,


    },
    {
        "varName": "state",
        "readOnly": true,
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "State",
        "grid": 2,

    },
    {
        "varName": "postalCode",
        "type": "text",
        "readOnly": true,
        "label": "Postal Code",
        "grid": 2,
        "multiline": true,

    },
    {
        "varName": "country",
        "label": "Country",
        "readOnly": true,
        "type": "autoCompleteValueReturn",
        "enum": [],


        "grid": 2,
    },
    /* {
         "varName": "comAddress",
         "type": "text",

         "label": "Communication Address 1",
         "grid": 2,
         "multiline": true
     }, {
         "varName": "comAddress2",
         "type": "text",
         "label": "Communication Address 2",
         "grid": 2,
         "multiline": true
     }, {
         "varName": "city",
         "type": "text",
         "label": "City",
         "grid": 2,
         "multiline": true
     },  {
         "varName": "post",
         "type": "text",
         "label": "Postal Code",
         "grid": 2,
         "multiline": true
     },

     {
         "varName": "country",
         "type": "select",

         "enum": [
             "Bangladesh",
             "Japan",
             "Other",
         ],
         "label": "Country",
         "grid": 2,
     },
     {
         "varName": "occupationCode",
         "type": "text",
         "label": "Occupation Code",
         "grid": 2,
     },
     {
         "varName": "introducerID",
         "type": "text",
         "label": "Introducer ID",
         "grid": 2,
     },
     {
         "varName": "introducerName",
         "type": "text",
         "label": "Introducer Name",
         "grid": 2,
     },
     {
         "varName": "cityAlo",
         "type": "text",
         "label": "City Alo",
         "grid": 2,
     },*/
    /* {
         "varName": "cityAlo",
         "type": "text",
         "label": "City Alo",
         "grid": 2,
     },*/
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Debit Instruction",
        "grid": 12,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit Account Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "transactionID",
        "type": "text",
        "label": "Transaction ID/Cheque Number",
        "grid": 2,
    },
    {
        "varName": "transactionDate",
        "type": "date",
        "maxDate": true,
        "label": "Transaction Date",
        "grid": 2,
    },
    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly": true,
        "label": "Available Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitAccountNumberApiCall", "availableAmountShow"],
        "conditionalArrayValue": ["YES", true],
    },
    {
        "varName": "currency",
        "label": "Currency",
        "readOnly": true,
        "grid": 2,
        "type": "text",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
];
let BMJsonFormForNewWithExistCB = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForNewWithExistCB.concat(CSJsonFormForFDRServiceAllCbOnly))));
let BOMJsonFormForNewWithExistCB = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject((CSJsonFormForNewWithExistCB.concat(CSJsonFormForFDRServiceAllCbOnly))))));

let CSJsonFormForCasaIndividualTagFdr = [
    {
        "type": "title",
        "label": "CB Information",
        "grid": 12,
    },
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "label": "EQM Ticket Number",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
     {
        "varName": "freeCode1SpecialTaxagentOutlet",
         "type": "autoCompleteValueReturn",
         "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "agentBankingFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "readOnly": true,
        "label": "CB Number",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "customerName",
        "type": "text",
        "readOnly": true,
        "label": "CB Name",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "cbNumberApiCall",
        "conditionalVarValue": "YES",

    },
    /*{
        "varName": "accountSource",
        "type": "select",
        "label": "Account Source",
        "enum": [
            "CONVENTIONAL",
            "ISLAMIC",
        ],
        "grid":
         2,
    },*/
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "Debit Instruction",
        "grid": 12,
    },
    {
        "varName": "debitAccountNumber",
        "type": "text",
        "readOnly": true,
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "conditionalNewButton",
        "conditionalVarValue":false,

    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown": true,
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "conditionalNewButton",
        "conditionalVarValue": true,

    },

    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
    },


    /* {
         "varName": "businessSegment",
         "type": "select",
         "enum": [
             "CONVENTIONAL",
             "ISLAMIC",
         ],
         "label": "Business Segment",
         "grid": 2,
     },*/

];
let CSJsonFormForCasaIndividualTagFdr2 = [


    {
        "varName": "accountType",
        "type": "select",
        "grid": 2,
        "enum": [
            "FDR",
            "Double Money FD Account",
            "Monthly Interest Paying FD",
        ],
        "label": "Type Of Account",

    },

    ///// Account Type Double ///
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [
            "1 Month",
            "3 Month",
            "6 Month",
            "12 Month",
            "24 Month",
            "36 Month",

        ],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["tenureType", "accountType"],
        "conditionalArrayValue": ["Monthly", "Double Money FD Account"]

    },
    {
        "varName": "doubleAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "doubleInterest",
        "type": "text",
        "label": "Interest Rate",
        "grid": 2,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "doubleSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "No"
        ],
        "label": "Renewal / Maturity Instruction",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "FD Value Date",
        "maxDate": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },
    {
        "varName": "fdMaturityDate",
        "type": "date",
        "label": "FD Maturity Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Double Money FD Account",
    },

    /////  Account Type Fix General /////
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Day",
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [
            "1 Month",
            "3 Month",
            "6 Month",
            "12 Month",
            "24 Month",
            "36 Month",

        ],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["tenureType", "accountType"],
        "conditionalArrayValue": ["Monthly", "FDR"]

    },
    {
        "varName": "fixGAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "fixGInterest",
        "type": "text",
        "label": "Interest Rate",
        "grid": 2,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "fixGSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
            "No",
            "Encash at Maturity to my / Our Account No",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "fixdGMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No",
            "Renew Both Principal and Interest"
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "fixGSiMaturity",
        "conditionalVarValue": "Yes",
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "FD Value Date",
        "maxDate": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },
    {
        "varName": "fdMaturityDate",
        "type": "date",
        "label": "FD Maturity Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "FDR",
    },

    ///////  Month Tenor /////
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [
            "Monthly"
        ],
        "label": "Tenure Type",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [
            "1 Month",
            "3 Month",
            "6 Month",
            "12 Month",
            "24 Month",
            "36 Month",

        ],
        "label": "Tenure",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["tenureType", "accountType"],
        "conditionalArrayValue": ["Monthly", "Monthly Interest Paying FD"]

    },
    {
        "varName": "monthAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "monthInterest",
        "type": "text",
        "label": "Interest Rate",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "monthSiMaturity",
        "type": "select",
        "grid": 2,
        "enum": [
            "Yes",
        ],
        "label": "Renewal / Maturity Instruction",
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "monthMaturityYes",
        "type": "select",
        "label": "Maturity Yes",
        "enum": [
            "Renew Principal Only and Credit Interest to the Account No"
            // "Renew Principal Only "
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "FD Value Date",
        "maxDate": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    },
    {
        "varName": "fdMaturityDate",
        "type": "date",
        "label": "FD Maturity Date",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountType",
        "conditionalVarValue": "Monthly Interest Paying FD",
    }, {
        "varName": "priority",
        "type": "select",
        "required": true,
        "label": "Priority",
        "enum": [
            "GENERAL",
            "HIGH",
        ],
        "grid": 2,
    },
    /////////
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    },
    {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 2
    },
    {
        "varName": "letterOfCredit",
        "type": "checkbox",
        "label": "Letter Of Credit",
        "grid": 2,

    }
];
let MAKERJsonFormForKyc = [
    {
        "type": "title",
        "label": "KYC",
        "grid": 12,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": " SOL ID",
        "grid": 6,
    },
    {
        "varName": "customerID",
        "type": "text",
        "label": "Customer ID",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "accountOpenDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Open Date",
        "grid": 6,
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
    },
    {
        "varName": "interestCreditAccountID",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "interestCreditAccountApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withHoldingTax%",
        "type": "text",
        "label": "WithHolding Tax%",
        "grid": 6,
    },
    {
        "varName": "deposit/InstallAmount",
        "type": "text",
        "wordFromAmount":true,
        "label": "Deposit/Installment Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "deposit/InstallAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "deposit/InstallAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },

    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value Date",
        "maxDate": true,
        "grid": 6,
    },
    {
        "varName": "valueDate",
        "type": "text",
        "label": "Payment A/C ID",
        "grid": 6,
    },
    {
        "varName": "autoClosure",
        "type": "text",
        "label": "AUTO. CLOSURE ?",
        "grid": 6,
    },
    {
        "varName": "autoRenewal",
        "type": "text",
        "label": "AUTO. RENEWAL ?",
        "grid": 6,
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee's Names",
        "grid": 6,
    },
    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
    },
    {
        "varName": "nomeeRegNo",
        "type": "text",
        "label": "REG. NO",
        "grid": 6,
    },
    {
        "varName": "nomeeAddress",
        "type": "text",
        "label": "Address",
        "grid": 6,
    },
    {
        "varName": "nomeeCityCode",
        "type": "text",
        "label": "City Code",
        "grid": 6,
    },
    {
        "varName": "nomeeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
    },
    {
        "varName": "nomeeCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": 6,
    },
    {
        "varName": "nomeePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },
    {
        "varName": "nomeeDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": 6,
    },
    {
        "varName": "guardianName",
        "type": "text",
        "label": "Guardian Name",
        "grid": 6,
    },
    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
    },
    {
        "varName": "guardianAddress",
        "type": "text",
        "label": "Guardian Address",
        "grid": 6,
    },
    {
        "varName": "guardianCityCode",
        "type": "text",
        "label": "City Code",
        "grid": 6,
    },
    {
        "varName": "guardianStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
    },
    {
        "varName": "guardianCountryCode",
        "type": "text",
        "label": "Country Code",
        "grid": 6,
    },
    {
        "varName": "guardianPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },
    {
        "varName": "guardianSectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "guardianSubSectorCode",
        "type": "text",
        "label": " Sub-Sector Code",
        "grid": 6,
    },
    {
        "varName": "guardianOccupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "freeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7 (Deposit Type Code)",
        "grid": 6,
    },
    {
        "varName": "trancreationDuring",
        "type": "text",
        "label": "Tran Creation During(O/V)",
        "grid": 6,
    },
    {
        "varName": "fundingAccount",
        "type": "text",
        "label": "Funding A/C",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C NO.",
        "grid": 6,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "grid": 6,
    },
    {
        "varName": "nomineeAge",
        "type": "text",
        "label": "Nominee Age",
        "grid": 6,
    },
    {
        "varName": "nomShare",
        "type": "text",
        "label": "NOM Share (%)",
        "grid": 6,
    },
    {
        "varName": "nomRelation",
        "type": "text",
        "label": "NOM Relation(LIST)",
        "grid": 6,
    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",
        "grid": 6,
    },
    {
        "varName": "city",
        "type": "text",
        "label": "City(List)",
        "grid": 6,
    },
    {
        "varName": "state",
        "type": "text",
        "label": "State(List)",
        "grid": 6,
    },
    {
        "varName": "accountID",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
    },
    {
        "varName": "relationType",
        "type": "text",
        "label": "Relation Type",
        "grid": 6,
    },
    {
        "varName": "relationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": 6,
    },
    {
        "varName": "relationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": 6,
    },
    {
        "varName": "customerId",
        "type": "text",
        "label": "CUST. ID",
        "grid": 6,
    },
];
let CheckerJsonFormForKyc = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForKyc)));
let makeFdrForm = (JSON.parse(JSON.stringify([
    {
        "label": "FDR Opening Form",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "maxDate": true,
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,


    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure ?",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal ?",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": " Sub-Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    }
])));
let MAKERJsonFormForNewFdrForm = [

    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
//Sd part
    {
        "type": "title",
        "label": "Fixed Deposit Account Opening",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "General Info",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "readOnly": true,
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCY",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },
    {
        "varName": "shortName",
        "type": "text",
        "label": "A/c.  SHORT NAME",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Opening Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Interest And Tax",
        "grid": 12,
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
    },
    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },
    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", "YES"],

    },
    {
        "varName": "taxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*"None",
            "All",*/
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "onKeyDown": true,
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "wordFromAmount":true,
        "label": "Deposit/Installment Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "depositinstallAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "depositTenure",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Day",
            "Month",
            //"Year",
        ],
        "label": "Period Type",
        "grid": 6,
    },
    {
        "varName": "depositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },
    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],

    },
    {
        "varName": "updateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    /* {
         "varName": "nominationPcnt",
         "type": "text",
         "label": "Nomination",
         "conditional": true,
         "conditionalVarName": "nomineeExistNot",
         "conditionalVarValue": "N",

         "grid": 6,
     },*/
    {
        "type": "title",
        "label": "Renewal and Closure",
        "grid": 12,
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. CLOSURE ? ",
        "grid": 6,
    }, {
        "varName": "autoRenewal",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. RENEWAL ? ",
        "grid": 6,
    },
    {
        "varName": "maturity",
        "type": "select",
        "onKeyDown":true,
        "enum": [
            "Renew both Principal and Interest",
            "Renew Principal Only and Credit Interest to",
            "Encashment at Maturity",
        ],
        "required": true,
        "label": "Maturity/Disposal Instruction",
        "grid": 6,
    },

    {
        "type": "title",
        "grid": 12,
        "label": "Nominee",
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"

    },
    {
        "varName": "nomineeType",
        "type": "select",
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "required": true,
        "label": "Nominee Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },


    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "varName": "nomineeRegNo",
        "type": "text",
        "readOnly": true,
        "label": "Registration No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "percentageNominee",
        "type": "text",
        "label": "Percentage %",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "nomineeAddress1",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeAddress2",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },

    {
        "varName": "nomineeCityCode",
        "type": "text",
        "readOnly": true,
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "photoIdNumberRegNo",
        "type": "text",
        "label": "Photo ID Number/Reg No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "maxDate": true,
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },


    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "type": "title",
        "label": "Nominee Assign",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "New Nominee Assignment"
    }

];
let MAKERJsonFormForNewFdrFormLasts = [
    {
        "type": "title",
        "label": "MIS Code",
        "grid": 12,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "conditional": true,
        "conditionalVarName": "sectorCodeApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "depositeType",
        "type": "text",
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "rmCodeApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "freeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Free Code 7 (Deposit Code)",
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Payment Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "flowCode",
        "type": "text",
        "label": "Flow Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amtType",
        "type": "text",
        "label": "Amount Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amt",
        "type": "text",
        "label": "Excise Duty Amount",
         "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "type": "title",
        "label": "Transaction",
        "grid": 12,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Transaction Creation During (O/V)",
        "grid": 6,
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown":true,
        "label": "Debit A/C ID",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    /* {
         "varName": "relationType",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Type ",
         "grid": 6,
     },

     {
         "varName": "relationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Code",
         "grid": 6,
     },

     {
         "varName": "designationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Designation Code",
         "grid": 6,
     },

     {
         "varName": "cifId",
         "type": "text",
         "label": "Cif. Id",
         "grid": 6,
     },
*/
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 6,
    },
];
let CheckerJsonFormForNewFdrForm = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForNewFdrForm)));
let CheckerJsonFormForNewFdrFormLasts = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForNewFdrFormLasts)));

let MAKERJsonFormForNewFdrFormFirst = [
    /* {
         "varName": "customerId",
         "type": "text",
         "label": "Customer ID",
         "required": true,
         "grid": 6,

     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Title",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Customer Name",
         "grid": 6,
         "length": 80,
         required: true,
         "multiline": true
     },

     {
         "varName": "shortName",
         "type": "text",
         "label": "Short Name",
         "grid": 6,
         "length": 10,
         required: true,
     },

     {
         "varName": "email",
         "type": "text",
         "label": "Email",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "phone",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "multiline": true
     },

     /!*
         {
             "label": "AOF 2",
             "type": "title",
             "grid": 12,
         },
     *!/

     {
         "varName": "introducerCustomerId",
         "type": "text",
         "label": "Introducer Customer ID",
         "grid": 6,
         required: true,
     },

     {
         "varName": "introducerName",
         "type": "text",
         "label": "Introducer Name",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "introducerStaff",
         "type": "text",
         "label": "Introducer Staff",
         "grid": 6,
         required: true,
     },

     {
         "varName": "applicantMinor",
         "type": "select",
         "label": "Applicant Minor",
         "grid": 6,
         "enum": ["Yes", "No"],
     },
     {
         "varName": "gurdian",
         "type": "text",
         "label": "Gurdian",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "gurdianName",
         "type": "text",
         "label": "Gurdian Name",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "address23",
         "type": "text",
         "label": "Address",
         "multiline": true,
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "city1",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "state1",
         "type": "text",
         "label": "State",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
         "multiline": true
     },

     {
         "varName": "postal",
         "type": "text",
         "label": "Postal",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "country3",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,

     },

     /!*    {
             "label": "IIF Page 1",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "gender",
         "type": "select",
         "enum": ["Male", "Female"],
         "label": "Gender",
         "grid": 6,
         required: true,
     },

     {
         "varName": "residentStatus",
         "type": "select",
         "label": "Resident Status",
         "enum": ["Resident", "Non-Resident"],
         "grid": 6,
         required: true,
     },

     {
         "varName": "nid",
         "type": "text",
         "label": "National Id Card",
         "grid": 6,
         required: true,
     },

     {
         "varName": "dob",
         "type": "date",
         "label": "DOB",
         "grid": 6,
         "maxDate": true,
         required: true,
     },

     {
         "varName": "father",
         "type": "text",
         "label": "Father ",
         "grid": 6,
         required: true,
     },

     {
         "varName": "mother",
         "type": "text",
         "label": "Mother",
         "grid": 6,
         required: true,
     },

     {
         "varName": "maritialStatus",
         "type": "select",
         "label": "Maritial Status",
         "enum": ["Married", "Un Married"],
         "grid": 6,
         required: true,
     },
     {
         "varName": "spouse",
         "type": "text",
         "label": "Spouse",
         "conditional": true,
         "conditionalVarName": "maritialStatus",
         "conditionalVarValue": "Married",
         "grid": 6,
         required: true,
     },

     {
         "varName": "pangirNo",
         "type": "text",
         "label": "PAN GIR No",
         "grid": 6,
     },

     {
         "varName": "passport",
         "type": "text",
         "label": "Passport No",
         "grid": 6,
     },

     // {
     //     "varName": "issueDate",
     //     "type": "text",
     //     "label": "Issue Date",
     //     "grid": 12,
     // },

     {
         "varName": "passportDetails",
         "type": "text",
         "label": "Passport Details",
         "grid": 6,
     },

     // {
     //     "varName": "expiryDate",
     //     "type": "text",
     //     "label": "Expiry Date",
     //     "grid": 12,
     // },

     {
         "varName": "freeText5",
         "type": "text",
         "label": "Free Text 5",
         "grid": 6,
     },

     {
         "varName": "freeText13",
         "type": "text",
         "label": "Free Text 13",
         "grid": 6,

     },
     {
         "varName": "freeText14",
         "type": "text",
         "label": "Free Text 14",
         "grid": 6,
     },
     {
         "varName": "freeText15",
         "type": "text",
         "label": "Free Text 15",
         "grid": 6,
     },
     /!*    {
             "label": "IIF Page 2",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "comAddress",
         "type": "text",
         "label": "Communication Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "comAddress2",
         "type": "text",
         "label": "Communication Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city",
         "type": "text",
         "required": true,
         "label": "City",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "state",
         "type": "text",
         "required": true,
         "label": "State",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "post",
         "type": "text",
         "required": true,
         "label": "Post",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "country",
         "type": "select",
         "required": true,
         "enum": [
             "Bangladesh",
             "Japan",
             "Other",
         ],
         "label": "Country",
         "grid": 6,
     },
     {
         "varName": "phoneNo12",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "phoneNo21",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "permanentAddress1",
         "type": "text",
         "label": "Permanent Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "permanentAddress2",
         "type": "text",
         "label": "Permanent Address 2",
         "grid": 6,
         "multiline": true
     },

     // {
     //     "varName": "city3",
     //     "type": "select",
     //     "enum": ["Dhaka","Chittagong","Barishal","Rajshahi","Khulna"],
     //     "label": "City",
     //     "grid": 6,
     //     required: true,
     // },

     {
         "varName": "state3",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode4",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
         required: true,
     },

     {
         "varName": "country5",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         required: true,
     },

     {
         "varName": "phoneNo22",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "email2",
         "type": "text",
         "label": "Email",
         "grid": 6,
     },

     {
         "varName": "employerAddress1",
         "type": "text",
         "label": "Employer Address 1",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "employerAddress2",
         "type": "text",
         "label": "Employer Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city4",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         required: true,
     },

     {
         "varName": "state4",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode5",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
     },

     {
         "varName": "country12",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
     },

     {
         "varName": "phoneNo13",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
     },

     {
         "varName": "phoneNo23",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "telexNo1",
         "type": "text",
         "label": "Telex No",
         "grid": 6,
     },

     {
         "varName": "email3",
         "type": "text",
         "label": "Email",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "faxNo",
         "type": "text",
         "label": "Fax No",
         "grid": 6,
     },

     {
         "label": "KYC",
         "type": "title",
         "grid": 12,
     },
     {
         "varName": "acNo",
         "type": "text",
         "label": "Ac No",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "acTitle",
         "type": "text",
         "label": "Ac Title",
         "grid": 6,
         "multiline": true,
     },

     {
         "varName": "customerOccupation",
         "type": "select",
         "enum": ["Teacher", "Doctor", "House-Wife", "Privet Job Holder"],
         "label": "Customer Occupation",
         "grid": 6,
         required: true,
     },

     {
         "varName": "docCollectToEnsure",
         "type": "text",
         "label": "To Ensure Source Of Fund",
         "grid": 6,
         required: true,
     },

     {
         "varName": "collectedDocHaveBeenVerified",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Collected Doc Have Been Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "howTheAddress",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "How The Address Is Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "hasTheBeneficialOwner",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Beneficial Owner Identified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "whatDoesTheCustomer",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Is The Customer Engaged",
         "grid": 6,
         required: true,
     },

     {
         "varName": "customersMonthlyIncome",
         "type": "select",
         "enum": [20000, 50000, 100000],
         "label": "Customers Monthly Income",
         "grid": 6,
         required: true,
     },*/
    //// FDR ////
    /* {
         "label": "FDR Opening Form",
         "type": "title",
         "grid": 12,
     },*/ {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },

    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure ?",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal ?",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": " Sub-Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },

    //Kyc
    {
        "varName": "functionCode",
        "type": "text",
        "label": "Function Code",
        "grid": 6,

    },
    {
        "varName": "customerType",
        "type": "text",
        "label": "Customer Type",
        "grid": 6,

    }
    ,
    {
        "varName": "customerAccount",
        "type": "text",
        "label": "CUSTOMER ACCOUNT",
        "grid": 6,

    }
    ,
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,

    },
    {
        "varName": "typeOfAccount",
        "type": "text",
        "label": "Type of Account",
        "grid": 6,

    }
    ,
    {
        "varName": "accountReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,

    }
    ,
    {
        "varName": "nameOfficerOpeningAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account",
        "grid": 6,

    }
    ,
    {
        "varName": "customersOccupation",
        "type": "text",
        "label": "Customers Occupation (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "customersProbableMonthlyIncome",
        "type": "text",
        "label": "Customers probable monthly income",
        "grid": 6,

    }
    ,
    {
        "varName": "sourcesOfFund",
        "type": "text",
        "label": "Sources of Fund",
        "grid": 6,

    },

    {
        "varName": "documentsCollectedEnsureSourceOfFund",
        "type": "text",
        "label": "Documents collected to ensure the source of fund",
        "grid": 6,

    },
    {
        "varName": "collectedDocumentsVerified",
        "type": "text",
        "label": "Collected documents have been verified or not?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "accountVerified",
        "type": "text",
        "label": "How the address (es) of the account holder has been verified? (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "BeneficiaAccountIdentified",
        "type": "text",
        "label": "Has the Beneficial owner of the account been identified?",
        "grid": 6,

    }
    ,
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,

    }
    ,
    {
        "varName": "nationalIDCardNo",
        "type": "text",
        "label": "National ID Card No",
        "grid": 6,

    }
    ,
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": 6,

    }
    ,
    {
        "varName": "tinNo",
        "type": "text",
        "label": "Tin No",
        "etinBlock":true,
        "grid": 6,

    }
    ,
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNoopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNoopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNoopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNoopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },
    {
        "varName": "vatRegistrationNo",
        "type": "text",
        "label": "VAT Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "companyRegistrationNo",
        "type": "text",
        "label": "Company Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No",
        "grid": 6,

    }
    ,
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,

    }
    ,
    {
        "varName": "reasonopeningAccountForeigner:",
        "type": "text",
        "label": "Reason for opening the account for Non-Resident and Foreigner:",
        "grid": 6,

    }
    ,
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,

    }
    ,
    {
        "varName": "dateOfExpire",
        "type": "date",
        "minDate": true,
        "label": "Date Of Expire",
        "grid": 6,

    }
    ,
    {
        "varName": "workPermitPermission",
        "type": "text",
        "label": "Is Work permit and permission to open account form competent authority is taken for open account of work permit holder?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "CustomerPolitically",
        "type": "select",
        "label": "Is The customer a Politically Exposed Person (PEP) or Influential Person (IP)or Head or high officials of international organization and their family members or close associates?",
        "enum": [
            "Has the approval been taken from senior management? ",
            "Has interviewed the customer face to face?",
            "NO",
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "matchCustomername",
        "type": "select",
        "label": "Is there any match to the customer name with the terrorist activity?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    },
    {
        "varName": "matchCustomernameDetails",
        "type": "select",
        "label": "Details",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "riskGrading",
        "type": "text",
        "label": "Risk Grading",
        "enum": [
            "HIGH",
            "LOW"
        ],
        "grid": 6,

    },


    //Sd part
    {
        "varName": "solId",
        "type": "text",
        "label": " SOL ID",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCY",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Opening Date",
        "grid": 6,
    },

    {
        "varName": "taxCategory",
        "type": "text",
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },

    {
        "varName": "valueDate",
        "type": "date",
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "wordFromAmount":true,
        "label": "Deposit/Installment Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "depositinstallAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "depositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },

    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "nominationPcnt",
        "type": "text",
        "label": "Nomination Pcnt.",
        "grid": 6,
    },

    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "text",
        "label": " Sub-Sector Code",
        "grid": 6,
    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "text",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
    },

    {
        "varName": "depositeType",
        "type": "text",
        "label": "Free Code 7 (Deposit Code)",
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "text",
        "label": "Relation Type ",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif. Id",
        "grid": 6,
    },

    {
        "varName": "etinNumber",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinNumberopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid":6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinNumberopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinNumberopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinNumberopen"],
        "conditionalArrayValue": ["N", true],
        "grid":6,
    },
    /*{
        "varName": "cifId",
        "type": "text",
        "label": "CIF ID",
        "grid": 6,
    },
*/
    {
        "varName": "nomineesName",
        "type": "text",
        "label": "Nominee'S Name",
        "grid": 6,
    },

    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "nomineePhotoIdNo",
        "type": "text",
        "label": "Nominee Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "nomineeMinor",
        "type": "select",
        "label": "Nominee Minor?",
        "grid": 6,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": 6,
    },

    {
        "varName": "nominationPent",
        "type": "text",
        "label": "Nomination Pent.",
        "grid": 6,
    },

    {
        "varName": "guardianName",
        "type": "text",
        "label": "Guardian Name ",
        "grid": 6,
    },

    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "guardianPhotoIdNo",
        "type": "text",
        "label": "Guardian Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "siSrlNo",
        "type": "text",
        "label": "Si Srl. No.",
        "grid": 6,
    },

    {
        "varName": "standingInstructionsFrequency",
        "type": "select",
        "label": "Standing Instructions Frequency  ",
        "grid": 6,
    },

    {
        "varName": "nextExecutionDate",
        "type": "date",
        "minDate":true,
        "label": "Next Execution Date",
        "grid": 6,
    },

    {
        "varName": "temporaryAcId",
        "type": "text",
        "label": "Temporary A/C. Id",
        "grid": 6,
    },
];
let MAKERJsonFormForNewFdrFormLast = [
    /* {
     "varName":"noOfNominee",
     "type":"text",
     "label":"No of Nominee",
     "grid":6,
    },

     {
         "varName":"nomineeName",
         "type":"text",
         "label":"Nominee Name",
         "grid":6,
     },

     {
         "varName":"relationship",
         "type":"text",
         "label":"Relationship",
         "grid":6,
     },

     {
         "varName":"percentage",
         "type":"text",
         "label":"Percentage",
         "grid":6,
     },

     {
         "varName":"addressField1",
         "type":"text",
         "label":"Address Field 1 ",
         "grid":6,
     },

     {
         "varName":"addressField2",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },

     {
         "varName":"city",
         "type":"select",
         "label":"City",
         "grid":6,
     },

     {
         "varName":"state",
         "type":"text",
         "label":"State",
         "grid":6,
     },

     {
         "varName":"postalCode",
         "type":"date",
         "label":"Postal Code",
         "grid":6,
     },

     {
         "varName":"country",
         "type":"select",
         "label":"Country",
         "grid":6,
     },

     {
         "varName":"photoIdNumberregNo",
         "type":"text",
         "label":"Photo ID Number/Reg No.",
         "grid":6,
     },

     {
         "varName":"nomineeDob",
         "type":"date",
          "maxDate":true,
         "label":"Nominee DOB",
         "grid":6,
     },

     {
         "varName":"nomineeMinor",
         "type":"text",
         "label":"Nominee Minor",
         "grid":6,
     },

     {
         "varName":"selectionForGurdianRequiredOrNot(MinimizeRibbonAndGurdianInformationIsNotMandatoryField)",
         "type":"text",
         "label":"Selection for Gurdian Required or Not (Minimize Ribbon & Gurdian Information is Not Mandatory Field)",
         "grid":6,
     },

     {
         "varName":"guardiansName",
         "type":"date",
         "label":"Guardian's Name",
         "grid":6,
     },

     {
         "varName":"guardianCode",
         "type":"text",
         "label":"Guardian Code",
         "grid":6,
     },

     {
         "varName":"guardianPhotoId",
         "type":"date",
         "label":"Guardian Photo Id",
         "grid":6,
     },

     {
         "varName":"addressField1",
         "type":"text",
         "label":"Address Field 1",
         "grid":6,
     },

     {
         "varName":"addressField2",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },

     {
         "varName":"city",
         "type":"select",
         "label":"City",
         "grid":6,
     },

     {
         "varName":"state",
         "type":"text",
         "label":"State",
         "grid":6,
     },

     {
         "varName":"postal",
         "type":"text",
         "label":"Postal ",
         "grid":6,
     },

     {
         "varName":"country",
         "type":"select",
         "label":"Country",
         "grid":6,
     },*/
    {
        "type": "title",
        "label": "Other Bank Information",
        "grid": 12,
    },
    {
        "varName": "otherBankInformationBankName",
        "type": "text",
        "label": "Bank Name",
        "grid": 6,
    },

    {
        "varName": "otherBankInformationBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6,
    },

    {
        "varName": "otherBankInformationModeOfOperation",
        "type": "text",
        "label": "Mode of Operation",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Change For Insta Pack",
        "grid": 12,
    },
    {
        "varName": "schemeChangeForInstaPackFunctionForSchemeChangeInCaseOfInstaPack",
        "type": "text",
        "label": "Function for Scheme Change in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeChangeForInstaPackTargetSchemeCodeInCaseOfInstaPack",
        "type": "text",
        "label": "Target Scheme Code in case of Insta Pack",
        "grid": 6,
    },

    {
        "varName": "schemeChangeForInstaPackTrialMode",
        "type": "text",
        "label": "Trial Mode",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CRM MIS",
        "grid": 12,
    },
    {
        "varName": "crmMisSegment ",
        "type": "text",
        "label": "Segment  ",
        "grid": 6,
    },

    {
        "varName": "crmMisSubSegment",
        "type": "text",
        "label": "Sub Segment",
        "grid": 6,
    },

    {
        "varName": "crmMisEnableCrmAlerts",
        "type": "text",
        "label": "Enable CRM Alerts",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "OTHER",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "dob",
        "type": "date",
        "maxDate": true,
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianStatusBankRelationType",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianStatusGurdianCifNumber",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianStatusGuardianSelection",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "PHOTO ID CARD",
        "grid": 12,
    },
    {
        "type": "title",
        "label": " NATIONAL ID CARD",
        "grid": 12,
    },
    {
        "varName": "nationalIdCardDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardDocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "nid",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNidVerificationConfirmationdocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Document Verified",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "SMART CARD",
        "grid": 12,
    },
    {
        "varName": "smartCardDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "smartCardDocumentCode",
        "type": "text",
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "smartCardSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "smartCardNidVerificationConfirmationdocumentVerified",
        "type": "text",
        "label": "NID Verification Confirmation/Document Verified",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "PASSPORT NUMBER",
        "grid": 12,
    },
    {
        "varName": "passportNumberDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "passportNumberDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "passportNumberIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "passportNumberPassportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,
    },

    {
        "varName": "passportNumberExpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "BIRTH CERTIFICATE",
        "grid": 12,
    },
    {
        "varName": "birthCertificateDocumentType",
        "type": "text",
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "birthCertificateDocumentCode",
        "type": "text",
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "birthCertificatePlaceOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "maxLength":15,
        "validationB":"length",
        "grid": 6,
    },

    {
        "varName": "birthCertificateCountryOfBirthfreeText15",
        "type": "select",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "BANGLADESH",
            "JAPAN",
            "OTHER",
        ],
        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "birthCertificateMinorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "eTinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "eTinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 6,
    },
    {
        "type": "title",
        "label": "EMPLOYER DATA",
        "grid": 12,
    },
    {
        "varName": "employerDataProfessionEmploymentType",
        "type": "text",
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "employerDataNameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "employerDataMonthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CONTACT NUMBER PHONE",
        "grid": 12,
    },
    {
        "varName": "contactNumberContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "designatedMobileNumber",
        "type": "text",
        "phonePrefix": true,
        "label": "Communication Phone Number 1",
        "grid": 6,
    },

    {
        "varName": "phone2",
        "type": "text",
        "phonePrefix": true,
        "label": "Communication Phone Number 2",
        "grid": 6,
    },

    {
        "varName": "contactNumberContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberHomePhoneNo1",
        "type": "text",
        "phonePrefix": true,
        "label": "Home Phone No 1",
        "grid": 6,
    },

    {
        "varName": "contactNumberHomePhoneNo2",
        "type": "text",
        "phonePrefix": true,
        "label": "Home Phone No 2",
        "grid": 6,
    },

    {
        "varName": "contactNumberContactType",
        "type": "text",
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberWorkPhoneNumber1",
        "type": "text",
        "label": "Work Phone Number 1",
        "grid": 6,
    },

    {
        "varName": "contactNumberWorkPhoneNumber2",
        "type": "text",
        "phonePrefix": true,
        "label": "Work Phone Number 2",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "CONTACT NUMBER EMAIL",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailEmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailEmaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailEmailType",
        "type": "text",
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailEmailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "MAILING ADDRESS",
        "grid": 12,
    },
    {
        "varName": "mailingAddressAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "mailingAddressAddressValidForm",
        "type": "date",
        "label": "Address Valid Form",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "HOME ADDRESS",
        "grid": 12,
    },
    {
        "varName": "homeAddressAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressHomeAddressField1",
        "type": "date",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressHomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressCity",
        "type": "select",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "homeAddressState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "homeAddressPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "homeAddressCountry",
        "type": "select",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "homeAddressAddressValidForm",
        "type": "date",
        "label": "Address Valid Form",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "WORK ADDRESS",
        "grid": 12,
    },
    {
        "varName": "workAddressAddressFormat",
        "type": "text",
        "label": "Address Format",
        "grid": 6,
    },
    {
        "varName": "workAddressWorkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressWorkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressCity",
        "type": "select",
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "workAddressState",
        "type": "text",
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "workAddressPostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "workAddressCountry",
        "type": "select",
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "workAddressAddressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "RELATED CB TAGGING",
        "grid": 12,
    },
    {
        "varName": "relatedCbTaggingRelationType",
        "type": "text",
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingRelationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingDesignationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingCifId",
        "type": "text",
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "relatedCbTaggingDeleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
    {

        "type": "title",
        "label": "KYC",
        "grid": 12,
    },
    {
        "varName": "kycDate",
        "type": "text",
        "label": "Date",
        "grid": 6,

    },

    {
        "varName": "kycUniqueCustomerID",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6,

    },

    {
        "varName": "kycACNo",
        "type": "text",
        "label": "AC No",
        "grid": 6,
        "length": 13,
    },

    {
        "varName": "kycACTitle",
        "type": "text",
        "label": "AC Title",
        "grid": 6,

    },

    {
        "varName": "kycTypeOfAC",
        "type": "text",
        "label": "Type of AC",
        "grid": 6,

    },

    {
        "varName": "kycCustomerOccupation",
        "type": "selection",
        "label": "Customer occupation",
        "grid": 6,

    },

    {
        "varName": "kycCustomerProbableMonthlyIncome",
        "type": "text",
        "label": "Customer probable monthly income",
        "grid": 6,

    },

    {
        "varName": "kycSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,

    },

    {
        "varName": "kycDOCCollectToEnsureSourceOfFund",
        "type": "text",
        "label": "DOC collect to Ensure Source of Fund",
        "grid": 6,

    },

    {
        "varName": "kycCollectedDOCHaveBeenVerified",
        "type": "selection",
        "label": "Collected DOC have been Verified",
        "grid": 6,

    },

    {
        "varName": "kycHowTheAddressIsVerified",
        "type": "selection",
        "label": "How the Address is verified",
        "grid": 6,

    },

    {
        "varName": "kycHasTheBeneficialOwnerOfTheAcBeenIdentified",
        "type": "selection",
        "label": "Has the Beneficial owner of the ac been identified",
        "grid": 6,

    },

    {
        "varName": "kycPassportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,

    },

    {
        "varName": "kycNIDNo",
        "type": "text",
        "label": "NID no",
        "grid": 6,

    },

    {
        "varName": "kycBirthCertificateNo",
        "type": "text",
        "label": "Birth certificate no",
        "grid": 6,

    },

    {
        "varName": "kycETIN",
        "type": "text",
        "label": "E-TIN",
        "grid": 6,

    },

    {
        "varName": "kycDrivingLicenseNo",
        "type": "text",
        "label": "Driving License no",
        "grid": 6,

    },

    {
        "varName": "kycOtherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,

    },

    {
        "varName": "kycReasonForACOpeningOfForeignCompany",
        "type": "text",
        "label": "Reason for AC opening of Foreign Company",
        "grid": 6,

    },

    {
        "varName": "kycTypeOfVISA",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,

    },

    {
        "varName": "kycExpDate",
        "type": "text",
        "label": "Exp date",
        "grid": 6,

    },

    {
        "varName": "kycWorkPermitAndPermission",
        "type": "text",
        "label": "Work permit and permission",
        "grid": 6,

    },

    {
        "varName": "kycPepip",
        "type": "text",
        "label": "PEPIP",
        "grid": 6,

    },

    {
        "varName": "kycIfyesThen",
        "type": "text",
        "label": "IF YES then",
        "grid": 6,

    },

    {
        "varName": "kycAnyMatchOfTerroristActivity",
        "type": "text",
        "label": "Any match of Terrorist activity",
        "grid": 6,

    },

    {
        "varName": "kycWhatDoesTheCustomerDoinWhatTypeOfBusinessIsTheCustomerEngaged",
        "type": "text",
        "label": "What does the customer doin what type of business is the customer engaged",
        "grid": 6,

    },

    {
        "varName": "kycCustomersMonthlyIncome",
        "type": "text",
        "label": "Customers monthly income",
        "grid": 6,

    },

    {
        "varName": "kycExpectedAmountOfMonthlyTotalTransaction",
        "type": "selection",
        "label": "Expected Amount of Monthly Total Transaction",
        "grid": 6,

    },

    {
        "varName": "kycExpectedAmountOfMonthlyCashTransaction",
        "type": "selection",
        "label": "Expected Amount of Monthly Cash Transaction",
        "grid": 6,

    },

    {
        "varName": "kycTotalRiskScore",
        "type": "text",
        "label": "Total Risk Score",
        "grid": 6,

    },

    {
        "varName": "kycComments",
        "type": "text",
        "label": "Comments",
        "grid": 6,

    },
    {
        "type": "title",
        "label": "TP",
        "grid": 12,

    },
    {
        "varName": "tpDate",
        "type": "date",
        "label": "Date",
        "grid": 6,
    },

    {
        "varName": "tpUniqueCustomerID",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6,
    },

    {
        "varName": "tpACNo",
        "type": "text",
        "label": "AC No",
        "grid": 6,
    },

    {
        "varName": "tpACTitle",
        "type": "text",
        "label": "AC Title",
        "grid": 6,
    },

    {
        "varName": "tpMonthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income",
        "grid": 6,
    },

    {
        "varName": "tpMonthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid": 6,
    },

    {
        "varName": "tpSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
    },

    {
        "varName": "tpCashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid": 6,
    },

    {
        "varName": "tpDepositByTransfer",
        "type": "text",
        "label": "Deposit by Transfer",
        "grid": 6,
    },

    {
        "varName": "tpForeignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid": 6,
    },

    {
        "varName": "tpDepositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income from Export",
        "grid": 6,
    },

    {
        "varName": "tpDeposittransferFromBOAC",
        "type": "text",
        "label": "DepositTransfer from BO AC",
        "grid": 6,
    },

    {
        "varName": "tpOthers1",
        "type": "text",
        "label": "Others",
        "grid": 6,
    },

    {
        "varName": "tpTotalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid": 6,
    },

    {
        "varName": "tpCashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid": 6,
    },

    {
        "varName": "tpWithdrawalThroughTransferinstrument",
        "type": "text",
        "label": "Withdrawal through TransferInstrument",
        "grid": 6,
    },

    {
        "varName": "tpForeignOutwardRemittance",
        "type": "text",
        "label": "Foreign outward Remittance",
        "grid": 6,
    },

    {
        "varName": "tpPaymentAgainstImport",
        "type": "text",
        "label": "Payment against import",
        "grid": 6,
    },

    {
        "varName": "tpDeposittransferToBOAC",
        "type": "text",
        "label": "DepositTransfer to BO AC",
        "grid": 6,
    },

    {
        "varName": "tpOthers2",
        "type": "text",
        "label": "Others",
        "grid": 6,
    },

    {
        "varName": "tpTotalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable Withdrawal",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "FINCORE DATA UPDATE",
        "grid": 12,
    },
    {
        "varName": "fincoreDataUpdateCashLimit",
        "type": "text",
        "label": "Cash Limit (Dr And Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateClearingLimit",
        "type": "text",
        "label": "Clearing Limit (Dr And Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateTransferLimit",
        "type": "text",
        "label": "Transfer Limit (Dr And Cr)",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateNotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateAccountStatement",
        "type": "text",
        "label": "Account Statement",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateStatementFrequency",
        "type": "text",
        "label": "Standing Instruction Frequency",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateDispatchMode",
        "type": "text",
        "label": "Dispatch Mode",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateNextPrintDate",
        "type": "date",
        "label": "Next Print Date",
        "grid": 6,
    },

    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "text",
        "label": " Sub-Sector Code",
        "grid": 6,
    },

    {
        "varName": "occupationCodeNormal",
        "type": "select",
        "enum": [
            "R",
            "E",
            "AR",
            "PAYBR",
            "PAYDS",
            "PAYHO",
        ],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateDepositCode",
        "type": "text",
        "label": "Deposit Code",
        "grid": 6,
    },


];
let CheckerJsonFormForNewFdrFormFirst = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForNewFdrFormFirst)));
let CheckerJsonFormForNewFdrFormLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MAKERJsonFormForNewFdrFormLast)));
let BMJsonFormForCasaIndividualTagFdr = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualTagFdr.concat(CSJsonFormForFDRServiceAll))));
let BMJsonFormForCasaIndividualTagFdr2 = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualTagFdr2)));
let BOMJsonFormForCasaIndividualTagFdr = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaIndividualTagFdr.concat(CSJsonFormForFDRServiceAll)))));
let MakerJsonFormForCasaIndividualTagFdr2 = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Fixed Deposit Account Opening",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "General Info",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "readOnly": true,
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCY",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },
    {
        "varName": "shortName",
        "type": "text",
        "label": "A/c.  SHORT NAME",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Opening Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Interest And Tax",
        "grid": 12,
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
    },
    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },

    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", "YES"],

    },
    {
        "varName": "taxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*"None",
            "All",*/
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "onKeyDown": true,
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "wordFromAmount":true,
        "label": "Deposit/Installment Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "depositinstallAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "depositTenure",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Day",
            "Month",
            //"Year",
        ],
        "label": "Period Type",
        "grid": 6,
    },
    {
        "varName": "depositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },

    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "updateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    /* {
         "varName": "nominationPcnt",
         "type": "text",
         "label": "Nomination",
         "conditional": true,
         "conditionalVarName": "nomineeExistNot",
         "conditionalVarValue": "N",

         "grid": 6,
     },*/
    {
        "type": "title",
        "label": "Renewal and Closure",
        "grid": 12,
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. CLOSURE ? ",
        "grid": 6,
    }, {
        "varName": "autoRenewal",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. RENEWAL ? ",
        "grid": 6,
    },
    {
        "varName": "maturity",
        "type": "select",
        "onKeyDown":true,
        "enum": [
            "Renew both Principal and Interest",
            "Renew Principal Only and Credit Interest to",
            "Encashment at Maturity",
        ],
        "required": true,
        "label": "Maturity/Disposal Instruction",
        "grid": 6,
    },

    {
        "type": "title",
        "grid": 12,
        "label": "Nominee",
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"

    },
    {
        "varName": "nomineeType",
        "type": "select",
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "required": true,
        "label": "Nominee Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },


    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "varName": "nomineeRegNo",
        "type": "text",
        "readOnly": true,
        "label": "Registration No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "percentageNominee",
        "type": "text",
        "label": "Percentage %",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "nomineeAddress1",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeAddress2",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },

    {
        "varName": "nomineeCityCode",
        "type": "text",
        "readOnly": true,
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "photoIdNumberRegNo",
        "type": "text",
        "label": "Photo ID Number/Reg No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "maxDate": true,
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },


    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "type": "title",
        "label": "Nominee Assign",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "New Nominee Assignment"
    }

];
let fdKYC = [
    {
        "type": "title",
        "label": "KYC",
        "grid": 12,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
    },

    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Individual",
            "Non Individual",
            "Deposit",
            "Govt",
        ],
        "label": "Customer Type",
        "grid": 6,
    },

    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
    },

    {
        "varName": "accountTitle",
        "type": "select",
        "enum": [
            "CAPT.",
            "DR.",
            "ENGG.",
            "LAW",
            "MAST.",
            "MINOR",
            "MR",
            "MR.",
            "MRS.",
            "MS.",
            "JOINT",

        ],
        "label": "Account Title",
        "grid": 6,
    },

    {
        "varName": "accountType",
        "type": "select",
        "enum": [
            "SAVINGS",
            "CURRENT",
            /* "FCY",
             "RFCD"*/,
        ],
        "label": "Account Type",
        "grid": 6,
    },

    {
        "varName": "accountOrReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,
    },

    {
        "varName": "nameOfOfficerOpeningTheAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account:",
        "grid": 6,
    },
    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Customers Occupation (Details )",
        "grid": 6,
    },

    {
        "varName": "customersPro",
        "type": "text",
        "label": "Customers probable monthly income",
        "grid": 6,
    },

    {
        "varName": "sourceOfFundKyc",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "documentsCollectedToEnsureTheSourceOfFund",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Documents Collected to Ensure the Source of Fund",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
            "NOT APPLICABLE",
        ],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": 6,
    },

    {
        "varName": "tinNo",
        "type": "text",
        "etinBlock":true,
        "label": "Tin No",
        "grid": 6,
    },
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNoopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "tinNoopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNoopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "tinNoopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },
    {
        "varName": "vatRegistrationNo",
        "type": "text",
        "label": "VAT Registration No",
        "grid": 6,
    },

    {
        "varName": "companyRegistrationNo",
        "type": "text",
        "label": "Company Registration No",
        "grid": 6,
    },

    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No",
        "grid": 6,
    },

    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },

    {
        "varName": "reasonForOpening",
        "type": "text",
        "label": "Reason for opening the account for NonResident and Foreigner:",
        "grid": 6,
    },

    {
        "varName": "iTypeOfVisa:",
        "type": "text",
        "label": "I Type of VISA:",
        "grid": 6,
    },

    {
        "varName": "dateOfExpire",
        "type": "date",
        "label": "Date Of Expire:",
        "grid": 6,
    },

    {
        "varName": "iiIsWorkPerm",
        "type": "select",
        "enum": [],
        "label": "II Is Work permit and permission to open account form competent authority is taken for open account of work permit holder?  ",
        "grid": 6,
    },

    {
        "varName": "isTheCustome",
        "type": "select",
        "label": "Is The customer a Politically Exposed Person (PEP) or Influential Person (IP)or Head or high officials of international organization and their family members or close associates?",
        "grid": 6,
    },

    {
        "varName": "aHasTheAppro",
        "type": "select",
        "enum": [],
        "label": "A Has the approval been taken from senior management? ",
        "grid": 6,
    },

    {
        "varName": "bHasIntervie",
        "type": "select",
        "enum": [],
        "label": "B Has interviewed the customer face to face?  ",
        "grid": 6,
    },

    {
        "varName": "isThereAnyMa",
        "type": "select",
        "enum": [],
        "label": "Is there any match to the customer name with the terrorist activity funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Councils different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws rules and circular?   ",
        "grid": 6,
    },

    {
        "varName": "ifYesThenDet",
        "type": "text",
        "enum": [],
        "label": "IF YES then Details of action taken regarding this issue",
        "grid": 6,
    },

    {
        "varName": "riskGrading ",
        "type": "select",
        "enum": [
            "High",
            "Low",
        ],
        "label": "Risk Grading",
        "grid": 6,
    },

    {
        "varName": "comments",
        "type": "text",
        "label": "Comments",
        "grid": 6,
    },
];
let fdTP = [];
let commonCsInformationForSdPart = [
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Debit Account Number",
        "grid": 2,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit Account Name",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    /*  {
          "varName": "transactionID",
          "type": "text",
          "label": "Transaction ID/Cheque Number",
           "grid": 2,
      },
      {
          "varName": "transactionDate",
          "type": "date",
           "maxDate":true,
          "label": "Transaction Date",
          "grid": 2,
      },*/
    {
        "varName": "availableAmount",
        "type": "text",
        "readOnly": true,
        "label": "Available Amount",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["debitAccountNumberApiCall", "availableAmountShow"],
        "conditionalArrayValue": ["YES", true],
    }, {
        "varName": "transactionID",
        "type": "text",
        "label": "Transaction ID/Cheque Number",
        "grid": 2,
    },
    {
        "varName": "transactionDate",
        "type": "date",
        "maxDate": true,
        "label": "Transaction Date",
        "grid": 2,
    }, {
        "varName": "lien",
        "type": "date",
        "label": "Lien",
        "grid": 2,
    }, {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 2,
    },
    {
        "varName": "freeze",
        "type": "text",
        "label": "Freeze",
        "grid": 2,
    },
];
let MakerJsonFormForCasaIndividualTagFdr2Last = [
    {
        "type": "title",
        "label": "MIS Code",
        "grid": 12,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "conditional": true,
        "conditionalVarName": "sectorCodeApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "rmCodeApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "freeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Free Code 7 (Deposit Code)",
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Payment Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "flowCode",
        "type": "text",
        "label": "Flow Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amtType",
        "type": "text",
        "label": "Amount Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amt",
        "type": "text",
        "label": "Excise Duty Amount",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "type": "title",
        "label": "Transaction",
        "grid": 12,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Transaction Creation During (O/V)",
        "grid": 6,
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown":true,
        "label": "Debit A/C ID",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    /* {
         "varName": "relationType",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Type ",
         "grid": 6,
     },

     {
         "varName": "relationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Code",
         "grid": 6,
     },

     {
         "varName": "designationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Designation Code",
         "grid": 6,
     },

     {
         "varName": "cifId",
         "type": "text",
         "label": "Cif. Id",
         "grid": 6,
     },
*/
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditional": true,
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 6,
    },
];
let CheckerJsonFormForCasaIndividualTagFdr2 = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualTagFdr2)));
let CheckerJsonFormForCasaIndividualTagFdr2Last = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualTagFdr2Last)));
//with existing CB Only
let MakerJsonFormForCasaIndividualWithExistingCBTagFdr = [


    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
//Sd part
    {
        "type": "title",
        "label": "Fixed Deposit Account Opening",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "General Info",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "readOnly": true,
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "CCY",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },
    {
        "varName": "shortName",
        "type": "text",
        "label": "A/c.  SHORT NAME",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "maxDate": true,
        "label": "A/C Opening Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Interest And Tax",
        "grid": 12,
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
    },
    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },

    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", "YES"],
    },
    {
        "varName": "taxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*"None",
            "All",*/
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "onKeyDown": true,
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "wordFromAmount":true,
        "label": "Deposit/Installment Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "depositinstallAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "depositTenure",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Day",
            "Month",
            //"Year",
        ],
        "label": "Period Type",
        "grid": 6,
    },
    {
        "varName": "depositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "text",
        "label": "Maturity Date",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },

    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "updateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    /* {
         "varName": "nominationPcnt",
         "type": "text",
         "label": "Nomination",
         "conditional": true,
         "conditionalVarName": "nomineeExistNot",
         "conditionalVarValue": "N",

         "grid": 6,
     },*/
    {
        "type": "title",
        "label": "Renewal and Closure",
        "grid": 12,
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. CLOSURE ? ",
        "grid": 6,
    }, {
        "varName": "autoRenewal",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. RENEWAL ? ",
        "grid": 6,
    },
    {
        "varName": "maturity",
        "type": "select",
        "onKeyDown":true,
        "enum": [
            "Renew both Principal and Interest",
            "Renew Principal Only and Credit Interest to",
            "Encashment at Maturity",
        ],
        "required": true,
        "label": "Maturity/Disposal Instruction",
        "grid": 6,
    },

    {
        "type": "title",
        "grid": 12,
        "label": "Nominee",
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"

    },
    {
        "varName": "nomineeType",
        "type": "select",
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "required": true,
        "label": "Nominee Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },


    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "varName": "nomineeRegNo",
        "type": "text",
        "readOnly": true,
        "label": "Registration No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "percentageNominee",
        "type": "text",
        "label": "Percentage %",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "nomineeAddress1",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeAddress2",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },

    {
        "varName": "nomineeCityCode",
        "type": "text",
        "readOnly": true,
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "photoIdNumberRegNo",
        "type": "text",
        "label": "Photo ID Number/Reg No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "maxDate": true,
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },


    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "type": "title",
        "label": "Nominee Assign",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "New Nominee Assignment"
    }

];
let MakerJsonFormForCasaIndividualWithExistingCBTagFdrLast = [
    {
        "type": "title",
        "label": "MIS Code",
        "grid": 12,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "conditional": true,
        "conditionalVarName": "sectorCodeApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "rmCodeApicall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "freeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Free Code 7 (Deposit Code)",
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Payment Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "flowCode",
        "type": "text",
        "label": "Flow Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amtType",
        "type": "text",
        "label": "Amount Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "amt",
        "type": "text",
        "label": "Excise Duty Amount",
        "validation": "numeric",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "type": "title",
        "label": "Transaction",
        "grid": 12,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Transaction Creation During (O/V)",
        "grid": 6,
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown":true,
        "label": "Debit A/C ID",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },
    /* {
         "varName": "relationType",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Type ",
         "grid": 6,
     },

     {
         "varName": "relationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Code",
         "grid": 6,
     },

     {
         "varName": "designationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Designation Code",
         "grid": 6,
     },

     {
         "varName": "cifId",
         "type": "text",
         "label": "Cif. Id",
         "grid": 6,
     },
*/
    {
        "varName": "etin",
        "type": "text",
        "etinBlock":true,
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 6,
    },

];
let CheckerJsonFormForCasaIndividualWithExistingCBTagFdr = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualWithExistingCBTagFdr)));
let CheckerJsonFormForCasaIndividualWithExistingCBTagFdrLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualWithExistingCBTagFdrLast)));


///// DPS ////
let CSJsonFormForCasaIndividualDPS = [
    {
        "varName": "eqmTicketNumber",
        "type": "text",
        "label": "EQM Ticket Number",
        "grid": 2,

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "readOnly": true,
        "grid": 2,

    },
    {
        "varName": "branchSolId",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "SOL ID",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "dstFlag",
        "conditionalVarValue": true,
    },
     {
        "varName": "dpsfreeCode1SpecialTaxagentOutlet",
         "type": "autoCompleteValueReturn",
         "enum": [],
        "label": "Agent Banking Outlet",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "agentBankingFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "CB Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "cbNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 2,

    },
    {
        "type": "empty",
        "grid": 6,

    },
    {
        "type": "title",
        "grid": 12,
        "label": "Debit Instruction"

    },


    {
        "varName": "debitAccountNumber",
        "type": "text",
        "required": true,
        "readOnly": true,
        "label": "Debit Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "conditionalNewButton",
        "conditionalVarValue":false,

    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown": true,
        "required": true,
         "label": "Debit Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "conditionalNewButton",
        "conditionalVarValue":true,

    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "label": "Debit Account Name",
        "grid": 2,

    }
    ,
    {
        "varName": "availableAmount",
        "type": "text",
        "label": "Available Amount",
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["debitAccountNumberApiCall", "availableAmountShow"],
        "conditionalArrayValue": ["YES", true],
        "grid": 2,

    }
    ,
    /*{
        "varName": "accountRestriction",
        "type": "text",
        "label": "Account Restriction(if Any)",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 2,

    }
    ,*/
    {
        "varName": "currency",
        "type": "text",
        "label": "Currency",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 2,

    }
    ,
    /* {
         "varName": "businessSegment",
         "type": "select",
         "enum": [
             "CONVENTIONAL",
             "ISLAMIC",
         ], "label": "Business Segment",
         "grid": 2,

     },*/
    {
        "type": "title",
        "grid": 12,
        "label": "DPS Information"
    },

    {
        "varName": "fdValueDate",
        "type": "date",
        "dayAdd": 0,
        "grid": 2,
        "required":true,
        "maxDate": true,
        "onKeyDown": true,
        "label": "DPS Value Date",

    }
    ,

    {
        "varName": "nameOfScheme",
        "type": "select",
        "grid": 2,
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Name Of Scheme",
        "conditional": true,
        "conditionalVarName": "nameOfSchemeApiCall",
        "conditionalVarValue": true,

    },
    {
        "varName": "accountAcquisition",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Account Acquisition By",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "accountAcquisitionApiCall",
        "conditionalVarValue": true,


    },
    {
        "varName": "tenureType",
        "type": "select",
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "required": true,
        "label": "Scheme Type",
        "conditional": true,
        "conditionalVarName": "tenureTypeApiCall",
        "conditionalVarValue": true,

    },
    {
        "varName": "tenure",
        "type": "select",
        "enum": [],
        "grid": 2,
        "required": true,
        "label": "Period Of Scheme",
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "tenureApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "repaymentAccounts",
        "type": "textApiCall",
        "label": "Repayment Account",
        "required":true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsSchemeCodeApiCall",
        "conditionalVarValue": true,
    },
    {
        "varName": "repaymentsName",
        "type": "text",
        "label": "Repayment Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsSchemeCodeApiCall","repaymentsAccountApiCall"],
        "conditionalArrayValue": [true,"YES"],
    },
    {
        "varName": "dpsSchemeCode",
        "type": "autoCompleteValueReturn",
        "required": true,
        "label": "DPS Scheme Code",
        "multiline": true,
        "readOnly": true,
        "grid": 4,
        "conditional": true,
        "conditionalVarName": "dpsSchemeCodeApiCall",
        "conditionalVarValue": true,

    }
    ,
    {
        "varName": "amount",
        "type": "text",
        "wordFromAmount":true,
        "validation": "numeric",
        "required": true,
        "onKeyDown": true,
        "label": "Amount Per Installment",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "dpsSchemeCodeApiCall",
        "conditionalVarValue": true,

    }
    ,
    {
        "varName": "amountWithWord",
        "type": "text",
        "label": "Amount In Word Per Installment",
        "readOnly": true,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["dpsSchemeCodeApiCall", "amountWithWordApiFlag"],
        "conditionalArrayValue": [true, true]
    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "dayAdd": 0,
        "minDate":true,
        "required": true,
        "onKeyDown": true,
         "label": "Installment Start Date",
        "grid": 2,

    }
    ,
    {
        "varName": "payableAtMaturity",
        "type": "text",
        "readOnly": true,
        "label": "Payable At Maturity",
        "grid": 2,

    }
    ,
    {
        "varName": "fdMaturityDate",
        "type": "text",
        "label": "Maturity Date",
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "interestRate",
        "type": "text",
        "label": "Rate %",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "interestRateApicall",
        "conditionalVarValue": "YES",
        "grid": 2,
    },

    {
        "varName": "etin",
        "type": "text",
        "validation": "etin",
        "etinBlock":true,
        "label": "Tin No",
        "grid": 2,

    }
    ,
    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "etinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":2,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "etinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 2,
    },

    {
        "varName": "taxWaiverChanges",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Tax Waiver Applicable",
        "grid": 2,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Period Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "depositeTypeApiCall",
        "conditionalVarValue": true,

    }
    ,
    {
        "varName": "occupationCode",
        "type": "select",
        "enum": [],
        "required": true,
        "label": "Occupation Code",
        "grid": 2,

    }
    ,
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "label": "Sector Code",
        "grid": 2,
        required: true,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "grid": 2,
        required: true,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },
    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required": true,
        "label": "RM Code",
        "grid": 2,

    }
    ,
    {
        "varName": "rmCodeName",
        "type": "text",
        "readOnly": true,
        "label": "RM Code Name",
        "conditional": true,
        "conditionalVarName": "rmCodeApicall",
        "conditionalVarValue": "YES",
        "grid": 2,

    }
    ,
    {
        "varName": "nomineeType",
        "type": "select",
        "required": true,
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "label": "Nominee Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },

    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },


];
let CSJsonFormForDPSService = [
    {
        "varName": "priority",
        "type": "select",
        "enum": [
            "HIGH",
            "NORMAL"
        ],
        "label": "Priority",
        "grid": 2,

    },

    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    },
    {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 2
    },
    {
        "varName": "letterOfCredit",
        "type": "checkbox",
        "label": "Letter Of Credit",
        "grid": 2,

    }
];
let CSJsonFormForCasaIndividualTagDPS = [
    /*{
           "varName": "dpsSi",
           "type": "date",
           "label": "SI Date",
           "grid": 2,
           // "conditional": true,
           // "conditionalVarName": "dpsRequest",
           // "conditionalVarValue": true,
       },*/


];


let BMJsonFormForCasaIndividualDPS = makeReadOnlyObject(JSON.parse(JSON.stringify(CSJsonFormForCasaIndividualDPS)));
let BMJsonFormForCasaIndividualTagDPS = makeReadOnlyObject(JSON.parse(JSON.stringify([
    // {
    //     "varName": "accountType",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Fix General",
    //         "Double",
    //         "Monthly",
    //     ],
    //     "label":"Type Of Account",
    //
    // },

    // ///// Account Type Double ///
    // {
    //     "varName": "doubleTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "tennordDouble",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "No"
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },
    //
    // /////  Account Type Fix General /////
    // {
    //     "varName": "fixGTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Day",
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "tennorFixG",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Yes",
    //         "No",
    //         "Encash at Maturity to my / Our Account No",
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixdGMaturityYes",
    //     "type": "select",
    //     "label": "Maturity Yes",
    //     "enum":[
    //         "Renew Principal Only and Credit Interest to the Account No",
    //         "Renew Both Principal and Interest"
    //     ],
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "fixGSiMaturity",
    //     "conditionalVarValue": "Yes",
    // },
    //
    // ///////  Month Tenor /////
    // {
    //     "varName": "monthTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // }, {
    //     "varName": "tennorMonth",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Yes",
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthMaturityYes",
    //     "type": "select",
    //     "label": "Maturity Yes",
    //     "enum":[
    //         "Renew Principal Only and Credit Interest to the Account No"
    //         // "Renew Principal Only "
    //     ],
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },
    /////////  DPS //
    // {
    //     "varName": "dpsRequest",
    //     "type": "checkbox",
    //     "label": "DPS Request",
    //     "grid": 12
    // },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "grid": 2,
        "label": "Tennor Type",
        "enum": [
            "Day",
            "Monthly"
        ],
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsTennor",
        "type": "text",
        "label": "Tennor",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest %",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 2,
        "readOnly": true
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    },
    {
        "varName": "dpsSchemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 2,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 2
    },
    {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 2
    },
    {
        "varName": "letterOfCredit",
        "type": "checkbox",
        "label": "Letter Of Credit",
        "grid": 2,

    }
])));


let BOMJsonFormForCasaIndividualDPS = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(CSJsonFormForCasaIndividualDPS))));

let BOMJsonFormForCasaIndividualTagDPS = makeReadOnlyObject(JSON.parse(JSON.stringify([
    // {
    //     "varName": "accountType",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Fix General",
    //         "Double",
    //         "Monthly",
    //     ],
    //     "label":"Type Of Account",
    //
    // },

    // ///// Account Type Double ///
    // {
    //     "varName": "doubleTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "tennordDouble",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },{
    //     "varName": "doubleSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "No"
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Double",
    // },
    //
    // /////  Account Type Fix General /////
    // {
    //     "varName": "fixGTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Day",
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "tennorFixG",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixGSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Yes",
    //         "No",
    //         "Encash at Maturity to my / Our Account No",
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Fix General",
    // },{
    //     "varName": "fixdGMaturityYes",
    //     "type": "select",
    //     "label": "Maturity Yes",
    //     "enum":[
    //         "Renew Principal Only and Credit Interest to the Account No",
    //         "Renew Both Principal and Interest"
    //     ],
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "fixGSiMaturity",
    //     "conditionalVarValue": "Yes",
    // },
    //
    // ///////  Month Tenor /////
    // {
    //     "varName": "monthTennor",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Monthly"
    //     ],
    //     "label":"Tennor Type",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // }, {
    //     "varName": "tennorMonth",
    //     "type": "text",
    //     "label": "Tennor",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthAmount",
    //     "type": "text",
    //     "label": "Amount",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthInterest",
    //     "type": "text",
    //     "label": "Interest Rate",
    //     "grid": 3,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthSiMaturity",
    //     "type": "select",
    //     "grid": 3,
    //     "enum":[
    //         "Yes",
    //     ],
    //     "label":"SI Maturity",
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },{
    //     "varName": "monthMaturityYes",
    //     "type": "select",
    //     "label": "Maturity Yes",
    //     "enum":[
    //         "Renew Principal Only and Credit Interest to the Account No"
    //         // "Renew Principal Only "
    //     ],
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "accountType",
    //     "conditionalVarValue": "Monthly",
    // },
    /////////  DPS //
    // {
    //     "varName": "dpsRequest",
    //     "type": "checkbox",
    //     "label": "DPS Request",
    //     "grid": 12
    // },
    {
        "varName": "dpsTenorType",
        "type": "select",
        "grid": 3,
        "label": "Tennor Type",
        "enum": [
            "Day",
            "Monthly"
        ],
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsTennor",
        "type": "text",
        "label": "Tennor",
        "grid": 3,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 3,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsInterest",
        "type": "text",
        "label": "Interest %",
        "grid": 3,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    }, {
        "varName": "dpsSi",
        "type": "date",
        "label": "SI Date",
        "grid": 3,
        "readOnly": true
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    },
    {
        "varName": "dpsSchemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 3,
        // "conditional": true,
        // "conditionalVarName": "dpsRequest",
        // "conditionalVarValue": true,
    },
    {
        "varName": "loanRequest",
        "type": "checkbox",
        "label": "Loan Request",
        "grid": 3
    },
    {
        "varName": "creditCard",
        "type": "checkbox",
        "label": "Credit Card",
        "grid": 3
    },
    {
        "varName": "letterOfCredit",
        "type": "checkbox",
        "label": "Letter Of Credit",
        "grid": 3,

    }
])));


let MakerJsonFormForCasaIndividualTagDPS = [
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "DPS Account Opening",
        "grid": 12,

    },
    {
        "type": "title",
        "label": "General Information",
        "grid": 12,

    },
    {
        "varName": "dpssolId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,

    },
    {
        "varName": "dpscifId",
        "type": "text",
        "readOnly": true,
        //"validation":"numeric",
        "label": "CIF ID",
        "grid": 6,

    }
    ,
    {
        "varName": "dpsccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "CCY",
        "grid": 6,

    }

    ,
    {
        "varName": "dpsschemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Scheme Code",
        "grid": 6,

    },
    {
        "varName": "dpsaccountName",
        "type": "text",
        "readOnly": true,
        "validation": "string",
        "label": "A/c. NAME",
        "grid": 6,

    }
    ,
    {
        "varName": "dpsaccountOpeningDate",
        "type": "date",
        "required": true,
        "maxDate": true,
        "label": "A/C Opening Date",
        "grid": 6,

    }
    ,
    {
        "type": "title",
        "label": "Interest and Tax",
        "grid": 12,

    },
    {
        "varName": "taxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "required": true,
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "required": true,
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*"None",
            "All",*/
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "required": true,
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "required": true,
        "label": "Withholding Tax Pcnt.",
        "grid": 6,

    }
    ,
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,

    },
    {
        "varName": "dpsvalueDate",
        "type": "date",
        "onKeyDown": true,
        "maxDate": true,
        "required": true,
        "label": "Value Date",
        "grid": 6,

    },

    {
        "varName": "dpsdepositAmount",
        "type": "text",
        "wordFromAmount":true,
        "validation": "numeric",
        "label": "Deposit/Installment Amount",
        "grid": 6,

    },
    {
        "varName": "dpsdepositAmountWithWord",
        "type": "text",
        "readOnly":true,
        "label": "Deposit/Installment Amount In Word",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsdepositAmountWithWordApiFlag",
        "conditionalVarValue": true,
    },
    {
        "varName": "dpsdepositTenure",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Month",
        ],
        "label": "Deposit Tenure",
        "grid": 6,

    },
    {
        "varName": "dpsdepositPeriodMonthsdays",
        "type": "select",
        "onKeyDown": true,
        "enum": [],
        "label": "Deposit Period (Months/ Days)",

        "grid": 6,

    }
    ,
    {
        "varName": "dpsmaturityDate",
        "type": "text",
        "readOnly": true,
        "label": "Maturity Date",
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "grid": 6,

    }
    ,
    {
        "varName": "dpsmaturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,

    }
    ,
    {
        "varName": "dpspaymentAccountId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "required": true,
        "grid": 6,

    }
    ,
    {
        "varName": "dpspaymentAccountName",
        "type": "text",
        "readOnly":true,
        "label": "Repay A/C Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpspaymentAccountIdApiCall",
        "conditionalVarValue": "YES",


    }
    ,
    {
        "varName": "updateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "MIS Codes",
        "grid": 12,

    },
    /*   {
           "varName": "flowDetails",
           "type": "text",
           "label": "FLOW DETAILS",
           "grid": 6,

       }
       ,*/
    {
        "varName": "dpssectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "dpssubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": " Sub-Sector Code",
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "dpsoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "dpsfreeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "dpsrmCode",
        "type": "textApiCall",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,

    },
    {
        "varName": "dpsrmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "rmCodeApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "dpsfreeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,

    },



    {
        "varName": "dpsdepositeType",
        "type": "text",
        "readOnly": true,
        ///"enum": [],
        "label": "Free Code 7 (Deposit Code)",
        "required": true,
        "grid": 6,
    },
    /*  {
          "varName": "relationType",
          "type": "autoCompleteValueReturn",
          "enum": [],
          "label": "Relation Type ",
          "grid": 6,
      },

      {
          "varName": "relationCode",
          "type": "autoCompleteValueReturn",
          "enum": [],
          "label": "Relation Code",
          "grid": 6,
      },

      {
          "varName": "designationCode",
          "type": "autoCompleteValueReturn",
          "enum": [],
          "label": "Designation Code",
          "grid": 6,
      },
      //Nominee Information


      {
          "varName": "cifId",
          "type": "text",
          "label": "CIF ID",
          "grid": 6,

      }
      ,*/
    {
        "varName": "dpsetin",
        "type": "text",
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,

    },

    {
        "label": "ETIN Registration Date",
        "type": "date",
        "varName": "registrationDateSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dpsetinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception",
        "type": "select",
        "enum":["Y","N"],
        "varName": "exceptionSubmission",
        "grid": 6,
        "required":true,
        "conditional": true,
        "conditionalVarName": "dpsetinopen",
        "conditionalVarValue": true,
    },
    {
        "label": "Exception Expiry Date",
        "type": "date",
        "required":true,
        "varName": "exceptionExpiryDateSubmission",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "dpsetinopen"],
        "conditionalArrayValue": ["Y", true],
        "grid":6,
    },
    {
        "varName": "returnSubmission",
        "type": "select",
        "enum":listOfReturnSubmission(startReturnSubmission),
        "required":true,
        "label": "Tax Submission Year",
        "conditionalArray": true,
        "conditionalArrayList": ["exceptionSubmission", "dpsetinopen"],
        "conditionalArrayValue": ["N", true],
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"

    },

    {
        "varName": "dpsnomineeType",
        "type": "select",
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "required": true,
        "label": "Nominee Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"
    },


    {
        "varName": "dpsnomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeRegNo",
        "type": "text",
        "readOnly": true,
        "label": "Registration No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "percentageNominee",
        "type": "text",
        "label": "Percentage %",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "nomineeAddress1",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeAddress2",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },

    {
        "varName": "nomineeCityCode",
        "type": "text",
        "readOnly": true,
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "photoIdNumberRegNo",
        "type": "text",
        "label": "Photo ID Number/Reg No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "maxDate": true,
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },


    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "dpsnomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

];
let MakerJsonFormForCasaIndividualTagDPSLast = [
    /*   {
           "type": "title",
           "label":"Header Details",
           "grid": 12,

       },

       {
           "varName": "siSrlNo",
           "type": "text",
           "label": "Si Srl. No.",
           "grid": 6,
       },*/

    {
        "type": "title",
        "label": "Standing Instruction Frequency",
        "grid": 12,

    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "required": true,
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 4,

    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "required": true,
        "enum": day,
        "grid": 4,

    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "required": true,
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 4,

    },

    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "minDate":true,
        "label": "Next Execution Date",
        "grid": 6,
    },

    //Nominee Information
    /* {
         "type": "title",
         "label": "Nominee Assign",
         "grid": 12,
         "conditional": true,
         "conditionalVarName": "nomineeType",
         "conditionalVarValue": "New Nominee Assignment"
     },
   */

];
let CheckerJsonFormForCasaIndividualTagDPS = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualTagDPS)));
let CheckerJsonFormForCasaIndividualTagDPSLast = makeReadOnlyObject(JSON.parse(JSON.stringify(MakerJsonFormForCasaIndividualTagDPSLast)));

///////// Remetance ///////

/*let fttFirstLayerFiledRemitance = [

    {
        "varName": "requestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "branch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "emailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }
];
let fddFirstLayerFiledRemitance = [

    {
        "varName": "fddrequestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "fddcurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddAmount",
        "type": "text",
        "label": "Amount",
        "required": true,
        "grid": 2,

    }, {
        "varName": "fddbranch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddemailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }
];
let outwardFirstLayerFiledRemitance = [
    {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 2,
    }
];

let fttJasonFormRemitanceMidLayer = [

    {
        "label": "Receiver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "reciverName",
        "type": "text",
        "label": "Name",
        "grid": 3,
        "multiline": true

    }, {
        "varName": "reciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "reciverAccountNo",
        "type": "text",
        "label": "Account No",
        "grid": 2,

    }, {
        "varName": "reciverAddress",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "multiline": true

    }, {
        "varName": "swiftOrCode",
        "type": "select",
        "label": "Swift Or Code",
        "enum": [
            "Swift",
            "Code",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttswift",
        "type": "text",
        "grid": 2,
        "label": "Swift",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Swift",
    }, {
        "varName": "fttCode",
        "type": "text",
        "grid": 2,
        "label": "Code",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Code",
    }, {
        "varName": "fttBankName",
        "type": "select",
        "label": "BankName",
        "enum": [
            "HSBC",
            "Citi",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttRemerks1",
        "type": "text",
        "label": "Remarks",
        "grid": 3,

    }, {
        "varName": "fttRemerks2",
        "type": "text",
        "grid": 3,

    }, {
        "varName": "chargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    }, {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, {
        "varName": "fttPurposecode",
        "type": "select",
        "label": "Purpose Code",
        "enum": [
            "1122",
            "1120",
            "1121",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
    }, {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    }, {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 3,
    }, {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 3,
        "multiline": true
    }, {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [],
        "grid": 2,
    }, {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "multiline": true
    }, {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    }, {
        "varName": "fttSenderPhone",
        "type": "text",
        "label": "Phone",
        "grid": 2,
    }, {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 3,
    },
];
let fddJasonFormRemitanceMidLayer = [

    {
        "label": "Reciver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fddreciverName",
        "type": "text",
        "label": "Name",
        "required": true,
        "grid": 3,
        "multiline": true

    }, {
        "varName": "fddreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddRemerks",
        "type": "text",
        "label": "Remerks",
        "grid": 3,

    }, {
        "varName": "fddchargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    }, {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, {
        "varName": "fddPurposeCode",
        "type": "text",
        "label": "Purpose Code",
        "grid": 2,
    }, {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
    }, {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    }, {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 3,
    }, {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 3,
    }, {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [
            "My self",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 3,
    }, {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    }, {
        "varName": "fttSenderPhone",
        "type": "text",
        "label": "Phone",
        "grid": 2,
    }, {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 2,
    }, {
        "varName": "fddPrintBy",
        "type": "select",
        "label": "FDD Print By",
        "enum": [
            "Branch",
            "SD Maker",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddCheckNo",
        "type": "text",
        "label": "Check No",
        "grid": 2,
    }
];
let outwardJasonFormRemitanceMidLayer = [

    {
        "label": "Entity Info",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "owReferenceNo",
        "type": "text",
        "label": "Reference No",
        "required": true,
        "grid": 3,

    }, {
        "varName": "owreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owBranch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owNameOfCourse",
        "type": "text",
        "label": "Name Of Course",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owBookedBy",
        "type": "text",
        "label": "Booked By",
        "grid": 2,

    }, {
        "varName": "owDateOfCommencmante",
        "type": "date",
        "label": "Date Of Commencmante",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owbookedByName",
        "type": "text",
        "label": "Booked By Name",
        "grid": 3,
    }, {
        "varName": "owDurationOfYar",
        "type": "select",
        "label": "Duration Of the course year",
        "enum": [
            "2019",
            "2020",
            "2021",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDurationOfMarch",
        "type": "select",
        "label": "Duration Of the course month",
        "enum": [
            "Jan",
            "Feb",
            "March",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcNumber",
        "type": "text",
        "label": "Debit Ac Number",
        "required": true,
        "grid": 3,
    }, {
        "varName": "owCurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "INR",
            "USD",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcName",
        "type": "text",
        "label": "Debit Ac Name",
        "grid": 3,
    }, {
        "varName": "owAnnualExpance",
        "type": "text",
        "label": "Annual Expance",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owDebitAcType",
        "type": "select",
        "label": "Debit Ac Type",
        "enum": [
            "Type 1",
            "Type 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcAddress",
        "type": "text",
        "label": "Debit Ac Address",
        "grid": 3,
        "multiline": true
    }, {
        "varName": "owTutionFee",
        "type": "text",
        "label": "Tution Fee",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owBordingLodgingFee",
        "type": "text",
        "label": "Bording Lodging Fee",
        "grid": 2,
        "required": true,
    }, {
        "varName": "tobeDeclaredLater",
        "type": "checkbox",
        "label": "To be declared Later",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owDebitAcBranch",
        "type": "select",
        "label": "Debit Ac Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owTotalFee",
        "type": "text",
        "label": "Total Fee",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owReferringAgency",
        "type": "text",
        "label": "Referring Agency",
        "grid": 2,
    }, {
        "varName": "owFileOpeningDate",
        "type": "date",
        "label": "File Opening Date",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owAmmontToBeRemitteInAdvance",
        "type": "text",
        "label": "Total Ammount to be Remmited in Advance",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owyes",
        "type": "checkbox",
        "label": "Yes",
        "grid": 1,
        "required": true,
    }, {
        "varName": "owNo",
        "type": "checkbox",
        "label": "No",
        "grid": 1,
        "required": true,
    }, {
        "varName": "owApplicantName",
        "type": "text",
        "label": "Name Of The Applicant",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owLastDateOfRemitanceAdvanceAmmount",
        "type": "date",
        "label": "Last Date Of Remittance Advance Amount",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owApplicantAddress",
        "type": "text",
        "label": "Address Of The Applicant",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owRemittableAdvance",
        "type": "text",
        "label": "Remittable Advance",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owApplicanteDateOfBirth",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owDetailsOfForeignCurrencyPurchase",
        "type": "text",
        "label": "Details Of Foreign Currency Purchase",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owAge",
        "type": "text",
        "label": "Age",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owRelationshipWithStudent",
        "type": "select",
        "label": "Relationship With Student",
        "enum": [
            "Father",
            "Mother",
            "Brtoher",
            "Sister",
            "Cousine",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owContactNo",
        "type": "text",
        "label": "Contact No",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPassportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 2,
    }, {
        "varName": "owIssuDate",
        "type": "date",
        "label": "Date Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owExpairyDate",
        "type": "date",
        "label": "Date Of Expairy",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPlaceOfIssue",
        "type": "text",
        "label": "Place Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owEducationalQualification",
        "type": "select",
        "label": "Educational Qualification",
        "enum": [
            "BBA",
            "MBA",
            "HSC",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owEducationalInstituteName",
        "type": "text",
        "label": "Name Of Educational Institute",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owAddressEducationalInstituteName",
        "type": "text",
        "label": "Address Of Educational Institute",
        "grid": 3,
        "required": true,
    }, {
        "varName": "owFreeField1",
        "type": "text",
        "label": "Free Field 1",
        "grid": 2,
    }, {
        "varName": "owFreeField2",
        "type": "text",
        "label": "Free Field 2",
        "grid": 2,
    }, {
        "varName": "owFreeField3",
        "type": "text",
        "label": "Free Field 3",
        "grid": 2,
    },
];
let outwardMedicalFileRemetance = [
    {
        "label": "Medical File",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 3,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch (AD)"
    },
    {
        "varName": "bookedBy",
        "grid": 4,
        "type": "text",
        "label": "Booked By "
    },
    {
        "varName": "bookedByName",
        "grid": 3,
        "type": "text",
        "label": "Booked By Name "
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Number",
    },
    {
        "varName": "debitAcName",
        "grid": 3,
        "type": "text",
        "label": "Debit A/C Name"
    },
    {
        "varName": "debitAcTyp",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Typ"
    },
    {
        "varName": "debitAcAddress",
        "grid": 3,
        "type": "text",
        "label": "Debit A/C Address"
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Branch"
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency"
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date"
    },
    {
        "varName": "nameOfThePatient",
        "grid": 3,
        "type": "text",
        "label": "Name of the Patient"
    },
    {
        "varName": "addressOfThePatient",
        "grid": 3,
        "type": "text",
        "label": "Address of the Patient"
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "label": "Date of Birth"
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age"
    },
    {
        "varName": "nationality",
        "grid": 2,
        "type": "select",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No."
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport"
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue"
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry"
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue"
    },
    {
        "varName": "hospitalmedicalCollegeName",
        "grid": 3,
        "type": "text",
        "label": "Hospital/Medical College Name"
    },
    {
        "varName": "addressOfTheHospitalmedicalCollege",
        "grid": 4,
        "type": "text",
        "label": "Address of the Hospital/Medical College"
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "nameOfTheDisease",
        "grid": 2,
        "type": "text",
        "label": "Name of the Disease"
    },
    {
        "varName": "periodContinuousResidencInBangladesh",
        "grid": 4,
        "type": "text",
        "label": "Period continuous residenc in Bangladesh"
    },
    {
        "varName": "nameOfTheTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Treatment"
    },
    {
        "varName": "nameOfTheInstitutionsdoctorsWhoHaveCariedOutTheAboveTreatment",
        "grid": 4,
        "type": "text",
        "label": "Name of the Institutions/Doctors who have caried out the above treatment"
    },
    {
        "varName": "treatmentContemplatedAndInWhichCountrycountries",
        "grid": 3,
        "type": "text",
        "label": "Treatment contemplated and in which country/countries"
    },
    {
        "varName": "anticipatedLengthOfStayAbroadForTreatment",
        "grid": 3,
        "type": "text",
        "label": "Anticipated length of stay abroad for treatment "
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "select",
        "label": "Currency",
        "enum": [
            "USD",
            "INR",
            "BDT",
        ],
    },
    {
        "varName": "totalEstimatedExpenses",
        "grid": 2,
        "type": "text",
        "label": "Total Estimated Expenses"
    },
    {
        "varName": "operation",
        "grid": 2,
        "type": "text",
        "label": "Operation"
    },
    {
        "varName": "medicines",
        "grid": 2,
        "type": "text",
        "label": "Medicines"
    },
    {
        "varName": "consuntingMedicalAdvice",
        "grid": 2,
        "type": "text",
        "label": "Consulting Medical Advice"
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 3,
        "type": "text",
        "label": "Boarding/Lodging Fee"
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 3,
        "type": "text",
        "label": "Total amount to be Remitted in Advance"
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 3,
        "type": "date",
        "label": "Last date of Remittance Advance amount"
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 3,
        "type": "text",
        "label": "Details of Foreign Currency Purchase"
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance"
    },
    {
        "varName": "relationshipWithPatient",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Patient"
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1"
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2"
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
    },

];
let cancelationRequestRemetance = [
    {
        "label": "Cancellation Request",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "relatedReferenceNumber",
        "grid": 2,
        "type": "text",
        "label": "Related reference number"
    },
    {
        "varName": "BeneficiaryAccountNumber",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account number",
    },
    {
        "varName": "beneficiaryAccountName",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account Name",
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
    },
    {
        "varName": "amount",
        "grid": 2,
        "type": "text",
        "label": "Amount",
    },
];
let letStudentFileRenew = [

    {
        "label": "Student File-Renew",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 4,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 4,
        "type": "text",
        "label": "Branch AD",
        "readOnly": true,
    },
    {
        "varName": "bookedBy",
        "grid": 4,
        "type": "text",
        "label": "Booked By ",
        "readOnly": true,
    },
    {
        "varName": "bookedByName",
        "grid": 4,
        "type": "text",
        "label": "Booked By Name ",
        "readOnly": true,
    },
    {
        "varName": "debitAcNumber",
        "grid": 4,
        "type": "text",
        "label": "Debit AC Number",
        "readOnly": true,
    },
    {
        "varName": "debitAcName",
        "grid": 4,
        "type": "text",
        "label": "Debit AC Name",
        "readOnly": true,
    },
    {
        "varName": "debitAcType",
        "grid": 4,
        "type": "text",
        "label": "Debit AC Type",
        "readOnly": true,
    },
    {
        "varName": "debitAcAddress",
        "grid": 4,
        "type": "text",
        "label": "Debit AC Address",
        "readOnly": true,
    },
    {
        "varName": "debitAcBranch",
        "grid": 4,
        "type": "text",
        "label": "Debit AC Branch",
        "readOnly": true,
    },
    {
        "varName": "referringAgency",
        "grid": 4,
        "type": "text",
        "label": "Referring Agency",
        "readOnly": true,
    },
    {
        "varName": "fileOpeningDate",
        "grid": 4,
        "type": "date",
        "label": "File Opening Date",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheApplicant",
        "grid": 4,
        "type": "text",
        "label": "Name of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheApplicant",
        "grid": 4,
        "type": "text",
        "label": "Address of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "grid": 4,
        "type": "date",
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 4,
        "type": "text",
        "label": "Age",
        "readOnly": true,
    },
    {
        "varName": "contactNo",
        "grid": 4,
        "type": "text",
        "label": "Contact No",
        "readOnly": true,
    },
    {
        "varName": "passport",
        "grid": 4,
        "type": "text",
        "label": "Passport",
        "readOnly": true,
    },
    {
        "varName": "dateOfIssue",
        "grid": 4,
        "type": "date",
        "label": "Date of Issue",
        "readOnly": true,
    },
    {
        "varName": "dateOfExpiry",
        "grid": 4,
        "type": "date",
        "label": "Date of Expiry",
        "readOnly": true,
    },
    {
        "varName": "placeOfIssue",
        "grid": 4,
        "type": "text",
        "label": "Place of Issue",
        "readOnly": true,
    },
    {
        "varName": "educationalQualification",
        "grid": 4,
        "type": "text",
        "label": "Educational Qualification",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheEducationalInstitute",
        "grid": 4,
        "type": "text",
        "label": "Name of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheEducationalInstitute",
        "grid": 4,
        "type": "text",
        "label": "Address of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "country",
        "grid": 4,
        "type": "text",
        "label": "Country",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheCourse",
        "grid": 4,
        "type": "text",
        "label": "Name of the Course ",
        "readOnly": true,
    },
    {
        "varName": "dateOfCommencement",
        "grid": 4,
        "type": "date",
        "label": "Date of Commencement",
        "readOnly": true,
    },
    {
        "varName": "durationOfTheCourse",
        "grid": 4,
        "type": "text",
        "label": "Duration of the Course",
        "readOnly": true,
    },
    {
        "varName": "currency",
        "grid": 4,
        "type": "text",
        "label": "Currency",
        "readOnly": true,
    },
    {
        "varName": "annualExpenses",
        "grid": 4,
        "type": "text",
        "label": "Annual Expenses",
        "readOnly": true,
    },
    {
        "varName": "tuitionFees",
        "grid": 4,
        "type": "text",
        "label": "Tuition Fees",
        "readOnly": true,
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 4,
        "type": "text",
        "label": "BoardingLodging Fee",
        "readOnly": true,
    },
    {
        "varName": "totalFee",
        "grid": 4,
        "type": "text",
        "label": "Total Fee",
        "readOnly": true,
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 4,
        "type": "text",
        "label": "Total amount to be Remitted in Advance",
        "readOnly": true,
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 4,
        "type": "date",
        "label": "Last date of Remittance Advance amount",
        "readOnly": true,
    },
    {
        "varName": "remittableAdvance",
        "grid": 4,
        "type": "text",
        "label": "Remittable advance",
        "readOnly": true,
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 4,
        "type": "text",
        "label": "Details of Foreign Currency Purchase",
        "readOnly": true,
    },
    {
        "varName": "relationshipWithStudent",
        "grid": 4,
        "type": "text",
        "label": "Relationship with Student",
        "readOnly": true,
    },
    {
        "varName": "freeField1",
        "grid": 4,
        "type": "text",
        "label": "Free Field 1",
        "readOnly": true,
    },
    {
        "varName": "freeField2",
        "grid": 4,
        "type": "text",
        "label": "Free Field 2",
        "readOnly": true,
    },
    {
        "varName": "freeField3",
        "grid": 4,
        "type": "text",
        "label": "Free Field 3",
        "readOnly": true,
    },

];*/
let StudentTypeNoFiledRemitance = [
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 2,
        "readOnly": true
    },
    {
        "varName": "source",
        "type": "select",
        "required": true,
        "enum": ["FINACLE", "ABABIL"],
        "onKeyDown": true,
        "label": "Source",
        "grid": 2,
    },
    {
        "varName": "remittanceAccountNumber",
        "type": "textApiCall",
        "label": "Remittance Account Number",
        "validation": "numeric",
        "required": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        "validation": "text",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"

    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "validation": "string",
        "multiline": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "readOnly": true,
        "multiline": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "readOnly": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "chargeAccountNumber",
        "type": "text",
        "validation": "numeric",
        "label": "Charge Account Number",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "NO"
    },
    {
        "varName": "currency",
        "type": "text",
        "label": "Currency",
        "required": true,
        "grid": 2,
        "validation": "length",
        "validationLengthType": "string",
        "maxLength": 3,
        "minLength": 3,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "amount",
        "type": "textApiCall",
        "label": "Amount",
        "validation": "numeric",
        // "validation": "compareValue",
        // "compareVarName": "accountBalance",
        // "compareLabel": "Account Balance",
        "required": true,
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalOrList": ["NO", "YES"]
    },
    {
        "varName": "swiftCode",
        "type": "select",
        "label": "SWIFT/Code (For FTT)",
        "enum": [
            "SWIFT",
            "Code"
        ],
        "onKeyDown": true,
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectRemType",
        "conditionalVarValue": "FTT",
    },
    // {
    //     "varName": "swiftCode",
    //     "type": "select",
    //     "label": "SWIFT/Code (For FTT)",
    //     "enum": [
    //         // "SWIFT",
    //         "Code"
    //     ],
    //     "required": true,
    //     "grid": 2,
    //     "conditional": true,
    //     "conditionalVarName": "selectRemType",
    //     "conditionalVarValue": "FDD",
    // },
    {
        "varName": "swiftCodeValue",
        "type": "textApiCall",
        "label": "",
        "required": true,
        "validation": "length",
        "validationLengthType": "string",
        "maxLength": 11,
        "minLength": 3,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "swiftCode"],
        "conditionalArrayValue": ["FTT", "SWIFT"]
    },
    {
        "varName": "swiftCodeValue",
        "type": "textApiCall",
        "label": "",
        "required": true,
        "validation": "length",
        "validationLengthType": "string",
        "maxLength": 11,
        "minLength": 3,
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "swiftCode"],
        "conditionalArrayValue": ["FTT", "Code"]
    },
    {
        "varName": "beneficiaryCountry",
        "type": "select",
        "label": "Beneficiary Country",
        "enum": [],
        "grid": 2,
        // "validateReadOnly": true,
        "required": true,
        // "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "showSwiftValue"],
        "conditionalArrayValue": ["FTT", true]
    },
    {
        "varName": "beneficiaryCountry",
        "type": "select",
        "label": "Beneficiary Country",
        "enum": [],
        "grid": 2,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType"],
        "conditionalArrayValue": ["FDD"]
    },
    {
        "varName": "beneficiaryBankName",
        "type": "text",
        "label": "Beneficiary Bank Name",
        "grid": 2,
        "readOnly": true,
        "validateReadOnly": true,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "showSwiftValue"],
        "conditionalArrayValue": ["FTT", true]
    },
    {
        "varName": "beneficiaryBankAddress",
        "type": "text",
        "label": "Beneficiary Bank Address",
        "grid": 2,
        // "validateReadOnly": true,
        "required": true,
        // "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "showSwiftValue"],
        "conditionalArrayValue": ["FTT", true]
    },
    {
        "varName": "chargesOutsideBD",
        "type": "select",
        "enum": ["From Remitter", "From Beneficiary", "Others"],
        "label": "Charges Outside BD",
        "grid": 2,
        "required": true,
        // "validation": "numeric",
        // "conditional": true,
        // "conditionalVarName": "selectStudentFileType",
        // "conditionalVarValue": "YES"
    }
    ,
    {
        "varName": "beneficiaryName",
        "type": "text",
        "label": "Beneficiary Name",
        "validation": "string",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    }
    ,
    {
        "varName": "beneficiaryAccount",
        "type": "text",
        "label": "Beneficiary Account",
        // "validation": "numeric",
        // "validation": "alphaNumeric",
        "grid": 2,
        "conditionalArray": true,
        "conditionalArrayList": ["selectRemType", "selectStudentFileType"],
        "conditionalArrayValue": ["FTT", "YES"],
    },
    {
        "varName": "remarks1",
        "type": "text",
        "label": "Remarks1",
        "multiline": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    }
    ,
    {
        "varName": "remarks2",
        "type": "text",
        "label": "Remarks2",
        "multiline": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    }
    ,
    {
        "varName": "purposeCode",
        "type": "text",
        "label": "Purpose Code",
        "grid": 2,
        "validation": "length",
        "minLength": 4,
        "maxLength": 4,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    }
    ,
    {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "multiline": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "selectStudentFileType",
        "conditionalVarValue": "YES"
    }
];

let fttFirstLayerFiledRemitance = [

    {
        "varName": "requestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "branch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "emailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }
];
let fddFirstLayerFiledRemitance = [

    {
        "varName": "requestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "branch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "emailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }
];
let outwardFirstLayerFiledRemitance = [
    {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 2,
    }
];

let fttJasonFormRemitanceMidLayer = [

    {
        "label": "Receiver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "reciverName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "multiline": true

    },/*  */{
        "varName": "reciverAccountNo",
        "type": "text",
        "label": "Account No",
        "grid": 2,

    }, /**/ {
        "varName": "swiftOrCode",
        "type": "select",
        "label": "Swift Or Code",
        "enum": [
            "Swift",
            "Code",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttswift",
        "type": "text",
        "grid": 2,
        "label": "Swift",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Swift",
    }, {
        "varName": "fttCode",
        "type": "text",
        "grid": 2,
        "label": "Code",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Code",
    },/*  */{
        "varName": "fttRemerks1",
        "type": "text",
        "label": "Remarks",
        "grid": 2,

    }, {
        "varName": "fttRemerks2",
        "type": "text",
        "grid": 2,

    },
    {
        "varName": "reciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "reciverAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "fttBankName",
        "type": "select",
        "label": "BankName",
        "enum": [
            "HSBC",
            "Citi",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "chargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    },
    {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, /**/ {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
        "required": true,
    },/* */ {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, /**/ {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "multiline": true
    }, /**/ {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
        "multiline": true
    },/* */ {
        "varName": "fttSenderPhone",
        "type": "text",
        "phonePrefix": true,
        "label": "Phone",
        "grid": 2,
    },
    {
        "varName": "fttPurposecode",
        "type": "select",
        "label": "Purpose Code",
        "enum": [
            "1122",
            "1120",
            "1121",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    },
    {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 2,
    },
    {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [],
        "grid": 2,
    },
    {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    },
    {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 2,
    },
];
let fddJasonFormRemitanceMidLayer = [

    {
        "label": "Reciver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fddreciverName",
        "type": "text",
        "label": "Name",
        "required": true,
        "grid": 2,
        "multiline": true

    }, /**/ {
        "varName": "fddRemerks",
        "type": "text",
        "label": "Remerks",
        "grid": 2,

    },
    {
        "varName": "fddreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "fddchargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    },
    {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
    }, {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 2,
    }, {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
    },
    {
        "varName": "fttSenderPhone",
        "type": "text",
        "phonePrefix": true,
        "label": "Phone",
        "grid": 2,
    },
    {
        "varName": "fddPrintBy",
        "type": "select",
        "label": "FDD Print By",
        "enum": [
            "Branch",
            "SD Maker",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "fddPurposeCode",
        "type": "text",
        "label": "Purpose Code",
        "grid": 2,
    }, {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    }, {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 2,
    }, {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [
            "My self",
        ],
        "required": true,
        "grid": 2,
    },

    {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    },
    {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 2,
    }, {
        "varName": "fddCheckNo",
        "type": "text",
        "label": "Check No",
        "grid": 2,
    }
];

let RemittanceServiceOtherJsonForm = [
    {
        "varName": "caseId",
        "type": "text",
        "label": "Case Id",
        "readOnly": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "validation": "numeric",
        "required": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "validation": "phone",
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },

];
let RemittanceServiceOtherJsonFormBOM = [
    {
        "varName": "caseId",
        "type": "text",
        "label": "Case ID",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 6,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 6,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "readOnly": true,
        "grid": 6,
    },

];

let StudentFileJsonForm = [
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 2,
        "readOnly": true
    },
    {
        "varName": "source",
        "type": "select",
        "required": true,
        "enum": ["FINACLE", "ABABIL"],
        "onKeyDown": true,
        "label": "Source",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "required": true,
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "accountType",
        "type": "text",
        "label": "Account Type",
        "readOnly": true,
        "grid": 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "schemeCodeDescription",
        "type": "text",
        "label": "Scheme Code Description",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "validation": "phone",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        // "validation": "numeric",
        "required": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "selectFileType",
        "conditionalVarValue": "Open",
        "grid": 2,
    },

    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        // "validation": "numeric",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "selectFileType",
        "conditionalVarValue": "Update"
    }, {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        // "validation": "numeric",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "selectFileType",
        "conditionalVarValue": "Close"
    },
    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        // "validation": "numeric",
        "grid": 2,
        "required":true,
        "conditional": true,
        "conditionalVarName": "selectFileType",
        "conditionalVarValue": "Renew",
    },
    {
        "varName": "studentName",
        "type": "text",
        "label": "Student Name",
        "validation": "string",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "courseCommencementDate",
        "type": "date",
        "label": "Course Commencement Date",
        "required": true,
        // "minDate": true,
        "grid": 2,
    },
    {
        "varName": "courseDuration",
        "type": "text",
        "label": "Course Duration",
        "required": true,
        // "validation": "alphaNumeric",
        "grid": 2,
    },
    {
        "varName": "bookedBy",
        "type": "text",
        "label": "Booked By",
        "validation": "numeric",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "agencyName",
        "type": "text",
        "label": "Agency Name",
        "validation": "string",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "University",
        "required": true,
        "validation": "string",
        "grid": 2,
    },
    {
        "varName": "tuitionFee",
        "type": "text",
        "label": "Tuition Fee",
        // "validation": "compareValue",
        // "compareVarName": "accountBalance",
        // "compareLabel": "Account Balance",
        "grid": 2,
    },
    {
        "varName": "livingCost",
        "type": "text",
        "label": "Living Cost",
        // "validation": "compareValue",
        // "compareVarName": "accountBalance",
        // "compareLabel": "Account Balance",
        "grid": 2,
    },
    {
        "varName": "otherCosts",
        "type": "text",
        "label": "Country",
        "required": true,
        "grid": 2,
    },
];

let MedicalFileJsonForm = [
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 2,
        "readOnly": true
    },
    {
        "varName": "source",
        "type": "select",
        "required": true,
        "enum": ["FINACLE", "ABABIL"],
        "onKeyDown": true,
        "label": "Source",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "label": "Account Number",
        "required": true,
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "fileNumber",
        "type": "text",
        "label": "File Number",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fileNumberApiCall",
        "conditionalVarValue": true,
        "grid": 2,
    },
    {
        "varName": "patientName",
        "type": "text",
        "label": "Patient Name",
        "validation": "string",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "Remittance Currency",
        "validation": "string",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "remittanceAmount",
        "type": "text",
        "label": "Remittance Amount",
        "validation": "numeric",
        // "validation": "compareValue",
        // "compareVarName": "accountBalance",
        // "compareLabel": "Account Balance",
        "required": true,
        "grid": 2,
    },
];
let MedicalFileJsonFormBM = [
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 2,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "readOnly": true,
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "fileNumber",
        "type": "text",
        "label": "File Number",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "patientName",
        "type": "text",
        "label": "Patient Name",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "Remittance Currency",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "remittanceAmount",
        "type": "text",
        "readOnly": true,
        "label": "Remittance Amount",
        "grid": 2,
    },
];
let MedicalFileJsonFormBOM = [
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "readOnly": true,
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 6,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 6,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "fileNumber",
        "type": "text",
        "label": "File Number",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "patientName",
        "type": "text",
        "label": "Patient Name",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "Remittance Currency",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "remittanceAmount",
        "type": "text",
        "readOnly": true,
        "label": "Remittance Amount",
        "grid": 6,
    },
];

let outwardJasonFormRemitanceMidLayer = [
    {
        "label": "Entity Info",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "owReferenceNo",
        "type": "text",
        "label": "Reference No",
        "required": true,
        "grid": 2,

    }, {
        "varName": "owBranch",
        "type": "select",
        "label": "Branch(AD)",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owBookedBy",
        "type": "text",
        "label": "Booked By",
        "grid": 2,

    }, {
        "varName": "owbookedByName",
        "type": "text",
        "label": "Booked By Name",
        "grid": 2,
    }, {
        "varName": "owDebitAcNumber",
        "type": "text",
        "label": "Debit Ac Number",
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcName",
        "type": "text",
        "label": "Debit Ac Name",
        "grid": 2,
    }, {
        "varName": "owDebitAcType",
        "type": "select",
        "label": "Debit Ac Type",
        "enum": [
            "Type 1",
            "Type 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcAddress",
        "type": "text",
        "label": "Debit Ac Address",
        "grid": 2,
        "multiline": true
    }, {
        "varName": "owDebitAcBranch",
        "type": "select",
        "label": "Debit Ac Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owReferringAgency",
        "type": "text",
        "label": "Referring Agency",
        "grid": 2,
    }, {
        "varName": "owFileOpeningDate",
        "type": "date",
        "label": "File Opening Date",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owApplicantName",
        "type": "text",
        "label": "Name Of The Applicant",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owApplicantAddress",
        "type": "text",
        "label": "Address Of The Applicant",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owApplicanteDateOfBirth",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owAge",
        "type": "text",
        "label": "Age",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owContactNo",
        "type": "text",
        "label": "Contact No",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPassportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 2,
    }, {
        "varName": "owIssuDate",
        "type": "date",
        "label": "Date Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owExpairyDate",
        "type": "date",
        "label": "Date Of Expairy",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPlaceOfIssue",
        "type": "text",
        "label": "Place Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owEducationalQualification",
        "type": "select",
        "label": "Educational Qualification",
        "enum": [
            "BBA",
            "MBA",
            "HSC",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owEducationalInstituteName",
        "type": "text",
        "label": "Name Of Educational Institute",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owAddressEducationalInstituteName",
        "type": "text",
        "label": "Address Of Educational Institute",
        "grid": 2,
        "required": true,
    },


    {
        "varName": "owreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owNameOfCourse",
        "type": "text",
        "label": "Name Of Course",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owDateOfCommencmante",
        "type": "date",
        "label": "Date Of Commencmante",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owDurationOfYar",
        "type": "select",
        "label": "Duration Of the course year",
        "enum": [
            "2019",
            "2020",
            "2021",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owDurationOfMarch",
        "type": "select",
        "label": "Duration Of the course month",
        "enum": [
            "Jan",
            "Feb",
            "March",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owCurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "INR",
            "USD",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owAnnualExpance",
        "type": "text",
        "label": "Annual Expance",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owTutionFee",
        "type": "text",
        "label": "Tution Fee",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owBordingLodgingFee",
        "type": "text",
        "label": "Bording Lodging Fee",
        "grid": 2,
        "required": true,
    }, {
        "varName": "tobeDeclaredLater",
        "type": "checkbox",
        "label": "To be declared Later",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owTotalFee",
        "type": "text",
        "label": "Total Fee",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owAmmontToBeRemitteInAdvance",
        "type": "text",
        "label": "Total Ammount to be Remmited in Advance",
        "grid": 2,
        "required": true,
    },

    {
        "varName": "owLastDateOfRemitanceAdvanceAmmount",
        "type": "date",
        "label": "Last Date Of Remittance Advance Amount",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owRemittableAdvance",
        "type": "text",
        "label": "Remittable Advance",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owDetailsOfForeignCurrencyPurchase",
        "type": "text",
        "label": "Details Of Foreign Currency Purchase",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owRelationshipWithStudent",
        "type": "select",
        "label": "Relationship With Student",
        "enum": [
            "Father",
            "Mother",
            "Brtoher",
            "Sister",
            "Cousine",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owFreeField1",
        "type": "text",
        "label": "Free Field 1",
        "grid": 2,
    }, {
        "varName": "owFreeField2",
        "type": "text",
        "label": "Free Field 2",
        "grid": 2,
    }, {
        "varName": "owFreeField3",
        "type": "text",
        "label": "Free Field 3",
        "grid": 2,
    },

];
let outwardMedicalFileRemetance = [
    {
        "label": "Medical File",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch (AD)"
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By "
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name "
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Number",
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Name"
    },
    {
        "varName": "debitAcTyp",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Typ"
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Address"
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Branch"
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency"
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date"
    },
    {
        "varName": "nameOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Name of the Patient"
    },
    {
        "varName": "addressOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Address of the Patient"
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age"
    },
    {
        "varName": "nationality",
        "grid": 2,
        "type": "select",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No."
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport"
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue"
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry"
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue"
    },
    {
        "varName": "hospitalmedicalCollegeName",
        "grid": 2,
        "type": "text",
        "label": "Hospital/Medical College Name"
    },
    {
        "varName": "addressOfTheHospitalmedicalCollege",
        "grid": 2,
        "type": "text",
        "label": "Address of the Hospital/Medical College"
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "nameOfTheDisease",
        "grid": 2,
        "type": "text",
        "label": "Name of the Disease"
    },
    {
        "varName": "periodContinuousResidencInBangladesh",
        "grid": 2,
        "type": "text",
        "label": "Period continuous residenc in Bangladesh"
    },
    {
        "varName": "nameOfTheTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Treatment"
    },
    {
        "varName": "nameOfTheInstitutionsdoctorsWhoHaveCariedOutTheAboveTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Institutions/Doctors who have caried out the above treatment"
    },
    {
        "varName": "treatmentContemplatedAndInWhichCountrycountries",
        "grid": 2,
        "type": "text",
        "label": "Treatment contemplated and in which country/countries"
    },
    {
        "varName": "anticipatedLengthOfStayAbroadForTreatment",
        "grid": 2,
        "type": "text",
        "label": "Anticipated length of stay abroad for treatment "
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "select",
        "label": "Currency",
        "enum": [
            "USD",
            "INR",
            "BDT",
        ],
    },
    {
        "varName": "totalEstimatedExpenses",
        "grid": 2,
        "type": "text",
        "label": "Total Estimated Expenses"
    },
    {
        "varName": "operation",
        "grid": 2,
        "type": "text",
        "label": "Operation"
    },
    {
        "varName": "medicines",
        "grid": 2,
        "type": "text",
        "label": "Medicines"
    },
    {
        "varName": "consuntingMedicalAdvice",
        "grid": 2,
        "type": "text",
        "label": "Consulting Medical Advice"
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "Boarding/Lodging Fee"
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance"
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount"
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase"
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance"
    },
    {
        "varName": "relationshipWithPatient",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Patient"
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1"
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2"
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
    },

];
let cancelationRequestRemetance = [
    {
        "label": "Cancellation Request",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "relatedReferenceNumber",
        "grid": 2,
        "type": "text",
        "label": "Related reference number"
    },
    {
        "varName": "BeneficiaryAccountNumber",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account number",
    },
    {
        "varName": "beneficiaryAccountName",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account Name",
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
    },
    {
        "varName": "amount",
        "grid": 2,
        "type": "text",
        "label": "Amount",
    },
];
let letStudentFileRenew = [

    {
        "label": "Student File-Renew",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch AD",
        "readOnly": true,
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By ",
        "readOnly": true,
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name ",
        "readOnly": true,
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Number",
        "readOnly": true,
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Name",
        "readOnly": true,
    },
    {
        "varName": "debitAcType",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Type",
        "readOnly": true,
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Address",
        "readOnly": true,
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Branch",
        "readOnly": true,
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency",
        "readOnly": true,
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Name of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Address of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age",
        "readOnly": true,
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No",
        "readOnly": true,
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport",
        "readOnly": true,
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue",
        "readOnly": true,
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry",
        "readOnly": true,
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue",
        "readOnly": true,
    },
    {
        "varName": "educationalQualification",
        "grid": 2,
        "type": "text",
        "label": "Educational Qualification",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Name of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Address of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Name of the Course ",
        "readOnly": true,
    },
    {
        "varName": "dateOfCommencement",
        "grid": 2,
        "type": "date",
        "label": "Date of Commencement",
        "readOnly": true,
    },
    {
        "varName": "durationOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Duration of the Course",
        "readOnly": true,
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
        "readOnly": true,
    },
    {
        "varName": "annualExpenses",
        "grid": 2,
        "type": "text",
        "label": "Annual Expenses",
        "readOnly": true,
    },
    {
        "varName": "tuitionFees",
        "grid": 2,
        "type": "text",
        "label": "Tuition Fees",
        "readOnly": true,
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "BoardingLodging Fee",
        "readOnly": true,
    },
    {
        "varName": "totalFee",
        "grid": 2,
        "type": "text",
        "label": "Total Fee",
        "readOnly": true,
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance",
        "readOnly": true,
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount",
        "readOnly": true,
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance",
        "readOnly": true,
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase",
        "readOnly": true,
    },
    {
        "varName": "relationshipWithStudent",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Student",
        "readOnly": true,
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1",
        "readOnly": true,
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2",
        "readOnly": true,
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
        "readOnly": true,
    },

];
let owtwardRemetance = [
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
        ],
        "label": "Select Remittance Type",
        "grid": 2,
    },
    {
        "varName": "selectStudentFileType",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Select Student File",
        "grid": 2,
    },
];

let fddJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify([

    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 2,
    }, {
        "varName": "fddrequestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "fddcurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddAmount",
        "type": "text",
        "label": "Amount",
        "required": true,
        "grid": 2,

    }, {
        "varName": "fddbranch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddemailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }, {
        "label": "Reciver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fddreciverName",
        "type": "text",
        "label": "Name",
        "required": true,
        "grid": 2,
        "multiline": true

    }, {
        "varName": "fddreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddRemerks",
        "type": "text",
        "label": "Remerks",
        "grid": 2,

    }, {
        "varName": "fddchargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    }, {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, {
        "varName": "fddPurposeCode",
        "type": "text",
        "label": "Purpose Code",
        "grid": 2,
    }, {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
    }, {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    }, {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 2,
    }, {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 2,
    }, {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [
            "My self",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
    }, {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    }, {
        "varName": "fttSenderPhone",
        "type": "text",
        "phonePrefix": true,
        "label": "Phone",
        "grid": 2,
    }, {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 2,
    }, {
        "varName": "fddPrintBy",
        "type": "select",
        "label": "FDD Print By",
        "enum": [
            "Branch",
            "SD Maker",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fddCheckNo",
        "type": "text",
        "label": "Check No",
        "grid": 2,
    }
])));

let outwardJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "selectFileType",
        "type": "select",
        "enum": [
            "Open",
            "Renew",
            "Update",
            "Close",
        ],
        "label": "Select File TYPE",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 2,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "required": true,
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "readOnly": true,
        "grid": 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "accountType",
        "type": "text",
        "label": "Account Type",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "validation": "phone",
        "readOnly": true,
        "grid": 2,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        "validation": "numeric",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "studentName",
        "type": "text",
        "label": "Student Name",
        "validation": "string",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "courseCommencementDate",
        "type": "date",
        "label": "Course Commencement Date",
        "required": true,
        "minDate": true,
        "grid": 2,
    },
    {
        "varName": "courseDuration",
        "type": "text",
        "label": "Course Duration",
        "required": true,
        "validation": "alphaNumeric",
        "grid": 2,
    },
    {
        "varName": "bookedBy",
        "type": "text",
        "label": "Booked By",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "varName": "agencyName",
        "type": "text",
        "label": "Agency Name",
        "validation": "string",
        "grid": 2,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "University",
        "validation": "string",
        "grid": 2,
    },
    {
        "varName": "tuitionFee",
        "type": "text",
        "label": "Tuition Fee",
        "grid": 2,
    },
    {
        "varName": "livingCost",
        "type": "text",
        "label": "Living Cost",
        "grid": 2,
    },
    {
        "varName": "otherCosts",
        "type": "text",
        "label": "Country",
        "grid": 2,
    },

])));
let cancellationRquestJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 2,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 2,
    }, {
        "label": "Cancellation Request",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "relatedReferenceNumber",
        "grid": 2,
        "type": "text",
        "label": "Related reference number"
    }, {
        "varName": "BeneficiaryAccountNumber",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account number",
    }, {
        "varName": "beneficiaryAccountName",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account Name",
    }, {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
    }, {
        "varName": "amount",
        "grid": 2,
        "type": "text",
        "label": "Amount",
    },
])));
let medicalFileJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 2,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 2,
    }, {
        "label": "Medical File Info",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch (AD)"
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By "
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name "
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Number",
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Name"
    },
    {
        "varName": "debitAcTyp",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Typ"
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Address"
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Branch"
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency"
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date"
    },
    {
        "varName": "nameOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Name of the Patient"
    },
    {
        "varName": "addressOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Address of the Patient"
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth"
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age"
    },
    {
        "varName": "nationality",
        "grid": 2,
        "type": "select",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No."
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport"
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue"
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry"
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue"
    },
    {
        "varName": "hospitalmedicalCollegeName",
        "grid": 2,
        "type": "text",
        "label": "Hospital/Medical College Name"
    },
    {
        "varName": "addressOfTheHospitalmedicalCollege",
        "grid": 2,
        "type": "text",
        "label": "Address of the Hospital/Medical College"
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    },
    {
        "varName": "nameOfTheDisease",
        "grid": 2,
        "type": "text",
        "label": "Name of the Disease"
    },
    {
        "varName": "periodContinuousResidencInBangladesh",
        "grid": 2,
        "type": "text",
        "label": "Period continuous residenc in Bangladesh"
    },
    {
        "varName": "nameOfTheTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Treatment"
    },
    {
        "varName": "nameOfTheInstitutionsdoctorsWhoHaveCariedOutTheAboveTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Institutions/Doctors who have caried out the above treatment"
    },
    {
        "varName": "treatmentContemplatedAndInWhichCountrycountries",
        "grid": 2,
        "type": "text",
        "label": "Treatment contemplated and in which country/countries"
    },
    {
        "varName": "anticipatedLengthOfStayAbroadForTreatment",
        "grid": 2,
        "type": "text",
        "label": "Anticipated length of stay abroad for treatment "
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "select",
        "label": "Currency",
        "enum": [
            "USD",
            "INR",
            "BDT",
        ],
    },
    {
        "varName": "totalEstimatedExpenses",
        "grid": 2,
        "type": "text",
        "label": "Total Estimated Expenses"
    },
    {
        "varName": "operation",
        "grid": 2,
        "type": "text",
        "label": "Operation"
    },
    {
        "varName": "medicines",
        "grid": 2,
        "type": "text",
        "label": "Medicines"
    },
    {
        "varName": "consuntingMedicalAdvice",
        "grid": 2,
        "type": "text",
        "label": "Consulting Medical Advice"
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "Boarding/Lodging Fee"
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance"
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount"
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase"
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance"
    },
    {
        "varName": "relationshipWithPatient",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Patient"
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1"
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2"
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
    },

])));
let studentFileRenewJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify([

    {
        "label": "Student File-Renew",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 2,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch AD",
        "readOnly": true,
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By ",
        "readOnly": true,
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name ",
        "readOnly": true,
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Number",
        "readOnly": true,
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Name",
        "readOnly": true,
    },
    {
        "varName": "debitAcType",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Type",
        "readOnly": true,
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Address",
        "readOnly": true,
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Branch",
        "readOnly": true,
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency",
        "readOnly": true,
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Name of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Address of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age",
        "readOnly": true,
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No",
        "readOnly": true,
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport",
        "readOnly": true,
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue",
        "readOnly": true,
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry",
        "readOnly": true,
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue",
        "readOnly": true,
    },
    {
        "varName": "educationalQualification",
        "grid": 2,
        "type": "text",
        "label": "Educational Qualification",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Name of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Address of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Name of the Course ",
        "readOnly": true,
    },
    {
        "varName": "dateOfCommencement",
        "grid": 2,
        "type": "date",
        "label": "Date of Commencement",
        "readOnly": true,
    },
    {
        "varName": "durationOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Duration of the Course",
        "readOnly": true,
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
        "readOnly": true,
    },
    {
        "varName": "annualExpenses",
        "grid": 2,
        "type": "text",
        "label": "Annual Expenses",
        "readOnly": true,
    },
    {
        "varName": "tuitionFees",
        "grid": 2,
        "type": "text",
        "label": "Tuition Fees",
        "readOnly": true,
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "BoardingLodging Fee",
        "readOnly": true,
    },
    {
        "varName": "totalFee",
        "grid": 2,
        "type": "text",
        "label": "Total Fee",
        "readOnly": true,
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance",
        "readOnly": true,
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount",
        "readOnly": true,
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance",
        "readOnly": true,
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase",
        "readOnly": true,
    },
    {
        "varName": "relationshipWithStudent",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Student",
        "readOnly": true,
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1",
        "readOnly": true,
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2",
        "readOnly": true,
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
        "readOnly": true,
    },

])));

let remittanceJsonForStudent = [
    {
        "varName": "totalStudentFileAmount",
        "type": "text",
        "required": true,
        "label": "Total Student Amount",
        "grid": 6,
    },
    {
        "varName": "incomeAmount",
        "type": "text",
        "required": true,
        "label": "Income Amount",
        "validation": "numeric",
        "grid": 6,
    },
    {
        "varName": "vatAmount",
        "type": "text",
        "required": true,
        "label": "Vat Amount",
        "validation": "numeric",
        "grid": 6,
    },
];

let remittanceJsonForStudentReadOnly = {};
remittanceJsonForStudentReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(remittanceJsonForStudent)));

let outwardJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "selectFileType",
        "type": "select",
        "enum": [
            "Open",
            "Renew",
            "Update",
            "Close",
        ],
        "label": "Select File TYPE",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "caseId",
        "label": "Case ID",
        "type": "text",
        "grid": 6,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
    },
    {
        "varName": "name",
        "type": "text",
        "label": "Name",
        "grid": 6,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 6,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "varName": "accountType",
        "type": "text",
        "label": "Account Type",
        "grid": 6,
    },
    {
        "varName": "addressOne",
        "type": "text",
        "label": "Address Line 1",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "addressTwo",
        "type": "text",
        "label": "Address Line 2",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "schemeCodeDescription",
        "type": "text",
        "label": "Scheme Code Description",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "contactNumber",
        "type": "text",
        "label": "Contact Number",
        "grid": 6,
    },
    {
        "varName": "adBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "grid": 6,
    },
    {
        "varName": "studentFileNumber",
        "type": "text",
        "label": "Student File Number",
        "grid": 6,
    },
    {
        "varName": "studentName",
        "type": "text",
        "label": "Student Name",
        "grid": 6,
    },
    {
        "varName": "courseCommencementDate",
        "type": "date",
        "label": "Course Commencement Date",
        "grid": 6,
    },
    {
        "varName": "courseDuration",
        "type": "text",
        "label": "Course Duration",
        "grid": 6,
    },
    {
        "varName": "bookedBy",
        "type": "text",
        "label": "Booked By",
        "grid": 6,
    },
    {
        "varName": "agencyName",
        "type": "text",
        "label": "Agency Name",
        "grid": 6,
    },
    {
        "varName": "remittanceCurrency",
        "type": "text",
        "label": "University",
        "grid": 6,
    },
    {
        "varName": "tuitionFee",
        "type": "text",
        "label": "Tuition Fee",
        "grid": 6,
    },
    {
        "varName": "livingCost",
        "type": "text",
        "label": "Living Cost",
        "grid": 6,
    },
    {
        "varName": "otherCosts",
        "type": "text",
        "label": "Country",
        "grid": 6,
    },
])));

let fddJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify([

    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 6,
    }, {
        "varName": "fddrequestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 6,

    }, {
        "varName": "fddcurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "fddAmount",
        "type": "text",
        "label": "Amount",
        "required": true,
        "validation": "numeric",
        "grid": 6,

    }, {
        "varName": "fddbranch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "fddemailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 6,
    }, {
        "label": "Reciver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fddreciverName",
        "type": "text",
        "label": "Name",
        "required": true,
        "grid": 6,
        "multiline": true

    }, {
        "varName": "fddreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "fddRemerks",
        "type": "text",
        "label": "Remerks",
        "grid": 6,

    }, {
        "varName": "fddchargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 6,
    }, {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 6,
    }, {
        "varName": "fddPurposeCode",
        "type": "text",
        "label": "Purpose Code",
        "grid": 6,
    }, {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 6,
    }, {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 6,
    }, {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 6,
    }, {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 6,
    }, {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 6,
    }, {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [
            "My self",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 6,
    }, {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 6,
    }, {
        "varName": "fttSenderPhone",
        "type": "text",
        "phonePrefix": true,
        "label": "Phone",
        "grid": 6,
    }, {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 6,
    }, {
        "varName": "fddPrintBy",
        "type": "select",
        "label": "FDD Print By",
        "enum": [
            "Branch",
            "SD Maker",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "fddCheckNo",
        "type": "text",
        "label": "Check No",
        "grid": 6,
    }
])));

let cancellationRquestJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "label": "Cancellation Request",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 6,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 6,
    }, {
        "varName": "relatedReferenceNumber",
        "grid": 6,
        "type": "text",
        "label": "Related reference number"
    },
    {
        "varName": "BeneficiaryAccountNumber",
        "grid": 6,
        "type": "text",
        "label": "Beneficiary account number",
    },
    {
        "varName": "beneficiaryAccountName",
        "grid": 6,
        "type": "text",
        "label": "Beneficiary account Name",
    },
    {
        "varName": "currency",
        "grid": 6,
        "type": "text",
        "label": "Currency",
    },
    {
        "varName": "amount",
        "grid": 6,
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
    },
])));
let medicalFileJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "label": "Medical File",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 6,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 6,
    },
    {
        "varName": "referenceNo",
        "grid": 6,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 6,
        "type": "text",
        "label": "Branch (AD)"
    },
    {
        "varName": "bookedBy",
        "grid": 6,
        "type": "text",
        "label": "Booked By "
    },
    {
        "varName": "bookedByName",
        "grid": 6,
        "type": "text",
        "label": "Booked By Name "
    },
    {
        "varName": "debitAccountNumber",
        "grid": 6,
        "type": "textApiCall",
        "onKeyDown": true,
        "label": "Debit A/C Number",
    },
    /* {
         "varName": "debitAcName",
         "grid": 6,
         "type": "text",
         "label": "Debit A/C Name"
     },*/
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },
    {
        "varName": "debitAcTyp",
        "grid": 6,
        "type": "text",
        "label": "Debit A/C Typ"
    },
    {
        "varName": "debitAcAddress",
        "grid": 6,
        "type": "text",
        "label": "Debit A/C Address"
    },
    {
        "varName": "debitAcBranch",
        "grid": 6,
        "type": "text",
        "label": "Debit A/C Branch"
    },
    {
        "varName": "referringAgency",
        "grid": 6,
        "type": "text",
        "label": "Referring Agency"
    },
    {
        "varName": "fileOpeningDate",
        "grid": 6,
        "type": "date",
        "label": "File Opening Date"
    },
    {
        "varName": "nameOfThePatient",
        "grid": 6,
        "type": "text",
        "label": "Name of the Patient"
    },
    {
        "varName": "addressOfThePatient",
        "grid": 6,
        "type": "text",
        "label": "Address of the Patient"
    },
    {
        "varName": "dateOfBirth",
        "grid": 6,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth"
    },
    {
        "varName": "age",
        "grid": 6,
        "type": "text",
        "label": "Age"
    },
    {
        "varName": "nationality",
        "grid": 6,
        "type": "text",
        "label": "Nationality"
    },
    {
        "varName": "contactNo",
        "grid": 6,
        "type": "text",
        "label": "Contact No."
    },
    {
        "varName": "passport",
        "grid": 6,
        "type": "text",
        "label": "Passport"
    },
    {
        "varName": "dateOfIssue",
        "grid": 6,
        "type": "date",
        "label": "Date of Issue"
    },
    {
        "varName": "dateOfExpiry",
        "grid": 6,
        "type": "date",
        "label": "Date of Expiry"
    },
    {
        "varName": "placeOfIssue",
        "grid": 6,
        "type": "text",
        "label": "Place of Issue"
    },
    {
        "varName": "hospitalmedicalCollegeName",
        "grid": 6,
        "type": "text",
        "label": "Hospital/Medical College Name"
    },
    {
        "varName": "addressOfTheHospitalmedicalCollege",
        "grid": 6,
        "type": "text",
        "label": "Address of the Hospital/Medical College"
    },
    {
        "varName": "country",
        "grid": 6,
        "type": "text",
        "label": "Country"
    },
    {
        "varName": "nameOfTheDisease",
        "grid": 6,
        "type": "text",
        "label": "Name of the Disease"
    },
    {
        "varName": "periodContinuousResidencInBangladesh",
        "grid": 6,
        "type": "text",
        "label": "Period continuous residenc in Bangladesh"
    },
    {
        "varName": "nameOfTheTreatment",
        "grid": 6,
        "type": "text",
        "label": "Name of the Treatment"
    },
    {
        "varName": "nameOfTheInstitutionsdoctorsWhoHaveCariedOutTheAboveTreatment",
        "grid": 6,
        "type": "text",
        "label": "Name of the Institutions/Doctors who have caried out the above treatment"
    },
    {
        "varName": "treatmentContemplatedAndInWhichCountrycountries",
        "grid": 6,
        "type": "text",
        "label": "Treatment contemplated and in which country/countries"
    },
    {
        "varName": "anticipatedLengthOfStayAbroadForTreatment",
        "grid": 6,
        "type": "text",
        "label": "Anticipated length of stay abroad for treatment "
    },
    {
        "varName": "currency",
        "grid": 6,
        "type": "text",
        "label": "Currency"
    },
    {
        "varName": "totalEstimatedExpenses",
        "grid": 6,
        "type": "text",
        "label": "Total Estimated Expenses"
    },
    {
        "varName": "operation",
        "grid": 6,
        "type": "text",
        "label": "Operation"
    },
    {
        "varName": "medicines",
        "grid": 6,
        "type": "text",
        "label": "Medicines"
    },
    {
        "varName": "consuntingMedicalAdvice",
        "grid": 6,
        "type": "text",
        "label": "Consunting Medical Advice"
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 6,
        "type": "text",
        "label": "Boarding/Lodging Fee"
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 6,
        "type": "text",
        "label": "Total amount to be Remitted in Advance"
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 6,
        "type": "date",
        "label": "Last date of Remittance Advance amount"
    },
    {
        "varName": "remittableAdvance",
        "grid": 6,
        "type": "text",
        "label": "Remittable advance"
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 6,
        "type": "text",
        "label": "Details of Foreign Currency Purchase"
    },
    {
        "varName": "relationshipWithPatient",
        "grid": 6,
        "type": "text",
        "label": "Relationship with Patient"
    },
    {
        "varName": "freeField1",
        "grid": 6,
        "type": "text",
        "label": "Free Field 1"
    },
    {
        "varName": "freeField2",
        "grid": 6,
        "type": "text",
        "label": "Free Field 2"
    },
    {
        "varName": "freeField3",
        "grid": 6,
        "type": "text",
        "label": "Free Field 3",
    },

])));
let studentFileRenewJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify([

    {
        "label": "Student File-Renew",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
            "Outward"
        ],
        "label": "SELECT Remittance TYPE",
        "grid": 6,
    }, {
        "varName": "entityCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Student File",
            "Medical File",
            "Cancellation Request",
            "Student File-Renew",
        ],
        "required": true,
        "grid": 6,
    },
    {
        "varName": "referenceNo",
        "grid": 6,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 6,
        "type": "text",
        "label": "Branch AD",
        "readOnly": true,
    },
    {
        "varName": "bookedBy",
        "grid": 6,
        "type": "text",
        "label": "Booked By ",
        "readOnly": true,
    },
    {
        "varName": "bookedByName",
        "grid": 6,
        "type": "text",
        "label": "Booked By Name ",
        "readOnly": true,
    },
    {
        "varName": "debitAcNumber",
        "grid": 6,
        "type": "text",
        "label": "Debit AC Number",
        "readOnly": true,
    },
    {
        "varName": "debitAcName",
        "grid": 6,
        "type": "text",
        "label": "Debit AC Name",
        "readOnly": true,
    },
    {
        "varName": "debitAcType",
        "grid": 6,
        "type": "text",
        "label": "Debit AC Type",
        "readOnly": true,
    },
    {
        "varName": "debitAcAddress",
        "grid": 6,
        "type": "text",
        "label": "Debit AC Address",
        "readOnly": true,
    },
    {
        "varName": "debitAcBranch",
        "grid": 6,
        "type": "text",
        "label": "Debit AC Branch",
        "readOnly": true,
    },
    {
        "varName": "referringAgency",
        "grid": 6,
        "type": "text",
        "label": "Referring Agency",
        "readOnly": true,
    },
    {
        "varName": "fileOpeningDate",
        "grid": 6,
        "type": "date",
        "label": "File Opening Date",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheApplicant",
        "grid": 6,
        "type": "text",
        "label": "Name of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheApplicant",
        "grid": 6,
        "type": "text",
        "label": "Address of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "grid": 6,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 6,
        "type": "text",
        "label": "Age",
        "readOnly": true,
    },
    {
        "varName": "contactNo",
        "grid": 6,
        "type": "text",
        "label": "Contact No",
        "readOnly": true,
    },
    {
        "varName": "passport",
        "grid": 6,
        "type": "text",
        "label": "Passport",
        "readOnly": true,
    },
    {
        "varName": "dateOfIssue",
        "grid": 6,
        "type": "date",
        "label": "Date of Issue",
        "readOnly": true,
    },
    {
        "varName": "dateOfExpiry",
        "grid": 6,
        "type": "date",
        "label": "Date of Expiry",
        "readOnly": true,
    },
    {
        "varName": "placeOfIssue",
        "grid": 6,
        "type": "text",
        "label": "Place of Issue",
        "readOnly": true,
    },
    {
        "varName": "educationalQualification",
        "grid": 6,
        "type": "text",
        "label": "Educational Qualification",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheEducationalInstitute",
        "grid": 6,
        "type": "text",
        "label": "Name of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheEducationalInstitute",
        "grid": 6,
        "type": "text",
        "label": "Address of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "country",
        "grid": 6,
        "type": "text",
        "label": "Country",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheCourse",
        "grid": 6,
        "type": "text",
        "label": "Name of the Course ",
        "readOnly": true,
    },
    {
        "varName": "dateOfCommencement",
        "grid": 6,
        "type": "date",
        "label": "Date of Commencement",
        "readOnly": true,
    },
    {
        "varName": "durationOfTheCourse",
        "grid": 6,
        "type": "text",
        "label": "Duration of the Course",
        "readOnly": true,
    },
    {
        "varName": "currency",
        "grid": 6,
        "type": "text",
        "label": "Currency",
        "readOnly": true,
    },
    {
        "varName": "annualExpenses",
        "grid": 6,
        "type": "text",
        "label": "Annual Expenses",
        "readOnly": true,
    },
    {
        "varName": "tuitionFees",
        "grid": 6,
        "type": "text",
        "label": "Tuition Fees",
        "readOnly": true,
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 6,
        "type": "text",
        "label": "BoardingLodging Fee",
        "readOnly": true,
    },
    {
        "varName": "totalFee",
        "grid": 6,
        "type": "text",
        "label": "Total Fee",
        "readOnly": true,
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 6,
        "type": "text",
        "label": "Total amount to be Remitted in Advance",
        "readOnly": true,
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 6,
        "type": "date",
        "label": "Last date of Remittance Advance amount",
        "readOnly": true,
    },
    {
        "varName": "remittableAdvance",
        "grid": 6,
        "type": "text",
        "label": "Remittable advance",
        "readOnly": true,
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 6,
        "type": "text",
        "label": "Details of Foreign Currency Purchase",
        "readOnly": true,
    },
    {
        "varName": "relationshipWithStudent",
        "grid": 6,
        "type": "text",
        "label": "Relationship with Student",
        "readOnly": true,
    },
    {
        "varName": "freeField1",
        "grid": 6,
        "type": "text",
        "label": "Free Field 1",
        "readOnly": true,
    },
    {
        "varName": "freeField2",
        "grid": 6,
        "type": "text",
        "label": "Free Field 2",
        "readOnly": true,
    },
    {
        "varName": "freeField3",
        "grid": 6,
        "type": "text",
        "label": "Free Field 3",
        "readOnly": true,
    },

])));

let CSfttJasonFormRemitance = [

    {
        "label": "FTT Info",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "requestDate",
        "type": "date",
        "label": "Request Date",
        "required": true,
        "grid": 2,

    }, {
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "CDD",
            "BDT",
            "USD",
            "USD",
            "INR",
            "EUR",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "branch",
        "type": "select",
        "label": "Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "emailCategory",
        "type": "select",
        "label": "Entity Category",
        "enum": [
            "Others",
        ],
        "required": true,
        "grid": 2,
    }, {
        "label": "Receiver Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "reciverName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "multiline": true

    },/*  */{
        "varName": "reciverAccountNo",
        "type": "text",
        "label": "Account No",
        "grid": 2,

    }, /**/ {
        "varName": "swiftOrCode",
        "type": "select",
        "label": "Swift Or Code",
        "enum": [
            "Swift",
            "Code",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "fttswift",
        "type": "text",
        "grid": 2,
        "label": "Swift",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Swift",
    }, {
        "varName": "fttCode",
        "type": "text",
        "grid": 2,
        "label": "Code",
        "conditional": true,
        "conditionalVarName": "swiftOrCode",
        "conditionalVarValue": "Code",
    },/*  */{
        "varName": "fttRemerks1",
        "type": "text",
        "label": "Remarks",
        "grid": 2,

    }, {
        "varName": "fttRemerks2",
        "type": "text",
        "grid": 2,

    },
    {
        "varName": "reciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "reciverAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
        "multiline": true

    },
    {
        "varName": "fttBankName",
        "type": "select",
        "label": "BankName",
        "enum": [
            "HSBC",
            "Citi",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "chargesOutside",
        "type": "select",
        "label": "Charges Outside Bangladesh",
        "enum": [
            "Our",
        ],
        "grid": 2,
    },
    {
        "label": "Sender Information",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "fttCustomer",
        "type": "checkbox",
        "label": "Customer",
        "grid": 2,
    }, /**/ {
        "varName": "fttRealizationAcNo",
        "type": "text",
        "label": "Realization AC No",
        "grid": 2,
        "required": true,
    },/* */ {
        "varName": "fttChargeAcno",
        "type": "text",
        "label": "Charge Account No",
        "grid": 2,
    }, /**/ {
        "varName": "fttSenderName",
        "type": "text",
        "label": "Name",
        "grid": 2,
        "multiline": true
    }, /**/ {
        "varName": "fttSenderAddress",
        "type": "text",
        "label": "Address",
        "grid": 2,
        "multiline": true
    },/* */ {
        "varName": "fttSenderPhone",
        "type": "text",
        "phonePrefix": true,
        "label": "Phone",
        "grid": 2,
    },
    {
        "varName": "fttPurposecode",
        "type": "select",
        "label": "Purpose Code",
        "enum": [
            "1122",
            "1120",
            "1121",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "fttApprovalNo",
        "type": "text",
        "label": "Approval No",
        "grid": 2,
    },
    {
        "varName": "fttApprovalDate",
        "type": "date",
        "label": "Approval Date",
        "grid": 2,
    },
    {
        "varName": "fttDraftCollectedBy",
        "type": "select",
        "label": "Draft Will Be Collected By",
        "enum": [],
        "grid": 2,
    },
    {
        "varName": "fttRepresentative",
        "type": "text",
        "label": "Representative",
        "grid": 2,
    },
    {
        "varName": "para",
        "type": "text",
        "label": "Para",
        "grid": 2,
    },
]; //done amount field missing
let CSfddJasonFormRemitance = [
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
        ],
        "onKeyDown": true,
        "label": "Select Remittance Type",
        "grid": 2,
    },
    {
        "varName": "selectStudentFileType",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Select Student File",
        "grid": 2,
    },
].concat(StudentTypeNoFiledRemitance);
let fttJasonFormRemitanceBM = makeReadOnlyObject(JSON.parse(JSON.stringify(CSfddJasonFormRemitance)));
let fttJasonFormRemitanceBOM = makeReadOnlyObject(JSON.parse(JSON.stringify(makeGridObject(fttJasonFormRemitanceBM))));
let CSoutwardJasonFormRemitance = [
    {
        "label": "Entity Info",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "owReferenceNo",
        "type": "text",
        "label": "Reference No",
        "required": true,
        "grid": 2,

    }, {
        "varName": "owBranch",
        "type": "select",
        "label": "Branch(AD)",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owBookedBy",
        "type": "text",
        "label": "Booked By",
        "grid": 2,

    }, {
        "varName": "owbookedByName",
        "type": "text",
        "label": "Booked By Name",
        "grid": 2,
    }, {
        "varName": "owDebitAcNumber",
        "type": "text",
        "label": "Debit Ac Number",
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcName",
        "type": "text",
        "label": "Debit Ac Name",
        "grid": 2,
    }, {
        "varName": "owDebitAcType",
        "type": "select",
        "label": "Debit Ac Type",
        "enum": [
            "Type 1",
            "Type 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owDebitAcAddress",
        "type": "text",
        "label": "Debit Ac Address",
        "grid": 2,
        "multiline": true
    }, {
        "varName": "owDebitAcBranch",
        "type": "select",
        "label": "Debit Ac Branch",
        "enum": [
            "Motijhil",
            "Gulshan 1",
            "Gulshan 2",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owReferringAgency",
        "type": "text",
        "label": "Referring Agency",
        "grid": 2,
    }, {
        "varName": "owFileOpeningDate",
        "type": "date",
        "label": "File Opening Date",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owApplicantName",
        "type": "text",
        "label": "Name Of The Applicant",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owApplicantAddress",
        "type": "text",
        "label": "Address Of The Applicant",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owApplicanteDateOfBirth",
        "type": "date",
        "maxDate": true,
        "label": "Date Of Birth",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owAge",
        "type": "text",
        "label": "Age",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owContactNo",
        "type": "text",
        "label": "Contact No",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPassportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 2,
    }, {
        "varName": "owIssuDate",
        "type": "date",
        "label": "Date Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owExpairyDate",
        "type": "date",
        "label": "Date Of Expairy",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owPlaceOfIssue",
        "type": "text",
        "label": "Place Of Issue",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owEducationalQualification",
        "type": "select",
        "label": "Educational Qualification",
        "enum": [
            "BBA",
            "MBA",
            "HSC",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owEducationalInstituteName",
        "type": "text",
        "label": "Name Of Educational Institute",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owAddressEducationalInstituteName",
        "type": "text",
        "label": "Address Of Educational Institute",
        "grid": 2,
        "required": true,
    },


    {
        "varName": "owreciverCountry",
        "type": "select",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owNameOfCourse",
        "type": "text",
        "label": "Name Of Course",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owDateOfCommencmante",
        "type": "date",
        "label": "Date Of Commencmante",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owDurationOfYar",
        "type": "select",
        "label": "Duration Of the course year",
        "enum": [
            "2019",
            "2020",
            "2021",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owDurationOfMarch",
        "type": "select",
        "label": "Duration Of the course month",
        "enum": [
            "Jan",
            "Feb",
            "March",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owCurrency",
        "type": "select",
        "label": "Currency",
        "enum": [
            "BDT",
            "INR",
            "USD",
        ],
        "required": true,
        "grid": 2,
    },
    {
        "varName": "owAnnualExpance",
        "type": "text",
        "label": "Annual Expance",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owTutionFee",
        "type": "text",
        "label": "Tution Fee",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owBordingLodgingFee",
        "type": "text",
        "label": "Bording Lodging Fee",
        "grid": 2,
        "required": true,
    }, {
        "varName": "tobeDeclaredLater",
        "type": "checkbox",
        "label": "To be declared Later",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owTotalFee",
        "type": "text",
        "label": "Total Fee",
        "grid": 2,
        "required": true,
    },
    {
        "varName": "owAmmontToBeRemitteInAdvance",
        "type": "text",
        "label": "Total Ammount to be Remmited in Advance",
        "grid": 2,
        "required": true,
    },

    {
        "varName": "owLastDateOfRemitanceAdvanceAmmount",
        "type": "date",
        "label": "Last Date Of Remittance Advance Amount",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owRemittableAdvance",
        "type": "text",
        "label": "Remittable Advance",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owDetailsOfForeignCurrencyPurchase",
        "type": "text",
        "label": "Details Of Foreign Currency Purchase",
        "grid": 2,
        "required": true,
    }, {
        "varName": "owRelationshipWithStudent",
        "type": "select",
        "label": "Relationship With Student",
        "enum": [
            "Father",
            "Mother",
            "Brtoher",
            "Sister",
            "Cousine",
        ],
        "required": true,
        "grid": 2,
    }, {
        "varName": "owFreeField1",
        "type": "text",
        "label": "Free Field 1",
        "grid": 2,
    }, {
        "varName": "owFreeField2",
        "type": "text",
        "label": "Free Field 2",
        "grid": 2,
    }, {
        "varName": "owFreeField3",
        "type": "text",
        "label": "Free Field 3",
        "grid": 2,
    },

]; //done
let CSinWardJasonFormRemitance = [
    {
        "varName": "caseId",
        "type": "text",
        "label": "Case ID",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwNonAdBranch",
        "type": "text",
        "label": "Non-AD Branch",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwAdBranch",
        "type": "text",
        "label": "AD Branch",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwBeneficiaryAccount",
        "type": "text",
        "label": "Beneficiary Account",
        "grid": 3,
        "readOnly": true,
        "multiline": true,
    },
    {
        "varName": "iwBeneficiaryName",
        "type": "text",
        "label": "Beneficiary Name",
        "grid": 3,
        "readOnly": true,
        "multiline": true,
    },
    {
        "varName": "iwRemitter",
        "type": "text",
        "label": "Remitter",
        "grid": 3,
        "readOnly": true,
        "multiline": true,
    },
    {
        "varName": "iwAccountCurrency",
        "type": "text",
        "label": "Currency",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwAmount",
        "type": "text",
        "label": "Amount",
        "validation": "numeric",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwPurposeMentionedInTheSWIFT",
        "type": "text",
        "label": "Purpose mentioned in the SWIFT",
        "grid": 3,
        "readOnly": true,
        "multiline": true,
    },
    {
        "varName": "iwNostroBank",
        "type": "text",
        "label": "Nostro Bank",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "iwReferenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 3,
        "readOnly": true,
    },
];
let BMinWardJasonFormRemitance = CSinWardJasonFormRemitance;
let BOMinWardJasonFormRemitance = makeReadOnlyObject(makeGridObject(JSON.parse(JSON.stringify(CSinWardJasonFormRemitance))));

let CScancellationRquestJasonFormRemitance = [
    {
        "label": "Cancellation Request",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "relatedReferenceNumber",
        "grid": 2,
        "type": "text",
        "label": "Related reference number"
    }, {
        "varName": "BeneficiaryAccountNumber",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account number",
    }, {
        "varName": "beneficiaryAccountName",
        "grid": 2,
        "type": "text",
        "label": "Beneficiary account Name",
    }, {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
    }, {
        "varName": "amount",
        "grid": 2,
        "type": "text",
        "label": "Amount",
    },
];
let CSmedicalFileJasonFormRemitance = [
    {
        "label": "Medical File",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch (AD)"
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By "
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name "
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Number",
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Name"
    },
    {
        "varName": "debitAcTyp",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Typ"
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Address"
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit A/C Branch"
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency"
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date"
    },
    {
        "varName": "nameOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Name of the Patient"
    },
    {
        "varName": "addressOfThePatient",
        "grid": 2,
        "type": "text",
        "label": "Address of the Patient"
    }, {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth"
    }, {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age"
    }, {
        "varName": "nationality",
        "grid": 2,
        "type": "select",
        "label": "Nationality",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    }, {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No."
    }, {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport"
    }, {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue"
    }, {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry"
    }, {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue"
    }, {
        "varName": "hospitalmedicalCollegeName",
        "grid": 2,
        "type": "text",
        "label": "Hospital/Medical College Name"
    }, {
        "varName": "addressOfTheHospitalmedicalCollege",
        "grid": 2,
        "type": "text",
        "label": "Address of the Hospital/Medical College"
    }, {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "enum": [
            "Bangladesh",
            "India",
            "Pakistan",
            "US",
            "Canada",
        ],
    }, {
        "varName": "nameOfTheDisease",
        "grid": 2,
        "type": "text",
        "label": "Name of the Disease"
    }, {
        "varName": "periodContinuousResidencInBangladesh",
        "grid": 2,
        "type": "text",
        "label": "Period continuous residenc in Bangladesh"
    }, {
        "varName": "nameOfTheTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Treatment"
    }, {
        "varName": "nameOfTheInstitutionsdoctorsWhoHaveCariedOutTheAboveTreatment",
        "grid": 2,
        "type": "text",
        "label": "Name of the Institutions/Doctors who have caried out the above treatment"
    }, {
        "varName": "treatmentContemplatedAndInWhichCountrycountries",
        "grid": 2,
        "type": "text",
        "label": "Treatment contemplated and in which country/countries"
    }, {
        "varName": "anticipatedLengthOfStayAbroadForTreatment",
        "grid": 2,
        "type": "text",
        "label": "Anticipated length of stay abroad for treatment "
    }, {
        "varName": "currency",
        "grid": 2,
        "type": "select",
        "label": "Currency",
        "enum": [
            "USD",
            "INR",
            "BDT",
        ],
    }, {
        "varName": "totalEstimatedExpenses",
        "grid": 2,
        "type": "text",
        "label": "Total Estimated Expenses"
    }, {
        "varName": "operation",
        "grid": 2,
        "type": "text",
        "label": "Operation"
    }, {
        "varName": "medicines",
        "grid": 2,
        "type": "text",
        "label": "Medicines"
    }, {
        "varName": "consuntingMedicalAdvice",
        "grid": 2,
        "type": "text",
        "label": "Consulting Medical Advice"
    }, {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "Boarding/Lodging Fee"
    }, {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance"
    }, {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount"
    }, {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase"
    }, {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance"
    }, {
        "varName": "relationshipWithPatient",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Patient"
    }, {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1"
    }, {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2"
    }, {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
    },

];
let CSstudentFileRenewJasonFormRemitance = [

    {
        "label": "Student File-Renew",
        "type": "title",
        "grid": 12,
    },
    {
        "varName": "referenceNo",
        "grid": 2,
        "type": "text",
        "label": "Reference No"
    },
    {
        "varName": "branchAd",
        "grid": 2,
        "type": "text",
        "label": "Branch AD",
        "readOnly": true,
    },
    {
        "varName": "bookedBy",
        "grid": 2,
        "type": "text",
        "label": "Booked By ",
        "readOnly": true,
    },
    {
        "varName": "bookedByName",
        "grid": 2,
        "type": "text",
        "label": "Booked By Name ",
        "readOnly": true,
    },
    {
        "varName": "debitAcNumber",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Number",
        "readOnly": true,
    },
    {
        "varName": "debitAcName",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Name",
        "readOnly": true,
    },
    {
        "varName": "debitAcType",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Type",
        "readOnly": true,
    },
    {
        "varName": "debitAcAddress",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Address",
        "readOnly": true,
    },
    {
        "varName": "debitAcBranch",
        "grid": 2,
        "type": "text",
        "label": "Debit AC Branch",
        "readOnly": true,
    },
    {
        "varName": "referringAgency",
        "grid": 2,
        "type": "text",
        "label": "Referring Agency",
        "readOnly": true,
    },
    {
        "varName": "fileOpeningDate",
        "grid": 2,
        "type": "date",
        "label": "File Opening Date",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Name of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheApplicant",
        "grid": 2,
        "type": "text",
        "label": "Address of the Applicant ",
        "readOnly": true,
    },
    {
        "varName": "dateOfBirth",
        "grid": 2,
        "type": "date",
        "maxDate": true,
        "label": "Date of Birth",
        "readOnly": true,
    },
    {
        "varName": "age",
        "grid": 2,
        "type": "text",
        "label": "Age",
        "readOnly": true,
    },
    {
        "varName": "contactNo",
        "grid": 2,
        "type": "text",
        "label": "Contact No",
        "readOnly": true,
    },
    {
        "varName": "passport",
        "grid": 2,
        "type": "text",
        "label": "Passport",
        "readOnly": true,
    },
    {
        "varName": "dateOfIssue",
        "grid": 2,
        "type": "date",
        "label": "Date of Issue",
        "readOnly": true,
    },
    {
        "varName": "dateOfExpiry",
        "grid": 2,
        "type": "date",
        "label": "Date of Expiry",
        "readOnly": true,
    },
    {
        "varName": "placeOfIssue",
        "grid": 2,
        "type": "text",
        "label": "Place of Issue",
        "readOnly": true,
    },
    {
        "varName": "educationalQualification",
        "grid": 2,
        "type": "text",
        "label": "Educational Qualification",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Name of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "addressOfTheEducationalInstitute",
        "grid": 2,
        "type": "text",
        "label": "Address of the educational institute",
        "readOnly": true,
    },
    {
        "varName": "country",
        "grid": 2,
        "type": "text",
        "label": "Country",
        "readOnly": true,
    },
    {
        "varName": "nameOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Name of the Course ",
        "readOnly": true,
    },
    {
        "varName": "dateOfCommencement",
        "grid": 2,
        "type": "date",
        "label": "Date of Commencement",
        "readOnly": true,
    },
    {
        "varName": "durationOfTheCourse",
        "grid": 2,
        "type": "text",
        "label": "Duration of the Course",
        "readOnly": true,
    },
    {
        "varName": "currency",
        "grid": 2,
        "type": "text",
        "label": "Currency",
        "readOnly": true,
    },
    {
        "varName": "annualExpenses",
        "grid": 2,
        "type": "text",
        "label": "Annual Expenses",
        "readOnly": true,
    },
    {
        "varName": "tuitionFees",
        "grid": 2,
        "type": "text",
        "label": "Tuition Fees",
        "readOnly": true,
    },
    {
        "varName": "boardinglodgingFee",
        "grid": 2,
        "type": "text",
        "label": "BoardingLodging Fee",
        "readOnly": true,
    },
    {
        "varName": "totalFee",
        "grid": 2,
        "type": "text",
        "label": "Total Fee",
        "readOnly": true,
    },
    {
        "varName": "totalAmountToBeRemittedInAdvance",
        "grid": 2,
        "type": "text",
        "label": "Total amount to be Remitted in Advance",
        "readOnly": true,
    },
    {
        "varName": "lastDateOfRemittanceAdvanceAmount",
        "grid": 2,
        "type": "date",
        "label": "Last date of Remittance Advance amount",
        "readOnly": true,
    },
    {
        "varName": "remittableAdvance",
        "grid": 2,
        "type": "text",
        "label": "Remittable advance",
        "readOnly": true,
    },
    {
        "varName": "detailsOfForeignCurrencyPurchase",
        "grid": 2,
        "type": "text",
        "label": "Details of Foreign Currency Purchase",
        "readOnly": true,
    },
    {
        "varName": "relationshipWithStudent",
        "grid": 2,
        "type": "text",
        "label": "Relationship with Student",
        "readOnly": true,
    },
    {
        "varName": "freeField1",
        "grid": 2,
        "type": "text",
        "label": "Free Field 1",
        "readOnly": true,
    },
    {
        "varName": "freeField2",
        "grid": 2,
        "type": "text",
        "label": "Free Field 2",
        "readOnly": true,
    },
    {
        "varName": "freeField3",
        "grid": 2,
        "type": "text",
        "label": "Free Field 3",
        "readOnly": true,
    },

];

let CSLockerOpeningForm = [
    {
        "varName": "applicantName",
        "grid": 3,
        "type": "text",
        "label": "Applicant Name",
        "multiline": true
    }, {
        "varName": "accountNumber",
        "grid": 3,
        "type": "text",
        "label": "Applicant A/C No"
    }, {
        "varName": "communicationAddress",
        "grid": 3,
        "type": "text",
        "label": "Communication Address",
        "multiline": true
    }, {
        "varName": "lockerName",
        "grid": 3,
        "type": "text",
        "label": "locker Name"
    }, {
        "varName": "lockerSize",
        "type": "select",
        "label": "Locker Size",
        "enum": [
            "Small",
            "Midum",
            "Large",
        ],
        "required": true,
        "grid": 2,
    },
    // {
    //     "varName": "numberOfNominee",
    //     "type": "select",
    //     "label": "No Of Nominee",
    //     "enum":[
    //         "1",
    //         "2",
    //         "3",
    //         "4",
    //         "5",
    //         "6",
    //     ],
    //     "required": true,
    //     "grid": 2,
    // },
];
let BOMLockerForm = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "applicantName",
        "grid": 6,
        "type": "text",
        "label": "Applicant Name",
        "multiline": true,
        "readOnly": true,
    }, {
        "varName": "accountNumber",
        "grid": 6,
        "type": "text",
        "label": "Applicant A/C No",
        "readOnly": true,
    }, {
        "varName": "communicationAddress",
        "grid": 6,
        "type": "text",
        "label": "Communication Address",
        "multiline": true,
        "readOnly": true,
    },
    // {
    //     "varName": "lockerName",
    //     "grid": 6,
    //     "type": "text",
    //     "label": "locker Name"
    // },
    {
        "varName": "lockerSize",
        "type": "select",
        "label": "Locker Size",
        "enum": [
            "Small",
            "Midum",
            "Large",
        ],
        "required": true,
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "availableLockerNo",
        "type": "select",
        "label": "Available Locker No",
        "enum": [
            "1",
            "12",
            "13",
        ],
        "required": true,
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "numberOfNominee",
        "type": "text",
        "label": "Number of Nominee",
        "required": true,
        "grid": 6,
        "readOnly": true,
    },
])));
let SDMLockerForm = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "applicantName",
        "grid": 6,
        "type": "text",
        "label": "Applicant Name",
        "multiline": true,
        "readOnly": true,
    }, {
        "varName": "accountNumber",
        "grid": 6,
        "type": "text",
        "label": "Applicant A/C No",
        "readOnly": true,
    }, {
        "varName": "communicationAddress",
        "grid": 6,
        "type": "text",
        "label": "Communication Address",
        "multiline": true,
        "readOnly": true,
    },
    // {
    //     "varName": "lockerName",
    //     "grid": 6,
    //     "type": "text",
    //     "label": "locker Name"
    // },
    {
        "varName": "lockerSize",
        "type": "select",
        "label": "Locker Size",
        "enum": [
            "Small",
            "Midum",
            "Large",
        ],
        "required": true,
        "grid": 6,
        "readOnly": true,
    }, {
        "varName": "availableLockerNo",
        "type": "select",
        "label": "Available Locker No",
        "required": true,
        "grid": 6,
        "readOnly": true,
    },
])));
let SDCLockerForm = makeReadOnlyObject(JSON.parse(JSON.stringify([
    {
        "varName": "applicantName",
        "grid": 6,
        "type": "text",
        "label": "Applicant Name",
        "multiline": true,
        "readOnly": true,
    }, {
        "varName": "accountNumber",
        "grid": 6,
        "type": "text",
        "label": "Applicant A/C No",
        "readOnly": true,
    }, {
        "varName": "communicationAddress",
        "grid": 6,
        "type": "text",
        "label": "Communication Address",
        "multiline": true,
        "readOnly": true,
    }, {
        "varName": "lockerName",
        "grid": 6,
        "type": "text",
        "label": "locker Name",
        "readOnly": true,
    }, {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "required": true,
        "grid": 6,
        "readOnly": true,
    }, {
        "varName": "availableLockerNo",
        "type": "text",
        "label": "Available Locker No",
        "required": true,
        "grid": 6,
        "readOnly": true,
    }, {
        "varName": "siDate",
        "grid": 6,
        "type": "date",
        "readOnly": true,
        "label": "SI Date"
    }, {
        "varName": "lockerKeyNumber",
        "grid": 6,
        "type": "text",
        "label": "Locker Key Number"
    },
])));

let referenceNumber = [
    {
        "varName": "referenceNumber",
        "grid": 6,
        "type": "text",
        "label": "Reference Number",
        "multiline": true,
    }
];
let referenceNumberReadOnly = [
    {
        "varName": "referenceNumber",
        "grid": 6,
        "type": "text",
        "label": "Reference Number",
        "multiline": true,
        "readOnly": true,
    }
];


module.exports = {
    CSJsonFormForFDRServiceLast,
    CSJsonFormForFDRServiceAll,
    CSJsonFormForFDRService,
    CSJsonFormForFDRServiceForExistCbOnly,
    CSJsonFormForFDRServiceNew,
    CSJsonFormForCasaIndividualFdr,
    CSJsonFormForCasaIndividualFdrLast,
    BMJsonFormForCasaIndividualFdr,
    BOMJsonFormForCasaIndividualFdr,
    CSJsonFormForNewWithExistCB,
    BMJsonFormForNewWithExistCB,
    BOMJsonFormForNewWithExistCB,
    CSJsonFormForCasaIndividualTagFdr,
    CSJsonFormForCasaIndividualTagFdr2,
    fdKYC,
    fdTP,
    commonCsInformationForSdPart,
    BMJsonFormForCasaIndividualTagFdr,
    BMJsonFormForCasaIndividualTagFdr2,

    BOMJsonFormForCasaIndividualTagFdr,
    MakerJsonFormForCasaIndividualTagFdr2,
    MakerJsonFormForCasaIndividualTagFdr2Last,
    MAKERJsonFormForKyc,
    makeFdrForm,
    MAKERJsonFormForNewFdrFormFirst,
    MAKERJsonFormForNewFdrForm,
    MAKERJsonFormForNewFdrFormLasts,
    CheckerJsonFormForNewFdrForm,
    CheckerJsonFormForNewFdrFormLasts,
    MAKERJsonFormForNewFdrFormLast,
    CheckerJsonFormForKyc,
    CheckerJsonFormForNewFdrFormFirst,
    CheckerJsonFormForNewFdrFormLast,
    CheckerJsonFormForCasaIndividualTagFdr2,
    CheckerJsonFormForCasaIndividualTagFdr2Last,
    //with exist Cb only fdr
    MakerJsonFormForCasaIndividualWithExistingCBTagFdr,
    MakerJsonFormForCasaIndividualWithExistingCBTagFdrLast,
    CheckerJsonFormForCasaIndividualWithExistingCBTagFdr,
    CheckerJsonFormForCasaIndividualWithExistingCBTagFdrLast,

    CSJsonFormForCasaIndividualDPS,
    CSJsonFormForDPSService,
    CSJsonFormForCasaIndividualTagDPS,

    BMJsonFormForCasaIndividualDPS,
    BMJsonFormForCasaIndividualTagDPS,

    BOMJsonFormForCasaIndividualDPS,
    MakerJsonFormForCasaIndividualTagDPS,
    MakerJsonFormForCasaIndividualTagDPSLast,
    CheckerJsonFormForCasaIndividualTagDPS,
    CheckerJsonFormForCasaIndividualTagDPSLast,
    fttFirstLayerFiledRemitance,
    outwardFirstLayerFiledRemitance,
    fttJasonFormRemitanceMidLayer,
    fddJasonFormRemitanceMidLayer,
    outwardJasonFormRemitanceMidLayer,
    outwardMedicalFileRemetance,
    cancelationRequestRemetance,
    letStudentFileRenew,

    cancellationRquestJasonFormRemitanceBM,
    medicalFileJasonFormRemitanceBM,
    studentFileRenewJasonFormRemitanceBM,
    outwardJasonFormRemitanceBM,
    fttJasonFormRemitanceBM,
    fddJasonFormRemitanceBM,


    outwardJasonFormRemitanceBOM,
    fttJasonFormRemitanceBOM,
    fddJasonFormRemitanceBOM,
    cancellationRquestJasonFormRemitanceBOM,
    medicalFileJasonFormRemitanceBOM,
    studentFileRenewJasonFormRemitanceBOM,

    fddFirstLayerFiledRemitance,

    CSoutwardJasonFormRemitance,
    CSfttJasonFormRemitance,
    CSfddJasonFormRemitance,

    CSinWardJasonFormRemitance,
    BMinWardJasonFormRemitance,
    BOMinWardJasonFormRemitance,

    CScancellationRquestJasonFormRemitance,
    CSmedicalFileJasonFormRemitance,
    CSstudentFileRenewJasonFormRemitance,
    CSLockerOpeningForm,
    BOMLockerForm,
    SDMLockerForm,
    SDCLockerForm,
    StudentTypeNoFiledRemitance,
    StudentFileJsonForm,
    MedicalFileJsonFormBOM,
    MedicalFileJsonFormBM,
    MedicalFileJsonForm,
    RemittanceServiceOtherJsonForm,
    RemittanceServiceOtherJsonFormBOM,
    referenceNumberReadOnly,
    referenceNumber,
    remittanceJsonForStudent,
    remittanceJsonForStudentReadOnly
}
