import React, {useState, useEffect} from "react";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import Card from "../../Card/Card";
import loader from "../../../Static/loader.gif";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import MyValidation from "../../JsonForm/MyValidation";
import Notification from "../../NotificationMessage/Notification";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";

/*let jsonForm = [
    {
        "varName": "title",
        "label": "Title",
        "type": "select",
        "enum": [
            "Mr",
            "Ms"
        ],
        "grid": 3,
        "required": true
    },
    {
        "varName": "name",
        "label": "Name",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "email",
        "label": "Email",
        "type": "text",
        "grid": 3,
        "validation": "email",
        "multiline": true,
        // "required": true
    },
    {
        "varName": "mobileNumber",
        "label": "Mobile Number",
        "validation": "length",
        "maxLength": 11,
        "minLength": 11,
        "type": "text",
        "grid": 3,
        "multiline": true,
        // "required": true
    },
    {
        "varName": "signatureName",
        "label": "Sender Name",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "signatureMobileNumber",
        "label": "Sender Contact Number",
        "validation": "length",
        "maxLength": 11,
        "minLength": 11,
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "signatureEmail",
        "label": "Sender Email Address",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "domain",
        "type": "text",
        "grid": 2,
        "validation": "email",
        "readOnly":true
    }
];*/

let jsonFormReceiver = [
    {
        "type": "title",
        "label": "Receiver Information",
        "grid": 12,
    },
    {
        "varName": "title",
        "label": "Title",
        "type": "select",
        "enum": [
            "Mr",
            "Ms"
        ],
        "grid": 3,
        "required": true
    },
    {
        "varName": "name",
        "label": "Name",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "email",
        "label": "Email",
        "type": "text",
        "grid": 3,
        "validation": "email",
        "multiline": true,
        // "required": true
    },
    {
        "varName": "mobileNumber",
        "label": "Mobile Number",
        "validation": "length",
        "maxLength": 11,
        "minLength": 11,
        "type": "text",
        "grid": 3,
        "multiline": true,
        // "required": true
    }
];

let jsonFormSender = [
    {
        "type": "title",
        "label": "Sender Information",
        "grid": 12,
    },
    {
        "varName": "signatureName",
        "label": "Sender Name",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "signatureMobileNumber",
        "label": "Sender Contact Number",
        "validation": "length",
        "maxLength": 11,
        "minLength": 11,
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "signatureEmail",
        "label": "Sender Email Address",
        "type": "text",
        "grid": 3,
        "multiline": true,
        "required": true
    },
    {
        "varName": "domain",
        "type": "text",
        "grid": 2,
        "validation": "email",
        "readOnly":true
    }
];

const Greeting = () => {
    const [stateValue, setStateValue] = useState({
        inputData: {},
        varValue: {},
        showValue: false,
        loading: false,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        getData: false,
        errorArray: {},
        errorMessages: {},

    });


    useEffect(() => {
        // simulate async api call with set timeout

        let inputData={};
        inputData.domain="@thecitybank.com";
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData,
                varValue: inputData,
                showValue: true,
                getData: true
            }
        }));
    }, []);



    const updateComponent = () => {

    };




    const handleSubmit = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: false,
                loading: true,
                disabled: true,
            }
        }));
/*        let error = MyValidation.defaultValidation(jsonForm, stateValue);*/
        let error = MyValidation.defaultValidation(jsonFormReceiver.concat(jsonFormSender), stateValue);
        if (error === true) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                    loading: false,
                    disabled: false,
                }
            }));
            return false;
        } else {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    getData: true,
                }
            }));
            let url = backEndServerURL + "/sendGreetingsEmailAndSmsAfterVisiting";
            axios.post(url, stateValue.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(() => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        title: "Successful!",
                        notificationMessage: "Greetings send successful!",
                        alert: true,
                        loading: false
                    }
                }));
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            }).catch((error) => {
                resetState(error.response.data.message)
            });
        }
    };


    const resetState = (title, bool) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                title: title,
                getData: true,
                loading: false,
                disabled: false,
                cbNotFound: !prevState.cbNotFound
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };


    return (
        <>
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Send Greeting Message</h4>
                </CardHeader>
                <br/>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.cbNotFound}>
                        <DialogContent>
                            <CBNotFound closeModal={() => resetState("")} title={stateValue.title}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={stateValue.loading}>
                        <DialogContent>
                            <center><img src={loader} alt=""/></center>
                        </DialogContent>
                    </Dialog>
                    {/*<Grid item xs={12}>*/}
                    {/*<Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {stateValue.getData && CommonJsonFormComponent.renderJsonForm(stateValue, jsonForm, updateComponent)}
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    backgroundColor: 'red',
                                    color: '#ffffff',
                                    verticalAlign: 'left',
                                    marginTop: "15px",
                                    height: "29px"
                                }}
                                disabled={stateValue.disabled}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </ThemeProvider>
                    </Grid>*/}

                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {stateValue.getData && CommonJsonFormComponent.renderJsonForm(stateValue, jsonFormReceiver, updateComponent)}

                        </ThemeProvider>
                    </Grid>
                    <br/>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {stateValue.getData && CommonJsonFormComponent.renderJsonForm(stateValue, jsonFormSender, updateComponent)}
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    backgroundColor: 'red',
                                    color: '#ffffff',
                                    verticalAlign: 'left',
                                    marginTop: "15px",
                                    height: "29px"
                                }}
                                disabled={stateValue.disabled}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </ThemeProvider>
                    </Grid>


                    {renderNotification()}
                    {/*</Grid>*/}

                </CardBody>
            </Card>
        </>
    )
};

export default Greeting;