import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import CellWidthControlTable from "../Table/CellWidthControlTable";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {backEndServerURL} from "../../Common/Constant";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from "axios";
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',

    },

});

class Customer360View extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            varValueP: [],
            varValueQ: [],
            getData: false,
            dataSet: true,
            productData: [],
            customerId: '',
            mainCustId: '',
            tableData: [[" ", " "]],
            IDENTIFICATION_NO: '',
            uniqueId: '',
            CustomerSegment: '',
            varValueG: [],
            varValueS: [],
            searchTableData: null,
            renderCoreDetailsData: false,
            renderValueOfHoldings: false,
            renderGroupDetailsData: false,
            renderGroupProductHoldings: false,
            renderProductPropensityXsellData: true,
            renderServicePropensityXsellData: false,
            groupId: '',
            groupDetails: [],
            activeCardDetails: [],
            redirectLogin: false,


        }
    }


    componentDidMount() {
        let url = backEndServerURL + "/activeCards/" + this.props.IDENTIFICATION_NO;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let cardDetails = [];
                for (let item of response.data) {
                    cardDetails.push([item])
                }
                // console.log(cardDetails);
                this.setState({
                    activeCardDetails: cardDetails,
                    renderCoreDetailsData: true,
                    renderValueOfHoldings: true,
                })
            })
            .catch((error) => {
                this.setState({
                    renderCoreDetailsData: true,
                    renderValueOfHoldings: true,
                })
            })
    }


    renderCoreDetailsData = () => {
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Customer Name", this.props.prioritize360["CUSTOMERNAME"]],
                        ["Customer ID", this.props.prioritize360["IDENTIFICATION_NO_MOD"]],
                        ["Customer RV CB", this.props.prioritize360["RVCB"]],
                        ["Customer Segment", this.props.prioritize360["CUSTOMERSEGMENT"]],
                        ["Customer Band", this.props.prioritize360["CUSTOMERBAND"]],
                        ["Home Branch", this.props.prioritize360["HOMEBRANCH"]],
                        ["Line Of Business", this.props.prioritize360[""]],

                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCoreDetailsData2 = () => {
        var today = new Date();

        let dob = new Date(this.props.prioritize360["DATEOFBIRTH"]);
        let newDate = new Date(today - dob);
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Age In Years", newDate.getFullYear() - 1970],
                        ["Occupation", this.props.prioritize360["OUTOCCUPATION"]],
                        ["Customer Constitution", this.props.prioritize360["CUSTOMERCONSTITUTION"]],
                        ["Staff", this.props.prioritize360["CUSTSTAFF"]],
                        ["Citygem Flag", this.props.prioritize360["CITYGEM_FLAG"]],
                        ["Customer Since", this.props.prioritize360["CUSTOPNDATE"]],
                        ["Name of RM", this.props.prioritize360["MONITORINGRM"]],
                        ["Group ID", this.props.getGroupDetails.groupId],
                        ["Group ID details", this.props.getGroupDetails.groupDetails]
                    ]}
                    tableAllign={['left', 'left']}
                />

            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderValueOfHoldings = () => {

        if (this.props.booleanPrioritize360 === true) {
            return (
                <CellWidthControlTable
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Current Account Average Monthly Balance", this.props.prioritize360["CAAMB"]],
                        ["Current Account Balance", this.props.prioritize360["CAABL"]],
                        ["Savings Account Average Monthly Balance", this.props.prioritize360["SBAMB"]],
                        ["Savings Account Balance", this.props.prioritize360["SBABL"]],
                        ["TD Balance", this.props.prioritize360["FDBALANCE"]],
                        ["SND Balance", this.props.prioritize360["SNDBALANCE"]],
                        ["DPS Balance", this.props.prioritize360["DPSBALANCE"]],
                        ["SME Loan Outstanding", this.props.prioritize360["SMELOANOUTSTANDING"]],
                        ["Two Wheeler Outstanding", this.props.prioritize360["TWOWHEELEROUTSTANDING"]],
                        ["Home Loan Outstanding", this.props.prioritize360["HOMELOANOUTSTANDING"]],
                        ["PL Outstanding", this.props.prioritize360["PERSONALLOAN"]],

                    ]}
                    tableAllign={['left', 'left']}
                />

            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    }
    renderValueOfHoldings2 = () => {
        let tableData = [
            ["Auto Loan Outstanding", this.props.prioritize360["AUTOLOANOUTSTANDING"]],
            ["Relationship Value", this.props.prioritize360["RELATIONSHIPVALUE"]],
            ["Secured Facility", this.props.prioritize360["SECUREDLOAN"]],
            ["Debit Card", this.props.cardSummary["DEBITCARD"]],
            ["Debit Card Type", this.props.cardSummary["DEBITCARDTYPE"]],
            ["Credit Card", this.props.cardSummary["CREDITCARD"]],
            ["Credit Card Type", this.props.cardSummary["CREDITCARDTYPE"]]
        ];
        console.log(this.props.cardSummary["CREDITCARDPRODUCT"]);
        if (this.props.cardSummary["CREDITCARDPRODUCT"] !== undefined && this.props.cardSummary["CREDITCARDPRODUCT"].length > 0)
            tableData.push(["Credit Card Product", this.props.cardSummary["CREDITCARDPRODUCT"][0]]);
        if (this.props.cardSummary["CREDITCARDPRODUCT"] !== undefined && this.props.cardSummary["CREDITCARDPRODUCT"].length > 1) {
            for (let i = 1; i < this.props.cardSummary["CREDITCARDPRODUCT"].length; i++) {
                tableData.push(["", this.props.cardSummary["CREDITCARDPRODUCT"][i]]);

            }
        }
        tableData.push(["Credit Card Limit(BDT)", this.props.cardSummary["creditCardLimitBDT"]]);
        tableData.push(["Credit Card Limit(USD)", this.props.cardSummary["creditCardLimitUSD"]]);

        if (this.props.booleanCardSummary === true) {
            return (
                <CellWidthControlTable
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={tableData}
                    tableAllign={['left', 'left']}
                />


            )
        } else if (this.props.booleanCardSummary === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    }
    renderGroupDetailsData = () => {

        if (this.props.booleanGetAllGroupMember === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Customer ID", "Customer Name", "Member Type", "RV Value", "Total Group RV"
                    ]}
                    tableData={
                        this.props.getAllGroupMember
                    }
                    tableAllign={['left', 'left', 'left', 'right', 'right']}
                />

            )
        } else if (this.props.booleanGetAllGroupMember === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderGroupProductHoldings = () => {

        if (this.props.booleanGroupProductHoldings === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Group Product Holdings"]}
                    tableData={
                        this.props.groupProductHoldings
                    }
                    tableAllign={['left']}
                />


            )
        } else if (this.props.booleanGroupProductHoldings === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderGroupServiceHoldings = () => {

        if (this.props.booleanCardSummary === true && this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Group Service Holdings"]}
                    tableData={[...this.state.activeCardDetails, [this.props.groupServiceHoldings.CITYTOUCH], [this.props.groupServiceHoldings.E_STATEMENT]]}
                    tableAllign={['left']}
                />
            )
        } else {
            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        }
    };

    renderCustomerHistoryData = () => {
        return (
            <Table
                tableHeaderColor="primary"
                tableHead={[" ", " "
                ]}
                tableData={[
                    ["Last Query date", this.props.prioritize360[""]],
                    ["Details of Query", this.props.prioritize360[""]],
                    ["Attended by", this.props.prioritize360[""]],
                    ["Customer Feedback", this.props.prioritize360[""]],
                ]}
                tableAllign={['left', 'left']}
            />
        )


    };
    renderProductPropensityXsellData = () => {

        if (this.props.booleanGetAllProduct === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["PRODUCT PROPENSITY XSELL"
                    ]}
                    tableData={
                        this.props.getAllProduct
                    }
                    tableAllign={['left']}
                />


            )
        } else if (this.props.booleanGetAllProduct === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderServicePropensityXsellData = () => {
        if (this.props.booleanGetAllService === true) {
            return (
                <center>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["SERVICE PROPENSITY XSELL"
                        ]}
                        tableData={
                            this.props.getAllService
                        }
                        tableAllign={['left']}
                    />
                </center>


            )
        } else if (this.props.booleanGetAllService === true === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }


    };
    renderRemindersData = () => {
        return (

            <Table
                tableHeaderColor="primary"

                tableData={[
                    ["TD Expiry Date:Sept 2016/Personal Loan end Date:Dec 2017"]
                ]}
                tableAllign={['left']}
            />


        )
    };
    renderCustomerSegmentData = () => {
        return (
            <Table
                tableHeaderColor="primary"

                tableData={[
                    ["Details of Query", "Follow up date Customer"],
                    ["Attended by", "feedback"]
                ]}
                tableAllign={['left']}
            />
        )
    };

    render() {
        const {classes} = this.props;
        console.log(typeof this.props.prioritize360["CUSTOPNDATE"]);

        {

            Functions.redirectToLogin(this.state)


        }
        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>

                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>


                                                <Grid item xs={12}>
                                                    <h4 style={{color: "white"}} >CORE DETAILS</h4>
                                                </Grid>
                                                <Grid item xs={6}>


                                                    <Paper className={classes.paper}>

                                                        {this.renderCoreDetailsData()}


                                                    </Paper>

                                                </Grid>
                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderCoreDetailsData2()}
                                                    </Paper>


                                                </Grid>
                                                <Grid item xs={12}>
                                                    <h4 style={{color: "white"}} >VALUE OF HOLDINGS</h4>
                                                    <center><h6> (<b>RV Table dated: { this.props.prioritize360["rvDate"]}</b>)</h6></center>

                                                </Grid>
                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderValueOfHoldings()}

                                                    </Paper>


                                                </Grid>
                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>
                                                        {this.renderValueOfHoldings2()}

                                                    </Paper>

                                                </Grid>

                                                <Grid item xs={12}>
                                                    <h4 style={{color: "white"}} >Group Details</h4>
                                                </Grid>
                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderGroupDetailsData()}

                                                    </Paper>

                                                </Grid>


                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>
                                                        {this.renderGroupProductHoldings()}

                                                    </Paper>

                                                </Grid>
                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>
                                                        {this.renderGroupServiceHoldings()}


                                                    </Paper>

                                                </Grid>

                                                {/* <Grid item xs={12}>

                                                    <Paper className={classes.paper}>
                                                        <center><h4 style={{color: "white"}} >Customer History</h4></center>
                                                        {this.renderCustomerHistoryData()}

                                                    </Paper>

                                                </Grid>


                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderProductPropensityXsellData()}


                                                    </Paper>

                                                </Grid>
                                                <Grid item xs={6}>

                                                    <Paper className={classes.paper}>
                                                        {this.renderServicePropensityXsellData()}

                                                    </Paper>

                                                </Grid>


                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>
                                                        <center><h4 style={{color: "white"}} >REMINDERS</h4></center>
                                                        {this.renderRemindersData()}


                                                    </Paper>


                                                </Grid>


                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>

                                                        <center><h4 style={{color: "white"}} >CUSTOMER REVIEW DETAILS</h4></center>

                                                        {this.renderCustomerSegmentData()}


                                                    </Paper>


                                                </Grid>*/}


                                            </Grid>

                                        </Grid>

                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>
        )
    }


}

export default withStyles(styles)(Customer360View);