import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import SavingsCurrentSnd from "./SavingsCurrentSnd";
import CustomerLoan from "./CustomerLoan";
import IndividualTermDeposit from "./IndividualTermDeposit";
import StandingInstruction from "./StandingInstruction";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';
import DialogContent from "@material-ui/core/DialogContent";
import SignatureCardView from "./SignatureCardView";
import AccountStatement from "../workflow/fdr/AccountStatement";
// core components

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class ListOfAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: false,
            SavingsAccount: false,
            sinatureCardFlag: false,
            LoanAccount: false,
            TermDeposit: false,
            tableData: [[" ", " "]],
            type: '',
            StandingInstruction: false,
            IDENTIFICATION_NO: '',
            ACCOUNTNO: "",
            productName: '',
            CURRENTSAVINGS: 'CURRENTSAVINGS',
            TERMDEPOSIT: "TERMDEPOSIT",
            LOAN: "LOAN",
            renderListOfAccountData: false,
            redirectLogin: false,
            accountStatement: false
        }
    }


    StandingInstruction() {
        this.setState({
            StandingInstruction: true,

        });
    }

    closeSavingsAccountModal = () => {
        this.setState({
            SavingsAccount: false,

        });
    };
    closeLoanAccountModal = () => {
        this.setState({
            LoanAccount: false,

        });
    };
    closeTermDepositModal = () => {
        this.setState({
            TermDeposit: false,

        });
    };
    closeSinatureCardFlagModal = () => {
        this.setState({
            sinatureCardFlag: false,

        });
    };

    closeStandingInstructionModal = () => {
        this.setState({
            StandingInstruction: false,

        });
    };
    renderSignatureButton = (type, accountNo, accountStatus) => {
        if (accountStatus !== "Dormant") {
            return (
                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={
                        event => this.signatureCardView(type, accountNo)
                    }
                >
                    <Fab size="small" color="primary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>
            )
        }
    };
    createTableData = (id, accountNo, accountName, accountStatus, schemeCode, productType, effectiveDate, ineffectiveDate, type) => {

        return ([accountNo, accountName, accountStatus, "FINACLE", schemeCode, productType, effectiveDate, ineffectiveDate,
            <button
                className="btn"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={
                    event => this.showAccountStatement(accountNo)
                }
            >
                <Fab size="small" color="secondary" aria-label="pageview">

                    <Pageview/>
                </Fab>

            </button>, this.renderSignatureButton(type, accountNo, accountStatus),

            <button
                className="btn"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={
                    event => this.ListOfAccountModalSelection(type, accountNo)
                }
            >
                <Fab size="small" color="secondary" aria-label="pageview">

                    <Pageview/>
                </Fab>

            </button>
        ]);

    };


    showAccountStatement = (accountNo) => {
        this.setState({
            accountStatement: true,
            ACCOUNTNO: accountNo,
        })
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    ListOfAccountModalSelection = (type, accountNo) => {


        if (type === "SBA" || type === "CAA") {

            this.setState({
                SavingsAccount: true,
                type: this.state.CURRENTSAVINGS,
                ACCOUNTNO: accountNo,
            });

        } else if (type === "TDA") {
            this.setState({
                TermDeposit: true,
                type: this.state.TERMDEPOSIT,
                ACCOUNTNO: accountNo
            });

        } else if (type === "LAA" || type === "ODA" || type === "CCA") {
            this.setState({
                LoanAccount: true,
                type: this.state.LOAN,
                ACCOUNTNO: accountNo
            })

        } else {

        }

    };
    signatureCardView = (type, accountNo) => {
        this.setState({
            sinatureCardFlag: true,
            type: type,
            ACCOUNTNO: accountNo
        })

    };

    componentDidMount() {
        if (this.props.IDENTIFICATION_NO !== undefined) {
            let url = backEndServerURL + "/demographic/accounts/" + this.props.IDENTIFICATION_NO;

            const ListOfAccount = [];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    response.data.map((account) => {
                        let date = account.effectiveDate;
                        let statusDate = account.statusDate;
                        let splitDate = "";
                        let splitstatusDate = "";
                        if (date !== undefined && date !== null) {
                            splitDate = date.split('T')[0]
                        } else {
                            splitDate = date;
                        }
                        if (statusDate !== undefined && statusDate !== null) {
                            splitstatusDate = statusDate.split('T')[0]
                        } else {
                            splitstatusDate = statusDate
                        }
                        ListOfAccount.push(this.createTableData(account.id, account.accountNo, account.accountName, account.status, account.productCode, account.productName, splitDate, splitstatusDate, account.type));


                    });
                    this.setState({
                        tableData: ListOfAccount,
                        renderListOfAccountData: true
                    })


                })

                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({
                        renderListOfAccountData: true
                    })
                });

        } else {

        }

    }

    renderListOfAccountData = () => {
        if (this.state.renderListOfAccountData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Account Number", "Account Name", "Account Status", "Platform", "Scheme code", "Product Type", "Account Open Date", "Account Status Date", "A/C Statement", "Signature Card View", "Details View"
                    ]}
                    tableData={
                        this.state.tableData
                    }
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />
            )
        } else if (this.state.renderListOfAccountData === false) {

            return (
                <CircularProgress/>
            )
        } else {

        }

    };
    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


        return (
            <section>
                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.StandingInstruction}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <StandingInstruction platform="FINACLE" closeModal={this.closeStandingInstructionModal}
                                             accountNo={this.state.accountNo} type={this.state.type}/>


                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.accountStatement}>
                    <DialogContent className={classes.dialogPaper}>
                        <AccountStatement platform="FINACLE" closeModal={this.closeStatement}
                                          accountNumber={this.state.ACCOUNTNO}/>
                    </DialogContent>
                </Dialog>
                {/*<Dialog

                    className={classes.modal}
                    open={this.state.StandingInstruction}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}

                >

                    <StandingInstruction closeModal={this.closeStandingInstructionModal} accountNo={this.state.accountNo} type={this.state.type}/>

                </Dialog>

*/}
                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SavingsAccount}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <SavingsCurrentSnd platform="FINACLE" closeModal={this.closeSavingsAccountModal} ACCOUNTNO={this.state.ACCOUNTNO}
                                           type={this.state.type}/>


                    </DialogContent>
                </Dialog>
                {/*<Dialog

                    className={classes.modal}
                    open={this.state.SavingsAccount}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}

                >

                    <SavingsCurrentSnd closeModal={this.closeSavingsAccountModal}  ACCOUNTNO={this.state.ACCOUNTNO} type={this.state.type}/>

                </Dialog>*/}
                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.LoanAccount}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <CustomerLoan platform="FINACLE" closeModal={this.closeLoanAccountModal} ACCOUNTNO={this.state.ACCOUNTNO}
                                      type={this.state.type}/>


                    </DialogContent>
                </Dialog>
                {/* <Dialog

                    className={classes.modal}
                    open={this.state.LoanAccount}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}

                >

                    <CustomerLoan closeModal={this.closeLoanAccountModal} ACCOUNTNO={this.state.ACCOUNTNO} type={this.state.type}/>

                </Dialog>*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.TermDeposit}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <IndividualTermDeposit platform="FINACLE" closeModal={this.closeTermDepositModal} ACCOUNTNO={this.state.ACCOUNTNO}
                                               type={this.state.type}/>


                    </DialogContent>
                </Dialog>
                <Dialog

                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.sinatureCardFlag}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <SignatureCardView platform="FINACLE" closeModal={this.closeSinatureCardFlagModal} ACCOUNTNO={this.state.ACCOUNTNO}
                                           type={this.state.type}/>


                    </DialogContent>
                </Dialog>
                {/* <Dialog

                    className={classes.modal}
                    open={this.state.TermDeposit}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}

                >

                    <IndividualTermDeposit closeModal={this.closeTermDepositModal} ACCOUNTNO={this.state.ACCOUNTNO} type={this.state.type}/>

                </Dialog>*/}
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>

                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                    <h4 style={{color: "white"}} >List of Accounts<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>
                                    <div>
                                        {this.renderListOfAccountData()}
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(ListOfAccount);
