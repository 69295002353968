import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import {Redirect} from "react-router-dom";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

/**
  "enum": [
 "Closing Request(Other than secured Loan)",
 "Closing Request(secured Loan)",
 "Outstanding Certificate",
 "Partial Payment Request",
 "EMI date change Request",
 "Loan AC tenor Change Request",
 "Interest transfer request",
 "Recovery amount realization against write off account/Manual Realization",
 "Deceased mark request of retail loan accounts",
 "Lien marking of Accounts",
 "Waiver Request"
 ],
 */

let requestType = [
    {
        "varName": "requestType",
        "type": "select",
        "label": "Select Service Request Type",
        "grid": 3
    }
    ];

class assetOperationService extends Component {
    constructor() {
        super();
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            inputData: {
                requestType:""
            },
            showValue: false,
            varValue: {},
            loading: true,
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {
        this.setState({
            loading: false
        })
    };


    functionForServiceRequest = () => {
        if (this.state.inputData.requestType) {

            let redirectPath;

            switch (this.state.inputData.requestType) {
                case "Closing Request(Other than secured Loan)":
                    redirectPath = "CsFormLoanClosing";
                    break;
                case "Closing Request(secured Loan)":
                    redirectPath = "CsSecuredLoanClosing";
                    break;
                case  "Outstanding Certificate":
                    redirectPath = "OutstandingCertificate";
                    break;
                case   "Partial Payment Request":
                    redirectPath = "PartialPayment";
                    break;
                case  "EMI date change Request":
                    redirectPath = "emidatechange";
                    break;
                case  "Loan AC tenor Change Request":
                    redirectPath = "loanaccounttenorchange";
                    break;
                case  "Other Service Request":
                    redirectPath = "interesttransfer";
                    break;
                case  "Recovery amount realization against write off account/Manual Realization":
                    redirectPath = "RecoveryAmount";
                    break;
                case  "Deceased mark request of retail loan accounts":
                    redirectPath = "CsDeceasedMark";
                    break;
                case   "Lien marking of Accounts":
                    redirectPath = "LienMarking";
                    break;
                case   "Waiver Request":
                    redirectPath = "CsWaiverRequest";
                    break;
                default:
                    redirectPath = "ServiceRequest";
            }
            return (<Redirect to={{
                pathname: `/${redirectPath}`,
            }}/>);
        }
    };

    render() {
       let  workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
       if(workflowGroup === 'CS') {
          requestType[0].enum = [
               "Closing Request(Other than secured Loan)",
               "Closing Request(secured Loan)",
               "Outstanding Certificate",
               "Partial Payment Request",
               "EMI date change Request",
               "Loan AC tenor Change Request",
               "Other Service Request"
           ];
       } else if(workflowGroup === 'CR AWS') {
           requestType[0].enum = [
               "Recovery amount realization against write off account/Manual Realization",
               "Deceased mark request of retail loan accounts",
               "Lien marking of Accounts",
               "Waiver Request"
           ];
       } else {
           requestType[0].enum = [];
       }

        const {classes, onClose} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Service Request Type
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {CommonJsonFormComponent.renderJsonForm(this.state, requestType, this.updateComponent)}
                                        {this.functionForServiceRequest()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }

    }
}

export default withStyles(styles)(assetOperationService);
