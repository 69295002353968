import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";

const filterField = [
    {
        varName: "fileSerial",
        label: "File Serial",
        type: "text",
        grid: 2,
    },
    {
        varName: "applicantsName",
        label: "Applicant's Name",
        type: "text",
        grid: 2,
    },
    {
        varName: "applicantsFathersname",
        label: "Father's Name",
        type: "text",
        grid: 2,
    },
    {
        varName: "applicantsMothersname",
        label: "Mother's Name",
        type: "text",
        grid: 2,
    },
    {
        varName: "applicantsSpousename",
        label: "Spouse Name",
        type: "text",
        grid: 2,
    },
    {
        varName: "birthDate",
        label: "Date of Birth",
        type: "date",
        max: true,
        grid: 2,
    },
    {
        varName: "tin_No",
        label: "Tin Number",
        type: "text",
        grid: 2,
    },
    {
        varName: "national_id_No",
        label: "National ID",
        type: "text",
        grid: 2,
    },
    {
        varName: "permanentCity",
        label: "Permanent City",
        type: "text",
        grid: 2,
    },
    {
        varName: "homePhoneLand",
        label: "Home Phone Land",
        type: "text",
        grid: 2,
    },
    {
        varName: "homePhoneMobile",
        label: "Home Mobile",
        type: "text",
        grid: 2,
    },
    {
        varName: "Guarantor",
        label: "Guarantor",
        type: "text",
        grid: 2,
    },
    {
        varName: "businessAddress",
        label: "Business Address",
        type: "text",
        grid: 2,
    },
    {
        varName: "companyName",
        label: "Busi/Corp Name",
        type: "text",
        grid: 2,
    },
    {
        varName: "idNo",
        label: "ID No",
        type: "text",
        grid: 2,
    }, {
        varName: "GurantorsOrganization",
        label: "Guarantor Business Organization",
        type: "text",
        grid: 2,
    },
    // {
    //     varName: "guarantorMotherName",
    //     label: "Guarantor Mother Name",
    //     type: "text",
    //     grid: 2,
    // },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class LoanCustomerSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: false,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 250,
            tableColumns: [
                {title: "File serial", field: "fileSerial"},
                {title: "Applicant's Name", field: "applicantsName"},
                {title: "Analyst Name", field: "analystName"},
                {title: "Father's Name", field: "applicantsFathersname"},
                {title: "Mother's Name", field: "applicantsMothersname"},
                {title: "File Status", field: "fileStatus"},
                {title: "Spouse Name", field: "applicantsSpousename"},
                {title: "Date of Birth", field: "birthDate"},
                {title: "TIN No", field: "tin_No"},
                {title: "NID No", field: "national_id_No"},
                {title: "Business Name", field: "companyName"},
                {title: "Guarantor", field: "Guarantor"},
                {title: "Source ID", field: "introducer_id"},
                {title: "Home Mobile", field: "homePhoneMobile"},
                {title: "Guarantor NID", field: "gurantorNID"},
                {title: "Type", field: "type"},
                // {title: "Permanent City", field: "permanentCity"},
                // {title: "Home Phone Land", field: "homePhoneLand"},
                // {title: "Business Address", field: "businessAddress"},
                // {title: "Guarantor Business Organization", field: "GurantorsOrganization"},
                // {title: "Guarantor Mother Name", field: "guarantorMotherName"},
            ],
            page: 0,
            rvValue: 0
        }
    }

    componentDidMount() {
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Loan Customer Search</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            openHierarchy: false,
            cbNotFound: false
        })
    };


    resetHandler = () => {
        window.location.reload();
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    searchHandler = (pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            getData: false,
            loading: true,
            showTable: false,
        });

        let postData = this.copyJson(this.state.inputData);
        let pageN = pageNumber ? pageNumber : 0;
        let url = backEndServerURL + "/filterCustomerLoanDedup/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                let rowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    let res = response.data.content[i];
                    rowData = {
                        "fileSerial": res.FILESERIAL,
                        "applicantsName": res.APPLICANTSNAME,
                        "applicantsFathersname": res.APPLICANTSFATHERSNAME,
                        "applicantsMothersname": res.APPLICANTSMOTHERSNAME,
                        "applicantsSpousename": res.APPLICANTSSPOUSENAME,
                        "birthDate": res.BIRTHDATE ? res.BIRTHDATE.split("T")[0] : "",
                        "tin_No": res.TIN_NO,
                        "national_id_No": res.NATIONAL_ID_NO,
                        "permanentCity": res.PERMANENTCITY,
                        "homePhoneLand": res.HOMEPHONELAND,
                        "homePhoneMobile": res.HOMEPHONEMOBILE,
                        "Guarantor": res.GUARANTOR,
                        "businessAddress": res.BUSINESSADDRESS,
                        "companyName": res.COMPANYNAME,
                        "introducer_id": res.INTRODUCER_ID,
                        "GurantorsOrganization": res.GURANTORSORGANIZATION,
                        "analystName": res.ANALYST_NAME,
                        "fileStatus": res.STATUS,
                        "type": res.type,
                        "gurantorNID": "",
                        "type": res.type,
                        // "guarantorMotherName": res[6],
                    };
                    tableData.push(rowData);
                }
                this.setState({
                    page: pageN,
                    totalRow: response.data.totalElements,
                    tableData: tableData,
                    showTable: true,
                    searchData: true,
                    getData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: false,
                    loading: false,
                    showTable: true,
                    searchData: false,
                })
            });
    };


    renderFilterField = () => {
        const {classes} = this.props;
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => this.searchHandler(0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={this.resetHandler}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)
        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loading} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>

                        {(this.state.getData && this.state.showTable) &&
                        <TableComponent tableData={this.state.tableData} tableColumns={this.state.tableColumns}
                                        loader={this.state.loading} maxBodyHeight="450px" totalRow={this.state.totalRow}
                                        page={this.state.page} selection={false}
                                        handleChangePage={this.searchHandler} rowsPerPage={this.state.rowsPerPage}
                        />}
                        <Grid container>
                            {this.renderNotification()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(LoanCustomerSearch);
