import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import "../../Static/css/CommonJsonForm.css";


class AutoCompleteComponentForKey {

    static handleChange(option, state, update, field) {
        console.log(option);
        if (option !== null && field.key === true) {
            state.inputData[field.varName] = option.key;
            console.log(option);
            update();


        } else if (option !== null && field.value === true) {
            state.inputData[field.varName] = option.value;
            console.log(option);
            update();
        } else if (option === null) {
            state.inputData[field.varName] = option;
            console.log(option);
            update();
        }

    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>

            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";

            else{
                if(state.varValue[field.varName] === undefined || state.varValue[field.varName] == null || state.varValue[field.varName] === "undefined" || state.varValue[field.varName] == "null"){
                    return "";
                }
                else{
                    return state.varValue[field.varName];
                }

            }


        }
    }


    static select(state, update, field) {
        let defaultProps = {
            options: field.enum,
            //getOptionLabel: option => option.value,
        };
        let styles = { paddingRight: 20};
        if (field.readOnly === true && field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        } else if (field.readOnly === true) {
            const firstStyle = {
                color: '#212F3D',
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }

        /*   options: field.enum,
               getOptionLabel: option => option.value,*/
        return (
            <React.Fragment>
                {this.asteriksFunction(field)}

                <Grid item>
                    <Autocomplete onChange={(event, option) => this.handleChange(option, state, update, field)}
                        //defaultValue={this.state.inputData["numberOfNominee"]}
                                  options={field.enum} getOptionLabel={option => option.value}
                                  {...defaultProps} disabled={field.readOnly}
                                  defaultValue={this.returnDefaultValue(field, state)}
                        //style={ field.readOnly===true?{paddingRight: 20}:{paddingRight: 20}}
                                  style={styles}
                        //style={ {paddingRight: 20}}
                                  renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/

                                      <TextField {...params}
                                                 error={state.errorArray !== undefined && state.errorArray[field.varName]}
                                                 helperText={state.errorArray[field.varName] === true ? state.errorMessages[field.varName] : ""}
                                                 variant="outlined"
                                                 style={field.readOnly === true ? {color: "#ffffff"} : {color: "#000000"}}
                                                 fullWidth/>}
                    />
                    {/*<Autocomplete onChange={(event, option) => this.handleChange(option, state, update, field)}
                                  helperText={state.errorMessages[field.varName]}
                                  error={state.errorArray[field.varName]}
                                  required={field.required}
                                  {...defaultProps} disabled={field.readOnly}
                                  defaultValue={this.returnDefaultValue(field, state)}
                                  renderInput={(params) => <TextField {...params}  error={state.errorArray !== undefined && state.errorArray[field.varName]}
                                                                      helperText={ state.errorArray[field.varName] === true? state.errorMessages[field.varName]:""} variant="outlined"
                                                                      style={{paddingRight: 20}} fullWidth/>}
                    />*/}
                </Grid>
            </React.Fragment>

        )


    }

}

export default AutoCompleteComponentForKey;