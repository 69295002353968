import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import DedupResultFunction from "../../DedupResultFunction";
import SecondaryCbFormDetails from "../CASA/SecondaryCbFormDetails";
import {tagForm, taggingForm} from "../WorkflowJsonFormRashed"
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";

let csRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12,
        "required": true
    }];

let lockerSIAndKeyNymberForm = [
    {
        "varName": "siDate",
        "grid": 6,
        "type": "date",
        "label": "SI Date"
    }, {
        "varName": "lockerKeyNumber",
        "grid": 6,
        "type": "text",
        "label": "Locker Key Number"
    },];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class SDMLocker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            renderCumModalopen: false,
            getData: false,
            getNewCase: false,
            varValue: [],
            objectForNomineeArray: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            loading: true,
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            getMappingCropImage: false,
            imageCropModal: false,
            tagId: '',
            tagData: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            getTagList: [],
            city: [],
            stateCode: [],
            country: [],
            guardianCode: [],
            relationCode: [],

        };
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.renderNomineeForm = this.renderNomineeForm.bind(this);
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === 'undefined' || clone[prop] === null || clone[prop] === undefined || clone[prop] === '-1')
                delete clone[prop];
        return clone;
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    submitApprove = (event) => {
        event.preventDefault();
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";
        let error = MyValidation.defaultValidation(this.state.objectForNomineeArray, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.handleSubmit();
        }
    };

    submitReturn = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.state.inputData.maker_update_all_info_send_to = "CS";
            this.handleSubmit();
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitReturn}
                    > Return
                    </button>
                </div>

            )
        }
    };

    dynamicApiCall = (enumType, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (enumType === "city") {
                    this.setState({
                        city: response.data
                    });
                }
                if (enumType === "state") {
                    this.setState({
                        stateCode: response.data
                    })
                }
                if (enumType === "guardianCode") {
                    this.setState({
                        guardianCode: response.data
                    })
                }
                if (enumType === "relationCode") {
                    this.setState({
                        relationCode: response.data
                    })
                }
                if (enumType === "country") {
                    this.setState({
                        country: response.data
                    })
                }

                {
                    this.findByVarNameApiData(enumType, response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.state.objectForNomineeArray;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.enumType === searchVarname) {
                jsonArray[i].enum = getValue;
            }
        }

        console.log(jsonArray);
    };

    componentDidMount() {

        this.setState({
            loaderNeeded: false
        });

        if (this.props.subServiceType === "Joint Account") {
            let tableArrayData = [];
            let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
            axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    //alert('success')
                    console.log(response.data);
                    response.data.map((data, index) => {
                        tableArrayData.push(this.createTaggingData(data));

                    });
                    this.setState({
                        getTagList: tableArrayData,

                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }


        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        let varValue = [];
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    tagId: response.data.jointDedupData,
                    tagData: true
                });

                var sl;
                let objectForNominee = [];
                for (let i = 0; i < parseInt(response.data.numberOfNominee); i++) {
                    sl = i + 1;
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "Nominee  " + sl,
                            "grid": 12,
                        },
                        {
                            "varName": "search" + sl,
                            "type": "select",
                            "enum": [
                                "CIF ID",
                                "INFORMATION",
                            ],
                            "required": true,
                            "onKeyDown": true,
                            "label": "Input CIF ID/INFORMATION ?",
                            "grid": 6,
                        },
                        {
                            "varName": "nomineecifId" + sl,
                            "type": "textApiCall",
                            "label": "CIF ID",
                            "grid": 6,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",

                        },

                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",

                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "relationCode",
                            "enum": [],
                            "label": "Relationship",
                            "grid": 6,
                            "required": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],


                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "relationCode",
                            "enum": [],
                            "label": "Relationship",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },

                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "city",
                            "enum": [],
                            "label": "City Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "city",
                            "enum": [],
                            "label": "City Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "state",
                            "enum": [],
                            "label": "State Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "state",
                            "enum": [],
                            "label": "State Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "country",
                            "enum": [],
                            "label": "Country",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "country",
                            "enum": [],
                            "label": "Country",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "text",
                            "label": "D.O.B(YYYY-MM-DD)",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 6,
                            "onKeyDown": true,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },


                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,


                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "guardianCode",
                            "enum": [],
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "city",
                            "enum": [],
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "state",
                            "enum": [],
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "country",
                            "enum": [],
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "guardianCode",
                            "enum": [],
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "city",
                            "enum": [],
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "state",
                            "enum": [],
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enumType": "country",
                            "enum": [],
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },
                    )

                }
                this.setState({
                    objectForNomineeArray: objectForNominee
                });

                setTimeout(() => {
                    this.dynamicApiCall("state", "state");
                    this.dynamicApiCall("guardianCode", "guardianCode");
                    this.dynamicApiCall("relationCode", "nomineeRelationCode");
                    this.dynamicApiCall("city", "city");
                    this.dynamicApiCall("country", "country");
                }, 1000);


                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    response.data.map((data) => {
                        if (data.remarks !== 'undefined') {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        }
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                    .catch((error) => {
                        console.log(error)
                    });
                console.log(response.data);
                let varValue = response.data;
                this.setState({
                    getData: true,
                    varValue: this.getSearchvalue(varValue),
                    appData: this.getSearchvalue(response.data),
                    inputData: this.getSearchvalue(response.data),
                    showValue: true,
                    appId: this.props.appId,
                    loaderNeeded: true,
                    loading: false
                });
            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();
                        this.setState({
                            redirectLogin: true
                        })
                    }
                });
        }


    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    getTaggingData = (index, data, customerNo) => {

        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });

                this.setState({
                    getTagList: tableArrayData,
                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    createTaggingData = (data) => {
        return (
            ["Customer " + data.customer, data.cbNumber, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer)}
            >View
            </button>]]


        )
    };

    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false

        })
    };

    tagCbDetailsModal = (event, cb) => {
        console.log(cb);
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb
        })

    };

    renderDedupComponent = () => {
        console.log(this.props.appId);
        if (this.props.subServiceType !== "INDIVIDUAL" && this.state.getData && !this.state.loading) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData}
                                         taggingForm={taggingForm}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };

    renderTagging = () => {
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));

                });

                console.log('.........tagList.........');
                console.log(tableArrayData);

                this.setState({
                    getTagList: tableArrayData
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })
    };


    renderTagList = () => {

        if (this.state.getData && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    {/*<paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
*/}
                    <div>
                        {/*  <center>
                        <h4 style={{color: "white"}} >Link CB List</h4>
                    </center>*/}
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer No", "CB Number", "View"]}

                            tableData={this.state.getTagList}
                            tableAllign={['left', 'left', 'left']}
                        />

                        <br/>


                    </div>

                </div>

            )
        }

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {
        //
    };
    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )
        }
    };
    returnSidateAndLockerKeyNumber = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, lockerSIAndKeyNymberForm, this.updateComponent)
            )
        }
    };

    handleSubmit = () => {
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.makerRemarks = undefined;

        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true
                    });
                    this.props.closeModal();
                })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    close = () => {
        this.props.closeModal();
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }
    };

    onKeyDownForDedup = (event, data) => {
        let totalNominee = this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["search" + (i + 1)] = this.state.inputData["search" + (i + 1)];
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            //this.state.varValue["nomineeMinor" + i] == "YES";
                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })

            }
        }
    };

    onKeyDownChange = (data) => {
        let totalNominee = this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data.varName === "search" + (i + 1)) {
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["nomineecifId" + (i + 1)] = "";
                this.state.varValue["nomineecifId" + (i + 1)] = "";
                this.state.inputData["nomineeName" + (i + 1)] = "";
                this.state.inputData["nomineeAddress1" + (i + 1)] = "";
                this.state.inputData["nomineeAddress2" + (i + 1)] = "";
                this.state.inputData["nomineeCityCode" + (i + 1)] = "";
                this.state.inputData["nomineeStateCode" + (i + 1)] = "";
                this.state.inputData["nomineePostalCode" + (i + 1)] = "";
                this.state.inputData["nomineeCountry" + (i + 1)] = "";
                this.state.inputData["percentageNominee" + (i + 1)] = "";
                this.state.inputData["nomineeRelationship" + (i + 1)] = "";
                this.state.varValue["percentageNominee" + (i + 1)] = "";
                this.state.varValue["nomineeRelationship" + (i + 1)] = "";
                this.state.inputData["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeName" + (i + 1)] = "";
                this.state.varValue["nomineeAddress1" + (i + 1)] = "";
                this.state.varValue["nomineeAddress2" + (i + 1)] = "";
                this.state.varValue["nomineeCityCode" + (i + 1)] = "";
                this.state.varValue["nomineeStateCode" + (i + 1)] = "";
                this.state.varValue["nomineePostalCode" + (i + 1)] = "";
                this.state.varValue["nomineeCountry" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.inputData["nomineeMinor" + (i + 1)] = "";
                this.state.varValue["nomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    console.log(stringToDate, inputDate);
                    console.log(stringToDate >= inputDate);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }
    };

    renderNomineeForm = () => {
        if (this.state.getData && parseInt(this.state.inputData.numberOfNominee) > 0) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNomineeArray, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )

        }
    };
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <SecondaryCbFormDetails tagForm={tagForm} customerNumber={this.state.tagCb}
                                                    appId={this.props.appId} tagCb={this.state.tagCb}
                                                    closeModal={this.tagCbDetailsModalClose}/>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.returnJsonForm()}
                            {this.returnSidateAndLockerKeyNumber()}
                            <Grid item xs={6}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderNomineeForm()}
                            <Grid item xs='12'>
                                {this.renderRemarksData()}
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                    {this.renderDedupComponent()}
                    {this.renderTagList()}
                    <ThemeProvider theme={theme}>
                        {this.renderRemarks()}
                        <br/>
                        <br/>
                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                }}
                                type='button' value='add more'
                                onClick={this.submitApprove}
                            > Approve
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                }}
                                type='button' value='add more'
                                onClick={this.submitReturn}
                            > Return
                            </button>
                        </div>
                    </ThemeProvider>
                </div>
            </GridList>
        );


    }

}

export default withStyles(styles)(SDMLocker);
