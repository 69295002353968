import React, {Component} from "react";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import Card from "../../../Card/Card";
import CardHeader from "../../../Card/CardHeader";
import CardBody from "../../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {retailLoanJsonForm} from "./JsonForm";
import Notification from "../../../NotificationMessage/Notification";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Table from "../../../Table/Table";
import CloseIcon from "@material-ui/icons/Close";
import MyValidation from "../../../JsonForm/MyValidation";
import withStyles from "@material-ui/core/styles/withStyles";

let jsonFormPartTwo = [
    {
        "label": "Deferral Duration ",
        "varName": "deferralDuration",
        "type": "date",
        "grid": 2,
    },
    {
        "label": "Deferral Details ",
        "varName": "deferralDetails",
        "type": "text",
        "grid": 2,
    },
];

let remarks = [
    {
        "varName": "misRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class AssetOpsMis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            title: "",
            notificationMessage: "",
            alert: false,
            appId: 0,
            getRemarks: [],
        }
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            let start_url = backEndServerURL + "/variables/" + this.props.appId;
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {


                    let remarksArray = [];
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray,
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    this.setState({
                        inputData: response.data,
                        varValue: this.copyJson(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        getData: true,
                        loading: false
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = backEndServerURL + "/startCase/asset_mis_case_start";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    this.setState({
                        appId: response.data.id,
                        loading: false
                    });


                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Retail Loan Archiving
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Retail Loan Archiving
                </h4>
            )
        }
    };

    submitHandler = () => {
        let error = MyValidation.defaultValidation(retailLoanJsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0
        } else {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.misRemarks + "/" + this.state.appId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                })
                .catch(error => {
                    console.log(error)
                });

            let postData = this.state.inputData;
            postData.category = "Retail";
            postData.subCategory = "Loan Archiving";
            postData.serviceType = "Retail Loan Archiving";
            postData.subServiceType = "Retail Loan Archiving";
            postData.misRemarks = undefined;

            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                getData: false
                            });
                            if (this.props.appId === undefined) {
                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000);
                            } else {
                                this.props.closeModal();
                            }

                        })
                        .catch(error => {
                            console.log(error);
                        });


                })
                .catch(error => {
                    console.log(error)
                });
        }
    };


    render() {
        const {classes, onClose} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {CommonJsonFormComponent.renderJsonForm(this.state, retailLoanJsonForm.concat(jsonFormPartTwo), this.updateComponent)}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderRemarksData()}

                                        <Grid item xs={12}>

                                        </Grid>

                                        {this.renderRemarks()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={this.submitHandler}
                                >
                                    Submit
                                </button>
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)(AssetOpsMis)