function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
        returnObjectVariables[i]["required"] = false;
    }
    return returnObject;
}

let csJsonForm = [
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 4,
        enum: [
            "2k",
            "5k",
            "10k",
            "20k",
            "25k",
            "50k",
        ],
        conditional: true,
        conditionalVarName: "showCardType",
        conditionalVarValue: "giftCard"
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 4,
        enum: [
            "AMEX Instant Reloadable Prepaid Card",
        ],
        conditional: true,
        conditionalVarName: "showCardType",
        conditionalVarValue: "reloadablePrepaidCard"
    },
    {
        type: "empty",
        grid: 8,
    },
    {
        varName: "quantity",
        type: "text",
        label: "Quantity",
        validation: "numeric",
        required: true,
        grid: 4,

    },
    {
        type: "empty",
        grid: 8,
    },
];

let bomJsonForm = {};
bomJsonForm = makeReadOnlyObject(JSON.parse(JSON.stringify(csJsonForm)));


let giftCardIssuanceCsSearch = [
    {
        varName: "selectCustomerType",
        label: "Customer Type",
        type: "select",
        enum: [
            "Existing Customer",
            "New Customer"
        ],
        grid: 2,
        required: true
    },
    {
        varName: "clientId",
        label: "Client Id",
        type: "text",
        required: true,
        grid: 2,
    }
];

let giftCardIssuanceCsAfterSearch = [
    {
        varName: "casaAccountNumber",
        label: "CASA Account Number",
        type: "textApiCall",
        grid: 2,
        required: true,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "cardAccountNumber",
        label: "Card Account Number",
        type: "text",
        required: true,
        grid: 2,
    },
    // {
    //     varName: "dob",
    //     label: "Date of Birth",
    //     type: "date",
    //     readOnly: true,
    //     grid: 2,
    // },
    {
        varName: "cardNumber",
        label: "Card Number",
        onKeyDown: true,
        type: "autoCompleteValueReturn",
        enum: [],
        required: true,
        grid: 2,
    },
    {
        varName: "cardType",
        label: "Card Type",
        type: "text",
        readOnly: true,
        grid: 2,
        multiline: true,
        conditional: true,
        conditionalVarName: "showCardType",
        conditionalVarValue: true
    },
    {
        varName: "customerName",
        label: "Customer Name",
        type: "text",
        grid: 2,
        readOnly: true,
        conditionalArray: true,
        conditionalArrayList: ["selectCustomerType","showAccountInfo"],
        conditionalArrayValue: ["Existing Customer",true]
    },
    {
        varName: "email",
        label: "Email",
        type: "text",
        readOnly: true,
        grid: 2,
        conditionalArray: true,
        conditionalArrayList: ["selectCustomerType","showAccountInfo"],
        conditionalArrayValue: ["Existing Customer",true]
    },
    {
        varName: "mobileNumber",
        label: "Cell Phone",
        type: "text",
        readOnly: true,
        grid: 2,
        conditionalArray: true,
        conditionalArrayList: ["selectCustomerType","showAccountInfo"],
        conditionalArrayValue: ["Existing Customer",true]
    },
    {
        varName: "customerName",
        label: "Customer Name",
        type: "text",
        grid: 2,
        required: true,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "email",
        label: "Email",
        type: "text",
        required: true,
        grid: 2,
        validation: "email",
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "mobileNumber",
        label: "Cell Phone",
        type: "text",
        required: true,
        validation: "length",
        minLength: 11,
        maxLength: 11,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "nationalId",
        label: "National ID",
        type: "text",
        validation: "NID",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showNID",
        conditionalVarValue: true
    },
    {
        varName: "smartCard",
        label: "Smart Card",
        type: "text",
        validation: "length",
        minLength: 10,
        maxLength: 10,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showNID",
        conditionalVarValue: true
    },
    // {
    //     varName: "amount",
    //     label: "Amount",
    //     type: "text",
    //     required: true,
    //     grid: 2,
    // },
];
let giftCardRequisitionBom = {};
giftCardRequisitionBom = makeReadOnlyObject(JSON.parse(JSON.stringify(giftCardIssuanceCsSearch.concat(giftCardIssuanceCsAfterSearch))));


let csReturnGiftCardRequisition = {};
csReturnGiftCardRequisition = makeReadOnlyObject(JSON.parse(JSON.stringify(giftCardIssuanceCsSearch))).concat(giftCardIssuanceCsAfterSearch);


let prepaidCardIssuanceCsSearch = [
    {
        varName: "selectCustomerType",
        label: "Customer Type",
        type: "select",
        enum: [
            "New Customer",
            "Existing Customer"
        ],
        grid: 2,
        required: true
    },
    {
        varName: "clientId",
        label: "Client Id",
        type: "text",
        grid: 2,
        required: true
    },
    {
        varName: "accountNumber",
        label: "Account Number",
        type: "text",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    }
];

let prepaidCardIssuanceCsAfterSearch = [
    {
        varName: "nationalId",
        label: "National ID",
        type: "textApiCall",
        validation: "NID",
        grid: 2,
        required: true
    },
    {
        varName: "smartCard",
        label: "Smart Card",
        type: "text",
        validation: "length",
        minLength: 10,
        maxLength: 10,
        required: true,
        grid: 2,
    },
    {
        varName: "cardAccountNumber",
        label: "Card Account Number",
        type: "text",
        grid: 2,
        required: true
    },
    {
        varName: "cardNumber",
        label: "Card Number",
        onKeyDown: true,
        type: "autoCompleteValueReturn",
        enum: [],
        required: true,
        grid: 2,
    },
    {
        varName: "cardStatus",
        label: "Card Status",
        type: "text",
        // enum: [],
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showCardStatus",
        conditionalVarValue: true
    },
    {
        varName: "nameOnCard",
        label: "Name on Card",
        type: "text",
        validation: "length",
        validationLengthType: "string",
        minLength: 1,
        maxLength: 19,
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        label: "Customer Name",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "customerName",
        label: "Customer Name",
        type: "text",
        // readOnly: true,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "accountStatus",
        label: "Account Status",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "CBNumber",
        label: "CB Number",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "casaSolId",
        label: "CASA SOL ID",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "dob",
        label: "Date of Birth",
        type: "date",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "dob",
        label: "Date of Birth",
        type: "date",
        // readOnly: true,
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "fatherName",
        label: "Father's Name",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "fatherName",
        label: "Father's Name",
        type: "text",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "motherName",
        label: "Mother's Name",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "motherName",
        label: "Mother's Name",
        type: "text",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "mailingAddress",
        label: "Mailing Address",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "mailingAddress",
        label: "Mailing Address",
        type: "text",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "region",
        label: "Region",
        type: "autoCompleteValueReturn",
        enum: [],
        required: true,
        onKeyDown: true,
        grid: 2,
    },
    {
        varName: "city",
        label: "City",
        type: "autoCompleteValueReturn",
        enum: [],
         // required: true,
        grid: 2,
        conditionalVarName: "showCity",

    },
    {
        varName: "passportNumber",
        label: "Passport Number",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "passportNumber",
        label: "Passport Number",
        type: "text",
        // required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "email",
        label: "Email",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "email",
        label: "Email",
        type: "text",
        required: true,
        validation: "email",
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "mobileNumber",
        label: "Mobile Number",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "Existing Customer"
    },
    {
        varName: "mobileNumber",
        label: "Mobile Number",
        type: "text",
        required: true,
        validation: "length",
        minLength: 11,
        maxLength: 11,
        grid: 2,
        conditional: true,
        conditionalVarName: "selectCustomerType",
        conditionalVarValue: "New Customer"
    },
    {
        varName: "rmCode",
        label: "RM Code",
        type: "textApiCall",
        required: true,
        grid: 2,
    },
    {
        varName: "rmName",
        label: "RM name",
        type: "text",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showRmName",
        conditionalVarValue: true
    }
];

let prepaidCardRequisitionBom = {};
prepaidCardRequisitionBom = makeReadOnlyObject(JSON.parse(JSON.stringify(prepaidCardIssuanceCsSearch.concat(prepaidCardIssuanceCsAfterSearch))));


let csReturnPrepaidCardRequisition = {};
csReturnPrepaidCardRequisition = makeReadOnlyObject(JSON.parse(JSON.stringify(prepaidCardIssuanceCsSearch))).concat(prepaidCardIssuanceCsAfterSearch);


export {
    csJsonForm,
    bomJsonForm,
    giftCardIssuanceCsSearch,
    giftCardIssuanceCsAfterSearch,
    giftCardRequisitionBom,
    prepaidCardIssuanceCsSearch,
    prepaidCardIssuanceCsAfterSearch,
    prepaidCardRequisitionBom,
    csReturnPrepaidCardRequisition,
    csReturnGiftCardRequisition
}
