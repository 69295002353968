import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {
    linkAccountChangeRequest,
    lockerJsonFormOne,
    lockerJsonFormThree,
    lockerJsonFormTwo,
} from '../WorkflowJsonFormRashed';
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import SignatureButton from "../Maintenance/SignatureButton";

let lockerJsonForm = [
    {
        "varName": "lockerNumber",
        "type": "textDedup",
        "validation": "numeric",
        "required": true,
        "label": "Locker Number",
        "grid": 2
    }
];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12,
        "required": true,
    }];

const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}


];
const arrayListIndividual = [

    {label: 'Locker Maintenance Form'},
    {label: 'Nominee Photo ID'},
    {label: 'Nominee Assign Form'},
    {label: 'CITY LOCKER APPLICATION FORM'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];

const gdCopy = [
    {label: 'GD Copy One'},
    {label: 'GD Copy Two'},
    {label: 'GD Copy Three'},
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class LockerMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            showValue: false,
            inputData: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            fileUploadData: {},
            gdFileUploadData: {},
            selectedDate: {},
            uploadModal: false,
            loading: true,
            getMappingAllImage: false,
            gdGetMappingAllImage: false,
            loaderNeeded: null,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            lockerGetData: false,
            beforeSearch: true,
            signatureCard: false,
            numberOfNomineeOld: 2,
            existingNominee: [],
            nominee: false,
            allData: {},
            objectForExistingNominee: [],
            gdUploadModal: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {

        let url = backEndServerURL + "/startCase/cs_data_capture";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);

            this.setState({
                appId: response.data.id,
                loading: false
            });
        })
            .catch((error) => {
                console.log(error);
            });

        var sl;
        var objectForExistingNominee = [];
        for (var i = 0; i < this.state.numberOfNomineeOld; i++) {
            sl = i + 1;
            objectForExistingNominee.push(
                {
                    "varName": "Nominee " + i,
                    "type": "title",
                    "label": "Existing  Nominee  " + sl,
                    "grid": 12,

                },
                {
                    "varName": "nomineeNameOld" + sl,
                    "type": "text",
                    "label": "Existing Nominee Name",
                    "grid": 2,
                },

                {
                    "varName": "nomineeNameNew" + sl,
                    "type": "text",
                    "label": "New Nominee Name",
                    "grid": 2,
                    "validation": "string",
                    "required": true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "dob1Old" + sl,
                    "type": "date",
                    "label": "Existing D.O.B",
                    "grid": 2,
                    "readOnly": true
                },
                {
                    "varName": "dob1New" + sl,
                    "type": "date",
                    "maxDate": true,
                    "label": "New D.O.B",
                    "required": true,
                    "grid": 2,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "percentageNomineeOld" + sl,
                    "type": "text",
                    "label": "Existing Percentage %",
                    "grid": 2,
                },
                {
                    "varName": "percentageNomineeNew" + sl,
                    "type": "text",
                    "label": "New Percentage %",
                    "grid": 2,
                    "validation": "percent",
                    "required": true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "nomineeMinorOld" + sl,
                    "type": "text",
                    "label": "Existing Nominee Minor",
                    "grid": 2,
                },
                {
                    "varName": "nomineeMinorNew" + sl,
                    "type": "select",
                    "enum": [
                        "Yes",
                        "No"
                    ],
                    "label": "New Nominee Minor",
                    "grid": 2,
                    "required": true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "guardiansName" + sl,
                    "type": "text",
                    "label": "Guardians Name",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                },
                {
                    "varName": "guardianCode" + sl,
                    "type": "text",
                    "label": "Guardian Code",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "address4" + sl,
                    "type": "text",
                    "label": "Address",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "cityCode2" + sl,
                    "type": "select",
                    "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
                    "label": "City Code",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "stateCode2" + sl,
                    "type": "text",
                    "label": "State Code",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "postalCode2" + sl,
                    "type": "text",
                    "label": "Postal Code",
                    "validation": "numeric",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "country2" + sl,
                    "type": "select",
                    "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
                    "label": "Country",
                    "grid": 2,
                    "conditional": true,
                    "conditionalVarName": "nomineeMinorNew" + sl,
                    "conditionalVarValue": "Yes",
                    required: true,
                },
                {
                    "varName": "empty",
                    "type": "empty",
                    "label": "",
                    "grid": 8,
                },
                {
                    "varName": "nomineeDelete" + sl,
                    "type": "checkbox",
                    "label": "Delete This Nominee",
                    "grid": 6,
                },
            )

        }


        this.setState({
            objectForExistingNominee: objectForExistingNominee,
        })
    }

    functionForOnKeyDownForDedup = (event, data) => {
        event.preventDefault()
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.lockerSearch(event);

        }

    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };

    lockerSearch = () => {
        let error = MyValidation.defaultValidation(lockerJsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/locker/branch/" + this.state.inputData.lockerNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);

                let inputData = this.copyJson(this.state.inputData);
                inputData.lockerChangeRequest = this.state.inputData.lockerChangeRequest;
                inputData.lockerSize = response.data.lockerType;
                inputData.lockerName = response.data.customerName;
                inputData.accountNumber = response.data.accountNumber;


                let showData = response.data;
                showData.existingLinkAccountNumber = '123456789';
                showData.nomineeMinorOld1 = 'NO';
                showData.percentageNomineeOld1 = '50%';
                showData.dob1Old1 = '10-10-1992';
                showData.nomineeNameOld1 = 'Mr. Rakib';
                showData.siDateOld1 = '10-10-1992';
                showData.nomineeMinorOld2 = 'NO';
                showData.percentageNomineeOld2 = '50%';
                showData.dob1Old2 = '10-10-1992';
                showData.nomineeNameOld2 = 'Mr. Rana';
                showData.siDateOld2 = '10-10-1992';


                this.setState({
                    allData: response.data,
                    inputData: this.copyJson(showData),
                    varValue: showData,
                    showValue: true,
                    loading: false,
                    lockerGetData: true,
                    beforeSearch: false
                });

            })
                .catch((error) => {
                    this.state.varValue["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.state.inputData["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.setState({
                        loading: false,
                        showValue: true,
                        cbNotFound: true,
                        title: "Locker Not Found"
                    });
                })
        }


    };

    renderCustomerName = () => {
        if (this.state.lockerGetData) {
            return (

                <div style={{
                    "border-top-style": "ridge",
                    "border-bottom-style": "ridge",
                }}>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}>

                            </Grid>

                            <Grid item xs={3}>
                                <label for="customerName"><font size="2"><b> Customer Name :</b></font></label>

                                {this.state.allData.customerName}
                            </Grid>

                            <Grid item xs={3}>
                                <label for="cbNumber"><b><font size="2">Locker Number :</font></b> </label>

                                {this.state.allData.lockerNumber}
                            </Grid>
                            <Grid item xs={2}>
                                <label for="customerName"><font size="2"><b> Locker Type :</b></font></label>

                                {this.state.allData.lockerType}
                            </Grid>

                            <Grid item xs={4}>
                                <label for="cbNumber"><b><font size="2">Locker key :</font></b> </label>

                                {this.state.allData.lockerKey}
                            </Grid>

                        </ThemeProvider>
                    </Grid>
                </div>
            )
        }
    };


    handleSubmit = (event) => {

        event.preventDefault();

        let error = false;
        let errorTwo = false;
        let errorThree = false;

        if (this.state.inputData.nomineeChangeRequest === true && this.state.getNominee) {
            error = MyValidation.defaultValidation(this.state.objectForNominee, this.state);
            this.forceUpdate();
        }

        if (this.state.inputData.linkAccountChangeRequest === true) {
            errorTwo = MyValidation.defaultValidation(linkAccountChangeRequest, this.state);
            this.forceUpdate();
        }


        if (this.state.inputData.nomineeChangeRequest === true) {
            errorThree = MyValidation.defaultValidation(this.state.objectForExistingNominee, this.state);
            this.forceUpdate();
        }

        if (error === true || errorTwo === true || errorThree === true) {
            return 0
        } else {

            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data)
            })
                .catch((error) => {
                    console.log(error)
                });
            var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

            let data = this.copyJson(this.state.inputData);

            data.cs_deferal = 'NO';
            data.serviceType = 'Locker';
            data.subServiceType = 'LockerMaintenance';
            data.category = 'Locker';
            data.subCategory = 'Maintenance';
            data.numberOfOldNominee = this.state.numberOfNomineeOld;
            data.numberOfNewNominee = this.state.inputData["numberOfNominee"];
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(data);

                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    console.log("Successfully Routed!");

                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                })

                    .catch((error) => {
                        console.log(error);
                    });
            })
                .catch((error) => {
                    console.log(error)
                });
        }

    };


    // handleSubmitDraft = (event) => {
    //     event.preventDefault();

    //     var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
    //     let data = this.state.inputData;
    //     data.serviceType = 'Locker';
    //     data.subServiceType = 'Locker CLoseing';
    //     data.category = 'Locker';
    //     data.subCategory = 'Closeing';

    //     axios.post(variableSetUrl, data, { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } }).then((response) => {
    //         console.log(response.data);

    //         this.setState({
    //             title: "Successfull!",
    //             notificationMessage: "Successfully Save!",
    //             alert: true
    //         })
    //         this.close()
    //     })
    //         .catch((error) => {
    //             console.log(error)
    //         });
    // }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    beforeLockerSearch = () => {
        if (this.state.beforeSearch) {
            return (
                <Grid container spacing={1}>


                    <Grid item xs='12'></Grid>

                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonForm, this.updateComponent, this.onKeyDownForDedup)
                    }
                    <Grid item xs='3'>
                        <button className="btn btn-outline-primary btn-sm" onClick={this.lockerSearch}
                                style={{marginTop: '15px'}}>Search
                        </button>
                    </Grid>
                </Grid>
            )
        }
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    close = () => {
        this.props.closeModal();
    };

    LockerSearchOne = () => {
        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormOne, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )

    };

    LockerSearchTwo = () => {

        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormTwo, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )

    };

    LockerSearchThree = () => {
        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormThree, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )
    };

    renderExistingNomineeForm = () => {

        if (this.state.inputData.nomineeChangeRequest === true) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForExistingNominee, this.updateComponent, this.onKeyDownForDedup)

            )
        }
    };

    renderNewNominee = () => {
        if (this.state.inputData.nomineeChangeRequest === true) {
            return (
                <Grid item xs={12}>
                    <button
                        onClick={this.handleChangeNominee}
                        className="btn btn-danger btn-sm">
                        Number Of New Nominee
                    </button>
                </Grid>
            )
        }
    };

    renderNomineeForm = () => {
        if (this.state.inputData.nomineeChangeRequest === true && this.state.getNominee) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent, this.onKeyDownForDedup)
            )
        }
    };

    handleChangeNominee = (event) => {
        event.preventDefault();
        this.setState({
            getNumberofNominee: true
        })
    };

    renderNumberOfNominee = () => {
        if (this.state.inputData.nomineeChangeRequest === true && this.state.getNumberofNominee) {
            return (
                <Grid item xs={2}>
                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Number Of Nominee</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={numberOfNominee} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();

        if (option !== null) {
            this.state.inputData["numberOfNominee"] = option.title;
            this.forceUpdate();

            if (option.title > 0) {
                var sl;
                let objectForNominee = [];
                for (var i = 0; i < option.title; i++) {
                    sl = i + 1;
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "New Nominee  " + sl,
                            "grid": 12,

                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "validation": "string",
                            "grid": 2,
                            required: true,
                        },

                        {
                            "varName": "dob1" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "maxDate": true,
                            "grid": 2,
                            required: true,
                        },


                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 2,
                            "validation": "percent",
                            required: true,
                        },
                    )

                }

                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true

                });
                this.renderNomineeForm();

            }
        } else if (option === null) {
            this.setState({
                objectForNominee: [],
                getNominee: true,
                getNumberofNominee: false,

            });
            this.renderNomineeForm();

        }

    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.lockerGetData) {
            return <UploadComponent appId={this.state.appId} indexList={arrayListIndividual} classes={classes}/>
        }
    };

    previewButton = () => {

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )

    };


    lastSegments = () => {
        if (this.state.inputData.linkAccountChangeRequest === true || this.state.inputData.keyReplacement === true || this.state.inputData.nomineeChangeRequest) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {this.uploadFunction()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.previewButton()}
                    </Grid>
                    <Grid item xs='12'>
                        {this.renderRemarks()}
                    </Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <button
                            className="btn btn-outline-info btn-sm"
                            onClick={this.handleSubmitDraft}
                        >
                            Draft
                                    </button> */}
                    </Grid>
                </Grid>
            )
        }
    };

    linkAccount = () => {
        if (this.state.inputData.linkAccountChangeRequest === true) {
            return CommonJsonFormComponent.renderJsonForm(this.state, linkAccountChangeRequest, this.updateComponent, this.onKeyDownForDedup)
        }
    };

    keyReplacement = () => {
        if (this.state.inputData.keyReplacement === true) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <UploadComponent name="Upload GD" appId={this.state.appId} indexList={gdCopy}
                                         classes={this.props}/>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>
            )
        }
    };


    afterLockerSearch = () => {
        if (this.state.lockerGetData) {
            return (
                <Grid container spacing={1}>

                    {
                        this.LockerSearchOne()
                    }

                    {
                        this.linkAccount()
                    }

                    {
                        this.LockerSearchTwo()
                    }

                    {
                        this.renderExistingNomineeForm()
                    }


                    {
                        this.renderNewNominee()
                    }

                    {
                        this.renderNumberOfNominee()
                    }

                    {
                        this.renderNomineeForm()
                    }

                    {
                        this.LockerSearchThree()
                    }

                    {
                        this.keyReplacement()
                    }

                    {
                        this.lastSegments()
                    }
                </Grid>
            )

        }
    };

    render() {

        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Locker Maintenance</h4>
                    </CardHeader>
                    <CardBody>
                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {
                                this.renderNotification()
                            }

                            {
                                this.renderCustomerName()
                            }


                            {
                                this.beforeLockerSearch()
                            }

                            {
                                this.afterLockerSearch()
                            }
                        </ThemeProvider>
                    </CardBody>
                </Card>
            );
        }
    }

}

export default withStyles(styles)(LockerMaintenance);
