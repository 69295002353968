import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";

let populateForm = [
    {
        varName: "solId",
        type: "text",
        label: "Branch Sol ID",
        required: true,
        grid: 2,
    },
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: [
            "CITY MAXX STAFF",
            "CITY MAXX GENERAL",
            "CITY MAXX CORPORATE 1st year free",
            "CITY MAXX KIOSK CAMPAIGN",
            "CITY MAXX CITY GEMS (PRIORITY BANKING)",
            "CITY MAXX YOUTH",
            "MASTER CARD PLATINUM STAFF",
            "MASTER CARD PLATINUM GENERAL",
            "MASTER CARD DEBIT CARD STAFF",
            "MASTER CARD DEBIT CARD GENERAL",
            "MASTER DEBIT CARD CORPORATE 1st year free",
            "MASTER CARD DEBIT CARD YOUTH",
            "VISA DEBIT CARD STAFF",
            "VISA DEBIT CARD GENERAL",
            "VISA CORPORATE 1st year free",
            "VISA DEBIT CARD YOUTH",
            "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
            "Citymaxx Corporate 2nd Year Tk. 300 ",
            "Master Debit Card Corporate 2nd year tk 300",
            "VISA Debit Card corporate 2nd year tk 300",
            "CITYMAXX corporate lifetime free",
            "VISA Debit Card corporate lifetime free",
            "Master Debit Card corporate lifetime free",
            "Master Platinum Gems staff card",
            "MaxX City Gems Sapphire",
            "MC Platinum Debit Card - City Gems - Sapphire",
            "Master Manarah Debit card general",
            "Master Manarah Debit card Corporate",
            "VISA Debit Card - City Alo",
            "Easy City Maxx Debit Card",
            "Easy Plus City Maxx Debit Card",
            "Easy VISA Debit card",
            "Easy Plus VISA Debit card",
            "Visa Corporate 1st Year & 2nd Year Free",
            "Master Card Corporate 1st Year & 2nd Year Free",
            "City MaxX Corporate 1st Year & 2nd Year Free",
            "Debit Card Fee - University Campaign [172.5,345]",
        ]
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditionalOrValue: true,
        conditionalVarName: "productType",
        conditionalOrList: [
            "CITY MAXX STAFF",
            "CITY MAXX GENERAL",
            "CITY MAXX CORPORATE 1st year free",
            "CITY MAXX KIOSK CAMPAIGN",
            "CITY MAXX CITY GEMS (PRIORITY BANKING)",
            "CITY MAXX YOUTH",
            "Citymaxx Corporate 2nd Year Tk. 300",
            "CITYMAXX corporate lifetime free",
            "MaxX City Gems Sapphire",
            "Easy City Maxx Debit Card",
            "Easy Plus City Maxx Debit Card",
            "City MaxX Corporate 1st Year & 2nd Year Free"
        ],
        enum: ["CITY MAXX DEBIT CARD"]
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditionalOrValue: true,
        conditionalVarName: "productType",
        conditionalOrList: [
            "MASTER CARD PLATINUM STAFF",
            "MASTER CARD PLATINUM GENERAL",
            "MASTER CARD DEBIT CARD STAFF",
            "MASTER CARD DEBIT CARD GENERAL",
            "MASTER DEBIT CARD CORPORATE 1st year free",
            "MASTER CARD DEBIT CARD YOUTH",
            "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
            "Master Debit Card Corporate 2nd year tk 300",
            "Master Debit Card corporate lifetime free",
            "Master Platinum Gems staff card",
            "MC Platinum Debit Card - City Gems - Sapphire",
            "Master Manarah Debit card general",
            "Master Manarah Debit card Corporate",
            "Master Card Corporate 1st Year & 2nd Year Free",
        ],
        enum: ["MASTER CARD DEBIT CARD"]
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        conditionalOrValue: true,
        conditionalVarName: "productType",
        conditionalOrList: [
            "VISA DEBIT CARD STAFF",
            "VISA DEBIT CARD GENERAL",
            "VISA CORPORATE 1st year free",
            "VISA DEBIT CARD YOUTH",
            "VISA Debit Card corporate 2nd year tk 300",
            "VISA Debit Card corporate lifetime free",
            "VISA Debit Card - City Alo",
            "Easy VISA Debit card",
            "Easy Plus VISA Debit card",
            "Visa Corporate 1st Year & 2nd Year Free",
            "Debit Card Fee - University Campaign [172.5,345]"
        ],
        enum: ["VISA DEBIT CARD"]
    },
    {
        varName: "stockLimit",
        type: "text",
        label: "Stock Limit",
        grid: 2,
        required: true,
    },

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class InstaCardRequisition extends Component {
    constructor() {
        super();
        this.state = {
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            notificationMessage: "",
            alert: false,
            loading: true,
            title: "",
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    submitHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(populateForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            let postUrl = backEndServerURL + "/saveInstaDebitCardConfiguration";
            axios.post(postUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
                if (response.status === 200) {
                    this.setState({
                        alert: true,
                        title: "Successful!",
                        notificationMessage: "Configuration Save Successful"
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch(error => {
                console.log(error)
            })
        }


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Instapack Configuration
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Instapack Configuration
                </h4>
            )
        }
    };


    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>

                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        populateForm,
                                        this.updateComponent
                                    )}
                                </ThemeProvider>
                            </Grid>

                            <Grid item xs="3" style={{marginTop: "20px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.submitHandler}
                                >
                                    Submit
                                </button>
                            </Grid>
                        </CardBody>
                        {
                            this.renderNotification()
                        }
                    </Card>
                </React.Fragment>
            );
        }


    }
}

export default withStyles(styles)(InstaCardRequisition);
