import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axiosDownload from '../FunctionForFileDownload';
import TablePagination from "@material-ui/core/TablePagination";
import {createMuiTheme} from "@material-ui/core/styles";
import {
    buttonStyle,
    commonInboxButton,
    localization,
    options,
    tabActive,
    tabInactive,
    tableIcons
} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import withStyles from "@material-ui/core/styles/withStyles";
import InstaPackFileUploadForTag from "./InstaPackFileUploadForTag";
import loader from "../../../Static/loader.gif";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "type",
        type: "select",
        label: "Account Source",
        grid: 2,
        enum: [
            "Finacle",
            "Ababil",
        ]
    },
    // {
    //     varName: "customerType",
    //     type: "select",
    //     label: "Customer type",
    //     grid: 2,
    //     enum: [
    //         " Normal",
    //         "CityAlo",
    //         "CityAlo(Ababil)",
    //         "Priority",
    //         "Priority(Finacle)",
    //         "Priority(Ababil)",
    //         "Sapphire"
    //     ]
    // },
    {
        varName: "accountType",
        type: "select",
        label: "Account type",
        grid: 2,
        enum: [
            "SAVINGS",
            "CURRENT",
            "CITY ALO",
            // "Savings(Alo)",
            // "Savings(Ababil Alo)"
        ]
    },
    // {
    //     varName: "currency",
    //     type: "autoCompleteValueReturn",
    //     label: "Currency",
    //     enum: [],
    //     grid: 2
    // },
    {
        varName: "branch",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Branch",
        grid: 2,
    },
];

class ManageInstaPack extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            autoComplete: {},
            responseData: [],
            activeItem: "New",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            searchItem: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
            rowsPerPage: 100,
            allData: [],
            mailModal: false,
            selectedRowIdArray: [],
            loader: true,
            afterApproved: false,
            columns: [
                {title: "Case Id", field: "caseId"},
                {title: "Customer Name", field: "userName"},
                {title: "Branch Name", field: "branchName"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Account Source", field: "accountSource"},
                {title: "Account Type", field: "accountType"},
                {title: "Page of instapack", field: "pageofinstapack"},
                {title: "Quantity", field: "quantity"},
                {title: "Batch No", field: "batchNo"},
                {title: "Status", field: "status"},
                {title: "Maker", field: "maker"},
                {title: "Verifier ID", field: "verifier"},
            ], productionColumns: [
                {title: "Case Id", field: "caseId"},
                {title: "Customer Name", field: "userName"},
                {title: "Branch Name", field: "branchName"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Account Type", field: "accountType"},
                {title: "Account Source", field: "accountSource"},
                {title: "Page of instapack", field: "pageofinstapack"},
                {title: "Quantity", field: "quantity"},
                {title: "Cheque Number", field: "chequeNumber"},
                {title: "Batch No", field: "batchNo"},
                {title: "Status", field: "status"},
                {title: "Maker", field: "maker"},
                {title: "Verifier ID", field: "verifier"},
            ],
            newColumns: [
                {title: "Case Id", field: "caseId"},
                // {title: "User Name", field: "userName"},
                {title: "Branch Name", field: "branchName"},
                {title: "Account Source", field: "accountSource"},
                {title: "Account Type", field: "accountType"},
                {title: "Page of instapack", field: "pageofinstapack"},
                {title: "Quantity", field: "quantity"},
                {title: "Status", field: "status"},
                {title: "Maker", field: "maker"},
                {title: "Verifier ID", field: "verifier"},
                {title: "Action", field: "action"},
            ],

        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
        });

        if (value === "All") {
            this.fetchDataFromAPI('All', 'getAllInstaPack/page/0');
        } else if (value === "New" || value === "Cancel") {
            this.fetchDataFromAPI(value, 'getinstapacknewstatus')
        }
            //  else if (value === "Failed" || value === "On Processing") {
            //         //     this.fetchDataFromAPI(value, 'instaPack/getFailedList/0')
        //         // }
        else {
            this.fetchDataFromAPI(value)
        }
    };

    dynamicApiCall = (apiType) => {
        let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        this.dynamicApiCall("branch");
        let inbox_url = backEndServerURL + "/getinstapacknewstatus/New/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        userName: response.data.content[i].customerName,
                        branchName: response.data.content[i].branchName,
                        accountNumber: response.data.content[i].accountNumber,
                        accountSource: response.data.content[i].accountSource,
                        accountType: response.data.content[i].accountType,
                        instapackDesign: response.data.content[i].chequebookDesign,
                        pageofinstapack: response.data.content[i].pageOfChequebook,
                        quantity: response.data.content[i].quantity,
                        chequeNumber: response.data.content[i].chequeNumber,
                        batchNo: response.data.content[i].batchNo,
                        status: response.data.content[i].status,
                        maker: response.data.content[i].initiatorId,
                        verifier: response.data.content[i].verifierId,
                        action: this.renderEditButton(response.data.content[i].id),
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                setTimeout(() => {
                    this.setState({
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        allData: array,
                        responseData: responseArray,
                        loader: false,
                        loading: false
                    });
                }, 1000);
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    renderEditButton = (id) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.onProceedButtonClick(event, id)}
                >
                    Proceed
                </Button>
            </>
        )
    };

    onProceedButtonClick = (event, id) => {
        event.preventDefault();
        this.setState({
            loader: true
        });
        let url = backEndServerURL + "/instaPack/createBulkAccounts/" + id;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.fetchDataFromAPI('New', 'getinstapacknewstatus')
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loader: false
                })
            });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    resetHandler = () => {
        window.location.reload();
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    searchData = (e, page, status) => {

        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }

        let inbox_url = backEndServerURL + "/filterInstaPackInventory/" + status + "/" + page;

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].appId,
                        userName: response.data.content[i].customerName,
                        branchName: response.data.content[i].branchName,
                        accountNumber: response.data.content[i].accountNumber,
                        accountSource: response.data.content[i].accountSource,
                        accountType: response.data.content[i].accountType,
                        instapackDesign: response.data.content[i].chequebookDesign,
                        pageofinstapack: response.data.content[i].pageOfChequebook,
                        quantity: response.data.content[i].quantity,
                        chequeNumber: response.data.content[i].chequeNumber,
                        batchNo: response.data.content[i].batchNo,
                        status: response.data.content[i].status,
                        maker: response.data.content[i].initiatorId,
                        verifier: response.data.content[i].verifierId,
                        action: this.renderEditButton(response.data.content[i].id),
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };


    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false,
                loader: false
            })
        } else {
            this.setState({
                mailModal: false,
                loader: true
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/instaPackSendToVendor";
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    this.fetchDataFromAPI('Ready for Production')
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };


    mailNotification = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.mailModal}>
                <DialogContent className={classes.dialogPaper}>
                    <MailNotification closeModal={this.closeMailModal} processName="INSTA CHEQUE BOOK"/>
                </DialogContent>
            </Dialog>
        )
    };


    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Insta Cheque Book
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, 'All')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Deliverable Management (Insta Cheque Books)</h4>
                        </CardHeader>
                        <CardBody>
                            {this.mailNotification()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.openUploadModal}>
                                <DialogContent className={classes.dialogPaper}>
                                    <InstaPackFileUploadForTag closeModal={this.closeUploadModal}
                                                               fetchData={this.fetchDataFromAPI}/>
                                </DialogContent>
                            </Dialog>
                            <div className="deliverable">
                                <React.Fragment>
                                    <div>
                                        <Menu pointing>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />
                                            <Menu.Item
                                                name="New"
                                                active={activeItem === "New"}
                                                style={
                                                    activeItem === "New"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "New");
                                                }}
                                            />
                                            <Menu.Item
                                                name="On Processing"
                                                active={activeItem === "On Processing"}
                                                style={
                                                    activeItem === "On Processing"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "On Processing");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Failed"
                                                active={activeItem === "Partial Upload"}
                                                style={
                                                    activeItem === "Partial Upload"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "Partial Upload");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Cancel"
                                                active={activeItem === "Cancel"}
                                                style={
                                                    activeItem === "Cancel"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Cancel");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Upload to Finacle"
                                                active={activeItem === "Upload to Finacle"}
                                                style={
                                                    activeItem === "Upload to Finacle"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Upload to Finacle");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Upload to Ababil"
                                                active={activeItem === "Upload to Ababil"}
                                                style={
                                                    activeItem === "Upload to Ababil"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Upload to Ababil");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Ready for Production"
                                                active={activeItem === "Ready for Production"}
                                                style={
                                                    activeItem === "Ready for Production"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Ready for Production");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Send to Vendor"
                                                active={activeItem === "Send to Vendor"}
                                                style={
                                                    activeItem === "Send to Vendor"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Vendor");
                                                }}
                                            />

                                            <Menu.Item
                                                name="Send to Branch"
                                                active={activeItem === "Send to Branch"}
                                                style={
                                                    activeItem === "Send to Branch"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Send to Branch");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Received by Branch"
                                                active={activeItem === "Received by Branch"}
                                                style={
                                                    activeItem === "Received by Branch"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Received by Branch");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Sold"
                                                active={activeItem === "Sold"}
                                                style={
                                                    activeItem === "Sold"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Sold");
                                                }}
                                            />
                                        </Menu>

                                        {/*<Segment>*/}
                                        {this.tableChoice()}
                                        {/*</Segment>*/}
                                    </div>
                                </React.Fragment>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    fetchDataFromAPI = (status, url) => {

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {

            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getinstapackstatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            caseId: response.data.content[i].appId,
                            userName: response.data.content[i].customerName,
                            branchName: response.data.content[i].branchName,
                            accountNumber: response.data.content[i].accountNumber,
                            accountSource: response.data.content[i].accountSource,
                            accountType: response.data.content[i].accountType,
                            instapackDesign: response.data.content[i].chequebookDesign,
                            pageofinstapack: response.data.content[i].pageOfChequebook,
                            quantity: response.data.content[i].quantity,
                            chequeNumber: response.data.content[i].chequeNumber,
                            batchNo: response.data.content[i].batchNo,
                            status: response.data.content[i].status,
                            maker: response.data.content[i].initiatorId,
                            verifier: response.data.content[i].verifierId,
                            action: this.renderEditButton(response.data.content[i].id),
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        activeItem: status,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            this.setState({
                loader: true
            });
            let inbox_url;
            if (this.state.activeItem === status) {
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getAllInstaPack/page/" + page;
                } else if (status === 'New' || status === "Cancel") {
                    inbox_url = backEndServerURL + "/getinstapacknewstatus/" + status + "/" + +page;
                }
                    // else if (status === 'Failed' || status === 'On Processing') {
                    //     inbox_url = backEndServerURL + "/instaPack/getFailedList/" + page;
                // }
                else {
                    inbox_url = backEndServerURL + "/getinstapackstatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                caseId: response.data.content[i].appId,
                                userName: response.data.content[i].customerName,
                                branchName: response.data.content[i].branchName,
                                accountNumber: response.data.content[i].accountNumber,
                                accountSource: response.data.content[i].accountSource,
                                accountType: response.data.content[i].accountType,
                                instapackDesign: response.data.content[i].chequebookDesign,
                                pageofinstapack: response.data.content[i].pageOfChequebook,
                                quantity: response.data.content[i].quantity,
                                chequeNumber: response.data.content[i].chequeNumber,
                                batchNo: response.data.content[i].batchNo,
                                status: response.data.content[i].status,
                                maker: response.data.content[i].initiatorId,
                                verifier: response.data.content[i].verifierId,
                                action: this.renderEditButton(response.data.content[i].id),
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: this.state.activeItem,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            totalRow: response.data.totalElements,
                            loader: false
                        });
                        console.log(this.state.rowsPerPage, this.state.pageNumber, this.state.totalRow,);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForFailedToProceed = (event, rowData, props) => {
        if (this.state.activeItem === "Partial Upload") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url =
                    backEndServerURL +
                    "/instaPack/executeFailedList";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Partial Upload')
                    })
                    .catch(error => {
                        this.setState({
                            loader: false
                        });
                        console.log(error);
                    })
            }

        }
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };

    actionForVendorToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Vendor") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];

            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Send to Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        console.log(response);
                        let download_url = backEndServerURL + "/getinstapackExcelResource/Send to Vendor";
                        axiosDownload.downloadFile(download_url, 'POST', rowIdArray, 'insta-pack.xlsx').then((res) => {
                            this.fetchDataFromAPI('Send to Vendor')
                        }).catch((err) => {
                            this.fetchDataFromAPI('Send to Vendor')
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    };

    actionForProductionToVendor = (event, rowData, props) => {
        if (this.state.activeItem === "Ready for Production") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                mailModal: true,
            });

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                this.setState({
                    selectedRowIdArray: rowIdArray,
                });
            }
        }
    };

    actionForProductionToProceed = (event, rowData, props) => {
        if (this.state.activeItem === "Upload to Finacle" || this.state.activeItem === "Upload to Ababil") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/uploadInstantChequeBook";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.setState({
                            title: "Successful",
                            notificationMessage: "Upload Successful!",
                            alert: true,
                        });
                        this.fetchDataFromAPI(this.state.activeItem )
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            title: "Failed",
                            notificationMessage: "Upload Failed!",
                            alert: true,
                            loader: false
                        })
                    })
            }
        }
    };

    actionForCancel = (event, rowData, props) => {
        if (this.state.activeItem === "Upload to Ababil" ||this.state.activeItem === "Upload to Finacle" || this.state.activeItem === "New") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/instapackRequisitionChangeStatus/Cancel";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.setState({
                            title: "Successful",
                            notificationMessage: "Status Change Successful!",
                            alert: true,
                        });
                        this.fetchDataFromAPI("New", 'getinstapacknewstatus')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            title: "Failed",
                            notificationMessage: "Status Change Failed!",
                            alert: true,
                            loader: false
                        })
                    })
            }
        }
    };

    actionForDeliverToBranchToSendToBranch = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateinstapackstatus/Received by Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Send to Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForReceivedByBranchToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Received by Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Received by Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForReceivedByBranchToSold = (event, rowData, props) => {
        if (this.state.activeItem === "Received by Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Sold";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Received by Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateinstapackstatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    functionForUploadModal = () => {
        this.setState({
            openUploadModal: true
        })
    };

    closeUploadModal = () => {
        this.setState({
            openUploadModal: false
        })
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "New" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.newColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            {/*&nbsp;*/}
                                            {/*<Button*/}
                                            {/*    style={buttonStyle}*/}
                                            {/*    onClick={(event, rowData) => this.actionForCancel(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Proceed*/}
                                            {/*</Button>*/}
                                            {/*<Button*/}
                                            {/*    style={buttonStyle}*/}
                                            {/*    onClick={this.functionForUploadModal}*/}
                                            {/*>*/}
                                            {/*    Upload Excel*/}
                                            {/*</Button>*/}
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCancel(event, rowData, props)}
                                            >
                                                Cancel
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'New')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Partial Upload" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForFailedToProceed(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Cancel" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            &nbsp;
                                            {/*<Button*/}
                                            {/*    style={buttonStyle}*/}
                                            {/*    onClick={(event, rowData) => this.actionForNewToProceed(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Proceed*/}
                                            {/*</Button>*/}
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        else if (this.state.activeItem === "Upload to Finacle" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForProductionToProceed(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Upload to Finacle')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        else if (this.state.activeItem === "Upload to Ababil" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForProductionToProceed(event, rowData, props)}
                                            >
                                                Proceed
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Upload to Ababil')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }

        else if (this.state.activeItem === "Ready for Production" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.productionColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForProductionToVendor(event, rowData, props)}
                                            >
                                                Send to Vendor
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Ready for Production')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Send to Vendor" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForVendorToBranch(event, rowData, props)}
                                            >
                                                Send to Branch
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Vendor')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

            );
        } else if (this.state.activeItem === "Send to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDeliverToBranchToSendToBranch(event, rowData, props)}
                                            >
                                                Received by Branch
                                            </Button>

                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Send to Branch')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Received by Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForReceivedByBranchToSold(event, rowData, props)}
                                            >
                                                Sold
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Received by Branch')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(ManageInstaPack);
