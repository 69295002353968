import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {backEndServerURL} from "../Common/Constant";
import axios from "axios";
import Inbox from "./workflow/Inbox";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";
import CardHeader from "@material-ui/core/CardHeader";

//do something...

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        mode: "inline",
        marginTop: 40,
        //only menu content color


    },
         listSubHeaderRoot: {
             color: "#FFFFFF",
             background: "#DCDCDC",
             marginTop:0,
             marginBottom:20,
             marginLeft:5,
             marginRight:25,
         },
    nested: {
        paddingLeft: theme.spacing.unit * 5,
        //frontSize:6,

        //sub menu back ground color
        color: "#000000",
        '&:hover': {
            color: "#FFFF00",
            background: "#142398"

        }
    },
    menuLinkNested: {


        //sub menu back ground color
        color: "#142398",
        '&:hover': {
            color: "#FFFF00",
            background: "#142398"

        }
    },

    selectedClass: {
        backgroundColor: "#142398",
        color: "#FFFF00",
        fontWeight: "bolder",
    },
    selectedClasss: {
        backgroundColor: "#142398",
        color: "#FFFF00",
        fontWeight: 600,
    },
    menuLink: {
        color: "#142398",

        '&:hover': {
            color: "#FFFF00",
            background: "#142398"
        }
    },

});

class DashboardMenu extends React.Component {
    state = {
        open: true,
        open1: true,
        openHome: true,
        openAdmin: false,
        openCustomer: true,
        openMaster: false,
        openFundTransfer: false,
        openBeneficiaryDetails: false,
        openLiability: false,
        openAsset: false,
        openMerchant: false,
        openCardConfiguration: false,
        openRemittance: false,
        openMaintenance: false,
        otherMaintenance: false,
        openMandate: false,
        openCard: false,
        openBondSanchaypatra: false,
        openDeliverable: false,
        openMailConfiguration: false,
        openGlobalSearch: false,
        openDebitCard: false,
        openChequeBook: false,
        openReports: false,
        openStamp: true,
        selectedMenu: "",
        selectedUrl: "",
        getCountInbox: 0,
        getCountReturn: 0,
        getCountPending: 0,
        setInboxData: false,
        openAssetOperation: false,
        openAdcService: false,
        openPasswordChange: true,
        openDataChange: true,
    };

    componentDidMount() {
        let getUrl = window.location.href;
        getUrl = getUrl.split("#")[1];
        this.setState({
            selectedUrl: getUrl
        })
    }


    handleClickAdmin = () => {

        this.setState(state => ({

            openAdmin: !state.openAdmin,
        }));
    };
    handleClickMaster = () => {

        this.setState(state => ({

            openMaster: !state.openMaster,
        }));
    };
    handleClickAuxiliaryTask = () => {

        this.setState(state => ({

            openAuxiliaryTakInbox: !state.openAuxiliaryTakInbox,
        }));
    };

    handleClick360 = () => {

        this.setState(state => ({

            openCustomer: !state.openCustomer,
        }));
    };
    handleClickLocker = () => {

        this.setState(state => ({

            openLocker: !state.openLocker,
        }));
    };
    handleClickLiability = () => {

        this.setState(state => ({

            openLiability: !state.openLiability,
        }));
    };
    handleClickMandate = () => {

        this.setState(state => ({

            openMandate: !state.openMandate,
        }));
    };
    handleClickMaintenance = () => {

        this.setState(state => ({

            openMaintenance: !state.openMaintenance,
        }));
    };

    handleOtherMaintenance = () => {

        this.setState(state => ({

            otherMaintenance: !state.otherMaintenance,
        }));
    };
    handleClickAsset = () => {

        this.setState(state => ({

            openAsset: !state.openAsset,
        }));
    };
    handleClickMerchant = () => {

        this.setState(state => ({

            openMerchant: !state.openMerchant,
        }));
    };
    handleClickCardConfiguration = () => {

        this.setState(state => ({

            openCardConfiguration: !state.openCardConfiguration,
        }));
    };
    handleClickCard = () => {

        this.setState(state => ({

            openCard: !state.openCard,
        }));
    };
    handleClickRemittance = () => {

        this.setState(state => ({

            openRemittance: !state.openRemittance,
        }));
    };
    handleClickBondSanchaypatra = () => {

        this.setState(state => ({

            openBondSanchaypatra: !state.openBondSanchaypatra,
        }));
    };
    handleClickDeliverable = () => {

        this.setState(state => ({

            openDeliverable: !state.openDeliverable,
        }));
    };
    handleClickGlobalSearch = () => {

        this.setState(state => ({

            openGlobalSearch: !state.openGlobalSearch,
        }));
    };
    handleClickMailConfiguration = () => {

        this.setState(state => ({

            openMailConfiguration: !state.openMailConfiguration,
        }));
    };
    handleClickReports = () => {

        this.setState(state => ({

            openReports: !state.openReports,
        }));
    };
    handleStampTracker = () => {

      this.setState(state => ({

        openStamp: !state.openStamp,
      }));
  };
    handleClickDebitCard = () => {

        this.setState(state => ({

            openDebitCard: !state.openDebitCard,
        }));
    };
    handleClickChequeBook = () => {
        this.setState(state => ({

            openChequeBook: !state.openChequeBook,
        }));
    };

    handleClickFundTransfer = () => {
        this.setState(state => ({
            openFundTransfer: !state.openFundTransfer,
        }));
    };

    handleClickBeneficiaryDetails = () => {
        this.setState(state => ({
            openBeneficiaryDetails: !state.openBeneficiaryDetails,
        }));
    };

    handleClickAssetOperation = () => {
        this.setState(state => ({
            openAssetOperation: !state.openAssetOperation,
        }));
    };
    handleClickAdcService = () => {
        this.setState(state => ({
            openAdcService: !state.openAdcService,
        }));
    };


    showMenu = (menuList, props, includeMenu, handleClick, openMenu, menuName) => {
        const {classes} = props;
        if (menuList!==undefined && menuList.includes(includeMenu)) {
            return (
                <ListItem className={classes.menuLink} button onClick={handleClick}>

                    {openMenu ? <ExpandLess/> : <ExpandMore/>} <b><ListItemText style={{marginLeft: "-45px", fontWeight: "bold"}} inset
                                                                            primary={menuName}/></b> 


                </ListItem>
            );
        }
    };

    handleChangeMenu = (menuName, url) => {

        if (menuName === this.state.selectedMenu || this.state.selectedUrl === url) {
           // window.location.reload();
        }
        this.setState(state => ({
            selectedMenu: menuName,
            selectedUrl: url

        }));
    };
    /* showSubMenu = (menuList, props, includeMenu, handleClick, openMenu, menuName) => {
         const {classes} = props;
         if (menuList.includes(includeMenu)) {
             return (
                 <ListItem className={classes.menuLink} button onClick={handleClick}>

                     {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}} inset
                                                                             primary={menuName}/>


                 </ListItem>
             );
         }
     };*/
    showCollapse = (menuList, props, includeMenu, openMenu, url, menuName) => {
        const {classes} = props;
        if (menuList!==undefined && menuList.includes(includeMenu)) {

            if (includeMenu === "Inbox" && this.state.setInboxData && LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('BM') !== -1) {
                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to={url} button
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}}
                                                                                        inset
                                                                                        primary={"Other Approvals"}/>
                            </ListItem>


                        </List>
                    </Collapse>
                );
            } else if (includeMenu === "Inbox" && this.state.setInboxData) {
                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to={url} button
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px",  fontWeight: "bold"}}
                                                                                        inset
                                                                                        primary={menuName }/>
                            </ListItem>


                        </List>
                    </Collapse>
                );
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT") {
                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to={url} button
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}}
                                                                                        inset
                                                                                        primary={menuName}/>
                            </ListItem>
                        </List>
                    </Collapse>
                );
            } else {

                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to={url} button
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}}
                                                                                        inset
                                                                                        primary={menuName}/>
                            </ListItem>
                        </List>
                    </Collapse>
                );
            }

        }
    };
    showMenuAction = (menuList, props, includeMenu, openMenu, url, menuName) => {
        const {classes} = props;
        if (menuList!==undefined && menuList.includes(includeMenu)) {
            return (
                /* <ListItem className={classes.menuLink} button  to={url}>

                     {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}} inset
                                                                             primary={menuName}/>


                 </ListItem>*/
                <ListItem component={Link} to={url} button
                          onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                          className={classNames(classes.menuLinkNested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                    {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText  style={{marginLeft: "-45px" , fontWeight: "bold"}}
                                                                            inset
                                                                            primary={menuName}/>
                </ListItem>

            );
        }


    };
    showCollapseClaim = (menuList, props, includeMenu, openMenu, url, menuName) => {

        const {classes} = props;
        if (menuList!==undefined && menuList.includes(includeMenu)) {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles").indexOf('SD_ADMIN') !== -1) {

                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to="/AdminReassignCase"
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}}
                                                                                        inset
                                                                                        primary={menuName}/>
                            </ListItem>
                        </List>
                    </Collapse>
                );
            } else {
                return (

                    <Collapse in={openMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem component={Link} to="/claimcase" button
                                      onClick={(event) => this.handleChangeMenu(menuName, url)} name={menuName}
                                      className={classNames(classes.nested, menuName === this.state.selectedMenu && classes.selectedClass)}>

                                {openMenu ? <ExpandLess/> : <ExpandMore/>}<ListItemText style={{marginLeft: "-45px"}}
                                                                                        inset
                                                                                        primary={menuName}/>
                            </ListItem>
                        </List>
                    </Collapse>
                );
            }
        }

    };


    renderMenuList = (menuList) => {
        return (
            <React.Fragment>
             {/*   {
                    this.showMenuAction(menuList, this.props, "Dashboard", true, "/dashboard", "Dashboard")
                }
                {
                    this.showMenuAction(menuList, this.props, "Customer greetings", this.state.openDataChange, "/Greeting", "Customer greetings")
                }*/}
                {
                    this.showMenu(menuList, this.props, "Admin", this.handleClickAdmin, this.state.openAdmin, "Admin")}
                {
                    this.showCollapse(menuList, this.props, "Role", this.state.openAdmin, "/RoleAdd", "Role Creation")
                }
                  {
                    this.showCollapse(menuList, this.props, "Branch Configuration", this.state.openAdmin, "/branch-configuration", "Branch Configuration")
                }
                  {
                    this.showCollapse(menuList, this.props, "Document Type Configuration", this.state.openAdmin, "/document-type-configuration", "Document Type Configuration")
                }
                {
                    this.showCollapse(menuList, this.props, "Branch Special Permission", this.state.openAdmin, "/RoleSwitchPermissionlist", "Branch Special Permission")
                }
                {
                    this.showCollapse(menuList, this.props, "Workplace Unit List", this.state.openAdmin, "/WorkplaceUnitList", "Workplace Unit List")
                }
                {
                    this.showCollapse(menuList, this.props, "User Registration", this.state.openAdmin, "/userregistration", "User Creation")
                }
                {
                    this.showCollapse(menuList, this.props, "User Modification", this.state.openAdmin, "/UserEdit", "User Modification")
                }
                {
                    this.showCollapse(menuList, this.props, "Activate User", this.state.openAdmin, "/ActivateUserAdmin", "Enable User")
                }
                {
                    this.showCollapse(menuList, this.props, "Disable User", this.state.openAdmin, "/DeactiveUserAdmin", "Disable User")
                }
                {
                    this.showCollapse(menuList, this.props, "User Bulk Upload", this.state.openAdmin, "/UserBulkUpload", "Bulk Users File Upload")
                }
                {
                    this.showCollapse(menuList, this.props, "Reset Password", this.state.openAdmin, "/ResetPasswordAdmin", "Reset Password")
                }
                {
                    this.showCollapse(menuList, this.props, "Role Permission", this.state.openAdmin, "/RoleListDashboard", "Role Permission")
                }
                {
                    this.showCollapse(menuList, this.props, "All User List", this.state.openAdmin, "/allUserList", "All User List")
                }
                {
                    this.showCollapse(menuList, this.props, "Business Hierarchy", this.state.openAdmin, "/businessHierarcy", "Business Hierarchy")
                }
                {
                    this.showCollapse(menuList, this.props, "Cluster Configuration", this.state.openAdmin, "/tagCluster", "Cluster Configuration")
                }
                {/*{*/}
                {/*    this.showCollapse(menuList, this.props, "Change Password", this.state.openAdmin, "/PasswordChange", "Change Password")*/}
                {/*}*/}
                {/* 
                  {
                    this.showCollapse(menuList, this.props, "User", this.state.openAdmin, "/user", "User")
                }
                {
                    this.showCollapse(menuList, this.props, "Role", this.state.openAdmin, "/RoleListDashboard", "Role")
                }
                {
                    this.showCollapse(menuList, this.props, "Menu", this.state.openAdmin, "/MenuListDashboard", "Menu")
                }*/}


                {
                    this.showMenuAction(menuList, this.props, "Password Change", this.state.openPasswordChange, "/PasswordChange", "Password Change")
                }
                {
                    this.showMenuAction(menuList, this.props, "Data Rectification", this.state.openDataChange, "/CbAndAccountUpdateComponent", "Data Rectification")
                }
               {/* {
                    this.showMenu(menuList, this.props, "Auxiliary Task", this.handleClickAuxiliaryTask, this.state.openAuxiliaryTakInbox, "Auxiliary Task")
                }
                {
                    this.showCollapse(menuList, this.props, "Auxiliary Task Creation", this.state.openAuxiliaryTakInbox, "/RequestInitialization", "Auxiliary Task Initialization")
                }
                {
                    this.showCollapse(menuList, this.props, "Auxiliary Task Inbox", this.state.openAuxiliaryTakInbox, "/AuxiliaryTaskInbox", "Auxiliary Task Inbox")
                }
                {
                    this.showCollapse(menuList, this.props, "Auxiliary Task Configuration", this.state.openAuxiliaryTakInbox, "/AuxiliaryTaskMapping", "Auxiliary Task Configuration")
                }*/}

                {
                    this.showMenu(menuList, this.props, "Master", this.handleClickMaster, this.state.openMaster, "Master")
                }
                {
                    this.showCollapse(menuList, this.props, "City", this.state.openMaster, "/city", "City")
                }
                {
                    this.showCollapse(menuList, this.props, "Branch", this.state.openMaster, "/branch", "Branch")
                }
                {
                    this.showCollapse(menuList, this.props, "Branch workflow", this.state.openMaster, "/SdAndBranchFlowConfigureAllData", "Branch workflow")
                }
                {
                    this.showCollapse(menuList, this.props, "Product", this.state.openMaster, "/product", "Product")
                }
                {
                    this.showCollapse(menuList, this.props, "State", this.state.openMaster, "/state", "State")
                }
                {
                    this.showCollapse(menuList, this.props, "Country", this.state.openMaster, "/country", "Country")
                }
                {
                    this.showCollapse(menuList, this.props, "Salutation", this.state.openMaster, "/salutation", "Salutation")
                }

                {
                    this.showMenu(menuList, this.props, "Insurance", this.handleClick360, this.state.openCustomer, "Insurance")
                }
                {
                    this.showCollapse(menuList, this.props, " Inbox", this.state.openCustomer, "/inbox", " Inbox")
                }
                {
                    this.showCollapse(menuList, this.props, "Create Master Data (Stamp)", this.state.openCustomer, "/InsuranceMasterDataList", "Create Master Data (Stamp)")
                }
                {
                    this.showCollapse(menuList, this.props, "Create Master Data (Value Calculation)", this.state.openCustomer, "/InsuranceValuecalculationMasterDataList", "Create Master Data (Value Calculation)")
                }
                {
                    this.showCollapse(menuList, this.props, "Branch New Requisition (Stamp)", this.state.openCustomer, "/RequestInitializations", "Branch New Requisition (Stamp)")
                }
                {
                    this.showCollapse(menuList, this.props, "New Request (Stamp Issuance)", this.state.openCustomer, "/StampSaleRequest", "New Request (Stamp Issuance)")
                }
                {/* {
                    this.showCollapse(menuList, this.props, "Dispatch Management (Incoming)", this.state.openCustomer, "/IncomingDispatchIntiator", "Dispatch Management (Incoming)")
                }
                {
                    this.showCollapse(menuList, this.props, "Dispatch Management (Outgoing)", this.state.openCustomer, "/OutgoingDispatchIntiator", "Dispatch Management (Outgoing)")
                } */}
                {
                    this.showMenuAction(menuList, this.props, "Dispatch Management (Incoming)", true, "/IncomingDispatchIntiator", "Dispatch Management (Incoming)")
                }
                   {
                    this.showMenuAction(menuList, this.props, "Dispatch Management (Outgoing)", true, "/OutgoingDispatchIntiator", "Dispatch Management (Outgoing)")
                }
                
                {
                this.showCollapse(menuList, this.props, " Global Search", this.state.openCustomer, "/GlobalSearch", "Global Search")
            }
                {/* {
                    this.showCollapse(menuList, this.props, "Group Search", this.state.openCustomer, "/Grouping", "Group Search")
                } */}
               

                {
                    this.showMenu(menuList, this.props, "Tracker Menu", this.handleClickChequeBook, this.state.openChequeBook, "Tracker (Stamp)")
                }
              
                {
                    this.showCollapse(menuList, this.props, "Stamp Tracker", this.state.openChequeBook, "/CsChecqueBookManage", "Stamp Tracker")
                }
                {/* {
                    this.showCollapse(menuList, this.props, "Cheque Book Tracker", this.state.openChequeBook, "/OutgoingTrackerManage", "Document Tracker Management (Outgoing)")
                }
                {
                    this.showCollapse(menuList, this.props, "Cheque Book Tracker", this.state.openChequeBook, "/IncomingTrackerManage", "Document Tracker Management (Incoming)")
                } */}

                { 
                    this.showMenu(menuList, this.props, "Tracker (Dispatch Management)", this.handleStampTracker, this.state.openStamp, "Tracker (Dispatch Management)")
                }
                {
                    this.showCollapse(menuList, this.props, "Dispatch Tracker Management (Incoming)", this.state.openStamp, "/IncomingTrackerManage", "Dispatch Tracker Management (Incoming)")
                }
                {
                    this.showCollapse(menuList, this.props, "Dispatch Tracker Management (Outgoing)", this.state.openStamp, "/OutgoingTrackerManage", "Dispatch Tracker Management (Outgoing)")
                }
                 
                {
                    this.showMenu(menuList, this.props, "Reports", this.handleClickReports, this.state.openReports, "Reports")
                }
                {
                    this.showCollapse(menuList, this.props, "Document Tracker Report", this.state.openReports, "/GreenDeltaDespatchReport/branch", "Document Tracker Report")
                }
                {/* {
                    this.showCollapse(menuList, this.props, "Cheque Book Report For Branch", this.state.openReports, "/GreenDeltaStockAndSoldReport/branch", "Available Stock/ Sold Report")
                } */}
                {
                    this.showCollapse(menuList, this.props, "Outgoing Details Report", this.state.openReports, "/GreenDeltaRequisitionReport/outgoing", "Outgoing Details Report")
                }
                  {
                    this.showCollapse(menuList, this.props, "Incoming Details Report", this.state.openReports, "/GreenDeltaRequisitionReport/incoming", "Incoming Details Report")
                }
                
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </React.Fragment>
        )

    };

    render() {
        const {classes} = this.props;
        const menuList = (LocalstorageEncrypt.encryptStorageFunction.getItem("menus"));

        return (
<>
    <br/>
    <br/>
            <CardHeader className={classes.menuStyleHeader}>
                <h4 style={{color: "white"}} >Menu Name</h4>
            </CardHeader>
            <List
                component="nav"


            >

                 {this.renderMenuList(menuList)}
            </List>
</>
        );


    }
}

DashboardMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardMenu);
