import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import {Redirect} from "react-router-dom";
import axios from 'axios';
import '../Static/css/login.css';
import {backEndServerURL} from '../Common/Constant';
import bablLogo from "../Static/babl_logo.png";
import GreenDelta from "../Static/green-delta.png";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import Notification from "./NotificationMessage/Notification";
import Link from "react-router-dom/Link";
import LogoutComponent from "./LogoutComponent";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {

        marginTop: theme.spacing.unit * 1,

    },
    Buttoncolorchange: {
        backgroundColor: '#142398',
        '&:hover': {
            backgroundColor: "#142398"
        }
    },
    Errorr: {
        color: '#142398',
        size: "46px"

    }

});

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            redirect: false,
            renderFirstLoginPage: false,
            errorMessage: "",
            id: 100,
            tableData: [[" ", " "]],
            roleId: '',
            title: "Successfull!",
            notificationMessage: "Successfully Logined!",
            alert: false,
            errorType: "success",
            firstLoginResponse: false,
            redirectLogoutComponent: false
        }

    };

    handleusername = (event) => {
        this.setState({
            username: event.target.value
        })
    };

    handlepassword = event => {
        this.setState({
            password: event.target.value
        })
    };
    createTableData = (roles, menus) => {
        return ([roles, menus
        ]);


    };
    userPermission = () => {
        let url = backEndServerURL + "/get/roleAndMenu/" + this.state.username;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let currentDate = new Date();
                LocalstorageEncrypt.encryptStorageFunction.setItem("previousSessionTime", currentDate.getMinutes());
                // cookie.save("spring_session", response.headers.spring_session);
                LocalstorageEncrypt.encryptStorageFunction.setItem("username", this.state.username);
                LocalstorageEncrypt.encryptStorageFunction.setItem("roles", response.data.roles);
                LocalstorageEncrypt.encryptStorageFunction.setItem("contactNo", response.data.contactNo);
                LocalstorageEncrypt.encryptStorageFunction.setItem("solId", response.data.solId);
                LocalstorageEncrypt.encryptStorageFunction.setItem("workplace", response.data.workplace);
                LocalstorageEncrypt.encryptStorageFunction.setItem("workflowGroup", response.data.workflowGroup);
                LocalstorageEncrypt.encryptStorageFunction.setItem("menus", JSON.stringify(response.data.menus));
                LocalstorageEncrypt.encryptStorageFunction.setItem("workplaceUnits", response.data.workplaceUnits);
                LocalstorageEncrypt.encryptStorageFunction.setItem("fullName", response.data.fullName);
                LocalstorageEncrypt.encryptStorageFunction.setItem("roleSwithPermission", response.data.roleSwithPermission);
                LocalstorageEncrypt.encryptStorageFunction.setItem("rolePermissionName", response.data.rolePermissionName);

                if (this.state.firstLoginResponse === true) {
                    this.setState({
                        loading: false,
                        renderFirstLoginPage: true,

                    })
                } else {
                  //  LocalstorageEncrypt.encryptStorageFunction.setItem("reload", "true");
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getUrlAndMenuList", "true");
                    this.setState({
                        loading: false,
                        redirect: true,

                    })
                }


            })
            .catch((error) => {
                this.setState({
                    loading: false,

                });
                console.log(error);
            });


    };
    errorObjectCheck = (response) => {
        if (response !== undefined) {
            if (response.data !== undefined) {
                if (response.data.message !== undefined) {
                    return response.data.message
                } else {
                    return "Username or password invalid"
                }
            } else {
                return "Username or password invalid"
            }
        } else {
            return "Username or password invalid"
        }
    };



    handlesubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let data = {};
        data.username = this.state.username;
        data.password = this.state.password;
        let url = backEndServerURL + "/logMeIn";
        axios.post(url, data, {})
            .then((response) => {
                LocalstorageEncrypt.encryptStorageFunction.clear();
                localStorage.clear();
                sessionStorage.removeItem("accessToken")
                if (response.data.response === "firstLogin") {
                    this.setState({
                        firstLoginResponse: true
                    });
                    localStorage.setItem("accessToken", response.data.accessToken);
                    sessionStorage.setItem("accessToken", response.data.accessToken);
                    this.userPermission()

                } else if (response.data.response === true) {
                    localStorage.setItem("accessToken", response.data.accessToken);

                    sessionStorage.setItem("accessToken", response.data.accessToken);
                    this.userPermission()
                } else if (response.data.message !== undefined && response.data.message === "User Already Logged In") {
                    this.setState({
                        redirectLogoutComponent: true
                    });
                } else {
                    this.setState({
                        errorMessage: this.errorObjectCheck(response),
                        loading: false,
                        title: "Error!",
                        notificationMessage: this.errorObjectCheck(response),
                        alert: true,
                        errorType: "error",
                    })
                }

            })
            .catch(error => {
                console.log(error);

                this.setState({
                    loading: false,
                    errorMessage: "Unable to Connect Back-End Server",
                    title: "Error!",
                    notificationMessage: "Unable to Connect Back-End Server!",
                    alert: true,
                    errorType: "error",

                });
            })

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.errorType} stopNotification={this.stopNotification}
                              title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRedirect = () => {

        const menuList = LocalstorageEncrypt.encryptStorageFunction.getItem("menus");

        if (this.state.redirect) {
            /*            if (menuList.includes("Dashboard")) {
                            return (<Redirect to={{
                                /!*pathname: '/CustomerDedupSearch',*!/
                                pathname: '/dashboard',
                                state: {roles: this.state.tableData}
                            }}/>);
                        } else {*/
            return (<Redirect to={{
                /*pathname: '/CustomerDedupSearch',*/
                pathname: '/firstLoginPage',
                state: {roles: this.state.tableData}
            }}/>);
            // }


            // if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CS") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/csdashboard/CS',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // }
            // else if (LocalstorageEncrypt.encryptStorageFunction.getItem("username") === "maker16" || LocalstorageEncrypt.encryptStorageFunction.getItem("username") === "checker16") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/FDRDashboard',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "BOM") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/bmdashboard',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // }
            // else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "ITADMIN") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/userregistration',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // }
            // else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "CARD_DIVISION") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/csdashboard/CARD',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // }
            // else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "SD" || LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "ADC") {
            //     return (<Redirect to={{
            //         /*pathname: '/CustomerDedupSearch',*/
            //         pathname: '/inbox',
            //         state: {roles: this.state.tableData}
            //
            //     }}/>);
            // }


        }

    };
    renderFirstLoginPage = () => {
        if (this.state.renderFirstLoginPage === true) {
            return (<Redirect to={{
                pathname: '/PasswordChange',
            }}/>);
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.redirectLogoutComponent === true) {
            return <LogoutComponent classes={this.props} username={this.state.username} password={this.state.password}/>
        } else {
            return (
                <div>

                    {
                        this.renderRedirect()

                    }
                    {
                        this.renderFirstLoginPage()

                    }
                    {
                        this.renderNotification()

                    }
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <center>


                        <main className={classes.main}>
                            <CssBaseline/>
                            <Paper className={classes.paper}>

                            <img   width='280px' height='160px' src={GreenDelta}/>

                                {/* <img width='150px' height='120px'  style={{backgroundColor: "#274cc4"}} src={logo}/> */}

                                {/* <Typography component="h1" variant="h5">
                                    Sign in
                                </Typography> */}


                                <p className={classes.Errorr}>{this.state.errorMessage}</p>

                                <form onSubmit={this.handlesubmit} className={classes.form}>
                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="email">User ID</InputLabel>
                                        <Input onChange={this.handleusername} id="email" name="email"
                                               autoComplete="email"
                                               autoFocus/>
                                    </FormControl>

                                    <FormControl margin="normal" required fullWidth>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input onChange={this.handlepassword} name="password" type="password"
                                               id="password"
                                               autoComplete="current-password"/>
                                    </FormControl>
                                    <br/>
                                    <br/>


                                    <center>
                                        <Button
                                            type="primary" htmlType="submit" className={classes.Buttoncolorchange}


                                            variant="contained"
                                            color="secondary"

                                        >

                                            Sign in
                                        </Button> <br/>
                                        <br/>
                                        &nbsp; &nbsp; &nbsp; &nbsp;<Link to='/ForgetPassword'>Forgot password?</Link>
                                        <br/>
                                        <br/>
                                        {/* <p><b>Powered By Business Accelerate BD LTD</b></p> */}
                                        <div style={{marginTop:"10px"}}><b>Powered by </b> <a href="https://businessaccelerate.com.bd/" target="_blank"><img   width='35px' height='15px' src={bablLogo}/></a></div> 
                                    </center>

                                </form>
                            </Paper>
                        </main>
                    </center>
                </div>
            );
        }
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);