import React from "react";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import axios from "axios";
import Functions from "../Common/Functions";
import Table from "./Table/Table";
import CardHeader from "./Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import MakerCumInput from "./workflow/CASA/MakerCumInput";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import MakerView from "./360View/MakerView";
import SecondaryJointCbTagging from "./workflow/CASA/SecondaryJointCbTagging";
import ErrorModal from "./workflow/CommonComponent/ErrorModal";
import Grid from "@material-ui/core/Grid";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class MandateAndBeneficiaryResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            relatedPartyType: "",
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            CBGenerateModal: false,
            loading: true,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            mandatededupData: [],
            beneficiaryDedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},
            cbNumber: "",
            beneficiaryDedupDataFlag: false,
            mandateDedupDataFlag: false,
            errorModalBoolean: false,
            errorTittle: ""

        };


    }

    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false
        })
    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        let url = "/Customer/" + id;
        window.open(frontEndServerURL + url)
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };

    radioButtonChange = (event, customerId) => {

        let variable = {};
        console.log(event.target.name);
        console.log(event.target.value);
        variable[event.target.name] = event.target.value;
        this.setState({
            customerId: customerId,
            getRadioButtonData: variable,
            getCustomerNumber: event.target.name
        })


    };
    closeModalForError = () => {
        this.setState({
            errorModalBoolean: false,
            errorTittle: "",
            renderCumModalopen: true,
        })
    }
    closeModalForErrorIcon = () => {
        this.setState({
            errorModalBoolean: false,
            errorTittle: "",
         })
    }
    tagingModal = (event, index, customerid, relatedPartyType) => {
        if (customerid !== undefined && customerid !== null) {
            let getCbType = backEndServerURL + "/getCbTypes/" + customerid.trim();
            axios.get(getCbType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((cbTypeResponse) => {
                    if (cbTypeResponse.data !== "") {
                        if (cbTypeResponse.data !== "RETAIL") {
                            this.setState({
                                //cbNumber: customerid,
                                tagingModalCbnumber: index,
                                cbNumber: customerid,
                                relatedPartyType: relatedPartyType,
                                errorModalBoolean: true,
                                errorTittle: "Not a Retail Account!",
                            });
                        }
                        else{
                            this.setState({
                                renderCumModalopen: true,
                                //cbNumber: customerid,
                                tagingModalCbnumber: index,
                                cbNumber: customerid,
                                relatedPartyType: relatedPartyType

                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)

                    this.setState({
                        renderCumModalopen: true,
                        //cbNumber: customerid,
                        tagingModalCbnumber: index,
                        cbNumber: customerid,
                        relatedPartyType: relatedPartyType
                    })
                })

        }

    };


    taging = (index, data) => {
        let value = index + 1;
        if (data !== undefined) {
            this.props.getTaggingData("tagging" + value, data, value);
        } else {
            this.props.getTaggingData("tagging" + value, this.state.getRadioButtonData["tagging" + value], value);
        }


    };
    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, index) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Joint Account' || this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.radioButton === "NO") {
                return (

                    ["", customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            } else {
                return (

                    [<input type="radio" onChange={(event) => this.radioButtonChange(event, customerId)}
                            name={randomNumber}
                            value={customerId}/>, customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        } else if (((this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening"))) {
            return ([customerId, accountSource, customerName,


                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        } else {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else if (this.props.radioButton === "NO") {
                return (["", customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else {
                return ([<input type="radio" onChange={(event) => this.radioButtonChange(event, customerId)}
                                name={randomNumber}
                                value={customerId}/>, customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            }
        }

    };
    createTableDataWithoutProprietorship = (id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, index) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        }


    };
    createTablemandateDedupData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, index) => {
        if (accountSource === "TRANZWARE") {
            return ([customerId, accountSource, customerName,
                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>])
        } else if (this.props.radioButton === "NO") {
            return (

                [customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
        } else {
            return (

                [customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>, <button
                    className="btn btn-outline-primary"
                    style={{

                        /* verticalAlign: 'right',
                         position: "absolute",
                         right: 80,*/

                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagingModal(event, index, customerId, "Mandate")}>
                    Tag
                </button>


                ])
        }


    };
    createTablebeneficiaryDedupData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType, index) => {
        if (accountSource === "TRANZWARE") {
            return ([customerId, accountSource, customerName,
                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>])
        } else if (this.props.radioButton === "NO") {
            return (

                [customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
        } else {
            return (

                [customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>, <button
                    className="btn btn-outline-primary"
                    style={{

                        /* verticalAlign: 'right',
                         position: "absolute",
                         right: 80,*/

                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagingModal(event, index, customerId, "Beneficial")}>
                    Tag
                </button>


                ])
        }


    };

    componentDidMount() {

        /*  this.setState({
              loading: true
          })*/


        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log("llll");
                    console.log(response.data);
                    let objectForJoinAccount = [];
                    var sl;
                    this.setState({
                        getDedupData: response.data,
                        objectForJoinAccount: objectForJoinAccount,
                        getgenerateForm: true

                    });
                    console.log(response.data);

                    let tableArray = [];
                    let relatedTableArray = [];
                    let data = "";
                    if (this.props.subServiceType === "Joint Account") {
                        data = this.state.getDedupData.jointDedupData
                    } else {
                        data = this.state.getDedupData.individualDedupData
                    }

                    let dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + data;
                    axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log("mandateDedupData");
                            console.log(response.data);
                            let randomNumber = 0;
                            if (response.data.mandateDedupData.highMatchCustomers !== null && Array.isArray(response.data.mandateDedupData.highMatchCustomers)) {
                                randomNumber = randomNumber + 1;
                                response.data.mandateDedupData.highMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTablemandateDedupData("Mandate", dedup.id, dedup.identification_no, dedup.szcustomerid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match", "Mandate"));

                                });

                            }

                            if (response.data.mandateDedupData.mediumMatchCustomers !== null && Array.isArray(response.data.mandateDedupData.mediumMatchCustomers)) {
                                randomNumber = randomNumber + 1;
                                response.data.mandateDedupData.mediumMatchCustomers.map((dedup) => {

                                    tableArray.push(this.createTablemandateDedupData("Mandate", dedup.id, dedup.identification_no, dedup.szcustomerid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match", "Mandate"));

                                });
                            }
                            console.log(tableArray);

                            this.setState({

                                mandatededupData: tableArray,
                                //relatedData: relatedTableArray,
                                searchTableData: true,
                                mandateDedupDataFlag: true,
                                searchTableRelatedData: true,
                                //getsearchValue: object,
                                loading: false,

                            })


                        })
                        .catch((error) => {

                            this.setState({
                                mandatededupData: [],
                                relatedData: [],
                                searchTableData: true,
                                mandateDedupDataFlag: true,
                                searchTableRelatedData: true,
                                loading: false,
                            })

                        });
                    let mandateTable = [];
                    dedupDataViewUrl = backEndServerURL + "/dedup/get/" +(this.state.appId===undefined?this.props.appId:this.state.appId) +"/"  + data;
                    axios.get(dedupDataViewUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log("lko");
                            console.log(response.data);
                            let randomNumber = 0;
                            if (response.data.beneficiaryDedupData.highMatchCustomers !== null && Array.isArray(response.data.beneficiaryDedupData.highMatchCustomers)) {
                                randomNumber = randomNumber + 1;
                                response.data.beneficiaryDedupData.highMatchCustomers.map((dedup) => {

                                    mandateTable.push(this.createTablebeneficiaryDedupData("Beneficiary", dedup.id, dedup.identification_no, dedup.szcustomerid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match", "Beneficial"));

                                });

                            }
                            if (response.data.beneficiaryDedupData.mediumMatchCustomers !== null && Array.isArray(response.data.beneficiaryDedupData.mediumMatchCustomers)) {
                                randomNumber = randomNumber + 1;
                                response.data.beneficiaryDedupData.mediumMatchCustomers.map((dedup) => {

                                    mandateTable.push(this.createTablebeneficiaryDedupData("Beneficiary", dedup.id, dedup.identification_no, dedup.szcustomerid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Medium-Match", "Beneficial"));

                                });

                            }


                            this.setState({

                                beneficiaryDedupData: mandateTable,
                                //relatedData: relatedTableArray,
                                searchTableData: true,
                                beneficiaryDedupDataFlag: true,
                                searchTableRelatedData: true,
                                //getsearchValue: object,
                                loading: false,

                            })


                        })
                        .catch((error) => {

                            this.setState({
                                beneficiaryDedupData: [],
                                relatedData: [],
                                searchTableData: true,
                                beneficiaryDedupDataFlag: true,
                                searchTableRelatedData: true,
                                loading: false,
                            })

                        });


                    console.log(response.data);
                    let varValue = response.data;
                    this.setState({
                        jointAccountCustomerNumber: response.data.jointAccountCustomerNumber,
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        showValue: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };


    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };


    closeModal = (account) => {

        this.setState({
            renderCumModalopen: false,
            generateAccountNo: account,

        })
    };
    closeIcon = () => {
        this.setState({
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            closeModalNew: false,

        })
    };
    closeModalNew = (account) => {

        this.setState({
            renderCumModalopenNew: false,
            // CBGenerateModal: true,
            generateAccountNo: account,

        })
    };

    closeModalCBGenerate = (account) => {

        this.setState({
            CBGenerateModal: false,
        })
    };

    renderNewAccountOpeingForm = (event, index, relatedPartyType) => {
        event.preventDefault();
        this.setState({
            renderCumModalopenNew: true,
            generateAccountNo: "NEW",
            tagingModalCbnumber: index,
            relatedPartyType: relatedPartyType,

        })


    };

    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };

    renderTagButton = (data, relatedPartyType, cbNumber, tableData) => {
        if (this.props.radioButton !== "NO") {
            return (
                <center>
                    {/*  {Array.isArray(tableData) && tableData.length > 0 &&    <button
                                className="btn btn-outline-primary"
                                style={{

                                    verticalAlign: 'right',
                                    position: "absolute",
                                    right: 80,

                                }}
                                type='button' value='add more'
                                onClick={(event) => this.tagingModal(event, data, relatedPartyType, cbNumber,index)}>
                                Tag
                            </button>}
                            &nbsp;&nbsp;&nbsp;&nbsp;*/}
                    <button
                        className="btn btn-outline-primary"
                        style={{

                            verticalAlign: 'right',
                            position: "absolute",
                            right: 15,
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.renderNewAccountOpeingForm(event, data, relatedPartyType)}>
                        New
                    </button>
                    <br/>


                </center>
            )
        }
    };
    renderMandateTableOnly = (mandatededupData) => {
        if (Array.isArray(mandatededupData) && mandatededupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHovor="yes"
                    tableHeaderColor="primary"
                    tableHead={["CB No", "Source", "Customer Name", "View"]}

                    tableData={mandatededupData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };
    searchTableMandateData = (style) => {
        if (this.state.searchTableData && this.state.mandateDedupDataFlag && this.state.getDedupData.mandate === "YES") {
            /*  return (
                  this.state.mandatededupData.map((tableData, index)=> {*/
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} > Mandate Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    {this.renderTagButton(this.state.getCustomerNumber, "Mandate", this.state.customerId, this.state.mandatededupData)}
                    {/*    <center>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{

                                        verticalAlign: 'right',
                                        position: "absolute",
                                        right: 80,

                                    }}
                                    onClick={(event) => this.tagingModal(this.state.getCustomerNumber)}>
                                    Tag
                                </button>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    style={{

                                        verticalAlign: 'right',
                                        position: "absolute",
                                        right: 15,
                                    }}
                                    onClick={(event) => this.renderNewAccountOpeingForm(this.state.getCustomerNumber)}>
                                    New
                                </button>
                            </Grid>

                        </Grid>
                    </center>*/}

                    <br/>

                    {this.renderMandateTableOnly(this.state.mandatededupData)}

                    <br/>


                </div>

                /*
                                    )
                                })*/
            )

        } else {

        }

    };
    renderbeneficiaryTableOnly = (beneficiaryDedupData) => {
        if (Array.isArray(beneficiaryDedupData) && beneficiaryDedupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table

                    tableHovor="yes"
                    tableHeaderColor="primary"
                    tableHead={["CB No", "Source", "Customer Name", "View"]}
                    tableData={beneficiaryDedupData}
                    tableAllign={['left', 'left', 'left', 'left']}
                />
            )
        }
    };
    searchTableBeneficiaryData = () => {
        if (this.state.searchTableData && this.state.beneficiaryDedupDataFlag && this.state.getDedupData.beneficiary === "YES") {
            /*    return (
                    this.state.beneficiaryDedupData.map((tableData, index)=> {*/
            return (
                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Beneficial Owner Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    {this.renderTagButton(this.state.getCustomerNumber, "Beneficial", this.state.customerId, this.state.beneficiaryDedupData)}


                    <br/>
                    <div>

                        {this.renderbeneficiaryTableOnly(this.state.beneficiaryDedupData)}
                    </div>

                </div>


                /*   )
               })*/
            )

        }
    };

    renderMandate = (mandateDedupData) => {
        if (Array.isArray(mandateDedupData) && mandateDedupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={mandateDedupData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };
    searchTableMandateSDNData = (style) => {
        if (this.state.searchTableData && this.state.mandateDedupDataFlag) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Mandate SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    {this.renderMandate(this.state.mandateDedupData)}

                    <br/>


                </div>

            )


        }


    };
    renderBeneficiary = (beneficiaryDedupData) => {
        if (Array.isArray(beneficiaryDedupData) && beneficiaryDedupData.length < 1) {
            return (
                <center>
                    <h6 style={{
                        color: '#ff1f28'
                    }}>No Match Found</h6>
                </center>
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={beneficiaryDedupData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        }
    };
    searchTableBeneficiarySDNData = (style) => {
        if (this.state.searchTableData && this.state.beneficiaryDedupDataFlag) {
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Beneficial Owner SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    {this.renderBeneficiary(this.state.beneficiaryDedupData)}

                    <br/>


                </div>

            )


        }


    };
    renderJointCB = () => {
        if (this.props.FdrDps === true) {
            return (
                <SecondaryJointCbTagging editMode={true} FdrDps={this.props.FdrDps} freeFlag1={this.props.freeFlag1}
                                         customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                         solId={this.props.solId} freeFlag4={this.props.freeFlag4}
                                         introducerId={this.props.introducerId}
                                         introducerName={this.props.introducerName} appId={this.props.appId}
                                         closeIcon={this.closeIcon} taggingForm={this.props.taggingForm}
                                         customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                                         relatedPartyType={this.state.relatedPartyType}
                                         hideButton={this.props.hideButton}
                                         generateAccountNo={this.state.generateAccountNo}
                                         cbNumber={this.state.cbNumber}
                                         tagingModalCbnumber={this.state.relatedPartyType} taging={this.taging}
                                         serviceType={this.props.serviceType}
                                         closeModal={this.closeModal}/>
            )
        } else {
            return (
                <MakerCumInput freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                               accountNumber={this.props.accountNumber} solId={this.props.solId}
                               freeFlag4={this.props.freeFlag4}
                               introducerId={this.props.introducerId} introducerName={this.props.introducerName}
                               appId={this.props.appId} closeIcon={this.closeIcon} taggingForm={this.props.taggingForm}
                               customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                               relatedPartyType={this.state.relatedPartyType}
                               hideButton={this.props.hideButton}
                               generateAccountNo={this.state.generateAccountNo}
                               cbNumber={this.state.cbNumber}
                               tagingModalCbnumber={this.state.relatedPartyType} taging={this.taging}
                               serviceType={this.props.serviceType}
                               closeModal={this.closeModal}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (

            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"

                    open={this.state.errorModalBoolean}>
                    <DialogContent>
                        <ErrorModal title={this.state.errorTittle}
                                    closeModal={this.closeModalForError}
                                    closeIcon={this.closeModalForErrorIcon}

                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}

                    open={this.state.renderCumModalopen}>
                    <DialogContent>
                        {this.renderJointCB()}
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    fullScreen={true}

                    open={this.state.renderCumModalopenNew}>
                    <DialogContent>
                        <MakerCumInput freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber} solId={this.props.solId}
                                       freeFlag4={this.props.freeFlag4}
                                       introducerId={this.props.introducerId} introducerName={this.props.introducerName}
                                       appId={this.props.appId} closeIcon={this.closeIcon}
                                       taggingForm={this.props.taggingForm}
                                       customerNumber={this.state.getCustomerNumber} appId={this.props.appId}
                                       hideButton={this.props.hideButton}
                                       relatedPartyType={this.state.relatedPartyType}
                                       generateAccountNo={this.state.generateAccountNo}
                            //cbNumber={this.state.cbNumber}
                                       tagingModalCbnumber={this.state.relatedPartyType} taging={this.taging}
                                       serviceType={this.props.serviceType}
                                       closeModal={this.closeModalNew}/>
                    </DialogContent>
                </Dialog>
                {/*   <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CBGenerateModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title={"Generate CB Number Is :" + this.state.generateAccountNo}
                                    closeModal={this.closeModalCBGenerate}
                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>*/}
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}

                >
                    <DialogContent className={classes.dialogPaper}>
                        <MakerView appId={this.props.appId} closeModal={this.closeCustomerModal}
                                   IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableMandateData()}
                                {this.searchTableBeneficiaryData()}

                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


            </div>

        )

    }


}

export default withStyles(styles)(MandateAndBeneficiaryResult);
