import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import GridItem from "../../Grid/GridItem.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import Table from "../../Table/Table";
import ShowDocument from "./ShowDocuments";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import TextField from '@material-ui/core/TextField';
import MissingFlies from "./MissingFlies";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import emi from "../../../assets/emi.pdf";
import Functions from '../../../Common/Functions';
import WaiverList from "./MerchantWaiverList";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";

const jsonForm = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "DBA Name",
        "type": "text",
        "varName": "dba",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Key Contact Person Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 2,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "POS Model",
        "type": "text",
        "varName": "posModel",
        "validation": "length",
        "maxLength": 3,
        "minLength": 3,
        "required": true,
        "grid": 2,

    },
    {
        "label": "SIM",
        "type": "text",
        "varName": "sim",
        "grid": 2
    },
    {
        "label": "POS Serial",
        "type": "text",
        "varName": "posSerial",
        "grid": 2,

    },
    {
        "label": "IMEI",
        "type": "text",
        "varName": "imei",
        "grid": 2
    },

];


let mbuRemarks = [
    {
        "varName": "mbuRemarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];

var deferalOther =
    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "grid": 12
    };

var deferal;
var deferalCategory = {
    "varName": "deferalCategory",
    "type": "select",
    "label": "Category",
    "enum": [
        "G",
        "R"
    ],

};
var deferalCommForm = [
    {
        "varName": "deferalDstId",
        "type": "text",
        "label": "Dst Id",
        "required": true,
        "grid": 2,

    }, {
        "varName": "deferalRmCode",
        "type": "text",
        "label": "Rm Code",
        "required": true,
        "grid": 2,
    },
];
var deferalSubCategoryG = {
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "30",
        "60",
        "90",
        "180",
    ],

};
var deferalSubCategoryR = {
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "30",
        "60",
        "90"
    ],

};

var days = {
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "10",
        "15",
        "20"
    ],
    "grid": 4
};

const date = {
    "varName": "expireDate",
    "type": "text",
    "label": "Expire Date",
    "grid": 4,
};


const proprietorDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'NID or Readable Passport'},
    {label: 'Passport Size Photograph'},
    {label: 'E-TIN Certificate'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const partnershipDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'NID or Readable Passport'},
    {label: 'Certified Copy of Partnership Deed'},
    {label: 'Partnership Resolution Letter with Organization Letter'},
    {label: 'Passport Size Photograph'},
    {label: 'E-TIN Certificate'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const llcDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'Memorandum, Certificate Incorporation, Articles of Association & Form XII'},
    {label: 'Certificate of commencement of Business'},
    {label: 'Board Resolution Letter with Power of Attorney or Delegation Related Documents for authorized person to operate banking/financial activities'},
    {label: 'NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares'},
    {label: 'Company E-TIN Certificate'},
    {label: 'Passport Size Photograph'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const othersDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Certificate of registration'},
    {label: 'List of executive body'},
    {label: 'Certificate of NGO Bureau or Other Regulatory'},
    {label: 'Certificate of Micro Credit Regulatory Authority or Other regulatory'},
    {label: 'Memorandum & Article of Association'},
    {label: 'Board Resolution or Extract regarding the authorized personal with attested sign'},
    {label: 'NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares'},
    {label: 'EFTN Form'},
    {label: 'Visiting Card'},


];

var waiverOther =
    {
        "varName": "waiverOther",
        "type": "text",
        "multiline": true,
        "label": "Remarks",

    };

let typeofDeferralDocs = {
    "varName": "typeofDeferralDocs",
    "type": "select",
    "label": "Type of Deferral Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};
let typeofWaiverDocs = {
    "varName": "typeofWaiverDocs",
    "type": "select",
    "label": "Type of Waiver Docs",
    "enum": [
        "Regulatory",
        "Non Regulatory",
    ],
};

let documents;
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class MbuDefferalPos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {
                deferal: "NO",
                mbu_deferal: "NO"
            },
            showValue: true,
            deferalNeeded: false,
            values: [],
            SelectedData: false,
            AddDeferal: false,
            selectedDate: {},
            getData: false,
            type: [],
            dueDate: '',
            title: "",
            notificationMessage: "",
            alert: false,
            getDeferalList: [],
            defferalData: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            images: [],
            remarks: '',
            getRemarkList: [],
            documentModal: false,
            documentList: [],
            getDocument: false,
            deferralList: [],
            getSplitFile: [],
            attached: false,
            missing: false,
            loading: true,
            waiverValues: [],
            SDRegulatory: [],
            SDNonRegulatory: [],
            disabled: false,
        }
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.mbuRemarks = null;

                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    getData: true,
                    loading: false
                });

                if (this.state.inputData.merchantType === "Sole Proprietorship") {
                    deferal =
                        {
                            "varName": "deferalType",
                            "type": "select",
                            "label": "Deferral Type",
                            "enum": [
                                "Trade License",
                                "NID or Readable Passport",
                                "Passport Size Photograph",
                                "E-TIN Certificate",
                                "EFTN Form",
                                "18-A Form",
                                "Visiting Card",
                                "other"
                            ],
                            "grid": 12
                        };
                } else if (this.state.inputData.merchantType === "Partnership") {
                    deferal =
                        {
                            "varName": "deferalType",
                            "type": "select",
                            "label": "Deferral Type",
                            "enum": [
                                "Trade License",
                                "NID or Readable Passport",
                                "Certified Copy of Partnership Deed",
                                "Partnership Resolution Letter with Organization Letter",
                                "Passport Size Photograph",
                                "E-TIN Certificate",
                                "EFTN Form",
                                "18-A Form",
                                "Visiting Card",
                                "other"
                            ],
                            "grid": 12
                        };
                } else if (this.state.inputData.merchantType === "Limited Liability") {
                    deferal =
                        {
                            "varName": "deferalType",
                            "type": "select",
                            "label": "Deferral Type",
                            "enum": [
                                "Trade License",
                                "Memorandum, Certificate Incorporation, Articles of Association & Form XII",
                                "Certificate of commencement of Business",
                                "Board Resolution Letter with Power of Attorney or Delegation Related Documents for authorized person to operate banking/financial activities",
                                "NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares",
                                "Company E-TIN Certificate",
                                "Passport Size Photograph",
                                "EFTN Form",
                                "18-A Form",
                                "Visiting Card",
                                "other"
                            ],
                            "grid": 12
                        };
                } else if (this.state.inputData.merchantType === "Others") {
                    deferal =
                        {
                            "varName": "deferalType",
                            "type": "select",
                            "label": "Deferral Type",
                            "enum": [
                                "Certificate of registration",
                                "List of executive body",
                                "Certificate of NGO Bureau or Other Regulatory",
                                "Certificate of Micro Credit Regulatory Authority or Other regulatory",
                                "Memorandum & Article of Association",
                                "Board Resolution or Extract regarding the authorized personal with attested sign",
                                "NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares",
                                "EFTN Form",
                                "18-A Form",
                                "Visiting Card",
                                "other"
                            ],
                            "grid": 12
                        };
                }


                //console.log(this.state.varValue)
            })
            .catch((error) => {
                console.log(error)
            });


        let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

        axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                //console.log(response.data)
                if (response.data == '') {
                    // console.log('empty')
                } else {
                    let deferealData = [];

                    response.data.map((deferal) => {

                        var status;
                        if (deferal.status === "ACTIVE") {
                            status = "Approved"
                        } else if (deferal.status === "APPROVAL_WAITING") {
                            status = "Pending"
                        } else {
                            status = "Not Approved";
                        }

                        deferealData.push(this.createTableForDeferal(deferal.id, deferal.type, deferal.deferalCategory,deferal.deferalSubCategory, deferal.deferalSubCategory, deferal.deferalDstId, deferal.deferalRmCode, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status))
                    });

                    this.setState({
                        getDeferalList: deferealData,
                        defferalData: true
                    })
                }

            })
            .catch((error) => {
                console.log(error)
            });


        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    images: response.data,
                    documentList: response.data,
                    getDocument: true,
                    getSplitFile: response.data,
                    attached: false
                })

            })
            .catch((error) => {
                console.log(error)
            });


        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let remarkData = [];

                response.data.map((res) => {

                    remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                });

                this.setState({
                    getRemarkList: remarkData,
                    remarks: true
                })

                //console.log(this.state.getRemarkList)


            })
            .catch((error) => {
                console.log(error)
            })


    }

    open = (e) => {
        e.preventDefault();
        this.setState({
            documentModal: true
        })
    };

    documentModal = () => {
        this.setState({
            documentModal: false
        })
    };

    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, mbuRemarks, this.updateComponent)

        )
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    createTableForDeferal = (id, type, category, subCategory, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, category, subCategory, dueDate, appliedBy, applicationDate, status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px', marginBottom: "10px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '10px'}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };

    addClickWaiver() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            waiverValues: [...prevState.waiverValues, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
        this.state.inputData["csWaiver"] = "YES";
    };

    addWaiverForm() {

        return this.state.waiverValues.map((el, i) =>
            <React.Fragment>
                {/*<Grid item xs={2}>*/}
                {/*    {*/}
                {/*        this.dynamicTypeOfWaiver(el)*/}
                {/*    }*/}
                {/*</Grid>*/}
                <Grid item xs={2}>
                    {
                        this.dynamicWaiver(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {this.dynamicWaiverOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClickWaiver.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
                <Grid item xs={4}>

                </Grid>
            </React.Fragment>
        )


    };

    dynamicTypeOfWaiver = (i) => {

        let field = JSON.parse(JSON.stringify(typeofWaiverDocs));
        field.varName = "typeofWaiverDocs" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicWaiver = (i) => {
        // if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory") {
        let field = JSON.parse(JSON.stringify(
            {
                "varName": "waiverType",
                "type": "select",
                "label": "Waiver Type",
                // "enum": this.state.SDRegulatory,
                "enum": [
                    "Trade License",
                    "NID or Readable Passport",
                    "Passport Size Photograph",
                    "E-TIN Certificate",
                    "EFTN Form",
                    "18-A Form",
                    "Visiting Card",
                    "other"
                ]
            }
        ));
        field.varName = "waiverType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
        // }
        // else if (this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {
        //     let field = JSON.parse(JSON.stringify(
        //         {
        //             "varName": "waiverType",
        //             "type": "select",
        //             "label": "Waiver Type",
        //             "enum": this.state.SDNonRegulatory,
        //         }
        //     ));
        //     field.varName = "waiverType" + i;
        //     return SelectComponent.select(this.state, this.updateComponent, field);
        // }

    };

    dynamicWaiverOther = (i) => {
        // if (this.state.inputData["typeofWaiverDocs" + i] === "Regulatory" || this.state.inputData["typeofWaiverDocs" + i] === "Non Regulatory") {

        // if (this.state.inputData["waiverType" + i] === "Others") {
        let field = JSON.parse(JSON.stringify(waiverOther));
        field.varName = "waiverOther" + i;
        return TextFieldComponent.text(this.state, this.updateComponent, field);
        // }
        // }
    };

    renderAddButtonShow = () => {

        return (
            <div>
                <button
                    className="btn btn-outline-primary btn-sm"

                    style={{
                        width: 100,
                        // float: 'right',
                        // verticalAlign: 'right',
                        //marginTop: "10px",
                        marginRight: "10px"
                    }}

                    type='button' value='add more'
                    onClick={this.addClick.bind(this)}


                >Add Deferral
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"

                    style={{
                        width: 100,
                        float: 'right',
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.addClickWaiver.bind(this)}


                >Add Waiver
                </button>
            </div>
        )

    };


    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferralCategory = (i) => {
        let field = JSON.parse(JSON.stringify(deferalCategory));
        field.varName = "deferalCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferralSubCategory = (i) => {
        let field = JSON.parse(JSON.stringify(deferalSubCategoryG));
        if (this.state.inputData["deferalCategory" + i] === "R") {
            field = JSON.parse(JSON.stringify(deferalSubCategoryR));
        }
        field.varName = "deferalSubCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDays = (i) => {
        let field = JSON.parse(JSON.stringify(deferalSubCategoryG));
        if (this.state.inputData["deferalCategory" + i] === "R") {
            field = JSON.parse(JSON.stringify(deferalSubCategoryR));
        }
         field.varName = "expireDays" + i;
        var exDate = "expireDate" + i;

        if (this.state.inputData[field.varName] !== undefined && this.state.inputData[field.varName] !== "") {
            var someDate = new Date();
            var x = this.state.inputData[field.varName];
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            let fullDate = month + '/' + date + '/' + year;

            fullDate === "NaN/NaN/NaN" ? this.state.inputData[exDate] = "" : this.state.inputData[exDate] = fullDate;

            console.log(fullDate);
            console.log(this.state.inputData[exDate])

            // this.state.inputData[exDate] = fullDate

        }

        return (

            <React.Fragment>
                <Grid item xs={1.5}>
                    {
                        SelectComponent.select(this.state, this.updateComponent, field)
                    }
                </Grid>

                <Grid item xs={1.5}>
                    {
                        this.dynamicDate(this.state.inputData[exDate])
                    }
                </Grid>
            </React.Fragment>

        );
    };

    dynamicDate = (i) => {
        return (
            <React.Fragment>
                <Grid item>
                    <label className="input-label-common">Expire Date</label>
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        value={i}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>
        )

    };

    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <React.Fragment>
                    <Grid item xs={2}>
                        {
                            this.dynamicDeferral(el)
                        }
                    </Grid>

                </React.Fragment>


                {/* <Grid item xs={5}> */}
                <Grid item xs={1.5}>
                    {
                        this.dynamicDeferralCategory(el)
                    }
                </Grid>
            {/*    <Grid item xs={1.5}>
                    {
                        this.dynamicDeferralSubCategory(el)
                    }
                </Grid>*/}

                {
                    this.dynamicDays(el)
                }

                {/* </Grid> */}


                < React.Fragment>
                    <Grid item xs={2}>
                        {this.dynamicDeferralOther(el)}

                    </Grid>
                    <Grid item xs={2}>
                        <button
                            style={{float: "left", marginTop: "15px"}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                        >
                            Remove
                        </button>


                    </Grid>
                </React.Fragment>
            </React.Fragment>
        )

    }

    addDeferalCommonForm() {
        if (this.state.values.length > 0) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state,deferalCommForm, this.updateComponent)
                    }
                </React.Fragment>
            );

        }
        }


    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["deferal"] = "YES";
    }

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["deferal"] = "YES"
            } else {
                this.state.inputData["deferal"] = "NO"
            }
        }


    }

    removeClickWaiver(i, event) {
        event.preventDefault();
        let pos = this.state.waiverValues.indexOf(i);
        if (pos > -1) {
            this.state.waiverValues.splice(pos, 1);
            this.updateComponent();
            if (this.state.waiverValues.length > 0) {
                this.state.inputData["csWaiver"] = "YES"
            } else {
                this.state.inputData["csWaiver"] = "NO"
            }
        }
    }

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };

    handleChange = (event, value) => {

        this.state.inputData["deferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
                    }
                </React.Fragment>
            );

        }
    };

    modalClose = () => {
        this.props.closeModal();
    };

    submitHandler = (e) => {
        e.preventDefault();

        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        }
        this.setState({
            disabled: true
        });

        if (this.state.inputData["deferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var deferalCategory = [];
            var deferalSubCategory = [];

            let appId = this.props.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);
                deferalCategory.push(this.state.inputData["deferalCategory" + value])
                deferalSubCategory.push(this.state.inputData["expireDays" + value])
                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                deferralCategory: deferalCategory,
                deferralSubCategory: deferalSubCategory,
                deferralDstId: this.state.inputData.deferalDstId,
                deferralRmCode: this.state.inputData.deferalRmCode,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log('Defferal Create Successfully');
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                })
        }

        if (this.state.values.length > 0) {
            this.state.inputData['mbu_deferal'] = "YES"
        } else {
            this.state.inputData['mbu_deferal'] = "NO"
        }

        if (this.state.inputData["csWaiver"] === "YES") {

            var waiverType = [];
            var wRemarks = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                let waiverRemarks = this.state.inputData["waiverOther" + waiverValues];
                waiverType.push(docType);
                wRemarks.push(waiverRemarks);
            }


            let waiverUrl = backEndServerURL + "/waiver/create/bulk";
            axios.post(waiverUrl, {
                appId: this.props.appId,
                type: waiverType,
                remarks: wRemarks
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });

                    Functions.loginRedirection(error);
                })
        }


        if (this.state.inputData.mbuRemarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mbuRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }


        let postData = this.state.inputData;
        postData.mbuRemarks = undefined;

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.modalClose();
                        this.setState({
                            title: "Successful!",
                            //  notificationMessage: "Data Store Successfully!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            disabled: false
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            disabled: false
                        });
                        console.log(error)
                    })
            })
            .catch((error) => {
                this.setState({
                    disabled: false
                });
                console.log(error);
            });
    };

    saveHandler = (e) => {
        e.preventDefault();
        this.setState({
            disabled: true
        });

        if (this.state.inputData["deferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var deferalCategory = [];
            var deferalSubCategory = [];

            let appId = this.props.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);
                deferalCategory.push(this.state.inputData["deferalCategory" + value])
                deferalSubCategory.push(this.state.inputData["deferalSubCategory" + value])
                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                deferralCategory: deferalCategory,
                deferralSubCategory: deferalSubCategory,
                deferralDstId: this.state.inputData.deferralDstId,
                deferralRmCode: this.state.inputData.deferralRmCode,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log('Defferal Create Successfully');
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                })
        }

        if (this.state.values.length > 0) {
            this.state.inputData['mbu_deferal'] = "YES"
        } else {
            this.state.inputData['mbu_deferal'] = "NO"
        }


        if (this.state.inputData.mbuRemarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mbuRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                    console.log(error)
                })
        }

        if (this.state.inputData["csWaiver"] === "YES") {

            var waiverType = [];
            var wRemarks = [];
            for (let i = 0; i < this.state.waiverValues.length; i++) {
                let waiverValues = this.state.waiverValues[i];
                let docType = this.state.inputData["waiverType" + waiverValues];
                let waiverRemarks = this.state.inputData["waiverOther" + waiverValues];
                waiverType.push(docType);
                wRemarks.push(waiverRemarks);
            }


            let waiverUrl = backEndServerURL + "/waiver/create/bulk";
            axios.post(waiverUrl, {
                appId: this.props.appId,
                type: waiverType,
                remarks: wRemarks
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });

                    Functions.loginRedirection(error);
                })
        }

        let url = backEndServerURL + "/save/" + this.props.appId;
        axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.modalClose();

                this.setState({
                    title: "Successful!",
                    notificationMessage: "Data Store Successfully!",
                    alert: true,
                    disabled: false
                })

            })
            .catch((error) => {
                this.setState({
                    disabled: false
                });
                console.log(error);
            })

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    returnHandler = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(mbuRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });
            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;
            let mbu_deferal = "RETURN";

            axios.post(deferalCheckUrl, {mbu_deferal: mbu_deferal}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log('Case Route Successful');

                            this.modalClose();
                            this.setState({
                                title: "Successful!",
                                //    notificationMessage: "Return Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            })
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                            console.log(error)
                        })
                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                    console.log(error)
                });


            if (this.state.inputData.mbuRemarks !== null) {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.mbuRemarks + "/" + this.props.appId;

                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log('remarks');
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    };

    missingOpen = (e) => {
        e.preventDefault();
        this.setState({
            missing: true
        })
    };

    documentMissingModal = () => {
        this.setState({
            missing: false,
        });
    };

    missingButton = () => {
        if (this.state.getSplitFile.length > 0 && this.state.getData) {

            let documentList = [];

            if (this.state.inputData.merchantType === "Sole Proprietorship") {
                documentList = proprietorDocuments;
            } else if (this.state.inputData.merchantType === "Limited Liability") {

                documentList = llcDocuments
            } else if (this.state.inputData.merchantType === "Partnership") {

                documentList = partnershipDocuments
            } else {

                documentList = othersDocuments;
            }

            return (
                <div>
                    <button
                        style={{
                            marginTop: '20px',
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='submit'
                        onClick={this.open}
                    >
                        Preview Document
                    </button>
                    &nbsp;&nbsp;
                    <button
                        style={{marginTop: '20px',}}
                        className="btn btn-outline-primary btn-sm"
                        type='submit'
                        onClick={this.missingOpen}
                    >
                        Missing Files
                    </button>
                    <a className="btn btn-outline-primary btn-sm"
                       style={{
                           verticalAlign: 'middle',
                           marginLeft: '12px',
                           marginRight: '10px',
                           marginTop: "20px"
                       }}
                       href={emi} target={"_blank"}>Print</a>
                    <br/>
                    <br/>

                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        open={this.state.missing}>
                        <DialogContent>

                            <MissingFlies closeModal={this.documentMissingModal} documents={documentList}
                                          appId={this.state.inputData.appId}/>
                        </DialogContent>
                    </Dialog>
                </div>
            )

        }
    };
    renderSDNDedupeResult = () => {
        if (this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true} closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };

    render() {
        console.log(".....mbu component......");
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (

                <Grid Container spacing={0}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                {   this.renderSDNDedupeResult()}
                                <div>
                                    <Grid container spacing={0}>
                                        <ThemeProvider theme={theme}>
                                            {
                                                this.renderFormWithData()
                                            }
                                            <Grid item xs='12'>
                                            </Grid>
                                            <br/>
                                            {
                                                this.missingButton()
                                            }

                                            <Grid item xs='12'>
                                            </Grid>
                                            {
                                                this.renderNotification()
                                            }

                                            {this.renderAddButtonShow()}

                                            <Grid item xs='12'>

                                            </Grid>
                                            {
                                                this.addDeferalForm()
                                            }
                                            {
                                                this.addDeferalCommonForm()
                                            }
                                            <Grid item xs='12'>
                                            </Grid>
                                            {
                                                this.addWaiverForm()
                                            }


                                            <Grid item xs='12'>
                                            </Grid>
                                            <Grid item xs='12'>
                                                {
                                                    this.renderDeferalApprovalTableData()
                                                }
                                            </Grid>

                                            <Grid item xs={12}>
                                                {this.functionForWaiver()}
                                            </Grid>

                                            <Grid item xs='12'>
                                                <br/>
                                            </Grid>

                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="md"
                                                open={this.state.documentModal}>
                                                <DialogContent>
                                                    <ShowDocument closeModal={this.documentModal}
                                                                  appId={this.props.appId}/>
                                                </DialogContent>
                                            </Dialog>

                                            <Grid container spacing={0}>
                                                <ThemeProvider theme={theme}>

                                                    <Grid item xs={12}>
                                                        {
                                                            this.renderRemarksTable()
                                                        }
                                                    </Grid>


                                                    <Grid item xs={12}>

                                                        {this.renderRemarks()}

                                                    </Grid>


                                                </ThemeProvider>
                                            </Grid>


                                            <Grid item xs='12' style={{marginTop: '20px'}}>

                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        marginRight: '10px',
                                                    }}
                                                    type="submit"
                                                    onClick={this.submitHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Submit
                                                </button>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        verticalAlign: 'middle',
                                                        marginRight: '10px',
                                                    }}
                                                    type="submit"
                                                    onClick={this.saveHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    onClick={this.returnHandler}
                                                    disabled={this.state.disabled}
                                                >
                                                    Return
                                                </button>

                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>

            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }
}

export default withStyles(styles)(MbuDefferalPos);