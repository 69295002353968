import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import FileMappingReadOnly from "../../CommonComponent/FileMappingReadOnly";
import {Button} from "@material-ui/core";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CloseIcon from "@material-ui/icons/Close";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


const jsonForm = [

    {
        varName: "customerCB",
        type: "text",
        label: "Customer CB",
        required: true,
        readOnly:true,
        grid: 2,
    },

    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "text",
        label: "Loan Accounts",
        readOnly:true,
        grid: 2,

    },
    {
        "varName": "loanCategory",
        "type": "text",
        "label": "Loan Category",
        "grid": 2,
        "readOnly": true,
    },

    {
        "varName": "productName",
        "type": "text",
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,

    },
    {
        "varName": "accountStatus",
        "type": "text",
        "label": "Account Status",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "sanctionLimit",
        "type": "text",
        "label": "Loan Sanction Amount",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "loanOutstanding",
        "type": "text",
        "label": "Loan Oustanding",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "unappliedInterest",
        "type": "text",
        "label": "Unapplied Interest",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "limitExpiry",
        "type": "text",
        "label": "Limit Expiry",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "sol",
        "type": "text",
        "label": "SOL",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "branchName",
        "type": "text",
        "label": "Sourcing Branch",
        "grid": 2,
        "readOnly": true,
    },
    {
        "varName": "classificationLoanStatus",
        "type": "text",
        "label": "Classification Status",
        "grid": 2,
        "readOnly": true,
    },
    {
        varName: "closedBy",
        type: "select",
        label: "Closed By",
        readOnly:true,
        grid: 2,

    },
    {
        type: "empty",
        grid: 12,
    },




]

let csAfterMakerRemarks = [
    {
        "varName": "csAfterMakerRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }]
;




class CsAfterMakerSecuredLoanClosing extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {
            cs_after_maker_approved: '',
            subServiceType: '',
        },
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        confirmAlert: false,
        actionType: "",
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: false,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false

    };




    componentDidMount() {

        var remarksArray = [];
        this.setState({
            loading: true
        })

        if(this.props.appId !== undefined){

            let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    if (data.remarks !== 'undefined') {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    }
                })
                this.setState({
                    getRemarks: remarksArray
                })
            })
                .catch((error) => {
                    console.log(error)
                })

            let url = assetOpsBackEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                .then((response) => {


                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })


                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    this.setState({
                        getRemarks: remarksArray
                    })

                    console.log(response.data)
                    let inputData=response.data;
                    inputData.csAfterMakerRemarks=undefined;
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appId: this.props.appId,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });

        }




    }

    updateComponent = () => {
        this.forceUpdate();
    };
    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)

            )
        }
    }





    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject))
        for (var prop in clone)
            if (clone[prop] === ''     || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                delete clone[prop];
        return clone;
    }








    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };



    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    }

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject))
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
delete clone[prop];
        return clone;
    }


    dateModifier=(date)=>{
        if(date!==undefined && date!=null){
            let splittedDate = (date).split("/");
            return`${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        }
    }

    renderFormTable = () => {
        return (
            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Product Name</th>
                            <td>{this.state.inputData.productName}</td>
                            <th scope="row">Loan Amount</th>
                            <td>{this.state.inputData.loanAmt}</td>
                        </tr>
                        <tr>
                            <th scope="row">Scheme Code</th>
                            <td>{this.state.inputData.schemeCode}</td>
                            <th scope="row">Pay Of Balance</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td>{this.dateModifier(this.state.inputData.disbursementDate)}</td>
                            <th scope="row">Early Settlement Fee</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                        </tr>
                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.dateModifier(this.state.inputData.loanAccountExpiryDate)}</td>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                        </tr>
                        <tr>
                            <th scope="row">Maturity Status</th>
                            <td>{this.state.inputData.maturityStatus}</td>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.exciseDuty}</td>
                        </tr>

                        <tr>
                            <th scope="row">Total Settlement Amount</th>
                            <td>{this.state.inputData.totalAmount}</td>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                        </tr>

                    </table>
                </div>
            </div>
        )
    };
    handleSubmit = (data) => {
        console.log(this.state.inputData);
        let dependencyField = [];

        this.setState({
            loading: true
        });
        let  saveDataDeferralRemarksAndRoute={};
        if(this.state.inputData.csAfterMakerRemarks!==undefined && this.state.inputData.csAfterMakerRemarks!==null){
            saveDataDeferralRemarksAndRoute.remark=this.state.inputData.csAfterMakerRemarks;
        }

        var appId = this.props.appId;
        var approval = data;

        if(data==="NO"){
            this.state.inputData.cs_after_maker_approved = data;
        }
        else{
            this.state.inputData.cs_after_maker_approved = data;
        }



        let variableSetUrl = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("Before case route in CS after Maker");
                console.log(this.state.inputData);
                var url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;

                axios.post(url,saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: response.data,
                            alert: true,
                            loading: false
                        });

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })

                    });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });


    };






    close = () => {
        this.props.closeModal();
    }


    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    }

    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csAfterMakerRemarks, this.updateComponent)

            )
        }
        return;
    }

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    }
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width:'100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    }


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"} href={assetOpsBackEndServerURL + "/file/" + data+'/'+sessionStorage.getItem("accessToken")} style={{
                            backgroundColor: "red",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1,
                            textDecoration: "none"
                        }} target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}

                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleReject")}

                    >Return
                    </button>
                </div>


            )
        }
    }
    handleCommon = (event, type) => {
        event.preventDefault();

        {
            this.validationForHandleConfirm(type)
        }


    };

    validationForHandleConfirm = (actionType) => {
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "handleReject") {
            let dependencyField = [];
            if (actionType === "handleReject") {
                dependencyField.push({

                    "varName": "csAfterMakerRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "CS Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmit") {

            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "handleReject") {

            this.handleSubmit("NO")
        }
    };


    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}  <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Closing Request(secured Loan){this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };




    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}
                        asset={true} />
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                      closeModal={this.closeConfirmAlert}
                                      asset={true}


                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    {this.renderHeader()}
                </CardHeader>

                <CardBody>

                <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                        {this.renderNotification()}
                        {this.renderForm()}
                        <Grid item xs={12}></Grid>

                        {this.mappingPhoto()}
                    </Grid>
                    {/*{this.renderFormTable()}*/}
                    <br/>


                    {this.renderRemarksData()}
                    <br/>
                    {this.renderFlowSUmmeryButton()}
                    <br/>

                    {this.renderRemarks()}
                    <br/>

                    <div>
                        {this.renderSubmitButton()}
                    </div>
                </ThemeProvider>

                </CardBody>

            </Card>

        )
    }


}

export default withStyles(styles)(CsAfterMakerSecuredLoanClosing);
