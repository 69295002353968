import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DebitCardCheckerSplit from "../DebitCard/DebitCardCheckerSplit";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import SignatureButton from "../../workflow/Maintenance/SignatureButton";
import MyValidation from "../../JsonForm/MyValidation";
import Table from "../../Table/Table";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let pdfForm = [
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        readOnly: true,
        validation: "numeric"
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        grid: 2,
        readOnly: true
    },
    {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        readOnly: true,
        grid: 2,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "mobileNumber",
        type: "text",
        label: "Mobile Number",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "numberOfChequeBook",
        type: "text",
        label: "Number of Cheque Book",
        grid: 2,
        readOnly: true
    },
    {
        varName: "pageofChequebook",
        type: "text",
        label: "Page of Chequebook",
        grid: 2,
        readOnly: true
    },

    {
        varName: "deliveryType",
        type: "text",
        label: "Delivery Type",
        grid: 2,
        readOnly: true
    },

    {
        varName: "branchName",
        type: "text",
        label: "Branch Name",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        required: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerType",
        type: "select",
        label: "Customer Type",
        readOnly: true,
        enum: ["General", "Priority", "Sapphire"],
        grid: 2,
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        conditional: true,
        readOnly: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Priority",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5"],
    },
    {
        varName: "checqueBookDesign",
        type: "select",
        label: "Chequebook Design",
        readOnly: true,
        conditional: true,
        conditionalVarName: "customerType",
        conditionalVarValue: "Sapphire",
        grid: 2,
        enum: ["Design Code 1", "Design Code 2", "Design Code 3", "Design Code 4", "Design Code 5", "Design Code 6"],
    },
    {
        varName: "chequeBookType",
        type: "text",
        readOnly: true,
        label: "Cheque Book Type",
        grid: 2,
    },
];
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class ChequeBookSplit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            signatureModal: false,
            documentList: [],
            selectedDate: {},
            getDocument: false,
            loading: true,
            cbNotFound: false,
            getData: false,
            disabled: false,
            getRemarks: []

        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    getVariables() {
        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log("Variable data");
                console.log(response.data);

                this.setState({
                    varValue: this.copyJson(response.data),
                    appData: this.copyJson(response.data),
                    inputData: this.copyJson(response.data),
                    showValue: true,
                    getData: true,
                    loading: false
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        console.log("file");
        console.log(this.props.file);
        console.log(this.props.appId);
        this.functionForGetRemarks();
        if (this.props.appId !== undefined) {
            this.getVariables();
        } else {
            this.setState({
                getData: true
            });
        }
    }

    inboxCase = () => {
        return (
            <DebitCardCheckerSplit
                closeModal={this.props.closeModal}
                documentList={this.state.documentList}
                appId={this.props.appId}
                form={this.renderFormWithData}
            />
        );
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = event => {
        event.preventDefault();
        this.setState({
            disabled: true,
            loading: true
        });
        let approval={};
        if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
            this.state.inputData.call_center_checker_approval="APPROVED"
            approval.call_center_checker_approval="APPROVED";
        }
        else{
            this.state.inputData.bom_approval="APPROVED";
            approval.bom_approval= "APPROVED";
        }

        this.forceUpdate();
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(url, approval, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let postData = this.state.inputData;
                postData.appId = this.props.appId;
                let routeTaskUrl="";

                routeTaskUrl = backEndServerURL + "/checquebookrequisitionsave";
                axios.post(routeTaskUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.caseRoute(postData);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            disabled: false,
                            loading: false,
                            cbNotFound: true,
                            title: 'Unable to Save Cheque Book!'
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                });
            });
    };

    functionForRemarksSave = () => {
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                this.setState({
                    disabled: false,
                });
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;
    };



    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    caseRoute = (data) => {
        /*let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    disabled: false,
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                });
                this.props.closeModal()
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                });
            });*/
     let saveDataDeferralRemarksAndRoute={};

       if(this.state.inputData.bomRemarks!==undefined && this.state.inputData.bomRemarks!==null){
           saveDataDeferralRemarksAndRoute.remark=this.state.inputData.bomRemarks;
       }
        data.bomRemarks = undefined;
        saveDataDeferralRemarksAndRoute.map=data;

        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                disabled: false,
                loading: false,
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
            });
            this.props.closeModal();
        }).catch((error) => {
            console.log(error);
            this.setState({
                disabled: false,
                loading: false
            })
        });
    };


    returnHandler = event => {
        event.preventDefault();

        let dependencyField = [
            {
                varName: "bomRemarks",
                type: "textArea",
                required: true
            }
        ];
        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else{
            this.setState({
                disabled: true,
                loading: true
            });
            let inputData={};
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
                this.state.inputData.call_center_checker_approval="RETURN";
                inputData.call_center_checker_approval="RETURN";
            }
            else{
                this.state.inputData.bom_approval="RETURN";
                inputData.bom_approval="RETURN";
            }

            this.forceUpdate();
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(url, inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.caseRoute(inputData);
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false
                    });
                });
        }

    };

    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };
    closeModalCBNotFound = () => {
        this.setState({
            signatureModal: false,
            cbNotFound: false
        })
    };

    signature = () => {
      //  if (this.state.getData) {
            return (
                <>
                    <SignatureButton signatureSource={this.state.inputData.requestType} accountNumber={this.state.inputData.accountNumber}
                                     requestType={this.state.inputData.requestType} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        //}
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                pdfForm,
                                this.updateComponent
                            )}
                            <Grid item xs={12}>
                                {this.signature()}
                            </Grid>

                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                                <br/>
                                {
                                    this.renderRemarks()
                                }
                            </Grid>
                            {this.renderNotification()}
                        </ThemeProvider>
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid item xs="3" style={{marginTop: "20px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={this.state.disabled}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.returnHandler}
                            disabled={this.state.disabled}
                        >
                            Return
                        </button>

                    </Grid>

                </div>
            );
        }
    }

}

export default withStyles(styles)(ChequeBookSplit);
