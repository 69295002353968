import React, {Component} from "react";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../Table/Table";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class AccountNoGenerate extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,
        inputData: {},
        dropdownSearchData: {},
        accountNo: 115,


    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        //this.props.close()
        this.props.closeModal(this.state.accountNo);
    };

    closeOnlyModal = () => {
        this.props.close()
    };
    renderAccountDetails = () => {
        if (this.props.solId !== undefined && this.props.dps === true && this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Customer Name", this.props.customerName],
                        ["Account Number", this.props.generateNewAccountNumber],
                        ["SOLID", this.props.solId],
                        ["CB Number", this.props.cbNumber],
                        ["Scheme Code", this.props.schemeCode],
                        ["SI Serial number", this.props.recpmtid],
                        ["Product Type ", "TDA"],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else if (this.props.solId !== undefined && this.props.dps === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Customer Name", this.props.customerName],
                        ["Account Number", this.props.generateNewAccountNumber],
                        ["SOLID", this.props.solId],
                        ["CB Number", this.props.cbNumber],
                        ["Scheme Code", this.props.schemeCode],
                        ["Transaction CB number", this.props.recpmtid],
                        ["Product Type ", "TDA"],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else if (this.props.solId !== undefined && this.props.cbAndScheme === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Customer Name", this.props.customerName],
                        ["Account Number", this.props.generateNewAccountNumber],
                        ["SOLID", this.props.solId],
                        ["CB Number", this.props.cbNumber],
                        ["Scheme Code", this.props.schemeCode],
                        ["Product Type ", "Regular"],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else if (this.props.solId !== undefined) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Customer Name", this.props.customerName],
                        ["Account Number", this.props.generateNewAccountNumber],
                        ["SOLID", this.props.solId],
                        // ["CB Number", this.state.generateNewCB],
                        ["Product Type ", "Regular"],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Customer Name", this.props.customerName],
                        ["Account Number", this.props.generateNewAccountNumber],
                        // ["CB Number", this.state.generateNewCB],
                        ["Product Type ", "Regular"],


                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        }
        /*  } else if (this.props.generateNewAccountNumber !== 0 && this.props.generateNewAccountNumber !== "") {
              return (
                  <Table
                      tableHeaderColor="primary"
                      tableHead={["View Data", "Value"
                      ]}
                      tableData={[
                          ["Account Number", this.props.generateNewAccountNumber],
                          ["Product Type ", "Regular"],


                      ]}
                      tableAllign={['left', 'left']}
                  />
              )
          } else {
              return (
                  <Table
                      tableHeaderColor="primary"
                      tableHead={["View Data", "Value"
                      ]}
                      tableData={[
                          ["CB Number", this.state.generateNewCB],
                          ["Product Type ", "Regular"],


                      ]}
                      tableAllign={['left', 'left']}
                  />
              )

          }*/
    };

    render() {
        const {classes} = this.props;
        if (this.props.maturityAmt_amountValue !== undefined && this.props.maturityAmt_amountValue !== null) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Payable At Maturity<a><CloseIcon onClick={this.close} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>


                            </CardHeader>
                            <CardBody>
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={["View Data", "Value"
                                    ]}
                                    tableData={[
                                        ["Payable At Maturity", this.props.maturityAmt_amountValue]
                                    ]}
                                    tableAllign={['left', 'left']}
                                />
                            </CardBody>
                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.close}

                                >
                                    Ok
                                </button>
                            </center>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Account Number Generate<a><CloseIcon onClick={this.close} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>


                            </CardHeader>
                            <CardBody>
                                {this.renderAccountDetails()}
                            </CardBody>
                            <center>
                                <button
                                    className="btn btn-outline-primary"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.close}

                                >
                                    Ok
                                </button>
                            </center>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }

    }

}

export default withStyles(styles)(AccountNoGenerate);