import TextField from "@material-ui/core/TextField";
import React from "react";
import Grid from "@material-ui/core/Grid";

class TextFieldComponent {

    static handleChange(event, state, update) {
        // let inputData = state.inputData;
        // inputData[event.target.name] = event.target.value;
        state.inputData[event.target.name] = event.target.value;
        //update(inputData);
    }

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";
            return state.varValue[field.varName];
        }
    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static text(state, update, field) {
        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }
                <Grid item>
                    <TextField
                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        variant="outlined"
                        defaultValue={this.returnDefaultValue(field, state)}
                        required={field.readOnly === true ? false : field.required} key={field.varName}
                        name={field.varName}
                        disabled={field.readOnly}
                        multiline
                        rows="2"
                        rowsMax="12"
                        //label={field.label}
                        // multiline={field.multiline === true ? true : false}
                        onChange={(event) => this.handleChange(event, state, update)}
                        InputProps={{
                            //readOnly: field.readOnly
                        }}
                        //InputLabelProps={{shrink:true}}
                        fullWidth
                        style={field.readOnly === true ? {
                            paddingRight: 20,
                            color: '#212F3D',
                            background: '#f8f9fa'
                        } : {paddingRight: 20}}

                    />
                </Grid>
            </React.Fragment>

        )


    }

}

export default TextFieldComponent;
