import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from "react";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/CommonJsonForm.css";


class AutoCompleteComponent {

    static handleChange(option, state, update, field) {
        state.inputData[field.varName] = option === null ? null : option.label;

        //update();
    }

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";

            else{
                if(state.varValue[field.varName] === undefined || state.varValue[field.varName] == null || state.varValue[field.varName] === "undefined" || state.varValue[field.varName] == "null"){
                    return "";
                }
                else{
                    return state.varValue[field.varName];
                }

            }


        }
    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static renderSelect(state, update, field) {
        let defaultProps = {
            options: state.autoComplete[field.varName],
            getOptionLabel: option => option.label,
        };
        let styles = { paddingRight: 20};
        if (field.readOnly === true && field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        } else if (field.readOnly === true) {
            const firstStyle = {
                color: '#212F3D',
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }else if (field.fieldColor === true || (field.readOnly === true && field.fieldColor === true)) {
            const secondStyle = {
                color: '#ffffff',
                background: '#A6BDF9'
            };
            styles = Object.assign(styles, secondStyle)
        }


        return (
            <React.Fragment>
                {this.asteriksFunction(field)}

                <Grid item>
                    <Autocomplete onChange={(event, option) => this.handleChange(option, state, update, field)}
                                  helperText={state.errorMessages[field.varName]}
                                  error={state.errorArray[field.varName]}
                                  required={field.readOnly === true ? false : field.required}
                                  {...defaultProps}
                                  style={styles}
                                  defaultValue={this.returnDefaultValue(field, state)}
                                  renderInput={(params) => <TextField {...params}
                                                                      error={state.errorArray !== undefined && state.errorArray[field.varName]}
                                                                      helperText={state.errorArray[field.varName] === true ? state.errorMessages[field.varName] : ""}
                                                                      variant="outlined"
                                                                      style={field.readOnly === true ? {color: "#ffffff"} : {color: "#000000"}}
                                                                      fullWidth/>}
                    />
                </Grid>
            </React.Fragment>

        )


    }

}

export default AutoCompleteComponent;