import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import React, {Component} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CustomerCreditCard from "../customerDetails/CustomerCreditCard";
import SavingsCurrentSnd from "../customerDetails/SavingsCurrentSnd";
import FreezeAccount from "../customerDetails/FreezeAccount";
import CustomerLoan from "../customerDetails/CustomerLoan";
import IndividualTermDeposit from "../customerDetails/IndividualTermDeposit";
import CustomerLocker from "../customerDetails/CustomerLocker";
import StandingInstruction from "../customerDetails/StandingInstruction";
import ListOfCards from "../customerDetails/ListOfCards";
import StatusOfPropagation from "../customerDetails/StatusOfPropagation";
import ListOfAccounts from "../customerDetails/ListOfAccounts";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Functions from '../../Common/Functions';
import DialogContent from "@material-ui/core/DialogContent";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class DemographicsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ListOfAccounts: false,
            ListOfCards: false,
            StatusOfPropagation: false,
            ListOfLockerFacility: false,
            ListOfStandingInstruction: false,
            ListOfFreezeAccount: false,
            open: true,
            varValue: [],
            tableData: [[" ", " "]],
            getData: false,
            MAINCB: "",
            IDENTIFICATION_NO: '',
            CustomerSegment: '',
            renderCustomerGeneralInfoData: true,
            renderCustomerRelationTableData: true,
            redirectLogin: false,
            ACQUIRINGRM: '',
            lastTransactionDates: ''
        }
    }


    componentDidMount() {

        this.setState({
            renderCustomerGeneralInfoData: true
        });


        /*  if (this.props.booleanAccountSummary) {
              let accountSummary = this.props.accountSummary;
              let rmCode = accountSummary.getAcquringRmCode;
              if (rmCode !== undefined && rmCode !== null) {
                  rmCode = rmCode + "-" + accountSummary.getAcquringRmName
              }

              let lastTransactionDates = accountSummary.lastTransactionDate;
              if (lastTransactionDates !== undefined && lastTransactionDates !== null) {
                  lastTransactionDates = lastTransactionDates.split("T")[0];
                  lastTransactionDates = lastTransactionDates
              }

              this.setState({
                  ACQUIRINGRM: rmCode,
                  lastTransactionDates: lastTransactionDates,

              })
          } else {
          }*/

        const getAllGroupMember = [];
        /*if (this.props.booleanGetAllGroupMember) {
            this.setState({
                groupId: this.props.getAllGroupMember[0].groupUid,
            })

        } else {

        }*/


    }


    ListOfAccounts(cbNumber) {
        this.setState({
            ListOfAccounts: true,
            IDENTIFICATION_NO: cbNumber
        });
    }

    ListOfCards(cbNumber) {
        this.setState({
            ListOfCards: true,
            IDENTIFICATION_NO: cbNumber
        });
    }

    StatusOfPropagation() {
        this.setState({
            StatusOfPropagation: true,
        });
    }

    ListOfLockerFacility() {
        this.setState({
            ListOfLockerFacility: true,
        });
    }

    ListOfStandingInstruction() {
        this.setState({
            ListOfStandingInstruction: true,
        });
    }

    ListOfFreezeAccount() {
        this.setState({
            ListOfFreezeAccount: true,
        });
    }


    SingleCards() {
        this.setState({
            SingleCards: true,
        });
    }


    closeListOfAccountsModal = () => {
        this.setState({
            ListOfAccounts: false,

        });
    };

    closeListOfCardsModal = () => {
        this.setState({
            ListOfCards: false,

        });
    };

    closeStatusOfPropagationModal = () => {
        this.setState({
            StatusOfPropagation: false,

        });
    };
    closeListOfLockerFacilityModal = () => {
        this.setState({
            ListOfLockerFacility: false,

        });
    };
    closeListOfStandingInstructionModal = () => {
        this.setState({
            ListOfStandingInstruction: false,

        });
    };

    closeListOfFreezeAccountModal = () => {
        this.setState({
            ListOfFreezeAccount: false,

        });
    };


    closeSingleCardsModal = () => {
        this.setState({
            SingleCards: false,

        });
    };


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    renderCustomerGeneralInfoData = () => {
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Customer 360 Degree ID", this.props.prioritize360["IDENTIFICATION_NO_MOD"]],
                        ["Customer RV CB", this.props.prioritize360["RVCB"]],
                        ["Group ID", this.state.groupId],
                        ["Customer Name", this.props.prioritize360["CUSTOMERNAME"]],
                        ["DOB", this.props.prioritize360["DATEOFBIRTH"]],
                        ["Home Branch", this.props.prioritize360["HOMEBRANCH"]],
                        ["Gender", this.props.prioritize360["GENDER"]],
                        ["NID", this.props.prioritize360["NID"]],
                        ["Passport", this.props.prioritize360["PASSPORT"]],

                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />


            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerGeneralInfoData2 = () => {
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["E-TIN", this.props.prioritize360["TRADELICENSE"]],
                        ["Trade License", this.props.prioritize360[""]],
                        ["Mother Name", this.props.prioritize360["MOTHERNAME"]],
                        ["Father Name", this.props.prioritize360["FATHERNAME"]],
                        ["Spouse Name", this.props.prioritize360["SPOUSNAME"]],
                        ["Introducer CB", this.props.prioritize360["INTRODUCERCB"]],
                        ["Introducer Name", this.props.prioritize360["INTRODUCERNAME"]],
                        ["Staff Flag", this.props.prioritize360["CUSTSTAFF"]],
                        ["Last Customer Induced Transaction Date", this.props.accountSummary.lastTransactionDates],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerContactInfoData = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Communication Address 1", this.props.prioritize360["ADDRESS1"]],
                        ["Communication Address 2", this.props.prioritize360["ADDRESS2"]],
                        ["City", this.props.prioritize360["CITY"]],
                        ["State", this.props.prioritize360["STATE"]],
                        ["Postal Code", this.props.prioritize360[""]],
                        ["Country", this.props.prioritize360["COUNTRY"]],
                        ["Email ID", this.props.prioritize360["EMAILADDRESS"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerContactInfoData2 = () => {
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["Demographics", "Value"
                    ]}
                    tableData={[
                        ["Permanent Address 1", this.props.prioritize360["PERMANENT_ADD1"]],
                        ["Permanent Address 2", this.props.prioritize360["PERMANENT_ADD2"]],
                        ["City", this.props.prioritize360["PERMANENT_CITY"]],
                        ["State", this.props.prioritize360["PERMANENT_STATE"]],
                        ["Residence Phone", this.props.prioritize360["RESPHONENUM"]],
                        ["Mobile No.", this.props.prioritize360["MOBNO"]],
                        ["Office Phone", this.props.prioritize360["OFFPHONENUM"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerBusinessRelationData = () => {
        if (this.state.renderCustomerGeneralInfoData === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Customer Constitution", this.props.prioritize360["CUSTOMERCONTITUTION"]],
                        ["Line of Business", this.props.prioritize360[""]],
                        ["Customer Segment", this.props.prioritize360["CUSTOMERSEGMENT"]],
                        ["Customer Type", this.props.prioritize360["CUSTOMERTYPE"]],
                        ["Occupation", this.props.prioritize360["OUTOCCUPATION"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />

            )
        } else if (this.state.renderCustomerGeneralInfoData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerBusinessRelationData2 = () => {
        if (this.props.booleanPrioritize360 === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Business Sector", this.props.prioritize360["SUBSECTOR"]],
                        ["Source", this.props.prioritize360[""]],
                        ["Lead Generator", this.props.prioritize360[""]],
                        ["Acquiring RM", this.props.accountSummary.ACQUIRINGRM],
                        ["Monitoring RM", this.props.prioritize360["MONITORINGRM"]],
                    ]}
                    tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                />
            )
        } else if (this.props.booleanPrioritize360 === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderCustomerRelationTableData = () => {
        if (this.props.booleanRelatedCB === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["CB", "Related CB", "Related CB Name", "Relationship"]}
                    tableData={this.props.relatedCB}
                    tableAllign={['left', 'left', 'left', 'left']}
                />
            )
        } else if (this.props.booleanRelatedCB === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SingleCards}
                    onClose={this.closeSingleCardsModal}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <CustomerCreditCard closeModal={this.closeSingleCardsModal}/>


                    </DialogContent>
                </Dialog>


                {/*  <Dialog

                    className={classes.modal}
                    open={this.state.SingleCards}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}

                >

                    <CustomerCreditCard closeModal={this.closeSingleCardsModal}/>
                </Dialog>
*/}
                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SingleAccount}
                    onClose={this.closeSingleAccountModal}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <SavingsCurrentSnd closeModal={this.closeSingleAccountModal}/>
                        <CustomerLoan closeModal={this.closeSingleAccountModal}/>
                        <IndividualTermDeposit closeModal={this.closeSingleAccountModal}/>

                    </DialogContent>
                </Dialog>
                {/* <Dialog

                    className={classes.modal}
                    open={this.state.SingleAccount}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}
                    onClose={this.closeSingleAccountModal}
                >

                    <SavingsCurrentSnd closeModal={this.closeSingleAccountModal}/>
                    <CustomerLoan closeModal={this.closeSingleAccountModal}/>
                    <IndividualTermDeposit closeModal={this.closeSingleAccountModal}/>
                </Dialog>
*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.ListOfStandingInstruction}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <StandingInstruction closeModal={this.closeListOfStandingInstructionModal}/>


                    </DialogContent>
                </Dialog>

                {/*<Dialog

                    className={classes.modal}
                    open={this.state.ListOfStandingInstruction}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >
                    <StandingInstruction closeModal={this.closeListOfStandingInstructionModal}/>

                </Dialog>
*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.ListOfLockerFacility}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <CustomerLocker closeModal={this.closeListOfLockerFacilityModal}/>


                    </DialogContent>
                </Dialog>

                {/* <Dialog

                    className={classes.modal}
                    open={this.state.ListOfLockerFacility}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >
                    <CustomerLocker closeModal={this.closeListOfLockerFacilityModal}/>

                </Dialog>*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.StatusOfPropagation}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <StatusOfPropagation closeModal={this.closeStatusOfPropagationModal}/>


                    </DialogContent>
                </Dialog>
                {/*  <Dialog

                    className={classes.modal}
                    open={this.state.StatusOfPropagation}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >
                    <StatusOfPropagation closeModal={this.closeStatusOfPropagationModal}/>

                </Dialog>*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.ListOfCards}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <ListOfCards closeModal={this.closeListOfCardsModal}
                                     IDENTIFICATION_NO={this.props.IDENTIFICATION_NO}/>


                    </DialogContent>
                </Dialog>
                {/*<Dialog

                    className={classes.modal}
                    open={this.state.ListOfCards}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >
                    <ListOfCards closeModal={this.closeListOfCardsModal} IDENTIFICATION_NO={this.props.IDENTIFICATION_NO}/>

                </Dialog>*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.ListOfAccounts}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <ListOfAccounts closeModal={this.closeListOfAccountsModal}
                                        IDENTIFICATION_NO={this.props.IDENTIFICATION_NO}/>


                    </DialogContent>
                </Dialog>
                {/* <Dialog

                    className={classes.modal}
                    open={this.state.ListOfAccounts}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >

                    <ListOfAccounts closeModal={this.closeListOfAccountsModal} IDENTIFICATION_NO={this.props.IDENTIFICATION_NO}/>


                </Dialog>
*/}

                <Dialog

                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.ListOfFreezeAccount}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <FreezeAccount closeModal={this.closeListOfFreezeAccountModal}/>


                    </DialogContent>
                </Dialog>

                {/*<Dialog

                    className={classes.modal}
                    open={this.state.ListOfFreezeAccount}
                    fullWidth="true"
                    maxWidth="md"

                    TransitionComponent={Transition}

                >
                    <FreezeAccount closeModal={this.closeListOfFreezeAccountModal}/>

                </Dialog>*/}


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>

                            <CardBody>
                                <br/>


                                <Paper className={classes.paper}>
                                    <center>
                                        <div>

                                            <Button variant="outlined" color="secondary" className={classes.button}

                                                    onClick={() => {
                                                        this.setState({
                                                            ListOfAccounts: true
                                                        })
                                                    }
                                                    }
                                            >
                                                List of Accounts
                                            </Button>

                                            <Button variant="outlined" color="secondary" className={classes.button}

                                                    onClick={() => {
                                                        this.setState({
                                                            ListOfCards: true
                                                        })
                                                    }
                                                    }
                                            >
                                                List of Cards
                                            </Button>

                                            <Button variant="outlined" color="secondary" className={classes.button}

                                                    onClick={() => {
                                                        this.setState({
                                                            StatusOfPropagation: true
                                                        })
                                                    }
                                                    }
                                            >
                                                Status of Propagation
                                            </Button>

                                        </div>
                                    </center>

                                </Paper>


                                <br/>

                                <div className={classes.root}>
                                    <Grid container spacing={5}>
                                        <Grid container item xs={12} spacing={5}>
                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Customer General Info</h4>
                                                </center>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerGeneralInfoData()}


                                                </Paper>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerGeneralInfoData2()}


                                                </Paper>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Customer Contact Info</h4>
                                                </center>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerContactInfoData()}

                                                </Paper>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerContactInfoData2()}
                                                </Paper>

                                            </Grid>

                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Customer Business Relation</h4>
                                                </center>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerBusinessRelationData()}

                                                </Paper>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerBusinessRelationData2()}

                                                </Paper>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <center>

                                                    <h4 style={{color: "white"}} >Relationship Table</h4>
                                                </center>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Paper className={classes.paper}>
                                                    {this.renderCustomerRelationTableData()}


                                                </Paper>


                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(DemographicsView);