import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import FileTypeComponent from '../../JsonForm/FileTypeComponent';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import MyValidation from "../../JsonForm/MyValidation";
import BulkUpload from "./FileUpload";
import CBNotFound from "../CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import SelectComponent from "../../JsonForm/SelectComponent";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import ConfirmationModal from "./ConfirmationModal";
import SignatureButton from "../Maintenance/SignatureButton";
import GridContainer from "../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Hard copy missing",
        "Amount mismatch",
        "BEFTN agreement",
        "Signature missing",
        "Mismatch in amount",
        "Wrong Routing No",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};
let CustomerServiceSearch = [
    {
        "label": "Request Type",
        "type": "select",
        "varName": "requestType",
        "enum": [
            "Single Transaction",
            "Bulk Transaction"
        ],
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
        "required": true,
    },
    {
        varName: "accountNumber",
        type: "textDedup",
        label: "Account Number",
        grid: 2,
        errorMessage: "Error",
        required: true,
        validation: "numeric"
    },
];
let CustomerServiceJsonFormOne = [
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 2,
        "readOnly": true,
        "multiline": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "maker",
    },

    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "grid": 2,
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "conditionalOrValue": true,
        "conditionalVarName": "transferType",
        "conditionalOrList": ["RTGS", "BEFTN", "Salary"],
        "required": true,
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
];
let CustomerServiceJsonFormForBranch = [
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 2,
        "readOnly": true,
        "multiline": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
        ],
        "onKeyDown": true,
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "maker",
    },
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "transferType",
        "conditionalOrList": ["RTGS", "BEFTN", "Salary"],
        "required": true,
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "label": "Beneficiary Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 2,
        "validation": "beneficiaryAccount",
        "required": true,
    },
    {
        "label": "Beneficiary Name",
        "type": "text",
        "varName": "beneficiaryDetails",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "label": "Beneficiary Bank Name",
        "type": "select",
        "enum": [],
        "varName": "beneficiaryBankName",
        "grid": 4,
        "onKeyDown": true,
        "required": true,
    },
    {
        "label": "Branch Name",
        "type": "select",
        "onKeyDown": true,
        "enum": [],
        "varName": "branchName",
        "grid": 4,
        "required": true,
    },
    {
        "label": "Routing Number",
        "type": "text",
        "varName": "routingNumber",
        "grid": 4,
        "required": true,
        "readOnly": true,
        // "validation": "numeric"
    },
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];


const documentType = [

    {label: 'Page 1'},
    {label: 'Page 2'},
    {label: 'Page 3'},
    {label: 'Page 4'},
    {label: 'Page 5'},
    {label: 'Page 6'},
    {label: 'Page 7'},
    {label: 'Page 8'},
    {label: 'Page 9'},
    {label: 'Page 10'},
    {label: 'Page 11'},
    {label: 'Page 12'},
    {label: 'Page 13'},
    {label: 'Page 14'},
    {label: 'Page 15'},
    {label: 'Page 16'},
    {label: 'Page 17'},
    {label: 'Page 18'},
    {label: 'Page 19'},
    {label: 'Page 20'},

];

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".xlsx,.xls,.exl,xlsb,xlxb",
    "label": "Upload Excel",
    "fileType": "multiple",
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class MakerCustomerServiceInitialized extends Component {
    constructor(props) {
        super(props);
        this.state = this.initialState;
        this.handleSubmit = this.handleSubmit.bind(this);


    }

    get initialState() {
        return {
            varValue: {},
            inputData: {
                requisitionType: this.props.match.params.enduser
            },
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: false,
            appId: 0,
            getMappingAllImage: false,
            uploadModal: false,
            preview: false,
            bulkUpload: false,
            signature: false,
            submit: false,
            amountNotApplicable: false,
            endPoint: this.props.match.params.enduser,
            selectedDate: {},
            values: [],
            fileUploadData: {},
            loading: true,
            cbNotFound: false,
            getRemarks: [],
            PreviewFile: false,
            disabled: false,
            confirmation: false,
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {

        let url = backEndServerURL + "/startCase/maker_fund_transfer_data_capture";

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                // if (this.state.endPoint === "maker") {
                //     this.getBankName()
                // }

                this.setState({
                    appId: response.data.id,
                    loading: false
                });
                console.log(this.state.inputData.requisitionType)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    getBankName = () => {
        let url = backEndServerURL + "/finMasterData/custom/otherBanksWithTransfer/" + this.state.inputData.transferType;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let bankName = [];
                    for (let bank of response.data) {
                        bankName.push(bank.value)
                    }
                    for (let field of CustomerServiceJsonFormForBranch) {
                        if (field.varName === "beneficiaryBankName") {
                            field.enum = bankName
                        }
                    }
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.enduser !== prevProps.match.params.enduser) {
            this.setState(this.initialState);
            // if (this.props.match.params.enduser === "maker") {
            //     this.getBankName()
            // }
            let url = backEndServerURL + "/startCase/maker_fund_transfer_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.requisitionType = this.props.match.params.enduser;
                    this.setState({
                        endPoint: this.props.match.params.enduser,
                        appId: response.data.id,
                        loading: false
                    });
                    console.log(this.state.inputData.requisitionType)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }


    renderRemarks = () => {
        if (this.state.getData) {

            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)}
                </ThemeProvider>
            )
        }
    };


    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event);

        }

    };

    renderSubmitButton = () => {
        if (this.state.submit) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        backgroundColor: 'red',
                        color: '#ffffff',
                        verticalAlign: 'left'
                    }}
                    disabled={this.state.disabled}
                    onClick={this.handleSubmit}
                >
                    Submit
                </button>
            )
        }
    };

    submitModal = (data) => {
        if (data === "SUBMIT") {
            this.setState({
                PreviewFile: true
            });
            this.forceUpdate()
        }
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };

    bulkUpload = () => {

        return (
            <BulkUpload appId={this.state.appId}
                        name="AttachmentsOne"
                        closeModal={this.closeExcelUpload}/>
        )

    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    customerServiceForm = () => {

        return (
            <ThemeProvider theme={theme}>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceSearch, this.updateComponent, this.onKeyDownForDedup)
                }
                <Grid item xs={8}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginTop: '15px',
                        }}
                        onClick={this.searchHandler}
                        type="submit"

                    >
                        Search
                    </button>
                </Grid>
                <Grid item xs={12}>

                </Grid>
            </ThemeProvider>
        )


    };

    searchHandler = (e) => {
        e.preventDefault();

        let error = MyValidation.defaultValidation(CustomerServiceSearch, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                err: false,
                errorArray: {},
                errorMessages: {},
            });
            let inputData = this.state.inputData;
            FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((response) => {
                inputData.uploadFileAlertRequest = true;
                inputData.cbNumber = response.data.cifId;
                inputData.customerName = response.data.acctName;
                inputData.currency = response.data.crncyCode;
                inputData.solId = response.data.solId;
                inputData.showAccountBalance = response.data.schmCode !== "SBSTF";
                inputData.accountBalance = response.data.acctEftvBalance;
                inputData.transferType = null;
                inputData.customerType = "SME/Corporate";
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false,
                    getData: true,
                    searchValue: true,
                    submit: true,
                    showValue: true
                });

            }).catch((error) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    showValue: true,
                    title: error.response.data.message
                });
            });
        }

    };


    functionForGettingRoutingNumber = (data) => {
        if (data.varName === "transferType") {
            if (this.state.endPoint === "maker") {
                this.setState({
                    loading: true
                });
                this.getBankName()
            }
        } else {
            if (this.state.endPoint === "maker") {
                this.setState({
                    loading: true
                });

                var url = backEndServerURL + "/finMasterData/custom/otherBranchesWithTransfer/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.transferType;
                let inputData = this.state.inputData;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (response.status === 200) {
                            let branchName = [];
                            for (let item of response.data) {
                                branchName.push(item.value)
                            }
                            for (let field of CustomerServiceJsonFormForBranch) {
                                if (field.varName === "branchName") {
                                    field.enum = branchName
                                }
                            }
                            if (this.state.inputData.beneficiaryBankName && this.state.inputData.branchName) {
                                let routingUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.branchName;

                                axios.get(routingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        if (response.status === 200) {
                                            inputData.routingNumber = response.data.toString();

                                            this.setState({
                                                inputData: inputData,
                                                varValue: inputData,
                                                loading: false
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            inputData: inputData,
                                            varValue: inputData,
                                            loading: false
                                        });
                                        console.log(error)
                                    });
                            } else {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                });
                            }
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            loading: false
                        });
                        console.log(error)
                    });
                this.forceUpdate();
            }
        }
    };


    amountConvert = (event, data) => {
        this.setState({
            loading: true
        });
        this.state.inputData.showAmountConvertField = false;
        if (data === "transferAmount") {
            let form = [
                {
                    "varName": "transferAmount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                let inputData = this.state.inputData;
                let amount = this.state.inputData.transferAmount.split(".");

                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = this.state.inputData.transferAmount;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            this.state.inputData.showAmountConvertField = true;
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        });
                }
            }
        }
    };

    renderForm = () => {
        if (this.state.endPoint === "maker") {
            return CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceJsonFormForBranch, this.updateComponent, this.amountConvert, this.functionForGettingRoutingNumber)
        } else {
            return CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceJsonFormOne, this.updateComponent, this.amountConvert)
        }
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };

    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}

                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }


    renderAddButtonShow = () => {
        if (this.props.match.params.enduser !== "maker") {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        width: 100,
                        float: 'left',
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.addClick.bind(this)}


                >Add Deferral</button>
            )
        }
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["csDeferal"] = "YES";
    }

    dynamicDeferral = (i) => {

        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    customerInformation = () => {
        if (this.state.searchValue) {
            return (
                <ThemeProvider theme={theme}>

                    {
                        this.renderForm()
                    }

                    <Grid item xs={12}>
                        {this.signature()}
                    </Grid>

                    <Grid item xs='12'>

                    </Grid>

                    {
                        this.renderAddButtonShow()
                    }


                    <Grid item xs='12'>

                    </Grid>
                    {
                        this.addDeferalForm()
                    }
                    <Grid item xs={12}></Grid>

                    {
                        this.customerDocumentType()
                    }


                </ThemeProvider>
            )
        }
    };


    excelUpload = () => {
        this.setState({
            bulkUpload: true
        })
    };

    closeExcelUpload = () => {
        this.setState({
            bulkUpload: false
        })
    };


    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent indexList={documentType} previewImage={this.previewImage}
                                         appId={this.state.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };


    customerDocumentType = () => {
        if (this.state.inputData.transferType) {
            return (
                <ThemeProvider theme={theme}>

                    <Grid item xs={12}>
                        {this.uploadFunction()}
                    </Grid>

                    <Grid item xs={12}>

                    </Grid>

                    {this.state.inputData.requisitionType !== "maker" && FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}

                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            disabled: true,
        });

        if (this.state.inputData.currency !== "BDT") {
            this.setState({
                loading: false,
                cbNotFound: true,
                disabled: false,
                title: 'Foreign Currency will not be execute!'
            });
            return 0;
        } else {
            let error = false;
            let erroFileType = false;
            if (this.state.endPoint === "maker") {
                erroFileType = MyValidation.fileValidation(fileUpload, this.state);
                error = MyValidation.defaultValidation(CustomerServiceJsonFormForBranch, this.state);
                this.forceUpdate();
            } else {
                erroFileType = MyValidation.fileValidation(fileUpload, this.state);
                error = MyValidation.defaultValidation(CustomerServiceJsonFormOne, this.state);
                this.forceUpdate();
            }

            var amountNotApplicable = false;

            if (this.state.inputData.transferType === "RTGS" && parseInt(this.state.inputData.transferAmount) < 100000) {
                amountNotApplicable = true;
                this.setState({
                    cbNotFound: true,
                    disabled: false,
                    title: "Minimum RTGS Payment is 1 Lac"
                });
            }
            this.forceUpdate();
            if (error === true || erroFileType === true || amountNotApplicable === true) {
                this.setState({
                    disabled: false,
                });
                return 0;
            } else {
                if ((this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN") && parseInt(this.state.inputData.transferAmount) >= 50000000) {
                    this.setState({
                        disabled: false,
                        title: "For payment of Tk. 5 crore and above, please take approval from Treasury Division.Did you take the approval ?",
                        confirmation: true,
                    });
                } else if (this.state.inputData.transferType === "RTGS") {
                    this.setState({
                        title: "Charge and VAT applicable as per Schedule of Charges",
                        confirmation: true,
                    })
                } else {
                    this.functionForRoute();
                }
            }

        }
    };

    functionForRoute = () => {
        if (this.state.inputData.requisitionType === "maker") {
            this.state.inputData.requestType = "Single Transaction"
        }
        if (this.state.inputData.uploadFileAlertRequest && this.state.inputData.previewImage === undefined) {
            this.setState({
                disabled: false,
                cbNotFound: true,
                title: "Please Upload File!"
            })
        } else if (this.state.fileUploadData.scanningFile === undefined && this.state.inputData.requestType === "Bulk Transaction") {
            this.setState({
                title: "Excel file required for Bulk Transaction",
                disabled: false,
                cbNotFound: true,
            })
        } else {

            let formData = new FormData();
            if (this.state.fileUploadData.scanningFile !== undefined) {
                this.state.fileUploadData.scanningFile.map((item, index) => {
                    formData.append("file", item);
                    formData.append("type", "fundTransfer-cs-attachment")
                });
                let fileUploadPath = backEndServerURL + "/case/upload";
                formData.append("appId", this.state.appId);
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {

                    })
                    .catch((error) => {
                        this.setState({
                            disabled: false,
                        });
                        console.log(error)
                    })
            }

            if (this.state.inputData["csDeferal"] === "YES") {
                var defType = [];
                var expDate = [];

                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let deferalType = this.state.inputData["deferalType" + value];
                    if (deferalType === "other") {
                        deferalType = this.state.inputData["deferalOther" + value];
                    }
                    defType.push(deferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);

                }
                let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                axios.post(deferalRaisedUrl, {
                    appId: appId,
                    type: defType,
                    dueDate: expDate
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log("deferral created")
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }

            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                    });
                    console.log(error)
                });
            this.state.inputData.csRemarks = undefined;

            var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

            let postData = this.state.inputData;
            postData.serviceType = "FundTransfer";
            postData.category = this.state.inputData.transferType;
            postData.subCategory = this.state.inputData.requestType;
            postData.subServiceType = "NewFundTransfer";
            postData.cs_deferal = this.state.inputData["csDeferal"] === "YES" ? "YES" : "NO";
            if (this.state.inputData.transferType === "BEFTN" && this.state.inputData.customerType === "Retail") {
                postData.department = "SD"
            } else {
                postData.department = "OPSD"
            }
            if (this.state.endPoint === "maker") {
                postData.branchEnd = true
            }
             postData.freeFlag1="Fund Transfer SD End Initilized";
            axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    var url = backEndServerURL + "/case/route/" + this.state.appId;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    searchValue: false,
                                    getData: false,
                                    confirmation: false,
                                    title: "Successful!",
                                    disabled: false,
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                });

                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000);
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                            });
                        });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                    });
                });
        }

    };

    confirmationClose = (value) => {
        if (value === "ok") {
            this.functionForRoute()
        } else {
            this.setState({
                disabled: false,
                confirmation: false
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes, onClose} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.searchValue) {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Fund Transfer</h4>
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>


                                    {
                                        this.customerServiceForm()
                                    }

                                    <br/>
                                    <br/>
                                    {
                                        this.customerInformation()
                                    }

                                    {
                                        this.renderRemarks()
                                    }


                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.cbNotFound}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <CBNotFound
                                                    closeModal={this.closeModalCBNotFound}
                                                    title={this.state.title}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            open={this.state.confirmation}>
                                            <DialogContent>
                                                <ConfirmationModal closeModal={this.confirmationClose}
                                                                   title={this.state.title}/>
                                            </DialogContent>
                                        </Dialog>
                                    </ThemeProvider>
                                    {
                                        this.renderNotification()
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                            {
                                this.renderSubmitButton()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Fund Transfer</h4>
                        </CardHeader>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {
                                        this.customerServiceForm()
                                    }
                                    {
                                        this.renderNotification()
                                    }
                                </Grid>
                            </Grid>
                            <br/>
                        </CardBody>
                    </Card>
                </React.Fragment>
            )
        }
    }

}

export default withStyles(styles)(MakerCustomerServiceInitialized);
