import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../Grid/GridContainer";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class ImageLinkReadOnly extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        loader: null,
        inputData: {},
        dropdownSearchData: {},
        autoComplete: {},
        showValue: true,
        varValue: {},
        getData: false,
        getImageLink: [],
        errorMessages: {},
        errorArray: {},
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {

        this.props.closeModal();
    };

    componentDidMount() {

        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    getSplitFile: response.data,
                    getImageLink: response.data,
                    loader: false,
                    getData: true
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({

                    loader: false,

                })
            })

    }


    renderDataShow = (data, index) => {

        if (this.state.getData) {

            return (
                <Grid item xs={4}>

                    <h4 style={{color: "white"}} >{data.split(".")[0].split("_")[1]}</h4>
                    <img width='90%'
                         src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")} alt=""/>

                    <br/>
                </Grid>


            )


        }

    };

    renderloader = () => {


        if (this.state.loader === false) {
            return (
                <Grid container spacing={1}>
                    {
                        this.state.getSplitFile.map((data, index) => {
                            //console.log(index)
                            return (

                                this.renderDataShow(data, index)


                            )

                        })

                    }
                </Grid>
            )
        }

    };

    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <h4 style={{color: "white"}} >Related Document<a><CloseIcon onClick={this.close}
                                                              style={{
                                                                  position: 'absolute',
                                                                  right: 10,
                                                                  color: "#000000"
                                                              }}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <br/>

                            <ThemeProvider theme={theme}>
                                <div>

                                    <Grid container spacing={3}>
                                        {this.renderloader()}
                                    </Grid>
                                </div>


                            </ThemeProvider>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(ImageLinkReadOnly);