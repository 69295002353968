import Table from "../../Table/Table.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import "antd/dist/antd.css";
import Functions from "../../../Common/Functions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    dialogPaper: {
        overflow: "visible",
    },
};

class FileRequisition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addFileRequisition: false,
            editFileRequisition: false,
            FileRequisitionhName: "",
            open: true,
            fileRequisitionId: "",
            tableData: [[" ", " "]],
            fileRequisitionData: [[" ", " "]],
            redirectLogin: false,
        };
    }

    addBranch() {
        this.setState({
            addFileRequisition: true,
        });
    }

    editBranch = (id) => {
        this.setState({
            editFileRequisition: true,
            fileRequisitionId: id,
        });
    };

    cancelBranchModal = () => {
        this.setState({addFileRequisition: false});
    };
    cancelEditBranchModal = () => {
        this.setState({editFileRequisition: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    closeModal = () => {
        this.setState({
            addFileRequisition: false,
            editFileRequisition: false,
        });
    };
    createTableData = (
        id,
        fileReferenceNo,
        requisitionBy,
        requisitionDate,
        sentBy,
        sentDate,
        receivedBy,
        receivedDate,
        returnedBy,
        returnedDate,
        returnReceivedBy,
        returnReceivedDate,
        status
    ) => {
        return [

            fileReferenceNo,
            requisitionBy,
            requisitionDate,
            sentBy,
            sentDate,
            receivedBy,
            receivedDate,
            returnedBy,
            returnedDate,
            returnReceivedBy,
            returnReceivedDate,
            status,
        ];
    };

    componentDidMount() {
        let url = backEndServerURL + "/fileRequisition/getAll";
        let getAllFileRequisition = [];
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
            .then((response) => {
                console.log(response);
                response.data.map((fileRequisition) => {
                    getAllFileRequisition.push(
                        this.createTableData(
                            fileRequisition.id,
                            fileRequisition.fileReferenceNo,
                            fileRequisition.requisitionBy,
                            fileRequisition.requisitionDate,
                            fileRequisition.sentBy,
                            fileRequisition.sentDate,
                            fileRequisition.receivedBy,
                            fileRequisition.receivedDate,
                            fileRequisition.returnedBy,
                            fileRequisition.returnedDate,
                            fileRequisition.returnReceivedBy,
                            fileRequisition.returnReceivedDate,
                            fileRequisition.status,
                        )
                    );
                });
                this.setState({
                    fileRequisitionData: getAllFileRequisition,
                });
            })
            .catch((error) => {
                console.log(error);

            });
    }

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state);
        }
        return (
            <section>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>
                                    File Requisition List
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={[
                                            "File Reference No.",
                                            "RequisitionBy",
                                            "Requisition Date",
                                            "Sent By",
                                            "Sent Date",
                                            "Received By",
                                            "Received Date",
                                            "Returned By",
                                            "Returned Date",
                                            "ReturnReceived By",
                                            "Return Received Date",
                                            "Status",
                                        ]}
                                        tableData={this.state.fileRequisitionData}
                                        tableAllign={[
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                            "left",
                                        ]}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(FileRequisition);
