import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import VerifyMerchantPhoto from './VerifyMerchantPhoto'
import SplitApproval from './splitApprovalForGatePass'
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";


let mouRemarks = [
    {
        "varName": "mouRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class GatePassApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            //showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            hombuRemarks: false,
            getRemarkList: [],
            getDocument: false,
            documentList: [],
            loading: true
        }
    }

    modalClose = () => {
        this.props.closeModal();
    };

    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.mouRemarks = null;
                this.setState({
                    varValue: response.data,
                    inputData: response.data,
                    getData: true,
                    loading: false
                });

                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            documentList: response.data,
                            getDocument: true
                        })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    inboxCasePhoto = () => {
        if (this.state.getDocument) {
            return (<VerifyMerchantPhoto closeModal={this.props.closeModal}
                                         titleName="Mou Approval"
                                         documentList={this.state.documentList}
                                         appId={this.props.appId}
            />)
        }

    };

    inboxCase = () => {
        return (<SplitApproval closeModal={this.props.closeModal}
                               taskTitle={this.props.taskTitle}
                               titleName="Mou Approval"
                               documentList={this.state.documentList}
                               appId={this.props.appId} form={this.renderFormWithData}/>)
    };


    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (
                <Grid container spacing={0}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={0}>
                                        <ThemeProvider theme={theme}>

                                            <Grid item xs={8}>

                                                {this.inboxCasePhoto()}

                                            </Grid>


                                            <Grid item xs={4}>
                                                <div>
                                                    {
                                                        this.inboxCase()
                                                    }
                                                </div>
                                            </Grid>

                                        </ThemeProvider>
                                    </Grid>
                                </div>

                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            );
        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }
}

export default withStyles(styles)(GatePassApproval);