import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Functions from '../../Common/Functions';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class SignatureCardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            signatureCardName: [],
            getData: false,
            imageUrl: "",

            accountDataFlag: null,
            redirectLogin: false,
            loaderModal: true,


        }
    }

    setLoader = () => {
        this.setState({
            loaderModal: false
        })
    };
    renderLoader = (event) => {
        if (this.state.loaderModal === true) {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)
        }
    };

    renderSignatureCard = () => {
        return (
            <React.Fragment>
                <img onLoad={(event) => this.setLoader(event)}
                     src={backEndServerURL + "/intgr/signature/" + this.props.ACCOUNTNO + "/" + sessionStorage.getItem("accessToken")}
                     alt=""/>
                <div> {this.renderLoader()}</div>
            </React.Fragment>


        )


    };

    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                    <h4 style={{color: "white"}} >Signature Card<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loaderModal}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        {this.renderSignatureCard()}

                                                    </Paper>

                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(SignatureCardView);
