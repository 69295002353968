import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation"
import UploadComponent from "../Maintenance/casa/UploadComponent";
import CBNotFound from "../CASA/CBNotFound";
import {LockerJsonForm} from "../WorkflowJsonFormRashed"
import ErrorModal from "../CommonComponent/ErrorModal";


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];


var lockerNumber = [
    {
        "varName": "availableLockerNo",
        "type": "select",
        "required": true,
        "label": "Available Locker No.",
        "enum": [],
        "grid": 2
    }
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


class OpeningCSLocker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            loading: true,
            getData: false,
            getNewCase: false,
            varValue: {},
            cbNotFound: false,
            errorLockerSize: false,
            requiredMessage: "",
            lockerSizeError: false,
            lockerSizeErrorMessage: "",
            caseId: "",
            title: "",
            successbloo: false,
            popUpNotificationMessage: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            lockerSelect: false,
            dueDate: '',
            inputData: {
                csDeferal: "NO",
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            conditionalRender: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            getAvailiableLocker: [],
            nomineeNumber: 0,
            getNominee: false,
            objectForNominee: [],


        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };

    DedupDataSaveApi = (subServiceType) => {
        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data" + subServiceType);
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Joint Account") {

            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {


        if (this.props.appId !== undefined) {
            var remarksArray = [];
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);

                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                    response.data.map((data) => {
                        if (data.remarks !== 'undefined') {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        }
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                    .catch((error) => {
                        console.log(error)
                    });
                console.log(response.data);
                let varValue = response.data;
                this.setState({
                    getData: true,
                    varValue: varValue,
                    appData: this.getSearchvalue(response.data),
                    inputData: this.getSearchvalue(response.data),
                    showValue: true,
                    // caseId : response.data.caseId,
                    appId: this.props.appId,
                    lockerSelect: true,
                    loading: false,
                    loaderNeeded: true,
                    conditionalRender: true,
                });
            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();
                        this.setState({
                            redirectLogin: true
                        })
                    }
                });
        } else {

            let url = backEndServerURL + "/startCase/cs_data_capture";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                {
                    this.DedupDataSaveApi(this.props.subServiceType)
                }
                this.setState({
                    appId: response.data.id,
                    caseId: response.data.caseId,
                    appData: response.data.inputData,
                    getNewCase: true,
                    loaderNeeded: true
                });
            })
                .catch((error) => {
                    console.log(error);
                });


            if (this.props.selected !== undefined) {
                let SelectedCBurl = backEndServerURL + "/cbsCustomerGet/" + this.props.selected;
                axios.get(SelectedCBurl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                    this.state.varValue.accountNumber = this.props.accountNumber;
                    this.state.inputData.accountNumber = this.props.accountNumber;

                    this.state.inputData["applicantName"] = response.data.customerName;
                    this.state.varValue["applicantName"] = response.data.customerName;

                    this.state.inputData["communicationAddress"] = response.data.address1;
                    this.state.varValue["communicationAddress"] = response.data.address1;


                    this.setState({
                        loading: false,
                        showValue: true,
                        getData: true,
                        conditionalRender: true,
                    });

                }).catch((error) => {
                    console.log(error);
                });
            } else {
                let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.props.accountNumber;
                axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.state.varValue.accountNumber = this.props.accountNumber;
                        this.state.inputData.accountNumber = this.props.accountNumber;

                        this.state.inputData["applicantName"] = response.data.customerName;
                        this.state.varValue["applicantName"] = response.data.customerName;

                        this.state.inputData["communicationAddress"] = response.data.address;
                        this.state.varValue["communicationAddress"] = response.data.address;
                        this.setState({
                            loading: false,
                            showValue: true,
                            getData: true,
                            conditionalRender: true
                        });


                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

        }

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, LockerJsonForm, this.updateComponent, "", this.handleChange)}

                </>
            )
        }
    };

    handleChange = (data) => {
        if (data) {
            this.state.inputData.showLockerNumber = false;
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/getAvailableLocker/" + this.state.inputData.lockerSize;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of LockerJsonForm) {
                    if (item.varName === "availableLockerNo") {
                        item.enum = response.data
                    }
                }
                this.state.inputData.showLockerNumber = true;
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
        }
    };


    closeSuccessModal = () => {
        this.setState({
            successbloo: false,
            title: "Successful!",
            alert: true,
            notificationMessage: "Successfully Routed!",
        });
        this.props.closeModal();
    };


    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.successbloo}>
                    <DialogContent>
                        <ErrorModal title={this.state.popUpNotificationMessage} closeModal={this.closeSuccessModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.forceUpdate();
        let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0
        } else {
            this.setState({
                loading: true
            });

            let acNumber = this.props.accountNumber ? this.props.accountNumber : this.state.inputData.accountNumber;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + acNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {

                        var lockerStatusGetUrl = backEndServerURL + "/getLockerStatus/" + this.state.inputData.availableLockerNo;
                        axios.get(lockerStatusGetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                            if (response.data !== "REQUEST_IN_PROGRESS") {

                                this.setState({
                                    loading: false
                                });

                                let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
                                axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {

                                        if (response.data.length > 0) {
                                            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
                                            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                                console.log(response.data)
                                            })
                                                .catch((error) => {
                                                    console.log(error)
                                                });
                                            var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

                                            let data = this.state.inputData;
                                            data.csRemarks = undefined;
                                            data.cs_deferal = this.state.inputData["csDeferal"];
                                            data.serviceType = this.props.serviceType;
                                            data.individualDedupData = this.state.individualDataSaveId;
                                            data.jointDedupData = this.state.jointDataSaveId;
                                            data.subServiceType = this.props.subServiceType;
                                            data.category = 'Locker';
                                            if (this.props.subServiceType === "Joint Account") {
                                                data.subCategory = 'Opening Joint';
                                            } else {
                                                data.subCategory = 'Opening Individual';
                                            }
                                            data.customerName = this.state.inputData["applicantName"];
                                            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                                var lockerStatusUrl = backEndServerURL + "/changeLockerStatus/" + this.state.inputData.availableLockerNo + "/REQUEST_IN_PROGRESS";
                                                axios.get(lockerStatusUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                                                    var url = backEndServerURL + "/case/route/" + this.state.appId;
                                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {


                                                        if (this.props.appId !== undefined) {
                                                            {
                                                                this.close();
                                                            }
                                                            this.setState({
                                                                popUpNotificationMessage: "Reference Number : " + this.state.caseId,
                                                                successbloo: true,
                                                            })
                                                        } else {
                                                            this.setState({
                                                                popUpNotificationMessage: "Reference Number : " + this.state.caseId,
                                                                successbloo: true,
                                                            })
                                                        }

                                                    })

                                                        .catch((error) => {
                                                            console.log(error);
                                                        });
                                                })

                                                    .catch((error) => {
                                                        console.log(error);
                                                    });

                                            })
                                                .catch((error) => {
                                                    console.log(error)
                                                });

                                        } else {
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: 'File Missing!'
                                            });
                                        }

                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    })
                            } else {
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: "Pending Request Found"
                                });
                            }

                        }).catch((error) => {
                            console.log(error);
                        });
                    }

                })
                .catch((error) => {
                    console.log(error)
                })


        }

    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };


    close = () => {
        this.props.closeModal();
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.state.appId} classes={classes}/>
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


    render() {

        const {classes} = this.props;
        if (this.state.loading && !this.state.getData) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.showValue && this.state.getData && this.state.conditionalRender) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Locker Opening
                            <a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={1}>
                                    {this.renderNotification()}
                                    {this.returnJsonForm()}
                                    <Grid item xs={12}>
                                        {
                                            this.uploadFunction()
                                        }
                                    </Grid>
                                    <Grid item xs='12'>
                                        {this.renderRemarks()}
                                    </Grid>
                                    {this.dialogBoxShow()}
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <Grid item xs={12}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.handleSubmit}
                                        >
                                            Submit
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {(this.state.inputData.maker_update_all_info_send_to === "CS" || this.state.inputData.bom_approval === "RETURN") &&
                                        <button
                                            className="btn btn-outline-info btn-sm"
                                            onClick={this.handleClose}
                                        >
                                            Close
                                        </button>}
                                    </Grid>
                                </Grid>
                            </ThemeProvider>
                        </div>
                    </CardBody>
                </Card>
            );
        } else {
            return ""
        }
    }

}

export default withStyles(styles)(OpeningCSLocker);
