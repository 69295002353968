import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label"
import PreviewButton from "../PreviewButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

let newNominee = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "readOnly": true,
        "grid": 12
    },
    {
        "varName": "numberOfNominee",
        "type": "text",
        "label": "Number of New Nominee",
        "readOnly": true,
        "grid": 2
    }]
;

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "required": true,
        "grid": 12
    }]
;

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BMApproval2 extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: true,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false,
        submitFlag: "",
        confirmationAlert: false
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }

                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                            console.log(response.data);
                                            response.data.map((data) => {

                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getRemarks: remarksArray
                    });
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appData: response.data,
                        // loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);

                });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });

            this.state.inputData.returnDataBm = true;
            var appId = this.props.appId;
            var approval = this.state.submitFlag;
            this.state.submitFlag === "APPROVED" ? approval = "APPROVE" : approval = "REJECT";
            let deferalUrl = backEndServerURL + "/deferral/approval";
            axios.post(deferalUrl, {
                appId,
                approval
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false
                    })
                });

            this.state.inputData.bm_approval = this.state.submitFlag;
            if (this.state.submitFlag === "APPROVED") {
                this.state.inputData.fdr_maintenance_send_to_bm = "";
                this.state.inputData.already_approved_by_bm = "YES"
            }
            this.state.inputData.cs_deferal = "NO";
            this.state.inputData.csDeferal = "NO";

            let remarksData = {};
            remarksData.remark = this.state.inputData.bmRemarks;
            remarksData.map = {...this.state.inputData, bmRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false,
                    disabled: false
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    disabled: false
                })
            });
        } else {
            this.setState({
                confirmationAlert: false,
                disabled: false
            })
        }
    };

    handleSubmit = (event, data) => {
        event.preventDefault();

        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bmRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                this.setState({
                    submitFlag: data,
                    title: "Do you want to confirm?",
                    confirmationAlert: true
                })
            }
        } else {
            this.setState({
                submitFlag: data,
                title: "Do you want to confirm?",
                confirmationAlert: true
            })
        }
    };


    renderForm = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
                    }
                </React.Fragment>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </Grid>


            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };


    previewButton = () => {
        if (this.state.inputData.subCategory) {
            return (
                <React.Fragment>
                    <br/>
                    <Grid container spacing={0}>
                        <PreviewButton appId={this.props.appId} classes={this.props}/>
                    </Grid>
                    <br/>
                </React.Fragment>
            )
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.subCategory === "FD Nominee Change") {
            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "exNominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 2,
                        "multiline": true,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "expercentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 2,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["subCategory", "exnomineeMinor" + sl,],
                        "conditionalArrayValue": ["FD Nominee Change", "Yes"],
                    },
                )
            }
            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
                    }
                </>
            )

        }
    };


    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.getData) {
            return <Label cbNumber={this.state.inputData.cbNumber} subCategory={this.state.inputData.subCategory}
                          stopLoading={this.stopLoading} accountNumber={this.state.inputData.accountNumber}
                          classes={this.props}/>
        }
    };

    renderMultiTaskLink = () => {
        if (this.state.inputData.subCategory === "Dupl. Advice (Special)") {
            return (
                <>
                    <br/>
                    <button className="btn btn-outline-primary btn-sm">
                        <a
                            className="anchorButton"
                            target="_blank"
                            href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                            Multitask Link</a>
                    </button>
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Grid container spacing={0}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmation}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        {this.renderCustomerDetails()}
                        {this.renderNotification()}
                        <Grid item xs={12}>
                            {this.previewButton()}
                        </Grid>
                        {this.renderForm()}
                        {this.renderExistingNomineeForm()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderMultiTaskLink()}
                    </Grid>
                    <Grid item={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    <Grid item xs={12}><br/>{this.viewAttachedImages()}</Grid>
                    {this.renderRemarks()}
                    {this.renderSubmitButton()}
                </ThemeProvider>
            </div>
        )
    }

}

export default withStyles(styles)(BMApproval2);
