import React, {Component} from "react";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Notification from "../../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import TableComponent from "../CommonComponent/TableComponent";
import GridItem from "../../Grid/GridItem";
import {auxiliaryMappingTaskInbox, auxiliaryTaskInbox} from "../ColumnNameForInbox";
import Button from "@material/react-button";
import {commonInboxButton} from "../../DeliverableManagement/configuration";

let Form = [

    {
        varName: "requestType",
        type: "text",
        label: "Request Type",
        grid: 2,

    },
];

let validation = [

    {
        varName: "requestType",
        type: "text",
        label: "Request Type",
        grid: 2,
        required:true

    },
];

let branchAndBakeOffice = [

];

let workStationRequestType = [];
let workplaceNames = [];
let bo = [ ];
let roleRequestType = [];
let otherRequestType = [];
let workplaceUnits = [];

class AddMapping extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        type: "success",
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        title: "",
        notificationMessage: "",
        alert: false,
        otherRequestTitle: "",
        loading: false,
        getWorkplaceUnits: false,
        getWorkplaceNames: false,
        id: "",
        requestType: "",
        createdDate: "",
        createdBy: "",
        modifiedDate: "",
        modifiedBy: "",
        getAuxiliaryTaskStatus: "",
        action:"",
        page: 0,
        totalRow: 0,
        tableData: [],
        showTable: false,
        rowsPerPage: 50,
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let data = {};
        this.setState({
            loading: true
        });


        let workplaceTypeArray = [
            {
                varName: "roleId",
                type: "text",
                required: true
            },

        ];

        console.log(this.state.inputData);
        let dependencyField = validation.concat(workplaceTypeArray);
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");
        if (error === true || this.state.inputData.roleId===undefined ) {
            this.setState({
                loading: false
            });
            return 0;
        }
        console.log(this.state.inputData);

        data.requestType = this.state.inputData.requestType;
        data.role = this.state.inputData.roleId.value;
        data.status = 'ACTIVE';

        //   return 0;
        let url = backEndServerURL + "/auxiliaryTakMapping/add";
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    type: "success",
                    title: "Successfull!",
                    notificationMessage: "Successfully Added!",
                    alert: true,
                    loading: false
                });
               this.props.closeModal();


            })
            .catch((error) => {
                this.setState({
                    type: "Error",
                    title: "Request Type Should Be Unique!",
                    notificationMessage: error.massage,
                    alert: true,
                    loading: false
                });
                console.log(error)
            })
    };
    renderButton = () => {
        if (this.state.inputData.roleId!==undefined) {
            return (

                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{
                        marginTop: "18px"
                    }}
                    onClick={this.handleSubmit}

                >
                    Submit
                </button>


            )
        }


    };



    componentDidMount() {
        this.setState({
            loading: true,
        });


        this.handleChangWorkPlaceName();
        //this.fetchTableData(this.getApiUrl(), 0)


    }

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangWorkPlaceName = () => {
        this.state.inputData.roleId = null;
        this.state.inputData.workplaceUnits = null;
        this.setState({
            getWorkplaceUnits: false,
            workstation: false,
        });
        roleRequestType = [];
        workplaceUnits = [];

        this.updateComponent();
        let roleListUrl = backEndServerURL + "/role/get";
        axios.get(roleListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                Form= [

                    {
                        varName: "requestType",
                        type: "text",
                        label: "Request Type",
                        "multiline": true,
                        grid: 7,

                    },
                ];
                response.data.map((data) => {
                    roleRequestType.push({
                        value: data.authority,
                        key: data.id
                    })
                });
                Form.push(
                    {
                        varName: "roleId",
                        type: "autoCompleteValueReturn",
                        enum:roleRequestType,
                        label: "Request Receiver Role",
                        grid: 5,

                    },
                )
                this.setState({
                    getData: true,
                    workstation: true,
                    getWorkplaceNames: true,
                    loading: false,
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })

            })

    };

    handleChangRoll = (event, option) => {
        event.preventDefault();
        this.state.inputData.roleId = option;
    };
    handleChangBO = (event, option) => {
        event.preventDefault();
        console.log("workplaceUnitsByRole");
        console.log(option);
        this.state.inputData.workplaceNames = option;
        this.state.inputData.workplaceUnits = null;
        workplaceUnits = [];
        this.setState({
            getWorkplaceUnits: false,
        });
        if (option !== null) {
            let Url = backEndServerURL + "/workplaceUnit/getByBranchOrOps/" + option.title;

            axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    response.data.map((data) => {
                        workplaceUnits.push({
                            title: data.value,
                            key: data.key,
                        })
                    });

                    this.setState({
                        getWorkplaceUnits: true
                    });
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });

            this.setState({
                role: true,
            })

        }

    };


    renderRole = () => {
        if (this.state.workstation) {
            return (
                <React.Fragment>

                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">Role</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["roleId"]}
                                error={this.state.errorArray["roleId"]}
                                required={this.state.inputData.roleId}
                                id="tags-standard"
                                options={roleRequestType}
                                required={true}
                                onChange={(event, option) => this.handleChangRoll(event, option)}
                                getOptionLabel={option => option.title}
                                size="small"
                                renderInput={params => (

                                    <TextField

                                        {...params}
                                        variant="outlined"
                                        error={this.state.errorArray !== undefined && this.state.errorArray["roleId"]}
                                        helperText={this.state.errorArray["roleId"] === true ? this.state.errorMessages["roleId"] : ""}
                                        fullWidth
                                    />

                                )}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }


    };

    renderBO = () => {
        if (this.state.getWorkplaceNames) {
            return (
                <React.Fragment>

                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">Branch/Back Office</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["workplaceNames"]}
                                error={this.state.errorArray["workplaceNames"]}
                                required={this.state.inputData.workplaceNames}
                                id="tags-standard"
                                options={bo}
                                required={true}
                                onChange={(event, option) => this.handleChangBO(event, option)}
                                getOptionLabel={option => option.title}
                                size="small"
                                renderInput={params => (

                                    <TextField

                                        {...params}
                                        variant="outlined"
                                        error={this.state.errorArray !== undefined && this.state.errorArray["workplaceNames"]}
                                        helperText={this.state.errorArray["workplaceNames"] === true ? this.state.errorMessages["workplaceNames"] : ""}
                                        fullWidth
                                    />

                                )}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }


    };

    handleChangWorkPlaceUnit(event, option) {
        event.preventDefault();
        this.state.inputData.workplaceUnits = option;
        if (option != null) {
            console.log(option);

        }
    }

    renderWorkPlaceUnit = () => {
        if (this.state.getWorkplaceUnits) {
            return (
                <React.Fragment>

                    <Grid item xs={2}>

                        <Grid item>
                            <label className="input-label-common">WorkPlace Unit</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                helperText={this.state.errorMessages["workplaceUnits"]}
                                error={this.state.errorArray["workplaceUnits"]}
                                required={this.state.inputData.workplaceUnits}
                                id="tags-standard"
                                options={workplaceUnits}
                                required={true}
                                onChange={(event, option) => this.handleChangWorkPlaceUnit(event, option)}
                                getOptionLabel={option => option.title}
                                size="small"
                                renderInput={params => (

                                    <TextField

                                        {...params}
                                        variant="outlined"
                                        error={this.state.errorArray !== undefined && this.state.errorArray["workplaceUnits"]}
                                        helperText={this.state.errorArray["workplaceUnits"] === true ? this.state.errorMessages["workplaceUnits"] : ""}
                                        fullWidth
                                    />

                                )}
                            />

                        </Grid>

                    </Grid>

                </React.Fragment>

            )
        }


    };

    returnJsonForm = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, Form, this.updateComponent)
            )
        }
    };

    returnJsonFormBAO = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, branchAndBakeOffice, this.updateComponent)
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close=()=>{
        this.props.closeIcon();
    }
    renderHeader = () => {

            return (
                <h6>New Request Add<a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )

    };




    render() {
        return (

            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>

                        <ThemeProvider theme={theme}>

                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.loading}>
                                    <DialogContent>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                {this.renderNotification()}

                                {this.returnJsonForm()}

                                {/* {this.renderWorkPlaceNames()} */}
                                {/* <Grid item xs={1}>
                                </Grid> */}
                                {/*
                             {this.renderRole()}&nbsp;
*/}
                                {/*  {this.renderBO()}&nbsp;*/}
                                {this.renderWorkPlaceUnit()}&nbsp;&nbsp;&nbsp;&nbsp;

                                <Grid item xs={1}>
                                    {this.renderButton()}
                                </Grid>
                                <Grid item xs={12}>
                                    <br/>
                                </Grid>



                            </Grid>

                        </ThemeProvider>


                    </div>


                </CardBody>

            </Card>


        )
    }

}

export default AddMapping;