import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import DateComponent from "../../JsonForm/DateComponent";
import {merchantDstJsonForm} from '../WorkflowJsonFormRashed'
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "./MerchantWaiverList";
import ErrorModal from "../CommonComponent/ErrorModal";
import theme from "../../JsonForm/CustomeTheme";
import {ThemeProvider} from "@material-ui/styles";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import DedupFullResultButton from "../CommonComponent/DedupFullResultButton";


var agreementSignUpDate = {
    "varName": "agreementSignUpDate",
    "type": "date",
    "label": "Agreement Sign Up Date",
    "grid": 12
};


let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class splitMaker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inputData: {},
            varValue: {},
            showValue: false,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            sendTo: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            agreementSignUpDate: "",
            remarks: false,
            getRemarkList: [],
            disabled: false,
            errorModalBoolean: false,
            errorTittle: "Error in Getting Information",
            confirmAlert: false,
             actionType: ""
        };


    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    modalClose = () => {
        this.props.closeModal();
    };
    makeWithoutReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = false;
        }
        return returnObject;
    }
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = merchantDstJsonForm;
        let jsonArrayLast = merchantDstJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                merchantDstJsonForm[i].enum = getValue;

            }
        }
    }
    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/getMerchantMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (apiType === "merchantRegionCode") {
                    this.findByVarNameApiData("regionCode", response.data);
                    this.findByVarNameApiData("generalRegionCode", response.data);
                    this.findByVarNameApiData("contactsRegionCode", response.data);
                    this.findByVarNameApiData("additionalRegionCode", response.data);
                    this.findByVarNameApiData("regionCode", response.data);

                } else if (apiType === "merchantCardProsGrpup") {
                    this.findByVarNameApiData("acqGroup", response.data);

                } else if (apiType === "merchantCardCurrency") {
                    this.findByVarNameApiData("seSettleCCY", response.data);
                    this.findByVarNameApiData("submissionCcy", response.data);
                    this.findByVarNameApiData("floorLimitCCY", response.data);
                    this.findByVarNameApiData("settlementCurr", response.data);
                    this.findByVarNameApiData("settlementCurrency", response.data);
                    this.findByVarNameApiData("transitCurrency", response.data);
                    this.findByVarNameApiData("terminalCurrency", response.data);

                } else if (apiType === "merchantCardTypeCode") {
                    this.findByVarNameApiData("merchantCardTypeCode", response.data);

                } else if (apiType === "merchantFinProf") {
                    this.findByVarNameApiData("finacleProfile", response.data);

                } else if (apiType === "merchantType") {
                    this.findByVarNameApiData("merchantType", response.data);

                }
            })
    }
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        /*  if (varName === "autoPopulate" && this.state.inputData.autoPopulate===true){

              this.setState({
                  showValue: false,
                  loading: true,
                  getData:false
              });
              let inputData=this.state.inputData;

              //terminal
              inputData.terminalDbaName=inputData.dba;
              inputData.terminalType="P";
              inputData.timeOffset="0";
              inputData.terminalMcc=inputData.mcc;
              inputData.terminalAddress=inputData.physicalAddress;

              //Transit
              inputData.transitOneTireAct= "20";
              inputData.transitTwoTireAct= "20202";
              inputData.transitState= "0";
              inputData.transitName=inputData.fullName;
              inputData.transitComment=inputData.fullName;
              //SETTLEMENT ACCOUNT
              inputData.settlementOneTireAct="20";
              inputData.settlementTwoTireAct="20202";
              inputData.settlementState="0";
              inputData.settlementName=inputData.fullName;
              inputData.settlementComment=inputData.fullName;
              setTimeout(() => {
                  this.setState({
                      inputData:inputData,
                      varValue:this.emptyValueRemove(inputData),
                      showValue: true,
                      loading: false,
                      getData: true,
                  });
                  this.forceUpdate();
              },1000)

          }*/
        console.log("varName")
        console.log(varName)


    }

    componentDidMount() {
        /* let occupationCodeUrl = backEndServerURL + "/getAllMerchantMasterDataZipCode";
         axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
             .then((response) => {
                 console.log("lllllllllllllllllpppp");
                 console.log(response.data);
                 this.findByVarNameApiData("zipCode", response.data);
                 this.findByVarNameApiData("generalZipCode", response.data);
                 this.findByVarNameApiData("contactsZipCode", response.data);
                 this.findByVarNameApiData("additionalZipCode", response.data);
                 this.setState({
                     occupationCodeList: response.data
                 })
                 //this.findByVarNameApiData("customersOccupation", response.data)

             })
             .catch((error) => {
                 console.log(error)
             });*/
        {
            this.dynamicApiCall("merchantRegionCode", "merchantRegionCode",)
        }
        {
            this.dynamicApiCall("merchantCardProsGrpup", "merchantCardProsGrpup",)
        }

        {
            this.dynamicApiCall("merchantCardCurrency", "merchantCardCurrency",)
        }
        {
            this.dynamicApiCall("merchantCardTypeCode", "merchantCardTypeCode",)
        }
        {
            this.dynamicApiCall("merchantFinProf", "merchantFinProf",)
        }
        {
            this.dynamicApiCall("merchantType", "merchantType",)
        }

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.makerRemarks = null;
                let variableGetData = this.copyJson(response.data);
                variableGetData.fullName = variableGetData.merchantName;
                //terminal
                variableGetData.terminalType = "P";
                variableGetData.timeOffset = "0";
              //demograpics

                variableGetData.submCode="80";
                variableGetData.institutionId="11000000188";
                variableGetData.locationCode="1";
                variableGetData.mandatoryFiledControl="2";
                variableGetData.aid="00000000002";
                variableGetData.businessCenter="1";

                //Transit
                variableGetData.transitOneTireAct = "20";
                variableGetData.transitTwoTireAct = "20202";
                variableGetData.transitState = "0";
                variableGetData.subBranchId = "1";

                //SETTLEMENT ACCOUNT
                variableGetData.settlementOneTireAct = "20";
                variableGetData.settlementTwoTireAct = "20202";
                variableGetData.settlementState = "0";
                variableGetData.midApiData = true;
                if (variableGetData.mou_checker_approval !== undefined) {
                    this.setState({
                        inputData: this.emptyValueRemove(variableGetData),
                        varValue: variableGetData,
                        getData: true,
                        showValue: true,
                        loading: false
                    })
                } else {
                    var url = backEndServerURL + "/createMerchantDigit/" + this.props.appId + "/DO NOT SAVE";
                    let postData = {
                        digitType: "TID",
                        posModel: variableGetData.posModel
                    };

                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                                if (response.status === 200) {
                                    variableGetData.terminalTID = response.data;
                                    let transitUrl = backEndServerURL + "/createSettlementAndTransitAccountDigit/" + this.props.appId + "/DO NOT SAVE";
                                    axios.get(transitUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                            if (response.status === 200) {
                                                console.log(response.data);
                                                variableGetData.settlementNumber = response.data.settlementAccount;
                                                variableGetData.transitNumber = response.data.transitAccount;
                                                variableGetData.dataSetInMouMaker = true;
                                                this.setState({
                                                    inputData: this.emptyValueRemove(variableGetData),
                                                    varValue: variableGetData,
                                                    getData: true,
                                                    showValue: true,
                                                    loading: false
                                                })
                                            }
                                        }
                                    )
                                        .catch((error) => {
                                            console.log(error)
                                        });
                                }
                            }
                        )
                        .catch((error) => {
                            console.log(error)
                        });
                }


                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            documentList: response.data,
                            getDocument: true
                        });

                        let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                        axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.data == '') {
                                    console.log('empty')
                                } else {
                                    let deferealData = [];

                                    response.data.map((deferal) => {

                                        var status;
                                        if (deferal.status === "ACTIVE") {
                                            status = "Approved"
                                        } else if (deferal.status === "APPROVAL_WAITING") {
                                            status = "NOT APPROVED"
                                        } else {
                                            status = "Not Approved";
                                        }

                                        deferealData.push(this.createTableForDeferal(deferal.id, deferal.type, deferal.deferalCategory, deferal.deferalSubCategory, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, deferal.deferalDstId, deferal.deferalRmCode, status))
                                    });

                                    this.setState({
                                        getDeferalList: deferealData,
                                        defferalData: true
                                    })
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })


                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.data.length > 0) {
                    let remarkData = [];

                    response.data.map((res) => {

                        remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                    });

                    this.setState({
                        getRemarkList: remarkData,
                        remarks: true
                    });

                    console.log(this.state.getRemarkList)
                } else {
                    this.setState({
                        remarks: false
                    })
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }


    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)

        )
    };


    updateComponent = () => {
        this.forceUpdate();
    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    }
    functionForMIDandTID = (data) => {
        console.log(data)
        if (data.varName === "accountNo" && this.state.inputData[data.varName] !== undefined && (this.state.inputData[data.varName].trim()).length > 1) {
            if(this.state.inputData.selectBankType!==undefined && this.state.inputData.selectBankType!=="CITY BANK" ){
                this.state.inputData.accountName = "";
                this.state.varValue.accountName = "";
                this.state.inputData.accountNoApiCall = false;
                this.state.varValue.accountNoApiCall = false;
                this.forceUpdate();
                this.setState({
                    inputData: this.emptyValueRemove(this.state.inputData),
                    varValue: this.state.varValue,
                    loading: false
                })
            }
            else{
                this.setState({
                    loading: true
                });
                this.state.inputData.accountNoApiCall = false;
                this.state.varValue.accountNoApiCall = false
                let Url = backEndServerURL + "/GetCustomAcctDetailsRequest/" + (this.state.inputData[data.varName]);
                axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.accountName = response.data.acctName;
                        this.state.varValue.accountName = response.data.acctName;
                        this.state.inputData.accountNoApiCall = true;
                        this.state.varValue.accountNoApiCall = true;
                        this.forceUpdate();
                        setTimeout(() => {
                            this.setState({
                                inputData: this.emptyValueRemove(this.state.inputData),
                                varValue: this.state.varValue,
                                loading: false
                            })
                        }, 500)


                    })
                    .catch((error) => {
                        console.log(error);
                        this.state.inputData.accountNo = "__ERROR__";
                        this.state.varValue.accountNo = "__ERROR__";
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorTittle: "Error in Getting Information",

                        })
                    })

            }

        } else if (data.varName === "ababilAccountNumber" && this.state.inputData[data.varName] !== undefined && (this.state.inputData[data.varName].trim()).length > 1) {
            this.setState({
                loading: true
            });
            this.state.inputData.ababilAccountNumberApiCall = false;
            this.state.varValue.ababilAccountNumberApiCall = false
            let Url = backEndServerURL + "/accountInquiryAbabil/" + (this.state.inputData[data.varName]);
            axios.get(Url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.ababilAccountName = response.data.accountName;
                    this.state.varValue.ababilAccountName = response.data.accountName;
                    this.state.inputData.ababilAccountNumberApiCall = true;
                    this.state.varValue.ababilAccountNumberApiCall = true;
                    this.forceUpdate();
                    setTimeout(() => {
                        this.setState({
                            inputData: this.emptyValueRemove(this.state.inputData),
                            varValue: this.state.varValue,
                            loading: false
                        })
                    }, 500)


                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData.ababilAccountNumber = "__ERROR__";
                    this.state.varValue.ababilAccountNumber = "__ERROR__";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Error in Getting Information",

                    })
                })

        } else if (data.varName === "businessType" && this.state.inputData.businessType !== undefined) {
            this.setState({
                showValue: false,
                loading: true
            });
            var url = backEndServerURL + "/createMerchantDigit/" + this.props.appId + "/DO NOT SAVE";
            let postData = {
                digitType: "MID",
                businessType: this.state.inputData.businessType
            };
            this.state.inputData.mid = undefined;
            this.state.inputData.midApiData = false;
            this.forceUpdate();
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = this.state.inputData;
                        inputData.mid = response.data;
                        inputData.midApiData = true;

                        this.setState({
                            inputData: this.emptyValueRemove(inputData),
                            varValue: inputData,
                            showValue: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    let inputData = this.state.inputData;
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: inputData,
                        showValue: true,
                        loading: false
                    })
                });
            this.forceUpdate();
        }
        /*
                     else   if (data.varName === "numberOfSeDemographics" && this.state.inputData.numberOfSeDemographics==="YES"){
                          this.setState({
                             showValue: false,
                             loading: true,
                             getData:false
                         });
                         let inputData=this.state.inputData;
                         //MEMBER OF SE DEMOGRAPHICS
                         inputData.descriptBillName=inputData.merchantName;
                         inputData.addressLine=inputData.physicalAddress;
                         inputData.status="A";
                         inputData.resorseStatus="Y";
                         inputData.riskIndicator="N";
                         inputData.accountIndicator="1";
                         inputData.visaIndicator="A";
                         inputData.masterCardIndicator="A";
                         inputData.otherCardIndicator="A";
                         inputData.highLowIndicator="B";
                         inputData.effectTypeCode="+";
                         inputData.termOwnerLegal=inputData.merchantName;
                         inputData.termLocationLegal=inputData.physicalAddress;
                          inputData.termZipLegal=inputData.zipCode;
                          inputData.merchPhoneNumber=inputData.mobileNo;
                          inputData.regionCode=inputData.regionCode;

                         //terminal
                         inputData.terminalDbaName=inputData.dba;
                         inputData.terminalType="P";
                         inputData.timeOffset="0";
                         inputData.terminalMcc=inputData.mcc;
                         inputData.terminalAddress=inputData.physicalAddress;

                         //Transit
                         inputData.transitOneTireAct= "20";
                         inputData.transitTwoTireAct= "20202";
                         inputData.transitState= "0";
                         inputData.transitName=inputData.fullName;
                         inputData.transitComment=inputData.fullName;
                         //SETTLEMENT ACCOUNT
                         inputData.settlementOneTireAct="20";
                         inputData.settlementTwoTireAct="20202";
                         inputData.settlementState="0";
                         inputData.settlementName=inputData.fullName;
                         inputData.settlementComment=inputData.fullName;
                          setTimeout(() => {
                             this.setState({
                                 inputData:inputData,
                                 varValue:this.emptyValueRemove(inputData),
                                 showValue: true,
                                 loading: false,
                                 getData: true,
                             });
                             this.forceUpdate();
                         },1000)

                     }
        */
        else if (data.varName === "regionCode" && this.state.inputData.regionCode !== undefined && this.state.inputData.regionCode.key !== undefined) {
            this.setState({
                showValue: false,
                loading: true
            });
            var url = backEndServerURL + "/getMerchantMasterData/" + this.state.inputData.regionCode.key;

            this.state.inputData.cityCode = undefined;
            this.state.inputData.regionCodeApiCall = false;
            this.forceUpdate();
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = this.state.inputData;
                        inputData.regionCodeApiCall = true;
                        this.findByVarNameApiData("cityCode", response.data);
                        this.setState({
                            inputData: this.emptyValueRemove(inputData),
                            varValue: inputData,
                            showValue: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    let inputData = this.state.inputData;
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: inputData,
                        showValue: true,
                        loading: false
                    })
                });
            this.forceUpdate();
        } else if (data.varName === "cityCode" && this.state.inputData.cityCode !== undefined && this.state.inputData.cityCode.key !== undefined) {
            this.state.inputData.cityCodeApiCall = false;
            this.forceUpdate();
            setTimeout(() => {
                this.state.inputData.cityCodeApiCall = true;
                this.state.inputData.zipCode = this.state.inputData.cityCode.key;
                this.state.varValue.zipCode = this.state.inputData.cityCode.key;
                this.forceUpdate();
            }, 500)
        } else if (data.varName === "generalRegionCode" && this.state.inputData.generalRegionCode !== undefined && this.state.inputData.generalRegionCode.key !== undefined) {
            this.setState({
                showValue: false,
                loading: true
            });
            var url = backEndServerURL + "/getMerchantMasterData/" + this.state.inputData.generalRegionCode.key;

            this.state.inputData.generalCityCode = undefined;
            this.state.inputData.generalRegionCodeApiCall = false;
            this.forceUpdate();
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = this.state.inputData;
                        inputData.generalRegionCodeApiCall = true;
                        this.findByVarNameApiData("generalCityCode", response.data);
                        this.setState({
                            inputData: this.emptyValueRemove(inputData),
                            varValue: inputData,
                            showValue: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    let inputData = this.state.inputData;
                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: inputData,
                        showValue: true,
                        loading: false
                    })
                });
            this.forceUpdate();
        } else if (data.varName === "generalCityCode" && this.state.inputData.generalCityCode !== undefined && this.state.inputData.generalCityCode.key !== undefined) {
            this.state.inputData.generalCityCodeApiCall = false;
            this.forceUpdate();


            setTimeout(() => {
                this.state.inputData.generalCityCodeApiCall = true;
                this.state.inputData.generalZipCode = this.state.inputData.generalCityCode.key;
                this.state.varValue.generalZipCode = this.state.inputData.generalCityCode.key;
                this.forceUpdate();
            }, 500)
        }
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, merchantDstJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.functionForMIDandTID)
                    }
                    {/*{*/}
                    {/*    CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)*/}
                    {/*}*/}
                </React.Fragment>
            )

        }
    };

    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px', marginTop: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    createTableForDeferal = (id, type, category, subCategory, dueDate, appliedBy, applicationDate, dstId, rmCode, status) => {
        return ([
            type, category, subCategory, dueDate, appliedBy, applicationDate, dstId, rmCode, status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>

                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Category", "Expire Days", "Expire Date", "Raise By", "Raise Date", "DST ID", "RM Code", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };

    submitHandler = () => {

        let error = MyValidation.defaultValidation(merchantDstJsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true,
                loading: true,
            });

            if (this.state.inputData.makerRemarks !== null) {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;

                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = this.state.inputData;
            postData.mou_maker_approval = "APPROVED";
            postData.makerRemarks = undefined;
            postData.settlementOneTireAct = "20";
            postData.settlementTwoTireAct = "20202";
            postData.settlementState = "3";
            postData.transitOneTireAct = "20";
            postData.transitTwoTireAct = "20202";
            postData.transitState = "3";
            postData.contactsCountryCode = postData.generalCountryCode;
            postData.contactsRegionCode = postData.generalRegionCode;
            postData.contactsCityCode = postData.generalCityCode;
            postData.contactsZipCode = postData.generalZipCode;
            postData.contactsCorporateAddress = postData.generalCorporateAddress;
            postData.contactsMobileNo = postData.generalMobileNo;
            postData.contactsEmailAddress = postData.generalEmailAddress;
            postData.settlementName = postData.fullName;
            postData.settlementComment = postData.fullName;
            //postData.transitNumber=postData.settlementNumber;
            postData.transitName = postData.fullName;
            postData.transitComment = postData.fullName;
            postData.terminalAddress = postData.physicalAddress;
            postData.terminalLocation = postData.location;
            //AUto Populate field
            postData.terminalDbaName = postData.dba;
            postData.terminalType = "P";
            postData.timeOffset = "0";
            postData.terminalMcc = postData.mcc;

            //Transit
            postData.transitOneTireAct = "20";
            postData.transitTwoTireAct = "20202";
            postData.transitState = "0";
            postData.transitName = postData.fullName;
            postData.transitComment = postData.fullName;
            //SETTLEMENT ACCOUNT
            postData.settlementOneTireAct = "20";
            postData.settlementTwoTireAct = "20202";
            postData.settlementState = "0";
            postData.settlementName = postData.fullName;
            postData.settlementComment = postData.fullName;

            //number Of Se Demographics
            if (postData.transitAccountAvaailable === "NO") {
                postData.transitNumber=undefined;
            }
            if (postData.indQualityCode === undefined) {
                postData.indQualityCode="-1";
            }
            if (postData.indQualityCode === undefined) {
                postData.indQualityCode="-1";
            }
            if (postData.floorLimitCCY === undefined) {
                postData.floorLimitCCY="-1";
            }
         /*   if (postData.settleCode === undefined) {
                postData.settleCode="-1";
            }*/
           /* if (postData.seSettleCCY === undefined) {
                postData.seSettleCCY="-1";
            } if (postData.submissionCcy === undefined) {
                postData.submissionCcy="-1";
            }*/
            if (postData.numberOfSeDemographics !== "YES") {
                postData.seSettleCCY="";
                postData.seSettleCCY="";
                postData.submissionCcy="";
                postData.institutionId="";
                postData.submCode="";
                 postData.locationCode="";
                postData.mandatoryFiledControl="";
                postData.aid="";
                postData.businessCenter="";
            }
            /* if (postData.posDeploymentDate === undefined) {
                postData.posDeploymentDate="-1";
            }*/
                if (postData.numberOfSeDemographics === "YES") {
                postData.submCode="80";
                postData.institutionId="11000000188";
                postData.locationCode="1";
                postData.mandatoryFiledControl="2";
                postData.aid="00000000002";
                postData.businessCenter="1";
                postData.descriptBillName = postData.merchantName;
                postData.addressLine = postData.physicalAddress;
                postData.status = "A";
                postData.resorseStatus = "Y";
                postData.riskIndicator = "N";
                postData.accountIndicator = "1";
                postData.visaIndicator = "A";
                postData.masterCardIndicator = "A";
                postData.otherCardIndicator = "A";
                postData.highLowIndicator = "B";
                postData.effectTypeCode = "+";
                postData.termOwnerLegal = postData.merchantName;
                postData.termLocationLegal = postData.physicalAddress;
                postData.termZipLegal = postData.zipCode;
                postData.merchPhoneNumber = postData.mobileNo;
                postData.regionCode = postData.regionCode;
            }

            axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (postData.mou_maker_approval === "APPROVED" && postData.mou_checker_approval === undefined) {


                        let url = backEndServerURL + "/createMerchantDigit/" + this.props.appId + "/SAVE";
                        let postData = {
                            digitType: "MID",
                            businessType: this.state.inputData.businessType
                        };

                        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    let postData = {
                                        digitType: "TID",
                                        posModel: this.state.varValue.posModel
                                    };

                                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            if (response.status === 200) {
                                                let transitUrl = backEndServerURL + "/createSettlementAndTransitAccountDigit/" + this.props.appId + "/SAVE";
                                                axios.get(transitUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                                        if (response.status === 200) {
                                                            let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                                                            axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((response) => {
                                                                    this.setState({
                                                                        title: "Successful!",
                                                                        // notificationMessage: "Approved Successfully!",
                                                                        notificationMessage: "Successfully Routed!",
                                                                        alert: true,
                                                                        disabled: false,
                                                                        loading: false,
                                                                    });
                                                                    this.modalClose()
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({
                                                                        disabled: false,
                                                                        loading: false,
                                                                    });
                                                                })

                                                        }
                                                    }
                                                )
                                                    .catch((error) => {
                                                        console.log(error)
                                                        this.setState({
                                                            disabled: false,
                                                            loading: false,
                                                        });
                                                    });
                                            }
                                        })
                                        .catch((error) => {
                                            this.setState({
                                                disabled: false,
                                                loading: false,
                                            });
                                        });
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    disabled: false,
                                    loading: false,
                                });
                            });
                    } else {
                        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.setState({
                                    title: "Successful!",
                                    // notificationMessage: "Approved Successfully!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    disabled: false,
                                    loading: false,
                                });
                                this.modalClose()
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    disabled: false,
                                    loading: false,
                                });
                            })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        loading: false,
                    });
                });

        }

    };

    agreementSignUp = () => {
        let field = JSON.parse(JSON.stringify(agreementSignUpDate));
        field.varName = "agreementSignUpDate";
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    returnHandler = () => {

        let error = MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });

            if (this.state.inputData.makerRemarks !== null) {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        //console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            let deferalCheckUrl = backEndServerURL + "/variables/" + this.props.appId;

            let postData = this.state.inputData;
            postData.mou_maker_approval = "RETURN";
            postData.makerRemarks = undefined;

            axios.post(deferalCheckUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                                // notificationMessage: "Return Successfully!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                disabled: false
                            });
                            this.modalClose();
                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                        })
                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                });
        }
    };

    functionForWaiver = () => {
        if (this.state.getData) {
            return (
                <>
                    <WaiverList appId={this.props.appId}/>
                </>
            )
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()
        this.validationForHandleConfirm(type)


    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }

        let dependencyField = [];
        this.setState({
            loading: true
        });
        if (actionType === "handleReturn") {
            dependencyField.push({

                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12
            })
            let errors = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (errors === true) {
                console.log("Not working");
                this.setState({
                    loading: false
                });
                return 0;
            }

        } else if (actionType === "handleSubmit") {
            let errors = MyValidation.defaultValidation(merchantDstJsonForm, this.state);
            this.forceUpdate();

            if (errors === true) {
                console.log("Not working");
                this.setState({
                    loading: false
                });
                return 0;
            }

        }
        this.setState({
            confirmAlert: true,
            loading: false,
            actionType: actionType
        })
    }
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.submitHandler()
        } else if (data === "YES" && this.state.actionType === "handleReturn") {
            this.returnHandler()
        }

    };
    renderSDNDedupeResult = () => {
        if (  this.state.getData) {
            return (
                <DedupFullResultButton  merchantDedup={true} closeModal={this.closeModalForSdnResult}
                                       branchName={this.props.branchName}
                                       inputData={this.state.inputData}
                                       subServiceType="Joint Account"
                                       category="CASA"
                                       serviceType="Account Opening"
                                       subCategory="A/C Opening"
                                       freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                       serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                                       customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber}
                                       solId={this.props.solId} globalSearch={true}
                                       closeIcon={this.closeModalForSdnResult}
                                       appId={this.props.appId!==undefined?this.props.appId:this.state.caseId}/>


            )
        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.loading && this.state.getData !== true) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Grid container spacing={0}>
                            {   this.renderSDNDedupeResult()}
                            <Grid item xs={12}>
                            </Grid>
                            {
                                this.renderFormWithData()
                            }
                            <Grid item xs={12}>
                                {
                                    this.renderDeferalApprovalTableData()
                                }
                            </Grid>


                            <Grid item xs={12}>
                                {
                                    this.functionForWaiver()
                                }
                            </Grid>


                            <Grid item xs={12}>
                                {
                                    this.renderRemarksTable()
                                }
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    this.renderRemarks()
                                }
                            </Grid>


                            {
                                this.renderNotification()
                            }
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>

                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: '10px',
                                    marginTop: '10px',
                                    marginBottom: '15px'
                                }}

                                // onClick={this.submitHandler}
                                onClick={(event) => this.handleCommon(event, "handleSubmit")}
                                disabled={this.state.disabled}
                            >
                                Submit
                            </button>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                                </DialogContent>
                            </Dialog>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: '10px',
                                    marginTop: '10px',
                                    marginBottom: '15px'
                                }}
                                disabled={this.state.disabled}
                                // onClick={this.returnHandler}
                                onClick={(event) => this.handleCommon(event, "handleReturn")}

                            >
                                Return
                            </button>
                        </Grid>
                    </GridItem>
                </GridList>
            );
        }
    }


}

export default withStyles(styles)(splitMaker);
