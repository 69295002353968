import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import AssignedCropImage from "../CASA/AssignedCropImage";
import NocMakerLabel from "./NocMakerLabel";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const jsonForm = [

    {
        varName: "fileStatus",
        type: "select",
        label: "File Status",
        required: true,
        grid: 4,
        "enum": [
            "Under process",
            "Pending at Legal",
            "Received from Legal",
            "Completed but Yet to Deliver",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "documentReleaseCharge",
        type: "text",
        label: "Document Release -Charge",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "securityDocs",
        type: "text",
        label: "Security Docs",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "uploadSroToken",
        type: "file",
        label: "Upload SRO Token",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },


];

let csAfterHldRemarks = [
    {
        "varName": "csAfterHldRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }]
;


class CSOAfterHLD extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {},

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            console.log("render remarks data");
            console.log(this.state.getRemarks.length);

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    componentDidMount() {
        // this.renderStartCase();
        // this.updateComponent()


        var remarksArray = [];
        this.setState({
            loading: true
        });
        if (this.props.appId !== undefined) {
            console.log("AppId:");
            console.log(this.props.appId);


            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data);
                        response.data.map((data) => {
                            if (data.remarks !== 'undefined') {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            }
                        });

                        console.log("remarks Array");
                        console.log(remarksArray);
                        this.setState({
                            getRemarks: remarksArray
                        });

                        console.log("Get Remarks");
                        console.log(this.state.getRemarks);

                    })
                        .catch((error) => {
                            console.log(error)
                        });


                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                            /*let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    })
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                    this.setState({
                        getDeferalList: tableArray
                    })*/

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    console.log(response.data);
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appId: this.props.appId,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }


    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderCustomerDetails = () => {

        return (
            <NocMakerLabel data={this.state.inputData} classes={this.props}
                           labelGrid={[3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]}/>
        )

    };


    renderForm = () => {
        return (

            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)

        )

    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return (

            <div>


                <ThemeProvider theme={theme}>
                    <Grid container>
                        {this.renderForm()}
                        <Grid item xs='12'>
                        </Grid>


                    </Grid>
                </ThemeProvider>

            </div>


        )


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    handleSubmitButton = () => {


        return (
            <ThemeProvider>
                <Grid>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{}}
                        onClick={this.handleSubmit}

                    >
                        Generate debit Authority letter
                    </button>

                </Grid>
            </ThemeProvider>
        )


    };
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData;


        let url = backEndServerURL + "/archiveFile/requisition/";


        axios
            .post(
                url,
                {

                    fileReferenceNo: object.fileReferenceNo,
                    purpose: object.purpose,
                    departmentName: object.departmentName,
                    remarks: object.remarks,
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Added",
                    alert: true,
                });
                console.log("Added Successfully");
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
            });


    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csAfterHldRemarks, this.updateComponent)

            )
        }

    };


    render() {

        console.log("inputData");
        console.log(this.state.inputData);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.croppedImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>

                        </DialogContent>
                    </Dialog>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            CSO After HLD<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderForm()}
                            {/*  <Grid item xs={2}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderMultipleDebitCardForm()}
*/}
                        </Grid>
                        <br/>


                        {this.renderRemarksData()}
                        <br/>

                        {this.renderRemarks()}
                        <br/>


                    </ThemeProvider>
                </Card>
            );
        }


    }

}

export default withStyles(styles)(CSOAfterHLD);
