import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import SelectComponent from "../../JsonForm/SelectComponent";
import Notification from "../../NotificationMessage/Notification";
import {StudentTypeNoFiledRemitance,} from "../WorkflowJsonFormArin";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import MyValidation from "../../JsonForm/MyValidation";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../Maintenance/common/CommonApi";
import CommonFunctions from "../REMITENCE/CommonFunctions";


const fileIndexList = [
    {label: 'Document 1'},
    {label: 'Document 2'},
    {label: 'Document 3'},
    {label: 'Document 4'},
    {label: 'Document 5'},
    {label: 'Document 6'},
    {label: 'Document 7'},
    {label: 'Document 8'},
    {label: 'Document 9'},
    {label: 'Document 10'},
    {label: 'Document 11'},
    {label: 'Document 12'},
    {label: 'Document 13'},
    {label: 'Document 14'},
    {label: 'Document 15'},
    {label: 'Document 16'},
    {label: 'Document 17'},
    {label: 'Document 18'},
    {label: 'Document 19'},
    {label: 'Document 20'},

];

let remittanceAccountNumber = [
    {
        "varName": "remittanceAccountNumber",
        "type": "text",
        "validation": "numeric",
        "required": true
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let abhMaker = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
let bondProjectTypeBond = [
    {
        "varName": "selectRemType",
        "type": "select",
        "enum": [
            "FTT",
            "FDD",
        ],
        "onKeyDown": true,
        "label": "Select Remittance Type",
        "grid": 2,
    },
    {
        "varName": "selectStudentFileType",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Select Student File",
        "grid": 2,
    },
    {
        "varName": "empty",
        "type": "empty",
        "grid": 12,
    },
];


var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

class Remetance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: [],
            getMappingAllImage: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            uploadModal: false,
            selectType: "",
            title: "",
            loading: true,
            inputData: {
                csDeferal: "NO",
                tutionFee: "NO",
                livingExpence: "NO"
            },
            alert: false,
            message: "",
            notificationMessage: "",
            csDeferalPage: "",
            values: [],
            valuesTutionFee: [],
            valuesLivingExpence: [],
            varValue: [],
            cbNotFound: false,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            accountDataBoolean: false,
            searchDataBoolean: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let url = backEndServerURL + "/startCase/cs_data_capture";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            let inputData = {};
            inputData.caseId = response.data.caseId;
            let appId = response.data.id;
            let adBranchUrl = backEndServerURL + "/finMasterData/adBranchRemittance";
            axios.get(adBranchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                for (let item of StudentTypeNoFiledRemitance) {
                    if (item.varName === "adBranch") {
                        item.enum = response.data
                    }
                }
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    appId: appId,
                    getNewCase: true,
                    showValue: true,
                    getData: true,
                    loaderNeeded: true,
                    loading: false
                });
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        })
    }

    returnAccountTypeField = () => {

        return (
            <React.Fragment>
                {
                    CommonJsonFormComponent.renderJsonForm(this.state, bondProjectTypeBond, this.updateComponent, "", this.functionForRemittanceType)
                }
            </React.Fragment>
        )
    };

    functionForRemittanceType = (data) => {
        if (data.varName === "selectRemType" && this.state.inputData.selectRemType === "FDD") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            // inputData.swiftCode = undefined;
            let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
            axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of StudentTypeNoFiledRemitance) {
                        if (item.varName === "beneficiaryCountry") {
                            item.enum = response.data
                        }
                    }
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                    });
                })
                .catch(error => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    firstLayerField = () => {
        if (this.state.inputData.selectRemType && this.state.inputData.selectStudentFileType) {
            if(this.state.searchDataBoolean){
                return (
                    <React.Fragment>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, CommonFunctions.makeReadOnlyObjectForRemittance(StudentTypeNoFiledRemitance), this.updateComponent, this.amountConvert, this.onKeyDownChangeForSwift)
                        }
                    </React.Fragment>
                )
            }
            else{
                return (
                    <React.Fragment>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, StudentTypeNoFiledRemitance, this.updateComponent, this.amountConvert, this.onKeyDownChangeForSwift)
                        }
                    </React.Fragment>
                )
            }
        }
    };

    onKeyDownChangeForSwift = (data) => {
        if (data.varName === "swiftCode" && this.state.inputData.swiftCode === "Code") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            inputData.showSwiftValue = true;
            let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
            axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    for (let item of StudentTypeNoFiledRemitance) {
                        if (item.varName === "beneficiaryCountry") {
                            item.enum = response.data
                        }
                        if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                            item.readOnly = false;
                        }
                    }

                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false
                    });
                })
                .catch(error => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data.varName === "swiftCode" && this.state.inputData.swiftCode === "SWIFT") {
            this.setState({
                loading: true
            });
            let inputData = this.state.inputData;
            inputData.showSwiftValue = false;
            for (let item of StudentTypeNoFiledRemitance) {
                if (item.varName === "beneficiaryBankName" || item.varName === "beneficiaryBankAddress") {
                    item.readOnly = true;
                }
            }
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    showValue: true,
                    loading: false
                });
            }, 300)
        }
    };


    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.inputData.selectRemType !== undefined && this.state.inputData.selectStudentFileType !== undefined && this.state.inputData.selectRemType !== null && this.state.inputData.selectStudentFileType !== null) {
            return <Grid item xs={12}><UploadComponent indexList={fileIndexList} appId={this.state.appId}
                                                       classes={classes}/></Grid>
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.inputData.selectRemType) {
            for (let item of StudentTypeNoFiledRemitance) {
                if (item.varName === "swiftCode" || item.varName === "swiftCodeValue") {
                    this.state.inputData.selectRemType === "FDD" ? item.required = false : item.required = true
                }
            }
        }
        let deferralError = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        let error = MyValidation.defaultValidation(StudentTypeNoFiledRemitance.concat(remittanceAccountNumber), this.state);
        this.forceUpdate();
        if (error === true || deferralError === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let imageUrl = backEndServerURL + "/case/files/" + this.state.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.length > 0) {
                        if (this.state.values.length > 0) {
                            this.state.inputData["csDeferal"] = "YES";
                        } else {
                            this.state.inputData["csDeferal"] = "NO";
                        }

                        if (this.state.inputData["csDeferal"] === "YES") {
                            var defType = [];
                            var expDate = [];
                            var remarks = [];
                            let appId = this.state.appId;
                            for (let i = 0; i < this.state.values.length; i++) {
                                let value = this.state.values[i];
                                let defferalType = this.state.inputData["deferalType" + value];
                                let remarksData = this.state.inputData["deferalOther" + value];
                                remarks.push(remarksData);
                                defType.push(defferalType);
                                let expireDate = this.state.inputData["expireDate" + value];
                                expDate.push(expireDate);
                                console.log(expDate)
                            }
                            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                            axios.post(deferalRaisedUrl, {
                                appId: this.state.appId,
                                type: defType,
                                dueDate: expDate,
                                remarks: remarks
                            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                                console.log(response.data);
                            }).catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })
                            })
                        }
                        let data = this.state.inputData;
                        data.cs_deferal = this.state.inputData["csDeferal"];
                        data.dueDate = this.state.dueDate;
                        data.type = "";
                        data.category = 'Remittance';
                        data.subCategory = 'Outward';
                        data.serviceType = "Remetance";
                        data.freeFlag1 = this.state.inputData.currency;
                        data.freeFlag2 = this.state.inputData.amount;
                        data.freeFlag6 = `Outward - ${this.state.inputData.selectRemType}`;
                        data.subServiceType = this.state.inputData.selectRemType;
                        data.customerName = this.state.inputData.name;
                        let remarksData = {};
                        remarksData.remark = this.state.inputData.csRemarks;
                        remarksData.map = {...data, csRemarks: undefined};
                        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });
                            setTimeout(function () {
                                window.location.reload()
                            }, 500);
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });
                    } else {
                        this.setState({
                            title: "File upload is mandatory!",
                            cbNotFound: true,
                            loading: false
                        })
                    }
                }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }

    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = MyValidation.deferralValidation(this.state.values, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            if (this.state.inputData["csDeferal"] === "YES") {
                var defType = [];
                var expDate = [];
                var remarks = [];

                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let defferalType = this.state.inputData["deferalType" + value];
                    let remarksData = this.state.inputData["deferalOther" + value];
                    remarks.push(remarksData);
                    defType.push(defferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);

                    console.log(expDate)
                }

                let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
                axios.post(deferalRaisedUrl, {
                    appId: appId,
                    type: defType,
                    dueDate: expDate,
                    remarks: remarks
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
            }
            var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

            let data = this.state.inputData;
            data.cs_deferal = this.state.inputData["csDeferal"];
            data.category = 'Remittance';
            data.subCategory = 'Outward';
            data.freeFlag6 = `Outward - ${this.state.inputData.selectRemType}`;
            data.serviceType = "Remetance";
            data.freeFlag1 = this.state.inputData.currency;
            data.freeFlag2 = this.state.inputData.amount;
            data.customerName = this.state.inputData.name;
            data.subServiceType = this.state.inputData.selectRemType;

            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Save!",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 500);
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    };


    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }


    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }


    renderAddButtonShow = () => {
        if (this.state.inputData.selectRemType !== undefined && this.state.inputData.selectStudentFileType !== undefined && this.state.inputData.selectRemType !== null && this.state.inputData.selectStudentFileType !== null) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        onClick={this.addClick.bind(this)}
                    >Add Deferral
                    </button>
                </Grid>
            )

        }
    };

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }
                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}
                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )
    }


    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    renderRemarks = () => {
        if (this.state.inputData.selectRemType && this.state.inputData.selectStudentFileType) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, abhMaker, this.updateComponent)
            )
        }
    };

    renderSubmitButon = () => {
        if (this.state.inputData.selectRemType !== undefined && this.state.inputData.selectStudentFileType !== undefined && this.state.inputData.selectRemType !== null && this.state.inputData.selectStudentFileType !== null) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{}}
                    onClick={this.handleSubmit}
                >
                    Submit
                </button>
            )
        }

    };

    renderSaveButton = () => {
        if (this.state.inputData.selectRemType !== undefined && this.state.inputData.selectStudentFileType !== undefined && this.state.inputData.selectRemType !== null && this.state.inputData.selectStudentFileType !== null) {
            return (
                <button
                    className="btn btn-outline-info btn-sm"
                    style={{}}
                    onClick={this.handleSubmitDraft}
                >
                    Save
                </button>
            )
        }

    };

    functionForDownload = (document) => {

        let postData = {...this.state.inputData};
        if (this.state.inputData.selectRemType === "FDD") {
            if ('swiftCode' in postData) {
                delete postData.swiftCode;
                delete postData.swiftCodeValue
            }
        }

        let url;
        let fileName;
        if (document === "TM") {
            url = backEndServerURL + "/GetInwardRemittanceTMForm";
            fileName = "TM.pdf"
        } else if (document === "Request Form") {
            fileName = "Request Form.pdf";
            url = backEndServerURL + "/GetForeignCurrencyRemittanceRequestForm";
        }
        this.setState({
            loading: true,
            inputData: postData,
            varValue: this.copyJson(postData),
        });

        axiosDownload.downloadFile(url, "POST", postData, fileName).then((res) => {
            this.setState({
                loading: false
            });
        }).catch((err) => {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: "File Download Fail!"
            });
        });
    };

    amountConvert = (event, data) => {
        if (data === "remittanceAccountNumber" && this.state.inputData.source==="FINACLE") {
            this.setState({
                loading: true
            });
            this.state.inputData.remittanceAccountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.inputData.accountNumber = this.state.inputData.remittanceAccountNumber;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((custResponse) => {
                            inputData.accountBalance = custResponse.data.acctEftvBalance;
                            inputData.showSignature = true;
                            inputData.name = response.data.customerName;
                            inputData.customerName = response.data.customerName;
                            inputData.cbNumber = response.data.cbNumber;
                            inputData.showAccountBalance = response.data.schemeCode !== "SBSTF";
                            inputData.email = response.data.emailAddr;
                            let contactNumber = response.data.contactNumber ? response.data.contactNumber.replace(/\D/g, '') : "";
                            inputData.contactNumber = response.data.contactNumber ? contactNumber.substr(contactNumber.length - 11) : "";

                            if (response.data.mailingAddress) {
                                inputData.addressOne = response.data.mailingAddress.addrLine1;
                                inputData.addressTwo = response.data.mailingAddress.addrLine2
                            }
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                searchDataBoolean:true,
                            });
                        }).catch((error) => {
                            let inputData = this.state.inputData;
                            inputData.remittanceAccountNumber = "";
                            inputData.accountNumber = "";
                            inputData.showSignature = false;
                            inputData.showAccountBalance = false;
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                accountDataBoolean: true,
                            });
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    let inputData = this.state.inputData;
                    inputData.remittanceAccountNumber = "";
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        accountDataBoolean: true,
                    });
                });
        }
        else if (data === "remittanceAccountNumber" && this.state.inputData.source==="ABABIL") {
            this.setState({
                loading: true
            });
            this.state.inputData.remittanceAccountNumber = event.target.value;
            this.state.inputData.showSignature = false;
            this.state.inputData.showAccountBalance = false;
            this.state.inputData.accountNumber = this.state.inputData.remittanceAccountNumber;
            CommonApi.ababilAccountDetails(this.state.inputData.accountNumber)
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        let inputData = this.state.inputData;
                        inputData.showSignature = false;
                        inputData.showAccountBalance = false;
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.accountBalance = response.data.availableBalance;
                        inputData.showSignature = true;
                        inputData.name = response.data.acctName;
                        inputData.customerName = response.data.accountName;
                        inputData.cbNumber = response.data.customerId;

                        let contactNumber = response.data.conatactNumber ? response.data.conatactNumber.replace(/\D/g, '') : "";
                        inputData.contactNumber = response.data.conatactNumber ? contactNumber.substr(contactNumber.length - 11) : "";
                        inputData.addressOne = response.data.addressLineOne;
                        inputData.addressTwo = response.data.addressLineTwo;

                        CommonApi.getCbDetailsAbabil(response.data.customerId).then((cbResponse)=>{
                            inputData.email = cbResponse.data.email;
                        }).catch((error)=>{
                            console.log(error);
                            this.setState({
                                accountDataBoolean: true,
                                loading:false,
                            })
                        })
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            showValue: true,
                            loading: false,
                            searchDataBoolean:true,
                        });
                    }
                })
                .catch((error) => {
                    let inputData = this.state.inputData;
                    inputData.remittanceAccountNumber = "";
                    inputData.accountNumber = "";
                    inputData.showSignature = false;
                    inputData.showAccountBalance = false;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        accountDataBoolean: true,
                    });
                });
        }

        if (data === "swiftCodeValue" && this.state.inputData.swiftCode === "SWIFT") {
            this.setState({
                loading: true
            });
            this.state.inputData.swiftCodeValue = event.target.value;
            this.state.inputData.showSwiftValue = false;
            let getAccountUrl = backEndServerURL + "/finMasterData/custom/swiftBranch/" + this.state.inputData.swiftCodeValue;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.showSwiftValue = true;
                    inputData.beneficiaryCountry = response.data.country;
                    inputData.beneficiaryBankName = response.data.bankName;
                    inputData.beneficiaryBankAddress = response.data.address;
                    let countryUrl = backEndServerURL + "/finMasterData/SwiftCodeCountryList";
                    axios.get(countryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            for (let item of StudentTypeNoFiledRemitance) {
                                if (item.varName === "beneficiaryCountry") {
                                    item.enum = response.data
                                }
                            }

                            this.setState({
                                varValue: inputData,
                                showValue: true,
                                loading: false,
                            });
                        })
                        .catch(error => {
                            let inputData = this.state.inputData;
                            this.setState({
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        });
                })
                .catch(error => {
                    let inputData = this.state.inputData;
                    this.setState({
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
        if (data === "amount") {
            this.setState({
                loading: true
            });
            let form = [
                {
                    "varName": "amount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                let inputData = this.state.inputData;
                let amount = this.state.inputData.amount.split(".");

                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = this.state.inputData.amount;
                    postData.isBDT = false;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        });
                }
            }
        }
    };

    generateFinacleAbabilErrorModal=()=>{
        if(this.state.accountDataBoolean){

            this.setState({
                cbNotFound: true,
                title: "Invalid Account Number",
                accountDataBoolean:false,
            });
        }
    }

    signature = () => {
        if (this.state.inputData.showSignature) {
            return <Grid item xs={12}><SignatureButton accountNumber={this.state.inputData.accountNumber}
                                                       signatureType="multiple"
                                                       classes={this.props}/></Grid>;
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Outward Remittance Information</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>
                                            {this.renderNotification()}
                                            {this.returnAccountTypeField()}
                                            {this.firstLayerField()}
                                            {this.generateFinacleAbabilErrorModal()}
                                            <Grid item xs={12}>
                                                {this.state.inputData.selectRemType && this.state.inputData.selectStudentFileType &&
                                                <>
                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                        type='button' value='add more'
                                                        onClick={() => this.functionForDownload("TM")}
                                                    >Download TM File
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                        type='button' value='add more'
                                                        onClick={() => this.functionForDownload("Request Form")}
                                                    >Download Request Form
                                                    </button>
                                                </>}
                                            </Grid>
                                            {this.signature()}
                                            {this.renderAddButtonShow()}
                                            {this.addDeferalForm()}
                                            {this.uploadFunction()}
                                            {this.renderRemarks()}
                                            <Grid item xs={12}>
                                                {this.renderSubmitButon()}
                                                &nbsp;
                                                {this.renderSaveButton()}
                                            </Grid>
                                        </Grid>
                                    </ThemeProvider>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }

    }
}

export default withStyles(styles)(Remetance);
