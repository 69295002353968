import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import {AssetOperationReadOnlyForm} from "./JsonFormPayOrder"
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import ErrorModal from "../../CommonComponent/ErrorModal";
import CommonApi from "../../Maintenance/common/CommonApi";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";

var payOrderCopyFile = {
    "varName": "payOrderCopy",
    "type": "file",
    "accept": ".pdf",
    "label": "Pay Order Copy",
    "required": true,
    "grid": 12
};

var closingLetterFile = {
    "varName": "closingLetter",
    "type": "file",
    "accept": ".pdf",
    "label": "Closing Letter",
    "required": true,
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];


class CsPayOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            actionType: "",
            confirmAlert: false,
            selectedDate: {},
            fileUploadData: {},
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    renderFileUpload = () => {
        if (this.state.getData) {
            console.log("Upload a file");
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, payOrderCopyFile)}
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, closingLetterFile)}
                </Grid>

            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = this.removeNullValue(response.data);
                inputData.csRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            currentTask: response.data.currentTask,
                            varValue: inputData,
                            inputData: inputData,
                            appId: this.props.appId,
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    /*  renderFileUpload = () => {
          return (
              <Grid item xs={12}>
                  {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
              </Grid>

          )
      };*/


    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = () => {
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        let postData = {};
        if (this.state.currentTask === "cs_pay_order") {
            postData = {cs_approval: "YES"};
        } else if (this.state.currentTask === "rm_pay_order") {
            postData = {rm_approval: "YES"};
        }
        axios
            .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (this.state.fileUploadData.payOrderCopy !== undefined) {
                    let fileUploadPath = assetOpsBackEndServerURL + "/case/upload";
                    let types = 'file';
                    let files = this.state.fileUploadData.payOrderCopy;
                    let formData = new FormData();
                    formData.append("appId", this.props.appId);
                    formData.append("file", files);
                    formData.append("type", types);
                    axios({
                        method: 'post',
                        url: fileUploadPath,
                        data: formData,
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                        .then((response) => {

                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }
                if (this.state.fileUploadData.closingLetter !== undefined) {
                    let fileUploadPath = assetOpsBackEndServerURL + "/case/upload";
                    let types = 'file';
                    let files = this.state.fileUploadData.closingLetter;
                    let formData = new FormData();
                    formData.append("appId", this.props.appId);
                    formData.append("file", files);
                    formData.append("type", types);
                    axios({
                        method: 'post',
                        url: fileUploadPath,
                        data: formData,
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                        .then((response) => {

                        })
                        .catch((error) => {
                            console.log(error)
                        });
                }
                let remark_url = assetOpsBackEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
                axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                    })
                    .catch(error => {
                        console.log(error)
                    });

                /*   let fileUploadPath = assetOpsBackEndServerURL + "/case/upload";
                   let types = 'Attachments';
                   let files = this.state.fileUploadData.scanningFile;
                   let formData = new FormData();
                   formData.append("appId", this.props.appId);
                   formData.append("file", files);
                   formData.append("type", types);
                   axios({
                       method: 'post',
                       url: fileUploadPath,
                       data: formData,
                       headers: {
                           Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                           'content-type': 'multipart/form-data'
                       }
                   })
                       .then((response) => {

                       })
                       .catch((error) => {
                           console.log(error)
                       });
   */

                let caseRouteUrl = assetOpsBackEndServerURL + "/case/route/" + this.props.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.setState({
                            alert: true,
                            title: "Successful!",
                            notificationMessage: response.data,
                        });
                        this.props.closeModal()

                    })
                    .catch(error => {
                        console.log(error);
                    });

            })
            .catch(error => {
                console.log(error);
            });

    };

    returnHandler = () => {
        let postData = {};
        if (this.state.currentTask === "cs_pay_order") {
            postData = {cs_approval: "NO"};
        } else if (this.state.currentTask === "rm_pay_order") {
            postData = {rm_approval: "NO"};
        }
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;
        axios
            .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let remark_url = assetOpsBackEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
                axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                    })
                    .catch(error => {
                        console.log(error)
                    });
                let caseRouteUrl = assetOpsBackEndServerURL + "/case/route/" + this.props.appId;
                axios
                    .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        this.setState({
                            alert: true,
                            title: "Successful!",
                            notificationMessage: response.data,
                        });
                        this.props.closeModal()
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };


    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    closeModal = () => {
        this.setState({
            loading: false,
            errorModalBoolean: false,
        })
    }
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        console.log(this.state.inputData)
        if ((this.state.fileUploadData.payOrderCopy === undefined || this.state.fileUploadData.closingLetter === undefined) && this.props.appId === undefined) {
            this.setState({
                loading: false,
                errorModalBoolean: true,
                errorMessage: "File Upload Mandatory",
            })
            return 0;
        }


        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "csRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });
        if (data === "NO") {
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.approveHandler()
        } else if (data === "YES" && this.state.actionType === "handleReturn") {
            this.returnHandler()
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {
            this.validationForHandleConfirm(type)
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleReturn")}
                    >Return
                    </button>
                </div>
            )
        }
    };
    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AssetOperationReadOnlyForm, this.updateComponent)

            )
        }
    }
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Pay Order</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Pay Order Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };


    render() {
        const {classes, onClose} = this.props;

        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                          closeModal={this.closeConfirmAlert}

                            />

                        </DialogContent>
                    </Dialog>
                    <CardBody>
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                {this.renderJsonForm()}
                                <Grid item xs={12}></Grid>
                                {this.mappingPhoto()}
                            </ThemeProvider>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}
                                    {this.renderFlowSUmmeryButton()}
                                    {this.renderFileUpload()}
                                    {this.renderRemarks()}
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModalBoolean}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ErrorModal title={this.state.errorMessage} closeModal={this.closeModal}
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        {this.renderSubmitButton()}
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            </div>
        );
    }


}

export default withStyles(styles)(CsPayOrder);
