import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "./CasaCloserLabel";
import UploadComponent from "./UploadComponent";
import CBNotFound from "../../CASA/CBNotFound";
import {casaSiDelete, dormantActivation, manualFundTransfer, unfreezeMarking} from "../../WorkflowJsonFormRashed"
import SelectComponent from "../../../JsonForm/SelectComponent";

let dataArray = {};
const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "validation": "numeric",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "required": true,
        "label": "Account No",
        "validation": "numeric",
        "grid": 2,

    }
];

let csRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

let deleteField = {
    "varName": "deleteFiled",
    "type": "select",
    "onKeyDown": true,
    "label": "Delete Flag",
    "enum": ["Y", "N"],

};

class CloserRequestWiseMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = this.initialState;

    }

    get initialState() {
        return {
            cbNotFound: false,
            bearerApproval: '',
            csBearer: '',
            inputData: {},
            appId: 0,
            getData: false,
            lienTableArray: [],
            showExistingLien: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            fileUploadData: {},
            selectedDate: {},
            showValue: false,
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            caseId: 0,
            siTableArray: [],
            showSiData: false,
            multipleSignatureValue: [],
            showSignature: true,
            signatureDeleteFlag: []
        }
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        this.setState({
            loading: false
        });
        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }
    }


    functionForGetLienList = (value) => {
        let lienTableArray = [];
        let lienUrl = backEndServerURL + "/accounts/common/getLienList/" + this.state.inputData.accountNumber;
        axios.get(lienUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((item, index) => {
                    lienTableArray.push(this.createLienTableData(index + 1, item.newLienAmt, item.reasonCode, item.remarks, this.triggerExistingLienForDelete(item.lienId, index)))
                });

                if (value !== undefined) {
                    this.setState({
                        lienTableArray: lienTableArray,
                        showExistingLien: true,
                        loading: false
                    })
                } else {
                    this.setState({
                        lienTableArray: lienTableArray,
                        showExistingLien: true
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                });
            });
    };


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.maintenance !== prevProps.match.params.maintenance) {
            window.location.reload();
        }
    }

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.renderStartCase(event);

        }

    };

    renderStartCase = (e) => {
        e.preventDefault();
        let error = MyValidation.defaultValidation(accountMaintenanceSearch, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                err: false,
                errorArray: {},
                errorMessages: {},
                searchData: false,
                getData: false,
                loading: true,
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.message === "No Account Found!") {
                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.customerName = response.data.customerName;
                        inputData.cbNumber = response.data.cbNumber;
                        inputData.solId = response.data.solid;
                        inputData.mainAccountCurrency = response.data.acctCurrency;
                        if (this.props.match.params.maintenance === "siDelete") {
                            this.functionForGetSiList()
                        }
                        if (this.props.match.params.maintenance === "lien") {
                            this.functionForGetLienList();
                        }
                        if (this.props.match.params.maintenance === "fundTransfer") {
                            inputData.debitPartTransactionType = "Debit";
                            inputData.creditPartTransactionType = "Credit"
                        }
                        if (this.props.match.params.maintenance === "signature") {
                            this.functionForGetAllSignature()
                        }
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData)
                        });
                        this.caseStart();
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };


    functionForGetSiList = (value) => {
        let url = backEndServerURL + "/DPS/getAllSINumbers/" + this.state.inputData.accountNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((siResponse) => {
            let siTableArray = [];
            siResponse.data.map((item, index) => {
                siTableArray.push(this.createSiTableData(index + 1, item, this.triggerSiForDelete(item, index)))
            });
            if (value !== undefined) {
                this.setState({
                    siTableArray: siTableArray,
                    showSiData: true,
                    loading: false
                })
            } else {
                this.setState({
                    siTableArray: siTableArray,
                    showSiData: true
                })
            }
        }).catch((error) => {
            this.setState({
                showSiData: false,
                loading: false,
                cbNotFound: true,
                title: error.response.data.message
            });
        })
    };


    triggerSiForDelete = (id, index) => {
        let checkedValue = false;
        if (this.state.inputData.existingSiDeleteFlag !== undefined) {
            checkedValue = this.state.inputData.existingSiDeleteFlag["siForDelete" + index] !== undefined
        }
        return (
            <input type="checkbox" onChange={(event) => this.functionForSi(event)} checked={checkedValue}
                   name={"siForDelete" + index} value={id}/>
        )
    };

    functionForSi = (event, id, index) => {
        this.setState({
            loading: true,
            showSiData: false
        });
        let indexName = (event.target.name).toString();
        let indexValue = (event.target.value).toString();
        this.forceUpdate();
        if (event.target.checked) {
            dataArray[indexName] = indexValue;
        } else {
            delete dataArray[indexName]
        }
        this.state.inputData.existingSiDeleteFlag = dataArray;
        this.functionForGetSiList("stop");
        this.forceUpdate();
    };


    renderExistingLienData = () => {
        if (this.props.match.params.maintenance === "lien" && this.state.lienTableArray.length > 0 && this.state.showExistingLien) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Lien Details:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Delete"]}
                                tableData={this.state.lienTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    renderSiData = () => {
        if (this.props.match.params.maintenance === "siDelete" && this.state.siTableArray.length > 0 && this.state.showSiData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing SI Details:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Delete"]}
                                tableData={this.state.siTableArray}
                                tableAllign={['left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createLienTableData = (sl, amount, reasonCode, remarks, lienId) => {
        return ([
            sl, amount, reasonCode, remarks, lienId
        ])
    };

    createSiTableData = (sl, cbNumber, action) => {
        return ([
            sl, cbNumber, action
        ])
    };

    triggerExistingLienForDelete = (id, index) => {
        let checkedValue = false;
        if (this.state.inputData.existingLienFlag !== undefined) {
            checkedValue = this.state.inputData.existingLienFlag["lienForDelete" + index] !== undefined
        }
        return (
            <input type="checkbox" onChange={(event) => this.functionForUnlien(event)} checked={checkedValue}
                   name={"lienForDelete" + index} value={id}/>
        )
    };

    functionForUnlien = (event, id, index) => {
        this.setState({
            loading: true,
            showExistingLien: false
        });
        let indexName = (event.target.name).toString();
        let indexValue = (event.target.value).toString();
        this.forceUpdate();
        if (event.target.checked) {
            dataArray[indexName] = indexValue;
        } else {
            delete dataArray[indexName]
        }
        this.state.inputData.existingLienFlag = dataArray;
        this.functionForGetLienList("stop");
        this.forceUpdate();
    };


    caseStart = () => {
        if (this.state.inputData.accountNumber) {
            let url = backEndServerURL + "/startCase/sd_maker_maintenance_approval";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                    this.setState({
                        caseId: response.data.caseId,
                        appId: response.data.id,
                        showValue: true,
                        getData: true,
                        getDocument: true,
                        searchData: true
                    });

                })
                .catch((error) => {
                    this.setState({
                        title: "Case Start Failed!",
                        cbNotFound: true
                    })
                })
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData) {
            return (
                <>
                    <Label stopLoading={this.stopLoading} input={this.state.inputData} classes={this.props}/>
                    <br/>
                </>
            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
                            }
                            <Grid item xs={12}></Grid>
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    renderSearchForm = (root) => {
        return (
            <Grid item xs={12}>
                <Grid container>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent, this.onKeyDownForDedup)
                        }
                        <button
                            style={{
                                marginTop: "18px",
                                height: '30px'
                            }}
                            onClick={this.renderStartCase}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Grid>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            onClick={this.handleSubmitDraft}
                        >
                            Save
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let error = false;
        let requestName = "";
        if (this.props.match.params.maintenance === "dormant") {
            requestName = "Dormant Activation";
            error = MyValidation.defaultValidation(dormantActivation, this.state)
        } else if (this.props.match.params.maintenance === "unfreeze") {
            requestName = "Freeze/Unfreeze";
            error = MyValidation.defaultValidation(unfreezeMarking, this.state)
        } else if (this.props.match.params.maintenance === "siDelete") {
            requestName = "SI Delete";
            error = MyValidation.defaultValidation(casaSiDelete, this.state)
        } else if (this.props.match.params.maintenance === "fundTransfer") {
            requestName = "Manual Fund Transfer";
            error = MyValidation.defaultValidation(manualFundTransfer, this.state)
        } else if (this.props.match.params.maintenance === "signature") {
            requestName = "Signature and Photograph Deletion";
            this.state.inputData.signatureDelete = this.state.signatureDeleteFlag;
            // error = MyValidation.defaultValidation(signatureAndPhotograph, this.state)
        } else {
            requestName = this.props.match.params.maintenance;
        }
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let data = this.state.inputData;
            data.freeFlag3 = "(" + requestName + ") Case ID: " + this.state.caseId;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.category = "CASA Closer";
            data.subCategory = "Maintenance";
            data.maker_update_all_info_send_to = "CHECKER";
            data.maker_approval = "APPROVED";
            data.freeFlag1 = "CloserRequest";
            data.freeFlag2 = this.props.match.params.maintenance;
            let remarksData = {};
            remarksData.remark = this.state.inputData.makerRemarks;
            remarksData.map = {...data, makerRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                if (this.state.inputData.checker_approval === "RETURN") {
                    this.props.closeModal();
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();
        let error = false;
        if (error === true) {
            return 0;
        } else {
            let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
            let data = this.state.inputData;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.category = "CASA Closer";
            data.subCategory = "Maintenance";
            data.maker_update_all_info_send_to = "CHECKER";
            data.freeFlag1 = "CloserRequest";
            data.freeFlag2 = this.props.match.params.maintenance;
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Draft!",
                            alert: true
                        });
                        if (this.state.inputData.maker_approval === "RETURN") {
                            this.props.closeModal();
                        } else {
                            setTimeout(function () {
                                window.location.reload()
                            }, 1000);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        }

    };


    signature = () => {
        if (this.state.searchData === true && this.state.getData) {
            return (
                <React.Fragment>
                    {
                        this.previewButton()
                    }
                    <br/>
                </React.Fragment>
            )
        }
    };

    renderParamWiseJson = () => {
        if (this.props.match.params.maintenance === "dormant") {
            return CommonJsonFormComponent.renderJsonForm(this.state, dormantActivation, this.updateComponent)
        } else if (this.props.match.params.maintenance === "unfreeze") {
            return CommonJsonFormComponent.renderJsonForm(this.state, unfreezeMarking, this.updateComponent, this.onKeyDownFunction, this.onKeyDownChangeFunction)
        } else if (this.props.match.params.maintenance === "siDelete") {
            return CommonJsonFormComponent.renderJsonForm(this.state, casaSiDelete, this.updateComponent)
        } else if (this.props.match.params.maintenance === "fundTransfer") {
            return CommonJsonFormComponent.renderJsonForm(this.state, manualFundTransfer, this.updateComponent)
        } else if (this.props.match.params.maintenance === "signature") {
            // return CommonJsonFormComponent.renderJsonForm(this.state, signatureAndPhotograph, this.updateComponent, this.onKeyDownFunction, this.onKeyDownChangeFunction)
            return <Grid container spacing={1} style={{textAlign: "center"}}>{this.renderImage()}</Grid>
        }
    };


    functionForGetAllSignature = () => {
        let start_url = backEndServerURL + "/intgr/signatureMultiple/Finacle/" + this.state.inputData.accountNumber + "/" + `${sessionStorage.getItem("accessToken")}`;
        axios
            .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        multipleSignatureValue: response.data,
                        showSignature: true
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loader: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })
            });
    };

    renderImage = () => {
        if (this.state.showSignature && this.state.multipleSignatureValue.length > 0 && this.props.match.params.maintenance === "signature") {
            return this.state.multipleSignatureValue.map((item) => {
                let url = backEndServerURL + '/intgr/signaturePart/Finacle/' + item.id + "/" + `${sessionStorage.getItem("accessToken")}`;
                return (<Grid item xs={6}><img onLoad={this.stopPageLoad} style={{
                    width: '80%',
                    height: '80%',
                    border: "2px solid #000000",
                    padding: "5px"
                }} src={url}/><p
                    style={{fontSize: "17px", fontWeight: "bold", marginTop: "25px", marginBottom: "25px"}}>Remarks
                    :- {item.remarks}</p><Grid item xs={12}>{this.dynamicDeleteFlag(item.signatureId)}</Grid></Grid>)
            })
        }
    };

    dynamicDeleteFlag = (name) => {
        let field = JSON.parse(JSON.stringify(deleteField));
        field.varName = name;
        return SelectComponent.select(this.state, this.updateComponent, field, this.onKeyDownChange);
    };


    onKeyDownChange = (data) => {
        if (data !== undefined) {
            let array = this.state.signatureDeleteFlag;
            if (this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] === "Y") {
                array.push(data.varName);
                this.setState({
                    signatureDeleteFlag: [...new Set(array)]
                })
            } else if (this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] === "N") {
                array.splice(data.varName, 1);
                this.setState({
                    signatureDeleteFlag: [...new Set(array)]
                })
            }
        }
    };

    stopPageLoad = () => {
        this.setState({
            loading: false
        })
    };


    onKeyDownFunction = (event, data) => {

    };

    onKeyDownChangeFunction = (data) => {

    };


    requestForm = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {this.renderParamWiseJson()}
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };


    previewButton = () => {
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <Grid item xs={12}><UploadComponent appId={this.state.appId} classes={classes}/></Grid>
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderHeader = () => {
        return (
            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                <h4 style={{color: "white"}} >CASA Closer Maintenance</h4>
            </CardHeader>
        )
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                {this.renderHeader()}
                <CardBody>
                    {this.renderSearchForm(classes.root)}
                    {this.renderNotification()}
                    {this.renderCustomerDetails()}
                    {this.signature()}
                    {this.renderExistingLienData()}
                    {this.renderSiData()}
                    {this.requestForm()}
                    <Grid item xs={12}></Grid>
                    {this.uploadFunction()}
                    {this.renderRemarks()}
                    {this.handleSubmitButton()}
                </CardBody>
            </Card>
        );

    }

}

export default withStyles(styles)(CloserRequestWiseMaintenance);
