import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {
    commonInboxButton,
    commonInboxTabActive,
    commonInboxTabInactive
} from "../../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {
    auxiliaryTaskInbox
} from "../ColumnNameForInbox"
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import CSInboxCase from "../CSInboxCase";
import BMInboxCase from "../BMInboxCase";
import BOMInboxCase from "../BOMInboxCase";
import CallCenterInboxCase from "../Maintenance/CallCenterInboxCase";
import MakerInboxCase from "../MakerInboxCase";
import CheckerInboxCase from "../CheckerInboxCase";
import MerchantInboxCase from "../Merchant/MerchantInboxCase";
import VerifyAuxiliaryTask from "./VerifyAuxiliaryTask";
import Button from "@material/react-button";
import RemarksDataList from "./RemarksDataList";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";
let filterField = [


];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class AuxiliaryTaskInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            freeFlag1: "",
            freeFlag2: "",
            initBranchName: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            getCountDormant: 0,
            getCountDormantRectified: 0,
            getCountForCasaOpen: 0,
            getCountForCasaOpenRectified: 0,
            getCountMaintenance: 0,
            getCountMaintenanceRectified: 0,
            getRemittanceGroupCount: 0,
            getRemittanceRectifiedCount: 0,
            getRemittanceGroupCountForBranch: 0,
            id: '',

            requestType: '',
            getAuxiliaryTaskStatus: '',
            createdDate: '',
            createdBy: '',
            modifiedDate: '',
            modifiedBy: '',
            appId:'',
            getappId:'',
            remarksModal:false,
             getCountComplete: 0,
            getCountCurrentStatus: 0,
        }
    }


    renderTableColumn = () => {

        return auxiliaryTaskInbox;
    };


    getApiUrl = () => {
        let url = "";
        /* if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "INTERNET_BANKING" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ADC") {
             url = backEndServerURL + '/workspaceClaimable/0';
         } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM") {
             url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') + "/NOT_REMITTANCE_BRANCH/" + 0;
             this.setState({
                 activeItem: 'Group Inbox',
             })
         } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTCS") {
             url = backEndServerURL + '/inbox/return/0';
             this.setState({
                 activeItem: 'Return',
             })
         } else {
             if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("GROUP LOCK") !== -1) {
                 url = backEndServerURL + '/claimable/withoutRectified/0';
             } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("ACCOUNT OPENING GROUP LOCK") !== -1) {
                 this.setState({
                     activeItem: "Account Opening Group Inbox"
                 })
                 url = backEndServerURL + '/serviceWise/claimable/withoutRectified/' + LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') + "/CASA_OPENING/0";
             } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("REMITTANCE GROUP LOCK SD") !== -1) {
                 this.setState({
                     activeItem: "Remittance Group Inbox SD"
                 })
                 url = backEndServerURL + '/serviceWise/claimable/withoutRectified/MAKER/ONLY_REMITTANCE/0';
             } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("MAINTENANCE GROUP LOCK") !== -1) {
                 this.setState({
                     activeItem: "Maintenance Group Inbox"
                 })
                 url = backEndServerURL + '/serviceWise/claimable/withoutRectified/MAKER/MAINTENANCE_NOT_DORMANT/0';
             } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("DORMANT ACTIVATION GROUP LOCK") !== -1) {
                 this.setState({
                     activeItem: "Dormant Activation Group Inbox"
                 })
                 url = backEndServerURL + '/serviceWise/claimable/withoutRectified/MAKER/DORMANT_ACTIVATION_SD/0';
             }*/

        url = backEndServerURL + '/getGroupInbox/' + LocalstorageEncrypt.encryptStorageFunction.getItem("roles") + "/" + 0;
        return url;
    };

    functionForCategoryCount = () => {
        let countUrl = backEndServerURL + "/inbox/getCountAuxiliaryTask/"+LocalstorageEncrypt.encryptStorageFunction.getItem("roles");
        axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let getData = response.data;
                this.setState({
                     getCountInbox: getData.inbox,
                     getCountGroupInbox: getData.groupInbox,
                     getCountComplete: getData.complete,
                     getCountCurrentStatus: getData.currentStatus,

                });
               // LocalstorageEncrypt.encryptStorageFunction.setItem("inboxNumberOfCount", Number(getData.inbox) + Number(getData.return) + Number(getData.draft));
            })
            .catch((error) => {
                console.log(error);
            });
    };


    componentDidMount() {
        let branch = [];
        //// this.functionForCategoryCount();
        filterField=[];
        this.fetchTableData(this.getApiUrl(), 0)
        /*  let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
          axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
              .then((response) => {
                  response.data.map((item) => {
                      branch.push({key: item.solId, value: item.name})
                  });
                  for (let form of filterField) {
                      if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                          form.enum = branch
                      }
                  }
                  // this.functionForCategoryCount();
                  this.fetchTableData(this.getApiUrl(), 0)

              })
              .catch((error) => {
                  console.log(error);
                  this.setState({
                      getData: true,
                      showTable: true,
                      loading: false,
                  });
              })*/
        var url = backEndServerURL + "/getAllAuxiliaryMappingdata";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let listOfData = [];
                response.data.map((data) => {
                    listOfData.push({value: data.requestType, key: data.role});
                })

                filterField.push(
                    {
                        "varName": "requestTypes",
                        "type": "autoCompleteValueReturn",
                        "enum": listOfData,
                        "label": "Request Type",
                        "required": true,
                        "grid": 4
                    },

                    {
                        "varName": "refId",
                        "type": "text",
                        "label": "Ref. ID",
                        "grid": 2,
                    },

                    {
                        "varName": "fromDate",
                        "type": "date",
                        "label": "From Date",
                        "grid": 2,
                    },
                    {
                        "varName": "toDate",
                        "type": "date",
                        "label": "To Date",
                        "grid": 2,
                    },
                    {
                        "varName": "initiatorId",
                        "type": "text",
                        "lowerCase":true,
                        "label": "Initiator ID",
                        "grid": 2,
                    }

                )

                let inputData = {};
                let varValue = {}
                this.setState({
                   showValue: true,
                    getData: true,
                    loading: false,
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }
    createRemarksTable = (remarks,a,b ) => {
        return (
            [remarks,a,b ]
        )
    };
    handleChangeRemarksView = (event, id) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()


        let url = backEndServerURL + "/auxiliaryTaskRequisition/get/"+id;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
               let remarksArray=[];
                let dataList=0;
                let remarksDataOne=undefined;
                        this.setState({
                           getappId:response.data.appId,
                           remarksModal:true,
                           getData: true,
                           loading: false,
                       })
                   })



            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })


    };
    returnRemarksViewButton = (id) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.handleChangeRemarksView(event, id)}
                >
                    Remarks View
                </Button>


            </>
        )

    }

    fetchTableData = (url, page) => {
         // this.functionForCategoryCount();
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (100 * page)) : index + 1,
                    id: message.id,
                    CaseId: message.caseId,
                    appId: message.appId,
                    /* CaseId: message.caseId,
                     customer_name: message.customerName,
                     cb_number: message.cbNumber,
                     account_number: message.accountNumber,
                     businessSegment: message.businessSegment,
                     category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                     sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                     Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                     Date: message.delInitTime.replace("T", " "),
                     currentUserName: message.currentUserName,
                     branch_name: message.branchName,
                     Status: message.delStatus,
                     freeFlag1: message.freeFlag1,
                     freeFlag2: message.freeFlag2,
                     freeFlag3: message.freeFlag3,
                     freeFlag4: message.freeFlag4,
                     recpmtid: message.recpmtid,
                     freeFlag5: message.freeFlag5,
                     freeFlag6: message.freeFlag6,
                     appId: message.appId,
                     serviceType: message.serviceType,
                     subServiceType: message.subServiceType,
                     taskTitle: message.taskTitle,
                     solId: message.solId,
                     urgency: message.urgency,
                     returnedBack: message.returnBackTo ? message.returnBackTo : "",
                     returnedBy: message.sentByUsername,
                     jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                     requestStatus: message.status,*/
                    requestType: message.requestType,
                    forwardTo: message.forwardTo,
                    createdDate: message.createdDate,
                    createdBy: message.createdBy,
                    modifiedDate: message.modifiedDate,
                    modifiedBy: message.modifiedBy,
                    getAuxiliaryTaskStatus: message.getAuxiliaryTaskStatus,
                    remarksView: this.returnRemarksViewButton(message.id),
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Auxiliary Task Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        if ((freeFlag6 === "Customer Information Update" || freeFlag6 === "Mandate/Related Party Maintenance" || freeFlag6 === "Dormant Activation & Maintenance") && freeFlag1) {
            return freeFlag1.replaceAll("^", ",")
        } else {
            return priorityDesc
        }
    }

    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };


    manageStatusForSearch = () => {
        switch (this.state.activeItem) {
            case "Group Inbox":
                return "ACTIVE";
            case "Inbox":
                return "LOCKED";
            case "Submit":
                return "CLOSED";
            case "Case Status":
                return "ALL";
            default:
                return "ALL"
        }
    }

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        if(postData.requestTypes!==undefined && postData.requestTypes.value!==undefined){
            postData.requestTypes=postData.requestTypes.value;
        }
        let url = backEndServerURL + "/filterAuxiliaryTaskiInbox/" + this.manageStatusForSearch() + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (100 * pageNumber)) : index + 1,
                    id: message.id,
                    CaseId: message.caseId,
                    appId: message.appId,
                    /* CaseId: message.caseId,
                     customer_name: message.customerName,
                     cb_number: message.cbNumber,
                     account_number: message.accountNumber,
                     businessSegment: message.businessSegment,
                     category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                     sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory),
                     Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                     Date: message.delInitTime.replace("T", " "),
                     currentUserName: message.currentUserName,
                     branch_name: message.branchName,
                     Status: message.delStatus,
                     freeFlag1: message.freeFlag1,
                     freeFlag2: message.freeFlag2,
                     freeFlag3: message.freeFlag3,
                     freeFlag4: message.freeFlag4,
                     recpmtid: message.recpmtid,
                     freeFlag5: message.freeFlag5,
                     freeFlag6: message.freeFlag6,
                     appId: message.appId,
                     serviceType: message.serviceType,
                     subServiceType: message.subServiceType,
                     taskTitle: message.taskTitle,
                     solId: message.solId,
                     urgency: message.urgency,
                     returnedBack: message.returnBackTo ? message.returnBackTo : "",
                     returnedBy: message.sentByUsername,
                     jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                     requestStatus: message.status,*/
                    requestType: message.requestType,
                    forwardTo: message.role,
                    createdDate: message.createdDate,
                    createdBy: message.createdBy,
                    modifiedDate: message.modifiedDate,
                    modifiedBy: message.modifiedBy,
                    getAuxiliaryTaskStatus: message.getAuxiliaryTaskStatus,
                    remarksView: this.returnRemarksViewButton(message.id),
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "";
        if (value === "Inbox") {
            urls = backEndServerURL + '/getInbox/' + LocalstorageEncrypt.encryptStorageFunction.getItem("roles") + "/" + page;
        } else if (value === "Group Inbox") {
            urls = backEndServerURL + '/getGroupInbox/' + LocalstorageEncrypt.encryptStorageFunction.getItem("roles") + "/" + page;
        } else if (value === "Submit") {
            urls = backEndServerURL + '/getSubmit/' + LocalstorageEncrypt.encryptStorageFunction.getItem("roles") + "/" + page;
        } else if (value === "Case Status") {
            urls = backEndServerURL + '/getCaseStatus/' + LocalstorageEncrypt.encryptStorageFunction.getItem("roles") + "/" + page;
        }

        this.fetchTableData(urls, page)
    };


    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("GROUP LOCK", "Group Inbox", this.state.getCountGroupInbox, "Group Inbox")}
                    {this.renderMenuItem("INBOX", "Inbox", this.state.getCountInbox, "Self-Inbox")}
                    {this.renderMenuItem("SUBMIT", "Submit", this.state.getCountComplete, "Complete")}
                    {this.renderMenuItem("CASE STATUS", "Case Status", this.state.getCountCurrentStatus, "Current Status")}
                    {/* {this.renderMenuItem("COMPLETE", "Complete", undefined, "Complete")}*/}
                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem, badge, name) => {
        // if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf(permission) !== -1) {
        return (
            <Menu.Item
                name={activeItem}
                active={this.state.activeItem === activeItem}
                style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                onClick={e => {
                    this.headerSelect(e, activeItem);
                }}
            >
                {this.renderBadge(badge, name)}
            </Menu.Item>
        )
        // }

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div  style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeModal}
                                  closeIcon={this.closeModal}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };

    renderInboxCase = () => {
        return (
            <VerifyAuxiliaryTask closeModal={this.closeModal}
                                 closeIcon={this.closeModal}
                                 Id={this.state.id}
                                 appId={this.state.getappId}
                                 createdDate={this.state.createdDate}
                                 createdBy={this.state.createdBy}
                                 modifiedDate={this.state.modifiedDate}
                                 modifiedBy={this.state.modifiedBy}
                                 getAuxiliaryTaskStatus={this.state.getAuxiliaryTaskStatus}
                                 getAuxiliaryTaskStatus={this.state.getAuxiliaryTaskStatus}
                                 requestType={this.state.requestType}/>
        )


    };
    renderRemarksModalComponent = () => {
        return (
            <RemarksDataList closeModal={this.closeModal}
                                 closeIcon={this.closeModalForRemarks}
                             appId={this.state.getappId}
    />
        )


    };

    onRowClick = (event, rowData) => {
         event.preventDefault();
        if (this.state.activeItem === "Submit" || this.state.activeItem === "Case Status") {

        } else if (this.state.activeItem === "Group Inbox") {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/auxiliaryTaskRequisition/lock/" + rowData.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setInboxProps({...rowData, appId: response.data})
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.setInboxProps(rowData)
        }
    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            id: rowData.id,
            getAppId:rowData.appId,
            requestType: rowData.requestType,
            createdDate: rowData.createdDate,
            createdBy: rowData.createdBy,
            modifiedDate: rowData.modifiedDate,
            modifiedBy: rowData.modifiedBy,
            getAuxiliaryTaskStatus: rowData.getAuxiliaryTaskStatus,
            showInbox: true,
            loading: false
        });
    };

    functionForCaseLockOrUnlock = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.appId)
        }
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Maintenance Group Inbox") {
            single_url = backEndServerURL + "/case/claim/";
        } else {
            single_url = backEndServerURL + "/case/unClaim/";
        }

        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER") && (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Remittance Group Inbox SD")) {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if ((this.state.activeItem === "Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER") || this.state.activeItem === "Return") {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                // selection={(this.state.activeItem === "Group Inbox" || this.state.activeItem === "Inbox" || this.state.activeItem === "Return" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox")}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                /*tableButton={this.renderTopLabelButton()}*/
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            remarksModal: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };
    closeModalForRemarks = () => {
        this.setState({
             remarksModal: false,
            loading: false,
        } )
    };

    renderInboxComponent = () => {
        if (this.state.activeItem === "Submit" || this.state.activeItem === "Case Status") {
            //  return this.renderInboxCaseSubmitted()
        } else {
            return this.renderInboxCase()
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.remarksModal}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderRemarksModalComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(AuxiliaryTaskInbox);
