import Grid from "@material-ui/core/Grid";
import React from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class DateComponent {
    static  dateConverter = (value) => {
        let splitData = (value).split(',')[0];
        return splitData.split('T')[0];
    };

    static handleChange(onKeyDownChange, date, field, state, update) {
        state.selectedDate[field.varName] = date;
        state.inputData[field.varName] = (date !== null) ? this.dateConverter(date.toLocaleString('en-US')) : "";
        if (field.onKeyDown === true) {
            let data = {};
            data.varName = field.varName;
            onKeyDownChange(data);
        }
        update();
    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(DD-MM-YYYY) *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(DD-MM-YYYY)</label>
                </Grid>
            )
        }
    };

    static returnDefaultValue(state, field) {
        if (state.showValue) {
            let dateString = state.varValue[field.varName];
            if (dateString === -1)
                return state.selectedDate[field.varName];

            if (dateString === null || dateString === undefined || (dateString !== undefined && dateString.trim() === '')) {

                state.selectedDate[field.varName] = null;
                state.varValue[field.varName] = -1;
                return null;
            }

            let dateMonthYear = dateString.split(",")[0];
            let seperatedDateMonthYear = dateMonthYear.split("/");
            state.selectedDate[field.varName] = seperatedDateMonthYear;
            state.varValue[field.varName] = -1;
            return seperatedDateMonthYear;
        } else {

            if (state.selectedDate[field.varName] === undefined)
                return null;
            else
                return state.selectedDate[field.varName];
        }


    }

    static date(state, update, field, onKeyDownChange) {

        let styles = { paddingRight: 20};
        if (field.readOnly === true) {
            const firstStyle = {
                marginRight: 20,
                background: '#f8f9fa'
            };
            styles = Object.assign(styles, firstStyle)
        } else {
            const firstStyle = {
                marginRight: 20,
            };
            styles = Object.assign(styles, firstStyle)
        }
        if (field.highlight === true) {
            const secondStyle = {
                color: '#ffffff',
                background: 'red'
            };
            styles = Object.assign(styles, secondStyle)
        }

        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }

                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className={field.highlight === true ? "highLight-color" : ""}
                            style={styles}
                            error={state.errorArray[field.varName]}
                            helperText={state.errorMessages[field.varName]}
                            shouldDisableDate={field.readOnly}
                            minDate={field.minDate === true ? new Date() : undefined}
                            maxDate={field.maxDate === true ? new Date() : undefined}
                            value={this.returnDefaultValue(state, field)}
                            inputVariant="outlined"
                            disabled={field.readOnly}
                            allowKeyboardControl={false}
                            readOnly={field.readOnly}
                            placeholder="dd-mm-yyyy"
                            required={field.readOnly === true ? false : field.required}
                            fullWidth
                            onBlur={(event) => {
                                if (field.onKeyDown === true) {
                                    let data = {};
                                    data.varName = field.varName;
                                    onKeyDownChange(data);
                                }
                            }}
                            onChange={(date) => this.handleChange(onKeyDownChange, date, field, state, update)}
                            format="dd-MM-yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </React.Fragment>
        )
    }
}

export default DateComponent;