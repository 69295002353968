import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';

import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import loader from '../../../Static/loader.gif';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Scan and Upload File",
    "grid": 12
};
var selectFileName = {
    "varName": "fileName",
    "type": "dropdown",
    "required": true,
    "label": "This photo name",

    "grid": 6
};
const arrayListIndividual = [

    {label: 'APPLICATION PHOTOGRAPH'},
    {label: 'NOMINEE PHOTOGRAPH'},
    {label: 'ADDRESS PHOTOGRAPH'},
    {label: 'OTHER'}
];

let arrayListNonIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'NOMINEE NID'},
    {label: 'NOMINEE PASSPORT'},
    {label: 'NOMINEE DRIVING LICENSE'},
    {label: 'NOMINEE BIRTH CERTIFICATE'},
    {label: 'SIGNATURE CARD'},
    {label: 'OTHERS'},


];
let customerNonIndividual = [
    /* { label: 'IIF1'},
     { label: 'IIF2'},
     { label: 'NID'},
     { label: 'PASSPORT'},
     { label: 'DRIVING LICENSE'},
     { label: 'BIRTH CERTIFICATE'},*/


];

class DefferalMapingImageEdit extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        loader: null,
        inputData: {},
        dropdownSearchData: {},
        autoComplete: {},
        showValue: true,
        varValue: {},
        getData: false,
        getImageLink: [],
        errorMessages: {},
        errorArray: {},


    };

    constructor(props) {
        super(props);
        this.renderJointInformation()
    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderJointInformation = () => {
        for (let i = 0; i < this.props.jointAccountCustomerNumber; i++) {
            arrayListNonIndividual.push(
                {label: "Customer " + (i + 1) + ' IIF1'},
                {label: "Customer " + (i + 1) + ' IIF2'},
                {label: "Customer " + (i + 1) + ' NID'},
                {label: "Customer " + (i + 1) + ' PASSPORT'},
                {label: "Customer " + (i + 1) + ' DRIVING LICENSE'},
                {label: "Customer " + (i + 1) + ' BIRTH CERTIFICATE'},
            )
        }

    };
    close = () => {

        this.props.closeIcon();
    };

    componentDidMount() {

        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                //this.renderDropdownData(response.data)
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL") {
                    this.renderDropdownData(response.data)

                } else {
                    this.renderDropdownData(response.data)

                    //this.renderDropdownDataNonIndividual(response.data)
                }


                this.setState({
                    getSplitFile: response.data,
                    getImageLink: response.data,
                    loader: false,
                    getData: true
                })

            })
            .catch((error) => {
                console.log(error);
            })

    }

    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            loader: true
        });

        let url = backEndServerURL + "/case/renameFiles";
        axios.post(url, {
            appId: this.props.appId,
            fileNames: this.state.inputData
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.props.closeModal(response.data);
                this.setState({
                    loader: false
                })
            })
            .catch((error) => {
                console.log(error.data);
                console.log(error);

                this.setState({
                    loader: false
                })
            })

    };
    renderDropdownData = (data) => {

        data.map((name, index) => {

            //this.state.autoComplete[name] =arrayListIndividual;
            console.log(name);

            let nameShow = name.split('_')[1].split('.')[0];

            this.state.autoComplete[name] = arrayListIndividual;

            this.state.varValue[name] = {label: nameShow};


        })


    };

    renderDropdownDataNonIndividual = (data) => {
        data.map((name, index) => {
            this.state.autoComplete[name] = arrayListNonIndividual;
            if (index < 7) {
                this.state.varValue[name] = arrayListNonIndividual[index];
                this.state.inputData[name] = arrayListNonIndividual[index].label;
            } else
                for (let i = 0; i < this.props.jointAccountCustomerNumber; i++) {
                    if (index === 7 + 2 * i) {
                        this.state.varValue[name] = arrayListNonIndividual[13 + 6 * i];
                        this.state.inputData[name] = arrayListNonIndividual[13 + 6 * i].label;
                    } else if (index === 7 + (2 * i + 1)) {
                        this.state.varValue[name] = arrayListNonIndividual[13 + (6 * i + 1)];
                        this.state.inputData[name] = arrayListNonIndividual[13 + (6 * i + 1)].label;
                    }
                }
        })


    };

    renderCommonIndividual = (data, index) => {

        if (this.state.getData) {
            return (
                <Grid item xs={4}>
                    {/*
                        <div>


                            <br/>
                            {AutoCompleteComplete.renderSelect(this.state, this.updateComponent, {
                                "label": "Your Photo Name",
                                "varName": data
                            })}

                            <br/>

                        </div>*/}
                    <img width='90%'
                         src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")} alt=""/>

                    <br/>
                </Grid>


            )

        }

    };
    renderCommonNonIndividual = (data, index) => {

        if (this.state.getData) {
            return (
                <Grid item xs={4}>

                    {/*  <div>


                            <br/>
                            {AutoCompleteComplete.renderSelect(this.state, this.updateComponent, {
                                "label": "Your Photo Name",
                                "varName": data
                            })}

                            <br/>

                        </div>*/}
                    <img width='90%'
                         src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")} alt=""/>

                    <br/>
                </Grid>


            )

        }

    };

    renderloader = () => {
        console.log(this.props.subServiceType);

        /*(this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")*/
        if (this.state.loader === false && this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Proprietorship A/C" && this.props.subServiceType !== "NONINDIVIDUAL") {
            return (
                <Grid container spacing={1}>
                    {
                        this.state.getSplitFile.map((data, index) => {
                            //console.log(index)
                            return (

                                this.renderCommonNonIndividual(data, index)


                            )

                        })

                    }
                </Grid>
            )
        } else if (this.state.loader === false) {
            return (
                <Grid container spacing={1}>
                    {
                        this.state.getSplitFile.map((data, index) => {
                            //console.log(index)
                            return (

                                this.renderCommonIndividual(data, index)


                            )

                        })

                    }
                </Grid>
            )
        }
        //this.updateComponent();
    };

    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <h4 style={{color: "white"}} >Mapping Image<a><CloseIcon onClick={this.close}
                                                           style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <br/>

                            <ThemeProvider theme={theme}>
                                <div>

                                    <Grid container spacing={1}>
                                        {this.renderloader()}
                                    </Grid>
                                </div>


                                <div>

                                    <br/>
                                    <br/>
                                    <center>
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{
                                                verticalAlign: 'right',

                                            }}

                                            type='button' value='add more'
                                            onClick={this.handleSubmit}
                                        >Submit
                                        </button>
                                    </center>

                                </div>
                            </ThemeProvider>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(DefferalMapingImageEdit);