import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import MyValidation from "../../JsonForm/MyValidation";
import CommonApi from "../Maintenance/common/CommonApi";

let FundTransferJsonFormOne = [
    // {
    //     "label": "Source",
    //     "varName": "source",
    //     "type": "select",
    //     "enum": [
    //         "Finacle",
    //         "Ababil"
    //     ],
    //     "readOnly": true,
    //     "grid": 2,
    // },
    {
        "label": "Request Type",
        "type": "select",
        "varName": "requestType",
        "enum": [
            "Single Transaction",
            "Bulk Transaction"
        ],
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
    {
        "label": "Transfer Type",
        "type": "text",
        "varName": "transferType",
        "grid": 2,
        "readOnly": true
    },
    // {
    //     "label": "Customer Type",
    //     "type": "text",
    //     "varName": "customerType",
    //     "grid": 6,
    //     "readOnly": true
    // },
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "grid": 2,
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "readOnly": true
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
];

const fundTransferFormForBranch = [
    {
        "label": "Beneficiary Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 2,
        "readOnly": true,
        // "validation": "numeric",
    },
    {
        "label": "Beneficiary Name",
        "type": "text",
        "varName": "beneficiaryDetails",
        "grid": 2,
        "readOnly": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "label": "Beneficiary Bank Name",
        "type": "text",
        "varName": "beneficiaryBankName",
        "grid": 4,
        "validation": "string",
        "readOnly": true,
    },
    {
        "label": "Branch Name",
        "type": "text",
        "varName": "branchName",
        "grid": 4,
        "readOnly": true,
        "validation": "string"
    },
    {
        "label": "Routing Number",
        "type": "text",
        "varName": "routingNumber",
        "grid": 4,
        "readOnly": true,
    },
];

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class BmInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            selectedDate: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getDeferalList: [],
            getData: false,
            signature: false,
            documentList: [],
            getDocument: false,
            getRemarks: [],
            cbNotFound: false,
            loading: true,
            disabled: false,
            documentListSDN: [],
            getDocumentSDN: false,
        }
    }


    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {
        this.functionForFileGet();
        this.functionForSDNFileGet();
        CommonApi.getRemarkDetails(this.props.appId)
            .then((remarksArray) => {
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error);
            });

        CommonApi.getDeferralDetails(this.props.appId)
            .then((tableArray) => {
                this.setState({
                    getDeferalList: tableArray
                });
                CommonApi.getFiles(this.props.appId).then((fileData) => {
                    this.setState({
                        getImageLink: fileData,
                        getImageBoolean: true
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            });

        CommonApi.getVariables(this.props.appId)
            .then((response) => {
                let inputData = {...response.data};
                inputData.source ="Finacle"
                if (inputData.source === "Ababil") {
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false,
                        });
                    });
                } else {
                    CommonApi.getCustAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false,
                        });
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false,
                });
            })
    }

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-cs-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }

    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    jsonFormForBranch = () => {
        if (this.state.inputData.branchEnd) {
            return CommonJsonFormComponent.renderJsonForm(this.state, fundTransferFormForBranch, this.updateComponent)
        }
    };

    formWithData = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormOne, this.updateComponent)
                    }
                    {this.jsonFormForBranch()}
                </ThemeProvider>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    functionForHandleSubmit = (value) => {
        this.setState({
            disabled: true
        });
        let appId = this.props.appId;
        let approval = value;
        let deferralUrl = backEndServerURL + "/deferral/approval";
        axios.post(deferralUrl, {
            appId,
            approval
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        let postData = {...this.state.inputData};
        postData.bm_approval = value;
        postData.cs_deferal = "NO";
        postData.csDeferal = "NO";
        let remarksData = {};
        remarksData.remark = postData.bmRemarks;
        remarksData.map = {...postData, bmRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                disabled: false,
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                loading: false
            });
            this.props.closeModal()
        }).catch((error) => {
            console.log(error);
            this.setState({
                disabled: false,
                loading: false
            })
        });
    };

    approveHandler = (e) => {
        e.preventDefault();
        if (this.state.inputData.requestType === "Single Transaction") {
            let error = MyValidation.defaultValidation(FundTransferJsonFormOne, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                this.functionForHandleSubmit("APPROVE")
            }
        } else {
            this.functionForHandleSubmit("APPROVE")
        }
    };

    functionForExlDownload = (document) => {
        let download_url = backEndServerURL + "/file/" + document;
        axiosDownload.downloadFile(download_url, 'GET', {}, 'Fund Transfer Attachment.exl');
    };

    renderBulkFile = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.customerType) {
            return (
                this.state.documentList.map((document, index) => {
                    if (document.split(".")[1] === "exl") {
                        return (
                            <React.Fragment>
                                <button className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'middle',
                                            marginTop: '12px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() => this.functionForExlDownload(document)}
                                >Branch File {index + 1}</button>

                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment>
                                <a className="btn btn-outline-primary btn-sm"
                                   style={{
                                       verticalAlign: 'middle',
                                       marginTop: '12px',
                                       marginRight: '10px',
                                   }}
                                   target="_blank"
                                   href={backEndServerURL + "/file/" + document + "/" + sessionStorage.getItem("accessToken")}>Branch
                                    File {index + 1}</a>

                            </React.Fragment>
                        )
                    }
                })
            );
        }
    };

    functionForSDNFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-sdn-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentListSDN: response.data,
                    getDocumentSDN: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    downloadSDNFile = () => {
        if (this.state.getDocumentSDN && this.state.documentListSDN.length > 0) {
            return (
                this.state.documentListSDN.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForSDNDownload(document)}
                            >SDN File {index + 1}</button>
                        </React.Fragment>
                    )
                })
            );
        }
    };

    functionForSDNDownload = (value) => {
        let url = backEndServerURL + "/file/" + value + "/" + sessionStorage.getItem("accessToken");
        window.open(url);
    };

    renderRemarks = () => {
        if (this.state.getData) {

            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)}
                </ThemeProvider>
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>
                </div>
            )
        }
    };

    signature = () => {
        if (this.state.getData) {
            return <><br/><SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple" requestType={this.state.inputData.source} classes={this.props}/></>;
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table

                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }

    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Grid container>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        {this.formWithData()}
                        <Grid item xs={12}>
                            {this.signature()}
                        </Grid>
                        {this.renderNotification()}
                        {this.renderBulkFile()}
                        <Grid item xs={12}></Grid>
                        {this.downloadSDNFile()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                        {this.renderRemarks()}
                    </Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                marginTop: '12px',
                            }}
                            onClick={this.approveHandler}
                            type="submit"
                            disabled={this.state.disabled}
                        >
                            Submit
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                marginTop: '12px',
                                marginLeft: '10px'
                            }}
                            disabled={this.state.disabled}
                            onClick={() => this.functionForHandleSubmit("REJECT")}
                            type="submit"
                        >
                            Return
                        </button>
                    </Grid>
                </div>
            )
        }
    }
}

export default withStyles(styles)(BmInbox);
