import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {assetOpsBackEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import Button from "@material/react-button";
import ChangeTimeFormat from "../CommonComponent/ChangeTimeFormat";
import SdnAndDedupeResultShowing from "./SdnAndDedupeResultShowing";
import DeferalList from "./DeferalList";
import EmployeeDeatils from "./EmployeeDeatils";
import CardMaintenanceRequest from "../CardCallCategory/CardMaintenanceRequest";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class SubmittedCaseHistoryAwf extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            loading: true,
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            tableData: [],
            file: {},
            defferalId: "",
            uploadModal: false,
            getMappingAllImage: false,
            notificationMessage: "",
            getDownloadListFlag: false,
            SdnAndDedupeResultShowing: false,
            deferalListShowing: false,
            fileNotFoundTittle: "",
            downLoadFileList: [],
            getUserStaticData: false,
            viewModal: false,
            userIdForDetails: "",
            userGroupNameForDetails: "",

        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    closeModalForCustomerDetails = () => {
        this.setState({
            loading: false,
            userDeatils: false,
            userIdForDetails: "",
            userGroupNameForDetails: "",
        })
    }
    handleChangeCustomerDetails = (currentUserName, currentUserRole) => {
        if(this.props.forLive!==true   ){
            this.setState({
                userDeatils: true,
                userIdForDetails: currentUserName,
                userGroupNameForDetails: currentUserRole,
            })
        }


    }
    createTableData = (appId, caseId, currentUserName, currentUserRole, branchName, category, subCategory, delInitTime, delFinishTime, activity, remarks) => {


        return ([(currentUserRole.includes(" Group") === false) && [

            <span>
              <a href="javascript: void(0)"
                 onClick={() => this.handleChangeCustomerDetails(currentUserName, currentUserRole)}>{currentUserName}</a>
             </span>
        ], currentUserRole, branchName, category, subCategory, delInitTime, delFinishTime, activity, remarks
        ])

    };
    downloadDocument = (name) => {
        let url = assetOpsBackEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    componentDidMount() {

        if (this.props.globalSearch) {
            let downLoadFileList = [];
            let url = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
            if (this.props.freeFlag2 === true) {
                url = assetOpsBackEndServerURL + "/archivedCase/files/" + this.props.appId;
            }
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((data, index) => {
                        downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                    });
                    console.log(response.data);
                    this.setState({
                        getDownloadListFlag: true,
                        downLoadFileList: downLoadFileList
                    })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        fileNotFoundTittle: this.errorObjectCheck(error),
                    })
                })
        }
        let url = assetOpsBackEndServerURL + "/perticipatedHistory/" + this.props.appId;
        if(this.props.forLive===true){
              url = assetOpsBackEndServerURL + "/intgr/external/api/perticipatedHistory/" + this.props.appId;

        }
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(this.props.appId);
                let tableArray = [];
                let delInitTime = [];
                let delFinishTime = [];
                console.log(response.data);
                response.data.map((data,inbox) => {
                    if (data.delInitTime !== null && data.delInitTime !== undefined) {
                        delInitTime = ChangeTimeFormat.convertTime(data.delInitTime)
                    } else {
                        delInitTime = ChangeTimeFormat.convertTime(data.delInitTime);
                    }
                    if (data.delFinishTime !== null && data.delFinishTime !== undefined) {
                        delFinishTime = ChangeTimeFormat.convertTime(data.delFinishTime)
                    } else {
                        delFinishTime = null;

                    }
                    let dataLength=response.data.length;
                    if( this.props.cardMaintenance===true && dataLength!==(inbox+1) ) {

                        tableArray.push(this.createTableData(data.appId, data.caseId, data.currentUserName, data.currentUserRole, data.branchName, this.props.category, this.props.subCategory, delInitTime, delFinishTime, data.activity, data.remarks));
                    }
                   else if( this.props.cardMaintenance!==true) {

                        tableArray.push(this.createTableData(data.appId, data.caseId, data.currentUserName, data.currentUserRole, data.branchName, this.props.category, this.props.subCategory, delInitTime, delFinishTime, data.activity, data.remarks));

                    }
                });

                this.setState({
                    tableData: tableArray,
                    varValue: response.data,
                    showValue: true,
                    getData: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    loading: false,
                })
            })


    }

    renderSubmittedHistory = () => {
        if (this.state.getData) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "2px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"

                            tableHead={["Employee Id", "Employee Role", "User's Location", "Category", "Sub Category", "Start Date & Time", "End Date & Time", "Status", "Remarks"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.props.globalSearch && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    renderMultiTaskLink = () => {
        if (this.props.multiTaskLink === true || (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening")) {
            return (
                <div>
                    <h5><a
                        style={{
                            verticalAlign: 'left',
                            position: "absolute",
                            right: 10,
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                        target="_blank"
                        href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                        Multitask Link</a></h5>


                </div>)

        } else {

        }
    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    handleDeferalListResult = (event) => {
        event.preventDefault();
        this.setState({
            deferalListShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }

    closeModalForDeferalListResult = () => {
        this.setState({
            deferalListShowing: false
        })
    }

    renderSDNDedupeResult = () => {
        if (this.props.category === 'CASA' || this.props.category === 'DPS' || this.props.category === 'FDR') {
            return (
                <Button
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}
                    onClick={(event) => this.handleChangeSdnResult(event)}
                >
                    Full Dedupe and SDN result
                </Button>
            )
        }
    };

    renderDeferralList = () => {
        if (this.props.category === 'CASA' || this.props.category === 'DPS' || this.props.category === 'FDR') {
            return (
                <Button
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}
                    onClick={(event) => this.handleDeferalListResult(event)}
                >
                    Deferrals
                </Button>
            )
        }
    };

    renderCardMaintenanceDetails = () => {
        if (this.props.dataPassArray !== undefined) {
            return (
                <Button
                    style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        verticalAlign: 'left',
                        position: "absolute",
                        marginTop: "5px",
                        right: 50,
                    }}
                    onClick={(event) => this.handleViewDetails(event)}
                >
                    View Details
                </Button>
            )
        }
    };

    handleViewDetails = () => {
        this.setState({
            viewModal: true
        })
    };


    close = () => {
        this.props.closeModal()
    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })
    };


    closeIcon = () => {
        this.props.closeIcon()
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };

    cardMaintenanceViewModal = () => {
        this.setState({
            viewModal: false
        })
    };

    renderHeader = () => {
        return (
            <h4 style={{color: "white"}} >Summary- {this.props.serviceType}<br/>
                (<b> Case
                    Id:</b>{this.props.caseId !== undefined ? this.props.caseId : ""}<b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""}<b> CB
                    Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                <b> Account
                    Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}<b> Account
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                {/* <b> Scheme Code:</b>{this.props.schemeCode !== undefined ? this.props.schemeCode : ""}*/} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>
        )
    };

    render() {

        const {classes} = this.props;


        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>

                                        <Grid item xs={12}>
                                            {this.renderSDNDedupeResult()}
                                            {this.renderCardMaintenanceDetails()}

                                            <br/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderDeferralList()}
                                            <br/>
                                        </Grid>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.SdnAndDedupeResultShowing}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <SdnAndDedupeResultShowing closeModal={this.closeModalForSdnResult}
                                                                           branchName={this.props.branchName}

                                                                           subServiceType={this.props.subServiceType}
                                                                           category={this.props.category}
                                                                           serviceType={this.props.serviceType}
                                                                           subCategory={this.props.subCategory}
                                                                           freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                           serviceType={this.props.serviceType}
                                                                           customerName={this.props.customerName}
                                                                           accountNumber={this.props.accountNumber}
                                                                           cbNumber={this.props.cbNumber}
                                                                           solId={this.props.solId} globalSearch={true}
                                                                           closeIcon={this.closeModalForSdnResult}
                                                                           appId={this.props.appId}/>
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.deferalListShowing}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <DeferalList closeModal={this.closeModalForDeferalListResult}
                                                             branchName={this.props.branchName}

                                                             subServiceType={this.props.subServiceType}
                                                             category={this.props.category}
                                                             serviceType={this.props.serviceType}
                                                             subCategory={this.props.subCategory}
                                                             freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                             serviceType={this.props.serviceType}
                                                             customerName={this.props.customerName}
                                                             accountNumber={this.props.accountNumber}
                                                             cbNumber={this.props.cbNumber}
                                                             solId={this.props.solId} globalSearch={true}
                                                             closeIcon={this.closeModalForDeferalListResult}
                                                             appId={this.props.appId}
                                                />
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            open={this.state.viewModal}>
                                            <DialogContent>
                                                <CardMaintenanceRequest appId={this.props.appId}
                                                                        arrayValue={this.props.dataPassArray}
                                                                        closeModal={this.cardMaintenanceViewModal}
                                                                        headerValue="All"
                                                                        statusFlag={this.props.statusFlag}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.userDeatils}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <EmployeeDeatils closeModal={this.closeModalForCustomerDetails}
                                                                 branchName={this.props.branchName}
                                                                 userId={this.state.userIdForDetails}
                                                                 groupName={this.state.userGroupNameForDetails}
                                                                 subServiceType={this.props.subServiceType}
                                                                 category={this.props.category}
                                                                 serviceType={this.props.serviceType}
                                                                 subCategory={this.props.subCategory}
                                                                 freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                 serviceType={this.props.serviceType}
                                                                 customerName={this.props.customerName}
                                                                 accountNumber={this.props.accountNumber}
                                                                 cbNumber={this.props.cbNumber}
                                                                 solId={this.props.solId} globalSearch={true}
                                                                 closeIcon={this.closeModalForCustomerDetails}
                                                                 appId={this.props.appId}/>
                                            </DialogContent>
                                        </Dialog>
                                        {/* <Grid item xs={12}>
                                            <DeferalList appId={this.props.appId}/>
                                        </Grid>*/}
                                        {/*<Grid item xs={12}>*/}

                                        {/*    { this.props.forLive!==true &&  <WaiverList appId={this.props.appId}/>}*/}
                                        {/*</Grid>*/}
                                        <br/>


                                        <Grid item xs={12}>
                                            {this.renderSubmittedHistory()}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {this.props.forLive!==true && this.renderDownLoadFileLink()}

                                        </Grid>
                                        <Grid item xs={6}>
                                            {this.props.forLive!==true && this.renderMultiTaskLink()}

                                        </Grid>

                                    </ThemeProvider>


                                </Grid>
                            </div>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default withStyles(styles)(SubmittedCaseHistoryAwf);
