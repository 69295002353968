import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import VerifyMerchantPhoto from "./VerifyMerchantPhoto";
import SplitMaker from './SplitMaker'


class MouMakerUpdateInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            sendTo: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            agreementSignUpDate: "",
            remarks: false,
            getRemarkList: []
        }
    }


    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    modalClose = () => {
        this.props.closeModal();
    };

    componentDidMount() {
        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                //console.log(response.data)
                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    getData: true
                });


                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            documentList: response.data,
                            getDocument: true
                        })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }


    inboxCasePhoto = () => {
        if (this.state.getDocument) {
            return (<VerifyMerchantPhoto closeModal={this.props.closeModal}
                                         titleName="Maker Update All Information"
                                         documentList={this.state.documentList}/>)
        }

    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    inboxCase = () => {
        return (<SplitMaker closeModal={this.props.closeModal}
                            titleName="Maker Update All Information"
                            appId={this.props.appId}/>)
    };


    render() {

        return (
            <Grid container spacing={0}>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Merchant Onboarding Request<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={0}>

                                    <ThemeProvider theme={theme}>

                                        <Grid item xs={7}>

                                            {this.inboxCasePhoto()}

                                        </Grid>


                                        <Grid item xs={5}>
                                            {
                                                this.inboxCase()
                                            }
                                        </Grid>


                                        {
                                            this.renderNotification()
                                        }


                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </Grid>

        );
    }
}

export default MouMakerUpdateInfo;