import React, {Component} from "react";
import axios from "axios";

class functionForFileDownload extends Component {
    static downloadFile(url, method, data, fileName) {
        return (
            axios({
                url: url,
                method: method,
                data: data,
                responseType: 'blob', // important
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'application/json'
                }
            }).then((response) => {
                if (response.status === 200) {
                    if (response.data.size > 0) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        return "file found"
                    } else {
                        return "File Not Found"
                    }
                }
            })
        )
    }
}

export default functionForFileDownload
