import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import TableComponent from "../../workflow/CommonComponent/TableComponent";
import ConfirmAlert from "../../workflow/CommonComponent/ConfirmAlert";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import MyValidation from "../../JsonForm/MyValidation";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#d81c26",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const tableColumnName = [
    {field: "sl", title: "SL"},
    {field: "documentType", title: "Document Type"},
    {field: "code", title: "Code"},
    {field: "priority", title: "Priority"},
    {field: "status", title: "Status"},
];
const jsonForm = [
    {
        varName: "documentType",
        type: "text",
        required: true,
        label: "Document Type",
        grid: 2,
    },
    {
        varName: "code",
        type: "text",
        required: true,
        label: "Code",
        grid: 2,
    },
    {
        varName: "priority",
        type: "select",
        enum: ["HIGH", "MEDIUM", "LOW"],
        required: true,
        label: "Priority",
        grid: 2,
    },
    {
        varName: "status",
        type: "select",
        label: "Status",
        required: true,
        grid: 2,
        enum: ["ACTIVE", "INACTIVE"],
    }
];


class DocumentTypeConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            getMappingAllImage: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            allData: [],
            selected: [],
            totalRow: 0,
            rowsPerPage: 250,
            tableColumns: [],
            pageNumber: 0,
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            actionAppId: "",
            actionType: "",
            confirmAlert: false,
            showModal: false,
            updateId: 0,
            update: false,
            cardTypeResponseData: {},
            fileUploadData: {},
            showFileModal: false,
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        tableColumn = [...tableColumnName];
        return tableColumn;
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        this.setState({
            loading: false,
            showValue: true,
            getData: true,
        })
        this.fetchDataFromAPI("/getAllDocumentTypes/", 0);
    }
    handleCommon = (event, rowId, type) => {
        event.preventDefault();
        this.setState({
            actionAppId: rowId,
            actionType: type,
            confirmAlert: true,
        })
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderCloseIcon = () => {
        return (
            <h4 style={{

                color: "white",
            }}>Document Type Configuration</h4>
        )
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };
    changeToInactive = (event, data) => {
        if (data === undefined) return 0;

        let listData = [];
        for (let item of data) {
            let idListData = {
                id: item.rowId,
                status: item.status
            }
            listData.push(idListData);
        }
        if (listData.length > 0) {
            this.setState({
                loading: true
            });
            this.statusUpdate(listData, "Inactive")
        }
    }
    changeToActive = (event, data) => {
        console.log(event)
        if (data === undefined) return 0;

        let listData = [];
        for (let item of data) {
            let idListData = {
                id: item.rowId,
                status: item.status
            }
            listData.push(idListData);
        }
        if (listData.length > 0) {
            this.setState({
                loading: true
            });
            this.statusUpdate(listData, "Active")
        }
    }
    handleChangePage = (pageNumber, data) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let page;
        page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        this.fetchDataFromAPI("/getAllDocumentTypes/", page)
    };
    fetchDataFromAPI = (url, pageNumber) => {
        this.setState({
            loading: true,
        })
        url = backEndServerURL + url + pageNumber;
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];

                response.data.content.map((message, index) => {
                    tableData.push({
                        "sl": pageNumber > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * pageNumber)) : index + 1,
                        "rowId": message.id,
                        "documentType": message.documentType,
                        "code": message.code,
                        "priority": (message.priority !== undefined && message.priority == '1') ? 'HIGH'
                            : (message.priority !== undefined && message.priority == '2') ? 'MEDIUM'
                                : (message.priority !== undefined && message.priority == '3') ? 'LOW'
                                    : "",
                        "status": message.status,
                    })
                })
                this.setState({
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    allData: tableData,
                    getData: true,
                    showTable: true,
                    loading: false,
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };


    renderTable = () => {
        if (this.state.getData && this.state.showTable) {
            return (
                <TableComponent
                    tableButton={[
                        {
                            name: "ACTIVE",
                            triggerFunction: this.changeToInactive
                        },
                        {
                            name: "INCTIVE",
                            triggerFunction: this.changeToActive
                        },
                    ]}
                    tableData={this.state.allData}
                    tableColumns={this.renderTableColumn()}
                    loader={this.state.loading} totalRow={this.state.totalRow} page={this.state.pageNumber}
                    whiteSpace={"wrap"}
                    selection={true}
                    handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                />
            )
        }
    };

    closeModal = () => {
        this.setState({
            getData: true,
            showTable: true,
            loading: false,
            showModal: false,
            update: false,
        })
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false,
            loading: true,
        });
    };

    fetchTableData = (url) => {
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];

                response.data.content.map((message, index) => {
                    tableData.push({
                        "sl": index + 1,
                        "rowId": message.id,
                        "documentType": message.documentType,
                        "code": message.code,
                        "priority": (message.priority !== undefined && message.priority == '1') ? 'HIGH'
                            : (message.priority !== undefined && message.priority == '2') ? 'MEDIUM'
                                : (message.priority !== undefined && message.priority == '3') ? 'LOW'
                                    : "",
                        "status": message.status,
                    })
                })
                this.setState({
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    allData: tableData,
                    getData: true,
                    showTable: true,
                    loading: false,
                })
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });


    };

    statusUpdate = (idList, status) => {
        this.setState({
            loading: true,
            showTable: false
        });
        let url = backEndServerURL + "/document/change-status/" + status;
        axios.post(url, idList, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let urls = "";
                urls = backEndServerURL + '/getAllDocumentTypes/0';
                this.fetchTableData(urls);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Status Changed Successfully!",
                    alert: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            })
    };
    renderJsonForm = () => {
        return (
            <Grid container>
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)}

                    <Grid item xs={3}>
                        <button
                            className="btn btn-outline-primary   btn-sm"
                            style={{
                                marginTop: "18px",
                            }}
                            onClick={this.handleSubmit}
                        >
                            Create
                        </button>
                    </Grid>
                </ThemeProvider>
            </Grid>
        )
    };
    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return false;
        } else {
            this.setState({
                loading: true,
            });
            let postData = {};
            let url = backEndServerURL + "/create-document-type";
            postData.documentType = this.state.inputData.documentType;
            postData.priority = this.state.inputData.priority;
            postData.code = this.state.inputData.code;
            postData.status = this.state.inputData.status;
            axios.post(url, postData, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                },
            })
                .then((response) => {
                    if (response.data.saveStatus === false) {
                        this.setState({
                            loading: false,
                            title: "Same document type already exists!",
                            cbNotFound: true,
                        });
                    } else {
                        this.setState({
                            loading: false,
                            alert: true,
                            title: "Success",
                            notificationMessage: response.data.message,
                        });
                        this.fetchDataFromAPI("/getAllDocumentTypes/", 0)
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        title: "Unsucessful!",
                        cbNotFound: true,
                    });
                });
        }
    };
    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert appUid={this.state.appUid} closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderJsonForm()}
                            <Grid item xs={12}><br/></Grid>
                        </Grid>
                    </ThemeProvider>
                    <ThemeProvider theme={theme}>
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(DocumentTypeConfiguration);
