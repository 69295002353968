import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import MyValidation from "../../../JsonForm/MyValidation";
import {documentMakerLoanClosingJsonForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CardBody from "../../../Card/CardBody";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import CommonApi from "../../Maintenance/common/CommonApi";
import Label from "../Common/Label";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },
    }
};


let hldRemarks = [
    {
        "varName": "hldRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }]
;


class DocumentMakerLoanClosing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passingData: '',
            loading: true,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            inputData: {},
            getImageLink: [],
            getImageBoolean: false,
            appId: '',
            getData: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            fileUploadData: [],
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            submitButtonClick: false,
            cbNotFound: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        console.log(this.state);
        console.log(this.props);
        console.log(this.props.caseId);
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId,assetOpsBackEndServerURL).then((response) => {
                CommonApi.getRemarkDetailsNew(this.props.appId,assetOpsBackEndServerURL).then((remarksArray) => {
                    CommonApi.getFilesNew(this.props.appId,assetOpsBackEndServerURL).then((fileResponse) => {
                        console.log(response.data);
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(response.data),
                            inputData: this.removeNullValue(response.data),
                            appId: this.props.appId,
                            appData: response.data,
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, documentMakerLoanClosingJsonForm, this.updateComponent)
            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSave = () => {
        this.setState({
            loading: true
        });
        if (this.state.fileUploadData.uploadSroToken !== undefined && this.state.fileUploadData.uploadSroToken !== null) {
            let uploadUrl = assetOpsBackEndServerURL + "/case/upload";
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            if (Object.values(this.state.fileUploadData).length === 1) {
                formData.append("file", this.state.fileUploadData['uploadSroToken']);
                formData.append("type", "file1");
            }
            axios({
                method: 'post',
                url: uploadUrl,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    let loanClosingData = {};


                    let postData = {};
                    postData.map = {...this.state.inputData};
                    this.functionForCaseRoute(postData)
                })
                .catch((error) => {
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
        } else {
            let postData = {};
            postData.map = {...this.state.inputData};
            this.functionForCaseRoute(postData)
        }
    };

    closeConfirmAlert = (value) => {
        if (value === "ok" && this.state.actionType === "handleSubmit") {
            this.handleSubmit("YES")
        } else if (value === "ok" && this.state.actionType === "handleSave") {
            this.handleSave()
        } else if (value === "ok" && this.state.actionType === "handleReturn") {
            this.handleSubmit("NO")
        }
        this.setState({
            confirmAlert: false,
        });
    };

    emptyValueRemove = (jsonObject) => {
        return JSON.parse(JSON.stringify(jsonObject));
    };

    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(hldRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.props.appId,
            confirmAlert: false,
        });

        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"
            })
        } else if(actionType === "handleSave"){
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"
            })
        }
        else if (actionType === "handleReturn") {
            let dependencyField = [];
            if (actionType === "handleReturn") {
                dependencyField.push({
                    "varName": "hldRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "HLD Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };

    handleSubmitButton = () => {
        return (
            <div>
                {/*<div style={{margin: "10px 0"}}>
                    <Button target={"_blank"} href={DebitAuthorityLetter} style={{
                        backgroundColor: "red",
                        color: "white",
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        paddingBottom: 3,
                        borderRadius: 3,
                        border: 1,
                        textDecoration: "none"
                    }} target={"_blank"}>Generate debit Authority letter</Button>
                </div>*/}
                <Grid>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "handleSave")}
                    >
                        Save
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}
                    >
                        Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "handleReturn")}
                    >
                        Return
                    </button>
                </Grid>
            </div>
        )
    };


    handleSubmit = (data) => {
        this.setState({
            passingData: data,
            submitButtonClick: true,
        });
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, hldRemarks, this.updateComponent)
            )
        }
    };

    renderDynamicFileUpload = () => {
        if (!this.state.loading && this.state.getData) {
            return <DynamicFileAttachment appId={this.props.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false
        });
        if (status === "success" || status === "No File Found") {
            let uploadUrl = assetOpsBackEndServerURL + "/case/upload";
            if (this.state.fileUploadData.uploadSroToken !== undefined && this.state.fileUploadData.uploadSroToken !== null) {
                let formData = new FormData();
                formData.append("appId", this.props.appId);

                if (Object.values(this.state.fileUploadData).length === 1) {
                    formData.append("file", this.state.fileUploadData['uploadSroToken']);
                    formData.append("type", "file1");
                }

                this.state.inputData.hld_approved = this.state.passingData;
                let postData = {};
                postData.getRemarks = this.state.inputData.hldRemarks;
                postData.map = {...this.state.inputData};
                axios({
                    method: 'post',
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {


                        this.functionForCaseRoute(postData)
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message
                        })
                    })
            } else {
                let postData = {};
                postData.getRemarks = this.state.inputData.hldRemarks;
                this.state.inputData.hld_approved = this.state.passingData;
                postData.map = {...this.state.inputData};
                this.functionForCaseRoute(postData)
            }
        } else {
            this.setState({
                cbNotFound: true,
                title: "Failed to Upload File."
            })
        }
    };

    functionForCaseRoute = (data) => {
        let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.props.closeModal();
                this.setState({
                    title: "Successful!",
                    notificationMessage: response.data,
                    alert: true,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };

    close = () => {
        this.props.closeModal();
    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber || ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId || ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Home Loan Documentation Waiver Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderCustomerDetails()}
                            {this.renderForm()}
                            {this.renderDynamicFileUpload()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmAlert}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                        </Grid>
                        {this.renderRemarksData()}
                        <Grid item xs={12}></Grid>
                        {this.renderFlowSUmmeryButton()}
                        {this.renderRemarks()}
                        {this.handleSubmitButton()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(DocumentMakerLoanClosing);
