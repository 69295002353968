import React from "react";

class SubmitButtonComponent {

    static onKeyDownChange(event, onKeyDownChange, field, state) {
        if (field.event !== undefined) {
            onKeyDownChange(field);
        } else {
            onKeyDownChange(event, field.varName);
        }
    }

    static renderButton(state, update, field, onKeyDownChange) {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: "middle",
                    display: "block",
                    marginTop: field.mt ? field.mt : 5,
                    marginBottom: 10,
                }}
                type="submit"
                onClick={(event) => {
                    this.onKeyDownChange(event, onKeyDownChange, field, state)
                }}
            >
                {field.label}
            </button>
        )
    }

}

export default SubmitButtonComponent;