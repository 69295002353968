import React, {Component} from 'react';
import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import {Redirect, Route, Switch} from "react-router-dom";
//360view
import Customer from "./Components/360View/Customer";
import DemographicsView from "./Components/360View/DemographicsView";
import Grouping from './Components/360View/Grouping';
//accountOpeningProcess
import NewAccountDashboard from "./Components/accountOpeningProcess/NewAccountDashboard";
import DashboardNewAccountSearchResult from "./Components/accountOpeningProcess/DashboardNewAccountSearchResult";
import GlobalSearch from "./Components/workflow/CommonComponent/GlobalSearch";
import GlobalSearchAwf from "./Components/workflow/AssetOps/AwfGlobalSearch";
import User from "./Components/AdminModule/User";

import CsPartialPayment from "./Components/workflow/AssetOps/PartialPayment/CsPartialPayment";
import CsOutstandingCertificate from "./Components/workflow/AssetOps/OutstandingCertificate/CsOutstandingCertificate";
import CsLienMarking from "./Components/workflow/AssetOps/LienMarking/CsLienMarking";
import CsRecoveryAmount from "./Components/workflow/AssetOps/RecoveryAmount/CsRecoveryAmount";
import MakerPayOrderStart from "./Components/workflow/AssetOps/PayOrder/MakerPayOrderStart";
// File
import UploadFileToAutoRead from "./Components/File/UploadFileToAutoRead";
//grouping
import GroupAndMemberAdd from './Components/grouping/GroupAndMemberAdd';
import GroupingApproval from './Components/grouping/GroupingApproval';
import TestAddForm from './Components/grouping/TestAddForm';
//MasterModule
import Branch from "./Components/MasterModule/Branch";
import BusinessDivision from "./Components/MasterModule/BusinessDivision";
import CustomerCategory from "./Components/MasterModule/CustomerCategory";
import City from "./Components/MasterModule/City";
import CityAddEditDelete from "./Components/MasterModule/CityAddEditDelete";
import CustomerConstitution from "./Components/MasterModule/CustomerConstitution";
import CustomerOffer from "./Components/MasterModule/CustomerOffer";
import Country from "./Components/MasterModule/Country";
import CustomerBand from "./Components/MasterModule/CustomerBand";
import CustomerSegment from "./Components/MasterModule/CustomerSegment";
import CustomerType from "./Components/MasterModule/CustomerType";
import Gender from "./Components/MasterModule/Gender";
import LineOfBusiness from "./Components/MasterModule/LineOfBusiness";
import Notification from "./Components/MasterModule/Notification";
import Occupation from "./Components/MasterModule/Occupation";
import Product from "./Components/MasterModule/Product";
import Salutation from "./Components/MasterModule/Salutation";
import Sector from "./Components/MasterModule/Sector";
import SourceMaster from "./Components/MasterModule/SourceMaster";
import State from "./Components/MasterModule/State";
import SubSector from "./Components/MasterModule/SubSector";
//ProcessFlow
import BranchProcessFlowConfigureAdd from "./Components/ProcessFlow/BranchProcessFlowConfigureAdd";
import SdAndBranchFlowConfigureAllData from "./Components/ProcessFlow/SdAndBranchFlowConfigureAllData";
import SdAndBranchProcessFlow from "./Components/ProcessFlow/SdAndBranchProcessFlow";
import SdFlowConfigureAddUpdateDelete from "./Components/ProcessFlow/SdFlowConfigureUpdateDelete";
import SdProcessFlowMasterAdd from "./Components/ProcessFlow/SdProcessFlowMasterAdd";
import SdProcessFlowMasterBranchSearch from "./Components/ProcessFlow/SdProcessFlowMasterBranchSearch";
//sidebarAdminMenuclaimcase
import AddMenu from "./Components/sidebarAdminMenu/AddMenu";
import MenuListDashboard from "./Components/sidebarAdminMenu/MenuListDashboard";
import RoleListDashboard from "./Components/sidebarAdminMenu/RoleListDashboard";
import RequestInitialization from "./Components/workflow/AuxiliaryTask/RequestInitialization";
import UserListDashboard from "./Components/sidebarAdminMenu/UserListDashboard";
import SubMenuAdd from "./Components/AdminModule/SubMenuAdd";
//Table
import RelashionShipView from "./Components/Table/RelashionShipView";
import CustomerList from "./Components/Table/CustomerList";
//workflow
import BMApproval from "./Components/workflow/CASA/BMApproval";
import DataCaptureCS from "./Components/workflow/DataCaptureCS";
import VerifyDocumentBOM from "./Components/workflow/CASA/VerifyDocumentBOM";
import MaintenanceWIthPrintFile from "./Components/workflow/MaintenanceWIthPrintFile";
import MaintenanceWIthFile from "./Components/workflow/MaintenanceWIthFile";
import Inbox from "./Components/workflow/Inbox";
import ClaimCase from "./Components/workflow/ClaimCase";
import DebitCard from "./Components/workflow/DebitCard";
import DebitCardList from "./Components/workflow/DebitCardList";
import DebitCardListDownload from "./Components/workflow/DebitCardListDownload";
import UploadDeferal from "./Components/workflow/CASA/UploadDeferal";
import CSRejectedPage from "./Components/workflow/CASA/CSRejectedPage";
import ImageCrop from "./Components/workflow/CASA/ImageCrop";
import BMBulkAccountOpening from "./Components/workflow/BMBulkAccountOpening";
import AuxiliaryTaskInbox from "./Components/workflow/AuxiliaryTask/AuxiliaryTaskInbox";
import AuxiliaryTaskMapping from "./Components/workflow/AuxiliaryTask/AuxiliaryTaskMapping";
import Bondmanagement from "./Components/workflow/BOND/Bondmanagement";
import Encashment from "./Components/workflow/BOND/Encashment";
import AcesEnrollment from "./Components/workflow/BOND/Enrollment/AcesEnrollment";
import CBGlobalSearch from "./Components/CBGlobalSearch";
import CustomerDedupSearch from "./Components/CustomerDedupSearch";
import CustomerDedupeAndSdnSearch from "./Components/CustomerDedupeAndSdnSearch";
import CustomerSearch from "./Components/CustomerSearch";
import CustomerSearches from "./Components/CustomerSearches";
import WelcomeLetterGenerate from "./Components/WelcomeLetterGenerate";
import CustomerServedList from "./Components/CustomerServedList";
import ManagerView from "./Components/ManagerView";
import ProcessmakerDashboard from "./Components/ProcessmakerDashboard";
import AgentBanking from "./Components/workflow/AGENT/StaticData";
import AdminReassignCase from "./Components/workflow/CommonComponent/AdminReassignCase";
import CSDeferalClose from "./Components/workflow/CommonComponent/CSDeferalClose";
import TestReza from "./TestReza";
// import Notification from "./Components/NotificationMessage/Notification";
//test
import TestForm from "./Components/JsonForm/TestForm";
//merchant
import MerchantDedup from './Components/MerchantDedups';
import MbuClose from './Components/workflow/Merchant/deferralLifeCycle/DeferralLifeCycleMBU';
//card cheque
import CsOpening from './Components/workflow/Card/CsOpening';
import BOMOpening from './Components/workflow/Card/BomOpening';
//card
import MaintenanceRequest from "./Components/workflow/CardCallCategory/MaintenanceRequest";
import CardDivision from "./Components/workflow/CardCallCategory/CardDivision";
import MerchantMaintenance from "./Components/workflow/CardCallCategory/MerchantMaintenance";
//merchant Inbox
import MerchantInbox from "./Components/workflow/MerchantInbox"
import MerchantClaimCase from "./Components/workflow/Merchant/MerchantClaimCase"
//Remettance
import Remetance from "./Components/workflow/REMITENCE/Remetance";
import InwardRemittance from "./Components/workflow/REMITENCE/staticTable";
//Debit Insta Card
import InstaDebitCardRequest from "./Components/workflow/InstaDebitcard/InstaDebitCardRequest";
import VerifyInstaDebitCardBOM from "./Components/workflow/InstaDebitcard/VerifyInstaDebitCardBOM";
import csInstaDebitCard from "./Components/DeliverableManagement/InstaDebitCard/CsInstaDebitCard";
import instaDebitCardFileUpload from "./Components/DeliverableManagement/InstaDebitCard/InstaDebitCardFileUpload";
//Deliverbale management form define
import DeliverableChequeBookFrom from "./Components/DeliverableManagement/ChequeBook/DeliverableChequeBookFrom";
import ChecqueBookManage from "./Components/DeliverableManagement/ChequeBook/ChecqueBookManage";
import DebitCardManage from "./Components/DeliverableManagement/DebitCard/DebitCardManage";
import CsDebitCardManage from "./Components/DeliverableManagement/DebitCard/CsDebitCardManage";
import CsChecqueBookManage from "./Components/Greendelta/CsChecqueBookManage";
import OutgoingTrackerManage from "./Components/GreenDeltaDispatch/OutgoingTrackerManage";
import IncomingTrackerManage from "./Components/GreenDeltaDispatch/IncomingTrackerManage";
import CsChecqueBookManages from "./Components/DeliverableManagement/ChequeBookRequisitionService/CsChecqueBookManage";
import BomChequeBookManage from "./Components/DeliverableManagement/ChequeBook/BomChecqueBookManage";
import BomChequeBookManages from "./Components/DeliverableManagement/ChequeBookRequisitionService/BomChecqueBookManage";
//Cheque Book Requisition  management form define
import DeliverableChequeBookFroms from "./Components/DeliverableManagement/ChequeBookRequisitionService/DeliverableChequeBookFrom";
import ChecqueBookManages from "./Components/DeliverableManagement/ChequeBookRequisitionService/ChecqueBookManage";

import DebitCardRequisition from "./Components/DeliverableManagement/DebitCard/DebitCardRequisition";
import WelcomeLetterCreation from "./Components/DeliverableManagement/WelcomeLetter/WelcomeLetterCreation";
import WelcomeLetterBranch from "./Components/DeliverableManagement/WelcomeLetter/WelcomeLetterBranch";
import WelcomeLetterBackOfficeUpload
    from "./Components/DeliverableManagement/WelcomeLetter/WelcomeLetterBackOfficeUpload";
import DebitCardFileUpload from "./Components/DeliverableManagement/DebitCard/DebitCardFileUpload";
import ChequeBookFileUpload from "./Components/DeliverableManagement/ChequeBook/ChequeBookFileUpload";
import DebitCardSplitInbox from "./Components/DeliverableManagement/DebitCard/DebitCardSplitInbox";
import DebitCardCheckerSplit from "./Components/DeliverableManagement/DebitCard/DebitCardCheckerSplit";

import InstaPackSplit from "./Components/DeliverableManagement/InstaChequeBook/InstaPackSplit";
import ChequeBookSplit from "./Components/DeliverableManagement/ChequeBook/ChequeBookSplit";
import InstaCardSpilt from "./Components/DeliverableManagement/InstaDebitCard/InstaCardSpilt";
import InstaPackRequisition from "./Components/DeliverableManagement/InstaChequeBook/InstaPackRequisition";
import InstaDebitCardRequisition from "./Components/DeliverableManagement/InstaDebitCard/InstaDebitCardRequisition";
import ManageInstaPack from "./Components/DeliverableManagement/InstaChequeBook/ManageInstaPack";
import CsInstaPackManage from "./Components/DeliverableManagement/InstaChequeBook/CsInstaPackManage";
import ManageInstaDebitCard from "./Components/DeliverableManagement/InstaDebitCard/ManageInstaDebitCard";
//Adc
import AdcService from "./Components/workflow/Adc/AdcService";
import CityTouchReport from "./Components/workflow/Adc/CityTouchReport";
// Fund Transfer
import CustomerServiceFund from "./Components/workflow/FundTransfer/CustomerService";
import MakerCustomerServiceInitialized from "./Components/workflow/FundTransfer/MakerCustomerServiceInitialized";
// maintenance
import AccountMaintenance from "./Components/workflow/Maintenance/static/AccountMaintenance";
import LinkedAccountMaintenance from "./Components/workflow/Maintenance/static/LinkedAccountMaintenance";
import VarifyMakerAccountMaintenance from "./Components/workflow/Maintenance/static/VarifyMakerAccountMaintenance";
import VerifyCallCenter from "./Components/workflow/Maintenance/static/VerifyCallCenter";
import VerifyCheckerAccountMaintenance from "./Components/workflow/Maintenance/static/VerifyCheckerAccountMaintenance";
import VerifyDocumentBOM2 from "./Components/workflow/Maintenance/static/VerifyDocumentBOM2";
import BMApproval2 from "./Components/workflow/Maintenance/static/BMApproval2";
import FDRMaintenance from "./Components/workflow/Maintenance/FDR/FDRMaintenance";
import FdrEncashment from "./Components/workflow/Maintenance/FDR/FdrEncashment";
import Maintenance from './Components/workflow/Maintenance/Maintenance';
//Locker
import LockerSearch from "./Components/LockerSearch";
import CSOLockerSurrender from "./Components/workflow/LOCKER/CSOLockerSurrander";
import LockerDidupSearch from "./Components/LockerDidupSearch";
import TabTable from "./Components/lockerOPSD/TabTable";
import LockerBranch from "./Components/lockerOPSD/LockerBranch";
import ExcelUpload from "./Components/lockerOPSD/ExcelUpload";
import LockerMaintenance from "./Components/workflow/LOCKER/LockerMaintenance";
//Asset EFTN
import EFTNExcelUpload from "./Components/workflow/AssetEFTN/EFTNExcelUpload"
import EFTNDatabase from "./Components/workflow/AssetEFTN/EFTNDatabase"
//Reporting
import LWFExcelReport from "./Components/workflow/Reporting/LWFExcelReport"
//User Registration
import UserRegistration from "./Components/UserManagement/UserRegistration";
import PasswordChange from "./Components/UserManagement/PasswordChange";
import ResetPasswordAdmin from "./Components/UserManagement/ResetPasswordAdmin";
import ActivateUserAdmin from "./Components/UserManagement/ActivateUserAdmin";
import DeactiveUserAdmin from "./Components/UserManagement/DeactiveUserAdmin";
import RoleSwitchPermissionList from "./Components/UserManagement/RoleSwitchPermissionList";
import WorkplaceUnitList from "./Components/UserManagement/WorkplaceUnitList";
import UserList from "./Components/UserManagement/UserList";
import SdDepartmentAndTeamUpdate from "./Components/UserManagement/SdDepartmentAndTeamUpdate";
import UserRoleEdit from "./Components/UserManagement/UserRoleEdit";
import RoleAdd from "./Components/UserManagement/RoleAdd";
import InsuranceValuecalculationMasterDataList from "./Components/Greendelta/InsuranceValuecalculationMasterDataList";
import InsuranceMasterDataList from "./Components/Greendelta/InsuranceMasterDataList";
import RequestInitializations from "./Components/Greendelta/RequestInitialization";
import StampSaleRequest from "./Components/Greendelta/StampSaleRequest";
import OutgoingDispatchIntiator from "./Components/GreenDeltaDispatch/OutgoingDispatchIntiator";
import IncomingDispatchIntiator from "./Components/GreenDeltaDispatch/IncomingDispatchIntiator";
import GreenDeltaRequisitionReport from "./Components/GreenDeltaDispatch/GreenDeltaRequisitionReport";
import UserEdit from "./Components/UserManagement/UserEdit";
import DelegationOfAuthority from "./Components/UserManagement/DelegationOfAuthority";
import UserBulkUpload from "./Components/UserManagement/UserBulkUpload";
//daashboard
import CsDashboard from './Components/Dashboard/CsDashboard'
import FDRDashboard from './Components/Dashboard/FDRDashboard.jsx'
import CasaCloserMaintenance from "./Components/workflow/Maintenance/casa/CloserMaintenance";
import DpsCloser from "./Components/workflow/Maintenance/dps/CloserMaintenance";
import DpsCloserMaintenance from "./Components/workflow/Maintenance/dps/DpsCloserMaintenance";
//CreditCardChequeBook
import CreditCardChequeBookRequisition
    from "./Components/DeliverableManagement/CreditCardChequeBook/CreditCardChequeBookRequisition";
import CreditCardChequeBookManage
    from './Components/DeliverableManagement/CreditCardChequeBook/CreditCardChequeBookManage';
import CreditCardChequeBookFileUpload
    from './Components/DeliverableManagement/CreditCardChequeBook/CreditCardChequeBookFileUpload';
//Format Editor
import FormatEditor from "./Components/workflow/LetterFormat/FormatEditor";
import Search from "./Components/workflow/Search/Search";
import MailNotification from "./Components/workflow/CommonComponent/MailNotification";
//insta pack file upload
import InstaPackFileUpload from "./Components/DeliverableManagement/InstaChequeBook/InstaPackFileUpload";
import InstaPackConfiguration from "./Components/DeliverableManagement/InstaChequeBook/InstaPackConfiguration";
import InstaCardConfiguration from "./Components/DeliverableManagement/InstaDebitCard/InstaCardConfiguration";
import Outward from "./Components/workflow/REMITENCE/Other";
import StudentFile from "./Components/workflow/REMITENCE/StudentFile";
import MedicalFile from "./Components/workflow/REMITENCE/MedicalFile";
//Asset Ops
import FileRequisition from "./Components/workflow/AssetOps/FileRequisition";
import AssetManager from "./Components/workflow/AssetOps/AssetManager";
import AssetManagerAddEdit from "./Components/workflow/AssetOps/AssetManagerAddEdit";
import ArchiveManagerStatusQuery from "./Components/workflow/AssetOps/ArchiveManagerStatusQuery";
import FileRequisitionHistory from "./Components/workflow/AssetOps/FileRequistion/FileRequisitionHistory";

import CentralArchiving from "./Components/workflow/AssetOps/CentralArchiving";
import FileRequisitionProcessInbox from "./Components/workflow/AssetOps/FileRequistion/FileRequisitionProcessInbox";
import FileRequisitionInitiatorInbox from "./Components/workflow/AssetOps/FileRequistion/FileRequisitionInitiatorInbox";
import FileRequisitionProcessForm from "./Components/workflow/AssetOps/FileRequistion/FileRequisitionProcessForm";
import FileRequisitionInitiatorForm from "./Components/workflow/AssetOps/FileRequistion/FileRequisitionInitiatorForm";
import CsFormLoanClosing from "./Components/workflow/AssetOps/LoanClosing/CsFormLoanClosing";
import CSOAfterHLD from "./Components/workflow/AssetOps/CSOAfterHLD";
import GenerateEncashmentRequest from "./Components/workflow/AssetOps/SecuredLoanClosing/GenerateEncashmentRequest"
import CsDeceasedMark from "./Components/workflow/AssetOps/DeceasedMark/CsDeceasedMark";
import CsWiaverRequest from "./Components/workflow/AssetOps/WaiverRequest/CsWiaverRequest";
import CsGroupInbox from "./Components/workflow/AssetOps/CsGroupInbox";
//asset operation import files
import CsoRequisition from "./Components/workflow/AssetOps/EmiDataChange/CsEmiDateChange";
import CsoRequisitionForLoan from "./Components/workflow/AssetOps/LoanAccountTenorChange/CsoRequisition";
import CsoRequisitionForInterestTransfer from "./Components/workflow/AssetOps/OtherServiceRequest/CsoRequisition";

import ServiceRequest from "./Components/workflow/AssetOperation/assetOperationService";
import AssetOpsMis from "./Components/workflow/AssetOperation/RetailLoanArchiving/AssetOpsMis";

import MasterDataConfiguration from "./Components/workflow/Merchant/MasterDataConfiguration";
import MailConfigurationList from "./Components/workflow/CommonComponent/MailConfigurationList";

import BondFilter from "./Components/workflow/BOND/Filter"
import CreateCallCategory from "./Components/workflow/CardMaintenance/CreateCallCategory";
import CreateGroup from "./Components/workflow/CardMaintenance/CreateGroup";
import AssignGroup from "./Components/workflow/CardMaintenance/AssignGroup";
import ModifyExistingGroup from "./Components/workflow/CardMaintenance/ModifyExistingGroup";
import ModifyExistingByUser from "./Components/workflow/CardMaintenance/ModifyGroupByUser";
import cardInbox from "./Components/workflow/CardMaintenance/InitiatorInbox";
import UnlockComponentForLineManager from "./Components/workflow/CardMaintenance/UnlockComponentForLineManager";
import UpdateCallCategory from "./Components/workflow/CardMaintenance/UpdateCallCategory";

import InwardFileUpload from "./Components/workflow/REMITENCE/InwardFileUpload";

import Other from "./Components/workflow/Maintenance/staticOthers/Others";
import MandateUpdate from "./Components/workflow/Maintenance/signatory/MandateUpdate";

import GreenDeltaDespatchReport from "./Components/GreenDeltaDispatch/GreenDeltaDespatchReport";
import GreenDeltaStockAndSoldReport from "./Components/GreenDeltaDispatch/GreenDeltaStockAndSoldReport";
import FirstLoginPage from "./Components/FirstLoginPage";


import BusinessHierarcy from "./Components/workflow/Hierarcy/BusinessHierarcy";
import ListForRV from "./Components/workflow/Hierarcy/ListForRV";
import TagCluster from "./Components/workflow/Hierarcy/TagCluster";
import LoanCustomerSearch from "./Components/workflow/Hierarcy/LoanCustomerSearch";

import CloserRequestWiseMaintenance from "./Components/workflow/Maintenance/casa/CloserRequestWiseMaintenance";
import BranchSummaryReport from "./Components/DeliverableManagement/InstaDebitCard/BranchSummaryReport";
import RegularDebitCardBranchSummaryReport from "./Components/DeliverableManagement/DebitCard/BranchSummaryReport";

import BondReport from "./Components/workflow/BOND/Report/Report";
import fundTransferReport from "./Components/workflow/FundTransfer/Report/Report";
import categoryWiseReport from "./Components/workflow/All Report/Report"
import CardServiceReport from "./Components/workflow/All Report/CardServiceReport"


import CommonInbox from "./Components/workflow/CommonInbox";
import AssetBranchInbox from "./Components/workflow/AssetBranchInbox";
import AssetUserInbox from "./Components/workflow/AssetUserInbox";
import CommonReport from "./Components/workflow/All Report/CommonReport";
import BeneficiaryAdd from "./Components/workflow/FundTransfer/Beneficiary/BeneficiaryAdd";
import BeneficiaryList from "./Components/workflow/FundTransfer/Beneficiary/BeneficiaryList";
import CbAndAccountUpdateComponent from "./Components/workflow/CommonComponent/CbAndAccountUpdateComponent";
import SchemeCodeUpdateCS from "./Components/workflow/Maintenance/SchemeUpdate/SchemeCodeUpdateCS";
//Asset Ops Loan Closer
import CsSecuredLoanClosing from "./Components/workflow/AssetOps/SecuredLoanClosing/CsSecuredLoanClosing";

import BankList from "./Components/workflow/FundTransfer/Beneficiary/BankList";
import CsRequisition from "./Components/DeliverableManagement/GIftCard/CsRequisition";
import OpsdTracker from "./Components/DeliverableManagement/GIftCard/OpsdTracker";
import BranchTracker from "./Components/DeliverableManagement/GIftCard/BranchTracker";
import GiftCardIssuance from "./Components/DeliverableManagement/GIftCard/GiftCardIssuance";
import PrepaidCardIssuance from "./Components/DeliverableManagement/GIftCard/PrepaidCardIssuance";
import BulkDownload from "./Components/DeliverableManagement/GIftCard/BulkDownload";
import SchemeList from "./Components/DeliverableManagement/SchemeConfiguration/SchemeList";
import BondInbox from "./Components/workflow/BondInbox";
import CustomStatusUpdateRequisition from "./Components/workflow/Maintenance/CustomerStatusUpdate/CsRequisition";
import Greeting from "./Components/workflow/Greeting/Greeting";
import LocalstorageEncrypt from "./Components/JsonForm/LocalstorageEncrypt";
import CustomDutyCs from "./Components/workflow/FundTransfer/customDuty/CsRequisition"
import CustomDutyMaker from "./Components/workflow/FundTransfer/customDuty/MakerRequisition"
import CorporatePrepaidCardFileUpload from "./Components/DeliverableManagement/CorporatePrepaidCard/CorporatePrepaidCardFileUpload";
import ManageCorporatePrepaidCard
    from "./Components/DeliverableManagement/CorporatePrepaidCard/ManageCorporatePrepaidCard";
import BranchConfiguration from "./Components/Greendelta/MasterData/BranchConfiguration";
import DocumentTypeConfiguration from "./Components/Greendelta/MasterData/DocumentTypeConfiguration";

class ProtectedRoute extends Component {
    menuShow = (menuList, includeMenu, url, componentName) => {

        if (menuList!==undefined && menuList.includes(includeMenu)) {
            return (
                <Route path={url} component={componentName}/>
            );
        }


    };

    render() {
        if(sessionStorage.getItem("accessToken") !== undefined && sessionStorage.getItem("accessToken") !==null){
            let menuList = LocalstorageEncrypt.encryptStorageFunction.getItem("menus");


            if (menuList === null || menuList===undefined) {


                menuList = [];
            }


            if ((/*LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT" || */ LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==='MERCHANT_DST' || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MERCHANT_CALL_CENTER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT HOMBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU_DFF" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "FRM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "FRM_CHECKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "FRM_APPROVAL" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DST_LINE_MANAGER")) {


                return (
                    <ProtectedLayout>

                        <Switch>
                            {/*   360view  */}
                            <Route path="/Customer/:identity" component={Customer}/>
                            <Route path="/DemographicsView" component={DemographicsView}/>
                            <Route path="/Grouping" component={Grouping}/>


                            {/*  accountOpeningProcess*/}
                            <Route path="/newaccount" component={NewAccountDashboard}/>
                            <Route path="/newaccountsearchResult" component={DashboardNewAccountSearchResult}/>
                            <Route path="/GlobalSearch" component={GlobalSearch}/>


                            {/*  AdminModule*/}

                            {
                                this.menuShow(menuList, "User", "/userListDashboard", "UserListDashboard")

                            }
                            <Route path="/user" component={User}/>
                            <Route path="/subMenuAdd" component={SubMenuAdd}/>


                            {/*  File*/}
                            <Route path="/fileRead" component={UploadFileToAutoRead}/>

                            {/*  grouping*/}
                            <Route path="/GroupAndMemberAdd" component={GroupAndMemberAdd}/>
                            <Route path="/GroupingApproval" component={GroupingApproval}/>
                            <Route path="/TestAddForm" component={TestAddForm}/>


                            {/*  JsonForm*/}
                            <Route path="/DebitCard" component={DebitCard}/>
                            <Route path="/DebitCardList" component={DebitCardList}/>
                            <Route path="/DebitCardListDownload" component={DebitCardListDownload}/>
                            <Route path="/UploadDeferal" component={UploadDeferal}/>
                            <Route path="/CSRejectedPage" component={CSRejectedPage}/>
                            <Route path="/ImageCrop" component={ImageCrop}/>
                            <Route path="/BMBulkAccountOpening" component={BMBulkAccountOpening}/>
                            <Route path="/AuxiliaryTaskInbox" component={AuxiliaryTaskInbox}/>
                            <Route path="/AuxiliaryTaskMapping" component={AuxiliaryTaskMapping}/>

                            <Route path="/Bondmanagement" component={Bondmanagement}/>
                            <Route path="/Encashment" component={Encashment}/>
                            <Route path="/AcesEnrollment" component={AcesEnrollment}/>


                            {/*   Layouts*/}

                            <Route path="/merchantInbox" component={MerchantInbox}/>
                            <Route path="/ClaimCase" component={MerchantClaimCase}/>


                            <Route path="/Remetance" component={Remetance}/>
                            <Route path="/OutwardRemetance" component={Outward}/>
                            <Route path="/studentFile" component={StudentFile}/>
                            <Route path="/medicalFile" component={MedicalFile}/>
                            <Route path="/InwardRemittance" component={InwardRemittance}/>
                            //Deliverbale all route
                            <Route path="/welcomeLetter" component={WelcomeLetterGenerate}/>
                            <Route path="/deliverablecheque" component={DeliverableChequeBookFrom}/>
                            <Route path="/deliverablecheques" component={DeliverableChequeBookFroms}/>
                            <Route path="/GreenDeltaDespatchReport/:reportType" component={GreenDeltaDespatchReport}/>
                            <Route path="/GreenDeltaStockAndSoldReport/:reportType" component={GreenDeltaStockAndSoldReport}/>
                            <Route path="/chequebookmanage" component={ChecqueBookManage}/>
                            <Route path="/chequebookmanages" component={ChecqueBookManages}/>
                            <Route path="/deliverablecard" component={DebitCardRequisition}/>
                            <Route path="/debitcardmanage" component={DebitCardManage}/>
                            <Route path="/csDebitCardManage" component={CsDebitCardManage}/>
                            <Route path="/CsChecqueBookManage" component={CsChecqueBookManage}/>
                            <Route path="/IncomingTrackerManage" component={IncomingTrackerManage}/>
                            <Route path="/OutgoingTrackerManage" component={OutgoingTrackerManage}/>
                            <Route path="/CsChecqueBookManages" component={CsChecqueBookManages}/>
                            <Route path="/BomChequeBookManage" component={BomChequeBookManage}/>
                            <Route path="/BomChequeBookManages" component={BomChequeBookManages}/>
                            <Route path="/welcomelettercreate/:priority" component={WelcomeLetterCreation}/>
                            <Route path="/welcomeletterbackofficeupload" component={WelcomeLetterBackOfficeUpload}/>
                            <Route path="/welcomeletterbranch/:priority" component={WelcomeLetterBranch}/>
                            <Route path="/debitcardfileupload" component={DebitCardFileUpload}/>
                            <Route path="/chequebookfileupload" component={ChequeBookFileUpload}/>

                            <Route path="/instapackrequisition" component={InstaPackRequisition}/>
                            <Route path="/instadebitcardrequisition" component={InstaDebitCardRequisition}/>
                            <Route path="/manageinstapack" component={ManageInstaPack}/>
                            <Route path="/csinstapackmanage" component={CsInstaPackManage}/>
                            <Route path="/manageinstadebitcard" component={ManageInstaDebitCard}/>
                            <Route path="/csmanageinstadebitcard" component={csInstaDebitCard}/>
                            <Route path="/instaDebitCardFileUpload" component={instaDebitCardFileUpload}/>
                            <Route path="/instaPackFileUpload" component={InstaPackFileUpload}/>
                            <Route path="/instaPackConfiguration" component={InstaPackConfiguration}/>
                            <Route path="/instaCardConfiguration" component={InstaCardConfiguration}/>

                            <Route path="/debitcardcheck" component={DebitCardSplitInbox}/>
                            <Route path="/debitcardchecksplit" component={DebitCardCheckerSplit}/>
                            <Route path="/instapacksplit" component={InstaPackSplit}/>
                            <Route path="/chequebooksplit" component={ChequeBookSplit}/>
                            <Route path="/instacardsplit" component={InstaCardSpilt}/>

                            //Debit Insta Card
                            <Route path="/InstaDebitCardRequest" component={InstaDebitCardRequest}/>
                            <Route path="/VerifyInstaDebitCardBOM" component={VerifyInstaDebitCardBOM}/>
                            //AdcService
                            <Route path="/AdcService/:type" component={AdcService}/>
                            <Route path="/CityTouchReport" component={CityTouchReport}/>

                            <Route path="/csService/:enduser" component={CustomerServiceFund}/>
                            <Route path="/sdService/:enduser" component={MakerCustomerServiceInitialized}/>
                            {/*mainteannce*/}
                            <Route path="/VerifyCallCenter" component={VerifyCallCenter}/>
                            <Route path="/Maintenance" component={Maintenance}/>
                            <Route path="/AccountMaintenance/:requestType" component={AccountMaintenance}/>
                            <Route path="/AccountMaintenance" component={AccountMaintenance}/>
                            <Route path="/LinkedAccountMaintenance/:cbNumber/:acNumber"
                                   component={LinkedAccountMaintenance}/>
                            <Route path="/closerMaintenance/:maintenance" component={CasaCloserMaintenance}/>
                            <Route path="/casaCloserRequest/:maintenance" component={CloserRequestWiseMaintenance}/>
                            <Route path="/dpsCloser" component={DpsCloser}/>
                            <Route path="/dpsCloserMaintenance" component={DpsCloserMaintenance}/>
                            <Route path="/VarifyMakerAccountMaintenance" component={VarifyMakerAccountMaintenance}/>
                            <Route path="/VerifyCheckerAccountMaintenance" component={VerifyCheckerAccountMaintenance}/>
                            <Route path="/VerifyDocumentBOM2" component={VerifyDocumentBOM2}/>
                            <Route path="/BMApproval2" component={BMApproval2}/>
                            <Route path="/FDRMaintenance" component={FDRMaintenance}/>
                            <Route path="/FdrEncashment" component={FdrEncashment}/>

                            //// Locker///

                            <Route path="/LockerSearch" component={LockerSearch}/>
                            <Route path="/LockerSurrender" component={CSOLockerSurrender}/>
                            <Route path="/LockerDidupSearch" component={LockerDidupSearch}/>
                            <Route path="/LockerBranch" component={LockerBranch}/>
                            <Route path="/TabTable" component={TabTable}/>
                            <Route path="/ExcelUpload" component={ExcelUpload}/>
                            <Route path="/LockerMaintenance" component={LockerMaintenance}/>

                            //Asset EFTN
                            <Route path="/EFTNExcelUpload" component={EFTNExcelUpload}/>
                            <Route path="/EFTNDatabase" component={EFTNDatabase}/>

                            //Reporting
                            <Route path="/LWFExcelReport/:position" component={LWFExcelReport}/>

                            //User Registration
                            <Route path="/UserRegistration" component={UserRegistration}/>
                            {/*<Route path="/PasswordChange" component={PasswordChange}/>*/}
                            <Route path="/ResetPasswordAdmin" component={ResetPasswordAdmin}/>
                            <Route path="/ActivateUserAdmin" component={ActivateUserAdmin}/>
                            <Route path="/DeactiveUserAdmin" component={DeactiveUserAdmin}/>
                            <Route path="/RoleSwitchPermissionList" component={RoleSwitchPermissionList}/>
                            <Route path="/WorkplaceUnitList" component={WorkplaceUnitList}/>
                            <Route path="/allUserList" component={UserList}/>
                            <Route path="/SdDepartmentAndTeamUpdate" component={SdDepartmentAndTeamUpdate}/>
                            <Route path="/RoleAdd" component={RoleAdd}/>
                            <Route path="/InsuranceValuecalculationMasterDataList" component={InsuranceValuecalculationMasterDataList}/>
                            <Route path="/InsuranceMasterDataList" component={InsuranceMasterDataList}/>
                            <Route path="/RequestInitializations" component={RequestInitializations}/>
                            <Route path="/StampSaleRequest" component={StampSaleRequest}/>
                            <Route path="/OutgoingDispatchIntiator" component={OutgoingDispatchIntiator}/>
                            <Route path="/IncomingDispatchIntiator" component={IncomingDispatchIntiator}/>
                            <Route path="/GreenDeltaRequisitionReport/:reportType" component={GreenDeltaRequisitionReport}/>
                            <Route path="/UserEdit" component={UserEdit}/>
                            <Route path="/DelegationOfAuthority" component={DelegationOfAuthority}/>
                            <Route path="/UserBulkUpload" component={UserBulkUpload}/>

                            {/*Green Delta*/}
                            <Route path="/branch-configuration" component={BranchConfiguration}/>
                            <Route path="/document-type-configuration" component={DocumentTypeConfiguration}/>

                            //Dashboard pie chart
                            <Route path="/dashboard" component={CsDashboard}/>
                            {/*  MasterModule*/}
                            <Route path="/branch" component={Branch}/>
                            <Route path="/businessDivision" component={BusinessDivision}/>
                            <Route path="/customerCategory" component={CustomerCategory}/>
                            <Route path="/city" component={City}/>
                            <Route path="/CityAddEditDelete" component={CityAddEditDelete}/>
                            <Route path="/customerConstitution" component={CustomerConstitution}/>
                            <Route path="/CustomerOffer" component={CustomerOffer}/>
                            <Route path="/country" component={Country}/>
                            <Route path="/customerBand" component={CustomerBand}/>
                            <Route path="/customerSegment" component={CustomerSegment}/>
                            <Route path="/customerType" component={CustomerType}/>
                            <Route path="/gender" component={Gender}/>
                            <Route path="/lineOfBusiness" component={LineOfBusiness}/>
                            <Route path="/notificationMaster" component={Notification}/>
                            <Route path="/occupation" component={Occupation}/>
                            <Route path="/Product" component={Product}/>
                            <Route path="/salutation" component={Salutation}/>
                            <Route path="/sector" component={Sector}/>
                            <Route path="/sourceMaster" component={SourceMaster}/>
                            <Route path="/state" component={State}/>
                            <Route path="/subSector" component={SubSector}/>

                            {/*  ProcessFlow*/}
                            <Route path="/BranchProcessFlowConfigureAdd" component={BranchProcessFlowConfigureAdd}/>
                            <Route path="/SdAndBranchFlowConfigureAllData" component={SdAndBranchFlowConfigureAllData}/>
                            <Route path="/SdAndBranchProcessFlow" component={SdAndBranchProcessFlow}/>
                            <Route path="/SdFlowConfigureAddUpdateDelete" component={SdFlowConfigureAddUpdateDelete}/>
                            <Route path="/SdProcessFlowMasterAdd" component={SdProcessFlowMasterAdd}/>
                            <Route path="/SdProcessFlowMasterBranchSearch" component={SdProcessFlowMasterBranchSearch}/>


                            {/*  sidebarAdminMenu*/}
                            <Route path="/addMenu" component={AddMenu}/>
                            <Route path='/menuListDashboard' component={MenuListDashboard}/>
                            <Route path='/roleListDashboard' component={RoleListDashboard}/>
                            <Route path='/RequestInitialization' component={RequestInitialization}/>
                            <Route path='/UserRoleEdit' component={UserRoleEdit}/>
                            <Route path="/customerForm" component={UserListDashboard}/>
                            <Route path="/userListDashboard" component={UserListDashboard}/>

                            {/*  Table*/}
                            <Route path="/relationship" component={RelashionShipView}/>
                            <Route path='/customerList' component={CustomerList}/>


                            {/*   workflow*/}
                            <Route path="/bmApproval" component={BMApproval}/>
                            <Route path="/dataCaptureCS" component={DataCaptureCS}/>
                            <Route path="/verifyDocumentBOM" component={VerifyDocumentBOM}/>
                            <Route path="/MaintenanceWIthPrintFile" component={MaintenanceWIthPrintFile}/>
                            <Route path="/MaintenanceWIthFiles" component={MaintenanceWIthFile}/>
                            <Route path="/AgentBanking" component={AgentBanking}/>
                            <Route path="/AdminReassignCase" component={AdminReassignCase}/>
                            <Route path="/MerchantDedup" component={MerchantDedup}/>
                            <Route path="/MbuClose" component={MbuClose}/>

                            <Route path="/CSDeferalClose" component={CSDeferalClose}/>

                            {/* card cheque */}
                            <Route path="/CsOpening" component={CsOpening}/>
                            <Route path="/BomOpening" component={BOMOpening}/>
                            {/*card maintenance and call category*/}
                            <Route path="/MaintanceRequest" component={MaintenanceRequest}/>
                            <Route path="/CardDivision" component={CardDivision}/>
                            <Route path="/MerchantMaintenance" component={MerchantMaintenance}/>

                            <Route path="/cbGlobalSearch" component={CBGlobalSearch}/>
                            <Route path="/WelcomeLetterGenerate" component={WelcomeLetterGenerate}/>
                            <Route path='/customerServed' component={CustomerServedList}/>
                            <Route path="/statistics" component={CustomerServedList}/>
                            <Route path='/managerView' component={ManagerView}/>
                            <Route path="/ProcessmakerDashboard" component={ProcessmakerDashboard}/>
                            <Route path="/welcomeLetter" component={WelcomeLetterGenerate}/>
                            <Route path="/testReza" component={TestReza}/>
                            <Route path="/TestForm" component={TestForm}/>
                            <Route path="/creditcardchequebook" component={CreditCardChequeBookRequisition}/>
                            <Route path="/creditcardchequebookmanage" component={CreditCardChequeBookManage}/>
                            <Route path="/creditcardchequebookfileupload" component={CreditCardChequeBookFileUpload}/>
                            <Route path="/editor" component={FormatEditor}/>
                            <Route path="/mandateupdate" component={MandateUpdate}/>
                            {/*<Route path="/signatoryMaintenance" component={MandateUpdate}/>*/}
                            <Route path="/merchantMasterData" component={MasterDataConfiguration}/>
                            <Route path="/Search" component={Search}/>
                            <Route path="/bondSanchayapatraReport" component={BondFilter}/>
                            <Route path="/mailConfiguration" component={MailNotification}/>
                            <Route path="/mailConfigurationList" component={MailConfigurationList}/>
                            <Route path="/createCallCategory" component={CreateCallCategory}/>
                            <Route path="/createGroup" component={CreateGroup}/>
                            <Route path="/assignCallGroup" component={AssignGroup}/>
                            <Route path="/existingGroup" component={ModifyExistingGroup}/>
                            <Route path="/ModifyExistingGroupByUser" component={ModifyExistingByUser}/>
                            <Route path="/cardInbox" component={cardInbox}/>
                            <Route path="/unlockForLineManager" component={UnlockComponentForLineManager}/>
                            <Route path="/UpdateCallCategory" component={UpdateCallCategory}/>
                            <Route path="/inwardFileUpload/:uploadType" component={InwardFileUpload}/>

                            {/*asset operation route path*/}
                            <Route path="/emidatechange" component={CsoRequisition}/>
                            <Route path="/loanaccounttenorchange" component={CsoRequisitionForLoan}/>
                            <Route path="/interesttransfer" component={CsoRequisitionForInterestTransfer}/>
                            <Route path="/assetopsmis" component={AssetOpsMis}/>
                            <Route path="/AssetManager" component={AssetManager}/>
                            <Route path="/FileRequisition" component={FileRequisition}/>
                            <Route path='/FileRequisitionHistory' component={FileRequisitionHistory}/>
                            <Route path="/AssetManagerAddEdit" component={AssetManagerAddEdit}/>
                            <Route path="/ArchiveManagerStatusQuery" component={ArchiveManagerStatusQuery}/>

                            <Route path="/CentralArchiving" component={CentralArchiving}/>
                            <Route path="/FileRequisitionProcessInbox" component={FileRequisitionProcessInbox}/>
                            <Route path="/FileRequisitionInitiatorInbox" component={FileRequisitionInitiatorInbox}/>
                            <Route path="/FileRequisitionProcessForm" component={FileRequisitionProcessForm}/>
                            <Route path="/FileRequisitionInitiatorForm" component={FileRequisitionInitiatorForm}/>
                            <Route path="/CsFormLoanClosing" component={CsFormLoanClosing}/>
                            <Route path="/CsSecuredLoanClosing" component={CsSecuredLoanClosing}/>

                            <Route path="/CSOAfterHLD" component={CSOAfterHLD}/>
                            <Route path="/GenerateEncashmentRequest/:appId" component={GenerateEncashmentRequest}/>
                            <Route path="/CsDeceasedMark" component={CsDeceasedMark}/>
                            <Route path="/CsWiaverRequest" component={CsWiaverRequest}/>

                            <Route path="/CsGroupInbox" component={CsGroupInbox}/>
                            <Route path="/ServiceRequest" component={ServiceRequest}/>
                            <Route path="/staticOtherRequest" component={Other}/>
                            <Route path="/firstLoginPage" component={FirstLoginPage}/>
                            <Route path="/businessHierarcy" component={BusinessHierarcy}/>
                            <Route path="/listForRV" component={ListForRV}/>
                            <Route path="/tagCluster" component={TagCluster}/>
                            <Route path="/LoanCustomerSearch" component={LoanCustomerSearch}/>
                            <Route path="/BranchSummaryReport" component={BranchSummaryReport}/>
                            <Route path="/RegularDebitCardBranchSummaryReport"
                                   component={RegularDebitCardBranchSummaryReport}/>
                            <Route path="/bondSpReport/:requestType" component={BondReport}/>
                            <Route path="/fundTransferReport/:requestType" component={fundTransferReport}/>
                            <Route path="/categoryWiseReport/:requestType" component={categoryWiseReport}/>
                            <Route path="/CardServiceReport" component={CardServiceReport}/>
                            <Route path="/commonInbox" component={Inbox}/>
                            <Route path="/commonReport/:requestType" component={CommonReport}/>
                            <Route path="/beneficiary" component={BeneficiaryAdd}/>
                            <Route path="/beneficiaryList" component={BeneficiaryList}/>
                            <Route path="/bankList" component={BankList}/>
                            <Route path="/CbAndAccountUpdateComponent" component={CbAndAccountUpdateComponent}/>
                            <Route path="/schemeUpdate" component={SchemeCodeUpdateCS}/>
                            <Route path="/CsSecuredLoanClosing" component={CsSecuredLoanClosing}/>
                            <Route path="/cardRequisition/:requestType" component={CsRequisition}/>
                            <Route path="/cardRequisitionTracker/:requestType" component={OpsdTracker}/>
                            <Route path="/cardRequisitionBranchTracker/:requestType" component={BranchTracker}/>
                            <Route path="/giftCardIssuance" component={GiftCardIssuance}/>
                            <Route path="/prepaidCardIssuance" component={PrepaidCardIssuance}/>
                            <Route path="/prepaidCardBulkDownload" component={BulkDownload}/>
                            <Route path="/schemeList" component={SchemeList}/>
                            <Route path="/bondInbox" component={BondInbox}/>
                            <Route path="/customDutyPayment" component={CustomDutyCs}/>
                            <Route path="/customDutyPaymentSD" component={CustomDutyMaker}/>
                            <Route path="/customerStatusUpdate" component={CustomStatusUpdateRequisition}/>
                            <Route path="/Greeting" component={Greeting}/>
                        </Switch>


                    </ProtectedLayout>

                )
            } else {

                return (
                    <ProtectedLayout>

                        <Switch>
                            {/*   360view  */}
                            <Route path="/Customer" component={Customer}/>
                            <Route path="/DemographicsView" component={DemographicsView}/>
                            <Route path="/Grouping" component={Grouping}/>
                            <Route path="/merchantInbox" component={MerchantInbox}/>


                            {/*  accountOpeningProcess*/}
                            <Route path="/newaccount" component={NewAccountDashboard}/>
                            <Route path="/newaccountsearchResult" component={DashboardNewAccountSearchResult}/>
                            <Route path="/GlobalSearch" component={GlobalSearch}/>


                            {/*  AdminModule*/}

                            {
                                this.menuShow(menuList, "User", "/userListDashboard", "UserListDashboard")

                            }
                            <Route path="/user" component={User}/>
                            <Route path="/subMenuAdd" component={SubMenuAdd}/>


                            {/*  File*/}
                            <Route path="/fileRead" component={UploadFileToAutoRead}/>

                            {/*  grouping*/}
                            <Route path="/GroupAndMemberAdd" component={GroupAndMemberAdd}/>
                            <Route path="/GroupingApproval" component={GroupingApproval}/>
                            <Route path="/TestAddForm" component={TestAddForm}/>


                            {/*  JsonForm*/}
                            <Route path="/DebitCard" component={DebitCard}/>
                            <Route path="/DebitCardList" component={DebitCardList}/>
                            <Route path="/DebitCardListDownload" component={DebitCardListDownload}/>
                            <Route path="/UploadDeferal" component={UploadDeferal}/>
                            <Route path="/CSRejectedPage" component={CSRejectedPage}/>
                            <Route path="/ImageCrop" component={ImageCrop}/>
                            <Route path="/BMBulkAccountOpening" component={BMBulkAccountOpening}/>
                            <Route path="/AuxiliaryTaskMapping" component={AuxiliaryTaskMapping}/>
                            <Route path="/AuxiliaryTaskInbox" component={AuxiliaryTaskInbox}/>

                            <Route path="/Bondmanagement" component={Bondmanagement}/>
                            <Route path="/Encashment" component={Encashment}/>
                            <Route path="/AcesEnrollment" component={AcesEnrollment}/>


                            {/*   Layouts*/}


                            <Route path="/inbox" component={CommonInbox}/>
                            <Route path="/assetBranchInbox" component={AssetBranchInbox}/>
                            <Route path="/assetUserInbox" component={AssetUserInbox}/>
                            <Route path="/ClaimCase" component={ClaimCase}/>

                            <Route path="/Remetance" component={Remetance}/>
                            <Route path="/OutwardRemetance" component={Outward}/>
                            <Route path="/studentFile" component={StudentFile}/>
                            <Route path="/medicalFile" component={MedicalFile}/>
                            <Route path="/InwardRemittance" component={InwardRemittance}/>
                            //Deliverbale all route
                            <Route path="/welcomeLetter" component={WelcomeLetterGenerate}/>
                            <Route path="/deliverablecheque" component={DeliverableChequeBookFrom}/>
                            <Route path="/deliverablecheques" component={DeliverableChequeBookFroms}/>
                            <Route path="/GreenDeltaDespatchReport/:reportType" component={GreenDeltaDespatchReport}/>
                            <Route path="/GreenDeltaStockAndSoldReport/:reportType" component={GreenDeltaStockAndSoldReport}/>
                            <Route path="/chequebookmanage" component={ChecqueBookManage}/>
                            <Route path="/chequebookmanages" component={ChecqueBookManages}/>
                            <Route path="/deliverablecard" component={DebitCardRequisition}/>
                            <Route path="/debitcardmanage" component={DebitCardManage}/>
                            <Route path="/csDebitCardManage" component={CsDebitCardManage}/>
                            <Route path="/CsChecqueBookManage" component={CsChecqueBookManage}/>
                            <Route path="/IncomingTrackerManage" component={IncomingTrackerManage}/>
                            <Route path="/OutgoingTrackerManage" component={OutgoingTrackerManage}/>
                            <Route path="/CsChecqueBookManages" component={CsChecqueBookManages}/>
                            <Route path="/BomChequeBookManage" component={BomChequeBookManage}/>
                            <Route path="/BomChequeBookManages" component={BomChequeBookManages}/>
                            <Route path="/welcomelettercreate/:priority" component={WelcomeLetterCreation}/>
                            <Route path="/welcomeletterbackofficeupload" component={WelcomeLetterBackOfficeUpload}/>
                            <Route path="/welcomeletterbranch/:priority" component={WelcomeLetterBranch}/>
                            <Route path="/debitcardfileupload" component={DebitCardFileUpload}/>
                            <Route path="/chequebookfileupload" component={ChequeBookFileUpload}/>

                            <Route path="/instapackrequisition" component={InstaPackRequisition}/>
                            <Route path="/instadebitcardrequisition" component={InstaDebitCardRequisition}/>
                            <Route path="/manageinstapack" component={ManageInstaPack}/>
                            <Route path="/csinstapackmanage" component={CsInstaPackManage}/>
                            <Route path="/manageinstadebitcard" component={ManageInstaDebitCard}/>
                            <Route path="/csmanageinstadebitcard" component={csInstaDebitCard}/>
                            <Route path="/instaDebitCardFileUpload" component={instaDebitCardFileUpload}/>
                            <Route path="/instaPackFileUpload" component={InstaPackFileUpload}/>
                            <Route path="/instaPackConfiguration" component={InstaPackConfiguration}/>
                            <Route path="/instaCardConfiguration" component={InstaCardConfiguration}/>


                            <Route path="/debitcardcheck" component={DebitCardSplitInbox}/>
                            <Route path="/debitcardchecksplit" component={DebitCardCheckerSplit}/>
                            <Route path="/instapacksplit" component={InstaPackSplit}/>
                            <Route path="/chequebooksplit" component={ChequeBookSplit}/>
                            <Route path="/instacardsplit" component={InstaCardSpilt}/>
                            //Debit Insta Card
                            //Debit Insta Card
                            <Route path="/InstaDebitCardRequest" component={InstaDebitCardRequest}/>
                            <Route path="/VerifyInstaDebitCardBOM" component={VerifyInstaDebitCardBOM}/>
                            //AdcService
                            <Route path="/AdcService/:type" component={AdcService}/>
                            <Route path="/CityTouchReport" component={CityTouchReport}/>
                            <Route path="/csService/:enduser" component={CustomerServiceFund}/>
                            <Route path="/sdService/:enduser" component={MakerCustomerServiceInitialized}/>
                            {/*mainteannce*/}
                            <Route path="/VerifyCallCenter" component={VerifyCallCenter}/>
                            <Route path="/Maintenance" component={Maintenance}/>
                            <Route path="/AccountMaintenance/:requestType" component={AccountMaintenance}/>
                            <Route path="/AccountMaintenance" component={AccountMaintenance}/>
                            <Route path="/LinkedAccountMaintenance/:cbNumber/:acNumber"
                                   component={LinkedAccountMaintenance}/>
                            <Route path="/closerMaintenance/:maintenance" component={CasaCloserMaintenance}/>
                            <Route path="/casaCloserRequest/:maintenance" component={CloserRequestWiseMaintenance}/>
                            <Route path="/dpsCloser" component={DpsCloser}/>
                            <Route path="/dpsCloserMaintenance" component={DpsCloserMaintenance}/>
                            <Route path="/VarifyMakerAccountMaintenance" component={VarifyMakerAccountMaintenance}/>
                            <Route path="/VerifyCheckerAccountMaintenance" component={VerifyCheckerAccountMaintenance}/>
                            <Route path="/VerifyDocumentBOM2" component={VerifyDocumentBOM2}/>
                            <Route path="/BMApproval2" component={BMApproval2}/>
                            <Route path="/FDRMaintenance" component={FDRMaintenance}/>
                            <Route path="/FdrEncashment" component={FdrEncashment}/>

                            //// Locker///

                            <Route path="/LockerSearch" component={LockerSearch}/>
                            <Route path="/LockerSurrender" component={CSOLockerSurrender}/>
                            <Route path="/LockerDidupSearch" component={LockerDidupSearch}/>
                            <Route path="/LockerBranch" component={LockerBranch}/>
                            <Route path="/TabTable" component={TabTable}/>
                            <Route path="/ExcelUpload" component={ExcelUpload}/>
                            <Route path="/LockerMaintenance" component={LockerMaintenance}/>

                            //Asset EFTN
                            <Route path="/EFTNExcelUpload" component={EFTNExcelUpload}/>
                            <Route path="/EFTNDatabase" component={EFTNDatabase}/>

                            //Reporting
                            <Route path="/LWFExcelReport/:position" component={LWFExcelReport}/>


                            //User Registration
                            <Route path="/UserRegistration" component={UserRegistration}/>
                            {/*<Route path="/PasswordChange" component={PasswordChange}/>*/}
                            <Route path="/ResetPasswordAdmin" component={ResetPasswordAdmin}/>
                            <Route path="/ActivateUserAdmin" component={ActivateUserAdmin}/>
                            <Route path="/DeactiveUserAdmin" component={DeactiveUserAdmin}/>
                            <Route path="/RoleSwitchPermissionList" component={RoleSwitchPermissionList}/>
                            <Route path="/WorkplaceUnitList" component={WorkplaceUnitList}/>
                            <Route path="/allUserList" component={UserList}/>
                            <Route path="/SdDepartmentAndTeamUpdate" component={SdDepartmentAndTeamUpdate}/>
                            <Route path="/RoleAdd" component={RoleAdd}/>
                            <Route path="/InsuranceValuecalculationMasterDataList" component={InsuranceValuecalculationMasterDataList}/>
                            <Route path="/InsuranceMasterDataList" component={InsuranceMasterDataList}/>
                            <Route path="/RequestInitializations" component={RequestInitializations}/>
                            <Route path="/StampSaleRequest" component={StampSaleRequest}/>
                            <Route path="/OutgoingDispatchIntiator" component={OutgoingDispatchIntiator}/>
                            <Route path="/IncomingDispatchIntiator" component={IncomingDispatchIntiator}/>
                            <Route path="/GreenDeltaRequisitionReport/:reportType" component={GreenDeltaRequisitionReport}/>
                            <Route path="/UserEdit" component={UserEdit}/>
                            <Route path="/DelegationOfAuthority" component={DelegationOfAuthority}/>
                            <Route path="/UserBulkUpload" component={UserBulkUpload}/>

                            {/*Green Delta*/}
                            <Route path="/branch-configuration" component={BranchConfiguration}/>
                            <Route path="/document-type-configuration" component={DocumentTypeConfiguration}/>

                            //Dashboard pie chart
                            <Route path="/dashboard" component={CsDashboard}/>
                            <Route path="/FDRDashboard" component={FDRDashboard}/>
                            {/*  MasterModule*/}
                            <Route path="/branch" component={Branch}/>
                            <Route path="/businessDivision" component={BusinessDivision}/>
                            <Route path="/customerCategory" component={CustomerCategory}/>
                            <Route path="/city" component={City}/>
                            <Route path="/CityAddEditDelete" component={CityAddEditDelete}/>
                            <Route path="/customerConstitution" component={CustomerConstitution}/>
                            <Route path="/CustomerOffer" component={CustomerOffer}/>
                            <Route path="/country" component={Country}/>
                            <Route path="/customerBand" component={CustomerBand}/>
                            <Route path="/customerSegment" component={CustomerSegment}/>
                            <Route path="/customerType" component={CustomerType}/>
                            <Route path="/gender" component={Gender}/>
                            <Route path="/lineOfBusiness" component={LineOfBusiness}/>
                            <Route path="/notificationMaster" component={Notification}/>
                            <Route path="/occupation" component={Occupation}/>
                            <Route path="/Product" component={Product}/>
                            <Route path="/salutation" component={Salutation}/>
                            <Route path="/sector" component={Sector}/>
                            <Route path="/sourceMaster" component={SourceMaster}/>
                            <Route path="/state" component={State}/>
                            <Route path="/subSector" component={SubSector}/>

                            {/*  ProcessFlow*/}
                            <Route path="/BranchProcessFlowConfigureAdd" component={BranchProcessFlowConfigureAdd}/>
                            <Route path="/SdAndBranchFlowConfigureAllData" component={SdAndBranchFlowConfigureAllData}/>
                            <Route path="/SdAndBranchProcessFlow" component={SdAndBranchProcessFlow}/>
                            <Route path="/SdFlowConfigureAddUpdateDelete" component={SdFlowConfigureAddUpdateDelete}/>
                            <Route path="/SdProcessFlowMasterAdd" component={SdProcessFlowMasterAdd}/>
                            <Route path="/SdProcessFlowMasterBranchSearch" component={SdProcessFlowMasterBranchSearch}/>


                            {/*  sidebarAdminMenu*/}
                            <Route path="/addMenu" component={AddMenu}/>
                            <Route path='/menuListDashboard' component={MenuListDashboard}/>
                            <Route path='/roleListDashboard' component={RoleListDashboard}/>
                            <Route path='/RequestInitialization' component={RequestInitialization}/>
                            <Route path='/UserRoleEdit' component={UserRoleEdit}/>
                            <Route path="/customerForm" component={UserListDashboard}/>
                            <Route path="/userListDashboard" component={UserListDashboard}/>

                            {/*  Table*/}
                            <Route path="/relationship" component={RelashionShipView}/>
                            <Route path='/customerList' component={CustomerList}/>


                            {/*   workflow*/}
                            <Route path="/bmApproval" component={BMApproval}/>
                            <Route path="/dataCaptureCS" component={DataCaptureCS}/>
                            <Route path="/verifyDocumentBOM" component={VerifyDocumentBOM}/>
                            <Route path="/MaintenanceWIthPrintFile" component={MaintenanceWIthPrintFile}/>
                            <Route path="/MaintenanceWIthFiles" component={MaintenanceWIthFile}/>
                            <Route path="/AgentBanking" component={AgentBanking}/>
                            <Route path="/AdminReassignCase" component={AdminReassignCase}/>
                            <Route path="/MerchantDedup" component={MerchantDedup}/>
                            <Route path="/MbuClose" component={MbuClose}/>
                            <Route path="/CSDeferalClose" component={CSDeferalClose}/>

                            {/* card cheque */}
                            <Route path="/CsOpening" component={CsOpening}/>
                            <Route path="/BomOpening" component={BOMOpening}/>
                            {/*card maintenance and call category*/}
                            <Route path="/MaintanceRequest" component={MaintenanceRequest}/>
                            <Route path="/CardDivision" component={CardDivision}/>
                            <Route path="/MerchantMaintenance" component={MerchantMaintenance}/>

                            <Route path="/cbGlobalSearch" component={CBGlobalSearch}/>
                            <Route path="/CustomerDedupSearch" component={CustomerDedupSearch}/>
                            <Route path="/CustomerDedupeAndSdnSearch" component={CustomerDedupeAndSdnSearch}/>
                            <Route path="/CustomerSearch" component={CustomerSearch}/>
                            <Route path="/CustomerSearches" component={CustomerSearches}/>
                            <Route path="/WelcomeLetterGenerate" component={WelcomeLetterGenerate}/>
                            <Route path='/customerServed' component={CustomerServedList}/>
                            <Route path="/statistics" component={CustomerServedList}/>
                            <Route path='/managerView' component={ManagerView}/>
                            <Route path="/ProcessmakerDashboard" component={ProcessmakerDashboard}/>
                            <Route path="/welcomeLetter" component={WelcomeLetterGenerate}/>
                            <Route path="/testReza" component={TestReza}/>
                            <Route path="/TestForm" component={TestForm}/>
                            <Route path="/creditcardchequebook" component={CreditCardChequeBookRequisition}/>
                            <Route path="/creditcardchequebookmanage" component={CreditCardChequeBookManage}/>
                            <Route path="/creditcardchequebookfileupload" component={CreditCardChequeBookFileUpload}/>
                            <Route path="/editor" component={FormatEditor}/>
                            <Route path="/mandateupdate" component={MandateUpdate}/>
                            {/*<Route path="/signatoryMaintenance" component={MandateUpdate}/>*/}
                            <Route path="/Search" component={Search}/>
                            <Route path="/bondSanchayapatraReport" component={BondFilter}/>
                            <Route path="/mailConfiguration" component={MailNotification}/>
                            <Route path="/mailConfigurationList" component={MailConfigurationList}/>
                            <Route path="/createCallCategory" component={CreateCallCategory}/>
                            <Route path="/createGroup" component={CreateGroup}/>
                            <Route path="/assignCallGroup" component={AssignGroup}/>
                            <Route path="/unlockForLineManager" component={UnlockComponentForLineManager}/>
                            <Route path="/UpdateCallCategory" component={UpdateCallCategory}/>
                            <Route path="/existingGroup" component={ModifyExistingGroup}/>
                            <Route path="/ModifyExistingGroupByUser" component={ModifyExistingByUser}/>
                            <Route path="/cardInbox" component={cardInbox}/>
                            <Route path="/inwardFileUpload/:uploadType" component={InwardFileUpload}/>
                            //Corporate Prepaid Card
                            <Route path="/corporatePrepaidCardFileUpload" component={CorporatePrepaidCardFileUpload}/>
                            <Route path="/manageCorporatePrepaidCard" component={ManageCorporatePrepaidCard}/>

                            {/*asset operation route path*/}
                            <Route path="/emidatechange" component={CsoRequisition}/>
                            <Route path="/loanaccounttenorchange" component={CsoRequisitionForLoan}/>
                            <Route path="/interesttransfer" component={CsoRequisitionForInterestTransfer}/>
                            <Route path="/assetopsmis" component={AssetOpsMis}/>
                            <Route path="/AssetManager" component={AssetManager}/>
                            <Route path="/FileRequisition" component={FileRequisition}/>
                            <Route path='/FileRequisitionHistory' component={FileRequisitionHistory}/>
                            <Route path="/AssetManagerAddEdit" component={AssetManagerAddEdit}/>
                            <Route path="/ArchiveManagerStatusQuery" component={ArchiveManagerStatusQuery}/>

                            <Route path="/CentralArchiving" component={CentralArchiving}/>
                            <Route path="/FileRequisitionProcessInbox" component={FileRequisitionProcessInbox}/>
                            <Route path="/FileLockerBranchRequisitionInitiatorInbox"
                                   component={FileRequisitionInitiatorInbox}/>
                            <Route path="/FileRequisitionProcessForm" component={FileRequisitionProcessForm}/>
                            <Route path="/FileRequisitionInitiatorForm" component={FileRequisitionInitiatorForm}/>
                            <Route path="/CsFormLoanClosing" component={CsFormLoanClosing}/>

                            {/*asset operation route path*/}
                            <Route path="/emidatechange" component={CsoRequisition}/>
                            <Route path="/loanaccounttenorchange" component={CsoRequisitionForLoan}/>
                            <Route path="/interesttransfer" component={CsoRequisitionForInterestTransfer}/>
                            <Route path="/assetopsmis" component={AssetOpsMis}/>
                            <Route path="/AssetManager" component={AssetManager}/>
                            <Route path="/FileRequisition" component={FileRequisition}/>
                            <Route path="/AssetManagerAddEdit" component={AssetManagerAddEdit}/>
                            <Route path="/ArchiveManagerStatusQuery" component={ArchiveManagerStatusQuery}/>

                            <Route path="/CentralArchiving" component={CentralArchiving}/>
                            <Route path="/FileRequisitionProcessInbox" component={FileRequisitionProcessInbox}/>
                            <Route path="/FileRequisitionInitiatorInbox" component={FileRequisitionInitiatorInbox}/>
                            <Route path="/FileRequisitionProcessForm" component={FileRequisitionProcessForm}/>
                            <Route path="/FileRequisitionInitiatorForm" component={FileRequisitionInitiatorForm}/>
                            <Route path="/CsFormLoanClosing" component={CsFormLoanClosing}/> //Not Secured
                            <Route path="/CsSecuredLoanClosing" component={CsSecuredLoanClosing}/>
                            <Route path="/CSOAfterHLD" component={CSOAfterHLD}/>
                            <Route path="/GenerateEncashmentRequest/:appId" component={GenerateEncashmentRequest}/>
                            <Route path="/CsDeceasedMark" component={CsDeceasedMark}/>
                            <Route path="/CsWaiverRequest" component={CsWiaverRequest}/>

                            <Route path="/PartialPayment" component={CsPartialPayment}/>

                            <Route path="/OutstandingCertificate" component={CsOutstandingCertificate}/>

                            <Route path="/LienMarking" component={CsLienMarking}/>

                            <Route path="/RecoveryAmount" component={CsRecoveryAmount}/>

                            <Route path="/PayOrder" component={MakerPayOrderStart}/>

                            <Route path="/CsGroupInbox" component={CsGroupInbox}/>
                            <Route path="/ServiceRequest" component={ServiceRequest}/>
                            <Route path="/globalSearchAwf" component={GlobalSearchAwf}/>
                            <Route path="/staticOtherRequest" component={Other}/>
                            <Route path="/firstLoginPage" component={FirstLoginPage}/>
                            <Route path="/businessHierarcy" component={BusinessHierarcy}/>
                            <Route path="/listForRV" component={ListForRV}/>
                            <Route path="/tagCluster" component={TagCluster}/>
                            <Route path="/LoanCustomerSearch" component={LoanCustomerSearch}/>
                            <Route path="/BranchSummaryReport" component={BranchSummaryReport}/>
                            <Route path="/RegularDebitCardBranchSummaryReport"
                                   component={RegularDebitCardBranchSummaryReport}/>
                            <Route path="/bondSpReport/:requestType" component={BondReport}/>
                            <Route path="/fundTransferReport/:requestType" component={fundTransferReport}/>
                            <Route path="/categoryWiseReport/:requestType" component={categoryWiseReport}/>
                            <Route path="/CardServiceReport" component={CardServiceReport}/>
                            <Route path="/commonInbox" component={Inbox}/>
                            <Route path="/commonReport/:requestType" component={CommonReport}/>
                            <Route path="/beneficiary" component={BeneficiaryAdd}/>
                            <Route path="/beneficiaryList" component={BeneficiaryList}/>
                            <Route path="/bankList" component={BankList}/>
                            <Route path="/CbAndAccountUpdateComponent" component={CbAndAccountUpdateComponent}/>
                            <Route path="/schemeUpdate" component={SchemeCodeUpdateCS}/>
                            <Route path="/CsSecuredLoanClosing" component={CsSecuredLoanClosing}/>
                            <Route path="/cardRequisition/:requestType" component={CsRequisition}/>
                            <Route path="/cardRequisitionTracker/:requestType" component={OpsdTracker}/>
                            <Route path="/cardRequisitionBranchTracker/:requestType" component={BranchTracker}/>
                            <Route path="/giftCardIssuance" component={GiftCardIssuance}/>
                            <Route path="/prepaidCardIssuance" component={PrepaidCardIssuance}/>
                            <Route path="/prepaidCardBulkDownload" component={BulkDownload}/>
                            <Route path="/schemeList" component={SchemeList}/>
                            <Route path="/bondInbox" component={BondInbox}/>
                            <Route path="/customDutyPayment" component={CustomDutyCs}/>
                            <Route path="/customDutyPaymentSD" component={CustomDutyMaker}/>
                            <Route path="/customerStatusUpdate" component={CustomStatusUpdateRequisition}/>
                            <Route path="/Greeting" component={Greeting}/>
                        </Switch>


                    </ProtectedLayout>

                )
            }

        }
        else{
            return (
                <Redirect to={{
                    pathname: '/login',
                    }}
                />
            )
        }
    }
}

export default ProtectedRoute;
