import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class Maintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: null,
            uploadImageModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeUpdate: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                accountNumber: "",
                csDeferal: "NO",
                csBearer: "NO",
                customerName: "FAISAL AHMED",
                cbNumber: "CB1401933",
                branchName: "ANDARKILLA BRANCH",
                fatherName: "",
                motherName: '',
                spouseName: '',
                nidNo: '',
                passportNo: '',
                birthCertificateNo: '',
                dob: '',
                // panGirNoTinUpdateOld: 1234,
                // customerNameOld: "Raihan Rahman",
                // titleOld: "Mr",
                // postalCodeOld: "6654",
                // nomineeNameOld: "Samin Khan",
                // countryGuardianOld: "Bangladesh",
                // postalGuardianCodeOld: "7788",
                // cityGuardianCodeOld: "3344",
                // stateGuardianCodeOld: "4455",
                // relationshipOld: "Sister",
                // address1Old: "Dhaka",
                // address2Old: "Dkaka",
                // cityCodeOld: "2345",
                // stateCodeOld: "3342",
                // regNoOld: "44455",
                // nomineeMinorOld: "Monir",
                // guardianNameOld: "Mr. Salam",
                // guardianCodeOld: "3444",
                // addressGuardianOld: "3344",
                // expiryDateOld: "21-10-1999",
                // issueDateOld: "11-10-1999",
                // nationalIdCardOld: "21321432535",
                // passportDetailsOld: "21424325325",
                // passportNoOld: "21432535",
                // phoneNo1Old: "01912345567",
                // phoneNo2Old: "01813213456",
                // emailOld: "mamun@gmail.com",
                // despatchModeOld: "ewqrr",
                // cityOld: "Dhaka",
                // communicationAddress1Old: "Uttor Badda",
                // communicationAddress2Old: "Gulsan",
                // countryOld: "Bangladesh",
                // employerAddress1Old: "Mirpur",
                // employerAddress2Old: "Shamoli",
                // permanentAddress1Old: "Khulna",
                // permanentAddress2Old: "Khulna",
                //
                // stateOld: "dsgdsg",
                // rELATIONTYPEOld: "23535346",
                // rELATIONCODEOld: "325353",
                // dESIGNATIONCODEOld: "23423532",
                // cUStIDOld: "243252",
                // dobOld: "21-10-1999",
                // fatherOld: "dsgdsfh",
                // motherOld: "dfdsfdsgsd",
            },
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,


            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,


            setOpen: true,
        }
    }

    myFunction = () => {
        window.print();
    };
    close = () => {

        this.props.closeModal();
    };
    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    componentDidMount() {
        this.handleClose();
        this.setState({
            branchName: this.state.inputData.branchName
        })
    }

    render() {
        return (
            <div className="container">
                <h4 style={{color: "white"}} >
                    Green Delta Insurance</h4>


                <center><h4 style={{color: "white"}} >Individual Information Update Form with Existing Information</h4></center>

                <Grid item xs={3}>
                    <button onClick={this.myFunction}>Print</button>
                </Grid>
                <table className="table table-bordered">

                    <tbody>
                    <tr>
                        <td width="30%">Branch Name</td>
                        <td colspan="4">{this.state.inputData.branchName}</td>
                    </tr>
                    <tr>
                        <td>Customer Name</td>
                        <td colspan="4">{this.state.inputData.customerName}</td>
                    </tr>
                    <tr>
                        <td width="30%">CB Number</td>
                        <td width="30%">{this.state.inputData.cbNumber}</td>
                        <td width="20%">Account Number</td>
                        <td width="20%">{this.state.inputData.accountNumber}</td>
                    </tr>
                    <tr>
                        <td>Any other Account with CBL</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Issue</td>
                        <td>Existing Data</td>
                        <td colspan="3">New Data</td>
                    </tr>
                    <tr>
                        <td>Father’s Name ( Block Letters)</td>
                        <td>{this.state.inputData.fatherName}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Mother’s Name ( Block Letters)</td>
                        <td>{this.state.inputData.motherName}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Spouse’s Name( Block Letters)</td>
                        <td>{this.state.inputData.spouseName}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>National Id Card No.</td>
                        <td>{this.state.inputData.nidNo}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Previous NID Verification Confirmation</td>
                        <td>v</td>
                        <td colspan="3"></td>
                    </tr>

                    <tr colspan="2">
                        <td colspan="4">If previously provided NID was unverified, new NID needs to be taken and
                            verified from EC Database
                        </td>

                    </tr>
                    <tr>
                        <td>Passport No. ( If any )</td>
                        <td>{this.state.inputData.passportNo}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Passport Issue Date</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Passport Expiry Date</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Passport issue Place</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Birth Certificate Number (BC) /
                            Driving License (DL)
                        </td>
                        <td>{this.state.inputData.birthCertificateNo}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>TIN No. (If Any)</td>
                        <td>{this.state.inputData.tin}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Date of Birth (dd/mm/yy)</td>
                        <td>{this.state.inputData.dob}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Place of Birth (District/City)</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Country of Birth</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Religion</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Nationality</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Resident Status</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Profession</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Monthly Income</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Name of the Employer/Organization</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>E-mail Address</td>
                        <td>{this.state.inputData.email}</td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Contact Number 1</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    <tr>
                        <td>Contact Number 2</td>
                        <td></td>
                        <td colspan="3"></td>
                    </tr>
                    </tbody>
                </table>
                <br/>
                <br/>
                <br/>
                ---------------------------------------------------------------<br/>
                <b>A/C holder’s Signature (with Date)<br/>
                    Name: FAISAL AHMED</b>
                <br/>
                <br/>
                <br/>
                <br/>

                <h4 style={{color: "white"}} >Green Delta Insurance</h4>
                <table className="table table-bordered">

                    <tbody>
                    <tr>
                        <td height="120">Communication Address:</td>
                        <td width="40%"></td>
                        <td width="30%"></td>
                    </tr>
                    <tr>
                        <td width="30%" height="120">Business Address:</td>
                        <td width="40%"></td>
                        <td width="30%"></td>
                    </tr>
                    <tr>
                        <td width="30%" height="120">Permament Address</td>
                        <td width="40%"></td>
                        <td width="30%"></td>
                    </tr>
                    <tr>
                        <td colSpan="3">Emergency Contact :</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Name</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Address</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Email</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Mobile</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Relationship with Account Holder</td>
                    </tr>
                    <tr>
                        <td>FATCA Declaration</td>
                        <td></td>
                        <td colSpan="3">3. Do you hold a U.S. permanent resident card</td>
                    </tr>
                    <tr>

                        <td>1. Are you a U.S. Resident <input type="radio" name="Yes" value="Yes"/> Yes<input
                            type="radio" name="No" value="No"/>
                            No
                        </td>
                        <td>2. Are you a U.S. Citizen <input type="radio" name="Yes" value="Yes"/> Yes<input
                            type="radio" name="No" value="No"/> No
                        </td>
                        <td colSpan="3">(Green Card) <input type="radio" name="Yes" value="Yes"/> Yes<input type="radio"
                                                                                                            name="No"
                                                                                                            value="No"/> No
                        </td>
                    </tr>
                    <tr>
                        <td>Sector Code
                            (Mandatory fields for Regulatory)
                        </td>
                        <td></td>
                        <td colSpan="3"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withStyles(styles)(Maintenance);