import React, {Component} from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import {backEndServerURL} from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";

var jsonForm = [

    {
        "varName": "deliveryType",
        "type": "select",
        "enum": [
            "COURIER",
            "MESSENGER"
        ],
        "required": true,
        "label": "Delivery  Type",
        "grid": 6
    },
    {
        "varName": "courierName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "multiline": true,
        "label": "Courier User",
        "conditional": true,
        "conditionalVarName": "deliveryType",
        "conditionalVarValue": "COURIER",
        "grid": 6
    },
    {
        "varName": "messengerName",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Messenger User",
        "conditional": true,
        "conditionalVarName": "deliveryType",
        "conditionalVarValue": "MESSENGER",
        "grid": 6
    },


];

class RequestInitialization extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        getData: false,
        workstation: false,
        role: false,
        showValue: false,
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        err: false,
        varValue: [],
        errorArray: {},
        errorMessages: {},
        errorWorkPlace: false,
        errorgroup: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getGroupListData: false,
        getworkplaceTypeListData: false,
        workplace: "",
        type: "success",
        submitButton: false,
    };
    emptyValueRemove = (data) => {
        if (data === '' || data === ' ' || data === "null" || data === "undefined")
            data = "";
        return data;
    };
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(jsonForm), this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            this.setState({
                loading: false
            });
            return 0;
        }
        let request = {};
        request.rowId = this.props.rowIdArray;
        request.team = this.state.inputData.deliveryType;
        if (this.state.inputData.deliveryType !== undefined && this.state.inputData.deliveryType === 'COURIER') {
            request.username = this.state.inputData.courierName.key
        } else {
            request.username = this.state.inputData.messengerName.key
        }
        let url = backEndServerURL + "/assignDeliveryUser/" + this.props.activeItem + "/" + "Allocated";
        axios
            .post(url, request, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.props.closeModal();
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Allocated!",
                    alert: true,
                    loading: false,
                })
            })
            .catch(error => {
                this.setState({
                    loading: false,
                })
            });
    };
    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px"
                }}
                onClick={this.handleSubmit}
            >
                Submit
            </button>)
    };

    getCouriers() {
        let url = backEndServerURL + "/username/COURIER";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let array = [];
                for (let i = 0; i < response.data.length; i++) {
                    array.push({
                        value: response.data[i].value,
                        key: response.data[i].key,
                    })
                }
                for (let form of jsonForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "courierName") {
                        form.enum = array
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getMessengers() {
        let url = backEndServerURL + "/username/MESSENGER";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let array = [];
                for (let i = 0; i < response.data.length; i++) {
                    array.push({
                        value: response.data[i].value,
                        key: response.data[i].key,
                    })
                }
                for (let form of jsonForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "messengerName") {
                        form.enum = array
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.getCouriers();
        this.getMessengers();
        this.setState({
            showValue: true,
            getData: true
        })
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    }
    renderHeader = () => {
        return (
            <h4 className="text-white">Deliver to Courier or Messenger<a><CloseIcon onClick={this.close} style={{
                position: 'absolute',
                right: 10,
                color: "white"
            }}/></a></h4>
        )
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false
        })
    };
    jsonForm = () => {
        return (CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent))
    };

    render() {
        return (
            <Card>
                <CardHeader style={{backgroundColor: '#142398'}}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    open={this.state.reference}>
                                    <DialogContent>
                                        <ReferenceModal
                                            closeModal={this.closeModalCBNotFound}
                                            referenceTitle={this.state.referenceTitle}
                                        />
                                    </DialogContent>
                                </Dialog>
                                {this.renderNotification()}
                                {this.jsonForm()}
                                <br/>
                                <Grid item xs={12}>
                                    {this.renderButton()}
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default RequestInitialization;