import theme from "../JsonForm/CustomeTheme2";
import React, {Component} from "react";
import {rowsForInsuranceMasterDataList} from "../workflow/ColumnNameForInbox";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Notification from "../NotificationMessage/Notification";
import Badge from "@material-ui/core/Badge";
import TableComponent from "../workflow/CommonComponent/TableComponent";
import CaseViewPage from "../workflow/CommonComponent/CaseViewPage";
import SubmittedCaseHistory from "../workflow/CommonComponent/SubmittedCaseHistory";
import Functions from "../../Common/Functions";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {Button, Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loading from "../../Static/loader.gif";
import CBNotFound from "../workflow/CASA/CBNotFound";
import {ThemeProvider} from "@material-ui/styles";
import withStyles from "@material-ui/core/styles/withStyles";
 import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
 import InsuranceMasterDataValueCalculation from "./InsuranceMasterDataValueCalculation";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class InsuranceValuecalculationMasterDataList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 100,
            tableColumns: [],
            page: 0,
            getCountPending: 0,
            getCountReturn: 0,
            getCountInbox: 0,
            getCountRectified: 0,
            getCountGroupInbox: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            deferralId: "",
            deferralType: "",
            deferralDocType: "",
            account_number: "",
            customer_name: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",
            expire: "",
            expireStatus: "",
            CaseId: "",
            AddRoleSwitchPermission: false,
            frocefullyEdit: false,
        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        tableColumn = [...rowsForInsuranceMasterDataList];

        return tableColumn;
    };


    getApiUrl = () => {
        let url = "";

        const data = [];

        url = backEndServerURL + '/get/insuranceValueCalculationMasterDataList';
        this.setState({
            activeItem: 'ACTIVE',
        });



        return url;
    };



    componentDidMount() {

        this.fetchTableData(this.getApiUrl(), 0)


    }
    disablePermission = (status,username) => {
        this.setState({
            frocefullyEdit:true,
            username
        })

    }
    disableButton = (status,username) => {
        return(
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    marginTop: '15px',
                }}
                onClick={event => this.disablePermission(status,username)}
                type="submit"
            >
                Delete
            </button>
        )
    }



    fetchTableData = (url, page) => {
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.map((message, index) => {
                tableData.push({

                    SL: page > 0 ? ((index + 1) + (100 * page)) : index + 1,
                    insurancType:message.insurancType,
                    code:message.code,
                    amountCalculationType:message.amountCalculationType,
                    baseValueStart:message.baseValueStart,
                    baseValueEnd:message.baseValueEnd,
                    stampValue:message.stampValue,
                    incrementalAmount:message.incrementalAmount,
                    incrementalAmountValue:message.incrementalAmountValue,
                    baseValue:message.baseValue,
                   // action:this.disableButton(message.anotherRolePermission,message.username)
                });
            });
            console.log("tableData")
            console.log(tableData)
            this.setState({
                //  page: response.data.number,
                //  totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    open = (e) => {
        e.preventDefault();
        this.setState({
            AddRoleSwitchPermission: true
        })
    };
    AddRoleSwitchPermissionCloseModal = () => {
        this.setState({
            AddRoleSwitchPermission: false
        })

    };
    AddRoleSwitchPermissionCloseModalpageReload = () => {
        this.setState({
            AddRoleSwitchPermission: false,
            getData: false,
            showTable: false,
            loading: true

        })
        this.fetchTableData(this.getApiUrl(), 0)
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Master Data (Value Calculation) List
                 
                <Button
                 type='submit'
          onClick={this.open}
          
          style={{
            float: "right",
            verticalAlign: "right",
            backgroundColor: "#FDF0E0",
            fontSize: "bold",
            fontWeight: 'bolder',
            // color: "#ffffff",             
             //fontSize:'12px',
          }}
          variant="contained"
          color="success"
          size="small"
        >
          + Add New
        </Button>
            </h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory) => {
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };
    customizeStatus = (status) => {

    }
 
    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "getAllAnotherRoleChangePermission";

        this.fetchTableData(urls, page)
    };


    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge /**/ color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };


    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER") {
            let url = backEndServerURL + "/deferral/case/claim/" + rowData.deferralId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            
                .then(response => {
                    
                    if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                        this.setInboxProps(rowData)
                    } else {
                        this.setState({
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })

        } else {
            if (this.state.activeItem !== "SUBMIT" || this.state.activeItem !== "COMPLETE") {
                this.setInboxProps(rowData)
            } else {
                this.setState({
                    loading: false
                })
            }
        }

    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: this.customizeStatus(rowData.Status),
            taskTitle: rowData.taskTitle,
            status: this.customizeStatus(rowData.requestStatus),
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            deferralId: rowData.deferralId,
            deferralType: rowData.deferralType,
            deferralDocType: rowData.deferralDocType,
            showInbox: (this.state.activeItem !== "SUBMIT" && this.state.activeItem !== "COMPLETE" ?true:false),
            loading: false
        });
    };


    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable === true) &&
            <div className="deliverable">
                <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                    /*onRowClick={this.onRowClick}*/ loader={this.state.loading} maxBodyHeight="450px"
                                totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                                handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}

                />
            </div>

        )
    };

    closeModal = (user, data, action) => {
        let userType = "";
        if (user === "CS") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To BOM!",
                alert: true,
            })
        } else if (user === "BOM" && action === "APPROVED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To CHECKER!",
                alert: true,
            })
        } else if (user === "BOM" && action !== "APPROVED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To CS!",
                alert: true,
            })
        } else if (user === "CHECKER" && action === "APPROVED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Successfully Close!",
                alert: true,

            })
        } else if (user === "CHECKER" && action !== "APPROVED") {
            this.setState({
                title: "Successfull!",
                notificationMessage: "Forward To CS!",
                alert: true,

            })
        }
        this.fetchTableData();
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };
    closeIcon = () => {
        if(this.state.activeItem==="Group Inbox"){
            this.closeModal()
        }
        else {
            this.setState({
                showInbox: false,
                loading: false,
            })
        }
    };
    renderInboxCase = () => {
        if ((this.state.getData && this.state.showTable)) {
            return (
                /*  <CaseViewPage closeModal={this.closeModal} closeIcon={this.closeIcon} deferralId={this.state.deferralId}
                                appId={this.state.appUid}
                                serviceType={this.state.serviceType} taskTitle={this.state.taskTitle}
                                subServiceType={this.state.subserviceType}/>*/
                <CaseViewPage delStatus={this.state.delStatus} closeModal={this.closeModal} closeIcon={this.closeIcon}
                              appId={this.state.appUid} CaseId={this.state.CaseId}
                              deferralId={this.state.deferralId}
                              branch={this.state.branchName}
                              subCategory={this.state.subCategory} category={this.state.category}
                              serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                              freeFlag2={this.state.freeFlag2}
                              freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                              recpmtid={this.state.recpmtid}
                              accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                              customerName={this.state.customer_name}
                              solId={this.state.solId}
                              subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
            )
        }

    }

    renderInboxCaseSubmitted = () => {
        return (
            <SubmittedCaseHistory delStatus={this.state.delStatus} closeModal={this.closeIcon}
                                  closeIcon={this.closeIcon}
                                  appId={this.state.appUid}
                                  subCategory={this.state.subCategory}
                                  CaseId={this.state.CaseId}
                                  category={this.state.category} serviceType={this.state.serviceType}
                                  freeFlag1={this.state.freeFlag1}
                                  freeFlag2={this.state.freeFlag2}
                                  freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                  recpmtid={this.state.recpmtid}
                                  accountNumber={this.state.account_number} cbNumber={this.state.cb_number}
                                  customerName={this.state.customer_name}
                                  solId={this.state.solId}
                                  subServiceType={this.state.subServiceType} taskTitle={this.state.taskTitle}/>
        )
    };

    renderInboxComponent = () => {
        if (this.state.activeItem === "SUBMIT" || this.state.activeItem === "COMPLETE") {
            // return 0;
        } else {
            return this.renderInboxCase()
        }
    };
    closeModalForForceFully=()=>{
        this.setState({
            frocefullyEdit:false
        })
        let url = backEndServerURL + "/deleteAnotherRoleChangePermission/" + this.state.username;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log('refetch data',this.props.showTableData(backEndServerURL + '/get/insuranceValueCalculationMasterDataList', 0));
        console.log(response.data.status !== "UNSUCESSFULL")
        this.props.showTableData(backEndServerURL + '/get/insuranceValueCalculationMasterDataList', 0);
                this.setState({
                    getData: false,
                    showTable: false,
                    loading: true
                })
                this.fetchTableData(this.getApiUrl(), 0)
            })
            .catch((error)=>{
                console.log(error);
            })
    }
    closeIconForForceFully=()=>{
        this.setState({
            frocefullyEdit:false,
            loading:false
        })

    }
     render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={this.state.AddRoleSwitchPermission}>
                        <DialogContent>
                            <InsuranceMasterDataValueCalculation afterSuccessFullyAdd={this.AddRoleSwitchPermissionCloseModalpageReload}
                                                                 closeModal={this.AddRoleSwitchPermissionCloseModal}
                                                                 showTableData={this.fetchTableData}
                                                                 />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>

                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(InsuranceValuecalculationMasterDataList);