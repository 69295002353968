import React, {Component} from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});


class CBNotFound extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,
        inputData: {},
        dropdownSearchData: {}

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };
    handleSubmit = (event) => {

        event.preventDefault();
        this.setState({
            multipleScanningphotoShow: false
        })


    };
    renderCBNotfound = () => {
        if (this.props.title !== undefined) {
            return (
                <h4 style={{color: "white"}} >{this.props.title}</h4>
            )
        } else {

            return (
                <h4 style={{color: "white"}} >CB Not Found</h4>
            )

        }
    };


    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    <center>
                        {this.renderCBNotfound()}
                    </center>
                    <CardBody>

                        <center>
                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={this.close}
                            >OK
                            </button>
                        </center>
                    </CardBody>

                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(CBNotFound);