import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";


import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';
import CloseIcon from '@material-ui/icons/Close';
import CustomerCreditCard from "./CustomerCreditCard";
import CircularProgress from '@material-ui/core/CircularProgress';
import Functions from '../../Common/Functions';
import DialogContent from "@material-ui/core/DialogContent";
// core components

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class ListOfCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            SingleCards: false,
            cardType: '',
            cardNo: '',
            idClient: '',
            tableData: [[" ", " "]],
            IDENTIFICATION_NO: '',
            ACCOUNTNO: "",
            renderCardDetailsData: false,
            redirectLogin: false,
            listOfDebitCards: [[" ", " "]],
            getDebitcard: false
        }
    }

    SingleCards(cardNoOriginal, idClient) {
        this.setState({
            SingleCards: true,
            cardNo: cardNoOriginal,
            idClient: idClient
        });
    }

    closeSingleCardsModal = () => {
        this.setState({
            SingleCards: false,

        });
    };
    createTableData = (id, cardNo, cardHolderName, cardType, variant, status, effectiveDate, ineffectiveDate, idClient, cardNoOriginal, view) => {

        return ([cardNo, cardHolderName, cardType, variant, status, effectiveDate, ineffectiveDate,
            <button
                className="btn"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={
                    event => this.SingleCards(cardNoOriginal, idClient)
                }
            >
                <Fab size="small" color="secondary" aria-label="pageview">

                    <Pageview/>
                </Fab>

            </button>
        ]);

    };
    createTableDataDebitCard = (id, cardNo, cardHolderName, cardType, variant, status, effectiveDate, ineffectiveDate, idClient, view) => {

        return ([cardNo, cardHolderName, cardType, variant, status, effectiveDate, ineffectiveDate]);

    };


    componentDidMount() {
        if (this.props.IDENTIFICATION_NO !== undefined) {
            let url = backEndServerURL + "/demographic/cards/" + this.props.IDENTIFICATION_NO;
            const ListOfCards = [];
            let ListOfDebitCards = [];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        ListOfCards: response.data,

                    });
                    response.data.map((card) => {
                        let since = card.customerScience;
                        let expireDate = card.expiryDate;
                        if (since !== undefined && since !== null) {
                            since = since.split('T')[0];
                        }
                        if (expireDate !== undefined && expireDate !== null) {
                            expireDate = expireDate.split('T')[0]
                        }
                        ListOfCards.push(this.createTableData(card.id, card.cardNo, card.cardHolderName, card.cardType, card.variant, card.status, since, expireDate, card.idclient, card.cardNoOriginal));
                    });
                    this.setState({
                        tableData: ListOfCards,
                        renderCardDetailsData: true

                    })
                })
                .catch((error) => {

                    this.setState({
                        renderCardDetailsData: true
                    })
                });
            let debitcardUrl = backEndServerURL + "/demographic/debitCards/" + this.props.IDENTIFICATION_NO;
            axios.get(debitcardUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data[0]);

                    response.data.map((debit) => {
                        let since = debit.customerScience;
                        let expireDate = debit.expiryDate;
                        if (since !== undefined && since !== null) {
                            since = since.split('T')[0];
                        }
                        if (expireDate !== undefined && expireDate !== null) {
                            expireDate = expireDate.split('T')[0]
                        }
                        ListOfDebitCards.push(this.createTableDataDebitCard(debit.id, debit.cardNo, debit.cardHolderName, debit.cardType, ' ', debit.status, since, expireDate, debit.idclient));

                    });
                    this.setState({
                        listOfDebitCards: ListOfDebitCards,
                        getDebitcard: true
                    })
                })
                .catch((error) => {
                    this.setState({
                        getDebitcard: true
                    })
                })

        } else {

        }

    }

    renderCardDetailsData = () => {
        if (this.state.renderCardDetailsData === true) {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Card No", "Name on Card", "Card Type", "Variant", "Status", "Member Since", "Card Expire Date", "View"
                    ]}
                    tableData={
                        this.state.tableData

                    }
                    tableAllign={['left', 'left', 'left', 'left', 'left']}

                />
            )
        } else if (this.state.renderCardDetailsData === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    renderDebitDetailsData = () => {

        if (this.state.getDebitcard === true) {
            return (

                <Table
                    tableHeaderColor="primary"

                    tableHead={["Card No", "Name on Card", "Card Type", "Variant", "Status", "Member Since", "Card Expire Date"
                    ]}
                    tableData={
                        this.state.listOfDebitCards
                    }
                    tableAllign={['left', 'left', 'left', 'left', 'left']}

                />
            )
        } else if (this.state.getDebitcard === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                {/* <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SingleCards}
                    onClose={this.closeSingleCardsModal}
                <DialogContent className={classes.dialogPaper}>

                    <CustomerCreditCard closeModal={this.closeSingleCardsModal} cardNo={this.state.cardNo} cardType={this.state.cardType}/>

                </DialogContent>
            </Dialog>*/}
                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SingleCards}
                    onClose={this.closeSingleCardsModal}
                >
                    <DialogContent className={classes.dialogPaper}>

                        <CustomerCreditCard closeModal={this.closeSingleCardsModal} cardNo={this.state.cardNo}
                                            idClient={this.state.idClient} cardNo={this.state.cardNo}
                                            cardType={this.state.cardType}/>

                    </DialogContent>
                </Dialog>

                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                    <h4 style={{color: "white"}} >List of Cards<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>
                                </CardHeader>
                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>

                                                        <center><h4 style={{color: "white"}} >Credit Card</h4></center>
                                                        {this.renderCardDetailsData()}
                                                    </Paper>
                                                    <br/>
                                                    <Paper className={classes.paper}>
                                                        <center><h4 style={{color: "white"}} >Debit Card</h4></center>
                                                        {this.renderDebitDetailsData()}
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(ListOfCards);
