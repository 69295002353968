import Grid from "@material-ui/core/Grid";
import React from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class DateComponentDeferal {
    /*  onKeyDown={(event) => {
      this.onKeyDownChange(event, onKeyDownForDedup)
  }}*/

    static  dateConverter = (value) => {

        let splitData = (value).split(',')[0];
        return splitData.split('T')[0];
    };

    static handleChange(onKeyDownChange, date, field, state, update) {
        state.selectedDate[field.varName] = date;
        state.inputData[field.varName] = (date !== null) ? this.dateConverter(date.toLocaleString('en-US')) : "";
        if (field.onKeyDown === true) {
            let data = {};
            data.varName = field.varName;
            //data.value=option;

            onKeyDownChange(data);
        }
        update();
    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(DD-MM-YYYY) *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}(DD-MM-YYYY)</label>
                </Grid>
            )
        }
    };

    static returnDefaultValue(state, field, update) {
        if (state.inputData[field.varName] !== undefined && state.inputData[field.varName] !== null) {
            return state.selectedDate[field.varName];
        } else {
            let someDate = new Date();
            let x = 7;
            if (field.dayAdd !== undefined) {
                x = field.dayAdd;
            }
            let numberOfDaysToAdd = parseInt(x, 10);
            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            let newDate = new Date(year, month - 1, date,);
            if (state.inputData[field.varName] === undefined) {
                console.log(newDate);
                state.selectedDate[field.varName] = newDate;
                state.inputData[field.varName] = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                state.varValue[field.varName] = (newDate !== null) ? newDate.toLocaleString('en-US') : "";
                update()
            }
            //state.inputData[field.varName] = (newDate !== null) ? date.toLocaleString('en-US') : "";
            return newDate;
        }

    }

    static date(state, update, field, onKeyDownChange) {
        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }

                <Grid item>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker

                            style={field.readOnly === true ? {
                                paddingRight: 20,
                                color: '#212F3D',
                                background: '#f8f9fa'
                            } : {paddingRight: 20}}
                            //minDate={field.minDate===true?new Date():undefined} maxDate = {field.maxDate===true?new Date():undefined}
                            value={this.returnDefaultValue(state, field, update)}
                            inputVariant="outlined"
                            minDate={field.minDate === true ? new Date() : undefined}
                            maxDate={field.maxDate === true ? new Date() : undefined}
                            shouldDisableDate={field.readOnly}
                            placeholder="dd-mm-yyyy"
                            disabled={field.readOnly}
                            allowKeyboardControl={false}
                            readOnly={field.readOnly}
                            // helperText={this.state.errorMessages[field.varName]}
                            //error={this.state.errorArray[field.varName]}
                            required={field.readOnly === true ? false : field.required}
                            //label={field.label}
                            fullWidth
                            onChange={(date) => this.handleChange(onKeyDownChange, date, field, state, update)}
                            onBlur={(event) => {
                                if (field.onKeyDown === true) {
                                    let data = {};
                                    data.varName = field.varName;
                                    //data.value=option;
                                    onKeyDownChange(data);
                                }
                            }}
                            /*  onChange={(date)=> {
                                   state.selectedDate[field.varName] = date;
                                  state.inputData[field.varName] = (date !== null) ? date.toLocaleString('en-US') : "";
                                  if(field.onKeyDown===true){
                                      //onKeyDownChange(event);
                                   }
                                  update();
                              }}*/
                            format="dd-MM-yyyy"
                        />

                    </MuiPickersUtilsProvider>


                    {/* <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Date picker dialog"
                        format="dd-MM-yyyy"
                        value={ new Date()}
                        onChange={(date) => {
                            state.selectedDate[field.varName] = date;
                            state.inputData[field.varName] = (date !== null) ? date.toLocaleString('en-US') : "";
                            update();
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />*/}
                </Grid>
            </React.Fragment>
        )


    }

}

export default DateComponentDeferal;