import React, { Component } from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { backEndServerURL } from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import { Button, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import SelectComponent from "../JsonForm/SelectComponent";
import TextFieldComponent from "../JsonForm/TextFieldComponent";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";
import Swal from "sweetalert2";
let Remarks = [
  {
    varName: "remarks",
    type: "textArea",
    label: "Remarks",
    //required: true,
    grid: 12,
  },
];
var stampType = [
  {
    varName: "stampType",
    type: "select",
    enum: [],
    required: true,
    label: "Stamp Type",
  },
];
var quantity = [
  {
    varName: "quantity",
    type: "text",
    label: "Quantity",
    required: true,
  },
];
let Form = [
  {
    varName: "insurancType",
    type: "select",
    label: "Insuranc Type",
    enum: [
      "A – Sea Insurance",
      "B – Fire and Other",
      "C – Accident & Sickness",
      "D – Indemnity against liability",
    ],
    required: true,
    grid: 2,
  },
  {
    varName: "code",
    type: "text",
    label: "Code",
    required: true,
    grid: 2,
  },
  {
    varName: "amountCalculation",
    type: "select",
    enum: ["Incremental"],
    label: "Amount Calculation",
    required: true,
    grid: 2,
  },
  {
    varName: "baseValueStart",
    type: "text",
    label: "Base Value Start",
    required: true,
    grid: 2,
  },
  {
    varName: "baseValueEnd",
    type: "text",
    label: "Base Value End",
    required: true,
    grid: 2,
  },
  {
    varName: "stampValue",
    type: "text",
    label: "Stamp Value",
    required: true,
    grid: 2,
  },
  {
    varName: "incrementalAmount",
    type: "text",
    label: "Incremental Amount",
    required: true,
    grid: 2,
  },
  {
    varName: "incrementalAmountValue",
    type: "text",
    label: "Incremental Amount Value",
    required: true,
    grid: 2,
  },
];
let workplace = [
  { title: "BRANCH" },
  { title: "OPERATIONS" },
  { title: "CSU" },
];
let groupList = [];
let workplaceTypeList = [];

class RequestInitialization extends Component {
  state = {
    inputData: {},
    selectedDate: {},
    getData: false,
    workstation: false,
    role: false,
    showValue: false,
    SelectedDropdownSearchData: null,
    dropdownSearchData: {},
    values: [],
    err: false,
    varValue: [],
    errorArray: {},
    errorMessages: {},
    errorWorkPlace: false,
    errorgroup: false,
    title: "",
    notificationMessage: "",
    alert: false,
    getGroupListData: false,
    getworkplaceTypeListData: false,
    workplace: "",
    type: "success",
    submitButton: false,
  };

//   reload =()=>{
//     setTimeout(function () {
//         window.location.reload();
//       }, 1000);
// }
// start reload after complete request

reload = () => {
  if (this.props.appId !== undefined) {
    this.setState({
      submitButton: false,
      appId: this.props.appID,
      getData: false,
      showValue: false,
      values:[],
    });
  } else {
    this.setState({
      values:[],
      submitButton: false,
      getData: false,
      showValue: false,
       
    });
    this.componentDidMount()
  } 
  
  this.getMasterdataAll();   
  this.renderRemarks() ;
  this.renderButton();
  this.dynamicForm()  ;
  this.dynamicForm1();
  this.addForm() ;
let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
  axios
    .get(start_url, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      },
    })
    .then((response) => {
      this.getMasterdataAll();
      this.setState({
        appId: response.data.id,
        caseId: response.data.caseId,
      });
    })
    .catch((error) => {
      console.log(error);
    });
}
// end request
  emptyValueRemove = (data) => {
    if (data === "" || data === " " || data === "null" || data === "undefined")
      data = "";
    return data;
  };
  handleSubmit = (event) => {
    event.preventDefault();

    /*  let error = MyValidation.defaultValidation(RequestInitialization, this.state);
          this.forceUpdate();
          if (error === true) {
              return 0;
          } else {*/
    let validationData = [];
    if (this.state.values.length > 0) {
    } else {
      Swal.fire({
        //position: 'bottom-end',
        
            //title: "Successfull!",
            text: "Please Add Stamp Type and Quantity",
            icon: "info",
            timer: 2500,
            
          })
       
      return 0;
    }
    this.setState({
      loading: true,
    });
    for (let i = 0; i < this.state.values.length; i++) {
      let value = this.state.values[i];
      validationData.push(
        {
          varName: "stampType" + value,
          type: "select",
          enum: [],
          required: true,
          label: "Stamp Type",
        },
        {
          varName: "quantity" + value,
          type: "text",
          label: "Quantity",
          required: true,
          "validation":"numeric",
        }
      );
    }
    let error = MyValidation.defaultValidation(
      this.emptyValueRemove(validationData),
      this.state
    );
    this.forceUpdate();

    if (
      error === true ||
      this.state.inputData.workplaceType === null ||
      this.state.inputData.groupName === null
    ) {
      console.log("Not working");
      this.setState({
        loading: false,
      });
      return 0;
    }
    error = MyValidation.defaultValidation(
      this.emptyValueRemove(Remarks),
      this.state
    );
    this.forceUpdate();

    if (error === true) {
      console.log("Not working");
      this.setState({
        loading: false,
      });
      return 0;
    }
    let remarksData = this.state.inputData.remarks;
    let postData = this.state.inputData;
    postData.values = this.state.values;

    postData.freeFlag1 = "FDD";
    postData.category = "Stamp";
    postData.subCategory = "Requisition";
    postData.serviceType = "Debit Card";
    postData.subServiceType = "Chequebook";
    postData.customerName = this.state.inputData.customerName;
    postData.appId = this.state.appId;
    postData.remarks = undefined;

    let url = backEndServerURL + "/variables/" + this.state.appId;
    axios
      .post(url, postData, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        let caseRouteUrl = backEndServerURL + "/case/route/" + this.state.appId;
        axios
          .get(caseRouteUrl, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
          })
          .then((response) => {
            
            if (response.data.status === 404) {
                
              this.setState({
                type: "error",
                title: "Case not found",
                notificationMessage: "Successfully done!",
                alert: true,
                loading: false,
              }); 
            } 
            let remark_url =
              backEndServerURL +
              "/appRemarkSave/" +
              remarksData +
              "/" +
              this.state.appId;
            axios
              .post(
                remark_url,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                }
              )
              .then((response) => {
                console.log('response is', response.data);
                this.reload()

                this.setState({
                  type: "success",
                  title: "Reference Number : " + this.state.caseId,
                  notificationMessage: "Successfully done!",
                  alert: true,
                  loading: false,
                });
                                        // if (response.data.massage === 'Successfully save') {
                                        //   this.setState({
                                        //     type: "success",
                                        //     title: "Reference Number : " + this.state.caseId,
                                        //     notificationMessage: "Successfully done!",
                                        //     alert: true,
                                        //     loading: false,
                                        //   });
                                        // }
                                          //this.closeIcon()
                                          // setTimeout(function () {
                                          //   window.location.reload();
                                          // }, 10000);
                                        // } else if (response.data.status === 200){
                                        //   this.setState({
                                        //     type: "warning",
                                        //     title: "warning!",
                                        //     notificationMessage: response.message,
                                        //     alert: true,
                                        //     loading: false,
                                        //   })
                                         //this.closeIcon()
                                        //  this.reload()
                                        
              })
              .catch((error) => {
                console.log(error);
                // this.reload()
                //this.closeIcon()
                this.setState({
                  loading: false,
                });
                // this.setState({
                //   type: "error",
                //   title: "error!!",
                //   notificationMessage: Functions.errorObjectCheck(error),
                //   alert: true,
                //   loading: false,
                // });
              });

            // this.setState({
            //   reference: true,
            //   referenceTitle: "Reference Number : " + this.state.caseId,
            //   title: "Successful!",
            //   notificationMessage: "Successfully Routed!",
            //   alert: true,
            //   getData: false,
            //   requestType: "",
            //   inputData: {},
            //   varValue: {},
            //   errorArray: {},
            //   loading: false,
            //   err: false,
            //   errorMessages: {},
            // })  
          })
          .catch((error) => {
            console.log(error);
            //this.reload()
            //this.closeIcon()
            this.setState({
              loading: false,
            });
          });
      })
      .catch((error) => {
        console.log("Cheque Book request error");
        this.setState({
          loading: false,
        });
        //this.reload()
        //this.closeIcon()
      });
     
  };
  renderButton = () => {
    // if(this.state.submitButton){
    return (
      <button
        className="btn btn-outline-primary  btn-sm"
        style={{
          marginTop: "18px",
        }}
        onClick={this.handleSubmit}
      >
        Submit
      </button>
    );
    // }
  };

  getMasterdataAll = () => {
    let url = backEndServerURL + "/get/insuranceMasterDataList";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        let stampList = [];
        response.data.map((data) => {
          stampList.push(data.stampDescription);
        });
        this.setState({
          stampTypeList: stampList,
          getData: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    if (this.props.appId !== undefined) {
      this.setState({
        submitButton: false,
        appId: this.props.appId,
      });
    } else {
      this.setState({
        submitButton: false,
      });
    }

    let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
    axios
      .get(start_url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        this.getMasterdataAll();
        this.setState({
          appId: response.data.id,
          caseId: response.data.caseId,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateComponent = () => {
    this.forceUpdate();
  };

  renderJsonForm = () => {
    return CommonJsonFormComponent.renderJsonForm(
      this.state,
      Form,
      this.updateComponent
    );
  };

  renderNotification = () => {
    if (this.state.alert) {
      return (
        <Notification
          type={this.state.type}
          stopNotification={this.stopNotification}
          title={this.state.title}
          message={this.state.notificationMessage}
        />
      );
    }
  };

  stopNotification = () => {
    this.setState({
      alert: false,
    });
  };
   
  renderHeader = () => {
    if (this.props.appId !== undefined) {
      return (
        <h4 style={{ color: "white" }}>
          {" "}
          Branch New Requisition (Stamp)
          <a>
            <CloseIcon
              onClick={this.close}
              style={{
                position: "absolute",
                right: 10,
                color: "white",
              }}
            />
          </a>
        </h4>
      );
    } else {
      return <h4 style={{ color: "white" }}>Branch New Requisition (Stamp)</h4>;
    }
  };
  returnAddButton = () => {
    return (
//       <Button
//       type="button"
// onClick={this.open}
 
// style={{
//  float: "left",
//  verticalAlign: "left",
//  backgroundColor: "#FDF0E0",
//  fontSize: "bold",
//  fontWeight: 'bolder',
//  // color: "#ffffff",             
//   //fontSize:'12px',
// }}
// variant="contained"
// color="success"
// size="small"
// >
// + Add New
// </Button>
      <button
        className="btn btn-outline-primary btn-sm"
        style={{
          float: "left",
          verticalAlign: "left",
        }}
        type="button"
        value="add more"
        onClick={this.addClick.bind(this)}
      >
        + Add New
      </button>
    );
  };

  addClick() {
    let randomNumber = Math.floor(Math.random() * 100000000000);
    this.setState((prevState) => ({
      setAllDeferalData: true,
      values: [...prevState.values, randomNumber],
    }));
  }
  removeClick(i, event) {
    event.preventDefault();
    let pos = this.state.values.indexOf(i);
    if (pos > -1) {
      this.state.values.splice(pos, 1);
      this.updateComponent();
    }
  }

  dynamicForm = (i) => {
    let field = JSON.parse(
      JSON.stringify({
        varName: "stampType",
        type: "select",
        enum: [],
        required: true,
        label: "Stamp Type",
      })
    );
    field.varName = "stampType" + i;
    field.enum = this.state.stampTypeList;
    return SelectComponent.select(this.state, this.updateComponent, field);
  };
  dynamicForm1 = (i) => {
    let field = JSON.parse(
      JSON.stringify({
        varName: "quantity",
        type: "text",
        label: "Quantity",
        "validation":"numeric",
        required: true,
      })
    );
    field.varName = "quantity" + i;
    return TextFieldComponent.text(this.state, this.updateComponent, field);
  };
  addForm() {
    if (this.state.values !== undefined) {
      return this.state.values.map((el, i) => (
        <React.Fragment>
          <Grid item xs={3}>
            {this.dynamicForm(el)}
          </Grid>
          <Grid item xs={3}>
            {this.dynamicForm1(el)}
          </Grid>
          <Grid item xs={3}>
            <button
              style={{
                float: "left",
                marginTop: "16px",
              }}
              className="btn btn-outline-primary btn-sm"
              type="button"
              value="remove"
              onClick={this.removeClick.bind(this, el)}
            >
              Remove
            </button>
          </Grid>
          <Grid item xs={12}></Grid>
        </React.Fragment>
      ));
    }
  }
  closeModalCBNotFound = () => {
    this.setState({
      cbNotFound: false,
      signatureModal: false,
      reference: false,
    });
  };
  renderRemarks = () => {
    if (this.state.getData) {
      return CommonJsonFormComponent.renderJsonForm(
        this.state,
        Remarks,
        this.updateComponent
      );
    }
  };
  render() {
    return (
      <Card>
        <CardHeader
          style={{
            backgroundColor: "#142398",
            //color: '#ffffff'
          }}
        >
          {this.renderHeader()}
        </CardHeader>
        <CardBody>
          <Grid item xs={8}>
            <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
              <DialogContent>
                <center>
                  <img src={loader} alt="" />
                </center>
              </DialogContent>
            </Dialog>

            <ThemeProvider theme={theme}>
              <Grid container>
                <Dialog
                  fullWidth="true"
                  maxWidth="sm"
                  open={this.state.reference}
                >
                  <DialogContent>
                    <ReferenceModal
                      closeModal={this.closeModalCBNotFound}
                      referenceTitle={this.state.referenceTitle}
                    />
                  </DialogContent>
                </Dialog>
                {this.renderNotification()}

                <Grid item xs={12}>
                  {this.returnAddButton()}
                </Grid >
                {this.addForm()}
                {/* <br /> */}
                <Grid item xs={8}>             
                
                {this.renderRemarks()}
                {this.renderButton()}
                </Grid >              

                {/* <Grid item xs={12}>
                  {this.renderButton()}
                </Grid> */}
              </Grid>
            </ThemeProvider>
          </Grid>
        </CardBody>
      </Card>
    );
  }
}

export default RequestInitialization;
