import React from "react";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Table from "../../Table/Table";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import Notification from "../../NotificationMessage/Notification";
import Grid from "@material-ui/core/Grid";
import Functions from "../../../Common/Functions";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";

import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import SingleImageShow from "../CASA/SingleImageShow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from "@material-ui/core/GridList";
import TextField from "@material-ui/core/TextField";
import SecondaryCbFormDetails from "../CASA/SecondaryCbFormDetails";
import loader from "../../../Static/loader.gif";
import AccountNoGenerate from "../AccountNoGenerate";
import MyValidation from "../../../MyValidation";
import ErrorModal from "../CommonComponent/ErrorModal";
import WaiverList from "../CommonComponent/WaiverList";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import AccountDetails from "./AccountDetails";
import DeferalList from "../CommonComponent/DeferalList";
import {fdKYC} from "../WorkflowJsonFormArin";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import ConfirmAlertImage from "../CommonComponent/ConfirmAlertImage";
import AccountStatement from "./AccountStatement";
import SignatureButton from "../Maintenance/SignatureButton";
import SecondaryJointCbTagging from "../CASA/SecondaryJointCbTagging";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import FileMapping from "../CommonComponent/FileMapping";

const firstStyle = {
    color: '#212F3D',
    background: '#f8f9fa'
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}
];

function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;


}

let objectForNominee = [];
let tagForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "Repayment Ac Id",
        "grid": 6,
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuringOv",
        "type": "text",
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },

];
let accountOpeningForm = [
    /* {
         "varName": "customerId",
         "type": "text",
         "label": "Customer ID",
         "required": true,
         "grid": 6,

     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Title",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "customerName",
         "type": "text",
         "label": "Customer Name",
         "grid": 6,
         "length": 80,
         required: true,
         "multiline": true
     },

     {
         "varName": "shortName",
         "type": "text",
         "label": "Short Name",
         "grid": 6,
         "length": 10,
         required: true,
     },

     {
         "varName": "email",
         "type": "text",
         "label": "Email",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "phone",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "multiline": true
     },

     /!*
         {
             "label": "AOF 2",
             "type": "title",
             "grid": 12,
         },
     *!/

     {
         "varName": "introducerCustomerId",
         "type": "text",
         "label": "Introducer Customer ID",
         "grid": 6,
         required: true,
     },

     {
         "varName": "introducerName",
         "type": "text",
         "label": "Introducer Name",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "introducerStaff",
         "type": "text",
         "label": "Introducer Staff",
         "grid": 6,
         required: true,
     },

     {
         "varName": "applicantMinor",
         "type": "select",
         "label": "Applicant Minor",
         "grid": 6,
         "enum": ["Yes", "No"],
     },
     {
         "varName": "gurdian",
         "type": "text",
         "label": "Gurdian",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "gurdianName",
         "type": "text",
         "label": "Gurdian Name",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "address23",
         "type": "text",
         "label": "Address",
         "multiline": true,
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "city1",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "state1",
         "type": "text",
         "label": "State",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
         "multiline": true
     },

     {
         "varName": "postal",
         "type": "text",
         "label": "Postal",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,
     },

     {
         "varName": "country3",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         "conditional": true,
         "conditionalVarName": "applicantMinor",
         "conditionalVarValue": "Yes",
         required: true,

     },

     /!*    {
             "label": "IIF Page 1",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "gender",
         "type": "select",
         "enum": ["Male", "Female"],
         "label": "Gender",
         "grid": 6,
         required: true,
     },

     {
         "varName": "residentStatus",
         "type": "select",
         "label": "Resident Status",
         "enum": ["Resident", "Non-Resident"],
         "grid": 6,
         required: true,
     },

     {
         "varName": "nid",
         "type": "text",
         "label": "National Id Card",
         "grid": 6,
         required: true,
     },

     {
         "varName": "dob",
         "type": "date",
         "label": "D.O.B",
         "grid": 6,
         "maxDate": true,
         required: true,
     },

     {
         "varName": "father",
         "type": "text",
         "label": "Father ",
         "grid": 6,
         required: true,
     },

     {
         "varName": "mother",
         "type": "text",
         "label": "Mother",
         "grid": 6,
         required: true,
     },

     {
         "varName": "maritialStatus",
         "type": "select",
         "label": "Maritial Status",
         "enum": ["Married", "Un Married"],
         "grid": 6,
         required: true,
     },
     {
         "varName": "spouse",
         "type": "text",
         "label": "Spouse",
         "conditional": true,
         "conditionalVarName": "maritialStatus",
         "conditionalVarValue": "Married",
         "grid": 6,
         required: true,
     },

     {
         "varName": "pangirNo",
         "type": "text",
         "label": "PAN GIR No",
         "grid": 6,
     },

     {
         "varName": "passport",
         "type": "text",
         "label": "Passport No",
         "grid": 6,
     },

     // {
     //     "varName": "issueDate",
     //     "type": "text",
     //     "label": "Issue Date",
     //     "grid": 12,
     // },

     {
         "varName": "passportDetails",
         "type": "text",
         "label": "Passport Details",
         "grid": 6,
     },

     // {
     //     "varName": "expiryDate",
     //     "type": "text",
     //     "label": "Expiry Date",
     //     "grid": 12,
     // },

     {
         "varName": "freeText5",
         "type": "text",
         "label": "Free Text 5",
         "grid": 6,
     },

     {
         "varName": "freeText13",
         "type": "text",
         "label": "Free Text 13",
         "grid": 6,

     },
     {
         "varName": "freeText14",
         "type": "text",
         "label": "Free Text 14",
         "grid": 6,
     },
     {
         "varName": "freeText15",
         "type": "text",
         "label": "Free Text 15",
         "grid": 6,
     },
     /!*    {
             "label": "IIF Page 2",
             "type": "title",
             "grid": 12,
         },*!/
     {
         "varName": "comAddress",
         "type": "text",
         "label": "Communication Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "comAddress2",
         "type": "text",
         "label": "Communication Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city",
         "type": "text",
         "required": true,
         "label": "City",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "state",
         "type": "text",
         "required": true,
         "label": "State",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "post",
         "type": "text",
         "required": true,
         "label": "Post",
         "grid": 6,
         "multiline": true
     }, {
         "varName": "country",
         "type": "select",
         "required": true,
         "enum": [
             "Bangladesh",
             "Japan",
             "Other",
         ],
         "label": "Country",
         "grid": 6,
     },
     {
         "varName": "phoneNo12",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "phoneNo21",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "permanentAddress1",
         "type": "text",
         "label": "Permanent Address 1",
         "grid": 6,
         required: true,
         "multiline": true
     },

     {
         "varName": "permanentAddress2",
         "type": "text",
         "label": "Permanent Address 2",
         "grid": 6,
         "multiline": true
     },

     // {
     //     "varName": "city3",
     //     "type": "select",
     //     "enum": ["Dhaka","Chittagong","Barishal","Rajshahi","Khulna"],
     //     "label": "City",
     //     "grid": 6,
     //     required: true,
     // },

     {
         "varName": "state3",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode4",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
         required: true,
     },

     {
         "varName": "country5",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
         required: true,
     },

     {
         "varName": "phoneNo22",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "email2",
         "type": "text",
         "label": "Email",
         "grid": 6,
     },

     {
         "varName": "employerAddress1",
         "type": "text",
         "label": "Employer Address 1",
         "grid": 6,
         "multiline": true,
         required: true,
     },

     {
         "varName": "employerAddress2",
         "type": "text",
         "label": "Employer Address 2",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "city4",
         "type": "select",
         "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
         "label": "City",
         "grid": 6,
         required: true,
     },

     {
         "varName": "state4",
         "type": "text",
         "label": "State",
         "grid": 6,
         required: true,
     },

     {
         "varName": "postalCode5",
         "type": "text",
         "label": "Postal Code",
         "grid": 6,
     },

     {
         "varName": "country12",
         "type": "select",
         "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
         "label": "Country",
         "grid": 6,
     },

     {
         "varName": "phoneNo13",
         "type": "text",
         "label": "Phone No 1 ",
         "grid": 6,
         "length": 13,
     },

     {
         "varName": "phoneNo23",
         "type": "text",
         "label": "Phone No 2",
         "grid": 6,
     },

     {
         "varName": "telexNo1",
         "type": "text",
         "label": "Telex No",
         "grid": 6,
     },

     {
         "varName": "email3",
         "type": "text",
         "label": "Email",
         "grid": 6,
         "multiline": true
     },

     {
         "varName": "faxNo",
         "type": "text",
         "label": "Fax No",
         "grid": 6,
     },

     {
         "label": "KYC",
         "type": "title",
         "grid": 12,
     },
     {
         "varName": "acNo",
         "type": "text",
         "label": "Ac No",
         "grid": 6,
         "length": 13,
         required: true,
     },

     {
         "varName": "acTitle",
         "type": "text",
         "label": "Ac Title",
         "grid": 6,
         "multiline": true,
     },

     {
         "varName": "customerOccupation",
         "type": "select",
         "enum": ["Teacher", "Doctor", "House-Wife", "Privet Job Holder"],
         "label": "Customer Occupation",
         "grid": 6,
         required: true,
     },

     {
         "varName": "docCollectToEnsure",
         "type": "text",
         "label": "To Ensure Source Of Fund",
         "grid": 6,
         required: true,
     },

     {
         "varName": "collectedDocHaveBeenVerified",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Collected Doc Have Been Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "howTheAddress",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "How The Address Is Verified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "hasTheBeneficialOwner",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Beneficial Owner Identified",
         "grid": 6,
         required: true,
     },

     {
         "varName": "whatDoesTheCustomer",
         "type": "select",
         "enum": ["Yes", "No"],
         "label": "Is The Customer Engaged",
         "grid": 6,
         required: true,
     },

     {
         "varName": "customersMonthlyIncome",
         "type": "select",
         "enum": [20000, 50000, 100000],
         "label": "Customers Monthly Income",
         "grid": 6,
         required: true,
     },*/
    //// FDR ////
    {
        "label": "FDR Opening Form",
        "type": "title",
        "grid": 12,
    }, {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "acOpenDate",
        "type": "",
        "label": "Ac Open Date",
        "grid": 6,
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "Ac Pref Int Cr",
        "grid": 6,
    },
    {
        "varName": "interestCreditAcId",
        "type": "text",
        "label": "Interest Credit Ac Id",
        "grid": 6,
    },
    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },
    {
        "varName": "withholdingTaxTax",
        "type": "text",
        "label": "Withholding Tax",
        "grid": 6,
    },
    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Depositinstall Amount",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "fdValueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "Repayment Ac Id",
        "grid": 6,
    },
    {
        "varName": "autoClosureClosure",
        "type": "text",
        "label": "Auto Closure",
        "grid": 6,
    },
    {
        "varName": "autoRenewalRenewal",
        "type": "text",
        "label": "Auto Renewal",
        "grid": 6,
    }, {
        "varName": "post",
        "type": "text",
        "label": "Postal Code 2",
        "grid": 6,
    },
    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subCectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode1",
        "type": "text",
        "label": "Free Code 1",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode2",
        "type": "text",
        "label": "Free Code 2",
        "grid": 6,
    },
    {
        "varName": "freeCodeCode3",
        "type": "text",
        "label": "Free Code 3",
        "grid": 6,
    },
    {
        "varName": "tranCreationDuringOv",
        "type": "text",
        "label": "Tran Creation During Ov",
        "grid": 6,
    },
    {
        "varName": "fundingAc",
        "type": "text",
        "label": "Funding Ac",
        "grid": 6,
    },
    {
        "varName": "typeOfTransaction",
        "type": "text",
        "label": "Type Of Transaction",
        "grid": 6,
    },

    //Kyc
    {
        "varName": "functionCode",
        "type": "text",
        "label": "Function Code",
        "grid": 6,

    },
    {
        "varName": "customerType",
        "type": "text",
        "label": "Customer Type",
        "grid": 6,

    }
    ,
    {
        "varName": "customerAccount",
        "type": "text",
        "label": "CUSTOMER ACCOUNT",
        "grid": 6,

    }
    ,
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account Title",
        "grid": 6,

    },
    {
        "varName": "typeOfAccount",
        "type": "text",
        "label": "Type of Account",
        "grid": 6,

    }
    ,
    {
        "varName": "accountReferenceNo",
        "type": "text",
        "label": "Account or Reference No",
        "grid": 6,

    }
    ,
    {
        "varName": "nameOfficerOpeningAccount",
        "type": "text",
        "label": "Name of Officer Opening the Account",
        "grid": 6,

    }
    ,
    {
        "varName": "customersOccupation",
        "type": "text",
        "label": "Customers Occupation (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "customersProbableMonthlyIncome",
        "type": "text",
        "label": "Customers probable monthly income",
        "grid": 6,

    }
    ,
    {
        "varName": "sourcesOfFund",
        "type": "text",
        "label": "Sources of Fund",
        "grid": 6,

    },

    {
        "varName": "documentsCollectedEnsureSourceOfFund",
        "type": "text",
        "label": "Documents collected to ensure the source of fund",
        "grid": 6,

    },
    {
        "varName": "collectedDocumentsVerified",
        "type": "text",
        "label": "Collected documents have been verified or not?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "accountVerified",
        "type": "text",
        "label": "How the address (es) of the account holder has been verified? (Details)",
        "grid": 6,

    }
    ,
    {
        "varName": "BeneficiaAccountIdentified",
        "type": "text",
        "label": "Has the Beneficial owner of the account been identified?",
        "grid": 6,

    }
    ,
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,

    }
    ,
    {
        "varName": "nationalIDCardNo",
        "type": "text",
        "label": "National ID Card No",
        "grid": 6,

    }
    ,
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": 6,

    }
    ,
    {
        "varName": "tinNo",
        "type": "text",
        "label": "Tin No",
        "grid": 6,

    }
    ,
    {
        "varName": "vatRegistrationNo",
        "type": "text",
        "label": "VAT Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "companyRegistrationNo",
        "type": "text",
        "label": "Company Registration No",
        "grid": 6,

    }
    ,
    {
        "varName": "drivingLicenceNo",
        "type": "text",
        "label": "Driving Licence No",
        "grid": 6,

    }
    ,
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,

    }
    ,
    {
        "varName": "reasonopeningAccountForeigner:",
        "type": "text",
        "label": "Reason for opening the account for Non-Resident and Foreigner:",
        "grid": 6,

    }
    ,
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "grid": 6,

    }
    ,
    {
        "varName": "dateOfExpire",
        "type": "date",
        "label": "Date Of Expire",
        "grid": 6,

    }
    ,
    {
        "varName": "workPermitPermission",
        "type": "text",
        "label": "Is Work permit and permission to open account form competent authority is taken for open account of work permit holder?  ",
        "grid": 6,

    }
    ,
    {
        "varName": "CustomerPolitically",
        "type": "select",
        "label": "Is The customer a Politically Exposed Person (PEP) or Influential Person (IP)or Head or high officials of international organization and their family members or close associates?",
        "enum": [
            "Has the approval been taken from senior management? ",
            "Has interviewed the customer face to face?",
            "NO",
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "matchCustomername",
        "type": "select",
        "label": "Is there any match to the customer name with the terrorist activity?",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    },
    {
        "varName": "matchCustomernameDetails",
        "type": "select",
        "label": "Details",
        "enum": [
            "YES",
            "NO"
        ],
        "grid": 6,

    }
    ,
    {
        "varName": "riskGrading",
        "type": "text",
        "label": "Risk Grading",
        "enum": [
            "HIGH",
            "LOW"
        ],
        "grid": 6,

    },


    //Sd part
    {
        "varName": "solId",
        "type": "text",
        "label": "Sol Id",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif Id",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "text",
        "label": "Ccy",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "text",
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "label": "A/C Opening Date",
        "grid": 6,
    },

    {
        "varName": "taxCategory",
        "type": "text",
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "text",
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "text",
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },

    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "text",
        "label": "Deposit/Install Amount",
        "grid": 6,
    },

    {
        "varName": "depositPeriodMonthsdays",
        "type": "text",
        "label": "Deposit Period (Months/Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "date",
        "label": "Maturity Date",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "text",
        "label": "Repayment A/C Id",
        "grid": 6,
    },

    {
        "varName": "nominationPcnt",
        "type": "text",
        "label": "Nomination Pcnt.",
        "grid": 6,
    },

    {
        "varName": "sectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "text",
        "label": "Sub Sector Code",
        "grid": 6,
    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "text",
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "text",
        "label": "Free Code 3 (Rm Code)",
        "grid": 6,
    },

    {
        "varName": "freeCode7DepositCode",
        "type": "text",
        "label": "Free Code 7 (Deposit Code)",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "text",
        "label": "Relation Type ",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "text",
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "text",
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif. Id",
        "grid": 6,
    },

    {
        "varName": "etinNumber",
        "type": "text",
        "label": "E-Tin Number",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "label": "Cif Id",
        "grid": 6,
    },

    {
        "varName": "nomineesName",
        "type": "text",
        "label": "Nominee'S Name",
        "grid": 6,
    },

    {
        "varName": "relationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "nomineePhotoIdNo",
        "type": "text",
        "label": "Nominee Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "nomineeMinor",
        "type": "select",
        "label": "Nominee Minor?",
        "grid": 6,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 6,
    },

    {
        "varName": "nominationPent",
        "type": "text",
        "label": "Nomination Pent.",
        "grid": 6,
    },

    {
        "varName": "guardianName",
        "type": "text",
        "label": "Guardian Name ",
        "grid": 6,
    },

    {
        "varName": "guardianCode",
        "type": "text",
        "label": "Guardian Code",
        "grid": 6,
    },

    {
        "varName": "addressLine1",
        "type": "text",
        "label": "Address Line1",
        "grid": 6,
    },

    {
        "varName": "addressLine2",
        "type": "text",
        "label": "Address Line2",
        "grid": 6,
    },

    {
        "varName": "guardianPhotoIdNo",
        "type": "text",
        "label": "Guardian Photo Id No.",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "label": "City ",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "label": "State ",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "label": "Country ",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "siSrlNo",
        "type": "text",
        "label": "Si Srl. No.",
        "grid": 6,
    },

    {
        "varName": "standingInstructionsFrequency",
        "type": "select",
        "label": "Standing Instructions Frequency  ",
        "grid": 6,
    },

    {
        "varName": "nextExecutionDate",
        "type": "date",
        "label": "Next Execution Date",
        "grid": 6,
    },

    {
        "varName": "temporaryAcId",
        "type": "text",
        "label": "Temporary A/C. Id",
        "grid": 6,
    },
];
let dpsAccountOpeningForm = [
    {
        "label": "DPS Opening Form",
        "type": "title",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "required": true,
        "label": "Sol Id",
        "grid": 6,
    },
    {
        "varName": "customerId",
        "type": "text",
        "required": true,
        "label": "Customer Id",
        "grid": 6,
    },
    {
        "varName": "schemeCode",
        "type": "text",
        "required": true,
        "label": "Scheme Code",
        "grid": 6,
    },
    {
        "varName": "aCOpenDate",
        "type": "date",
        "required": true,
        "label": "A/C Open Date",
        "grid": 6,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "required": true,
        "label": "Value Date",
        "grid": 6,
    },
    {
        "varName": "depositPeriod",
        "type": "text",
        "required": true,
        "label": "Deposit Period",
        "grid": 6,
    },
    {
        "varName": "depositInstallmentAmount",
        "type": "text",
        "required": true,
        "label": "Deposit/Installment Amount",
        "grid": 6,
    },
    {
        "varName": "repayAccountId",
        "type": "text",
        "required": true,
        "label": "Repay Account Id",
        "grid": 6,
    },
    {
        "varName": "cifIdIndividualId",
        "type": "text",
        "label": "Cif Id/Individual Id",
        "grid": 6,
    },
    /* {
         "varName":"numberOfNominee",
         "type":"text",
         "required":true,
         "label":"Number Of Nominee",
         "grid":6,
     },
     {
         "varName":"nomineeName",
         "type":"text",
         "label":"Nominee Name",
         "grid":6,
     },
     {
         "varName":"relationship",
         "type":"text",
         "label":"Relationship",
         "grid":6,
     },
     {
         "varName":"percentage",
         "type":"text",
         "label":"Percentage",
         "grid":6,
     },
     {
         "varName":"addressField1",
         "type":"text",
         "label":"Address Field 1 ",
         "grid":6,
     },
     {
         "varName":"addressField2",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },
     {
         "varName":"city",
         "type":"text",
         "label":"City",
         "grid":6,
     },
     {
         "varName":"state",
         "type":"text",
         "label":"State",
         "grid":6,
     },
     {
         "varName":"postalCode",
         "type":"text",
         "label":"Postal Code",
         "grid":6,
     },
     {
         "varName":"country",
         "type":"text",
         "label":"Country",
         "grid":6,
     },
     {
         "varName":"photoIdNumberRegNo",
         "type":"checkbox",
         "label":"Photo Id Number/Reg No",
         "grid":6,
     },
     {
         "varName":"nomineeDob",
         "type":"text",
         "label":"Nominee Dob",
         "grid":6,
     },
     {
         "varName":"nomineeMinor",
         "type":"text",
         "label":"Nominee Minor",
         "grid":6,
     },
     {
         "varName":"guardianSName",
         "type":"text",
         "label":"Guardian'S Name",
         "grid":6,
     },
     {
         "varName":"guardianCode",
         "type":"text",
         "label":"Guardian Code",
         "grid":6,
     },
     {
         "varName":"guardianPhotoId",
         "type":"text",
         "label":"Guardian Photo Id",
         "grid":6,
     },
     {
         "varName":"addressField3",
         "type":"text",
         "label":"Address Field 1",
         "grid":6,
     },
     {
         "varName":"addressField4",
         "type":"text",
         "label":"Address Field 2",
         "grid":6,
     },
     {
         "varName":"city1",
         "type":"select",
         "enum":[
           "DHAKA",
           "KHULNA",
           "JESSORE",
         ],
         "required":true,
         "label":"City",
         "grid":6,
     },
     {
         "varName":"state1",
         "type":"select",
         "enum":[
             "DHAKA",
             "KHULNA",
             "JESSORE",
         ],
         "required":true,
         "label":"State",
         "grid":6,
     },
     {
         "varName":"postal",
         "type":"text",
         "label":"Postal ",
         "grid":6,
     },
     {
         "varName":"country1",
         "type":"select",
         "enum":[
             "BD",
             "Japan",
             "India",
         ],
         "label":"Country",
         "grid":6,
     },*/
    {
        "varName": "sectorCode",
        "type": "text",
        "readOnly": true,
        "label": "Sector Code",
        "grid": 6,
    },
    {
        "varName": "subSectorCode",
        "type": "text",
        "readOnly": true,
        "label": "Sub Sector Code",
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "text",
        "readOnly": true,
        "label": "Occupation Code",
        "grid": 6,
    },
    {
        "varName": "agentOutletFreeCode1",
        "type": "text",
        "readOnly": true,
        "label": "Agent Outlet/Free Code 1",
        "grid": 6,
    },
    {
        "varName": "rmCodeFreeCode3",
        "type": "text",
        "required": true,
        "label": "Rm Code/ Free Code 3",
        "grid": 6,
    },
    {
        "varName": "depositCodeFreeCode7",
        "type": "text",
        "required": true,
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },
    {
        "varName": "standingAccountFrequency",
        "type": "text",
        "required": true,
        "label": "Standing Account Frequency",
        "grid": 6,
    },
    {
        "varName": "nextExecutionDate",
        "type": "date",
        "required": true,
        "label": "Next Execution Date",
        "grid": 6,
    },
];

let chekerRemarks = [
    {
        "varName": "chekerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "grid": 12
    }]
;

class OpeningSDC extends React.Component {
    state = {
        message: "",
        appData: {},
        selectedDate: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getTagList: [],
        needCheckForTag: [],
        fileUploadData: {},
        tagCbDetailsModal: false,
        jointCbtagCbDetailsModal: false,
        editMode: false,
        getImageBoolean: false,
        imageModalBoolean: false,
        getCheckerList: [],
        selectImage: "",
        errorMessages: {},
        errorArray: {},
        getRemarks: [],
        loading: false,
        alowApiCall: true,
        accountDetailsModal: false,
        maturityAmt_amountValue: true,
        errorModalBoolean: false,
        errorTittle: "",
        confirmAlert: false,
        getDownloadListFlag: false,
        accountStatement: false,
        uploadModal: false,
        getMappingAllImage: false,
        downLoadFileList: [],
        fileNotFoundTittle: "",
        actionType: "",
        requestType: "",
        tagCbNumber: 0,
        linkrelationCode: 0,

        customerName: "",

    };
    renderEditForm = (() => {
        if (this.props.subserviceType === 'New FDR Opening') {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(accountOpeningForm), this.updateComponent)
                )
            }
        } else if (this.props.subserviceType === 'Individual DPS Opening') {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(dpsAccountOpeningForm), this.updateComponent)
                )
            }
        }

    });
    renderMisRelatedForm = (() => {
        if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(this.props.jsonFormMisRelated), this.updateComponent)
                )
            }
        } else {
            return (
                <div>

                </div>
            )
        }

    });
    renderTPandKYCForm = (() => {
        if (this.props.freeFlag1 !== 'FDR Account Opening - with Existing Operative Account' && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' && this.props.freeFlag2 === "withKyc") {
            if (this.state.getData) {
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(fdKYC), this.updateComponent)
                )
            }
        } else {
            return (
                <div>

                </div>
            )
        }

    });

    copyObejct = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };

    renderTagList = () => {

        if (this.state.getData && /* (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && */ this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>


            )
        }

    };

    tagCbDetailsModalClse = () => {
        this.setState({
            tagCbDetailsModal: false,
            jointCbtagCbDetailsModal: false,
        })
    };

    tagCbDetailsModal = (event, customerNumber, requestType, cbNumber, relationCode, requestCharacter) => {
        event.preventDefault();
        if (requestCharacter === "true") {
            this.setState({
                editMode: true,
                jointCbtagCbDetailsModal: true,
                customerNumber: customerNumber,
                requestType: requestType,
                tagCbNumber: cbNumber,
                linkrelationCode: relationCode,
            })
        } else {
            this.setState({
                editMode: true,
                tagCbDetailsModal: true,
                customerNumber: customerNumber,
                requestType: requestType,
                tagCbNumber: cbNumber,
                linkrelationCode: relationCode,
            })

        }

        console.log(cbNumber)

    };

    createTaggingData = (data) => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
            return (
                [data.cbNumber, data.customerName, data.relatedCbTaggingrelationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.person, data.requestType, data.cbNumber, data.relatedCbTaggingrelationCode, data.requestCharacter)}
                >View
                </button>]]


            )
        } else {
            return (
                [data.cbNumber, data.customerName, data.relatedCbTaggingrelationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.person, data.requestType, data.cbNumber, data.relatedCbTaggingrelationCode, data.requestCharacter)}
                >View
                </button>]]


            )
        }
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    highLightsEmptyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["highlight"] = false;
        }
        return returnObject;


    };
    componentDidMount() {
        this.setState({
            loading: true
        });
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        let remarksArray = [];
        if (this.props.appId !== undefined) {
            let jsonForm = this.props.commonJsonForm.concat(objectForNominee);
            this.highLightsEmptyObject(jsonForm)
            let variableData = [];
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    variableData = response.data;
                    let inputData = response.data;

                    let countNumber = variableData.buttonCount;
                    let data = variableData.buttonFlow;
                    if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                        let url = backEndServerURL + '/TermDepositAccountAllowedForSD/' + this.props.appId;
                        if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                            url = backEndServerURL + '/DPSAccountAllowedForSD/' + this.props.appId;
                        }
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.data === false) {
                                    this.state.inputData.FdrDpsApiButton = "finalApproval";
                                    inputData.FdrDpsApiButton = "finalApproval";
                                    this.state.varValue.FdrDpsApiButton = "finalApproval";
                                    this.forceUpdate()
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                        })
                            .catch((error) => {
                                console.log(error);
                            });
                        console.log(response.data);
                        let tableArray = [];
                        response.data.map((deferal) => {
                            tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, deferal.status));
                        });
                        this.setState({
                            getDeferalList: tableArray
                        });
                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                response.data.map((data) => {
                                    if (data.remarks !== 'undefined') {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    }
                                });
                                this.setState({
                                    getRemarks: remarksArray
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                        .catch((error) => {
                            console.log(error);
                        });
                    if (variableData.checker_approval === "NOTAPPROVED" || variableData.checker_approval === "") {

                        let makerEdit = backEndServerURL + '/case/fields/updatedByMaker/' + this.props.appId;

                        axios.get(makerEdit,
                            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    let fieldVarName = response.data;

                                    let highLightArray = [];
                                    for (let i = 0; i < fieldVarName.length; i++) {
                                        let obj = jsonForm.find(item => item.varName === fieldVarName[i]);
                                        if (obj !== undefined) {
                                            highLightArray.push(obj)
                                        }
                                    }
                                    for (let i = 0; i < highLightArray.length; i++) {
                                        highLightArray[i].highlight = true
                                    }
                                    console.log(this.props.commonJsonForm)
                                    /* this.setState({
                                         highLightArray:highLightArray,
                                         highLight:true
                                     })*/
                                }
                            }).catch((error) => {
                            console.log(error)
                        });

                    }
                    let tableArrayData = [];
                    let tableArray = [];
                    let object = [];
                    object.push("cbNumber", "relatedPartyType", "relatedCbTaggingrelationCode", "requestCharacter", "person");
                    let cbTagUrl = backEndServerURL + "/secondaryDataCB/" + this.props.appId;
                    axios.post(cbTagUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data, index) => {
                                if (data.requestType === "Completed") {
                                    tableArray.push(data)
                                }
                                tableArrayData.push(this.createTaggingData(data));

                            });
                            this.setState({
                                getTagList: tableArrayData,
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    inputData.chekerRemarks = undefined;
                    this.setState({
                        needCheckForTag: tableArray,
                        getData: true,
                        loading: false,
                        showValue: true,
                        inputData: this.copyObejct(inputData),
                        varValue: this.copyObejct(inputData),
                        appData: this.copyObejct(response.data)
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });
        }
    }

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*   for (let prop in clone)
               if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
   delete clone[prop];*/
        return clone;
    };

    variableSetFunction = (object,direction) => {
        console.log(object);
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
                if(direction==="getTermDepositAccountMaturityMalue"){
                    this.getTermDepositAccountMaturityMalue()
                }
                else if(direction==="sendingEmail"){
                    let url=backEndServerURL+"/emailAttachmentFileFdrDps/"+this.props.appId;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                        })
                        .catch((error)=>{
                            console.log(error)
                        })
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
            })

    };

    buttonCount = (data) => {

        if (data === 0 || data === "0") {
            return 1;
        } else if (data === 1 || data === "1") {
            return 2;
        } else if (data === 2 || data === "2") {
            return 3;
        } else if (data === 3 || data === "3") {
            return 4;
        } else if (data === 4 || data === "4") {
            return 5;
        }

    };
    testGettermDeposite=()=>{
        let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
    }
    getTermDepositAccountMaturityMalue=()=>{
        let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let inputData = this.state.inputData;
                let varValue = this.state.varValue;
                this.setState({
                    maturityAmt_amountValue:false
                })
                inputData.maturityAmt_amountValue = response.data.depositAccount.maturityAmt_amountValue;
                this.variableSetFunction(inputData,"sendingEmail");


            })
            .catch((error) => {
                let url=backEndServerURL+"/emailAttachmentFileFdrDps/"+this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                    })
                    .catch((error)=>{
                        console.log(error)
                    })
            })

    }
    handleSubmit = (checker_approved) => {
        if (this.state.alowApiCall === true) {

            this.setState({
                alowApiCall: false
            });
            let saveDataDeferralRemarksAndRoute = {};
            if (checker_approved === "APPROVED") {
                if (this.state.needCheckForTag.length === this.state.getTagList.length) {

                } else {
                    /*   this.setState({
                           errorModalBoolean: true,
                           errorTittle: "Please Approve All Related Party Request!",
                       })
                       return 0;*/
                }
            }
            this.setState({
                loading: true,
            });
            this.state.inputData.temporaryButtonObjectcountNumber=(this.state.inputData.buttonCount);
            this.state.inputData.temporaryButtonObjectFdrDpsApiButton=(this.state.inputData.buttonFlow[this.state.inputData.buttonCount]);
            this.state.inputData.temporaryButtonObjectafterAccountGenerateButtonForBom ="finalApproval";
            this.state.inputData.temporaryButtonObjectbuttonCount=(this.buttonCount(this.state.inputData.buttonCount));
             let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
            if (this.state.fileUploadData.scanningFile !== undefined) {
                let fileUploadPath = backEndServerURL + "/case/upload";
                let types = 'Attachments';
                let files = this.state.fileUploadData.scanningFile;
                console.log("dfghjk");
                console.log(files);
                let formData = new FormData();
                formData.append("appId", this.props.appId);
                formData.append("file", files);
                formData.append("type", types);
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {

                        console.log(response);


                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            this.state.inputData.checker_approval = checker_approved;

            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            if (this.state.inputData.chekerRemarks !== undefined && this.state.inputData.chekerRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.chekerRemarks;
            }
            saveDataDeferralRemarksAndRoute.map = this.state.inputData;
            if (checker_approved === "APPROVED") {

                if ((this.state.inputData.FdrDpsApiButton).trim() === "updateCbInfo") {
                    if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB' || this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {

                        let updateUrl = backEndServerURL + "/updateCustomerInfo/" + this.props.appId;
                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((reponse) => {

                                let inputData = this.state.inputData;
                                let countNumber = inputData.buttonCount;
                                inputData.FdrDpsApiButton = inputData.buttonFlow[countNumber];
                                inputData.buttonCount = this.buttonCount(countNumber);
                                this.variableSetFunction(inputData)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    alowApiCall: true,
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error)
                                })
                            })

                    }

                } else if ((this.state.inputData.FdrDpsApiButton).trim() === "updateAccountInfo") {
                    let data = {};
                    data.digitTinChange = true;
                    data.tin = this.state.inputData.etin;
                    data.cbNumber = this.props.cbNumber;
                    data.onlyTinUpdate = true;
                    let variableSetUrl = backEndServerURL + "/casaStaticMaintenanceUpdate";
                    axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB' || this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {
                        /* let updateUrl = backEndServerURL + "/updateCustomerInfo/" + this.props.appId;
                         axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                             .then((response) => {*/
                        let updateUrl = backEndServerURL + "/openTermDepositAccountFDR/" + this.props.appId;
                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                /*   let object = {}
                                   object.digitTinChange = true
                                   object.tin = this.state.inputData.etin
                                   let tinUpdateUrl = backEndServerURL + "/casaStaticMaintenanceUpdate";
                                   axios.post(tinUpdateUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                       .then((responseTin) => {

                                           let newAccountNumber = response.data.accountNumber
                                           console.log(response.data)
                                           this.state.inputData.accountNumber = response.data.accountNumber
                                           this.state.inputData.solId = response.data.solId
                                           this.state.inputData.recpmtid = response.data.recpmtid
                                           this.state.varValue.accountNumber = response.data.accountNumber
                                           this.state.varValue.solId = response.data.solId
                                           this.state.varValue.recpmtid = response.data.recpmtid
                                           this.state.inputData.FdrDpsApiButton = "PAYABLE_AT_MATURITY"
                                           this.state.varValue.FdrDpsApiButton = "PAYABLE_AT_MATURITY"
                                           this.forceUpdate()
                                           this.variableSetFunction(this.state.inputData)
                                           this.setState({
                                               loading: false,
                                               generateNewAccountNumber: newAccountNumber,
                                               accountDetailsModal: true

                                           })

                                       })
                                       .catch((error) => {*/

                                let inputData = this.state.inputData;
                                let varValue = this.state.varValue;
                                let newAccountNumber = response.data.accountNumber;

                                this.state.inputData.accountNumber = response.data.accountNumber;
                                this.state.inputData.newSchemecode = response.data.schemeCode;
                                this.state.inputData.branchingAccountNumber = response.data.accountNumber;
                                this.state.inputData.solId = response.data.solId;
                                this.state.inputData.recpmtid = response.data.recpmtid;

                                this.state.varValue.branchingAccountNumber = response.data.accountNumber;
                                this.state.varValue.accountNumber = response.data.accountNumber;
                                this.state.varValue.solId = response.data.solId;
                                this.state.varValue.recpmtid = response.data.recpmtid;
                                inputData.accountsSolIds= response.data.solId;
                                let countNumber = inputData.buttonCount;
                                inputData.FdrDpsApiButton = inputData.buttonFlow[countNumber];
                                inputData.buttonCount = this.buttonCount(countNumber);
                                this.setState({
                                    loading: false,
                                    generateNewAccountNumber: newAccountNumber,
                                    accountDetailsModal: true

                                });
                                this.variableSetFunction(inputData,"getTermDepositAccountMaturityMalue");

                            })
                            .catch((error) => {
                                this.setState({
                                    alowApiCall: true,
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error)
                                })
                            })

                        /* })
                         .catch((error) => {
                             console.log(error)
                             this.setState({
                                 loading: false,
                                 errorModalBoolean: true,
                                 errorTittle: this.errorObjectCheck(error)
                             })
                         })*/

                    } else {
                        let updateUrl = backEndServerURL + "/openTermDepositAccountFDR/" + this.props.appId;
                        if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                            updateUrl = backEndServerURL + "/openTermDepositAccountDPS/" + this.props.appId;
                        }
                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let inputData = this.state.inputData;
                                let varValue = this.state.varValue;
                                let newAccountNumber = response.data.accountNumber;
                                console.log(response.data);
                                this.state.inputData.newSchemecode = response.data.schemeCode;
                                this.state.inputData.branchingAccountNumber = response.data.accountNumber;
                                this.state.inputData.accountNumber = response.data.accountNumber;
                                this.state.inputData.solId = response.data.solId;
                                this.state.inputData.recpmtid = response.data.recpmtid;

                                this.state.varValue.branchingAccountNumber = response.data.accountNumber;
                                this.state.varValue.accountNumber = response.data.accountNumber;
                                this.state.varValue.solId = response.data.solId;
                                inputData.accountsSolIds= response.data.solId;
                                this.state.varValue.recpmtid = response.data.recpmtid;
                                let countNumber = inputData.buttonCount;
                                inputData.FdrDpsApiButton = inputData.buttonFlow[countNumber];
                                inputData.buttonCount = this.buttonCount(countNumber);
                                this.variableSetFunction(inputData,"getTermDepositAccountMaturityMalue");




                                this.setState({
                                    loading: false,
                                    generateNewAccountNumber: newAccountNumber,
                                    accountDetailsModal: true

                                })
                            })
                            .catch((error) => {

                                this.setState({
                                    alowApiCall: true,
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error)
                                })

                            })
                    }
                } else if ((this.state.inputData.FdrDpsApiButton).trim() === "updateKycInfo") {
                    let updateUrl = backEndServerURL + "/addAcctDepositKYC/" + this.props.appId;
                    axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            let inputData = this.state.inputData;
                            let countNumber = inputData.buttonCount;
                            inputData.FdrDpsApiButton = inputData.buttonFlow[countNumber];
                            inputData.buttonCount = this.buttonCount(countNumber);
                            this.variableSetFunction(inputData)
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                alowApiCall: true,
                                loading: false,
                                errorModalBoolean: true,
                                errorTittle: this.errorObjectCheck(error)
                            })
                        })


                } else if ((this.state.inputData.FdrDpsApiButton).trim() === "finalApproval") {
                    let inputData = this.state.inputData;
                    //inputData.FdrDpsApiButton = "UPDATE"
                    saveDataDeferralRemarksAndRoute.map = inputData;
                    var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false,
                            });
                            this.props.closeModal();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                            })
                        });
                }


            } else if (checker_approved !== "APPROVED") {

                let inputData = this.state.inputData;
                if (checker_approved === "RETURN_TO_CS") {
                    if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
                        inputData.dpsccy = inputData.currency;
                    } else {
                        inputData.ccy = inputData.currency;
                    }

                }

                saveDataDeferralRemarksAndRoute.map = inputData;
                var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            loading: false,
                        });
                        this.props.closeModal();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })
                    });
            }

                })
                .catch((error)=>{
                    console.log(error)
                })
        } else {

        }
    };

    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false,
            loading: true,
        });
        let saveDataDeferralRemarksAndRoute = {};
        if ((this.state.inputData.FdrDpsApiButton).trim() === "UPDATE") {
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            let data = this.state.inputData;
            saveDataDeferralRemarksAndRoute.map = this.state.inputData;
            var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false,

                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        //getInitiateData: true,
                        errorModalBoolean: true,
                        errorTittle: "System Error"
                    })


                })


        } else {
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            let data = this.state.inputData;
            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });

        }

    };

    renderImageCrop = () => {
        if (this.state.getData) {
            return (
                <img width='20%' src={backEndServerURL + "/signaturePhoto/" + this.props.appId + "/CARD1"} alt=""/>
            )
        }
    };

    validationForHandleConfirm = (actionType) => {
        console.log(this.state.inputData);
        let errors = MyValidation.defaultValidation(chekerRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "submitApprove") {


            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        } else if (actionType === "submitReturn") {
            let dependencyField = [];
            dependencyField.push({

                "varName": "chekerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Cheker Remarks",
                "grid": 12
            });
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        } else if (actionType === "submitReturnToCS") {
            let dependencyField = [];
            dependencyField.push({

                "varName": "chekerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Cheker Remarks",
                "grid": 12
            });
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        }

    };

    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitApprove") {
            let checker_approved = "APPROVED";
            this.handleSubmit(checker_approved);
        } else if (data === "YES" && this.state.actionType === "submitReturn") {
            let checker_approved = "NOTAPPROVED";
            this.handleSubmit(checker_approved);
        } else if (data === "YES" && this.state.actionType === "submitReturnToCS") {
            // this.state.inputData.FdrDpsApiButton = "UPDATE";
            let checker_approved = "RETURN_TO_CS";
            this.handleSubmit(checker_approved);
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };

    handlePayableAtMaturity = (event, type) => {
        event.preventDefault();
        if ((this.state.inputData.FdrDpsApiButton).trim() === "finalApproval") {
            this.setState({
                loading: true,
            });
            let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
            axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    let varValue = this.state.varValue;
                    console.log(response.data);
                    inputData.maturityAmt_amountValue = response.data.depositAccount.maturityAmt_amountValue;
                    inputData.FdrDpsApiButton = "finalApproval";
                    let countNumber = inputData.buttonCount;
                    inputData.buttonCount = this.buttonCount(countNumber);
                    this.variableSetFunction(inputData);
                    this.setState({
                        accountDetailsModal: true,
                        loading: false,
                        maturityAmt_amountValue:true,
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error)
                    })
                })


        }


    };

    submitApprove = () => {
        let checker_approved = "APPROVED";
        this.handleSubmit(checker_approved);
    };

    submitReturn = () => {
        let checker_approved = "NOTAPPROVED";
        this.handleSubmit(checker_approved);
    };

    submitReturnToCS = () => {
        let checker_approved = "RETURN_TO_CS";
        this.handleSubmit(checker_approved);
    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }

    };

    onKeyDownForDedup = (event, data) => {


    };

    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };

    /* rederService = (() => {
         if(this.props.subserviceType === 'Tag FDR Opening' || this.props.subserviceType === 'Individual DPS Opening'){
             if (this.state.getData) {
                 return (
                    <div>

                    </div>
                 )
             }
         }
         return ;
     })*/
    stopLoading = () => {
        this.setState({
            loading: false
        })
    };
    renderCommonForm = () => {

        if (this.state.getData) {
            return (
                <AccountDetails interestRate={this.state.inputData.interestRate}

                                transactionDate={this.state.inputData.transactionDate}
                                taxWaiverChange={this.state.inputData.taxWaiverChange}
                                preventialRateChange={this.state.inputData.preventialRateChange}
                                taxWaiverChanges={this.state.inputData.taxWaiverChanges}
                                preventialRateChangs={this.state.inputData.preventialRateChanges}
                                transactionID={this.state.inputData.transactionID}
                                clearingLimitDR={this.state.inputData.clearingLimitDR}
                                clearingLimitCR={this.state.inputData.clearingLimitCR}
                                TransferLimitCR={this.state.inputData.TransferLimitCR} tin={this.state.inputData.etin}
                                maturity={this.state.inputData.maturity} amount={this.state.inputData.availableAmount}
                                freeFlag1={this.props.freeFlag1} cbNumber={this.state.inputData.cbNumber}
                                classes={this.props}
                                casaAccountNumber={this.state.inputData.accountNumber}
                                accountNumber={this.state.inputData.debitAccountNumber} stopLoading={this.stopLoading}
                                create={true}
                                crmStatus={this.state.inputData.custStatus}/>
            )

        }

    };
    renderSignatureAndDebitAccount = () => {
        if (this.state.getData) {
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff"}}
                      onClick={() => this.openSignature(this.state.inputData.debitAccountNumber)}> Signature Card
                View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };
    renderSericeForm = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    renderSericeFormLast = () => {

        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormLast, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    // bomApproval = () => {
    //     if (this.state.getData) {
    //         return (
    //             SelectComponent.select(this.state, this.updateComponent, bomApproval)
    //         )
    //     }
    //     return;
    // }

    checkerList = () => {
        if (this.state.getData) {
            let checkerJsonForm = {
                "varName": "next_user",
                "type": "select",
                "label": "Send To",
                "enum": []
            };
            this.state.getCheckerList.map((checker) => {

                checkerJsonForm.enum.push(checker)
            });
            return (
                SelectComponent.select(this.state, this.updateComponent, checkerJsonForm)
            )

        }

    };

    handleChange = (event) => {
        event.target.name = event.target.value;

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };

    trimFunction = (data) => {
        return (data).trim()
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.inputData.FdrDpsApiButton !== undefined && this.state.inputData.FdrDpsApiButton !== null) {
            return (
                <div>
                    {(this.state.inputData.FdrDpsApiButton).trim() === "updateAccountInfo" && this.state.loading === false && this.props.freeFlag1 !== 'DPS Account Opening -with Existing Operative Account' &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > Approve FD Opening
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.FdrDpsApiButton).trim() === "updateAccountInfo" && this.state.loading === false && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account' &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > Approve DPS Opening
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.FdrDpsApiButton).trim() === "updateCbInfo" && this.state.loading === false &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > CB Update
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.FdrDpsApiButton).trim() === "updateKycInfo" && this.state.loading === false &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > Approve FD KYC
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.FdrDpsApiButton).trim() === "finalApproval" && this.state.loading === false &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handlePayableAtMaturity(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > PAYABLE AT MATURITY
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.FdrDpsApiButton).trim() === "finalApproval" && this.state.loading === false && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}

                        >Final Approval
                        </button>
                        &nbsp;&nbsp;</>}


                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitReturn")}

                            //onClick={this.submitReturn}
                        > Return to Maker
                        </button>
                        &nbsp;&nbsp;</>

                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitReturnToCS")}
                            //onClick={this.submitReturnToCS}
                        > Return to CS
                        </button>
                        &nbsp;&nbsp;</>
                </div>
            )
        } else if (this.state.getData) {
            return (
                <div>
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitReturn")}

                            //onClick={this.submitReturn}
                        > Return to Maker
                        </button>
                        &nbsp;&nbsp;</>

                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitReturnToCS")}
                            //onClick={this.submitReturnToCS}
                        > Return to CS
                        </button>
                        &nbsp;&nbsp;</>
                </div>
            )
        }


    };
    updateComponent = () => {
        this.forceUpdate();
    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Raise By", "Raise Date", "Expire Date", "Status"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />
                    <br/>
                </div>

            )
        }

    };

    viewImageModal = (event) => {
        event.preventDefault();
        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })
    };
    closeModal = () => {
        this.setState({
            imageModalBoolean: false,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeModalError = () => {
        this.setState({
            errorModalBoolean: false,
        })
        //this.props.closeModal();
    };
    renderImageLink = () => {

        if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })
            )
        }

    };
    handleChangeRelatedPartyNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfRelatedParty"] = option.title;
            this.forceUpdate()

        }

    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, chekerRemarks, this.updateComponent)
            )
        }

    };
    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }

    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderRelatedPartyNumber = () => {
        return (
            <React.Fragment>
                <Grid item>
                    <label className="input-label-common">Related Party Number</label>
                </Grid>
                <Grid item>
                    {/*  <Autocomplete onChange={(event, option) => this.handleChangeRelatedPartyNumber(event, option)}
                        //defaultValue={this.state.inputData["numberOfRelatedParty"]}
                        aria-readonly={true}
                                  options={numberOfNominee} getOptionLabel={option => option.title}
                                  renderInput={(params) => <TextField {...params} variant="outlined"
                                                                      style={{paddingRight: 20}} fullWidth/>}
                    />*/}
                    <TextField
                        variant="outlined"
                        defaultValue={this.state.inputData["numberOfRelatedParty"]}
                        name="numberOfRelatedParty"
                        //label={field.label}
                        InputProps={{
                            // style:Object.assign(styles,firstStyle),
                            readOnly: true
                        }}
                        //InputLabelProps={{shrink:true}}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>

        )
    };
    renderRelatedParty = () => {

        var sl;
        let objectForRelatedParty = [];
        for (var i = 0; i < this.state.inputData["numberOfRelatedParty"]; i++) {
            sl = i + 1;

            objectForRelatedParty.push(
                {
                    "type": "title",
                    "label": "RELATED PARTY  " + sl,
                    "grid": 12,
                },

                {
                    "varName": "relationType" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    "readOnly": true,
                    "label": "Relation Type ",
                    "grid": 6,
                },

                {
                    "varName": "relationCode" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    "label": "Relation Code",
                    "grid": 6,
                    "readOnly": true,
                },

                {
                    "varName": "designationCode" + i,
                    "type": "autoCompleteValueReturn",
                    "enum": [],
                    "label": "Designation Code",
                    "grid": 6,
                    "readOnly": true,
                },
                //Nominee Information


                {
                    "varName": "cifId" + i,
                    "type": "text",
                    "label": "CIF ID",
                    "grid": 6,
                    "readOnly": true,

                }
            )

        }

        return (
            CommonJsonFormComponent.renderJsonForm(this.state, objectForRelatedParty, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
        )

    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    renderNomineeForm = () => {

        if (this.state.inputData.numberOfNominee > 0) {
            var sl;
            let objectForNominee = [];
            if (this.state.inputData["numberOfNominee"] !== undefined && this.state.inputData["numberOfNominee"] !== null) {
                objectForNominee.push(
                    {
                        "type": "title",
                        "label": "Nominee",
                        "grid": 12,
                    },
                    {
                        "varName": "nomineeRegNo",
                        "type": "text",
                        "label": "Registration No.",
                        "grid": 6,
                        "required": true,

                    },
                )
            }

            for (var i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                sl = i + 1;
                //inputData["nomineecifIdApiCall"+i]=false
                //varValue["nomineecifIdApiCall"+i]=false
                objectForNominee.push(
                    {
                        "varName": "Nominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "label": "Input CIF ID/Information ?",
                        "grid": 6,


                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    /*  {
                            "varName": "nomineenomineephotoIdNo" + i,
                            "type": "text",
                            "label": "NOMINEE PHOTO ID NO.",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "nomineecifIdApiCall" + i,
                            "conditionalVarValue": true,
                        },
        */
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo ID Number/Reg No.",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo ID Number/Reg No.",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "DOB",
                        "maxDate": true,
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "DOB",
                        "maxDate": true,
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        /*    "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",*/
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        /*  "conditional": true,
                          "conditionalVarName": "search" + sl,
                          "conditionalVarValue": "INFORMATION",*/
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    //Information guardian details
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },
                )

            }
            if (this.state.getData &&  (this.props.freeFlag1 !== "DPS Account Opening -with Existing Operative Account" && this.state.inputData.nomineeType === "New Nominee Assignment" ) || (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" && this.state.inputData.dpsnomineeType === "New Nominee Assignment")) {

                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(objectForNominee), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                )
            }

        }

    };
    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.numberOfNominee > 0) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number of Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField

                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                //style:Object.assign(styles,firstStyle),
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };
    renderDedupComponent = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton multiTaskLink={true} cbNumber={this.state.inputData.cbNumber}
                                   appId={this.props.appId}
                                   customerName={this.state.inputData.miscustomerNameLastName}
                                   accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    renderUploadButton = () => {
        if (this.state.getData) {
            return (

                <button

                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }

    };
    submitModal = (data) => {
        this.state.inputData.previewImage = true;
        this.updateComponent();
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };
    renderMappingImageEditModal = () => {


        return (
            <FileMappingEdit dropDownOption={arrayListIndividual}
                             appId={this.props.appId}
                             submitModal={this.submitModal}
                             closeModal={this.closeModal}/>
        )

    };
    renderMappingImageModal = () => {
        return (
            <FileMapping dropDownOption={arrayListIndividual}
                         submitModal={this.submitModal}
                         appId={this.props.appId}
                         closeModal={this.closeModal}/>
        )

    };
    renderSdnComponent = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };
    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };
    renderTaggingApi = () => {
        let tableArrayData = [];
        let tableArray = [];
        let object = [];
        object.push("cbNumber", "relatedPartyType", "relatedCbTaggingrelationCode", "requestCharacter", "person");
        let cbTagUrl = backEndServerURL + "/secondaryDataCB/" + this.props.appId;
        axios.post(cbTagUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    }
                    tableArrayData.push(this.createTaggingData(data));

                });
                this.setState({
                    getTagList: tableArrayData,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }

        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {this.renderNotification()}
                        <CardBody>
                            <GridList cellHeight={window.innerHeight} cols={1}>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.tagCbDetailsModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SecondaryCbFormDetails editMode={this.state.editMode}
                                                                relationCode={this.state.linkrelationCode}
                                                                freeFlag1={this.props.freeFlag1}
                                                                checkerEnd={true}
                                                                customerName={this.props.customerName}
                                                                accountNumber={this.props.accountNumber}
                                                                solId={this.props.solId}
                                                                freeFlag4={this.props.freeFlag4} tagForm={tagForm}
                                                                appId={this.props.appId}
                                                                customerNumber={this.state.customerNumber}
                                                                relationCode={this.state.linkrelationCode}
                                                                tagingModalCbnumber={this.state.customerNumber}
                                                                requestType={this.state.requestType}
                                                                closeModal={this.tagCbDetailsModalClse}
                                                                requestType={this.state.requestType}
                                                                cbNumber={this.state.tagCbNumber}
                                                                serviceType={this.props.serviceType}
                                                                getTaggingData={this.renderTaggingApi}/>
                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.jointCbtagCbDetailsModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SecondaryJointCbTagging relationCode={this.state.linkrelationCode}
                                                                 freeFlag1={this.props.freeFlag1}
                                                                 checkerEnd={true}
                                                                 customerName={this.props.customerName}
                                                                 accountNumber={this.props.accountNumber}
                                                                 solId={this.props.solId}
                                                                 freeFlag4={this.props.freeFlag4} tagForm={tagForm}
                                                                 appId={this.props.appId}
                                                                 customerNumber={this.state.customerNumber}
                                                                 relationCode={this.state.linkrelationCode}
                                                                 tagingModalCbnumber={this.state.customerNumber}
                                                                 requestType={this.state.requestType}
                                                                 closeModal={this.tagCbDetailsModalClse}
                                                                 requestType={this.state.requestType}
                                                                 cbNumber={this.state.tagCbNumber}
                                                                 serviceType={this.props.serviceType}
                                                                 getTaggingData={this.renderTaggingApi}
                                                                 taging={this.renderTaggingApi}
                                        />
                                    </DialogContent>

                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.imageModalBoolean}>
                                    <DialogContent>
                                        <SingleImageShow data={this.state.selectImage} closeModal={this.closeModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.history}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <SubmittedCaseHistory multiTaskLink={true}
                                                              customerName={this.state.customerName}
                                                              accountNumber={this.state.accountNumber}
                                                              solId={this.state.solId} globalSearch={true}
                                                              closeIcon={this.closeHistoryModal}
                                                              appId={this.state.appId}
                                                              closeModal={this.closeHistoryModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    open={this.state.accountDetailsModal}>
                                    <DialogContent>

                                        <AccountNoGenerate
                                            dps={true}
                                            maturityAmt_amountValue={this.state.maturityAmt_amountValue!==false? this.state.inputData.maturityAmt_amountValue:undefined}
                                            recpmtid={this.state.inputData.recpmtid}
                                            cbNumber={this.props.cbNumber}
                                            schemeCode={this.state.inputData.newSchemecode}
                                            cbAndScheme={true}
                                            freeFlag1={this.props.freeFlag1}
                                            generateNewAccountNumber={this.state.inputData.accountNumber}
                                            solId={this.state.inputData.solId}
                                            customerName={this.state.inputData.customerName}
                                            generateNewCB={this.state.generateNewCB}
                                            closeModal={this.accountDetailsModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="mD"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModalError}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlertImage validationForHandleConfirm={this.validationForHandleConfirm}
                                                           closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.accountStatement}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AccountStatement closeModal={this.closeStatement}
                                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        {this.renderMappingImageModal()}

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        {this.renderMappingImageEditModal()}

                                    </DialogContent>
                                </Dialog>
                                <div>
                                    <Grid item xs='12'></Grid>
                                    <Grid container spacing={1}>
                                        <ThemeProvider theme={theme}>
                                            <Grid item xs={12}>
                                                {this.renderDownLoadFileLink()}
                                            </Grid>
                                            {this.renderCommonForm()}
                                            <Grid item xs={12}>
                                                {this.renderSignatureAndDebitAccount()}
                                            </Grid>
                                            {this.renderMisRelatedForm()}
                                            {this.renderSericeForm()}


                                            <Grid item xs={6}>
                                                {this.renderNomineeNumber()}
                                            </Grid>
                                            <Grid item xs={6}>
                                            </Grid>

                                            {this.renderNomineeForm()}
                                            {this.renderSericeFormLast()}

                                            <br/>

                                            {this.renderTPandKYCForm()}
                                            <Grid item xs='12'>
                                                {this.renderDedupComponent()}
                                            </Grid>


                                            <Grid item xs='12'>
                                                {this.renderDedupComponentMandateAndBeneficiary()}
                                            </Grid>

                                            <Grid item xs={12}>
                                                {this.renderTagList()}
                                            </Grid>
                                            <Grid item xs='12'>
                                                {this.renderSdnComponent()}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <DeferalList appId={this.props.appId}/>
                                            </Grid>
                                            <Grid item={12}>

                                                <WaiverList appId={this.props.appId}/>
                                            </Grid>

                                            <Grid item xs='12'>
                                                {this.renderRemarksData()}
                                            </Grid>
                                            <br/>
                                            <Grid item xs='12'>
                                                {this.renderUploadButton()}
                                                &nbsp;
                                                {this.renderAssignedImage()}
                                            </Grid>
                                            {this.renderFlowSUmmeryButton()}
                                            <br/>
                                            {this.renderRemarks()}
                                        </ThemeProvider>
                                    </Grid>
                                    <br/>

                                    {this.renderImageCrop()}
                                    <br/>
                                    {this.renderSubmitButton()}
                                </div>
                            </GridList>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(OpeningSDC);
