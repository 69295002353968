import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import CBNotFound from "../CASA/CBNotFound";
import WithoutDedupeLinkCBTypeComponent from "./WithoutDedupeLinkCBTypeComponent";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "125%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};

class WithoutDedupeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            cbNotFound: false,
            title: "",
            dormant: false,
            showButton: false,
            withoutDedupeButton: false,

        }
    }


    withoutDedupeButton = () => {
        this.setState({
            withoutDedupeButton: true
        })
    };

    closewithoutDedupe = () => {
        this.setState({
            withoutDedupeButton: false
        })
    };

    renderwithoutDedupe = () => {

        return <WithoutDedupeLinkCBTypeComponent
            WithJointCb={this.props.WithJointCb}
            freeFlag1={this.props.freeFlag1}
            customerName={this.props.customerName} accountNumber={this.props.accountNumber}
            solId={this.props.solId} freeFlag4={this.props.freeFlag4}
            introducerId={this.props.introducerId} introducerName={this.props.introducerName}
            appId={this.props.appId}
            hideButton={this.props.hideButton}
            //cbNumber={this.state.cbNumber}
            serviceType={this.props.serviceType}
            renderTagging={this.props.renderTagging}
            taging={this.props.renderTagging}
            getTaggingData={this.props.getTaggingData}
            closeModal={this.closewithoutDedupe} id={this.props.accountNumber}/>

    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderAlertModal = (classes) => {
        return (
            <Dialog
                fullWidth="true"
                maxWidth="sm"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.cbNotFound}>
                <DialogContent className={classes.dialogPaper}>

                    <CBNotFound
                        closeModal={this.closeModalCBNotFound}
                        title={this.state.title}
                    />
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        const {classes} = this.props.classes;
        return (
            <React.Fragment>
                <button
                    className="btn btn-danger btn-sm"
                    style={{
                        verticalAlign: 'right',
                        // marginTop: 20

                    }}
                    onClick={this.withoutDedupeButton}
                >
                    Manual Link CB Add
                </button>

                {/*  {this.renderAlertModal(classes)}*/}
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    //className={classes.modal}
                    // classes={{paper: classes.dialogPaper}}
                    open={this.state.withoutDedupeButton}>
                    <DialogContent>
                        {this.renderwithoutDedupe()}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );


    }


}

export default withStyles(styles)(WithoutDedupeComponent);