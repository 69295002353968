import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponent from "../../JsonForm/DateComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import AccountNoGenerate from "../AccountNoGenerate";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import FileMapping from "../CommonComponent/FileMapping";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";

const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];
const arrayListIndividualCondition = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},

];

let arrayListNonIndividual = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},
    {label: 'OTHERS'},


];
let arrayListNonIndividualCondition = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},


];
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferral Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "minDate": true,
    "label": "Expire Date",

};

class AgentBanking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            abs_deferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {
                abs_deferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loading: true,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            getAllApprovalOfficer: [],
            city: [],
            getCity: false,
            state: [],
            getState: false,
            currency: [],
            getCurrency: false,
            country: [],
            getCountry: false,
            getAllSavingsSchemeCode: [],
            getSavingsSchemeFlag: false,
            getAllCurrentSchemeCode: [],
            getCurrentSchemeFlag: false,


        };
        this.renderJointInformation();
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    renderJointInformation = () => {
        for (let i = 0; i < this.props.jointAccountCustomerNumber; i++) {
            arrayListNonIndividual.push(
                {label: "Customer " + (i + 1) + ' IIF1'},
                {label: "Customer " + (i + 1) + ' IIF2'},
                {label: "Customer " + (i + 1) + ' NID'},
                {label: "Customer " + (i + 1) + ' PASSPORT'},
                {label: "Customer " + (i + 1) + ' DRIVING LICENSE'},
                {label: "Customer " + (i + 1) + ' BIRTH CERTIFICATE'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 1'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 2'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 3'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 4'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 5'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 6'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 7'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 8'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 9'},
                {label: "Customer " + (i + 1) + ' NOMINEE NID 10'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 1'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 2'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 3'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 4'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 5'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 6'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 7'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 8'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 9'},
                {label: "Customer " + (i + 1) + ' NOMINEE BIRTH CERTIFICATE 10'},
            )
        }

    };
    handleChange = (event, value) => {

        this.state.inputData["abs_deferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };


    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "8px"
                        }}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    DedupDataSaveApi = (subServiceType) => {
        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    /*axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        })*/
                    console.log(" dedup save data r");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            alert("please type select")
        }
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                console.log(getValue.key);
                let data = [];
                /* for(let j=0;j<getValue.length;j++){
                     let key=getValue[j].key;
                     let value=getValue[j].value;
                     data.push({key,value})
                 }*/
                this.props.commonJsonForm[i].enum = getValue;
                console.log(this.props.commonJsonForm[i]);
                console.log("ddddddddddddddddddddddddddddddddd")

            }
        }
    };
    setStateapiEnumValue = (setVarname, data) => {
        if (setVarname === "city") {
            this.setState({
                city: data
            })
        } else if (setVarname === "state") {
            this.setState({
                state: data
            })
        } else if (setVarname === "currency") {
            this.setState({
                currency: data
            })
        } else if (setVarname === "country") {
            this.setState({
                country: data
            })
        } else if (setVarname === "fdrSchemeCode") {
            this.setState({
                fdrSchemeCode: data
            })
        }

    };
    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                /*  if(apiType==="savingsSchemeCode"){
                      this.setState({
                          getAllSavingsSchemeCode:response.data,
                          getSavingsSchemeFlag:true
                      })
                  }
                  else if(apiType==="currentSchemeCode"){
                      this.setState({
                          getAllCurrentSchemeCode:response.data,
                          getCurrentSchemeFlag:true
                      })
                  }
                  else{*/

                this.findByVarNameApiData(fieldName, response.data)


            })
            .catch((error) => {
                console.log(error);
            })
    };

    componentDidMount() {
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("fdrSchemeCode", "fdrSchemeCode")
        }
        this.state.inputData["abs_deferal"] = "NO";
        let varValue = [];
        var remarksArray = [];
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let approvalOfficerUrl = backEndServerURL + "/user/absCheckers";
                                    axios.get(approvalOfficerUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            console.log(response.data);
                                            this.setState({
                                                getAllApprovalOfficer: response.data
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                    console.log(response.data);
                                    this.setState({
                                        fileUploadData: response.data,

                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    console.log(remarksArray);
                    this.state.inputData["abs_deferal"] = "NO";

                    console.log(response.data);
                    let inputData = this.getSearchvalue(response.data);
                    let varValue = this.getSearchvalue(response.data);
                    this.setState({
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        inputData: inputData,
                        showValue: true,
                        appId: this.props.appId,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        } else {
            let url = backEndServerURL + "/startCase/abs_maker_account_opening";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let approvalOfficerUrl = backEndServerURL + "/user/absCheckers";
                    axios.get(approvalOfficerUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getAllApprovalOfficer: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    {
                        this.DedupDataSaveApi(this.props.subServiceType)
                    }
                    console.log(this.props.searchValue);
                    console.log(response.data);

                    //let inputData = this.getSearchvalue(this.props.searchValue);
                    //let varValue = this.getSearchvalue(this.props.searchValue);
                    //
                    let inputData = {};
                    let varValue = {};
                    inputData.abs_deferal = "NO";
                    inputData.accountSource = "FINACLE";
                    varValue.accountSource = "FINACLE";
                    inputData.accountType = "SAVINGS";
                    varValue.accountType = "SAVINGS";
                    inputData.accType = "SAVINGS";
                    varValue.accType = "SAVINGS";
                    inputData.cbNumber = "CB5984143";
                    varValue.cbNumber = "CB5984143";
                    inputData.accountNumber = 2101430595001;
                    varValue.accountNumber = 2101430595001;
                    inputData.customerName = "Md Biplob Hossain .";
                    varValue.customerName = "Md Biplob Hossain .";
                    inputData.passport = 12345676;
                    inputData.passport = 12345676;
                    inputData.phone = "01714740719";
                    varValue.phone = "01714740719";
                    inputData.email = "a@gmail.com";
                    varValue.email = "a@gmail.com";
                    inputData.nid = "1996234567546";
                    varValue.nid = "1996234567546";

                    inputData.tin = "123456789101";
                    varValue.tin = "123456789101";
                    inputData.registrationNo = "12345678910111111";
                    varValue.registrationNo = "12345678910111111";
                    inputData.nationality = "BANGLADESH";
                    varValue.nationality = "BANGLADESH";
                    inputData.comAddress = "dhaka";
                    varValue.comAddress = "dhaka";
                    inputData.schemeCode = "12345";
                    varValue.schemeCode = "12345";
                    inputData.currency = "BDT";
                    varValue.currency = "BDT";
                    inputData.rmCode = "1234543";
                    varValue.rmCode = "1234543";
                    inputData.ccepCompanyCode = "876543456";
                    varValue.ccepCompanyCode = "876543456";
                    inputData.ccepCompanyCode = "876543456";
                    varValue.ccepCompanyCode = "876543456";
                    inputData.currency = "BDT";
                    varValue.currency = "BDT";
                    inputData.certificate = "234567567";
                    varValue.certificate = "234567567";
                    inputData.companyEtin = "654322";
                    varValue.companyEtin = "654322";
                    inputData.sbsCode = "345";
                    varValue.sbsCode = "345";
                    inputData.priority = "GENERAL";
                    varValue.priority = "GENERAL";

                    inputData.gender = "M";
                    varValue.gender = "M";
                    /*  inputData.dob = "1985-02-05";
                      varValue.dob = "1985-02-05";
                      inputData.sectorCode = "1200";
                      varValue.sectorCode = "1200";*/

                    if (this.props.subServiceType === "Company Account") {
                        inputData.accountType = "NON-INSTAPACK";
                        varValue.accountType = "NON-INSTAPACK";

                    } else {
                        inputData.accountType = "INSTAPACK";
                        varValue.accountType = "INSTAPACK";
                        // inputData.accountType = "INSTAPACK";
                    }

                    inputData.priority = "GENERAL";
                    inputData.varValue = "GENERAL";
                    inputData.smsAlertRequest = true;
                    inputData.varValue = true;
                    inputData.instaCardType = "CITYMAXX";
                    inputData.varValue = "CITYMAXX";


                    inputData.pageOfChequeBook = "25";
                    varValue.pageOfChequeBook = "25";
                    this.setState({
                        appId: response.data.id,
                        appData: response.data.inputData,
                        getNewCase: true,
                        //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                        inputData: inputData,
                        varValue: varValue,
                        showValue: true,
                        getData: true,
                        loading: false


                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        //appId: response.data.id,
                        //appData: response.data.inputData,
                        getNewCase: true,
                        //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                        //inputData: inputData,
                        varValue: varValue,
                        showValue: true,
                        getData: true,
                        loading: false


                    });
                })
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };
    renderAddButtonShow = () => {

        return (
            <button
                className="btn btn-outline-primary btn-sm"

                style={{
                    width: 100,
                    float: 'right',
                    verticalAlign: 'right',

                }}

                type='button' value='add more'
                onClick={this.addClick.bind(this)}


            >Add Deferral</button>
        )

    };

    dynamicDeferral = (i) => {
        let deferalType = "deferalType" + i;
        let expireDate = "expireDate" + i;
        let defferalOther = "defferalOther" + i;
        let arrayData = [];
        /*arrayData.push({deferalType,expireDate,defferalOther});
        this.setState({
            getAllDefferal:arrayData
        })*/
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )


        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        let dependencyField = [];
        console.log(this.state.inputData);


        if (this.state.inputData["accType"] === "SAVINGS") {
            dependencyField.push({
                "varName": "schemeCodeForSavings",
                "type": "autoCompleteValueReturn",
                "label": "Scheme Code *",
                "asteriks": true,
                "grid": 2,
                "required": true,
                "conditional": true,
                "conditionalVarName": "accType",
                "conditionalVarValue": "SAVINGS",
            })

        } else if (this.state.inputData["accType"] === "CURRENT") {
            dependencyField.push({
                "varName": "schemeCodeForCurrent",
                "type": "autoCompleteValueReturn",
                "label": "Scheme Code *",
                "asteriks": true,
                "grid": 2,
                "required": true,
                "conditional": true,
                "conditionalVarName": "accType",
                "conditionalVarValue": "CURRENT",
            },)

        }

        let error = MyValidation.defaultValidation(this.props.commonJsonForm.concat(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        console.log(this.state.inputData);
        if (this.state.inputData["abs_deferal"] === "YES") {

            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }


            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";

            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                })
                .catch((error) => {

                    console.log(error);
                })
        }

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

        let data = this.state.inputData;
        data.abs_deferal = this.state.inputData["abs_deferal"];
        data.csRemarks = undefined;
        data.serviceType = "Account Opening";
        data.customerType = "NEW";
        data.category = "CASA";
        data.subCategory = "A/C Opening";
        if (this.props.appId === undefined) {
            data.individualDedupData = this.state.individualDataSaveId;
            data.jointDedupData = this.state.jointDataSaveId;
            data.companyDedupData = this.state.companyDataSaveId;
            data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
            data.subServiceType = this.props.subServiceType;

            if (this.state.inputData.priority === "HIGH")
                data.urgency = 1;
            else
                data.urgency = 0;
        }

        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;


        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (this.state.inputData.accountType === "NON-INSTAPACK" && this.state.inputData.bm_approval === undefined && this.state.inputData.bom_approval === undefined) {
                    this.setState({
                        accountDetailsModal: true
                    })
                } else {
                    var url = backEndServerURL + "/case/route/" + this.state.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            console.log("Successfully Routed!");

                            {
                                this.close();
                            }
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true

                            })
                            //


                        })
                        .catch((error) => {
                            this.failedRoute();
                            //Login.loginRedirection(error);
                            console.log(error);

                        });
                }
            })
            .catch((error) => {
                console.log(error)
            });

    };
    failedRoute = () => {
        let data = this.state.inputData;
        data.cs_deferal = this.state.inputData["csDeferal"];
        data.serviceType = "Account Opening";
        data.category = "CASA";
        data.subCategory = "A/C Opening";
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.props.subServiceType;
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;

        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true

                });
                this.close()
                //


            })
            .catch((error) => {
                console.log(error)
            });
    };
    handleSubmitDraft = (event) => {
        event.preventDefault();

        /*   let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state)
           this.forceUpdate();
           console.log(this.state.inputData);

           console.log("Not working");

           if(error===true){
               return 0;
           }*/
        if (this.state.inputData["abs_deferal"] === "YES") {
            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;

        let data = this.state.inputData;
        data.abs_deferal = this.state.inputData["abs_deferal"];
        data.serviceType = "Account Opening";
        data.category = "CASA";
        data.subCategory = "A/C Opening";
        data.individualDedupData = this.state.individualDataSaveId;
        data.jointDedupData = this.state.jointDataSaveId;
        data.companyDedupData = this.state.companyDataSaveId;
        data.jointAccountCustomerNumber = this.props.jointAccountCustomerNumber;
        data.subServiceType = this.props.subServiceType;
        // data.dueDate=this.state.dueDate;
        // data.type=this.state.type;

        if (this.state.inputData.priority === "HIGH")
            data.urgency = 1;
        else
            data.urgency = 0;

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true

                });
                this.close()
                //


            })
            .catch((error) => {
                console.log(error)
            });

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["abs_deferal"] = "YES";
    }

    renderRemarks = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["abs_deferal"] = "YES"
            } else {
                this.state.inputData["abs_deferal"] = "NO"
            }
        }


    }


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    renderUploadButton = () => {
        if (!this.state.deferalNeeded) {
            return (
                <button
                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    renderAssignedImage = () => {
        if (this.state.uploadButtonClick || this.state.fileUploadData.length > 1) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Assigned Image
                </button>
            )
        }
    };

    closeModal = () => {
        this.setState({

            getMappingAllImage: false,


        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };

    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        var url = backEndServerURL + "/case/route/" + this.state.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("Successfully Routed!");

                {
                    this.close();
                }
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true

                })
                //


            })
            .catch((error) => {
                console.log(error);

            });


    };


    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderMappingImageModal = () => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMapping condition={true} dropDownConditionOption={arrayListIndividualCondition}
                             dropDownOption={arrayListIndividual}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMapping condition={true} dropDownConditionOption={arrayListNonIndividualCondition}
                             dropDownOption={arrayListNonIndividual}
                             appId={this.state.appId}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageEditModal = () => {

        if (this.props.subServiceType === "INDIVIDUAL" || this.state.inputData.subServiceType === "Individual A/C") {

            return (
                <FileMappingEdit dropDownOption={arrayListIndividual}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {

            return (
                <FileMappingEdit dropDownOption={arrayListNonIndividual}
                                 appId={this.state.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderApprovalOfficerList = () => {
        if (this.state.getData && this.state.inputData["abs_deferal"] === "NO") {


            let ApprovalOfficerJsonForm = {
                "varName": "next_user",
                "type": "select",
                "label": "Send To",
                "grid": 2,
                "enum": []
            };
            this.state.getAllApprovalOfficer.map((data) => {

                ApprovalOfficerJsonForm.enum.push(data)
            });
            return (
                SelectComponent.select(this.state, this.updateComponent, ApprovalOfficerJsonForm)
            )

        }
    };
    handleCaseClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Close!",
                    alert: true

                });
                this.close()
                //


            })
            .catch((error) => {
                console.log(error)
            });

    };

    render() {

        const {classes} = this.props;


        if (this.state.loading === true) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h6>New
                            Customer
                            Account Opening<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h6>

                    </CardHeader>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                </Card>


            )
        } else {
            return (


                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h6>New
                            Customer
                            Account Opening<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h6>

                    </CardHeader>
                    <CardBody>
                        <div>

                            <ThemeProvider theme={theme}>

                                <Grid container>

                                    {this.renderNotification()}


                                    {this.returnJsonForm()}

                                    <Grid item xs='12'>
                                    </Grid>
                                    {this.renderAddButtonShow()}


                                    <Grid item xs='12'>

                                    </Grid>
                                    {
                                        this.addDeferalForm()
                                    }


                                    <Grid item xs='12'>
                                    </Grid>


                                    <Grid item xs={12}>

                                        {this.renderDefferalData()}


                                    </Grid>
                                    <Grid item xs={12}>

                                        {this.renderRemarksData()}

                                    </Grid>
                                    <Grid item xs='12'>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {this.renderUploadButton()}

                                    </Grid>&nbsp;&nbsp;
                                    <Grid item xs={1}>
                                        {this.renderAssignedImage()}
                                    </Grid>
                                    <Grid item xs='12'>

                                    </Grid>
                                    <br/>
                                    {this.renderRemarks()}

                                    <Grid item xs='12'>
                                    </Grid>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        open={this.state.accountDetailsModal}>
                                        <DialogContent>

                                            <AccountNoGenerate closeModal={this.accountDetailsModal}/>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        open={this.state.getMappingAllImage}>
                                        <DialogContent>
                                            {this.renderMappingImageEditModal()}

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        open={this.state.uploadModal}>
                                        <DialogContent>
                                            {this.renderMappingImageModal()}

                                        </DialogContent>
                                    </Dialog>

                                    <Grid item xs='2'>
                                        {this.renderApprovalOfficerList()}
                                    </Grid>
                                    <Grid item xs='12'>
                                    </Grid>
                                    <center>
                                        <button
                                            className="btn btn-outline-primary  btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmit}

                                        >
                                            Submit
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                            className="btn btn-outline-info btn-sm"
                                            style={{}}
                                            onClick={this.handleSubmitDraft}

                                        >
                                            Save
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button
                                            className="btn btn-outline-info btn-sm"
                                            style={{}}
                                            onClick={this.handleCaseClose}

                                        >
                                            Close
                                        </button>

                                    </center>
                                </Grid>

                            </ThemeProvider>


                        </div>


                    </CardBody>
                </Card>


            );

        }

    }
}

export default withStyles(styles)(AgentBanking);
