import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import {
    nocCheckerWaiverJsonFormReadOnly,
    nocMakerWaiverJsonForm,
    waverRequestReadOnlyForm
} from "./JsonFormWaiverRequest";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CommonApi from "../../Maintenance/common/CommonApi";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import Functions from "../../../../Common/Functions";
import CBNotFound from "../../CASA/CBNotFound";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

class CommonApprovalWaiverRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            currentTask: "",
            confirmAlert: false,
            actionType: "",
            selectedDate: {},
            securityDetails: [],

        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                this.settingDynamicField(inputData.numOfSecurityDetails, inputData.currentTask);
                inputData.remarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            currentTask: response.data.currentTask,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            loading: false
                        })

                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification}
                              title={this.state.title} message={this.state.notificationMessage}/>
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = () => {
        let postData = {...this.state.inputData};
        this.setState({
            loading: true
        });
        if (this.state.currentTask === "closing_maker_waver_request") {
            postData.closing_maker_approved = "YES";
        } else if (this.state.currentTask === "closing_checker_waver_request") {
            postData.closing_checker_approved = "YES";
        } else if (this.state.currentTask === "noc_maker_waver_request") {
            postData.noc_maker_approved = "YES";
        } else if (this.state.currentTask === "noc_checker_waver_request") {
            postData.home_loan = "YES";
        }

        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.remarks !== undefined && this.state.inputData.remarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.remarks;
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    loading: false,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });


    };
    returnHandler = () => {
        let saveDataDeferralRemarksAndRoute = {};
        this.setState({
            loading: true,
        });
        if (this.state.inputData.remarks !== undefined && this.state.inputData.remarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.remarks;
        }
        let postData = {...this.state.inputData};
        if (this.state.currentTask === "closing_maker_waver_request") {
            postData.closing_maker_approved = "NO";
        } else if (this.state.currentTask === "closing_checker_waver_request") {
            postData.closing_checker_approved = "NO";
        } else if (this.state.currentTask === "noc_maker_waver_request") {
            postData.noc_maker_approved = "NO";
        } else if (this.state.currentTask === "noc_checker_waver_request") {
            postData.home_loan = "NO";
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    loading: false,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });

    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };
    castToString = (str) => {
        let newStr = JSON.stringify(str);
        if (newStr === '' || newStr === "null" || newStr === "undefined" )
            return "";
        return str;
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
        }
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginLeft: 5,
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    closeConfirmAlert = (value) => {
        this.setState({
            confirmAlert: false,
        });
        if (value === "ok" && this.state.actionType === "approveHandler") {
            this.approveHandler()
        } else if (value === "ok" && this.state.actionType === "returnHandler") {
            this.returnHandler()
        }
    };

    validationForHandleConfirm = (actionType) => {
        if (this.state.getData && this.state.currentTask === "noc_maker_waver_request") {
            let errorNocMakerWaiverJsonForm = MyValidation.defaultValidation(nocMakerWaiverJsonForm, this.state);
            this.forceUpdate();
            if (errorNocMakerWaiverJsonForm===true) {
                return 0;
            }
        }
        let error = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })
        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "remarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };

    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnHandler")}
                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    };

    renderJsonForm = () => {
        if (this.state.getData && this.state.currentTask === "noc_maker_waver_request") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, nocMakerWaiverJsonForm.concat(this.state.securityDetails), this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        } else if (this.state.getData && this.state.currentTask === "noc_checker_waver_request") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, nocCheckerWaiverJsonFormReadOnly.concat(this.state.securityDetails), this.updateComponent)
            )
        } else if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, waverRequestReadOnlyForm, this.updateComponent)
            )
        }
    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
    };
    settingDynamicField = (quant, task) => {
        let readOnly=(task !== "noc_maker_waver_request");
        let sd = [];
        this.setState({
            securityDetails: sd,
        });
        this.forceUpdate();
        for (let i = 1; i <= parseInt(quant); i++) {
            sd.push(
                {
                    varName: "securityDetails"+ i,
                    type: "text",
                    label: "Security Details"+ i,
                    required: true,
                    grid: 4,
                    "conditional": true,
                    "conditionalVarName":"chequeStatus",
                    "conditionalVarValue": "With Security Details",
                }
            )
        }
        this.forceUpdate();
        this.setState({
            securityDetails: sd,
        });

    };
    onKeyDownChange = (data) => {

        if(data.varName==="chequeStatus"){
            let sd=[];
            let inputData = {...this.state.inputData};
            inputData.engineNo=undefined;
            inputData.chassisNo=undefined;
            inputData.registrationNo=undefined;
            inputData.brtaAuthority=undefined;
            inputData.bankNamePdc=undefined;
            inputData.bankNameUdc=undefined;
            inputData.pdcNo=undefined;
            inputData.udcNo=undefined;

            inputData.nocIssueDate=undefined;
            inputData.customerTitle=undefined;
            inputData.ownerName=undefined;
            inputData.businessName=undefined;
            inputData.proprietorName=undefined;
            inputData.chequeNO=undefined;
            inputData.numOfSecurityDetails=undefined;
            this.setState({
                securityDetails: sd,
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
            });}

        if (data.varName === "numOfSecurityDetails") {
           this.settingDynamicField(this.state.inputData.numOfSecurityDetails, this.state.currentTask);
        }
        if(data.varName === "nocCheckerId"){
            let inputData = {...this.state.inputData};
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: true,
            });
            let url = assetOpsBackEndServerURL + "/user/get/" + this.state.inputData.nocCheckerId;
            console.log(url);
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.checkerUserName = this.castToString(response.data.firstName) +" "+ this.castToString(response.data.lastName);
                    inputData.checkerUserRole = response.data.userRoleName;
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                    });
                })
                .catch((error)=>{
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid User ID",
                    })
                })
        }


    };
    emptyValueRemove = (jsonObject) => {
        return JSON.parse(JSON.stringify(jsonObject));
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Waiver Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleReport = () => {
        if (this.state.getData && this.state.currentTask === "noc_maker_waver_request" && this.state.loading === false  && this.state.inputData.productName=="AUTO LOAN") {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.nocLetter}
                    >
                        Print Noc Letter
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.hypothicationLetter}
                    >
                        Print Hypothication Letter
                    </button>
                </div>
            )
        }
        else if (this.state.getData && this.state.currentTask === "noc_maker_waver_request" && this.state.loading === false ) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.nocLetter}
                    >
                        Print Noc Letter
                    </button>

                </div>
            )
        }

    };
    nocLetter = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getNocLetter";
        functionForFileDownload.downloadFile(url, "POST", inputData, "NOC_Letter.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }
    hypothicationLetter = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getHypothicationLetter";
        functionForFileDownload.downloadFile(url, "POST", inputData, "Hypothication_Letter.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <Card>
                {this.props.titleName === undefined ? <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader> : ""}
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmAlert}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderJsonForm()}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                        </Grid>
                        {this.renderRemarksData()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderFlowSUmmeryButton()}
                        {this.renderRemarks()}
                        <Grid item xs={12}> {this.handleReport()}</Grid>
                        <Grid item xs={12}><br/></Grid>
                        {this.renderSubmitButton()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        )
    }

}

export default withStyles(styles)(CommonApprovalWaiverRequest);
