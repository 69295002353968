import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import CircularProgress from "@material-ui/core/CircularProgress";
import loader from "../../../Static/loader.gif";
import {ThemeProvider} from "@material-ui/styles";
import {Menu} from "semantic-ui-react";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import MaintenanceRequest from "../CardCallCategory/MaintenanceRequest";
import TableComponent from "../CommonComponent/TableComponent";

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '8px',
            }
        },

    }
});

const filteringJsonForm = [
    {
        varName: "startDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        errorMessage: "Error",
    },

    {
        varName: "endDate",
        type: "date",
        label: "End Date",
        grid: 2,
        errorMessage: "Error",
    },
    {
        "varName": "caseId",
        "type": "text",
        "grid": 2,
        "label": "Case Id",
    },
    {
        "varName": "clientId",
        "type": "text",
        "grid": 3,
        "label": "Client Id",
    },
    // {
    //     "varName": "branchName",
    //     "type": "autoCompleteValueReturn",
    //     "grid": 2,
    //     "label": "Branch Name",
    // },
    {
        "varName": "customerCategory",
        "type": "select",
        "onKeyDown": true,
        "label": "Customer Category",
        "enum": [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        "grid": 3,
    },
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "label": "Call Category",
        "grid": 3,
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    },
};


class InitiatorInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            selectedDate: {},
            fileUploadData: {},
            responseData: [],
            activeItem: 'Open',
            backgroundColor: 'red',
            showValue: false,
            uploadModal: false,
            detailsModal: false,
            viewModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "View",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            loader: null,
            dialogHeader: "",
            err: false,
            supportDocumentArray: [],
            loading: false,
            confirmationAlert: false,
            errorArray: {},
            errorMessages: {},
            autoComplete: {},
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            showData: true,
            varValue: {},
            selectedCount: 0,
            appId: 0,
            inboxModal: false,
            freeFlag1: "",
            freeFlag2: "",
            freeFlag3: "",
            searchItem: "",
            confirmationType: "",
            confirmationData: [],
            allData: [],
            columns: [
                {title: 'SL', field: 'sl',},
                {title: 'Case Id', field: 'caseId'},
                {title: 'Name', field: 'customerName'},
                {title: 'Client Id', field: 'clientId'},
                {title: 'Account/Card Number', field: 'accountOrCardNumber'},
                // {title: 'Card', field: 'card'},
                {title: 'Customer Category', field: 'customerCategory'},
                {title: 'Sub Category', field: 'subCategory'},
                {title: 'Call Category', field: 'callCategory'},
                {title: 'Date of Activity', field: 'dateOfActivity'},
                // {title: 'Executor Group', field: 'executorGroup'},
                // {title: 'Executor Id', field: 'executorId'},
                {title: 'Status', field: 'status'},
            ],
            totalPages: 0,
            pageForOnChange: 1
        };
    }

    dateConverter = (value) => {
        let date = value.split("T");
        return date[0] + " " + date[1]
    };

    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {

                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete.callCategory = callCategory;
                        this.setState({
                            autoComplete: {...this.state.autoComplete, callCategory},
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    fetchDatafromAPI = (status, pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.activeItem)
        } else {
            this.setState({
                loader: true
            });

            let open_url;
            if (status === "Locked") {
                open_url = backEndServerURL + '/inbox/waiting/' + page;
            } else if (status === "Return") {
                open_url = backEndServerURL + '/inbox/return/' + page;
            } else if (status === "Open") {
                open_url = backEndServerURL + '/getAllOpenCardMaintenanceRequest/' + page;
            }

            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let res = response.data.content;
                    let totalRow = response.data.totalElements;
                    let totalPage = response.data.totalPages;
                    let array = [];
                    let rowData;
                    let responseArray = [];
                    let responseRowData;
                    let sl = 0;
                    for (let i = 0; i < res.length; i++) {
                        responseRowData = res[i].appId;
                        rowData = {
                            sl: ++sl,
                            id: res[i].id,
                            caseId: res[i].caseId,
                            appId: res[i].appId,
                            serviceType: res[i].serviceType,
                            subServiceType: res[i].subServiceType,
                            freeFlag1: res[i].freeFlag1,
                            freeFlag2: res[i].freeFlag2,
                            freeFlag3: res[i].freeFlag3,
                            customerName: res[i].customerName,
                            clientId: res[i].cbNumber,
                            accountOrCardNumber: res[i].accountNumber,
                            // card: "",
                            customerCategory: res[i].freeFlag1,
                            subCategory: res[i].subCategory,
                            callCategory: res[i].freeFlag2,
                            dateOfActivity: res[i].delInitTime.split("T")[0],
                            // executorGroup: "",
                            // executorId: "",
                            status: res[i].delStatus,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    console.log(array);

                    this.setState(
                        {
                            allData: array,
                            loader: false,
                            pageNumber: page,
                            responseData: responseArray,
                            totalRow: totalRow,
                            totalPages: totalPage,
                        }
                    );
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };


    componentDidMount() {
        this.fetchDatafromAPI("Open", 0);
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    headerSelect = (event, value) => {
        event.preventDefault();
        this.forceUpdate();
        this.setState({
            loader: true,
            activeItem: value,
            allData: [],
            responseData: [],
        });
        if (this.state.searchItem) {
            setTimeout(() => {
                this.fetchDatafromAPI(value, 0)
            }, 300)
        } else {
            this.fetchDatafromAPI(value, 0)
        }
    };

    renderFilterForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent, "", this.onKeyDownChange)
        )
    };

    searchData = (e, activeItem, page) => {
        this.setState({
            loader: true,
        });

        console.log(activeItem);
        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                pageNumber: 0
            });
            inbox_url = backEndServerURL + "/filterApplicationForCardInitiator/" + activeItem + "/0";
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
            });
            inbox_url = backEndServerURL + "/filterApplicationForCardInitiator/" + activeItem + "/" + page;
        }


        let postData = {
            fromDate: this.state.inputData.startDate,
            toDate: this.state.inputData.endDate,
            caseId: this.state.inputData.caseId,
            branchName: this.state.inputData.branchName,
            customerCategory: this.state.inputData.customerCategory,
            callCategory: this.state.inputData.callCategory,
            clientId: this.state.inputData.clientId,
        };

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let rowData;
                let sl = 0;
                for (let i = 0; i < response.data.content.length; i++) {
                    rowData = {
                        sl: ++sl,
                        id: response.data.content[i].id,
                        caseId: response.data.content[i].caseId,
                        appId: response.data.content[i].appId,
                        serviceType: response.data.content[i].serviceType,
                        subServiceType: response.data.content[i].subServiceType,
                        freeFlag1: response.data.content[i].freeFlag1,
                        freeFlag2: response.data.content[i].freeFlag2,
                        freeFlag3: response.data.content[i].freeFlag3,
                        customerName: response.data.content[i].customerName,
                        clientId: response.data.content[i].clientId,
                        accountOrCardNumber: response.data.content[i].cardNumber,
                        // card: "",
                        customerCategory: response.data.content[i].freeFlag1,
                        subCategory: response.data.content[i].subCategory,
                        callCategory: response.data.content[i].freeFlag2,
                        dateOfActivity: response.data.content[i].delInitTime.split("T")[0],
                        // executorGroup: "",
                        // executorId: "",
                        status: response.data.content[i].delStatus,
                    };
                    array.push(rowData)
                }

                this.setState(
                    {
                        allData: array,
                        loading: false,
                        pageNumber: this.state.pageNumber,
                        totalRow: response.data.totalElements,
                        totalPages: response.data.totalPages,
                        searchItem: this.state.activeItem,
                        loader: false,
                    }
                );
                console.log(this.state.allData)
            })
            .catch(error => {
                console.log(error);
            });
    };

    closeModal = () => {
        this.setState({
            inboxModal: false,
        });
    };


    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: ' ',
                    }}>
                        <h4 style={{color: "black"}}>Card Maintenance</h4>
                    </CardHeader>
                    <CardBody style={{
                        backgroundColor: "white"
                    }}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="lg"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.inboxModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <MaintenanceRequest closeModal={this.closeModal}
                                                    serviceType={this.state.serviceType}
                                                    freeFlag1={this.state.freeFlag1}
                                                    freeFlag2={this.state.freeFlag2}
                                                    freeFlag3={this.state.freeFlag3}
                                                    subCategory={"Maintenance"}
                                                    category={"CARD"}
                                                    subServiceType={this.state.subServiceType}
                                                    appId={this.state.appId}/>)
                            </DialogContent>
                        </Dialog>
                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>
                                {this.renderFilterForm()}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem)
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => window.location.reload()}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>

                        </Grid>
                        <br></br>
                        <React.Fragment>
                            <div>
                                <Menu pointing>
                                    <Menu.Item
                                        name='Open'
                                        active={activeItem === 'Open'}
                                        style={activeItem === 'Open' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Open')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Locked'
                                        active={activeItem === 'Locked'}
                                        style={activeItem === 'Locked' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Locked')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Return'
                                        active={activeItem === 'Return'}
                                        style={activeItem === 'Return' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Return')
                                        }}
                                    />
                                </Menu>
                                {this.tableShow(this.state.activeItem)}
                                {this.renderNotification()}
                            </div>
                        </React.Fragment>
                    </CardBody>
                </React.Fragment>
            );
        }
    }


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    renderInboxCase = (e, rowData) => {
        this.setState({
            appId: rowData.appId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            inboxModal: true,
        });
    };

    onRowClick = () => {

    };

    handleChangePage = () => {
        this.fetchDatafromAPI(this.state.activeItem, 0);
    };

    tableShow = (value) => {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <CircularProgress style={{marginLeft: '50%'}}/>
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else {
            return (
                <TableComponent tableData={this.state.allData} tableColumns={this.state.columns}
                                onRowClick={this.onRowClick} loader={this.state.loader} maxBodyHeight="450px"
                                totalRow={this.state.totalRow} page={this.state.pageNumber} whiteSpace={"wrap"}
                                selection={false} handleChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage}
                />
            )
        }
    };


}

export default withStyles(styles)(InitiatorInbox);
