import React from 'react';
import Grid from "@material-ui/core/Grid";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import theme from "../JsonForm/CustomeTheme";
import Notification from "../NotificationMessage/Notification";
import Functions from '../../Common/Functions';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import CBNotFound from "../workflow/CASA/CBNotFound";
import Autocomplete from "@material-ui/lab/Autocomplete";
/*const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,

    },
    buttonAddMember: {
        marginRight: theme.spacing(16),
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}));*/

let typeOfGroup = [
    {"label": "FAMILY"},
    {"label": "CORPORATE"}

];
const styles = theme => ({


    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",

        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        padding: theme.spacing(3),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 300,
        paddingBottom: theme.spacing(3),

    },
    subTextField: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(1),
        width: 350,


    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },


});

class GroupAndMemberAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            inputData: [],
            customerName: [],
            customerId: [],
            groupNameAndCustomerId: false,
            memberNameAndCustomerId: [],
            allMember: [],
            loading: false,
            errorModal: false,
            errorMessage: "Error",
            alert: false,
            setAllData: false,
            title: "",
            notificationMessage: "SuccessFully Added",
            redirectLogin: false,
        };
        //this.handleSubmit = this.handleSubmit.bind(this);
    }


    renderMemberCustomerNameAndCustomerId = (i, substylesTexField) => {

        if (this.state.customerName[i] !== undefined && this.state.customerId[i] !== undefined) {
            return (
                <div>
                    <GridContainer xs={8} md={8}>
                        <GridItem xs={1} md={1}></GridItem>
                        <GridItem xs={5} md={5}>
                            <Grid item>
                                <label className="input-label-common">Customer Name</label>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    value={this.state.customerName[i]}
                                    InputProps={{
                                        readOnly: true,
                                        required: true,

                                    }}

                                    style={{paddingRight: 20, width: 300}}
                                />
                            </Grid>
                        </GridItem>
                        <GridItem xs={1} md={1}></GridItem>
                        <GridItem xs={5} md={5}>
                            <Grid item>
                                <label className="input-label-common">Customer ID</label>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    value={this.state.customerId[i]}
                                    InputProps={{
                                        required: true,
                                        readOnly: true
                                    }}
                                    style={{paddingRight: 20, width: 200}}
                                />
                            </Grid>
                        </GridItem>
                    </GridContainer>

                </div>

            )
        }


    };
    memberHandleKeyDown = (i, event) => {

        event.preventDefault();
        this.setState({
            loading: true,
        });
        let trimCBValue = (event.target.value).trim();

        let url = backEndServerURL + "/customerInfo/" + trimCBValue;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let customerId = [...this.state.customerId];
                customerId[i] = response.data.customerId;
                this.setState({customerId: customerId});
                let customerName = [...this.state.customerName];
                customerName[i] = response.data.fullName;
                let memberNameAndCustomerId = [];
                memberNameAndCustomerId[i] = i;

                this.setState({
                    customerName: customerName,
                    memberNameAndCustomerId: memberNameAndCustomerId,
                    loading: false,
                });


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true,

                    })

                }
                this.setState({
                    loading: false,
                    errorModal: true,
                    errorMessage: "CB Not Found"
                });
                let customerId = [...this.state.customerId];
                customerId[i] = "";
                this.setState({customerId: customerId});
                let customerName = [...this.state.customerName];
                customerName[i] = "";
                let memberNameAndCustomerId = [];
                memberNameAndCustomerId[i] = i;

                this.setState({
                    customerName: customerName,
                    memberNameAndCustomerId: memberNameAndCustomerId,
                    loading: false,
                });

            })

    };

    addMemberForm(stylesTexField, substylesTexField) {
        if (this.state.setAllData === true) {

            return this.state.values.map((el, i) =>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <div key={i}>
                            <Grid item>
                                <label className="input-label-common">CB Number</label>
                            </Grid>
                            <Grid item>
                                <TextField
                                    className={stylesTexField}
                                    variant="outlined"
                                    value={this.state.values[i]}
                                    onBlur={this.memberHandleKeyDown.bind(this, i)}
                                    onChange={this.handleChangeCBNumber.bind(this, i)} name="cbNumber"
                                    InputProps={{
                                        required: true,
                                        readOnly: false
                                    }}

                                    style={{paddingRight: 20, width: 300}}
                                />
                            </Grid>


                            <button
                                className="btn btn-outline-primary"
                                style={{
                                    position: "absolute",
                                    right: 400,
                                    marginTop: 5
                                }}

                                type='button' value='remove' onClick={this.removeClick.bind(this, i)}

                            >
                                Cancel
                            </button>


                            {this.renderMemberCustomerNameAndCustomerId(i, substylesTexField)}


                            <br/><br/>
                        </div>

                    </GridItem>

                </GridContainer>
            )
        }

    }


    addClick(event) {
        event.preventDefault();

        this.setState(prevState => ({
            setAllData: true,
            values: [...prevState.values, ' '],
            customerId: [...prevState.customerId, ' '],
            customerName: [...prevState.customerName, ' '],

        }))


    }

    notification = () => {
        if (this.state.alert) {

            return (<Notification stopNotification={this.stopNotification} title={this.state.title}
                                  message={this.state.notificationMessage}/>)
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    closeModal = () => {
        this.setState({
            errorModal: false
        })
    };

    removeClick(i, event) {
        event.preventDefault();
        this.setState({
            setAllData: false
        });

        let values = [...this.state.values];
        values.splice(i, 1);

        let customerName = [...this.state.customerName];
        customerName.splice(i, 1);
        this.setState({customerName});
        let customerId = [...this.state.customerId];
        customerId.splice(i, 1);
        this.setState({customerId});
        this.setState({
            values: values,
            customerName: customerName,
            customerId: customerId,
            setAllData: true
        });
        console.log(this.state.values)


    }

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            alert: false,
            loading: true,
        });
        let error = false;
        let object = [];
        console.log(this.state.inputData);

        console.log(this.state.customerName.length);
        if (this.state.inputData['customerId'] === undefined || this.state.inputData['customerId'].length === 0) {
            error = true;
        }
        if (this.state.customerName.length === 0) {
            return 0;
        }
        for (let i = 0; i < this.state.customerName.length; i++) {
            if (this.state.customerId[i] !== undefined && this.state.customerId[i].length > 0) {
                object.push({"customerName": this.state.customerName[i], "customerId": this.state.customerId[i]})
            } else {
                error = true;
            }

            console.log(this.state.customerName[i]);
            console.log(this.state.customerId[i])
        }

        if (error === true) {
            this.setState({
                title: "Failed!",
                notificationMessage: "Customer ID is not valid",
                alert: true,
                loading: false,

            });

            return 0;
        }


        let url = backEndServerURL + "/group/create";
        axios.post(url, {
            "keyCustomerName": this.state.inputData['customerName'],
            "keyCustomerId": this.state.inputData['customerId'],
            "groupUid": this.state.inputData['customerId'],
            "descriptionOfGrouping": this.state.inputData['descriptionOfGrouping'],
            "addMemberDTOS": object


        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                if (response.data.status !== 'UNSUCESSFULL') {
                    this.setState({
                        loading: false,
                        alert: true,
                        title: "Success!",
                        notificationMessage: "Group Added",
                    });
                    window.location.reload();
                } else {
                    this.setState({
                        loading: false,
                        alert: true,
                        errorModal: true,
                        errorMessage: 'Already Exist Group!!',
                        title: "Error!",
                        notificationMessage: "Group Not Added",
                    })
                }


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true,
                        loading: false,
                    })

                }
            })


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleChangeCBNumber = (i, event) => {
        event.preventDefault();
        let values = [...this.state.values];
        let string = event.target.value.trim();
        values[i] = string.toUpperCase();
        this.setState({values: values});


    };

    handleChange = (event) => {
        event.preventDefault();
        let string = event.target.value.trim();

        this.state.inputData[event.target.name] = string.toUpperCase();


    };
    handleChangeSelect = (option, varName) => {
        if (option !== null) {
            let string = option.label;
            this.state.inputData[varName] = string;
            this.forceUpdate();
        }


    };
    groupHandleKeyDown = (event) => {
        event.preventDefault();
        this.setState({
            groupNameAndCustomerId: false,
            loading: true,
        });
        let trimCBValue = (event.target.value).trim();
        if (event.target.name === 'cbNumber') {
            let url = backEndServerURL + "/customerInfo/" + trimCBValue;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData['customerId'] = response.data.customerId;
                    this.state.inputData['customerName'] = response.data.fullName;
                    this.setState({
                        groupNameAndCustomerId: true,
                        loading: false,
                    })

                })
                .catch((error) => {
                    console.log(error);

                    this.state.inputData['customerId'] = "";
                    this.state.inputData['customerName'] = "";
                    this.setState({

                        loading: false,
                        errorModal: true,
                        errorMessage: "CB Not Found"
                    })

                })
        }


    };
    groupNameAndCustomerId = (styleTextField) => {

        if (this.state.groupNameAndCustomerId) {
            return (
                <div>

                    <GridContainer item xs={8} md={8}>
                        <GridItem xs={6} md={6}>
                            <Grid item>
                                <label className="input-label-common" style={{marginLeft: '40px'}}>Key-Customer
                                    Name</label>
                            </Grid>
                            <Grid item>
                                <TextField
                                    className={styleTextField}

                                    variant="outlined"
                                    style={{paddingRight: 20, width: 300}}
                                    value={this.state.inputData['customerName']}
                                    name="customerName"
                                    InputProps={{
                                        readOnly: true,
                                        required: true,
                                    }}
                                />
                            </Grid>

                        </GridItem>
                        <GridItem xs={6} md={6}>
                            <Grid item>
                                <label className="input-label-common" style={{marginLeft: '40px'}}>Key-Customer
                                    ID</label>
                            </Grid>
                            <Grid item>
                                <TextField
                                    className={styleTextField}

                                    variant="outlined"
                                    style={{paddingRight: 20, width: 200}}
                                    value={this.state.inputData['customerId']}
                                    InputProps={{
                                        readOnly: true,
                                        required: true,
                                    }}
                                />
                            </Grid>

                        </GridItem>
                    </GridContainer>


                </div>
            )
        }

    };


    render() {

        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <div>
                {this.notification()}
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <center>
                                    <h4 style={{color: "white"}} >Group and Member Add</h4>
                                </center>
                            </CardHeader>
                            <CardBody>

                                <div className={classes.root}>
                                    <Grid container spacing={1}>
                                        <Grid container item xs={12} spacing={3}>
                                            <ThemeProvider theme={theme}>
                                                <form className={classes.container}>

                                                    <div>
                                                        <GridItem xs={6} md={6}>
                                                            <Grid item>
                                                                <label className="input-label-common">Key-CB
                                                                    Number</label>
                                                            </Grid>
                                                            <Dialog
                                                                fullWidth="true"
                                                                maxWidth="sm"
                                                                className={classes.modal}
                                                                classes={{paper: classes.dialogPaper}}
                                                                open={this.state.loading}>
                                                                <DialogContent className={classes.dialogPaper}>

                                                                    <center>
                                                                        <img src={loader} alt=""/>
                                                                    </center>
                                                                </DialogContent>
                                                            </Dialog>
                                                            <Dialog
                                                                fullWidth="true"
                                                                maxWidth="sm"
                                                                className={classes.modal}
                                                                classes={{paper: classes.dialogPaper}}
                                                                open={this.state.errorModal}>
                                                                <DialogContent className={classes.dialogPaper}>

                                                                    <CBNotFound
                                                                        closeModal={this.closeModal}
                                                                        title={this.state.errorMessage}
                                                                    />
                                                                </DialogContent>
                                                            </Dialog>
                                                            <Grid item>
                                                                <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"

                                                                    style={{paddingRight: 20, width: 300}}
                                                                    onChange={this.handleChange}
                                                                    onBlur={(event) => {
                                                                        this.groupHandleKeyDown(event)
                                                                    }}
                                                                    name="cbNumber"
                                                                    InputProps={{
                                                                        readOnly: false
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </GridItem>


                                                        {this.groupNameAndCustomerId(classes.subTextField)}
                                                        <br/>
                                                        <GridItem xs={6} md={6}>
                                                            <Grid item>
                                                                <label className="input-label-common">Description Of
                                                                    Grouping</label>
                                                            </Grid>
                                                            <Grid item>
                                                                {/* <TextField
                                                                    className={classes.textField}
                                                                    variant="outlined"

                                                                    style={{paddingRight: 20, width: 300}}
                                                                    onChange={this.handleChange}
                                                                    name="descriptionOfGrouping"

                                                                    InputProps={{
                                                                        readOnly: false
                                                                    }}
                                                                />*/}
                                                                <Autocomplete
                                                                    onChange={(event, option) => this.handleChangeSelect(option, "descriptionOfGrouping")}
                                                                    //defaultValue={this.state.inputData["numberOfNominee"]}
                                                                    options={typeOfGroup}
                                                                    getOptionLabel={option => option.label}
                                                                    renderInput={(params) => /*<TextField {...params} variant="outlined"

                                                                      style={{paddingRight: 20}} fullWidth/>}*/
                                                                        <TextField {...params}
                                                                                   variant="outlined"
                                                                                   style={{
                                                                                       paddingRight: 20,
                                                                                       width: 200
                                                                                   }} fullWidth/>}
                                                                />
                                                            </Grid>
                                                        </GridItem>

                                                        <button
                                                            className="btn btn-outline-primary"
                                                            style={{
                                                                paddingRight: 20,
                                                                marginTop: "5px",
                                                                verticalAlign: 'left',
                                                                marginRight: theme.spacing(100),


                                                            }}

                                                            type='button' value='add more'
                                                            onClick={(event) => this.addClick(event)}

                                                        >
                                                            Add Member
                                                        </button>
                                                    </div>

                                                    <br/> <br/>
                                                    {this.addMemberForm(classes.textField, classes.subTextField)}
                                                    <br/> <br/>
                                                    <center>
                                                        <button
                                                            className="btn btn-outline-primary"
                                                            style={{
                                                                verticalAlign: 'middle',
                                                            }}

                                                            type='button'
                                                            onClick={(event) => this.handleSubmit(event)}

                                                        >
                                                            Submit
                                                        </button>
                                                    </center>

                                                </form>
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </div>

        );
    }
}

export default withStyles(styles)(GroupAndMemberAdd);
