import React from "react";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";


class RadioButtonComponent {

    static handleChange(event, state, update) {

        state.inputData[event.target.name] = event.target.value;
        update();
    }

    static returnDefaultValue = (state, field) => {
        if (field.readOnly)
            return state.varValue[field.varName];

        if (state.showValue) {
            if (state.varValue[field.varName] === -1)
                return state.inputData[field.varName];
            state.inputData[field.varName] = state.varValue[field.varName];
            state.varValue[field.varName] = -1;
        }
        return state.inputData[field.varName];
    };
    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <FormLabel>{field.label} *</FormLabel>
            )
        } else {
            return (
                <FormLabel>{field.label}</FormLabel>
            )
        }
    };

    static radio(state, update, field) {


        return (
            <FormControl>
                {
                    this.asteriksFunction(field)
                }
                <RadioGroup
                    //style={ field.readOnly===true?{  color:'#212F3D',background: '#f8f9fa'  }:{  } }
                    /* style={{
                         marginTop: marginTop,
                         marginBottom: marginBottom
                     }}*/
                    //defaultValue={this.returnDefaultValue(field)}
                    // helperText={this.state.errorMessages[varName]}
                    //error={this.state.errorArray[varName]}
                    row aria-label="position"
                    value={this.returnDefaultValue(state, field)}
                    required={field.readOnly === true ? false : field.required}
                    name={field.varName}
                    onChange={(event) => this.handleChange(event, state, update)}
                >
                    {
                        field.enum.map((radios) => {
                            return (

                                <FormControlLabel disabled={field.readOnly} value={radios}
                                                  control={<Radio color="primary"/>} label={radios}/>
                                /*  <FormControlLabel
                                  control={<Radio/>}
                                  disabled={field.readOnly}
                                  value={radios}
                                  label={radios}

                              />*/)
                        })
                    }


                </RadioGroup>
            </FormControl>

        )


    }

}

export default RadioButtonComponent;