import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import "semantic-ui-offline/semantic.min.css";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../../NotificationMessage/Notification";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import CloseIcon from "@material-ui/icons/Close";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#000",
        },
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: "auto",
    },
    Tab: {
        flexDirection: "row-reverse",
    },
    progress: {
        margin: 50,
    },
};

let jsonForm = [
    {
        varName: "bankName",
        type: "autoCompleteValueReturn",
        label: "Bank Name",
        enum: [],
        grid: 2,
        required: true,
    },
    {
        varName: "branchName",
        type: "text",
        label: "Branch Name",
        grid: 2,
        required: true,
    },
    {
        varName: "distName",
        type: "text",
        label: "District Name",
        grid: 2,
        required: true,
    },
    {
        varName: "transferType",
        type: "select",
        enum: [
            "RTGS",
            "BEFTN",
            "BOTH"
        ],
        label: "Transfer Type",
        grid: 2,
        required: true,
    },
    {
        varName: "routingNo",
        type: "text",
        validation: "length",
        maxLength: 9,
        minLength: 9,
        label: "Routing Number",
        grid: 2,
        required: true,
    },
];

let jsonFormTwo = [
    {
        varName: "bankName",
        type: "autoCompleteValueReturn",
        label: "Bank Name",
        enum: [],
        grid: 2,
        required: true,
    },
    {
        varName: "branchName",
        type: "text",
        label: "Branch Name",
        grid: 2,
        required: true,
    },
    {
        varName: "distName",
        type: "text",
        label: "District Name",
        grid: 2,
        required: true,
    },
    {
        varName: "routingNo",
        type: "text",
        validation: "length",
        maxLength: 9,
        minLength: 9,
        label: "Routing Number",
        grid: 2,
        required: true,
    },
];

class BeneficiaryAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            showValue: false,
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false,
            alert: false,
            getData: false,
            bankName: []
        };
    }


    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        if (value && (typeof value !== "string")) {
            return value
        } else {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };


    componentDidMount() {
        this.getBankName().then((response) => {
            if (this.props.update === true) {
                for (let item of jsonFormTwo) {
                    if (item.varName === "routingNo") {
                        item.readOnly = true;
                    }
                }
                let inputData = {};
                inputData.bankName = this.findByVarNameGetKeyValue(this.props.rowData.bank_name, this.state.bankName);
                inputData.branchName = this.props.rowData.branch_name;
                inputData.distName = this.props.rowData.district_name;
                inputData.routingNo = this.props.rowData.routing_number;
                inputData.isActive = this.props.rowData.status_code;
                this.setState({
                    varValue: inputData,
                    inputData: inputData,
                    showValue: true,
                    loading: false,
                    getData: true,
                });
            } else {
                this.setState({
                    getData: true,
                    loading: false,
                });
            }
        }).catch((error) => {
            this.setState({
                loading: false
            })
        });
    }

    getBankName = () => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/finMasterData/custom/otherBanks/all";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let field of jsonForm) {
                        if (field.varName === "bankName") {
                            field.enum = response.data
                        }
                    }
                    this.setState({
                        bankName: response.data
                    });
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false,
        });
    };

    copyJson = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === " " ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    handleSubmit = () => {
        let formArray = this.props.update === true ? jsonFormTwo : jsonForm;
        let error = MyValidation.defaultValidation(formArray, this.state);
        this.forceUpdate();
        if (error === true) {
            return false;
        } else {
            this.setState({
                loading: true,
            });
            let url;
            if (this.props.update === true) {
                url = backEndServerURL + "/finBankBranchMasterData/updateById";
            } else {
                url = backEndServerURL + "/finBankBranchMasterData/create";
            }
            axios
                .post(url, this.copyJson(this.state.inputData), {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    this.setState({
                        loading: false,
                        alert: true,
                        title: "Success",
                        notificationMessage: "Data Save Successful!",
                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true,
                    });
                });
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        });
    };

    renderHeader = () => {
        return (
            <h4 style={{color: "white"}} >
                {this.props.update === true ? "Branch Update" : "Branch Add"}
                <a>
                    <CloseIcon
                        onClick={this.props.closeModal}
                        style={{
                            position: "absolute",
                            right: 10,
                            color: "#000000",
                        }}
                    />
                </a>
            </h4>
        );
    };

    renderForm = () => {
        let formArray = this.props.update === true ? jsonFormTwo : jsonForm;
        if (this.state.getData) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        formArray,
                        this.updateComponent
                    )}
                    {this.renderNotification()}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={this.handleSubmit}
                        style={{
                            height: 25,
                            marginTop: 20,
                        }}
                    >
                        Submit
                    </button>
                </>
            );
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>{this.renderHeader()}</CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}
                        >
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>{this.renderForm()}</ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(BeneficiaryAdd);
