import React from "react";
import FormSample from '../JsonForm/FormSample';
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [

        {
            "varName": "dropdownCountryName",
            "type": "dropdownSearch",
            "label": "Country Name",
        },
        {
            "varName": "stateName",
            "type": "text",
            "label": "State Name",
            "required": true,

        },


        {
            "varName": "status",
            "type": "select",
            "label": "status",
            "required": false,
            "select": true,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]


        },

    ],

};


class StateAddEditDelete extends React.Component {


    renderEditForm = (() => {
        if (this.state.getSubSectorData) {
            return (
                <FormSample dropdownSearchData={this.state.dropdownSearchData} showValue={true}
                            secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} varValue={this.state.varValue} grid="12"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        } else if (this.props.id === undefined) {

            return (
                <FormSample dropdownSearchData={this.state.dropdownSearchData}
                            secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} grid="12"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        console.log(props.id);
        this.state = {
            showValue: true,
            varValue: [],
            countryId: '',
            countryIdShow: false,
            dropdownSearchData: [],
            countryNameANdId: [],
            getSubSectorData: false,
            getStateData: false,
            redirectLogin: false,
        }
    }

    componentDidMount() {
        const getCountry = [];
        let dropdownData = [];
        let url1 = backEndServerURL + "/countryMaster/getAll";
        axios.get(url1, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = [];
                console.log(response.data);
                response.data.map((country) => {
                    getCountry.push(country.id, country.countryName);

                    let object = {
                        "label": country.countryName,
                        "value": country.id
                    };
                    data.push(object);

                });


                dropdownData["dropdownCountryName"] = data;

                this.setState({
                    dropdownSearchData: dropdownData,
                    countryNameANdId: data,
                    getStateData: true
                })
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

        if (this.props.id !== undefined) {

            let url = backEndServerURL + "/stateMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let varValue = [];
                    varValue["stateName"] = response.data.stateName;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getSubSectorData: true

                    })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    getSubmitedForm = (object) => {


        if (this.props.id !== undefined) {

            let url = backEndServerURL + "/stateMaster/update/" + this.props.id;
            axios.post(url, {

                "countryId": object.dropdownCountryName,
                "stateName": this.firstLetterUpperCase(object.stateName),
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.onUpdate()

                })
                .catch((error) => {
                    console.log(error);
                })


        } else {
            let url = backEndServerURL + "/stateMaster/add";
            axios.post(url, {
                "countryId": object.dropdownCountryName,
                "stateName": this.firstLetterUpperCase(object.stateName),
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.onAdd()
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>

                                {this.renderEditForm()}
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(StateAddEditDelete);
