import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import FileMapping from "../../CommonComponent/FileMapping";
import FileMappingReadOnly from "../../CommonComponent/FileMappingReadOnly";

let arrayListNonIndividual = [
    {label: 'AM01'},
    {label: 'AM02'},
    {label: 'E-TIN'},
    {label: 'CUSTOMER DECLARATION'},
    {label: 'OLD FDR RECEIPT'},
    {label: 'UNUSED CHEQUE BOOK'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'TRADE LICENSE'},
    {label: 'CERTIFICATE OF INCORPORATE'},
    {label: 'CERTIFICATE OF COMMENCEMEN'},
    {label: 'CONTACT POINT VERIFICATION'},
    {label: 'OFFICE ID'},
    {label: 'MANDATE PHOTO ID'},
    {label: 'MANDATE IIF'},
    {label: 'MANDATE SIGNATURE CARD'},
    {label: 'NOMINEE PHOTO ID'},


];

class PreviewMappingImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            uploadButtonClick: false,
            uploadModal: false,
            getMappingAllImage: false,
            fileUploadData: {}
        }
    }


    componentDidMount() {

        console.log(this.props.appId)

    }


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
        })
    };

    renderAssignedImage = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"

                onClick={this.mappingAllImage}

            >
                Preview Document.
            </button>
        )
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };

    renderMappingImageModal = () => {

        return (<FileMapping
                dropDownOption={arrayListNonIndividual}
                appId={this.props.appId}
                closeModal={this.closeUploadModal}/>
        )

    };
    renderMappingImageEditModal = () => {


        return (<FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
        )

    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };

    render() {
        return (

            <React.Fragment>
                {
                    this.renderAssignedImage()
                }
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        {this.renderMappingImageEditModal()}

                    </DialogContent>
                </Dialog>

            </React.Fragment>

        );
    }


}

export default PreviewMappingImage;