import React, {Component} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import ErrorModal from "../Common/ErrorModal";
import SignatureButton from "../../Maintenance/SignatureButton.jsx";
import {LoanAccountChangeCsoForm} from "../../WorkflowjsonFormForAssetOps";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import Label from "../Common/Label";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import AssetCommonFunctions from "../AssetCommonFunctions";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class CsoRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            submitButtonClick: false,
            errorModal: false,
            actionType: "",
            confirmAlert: false,
            submission: false
        };

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    componentDidMount() {

        if (this.props.appId !== undefined) {

            let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    let remarksArray = [];
                    let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true,

                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true,
                                imageName: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        varValue: this.copyJson(response.data),
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        loading: false,
                        submission: true,
                    });

                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = assetOpsBackEndServerURL + "/startCase/cs_case_start_emi_date_change";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    this.setState({
                        getData: true,
                        showValue: true,
                        appId: response.data.id,
                        loading: false
                    });


                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };


    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.state.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true,

        });
        if (status === "success" || status === "No File Found") {

            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
            }
            let postData = this.state.inputData;
            postData.category = "Loan Account";
            postData.subCategory = "Tenor Change";

            if (this.state.inputData.credit_analyst_checked === "YES") {
                postData.credit_mis = "NO";
                postData.asset_ops_maker = "YES";
            } else {
                postData.credit_mis = "YES";
                postData.asset_ops_maker = "NO";
            }

            postData.serviceType = "Loan AC Tenor Change Request";
            postData.subServiceType = "Loan Account Tenor Change";
            saveDataDeferralRemarksAndRoute.map = postData;
            let caseRouteUrl =
                assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios
                .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: response.data,
                        alert: true,
                        getData: false,
                        loading: false,
                    });
                    if (this.props.appId === undefined) {
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.props.closeModal();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });


        } else {
            this.setState({
                errorModal: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    closeErrorModal = () => {
        this.setState({
            errorModal: false
        })
    };

    saveHandler = () => {
        this.setState({
            submitButtonClick: true
        });

    };


    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Loan Account Tenor Change</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== undefined ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""} <b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Loan Account Tenor
                </h4>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = LoanAccountChangeCsoForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                LoanAccountChangeCsoForm[i].enum = getValue;
            }
        }


    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.rateOfpfCBApiCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;
            inputData.casaAccount=undefined;

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        showValue: true,
                        submission: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        submission: false,

                    })
                })
        }

    }

    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    onKeyDownChange = (data) => {
        console.log(this.state.inputData)

        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.cbNumber=this.state.inputData.cbNumber;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
        }
        else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            let postData = {
                "loanAccounts": this.state.inputData[data.varName],
                "cbNumber": this.state.inputData.cbNumber,
                "loanAccountDetails": "Y",
                "loanAccountInfo": "Y",
                "getCustomAcctDetails": "Y",
            };
            AssetCommonFunctions.getCustLoanAccountDetails(postData)
                .then((custLoanAccountDetailsResponse) => {
                    inputData.productName = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                    inputData.casaAccount = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.operAcctId;
                    inputData.loanOutstanding = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.closingBal;

                    let originalTenureMonths = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.originalTenureMonths);
                    let tenureBalanceYears = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.tenureBalanceYears);
                    let tenureBalanceMonths = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.tenureBalanceMonths);
                    let remainingTenor = tenureBalanceYears * 12 + tenureBalanceMonths;
                    inputData.remainingTenor = remainingTenor;
                    inputData.emiCompleted = originalTenureMonths - remainingTenor;

                    inputData.accountNumber = this.state.inputData[data.varName];
                    inputData.casaAccountStatus = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctStatus;
                    inputData.fundAvailability = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctBalance;

                    inputData.loanAccountsCBApiCall = "YES";
                    inputData.showCasaDetails = "YES";

                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        showValue: true,
                        submission: true,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }
    }

    renderJsonFrom = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, LoanAccountChangeCsoForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        }
    }
    closeConfirmAlert = (data, appId) => {


        this.setState({

            confirmAlert: false,

        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "saveHandler") {

            this.saveHandler()
        }
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "saveHandler") {
            let error = MyValidation.defaultValidation(LoanAccountChangeCsoForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    {this.state.submission && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={(event) => this.handleCommon(event, "saveHandler")}
                    >
                        Submit
                    </button>}
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginLeft: "10px"
                        }}
                        onClick={this.printLoanClosingForm}
                    >
                        Print
                    </button>
                </div>
            )
        }
    }
    printLoanClosingForm = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        inputData.printingCategory="loanAccountTenorChange";

        let url = assetOpsBackEndServerURL + "/getOtherServiceRequestForm";
        functionForFileDownload.downloadFile(url, "POST", inputData, "Other_Service_Request_Form.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }


    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;


        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <ThemeProvider theme={theme}>

                                    {this.renderJsonFrom()}
                                    <br/>
                                    {this.renderCustomerDetails()}
                                    {this.state.inputData.casaAccount !== undefined && this.state.inputData.casaAccount !== null ?
                                        <SignatureButton accountNumber={this.state.inputData.casaAccount}
                                                         signatureType="multiple"
                                                         classes={this.props}/> : ""}

                                    <Grid item xs={12}></Grid>
                                    {this.mappingPhoto()}
                                    <br/>
                                    {this.renderDynamicFileUpload()}
                                    <Grid item xs={12}>
                                        <br/>
                                        {(this.props.flowSummery === true) && this.renderFlowSUmmeryButton()}
                                    </Grid>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModal}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <ErrorModal
                                                closeModal={this.closeErrorModal}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.confirmAlert}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                          closeModal={this.closeConfirmAlert}

                                            />

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}

                                    <Grid item xs={12}>

                                    </Grid>

                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            {this.renderSubmitButton()}
                        </Grid>
                        {
                            this.renderNotification()
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}


export default withStyles(styles)(CsoRequisition);
