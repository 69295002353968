import React from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import FlowSummeryButton from "./FlowSummeryButton";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Table from "../../Table/Table";
import WithoutDedupeComponent from "./WithoutDedupeComponent";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import DedupResultFunction from "../../DedupResultFunction";
import DedupeResultFunctionForAgentBanking from "../../DedupeResultFunctionForAgentBanking";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import CardHeader from "../../Card/CardHeader";
import Functions from "../../../Common/Functions";
import GridList from "@material-ui/core/GridList";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import ErrorModal from "./ErrorModal";
import ConfirmAlert from "./ConfirmAlert";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import MakerCumInput from "../CASA/MakerCumInput";
import ImageCrop from "../CASA/ImageCrop";
import AssignedCropImage from "../CASA/AssignedCropImage";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CloseIcon from "@material-ui/icons/Close";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import GridContainer from "../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import CommonApi from "../Maintenance/common/CommonApi";
import CBNotFound from "../CASA/CBNotFound";

let day = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",

];
let taggingForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
        "length": 9,


    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,


        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,


    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status as on Date",
        "grid": 6
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "AC Manager",
        "grid": 6,


    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,


        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,


        required: true,
    },

    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "text",
        "label": "Staff Flag",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,


        required: true,
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,


    },

    {
        "varName": "nonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,


        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,


        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National ID Card",
        "grid": 6,


        required: true,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of Birth",
        "grid": 6,


    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer Id",
        "grid": 6,
        "length": 9,

        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,


    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,


    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father",
        "grid": 6,


        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,


        required: true,
    },

    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "grid": 6,


        required: true,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address1",
        "grid": 6,


    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address2",
        "grid": 6,


    },

    {
        "varName": "city1",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country1",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,
        "length": 11,

        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address1",
        "grid": 6,


    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address2",
        "grid": 6,


    },

    {
        "varName": "city2",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country2",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo222",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address1",
        "grid": 6,


    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address2",
        "grid": 6,


    },

    {
        "varName": "city3",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,


    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,


    },

    {
        "varName": "tds",
        "type": "text",
        "label": "TDS",
        "grid": 6,


    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PANGIR No",
        "grid": 6,


    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,


    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,


    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,


        required: true,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,


    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,


    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text2",
        "grid": 6,


    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,


    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,


    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text13",
        "grid": 6,


    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text14",
        "grid": 6,


    },

    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text15",
        "grid": 6,


    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code1",
        "grid": 6,


    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code3",
        "grid": 6,


    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,


    },
];
let objectForNominee = [];
let objectForNomineeForNew = [];
let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12
    }];
const numberOfNominee = [
    {title: "1"},
    {title: "2"},
    {title: "3"},
    {title: "4"},
    {title: "5"},
    {title: "6"},
    {title: "7"},
    {title: "8"},
    {title: "9"},
    {title: "10"},
    {title: "11"},
    {title: "12"},
    {title: "13"},
    {title: "14"},
    {title: "15"},
    {title: "16"},
    {title: "17"},
    {title: "18"},
    {title: "19"},
    {title: "20"}


];

let jsonFormForCB = [
/*    {
        type: "empty",
        grid: 12
    },
    {
        varName: "nid",
        type: "file",
        label: "Upload File",
        // accept: "",
        grid: 2,
    },*/

    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         "label": "Primary Sol ID",
         "grid": 6,
         "required": true,
     },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required": true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,


    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy1",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly":true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly":true,
         "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
   /* {
        "varName": "aof1currencyCodeCcy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "New Currency Code/CCY",
        "grid": 6,
        "required": true,

        //"readOnly":true,
    },*/
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly": true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB": "length",
        "maxLength": 50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue": "MARID",
        "validationB": "length",
        "maxLength": 240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly": true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName": "etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },


    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required": true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "addressType",
                "key": "NRERelative",
                "value": "NRERelative"
            }
        ],
        "label": "Addrss type",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "label": "Address label",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },


];
let jsonFormForAccount = [
/*    {
        type: "empty",
        grid: 12
    },
    {
        varName: "nid",
        type: "file",
        label: "Upload File",
        // accept: "",
        grid: 2,
    },*/
    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    /* {
         "varName": "fincoreDataUpdateaCReportCode",
         "type": "text",
         "label": "A/C Report Code",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecustomerRelationshipACManager",
         "type": "text",
         "label": "Customer Relationship A/C Manager",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdateaCManagerId",
         "type": "text",
         "label": "A/C Manager ID",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecontactPhoneNo",
         "type": "text",
         "label": "Contact Phone No.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [
            "S-STATEMENT",
            "NONE",
        ],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "grid":12,
     },

    {
        "type": "title",
        "label": "Statement Frequency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "enum": day,
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },

    /*{
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },*/

    /*  {
          "varName": "fincoreDataUpdatenextPrintDate",
          "type": "date",
          "label": "Next Print Date",
          "grid": 6,
      },*/

    /* {
         "varName": "fincoreDataUpdateinterestCreditAC",
         "type": "text",
         "label": "Interest Credit A/c.",
        "grid": 6,
        "required": true,
     },*/

    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
          "type": "text",
          "label": "Withholding Tax Borne By",
         "grid": 6,
        "required": true,
      },
  */
    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxLevel",
          "type": "text",
          "label": "Withholding Tax Level",
         "grid": 6,
        "required": true,
      },*/

    /* {
         "varName": "fincoreDataUpdatewithholdingTaxPcnt",
         "type": "text",
         "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "accountStatus",
                "key": "A",
                "value": "Active"
            },
            {
                "masterDataName": "accountStatus",
                "key": "D",
                "value": "Dormant"
            },
            {
                "masterDataName": "accountStatus",
                "key": "I",
                "value": "Inactive"
            },
        ],
        "label": "A/C Status",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    /* {
         "varName": "fincoreDataUpdatenomineeSelect",
         "type": "text",
         "label": "Nominee Select",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateaCInformation",
         "type": "text",
         "label": "A/c. Information ",
         "grid": 6,
     },
 */
    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code",
        "grid": 6,
    },
    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName",
        "type": "text",
        //rm code
        "label": "RM Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Deposit Code/Free Code 7",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    }


];
let jsonFormForCbAndAccount=[
/*    {
        type: "empty",
        grid: 12
    },
    {
        varName: "nid",
        type: "file",
        label: "Upload File",
        // accept: "",
        grid: 2,
    },*/
    {
        "type": "title",
        "label": "MIS RELATED",
        "grid": 12,
    },

    /* {
         "varName": "misprimarySolId",
         "type": "text",
         "label": "Primary Sol ID",
         "grid": 6,
         "required": true,
     },*/


    {
        "varName": "miscustomerIdCifId",
        "type": "text",
        //"required": true,
        "label": "Customer ID/CIF ID",
        "grid": 6,
        "readOnly": true,
        // "required": true,
    },


    {
        "varName": "mistitle",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //mistitle=inputData.customerTitle
        "label": "Title",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "miscustomerNameLastName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 80,
        //inputData.miscustomerNameLastName=inputData.customerName;
        "label": "Customer Name/Last Name",
        "grid": 6,
    },

    {
        "varName": "misshortName",
        "type": "text",
        "required": true,
        "validationB": "length",
        "maxLength": 10,
        //inputData.misshortName=inputData.shortName;

        "label": "Short Name",
        "grid": 6,
    },

    /*  {
          "varName": "misgender",
          "type": "select",
          //"required": true,
          "enum": [
              "M",
              "F",
              "TRANSGENDER",
              "OTHER"
          ],
          "label": "Gender",
          "grid": 6,
          "required": true,
      },*/

    {
        "varName": "miscustomerStatusNormalStaffPriorityEtc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer Status",
        //inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.customerStatus
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misoccupationCodePriorityCodeStaff",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
        "required": true,
        //
        /*  if(this.state.inputData.occupationCodeNormal!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeNormal
      }
      else if(this.state.inputData.occupationCodePriority!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodePriority
      }
      else if(this.state.inputData.occupationCodeStaff!==undefined){
      inputData.miscustomerStatusNormalStaffPriorityEtc=inputData.occupationCodeStaff
      }*/
    },

    {
        "varName": "misconstitution",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //master data cbl provide
        "label": "Constitution",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "misstaffIndicatorStatus",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "label": "Staff Indicator/Status",
        //inputData.misstaffIndicatorStatus=inputData.staffIndicator
        "grid": 6,


    },

    {
        "varName": "misstaffIdNumber",
        "type": "text",
        "label": "Staff ID Number",
        "validation": "staffId",
        //inputData.misstaffIdNumber=inputData.staffIdNumber
        "grid": 6,

    },

    {
        "varName": "mispriorityCenterCodeFreeCode1",
        "type": "select",
        "enum": [
           "PR104",
            "PR142",
            "PR177",
            "PR186",
            "PR187",
            "PR195",
            "PR208",
            "PR169",
        ],
        //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
        "label": "Priority Center Code/Free Code 1",
        "grid": 6,
    },

    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3",
        "type": "textApiCall",
        "enum": [],
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code",
        "grid": 6,
    },
    {
        "varName": "mispriorityMonitoringRmCodeFreeCode3Name",
        "type": "text",
        //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
        "label": "Priority Monitoring  RM Code Name",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "mispriorityMonitoringRmCodeFreeCode3ApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },

    {
        "varName": "misprimaryCbHolderForPriorityFreeText8",
        "type": "text",
        //inputData.misprimaryCbHolderForPriorityFreeText8=inputData.primaryCBHolderforPriority
        "label": "Primary CB Holder for Priority",
        "grid": 6,
    },

    {
        "varName": "misrelationshipWithPrimaryCbHolderFreeText9",
        "type": "text",
        //inputData.misrelationshipWithPrimaryCbHolderFreeText9=inputData.relationshipwithPrimaryCBHolder;
        "label": "Relationship with Primary CB Holder",
        "grid": 6,
    },


    {
        "varName": "misbbkSegmentCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "BBK Segment Code",
        "grid": 6,
        "required": true,
    },


    {
        "varName": "mismigratedNidVerificationFreeText2",
        "type": "text",
        "label": "Migrated NID Verification",
        "grid": 6,
    },

    {
        "varName": "mismigratedSmartCardNumberFreeText5",
        "type": "text",
        "label": "Migrated Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "mispepStatusFreeText10",
        "type": "text",
        "label": "PEP Status",
        "grid": 6,
    },

    {
        "varName": "mismigratedCifsDocumentNoFreeText13",
        "type": "text",
        "label": "Migrated CIFs Document No",
        "grid": 6,
    },


    {
        "varName": "misfreeCodeFreeText1",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText2",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misfreeCodeFreeText3",
        "type": "text",
        "label": "Free Code",
        "grid": 6,
    },

    {
        "varName": "misavailedTradeServices",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Availed Trade services",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Currency Code",
        "grid": 12,
    },

    {
        "varName": "aof1currencyCodeCcy1",
        "type": "text",
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly":true,
        //"readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": true,
    },
    {
        "varName": "aof1currencyCodeCcy1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.aof1currencyCodeCcy=inputData.currency
        "label": "Existing Currency Code/CCY",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "aof1currencyCodeCcyExist",
        "conditionalVarValue": false,
        //"readOnly":true,
    },
    /*  {
          "varName": "aof1currencyCodeCcy",
          "type": "autoCompleteValueReturn",
          "enum": [],
          //inputData.aof1currencyCodeCcy=inputData.currency
          "label": "New Currency Code/CCY",
          "grid": 6,
          "required": true,

          //"readOnly":true,
      },*/
    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },
    {
        "varName": "contactNumberEmailpreferedEmailIdType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "readOnly": true,
        "label": "Prefered Email ID type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "COMMEML",
            "value": "COMMEML"
        }],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId4",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1",
        "type": "text",
        "label": "Designated Email Address/Email ID 1",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmaildesignatedEmailAddressEmailId1Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,


    },
    {
        "type": "title",
        "label": "Contact Number - Phone",
        "grid": 12,
    },
    {
        "varName": "contactNumberpreferredContactNoType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Preferred Contact no type",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 1",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH1",
            "value": "COMMPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "contactNumberphoneNoEmailId1",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No./Email ID",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "mobileTypecontactNumberphoneNo1",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType1",
        "conditionalVarValue": "COMMPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo1",
        "conditionalVarValue": "Overseas",

    },


    {
        "type": "title",
        "label": "Primary Introducer details",
        "grid": 12,
    },
    {
        "varName": "nidVerified",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "required": true,
        "label": "Is All NID/Smart Card Document Verified?",
        "grid": 6,

    },
    {
        "varName": "primryintroducerCustomerIdCifId",
        "type": "textApiCall",
        //inputData.primryintroducerCustomerIdCifId=inputData.introducerId
        "label": "Introducer Customer Id/CIF ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },


    {
        "varName": "primryiintroducerNameLastName",
        "type": "text",
        //inputData.primryiintroducerNameLastName=inputData.introducerName
        "label": "Introducer Name/Last Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["introducerIdApiCall", "nidVerified"],
        "conditionalArrayValue": [true, "NO"]

    },

    {
        "varName": "primryiintroducerStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Introducer Status",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },

    {
        "varName": "primryicifTypePrimaryIntroducerDetails",
        "type": "select",
        "enum": [
            "Retail",
            "Corporate",
        ],
        "label": "CIF Type/Primary Introducer Details",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nidVerified",
        "conditionalVarValue": "NO",
    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "crmMissubSegment",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Sub Segment",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Basic Information Update",
        "grid": 12,
    },
    {
        "varName": "basicInformationUpdateprimarySolId",
        "type": "text",
        "enum": [],
        "readOnly": true,
        "label": "Primary Sol ID",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdategender",
        "type": "select",
        "enum": [
            "M",
            "F",
            "TRANSGENDER",
            "OTHER"
        ],
        "label": "Gender",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatefatherNamePrefferedName",
        "type": "text",
        "label": "Father Name/Preffered Name",
        "validationB": "length",
        "maxLength": 50,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdatemotherNameMothersMaidenName",
        "type": "text",
        "label": "Mother Name/Mothers Maiden Name",
        "grid": 6,
        "validationB": "length",
        "maxLength": 50,
    },

    {
        "varName": "basicInformationUpdatemaritalStatus",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Marital Status",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "basicInformationUpdatespouseNamePurgeRemarks",
        "type": "text",
        "label": "Spouse Name/Purge Remarks",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "basicInformationUpdatemaritalStatus",
        "conditionalVarValue": "MARID",
        "validationB": "length",
        "maxLength": 240,

    },

    {
        "varName": "basicInformationUpdatedob",
        "type": "date",
        "maxDate": true,
        "onKeyDown": true,
        //inputData.basicInformationUpdatedob=inputData.gender
        "label": "DOB",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "basicInformationUpdateminorStatusMinorIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N"
        ],
        "readOnly": true,
        "label": "Minor Status/Minor Indicator",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateseniorCitizenIndicator",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Senior Citizen Indicator",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdatedobApiCall",
        "conditionalVarValue": "YES",
        "required": true,
    },
    {
        "type": "title",
        "label": "Gurdian Details - Only Visible In Case Minor Account Holder",
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",

        "grid": 12,
    },


    {
        "varName": "guardianDetailsrelation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsbankRelationType",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Bank Relation Type *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsrelation1",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Relation *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsguardianSelectionGurdian",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Guardian Selection/Gurdian",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailsgurdianCifNumberCustomer",
        "type": "textApiCall",
        "label": "Gurdian CIF Number/Customer *",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
        "conditionalVarValue": "Y",
    },

    {
        "varName": "guardianDetailslastName",
        "type": "text",
        "enum": [],
        "label": "Last Name *",
        "grid": 6,
        "required": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "guardianDetailsgurdianCifNumberCustomerApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "type": "title",
        "label": "National ID Card",
        "grid": 12,
    },
    {
        "varName": "natioanlIdCarddocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "natioanlIdCarddocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        }],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "nationalIdCardNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "National ID Card Number/Unique ID",
        "grid": 6,
        "validation": "nid",
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardnidVerificationConfirmationIsDocumentVerified1",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "varName": "natioanlIdCardissueDateN",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },
    {
        "varName": "natioanlIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "natioanlIdCarddocumentCodeN",
        "conditionalVarValue": "NID"
    },

    {
        "type": "title",
        "label": "Smart ID Card",
        "grid": 12,
    },
    {
        "varName": "smartIdCarddocumentTypeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentTypeDescriptionS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "smartIdCarddocumentCodeS",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }],
        "label": "Document Code ",
        "grid": 6,
        //"required": true,
    },

    {
        "varName": "smartCardNumberUniqueId",
        "type": "text",
        "label": "Smart Card Number/Unique ID",
        "grid": 6,
        //"required": true,
        "validation": "smartCard",
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"

    },

    {
        "varName": "smartIdCardnidVerificationConfirmationIsDocumentVerified",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },

    {
        "varName": "smartIdCardissueDateS",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "varName": "smartIdCardDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "smartIdCarddocumentCodeS",
        "conditionalVarValue": "RSNID"
    },
    {
        "type": "title",
        "label": "Passport Number",
        "grid": 12,
    },
    {
        "varName": "passportNumberdocumentTypeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentTypeDescriptionP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        //inputData.passportNumberdocumentTypeDescriptionP=inputData.passport
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "passportNumberdocumentCodeP",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "PASSPORT",
            "value": "PASSPORT IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumberpassportNumberUniqueId",
        "type": "textApiCall",
        "label": "Passport Number/Unique ID",
        "grid": 6,
        "validation": "passport",
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberplaceOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Place of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberissueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumbercountryOfIssue",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country of Issue",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },

    {
        "varName": "passportNumberexpiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "varName": "passportNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "passportNumberdocumentCodeP",
        "conditionalVarValue": "PASSPORT"
    },
    {
        "type": "title",
        "label": "Driving License",
        "grid": 12,
    },
    {
        "varName": "drivingLicensedocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "drivingLicensedocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "DL",
            "value": "DRIVING LICENSE"
        },],
        "label": "Document Code",
        "grid": 6,

    },

    {
        "varName": "drivingLicenseNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Driving ID Card Number/Unique ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },
    {
        "varName": "drivingLicenseNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "drivingLicensedocumentCodeN",
        "conditionalVarValue": "DL"
    },

    {
        "type": "title",
        "label": "Resident ID",
        "grid": 12,
    },
    {
        "varName": "residentdocumentTypeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentTypeDescriptionN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "residentdocumentCodeN",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "RESID",
            "value": "RESIDENT ID CARD"
        },],
        "label": "Document Code ",
        "grid": 6,

    },

    {
        "varName": "residentNumberUniqueId",
        "type": "text",
        //inputData.nationalIdCardNumberUniqueId=inputData.nid
        "label": "Resident ID",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "varName": "residentNumberDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "residentdocumentCodeN",
        "conditionalVarValue": "RESID"
    },
    {
        "type": "title",
        "label": "Birth Certificate",
        "grid": 12,
    },
    {
        "varName": "birthCertificatedocumentTypeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentTypeDescriptionB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "birthCertificatedocumentCodeB",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "BC",
            "value": "BIRTH CERTIFICATE"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificatebirthCertificateNumberUniqueId",
        "type": "text",
        "label": "Birth Certificate Number/Unique ID",
        //inputData.birthCertificatebirthCertificateNumberUniqueId=inputData.birthCertificateNo
        "grid": 6,
        "validation": "birthCertificateNumber",
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "varName": "birthCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "birthCertificatedocumentCodeB",
        "conditionalVarValue": "BC"
    },
    {
        "type": "title",
        "label": "Chairman Certificate",
        "grid": 12,
    },
    {
        "varName": "chairmanCertificatedocumentTypeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentTypeDescriptionCC",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "chairmanCertificatedocumentCodeCC",
        "type": "autoCompleteValueReturn",
        "enum": [{"masterDataName": "documentCode", "key": "CHAC", "value": "CHAIRMAN CERTIFICATE"}],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "chairmanCertificateUniqueId",
        "type": "text",
        "label": "Chairman Certificate/Unique ID",
        "grid": 6,
        "validationB": "length",
        "maxLength": 20,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },
    {
        "varName": "chairmanCertificateDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatedocumentCodeCC",
        "conditionalVarValue": "CHAC"
    },


    {
        "type": "title",
        "label": "Other Photo ID",
        "grid": 12,
    },


    {
        "varName": "photoIddocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Type Description",
        "grid": 6,
    },

    {
        "varName": "photoIddocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "photoIdUniqueId",
        "type": "text",
        "label": "Unique ID",
        "grid": 6,
    },
    {
        "varName": "photoIdDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 12,

    },
    {
        "varName": "chairmanCertificateplaceOfBirthFreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,

    },

    {
        "varName": "chairmanCertificatecountryOfBirthFreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,

    },

    {
        "varName": "Customersnationality",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Nationality",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificatenonResident",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Resident",
        "grid": 6,
        "required": true,

    },

    {
        "varName": "chairmanCertificateturnedNonResidentOn",
        "type": "date",
        "label": "Turned Non Resident On",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "type": "title",
        "label": "E-Tin",
        "grid": 12,
    },
    {
        "varName": "etindocumentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentTypeDescription",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCodeDocumentType",
            "key": "IDENTIFICATION PROOF",
            "value": "IDENTIFICATION PROOF"
        }],
        "label": "Document Type Description",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "etindocumentCode",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "documentCode",
            "key": "ETIN",
            "value": "TIN CARD IDENTITY"
        }],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "etineTinNumber",
        "type": "text",
        //inputData.etineTinNumber=inputData.eTin
        "label": "E-Tin Number/Unique ID",
        "grid": 6,
        "validation": "etin",
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "varName": "etinDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "etindocumentCode",
        "conditionalVarValue": "ETIN"
    },
    {
        "type": "title",
        "label": "Employer Data",
        "grid": 12,
    },
    {
        "varName": "employerDataprofessionEmploymentType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "employmentType",
            "key": "MEDIA CELEBRITY",
            "value": "MEDIA CELEBRITY"
        }],
        "label": "Profession /Employment Type",
        "grid": 6,
    },


    {
        "varName": "employerDatanameOfEmployerEmployerName",
        "type": "text",
        "label": "Name of Employer/Employer Name",
        "grid": 6,
    },

    {
        "varName": "employerDatamonthlyIncomeGrossIncome",
        "type": "text",
        "label": "Monthly Income/Gross income",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 2",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "COMMPH2",
            "value": "COMMPH2"
        }],
        "label": "Contact Type",
        "grid": 6,

    },

    {
        "varName": "contactNumberphoneNoEmailId2",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "varName": "mobileTypecontactNumberphoneNo2",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo2",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo2",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumberphoneNo2",
        "conditionalVarValue": "Overseas",

    },
    {
        "varName": "contactNumber2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType2",
        "conditionalVarValue": "COMMPH2"
    },
    {
        "type": "title",
        "label": "Contact Number - Phone 3",
        "grid": 12,
    },
    {
        "varName": "contactNumbercontactType3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "contactType",
            "key": "WORKPH1",
            "value": "WORKPH1"
        }],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberphoneNoEmailId3",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "PHONE",
            "value": "PHONE"
        }],
        "label": "Phone No.",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    /*  {
          "varName": "contactNumberphoneNo3",
          "type": "text",
          "validation": "numeric",
          "label": "Phone No",
          "grid": 6,
          "required": true,
          "masterData": true,
          "conditionalVarName": "contactNumbercontactType3",
          "conditionalVarValue": "WORKPH1"
      },*/

    {
        "varName": "mobileTypecontactNumbercontactType3",
        "type": "select",
        "enum": [
            "Local",
            "Overseas",
        ],
        "required": true,
        "label": "Mobile Type ",
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1",
        "grid": 6,
    },
    {
        "varName": "countryCodecontactNumberphoneNo3",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country Code",
        "asteriks": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",
        "grid": 6,
    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "phonePrefix": true,
        "validation": "phone",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Local",

    },
    {
        "varName": "contactNumberphoneNo3",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "mobileTypecontactNumbercontactType3",
        "conditionalVarValue": "Overseas",

    },


    {
        "varName": "contactNumber3Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "contactNumbercontactType3",
        "conditionalVarValue": "WORKPH1"
    },

    {
        "type": "title",
        "label": "Contact Number - Email",
        "grid": 12,
    },

    {
        "varName": "contactNumberEmailemailType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "emailType",
            "key": "HOMEEML",
            "value": "HOMEEML"
        }],
        "required": true,
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailphoneNoEmailId",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "phoneOrEmailId",
            "key": "EMAIL",
            "value": "EMAIL"
        }],
        "label": "Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumberEmailemailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },
    {
        "varName": "contactNumberEmailemailId2Delete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "required": true,
        "label": "Delete Flag",
        "grid": 6,
    },

    {
        "type": "title",
        "label": "Mailing Address",
        "grid": 12,
    },
    {
        "varName": "maillingAdresspreferredAddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        //inputData.maillingAdresspreferredAddressType=inputData.SelectCommunicationAddress

        "label": "Preferred Address type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        "label": "Addrss type",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Mailing",
            "value": "Mailing"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField1",
        "type": "text",
        //inputData.maillingAdressmailingAddressField1=inputData.communicationAddress1
        "label": "Mailing Address Field 1",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressmailingAddressField2",
        "type": "text",
        //inputData.maillingAdressmailingAddressField2=inputData.communicationAddress2
        "label": "Mailing Address Field 2",
        "validationB": "length",
        "maxLength": 45,
        "grid": 6,
    },

    {
        "varName": "maillingAdresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscity=inputData.city2

        "label": "City",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdressstate=inputData.state2

        "label": "Division(State)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        //inputData.maillingAdresspostalCode=inputData.postalCode3
        "grid": 6,
        "required": true,
    },

    {
        "varName": "maillingAdresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //inputData.maillingAdresscountry=inputData.nationality
        "label": "Country",
        "grid": 6,
        "required": true,
    },

    {
        "type": "title",
        "label": "Home Address",
        "grid": 12,
    },
    {
        "varName": "homeAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "required": true,
        "label": "Address Format",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        "onKeyDown": true,
        "label": "Address type",
        "grid": 6,

    },

    {
        "varName": "homeAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Home",
            "value": "Home"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,

    },

    {
        "varName": "homeAddresshomeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresshomeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],

        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },

    {
        "varName": "homeAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "varName": "homeAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "homeAddressaddrssType",
        "conditionalVarValue": "Home"
    },
    {
        "type": "title",
        "label": "Work Address",
        "grid": 12,
    },
    {
        "varName": "workAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        "label": "Address type",
        "grid": 6,
    },

    {
        "varName": "workAddressaddressType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Work",
            "value": "Work"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "workAddressworkAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressworkAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "masterData": true,
        "required": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },

    {
        "varName": "workAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "workAddressaddressType",
        "conditionalVarValue": "Work"
    },
    //1present address
    {
        "type": "title",
        "label": "Present Address",
        "grid": 12,
    },
    {
        "varName": "presentAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        "label": "Addrss type",
        "grid": 6,
    },

    {
        "varName": "presentAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "Future/OnSite",
            "value": "Future/OnSite"
        }],
        // "readOnly":true,
        "label": "Address label",
        "grid": 6,
    },

    {
        "varName": "presentAddresspresentAddressField1",
        "type": "text",
        "label": "Present Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"

    },

    {
        "varName": "presentAddresspresentAddressField2",
        "type": "text",
        "label": "Present Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },

    {
        "varName": "presentAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    },
    {
        "varName": "presentAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "presentAddressaddrssType",
        "conditionalVarValue": "Future/OnSite"
    }, //1NRE address
    {
        "type": "title",
        "label": "NRE Address",
        "grid": 12,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    {
        "varName": "nreAddressaddressFormat",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressFormat",
            "key": "FREE_TEXT_FORMAT",
            "value": "FREE_TEXT_FORMAT"
        }],
        "label": "Address Format",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "addressType",
                "key": "NRERelative",
                "value": "NRERelative"
            }
        ],
        "label": "Addrss type",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressaddrssType",
        "type": "autoCompleteValueReturn",
        "enum": [{
            "masterDataName": "addressType",
            "key": "NRERelative",
            "value": "NRERelative"
        }],
        "label": "Address label",
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "nreAddressnreAddressField1",
        "type": "text",
        "label": "NRE Address Field 1",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },

    {
        "varName": "nreAddressnreAddressField2",
        "type": "text",
        "label": "NRE Address Field 2",
        "grid": 6,
        "validationB": "length",
        "maxLength": 45,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscity",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddressstate",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresspostalCode",
        "type": "text",
        "validationB": "length",
        "maxLength": 10,
        "label": "Postal Code",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },

    {
        "varName": "nreAddresscountry",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"

    },
    {
        "varName": "nreAddressDelete",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Delete Flag",
        "grid": 6,
        "required": true,
        "masterData": true,
        "conditionalVarName": "chairmanCertificatenonResident",
        "conditionalVarValue": "Y"
    },
    //account

    {
        "type": "title",
        "label": "Fincore Data Update - Account Level",
        "grid": 12,
    },
    /* {
         "varName": "fincoreDataUpdateaCReportCode",
         "type": "text",
         "label": "A/C Report Code",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecustomerRelationshipACManager",
         "type": "text",
         "label": "Customer Relationship A/C Manager",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdateaCManagerId",
         "type": "text",
         "label": "A/C Manager ID",
        "grid": 6,
        "required": true,
     },*/

    /* {
         "varName": "fincoreDataUpdatecontactPhoneNo",
         "type": "text",
         "label": "Contact Phone No.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdatecashLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatecashLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Cash Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdateclearingLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Clearing Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitDr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Dr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatetransferLimitCr",
        "type": "text",
        "validation": "numeric",
        "label": "Transfer Limit (Cr)",
        "grid": 6,
        "required": true,
    },

    {
        "varName": "fincoreDataUpdatenotes",
        "type": "text",
        "label": "Notes",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdateaccountStatement",
        "type": "select",
        "enum": [
            "S-STATEMENT",
            "NONE",
        ],
        "label": "Account Statement",
        "grid": 6,
        "required": true,
    },
    {
        "type": "title",
        "grid":12,
    },

    {
        "type": "title",
        "label": "Statement Frequency",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency1",
        "type": "select",
        "enum": [
            "Half Yearly",
            "Yearly",
            "Quarterly",
            "Monthly",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency2",
        "type": "select",
        "enum": day,
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },
    {
        "varName": "fincoreDataUpdatestatementFrequency3",
        "type": "select",
        "enum": [
            "Next Day",
            "Holiday",
            "Previous Day",
            "Skip",
        ],
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdateaccountStatement",
        "conditionalVarValue": "S-STATEMENT",
    },

    /*{
        "varName": "fincoreDataUpdatedispatchMode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Dispatch Mode",
        "grid": 6,
    },*/

    /*  {
          "varName": "fincoreDataUpdatenextPrintDate",
          "type": "date",
          "label": "Next Print Date",
          "grid": 6,
      },*/

    /* {
         "varName": "fincoreDataUpdateinterestCreditAC",
         "type": "text",
         "label": "Interest Credit A/c.",
        "grid": 6,
        "required": true,
     },*/

    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxBorneBy",
          "type": "text",
          "label": "Withholding Tax Borne By",
         "grid": 6,
        "required": true,
      },
  */
    /*  {
          "varName": "fincoreDataUpdatewithholdingTaxLevel",
          "type": "text",
          "label": "Withholding Tax Level",
         "grid": 6,
        "required": true,
      },*/

    /* {
         "varName": "fincoreDataUpdatewithholdingTaxPcnt",
         "type": "text",
         "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "required": true,
     },*/

    {
        "varName": "fincoreDataUpdateaCStatus",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "accountStatus",
                "key": "A",
                "value": "Active"
            },
            {
                "masterDataName": "accountStatus",
                "key": "D",
                "value": "Dormant"
            },
            {
                "masterDataName": "accountStatus",
                "key": "I",
                "value": "Inactive"
            },
        ],
        "label": "A/C Status",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedormantActivationCharge",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "label": "Dormant Activation Charge",
        "grid": 6,
    },

    /* {
         "varName": "fincoreDataUpdatenomineeSelect",
         "type": "text",
         "label": "Nominee Select",
         "grid": 6,
     },

     {
         "varName": "fincoreDataUpdateaCInformation",
         "type": "text",
         "label": "A/c. Information ",
         "grid": 6,
     },
 */
    {
        "varName": "fincoreDataUpdatesectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "onKeyDown": true,
        "required": true,
        //inputData.fincoreDataUpdatesectorCode=inputData.nationality
        "label": "Sector Code",
        "grid": 6,
    },


    {
        "varName": "fincoreDataUpdatesubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        //subsector code
        "label": "Sub Sector Code",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "sectorCodeApicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdateoccupationCode",
        "type": "select",
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3",
        "type": "textApiCall",
        //rm code
        "label": "RM Code/ Wealth Manager Code",
        "grid": 6,
    },
    {
        "varName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName",
        "type": "text",
        //rm code
        "label": "RM Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall",
        "conditionalVarValue": true,
    },

    {
        "varName": "fincoreDataUpdatefreeText3",
        "type": "text",
        "label": "Free Text 3 ",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode6",
        "type": "text",
        "label": "Free Code 6",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatedepositCodeFreeCode7",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Deposit Code/Free Code 7",
        "required": true,
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCode8",
        "type": "text",
        "label": "Free Code 8",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeText10",
        "type": "text",
        "label": "Free Text 10",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText1",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText2",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    },

    {
        "varName": "fincoreDataUpdatefreeCodeFreeText3",
        "type": "text",
        "label": "Free Code/Free Text",
        "grid": 6,
    }
]
let jsonFormForTpKyc = [

/*    {
        type: "empty",
        grid: 12
    },
    {
        varName: "nid",
        type: "file",
        label: "Upload File",
        // accept: "",
        grid: 2,
    },*/

    {
        "type": "title",
        "label": "TP and KYC",
        "grid": 12,
        "readOnly": true,
    },
    {
        "varName": "functionCode",
        "type": "select",
        "enum": [
            "ADD",
            "UPDATE",
            "DELETE",
        ],
        "label": "Function Code",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPcustomerType",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Customer Type",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "customerAccount",
        "type": "text",
        "validation": "numeric",
        "label": "Customer Account",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TpEntityType",
        "type": "select",
        "enum": [
            "INDIVIDUL",
            "NON IND",
            "GOVT"
        ],
        "label": "Entity Type",
        "grid": 6,
        "readOnly": true,
    },

    {
        "varName": "aCTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "TPandKycAccountType",
        "type": "autoCompleteValueReturn",
        "enum": [
            /* {
                 "masterDataName": "accountType",
                 "key": "Savings A/C",
                 "value": "Savings A/C Risk-(1)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Current A/C",
                 "value": "Current A/C Risk-(4)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "Foreign Currency A/C",
                 "value": "Foreign Currency A/C Risk-(5)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "SND A/C",
                 "value": "SND A/C Risk-(3)"
             },
             {
                 "masterDataName": "accountType",
                 "key": "RFCD A/C",
                 "value": "RFCD A/C Risk-(5)"*/

        ],
        "label": "Account Type",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "purposeOfAcOpening",
        "type": "text",
        "label": "Purpose of A/C Opening",
        "grid": 6,
    },
    {
        "varName": "customersOccupation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer's Occupation",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersOccupation",
        "type": "text",
        "label": "Others Occupation",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customersOccupation",
        "conditionalVarValue": "others",
    },
    {
        "varName": "customerProbableMonthlyIncome",
        "type": "text",
        "label": "Customer probable monthly income",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "sourceOfFundKyc",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "howTheSourcesOfFundHaveBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the Source/Sources of Fund have been verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentsCollectedToEnsureTheSourceOfFund",
        "type": "text",
        "label": "Others Documents Collected to Ensure the Source of Fund",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheSourcesOfFundHaveBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "collectedDocumentsHaveBeenVerifiedOrNot",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Collected Documents have been verified or not?",
        "grid": 6,
    },
    {
        "varName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "How the address (es) of the account holder has been Verified?",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "othersDocumentTakenToVerifyAddress",
        "type": "text",
        "enum": [],
        "label": "Others Document taken to Verify Address",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "howTheAddressOfTheAccountHolderHasBeenVerified",
        "conditionalVarValue": "others",
    },
    {
        "varName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Has the Beneficial owner of the account been identified",
        "grid": 6,
    },

    {
        "varName": "nameOfBeneficiarOwner",
        "type": "text",
        "label": "Name of Beneficial Owner",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "hasTheBeneficialOwnerOfTheAccountBeenIdentified",
        "conditionalVarValue": "1",
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport no",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "passportCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "passportVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },

    {
        "varName": "nationalIdCardNo",
        "type": "text",
        "label": "National ID Card No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "nationalIdCardNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No:",
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "birthCertificateNoVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "electronicTaxId",
        "type": "textApiCall",
        "label": "Electronic Tax ID",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "electronicTaxIdVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 6,
    },
    {
        "type": "empty",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationCopyReceived",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Copy Received",
        "grid": 6,
    },
    {
        "varName": "otherDocumentationVerified",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Verified",
        "grid": 6,
    },
    {
        "varName": "customerNonresidentForeigner",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Customer is a Non-resident or Foreigner",
        "grid": 6,
    },
    {
        "varName": "reasonForOpeningTheAccountOfNonResidentAndForeigner",
        "type": "text",
        "label": "Reason for Opening the Account of Non-resident and Foreigner",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "typeOfVisa",
        "type": "text",
        "label": "Type of VISA",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },

    {
        "varName": "expDate",
        "type": "date",
        "label": "Exp date",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isWorkPermitPermissionOpenAc",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is Work permit and permission to open A/C from appropiate authority is taken for opening the A/C of work permit holder?",
        "masterData": true,
        "conditionalVarName": "customerNonresidentForeigner",
        "conditionalVarValue": "Y",
        "grid": 6,
    },
    {
        "varName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a PEP/IP/ Head or high official of intl org (as per BFIU Circular defination)? *",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "pepHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "pepHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "isTheCustomerPepHeadHigOfficialIntlOrg",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamily",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is the customer a family members or close associates of any PEP/IP/ Head or high official of intl org? ",
        "grid": 6,
        "required": true,
    },
    {
        "varName": "customerFamilyHasTheApproval",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has the approval been taken from senior management?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyHasInterviewed",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "Has interviewed the customer face to face?",
        "masterData": true,
        "conditionalVarName": "customerFamily",
        "conditionalVarValue": "5",
        "grid": 6,
    },
    {
        "varName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Is there any match to the customer name with the terrorist activity, funding in terrorist activities or listed person or entity on suspicion for Financing of proliferation of weapons of mass destruction under UN Security Council's different regulations and any listed person or banned entity by the Government of Bangladesh according to related laws, rules and circular?",
        "grid": 6,
    },
    {
        "varName": "regardingThisIssue",
        "type": "text",
        "label": "If Yes, Then Details of action taken regarding this issue:",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "customerFamilyIsThereAnyMatchToTheCustomer",
        "conditionalVarValue": "Yes",
    },
    //TP
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Deposit",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Deposit (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Deposit Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Deposit (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Deposit (Including Online/ATM Deposit)",
        "grid": 3,
    },
    {
        "varName": "cashDepositNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashDepositMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit by Transfer/Instrument",
        "grid": 3,
    },
    {
        "varName": "depositbyNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositbyMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Inward Remittance",
        "grid": 3,
    },
    {
        "varName": "foreignInwardNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignInwardMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit of Income from Export",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositofIncomeMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer from BO Account",
        "grid": 3,
    },
    {
        "varName": "depositTransferNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },
    {
        "varName": "othersNumberOfCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalCash",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersMaximumCash",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Total Probable Deposit",
        "grid": 3,
    },
    {
        "varName": "totalProbableNumberOfCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableNumberOfCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableMaximumCash",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbableMaximumCashApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "type": "titleBold",
        "label": "Withdrawal",
        "grid": 3,
    },

    {
        "type": "titleBold",
        "label": "No. of Withdrawal (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Total Withdrawal Amount (Monthly)",
        "grid": 3,
    },
    {
        "type": "titleBold",
        "label": "Maximum Amount of Withdrawal (Per Transaction)",
        "grid": 3,
    },
    {
        "type": "straightLine",
        "grid": 12,
    },
    //
    {
        "type": "titleBlack",
        "label": "Cash Withdrawal (Including Online/ATM Withdrawal)",
        "grid": 3,
    },

    {
        "varName": "cashWithdrawalNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "cashWithdrawalmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Withdrawal through Transfer/Instrument",
        "grid": 3,
    },

    {
        "varName": "withdrawalthroughNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "withdrawalthroughmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Foreign Outward Remittance",
        "grid": 3,
    },

    {
        "varName": "foreignOutwardNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "foreignOutwardmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Payment Against Import",
        "grid": 3,
    },

    {
        "varName": "paymentAgainstNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "paymentAgainstmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "Deposit/Transfer to BO Account",
        "grid": 3,
    },

    {
        "varName": "depositTransferNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransferTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "depositTransfermaximumAmount",
        "type": "text",
        "grid": 3,
    },
    //
    {
        "type": "titleBlack",
        "label": "others",
        "grid": 3,
    },

    {
        "varName": "othersNumberofWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersTotalWithdrawal",
        "type": "text",
        "grid": 3,
    },
    {
        "varName": "othersmaximumAmount",
        "type": "text",
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },

    //
    {
        "type": "titleBlack",
        "label": "Total Probable Withdrawal",
        "grid": 3,
    },

    {
        "varName": "totalProbableNumberofWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableNumberofWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbableTotalWithdrawal",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly": true,
        "grid": 3,
    },
    {
        "varName": "totalProbablemaximumAmount",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "totalProbablemaximumAmountApiCall",
        "conditionalVarValue": true,
        "grid": 3,
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "yearlyPropableTransaction",
        "type": "text",
        "label": "Yearly Propable Transaction",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "totalProbableTotalWithdrawalApiCall",
        "conditionalVarValue": true,
        "readOnly":true
    },
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "autoPopulationSummation",
        "type": "submit",
        "event": false,
        "label": "Auto Population Summation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 12,
    },

    {
        "varName": "riskRatingcalculation",
        "type": "submit",
        "event": false,
        "label": "Risk Rating calculation",
        "conditional": true,
        "conditionalVarName": "makerEndTpAndKyc",
        "conditionalVarValue": true,
        "grid": 6,

    },
    {
        "type": "empty",
        "grid": 6,

    },
    {
        "type": "title",
        "label": "Risk Rating",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Product/Service and Channel Risk",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "typeProductService",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*  {
                  "masterDataName": "accountType",
                  "key": "Savings A/C",
                  "value": "Savings A/C Risk-(1)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Current A/C",
                  "value": "Current A/C Risk-(4)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "Foreign Currency A/C",
                  "value": "Foreign Currency A/C Risk-(5)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "SND A/C",
                  "value": "SND A/C Risk-(3)"
              },
              {
                  "masterDataName": "accountType",
                  "key": "RFCD A/C",
                  "value": "RFCD A/C Risk-(5)"
              }*/
        ],
        "readOnly":true,
        "label": "Type of Product/Service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "typeOnboardingAccountOpening",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Type of Onboarding/Account Opening",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Geographical Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "nonResidentCustomerRisk",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Non-Resident Customer Risk",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "forForeignNationals",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "For Foreign Resident",
        "grid": 6,
        "masterData": true,
        "conditionalVarName": "nonResidentCustomerRisk",
        "conditionalVarValue": "3",
    },
    {
        "type": "title",
        "label": "Relationship Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "accordingBfiuCustomer",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": " According to BFIU Circular, whether the Customer is a PEP/IP/ Head or high officials of intl. org.? ",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "varName": "accordingBfiuFamily",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "According to the BFIU Circular, whether the Customer is a family member or close associate of PEP/IP/ Head or high officials of intl. org.?",
        "grid": 6,
        "readOnly":true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "required": true,
    },
    {
        "type": "title",
        "label": "Transactional Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "averageAmountOfYearlyTransactions",
        "type": "text",
        "label": "Average Amount of Yearly Transactions (in TK)",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Transparency Risk",
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        "grid": 12,
    },
    {
        "varName": "customerProvidedReliableInformation",
        "type": "autoCompleteValueReturn",
        "enum": [

        ],
        "label": "Has the customer provided reliable information on the source of fund?",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "type": "title",
        "label": "Business or Service Related Risk ",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheList",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Select from the list",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
    },
    {
        "varName": "selectFromTheListOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "selectFromTheListNumeric",
        "type": "text",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "selectFromTheList",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessServiceOther",
        "type": "text",
        "label": "Other",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "listBusinessService",
        "conditionalVarValue": "Others",
    },
    {
        "varName": "listBusinessService1",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    }, {
        "varName": "listBusinessService2",
        "type": "autoCompleteValueReturn",
        "enum": [


        ],
        "label": "Add from the list for more business or service",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,
        // "required": true,
    },

    {
        "varName": "overallRiskAssesment",
        "type": "text",
        "readOnly": true,
        "label": "Overall Risk Assesment",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "riskType",
        "type": "text",
        "readOnly": true,
        "label": "Risk Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "overallRiskFieldShow",
        "conditionalVarValue": true,

    },
    {
        "varName": "comments",
        "type": "text",
        "label": "Comments",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "riskRatingCalculation",
        "conditionalVarValue": true,

    },
];
let selectUpdateType = [
    {
        "varName": "freeFlag1",
        "type": "select",
        "required": true,
        "enum": [
            "Request Type 1",
            "Request Type 2",
            "Request Type 3",
            "Request Type 4",
            "Request Type 5",

        ],
        "label": "Request Type",
        "grid": 2
    },

    {
        "varName": "updateType",
        "type": "select",
        "required": true,
        "enum": [
            "CB Update",
            "Account Update",
            "Tp and Kyc Update" ,
            "CB and Account Update",
            "Signature card Update",

        ],
        "label": "Update Type",
        "grid": 2
    },
    {
        "varName": "cbNumber",
        "type": "textApiCall",
        "required": true,
        "label": "CB Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "CB Update",
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "Account Update",
    } ,
    /*{
        "varName": "cbNumber",
        "type": "text",
         "required": true,
        "label": "CB Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "CB and Account Update",
    },*/
    {
        "varName": "accountNumber",
        "type": "text",
        "required": true,
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "CB and Account Update",
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "Tp and Kyc Update",
    },
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Account Number",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "updateType",
        "conditionalVarValue": "Signature card Update",
    } ,


];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class CbAndAccountUpdateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {tagList: {}},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            accountDataPopulate: false,
            cbDataPopulate: false,
            submitButtonClick: false,
            requestType: "",
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            occupationCodeList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            customerNumber: 0,
            linkrelationCode: 0,
            objectForNominee: [],
            objectForNomineeForNew: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            imageCropModal: false,
            errorModalBoolean: false,
            cbGenerateModal: false,
            getRemarks: [], err: false,
            errorArray: {},
            errorMessages: {},
            getMappingCropImage: false,
            nomineeNumber: 0,
            getNominee: false,
            errorMessage: "Invalid Code",
            type: "successs",
            city: [],
            dispatchMode: [],
            getCity: false,
            stateCode: [],
            getGuardianCode: [],
            savingsSchemeCodeList: [],
            currentSchemeCodeList: [],
            getState: false,
            country: [],
            titleList: [],
            chequebookWaiverType: [],
            rmCodeList: [],
            phoneEmailType: [],
            bankRelationTypeList: [],
            phoneOrEmail: [],
            getCountry: false,
            currency: [],
            relationshipList: [],
            constitutionList: [],
            customerStatusList: [],
            waiverFieldList: [],
            ccepCodeList: [],
            introducerStatus: [],
            relationCodeList: [],
            nomineeRelationShipCodeList: [],
            getCurrency: false,
            confirmAlert: false,
            actionType: "",
            despatchmodeList: [],
            sectorCodeList: [],
            subsectorCodeList: [],
            depositCodeList: [],
            tradeServiceList: [],
            agentBankingOutletList: [],
            subSegmentList: [],
            segmentList: [],
            maritalStatusList: [],
            cbDataAfterSet: false,
            accountDataAfterSet: false,
            kycAutopopulateFlag: false,
            TpAutopopulateFlag: false,
            phoneNumCountryCodeList: [],
            getDownloadListFlag: false,
            downLoadFileList: [],
            getnonResident: [],
            getfreecode7: [],
            fileNotFoundTittle: "",
            employmentTypeList: [],
            placeOfIssueList: [],
            despatchList: [],
            uploadModal: false,
            fileUploadData: {},
            SelectedData: false,
            getMappingAllImage: false,
            TPandKycAccountType: [],
            customersOccupation: [],
            howTheSourcesOfFundHaveBeenVerified: [],
            howTheAddressOfTheAccountHolderHasBeenVerified: [],
        };


        this.handleSubmit = this.handleSubmit.bind(this);
    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined") {
                clone[prop] = undefined
            }

        return clone;
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        // let jsonCb=jsonFormForuploadingFile.concat(jsonFormForCB);
        let jsonArray = jsonFormForCB;
        let jsonArrayLast = jsonFormForAccount;
        let jsonArrayTpKyc = jsonFormForTpKyc;
        let jsonArrayCbAndAccount= jsonFormForCbAndAccount;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                jsonFormForCB[i].enum = getValue;

            }
        }
        for (let i = 0; i < jsonArrayLast.length; i++) {
            let jsonObjectLast = jsonArrayLast[i];
            if (jsonObjectLast.varName === searchVarname) {
                jsonFormForAccount[i].enum = getValue;

            }
        }

        for (let i = 0; i < jsonArrayTpKyc.length; i++) {
            let jsonObjectLast = jsonArrayTpKyc[i];
            if (jsonObjectLast.varName === searchVarname) {
                jsonFormForTpKyc[i].enum = getValue;

            }
        }

        for (let i = 0; i < jsonArrayCbAndAccount.length; i++) {
            let jsonObjectLast = jsonArrayCbAndAccount[i];
            if (jsonObjectLast.varName === searchVarname) {
                jsonFormForCbAndAccount[i].enum = getValue;

            }
        }

        /* if (searchVarname === "") {
             let tpArrayList = this.props.tp;
             for (let i = 0; i < tpArrayList.length; i++) {
                 let jsonObjectLast = tpArrayList[i];
                 if (jsonObjectLast.varName === searchVarname) {
                     this.props.tp[i].enum = getValue;

                 }
             }
         }*/
        if (searchVarname === "howTheAddressOfTheAccountHolderHasBeenVerified" || searchVarname === "customersOccupation" || searchVarname === "documentsCollectedToEnsureTheSourceOfFund" || searchVarname === "whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged" || searchVarname === "customerMonthlyIncome" || searchVarname === "howWasAccountOpened") {
            let kycArrayList = this.props.kyc;
            for (let i = 0; i < kycArrayList.length; i++) {
                let jsonObjectLast = kycArrayList[i];
                if (jsonObjectLast.varName === searchVarname) {
                    this.props.kyc[i].enum = getValue;

                }
            }
        }

    };
    dynamicApiCallBankAndBranch = (fieldName, bankName, branchName) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + bankName + "/" + branchName;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    };
    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                    // this.findByVarNameApiData("etindocumentType", response.data)
                    this.findByVarNameApiData("photoIddocumentType", response.data)

                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                    // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                    //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                    //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (apiType === "city") {
                    this.findByVarNameApiData("maillingAdresscity", response.data);
                    this.findByVarNameApiData("homeAddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);
                    this.findByVarNameApiData("mailingAddresscity1", response.data);
                    this.findByVarNameApiData("homeAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity2", response.data);


                    this.findByVarNameApiData("mailingAddresscity", response.data);
                    this.findByVarNameApiData("companyRegisteredcity", response.data);
                    this.findByVarNameApiData("factoryAddresscity", response.data);
                    this.findByVarNameApiData("officeAddresscity", response.data);
                    this.findByVarNameApiData("homeAddressaddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);


                    this.setState({
                        city: response.data,
                        getCity: true
                    })

                } else if (apiType === "state") {

                    this.findByVarNameApiData("maillingAdressstate", response.data);
                    this.findByVarNameApiData("homeAddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate1", response.data);
                    this.findByVarNameApiData("homeAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate2", response.data);


                    this.findByVarNameApiData("mailingAddressstate", response.data);
                    this.findByVarNameApiData("companyRegisteredstate", response.data);
                    this.findByVarNameApiData("factoryAddressstate", response.data);
                    this.findByVarNameApiData("officeAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate", response.data);
                    this.findByVarNameApiData("homeAddressaddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);


                    this.setState({
                        stateCode: response.data,
                        getState: true,
                    })
                } else if (apiType === "country") {
                    this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("maillingAdresscountry", response.data);
                    this.findByVarNameApiData("homeAddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("Customersnationality", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry1", response.data);
                    this.findByVarNameApiData("homeAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry2", response.data);
                    //this.findByVarNameApiData("passportNumberplaceOfIssue", response.data)


                    this.findByVarNameApiData("mailingAddresscountry", response.data);
                    this.findByVarNameApiData("companyRegisteredcountry", response.data);
                    this.findByVarNameApiData("factoryAddresscountry", response.data);
                    this.findByVarNameApiData("officeAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry", response.data);
                    this.findByVarNameApiData("homeAddressaddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("residenceCertificatenationality", response.data);
                    this.findByVarNameApiData("registrationNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("certificateOfIncorporationcountryOfIssue", response.data);
                    this.findByVarNameApiData("registrationNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("tradeLicensecountryOfIssue", response.data);


                    this.setState({
                        country: response.data,
                        getCountry: true
                    })
                } else if (apiType === "currency") {

                    this.findByVarNameApiData("workAddressCurrency", response.data);
                    this.findByVarNameApiData("homeAddressCurrency", response.data);
                     this.findByVarNameApiData("aof1currencyCodeCcy", response.data)
                    this.setState({
                        currency: response.data,
                        getCurrency: true
                    })
                } else if (apiType === "phoneCountryCode") {

                    this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } /*else if (apiType === "customerStatus") {
                    this.setState({
                        customerStatusList:response.data,
                    })
                }*/ else if (apiType === "savingsSchemeCode") {

                    this.setState({
                        savingsSchemeCodeList: response.data,
                    })
                } else if (apiType === "currentSchemeCode") {

                    this.setState({
                        currentSchemeCodeList: response.data,
                    })
                } else if (apiType === "placeOfIssue") {
                    this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                    this.findByVarNameApiData("registrationNumberplaceOfIssue", response.data);
                    this.findByVarNameApiData("certificateOfIncorporationplaceOfIssue", response.data);
                    this.findByVarNameApiData("registrationNumberplaceOfIssue", response.data);
                    this.findByVarNameApiData("tradeLicenseplaceOfIssue", response.data);
                    this.setState({
                        placeOfIssueList: response.data
                    })
                } else if (apiType === "despatchMode") {
                    this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                    this.setState({
                        despatchList: response.data
                    })
                } else if (apiType === "nomineeRelationCode") {
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data);
                    this.setState({
                        nomineeRelationShipCodeList: response.data,
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("sourceOfFund", response.data);
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data);
                    this.setState({
                        constitutionList: response.data
                    })

                } else if (apiType === "monthlyIncome") {
                    this.findByVarNameApiData("customerMonthlyIncome", response.data)


                } else if (apiType === "accountOpen") {
                    this.findByVarNameApiData("howWasAccountOpened", response.data)


                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data);
                    this.setState({
                        tradeServiceList: response.data
                    })

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                    this.setState({
                        despatchmodeList: response.data
                    })

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data);
                    this.setState({
                        introducerStatus: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                    this.setState({
                        getfreecode7: response.data
                    })

                } else if (apiType === "priorityCenterCode") {
                    //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                } else if (apiType === "contactType") {
                    //this.findByVarNameApiData("contactNumbercontactType1", response.data)
                    // this.findByVarNameApiData("contactNumbercontactType2", response.data)
                    //this.findByVarNameApiData("contactNumbercontactType3", response.data)
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                } else if (apiType === "addressType") {

                    // this.findByVarNameApiData("workAddressaddressType", response.data)
                    // this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                    // this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                    // this.findByVarNameApiData("homeAddressaddrssType", response.data)
                    //this.findByVarNameApiData("presentAddressaddrssType", response.data)
                    //this.findByVarNameApiData("nreAddressaddrssType", response.data)

                } else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);


                    this.setState({
                        getGuardianCode: response.data
                    })

                } else if (apiType === "accountStatement") {
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data);
                    this.setState({
                        subSegmentList: response.data
                    })

                } else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                    this.findByVarNameApiData("introducersubSectorCode", response.data);
                    this.setState({
                        subsectorCodeList: response.data
                    })
                } else if (apiType === "sectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.findByVarNameApiData("introducersectorCode", response.data);
                    this.setState({
                        sectorCodeList: response.data
                    })
                } else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.findByVarNameApiData("introducerdepositCodeFreeCode7", response.data);
                    this.setState({
                        depositCodeList: response.data
                    })
                } else if (apiType === "dispatchMode") {
                    // this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data)
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    console.log("llllllllllll");
                    console.log(response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                    this.setState({
                        rmCodeList: response.dat
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data)
                    //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverFunctionCode") {
                    // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                    // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                    this.setState({
                        chequebookWaiverType: response.data
                    })
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "customerStatus") {
                    //this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data)
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: response.data
                    })
                } else if (apiType === "addressFormat") {
                    // this.findByVarNameApiData("maillingAdressaddressFormat", response.data)
                    //this.findByVarNameApiData("homeAddressaddressFormat", response.data)
                    // this.findByVarNameApiData("workAddressaddressFormat", response.data)
                    //this.findByVarNameApiData("presentAddressaddressFormat", response.data)
                    //this.findByVarNameApiData("nreAddressaddressFormat", response.data)
                    // this.findByVarNameApiData("mailingAddressaddressFormat1", response.data)
                    // this.findByVarNameApiData("homeAddressaddressFormat1", response.data)
                    // this.findByVarNameApiData("workAddressaddressFormat1", response.data)
                    // this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.setState({
                        waiverFieldList: response.data
                    })
                    // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                    this.setState({
                        ccepCodeList: response.data
                    })

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                    this.setState({
                        getnonResident: response.data
                    })
                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                    this.setState({
                        maritalStatusList: response.data
                    })

                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data);
                    this.setState({
                        employmentTypeList: response.data
                    })
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);

                    this.setState({
                        relationshipList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                } else if (apiType === "contactType") {
                    // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                    /*  this.findByVarNameApiData("contactNumbercontactType1", response.data)
                      this.findByVarNameApiData("contactNumbercontactType", response.data)
                      this.findByVarNameApiData("contactNumbercontactType2", response.data)
                      this.findByVarNameApiData("contactNumbercontactType3", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                      this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)*/


                } else if (apiType === "title") {
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                    this.findByVarNameApiData("aCTitle", response.data);
                    this.setState({
                        titleList: response.data
                    })


                } else if (apiType === "documentCode") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentCodeN", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentCodeP", response.data)
                    // this.findByVarNameApiData("birthCertificatedocumentCodeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentCodeS", response.data)
                    // this.findByVarNameApiData("nationalIdCarddocumentCode", response.data)
                    //  this.findByVarNameApiData("smartlIdCarddocumentCode", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentCode", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentCode", response.data)
                    //  this.findByVarNameApiData("chairmanCertificatedocumentCode", response.data)
                    //  this.findByVarNameApiData("etindocumentCode", response.data)
                    this.findByVarNameApiData("photoIddocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                    this.findByVarNameApiData("crmMissegment", response.data);

                    this.setState({
                        segmentList: response.data
                    })
                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                    this.setState({
                        phoneOrEmail: response.data
                    })
                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data);
                    this.setState({
                        bankRelationTypeList: response.data
                    })

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                    this.setState({
                        phoneEmailType: response.data
                    })
                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                }
                /*  else if (apiType === "accountOpen") {

                      this.findByVarNameApiData("", response.data)

                  }
                  else if (apiType === "monthlyIncome") {

                      this.findByVarNameApiData("", response.data)

                  }*/


            })
            .catch((error) => {
                console.log(error);
            })
    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        if (value !== undefined && value !== null && Array.isArray(jsonArray) && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                    i = jsonArray.length;

                }
            }
        }


    }
    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {

            if (accountRelated === "accountRelated") {
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.accType = "SAVINGS"

                } else {
                    inputData.accountType = "CURRENTS";
                    inputData.accType = "CURRENTS"
                }
                if (data.nomineeInfoDetails) {
                    let nomineeLength = data.nomineeInfoDetails.length;
                    inputData.nomineeNumberOld = data.nomineeInfoDetails.length;
                    let sl = 0;
                    let nomineeInfo = data.nomineeInfoDetails;
                    for (let i = 0; i < nomineeInfo.length; i++) {
                        sl = i + 1;

                        inputData["nomineecifIdApiCall" + sl] = true;
                        inputData["nomineecifId" + sl] = (data.nominationInfoList !== undefined && data.nominationInfoList.length > 0) ? data.nominationInfoList[i].nomineecifid : "";
                        inputData["nomineeRegNo"] = nomineeInfo[i].regNum;
                        inputData["nomineeName" + sl] = nomineeInfo[i].nomineeName;
                        inputData["nomineeRelationship" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].relType, this.state.nomineeRelationShipCodeList);
                        inputData["percentageNominee" + sl] = nomineeInfo[i].nomineePercent;
                        inputData["nomineeAddress1" + sl] = nomineeInfo[i].addr1;
                        inputData["nomineeAddress2" + sl] = nomineeInfo[i].addr2;
                        inputData["nomineeCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].city, this.state.city);
                        inputData["nomineeStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].stateProv, this.state.stateCode);
                        inputData["nomineePostalCode" + sl] = nomineeInfo[i].postalCode;
                        inputData["nomineeCountry" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].country, this.state.country);
                        inputData["photoIdNumberRegNo" + sl] = nomineeInfo[i].addr3;
                        let date = nomineeInfo[i].nomineeBirthDt ? nomineeInfo[i].nomineeBirthDt.split("T")[0].split("-") : "";
                        inputData["nomineeDob" + sl] = date ? `${date[1]}/${date[2]}/${date[0]}` : "";
                        inputData["nomineeMinor" + sl] = nomineeInfo[i].nomineeMinorFlg === "Y" ? "YES" : "NO";
                        inputData["nomineeGuardiansName" + sl] = nomineeInfo[i].nomineeGuardianName;
                        inputData["nomineeGuardianCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCode, this.state.getGuardianCode);
                        inputData["guardianPhotoId" + sl] = nomineeInfo[i].nomineeGuardianAddr3;
                        inputData["nomineeGuardianAddress" + sl] = nomineeInfo[i].nomineeGuardianAddr1;
                        inputData["nomineeGuardianAddress2" + sl] = nomineeInfo[i].nomineeGuardianAddr2;
                        inputData["nomineeGuardianCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCity, this.state.city);
                        inputData["nomineeGuardianStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianStateProv, this.state.state);
                        inputData["nomineeGuardianPostalCode" + sl] = nomineeInfo[i].nomineeGuardianPostalCode;
                        inputData["nomineeGuardianCountry2" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCountry, this.state.country);
                    }
                    if (data.nominationInfoList !== undefined && data.nominationInfoList.length > 0) {
                        let sl = 0;
                        let nominationList = data.nominationInfoList;
                        for (let i = 0; i < nominationList.length; i++) {
                            sl = i + 1;
                            if (nominationList[i].nomineecifid !== undefined && nominationList[i].nomineecifid !== null && nominationList[i].nomineecifid !== "") {
                                inputData["search" + sl] = "CIF ID";
                            } else {
                                inputData["search" + sl] = "INFORMATION";
                            }

                            inputData["nomineeidnum" + sl] = nominationList[i].nomineeidnum;
                            inputData["nomineecifId" + sl] = nominationList[i].nomineecifid;
                            inputData["oldNomineecifIdExist" + sl] = nominationList[i].nomineecifid !== "";
                        }
                    }
                }
                inputData.misagentBankingOutletFreeCode1 = this.findByVarNameGetKeyValue(data.agentbankingoutlet, this.state.agentBankingOutletList); //acco

                inputData.upDateSchemeCode = data.schemeCode;
                inputData.fincoreDataUpdatecashLimitDr = data.drcashlim;
                inputData.functionCode = "ADD";
                inputData.customerType = "Individual";
                inputData.fincoreDataUpdatecashLimitCr = data.crcashlim;
                inputData.fincoreDataUpdateclearingLimitDr = data.drclrlim;
                inputData.fincoreDataUpdateclearingLimitCr = data.crclrlim;
                inputData.fincoreDataUpdatetransferLimitDr = data.drtxlim;
                inputData.fincoreDataUpdatetransferLimitCr = data.crtxlim;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.miscustomerIdCifId = data.cbNumber;
                inputData.misaCNumber = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;


                // inputData.fincoreDataUpdateaccountStatement=data. ;
                // inputData.fincoreDataUpdatestatementFrequency=data. ;
                inputData.fincoreDataUpdatedispatchMode = data.despatchmode;
                inputData.fincoreDataUpdatenextPrintDate = data.acctStmtNxtPrintDt;
                // inputData.fincoreDataUpdateaCStatus=data. ;
                inputData.fincoreDataUpdatedormantActivationCharge = data.dormchrg;
                // inputData.fincoreDataUpdatenomineeSelect=data. ;
                // inputData.fincoreDataUpdateaCInformation=data. ;
                inputData.fincoreDataUpdatesectorCode = this.findByVarNameGetKeyValue(data.sectorcode, this.state.sectorCodeList);
                inputData.fincoreDataUpdatesubSectorCode = this.findByVarNameGetKeyValue(data.subsectorcode, this.state.subsectorCodeList);
                inputData.schemeCodeForSavings=data.schemeCode;
                inputData.sectorCodeApicall = true;
                inputData.fincoreDataUpdateoccupationCode = data.occupationcode;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = data.rmcode;
                /* if(data.rmcode!==undefined && data.rmcode!==null){
                     inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall=true
                 }*/
                //inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false
                // inputData.fincoreDataUpdatefreeText3=data. ;
                // inputData.fincoreDataUpdatefreeCode6=data. ;
                inputData.fincoreDataUpdatedepositCodeFreeCode7 = this.findByVarNameGetKeyValue(data.depositcode, this.state.depositCodeList);
                // inputData.fincoreDataUpdatefreeCode8=data. ;
                inputData.miswaiverFieldFreeCode10 = this.findByVarNameGetKeyValue(data.waiverfield, this.state.waiverFieldList);
                inputData.customerName = data.customerName;
                inputData.cbNumber = data.cbNumber;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.aof1smsAlertFreeText11 = data.smsalert;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(data.modeOfOper, this.state.despatchmodeList);
                //kyc
                inputData.customerAccount = data.accountNumber;

                inputData.aCTitle = data.accountName;
                inputData.basicInformationUpdateprimarySolId = data.solid;

                inputData.accountType = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.TpCustomerAccount = data.accountNumber;
                inputData.TpAccountName = data.accountName;
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.TpAccountType = "SAVINGS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.savingsSchemeCodeList)

                } else {
                    inputData.accountType = "CURRENTS";
                    inputData.TpAccountType = "CURRENTS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.currentSchemeCodeList)
                }

                inputData.TpAccountNumber = data.accountNumber;
                if (data.acctStmtMode === "S") {
                    inputData.fincoreDataUpdateaccountStatement = "S-STATEMENT";

                } else {
                    inputData.fincoreDataUpdateaccountStatement = "NONE"

                }

                inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                /*   if (data.despatchmode==="E") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "Email only",
                          "value": "Email only"
                      }

                  } else if (getInputData.statementFacility === "E-Statement") {


                  }
              else if (getInputData.statementFacility === "NO") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "No Despatch",
                          "value": "No Despatch"
                      }

                  }*/

            }
            else if (accountRelated === "waiverType") {
                inputData.miswaiverTypeForChequeBookWaiver = this.findByVarNameGetKeyValue(data, this.state.chequebookWaiverType)

            } else if (accountRelated === "cbExtraInformation") {
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.introducercifType = data.intrd_cif_type;
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.freecode7, this.state.getfreecode7);              //

                inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            } else if (accountRelated === "cbRelated") {
                inputData.miscustomerIdCifId = this.state.inputData.cbNumber;
                inputData.misaCNumber = data.accountNumber;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;
                inputData.missolId = data.branchCode;
                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.customerName = data.customerName;
                inputData.miscorporateName = data.customerName;//ok
                inputData.misshortName = data.shortName;//ok
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissegment = this.findByVarNameGetKeyValue(data.segment, this.state.segmentList);
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subsegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                // inputData.misfunctionCodeForChequeBookWaiver = "A"
                inputData.aof1currencyCodeCcy1 = data.currency;
                inputData.aof1currencyCodeCcyExist = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 500)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

                if (data.GUARDIANBLOCK !== undefined) {
                    inputData.guardianDetailsrelation = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationshipCategory, this.state.relationshipList);
                    inputData.guardianDetailsbankRelationType = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.parentEntity, this.state.bankRelationTypeList);
                    inputData.guardianDetailsrelation1 = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationShip, this.state.nomineeRelationShipCodeList);
                    inputData.guardianDetailsguardianSelectionGurdian = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.guardCode, this.state.getGuardianCode);
                    // inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    inputData.guardianDetailsgurdianCifNumberCustomer = data.GUARDIANBLOCK.parentCIFID;
                    //inputData.guardianDetailslastName = data.GUARDIANBLOCK.parentCIFID;
                }

                //delete flag start
                inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                inputData.natioanlIdCardDelete = "N";
                inputData.smartIdCardDelete = "N";
                inputData.passportNumberDelete = "N";
                inputData.drivingLicenseNumberDelete = "N";
                inputData.residentNumberDelete = "N";
                inputData.birthCertificateDelete = "N";
                inputData.chairmanCertificateDelete = "N";
                inputData.photoIdDelete = "N";
                inputData.etinDelete = "N";
                inputData.contactNumber2Delete = "N";
                inputData.contactNumber3Delete = "N";
                inputData.contactNumberEmailemailId2Delete = "N";
                inputData.homeAddressDelete = "N";
                inputData.workAddressDelete = "N";
                inputData.nreAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                //delete flag End

                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.natioanlIdCarddocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "NID",
                    "value": "NID CARD IDENTITY"
                };
                //Passport
                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentCodeP = {
                    "masterDataName": "documentCode",
                    "key": "PASSPORT",
                    "value": "PASSPORT IDENTITY"
                };

                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentCodeS = {
                    "masterDataName": "documentCode",
                    "key": "RSNID",
                    "value": "RSNID"
                };

                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentCodeB = {
                    "masterDataName": "documentCode",
                    "key": "BC",
                    "value": "BIRTH CERTIFICATE"
                };
                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentCodeCC = {
                    "masterDataName": "documentCode",
                    "key": "CHAC",
                    "value": "CHAIRMAN CERTIFICATE"
                };

                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "ETIN",
                    "value": "TIN CARD IDENTITY"
                };
                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumbercontactType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType2 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH2",
                    "value": "COMMPH2"
                };
                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType3 = {
                    "masterDataName": "contactType",
                    "key": "WORKPH1",
                    "value": "WORKPH1"
                };
                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                //inputData.contactNumberphoneNo3=data. ;

                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                /*  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };*/
                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };


                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.companyRegisteredaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.factoryAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.officeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };


                inputData.presentAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Future/OnSite",
                    "value": "Future/OnSite"
                };
                inputData.tradeLicensedocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.tradeLicensedocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "TL",
                    "value": "TRADE LICENSE"
                };
                inputData.certificateOfIncorporationdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.certificateOfIncorporationdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "COI",
                    "value": "CERTIFICATE OF INCORPORATION"
                };
                inputData.registrationNumberdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.registrationNumberdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "REG",
                    "value": "REGISTRATION NUMBER"
                };
                inputData.companyRegisteredaddressType = {
                    "masterDataName": "Registered",
                    "key": "Registered",
                    "value": "Registered"
                };
                if (data.RegisteredBLOCK !== undefined) {
                    inputData.companyRegisteredaddressLabel = {
                        "masterDataName": "Registered",
                        "key": "Registered",
                        "value": "Registered"
                    };
                }
                inputData.factoryAddressaddressType = {
                    "masterDataName": "Head Office",
                    "key": "Head Office",
                    "value": "Head Office"
                };
                if (data.HeadOfficeBLOCK !== undefined) {
                    inputData.factoryAddressaddressLabel = {
                        "masterDataName": "Head Office",
                        "key": "Head Office",
                        "value": "Head Office"
                    };
                }

                inputData.officeAddressaddressType = {
                    "masterDataName": "Alt Lang Add",
                    "key": "Alt Lang Add",
                    "value": "Alt Lang Add"
                };
                if (data.AltLangAddBLOCK !== undefined) {
                    inputData.officeAddressaddressLabel = {
                        "masterDataName": "Alt Lang Add",
                        "key": "Alt Lang Add",
                        "value": "Alt Lang Add"
                    };
                }

                if (data.RegisteredBLOCK !== undefined) {
                    inputData.companyRegisteredaddressField1 = data.RegisteredBLOCK.addrLine1;
                    inputData.companyRegisteredaddressField2 = data.RegisteredBLOCK.addrLine2;
                    inputData.companyRegisteredcity = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.city, this.state.city);
                    inputData.companyRegisteredstate = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.state, this.state.stateCode);
                    inputData.companyRegisteredpostalCode = data.RegisteredBLOCK.postalCode;
                    inputData.companyRegisteredcountry = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.country, this.state.country);
                }

                if (data.HeadOfficeBLOCK !== undefined) {
                    inputData.factoryAddressaddressField1 = data.HeadOfficeBLOCK.addrLine1;
                    inputData.factoryAddressaddressField2 = data.HeadOfficeBLOCK.addrLine2;
                    inputData.factoryAddresscity = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.city, this.state.city);
                    inputData.factoryAddressstate = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.state, this.state.stateCode);
                    inputData.factoryAddresspostalCode = data.HeadOfficeBLOCK.postalCode;
                    inputData.officeAddresscountry = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.country, this.state.country);
                }
                if (data.AltLangAddBLOCK !== undefined) {
                    inputData.officeAddressaddressField1 = data.AltLangAddBLOCK.addrLine1;
                    inputData.officeAddressaddressField2 = data.AltLangAddBLOCK.addrLine2;
                    inputData.officeAddresscity = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.city, this.state.city);
                    inputData.officeAddressstate = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.state, this.state.stateCode);
                    inputData.officeAddresspostalCode = data.AltLangAddBLOCK.postalCode;
                    inputData.officeAddresscountry = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.country, this.state.country);
                }
                if (data.COIBLOCK !== undefined) {
                    inputData.certificateOfIncorporationcertificateOfIncorporationNumberUniqueId = data.COIBLOCK.referenceNum;
                    inputData.certificateOfIncorporationplaceOfIssue = this.findByVarNameGetKeyValue(data.COIBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.certificateOfIncorporationcountryOfIssue = this.findByVarNameGetKeyValue(data.COIBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.certificateOfIncorporationissueDate = data.COIBLOCK.issueDt;
                    //inputData.certificateOfIncorporationexpiryDate=data.COIBLOCK.issueDt;
                }

                if (data.REGBLOCK !== undefined) {
                    inputData.registrationNumbercertificateOfIncorporationNumberUniqueId = data.REGBLOCK.referenceNum;
                    inputData.registrationNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.REGBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.registrationNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.REGBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.registrationNumberissueDate = data.REGBLOCK.issueDt;
                    //inputData.registrationNumberexpiryDate=data.REGBLOCK.issueDt;
                }
                if (data.TLBLOCK !== undefined) {
                    inputData.tradeLicensetlNumberUniqueId = data.TLBLOCK.referenceNum;
                    inputData.tradeLicenseplaceOfIssue = this.findByVarNameGetKeyValue(data.TLBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.tradeLicensecountryOfIssue = this.findByVarNameGetKeyValue(data.TLBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.tradeLicenseissueDate = data.TLBLOCK.issueDt;
                    //inputData.tradeLicenseexpiryDate=data.TLBLOCK.issueDt;
                }
                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                }
                if (data.RSNIDBLOCK !== undefined) {

                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }


                }
                inputData.residentdocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "RESID",
                    "value": "RESIDENT ID CARD"
                };

                inputData.drivingLicensedocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "DL",
                    "value": "DRIVING LICENSE"

                };

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                }
                if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {

                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                }


                //inputData.passportNumberexpiryDate=data. ;
                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                if (data.chairmanCertificate !== undefined) {

                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                }
                // inputData.chairmanCertificateplaceOfBirthFreeText14=data. ;
                //inputData.chairmanCertificatecountryOfBirthFreeText15=data. ;
                //  inputData.chairmanCertificatenationality=data. ;
                // inputData.chairmanCertificatenonResident=data. ;
                // inputData.chairmanCertificateturnedNonResidentOn=data. ;
                if (data.ETINBLOCK !== undefined) {

                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;


                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                    /* if(data.COMMEMLBLOCK.phoneNumCountryCode==="88"){
                         inputData.mobileTypecontactNumberphoneNo2 ="88";
                     }
                     else{
                         inputData.mobileTypecontactNumberphoneNo2 ="0";
                     }*/
                }
                if (data.COMMPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                }
                if (data.WORKPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;

                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.MailingBLOCK !== undefined) {


                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }
                if (data.HomeBLOCK !== undefined) {

                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                }
                if (data.WorkBLOCK !== undefined) {

                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                }

            } else if (accountRelated === "Dormant") {
                if (data.acctStatus === "A") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "A",
                        "value": "Active"
                    }
                }
                if (data.acctStatus === "D") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "D",
                        "value": "Dormant"
                    }
                }
                if (data.acctStatus === "I") {

                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "I",
                        "value": "Inactive"
                    }

                }
            }

        }


        return inputData;
    };
    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.state.appId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {

        this.setState({
            loading: true
        });


        /*{
            this.dynamicApiCallBankAndBranch("bankName", "bankName","branchName")
        }*/

        {
            this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
        }
        {
            this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
        }
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.state.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("placeOfIssue", "placeOfIssue")
        }
        {
            this.dynamicApiCall("despatchMode", "despatchMode")
        }
        {
            this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
        }
        {
            this.dynamicApiCall("customersOccupation", "customerOccupation")
        }
        {
            this.dynamicApiCall("addressVariety", "addressVariety")
        }
        {
            this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
        }
        {
            this.dynamicApiCall("monthlyIncome", "monthlyIncome")
        }
        {
            this.dynamicApiCall("howWasAccountOpened", "accountOpen")
        }
        {
            this.dynamicApiCall("constitution", "constitution")
        }
        {
            this.dynamicApiCall("waiverField", "waiverField")
        }
        {
            this.dynamicApiCall("bankRelationType", "bankRelationType")
        }
        {
            this.dynamicApiCall("customerStatus", "customerStatus")
        }
        /* {
             this.dynamicApiCall("emailType", "emailType")
         }*/
        /*  {
              this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
          }*/

        {
            this.dynamicApiCall("ccepCode", "ccepCode")
        }
        {
            this.dynamicApiCall("maritalStatus", "maritalStatus")
        }

        /* {
             this.dynamicApiCall("bankName", "bankName")
         }*/
        {
            this.dynamicApiCall("title", "title")
        }
        {

            this.dynamicApiCall("segment", "segment")
        }
        {
            this.dynamicApiCall("documentCode", "documentCode")
        }
        {
            this.dynamicApiCall("relationship", "relationship")
        }
        /*  {
               this.dynamicApiCall("contactType", "contactType")
           }*/
        {
            this.dynamicApiCall("employmentType", "employmentType")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("nonResident", "nonResident")
        }
        {
            this.dynamicApiCall("addressFormat", "addressFormat")
        }
        /* {
             this.dynamicApiCall("branch", "branch")
         }*/
        {
            this.dynamicApiCall("relationCode", "relationCode")
        }
        {
            this.dynamicApiCall("relationType", "relationType")
        }
        {
            this.dynamicApiCall("dispatchMode", "dispatchMode")
        }
        {
            this.dynamicApiCall("statementFrequency", "statementFrequency")
        }
        {
            this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
        }
        {
            this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
        }
        /*  {
              this.dynamicApiCall("rmCode", "rmCode")
          }*/
        {
            this.dynamicApiCall("subSegment", "subSegment")
        }

        {
            this.dynamicApiCall("designationCode", "designationCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCall("subSectorCode", "subSectorCode")
        }
        {
            this.dynamicApiCall("depositCode", "depositCode")
        }
        {
            this.dynamicApiCall("tradeService", "tradeService")
        }
        {
            this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
        }
        {
            this.dynamicApiCall("addressType", "addressType")
        }
        {
            this.dynamicApiCall("guardianCode", "guardianCode")
        }
        {
            this.dynamicApiCall("accountStatement", "accountStatement")
        }

        {
            this.dynamicApiCall("modOfOperation", "modOfOperation")
        }
        {
            this.dynamicApiCall("introducerStatus", "introducerStatus")
        }
        {
            this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }




        {this.dynamicKycMasterDataApiCall("TPcustomerType","", "1")}
        {this.dynamicKycMasterDataApiCall("TPandKycAccountType","", "2")}
        {this.dynamicKycMasterDataApiCall("customersOccupation", "","3")}
        {this.dynamicKycMasterDataApiCall("howTheSourcesOfFundHaveBeenVerified","", "4")}
        {this.dynamicKycMasterDataApiCall("collectedDocumentsHaveBeenVerifiedOrNot","", "5")}
        {this.dynamicKycMasterDataApiCall("howTheAddressOfTheAccountHolderHasBeenVerified","", "6")}
        {this.dynamicKycMasterDataApiCall("hasTheBeneficialOwnerOfTheAccountBeenIdentified","", "7")}
        {this.dynamicKycMasterDataApiCall("CopyReceived","", "8")}
        {this.dynamicKycMasterDataApiCall("Verified","", "9")}
        {this.dynamicKycMasterDataApiCall("customerNonresidentForeigner","", "10")}
        {this.dynamicKycMasterDataApiCall("isWorkPermitPermissionOpenAc","", "11")}
        {this.dynamicKycMasterDataApiCall("isTheCustomerPepHeadHigOfficialIntlOrg","", "12")}
        {this.dynamicKycMasterDataApiCall("customerFamily","", "13")}
        {this.dynamicKycMasterDataApiCall("customerFamilyIsThereAnyMatchToTheCustomer","", "14")}
        {this.dynamicKycMasterDataApiCall("typeProductService","", "15")}
        {this.dynamicKycMasterDataApiCall("typeOnboardingAccountOpening","", "16")}
        {this.dynamicKycMasterDataApiCall("nonResidentCustomerRisk","", "17")}
        {this.dynamicKycMasterDataApiCall("forForeignNationals","", "18")}
        {this.dynamicKycMasterDataApiCall("accordingBfiuCustomer","", "19")}
        {this.dynamicKycMasterDataApiCall("accordingBfiuFamily","", "20")}
        {this.dynamicKycMasterDataApiCall("customerProvidedReliableInformation","", "22")}
        {this.dynamicKycMasterDataApiCall("selectFromTheList","", "23")}





        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("lllllllllllllllllpppp");
                console.log(response.data);
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.findByVarNameApiData("FincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error)
            });
        if (this.props.appId !== undefined) {
            let remarksArray = [];
            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    response.data.map((data) => {

                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                .catch((error) => {
                    console.log(error)
                });

            let url = backEndServerURL + '/variables/' + this.props.appId;
            let variableData = [];
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.removeNullValue(response.data);
                    let varValue = this.removeNullValue(response.data);
                    inputData.makerRemarks = undefined;
                    varValue.makerRemarks = undefined;
                    if(inputData.checker_approval === undefined){
                        this.functionForSetInquiry(inputData, varValue)
                    }



                    this.setState({
                        getData: true,
                        showValue: true,
                        appId: this.props.appId,
                        varValue: this.removeNullValue(inputData),
                        inputData: this.removeNullValue(varValue),
                        appData: response.data,
                        loaderNeeded: true,
                        loading: false,
                    });


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        } else {
            url = backEndServerURL + "/startCase/sd_maker_maintenance_approval";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        appId: response.data.id,
                        appData: response.data.inputData,
                        loading: false,
                        getData: true
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })

        }


    }




    commonAPiLoad = () => {
        let remarksArray = [];
        let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
        axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var status = "";
                var tableArray = [];
                response.data.map((deferal) => {
                    if (deferal.status === "ACTIVE") {
                        status = "Approved By BM"
                    } else {
                        status = deferal.status
                    }
                    tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                });
                this.setState({
                    getDeferalList: tableArray
                });
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        response.data.map((data) => {

                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            });
    };

    functionForSetInquiry = (finalInputValue, finalVarValue) => {
        CommonApi.setCaseInquiry(finalInputValue, this.state.appId)
            .then((saveResponse) => {
                this.setState({
                    inputData: finalInputValue,
                    varValue: finalVarValue,
                    showValue: true,
                    getData: true,
                });
            }).catch((error) => {
            console.log(error);
        })
    };





    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined" ||
                clone[prop] === "-1"
            )
                delete clone[prop];
        return clone;
    };



    //end



    dynamicKycMasterDataApiCall = (fieldName, apiType, code) => {
        let tpKycUrl = backEndServerURL + "/finMasterData/kycIndividual/customerType/" + code;
        axios.get(tpKycUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (code === "23") {
                    this.findByVarNameKycMasterDataApiData("selectFromTheList", response.data);
                    this.findByVarNameKycMasterDataApiData("listBusinessService", response.data);
                    this.findByVarNameKycMasterDataApiData("listBusinessService1", response.data);
                    this.findByVarNameKycMasterDataApiData("listBusinessService2", response.data);
                } else if (code === "8") {
                    this.findByVarNameKycMasterDataApiData("passportCopyReceived", response.data);
                    this.findByVarNameKycMasterDataApiData("nationalIdCardNoCopyReceived", response.data);
                    this.findByVarNameKycMasterDataApiData("birthCertificateNoCopyReceived", response.data);
                    this.findByVarNameKycMasterDataApiData("electronicTaxIdCopyReceived", response.data);
                    this.findByVarNameKycMasterDataApiData("otherDocumentationCopyReceived", response.data);
                } else if (code === "9") {
                    this.findByVarNameKycMasterDataApiData("passportVerified", response.data);
                    this.findByVarNameKycMasterDataApiData("nationalIdCardNoVerified", response.data);
                    this.findByVarNameKycMasterDataApiData("birthCertificateNoVerified", response.data);
                    this.findByVarNameKycMasterDataApiData("electronicTaxIdVerified", response.data);
                    this.findByVarNameKycMasterDataApiData("otherDocumentationVerified", response.data);
                }else if (code === "2") {
                    this.findByVarNameKycMasterDataApiData("TPandKycAccountType", response.data);
                    this.setState({
                        TPandKycAccountType: response.data
                    })
                } else if (code === "3") {
                    this.findByVarNameKycMasterDataApiData("customersOccupation", response.data);
                    this.setState({
                        customersOccupation: response.data
                    })
                } else if (code === "4") {
                    this.findByVarNameKycMasterDataApiData("howTheSourcesOfFundHaveBeenVerified", response.data);
                    this.setState({
                        howTheSourcesOfFundHaveBeenVerified: response.data
                    })
                }else if (code === "6") {
                    this.findByVarNameKycMasterDataApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data);
                    this.setState({
                        howTheAddressOfTheAccountHolderHasBeenVerified: response.data
                    })
                } else {
                    this.findByVarNameKycMasterDataApiData(fieldName, response.data);
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    findByVarNameKycMasterDataApiData = (searchVarname, getValue) => {
        let kycArrayList = jsonFormForTpKyc;
        for (let i = 0; i < kycArrayList.length; i++) {
            let jsonObjectLast = kycArrayList[i];
            if (jsonObjectLast.varName === searchVarname) {
                jsonFormForTpKyc[i].enum = getValue;

            }
        }

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];*/

        return clone;
    };

    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmitReturn") {
            this.handleSubmitReturn()
        } else if (data === "YES" && this.state.actionType === "handleSubmitSave") {
            this.handleSubmitSave()
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.handleSubmit()
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()
        this.validationForHandleConfirm(type)


    };
    validationForHandleConfirm = (actionType) => {
        console.log(this.state.inputData);
        console.log(this.state.errorArray);
        console.log(this.state.errorMessages);
        console.log("All Input Data");
        let dependencyField = [];
        this.setState({
            loading: true
        });
        if (actionType === "handleSubmit") {
            console.log(this.state.inputData);
            console.log(this.state.varValue);
            let percentage = 0;
            if ((this.state.inputData.updateType === "CB Update" )) {
                dependencyField = (jsonFormForCB);
            }if (this.state.inputData.updateType === "Account Update") {
                dependencyField = (jsonFormForAccount);
            }if (this.state.inputData.updateType === "Tp and Kyc Update") {
                dependencyField = (jsonFormForTpKyc);
            }
            if(this.state.inputData.updateType === "CB and Account Update"){
                dependencyField = (jsonFormForCbAndAccount);
            }
            if (this.state.inputData["numberOfNominee"] !== undefined) {
                for (let i = 0; i < Number(this.state.inputData["numberOfNominee"]) + Number(this.state.inputData["nomineeNumberOld"]); i++) {
                    percentage += Number(this.state.inputData["percentageNominee" + (i + 1)]);

                }

                /*  if (this.state.inputData.miscustomerStatusNormalStaffPriorityEtc === "STAFF") {
                      dependencyField.push({
                              "varName": "misstaffIndicatorStatus",
                              "type": "select",
                              "enum": [
                                  "Y",
                                  "N"
                              ],
                              "label": "Staff Indicator/Status",
                              //inputData.misstaffIndicatorStatus=inputData.staffIndicator
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
                              "conditionalVarValue": "STAFF",
                              "required": true,


                          },
                          {
                              "varName": "misstaffIdNumber",
                              "type": "text",
                              "label": "Staff ID Number",
                              "validation": "staffId",
                              //inputData.misstaffIdNumber=inputData.staffIdNumber
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
                              "conditionalVarValue": "STAFF",
                              "required": true,
                          },
                      )
                  }
                  if (this.state.inputData.misstaffIndicatorStatus === "Y") {
                      dependencyField.push({
                          "varName": "misstaffIdNumber",
                          "type": "text",
                          "label": "Staff ID Number",
                          //inputData.misstaffIdNumber=inputData.staffIdNumber
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "mispriorityCenterCodeFreeCode1",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
                          "label": "Priority Center Code/Free Code 1",
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "mispriorityMonitoringRmCodeFreeCode3",
                          "type": "textApiCall",
                          "enum": [],
                          //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
                          "label": "Priority Monitoring  RM Code/Free Code 3",
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "misfunctionCodeForChequeBookWaiver",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.misfunctionCodeForChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
                          "label": "Function Code for Cheque Book Waiver",
                          "grid": 6,
                          "conditional": true,
                          "conditionalVarName": "customerStatus",
                          "conditionalVarValue": "Priority",
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "miswaiverTypeForChequeBookWaiver",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.waiverTypeforChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
                          "label": "Waiver Type  for Cheque Book Waiver",
                          "grid": 6,
                          "conditional": true,
                          "conditionalVarName": "customerStatus",
                          "conditionalVarValue": "Priority",
                          "required": true,
                      })
                  }
                  if (this.state.inputData.basicInformationUpdateminorStatusMinorIndicator === "Y") {
                      dependencyField.push({
                              "varName": "guardianDetailsrelation",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Relation",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsbankRelationType",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Bank Relation Type",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsrelation1",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Relation",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsguardianSelectionGurdian",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Guardian Selection/Gurdian",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                          },

                          {
                              "varName": "guardianDetailsgurdianCifNumberCustomer",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Gurdian CIF Number/Customer ",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailslastName",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Last Name",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },)
                  }*/

                if (percentage !== 0) {
                    if (percentage !== 100) {
                        for (let i = 0; i < Number(this.state.inputData["numberOfNominee"]) + Number(this.state.inputData["nomineeNumberOld"]); i++) {
                            dependencyField.push({
                                "varName": "percentageNominee" + (i + 1),
                                "type": "text",
                                "label": "Percentage %",
                                "grid": 6,
                                required: true,
                            })

                        }
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorMessage: "Nominee Percentage should be 100%",
                        });
                        return 0;

                    }
                }
            }
        }
        this.setState({
            loading: false,
        });

        //let data = (this.props.commonJsonForm.concat(this.props.commonJsonFormLast));
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (this.state.fileUploadData !== undefined && Object.keys(this.state.fileUploadData).length > 0) {
            let files = [];
            let {nid} = this.state.fileUploadData;
            if (nid !== undefined) {
                files.push({file: nid, type: "tp kyc document"})
            }
            let formData = new FormData();
            formData.append("appId", this.state.appId);
            files.map((item, index) => {
                console.log(item.file, item.type);
                formData.append("file", item.file);
                formData.append("type", item.type)
            });
            let fileUploadPath = backEndServerURL + "/case/upload";
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            }).then((response) => {
                this.functionForCaseRoute()
                console.log("successful")
            }).catch((error) => {

                this.setState({
                    cbNotFound: true,
                    title: "File Upload failed!",
                    loading: false
                })
            })
        }
        if (error === true) {
            console.log("validation");
            return 0;
        } else {
            this.setState({
                confirmAlert: true,
                loading: false,
                actionType: actionType
            })

        }

    };

    handleSubmit = (data) => {

    let saveDataDeferralRemarksAndRoute = {};
    this.setState({
        loading: true
    });


    var appId = this.state.appId;
    var approval = data;

    this.state.inputData.maker_approval = data;
    //this.state.inputData["csDeferal"] = "NO";
    let inputData = this.state.inputData;

    /* saveDataDeferralRemarksAndRoute.map = inputData;
     if (this.state.inputData.bmRemarks !== undefined && this.state.inputData.bmRemarks !== null) {
         saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bmRemarks;
     }
     */
    console.log(inputData.requestTypes)
/*    if (inputData.requestTypes !== undefined && inputData.requestTypes.key !== undefined) {
        inputData.requestType = inputData.requestTypes.value;
        inputData.role = inputData.requestTypes.key;

        if (this.state.fileUploadData.fileUpload !== undefined) {
            let uploadUrl = backEndServerURL + "/case/upload";

            let formData = new FormData();
            formData.append("appId", this.state.appId);
            if (Object.values(this.state.fileUploadData).length === 1) {
                formData.append("file", this.state.fileUploadData['fileUpload']);
                formData.append("type", "file1");
            }
            axios({
                method: 'post',
                url: uploadUrl,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        reference: true,
                        referenceTitle: "File Upload Not Successfully ,please again try",
                        title: "Successful!",
                    })
                    return 0;
                })
        }
        // if (this.state.inputData.remarks !== undefined && this.state.inputData.remarks !== null) {
        //     var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.state.appId;
        //     axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        //         .then((response) => {
        //             console.log(response.data)
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //         });
        // }
        // inputData.appId = this.state.appId;
        // inputData.caseId = this.state.caseId;
        // console.log(inputData)
        // var url = backEndServerURL + "/auxiliaryTak/add";
        // axios.post(url, inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        //     .then((response) => {
        //         console.log(response.data);
        //         this.setState({
        //             reference: true,
        //             referenceTitle: "Reference Number : " + response.data.id,
        //             title: "Successful!",
        //             notificationMessage: "Successfully Routed!",
        //             alert: true,
        //             loading: false,
        //         });


        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.setState({
        //             loading: false
        //         })
        //     });
    }*/

    //end





        // let saveDataDeferralRemarksAndRoute = {};
        console.log(this.state.agentBankingOutletList);
        let percentage = 0;
        if (this.state.inputData["numberOfNominee"] !== undefined) {
            for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                percentage += Number(this.state.inputData["percentageNominee" + (i + 1)]);

            }
        }

        /* let error = MyValidation.defaultValidation(this.emptyValueRemove(data), this.state)
         this.forceUpdate();
         if (error === true) {
             //  return 0;
         } */
        this.setState({
            loading: true
        });
        if ((this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null)) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        /*  if (this.state.inputData["deferalNeed"] === "YES") {
              var defType = [];
              var expDate = [];
              var defOther = [];
              let appId = this.state.appId;
              for (let i = 0; i < this.state.values.length; i++) {
                  let value = this.state.values[i];
                  let defferalType = this.state.inputData["defferalType" + value];
                  if (defferalType === "other") {
                      defferalType = this.state.inputData["defferalOther" + value];
                  }
                  defType.push(defferalType);
                  let expireDate = this.state.inputData["expireDate" + value];
                  expDate.push(expireDate);

                  console.log(expDate)
              }

              let deferalRaisedUrl = backEndServerURL + "/deferral/raisedSd/Bulk";
              axios.post(deferalRaisedUrl, {
                  appId: appId,
                  type: defType,
                  dueDate: expDate
              }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      console.log(response.data);
                  })
                  .catch((error) => {
                      console.log(error);
                  })
          }*/
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";

        let array = [];
        let arrayCustomerList = [];


        for (let Tagging in this.state.getTaggingList) {

            array.push(this.state.getTaggingList[Tagging]);
        }

        this.state.inputData.tagging = this.state.getTaggingList;
        //this.state.inputData.accountNumber = "2302753028001";

        // let inputData = this.emptyValueRemove(this.state.inputData);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        if (this.state.inputData.updateType === "CB Update" || this.state.inputData.updateType === "CB and Account Update") {
            inputData.apiButton = "updateCustomerInfo";
        } else if (this.state.inputData.updateType === "Account Update"  || this.state.inputData.updateType === "CB and Account Update" || this.state.inputData.updateType === "Signature card Update") {
            inputData.apiButton = "updateAccountInfo";
        }
        else if (this.state.inputData.updateType === "Tp and Kyc Update") {
            inputData.apiButton = "addAccountTpThenKYC";
        }

        inputData.serviceType = "Maintenance";
        inputData.subServiceType = "AccountMaintenance";
        inputData.category = "CASA";
        inputData.subCategory = this.state.inputData.updateType;
        inputData.maker_update_all_info_send_to = "CHECKER";
        inputData.maker_approval = "APPROVED";
        if(this.state.inputData.updateType === "CB Update" || this.state.inputData.updateType === "CB and Account Update"){
            inputData.customerName=inputData.miscustomerNameLastName;
        }

        saveDataDeferralRemarksAndRoute.map = inputData;

     /*   let data = {};
        data.accountType = this.state.inputData.accType;
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            data.accountCategory = "individual";
            data.accountType = this.state.inputData.accType;
        } else {
            data.accountCategory = "nonindividual";
            data.accountType = this.state.inputData.accTypes;
        }

        data.cbNumber = this.state.inputData.cbNumber;


        data.accountNumber = this.state.inputData.accountNumber;*/
        console.log(this.state.inputData);
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                if (this.props.appId !== undefined && this.props.appId !== null) {
                    this.props.closeModal()
                } else {
                    setTimeout(() => {
                        window.location.reload()
                        //
                    }, 1000)
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    handleSubmitSave = () => {
        let variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
        this.setState({
            loading: true,
        });
        let data = this.state.inputData;
        data.makerRemarks = undefined;
        data.dataSaved = true;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    loading: false,

                });
                this.props.closeModal()
                //


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,

                })
            });

    };
    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };
    tagging = () => {
        this.setState({
            generateAccountNo: ""
        })
    };


    handleSubmitReturn = (data) => {

        var percentage = 0;
        let dependencyField = [];
        let saveDataDeferralRemarksAndRoute = {};

        /*  dependencyField.push({

              "varName": "makerRemarks",
              "type": "textArea",
              "required": true,
              "label": "Maker Remarks",
              "grid": 12
          })

          let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state)
          this.forceUpdate();
          if (error === true) {
              return 0;
          }*/
        //this.state.inputData.next_user = this.state.inputData.cs_send_to;
        this.state.inputData.maker_update_all_info_send_to = "CS";
        this.state.inputData.apiButton = "UPDATE";
        this.state.varValue.apiButton = "UPDATE";


        this.setState({
            loading: true,
        });
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        saveDataDeferralRemarksAndRoute.map = this.state.inputData;
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false,
                });
                this.props.closeModal()

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });


    };


    renderJsonFormFirst = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };

    handleTPPopulate = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
            TpAutopopulateFlag: false,
        });
        //let inputData = this.state.inputData;
        //let varValue = this.state.varValue;
        setTimeout(() => {
            this.state.inputData.TpMonthlyProbableIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;
            this.state.inputData.TpAccountName = this.state.inputData.miscustomerNameLastName;
            this.state.varValue.TpMonthlyProbableIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;
            this.state.varValue.TpAccountName = this.state.inputData.miscustomerNameLastName;
            this.forceUpdate();
            this.setState({
                // inputData: inputData,
                //varValue: varValue,
                showValue: true,
                getValue: true,
                loading: false,
                TpAutopopulateFlag: true,
            })
        }, 500)


    };
    handleTPPopulateSummation = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        //let inputData = this.state.inputData;
        //let varValue = this.state.varValue;
        // else if (data === "cashDepositNumberOfCash" || data === "depositbyNumberOfCash" || data === "foreignInwardNumberOfCash" || data === "depositofIncomeNumberOfCash" || data === "depositTransferNumberOfCash" || data === "othersNumberOfCash") {
        this.state.inputData.totalProbableNumberOfCashApiCall = false;
        this.state.inputData.totalProbableTotalCashApiCall = false;
        this.state.inputData.totalProbableMaximumCashApiCall = false;
        this.state.inputData.totalProbableNumberofWithdrawalApiCall = false;
        this.state.inputData.totalProbableTotalWithdrawalApiCall = false;
        this.state.inputData.totalProbablemaximumAmountApiCall = false;

        this.forceUpdate();
        setTimeout(() => {
            let a = this.state.inputData.cashDepositNumberOfCash;
            let b = this.state.inputData.depositbyNumberOfCash;
            let c = this.state.inputData.foreignInwardNumberOfCash;
            let d = this.state.inputData.depositofIncomeNumberOfCash;
            let e = this.state.inputData.depositTransferNumberOfCash;
            let f = this.state.inputData.othersNumberOfCash;

            if (a === undefined) {
                b = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }

            let total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableNumberOfCashApiCall = true;
            this.state.inputData.totalProbableNumberOfCash = total;
            this.state.varValue.totalProbableNumberOfCash = total;
            // }
            // else if (data === "cashDepositTotalCash" || data === "depositbyTotalCash" || data === "foreignInwardTotalCash" || data === "depositofIncomeTotalCash" || data === "depositTransferTotalCash" || data === "othersTotalCash") {

            a = this.state.inputData.cashDepositTotalCash;
            b = this.state.inputData.depositbyTotalCash;
            c = this.state.inputData.foreignInwardTotalCash;
            d = this.state.inputData.depositofIncomeTotalCash;
            e = this.state.inputData.depositTransferTotalCash;
            f = this.state.inputData.othersTotalCash;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableTotalCashApiCall = true;
            this.state.inputData.totalProbableTotalCash = total;
            this.state.varValue.totalProbableTotalCash = total;
            console.log(this.state.inputData.totalProbableTotalCash);

            // }
            //else if (data === "cashDepositMaximumCash" || data === "depositbyMaximumCash" || data === "foreignInwardMaximumCash" || data === "depositofIncomeMaximumCash" || data === "depositTransferMaximumCash" || data === "othersMaximumCash") {

            a = this.state.inputData.cashDepositMaximumCash;
            b = this.state.inputData.depositbyMaximumCash;
            c = this.state.inputData.foreignInwardMaximumCash;
            d = this.state.inputData.depositofIncomeMaximumCash;
            e = this.state.inputData.depositTransferMaximumCash;
            f = this.state.inputData.othersMaximumCash;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableMaximumCashApiCall = true;
            this.state.inputData.totalProbableMaximumCash = total;
            this.state.varValue.totalProbableMaximumCash = total;
            console.log(this.state.inputData.totalProbableNumberOfCash);

            //  }
            // else if (data === "cashWithdrawalNumberofWithdrawal" || data === "withdrawalthroughNumberofWithdrawal" || data === "foreignOutwardNumberofWithdrawal" || data === "paymentAgainstNumberofWithdrawal" || data === "depositTransferNumberofWithdrawal" || data === "othersNumberofWithdrawal") {

            a = this.state.inputData.cashWithdrawalNumberofWithdrawal;
            b = this.state.inputData.withdrawalthroughNumberofWithdrawal;
            c = this.state.inputData.foreignOutwardNumberofWithdrawal;
            d = this.state.inputData.paymentAgainstNumberofWithdrawal;
            e = this.state.inputData.depositTransferNumberofWithdrawal;
            f = this.state.inputData.othersNumberofWithdrawal;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableNumberofWithdrawalApiCall = true;
            this.state.inputData.totalProbableNumberofWithdrawal = total;
            this.state.varValue.totalProbableNumberofWithdrawal = total;
            console.log(this.state.inputData.totalProbableNumberofWithdrawal);

            // }
            // else if (data === "cashWithdrawalTotalWithdrawal" || data === "withdrawalthroughTotalWithdrawal" || data === "foreignOutwardTotalWithdrawal" || data === "paymentAgainstTotalWithdrawal" || data === "depositTransferTotalWithdrawal" || data === "othersTotalWithdrawal") {

            a = this.state.inputData.cashWithdrawalTotalWithdrawal;
            b = this.state.inputData.withdrawalthroughTotalWithdrawal;
            c = this.state.inputData.foreignOutwardTotalWithdrawal;
            d = this.state.inputData.paymentAgainstTotalWithdrawal;
            e = this.state.inputData.depositTransferTotalWithdrawal;
            f = this.state.inputData.othersTotalWithdrawal;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableTotalWithdrawalApiCall = true;
            this.state.inputData.totalProbableTotalWithdrawal = total;
            this.state.varValue.totalProbableTotalWithdrawal = total;
            console.log(this.state.inputData.totalProbableTotalWithdrawal);

            // }
            // else if (data === "cashWithdrawalmaximumAmount" || data === "withdrawalthroughmaximumAmount" || data === "foreignOutwardmaximumAmount" || data === "paymentAgainstmaximumAmount" || data === "depositTransfermaximumAmount" || data === "othersmaximumAmount") {

            a = this.state.inputData.cashWithdrawalmaximumAmount;
            b = this.state.inputData.withdrawalthroughmaximumAmount;
            c = this.state.inputData.foreignOutwardmaximumAmount;
            d = this.state.inputData.paymentAgainstmaximumAmount;
            e = this.state.inputData.depositTransfermaximumAmount;
            f = this.state.inputData.othersmaximumAmount;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbablemaximumAmountApiCall = true;
            this.state.inputData.totalProbablemaximumAmount = total;
            this.state.varValue.totalProbablemaximumAmount = total;
            this.forceUpdate();

            // }
            this.setState({
                //inputData: inputData,
                //varValue: varValue,
                showValue: true,
                getValue: true,
                loading: false,
            })
        }, 500);


    };
    handleKycPopulate = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
            kycAutopopulateFlag: false,
        });
        //let inputData = this.state.inputData;
        // let varValue = this.state.varValue;
        /*  inputData.passportNo = inputData.passportNumberpassportNumberUniqueId;
          varValue.passportNo = varValue.passportNumberpassportNumberUniqueId;
          inputData.nationalIdCardNo = inputData.nationalIdCardNumberUniqueId;
          varValue.nationalIdCardNo = varValue.nationalIdCardNumberUniqueId;
          inputData.nameOfOfficerOpeningTheAccount = inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
          varValue.nameofOfficerOpeningtheAccount = varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
          inputData.sourceOfFundKyc = inputData.TpSourceofFundForTransaction;
          varValue.sourceOfFundKyc = varValue.TpSourceofFundForTransaction;




          inputData.customerAccount=inputData.

              inputData.aCTitle=inputData.

              inputData.accountType=inputData.



              inputData.customerProbableMonthlyIncome=inputData.

              inputData.accountOrReference=inputData.

              inputData.nameofOfficerOpeningtheAccount=inputData.

              inputData.sourceOfFundKyc=inputData.

              inputData.passportNo=inputData.

              inputData.nationalIdCardNo=inputData.

              inputData.birthCertificateNo=inputData.

              inputData.electronicTaxId=inputData.*/

        //inputData.drivingLicenceNo=inputData.

        setTimeout(() => {
            this.state.inputData.customerProbableMonthlyIncome = this.state.inputData.TpMonthlyProbableIncome;
            this.state.inputData.nameofOfficerOpeningtheAccount = this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            this.state.inputData.sourceOfFundKyc = this.state.inputData.TpSourceofFundForTransaction;
            this.state.inputData.aCTitle = this.state.inputData.miscustomerNameLastName;
            this.state.inputData.passportNo = this.state.inputData.passportNumberpassportNumberUniqueId;
            this.state.inputData.nationalIdCardNo = this.state.inputData.nationalIdCardNumberUniqueId;
            this.state.inputData.birthCertificateNo = this.state.inputData.birthCertificatebirthCertificateNumberUniqueId;
            this.state.inputData.electronicTaxId = this.state.inputData.etineTinNumber;
            this.state.inputData.electronicTaxId = this.state.inputData.etineTinNumber;
            this.state.inputData.expectedAmountOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableTotalCash) + Number(this.state.inputData.totalProbableTotalWithdrawal);
            this.state.inputData.expectedNumberOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableNumberOfCash) + Number(this.state.inputData.totalProbableNumberOfCash);
            this.state.inputData.expectedAmountOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositTotalCash) + Number(this.state.inputData.cashWithdrawalTotalWithdrawal);
            this.state.inputData.expectedNumberOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositNumberOfCash) + Number(this.state.inputData.cashWithdrawalNumberofWithdrawal);

            this.state.varValue.customerProbableMonthlyIncome = this.state.inputData.TpMonthlyProbableIncome;
            this.state.varValue.nameofOfficerOpeningtheAccount = this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            this.state.varValue.sourceOfFundKyc = this.state.inputData.TpSourceofFundForTransaction;
            this.state.varValue.aCTitle = this.state.inputData.miscustomerNameLastName;
            this.state.varValue.passportNo = this.state.inputData.passportNumberpassportNumberUniqueId;
            this.state.varValue.nationalIdCardNo = this.state.inputData.nationalIdCardNumberUniqueId;
            this.state.varValue.birthCertificateNo = this.state.inputData.birthCertificatebirthCertificateNumberUniqueId;
            this.state.varValue.electronicTaxId = this.state.inputData.etineTinNumber;

            this.state.varValue.expectedAmountOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableTotalCash) + Number(this.state.inputData.totalProbableTotalWithdrawal);
            this.state.varValue.expectedNumberOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableNumberOfCash) + Number(this.state.inputData.totalProbableNumberOfCash);
            this.state.varValue.expectedAmountOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositTotalCash) + Number(this.state.inputData.cashWithdrawalTotalWithdrawal);
            this.state.varValue.expectedNumberOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositNumberOfCash) + Number(this.state.inputData.cashWithdrawalNumberofWithdrawal);
            this.forceUpdate();
            this.setState({
                //inputData: inputData,
                //varValue: varValue,
                showValue: true,
                getValue: true,
                loading: false,
                kycAutopopulateFlag: true,
            })
        }, 500)


    };
    autopopulateButtonKyC = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleKycPopulate(event)}

                    >Add KYC
                    </button>
                </div>

            )
        }
    };
    autopopulateButtonTP = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleTPPopulate(event)}

                    >Add TP
                    </button>
                </div>

            )
        }
    };
    autopopulateSummationTP = () => {
        if (this.state.TpAutopopulateFlag && this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleTPPopulateSummation(event)}

                    >Auto Population Summation
                    </button>
                </div>

            )
        }
    };

    renderJsonFormLast = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormLast, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };
    renderTPForm = () => {
        if (this.state.TpAutopopulateFlag && this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.tp, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };
    renderKYCForm = () => {
        if (this.state.kycAutopopulateFlag && this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.kyc, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };
    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNominee"] = option.title;
            this.forceUpdate()
        } else {
            this.state.inputData["numberOfNominee"] = option;
            this.forceUpdate()
        }

    };


    renderNomineeNumber = () => {
        if (this.state.getData && (this.state.inputData.updateType === "Account Update"   || this.state.inputData.updateType === "CB and Account Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {
            //if (this.props.delStatus !== "Return" && this.props.delStatus !== "RETURN") {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={{title: this.state.inputData.numberOfNominee}}
                                      options={numberOfNominee} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"

                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>


            )

        }
    };
    renderRemarksData = () => {


        if (this.state.getData && this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0 && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {

            return (
                <div style={{marginTop: "5px"}}>

                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    renderSubmitButton = () => {
        if (this.state.loading === false && this.state.getData && ((this.state.inputData.updateType === "CB Update" || this.state.inputData.updateType === "CB and Account Update") && (this.state.cbDataPopulate || this.props.appId !== undefined)) || ((this.state.inputData.updateType === "Account Update"  || this.state.inputData.updateType === "CB and Account Update" || this.state.inputData.updateType === "Tp and Kyc Update" || this.state.inputData.updateType === "Signature card Update") && (this.state.accountDataPopulate || this.props.appId !== undefined))) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}

                    >Submit
                    </button>

                </div>


            )
        }
    };

    closeModal = (account) => {

        this.setState({
            renderCumModalopen: false,
            getMappingCropImage: false,
            errorModalBoolean: false,
            cbGenerateModal: false,

            generateAccountNo: account,
            imageCropModal: false
        });
        this.renderImageCrop()
    };
    renderWithoutDedupeComponent = () => {
        if (this.state.getData) {
            return (
                <WithoutDedupeComponent freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                                        freeFlag4={this.props.freeFlag4}
                                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                        introducerName={this.state.inputData.primryiintroducerNameLastName}
                                        appId={this.state.appId}
                                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                        removingTaggingData={this.removingTaggingData}
                                        renderTagging={this.renderTagging}
                                        getTaggingData={this.getTaggingData}
                                        subServiceType={this.props.subServiceType} appId={this.state.appId}/>
            )
        }
    };

    renderDedupComponent = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet && this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.state.appId}
                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.state.appId}/>
                </div>

            )
        } else if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.state.appId}
                        category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.state.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentForAgentBanking = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupeResultFunctionForAgentBanking
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        appId={this.state.appId}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.state.appId}
                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.state.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                                 accountNumber={this.props.accountNumber} solId={this.props.solId}
                                                 freeFlag4={this.props.freeFlag4}
                                                 introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                                 introducerName={this.state.inputData.primryiintroducerNameLastName}
                                                 appId={this.state.appId} category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.state.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber} solId={this.props.solId}
                                       freeFlag4={this.props.freeFlag4}
                                       introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                       introducerName={this.state.inputData.primryiintroducerNameLastName}
                                       appId={this.state.appId} category={this.props.category}
                                       taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.state.appId}/>
                </div>

            )
        }
    };


    createTaggingData = (data) => {
        return (
            [data.cbNumber, data.customerName, data.relationCode, <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
            >Edit
            </button>,
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDelete(event, data.id)}
                >Delete
                </button>
            ]


        )
    };

    renderTagging = () => {
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.state.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));

                });
                this.setState({
                    getTagList: tableArrayData
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            });
        console.log(tableArrayData)


    };

    renderTagListPrevious = () => {

        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/ && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>

                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left']}
                    />


                </div>


            )
        }

    };
    renderTagList = () => {

        if (this.state.getData && /*(this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && */ this.state.getTagList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Tagging List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer No", "CB Number", "Action"]}

                            tableData={this.state.getTagList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>


                    </div>

                </div>

            )
        }

    };
    renderImageCrop = () => {
        if ((backEndServerURL + "/signaturePhoto/" + this.state.appId + "/signature" !== undefined)) {
            return (

                <img width='100%' src={backEndServerURL + "/signaturePhoto/" + this.state.appId + "/signature"} alt=""/>
            )
        }


    };

    getTaggingData = (index, data, customerNo) => {

        /*  console.log(index)
          console.log(data)
          console.log(customerNo);
          let customerList=[];
          customerList.push(data);
          customerList.push(customerNo);
          this.state.getTaggingList["customer"+index] = customerList;
          //this.state.getTaggingCustomerList[index] = customerNo;
          this.setState({
              tagClick: true
          })
          console.log(this.state.getTaggingList);*/
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.state.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    getTagList: tableArrayData,

                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    cropImage = (event) => {
        event.preventDefault();
        this.setState({
            imageCropModal: true
        })
    };
    renderRemarks = () => {
        if (this.state.getData && ((this.state.inputData.updateType === "CB Update"  || this.state.inputData.updateType === "CB and Account Update")&& (this.state.cbDataPopulate || this.props.appId !== undefined)) || ((this.state.inputData.updateType === "Account Update"   || this.state.inputData.updateType === "CB and Account Update"  || this.state.inputData.updateType === "Tp and Kyc Update" || this.state.inputData.updateType === "Signature card Update") && (this.state.accountDataPopulate || this.props.appId !== undefined))) {

            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}

                </div>

            )
        }

    };
    renderCropedImage = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (

                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Croped Image
                </button>

            )
        }

        else if (this.state.getData && this.state.inputData.updateType === "Signature card Update") {
            return (

                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Signature Card
                </button>

            )
        }

    };
    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };
    closeCropImage = () => {
        this.setState({
            getMappingCropImage: false
        })
    };
    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false

        })
    };
    tagCbDetailsModal = (event, customer, requestType, cb, relationCode) => {
        event.preventDefault();
        this.setState({
            requestType: requestType,
            tagCbDetailsModal: true,
            tagCb: cb,
            customerNumber: customer,
            linkrelationCode: relationCode
        })

    };
    tagCbDelete = (event, id) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/secondaryCB/delete/" + id;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArrayData = [];
                let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.state.appId;
                axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        response.data.map((data, index) => {
                            tableArrayData.push(this.createTaggingData(data));
                        });
                        this.setState({
                            loading: false,
                            getTagList: tableArrayData,

                        });

                        this.forceUpdate();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(error);
                    })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error);
            })

    };

    renderSignatureCardCrop = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right'
                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }

        else if (this.state.getData && this.state.inputData.updateType === "Signature card Update") {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }
    };


    makeReadOnlyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 6) {
                returnObjectVariables[i]["grid"] = 2;
            }

        }
        return returnObject;

    };
    onKeyDownChange = (data) => {

        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 500);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.varValue.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                console.log(stringToDate);
                console.log(this.state.inputData.basicInformationUpdatedob);
                console.log(inputDate);


                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }


                this.forceUpdate()

            }, 500)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data, secondVarName) => {

        for (let i = Number(this.state.inputData["nomineeNumberOld"]); i < Number(this.state.inputData["numberOfNominee"]) + Number(this.state.inputData["nomineeNumberOld"]); i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "cbNumber") {





        }

        else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };

    renderJsonFormCBandAccount = () => {
        if (this.state.getData && (this.state.inputData.updateType === "CB Update"  || this.state.inputData.updateType === "CB and Account Update") && (this.state.cbDataPopulate || this.props.appId !== undefined)) {

            if(this.state.getData && this.state.inputData.updateType === "CB and Account Update" && (this.state.cbDataPopulate || this.props.appId !== undefined)){
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject((jsonFormForCbAndAccount)), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

                )
            }
            else{
                return (
                    CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForCB), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

                )
            }

        }else if (this.state.getData && (this.state.inputData.updateType === "Account Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForAccount), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
        else if (this.state.getData && (this.state.inputData.updateType === "Tp and Kyc Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(jsonFormForTpKyc), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)

            )
        }
        if(this.state.getData && this.state.inputData.updateType === "Signature card Update" && (this.state.accountDataPopulate || this.props.appId !== undefined)){
            return (
            <Grid item xs={12}>
                {/*<Grid container spacing={0} >*/}
                <br/>
                {this.uploadFunction()}
                <br/>
                <br/>
                    {/*<div style={{marginLeft: "0px", marginRight: "0px", marginTop: "0px"}}>*/}
                        <div style={{marginLeft: "0px", marginRight: "0px", marginDown: "0px"}}>{this.renderSignatureCardCrop()}<br/></div>
                        <div style={{marginLeft: "0px", marginRight: "0px", marginTop: "10px"}}>{this.renderCropedImage()}</div>

                    {/*</div>*/}
                {/*</Grid>*/}

            </Grid>
            )
        }

    };

    findByVarNameGetKeyValueForKyc = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (String(value).toUpperCase() === String(jsonObject.key).toUpperCase()) {
                return jsonObject;
            }
        }


    };


    renderForm = () => {
        if (this.state.getData && this.props.appId === undefined) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, selectUpdateType, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }

    };

    setCbData=(cbNumber)=>{

        let error = MyValidation.defaultValidation(selectUpdateType, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }

        else if (cbNumber !== undefined && cbNumber !== null) {
            this.setState({
                loading: true,
                accountDataPopulate: false,
                cbDataPopulate: false,

            });

            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            let url = backEndServerURL + "/cbsCustomerGet/" + (cbNumber).trim();/*getCbnumber"CB1187408"*/
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                //api 1 then
                .then((cbDatResponse) => {
                    let dataInputs = this.apiDataSet(cbDatResponse.data, inputData, "cbRelated");
                    let datavarValues = this.apiDataSet(cbDatResponse.data, varValue, "cbRelated");
                    let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + cbNumber;/*"2801187408001"*/
                    axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((cb2DataResponse) => {
                            this.state.inputData.uploadFileAlertRequest=true;
                            setTimeout(() => {
                                let dataInput = this.apiDataSet(cb2DataResponse.data, dataInputs, "cbExtraInformation");
                                let datavarValue = this.apiDataSet(cb2DataResponse.data, datavarValues, "cbExtraInformation");
                                this.setState({
                                    getNewCase: true,
                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                    inputData: this.emptyValueRemove(dataInput),
                                    varValue: datavarValue,
                                    showValue: true,
                                    cbDataPopulate: true,
                                    getData: true,
                                    loading: false,
                                    accountDataAfterSet: true,
                                    cbDataAfterSet: true,
                                })
                            }, 200)


                        })
                        .catch((error) => {
                            this.setState({
                                loading: false,
                                accountDataAfterSet: true,
                                cbDataAfterSet: true,
                                errorMessage: this.errorObjectCheck(error),
                                errorModalBoolean: true

                            })
                        })

                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        cbDataAfterSet: true,
                        errorMessage: "CB Inquiry Api Error!",
                        errorModalBoolean: true
                    })
                })
        }
    }

    setAccountData=(accountNumber)=>{
        let error = MyValidation.defaultValidation(selectUpdateType, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else if ((this.state.inputData.updateType === "Account Update" || this.state.inputData.updateType === "Signature card Update")) {
                if (accountNumber !== undefined && accountNumber !== null) {
                    this.setState({
                        loading: true,
                        accountDataPopulate: false,
                        cbDataPopulate: false,
                    });
                    let inputData = this.state.inputData;
                    let varValue = this.state.varValue;
                    let url = backEndServerURL + "/GetCustomAcctStatus/" + (accountNumber).trim();
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData = this.apiDataSet(response.data, inputData, "Dormant");
                            varValue = this.apiDataSet(response.data, inputData, "Dormant")
                        })
                        .catch((error) => {
                            console.log(error);

                        });

                    let getAccountUrl = backEndServerURL + "/getAccountInfo/" + accountNumber;/*getAccountnumber""2801187408001""*/
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((accountDataResponse) => {

                            this.state.inputData.uploadFileAlertRequest = true;

                            let dataInput = this.apiDataSet(accountDataResponse.data, inputData, "accountRelated");
                            let datavarValue = this.apiDataSet(accountDataResponse.data, varValue, "accountRelated");

                            let getAccountUrl = backEndServerURL + "/getAccountChequeWaive/" + accountNumber;/*"2801187408001"*/
                            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((accountDataResponse) => {
                                    //api 3 then

                                    let accountDataInputData = this.apiDataSet(accountDataResponse.data, dataInput, "waiverType");
                                    let accountDataVarvalue = this.apiDataSet(accountDataResponse.data, datavarValue, "waiverType");

                                    this.functionForSetInquiry(accountDataInputData, accountDataVarvalue);
                                    this.setState({
                                        getNewCase: true,
                                        //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                        inputData: this.emptyValueRemove(accountDataInputData),
                                        varValue: accountDataVarvalue,
                                        showValue: true,
                                        accountDataPopulate: true,
                                        getData: true,
                                        loading: false,
                                        accountDataAfterSet: true,
                                        cbDataAfterSet: true,
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        getNewCase: true,
                                        //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                        inputData: this.emptyValueRemove(dataInput),
                                        varValue: datavarValue,
                                        showValue: true,
                                        getData: true,
                                        loading: false,
                                        accountDataAfterSet: true,
                                        cbDataAfterSet: true,
                                    })
                                })
                            //api 4 catch
                        })
                        .catch((error) => {
                            this.setState({
                                getNewCase: true,
                                //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                inputData: this.emptyValueRemove(inputData),
                                varValue: varValue,
                                showValue: true,
                                getData: true,
                                loading: false,
                                accountDataAfterSet: true,
                                cbDataAfterSet: true,
                            })
                        })
                }
            }
    }

    setTpAnKycData=(accountNumber)=>{

        let error = MyValidation.defaultValidation(selectUpdateType, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else if (this.state.inputData.updateType === "Tp and Kyc Update") {
            if (accountNumber !== undefined && accountNumber !== null) {
                this.setState({
                    loading: true,
                    accountDataPopulate: false,
                    cbDataPopulate: false,
                });
                let inputData = this.state.inputData;
                let varValue = this.state.varValue;
                let url = backEndServerURL + "/GetCustomAcctStatus/" + (accountNumber).trim();
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.uploadFileAlertRequest=true;
                        inputData = this.apiDataSet(response.data, inputData, "Dormant");
                        varValue = this.apiDataSet(response.data, inputData, "Dormant")

                        console.log("55555", (accountNumber).trim())
                        let imageUrl = backEndServerURL + "/getAccountTpInquiry/" + (accountNumber).trim();
                        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((responseTp) => {

                                console.log("55555",responseTp.data)
                                inputData.cashDepositMaximumCash = responseTp.data.cashDepMax;
                                varValue.cashDepositMaximumCash = responseTp.data.cashDepMax;
                                inputData.cashDepositNumberOfCash = responseTp.data.cashDepMon;
                                varValue.cashDepositNumberOfCash = responseTp.data.cashDepMon;
                                inputData.cashDepositTotalCash = responseTp.data.cashDepTot;
                                varValue.cashDepositTotalCash = responseTp.data.cashDepTot;
                                inputData.depositbyMaximumCash = responseTp.data.tranDepMax;
                                varValue.depositbyMaximumCash = responseTp.data.tranDepMax;
                                inputData.depositbyNumberOfCash = responseTp.data.tranDepMon;
                                varValue.depositbyNumberOfCash = responseTp.data.tranDepMon;
                                inputData.depositbyTotalCash = responseTp.data.tranDepTot;
                                varValue.depositbyTotalCash = responseTp.data.tranDepTot;
                                inputData.foreignInwardMaximumCash = responseTp.data.foreignDepMax;
                                varValue.foreignInwardMaximumCash = responseTp.data.foreignDepMax;
                                inputData.foreignInwardNumberOfCash = responseTp.data.foreignDepMon;
                                varValue.foreignInwardNumberOfCash = responseTp.data.foreignDepMon;
                                inputData.foreignInwardTotalCash = responseTp.data.foreignDepTot;
                                varValue.foreignInwardTotalCash = responseTp.data.foreignDepTot;
                                inputData.depositofIncomeMaximumCash = responseTp.data.exportDepMax;
                                varValue.depositofIncomeMaximumCash = responseTp.data.exportDepMax;
                                inputData.depositofIncomeNumberOfCash = responseTp.data.exportDepMon;
                                varValue.depositofIncomeNumberOfCash = responseTp.data.exportDepMon;
                                inputData.depositofIncomeTotalCash = responseTp.data.exportDepTot;
                                varValue.depositofIncomeTotalCash = responseTp.data.exportDepTot;
                                inputData.depositTransferNumberOfCash = responseTp.data.depBO11;
                                varValue.depositTransferNumberOfCash = responseTp.data.depBO11;
                                inputData.depositTransferTotalCash = responseTp.data.depBO22;
                                varValue.depositTransferTotalCash = responseTp.data.depBO22;
                                inputData.depositTransferMaximumCash = responseTp.data.depBO33;
                                varValue.depositTransferMaximumCash = responseTp.data.depBO33;
                                inputData.othersMaximumCash = responseTp.data.otherDepMax;
                                varValue.othersMaximumCash = responseTp.data.otherDepMax;
                                inputData.othersNumberOfCash = responseTp.data.otherDepMon;
                                varValue.othersNumberOfCash = responseTp.data.otherDepMon;
                                inputData.othersTotalCash = responseTp.data.otherDepTot;
                                varValue.othersTotalCash = responseTp.data.otherDepTot;
                                inputData.cashWithdrawalmaximumAmount = responseTp.data.cashWdMax;
                                varValue.cashWithdrawalmaximumAmount = responseTp.data.cashWdMax;
                                inputData.cashWithdrawalNumberofWithdrawal = responseTp.data.cashWdMon;
                                varValue.cashWithdrawalNumberofWithdrawal = responseTp.data.cashWdMon;
                                inputData.cashWithdrawalTotalWithdrawal = responseTp.data.cashWdTot;
                                varValue.cashWithdrawalTotalWithdrawal = responseTp.data.cashWdTot;
                                inputData.withdrawalthroughmaximumAmount = responseTp.data.tw1;
                                varValue.withdrawalthroughmaximumAmount = responseTp.data.tw1;
                                inputData.withdrawalthroughNumberofWithdrawal = responseTp.data.tw2;
                                varValue.withdrawalthroughNumberofWithdrawal = responseTp.data.tw2;
                                inputData.withdrawalthroughTotalWithdrawal = responseTp.data.tw3;
                                varValue.withdrawalthroughTotalWithdrawal = responseTp.data.tw3;
                                inputData.foreignOutwardmaximumAmount = responseTp.data.foreignWdMax;
                                varValue.foreignOutwardmaximumAmount = responseTp.data.foreignWdMax;
                                inputData.foreignOutwardNumberofWithdrawal = responseTp.data.foreignWdMon;
                                varValue.foreignOutwardNumberofWithdrawal = responseTp.data.foreignWdMon;
                                inputData.foreignOutwardTotalWithdrawal = responseTp.data.foreignWdTot;
                                varValue.foreignOutwardTotalWithdrawal = responseTp.data.foreignWdTot;
                                inputData.paymentAgainstmaximumAmount = responseTp.data.exportWdMax;
                                varValue.paymentAgainstmaximumAmount = responseTp.data.exportWdMax;
                                inputData.paymentAgainstNumberofWithdrawal = responseTp.data.exportWdMon;
                                varValue.paymentAgainstNumberofWithdrawal = responseTp.data.exportWdMon;
                                inputData.paymentAgainstTotalWithdrawal = responseTp.data.exportWdTot;
                                varValue.paymentAgainstTotalWithdrawal = responseTp.data.exportWdTot;
                                inputData.depositTransferNumberofWithdrawal = responseTp.data.wtbo11;
                                varValue.depositTransferNumberofWithdrawal = responseTp.data.wtbo11;
                                inputData.depositTransferTotalWithdrawal = responseTp.data.wtbo22;
                                varValue.depositTransferTotalWithdrawal = responseTp.data.wtbo22;
                                inputData.depositTransfermaximumAmount = responseTp.data.wtbo33;
                                varValue.depositTransfermaximumAmount = responseTp.data.wtbo33;
                                inputData.othersmaximumAmount = responseTp.data.otherWdMax;
                                varValue.othersmaximumAmount = responseTp.data.otherWdMax;
                                inputData.othersNumberofWithdrawal = responseTp.data.otherWdMon;
                                varValue.othersNumberofWithdrawal = responseTp.data.otherWdMon;
                                inputData.othersTotalWithdrawal = responseTp.data.otherWdTot;
                                varValue.othersTotalWithdrawal = responseTp.data.otherWdTot;
                                inputData.withdrawalthroughTotalWithdrawal = responseTp.data.tranWdTot;
                                varValue.withdrawalthroughTotalWithdrawal = responseTp.data.tranWdTot;
                                inputData.withdrawalthroughmaximumAmount = responseTp.data.tranWdmax;
                                varValue.withdrawalthroughmaximumAmount = responseTp.data.tranWdmax;
                                inputData.withdrawalthroughNumberofWithdrawal = responseTp.data.tranWdmon;
                                varValue.withdrawalthroughNumberofWithdrawal = responseTp.data.tranWdmon;
                                inputData.sourceOfFundKyc = responseTp.data.sourceOfFund;
                                varValue.sourceOfFundKyc = responseTp.data.sourceOfFund;
                                this.functionForSetInquiry(inputData, varValue)
                                this.setState({
                                    inputData: this.emptyValueRemove(inputData),
                                    varValue: this.emptyValueRemove(varValue),
                                    showValue: true,
                                    getData: true,

                                })
                                let kycUrl = backEndServerURL + "/getKYCInfoAccountInfo/" + (accountNumber).trim();
                                axios.get(kycUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((responseKyc ) => {

                                        inputData.TPandKycAccountType = this.findByVarNameGetKeyValueForKyc(responseKyc.data.accountType, this.state.TPandKycAccountType);
                                        varValue.TPandKycAccountType = this.findByVarNameGetKeyValueForKyc(responseKyc.data.accountType, this.state.TPandKycAccountType);
                                        inputData.customersOccupation = this.findByVarNameGetKeyValueForKyc(responseKyc.data.custOccupation, this.state.customersOccupation);
                                        varValue.customersOccupation = this.findByVarNameGetKeyValueForKyc(responseKyc.data.custOccupation, this.state.customersOccupation);
                                        inputData.sourceOfFundKyc = responseKyc.data.sourcesOfFund;
                                        varValue.sourceOfFundKyc = responseKyc.data.sourcesOfFund;
                                        inputData.howTheSourcesOfFundHaveBeenVerified = this.findByVarNameGetKeyValueForKyc(responseKyc.data.howFund,this.state.howTheSourcesOfFundHaveBeenVerified);
                                        varValue.howTheSourcesOfFundHaveBeenVerified = this.findByVarNameGetKeyValueForKyc(responseKyc.data.howFund,this.state.howTheSourcesOfFundHaveBeenVerified);
                                        inputData.howTheAddressOfTheAccountHolderHasBeenVerified = this.findByVarNameGetKeyValueForKyc(responseKyc.data.how_verify_addr,this.state.howTheAddressOfTheAccountHolderHasBeenVerified);
                                        varValue.howTheAddressOfTheAccountHolderHasBeenVerified = this.findByVarNameGetKeyValueForKyc(responseKyc.data.how_verify_addr,this.state.howTheAddressOfTheAccountHolderHasBeenVerified);
                                        inputData.pepHasInterviewed = responseKyc.data.face_to_face_interview === "N" ? "NO" :  responseKyc.data.face_to_face_interview === "Y" ? "YES": "";
                                        varValue.pepHasInterviewed = responseKyc.data.face_to_face_interview === "N" ? "NO" :  responseKyc.data.face_to_face_interview === "Y" ? "YES": "";
                                        this.functionForSetInquiry(inputData, varValue)
                                        this.setState({
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: this.emptyValueRemove(varValue),
                                            showValue: true,
                                            getData: true,

                                        })
                                    })
                                    .catch((error) => {
                                        console.log(error);

                                    })
                            })
                            .catch((error) => {
                                console.log(error);

                            })

                    })
                    .catch((error) => {
                        console.log(error);

                    })

                let getAccountUrl = backEndServerURL + "/getAccountInfo/" + accountNumber;/*getAccountnumber""2801187408001""*/
                axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((accountDataResponse) => {
                        //api 2 then
                        let inputData=this.state.inputData;
                        let varValue=this.state.varValue;
                        let dataInput = this.apiDataSet(accountDataResponse.data, inputData, "accountRelated");
                        let datavarValue = this.apiDataSet(accountDataResponse.data, varValue, "accountRelated");

                        let getAccountUrl = backEndServerURL + "/getAccountChequeWaive/" + accountNumber;/*"2801187408001"*/
                        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((accountDataResponse) => {
                                //api 3 then

                                let accountDataInputData = this.apiDataSet(accountDataResponse.data, dataInput, "waiverType");
                                let accountDataVarvalue = this.apiDataSet(accountDataResponse.data, datavarValue, "waiverType");
                                this.setState({
                                    getNewCase: true,
                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                    inputData: this.emptyValueRemove(accountDataInputData),
                                    varValue: accountDataVarvalue,
                                    showValue: true,
                                    accountDataPopulate: true,
                                    getData: true,
                                    loading: false,
                                    accountDataAfterSet: true,
                                    cbDataAfterSet: true,
                                })
                            })
                            .catch((error) => {
                                this.setState({
                                    getNewCase: true,
                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                    inputData: this.emptyValueRemove(dataInput),
                                    varValue: datavarValue,
                                    showValue: true,
                                    getData: true,
                                    loading: false,
                                    accountDataAfterSet: true,
                                    cbDataAfterSet: true,
                                })
                            })
                        //api 4 catch
                    })
                    .catch((error) => {
                        this.setState({
                            getNewCase: true,
                            //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                            inputData: this.emptyValueRemove(inputData),
                            varValue: varValue,
                            showValue: true,
                            getData: true,
                            loading: false,
                            accountDataAfterSet: true,
                            cbDataAfterSet: true,
                        })
                    })
            }
        }
    }


    setCbandAccountNumberData=(cbNumber,accountNumber)=>{
        let error = MyValidation.defaultValidation(selectUpdateType, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else{
            this.setState({
                loading: true,
                accountDataPopulate: false,
                cbDataPopulate: false,

            });
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;

            let url = backEndServerURL + "/cbsCustomerGet/" + (cbNumber).trim();/*getCbnumber"CB1187408"*/
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                //api 1 then
                .then((cbDatResponse) => {
                    this.state.inputData.uploadFileAlertRequest=true;
                    let dataInputs = this.apiDataSet(cbDatResponse.data, inputData, "cbRelated");
                    let datavarValues = this.apiDataSet(cbDatResponse.data, varValue, "cbRelated");
                    let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + this.state.inputData.cbNumber;/*"2801187408001"*/
                    axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((cb2DataResponse) => {

                            setTimeout(() => {
                                let dataInput = this.apiDataSet(cb2DataResponse.data, dataInputs, "cbExtraInformation");
                                let datavarValue = this.apiDataSet(cb2DataResponse.data, datavarValues, "cbExtraInformation");

                                inputData = dataInput;
                                varValue = datavarValue;
                                let url = backEndServerURL + "/GetCustomAcctStatus/" + (this.state.inputData.accountNumber).trim();
                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        inputData = this.apiDataSet(response.data, inputData, "Dormant");
                                        varValue = this.apiDataSet(response.data, inputData, "Dormant")
                                    })
                                    .catch((error) => {
                                        console.log(error);

                                    });

                                let getAccountUrl = backEndServerURL + "/getAccountInfo/" + accountNumber;/*getAccountnumber""2801187408001""*/
                                axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((accountDataResponse1) => {
                                        //api 2 then
                                        let dataInput = this.apiDataSet(accountDataResponse1.data, inputData, "accountRelated");
                                        let datavarValue = this.apiDataSet(accountDataResponse1.data, varValue, "accountRelated");

                                        let getAccountUrl = backEndServerURL + "/getAccountChequeWaive/" + this.state.inputData.accountNumber;/*"2801187408001"*/
                                        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((accountDataResponse) => {
                                                //api 3 then

                                                let accountDataInputData = this.apiDataSet(accountDataResponse.data, dataInput, "waiverType");
                                                let accountDataVarvalue = this.apiDataSet(accountDataResponse.data, datavarValue, "waiverType");
                                                this.setState({
                                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                                    cbDataPopulate: true,
                                                    getNewCase: true,
                                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                                    inputData: this.emptyValueRemove(accountDataInputData),
                                                    varValue: accountDataVarvalue,
                                                    showValue: true,
                                                    accountDataPopulate: true,
                                                    getData: true,
                                                    loading: false,
                                                    accountDataAfterSet: true,
                                                    cbDataAfterSet: true,
                                                })
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    getNewCase: true,
                                                    //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                                    inputData: this.emptyValueRemove(dataInput),
                                                    varValue: datavarValue,
                                                    showValue: true,
                                                    getData: true,
                                                    loading: false,
                                                    accountDataAfterSet: true,
                                                    cbDataAfterSet: true,
                                                })
                                            })
                                        //api 4 catch
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            getNewCase: true,
                                            //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                            inputData: this.emptyValueRemove(inputData),
                                            varValue: varValue,
                                            showValue: true,
                                            getData: true,
                                            loading: false,
                                            accountDataAfterSet: true,
                                            cbDataAfterSet: true,
                                        })
                                    })

                                /*   this.setState({
                                       getNewCase: true,
                                       //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                       inputData: this.emptyValueRemove(dataInput),
                                       varValue: datavarValue,
                                       showValue: true,
                                       cbDataPopulate: true,
                                       getData: true,
                                       loading: false,
                                       accountDataAfterSet: true,
                                       cbDataAfterSet: true,
                                   })*/
                            }, 200)
                            //api 4 thencrmMissubSegment


                        })
                        //api 4 catch
                        .catch((error) => {
                            this.setState({
                                loading: false,
                                accountDataAfterSet: true,
                                cbDataAfterSet: true,
                                errorMessage: this.errorObjectCheck(error),
                                errorModalBoolean: true

                            })
                        })

                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        cbDataAfterSet: true,
                        errorMessage: "CB Inquiry Api Error!",
                        errorModalBoolean: true
                    })
                })
            this.setState({
                loading: true,
                accountDataPopulate: false,
                cbDataPopulate: false,
            });
            console.log("okkay")
            console.log(this.state.inputData)
            console.log(this.state.varValue)
        }

    }


    renderSearchButton=(cbNumber,accountNumber)=>{
        if(this.state.inputData.updateType=== undefined){
            let error = MyValidation.defaultValidation(selectUpdateType, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        if(this.state.getData && (this.state.inputData.updateType==="CB and Account Update")){
            let cbNumber;
            let url = backEndServerURL + "/GetCustomAcctStatus/" + (accountNumber).trim();
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response)
                    console.log(response.data.cifId);
                    cbNumber= response.data.cifId
                    return(
                        this.setCbandAccountNumberData(cbNumber,accountNumber)
                    )
                })
                .catch((error) => {
                    console.log(error);

                });


        }
        else if(this.state.getData && (this.state.inputData.updateType==="CB Update")){
            return(
                this.setCbData(cbNumber)
            )
        }
        else if(this.state.getData && (this.state.inputData.updateType=== "Account Update"
            || this.state.inputData.updateType === "Signature card Update")){
            return(
               this.setAccountData(accountNumber)
            )
        }
        else if(this.state.getData && (this.state.inputData.updateType=== "Tp and Kyc Update")){
            return(
               this.setTpAnKycData(accountNumber)
            )
        }



    }


    renderSearch=()=>{
        if (this.state.getData && this.props.appId === undefined) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: "15px",
                    }}
                    onClick={() => this.renderSearchButton(this.state.inputData.cbNumber, this.state.inputData.accountNumber)}
                >
                    Search
                </button>
            )
        }
    }
    closeIcon = () => {
        this.props.closeModal()
    };
    renderHeader = () => {
       /* if (this.props.appId === undefined) {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>CB And Account Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.schemeCodeForSavings !== undefined && this.state.inputData.schemeCodeForSavings !== null) ? this.state.inputData.schemeCodeForSavings.value : ((this.state.inputData.schemeCodeForCurrent !== undefined && this.state.inputData.schemeCodeForCurrent !== null) ? this.state.inputData.schemeCodeForCurrent.value : "")})
                    </p></h4>


                </CardHeader>
            )
        } else*/
        if(this.state.inputData.updateType === "Account Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Account Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode :""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }
        else if(this.state.inputData.updateType === "Tp and Kyc Update"){
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Tp and Kyc Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode :""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }
        else if(this.state.inputData.updateType === "CB Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>CB Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }
        else if(this.state.inputData.updateType === "Signature card Update") {
            return (
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>Signature card Update </b> <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> SOLID:</b>{this.state.inputData.basicInformationUpdateprimarySolId} <b> Scheme
                            Code:</b>{(this.state.inputData.upDateSchemeCode !== undefined && this.state.inputData.upDateSchemeCode !== null) ? this.state.inputData.upDateSchemeCode :""}){/*<a><CloseIcon
                            onClick={this.closeIcon}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>*/}</p></h4>


                </CardHeader>
            )
        }

        else{
            return(
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                    <h4 style={{color: "white"}} ><b>CB and Account Update </b>  </h4>


                </CardHeader>
            )
        }
    };
    renderExistingNomineeForm = () => {
        if (this.state.getData && (this.state.inputData.updateType === "Account Update"   || this.state.inputData.updateType === "CB and Account Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {
            var sl;
            objectForNominee = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            if (this.state.inputData["nomineeNumberOld"] !== undefined && this.state.inputData["nomineeNumberOld"] !== null) {
                objectForNominee.push(
                    {
                        "type": "title",
                        "label": "Existing Nominee Information",
                        "grid": 12,
                    },
                    {
                        "varName": "nomineeNumberOld",
                        "type": "text",
                        "readOnly": true,
                        "label": "Existing Nominee",
                        "grid": 6,
                    },
                    {
                        "varName": "nomineeRegNo",
                        "type": "text",
                        "label": "Registration No",
                        "grid": 6,
                        "required": true,

                    },
                )
            }

            for (var i = 0; i < this.state.inputData["nomineeNumberOld"]; i++) {
                sl = i + 1;
                //inputData["nomineecifIdApiCall"+i]=false
                //varValue["nomineecifIdApiCall"+i]=false
                objectForNominee.push(
                    {
                        "varName": "Nominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "label": "Input CIF ID/INFORMATION ?",
                        "grid": 6,


                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    /*  {
                            "varName": "nomineenomineephotoIdNo" + i,
                            "type": "text",
                            "label": "NOMINEE PHOTO ID NO.",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "nomineecifIdApiCall" + i,
                            "conditionalVarValue": true,
                        },
        */
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {

                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "text",
                        "label": "D.O.B(YYYY-MM-DD)",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        /*    "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",*/
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        /*  "conditional": true,
                          "conditionalVarName": "search" + sl,
                          "conditionalVarValue": "INFORMATION",*/
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    //Information guardian details
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(objectForNominee), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    renderNewNomineeForm = () => {
        if (this.state.getData && (this.state.inputData.updateType === "Account Update"   || this.state.inputData.updateType === "CB and Account Update" || this.state.inputData.updateType === "Tp and Kyc Update") && (this.state.accountDataPopulate || this.props.appId !== undefined)) {
            var sl;
            objectForNomineeForNew = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            if (this.state.inputData["numberOfNominee"] !== undefined && this.state.inputData["numberOfNominee"] !== null) {
                objectForNomineeForNew.push(
                    {
                        "type": "title",
                        "label": "New Nominee",
                        "grid": 12,
                    },

                    {
                        "varName": "nomineeRegNo",
                        "type": "text",
                        "label": "Registration No",
                        "grid": 6,
                        "required": true,

                    },
                )
            }

            for (var i = Number(this.state.inputData["nomineeNumberOld"]); i < Number(Number(this.state.inputData["numberOfNominee"]) + Number(this.state.inputData["nomineeNumberOld"])); i++) {
                sl = i + 1;
                //inputData["nomineecifIdApiCall"+i]=false
                //varValue["nomineecifIdApiCall"+i]=false
                objectForNomineeForNew.push(
                    {
                        "varName": "Nominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "label": "Input CIF ID/INFORMATION ?",
                        "grid": 6,


                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    /*  {
                            "varName": "nomineenomineephotoIdNo" + i,
                            "type": "text",
                            "label": "NOMINEE PHOTO ID NO.",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "nomineecifIdApiCall" + i,
                            "conditionalVarValue": true,
                        },
        */
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {

                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "text",
                        "label": "D.O.B(YYYY-MM-DD)",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        /*    "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",*/
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        /*  "conditional": true,
                          "conditionalVarName": "search" + sl,
                          "conditionalVarValue": "INFORMATION",*/
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    //Information guardian details
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObject(objectForNomineeForNew), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };

    uploadFunction = () => {
        // if(this.state.getData && this.state.inputData.updateType=== "Signature card Update") {
        if(this.state.getData && ((this.state.inputData.updateType=== "Signature card Update")
            || (this.state.inputData.updateType === "CB Update" && (this.state.cbDataPopulate || this.props.appId !== undefined))
            || (this.state.inputData.updateType === "Account Update" && (this.state.accountDataPopulate || this.props.appId !== undefined))
            || (this.state.inputData.updateType === "CB and Account Update" && ((this.state.cbDataPopulate && this.state.accountDataPopulate) || this.props.appId !== undefined))
            || (this.state.inputData.updateType === "Tp and Kyc Update" && (this.state.accountDataPopulate || this.props.appId !== undefined)))) {

            const {classes} = this.props;
            if (this.state.getData) {
                return (
                        /*<Grid item xs={11}>
                            <UploadComponent  style={{marginTop: "-6px"}}
                                              previewImage={this.previewImage} showSignature={false}
                                             appId={this.state.appId}
                                             classes={classes}/>
                        </Grid>*/

                    <GridContainer>
                        <Grid item xs={0.5}>
                            <CheckBox
                                style={{marginTop: "-6px"}}
                                checked={this.state.inputData.uploadFileAlertRequest}
                                defaultValue={this.state.inputData.uploadFileAlertRequest}
                                onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                        </Grid>
                        <Grid item xs={11}>

                            <UploadComponent previewImage={this.previewImage} showSignature={true} appId={this.state.appId}
                                             classes={classes}/>
                        </Grid>
                    </GridContainer>
                )
            }
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };


    closeIcon = () => {
        this.props.closeModal()
    };

/*
    renderSignatureCardCrop = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }
    };*/

    renderCropedImage = () => {
        if (this.state.getData) {
            return (

                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Signature Card
                </button>

            )
        }
    };

    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };

    uploadFunctionCall = () => {
        if(this.state.getData && this.state.inputData.updateType !== "Signature card Update"){

            return this.uploadFunction();
        }
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                {this.renderHeader()}
                <CardBody>
                    <div>
                        <GridList cellHeight={window.innerHeight} cols={1}>
                            <div>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    fullScreen={true}
                                    open={this.state.renderCumModalopen}>
                                    <DialogContent>
                                        <MakerCumInput closeModal={this.closeModal}/>

                                    </DialogContent>
                                </Dialog>


                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.imageCropModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ImageCrop subServiceType={this.props.subServiceType} appId={this.state.appId}
                                                   closeModal={this.closeModal}/>

                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.getMappingCropImage}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <AssignedCropImage subCategory={this.props.subCategory}
                                                           serviceType={this.props.serviceType}
                                                           subServiceType={this.props.subServiceType}
                                                           appId={this.state.appId}
                                                           closeModal={this.closeModal}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorMessage} closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType} appId={this.state.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbGenerateModal}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title="Your New CB Number Is : " closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType} appId={this.state.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                                    </DialogContent>
                                </Dialog>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbNotFound}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <CBNotFound
                                            closeModal={this.closeModalCBNotFound}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>

                                <Grid container spacing={1}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderNotification()}
                                        {this.renderForm()}
                                        <Grid item xs={2}>
                                        {this.renderSearch()}
                                        </Grid>
                                            {this.renderJsonFormCBandAccount()}

                                        <Grid item xs={12}>
                                        </Grid>
                                        {this.renderExistingNomineeForm()}
                                        <Grid item xs={2}>
                                            {this.renderNomineeNumber()}
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                        {this.renderNewNomineeForm()}
                                        <Grid item xs={12}>
                                            {this.uploadFunctionCall()}
                                        </Grid>



                                        {/*<Grid item xs={6}>*/}
                                        {/*    {this.renderNomineeNumber()}*/}
                                        {/*</Grid>*/}
                                        {/*{this.renderNomineeFormReturn()}*/}
                                        {/*{this.renderJsonFormLast()}*/}
                                        {/*<Grid item xs={12}>*/}
                                        {/*    {this.autopopulateButtonTP()}*/}
                                        {/*</Grid>*/}
                                        {/*{this.renderTPForm()}*/}
                                        {/*<Grid item xs={12}>*/}
                                        {/*    {this.autopopulateSummationTP()}*/}
                                        {/*</Grid>*/}
                                        {/*<Grid item xs={12}>*/}
                                        {/*    {this.autopopulateButtonKyC()}*/}
                                        {/*</Grid>*/}
                                        {/*{this.renderKYCForm()}*/}

                                    </ThemeProvider>
                                </Grid>

                                {/*   {this.renderDedupComponentForAgentBanking()}
                    {this.renderDedupComponent()}

                    {this.renderDedupComponentMandateAndBeneficiary()}
                    {this.renderTagListPrevious()}
                    {this.renderSdnComponent()}
                     {this.renderTagList()}*/}


                                <ThemeProvider theme={theme}>
                                    {/*<div style={{marginTop: "5px"}}>*/}
                                    {/*    {this.renderSignatureCardCrop()}*/}
                                    {/*    &nbsp;*/}
                                    {/*    {this.renderCropedImage()}*/}
                                    {/*</div>*/}
                                    {/*<br/>*/}
                                    {/*{this.renderFlowSUmmeryButton()}*/}
                                    {/*<br/>*/}
                                    {/*<DeferalList appId={this.state.appId}/>*/}
                                    {/*<br/>*/}
                                    {/*<WaiverList appId={this.state.appId}/>*/}
                                    {/*{this.renderDefferalData()}*/}
                                    <br/>
                                    {this.renderRemarksData()}
                                    <br/>
                                    {this.renderRemarks()}

                                </ThemeProvider>

                                <br/>
                                <div>
                                    {this.renderSubmitButton()}
                                </div>


                            </div>
                        </GridList>
                    </div>
                </CardBody>
            </Card>
        )

    }


}

export default withStyles(styles)(CbAndAccountUpdateComponent);
