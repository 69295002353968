import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import ErrorModal from "../CommonComponent/ErrorModal";

const jsonForm = [
    {
        varName: "fileReferenceNo",
        type: "text",
        label: "File Reference No",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        varName: "trunkOrBoxNumber",
        type: "text",
        label: "Trunk Or Box Number",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        varName: "sendingDate",
        type: "date",
        label: "Sending Date",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        varName: "saveUserInformation",
        type: "select",
        label: "Save User Information",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


class CentralArchiving extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            loading: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},

            debitCard: "",
            showValue: false,
            AddDeferal: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            getInitiateData: false,

            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            errorModalBoolean: false,
            errorTittle: "",


        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {
        alert("Ok")
    };
    returnJsonForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        )

    };


    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData;
        let url = backEndServerURL + "/archiveFile/add";

        axios
            .post(
                url,
                {

                    fileReferenceNo: object.fileReferenceNo,
                    docType: object.docType,
                    source: object.source,
                    customerCB: object.customerCB,
                    location: object.location,
                    deferralDuration: object.deferralDuration,
                    deferralDetails: object.deferralDetails,
                    docTypeEntryCapacity: object.docTypeEntryCapacity,
                    sourceEntryCapacity: object.sourceEntryCapacity,
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Added",
                    alert: true,
                });
                console.log("Added Successfully");
            })
            .catch((error) => {
                console.log(error);
            });

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };


    renderButton = () => {

        return (
            <div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    //onClick={this.handleSubmit}
                >
                    Submit
                </button>

            </div>
        )

    };

    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >
                        Central Archiving</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.returnJsonForm()}

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="mD"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>

                                Applicant Name ( Auto Populate)
                                Asset Ops number ( Auto Populate)
                                Account Number ( Auto Populate)
                                Closing Date (Finacle)

                                <Grid item xs={12}>
                                    Applicant Name
                                </Grid>

                                <Grid item xs={12}>
                                    <input type="text" value="Aqib Hasan"/>
                                </Grid>

                                <Grid item xs={12}>
                                    Asset Ops number
                                </Grid>

                                <Grid item xs={12}>
                                    <input type="text" value="1222322211"/>
                                </Grid>

                                <Grid item xs={12}>
                                    Account Number
                                </Grid>

                                <Grid item xs={12}>
                                    <input type="text" value="45678"/>
                                </Grid>

                                <Grid item xs={12}>
                                    Closing Date
                                </Grid>

                                <Grid item xs={12}>
                                    <input type="text" value="22 January 2020, 8PM"/>
                                </Grid>

                                {this.renderButton()}

                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(CentralArchiving);

