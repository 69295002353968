import React, {Component} from "react";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import MyValidation from "../JsonForm/MyValidation";


let populateForm = [
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        accept: ".pdf",
        required: true,
        grid: 2,
        errorMessage: "Error"
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class UploadFile extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            autoComplete: {},
            fileUploadData: {},
            appId: "",
            inputData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            getData: false,
            cbNotFound: false,
            title: "",
            notificationMessage: "",
            alert: false,
            loading: false,
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    signatureCard = () => {
        this.setState({
            signatureModal: true
        })
    };


    componentDidMount() {

    };


    saveHandler = event => {
        event.preventDefault();

        let fieError = MyValidation.fileValidation(populateForm[0], this.state);
        this.forceUpdate();
        if (fieError === true) {
            return 0;
        } else {
            let uploadUrl;
            if (this.props.activeItem === "Unconfirmed Address Marked") {
                uploadUrl = backEndServerURL + "/welcomeLetter/upload/cpvDoc/" + this.props.itemId;
            } else {
                uploadUrl = backEndServerURL + "/welcomeLetter/upload/unconfirmedAddressDoc/" + this.props.itemId;
            }

            let formData = new FormData();
            formData.append("file", this.state.fileUploadData["fileUpload"]);
            formData.append("type", "file");

            axios({
                method: "post",
                url: uploadUrl,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        if (this.props.activeItem === "Unconfirmed Address Marked") {
                            let single_url =
                                backEndServerURL +
                                "/updateWelcomeLetterStatus/CPV Request";
                            axios
                                .post(single_url, [this.props.itemId], {
                                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                                })
                                .then(response => {
                                    if (response.status === 200) {
                                        this.props.functionForCPV("success")
                                    } else {
                                        this.props.functionForCPV("failed")
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                    this.props.functionForCPV("failed")
                                });
                        } else {
                            this.setState({
                                alert: true,
                                title: "Success",
                                notificationMessage: "File Upload Successful"
                            });
                            this.props.closeModal();
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        alert: true,
                        title: "Failed",
                        notificationMessage: "File Upload Failed"
                    });
                });
        }

    };


    render() {
        const {classes, onClose} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Upload File
                            <a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a>
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        populateForm,
                                        this.updateComponent
                                    )}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: "10px",
                                }}
                                onClick={this.saveHandler}
                            >
                                Submit
                            </button>
                        </Grid>
                        {
                            this.renderNotification()
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );

    }
}

export default withStyles(styles)(UploadFile);
