import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {jsonForOnlyLinkedCB} from "../static/JsonForCasaMaintenance";

let masterData = {
    occupationCodeList: [],
    phoneNumCountryCodeList: [],
    city: [],
    stateCode: [],
    getnonResident: [],
    getfreecode7: [],
    country: [],
    currency: [],
    despatchList: [],
    customerStatusList: [],
    savingsSchemeCodeList: [],
    placeOfIssueList: [],
    currentSchemeCodeList: [],
    constitutionList: [],
    tradeServiceList: [],
    despatchmodeList: [],
    introducerStatus: [],
    getGuardianCode: [],
    subSegmentList: [],
    subsectorCodeList: [],
    sectorCodeList: [],
    depositCodeList: [],
    dispatchMode: [],
    rmCodeList: [],
    chequebookWaiverType: [],
    relationCodeList: [],
    agentBankingOutletList: [],
    waiverFieldList: [],
    ccepCodeList: [],
    maritalStatusList: [],
    employmentTypeList: [],
    relationshipList: [],
    titleList: [],
    phoneOrEmail: [],
    phoneEmailType: [],
};

const functionForFinacleMasterData = () => {
    dynamicApiCall("title", "title");
    dynamicApiCall("placeOfIssue", "placeOfIssue");
    dynamicApiCall("documentCode", "documentCode");
    dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType");
    dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc");
    dynamicApiCall("phoneCountryCode", "phoneCountryCode");
    dynamicApiCall("city2", "city");
    dynamicApiCall("state2", "state",);
    dynamicApiCall("currency", "currency");
    dynamicApiCall("nomineeRelationCode", "nomineeRelationCode");
    dynamicApiCall("nationality", "country");
    dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund");
    dynamicApiCall("customersOccupation", "customerOccupation");
    dynamicApiCall("addressVariety", "addressVariety");
    dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness");
    dynamicApiCall("monthlyIncome", "monthlyIncome");
    dynamicApiCall("howWasAccountOpened", "accountOpen");
    dynamicApiCall("constitution", "constitution");
    dynamicApiCall("waiverField", "waiverField");
    dynamicApiCall("bankRelationType", "bankRelationType");
    dynamicApiCall("customerStatus", "customerStatus");
    dynamicApiCall("ccepCode", "ccepCode");
    dynamicApiCall("maritalStatus", "maritalStatus");
    dynamicApiCall("relationship", "relationship");
    dynamicApiCall("employmentType", "employmentType");
    dynamicApiCall("agentBankingOutlet", "agentBankingOutlet");
    dynamicApiCall("nonResident", "nonResident");
    dynamicApiCall("relationCode", "relationCode");
    dynamicApiCall("relationType", "relationType");
    dynamicApiCall("dispatchMode", "dispatchMode");
    dynamicApiCall("statementFrequency", "statementFrequency");
    dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode");
    dynamicApiCall("chequebookWaiverType", "chequebookWaiverType");
    dynamicApiCall("subSegment", "subSegment");
    dynamicApiCall("designationCode", "designationCode");
    dynamicApiCall("sectorCode", "sectorCode");
    dynamicApiCall("subSectorCode", "subSectorCode");
    dynamicApiCall("depositCode", "depositCode");
    dynamicApiCall("despatchMode", "despatchMode");
    dynamicApiCall("tradeService", "tradeService");
    dynamicApiCall("guardianCode", "guardianCode");
    dynamicApiCall("addressType", "addressType");
    dynamicApiCall("accountStatement", "accountStatement");
    dynamicApiCall("modOfOperation", "modOfOperation");
    dynamicApiCall("introducerStatus", "introducerStatus");
    dynamicApiCall("bbkSegmentCode", "bbkSegmentCode");
    dynamicApiCall("priorityCenterCode", "priorityCenterCode");
    dynamicApiCall("priorityCenterCode", "priorityCenterCode");
    dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode");
    dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode");

    let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
    axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        .then((response) => {
            findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
            findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
            masterData["occupationCodeList"] = response.data;
        })
        .catch((error) => {
        });
};

const dynamicApiCallDocumentType = (fieldName, apiType) => {
    let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
    axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        .then((response) => {
            if (apiType === "documentCodeDocumentType") {
                findByVarNameApiData("photoIddocumentType", response.data)

            } else if (apiType === "documentCodeDocumentTypeDesc") {
                findByVarNameApiData("photoIddocumentTypeDescription", response.data)

            }

        })
        .catch((error) => {
            console.log(error)
        })
};

const dynamicApiCall = (fieldName, apiType) => {
    let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
    axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
        .then((response) => {
            if (apiType === "title") {
                findByVarNameApiData("mistitle", response.data);
                findByVarNameApiData("titleOld", response.data);
                findByVarNameApiData("relatedCbTaggingtitle", response.data);
                findByVarNameApiData("aCTitle", response.data);
                masterData["titleList"] = response.data;
            } else if (apiType === "city") {
                findByVarNameApiData("maillingAdresscity", response.data);
                findByVarNameApiData("homeAddresscity", response.data);
                findByVarNameApiData("workAddresscity", response.data);
                findByVarNameApiData("presentAddresscity", response.data);
                findByVarNameApiData("nreAddresscity", response.data);
                findByVarNameApiData("mailingAddresscity1", response.data);
                findByVarNameApiData("homeAddresscity1", response.data);
                findByVarNameApiData("workAddresscity1", response.data);
                findByVarNameApiData("workAddresscity2", response.data);
                masterData["city"] = response.data;
            } else if (apiType === "phoneCountryCode") {
                findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                masterData["phoneNumCountryCodeList"] = response.data;
            } else if (apiType === "state") {
                findByVarNameApiData("maillingAdressstate", response.data);
                findByVarNameApiData("homeAddressstate", response.data);
                findByVarNameApiData("workAddressstate", response.data);
                findByVarNameApiData("presentAddressstate", response.data);
                findByVarNameApiData("nreAddressstate", response.data);
                findByVarNameApiData("mailingAddressstate1", response.data);
                findByVarNameApiData("homeAddressstate1", response.data);
                findByVarNameApiData("workAddressstate1", response.data);
                findByVarNameApiData("workAddressstate2", response.data);
                masterData["stateCode"] = response.data;
            } else if (apiType === "nonResident") {
                findByVarNameApiData("chairmanCertificatenonResident", response.data);
                masterData["getnonResident"] = response.data;
            } else if (apiType === "bbkSegmentCode") {
                findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                masterData["getfreecode7"] = response.data;
            } else if (apiType === "country") {
                findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                findByVarNameApiData("maillingAdresscountry", response.data);
                findByVarNameApiData("homeAddresscountry", response.data);
                findByVarNameApiData("workAddresscountry", response.data);
                findByVarNameApiData("Customersnationality", response.data);
                findByVarNameApiData("presentAddresscountry", response.data);
                findByVarNameApiData("nreAddresscountry", response.data);
                findByVarNameApiData("mailingAddresscountry1", response.data);
                findByVarNameApiData("homeAddresscountry1", response.data);
                findByVarNameApiData("workAddresscountry1", response.data);
                findByVarNameApiData("workAddresscountry2", response.data);
                masterData["country"] = response.data;
            } else if (apiType === "currency") {
                findByVarNameApiData("workAddressCurrency", response.data);
                findByVarNameApiData("homeAddressCurrency", response.data);
                masterData["currency"] = response.data;
            } else if (apiType === "customerStatus") {
                findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                masterData["customerStatusList"] = response.data;
            } else if (apiType === "savingsSchemeCode") {
                masterData["savingsSchemeCodeList"] = response.data;
            } else if (apiType === "placeOfIssue") {
                findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                masterData["placeOfIssueList"] = response.data;
            } else if (apiType === "despatchMode") {
                findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                masterData["despatchList"] = response.data;
            } else if (apiType === "currentSchemeCode") {
                masterData["currentSchemeCodeList"] = response.data;
            } else if (apiType === "sourceOfFund") {
                findByVarNameApiData("sourceOfFund", response.data);
                findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)
            } else if (apiType === "constitution") {
                findByVarNameApiData("misconstitution", response.data);
                findByVarNameApiData("constitutionCode", response.data);
                masterData["constitutionList"] = response.data;
            } else if (apiType === "monthlyIncome") {
                findByVarNameApiData("customerMonthlyIncome", response.data)
            } else if (apiType === "accountOpen") {
                findByVarNameApiData("howWasAccountOpened", response.data)
            } else if (apiType === "designationCode") {
                findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)
            } else if (apiType === "tradeService") {
                findByVarNameApiData("misavailedTradeServices", response.data);
                masterData["tradeServiceList"] = response.data;
            } else if (apiType === "modOfOperation") {
                findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                masterData["despatchmodeList"] = response.data;
            } else if (apiType === "introducerStatus") {
                findByVarNameApiData("primryiintroducerStatus", response.data);
                masterData["introducerStatus"] = response.data;
            } else if (apiType === "bbkSegmentCode") {
                findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)
            } else if (apiType === "guardianCode") {
                findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);
                masterData["getGuardianCode"] = response.data;
            } else if (apiType === "subSegment") {
                findByVarNameApiData("crmMissubSegment", response.data);
                masterData["subSegmentList"] = response.data;
            } else if (apiType === "subSectorCode") {
                findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                findByVarNameApiData("subSectorCode", response.data);
                masterData["subsectorCodeList"] = response.data;
            } else if (apiType === "sectorCode") {
                findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                findByVarNameApiData("sectorCode", response.data);
                masterData["sectorCodeList"] = response.data;
            } else if (apiType === "depositCode") {
                findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                masterData["depositCodeList"] = response.data;
            } else if (apiType === "dispatchMode") {
                findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                masterData["dispatchMode"] = response.data;
            } else if (apiType === "rmCode") {
                findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                masterData["rmCodeList"] = response.data;
            } else if (apiType === "accountStatement") {
                findByVarNameApiData("aof1statement", response.data)
            } else if (apiType === "statementFrequency") {
                findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                findByVarNameApiData("aof1statement", response.data)
            } else if (apiType === "chequebookWaiverType") {
                findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                masterData["chequebookWaiverType"] = response.data;
            } else if (apiType === "branch") {
                findByVarNameApiData("otherBankbranchName", response.data)
            } else if (apiType === "relationCode") {
                findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                masterData["relationCodeList"] = response.data;
            } else if (apiType === "addressFormat") {
                findByVarNameApiData("maillingAdressaddressFormat", response.data);
                findByVarNameApiData("homeAddressaddressFormat", response.data);
                findByVarNameApiData("workAddressaddressFormat", response.data);
                findByVarNameApiData("presentAddressaddressFormat", response.data);
                findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                findByVarNameApiData("homeAddressaddressFormat1", response.data);
                findByVarNameApiData("workAddressaddressFormat1", response.data);
                findByVarNameApiData("workAddressaddressFormat2", response.data)
            } else if (apiType === "agentBankingOutlet") {
                findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                masterData["agentBankingOutletList"] = response.data;
            } else if (apiType === "waiverField") {
                findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                masterData["waiverFieldList"] = response.data;
            } else if (apiType === "ccepCode") {
                findByVarNameApiData("misccepCodeFreeCode9", response.data);
                masterData["ccepCodeList"] = response.data;
            } else if (apiType === "nonResident") {
                findByVarNameApiData("chairmanCertificatenonResident", response.data)
            } else if (apiType === "agentBankingOutlet") {
                findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                masterData["agentBankingOutletList"] = response.data;
            } else if (apiType === "maritalStatus") {
                findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                findByVarNameApiData("basicInformationmaritalStatus", response.data);
                findByVarNameApiData("maritalStatus", response.data);
                masterData["maritalStatusList"] = response.data;
            } else if (apiType === "employmentType") {
                findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                findByVarNameApiData("etinprofessionEmploymentType", response.data);
                findByVarNameApiData("employerDataemploymentType", response.data);
                findByVarNameApiData("profession", response.data);
                masterData["employmentTypeList"] = response.data;
            } else if (apiType === "relationship") {
                findByVarNameApiData("guardianDetailsrelation", response.data);
                masterData["relationshipList"] = response.data;
            } else if (apiType === "relationType") {
                findByVarNameApiData("relatedCbTaggingrelationType", response.data)
            } else if (apiType === "nomineeRelationCode") {
                findByVarNameApiData("guardianDetailsrelation1", response.data)
            } else if (apiType === "documentCode") {
                findByVarNameApiData("photoIddocumentCode", response.data)
            } else if (apiType === "bankName") {
                findByVarNameApiData("otherBankbankName", response.data)
            } else if (apiType === "segment") {
                findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)
            } else if (apiType === "phoneOrEmailId") {
                findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                masterData["phoneOrEmail"] = response.data;
            } else if (apiType === "bankRelationType") {
                findByVarNameApiData("guardianDetailsbankRelationType", response.data)
            } else if (apiType === "emailType") {
                findByVarNameApiData("contactNumberEmailemailType1", response.data);
                findByVarNameApiData("contactNumberEmailemailType", response.data);
                findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                masterData["phoneEmailType"] = response.data;
            }
        })
        .catch((error) => {
            console.log(error);
        })
};

const findByVarNameGetKeyValue = (value, getKeyValue) => {
    let jsonArray = getKeyValue;
    for (let i = 0; i < jsonArray.length; i++) {
        let jsonObject = jsonArray[i];
        if (value === jsonObject.key) {
            return jsonObject;
        }
    }
};

const findByVarNameApiData = (searchVarName, getValue) => {
    let jsonArrayForAll = jsonForOnlyLinkedCB;

    for (let i = 0; i < jsonArrayForAll.length; i++) {
        let jsonObjectLast = jsonArrayForAll[i];
        if (jsonObjectLast.varName === searchVarName) {
            jsonForOnlyLinkedCB[i].enum = getValue;

        }
    }

};

const apiDataSet = (data, inputData, accountRelated) => {
    if (data !== undefined && data !== null && data !== "") {
        if (accountRelated === "cbExtraInformation") {
            inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
            inputData.introducercifType = data.intrd_cif_type;
            inputData.misbbkSegmentCodeFreeCode7 = findByVarNameGetKeyValue(data.freecode7, masterData.getfreecode7);
            inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
            inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
            inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
            inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
            inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
            inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
            inputData.mispepStatusFreeText10 = data.free_text_11;
            inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
            inputData.misavailedTradeServices = findByVarNameGetKeyValue(data.trade_services_availed, masterData.tradeServiceList);                   //
            inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
            inputData.primryicifTypePrimaryIntroducerDetails = data.cif_type;
            inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
            inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
            inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
            inputData.customerProbableMonthlyIncome = data.gross_income;
            inputData.chairmanCertificatenonResident = findByVarNameGetKeyValue(data.customer_nre_flg, masterData.getnonResident);  //
            inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
        } else {
            inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            inputData.passportNumberpassportNumberUniqueIdApiCall = true;
            inputData.nationalIdCardNumberUniqueIdApiCall = true;
            inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
            inputData.electronicTaxIdApiCall = true;
            inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;
            inputData.missolId = data.branchCode;
            inputData.misstaffIdNumber = data.staffEmployeeID;
            inputData.misstaffIndicatorStatus = data.staffFlag;
            inputData.misccepCodeFreeCode9 = findByVarNameGetKeyValue(data.ccepcode, masterData.ccepCodeList);
            inputData.misprimarySolId = data.branchCode;
            inputData.basicInformationUpdateprimarySolId = data.branchCode;
            inputData.miscustomerNameLastName = data.customerName;
            inputData.miscustomerNameLastName = data.customerName;
            inputData.misshortName = data.shortName;
            inputData.misaCNumber = data.accountNumber;
            inputData.customerAccount = data.accountNumber;
            inputData.crmMissubSegment = findByVarNameGetKeyValue(data.subSegment, masterData.subSegmentList);
            inputData.aCTitle = data.salutation;
            inputData.miscustomerStatusNormalStaffPriorityEtc = findByVarNameGetKeyValue(data.custStatus, masterData.customerStatusList);
            inputData.misconstitution = findByVarNameGetKeyValue(data.constitutionCode, masterData.constitutionList);
            inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
            // inputData.misfunctionCodeForChequeBookWaiver = "A";
            inputData.aof1currencyCodeCcy = data.currency;
            inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
            inputData.aof1smsAlertFreeText11 = findByVarNameGetKeyValue(data.currencyCode, masterData.currency);
            inputData.primryiintroducerStatus = findByVarNameGetKeyValue(data.statusOfIntroducer, masterData.introducerStatus);
            inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";

            inputData.basicInformationUpdategender = data.gender;
            inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
            inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
            inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
            inputData.basicInformationUpdatemaritalStatus = findByVarNameGetKeyValue(data.maritalStatus, masterData.maritalStatusList);
            inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
            let birthDate = data.birthDate;
            if (birthDate && birthDate !== -1) {
                let date = (birthDate).split('T')[0].split("-");
                inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                setTimeout(() => {
                    let x = 18;
                    var currentDate = new Date();

                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());

                    let inputDate = new Date(birthDate);

                    if (stringToDate >= inputDate) {
                        inputData.basicInformationUpdatedobApiCall = "YES";
                        inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                        //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                    } else {
                        inputData.basicInformationUpdatedobApiCall = "YES";
                        inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        //stateValue.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                    }
                    x = 60;
                    currentDate = new Date();
                    someDate = new Date();
                    numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    date = someDate.getDate();
                    month = someDate.getMonth() + 1;
                    year = someDate.getFullYear();
                    convertDate = year + "-" + month + "-" + date;
                    newDate = new Date(convertDate);
                    stringToDate = new Date(newDate.toDateString());

                    inputDate = new Date(birthDate);
                    if (stringToDate >= inputDate) {
                        inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                    } else {

                        inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                    }
                }, 1000)
            }
            inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
            inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
            inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

            inputData.primryintroducerCustomerIdCifId = data.introducerCifId;
            inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
            inputData.introducerIdApiCall = true;
            inputData.drivingLicensedocumentTypeN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };
            inputData.drivingLicensedocumentTypeDescriptionN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.residentdocumentTypeN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };
            inputData.residentdocumentTypeDescriptionN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.natioanlIdCarddocumentTypeN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.natioanlIdCarddocumentTypeDescriptionN = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.passportNumberdocumentTypeP = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.passportNumberdocumentTypeDescriptionP = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.smartIdCarddocumentTypeS = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };
            inputData.smartIdCarddocumentTypeDescriptionS = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };


            inputData.birthCertificatedocumentTypeB = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };
            inputData.birthCertificatedocumentTypeDescriptionB = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.chairmanCertificatedocumentTypeCC = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };
            inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };


            inputData.etindocumentType = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.etindocumentTypeDescription = {
                "masterDataName": "documentCodeDocumentType",
                "key": "IDENTIFICATION PROOF",
                "value": "IDENTIFICATION PROOF"
            };

            inputData.contactNumberEmailpreferedEmailIdType = {
                "masterDataName": "emailType",
                "key": "COMMEML",
                "value": "COMMEML"
            };
            inputData.contactNumberEmailemailType1 = {
                "masterDataName": "emailType",
                "key": "COMMEML",
                "value": "COMMEML"
            };
            inputData.contactNumberEmailphoneNoEmailId4 = {
                "masterDataName": "phoneOrEmailId",
                "key": "EMAIL",
                "value": "EMAIL"
            };
            inputData.contactNumberpreferredContactNoType1 = {
                "masterDataName": "contactType",
                "key": "COMMPH1",
                "value": "COMMPH1"
            };

            inputData.contactNumberphoneNoEmailId1 = {
                "masterDataName": "phoneOrEmailId",
                "key": "PHONE",
                "value": "PHONE"
            };

            inputData.contactNumberphoneNoEmailId2 = {
                "masterDataName": "phoneOrEmailId",
                "key": "PHONE",
                "value": "PHONE"
            };

            inputData.contactNumberphoneNoEmailId3 = {
                "masterDataName": "phoneOrEmailId",
                "key": "PHONE",
                "value": "PHONE"
            };
            inputData.contactNumberEmailemailType = {
                "masterDataName": "emailType",
                "key": "HOMEEML",
                "value": "HOMEEML"
            };
            inputData.contactNumberEmailphoneNoEmailId = {
                "masterDataName": "phoneOrEmailId",
                "key": "EMAIL",
                "value": "EMAIL"
            };

            inputData.maillingAdresspreferredAddressType = {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            };
            inputData.maillingAdressaddressFormat = {
                "masterDataName": "addressFormat",
                "key": "FREE_TEXT_FORMAT",
                "value": "FREE_TEXT_FORMAT"
            };
            inputData.maillingAdressaddrssType = {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            };
            inputData.maillingAdressaddrssType = {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            };
            inputData.homeAddressaddressFormat = {
                "masterDataName": "addressFormat",
                "key": "FREE_TEXT_FORMAT",
                "value": "FREE_TEXT_FORMAT"
            };


            inputData.workAddressaddressFormat = {
                "masterDataName": "addressFormat",
                "key": "FREE_TEXT_FORMAT",
                "value": "FREE_TEXT_FORMAT"
            };


            inputData.presentAddressaddressFormat = {
                "masterDataName": "addressFormat",
                "key": "FREE_TEXT_FORMAT",
                "value": "FREE_TEXT_FORMAT"
            };
            inputData.nreAddressaddressFormat = {
                "masterDataName": "addressFormat",
                "key": "FREE_TEXT_FORMAT",
                "value": "FREE_TEXT_FORMAT"
            };
            inputData.nreAddressaddrssType =
                {
                    "masterDataName": "addressType",
                    "key": "NRERelative",
                    "value": "NRERelative"
                };


            if (data.RESIDBLOCK !== undefined) {
                inputData.residentdocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "RESID",
                    "value": "RESIDENT ID CARD"
                };

                inputData.existingResidentId = data.RESIDBLOCK.referenceNum;
                inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
            } else {
                inputData.residentdocumentCodeN = null;
            }


            if (data.DLBLOCK !== undefined) {
                inputData.drivingLicensedocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "DL",
                    "value": "DRIVING LICENSE"
                };
                inputData.existingDrivingLicense = data.DLBLOCK.referenceNum;
                inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
            } else {
                inputData.drivingLicensedocumentCodeN = null;
            }

            if (data.BCBLOCK !== undefined) {
                inputData.birthCertificatedocumentCodeB = {
                    "masterDataName": "documentCode",
                    "key": "BC",
                    "value": "BIRTH CERTIFICATE"
                };
                inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
            } else {
                inputData.birthCertificatedocumentCodeB = null;
            }


            if (data.chairmanCertificate !== undefined) {
                inputData.chairmanCertificatedocumentCodeCC = {
                    "masterDataName": "documentCode",
                    "key": "CHAC",
                    "value": "CHAIRMAN CERTIFICATE"
                };
                inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
            } else {
                inputData.chairmanCertificatedocumentCodeCC = null;
            }

            if (data.PASSPORTBLOCK !== undefined) {
                inputData.passportNumberdocumentCodeP = {
                    "masterDataName": "documentCode",
                    "key": "PASSPORT",
                    "value": "PASSPORT IDENTITY"
                };
                inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                inputData.passportNo = data.PASSPORTBLOCK.referenceNum;

                inputData.passportNumberplaceOfIssue = findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, masterData.placeOfIssueList);
                let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                if (passportNumberissueDate && passportNumberissueDate !== -1) {
                    let date = (passportNumberissueDate).split('T')[0].split("-");
                    inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                }
                inputData.passportNumbercountryOfIssue = findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, masterData.country);
                inputData.passportNumberexpiryDate = data.PASSPORTBLOCK.expireDt;
            } else {
                inputData.passportNumberdocumentCodeP = null
            }

            if (data.ETINBLOCK !== undefined) {
                inputData.etindocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "ETIN",
                    "value": "TIN CARD IDENTITY"
                };
                inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                inputData.electronicTaxId = data.ETINBLOCK.referenceNum;
            } else {
                inputData.etindocumentCode = null
            }


            inputData.Customersnationality = findByVarNameGetKeyValue(data.nationality, masterData.country);
            if (data.NIDBLOCK !== undefined) {
                inputData.natioanlIdCarddocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "NID",
                    "value": "NID CARD IDENTITY"
                };
                inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                    let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                    inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                }
            } else {
                inputData.natioanlIdCarddocumentCodeN = null;
            }

            if (data.RSNIDBLOCK !== undefined) {
                inputData.smartIdCarddocumentCodeS = {
                    "masterDataName": "documentCode",
                    "key": "RSNID",
                    "value": "RSNID"
                };
                inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                if (smartCardissueDateN && smartCardissueDateN !== -1) {
                    let date = (smartCardissueDateN).split('T')[0].split("-");
                    inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                }
                inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.RSNIDBLOCK.isDocumentVerified;

                let smartIdCardissueDateS = data.RSNIDBLOCK.issueDt;
                if (smartIdCardissueDateS && smartIdCardissueDateS !== -1) {
                    let date = (smartIdCardissueDateS).split('T')[0].split("-");
                    inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                }
            } else {
                inputData.smartIdCarddocumentCodeS = null;
            }


            if (data.WORKPH1BLOCK !== undefined) {
                inputData.contactNumbercontactType3 = {
                    "masterDataName": "contactType",
                    "key": "WORKPH1",
                    "value": "WORKPH1"
                };
                inputData.existingOfficeNumber = data.WORKPH1BLOCK.phoneNumLocalCode;
                inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                    inputData.mobileTypecontactNumbercontactType3 = "Local";
                } else {
                    inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                    inputData.countryCodecontactNumberphoneNo3 = findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, masterData.phoneNumCountryCodeList)

                }
            } else {
                inputData.contactNumbercontactType3 = null;
            }

            if (data.COMMPH1BLOCK !== undefined) {
                inputData.contactNumbercontactType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                    inputData.mobileTypecontactNumberphoneNo1 = "Local";
                } else {
                    inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                }
            } else {
                inputData.contactNumbercontactType1 = null;
            }

            if (data.COMMPH2BLOCK !== undefined) {
                inputData.contactNumbercontactType2 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH2",
                    "value": "COMMPH2"
                };
                inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;
                if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                    inputData.mobileTypecontactNumberphoneNo2 = "Local";
                } else {
                    inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                }
            } else {
                inputData.contactNumbercontactType2 = null;
            }


            if (data.FutureOnSiteBLOCK !== undefined) {
                inputData.presentAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Future/OnSite",
                    "value": "Future/OnSite"
                };
                inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                inputData.presentAddresscity = findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, masterData.city);
                inputData.presentAddressstate = findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, masterData.stateCode);
                inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                inputData.presentAddresscountry = findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, masterData.country);
            } else {
                inputData.presentAddressaddrssType = null
            }


            if (data.HomeBLOCK !== undefined) {
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                inputData.homeAddresscity = findByVarNameGetKeyValue(data.HomeBLOCK.city, masterData.city);

                inputData.homeAddressstate = findByVarNameGetKeyValue(data.HomeBLOCK.state, masterData.stateCode);
                inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                inputData.homeAddresscountry = findByVarNameGetKeyValue(data.HomeBLOCK.country, masterData.country);
            } else {
                inputData.homeAddressaddrssType = null
            }
            if (data.WorkBLOCK !== undefined) {
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                inputData.workAddresscity = findByVarNameGetKeyValue(data.WorkBLOCK.city, masterData.city);
                inputData.workAddressstate = findByVarNameGetKeyValue(data.WorkBLOCK.state, masterData.stateCode);
                inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                inputData.workAddresscountry = findByVarNameGetKeyValue(data.WorkBLOCK.country, masterData.country);
            } else {
                inputData.workAddressaddressType = null
            }

            if (data.MailingBLOCK !== undefined) {
                inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                inputData.maillingAdresscity = findByVarNameGetKeyValue(data.MailingBLOCK.city, masterData.city);
                inputData.maillingAdressstate = findByVarNameGetKeyValue(data.MailingBLOCK.state, masterData.stateCode);
                inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                inputData.maillingAdresscountry = findByVarNameGetKeyValue(data.MailingBLOCK.country, masterData.country);

            }

            inputData.chairmanCertificatenonResident = findByVarNameGetKeyValue(data.isNRE, masterData.getnonResident);
            if (data.NRERelativeBLOCK !== undefined) {
                inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                inputData.nreAddresscity = findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, masterData.city);
                inputData.nreAddressstate = findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, masterData.stateCode);
                inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                inputData.nreAddresscountry = findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, masterData.country);
            }

            if (data.nameOfEmployer !== undefined) {
                inputData.employerDataprofessionEmploymentType = findByVarNameGetKeyValue(data.employmentStatus, masterData.employmentTypeList);
                inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
            }

            if (data.COMMEMLBLOCK !== undefined) {
                inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
            }

            if (data.HOMEEMLBLOCK !== undefined) {
                inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
            }
            inputData.mistitle = findByVarNameGetKeyValue(data.salutation, masterData.titleList);

        }
    }
    return inputData;
};


export {
    functionForFinacleMasterData,
    apiDataSet
}
