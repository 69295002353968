import React from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from "../../../Common/Functions";
import Table from "../../Table/Table";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../../Card/CardHeader";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Fab from '@material-ui/core/Fab';
import Pageview from '@material-ui/icons/Pageview';


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class SdnForFundTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            renderCumModalopenNew: false,
            CBGenerateModal: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            relatedData: {},
            CustomerModal: false,
            IDENTIFICATION_NO: '',
            searchTableData: false,
            searchTableRelatedData: false,
            getRadioButtonData: {},
            tagingModalCbnumber: '',
            getCustomerNumber: '',
            err: false,
            errorArray: {},
            errorMessages: {},
            beneficiaryDedupData: {},
            mandateDedupDataFlag: false,
            individualDedupData: {},


        };


    }

    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false
        })
    };
    CustomerModal = (uniqueId) => {
        this.setState({
            CustomerModal: true,
            IDENTIFICATION_NO: uniqueId
        })
    };

    radioButtonChange = (event) => {

        let variable = {};
        console.log(event.target.name);
        console.log(event.target.value);
        variable[event.target.name] = event.target.value;
        this.setState({
            getRadioButtonData: variable,
            getCustomerNumber: event.target.name.slice(-1)
        })


    };



    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.props.subServiceType === 'Joint Account' || this.props.subServiceType === "MandateMaintenance" || this.props.subServiceType === 'Company Account') {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else {
                return (

                    [<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                            value={customerId}/>, customerId, accountSource, customerName,
                        <button
                            className="btn"
                            style={{
                                verticalAlign: 'middle',
                            }}

                            onClick={() => this.CustomerModal(uniqueId)}
                        >
                            <Fab size="small" color="secondary" aria-label="pageview">

                                <Pageview/>
                            </Fab>

                        </button>


                    ])
            }
        } else if (((this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening"))) {
            return ([customerId, accountSource, customerName,


                <button
                    className="btn"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    <Fab size="small" color="secondary" aria-label="pageview">

                        <Pageview/>
                    </Fab>

                </button>


            ])
        } else {
            if (accountSource === "TRANZWARE") {
                return ([customerId, accountSource, customerName,
                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>])
            } else if (this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C') {
                return ([customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            } else {
                return ([<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                                value={customerId}/>, customerId, accountSource, customerName,


                    <button
                        className="btn"
                        style={{
                            verticalAlign: 'middle',
                        }}

                        onClick={() => this.CustomerModal(uniqueId)}
                    >
                        <Fab size="small" color="secondary" aria-label="pageview">

                            <Pageview/>
                        </Fab>

                    </button>


                ])
            }
        }

    };

    componentDidMount() {
        this.setState({
            loading: false,
            getData: true,
        })
    }


    updateComponent = () => {
        this.forceUpdate();
    };


    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };

    searchTableSDNData = (style) => {
        console.log("DedupdataisArray?" + Array.isArray(this.props.dedupData));
        console.log("DedupData " + this.props.dedupData);
        console.log("subserviceType " + this.props.subServiceType);
        console.log("searchTableData" + this.props.searchTableData);

       if (this.props.dedupData === null ||this.props.dedupData === '' || this.props.dedupData === "null" || this.props.dedupData === "undefined" || this.props.dedupData === undefined) {
            console.log("No dedupdata Found")
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                    <br/>


                </div>

            )
        } else if (Array.isArray(this.props.dedupData) && this.props.dedupData.length < 1 && (this.props.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Fund Transfer" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C'))) {
            console.log("No SDN Found in condition")
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>
                    <center>
                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                    </center>

                    <br/>


                </div>

            )

        } else if (Array.isArray(this.props.dedupData) && (this.props.searchTableData && (this.props.subServiceType === "Tag FDR Opening" || this.props.subServiceType === 'INDIVIDUAL' || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Fund Transfer" || this.props.subServiceType === 'NONINDIVIDUAL' || this.props.subServiceType === 'Proprietorship A/C'))) {
            console.log("sdn found")
            console.log(this.props.dedupData)
            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Individual SDN Result</h4>

                        </CardHeader>
                    </paper>
                    <br/>

                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.props.dedupData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )


        }


    };

    handleClose = (event) => {
        event.preventDefault();
        this.props.closeModal()
    }



    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        console.log("Props" + this.props);


        return (

            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                <Grid item xs={12}>
                                    {this.searchTableSDNData()}
                                </Grid>


                            </div>
                        </Card>

                        <center>
                            <button
                                className="btn btn-outline-primary  btn-sm"
                                style={{}}
                                onClick={(event) => this.handleClose(event)}

                            >
                                Close
                            </button>
                        </center>
                    </GridItem>


                </GridContainer>


            </div>

        )

    }


}

export default withStyles(styles)(SdnForFundTransfer);
