import React, {Component, forwardRef} from 'react';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../Common/Constant";
import {Menu} from 'semantic-ui-react'
import 'semantic-ui-offline/semantic.min.css';
import Button from '@material/react-button';
import MaterialTable from 'material-table';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import TablePagination from "@material-ui/core/TablePagination";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#37b159',
            light: '#37b159',
            dark: '#37b159',
        },
    },

});

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref}/>
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref}/>
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};
const table_style = {
    row: {
        border: "none",
        height: 10,
        "&:nth-of-type(odd)": {
            backgroundColor: "red"
        }
    },
};


class TabTable extends Component {

    state = {
        activeItem: 'home',
        backgroundColor: 'red',
    };

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: 'RENTED',
            backgroundColor: 'red',
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            loader: true,
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            selectedCount: 0,
            allData: [],
            columns: [
                {title: 'Locker Number', field: 'lockerNumber', export: false,},
                {title: 'Account Number', field: 'accountNumber'},
                {title: 'Locker Type', field: 'lockerType'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Status', field: 'status'},
                {title: 'Sol ID', field: 'solId'},
            ],
            columnsForInsurance: [
                {title: 'Locker Number', field: 'lockerNumber', export: false,},
                {title: 'Account Number', field: 'accountNumber'},
                {title: 'Locker Type', field: 'lockerType'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Status', field: 'status'},
                {title: 'Sol ID', field: 'solId'},
                {title: 'Action', field: 'action'},
            ],
            columnsForSurrendered: [
                {title: 'Action', field: 'action', type: 'checkbox'},
                {title: 'Locker Number', field: 'lockerNumber', export: false,},
                {title: 'Account Number', field: 'accountNumber'},
                {title: 'Locker Type', field: 'lockerType'},
                {title: 'Customer Name', field: 'customerName'},
                {title: 'Status', field: 'status'},
                {title: 'Sol ID', field: 'solId'},
            ]

        };
    }

    handleItemClick = (e, {name, style}) => this.setState({
        activeItem: name,
        backgroundColor: style

    });

    checkbox = (id) => {
        return (
            <Checkbox
                onChange={this.handleClick}
                value={id}
                inputProps={{'aria-label': 'select all desserts'}}
            />
        )
    };

    insuranceViewButton = (path) => {
        let documentsUrl = backEndServerURL + "/file/" + path;
        return (
            <a href={documentsUrl} target={"_blank"}>View</a>
        )
    };

    handleClick = (event) => {

        const {selected} = this.state;
        const selectedIndex = selected.indexOf(event.target.value);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, event.target.value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
        console.log(this.state.selected)


    };

    cancelInsurance = () => {
        this.setState({
            loader: true
        });
        var cancelInsuranceUrl = backEndServerURL + "/locker/cancelInsurance/";
        axios.post(cancelInsuranceUrl, this.state.selected, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                this.setState({
                    activeItem: 'SURRENDED',
                    allData: [],
                    responseData: []
                });
                let open_url = backEndServerURL + "/lockerResource/SURRENDERED/1/0";
                axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response);
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].solId;
                            rowData = {
                                action: this.checkbox(response.data.content[i].id),
                                id: response.data.content[i].id,
                                lockerNumber: response.data.content[i].lockerNumber,
                                lockerBranch: response.data.content[i].lockerBrunch,
                                accountNumber: response.data.content[i].accountNumber,
                                lockerType: response.data.content[i].lockerType,
                                customerName: response.data.content[i].customerName,
                                status: response.data.content[i].status,
                                solId: response.data.content[i].solId
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData)
                        }

                        this.setState(
                            {
                                allData: array,
                                responseData: responseArray,
                                pageNumber: this.state.pageNumber,
                                totalRow: response.data.totalElements,
                                loader: false
                            }
                        );

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    })


            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        //inbox call
        let inbox_url = backEndServerURL + "/lockerResource/RENTED/0/0";
        axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {

                    responseRowData = response.data.content[i].solId;
                    rowData = {
                        id: response.data.content[i].id,
                        lockerNumber: response.data.content[i].lockerNumber,
                        lockerBranch: response.data.content[i].lockerBrunch,
                        accountNumber: response.data.content[i].accountNumber,
                        lockerType: response.data.content[i].lockerType,
                        customerName: response.data.content[i].customerName,
                        status: response.data.content[i].status,
                        solId: response.data.content[i].solId
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData)
                }

                this.setState(
                    {
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false
                    }
                );
                console.log(this.state.allData)

            })
            .catch((error) => {
                console.log(error)
            })
    }

    headerSelect = (event, value, page = 0) => {
        event.preventDefault();
        this.setState({
            loader: true
        });

        if (value === 'RENTED') {
            //open call
            this.setState({
                activeItem: value,
                buttonName: "Download",
                allData: [],
                responseData: []
            });
            let open_url = backEndServerURL + "/lockerResource/RENTED/0/" + page;
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (value === 'INSURANCE COVERAGE') {

            this.setState({
                activeItem: value,
                allData: [],
                responseData: []
            });
            let open_url = backEndServerURL + "/lockerResource/RENTED/1/" + page;
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            lockerBranch: response.data.content[i].lockerBrunch,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId,
                            action: this.insuranceViewButton(response.data[i].documentPath)
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (value === 'SURRENDED') {

            this.setState({
                activeItem: value,
                allData: [],
                responseData: []
            });
            let open_url = backEndServerURL + "/lockerResource/SURRENDERED/1/" + page;
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            action: this.checkbox(response.data.content[i].id),
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            lockerBranch: response.data.content[i].lockerBrunch,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    console.log(array);
                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })

        } else if (value === 'UNRENTED') {

            this.setState({
                activeItem: value,
                allData: [],
                responseData: []
            });
            let open_url = backEndServerURL + "/lockerResource/SURRENDERED/0/" + page;
            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            lockerNumber: response.data.content[i].lockerNumber,
                            lockerBranch: response.data.content[i].lockerBrunch,
                            accountNumber: response.data.content[i].accountNumber,
                            lockerType: response.data.content[i].lockerType,
                            customerName: response.data.content[i].customerName,
                            status: response.data.content[i].status,
                            solId: response.data.content[i].solId
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }

                    this.setState(
                        {
                            allData: array,
                            responseData: responseArray,
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        }
                    );
                    console.log(this.state.allData)

                })
                .catch((error) => {
                    console.log(error)
                })

        }

    };


    updateComponent = () => {
        this.forceUpdate();

    };


    open = (data) => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        })
    };


    render() {

        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Locker Management</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        <React.Fragment>
                            <div>
                                <Menu pointing>
                                    <Menu.Item
                                        name='RENTED'
                                        active={activeItem === 'RENTED'}
                                        style={activeItem === 'RENTED' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        //     onClick={this.handleItemClick}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'RENTED')
                                        }}
                                    />
                                    <Menu.Item
                                        name='INSURANCE COVERAGE'
                                        active={activeItem === 'INSURANCE COVERAGE'}
                                        style={activeItem === 'INSURANCE COVERAGE' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'INSURANCE COVERAGE')
                                        }}
                                    />
                                    <Menu.Item
                                        name='SURRENDED'
                                        active={activeItem === 'SURRENDED'}
                                        style={activeItem === 'SURRENDED' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'SURRENDED')
                                        }}
                                    />
                                    <Menu.Item
                                        name='UNRENTED'
                                        active={activeItem === 'UNRENTED'}
                                        style={activeItem === 'UNRENTED' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'UNRENTED')
                                        }}
                                    />

                                </Menu>

                                {/*<Segment>*/}
                                {this.tableChoice()}
                                {/*</Segment>*/}
                            </div>
                        </React.Fragment>


                    </div>
                </CardBody>


            </Card>
        );
    }

    download = (event, rowData) => {
        event.preventDefault();
        if (this.state.activeItem === 'RENTED') {


            let download_url = backEndServerURL + "/lockerOpsdExcel";

            axios({
                url: download_url,
                method: "GET",
                responseType: 'blob', // important
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'application/json'
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "locker.xlsx");
                document.body.appendChild(link);
                link.click();
            })

        }

    };

    tableChoice() {

        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "RENTED") {
            return (

                <div>

                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    selection: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.tableData.checked
                                            ? "#37b15933"
                                            : ""
                                    }),
                                    emptyRowsWhenPaging: false,
                                    pageSize: 100,
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} selected',
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No records to display',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'RENTED', page)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button style={{
                                                backgroundColor: "green",
                                                color: "white",
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                                borderRadius: 3,
                                                border: 1
                                            }}
                                                    onClick={(event, rowData) => {

                                                        this.download(event, rowData)
                                                    }

                                                    }
                                            >
                                                Download Excel
                                            </Button>

                                        </React.Fragment>

                                    )
                                }}

                            />
                        </div>

                    </MuiThemeProvider>
                </div>


                //new button for microsoft word download


            )
        } else if (this.state.activeItem === "INSURANCE COVERAGE") {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columnsForInsurance}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    selection: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.tableData.checked
                                            ? "#37b15933"
                                            : ""
                                    }),
                                    emptyRowsWhenPaging: false,
                                    pageSize: 100,
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} selected',
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No records to display',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'INSURANCE COVERAGE', page)
                                            }
                                            }
                                        />
                                    )
                                }}


                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "SURRENDED") {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columnsForSurrendered}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    selection: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.tableData.checked
                                            ? "#37b15933"
                                            : ""
                                    }),
                                    emptyRowsWhenPaging: false,
                                    pageSize: 100,
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} selected',
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No records to display',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'SURRENDED', page)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button style={{
                                                backgroundColor: "green",
                                                color: "white",
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                paddingTop: 3,
                                                paddingBottom: 3,
                                                borderRadius: 3,
                                                border: 1
                                            }} onClick={this.cancelInsurance}>
                                                Cancel Insurance
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable

                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    selection: true,
                                    rowStyle: rowData => ({
                                        backgroundColor: rowData.tableData.checked
                                            ? "#37b15933"
                                            : ""
                                    }),
                                    emptyRowsWhenPaging: false,
                                    pageSize: 100,
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} of {count}',
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} selected',
                                    },
                                    header: {
                                        actions: 'Actions'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'No records to display',
                                        filterRow: {
                                            filterTooltip: 'Filter'
                                        }
                                    }
                                }}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.headerSelect(e, 'UNRENTED', page)
                                            }
                                            }
                                        />
                                    )
                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        }
    }


}

export default TabTable;
