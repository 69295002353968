import React, {Component} from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import SubImageCrop from "./SubImageCrop";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";

let ImageRemarks = [
    {
        "varName": "imageRemarks",
        "type": "text",
        "label": "Signature card Signing Instruction",
        "multiline": true,
        "grid": 6,
    }
];

class ImageCrop extends Component {
    state = {
        selectedImageURL: null,


        //selectedImageURL: sample,
        crop: {
            x: "100%",
            y: "100%",
            width: "100%",
            height: "100%",
            // aspect: 16 / 9,
        },
        selectedFile: null,
        croppedImage: null,
        inputData: {},
        varValue: {},
        handleSubmitBoolean: false,

        modalWidth: 0,
        modalHeight: 0,
        errorMessages: {},
        errorArray: {},
        getData: false,
        loading: true,
        showValue: false,
        getSignatureCardNumber: 0,
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };

    componentDidMount() {

        var imageArrayLink = [];

        if(this.props.singleImageCrop!==true){
            var imageArrayLink = [];


            let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let string = "SIGNATURE CARD";

                    if (this.props.subCategory !== "FDR" && this.props.subServiceType === "Company Account")
                        string = "SIGNATURE CARD";
                    else if (this.props.subServiceType === "New FDR Opnening")
                        string = "SIGNATURE CARD";
                    else if (this.props.subServiceType === "Tag FDR Opnening")
                        string = "SIGNATURE CARD";
                    else if (this.props.serviceType === "Locker Opening")
                        string = "CITY LOCKER APPLICATION FORM";
                    let getSignatureCardNumber = 0;
                    for (let i = 1; i < 20; i++) {
                        response.data.map((document, index) => {
                            let splitData = document.split("_")[1];
                            if (splitData.indexOf(string + " " + i + ".jpg") > -1) {
                                getSignatureCardNumber++;
                                imageArrayLink.push(document);
                            }
                        });
                    }
                    console.log("imageArrayLink");
                    console.log(imageArrayLink);
                    console.log(getSignatureCardNumber);

                    setTimeout(() => {
                        this.setState({
                            selectedImageArrayList: imageArrayLink,
                            getSignatureCardNumber: getSignatureCardNumber
                        });
                        let data = {};
                        data.appId = this.props.appId;
                        data.key = "getSignatureCardNumber";
                        data.value = this.state.getSignatureCardNumber;

                        if (getSignatureCardNumber !== 0) {


                            let setNumberOfSignature = backEndServerURL + "/applicationKeyValue/add";

                            axios.post(setNumberOfSignature, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                })
                                .catch((error) => {
                                    console.log(error);
                                })
                        }
                        setTimeout(() => {
                            let url = backEndServerURL + '/variables/' + this.props.appId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    let inputData = response.data;
                                    let varValue = response.data;
                                    for (let i = 0; i < (getSignatureCardNumber); i++) {
                                        if (inputData["deleteFlag" + i] === null || inputData["deleteFlag" + i] === undefined) {
                                            inputData["deleteFlag" + i] = "NO";
                                            varValue["deleteFlag" + i] = "NO";
                                        }


                                    }
                                    this.setState({
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        getData: true,
                                        getDocument: true,
                                        showValue: true,
                                        loading: false,
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        getData: true,
                                        loading: false,
                                    })
                                })
                        }, 500)

                    }, 1000)

                })
                .catch((error) => {
                    console.log("error");
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        }

        else {
            let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let string = this.props.caseId+"_SIGNATURE CARD "+(Number(this.props.imageIndex) + Number(1))+".jpg";
                    response.data.map((document, index) => {
                        //let splitData = document.split("_")[1];
                        let splitData = document;
                        console.log(document)
                        console.log(string)
                        if (splitData===string ) {
                            console.log(document)

                            imageArrayLink.push(document);
                        }
                    });

                    console.log("imageArrayLink"+imageArrayLink );
                    console.log("imageArrayLink");

                    setTimeout(() => {
                        this.setState({
                            selectedImageArrayList: imageArrayLink,
                        });
                        setTimeout(() => {
                            let url = backEndServerURL + '/variables/' + this.props.appId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    let inputData = response.data;
                                    let varValue = response.data;
                                    let i=(Number(this.props.imageIndex) + Number(1));
                                    if (inputData["deleteFlag" + i] === null || inputData["deleteFlag" + i] === undefined) {
                                        inputData["deleteFlag" + i] = "NO";
                                        varValue["deleteFlag" + i] = "NO";
                                    }
                                    /* if (inputData["imageRemarks" + i] === null || inputData["imageRemarks" + i] === undefined) {
                                         inputData["imageRemarks" + i] = "NO";
                                         varValue["imageRemarks" + i] = "NO";
                                     }*/
                                    this.setState({
                                        inputData: this.emptyValueRemove(inputData),
                                        varValue: this.emptyValueRemove(varValue),
                                        getData: true,
                                        getDocument: true,
                                        showValue: true,
                                        loading: false,
                                    })

                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        getData: true,
                                        loading: false,
                                    })
                                })
                        }, 500)

                    }, 1000)

                })
                .catch((error) => {
                    console.log("error");
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        }



    }

    assignedCropedImage = (data) => {
        this.state.inputData.assignedCropedImage = true;
        this.updateComponent();

        this.setState({
            handleSubmitBoolean: false,
        })

    };
    renderSubImageCrop = () => {
        if (this.state.getDocument && this.props.singleImageCrop===true) {
            var name = "";
            return this.state.selectedImageArrayList.map((data, i) => {
                if (data.split("_")[1].split(".")[0] === "AOF3") {
                    name = "SIGNATURE CARD 1";
                } else {
                    name = data.split('_')[1].split('.')[0];
                }
                return (

                    <React.Fragment>
                        <SubImageCrop customerId={this.props.customerId} handleSubmitClose={this.handleSubmitClose}
                                      handleSubmit={this.state.handleSubmitBoolean}
                                      subServiceType={this.props.subServiceType} appId={this.props.appId}
                                      closeModal={this.closeModal} assignedCropedImage={this.assignedCropedImage}
                                      imageName={name}
                                      imageLink={data}
                                      imageNumber={ (Number(this.props.imageIndex) + Number(1))}
                        />

                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, [

                                {
                                    "type": "empty",
                                    "grid": 3,
                                },

                                {
                                    "varName": "imageRemarks" + (Number(this.props.imageIndex)),
                                    "type": "text",
                                    "label": "Signature card Signing Instruction",
                                    "multiline": true,
                                    "grid": 3,
                                },
                                {
                                    "varName": "deleteFlag" + (Number(this.props.imageIndex) ),
                                    "type": "select",
                                    "enum": [
                                        "YES",
                                        "NO",
                                    ],
                                    "required": true,
                                    "label": "Delete Flag",
                                    "multiline": true,
                                    "grid": 3,
                                },

                                {
                                    "type": "empty",
                                    "grid": 3,
                                },

                            ], this.updateComponent)

                        } ,

                    </React.Fragment>
                )
            })
        }
        else if (this.state.getDocument) {
            var name = "";
            return this.state.selectedImageArrayList.map((data, i) => {
                if (data.split("_")[1].split(".")[0] === "AOF3") {
                    name = "SIGNATURE CARD 1";
                } else {
                    name = data.split('_')[1].split('.')[0];
                }
                return (

                    <React.Fragment>
                        <SubImageCrop customerId={this.props.customerId} handleSubmitClose={this.handleSubmitClose}
                                      handleSubmit={this.state.handleSubmitBoolean}
                                      subServiceType={this.props.subServiceType} appId={this.props.appId}
                                      closeModal={this.closeModal} assignedCropedImage={this.assignedCropedImage}
                                      imageName={name}
                                      imageLink={data}
                                      imageNumber={Number(i) + Number(1)}
                        />

                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, [

                                {
                                    "type": "empty",
                                    "grid": 3,
                                },

                                {
                                    "varName": "imageRemarks" + i,
                                    "type": "text",
                                    "label": "Signature card Signing Instruction",
                                    "multiline": true,
                                    "grid": 3,
                                },
                                {
                                    "varName": "deleteFlag" + i,
                                    "type": "select",
                                    "enum": [
                                        "YES",
                                        "NO",
                                    ],
                                    "required": true,
                                    "label": "Delete Flag",
                                    "multiline": true,
                                    "grid": 3,
                                },

                                {
                                    "type": "empty",
                                    "grid": 3,
                                },

                            ], this.updateComponent)

                        } ,

                    </React.Fragment>
                )
            })
        }

    };
    updateComponent = () => {
        this.forceUpdate();
    };
    /* renderImageRemarks = () => {
         if (this.state.getData === true) {
             return (

                 CommonJsonFormComponent.renderJsonForm(this.state, ImageRemarks, this.updateComponent)
             )
         }

     }*/
    close = () => {
        this.props.closeModal()
    };
    handleSubmitClose = () => {
        this.setState({
            handleSubmitBoolean: false
        })
    };
    handleSubmit = (event) => {
        event.preventDefault();
        let dependency = [];
        for (let i = 0; i < (this.state.getSignatureCardNumber); i++) {
            dependency.push(
                {
                    "varName": "deleteFlag" + i,
                    "type": "select",
                    "enum": [
                        "YES",
                        "NO",
                    ],
                    "required": true,
                    "label": "Delete Flag",
                    "multiline": true,
                    "grid": 6,
                }
            )

        }
        let error = MyValidation.defaultValidation(dependency, this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;

        }
        this.setState({
            loading: true,
        });
        let url = backEndServerURL + '/variables/' + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                console.log("llllllllllllll");
                let data = response.data;
                data.assignedCropedImage = this.state.inputData.assignedCropedImage;
                if(this.props.singleImageCrop===true){

                    let imageVariable = ["imageRemarks" + this.props.imageIndex];
                    let deleteFlagVariable = ["deleteFlag" + this.props.imageIndex];
                    data[deleteFlagVariable] = this.state.inputData["deleteFlag" + this.props.imageIndex];
                    data[imageVariable] = this.state.inputData["imageRemarks" + this.props.imageIndex];

                }else{
                    for (let i = 0; i < (this.state.getSignatureCardNumber); i++) {
                        let imageVariable = ["imageRemarks" + i];
                        let deleteFlagVariable = ["deleteFlag" + i];
                        data[deleteFlagVariable] = this.state.inputData["deleteFlag" + i];
                        data[imageVariable] = this.state.inputData["imageRemarks" + i];
                    }
                }

                setTimeout(() => {

                    console.log(data);
                    let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
                    axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                handleSubmitBoolean: true,
                                loading: false,
                            });

                            this.close()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                handleSubmitBoolean: true,
                                loading: false,
                            });
                            this.close()
                        })
                }, 500)
            })
            .catch((error) => {
                this.setState({
                    handleSubmitBoolean: true,
                    loading: false,
                });
                this.close()
            })


    };
    renderSubmitButton = () => {
        if (this.state.getData === true) {
            return (
                <button
                    className="btn btn-outline-primary"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'

                    onClick={this.handleSubmit}
                >Submit
                </button>
            )
        }
    };

    render() {
        return (
            <div>

                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Customer Photo and Signature Crop<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"


                            open={this.state.loading}>
                            <DialogContent>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>

                                {this.renderSubImageCrop()}
                                {/*
                            {this.renderImageRemarks()}
*/}
                            </Grid>
                        </ThemeProvider>
                        <center>
                            <Grid item xs={12}>
                                {this.renderSubmitButton()}

                            </Grid>
                        </center>
                    </CardBody>
                </Card>
            </div>

        )


    }
}

export default ImageCrop
