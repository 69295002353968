import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "../CommonComponent/WaiverList";
import DeferalList from "../CommonComponent/DeferalList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import AccountStatement from "../fdr/AccountStatement";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BMApproval extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        selectedDate: {},
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: false,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false,
        confirmAlert: false,
        actionType: "",


    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };
    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (var prop in clone)
              if (clone[prop] === ''     || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                  delete clone[prop];*/
        return clone;
    };
    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.solId}/>
            )
        }
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    componentDidMount() {
        var remarksArray = [];
        this.setState({
            loading: true
        });
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    /* let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                     axios.get(deferalListUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                         .then((response) => {
                             console.log(response.data);
                             let tableArray = [];
                             var status = "";
                             response.data.map((deferal) => {
                                 if (deferal.status === "APPROVAL_WAITING") {
                                     status = "Waiting For Approval"
                                 }
                                 else{
                                     status = deferal.status
                                 }
                                 tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                             });*/
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });
                    /*  this.setState({
                          getDeferalList: tableArray
                      })

                  })
                  .catch((error) => {
                      console.log(error);
                  })*/

                    this.setState({
                        getRemarks: remarksArray
                    });
                    console.log(response.data);
                    let inputData = this.removeNullValue(response.data);
                    let varValue = this.removeNullValue(response.data);
                    inputData.bmRemarks = undefined;
                    varValue.bmRemarks = undefined;
                    if (inputData.dob !== undefined && inputData.dob !== null && inputData.dob !== "" && inputData.dob !== -1) {
                        inputData.dob = (inputData.dob).split(',')[0];
                        varValue.dob = (varValue.dob).split(',')[0];
                    }
                    if (inputData.fdValueDate !== undefined && inputData.fdValueDate !== null && inputData.fdValueDate !== "" && inputData.fdValueDate !== -1) {
                        inputData.fdValueDate = (inputData.fdValueDate).split(',')[0];
                        varValue.fdValueDate = (varValue.fdValueDate).split(',')[0];
                    }
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: varValue,
                        inputData: inputData,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "REJECT") {
            this.handleSubmit("REJECT")
        } else if (data === "YES" && this.state.actionType === "APPROVE") {
            this.handleSubmit("APPROVE")
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(bmRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }
        let dependencyField = [];
        if (actionType === "REJECT") {
            dependencyField.push({
                "varName": "bmRemarks",
                "type": "textArea",
                "required": true,
                "label": "BM Remarks",
                "grid": 12
            })
        }
         error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            confirmAlert: true,
            actionType: actionType
        })
    };

    handleSubmit = (data) => {
        // event.preventDefault();
        console.log(this.state.inputData);
        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        if ((this.state.inputData.bmRemarks !== undefined && this.state.inputData.bmRemarks !== null) /*&& (data==="REJECT" || data==="APPROVE")*/) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bmRemarks;
        }
        var appId = this.props.appId;
        var approval = data;

        if (data === "APPROVE") {
            let deferalUrl = backEndServerURL + "/deferral/approval";
            axios.post(deferalUrl, {
                appId,
                approval
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.bm_approval = data;

                    this.state.inputData.cs_deferal = "NO";
                    this.state.inputData.csDeferal = "NO";
                    console.log(this.state.inputData);
                    saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
                    var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {


                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });

                            this.props.closeModal();
                            //
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })

                        });


                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                })

        } else {
            this.state.inputData.bm_approval = data;

            this.state.inputData.cs_deferal = "NO";
            this.state.inputData.csDeferal = "NO";

            console.log(this.state.inputData);
            saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
            var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {


                    console.log(response.data);
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    });

                    this.props.closeModal();
                    //
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });


        }

    };


    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent, "", this.onKeyDownChange)

            )
        }

    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)

            )
        }

    };


    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVE")}

                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "REJECT")}
                    >Return
                    </button>
                </div>


            )
        }
    };
    renderMultipleDebitCardForm = () => {


        if (this.state.getData) {

            var sl;
            let objectForDebitCard = [];
            for (var i = 0; i < this.state.inputData.numberOfDebitCard; i++) {
                sl = i + 1;
                objectForDebitCard.push(
                    {
                        "varName": "customer" + sl,
                        "type": "title",
                        "label": "Debit Card " + sl,
                        "grid": 12
                    },
                    {
                        "varName": "customerName" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Name On Card",
                        "grid": 2,


                    },

                    {
                        "varName": "cardType" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Card Type",
                        "grid": 2,
                        "enum": [
                            "VISA",
                            "MASTER",
                            "CITYMAXX",
                        ],


                    },
                    {
                        "varName": "debitCardDeliveryType" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Delivery Type",
                        "enum": [
                            "Courier",
                            "Branch"
                        ],
                        "grid": 2,


                    },

                    {
                        "varName": "debitRequestkBranch" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Branch Name",
                        "enum": [
                            "GULSHAN 1",
                            "MOTHIJHEEL 1",
                            "DHANMONDI",
                        ],
                        "grid": 2,
                        "conditional": true,
                        "conditionalVarName": "debitCardDeliveryType" + sl,
                        "conditionalVarValue": "Branch",

                    },
                )
            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForDebitCard, this.updateComponent)
            )

        }
    };
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Debit Card</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfDebitCard"]}
                            name="numberOfDebitCard"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        if (this.state.loading === true) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

            )
        } else {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.history}>
                        <DialogContent className={classes.dialogPaper}>
                            <SubmittedCaseHistory customerName={this.state.customerName}
                                                  accountNumber={this.state.accountNumber} solId={this.state.solId}
                                                  globalSearch={true} closeIcon={this.closeHistoryModal}
                                                  appId={this.state.appId} closeModal={this.closeHistoryModal}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.accountStatement}>
                        <DialogContent className={classes.dialogPaper}>
                            <AccountStatement closeModal={this.closeStatement}
                                              accountNumber={this.state.inputData.accountNumber}/>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderForm()}
                            {/*  <Grid item xs={2}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderMultipleDebitCardForm()}
*/}
                        </Grid>
                        <br/>
                        <Grid item={12}>
                            <DeferalList appId={this.props.appId}/>
                        </Grid>
                        <br/>
                        <Grid item={12}>

                            <WaiverList appId={this.props.appId}/>
                        </Grid>

                        <br/>
                        <Grid item={12}>

                            {this.renderRemarksData()}
                        </Grid>

                        <br/>

                        <Grid item xs={12}>
                            <button
                                style={{
                                    width: 150,

                                }}
                                className="btn btn-outline-primary btn-sm"

                                onClick={this.mappingAllImage}

                            >
                                Preview Image
                            </button>
                        </Grid>
                        <br/>
                        <Grid item xs={12}>
                            {this.renderFlowSUmmeryButton()}
                        </Grid>
                        <br/>
                        {this.renderRemarks()}
                        <br/>
                        <div>
                            {this.renderSubmitButton()}
                        </div>
                    </ThemeProvider>

                </div>


            )
        }
    }

}

export default withStyles(styles)(BMApproval);
