import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import {
    CsOutstandingCertificateJsonFromReadOnly,
    CsOutstandingCertificateJsonFromTaxReturnReadOnly
} from "../../WorkflowjsonFormForAssetOps";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let remarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];
let certificateTypeJsonFormReadOnly = [
    {
        "label": "Certificate Type",
        "type": "select",
        "varName": "certificateType",
        "grid": 2,
        "enum": [
            "General",
            "Tax Return Submission",
            "Take Over",
        ],
        "readOnly":true,
        "onKeyDown": true,
        "required": true,
    },
];
class BomOutstandingCertificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            selectedDate: {},
            confirmAlert: false,
            actionType: "",
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "approveHandler") {

            this.approveHandler()
        } else if (data === "YES" && this.state.actionType === "returnReturn") {

            this.returnReturn()
        }
    };
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();
        if (errorRemarks ===true) {
            return 0;
        }
        this.setState({
             confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })

        } else if (actionType === "returnReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "label": "Remarks",
                "grid": 12,
                "required":true,
            });
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };

    componentDidMount() {
        let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let inputData=response.data;
                inputData.makerRemarks=undefined;
                this.setState({
                    varValue: this.copyJson(inputData),
                    inputData: this.copyJson(inputData),
                    getData: true,
                    showValue:true,
                });

                let remarksArray = [];
                let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        response.data.map((data) => {
                            if (data.remarks !== 'undefined') {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            }
                        });
                        this.setState({
                            getRemarks: remarksArray,
                            showValue: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error);
            });

        let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})

            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data
                });

                let uploadUrl = assetOpsBackEndServerURL + "/recoveryAmountFileRead";
                let formData = new FormData();
                formData.append("file", response.data);
                axios({
                    method: "post",
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        "content-type": "multipart/form-data"
                    }
                })
                    .then(response => {
                        console.log(response.data)
                    })
                    .catch(error => {

                    });


            })
            .catch((error) => {
                console.log(error);
            })

    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = () => {
        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        saveDataDeferralRemarksAndRoute.map = {bom_approved: "YES"};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(routeUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                    loading: false
                });
                this.props.closeModal()

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });


    };
    returnReturn = () => {
        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        saveDataDeferralRemarksAndRoute.map = {bom_approved: "NO"};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(routeUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                    loading: false
                });
                this.props.closeModal()

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });


    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)

        }
    };
    renderRemarksData = () => {
        if (this.state.getData && this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderCertificateType = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, certificateTypeJsonFormReadOnly, this.updateComponent)
            )
        }
    }
    renderJsonForm = () => {

        if (this.state.getData && this.state.inputData.certificateType === "Tax Return Submission") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CsOutstandingCertificateJsonFromTaxReturnReadOnly, this.updateComponent)
            )
        } else if (this.state.getData && (this.state.inputData.certificateType === "General" || this.state.inputData.certificateType === "Take Over")) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CsOutstandingCertificateJsonFromReadOnly, this.updateComponent)
            )
        }
    }
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading!==true) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        // onClick={this.approveHandler}
                        onClick={(event) => this.handleCommon(event, "approveHandler")}

                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        //onClick={this.returnReturn}
                        onClick={(event) => this.handleCommon(event, "returnReturn")}

                    >
                        Return
                    </button>
                </React.Fragment>
            )

        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Outstanding Certificate</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}<b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Outstanding Certificate
                </h4>
            )
        }
    };



    render() {
        const {classes, onClose} = this.props;
        return (
            <div>
                <Card>
                     <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                         {this.renderHeader()}
                     </CardHeader>
                    <CardBody>
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                      closeModal={this.closeConfirmAlert}


                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <center>
                                            <img src={loader} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                {this.renderCertificateType()}
                                {this.renderJsonForm()}
                                <Grid item xs={12}></Grid>
                            </ThemeProvider>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {
                                        this.renderRemarksData()
                                    }
                                    <br/>
                                    {this.renderFlowSUmmeryButton()}
                                    <br/>
                                    <Grid item xs={12}></Grid>
                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "20px"}}>
                            {/*<center>*/}
                            {this.renderSubmitButton()}
                        </Grid>
                        {
                            this.renderNotification()
                        }
                    </CardBody>
                </Card>
            </div>
        );
    }


}

export default withStyles(styles)(BomOutstandingCertificate);
