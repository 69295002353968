import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import Table from "../../Table/Table";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";
import SignatureButton from "../Maintenance/SignatureButton";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }]
;

class VerifyInstaDebitCardBOM extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        selectedDate: {},
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: true,
        cbNotFound: false,
        disabled: false
    };
    renderEditForm = (() => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)
            )
        }
    });

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {

        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(response.data);
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    let inputData = {...response.data};
                    inputData.cardType = response.data.cardType.toString();
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.emptyValueRemove(inputData),
                        inputData: this.emptyValueRemove(inputData),
                        appData: response.data,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }


    // functionForCaseRoute = () => {
    //     var url = backEndServerURL + "/case/close/" + this.props.appId;
    //
    //     axios.post(url, {},{headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
    //         .then((response) => {
    //             this.setState({
    //                 loading: false,
    //                 title: "Successful!",
    //                 notificationMessage: "Successfully Closed!",
    //                 alert: true,
    //                 disabled: false
    //             });
    //             this.props.closeModal()
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //             this.setState({
    //                 loading: false,
    //                 cbNotFound: true,
    //                 title: "Case Close Failed!",
    //                 disabled: false
    //             });
    //         });
    // };

    handleSubmit = (event, data) => {
        event.preventDefault();
        this.state.inputData.bom_approval = data;
        let dependencyField = [];
        if (data === "RETURN") {
            dependencyField.push({

                "varName": "bomRemarks",
                "type": "textArea",
                "required": true,
                "label": "Bom Remarks",
                "grid": 12
            })
        }
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            loading: true,
            disabled: true
        });
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (data === "APPROVED") {
                    let postData = {};
                    postData.accountStatus = this.state.inputData.accountStatus;
                    postData.accountNumber = this.state.inputData.accountNumber;
                    postData.productType = this.state.inputData.productType;
                    postData.nameOnCard = this.state.inputData.nameOnCard;
                    postData.clientId = this.state.inputData.clientId;
                    postData.cardNumber = this.state.inputData.cardNumber;
                    postData.rmCode = this.state.inputData.rmCode;
                    postData.appId = this.props.appId;
                    postData.requisitionType=this.state.inputData.sourceType;
                    let routeTaskUrl = backEndServerURL + "/updateInstantDebitCard";
                    axios
                        .post(routeTaskUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.functionForRoute();
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        });
                } else {
                    this.functionForRoute();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false
                });
            });


    };

    functionForRoute = () => {
        var url = backEndServerURL + "/case/route/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    disabled: false
                });
                this.props.closeModal()
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "Case Route Failed!",
                    disabled: false
                });
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton signatureSource={this.state.inputData.sourceType} accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        disabled={this.state.disabled}
                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='add more'
                        disabled={this.state.disabled}
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </Grid>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        &nbsp;
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: "5px",
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document {index + 1}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <div>
                <ThemeProvider theme={theme}>
                    <div>
                        <Grid container spacing={1}>
                            {this.renderEditForm()}
                            <Grid item xs={12}>
                                {this.signature()}
                            </Grid>
                            {this.mappingPhoto()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                            </Grid>
                            {this.renderNotification()}
                            {this.renderRemarks()}
                            <br/>
                            <br/>
                            <br/>
                            {this.renderSubmitButton()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </div>
                </ThemeProvider>
            </div>
        )

    }
}

export default withStyles(styles)(VerifyInstaDebitCardBOM);