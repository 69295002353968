import Table from "../Table/Table.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import BusinessDivisionAddEditDelete from './BusinessDivisionAddEditDelete';
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [

        {
            "varName": "businessDivisionName",
            "type": "text",
            "label": "Business Division Name",
            "number": false,
        },
        {
            "varName": "createdBy",
            "type": "text",
            "label": "Created By",
        },
        {
            "varName": "modifiedBy",
            "type": "text",
            "label": "Modified By",
        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class BusinessDivision extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addBusinessDivision: false,
            editBusinessDivision: false,
            BusinessDivisionName: '',
            open: true,
            businessDivisionId: '',
            tableData: [[" ", " "]],
            businessDivisionData: [[" ", " "]],
            redirectLogin: false,


        }
    }

    addBusinessDivision() {
        this.setState({
            addBusinessDivision: true,
        });
    }

    editBusinessDivision = (id) => {
        this.setState({
            editBusinessDivision: true,
            businessDivisionId: id
        })

    };

    cancelBusinessDivisionModal = () => {
        this.setState({addBusinessDivision: false});
    };
    cancelEditBusinessDivisionModal = () => {
        this.setState({editBusinessDivision: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {
        this.setState({
            addBusinessDivision: false,
            editBusinessDivision: false
        });
    };
    createTableData = (id, businessDivisionName, createdBy, modifiedBy, createdDate, modifiedDate, status) => {

        return ([businessDivisionName, createdBy, modifiedBy, createdDate, modifiedDate, status,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editBusinessDivision(id)}>
                Edit</button>
        ]);

    };

    getSubmitedForm = (object) => {

        let objectTable = {};
        let tableArray = [];
        for (let variable in object) {
            let trimData = object[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }

        this.state.tableData.map((businessDivision) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== businessDivision[variable])
                    showable = false;
            }
            if (showable)
                tableArray.push(this.createTableData(businessDivision.id, businessDivision.businessDivisionName, businessDivision.createdBy, businessDivision.modifiedBy, businessDivision.createdDate, businessDivision.modifiedDate, businessDivision.status));


        });
        this.setState({
            businessDivisionData: tableArray
        })


    };
    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="12" buttonName="Filtering" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    componentDidMount() {
        let url = backEndServerURL + "/businessDivisionMaster/getAll";
        let getAllBusinessDivision = [];
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                response.data.map((businessDivision) => {

                    getAllBusinessDivision.push(businessDivision);
                });
                this.setState({
                    tableData: getAllBusinessDivision
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
    }

    businessDivisionAddModal = () => {

        return (
            <BusinessDivisionAddEditDelete onAdd={this.onAdd} close={this.closeModal} data={this.state.tableData}
                                           name="Add New businessDivision"/>
        )
    };
    businessDivisionEditModal = () => {
        return (
            <BusinessDivisionAddEditDelete onUpdate={this.onUpdate} close={this.closeModal} data={this.state.tableData}
                                           name="Edit businessDivision" id={this.state.businessDivisionId}/>
        )
    };
    onAdd = (object) => {

        this.setState({
            addBusinessDivision: false

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editBusinessDivision: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addBusinessDivision}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.businessDivisionAddModal()}
                    </DialogContent>

                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editBusinessDivision}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.businessDivisionEditModal()}
                    </DialogContent>

                </Dialog>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>BusinessDivision List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderFilterForm()}
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addBusinessDivision: true
                                            })
                                        }
                                        }
                                    >
                                        Add New businessDivision
                                    </button>

                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["BusinessDivision Name", "Created by", "Modified by", "Created Date", "Modified Date", "Status", "Action"]}
                                        tableData={this.state.businessDivisionData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(BusinessDivision);
