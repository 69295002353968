import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Button} from "@material-ui/core";
import MyValidation from "../../../JsonForm/MyValidation";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import {BMClosingJsonForm} from "./JsonFormLoanClosing";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import CommonApi from "../../Maintenance/common/CommonApi";
import Label from "../Common/Label";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "BM Remarks",
        "grid": 12
    }]
;


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class BmFormLoanClosing extends React.Component {
    state = {
        getData: false,
        loading: true,
        varValue: {},
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        showValue: false,
        err: false,
        cbNotFound: false,
        errorArray: {},
        actionType: "",
        confirmAlert: false,
        errorMessages: {},
        getImageLink: [],
        getImageBoolean: false,
        getRemarks: [],
    };

    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.bmRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (data) => {
        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.bmRemarks !== undefined && this.state.inputData.bmRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bmRemarks;
        }
        this.state.inputData.bm_approval = data;
        if (data === "NO") {
            this.state.inputData.bm_approved = data;
        } else {
            this.state.inputData.bm_approved = data;
        }

        if(this.state.inputData.apiButton === undefined && this.state.inputData.exciseDuty !== "0"){
            this.state.inputData.apiButton = "loanExciseDuty";
        }
        else if(this.state.inputData.apiButton === undefined && this.state.inputData.exciseDuty === "0"){
            this.state.inputData.apiButton = "loanFeeCollection";
        }
        saveDataDeferralRemarksAndRoute.map = {...this.state.inputData};
        let url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: response.data,
                    alert: true,
                    loading: false
                });
                this.props.closeModal();
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            });
    };


    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, BMClosingJsonForm, this.updateComponent)
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)
            )
        }
    };


    closeConfirmAlert = (value) => {
        this.setState({
            confirmAlert: false,
        });
        if (value === "ok" && this.state.actionType === "handleSubmit") {
            this.handleSubmit("YES")
        } else if (value === "ok" && this.state.actionType === "handleReturn") {
            this.handleSubmit("NO")
        }
    };

    emptyValueRemove = (jsonObject) => {
        return JSON.parse(JSON.stringify(jsonObject));
    };

    validationForHandleConfirm = (actionType) => {
        this.setState({
            appId: this.props.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            let errorRemarks = MyValidation.defaultValidation(bmRemarks, this.state);
            if (errorRemarks===true) {
                return 0;
            }
            this.forceUpdate();
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"

            })
        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            if (actionType === "handleReturn") {
                dependencyField.push({
                    "varName": "bmRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "BM Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }

            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do you want to confirm?"
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "handleReturn")}
                    >Return
                    </button>
                </div>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.showCasaDetails === "YES") {
            return (
                <>
                    <Label accountNumber={this.state.inputData.casaAccount} stopLoading={this.stopLoading}
                           classes={this.props}/>
                    <Grid item xs={12}><br/></Grid>
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmAlert}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <ThemeProvider theme={theme}>
                    <Grid container spacing={1}>
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.renderForm()}
                        {this.mappingPhoto()}
                        {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                    </Grid>
                    {this.renderRemarksData()}
                    <Grid item xs={12}><br/></Grid>
                    {this.renderFlowSUmmeryButton()}
                    {this.renderRemarks()}
                    {this.renderSubmitButton()}
                </ThemeProvider>
            </div>
        )
    }
}

export default withStyles(styles)(BmFormLoanClosing);
