import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponent from "../../JsonForm/DateComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../../MyValidation";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import loader from "../../../Static/loader.gif";
import WaiverList from "../CommonComponent/WaiverList";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import RemarksDataList from "./RemarksDataList";


let verifyRemarks = [
    {
        "varName": "verifyRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
let groupList = [];
let jsonForm = [
    {
        "varName": "requestType",
        "type": "text",
      //  "enum": [],
        "label": "Request Type",
        "multiline":true,
        "readOnly":true,
        "grid": 8,
    }

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Please Specify",

    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferal Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

class VerifyAuxiliaryTask extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId:undefined,
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            confirmAlert: false,
            actionType: "",
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            loading: true,
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            // individualDataSaveId: '',
            // jointDataSaveId: '',
            // companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            history: false,
            accountNumber: "",
            solId: "",
            customerName: "",
            accountStatement: false,
            getDownloadListFlag: false,
            fileNotFoundTittle: "",
            downLoadFileList: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }




    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (var prop in clone)
              if (clone[prop] === '')
                  delete clone[prop];*/
        return clone;
    };
    createRemarksTable = (remarks,a,b ) => {
        return (
            [remarks,a,b ]
        )
    };
    submitApprove = (event) => {
        event.preventDefault();
        let bm_approval = 'APPROVE';
        this.handleSubmit(event, bm_approval);
    };
    submitReturn = (event) => {
        event.preventDefault();
        let bm_approval = 'REJECT';
        this.handleSubmit(event, bm_approval);
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitApprove") {
            let bm_approval = 'APPROVE';
            this.handleSubmit(bm_approval);
        } else if (data === "YES" && this.state.actionType === "submitReturn") {
            let bm_approval = 'REJECT';
            this.handleSubmit(bm_approval);
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
  delete clone[prop];*/
        return clone;
    };
    validationForHandleConfirm = (actionType) => {
        if (actionType === "submitApprove") {

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "submitApprove")}

                        //onClick={this.submitApprove}
                    > Submit
                    </button>

                </div>
            )
        }
    };
    renderDownLoadFileLink = () => {
        if (this.state.getDownloadListFlag) {
            //let i=0;
            return this.state.downLoadFileList.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={backEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>  Document Preview
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };


    componentDidMount() {
        this.setState({
            loaderNeeded: false
        });
          let varValue = [];
        var remarksArray = [];
        let downLoadFileList = [];
        let urls = backEndServerURL + "/auxiliaryTaskRequisition/get/"+this.props.Id;
        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(responseData => {


                let url = backEndServerURL + "/case/files/" + responseData.data.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            getDownloadListFlag: true,
                             downLoadFileList:  response.data,
                         })
                    })
                    .catch((error) => {
                        console.log(error);

                    })
                let inputData = this.emptyValueRemove(responseData.data);
                let varValue = responseData.data
                this.setState({
                    appId:responseData.data.appId,
                    inputData: inputData,
                    varValue: varValue,
                    showValue: true,
                    getData: true,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })



    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, appliedBy, applicationDate, dueDate, status
        ])

    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Raise By", "Raise Date", "Expire Date", "Status"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />

                    <br/>
                </div>
            )
        }

    };
    renderRemarksData = () => {
        if (this.state.appId!==undefined) {
            return (
                <RemarksDataList appId={this.state.appId}/>
            )
        }
    };
    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary"
                style={{
                    width: 150,
                    float: 'right',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Defferal</button>
        )
    };

    dynamicDeferral = (i) => {
        let deferalType = "deferalType" + i;
        let expireDate = "expireDate" + i;
        let defferalOther = "defferalOther" + i;
        let arrayData = [];
        /*arrayData.push({deferalType,expireDate,defferalOther});
        this.setState({
            getAllDefferal:arrayData
        })*/
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, "", this.onKeyDownChange)
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    handleSubmit = (data) => {
        let saveDataDeferralRemarksAndRoute = {};
        this.setState({
            loading: true
        });
        var appId = this.props.appId;
        var approval = data;

        this.state.inputData.bm_approval = data;
        //this.state.inputData["csDeferal"] = "NO";
        let inputData = this.state.inputData;
        saveDataDeferralRemarksAndRoute.remarks=this.state.inputData;
        if(this.state.inputData.verifyRemarks!==undefined && this.state.inputData.verifyRemarks!==null){
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.verifyRemarks + "/" + this.state.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
        }
        var url = backEndServerURL + "/auxiliaryTaskRequisition/update/" + this.props.Id;
        axios.post(url, {"remarks":this.state.inputData.verifyRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Closed",
                    alert: true,
                    loading: false,
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });



    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, verifyRemarks, this.updateComponent)
            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({


            uploadModal: false
        })
    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        this.close()
    };

    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeIcon = () => {
        if (this.props.appId !== undefined) {
            this.props.closeIcon();
        } else {
            this.props.closeModal();
        }

    };
    renderHeader = () => {
        return (
            <h4 style={{color: "white"}} ><b>Verify Auxiliary Task</b><a><CloseIcon
                onClick={this.closeIcon}
                style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>
        )
    }

    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}

                </CardHeader>
                <CardBody>
                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            open={this.state.getMappingAllImage}>
                            <DialogContent>
                                <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.history}>
                            <DialogContent className={classes.dialogPaper}>
                                <SubmittedCaseHistory customerName={this.state.customerName}
                                                      accountNumber={this.state.accountNumber} solId={this.state.solId}
                                                      globalSearch={true} closeIcon={this.closeHistoryModal}
                                                      appId={this.props.appId} closeModal={this.closeHistoryModal}/>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert
                                    validationForHandleConfirm={this.validationForHandleConfirm}
                                    closeModal={this.closeConfirmAlert}/>

                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.returnJsonForm()}
                                <Grid item xs='12'>
                                </Grid>
                                <Grid item xs='12'>

                                    {this.renderRemarksData()}

                                </Grid>
                                {/* <Grid item xs={1}>
                                    <Grid item xs={12}>
                                        <button
                                            style={{
                                                width: 150,
                                            }}
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.mappingAllImage}
                                        >
                                            Preview Document
                                        </button>
                                    </Grid>
                                </Grid>*/}
                                {this.renderRemarks()}
                                <Grid item xs='12'>
                                </Grid>
                                <Grid item xs={6}>
                                    {this.renderDownLoadFileLink()}

                                </Grid>
                                <Grid item xs='12'>
                                </Grid>
                                <center>
                                    {this.renderSubmitButton()}
                                </center>
                            </Grid>
                        </ThemeProvider>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(VerifyAuxiliaryTask);
