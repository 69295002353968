import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import MyValidation from "../../../JsonForm/MyValidation";
import Label from './Label';
import PreviewButton from "../PreviewButton";
import UploadComponent from "../casa/UploadComponent";
import loader from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
// import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
// import TextField from "@material-ui/core/TextField";
import {fdrMaintenance} from "./JsonForFdrMaintenance";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CheckList from "./CheckList";
import FDRMaintenanceUtility from "./MaintenanceUtility";

// const numberOfNominee = [
//     {title: '1'},
//     {title: '2'},
//     {title: '3'},
//     {title: '4'},
//     {title: '5'},
//     {title: '6'},
//     {title: '7'},
//     {title: '8'},
//     {title: '9'},
//     {title: '10'},
//     {title: '11'},
//     {title: '12'},
//     {title: '13'},
//     {title: '14'},
//     {title: '15'},
//     {title: '16'},
//     {title: '17'},
//     {title: '18'},
//     {title: '19'},
//     {title: '20'}
// ];

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",
};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "other"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let searchFieldList = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account No",
        "required": true,
        "grid": 2,

    },
    {
        "varName": "subCategory",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            'Tenor or Scheme Change',
            'Link ACC Change',
            'FD Nominee Change',
            'S. Tax Reversal',
            'Dupl. Advice (Special)',
            'Auxiliary Task',
        ],
        "label": "FDR Maintenance Category",
        "required": true,
        "grid": 2,

    },
];

let searchFieldListReturn = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 3,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account No",
        "required": true,
        "readOnly": true,
        "grid": 3,

    },
    {
        "varName": "subCategory",
        "type": "text",
        "label": "FDR Maintenance Category",
        "required": true,
        "readOnly": true,
        "grid": 3,

    },
];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class FDRMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            cbNotFound: false,
            searchTableData: false,
            inputData: {
                accountNumber: "",
                csDeferal: "NO",
            },
            values: [],
            appId: '',
            message: "",
            getData: false,
            varValue: {},
            caseId: 0,
            title: "",
            notificationMessage: "",
            alert: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            AddDeferal: false,
            showValue: false,
            getDeferalList: [],
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            getNumberofNominee: false,
            objectForInitialNewNominee: [],
            getInitialNewNominee: 0,
            objectForNominee: [],
            disabled: false,
            city: [],
            state: [],
            country: [],
            relationCode: [],
            guardianCode: [],
            submitFlag: "",
            confirmationAlert: false,
            checkList: false,
        }
    }

    renderTenorRelatedApi = (tenorType, tenorPeriod, renewal, maturity, apiType) => {
        let obj = {};
        if (tenorType !== undefined && apiType === "tenorType") {
            this.state.inputData.showTenorPeriod = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor/maintenance";
            obj.tenureType = tenorType;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of fdrMaintenance) {
                        if (item.varName === "newTenor") {
                            item.enum = response.data
                        }
                    }
                    let inputData = this.state.inputData;
                    inputData.showTenorPeriod = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    });
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (tenorPeriod !== undefined && apiType === "tenorPeriod") {
            this.state.inputData.showRenewal = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of fdrMaintenance) {
                        if (item.varName === "renewal") {
                            item.enum = response.data.renewal
                        }
                    }
                    let inputData = this.state.inputData;
                    inputData.showRenewal = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    });
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (renewal !== undefined && apiType === "renewal") {
            this.state.inputData.showMaturity = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            obj.autoRenewals = renewal;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of fdrMaintenance) {
                        if (item.varName === "csmaturity") {
                            item.enum = response.data
                        }
                    }
                    let inputData = this.state.inputData;
                    inputData.showMaturity = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    });
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (maturity !== undefined && apiType === "maturity") {
            this.state.inputData.csshowSchemeCode = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            obj.autoRenewals = renewal;
            obj.maturity = maturity;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.csfdrSchemeCode = response.data;
                    inputData.csshowSchemeCode = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    });
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values});

        } else {
            this.setState({
                values: [],
            })
        }
    };

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        this.dynamicApiCall("city", "city");
        this.dynamicApiCall("country", "country");
        this.dynamicApiCall("state", "state");
        this.dynamicApiCall("guardianCode", "guardianCode");
        this.dynamicApiCall("relationCode", "relationCode");
        this.dynamicApiCall("csfdrSchemeCode", "fdrSchemeCode");
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.subCategory === "Tenor or Scheme Change") {
                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "tenorType");

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "tenorPeriod");

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "renewal");

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "maturity");
                    }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    this.setState({
                                        fileUploadData: response.data,
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "NO";

                    let varValue = response.data;
                    this.setState({
                        getData: true,
                        varValue: varValue,
                        searchTableData: true,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        // loading: false,
                        accountNumber: response.data.accountNumber,
                        subCategory: response.data.subCategory,
                    });

                    this.state.varValue["subCategory"] = response.data.subCategory;
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            let url = backEndServerURL + "/startCase/cs_maintenance";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    this.setState({
                        caseId: response.data.caseId,
                        appId: response.data.id,
                        showValue: true,
                        getData: true,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "city") {
                    this.setState({
                        city: response.data
                    })
                }
                if (apiType === "state") {
                    this.setState({
                        state: response.data
                    })
                }
                if (apiType === "country") {
                    this.setState({
                        country: response.data
                    })
                }
                if (apiType === "guardianCode") {
                    this.setState({
                        guardianCode: response.data
                    })
                }
                if (apiType === "relationCode") {
                    this.setState({
                        relationCode: response.data
                    })
                }

                if (apiType === "fdrSchemeCode") {
                    let jsonArrayServiceList = fdrMaintenance;

                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "csfdrSchemeCode") {
                            fdrMaintenance[i].enum = response.data;

                        }
                    }
                }

            })
            .catch((error) => {
                console.log(error);
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    renderAddButtonShow = () => {

        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',
                }}

                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Deferral</button>
        )
    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <UploadComponent appId={this.state.appId} classes={classes}/>
        }
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    renderSearchFormReturn = () => {
        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, searchFieldListReturn, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderSearchForm = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, searchFieldList, this.updateComponent, "", this.onKeyDownChange)
                        }
                        <Grid item xs={4}>
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={this.handleSearch}
                                className="btn btn-danger btn-sm">
                                Search
                            </button>
                            &nbsp;
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={() => window.location.reload()}
                                className="btn btn-danger btn-sm">
                                Reset
                            </button>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </React.Fragment>
        )
    };


    handleSearch = () => {
        let error = MyValidation.defaultValidation(searchFieldList, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                getData: false,
                searchTableData: false,
                loading: true,
            });
            let inputData = this.state.inputData;
            let getTermUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
            axios.get(getTermUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((acResponse) => {
                    inputData.fdValueDate = acResponse.data.depositAccount !== undefined ? acResponse.data.depositAccount.accountOpenDate.split("T")[0] : "";
                    let fData = new Date(inputData.fdValueDate);
                    if (fData.getFullYear() > 2010 && ((fData.getMonth() + 1) > 2) && fData.getDate() > 10) {
                        inputData.showFdValueChecker = false
                    } else {
                        inputData.showFdValueChecker = true
                    }
                    inputData.requestedSolId = acResponse.data.requestedSolId;
                    inputData.currencyCode = acResponse.data.depositAccount.acctCurrency;
                    inputData.existingLinkAccount = acResponse.data.depositAccount !== undefined ? acResponse.data.depositAccount.repayacctid : "";
                    if (acResponse.data.nominationList !== undefined && acResponse.data.nominationList.length > 0) {
                        let sl = 0;
                        let nominationList = acResponse.data.nominationList;
                        for (let i = 0; i < nominationList.length; i++) {
                            sl = i + 1;
                            inputData["exnomineeidnum" + sl] = nominationList[i].nomineeidnum;
                        }
                    }

                    if (acResponse.data.nomineeInfoList !== undefined && acResponse.data.nomineeInfoList.length > 0) {
                        inputData.nomineeNumberOld = acResponse.data.nomineeInfoList.length;
                        inputData.registrationNumber = acResponse.data.nomineeInfoList[0].regNum;
                        let sl = 0;
                        let nomineeInfo = acResponse.data.nomineeInfoList;
                        for (let i = 0; i < nomineeInfo.length; i++) {
                            sl = i + 1;
                            inputData["exnomineecifId" + sl] = (acResponse.data.nomineeInfoList !== undefined && acResponse.data.nomineeInfoList.length > 0) ? acResponse.data.nomineeInfoList[i].nomineecifid : "";
                            inputData["exnomineeName" + sl] = nomineeInfo[i].nomineeName;
                            inputData["expercentageNominee" + sl] = nomineeInfo[i].nomineePercent;
                            inputData["exnomineeDob" + sl] = nomineeInfo[i].nomineeBirthDt;
                            inputData["exnomineeMinor" + sl] = nomineeInfo[i].nomineeMinorFlg === "Y" ? "YES" : "NO";
                            inputData["exnomineeDobApiCall" + sl] = nomineeInfo[i].nomineeMinorFlg === "Y" ? true : false;
                            inputData["exnomineeGuardiansName" + sl] = nomineeInfo[i].nomineeGuardianName;
                            inputData["exnomineeRegNo"] = nomineeInfo[i].regNum;
                            inputData["exnomineeRelationship" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].relType, this.state.relationCode);
                            inputData["exnomineeAddress1" + sl] = nomineeInfo[i].addr1;
                            inputData["exnomineeAddress2" + sl] = nomineeInfo[i].addr2;
                            inputData["exnomineeCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].city, this.state.city);
                            inputData["exnomineeStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].stateProv, this.state.state);
                            inputData["exnomineePostalCode" + sl] = nomineeInfo[i].postalCode;
                            inputData["exnomineeCountry" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].country, this.state.country);
                            inputData["exphotoIdNumberRegNo" + sl] = nomineeInfo[i].addr3;
                            inputData["exnomineeGuardianCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCode, this.state.guardianCode);
                            inputData["exguardianPhotoId" + sl] = nomineeInfo[i].nomineeGuardianAddr3;
                            inputData["exnomineeGuardianAddress" + sl] = nomineeInfo[i].nomineeGuardianAddr1;
                            inputData["exnomineeGuardianAddress2" + sl] = nomineeInfo[i].nomineeGuardianAddr2;
                            inputData["exnomineeGuardianCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCity, this.state.city);
                            inputData["exnomineeGuardianStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianStateProv, this.state.state);
                            inputData["exnomineeGuardianPostalCode" + sl] = nomineeInfo[i].nomineeGuardianPostalCode;
                            inputData["exnomineeGuardianCountry2" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCountry, this.state.country);
                        }
                    }
                    let getAccountUrl = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.motherBranchSolId = response.data.solId;
                            inputData.solId = response.data.solId;
                            inputData.mainAccountCurrency = response.data.crncyCode;
                            inputData.csDeferal = "NO";
                            inputData.cbNumber = response.data.cifId;
                            inputData.cifId = response.data.cifId;
                            inputData.customerName = response.data.acctName;
                            for (let item of searchFieldList) {
                                if (item.varName === "subCategory") {
                                    item.readOnly = true
                                }
                            }

                            let cifUrl = backEndServerURL + "/getCustomerCIFDetails/" + response.data.cifId;
                            axios.get(cifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((cifResponse) => {
                                    inputData.showSolChecker = (cifResponse.data.freecode1 === "" && (inputData.requestedSolId !== inputData.motherBranchSolId));
                                    let deferralUrl = backEndServerURL + "/deferral/getByAccountAndStatus/" + this.state.inputData.accountNumber;
                                    axios.post(deferralUrl, ["ACTIVE", "CLOSE_WAITING"], {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((deferralResponse) => {
                                            inputData.showDeferral = deferralResponse.data.length > 0;
                                            setTimeout(() => {
                                                this.setState({
                                                    getData: true,
                                                    inputData: inputData,
                                                    varValue: this.copyJson(inputData),
                                                    searchTableData: true,
                                                    showValue: true,
                                                })
                                            }, 1000)
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: this.copyJson(inputData),
                                showValue: true,
                                cbNotFound: true,
                                title: error.response.data.message,
                                loading: false
                            });
                            console.log(error)
                        })
                })
                .catch((error) => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    });
                    console.log(error)
                })
        }
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        if (value && (typeof value !== "string")) {
            return value
        } else {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            submitFlag: "Close",
            title: "Do you want to confirm?",
            confirmationAlert: true
        })
    };

    renderMultiTaskLink = () => {
        if (this.state.inputData.subCategory === "Dupl. Advice (Special)") {
            return (
                <>
                    <button className="btn btn-outline-primary btn-sm">
                        <a
                            className="anchorButton"
                            target="_blank"
                            href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                            Multitask Link</a>
                    </button>
                </>
            )
        }
    };


    renderData = () => {
        if (this.state.inputData.subCategory) {
            return (
                <React.Fragment>
                    <ThemeProvider theme={theme}>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.renderAddButtonShow()}
                            </Grid>
                            {this.addDeferalForm()}
                            <Grid item xs={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.uploadFunction()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderMultiTaskLink()}
                            </Grid>
                            {this.renderRemarks()}
                            <Grid item xs={12}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{}}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.disabled}
                                >
                                    Submit
                                </button>
                                {/*&nbsp;&nbsp;&nbsp;*/}
                                {/*<button*/}
                                {/*    className="btn btn-outline-info btn-sm"*/}
                                {/*    style={{}}*/}
                                {/*    onClick={this.handleSubmitDraft}*/}
                                {/*    disabled={this.state.disabled}*/}
                                {/*>*/}
                                {/*    Draft*/}
                                {/*</button>*/}
                                {this.props.appId !== undefined && <>&nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{}}
                                        onClick={this.handleClose}
                                        disabled={this.state.disabled}
                                    >
                                        Close
                                    </button>
                                </>}
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </React.Fragment>
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "tenorType") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "tenorType")
        }
        if (data.varName === "newTenor") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "tenorPeriod")
        }
        if (data.varName === "renewal") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "renewal")
        }
        if (data.varName === "csmaturity") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "maturity")
        }
    };
    onBlurApiCall = (event, data) => {
        console.log(data);
    };

    renderSearchData = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, fdrMaintenance, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                            {this.renderExistingNomineeForm()}
                            {this.renderData()}
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderSearchDataReturn = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, fdrMaintenance, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                            <br/>
                            {this.renderExistingNomineeForm()}
                            {this.renderData()}
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <Label cbNumber={this.state.inputData.cbNumber} stopLoading={this.stopLoading}
                       subCategory={this.state.inputData.subCategory} accountNumber={this.state.inputData.accountNumber}
                       classes={this.props}/>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    checkListHandleSubmit = (value) => {
        this.setState({
            checkList: false,
            loading: true,
        }, this.functionForRoute(value));
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });
            if (this.state.submitFlag === "Close") {
                let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
                axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Close!",
                                alert: true,
                                disabled: false
                            });
                            this.props.closeModal();
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            disabled: false
                        });
                    });
            } else {
                this.setState({
                    checkList: true,
                    loading: false
                })
            }
        } else {
            this.setState({
                confirmationAlert: false,
                disabled: false
            })
        }
    };

    checkListClose = () => {
        this.setState({
            checkList: false,
            loading: false,
            disabled: false
        })
    };

    functionForRoute = (value) => {
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let deferalType = this.state.inputData["deferalType" + value];
                if (deferalType === "other") {
                    deferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(deferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                })
        }
        var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        let data = {...this.state.inputData, ...value};
        data.csRemarks = undefined;
        data.serviceType = "Maintenance";
        data.subServiceType = "FDRMaintenance";
        data.category = "FDR";
        data.subCategory = this.state.inputData.subCategory;
        data.freeFlag1 = this.state.inputData.subCategory;
        data.freeFlag2 = "FDR Maintenance";
        data.freeFlag3 = `${this.state.inputData.subCategory}`;
        data.cs_bearer = "NO";

        if (this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) {
            data.cs_deferal = "NO";
            data.cs_bearer = "NO";
        } else if (this.state.inputData.csDeferal === "YES") {
            data.cs_deferal = "YES";
        }
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                    var url = backEndServerURL + "/FDR/saveRoutingVariables/" + this.state.appId;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
                            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data)
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        disabled: false
                                    });
                                });
                            var url = backEndServerURL + "/case/route/" + this.state.appId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.status === 200) {
                                        this.setState({
                                            title: "Successful!",
                                            notificationMessage: "Successfully Routed!",
                                            alert: true,
                                            disabled: false
                                        });
                                        if (this.props.appId !== undefined) {
                                            this.props.closeModal()
                                        } else {
                                            setTimeout(function () {
                                                window.location.reload()
                                            }, 1000);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        disabled: false,
                                        loading: false
                                    });
                                });
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                                loading: false,
                                title: error.response.data.message,
                                cbNotFound: true,
                            });
                        });
                }
            )
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false
                });
            });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let errorThree = MyValidation.defaultValidation(fdrMaintenance, this.state);
        this.forceUpdate();

        if (errorThree === true) {
            return 0;
        } else {
            this.setState({
                submitFlag: "APPROVE",
                title: "Do you want to confirm?",
                confirmationAlert: true
            })
        }


    };


    // handleSubmitDraft = (event) => {
    //     event.preventDefault();
    //
    //     this.setState({
    //         disabled: true
    //     });
    //     if (this.state.inputData["csDeferal"] === "YES") {
    //         var defType = [];
    //         var expDate = [];
    //
    //         let appId = this.state.appId;
    //         for (let i = 0; i < this.state.values.length; i++) {
    //             let value = this.state.values[i];
    //             let defferalType = this.state.inputData["deferalType" + value];
    //             if (defferalType === "other") {
    //                 defferalType = this.state.inputData["deferalOther" + value];
    //             }
    //             defType.push(defferalType);
    //             let expireDate = this.state.inputData["expireDate" + value];
    //             expDate.push(expireDate);
    //
    //         }
    //
    //         let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
    //         axios.post(deferalRaisedUrl, {
    //             appId: appId,
    //             type: defType,
    //             dueDate: expDate
    //         }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
    //             .then((response) => {
    //                 console.log(response.data);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 this.setState({
    //                     disabled: false
    //                 })
    //             })
    //     }
    //     var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
    //
    //     let data = this.state.inputData;
    //     data.cs_deferal = this.state.inputData["csDeferal"];
    //     data.serviceType = "Maintenance";
    //     data.subServiceType = "FDRMaintenance";
    //     data.category = "FDR";
    //     data.subCategory = this.state.inputData.subCategory;
    //
    //
    //     axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
    //         .then((response) => {
    //             console.log(response.data);
    //             this.setState({
    //                 title: "Successful!",
    //                 notificationMessage: "Successfully Draft!",
    //                 alert: true,
    //                 disabled: false
    //             });
    //             this.close()
    //         })
    //         .catch((error) => {
    //             this.setState({
    //                 disabled: false
    //             });
    //             console.log(error)
    //         });
    //
    // };

    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        }
    };


    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close,this.props.caseId);
        } else {
            return (
                <h4 style={{color: "white"}} >FDR Maintenance</h4>
            )
        }
    };

    previewButton = () => {
        if (this.state.searchTableData && this.state.inputData.subCategory) {
            return (
                <React.Fragment>
                    <Grid item xs={12}><br/></Grid>
                    <Grid container spacing={0}>
                        <PreviewButton appId={this.state.appId} classes={this.props}/>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.subCategory === "FD Nominee Change") {
            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "exNominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 2,
                        "multiline": true,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "expercentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 2,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "subCategory",
                        "conditionalVarValue": "FD Nominee Change"
                    },
                    {
                        "varName": "exnomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 2,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["subCategory", "exnomineeMinor" + sl,],
                        "conditionalArrayValue": ["FD Nominee Change", "Yes"],
                    },
                )
            }
            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
                    }
                </>
            )

        }
    };


    renderAllDialog = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.checkList}>
                    <DialogContent>
                        <CheckList inputData={this.state.inputData} closeModal={this.checkListClose}
                                   handleSubmit={this.checkListHandleSubmit}/>
                    </DialogContent>
                </Dialog>
            </>
        )
    };


    render() {
        Functions.redirectToLogin(this.state);
        if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialog()}
                        {this.renderSearchFormReturn()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.previewButton()}
                        {this.renderSearchDataReturn()}
                    </CardBody>
                </Card>
            );
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        {this.renderAllDialog()}
                        {this.renderSearchForm()}
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        {this.previewButton()}
                        {this.renderSearchData()}
                    </CardBody>
                </Card>
            );
        }
    }

}

export default withStyles(styles)(FDRMaintenance);
