import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {backEndServerURL} from "../../../../Common/Constant";
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import signature from "../../../../assets/image.png";


class PreviewDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
        }
    }


    componentDidMount() {

        console.log(this.props.appId);

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    images: response.data
                })

            })
            .catch((error) => {
                console.log(error)
            })
    }

    renderImage = () => {
        return <img style={{width: '100%', height: 'auto'}} src={signature}/>
    };


    render() {
        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Preview Document<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>

                                        {
                                            this.renderImage()
                                        }


                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default PreviewDocument;