import Table from "../Table/Table.jsx";
import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import LineOfBusinessAddEditDelete from "./LineOfBusinessAddEditDelete";
import 'antd/dist/antd.css';
import {notification} from 'antd';

import FormSample from "../JsonForm/FormSample";

import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [

        {
            "varName": "lineOfBusinessName",
            "type": "text",
            "label": "Line Of Business Name",
            "number": false,
        },
        {
            "varName": "createdBy",
            "type": "text",
            "label": "Created By",
        },
        {
            "varName": "modifiedBy",
            "type": "text",
            "label": "Modified By",
        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class LineOfBusiness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addBusinessLine: false,
            tableData: [[" ", " "]],
            editLineOfBusiness: false,
            businessLineName: '',
            open: true,
            businessData: [[" ", " "]],
            redirectLogin: false,
        }
    }

    createTableData = (id, lineOfBusinessName, createdBy, modifiedBy, createdDate, modifiedDate, status) => {

        return ([lineOfBusinessName, createdBy, modifiedBy, createdDate, modifiedDate, status,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editlineOfBusiness(id)}>
                Edit</button>
        ]);

    };
    editlineOfBusiness = (id) => {
        this.setState({
            editLineOfBusiness: true,
            lineOfBusinessId: id
        })
    };

    componentDidMount() {
        let url = backEndServerURL + "/lineOfBusinessMaster/getAll";

        const getAllBusiness = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map((businessMaster) => {

                    getAllBusiness.push(businessMaster);
                });
                this.setState({
                    tableData: getAllBusiness
                })

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }

    addBusinessLine() {
        this.setState({
            addBusinessLine: true,
        });
    }

    cancelBusinessLineModal = () => {
        this.setState({addBusinessLine: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {
        this.setState({
            addBusinessLine: false,
            editLineOfBusiness: false

        });
    };
    onAdd = (object) => {
        this.setState({
            addBusinessLine: false

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editLineOfBusiness: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };
    getSubmitedForm = (object) => {
        console.log(object.data);
        let objectTable = {};
        let tableArray = [];
        for (let variable in object) {
            let trimData = object[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }

        this.state.tableData.map((businessMaster) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== businessMaster[variable])
                    showable = false;
            }
            if (showable)
                tableArray.push(this.createTableData(businessMaster.id, businessMaster.lineOfBusinessName, businessMaster.createdBy, businessMaster.modifiedBy, businessMaster.createdDate, businessMaster.modifiedDate, businessMaster.status));


        });
        this.setState({
            businessData: tableArray
        })
    };
    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="12" buttonName="Filtering" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addBusinessLine}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <LineOfBusinessAddEditDelete onAdd={this.onAdd} secondButtonFunction={this.closeModal}
                                                     secondButtonName="close" name="Add New Line Of Business"/>

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editLineOfBusiness}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <LineOfBusinessAddEditDelete onUpdate={this.onUpdate} secondButtonFunction={this.closeModal}
                                                     secondButtonName="close" name="Edit Line Of Business"
                                                     id={this.state.lineOfBusinessId}/>

                    </DialogContent>

                </Dialog>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Line Of Business List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderFilterForm()}
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addBusinessLine: true
                                            })
                                        }
                                        }
                                    >
                                        Add New Line of Business
                                    </button>

                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Line Of Business Name", "Created by", "Modified by", "Created Date", "Modified Date", "Status", "Action"]}
                                        tableData={this.state.businessData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(LineOfBusiness);
