import React from "react";
import FormSample from '../JsonForm/FormSample';
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../Common/Constant";
import axios from 'axios';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [
        {
            "varName": "dropdownSectorName",
            "type": "dropdownSearch",
            "label": "Sector Name",
        },
        {
            "varName": "subSectorName",
            "type": "text",
            "label": "Sub Sector Name",
            "required": true,

        },
        {
            "varName": "subSectorCode",
            "type": "text",
            "label": "Sub Sector Code",
            "required": false,
            "select": true,
            "number": true

        },
        {
            "varName": "status",
            "type": "select",
            "label": "status",
            "required": false,
            "select": true,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]


        },

    ],

};

class SubSectorAddEditDelete extends React.Component {
    renderEditForm = (() => {
        if (this.state.getSubSectorData) {
            return (

                <FormSample secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} showValue={true}
                            varValue={this.state.varValue} grid="12"
                            buttonName="Submit" dropdownSearchData={this.state.dropdownSearchData}
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>


            )
        } else if (this.props.id === undefined) {
            return (


                <FormSample dropdownSearchData={this.state.dropdownSearchData}
                            secondButtonFunction={this.props.secondButtonFunction}
                            secondButtonName={this.props.secondButtonName} grid="12" buttonName="Submit"
                            onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm}/>


            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: [],
            dropdownSearchData: [],
            getSubSectorData: false,
            getSectorData: false,
            redirectLogin: false,

        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    getSubmitedForm = (object) => {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/subSectorMaster/update/" + this.props.id;
            axios.post(url, {
                "sectorId": object.dropdownSectorName,
                "subSectorCode": object.subSectorCode,
                "subSectorName": this.firstLetterUpperCase(object.subSectorName),
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.onUpdate()
                })
                .catch((error) => {
                    console.log(error);
                })


        } else {
            let url = backEndServerURL + "/subSectorMaster/add";

            axios.post(url, {
                "sectorId": object.dropdownSectorName,
                "subSectorCode": object.subSectorCode,
                "subSectorName": this.firstLetterUpperCase(object.subSectorName),
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.onAdd(object);


                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    componentDidMount() {

        const getSector = [];
        let dropdownData = [];
        let url = backEndServerURL + "/sectorMaster/getAll";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let useData = [];
                response.data.map((sector) => {
                    getSector.push(sector.id, sector.sectorCode, sector.sectorName);

                    let object = {
                        "label": sector.sectorName,
                        "value": sector.id
                    };

                    useData.push(object);

                });
                dropdownData["dropdownSectorName"] = useData;
                this.setState({
                    dropdownSearchData: dropdownData,
                    getSectorData: true

                })

            })
            .catch((error) => {
                console.log(error);
            });
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/subSectorMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let varValue = [];
                    varValue["subSectorCode"] = response.data.subSectorCode;
                    varValue["subSectorName"] = response.data.subSectorName;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getSubSectorData: true

                    })
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }

    }

    render() {


        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}

                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(SubSectorAddEditDelete);
