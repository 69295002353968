import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import {Dialog} from "@material-ui/core";
import {backEndServerURL} from "../../../Common/Constant";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import axios from "axios";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import ErrorModal from "./ErrorModal";
import MakerCumInput from "../CASA/MakerCumInput";
import SecondaryJointCbTagging from "../CASA/SecondaryJointCbTagging";

let dedupType = [{title: 'Existing'},
    {title: 'New'},];
let jsonForm = [
    {
        "varName": "tagType",
        "type": "select",
        "enum": [
            "Existing",
            "New",
        ],
        "label": "Tag Type",
        "grid": 2,


    },
    {
        "varName": "cbNumber",
        "type": "textApiCall",
        "label": "CIF ID",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "tagType",
        "conditionalVarValue": "Existing",

    },
    {
        "varName": "cbName",
        "type": "text",
        "label": "Customer Name",
        "grid": 2,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["tagType", "cbNumberApiCall"],
        "conditionalArrayValue": ["Existing", true],

    },
];

class WithoutDedupeLinkCBTypeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            values: [],
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            loading: false,
            inputData: {},
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            errorModalBoolean: false,
            errorTittle: "Invalid Number",
            randomNumbergenerate: 0,


        }
    }


    componentDidMount() {
        this.setState({
            getData: true,
            showValue: true,
            randomNumbergenerate: Math.floor(Math.random() * 100000000000)
        })
    }


    stopLoading = () => {
        this.setState({
            loader: false
        })
    };
    handleChangeJoint = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.setState({
                handleChangeJoint: option.title
            })
        } else {
            this.setState({
                handleChangeJoint: ""
            })

        }

    };
    renderNextProcess = () => {
        if (this.state.getData && (this.state.inputData.cbNumberApiCall === true || this.state.inputData.tagType === "New")) {
            return (
                <Grid item xs={3}>

                    <button
                        style={{
                            marginTop: "15px"
                        }}
                        onClick={this.handleSubmitWithoutProprietorshipAccountForm}
                        className="btn btn-danger btn-sm">
                        Tag
                    </button>
                </Grid>
            )
        }
    };
    closeModalNew = () => {
        this.setState({
            renderCumModalopenNew: false,
            renderCumModalopen: false,
        })
    };
    handleSubmitWithoutProprietorshipAccountForm = () => {
        if (this.state.inputData.tagType === "New") {
            this.setState({
                renderCumModalopenNew: true
            })
        } else {
            this.setState({
                renderCumModalopen: true
            })

        }


    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        if (varName === "cbNumber") {
            this.setState({
                loading: true,
            });
            this.state.inputData.cbNumberApiCall = false;
            this.state.varValue.cbNumberApiCall = false;
            this.forceUpdate();
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;

             let getCbType = backEndServerURL + "/getCbTypes/" + inputData.cbNumber;
            axios.get(getCbType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((cbTypeResponse) => {
                if (cbTypeResponse.data !=="") {
                    if (cbTypeResponse.data !== "RETAIL") {
                        this.setState({
                            errorModalBoolean: true,
                            errorTittle: "Not a Retail Account!"
                        });
                    }

                    let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            inputData.cbNumber = this.state.inputData[secondVarName];
                            inputData.cbName = response.data.customerName;
                            varValue.cbName = response.data.customerName;
                            console.log(response.data);
                            inputData.cbNumberApiCall = true;
                            varValue.cbNumberApiCall = true;
                            this.forceUpdate();
                            this.setState({
                                inputData: inputData,
                                varValue: varValue,
                                showValue: true,
                                loading: false,
                            })
                        })
                        .catch((error) => {
                            inputData.cbNumber = "__ERROR__";
                            varValue.cbNumber = "__ERROR__";
                            this.forceUpdate();
                            console.log(error);
                            this.setState({
                                inputData: inputData,
                                varValue: varValue,
                                loading: false,
                                errorModalBoolean: true,
                                errorTittle: "Invalid Number"
                            })
                        })
                }
                else if (cbTypeResponse.data === "") {
                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Account!"
                    });
                }
                else {
                    this.setState({

                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Not a Retail Account!"
                    });
                }
            }).catch((error) => {
                this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                this.setState({
                    loader: false,
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                });
            });


        }
    };

    onKeyDownChange = () => {

    };
    updateComponent = () => {
        this.forceUpdate();
    };

    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        }
    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false

        })
    };
    taging = (index, data) => {
        let value = index + 1;
        if (data !== undefined) {
            this.props.getTaggingData("tagging" + value, data, value);
        } else {
            this.props.getTaggingData("tagging" + value, this.state.getRadioButtonData["tagging" + value], value);
        }


    };
    renderTagging = () => {
        if (this.props.WithJointCb === true && this.state.inputData.tagType === "Existing") {
            return (
                <SecondaryJointCbTagging makerEnd={true} WithJointCb={this.props.WithJointCb}
                                         introducerId={this.props.introducerId}
                                         introducerName={this.props.introducerName}
                                         freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                         accountNumber={this.props.accountNumber} solId={this.props.solId}
                                         freeFlag4={this.props.freeFlag4}
                                         appId={this.props.appId} closeIcon={this.props.closeModal}
                                         taggingForm={this.props.taggingForm}
                                         appId={this.props.appId}
                                         editMode={true}
                                         hideButton={this.props.hideButton}
                                         relatedPartyType="JOINT"
                                         generateAccountNo="Tag"
                                         renderTagging={this.props.renderTagging}
                                         getTaggingData={this.props.getTaggingData}
                                         customerNumber={this.state.randomNumbergenerate}
                                         getCbNumber={this.state.getCbNumber}
                                         tagingModalCbnumber={this.state.randomNumbergenerate}
                                         taging={this.props.renderTagging}
                                         cbNumber={this.state.inputData.cbNumber}
                                         serviceType={this.props.serviceType}
                                         closeModal={this.props.closeModal}/>
            )
        } else if (this.state.inputData.tagType === "Existing") {
            return (
                <MakerCumInput WithJointCb={this.props.WithJointCb} freeFlag1={this.props.freeFlag1}
                               customerName={this.props.customerName}
                               accountNumber={this.props.accountNumber} solId={this.props.solId}
                               freeFlag4={this.props.freeFlag4}
                               appId={this.props.appId} closeIcon={this.props.closeModal}
                               taggingForm={this.props.taggingForm}
                               appId={this.props.appId}
                               hideButton={this.props.hideButton}
                               relatedPartyType="JOINT"
                               generateAccountNo="Tag"
                               customerNumber={this.state.randomNumbergenerate}
                               getCbNumber={this.state.getCbNumber}
                               tagingModalCbnumber={this.state.randomNumbergenerate} taging={this.props.renderTagging}
                               cbNumber={this.state.inputData.cbNumber}
                               serviceType={this.props.serviceType}
                               renderTagging={this.props.renderTagging}
                               getTaggingData={this.props.getTaggingData}
                               closeModal={this.props.closeModal}/>
            )
        } else {
            return (
                <MakerCumInput WithJointCb={this.props.WithJointCb} freeFlag1={this.props.freeFlag1}
                               customerName={this.props.customerName}
                               accountNumber={this.props.accountNumber} solId={this.props.solId}
                               freeFlag4={this.props.freeFlag4}
                               appId={this.props.appId} closeIcon={this.props.closeModal}
                               taggingForm={this.props.taggingForm}
                               appId={this.props.appId}
                               hideButton={this.props.hideButton}
                               relatedPartyType="JOINT"
                               generateAccountNo="NEW"
                               customerNumber={this.state.randomNumbergenerate}
                               getCbNumber={this.state.getCbNumber}
                               tagingModalCbnumber={this.state.randomNumbergenerate} taging={this.props.renderTagging}
                    // cbNumber={this.state.inputData.cbNumber}
                               serviceType={this.props.serviceType}
                               renderTagging={this.props.renderTagging}
                               getTaggingData={this.props.getTaggingData}
                               closeModal={this.props.closeModal}/>
            )
        }
    };

    render() {

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB Type<a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Grid container spacing={3}>
                                    <ThemeProvider theme={theme}>

                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Dialog
                                                    fullWidth="true"
                                                    maxWidth="sm"

                                                    open={this.state.loading}>
                                                    <DialogContent>

                                                        <center>
                                                            <img src={loader} alt=""/>
                                                        </center>
                                                    </DialogContent>
                                                </Dialog>

                                                <Dialog
                                                    fullWidth="true"
                                                    maxWidth="xl"
                                                    fullScreen={true}

                                                    open={this.state.renderCumModalopenNew}>
                                                    <DialogContent>
                                                        <MakerCumInput freeFlag1={this.props.freeFlag1}
                                                                       customerName={this.props.customerName}
                                                                       accountNumber={this.props.accountNumber}
                                                                       solId={this.props.solId}
                                                                       freeFlag4={this.props.freeFlag4}
                                                                       introducerId={this.props.introducerId}
                                                                       introducerName={this.props.introducerName}
                                                                       appId={this.props.appId}
                                                                       closeIcon={this.props.closeModal}
                                                                       customerNumber={this.state.randomNumbergenerate}
                                                                       appId={this.props.appId}
                                                                       hideButton={this.props.hideButton}
                                                                       relatedPartyType="JOINT"
                                                                       generateAccountNo="NEW"
                                                                       tagingModalCbnumber={this.state.randomNumbergenerate}
                                                                       taging={this.props.renderTagging}
                                                            //cbNumber={this.state.inputData.cbNumber}
                                                                       serviceType={this.props.serviceType}
                                                                       closeModal={this.props.closeModal}/>
                                                    </DialogContent>
                                                </Dialog>
                                                <Dialog
                                                    fullWidth="true"
                                                    maxWidth="xl"
                                                    fullScreen={true}

                                                    open={this.state.renderCumModalopen}>
                                                    <DialogContent>

                                                        {this.renderTagging()}
                                                    </DialogContent>
                                                </Dialog>
                                                <Dialog
                                                    fullWidth="true"
                                                    maxWidth="sm"

                                                    open={this.state.errorModalBoolean}>
                                                    <DialogContent>
                                                        <ErrorModal title={this.state.errorTittle}
                                                                    closeModal={this.closeModal}
                                                                    closeIcon={this.closeModal}

                                                        />

                                                    </DialogContent>
                                                </Dialog>
                                                <ThemeProvider theme={theme}>
                                                    <Grid item xs={12}>
                                                        <label className="input-label-common">Dedupe Type</label>
                                                    </Grid>

                                                    {this.renderJsonForm()}
                                                    {this.renderNextProcess()}
                                                    <Grid item xs={12}>
                                                    </Grid>


                                                </ThemeProvider>
                                            </Grid>
                                        </Grid>

                                    </ThemeProvider>
                                </Grid>
                            </div>

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }


}

export default WithoutDedupeLinkCBTypeComponent;