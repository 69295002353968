import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";
import {Icon, Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Badge, Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    buttonStyle, commonInboxButton,
    commonInboxTabActive,
    commonInboxTabInactive,
    localization,
    options,
    tabActive,
    tabInactive,
    tableIcons
} from "../DeliverableManagement/configuration";
import Notification from "../NotificationMessage/Notification";
import Forward from "../DeliverableManagement/Forward";
import EditRequestInitialization from "../Greendelta/EditRequestInitialization";
import SendToCourierPion1 from "../Greendelta/SendToCourierPion1";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import RemarksModal from "../Greendelta/RemarksModal";
import IncomingDispatchIntiator from "./IncomingDispatchIntiator";
import FlowEFTNModal from "./FlowEFTNModal.jsx";
import ConfirmationModal from "../workflow/FundTransfer/ConfirmationModal.jsx";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5',
            }
        },

    }
});

let Form = [
    {
        varName: "refNum",
        type: "text",
        label: "Reference Number",
        required: true,
        grid: 3,
    },
];

class IncomingTrackerManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            remarksModalStatus: "",
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            searchItem: "",
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            loader: true,
            summary: false,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            forwardModal: false,
            forwardArray: [],
            loading: false,
            showModal: false,
            updateId: 0,
            update: false,
            failedList: [],
            failedModal: false,
            allData: [],
            confirmationAlert: false,
            btnName:"",
            confirmTitle: "",
            columnsAll: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Incoming Types", field: "incomingTypes"},
                {title: "Sender Name", field: "senderName"},
                {title: "Document Type", field: "documentType"},
                {title: "Official Concern", field: "officialConcern"},
                {title: "Department", field: "department"},
                {title: "Receiver Name", field: "receiverName"},
                {title: "Contact Number", field: "contactNumber"},
                {title: "In Date & Time", field: "date"},
                {title: "Document Details", field: "documentDetails"},
                {title: "Status", field: "status"},
                { title: 'History', field: 'flow', type: 'Button' },

            ],
            columns: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Incoming Types", field: "incomingTypes"},
                {title: "Sender Name", field: "senderName"},
                {title: "Document Type", field: "documentType"},
                {title: "Official Concern", field: "officialConcern"},
                {title: "Department", field: "department"},
                {title: "Receiver Name", field: "receiverName"},
                {title: "Contact Number", field: "contactNumber"},
                {title: "In Date & Time", field: "date"},
                {title: "Document Details", field: "documentDetails"}],
            columnsForReturn: [
                {title: "Reference Number", field: "referenceNumber"},
                {title: "Incoming Types", field: "incomingTypes"},
                {title: "Sender Name", field: "senderName"},
                {title: "Document Type", field: "documentType"},
                {title: "Official Concern", field: "officialConcern"},
                {title: "Department", field: "department"},
                {title: "Receiver Name", field: "receiverName"},
                {title: "Contact Number", field: "contactNumber"},
                {title: "In Date & Time", field: "date"},
                {title: "Document Details", field: "documentDetails"},
                {field: 'update', disableClick: true, title: 'Re-Assign'}],
                columnsOfHistory: [
                    { title: "Created By", field: "createdBy" },
                    { title: "Ref Number", field: "refNum" },
                    { title: "Date", field: "createdDate" },
                    { title: "Type", field: "type" },
                    { title: "Status", field: "status" }, 
                ],
                allExcelData: [],

                previewData: false,
        };
    }

    handleFlowSummery=(refNum)=>{
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/green-delta/history/" + refNum;
       axios
           .get(url, { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } })
           .then(response => {
               console.log(response);
               let array = [];
               let rowData;
               console.log();
               response.data.length>0 &&
               response.data.map((date,idx)=>{
                rowData={
                    createdBy: date.createdBy,
                    refNum: date.referenceNumber,
                    createdDate: date.createdDate,
                    type: date.type,
                    status: date.status,
                }
                
                 array.push(rowData);
               })
           
               this.setState({
                   allExcelData: array,
                   loading: false,
                   previewData: true,
               });
               console.log(this.state.allExcelData);
           })
           .catch(error => {
               this.setState({
                   allExcelData: [],
                   loading: false,
                   previewData: true,
               });
           });
    }

    renderPreviewButton = (refNum,data) => {
        return (
            <>
                <Badge className="btn btn-sm btn-info p-1 px-2" style={{ "backgroundColor":"#81c784"  , "fontSize":"11px", "color": "#ffffff", "marginBottom": "2px" }}
                    onClick={() => this.handleFlowSummery(refNum)}> History </Badge>
            </>
        )
       
    };

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0
        });
        if (value === "All") {
            this.fetchDataFromAPI('All', '/get/allIncomingRequisitions/0')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };
    dateConverter = (value) => {

        let dateString = value.substr(0, 10);
        return dateString
    };
    fetchDataFromAPI = (status, url) => {
        this.setState({
            activeItem: status,
            loader: true,
        });
        let urlSegment;
        let inbox_url;
        urlSegment = url;

        if (status === 'All') {
            inbox_url = backEndServerURL + "/get/allIncomingRequisitions/0";
        } else if (status === "Return") {
            inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/RETURN/0"
        } else if (status === "My Request") {
            let role = LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
            inbox_url = backEndServerURL + "/get/userWiseRequests/NEW/" + role + "/0"
        } else if (status === "Acknowledged") {
            inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/ACKNOWLEDGED/0"
        } else {
            inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
        }
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    rowData = {
                        id: response.data.content[i].id,
                        incomingTypes: response.data.content[i].incomingType,
                        senderName: response.data.content[i].senderName,
                        documentType: response.data.content[i].receiverDocumentType,
                        officialConcern: response.data.content[i].officialConcern,
                        department: response.data.content[i].department,
                        receiverName: response.data.content[i].receiver,
                        contactNumber: response.data.content[i].contactNumber,
                        date: response.data.content[i].date,
                        referenceNumber: response.data.content[i].referenceNumber,
                        documentDetails: response.data.content[i].documentDetails,
                        status: response.data.content[i].status,
                        update: this.handleUpdate(response.data.content[i]),
                        
                        flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),

                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        let inbox_url;
        if (this.state.activeItem === status) {
            this.setState({
                loader: true
            });
            if (status === 'All') {
                inbox_url = backEndServerURL + "/get/allIncomingRequisitions/"+page;
            } else if (status === "Return") {
                inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/RETURN/"+page;
            } else if (status === "My Request") {
                let role = LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
                inbox_url = backEndServerURL + "/get/userWiseRequests/NEW/" + role + "/"+page;
            } else if (status === "Acknowledged") {
                inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/ACKNOWLEDGED/"+page
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            incomingTypes: response.data.content[i].incomingType,
                            senderName: response.data.content[i].senderName,
                            documentType: response.data.content[i].receiverDocumentType,
                            officialConcern: response.data.content[i].officialConcern,
                            department: response.data.content[i].department,
                            receiverName: response.data.content[i].receiver,
                            contactNumber: response.data.content[i].contactNumber,
                            date: response.data.content[i].date,
                            referenceNumber: response.data.content[i].referenceNumber,
                            documentDetails: response.data.content[i].documentDetails,
                            update: this.handleUpdate(response.data.content[i]),
                            flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),

                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }
                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        activeItem: 'All',
                        pageNumber: page,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false
                    })
                });
        }
    };

    firstFetchTabandUrl = () => {
        let totalMenu = ["All", "My Request", "Return", "Acknowledged"]
        let tabUrl = [];
        let checking = 0;
        totalMenu.map((status, idx) => {

            if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(status) !== -1) {

                let inbox_url;

                if (status === 'All') {
                    inbox_url = backEndServerURL + "/get/allIncomingRequisitions/0";
                } else if (status === "Return") {
                    inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/RETURN/0"
                } else if (status === "My Request") {
                    let role = LocalstorageEncrypt.encryptStorageFunction.getItem("roles")[0];
                    inbox_url = backEndServerURL + "/get/userWiseRequests/NEW/" + role + "/0"
                } else if (status === "Acknowledged") {
                    inbox_url = backEndServerURL + "/get/incomingRequisitionStatusWiseList/ACKNOWLEDGED/0"
                }
                let obk = {}

                obk.tab = status;
                obk.url = inbox_url;
                tabUrl.push(obk)

            }

        })
        return tabUrl;
    }
    componentDidMount() {
        let tabUrl = this.firstFetchTabandUrl();
        if (tabUrl.length>0) {
            let inbox_url = tabUrl[0].url;

            this.setState({
                activeItem: tabUrl[0].tab,
            });

        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        incomingTypes: response.data.content[i].incomingType,
                        senderName: response.data.content[i].senderName,
                        documentType: response.data.content[i].receiverDocumentType,
                        officialConcern: response.data.content[i].officialConcern,
                        department: response.data.content[i].department,
                        receiverName: response.data.content[i].receiver,
                        contactNumber: response.data.content[i].contactNumber,
                        date: response.data.content[i].date,
                        referenceNumber: response.data.content[i].referenceNumber,
                        documentDetails: response.data.content[i].documentDetails,
                        status: response.data.content[i].status,
                        flow: this.renderPreviewButton(response.data.content[i].referenceNumber,response.data.content[i]),

                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
      }else{
        this.setState({
            loader: false,
        });
      }
     
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    resetHandler = () => {
        window.location.reload();
    };

    closeModal = () => {
        this.setState({
            editMode: false
        })
        this.fetchDataFromAPI(this.state.activeItem)
    }

    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu  style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("All", "All", this.state.getCountGroupInbox, "All Request","th list")}
                    {this.renderMenuItem("My Request", "My Request", this.state.getCountGroupInbox, "My Request","bell")}
                    {this.renderMenuItem("Return", "Return", this.state.getCountGroupInbox, "Return","undo")}
                    {this.renderMenuItem("Acknowledged", "Acknowledged", this.state.getCountGroupInbox, "Delivered","inbox")}

                </Menu>
            </div>
        );
    };
    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };
    renderMenuItem = (permission, activeItem, badge, name, icon) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
               
                    name={name}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                     <Icon name={icon} style={{
                         marginTop: "5px"
                      }} />
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };

    closePreviewData = () => {
        this.setState({
            previewData: false,
        });
    };

    onKeyDownChange = (data) => {
    }
    updateComponent = () => {
        this.forceUpdate();
    };
    renderJsonForm = () => {
     
            return CommonJsonFormComponent.renderJsonForm(
                this.state,
                Form,
                this.updateComponent, undefined, this.onKeyDownChange
            );
        
    };
    handleSearch= () =>{
        this.setState({
            loader: true,
        });
        

        let tabName = this.state.activeItem;

        if(this.state.activeItem ==="Cancel"){
            tabName= "Cancelled"
        }else if(this.state.activeItem==="Acknowledged"){
            tabName="ACKNOWLEDGED"
        }else if(this.state.activeItem==="Return"){
            tabName="RETURN"
        }else if(this.state.activeItem==="My Request"){
            tabName="NEW"
        }

        let single_url =
        backEndServerURL + "/filter/incoming/"+tabName+"/" + this.state.inputData.refNum;
    axios
        .get(single_url, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
        })
        .then((response) => {
            let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.length; i++) {
                    // responseRowData = response.data[i].appId;
                    rowData = {
                        id: response.data[i].id,
                        incomingTypes: response.data[i].incomingType,
                        senderName: response.data[i].senderName,
                        documentType: response.data[i].receiverDocumentType,
                        officialConcern: response.data[i].officialConcern,
                        department: response.data[i].department,
                        receiverName: response.data[i].receiver,
                        contactNumber: response.data[i].contactNumber,
                        date: response.data[i].date,
                        referenceNumber: response.data[i].referenceNumber,
                        documentDetails: response.data[i].documentDetails,
                        status: response.data[i].status,
                        flow: this.renderPreviewButton(response.data[i].referenceNumber,response.data[i]),

                    };
                    array.push(rowData);
                    // responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    // responseData: responseArray,
                    // totalRow: response.data.totalElements,
                    loader: false,
                });
            
           
        })
        .catch((err)=>{
            this.functionForNotificationMessage(
                "error",
                "Not Found!",
                ""
            );
            this.setState({
                activeItem: this.state.activeItem,
                loader: false,
            });
        })
    }
    renderButton = () => {
        return (
            <button
                className="btn btn-outline-primary  btn-sm"
                style={{
                    marginTop: "18px",
                    marginBottom: "20px",
                }}
                onClick={this.handleSearch}
            >
                Search
            </button>
        );
    };

    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        console.log(this.state.inputData.callStatus);
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor: '#142398',
                        }}>
                            <h4 style={{

                                color: "white",
                            }}>Dispatch Tracker Management (Incoming)</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="deliverable">
                                <React.Fragment>
                                    <div>
                                    <ThemeProvider theme={theme}>
                                            <Grid container>
                                                {this.renderJsonForm()}
                                                {this.renderButton()}
                                                <br />
                                                <Grid item xs="12"></Grid>
                                               
                                            </Grid>
                                        </ThemeProvider>
                                        <Grid item xs="12"></Grid>
                                        <Grid item xs="12"></Grid>
                                        {this.renderMenu()}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.summary}
                                        >
                                            <DialogContent className={classes.dialogPaper}>
                                                <SendToCourierPion1 rowIdArray={this.state.rowIdArray}
                                                                    closeModal={this.handleModalClose}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                        {this.tableChoice()}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.forwardModal}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <Forward closeModal={this.closeForwardModal}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{ paper: classes.dialogPaper }}
                                            open={this.state.confirmationAlert}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <ConfirmationModal
                                                    closeModal={this.closeConfirmation}
                                                    title={this.state.confirmTitle}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            open={this.state.editMode}>
                                            <DialogContent>
                                                <EditRequestInitialization close={this.closeModal}
                                                                           getId={this.state.getId}
                                                                           closeModal={this.closeModal}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.remarksModal}
                                        >
                                            <DialogContent className={classes.dialogPaper}>
                                                <RemarksModal rowIdArray={this.state.rowIdArray}
                                                              activeItem={"RETURN"}
                                                              type={"INCOMING"}
                                                              status={this.state.remarksModalStatus}
                                                              closeModal={this.handleRemarksModalClose}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.showModal}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <IncomingDispatchIntiator closeModal={this.closeReAssignModal}
                                                                          update={this.state.update}
                                                                          rowId={this.state.updateId}
                                                                          rowData={this.state.rowInput}
                                                                          cardTypeResponseData={this.state.cardTypeResponseData}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                        fullWidth="true"
                                        maxWidth="lg"
                                        className={classes.modal}
                                        classes={{ paper: classes.dialogPaper }}
                                        open={this.state.previewData}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <FlowEFTNModal
                                                closeModalFlow={this.closePreviewData}
                                                subServiceType=""
                                                columns={this.state.columnsOfHistory}
                                                allData={this.state.allExcelData}
                                                title={"Flow History"}
                                                rowLength={this.state.allExcelData.length}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    </div>
                                </React.Fragment>
                                {
                                    this.renderNotification()
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    };

    closeForwardModal = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                forwardModal: false,
                loader: true
            });
            let single_url = backEndServerURL + "/forwardChequeBookToAnotherBranch";
            let postData = {
                forwardTo: value.branchName.key,
                idList: this.state.forwardArray
            };

            axios.post(single_url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetchDataFromAPI('My Request')
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.setState({
                forwardModal: false,
            });
        }
    };
    handleUpdate = (msg) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.renderReAssignModal(msg)}
                >
                    Re-Assign
                </Button>
            </>
        )
    };
    closeReAssignModal = () => {
        this.setState({
            getData: true,
            showTable: true,
            loading: false,
            showModal: false,
            update: false,
        })
    };
    renderReAssignModal = (msg) => {
        this.setState({
            showModal: true,
            updateId: msg.id,
            update: true,
            rowInput: msg,
        })
    }
    changeStatusModal=(event, rowData, props, toStatus)=>{
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
        this.setState({
            confirmTitle: "Do you want to confirm?",
            btnName: toStatus,
            confirmationAlert: true,
            rowIdArray: rowIdArray,
            
        })
    }
    }

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                loading: true,
                confirmationAlert: false
            });
            this.changeStatus()
        } else {
            this.setState({
                loading: false,
                confirmationAlert: false
            })
        }
    }

    changeStatus = () => {
            let toStatus = this.state.btnName;
            let currentStatus = this.state.activeItem;
            let rowIdArray = this.state.rowIdArray;
            if (this.state.activeItem === "My Request") {
                currentStatus = 'NEW'
            }
            let single_url = backEndServerURL + "/updateIncomingRequisitions/" + currentStatus + "/" + toStatus;
            axios
                .post(single_url, rowIdArray, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    if (response.status === 200) {
                        this.functionForNotificationMessage("success","Successful!", toStatus);
                        this.fetchDataFromAPI(this.state.activeItem)
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        
    };

    handleModalClose = () => {
        this.setState({
            summary: false,

        })
        this.fetchDataFromAPI(this.state.activeItem);
    }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForNotificationMessage = (type,title, message) => {
        this.setState({
            type: type,
            title: title,
            notificationMessage: message,
            alert: true,
            loading: false
        })

    };
    handleRemarksModalOpen = (event, rowData, props, activeItem, status) => {
        let allSelectValue = (rowData, props.data);
        if (allSelectValue === undefined) return 0;
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].id);
            }
        }
        this.setState({
            remarksModalStatus: status,
            remarksModal: true,
            rowIdArray: rowIdArray
        })
    };
    handleRemarksModalClose = () => {
        this.setState({
            remarksModal: false,
        })
        this.fetchDataFromAPI(this.state.activeItem);
    }

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsAll}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "My Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.changeStatusModal(event, rowData, props, "ACKNOWLEDGED")}
                                            >
                                                Acknowledged
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.changeStatusModal(event, rowData, props, "RETURN")}
                                            >
                                                Return
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'My Request')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Return" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columnsForReturn}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.handleRemarksModalOpen(event, rowData, props, "RETURN", "ABANDONED")}
                                            >
                                                Abandoned
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Return')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Acknowledged" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Acknowledged')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(IncomingTrackerManage);
