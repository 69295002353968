import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import {DescendMarkReadOnlyForm} from "./JsonFormDeceasedMark";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import CommonApi from "../../Maintenance/common/CommonApi";
import CBNotFound from "../../CASA/CBNotFound";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};



let closingMakerRemarks = [
    {
        "varName": "closingMakerRemarks",
        "type": "textArea",
        "label": "Closing Maker Remarks",
        "grid": 12
    }]
;


class ClosingMakerDeceasedMark extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                closing_maker_approved: '',
            },

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            fileUploadData: {},
            confirmAlert: false,
            actionType: "",


        }

    }

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.closingMakerRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderForm = () => {
        if(this.state.getData){
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, DescendMarkReadOnlyForm, this.updateComponent)
            )
        }
    };

    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {
        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {
            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })
            )
        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification}
                              title={this.state.title} message={this.state.notificationMessage}/>
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });
        if (data === "NO") {
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "handleReturn") {
            this.handleSubmit("NO")
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;

    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(closingMakerRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "closingMakerRemarks",
                "type": "textArea",
                "label": "Remarks",
                "grid": 12,
                "required":true,
            });
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        }


    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {this.validationForHandleConfirm(type)}


    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                       // onClick={(event) => this.handleSubmit(event, "YES")}
                        onClick={(event) => this.handleCommon(event, "handleSubmit")}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                       // onClick={(event) => this.handleSubmit(event, "NO")}
                        onClick={(event) => this.handleCommon(event, "handleReturn")}

                    >Return
                    </button>
                </div>


            )
        }
    };


    renderFormTable = () => {
        return (

            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                     <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Loan Type</th>
                            <td>{this.state.inputData.loanType}</td>

                        </tr>
                        <tr>
                            <th scope="row">Product Code</th>
                            <td>CSLAB</td>
                        </tr>

                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.state.inputData.loanAccountExpiryDate}</td>
                        </tr>

                        <tr>
                            <th scope="row">Maturity</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">LOAN</th>
                             <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>

                        <tr>
                            <th scope="row">AMOUNT</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                         </tr>

                        <tr>
                            <th scope="row">FEE</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                         </tr>

                        <tr>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                         </tr>

                        <tr>
                            <th scope="row">Total</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                         </tr>


                    </table>


                                 </div>
            </div>
        )
    };

    handleSubmit = ( data) => {
         let dependencyField = [];

        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        if(this.state.inputData.closingMakerRemarks!==undefined && this.state.inputData.closingMakerRemarks!==null){
            saveDataDeferralRemarksAndRoute.remark=this.state.inputData.closingMakerRemarks;
        }

        var appId = this.props.appId;
        var approval = data;
        this.state.inputData.closing_maker_approved = data;
        console.log(this.state.inputData);
        saveDataDeferralRemarksAndRoute.map=this.emptyValueRemove(this.state.inputData);
        var url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url,saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: response.data,
                            alert: true,
                            loading: false
                        });

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })

                    });
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    renderRemarks = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, closingMakerRemarks, this.updateComponent)
        )
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: '100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>{this.state.inputData.serviceType}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== undefined ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""}  <b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId  : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Closing Maker Deceased Mark
                </h4>
            )
        }
    };

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }

            return (
                <Card>

                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loader}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                          closeModal={this.closeConfirmAlert}
                            />

                        </DialogContent>
                    </Dialog>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.renderForm()}
                                {this.mappingPhoto()}
                                {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            </Grid>
                            {this.renderRemarksData()}
                            <Grid item xs={12}><br/></Grid>
                            {this.renderFlowSUmmeryButton()}
                            {this.renderRemarks()}
                            <Grid item xs={12}><br/></Grid>
                            {this.renderSubmitButton()}
                        </ThemeProvider>

                    </CardBody>

                </Card>
            );
        }


}

export default withStyles(styles)(ClosingMakerDeceasedMark);
