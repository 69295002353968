import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import Button from "@material/react-button";
import EmployeeDeatils from "../CommonComponent/EmployeeDeatils";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class RemarksDataList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            loading: true,
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            tableData: [],
            file: {},
            defferalId: "",
            uploadModal: false,
            getMappingAllImage: false,
            notificationMessage: "",
            getDownloadListFlag: false,
            SdnAndDedupeResultShowing: false,
            fileNotFoundTittle: "",
            downLoadFileList: [],
            getUserStaticData: false,
            userIdForDetails: "",
            userGroupNameForDetails: "",
            getRemarks: [],

        }
    }

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };
    updateComponent = () => {
        this.forceUpdate();
    };
    closeModalForCustomerDetails = () => {
        this.setState({
            loading: false,
            userDeatils: false,
            userIdForDetails: "",
            userGroupNameForDetails: "",
        })
    }
    handleChangeCustomerDetails = (currentUserName, currentUserRole) => {
        this.setState({
            userDeatils: true,
            userIdForDetails: currentUserName,
            userGroupNameForDetails: currentUserRole,
        })

    }
    createTableData = (userName, remarks) => {

        return ([userName, remarks])
        /* return ([(currentUserRole.includes(" Group") === false) && [

             <span>
               <a href="javascript: void(0)"
                  onClick={() => this.handleChangeCustomerDetails(currentUserName, currentUserRole)}>{currentUserName}</a>
              </span>
         ], userName, remarks, category, subCategory, delInitTime, delFinishTime, activity, remarks
         ])*/

    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a]
        )
    };

    componentDidMount() {
        let tableArray = [];
        let username = undefined;
        let remarks = undefined;
        var remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                response.data.map((data) => {

                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error)
            })


    }

    renderSubmittedHistory = () => {
        if (this.state.getData) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "2px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"

                            tableHead={["User Name", "Remarks"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.props.globalSearch && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    renderMultiTaskLink = () => {
        if (this.props.multiTaskLink === true || (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening")) {
            return (
                <div>
                    <h5><a
                        style={{
                            verticalAlign: 'left',
                            position: "absolute",
                            right: 10,
                            marginTop: "5px",
                            marginBottom: "5px",
                        }}
                        target="_blank"
                        href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                        Multitask Link</a></h5>


                </div>)

        } else {

        }
    };
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnAndDedupeResultShowing: true
        })

    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnAndDedupeResultShowing: false
        })
    }

    renderSDNDedupeResult = () => {
        return (
            <Button
                style={{
                    backgroundColor: "red",
                    color: "white",
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 3,
                    paddingBottom: 3,
                    borderRadius: 3,
                    border: 1,
                    verticalAlign: 'left',
                    position: "absolute",
                    marginTop: "5px",
                    right: 50,
                }}
                onClick={(event) => this.handleChangeSdnResult(event)}
            >
                Full Dedupe and SDN result
            </Button>
        )


    };


    close = () => {
        this.props.closeModal()
    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })
    };


    closeIcon = () => {
        this.props.closeIcon()
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };

    render() {

        const {classes} = this.props;
        if (this.props.closeModal !== undefined) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Remarks Data <a><CloseIcon
                                    onClick={this.closeIcon} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={3}>
                                        <ThemeProvider theme={theme}>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.loading}>
                                                <DialogContent className={classes.dialogPaper}>

                                                    <center>
                                                        <img src={loader} alt=""/>
                                                    </center>
                                                </DialogContent>
                                            </Dialog>


                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="md"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.userDeatils}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <EmployeeDeatils closeModal={this.closeModalForCustomerDetails}
                                                                     branchName={this.props.branchName}
                                                                     userId={this.state.userIdForDetails}
                                                                     groupName={this.state.userGroupNameForDetails}
                                                                     subServiceType={this.props.subServiceType}
                                                                     category={this.props.category}
                                                                     serviceType={this.props.serviceType}
                                                                     subCategory={this.props.subCategory}
                                                                     freeFlag2={this.props.freeFlag2 === "externalUserClosedRequest" ? true : false}
                                                                     serviceType={this.props.serviceType}
                                                                     customerName={this.props.customerName}
                                                                     accountNumber={this.props.accountNumber}
                                                                     cbNumber={this.props.cbNumber}
                                                                     solId={this.props.solId} globalSearch={true}
                                                                     closeIcon={this.closeModalForCustomerDetails}
                                                                     appId={this.props.appId}/>
                                                </DialogContent>
                                            </Dialog>


                                            <Grid item xs={12}>
                                                {this.renderRemarksData()}
                                            </Grid>
                                            {/*  <Grid item xs={6}>
                                            {this.renderDownLoadFileLink()}

                                        </Grid>
                                        <Grid item xs={6}>
                                            {this.renderMultiTaskLink()}

                                        </Grid>
*/}
                                        </ThemeProvider>


                                    </Grid>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        }
        else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {this.renderRemarksData()}
                    </GridItem>
                </GridContainer>
            )
        }


    }


}

export default withStyles(styles)(RemarksDataList);
