import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Components/Grid/GridItem.jsx";
import GridContainer from "../../Components/Grid/GridContainer.jsx";
import Table from "../../Components/Table/Table";
import Card from "../../Components/Card/Card.jsx";
import Button from '@material-ui/core/Button';
import CardHeader from "../../Components/Card/CardHeader.jsx";
import CardBody from "../../Components/Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Dialog, Grow} from "@material-ui/core";
import AddMenu from './AddMenu';
import MenuView from "./AssignMenuToRole";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    fab: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    }
};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class MenuList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addMenu: false,
            menuView: false,
            tableData: [[" ", " "]],
        }
    }

    addMenu = () => {
        this.setState({
            addMenu: true,
        })
    };

    menuView = () => {
        this.setState({
            menuView: true
        })
    };

    closeModal = () => {
        this.setState({
            addMenu: false,
            menuView: false,
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    open={this.state.addMenu}
                    fullWidth="true"
                    maxWidth="sm"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflowy': 'auto'}}>
                        <center>
                            <AddMenu/>
                        </center>

                    </div>
                </Dialog>
                <Dialog
                    open={this.state.menuView}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>
                            <MenuView/>
                        </center>
                    </div>
                </Dialog>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Menu List </h4>

                            </CardHeader>

                            <CardBody>
                                <center>
                                    <Button onClick={() => this.addMenu()} color="primary">
                                        Add Menu
                                    </Button>
                                </center>
                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Menu Name", "Action"]}

                                    tableData={[
                                        ["",
                                            // "Admin",
                                            <Button onClick={() => this.menuView()} color="primary">
                                                View
                                            </Button>],

                                    ]}
                                    tableAllign={['left', 'left']}
                                /><br/><br/><br/>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </>
        );
    }
}

export default withStyles(styles)(MenuList);
