import TextField from "@material-ui/core/TextField";
import React from "react";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

class TextFieldForDedup {
    static onKeyDownChange(event, onKeyDownForDedup) {
        onKeyDownForDedup(event);
    }

    static handleChange(event, field, state, update, onKeyDownForDedup) {

        // let inputData = state.inputData;
        // inputData[event.target.name] = event.target.value;
        let string = event.target.value;
        if (field.phonePrefix === true) {
            //state.inputData[event.target.name] = "88" + string;
            state.inputData[event.target.name] = string;
        } else {
            if (field.validation === "email") {
                state.inputData[event.target.name] = string;
            } else {
                state.inputData[event.target.name] = string.toUpperCase();

            }
        }
        //update(inputData);
    };


    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";
            return state.varValue[field.varName];
        }
    }

    static returnPrefixString(field) {
        if (field.phonePrefix === true) {
            return (
                <InputAdornment position="start">88</InputAdornment>

            )
        }

    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label} *</label>
                </Grid>
            )
        } else {
            return (
                <Grid item>
                    <label className="input-label-common">{field.label}</label>
                </Grid>
            )
        }
    };

    static text(state, update, field, onKeyDownForDedup) {

        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }
                <Grid item>
                    <TextField
                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        variant="outlined"
                        defaultValue={this.returnDefaultValue(field, state)} required={field.required}
                        key={field.varName}
                        name={field.varName}

                        //label={field.label}
                        multiline={field.multiline === true ? true : false}
                        onKeyDown={(event) => {
                            this.onKeyDownChange(event, onKeyDownForDedup)
                        }}
                        onChange={(event) => this.handleChange(event, field, state, update, onKeyDownForDedup)}
                        InputProps={{
                            startAdornment: this.returnPrefixString(field),
                            readOnly: field.readOnly,
                            /* style: {
                                 color: "red"
                             }*/
                        }}

                        //InputLabelProps={{shrink:true}}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>

        )


    }

}

export default TextFieldForDedup;
