function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let retailLoanJsonForm = [
    {
        "label": "CB Number",
        "varName": "cbNumber",
        "required": true,
        "type": "text",
        "grid": 2,
    },
    {
        "label": "File Reference No",
        "varName": "fileReferenceNo",
        "type": "text",
        "grid": 2,
    },
    {
        "label": "A/c Number",
        "varName": "accountNumber",
        "type": "text",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "label": "Name",
        "varName": "name",
        "type": "text",
        "validation": "string",
        "grid": 2,
    },
    {
        "label": "SourceName",
        "varName": "sourceName",
        "type": "text",
        "validation": "string",
        "grid": 2,
    },
    {
        "label": "Amount",
        "varName": "amount",
        "type": "text",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "label": "Interest Rate",
        "varName": "interestRate",
        "type": "text",
        "validation": "numeric",
        "grid": 2,
    },
    {
        "label": "Tenor",
        "varName": "tenor",
        "type": "text",
        "grid": 2,
    },
    {
        "label": "Loan Name",
        "varName": "loanName",
        "type": "select",
        "enum": [
            "Auto Loan", "Bullet Payment", "City Double Loan", "City Gems Express", "City Scholar Secure", "Dealer Finance", "Home Loan", " Manarah Auto", "Manarah Home Finance", "Manarah Personal Finance", "Personal Loan", "Secured Loan", "Secured OD,Semi Pucca", "SME-S OD, SME-S Remittance Loan", "SME-S STL", "SME-S Micro Finance", "SME-S Term Loan"
        ],
        "grid": 2,
    },
    {
        "label": "Loan Category",
        "varName": "loanCategory",
        "type": "select",
        "enum": [
            "Retail",
            "SME",
            "Home Loan",
        ],
        "grid": 2,
    },
    {
        "label": "Loan Type",
        "varName": "loanType",
        "type": "text",
        "grid": 2,
    },
    {
        "label": "Sanction Type",
        "varName": "sanctionType",
        "type": "select",
        "enum": ["New", "Renewal", "Limit enhancement", "Limit decrease", "Limit enhancement & Renewal", "Limit decrease & Renewal"],
        "grid": 2,
    },
    {
        "label": "RM ID",
        "varName": "rmId",
        "type": "text",
        "grid": 2,
    },
    {
        "label": "RM Name/SME Name",
        "varName": "rmOrSmeName",
        "type": "text",
        "grid": 2,
    },
    {
        "label": "Contact No",
        "varName": "contactNo",
        "type": "text",
        "validation": "phone",
        "grid": 2,
    },
    {
        "label": "File Receiving Date & Time",
        "varName": "fileReceivingDataTime",
        "type": "date",
        "grid": 2,
    },
    {
        "label": "File Location",
        "varName": "fileLocation",
        "type": "select",
        "enum": ["Assign for Checking",
            "Send for Disbursement",
            "Send Back to Credit"],
        "grid": 2,
    },
    {
        "label": "Asset Ops No",
        "varName": "assetOpsNo",
        "type": "text",
        "readOnly": "true",
        "grid": 2,
    },
    {
        "label": "Send for disbursement date",
        "varName": "disbursementDate",
        "type": "date",
        "grid": 2,
    }
];

let retailLoanJsonFormForDocumentation = {};
retailLoanJsonFormForDocumentation = makeReadOnlyObject(JSON.parse(JSON.stringify(retailLoanJsonForm)));

let documentationMakerJsonFormPartOne = [
    {
        "label": "File Status",
        "varName": "fileStatus",
        "type": "select",
        "enum": [
            "Found Discrepancies", "Under Checking", "Disbursement with Deferral", "Forwarded for 2nd checking", "Sent for disbursement end"
        ],
        "grid": 2
    }
];
let documentationMakerJsonFormPartTwo = [
    {
        "label": "Purchase Order serial Number",
        "varName": "purchaseOrderSerialNumber",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Purchase Order Issue date",
        "varName": "purchaseOrderIssueDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Vendor Information",
        "varName": "vendorInformation",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Customer Address",
        "varName": "customerAddress",
        "multiline": true,
        "type": "text",
        "grid": 2
    },
    {
        "label": "Car Details",
        "varName": "carDetails",
        "type": "text",
        "grid": 2
    },
    {
        "label": "carModel",
        "varName": "Car Model",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Engine Number",
        "varName": "engineNumber",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Chassis No",
        "varName": "chassisNumber",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Cubic Centimeters (CC)",
        "varName": "cubicCentimeters",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Color",
        "varName": "color",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Registration From",
        "varName": "registrationForm",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Car Type",
        "varName": "carType",
        "type": "select",
        "enum": [
            "Hybrid",
            "non-Hybrid"
        ],
        "grid": 2
    },
    {
        "label": "Name of the Insurance Company ",
        "varName": "nameOfTheInsuranceCompany",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Received Insurance certificate before disbursement (Issued date)",
        "varName": "receivedInsuranceCertificateBeforeDisbursement",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Full value of Car (As per Quotation)",
        "varName": "fullValueOfCarAsPerQuotation",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Car Registration No",
        "varName": "carRegistrationNo",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Car Registration Date",
        "varName": "carRegistrationDate",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Notarization Status",
        "varName": "notarizationStatus",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Insurance Renewal request-1st letter Sent Date",
        "varName": "insuranceRenewalRequestQstLetterSentDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Insurance Renewal Received Status",
        "varName": "insuranceRenewalReceivedStatus",
        "type": "select",
        "enum": ["Yes", "No"],
        "grid": 2
    },
    {
        "label": "Insurance Renewal Received Date",
        "varName": "insuranceRenewalReceivedDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Remarks of Insurance Renewal received copy",
        "varName": "remarksOfInsuranceRenewalReceivedCopy",
        "type": "select",
        "enum": [
            "Yes",
            "No"
        ],
        "grid": 2
    },
    {
        "label": "Insurance Renewal request 2nd Letter sent date",
        "varName": "insuranceRenewalRequest2ndLetterSentDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Insurance renewal received Status",
        "varName": "insuranceRenewalReceivedStatus",
        "type": "select",
        "enum": ["Yes", "No"],
        "grid": 2
    },
    {
        "label": "Insurance Renewal Received Date",
        "varName": "insuranceRenewalReceivedDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "Remarks of Insurance renewal received copy",
        "varName": "remarksOfInsuranceRenewalReceivedCopy",
        "multiline": "true",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Report Physical checked",
        "varName": "reportPhysicalChecked",
        "type": "text",
        "grid": 2
    }
];

let disbursementMakerForm = [
    {
        "label": "Disbursement information updates",
        "type": "title",
        "grid": 12
    },
    {
        "label": "File Reference No.",
        "varName": "disbursementFileReferenceNo",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Loan Account",
        "varName": "disbursementLoanAccount",
        "type": "text",
        "validation": "numeric",
        "grid": 2
    },
    {
        "label": "Name",
        "varName": "disbursementName",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Amount",
        "varName": "disbursementAmount",
        "type": "text",
        "validation": "numeric",
        "grid": 2
    },
    {
        "label": "Interest Rate",
        "varName": "disbursementInterestRate",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Tenor",
        "varName": "disbursementTenor",
        "type": "text",
        "grid": 2
    },
    {
        "label": "Disbursement Date",
        "varName": "disbursementMakerDate",
        "type": "date",
        "grid": 2
    },
    {
        "label": "User Name",
        "varName": "disbursementUserName",
        "type": "text",
        "grid": 2
    },
];

let disbursementCheckerForm = {};
disbursementCheckerForm = makeReadOnlyObject(JSON.parse(JSON.stringify(disbursementMakerForm)));

module.exports = {
    retailLoanJsonForm,
    retailLoanJsonFormForDocumentation,
    documentationMakerJsonFormPartOne,
    documentationMakerJsonFormPartTwo,
    disbursementMakerForm,
    disbursementCheckerForm
};