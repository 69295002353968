import React from 'react';
import ReactDOM from 'react-dom';
import './Static/css/index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {backEndServerURL} from "./Common/Constant";
import LocalstorageEncrypt from "./Components/JsonForm/LocalstorageEncrypt";


axios.interceptors.response.use(function (response) {

        // console.clear();


    if( localStorage.getItem("accessToken")!==undefined && sessionStorage.getItem("accessToken")!==undefined && localStorage.getItem("accessToken")!==null && sessionStorage.getItem("accessToken")!==null && localStorage.getItem("accessToken")!==sessionStorage.getItem("accessToken")){
         alert("Duplicate login not allow. Re-load your page.");
        sessionStorage.setItem("accessToken",localStorage.getItem("accessToken"))
        window.location = '/#/firstLoginPage';
        window.location.reload();

    }
   else if (252 === response.status) {
        window.location = '/#/login';
    }
     if (response.config !==undefined && response.config!==null && response.config.url !==undefined && response.config.url!==null && (response.config.url).includes(backEndServerURL +"/case/route/") || (response.config.url).includes(backEndServerURL +"/case/saveDataDeferralRemarksAndRoute/")) {
          LocalstorageEncrypt.encryptStorageFunction.setItem("routeMessage", response.data);
         setTimeout(() => {

         },200)
    }
    return response;

}, function (error) {
    return Promise.reject(error);
});

ReactDOM.render(<App/>, document.getElementById('root'));
// Add a 401 response interceptor


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

