import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../../Static/loader.gif";
import Label from "./Label";
import SignatureButton from "../SignatureButton";
import PreviewDocument from "../casa/PreviewDocument";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "required": true,
        "grid": 12
    }]
;

let newNominee = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "readOnly": true,
        "grid": 12
    },
    {
        "varName": "numberOfNominee",
        "type": "text",
        "label": "Number of New Nominee",
        "readOnly": true,
        "grid": 6
    }]
;

class CloserBomApproval extends React.Component {
    state = {
        selectedDate: {},
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: true,
        previewDocument: false
    };
    renderEditForm = (() => {

        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)

            )
        }

    });

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        appData: response.data,
                        // loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    /* if(error.response.status===452){
                         Functions.removeCookie();

                         this.setState({
                             redirectLogin:true
                         })

                     }*/
                });
        }

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    handleSubmit = (event, data) => {
        event.preventDefault();

        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(bomRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }

        this.state.inputData.bom_maintenance_approval = data;

        // return false;
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });
                        this.props.closeModal()
                        //

                    })
                    .catch((error) => {
                        console.log(error);
                        /* if(error.response.status===452){
                             Functions.removeCookie();

                             this.setState({
                                 redirectLogin:true
                             })

                         }*/
                    });
            })
            .catch((error) => {
                console.log(error)
            });


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <center>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}

                            type='button' value='add more'

                            onClick={(event) => this.handleSubmit(event, "APPROVED")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN")}

                        >Return
                        </button>
                    </Grid>
                </center>

            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }

    };
    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };

    closePreviewDocument = () => {
        this.setState({
            previewDocument: false
        })
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    // {
                    //     "varName": "nomineecifId" + sl,
                    //     "type": "text",
                    //     "label": "CIF ID/Individual Id",
                    //     "grid": 3,
                    //     "readOnly": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "dpsCloserRequest",
                        "conditionalVarValue": "DPS Nominee Change"
                    },
                )

            }


            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
                    }
                </>
            )

        }
    };


    renderNewNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            console.log("i am in condition");

            var sl;
            let objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "New Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeNameNew" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "readOnly": true,
                        required: true,
                    },

                    {
                        "varName": "dobNew" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "readOnly": true,
                        required: true,
                    },


                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "readOnly": true,
                        required: true,
                    },
                    {
                        "varName": "nomineeMinorNew" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["Yes", "No"],
                        "readOnly": true,
                        "grid": 6,
                        required: true,
                    },
                )


            }


            return (
                <>
                    <br/>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, newNominee, this.updateComponent)
                    }

                    <br/>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)
                    }

                </>
            )

        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };


    render() {

        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <ThemeProvider theme={theme}>
                                <div>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.previewDocument}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <PreviewDocument closeModal={this.closePreviewDocument}
                                                             appId={this.state.appId}/>
                                        </DialogContent>
                                    </Dialog>
                                    <Grid container spacing={0}>
                                        <Label stopLoading={this.stopLoading}
                                               accountNumber={this.state.inputData.accountNumber}
                                               style={{margin: "0 auto"}} classes={this.props}
                                               cbNumber={this.state.inputData.cbNumber}/>
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        <SignatureButton accountNumber={this.state.inputData.accountNumber}
                                                         classes={this.props}/>&nbsp;
                                        <button
                                            onClick={this.previewDocument}
                                            className="btn btn-outline-primary btn-sm">
                                            Preview All Document
                                        </button>
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        {this.renderEditForm()}
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        {this.renderExistingNomineeForm()}
                                        <Grid item xs={12}>
                                            {this.renderRemarksData()}
                                        </Grid>
                                    </Grid>
                                </div>

                            </ThemeProvider>
                            <br/>
                            {this.viewAttachedImages()}
                            <ThemeProvider theme={theme}>
                                <div>
                                    <Grid container spacing={0}>
                                        <br/>
                                        {this.renderNotification()}
                                        {this.renderRemarks()}
                                        <br/>
                                        {this.renderSubmitButton()}
                                    </Grid>
                                </div>
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(CloserBomApproval);
