import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL,frontEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../../Static/loader.gif";
import Label from "../static/Label";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import SecondaryCbFormDetails from "../../CASA/SecondaryCbFormDetails";
import {CBDataJsonForm} from "../../WorkflowJsonForm4";
import CBNotFound from "../../CASA/CBNotFound";
import ExistingMandateDetails from "../static/ExistingMandateDetails";
import CardHeader from "../../../Card/CardHeader";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureCardDelete from "../static/SignatureCardDelete";

let jsonArrayForMod = [
    {
        "varName": "mandateOption",
        "type": "text",
        "label": "Mandate/Related Party Add or Remove",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "numberOfNewMandate",
        "type": "text",
        "label": " Number of New Mandate/Beneficial Owner/Signatory",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mandateOption",
        "conditionalVarValue": "Add Mandate/Related Party",
    },
    {
        "varName": "schemeCodeChangemodeOfOperation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "readOnly": true,
        "label": "Mode of Operation",
        "grid": 6,
    }
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "required": true,
        "label": "Checker Remarks",
        "grid": 12
    }];


class VerifyDocumentBOM2 extends React.Component {
    state = {
        disabled: false,
        message: "",
        appData: {},
        getData: false,
        varValue: {},
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        loading: true,
        getMappingCropImage: false,
        selectedDate: {},
        getTagList: [],
        needCheckForTag: [],
        tagCbDetailsModal: false,
        tagCb: "",
        cbNotFound: false,
        selectedImageArrayList: [],
        mandateTableArray: [],
        mandateModal: false,
        existingMandateCb: 0,
        rowId: 0,
        submittedTagCbList: [],
        requestType: "",
        tagCbNumber: 0,
        getMandateList: [],
        needCheckForExistingMandate: [],
        makerEditedExistingMandate: [],
        pendingExistingUpdate: [],
        checkerApproval: "",
        linkrelationCode: 0,
        confirmationAlert: false,
        submitButtonData: "",
        mandateUpdate: false,
        mandateDelete: false,
        signatureUpdate: false,
        finalApprove: false,
        actionButtonType: "",
        signatureDeleteButton: false,
        deleteSignature: false,
        showDeleteSignature: false,
        signatureButtonShowInImageCropedPage: false
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    getMandateFromAccount = (accountNumber) => {
        let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + accountNumber;
        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let mandateTableArray = [];
                    if (response.data.relPartyList) {
                        response.data.relPartyList.map((mandate, index) => {
                            if (mandate.relPartyType !== "M") {
                                let exFlag;
                                if (mandate.recDelFlg === "Y") {
                                    exFlag = "CB Deleted"
                                } else if (mandate.recDelFlg === "N") {
                                    exFlag = "CB Existed"
                                } else {
                                    exFlag = mandate.recDelFlg;
                                }
                                mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, mandate.relPartyTypeDesc, this.triggerExistingMandate(mandate.custId), exFlag, this.triggerExistingMandateForDelete(mandate.custId, index)))
                            }
                        });
                    }
                    this.setState({
                        mandateTableArray: mandateTableArray,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: false,
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })

            });
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        mandateUpdate: response.data.mandateUpdate,
                        mandateDelete: response.data.mandateDelete,
                        signatureUpdate: response.data.signatureUpdate,
                        finalApprove: response.data.finalApprove,
                        deleteSignature: response.data.deleteSignature,
                    });
                    this.getMandateFromAccount(response.data.accountNumber);
                    this.getTaggingData();
                    this.getTExistingMandateData();

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let string = "SIGNATURE CARD";
                                    let imageArrayLink = [];
                                    response.data.map((document, index) => {
                                        if (document.indexOf(string) !== -1) {
                                            let imageName = document.split('_')[1].split('.')[0];
                                            // let imageName = name.split("MANDATE ")[1];
                                            if (imageName !== undefined) {
                                                imageArrayLink.push(imageName.split(" ")[1] + imageName.split(" ")[2]);
                                            }
                                        }
                                    });
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true,
                                        selectedImageArrayList: imageArrayLink,
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    response.data.map((data, index) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        getData: true,
                        showValue: true,
                        appData: response.data,
                        // loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }

    };


    functionForAlertMessage = () => {
        if (this.state.needCheckForExistingMandate.length !== this.state.getMandateList.length) {
            let tempMessage = "";
            for (let item of this.state.pendingExistingUpdate) {
                if (item.requestType === "Waiting for Complete") {
                    let temp = tempMessage;
                    tempMessage = tempMessage === "" ? item.customerName : temp + ", " + item.customerName
                }
            }
            return tempMessage;
        } else {
            return "";
        }
    };

    functionForSecondaryAlert = () => {
        if (this.state.needCheckForTag.length !== this.state.getTagList.length) {
            let tempMessage = "";
            for (let item of this.state.getTagList) {
                if (item.requestType !== "Completed") {
                    let temp = tempMessage;
                    tempMessage = tempMessage === "" ? item[1] : temp + ", " + item[1]
                }
            }
            let firstAlert = this.functionForAlertMessage();
            let finalMessage = firstAlert === "" ? "" + tempMessage : firstAlert + ", " + tempMessage;
            return "Please Approve (" + finalMessage + ") info before submitting";
        }
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });

            if (this.state.actionButtonType === "signatureUpdate") {
                this.mappingCropImage()
            } else if (this.state.actionButtonType === "mandateDelete") {
                this.functionForMandateDelete().then((response) => {
                    this.setState({
                        mandateDelete: true,
                        disabled: false,
                        loading: false,
                        title: "Related CB \"Delete/Update\" Successful!",
                        cbNotFound: true
                    })
                }).catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                })
            } else if (this.state.actionButtonType === "mandateUpdate") {
                if ((this.state.needCheckForTag.length === this.state.getTagList.length) && (this.state.needCheckForExistingMandate.length === this.state.getMandateList.length)) {
                    this.functionForNewMandateAdd().then((response) => {
                        this.setState({
                            mandateUpdate: true,
                            disabled: false,
                            loading: false,
                        })
                    }).catch((error) => {
                        this.setState({
                            disabled: false,
                            loading: false,
                            title: error.response.data.message,
                            cbNotFound: true
                        })
                    })
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: this.functionForSecondaryAlert(),
                        disabled: false
                    });
                }
            } else if (this.state.actionButtonType === "Delete Signature" && !this.state.deleteSignature) {
                this.handleDeleteSignature();
            } else if (this.state.actionButtonType === "finalApprove") {
                if ((this.state.needCheckForTag.length === this.state.getTagList.length) && (this.state.needCheckForExistingMandate.length === this.state.getMandateList.length)) {
                    this.functionForSaveRemarks();
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: this.functionForSecondaryAlert(),
                        disabled: false
                    });
                }
            } else if (this.state.actionButtonType === "RETURN") {
                this.functionForSaveRemarks("RETURN")
            }
        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };

    functionForSignatureDelete = () => {
        if (this.state.inputData.signatureDeleteFlag !== undefined && this.state.inputData.signatureDeleteFlag.length > 0) {
            return new Promise((resolve, reject) => {
                this.setState({
                    loading: true
                });
                let url = backEndServerURL + "/intgr/delete/signature";
                axios.post(url, this.state.inputData.signatureDeleteFlag, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.deleteSignature = true;
                        this.functionForVariableSetApi().then((response) => {
                            this.setState({
                                showDeleteSignature: false,
                                signatureDeleteButton: false,
                                deleteSignature: true,
                                loading: false,
                                disabled: false,
                            })
                        }).catch((error) => {
                            this.setState({
                                showDeleteSignature: false,
                                signatureDeleteButton: false,
                                deleteSignature: false,
                                loading: false,
                                disabled: false,
                            })
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            showDeleteSignature: false,
                            signatureDeleteButton: false,
                            deleteSignature: false,
                            loading: false,
                            disabled: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        });
                        console.log(error);
                        reject(error)
                    });
            })
        }
    };

    functionForNewMandateAdd = () => {
        return new Promise((resolve, reject) => {
            if ((this.state.needCheckForTag.length === this.state.getTagList.length) && (this.state.needCheckForExistingMandate.length === this.state.getMandateList.length)) {
                let accountUpdateUrl = backEndServerURL + "/newMandate/add/" + this.props.appId + "/" + this.state.inputData.accountNumber;
                axios.get(accountUpdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.mandateUpdate = true;
                        this.functionForVariableSetApi().then((variableResponse) => {
                            resolve(response)
                        }).catch((error) => {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            }
        })
    };

    functionForMandateDelete = () => {
        if (this.state.inputData.existingMandateFlag !== undefined) {
            return new Promise((resolve, reject) => {
                let postData = this.state.inputData.existingMandateFlag;
                postData["schmType"] = this.state.inputData.schmType;

                let deleteMandate = backEndServerURL + "/mandate/delete/" + this.state.inputData.accountNumber;
                axios.post(deleteMandate, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.getMandateFromAccount(this.state.inputData.accountNumber);
                        this.state.inputData.mandateDelete = true;
                        this.functionForVariableSetApi().then((variableResponse) => {
                            resolve(response)
                        }).catch((error) => {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            })
        }
    };


    functionForSaveRemarks = (value) => {
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.props.appId;
        axios.post(commentsUrl, {remark: this.state.inputData.checkerRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    disabled: false,
                });
            });
        this.state.inputData.checkerRemarks = undefined;
        if (value !== undefined) {
            this.state.inputData.mandateUpdate = false;
            this.state.inputData.mandateDelete = false;
            this.state.inputData.signatureUpdate = false;
            this.state.inputData.finalApprove = false;
            this.state.inputData.deleteSignature = false;
            this.functionForVariableSetApi().then((response) => {
                this.caseRouteApi()
            }).catch((error) => {
                this.setState({
                    disabled: false,
                    loading: false,
                    title: error.response.data.message,
                    cbNotFound: true
                })
            })
        } else {
            this.state.inputData.finalApprove = true;
            this.functionForSMSAPI().then((response) => {
                this.functionForVariableSetApi().then((response) => {
                    this.caseRouteApi()
                }).catch((error) => {
                    this.setState({
                        disabled: false,
                        loading: false,
                        title: error.response.data.message,
                        cbNotFound: true
                    })
                })
            }).catch((error) => {
                this.setState({
                    disabled: false,
                    loading: false,
                    title: error.response.data.message,
                    cbNotFound: true
                })
            })
        }
    };

    functionForVariableSetApi = () => {
        return new Promise((resolve, reject) => {
            let data = this.state.submitButtonData;
            let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            let postData = this.state.inputData;
            postData.checker_approval = data;
            axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    };

    handleSubmit = (event, data, buttonType) => {
        event.preventDefault();
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return false
            }
        }
        this.state.checkerApproval = data;
        this.setState({
            submitButtonData: data,
            actionButtonType: buttonType,
            title: "Do you want to confirm?",
            confirmationAlert: true
        });
    };

    signatureCardApi = () => {
        if (this.state.selectedImageArrayList.length > 0) {
            return new Promise((resolve, reject) => {
                this.setState({
                    loading: true
                });
                let postData = [];
                this.state.selectedImageArrayList.map((type, i) => {
                    let object = {
                        appId: this.props.appId,
                        customerId: this.state.inputData.cbNumber,
                        accountNo: this.state.inputData.accountNumber,
                        type: "CARD" + (i + 1),
                        remarks: this.state.inputData["imageRemarks" + i]
                    };
                    if (this.state.inputData["deleteFlag" + i] === "NO") {
                        postData.push(object);
                    }
                });
                let signatureUpload = backEndServerURL + "/signatureImage/uploadToFinacleBulk";
                axios.post(signatureUpload, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.signatureUpdate = true;
                        this.functionForVariableSetApi().then((variableResponse) => {
                            this.setState({
                                loading: false,
                                signatureUpdate: true,
                                disabled: false
                            });
                            resolve(response)
                        }).catch((error) => {
                            this.setState({
                                signatureUpdate: false,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            signatureUpdate: false,
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                            disabled: false
                        });
                        reject(error)
                    });
            })
        }
    };

    functionForSMSAPI = () => {
        return new Promise((resolve, reject) => {
            let sendSmsUrl = backEndServerURL + "/accountMaintenance/sendSms/" + this.state.inputData.accountNumber;
            let postData = {};
            postData.mandateUpdate = true;
            postData.mobileNumber = this.state.inputData.existingPhoneNumber;
            axios.post(sendSmsUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    };

    caseRouteApi = () => {
        let url = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        disabled: false,
                    });
                    this.props.closeModal()
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    disabled: false,
                });
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    renderApproveButton = () => {
        let mandateDeleteFlag = (this.state.inputData.existingMandateFlag && Object.keys(this.state.inputData.existingMandateFlag).length > 0) ? this.state.mandateDelete : true;
        let signatureUpdate = (mandateDeleteFlag === true && this.state.selectedImageArrayList.length > 0 && this.state.mandateUpdate) ? this.state.signatureUpdate : true;

        let deleteSignature = (this.state.inputData.signatureDeleteFlag !== undefined && this.state.inputData.signatureDeleteFlag.length > 0) ? this.state.deleteSignature : true;
        return (
            <>
                {((this.state.inputData.existingMandateFlag && Object.keys(this.state.inputData.existingMandateFlag).length > 0) && !this.state.inputData.mandateDelete) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'

                        onClick={(event) => this.handleSubmit(event, "APPROVED", "mandateDelete")}
                    >Existing Related CB Delete/Update
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
                {(!this.state.mandateUpdate) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'

                        onClick={(event) => this.handleSubmit(event, "APPROVED", "mandateUpdate")}
                    >Related CB Update
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
                {((this.state.inputData.signatureDeleteFlag !== undefined && this.state.inputData.signatureDeleteFlag.length > 0) && !deleteSignature) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Delete Signature")}
                    >Delete Existing Signature
                    </button>
                    &nbsp;&nbsp;&nbsp;
                </>}
                {(!signatureUpdate) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "signatureUpdate")}
                    >Update Signature Card
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
                {(!this.state.finalApprove) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'

                        onClick={(event) => this.handleSubmit(event, "APPROVED", "finalApprove")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
            </>
        )
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid xs={12}>
                    <br/>
                    {this.renderApproveButton()}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "SEND_TO_CS", "RETURN")}
                    >Return to CS
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginBottom: "10px"
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN", "RETURN")}
                    >Return to Maker
                    </button>
                </Grid>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };


    mappingCropImage = () => {
        this.setState({
            getMappingCropImage: true,
            signatureButtonShowInImageCropedPage: true
        })
    };

    closeCropImage = () => {
        this.setState({
            loading: false,
            getMappingCropImage: false,
            signatureButtonShowInImageCropedPage: false
        })
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    signature = () => {
        if (this.state.getData) {
            // return <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}
            //                         signatureType="multiple"/>
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff", "margin-top": "15px"}}
                      onClick={() => this.openSignature(this.state.inputData.accountNumber)}>Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };

    renderLoader = () => {
        if (this.state.getData) {
            return (
                <>
                    <Label stopLoading={this.stopLoading} accountNumber={this.state.inputData.accountNumber}
                           cbNumber={this.state.inputData.cbNumber} style={{margin: "0 auto"}} classes={this.props}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <br/>
                </>
            )
        }
    };

    renderTagListPrevious = () => {
        if (this.state.getData && this.state.getTagList.length > 0) {
            return (
                <div style={{marginBottom: 40, marginTop: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>
                        </CardHeader>
                    </paper>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}
                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left']}
                    />
                </div>
            )
        }
    };

    createTaggingData = (data) => {
        return (
            [data.cbNumber, data.customerName, data.relationCode, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode, data.id)}
            >View
            </button>]]

        )
    };

    tagCbDetailsModal = (event, cb, requestType, cbNumber, relationCode, id) => {
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb,
            requestType: requestType,
            tagCbNumber: cbNumber,
            linkrelationCode: relationCode,
            rowId: id
        })

    };

    tagCbDetailsModalClose = (value) => {
        if (value) {
            this.state.submittedTagCbList.push(value);
            this.setState({
                tagCbDetailsModal: false
            })
        } else {
            this.setState({
                tagCbDetailsModal: false
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Related Party:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Designation Code", "Action", "Related CB Status", "Delete"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createMandateTableData = (sl, cb, name, relationship, designation, action, existingDeleteFlag, del) => {

        return ([
            sl, cb, name, relationship, designation, action, existingDeleteFlag, del
        ])

    };

    triggerExistingMandateForDelete = (cbNumber, index) => {
        console.log(this.state.inputData.existingMandateFlag);
        if (this.state.inputData.existingMandateFlag !== undefined) {
            let existingSelect = this.state.inputData.existingMandateFlag[cbNumber];
            return (
                <select name={cbNumber} disabled={true}>
                    <option value="Y" selected={existingSelect === "Y"}>Y</option>
                    <option value="N" selected={existingSelect === "N"}>N</option>
                </select>
            )
        }
    };

    triggerExistingMandate = (cbNumber) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.triggerExistingMandateDialog(cbNumber)}
            >
                View
            </button>
        )
    };

    triggerExistingMandateDialog = (cbNumber) => {
        let itemId = 0;
        for (let item of this.state.makerEditedExistingMandate) {
            if (item.customer === ("E" + cbNumber)) {
                itemId = item.id
            }
        }
        this.setState({
            rowId: itemId,
            mandateModal: true,
            existingMandateCb: cbNumber
        });
    };

    checkListClose = () => {
        this.setState({
            mandateModal: false,
        });
    };

    getTaggingData = () => {
        let tableArrayData = [];
        let tableArray = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    }
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    getTagList: tableArrayData,
                    needCheckForTag: tableArray
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    getTExistingMandateData = () => {
        let tableArrayData = [];
        let tableArray = [];
        let pendingExistingUpdate = [];
        let cbTagUrl = backEndServerURL + "/getExistingMandateInfo/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    } else {
                        pendingExistingUpdate.push(data)
                    }
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    makerEditedExistingMandate: response.data,
                    getMandateList: tableArrayData,
                    pendingExistingUpdate: pendingExistingUpdate,
                    needCheckForExistingMandate: tableArray
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    renderModOfOperationField = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonArrayForMod, this.updateComponent);
        }
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    closeModal = (account) => {
        this.setState({
            loading: false,
            disabled: false,
            getMappingCropImage: false,
        });
    };


    handleDeleteSignature = () => {
        this.setState(prev => ({
            disabled: false,
            loading: false,
            signatureDeleteButton: !prev.signatureDeleteButton,
            showDeleteSignature: !prev.showDeleteSignature
        }))
    };


    render() {

        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.signatureDeleteButton}>
                        <DialogContent className={classes.dialogPaper}>
                            <SignatureCardDelete
                                editMode={false}
                                deleteCheckedSignature={this.functionForSignatureDelete}
                                showDeleteSignature={this.state.showDeleteSignature}
                                accountNumber={this.state.inputData.accountNumber}
                                inputData={this.state.inputData}
                                handleDeleteSignature={this.handleDeleteSignature}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <SecondaryCbFormDetails customerNumber={this.state.tagCb} appId={this.props.appId}
                                                    rowId={this.state.rowId}
                                                    relationCode={this.state.linkrelationCode}
                                                    customerName={this.state.inputData.customerName}
                                                    accountNumber={this.state.inputData.account}
                                                    solId={this.state.inputData.solid}
                                                    freeFlag4={this.state.inputData.schemeCode}
                                                    tagingModalCbnumber={this.state.tagCb} tagCb={this.state.tagCb}
                                                    checkerEnd={true} tagForm={CBDataJsonForm}
                                                    getTaggingData={this.getTaggingData}
                                                    closeModal={this.tagCbDetailsModalClose}
                                                    requestType={this.state.requestType}
                                                    cbNumber={this.state.tagCbNumber}/>
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.getMappingCropImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage subCategory={this.props.subCategory}
                                               handleSignatureCard={this.signatureCardApi}
                                               signatureButtonShowInImageCropedPage={this.state.signatureButtonShowInImageCropedPage}
                                               serviceType={this.props.serviceType}
                                               subServiceType={this.props.subServiceType}
                                               appId={this.props.appId}
                                               closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmationAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmation}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.mandateModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ExistingMandateDetails readOnly={true} closeModal={this.checkListClose}
                                                    customerNumber={this.state.tagCb}
                                                    rowId={this.state.rowId}
                                                    getTExistingMandateData={this.getTExistingMandateData}
                                                    cbNumber={this.state.existingMandateCb} appId={this.props.appId}/>
                        </DialogContent>
                    </Dialog>
                    {this.renderLoader()}
                    {this.signature()}
                    <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {this.renderModOfOperationField()}
                    </ThemeProvider>
                    </Grid>
                    <Grid item={12}>
                        {this.renderMandateData()}
                    </Grid>
                    {this.renderTagListPrevious()}
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderFlowSummeryButton()}
                            <br/>
                        </Grid>
                        {this.renderDefferalData()}
                        {this.renderRemarksData()}
                        {this.renderNotification()}
                        {this.renderRemarks()}
                        {this.renderSubmitButton()}
                    </ThemeProvider>
                </div>
            </GridList>
        )

    }
}

export default withStyles(styles)(VerifyDocumentBOM2);
