import React, {Component} from "react";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import GridItem from "../Grid/GridItem";
import Grid from "../Grid/GridContainer";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Table from "../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import ErrorModal from "../workflow/CommonComponent/ErrorModal";
import Functions from "../../Common/Functions";


let userBulkUpload = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];


class UserBulkUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            fileUploadData: {},
            responseData: [],
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            notificationMessage: "",
            alert: false,
            showData: true,
            disabled: false,
            selectedCount: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            tableArray: [],
            getData: false,
            errorTittle: "",
            errorModalBoolean: false,
            loading: false
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    createTableData = (employeeId, status) => {
        return ([employeeId, status])
    };

    getSubmitedForm = (event) => {
        event.preventDefault();
        if(this.state.fileUploadData.fileName === undefined){
            return 0;
        }
        this.setState({
            getData: false,
            disabled: true,
            loading: true,
        });
        console.log(this.state.fileUploadData.fileName.name);
        let fileUploadedName = this.state.fileUploadData.fileName.name;
        let fileN = fileUploadedName.split(".");
        let lengthFileN = fileN.length;
        fileN = fileN[lengthFileN - 1];
        let acceptArray = (".xlsx,.xls").split(",");

        if (!acceptArray.includes(`.${fileN}`)) {
            this.setState({
                disabled: false,
                loading: false,
                errorModalBoolean: true,
                errorTittle: "Invalid File Type!",
            });
            return 0;
        }


        console.log(this.state.fileUploadData);
        let tableArray = [];
        const formData = new FormData();
        formData.append("file", this.state.fileUploadData.fileName);
        axios({
            method: "post",
            url: backEndServerURL + "/user/add/bulk",
            data: formData,
            withCredentials: true,
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
        })
            .then(response => {
                this.setState({
                    disabled: false,
                    title: "Successfully!!",
                    notificationMessage: "Successfully File Uploaded",
                    alert: true
                });
                console.log(response.data);
                response.data.map((data, index) => {
                    console.log(data);
                    tableArray.push(this.createTableData(data[0], data[1]));
                });
                this.setState({
                    disabled: false,
                    tableArray: tableArray,
                    getData: true,
                    loading: false
                })

            })
            .catch(error => {
                let notificationMessage="Wrong File Format!";
                let errorMessage=Functions.errorObjectCheck(error);
                notificationMessage=(errorMessage === "Unable to connect AWF") ? errorMessage : notificationMessage;
                console.log(error);
                this.setState({
                    disabled: false,
                    getData: true,
                    loading: false,
                    alert: true,
                    title: "Warning!",
                    notificationMessage: notificationMessage
                })
            });
    };


    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };
    renderUserDetails = () => {
        if (this.state.getData === true && this.state.tableArray.length > 0) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Bulk Upload Failed List</h4>
                    </CardHeader>
                    <CardBody>

                        <div>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Grid container spacing={0}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>
                                            <div>
                                                <Table

                                                    tableHovor="yes"
                                                    tableHeaderColor="primary"
                                                    tableHead={["Employee ID", "Status"]}
                                                    tableData={this.state.tableArray}
                                                    tableAllign={['left', 'left']}
                                                />


                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridItem>


                            </Grid>
                        </div>
                    </CardBody>
                </Card>


            )
        }

    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    };

    render() {
        return (
            <React.Fragment>


                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Bulk Users File Upload</h4>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                open={this.state.loading}>
                                <DialogContent >

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="mD"
                                open={this.state.errorModalBoolean}>
                                <DialogContent>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                    />

                                </DialogContent>
                            </Dialog>
                            {this.renderNotification()}

                            <Grid container spacing={0}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <CardBody>


                                            <div>
                                                <a href={process.env.PUBLIC_URL + '/sample upload files/Format Bulk LWF user creation.xlsx'} download="Sample File" style={{
                                                    display: "block",
                                                    float: "right",
                                                }}>
                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                    >
                                                        Download Report
                                                    </button></a>

                                                {CommonJsonFormComponent.renderJsonForm(
                                                    this.state,
                                                    userBulkUpload,
                                                    this.updateComponent
                                                )}

                                                <br/>
                                                <button
                                                    className="btn btn-outline-primary btn-sm"
                                                    style={{
                                                        marginRight: "10px",
                                                    }}
                                                    disabled={this.state.disabled}
                                                    onClick={this.getSubmitedForm}
                                                >
                                                    Submit
                                                </button>

                                            </div>
                                        </CardBody>
                                    </Card>
                                </GridItem>


                            </Grid>
                        </div>
                    </CardBody>
                </Card>
                <br/>
                {this.renderUserDetails()}
            </React.Fragment>
        );
    }
}

export default UserBulkUpload;
