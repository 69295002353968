import {CloserRequestFormMaker} from "./WorkflowJsonForm3";
import {maintenanceForMaker as MAKERAccountMaintenance} from "./Maintenance/static/JsonForCasaMaintenance"
import {
    CBDataJsonFormForFDR,
    KYC,
    MakerJsonFormForAcesEnrollmentBond,
    MakerJsonFormForAcesEnrollmentSanchaypatra,
    MAKERJsonFormForCasaCompany,
    MAKERJsonFormForCasaCompanyExist,
    MAKERJsonFormForCasaCompanyLast,
    MAKERJsonFormForCasaCompanyLastExist,
    MAKERJsonFormForCasaIndividual,
    MAKERJsonFormForCasaIndividualExist,
    MAKERJsonFormForCasaIndividualLast,
    MAKERJsonFormForCasaIndividualLastExist,
    MAKERJsonFormForCasaJoint,
    MAKERJsonFormForCasaJointExist,
    MAKERJsonFormForCasaJointLast,
    MAKERJsonFormForCasaJointLastExist,
    MAKERJsonFormForCasaProprietorship,
    MAKERJsonFormForCasaProprietorshipExist,
    MAKERJsonFormForCasaProprietorshipLast,
    MAKERJsonFormForCasaProprietorshipLastExist,
    MakerJsonFormForEncashmentBond,
    MakerJsonFormForEncashmentSanchaypatra,
    MakerJsonFormForIssuanceBond,
    MakerJsonFormForIssuanceSanchaypatra,
    NonIndividualKYC,
    NonIndividualTP,
    NonIndividualTPAndKYC,
    TP,
    TpKycForIndividual,
} from "./WorkflowJsonForm4";
import {
    BOMinWardJasonFormRemitance,
    fttJasonFormRemitanceBOM,
    MakerJsonFormForCasaIndividualTagDPS,
    MakerJsonFormForCasaIndividualTagDPSLast,
    MakerJsonFormForCasaIndividualTagFdr2,
    MakerJsonFormForCasaIndividualTagFdr2Last,
    MakerJsonFormForCasaIndividualWithExistingCBTagFdr,
    MakerJsonFormForCasaIndividualWithExistingCBTagFdrLast,
    MAKERJsonFormForNewFdrForm,
    MAKERJsonFormForNewFdrFormLasts,
    MedicalFileJsonFormBOM,
    outwardJasonFormRemitanceBOM,
    remittanceJsonForStudent,
    RemittanceServiceOtherJsonFormBOM,
    SDMLockerForm,
} from "./WorkflowJsonFormArin";
import {dpsJsonBomFormReadOnly} from "./WorkflowJsonFormRashed"
import React, {Component} from "react";
import VerifyMakerInboxCase from "./CASA/VerifyMakerInboxCase";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import ImageCrop from "./CASA/ImageCrop";
import VerifyMakerPhoto from "./VerifyMakerPhoto";
import OpeningSDM from "./fdr/OpeningSDM";
import VerifyMaker from "./BOND/VerifyMaker";
import VerifyMakerSanchoypatraInboxCase from "./Sanchoypatra/VerifyMakerSanchoypatra";
import ServiceDeliveryMaker from "./FundTransfer/SplitForm";
import SDMLocker from "./LOCKER/SDMLocker";
import VarifyMakerAccountMaintenance from "./Maintenance/static/VarifyMakerAccountMaintenance";
import MakerLockerSurrender from "./LOCKER/MakerLockerSurrender";
import MakerLockerMaintenance from "./LOCKER/MakerLockerMaintenance";
import CustomDutyPaymentMaker from "../workflow/FundTransfer/customDuty/MakerRequisition";


import CloserMakerInbox from "./Maintenance/casa/MakerInbox";
import FdrMakerApproval from "./Maintenance/FDR/FdrMakerApproval";

import MandateApproval from "./Maintenance/signatory/MakerApproval"
import ApprovalMaker from "./REMITENCE/ApprovalMaker";
import DpsMakerApprova from "./Maintenance/dps/MakerInbox"
import Others from "./Maintenance/staticOthers/Others";
import CloserRequestForMaker from "./Maintenance/casa/CloserRequestForMaker";
import {fdrEncashmentMaker, maintenanceForMaker} from "./Maintenance/FDR/JsonForFdrMaintenance"
import MaintenanceUtility from "./Maintenance/static/MaintenanceUtility";
import CbAndAccountUpdateComponent from "./CommonComponent/CbAndAccountUpdateComponent";
import FDRMaintenanceUtility from "./Maintenance/FDR/MaintenanceUtility";
import MakerViewForCustomStatusUpdate from "./Maintenance/CustomerStatusUpdate/MakerInboxView"


class MakerInboxCase extends Component {
    state = {
        sendTo: false,
        documentList: [],
        getDocument: false

    };

    constructor(props) {
        super(props);


    };


    componentDidMount() {

        let fileUrl = backEndServerURL + "/case/files/" + this.props.appUid;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(":d");
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  category={this.props.category}
                                  delStatus={this.props.delStatus}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  cbNumber={this.props.cbNumber}
                                  accountNumber={this.props.accountNumber}
                                  subServiceType={this.props.subServiceType}
                                  caseId={this.props.CaseId}
                                  solId={this.props.solId}

                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };
    casaMaintenance = () => {
        if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA" && (this.props.subCategory === "CB Update" || this.props.subCategory === "Account Update" || this.props.subCategory === "CB and Account Update" || this.props.subCategory === "Tp and Kyc Update"))) {
            return (
                <CbAndAccountUpdateComponent closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                             customerName={this.props.customerName}
                                             subCategory={this.props.subCategory}
                                             category={this.props.category}
                                             subServiceType={this.props.subServiceType}
                                             caseId={this.props.CaseId}
                                             solId={this.props.solId}
                                             accountNumber={this.props.accountNumber}
                                             cbNumber={this.props.cbNumber}
                                             serviceType={this.props.serviceType}
                                             freeFlag1={this.props.freeFlag1}
                                             freeFlag2={this.props.freeFlag2}
                                             freeFlag3={this.props.freeFlag3}
                                             freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                                             customerName={this.props.customerName}
                                             cbNumber={this.props.cbNumber}
                                             accountNumber={this.props.accountNumber}
                                             subServiceType={this.props.subServiceType}
                                             caseId={this.props.CaseId}
                                             appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" && this.props.freeFlag1 === "CloserRequest")) {
            return (
                <CloserRequestForMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}
                                       freeFlag1={this.props.freeFlag1}
                                       freeFlag2={this.props.freeFlag2}
                                       freeFlag3={this.props.freeFlag3}
                                       freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                                       customerName={this.props.customerName}
                                       cbNumber={this.props.cbNumber}
                                       accountNumber={this.props.accountNumber}
                                       subServiceType={this.props.subServiceType}
                                       caseId={this.props.CaseId}
                                       appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" || this.props.category === "DPS Closer" || this.props.category === "Deceased DPS Closer")) {
            return (
                <CloserMakerInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  category={this.props.category}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3}
                                  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                                  customerName={this.props.customerName}
                                  cbNumber={this.props.cbNumber}
                                  accountNumber={this.props.accountNumber}
                                  subServiceType={this.props.subServiceType}
                                  caseId={this.props.CaseId}
                                  titleName="Maker Update All Information"
                                  commonJsonForm={CloserRequestFormMaker}
                                  appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && this.props.category === "DPS Maintenance") {
            return (
                <DpsMakerApprova closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 category={this.props.category}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                 cbNumber={this.props.cbNumber}
                                 accountNumber={this.props.accountNumber}
                                 subServiceType={this.props.subServiceType}
                                 caseId={this.props.CaseId}
                                 commonJsonForm={dpsJsonBomFormReadOnly}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance' && this.props.taskTitle === 'sd_maker_maintenance_approval') {
            return (
                <VarifyMakerAccountMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                               category={this.props.category}
                                               serviceType={this.props.serviceType}
                                               freeFlag1={this.props.freeFlag1}
                                               freeFlag2={this.props.freeFlag2}
                                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                               cbNumber={this.props.cbNumber}
                                               accountNumber={this.props.accountNumber}
                                               subServiceType={this.props.subServiceType}
                                               caseId={this.props.CaseId}
                                               titleName="Maker Update All Information"
                                               commonJsonForm={MAKERAccountMaintenance}
                                               appId={this.props.appUid}/>)
        }

    };
    fdrMaintenance = () => {
        if (this.props.subServiceType === 'FDRMaintenance' && this.props.serviceType === "Maintenance") {
            return (
                <FdrMakerApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  category={this.props.category}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  cbNumber={this.props.cbNumber}
                                  accountNumber={this.props.accountNumber}
                                  subServiceType={this.props.subServiceType}
                                  caseId={this.props.CaseId}
                                  titleName="Maker Update All Information"
                                  commonJsonForm={this.props.freeFlag2 === "FDR Maintenance" ? maintenanceForMaker : fdrEncashmentMaker}
                                  appId={this.props.appUid}/>)
        }

    };

    mandateMaintenance = () => {
        if (this.props.subServiceType === 'MandateMaintenance' && this.props.serviceType === "Maintenance") {
            return (
                <MandateApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 category={this.props.category}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                 cbNumber={this.props.cbNumber}
                                 accountNumber={this.props.accountNumber}
                                 subServiceType={this.props.subServiceType}
                                 caseId={this.props.CaseId}
                                 titleName="Maker Update All Information"
                                 appId={this.props.appUid}/>)
        }

    };

    remetance = () => {

        if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Student File') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={outwardJasonFormRemitanceBOM.concat(remittanceJsonForStudent)}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Medical File') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={MedicalFileJsonFormBOM}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Other Service') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={RemittanceServiceOtherJsonFormBOM}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FTT') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={fttJasonFormRemitanceBOM}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FDD') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={fttJasonFormRemitanceBOM}
                                   appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Inward') {
            return (<ApprovalMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                   category={this.props.category}
                                   serviceType={this.props.serviceType}
                                   freeFlag1={this.props.freeFlag1}
                                   freeFlag2={this.props.freeFlag2}
                                   freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                   recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                   cbNumber={this.props.cbNumber}
                                   accountNumber={this.props.accountNumber}
                                   subServiceType={this.props.subServiceType}
                                   caseId={this.props.CaseId}
                                   titleName="Account Maintenance"
                                   commonJsonForm={BOMinWardJasonFormRemitance}
                                   appId={this.props.appUid}/>)
        }
    };


    casa = () => {

        if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") && this.props.taskTitle === 'maker_update_all_information') {

            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={TpKycForIndividual}
                                          commonJsonForm={MAKERJsonFormForCasaIndividual}
                                          commonJsonFormLast={MAKERJsonFormForCasaIndividualLast}
                                          titleName="Maker Update All Information"
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Joint Account" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={TpKycForIndividual}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaJoint}
                                          commonJsonFormLast={MAKERJsonFormForCasaJointLast}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Proprietorship A/C") && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          kyc={NonIndividualKYC}
                                          tp={NonIndividualTP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaProprietorship}
                                          commonJsonFormLast={MAKERJsonFormForCasaProprietorshipLast}

                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Company Account" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          kyc={NonIndividualKYC}
                                          tp={NonIndividualTP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaCompany}
                                          commonJsonFormLast={MAKERJsonFormForCasaCompanyLast}

                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Agent Account Opening" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaCompany}
                                          commonJsonFormLast={MAKERJsonFormForCasaCompanyLast}
                                          kyc={KYC}
                                          tp={TP}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.taskTitle === 'maker_signiture_photo_upload') {
            return (<ImageCrop closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               category={this.props.category}
                               delStatus={this.props.delStatus}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               cbNumber={this.props.cbNumber}
                               accountNumber={this.props.accountNumber}
                               subServiceType={this.props.subServiceType}
                               caseId={this.props.CaseId}
                               solId={this.props.solId}
                               titleName="Maker photo Upload"
                               kyc={KYC}
                               tp={TP}
                               tpAndKyc={NonIndividualTPAndKYC}
                               appId={this.props.appUid}/>)
        }


    };
    casaExist = () => {
        if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          commonJsonForm={MAKERJsonFormForCasaIndividualExist}
                                          commonJsonFormLast={MAKERJsonFormForCasaIndividualLastExist}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={TpKycForIndividual}
                                          titleName="Maker Update All Information"
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Joint Account" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaJointExist}
                                          commonJsonFormLast={MAKERJsonFormForCasaJointLastExist}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={TpKycForIndividual}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && (this.props.subServiceType === "NONINDIVIDUAL" || this.props.subServiceType === "Proprietorship A/C") && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaProprietorshipExist}
                                          commonJsonFormLast={MAKERJsonFormForCasaProprietorshipLastExist}
                                          kyc={NonIndividualKYC}
                                          tp={NonIndividualTP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Company Account" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaCompanyExist}
                                          commonJsonFormLast={MAKERJsonFormForCasaCompanyLastExist}
                                          kyc={NonIndividualKYC}
                                          tp={NonIndividualTP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.subServiceType === "Agent Account Opening" && this.props.taskTitle === 'maker_update_all_information') {
            return (<VerifyMakerInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber}
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName="Maker Update All Information"
                                          commonJsonForm={MAKERJsonFormForCasaCompanyExist}
                                          commonJsonFormLast={MAKERJsonFormForCasaCompanyLastExist}
                                          kyc={KYC}
                                          tp={TP}
                                          tpAndKyc={NonIndividualTPAndKYC}
                                          appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === "Account Opening" || this.props.serviceType === 'ExistAccountOpening') && this.props.taskTitle === 'maker_signiture_photo_upload') {
            return (<ImageCrop closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               category={this.props.category}
                               delStatus={this.props.delStatus}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                               cbNumber={this.props.cbNumber}
                               accountNumber={this.props.accountNumber}
                               subServiceType={this.props.subServiceType}
                               caseId={this.props.CaseId}
                               solId={this.props.solId}
                               titleName="Maker photo Upload"
                               appId={this.props.appUid}/>)
        }


    };

    locker = () => {
        if (this.props.serviceType === 'Locker Opening') {
            return (<SDMLocker
                closeModal={this.props.closeModal} closeIcon={this.props.closeIcon} category={this.props.category}
                delStatus={this.props.delStatus}
                serviceType={this.props.serviceType}
                freeFlag1={this.props.freeFlag1}
                freeFlag2={this.props.freeFlag2}
                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                customerName={this.props.customerName}
                cbNumber={this.props.cbNumber}
                accountNumber={this.props.accountNumber}
                subServiceType={this.props.subServiceType}
                caseId={this.props.CaseId}
                solId={this.props.solId}
                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                titleName="Maker Update All Information"
                commonJsonForm={SDMLockerForm}
                appId={this.props.appUid}/>)
        }
    };
    fdr = () => {
        /*   if (this.props.category === 'FDR Account Opening - with Existing CB Only') {
          else if (this.props.category === 'FDR Account Opening -Without Operative Account-NTB') {
              else if (this.props.category === 'FDR Account Opening - with Existing Operative Account') {*/

        if (this.props.serviceType === "FDR Opening" && this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {
            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                titleName="FDR Account Opening - with Existing CB Only"
                                jsonFormMisRelated={CBDataJsonFormForFDR}
                                commonJsonForm={MakerJsonFormForCasaIndividualWithExistingCBTagFdr}
                                commonJsonFormLast={MakerJsonFormForCasaIndividualWithExistingCBTagFdrLast}
                                appId={this.props.appUid}/>)

        } else if (this.props.serviceType === "FDR Opening" && this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                titleName="FDR Account Opening - with Existing Operative Account"
                                commonJsonForm={MakerJsonFormForCasaIndividualTagFdr2}
                                commonJsonFormLast={MakerJsonFormForCasaIndividualTagFdr2Last}
                                appId={this.props.appUid}/>)

        } else if (this.props.serviceType === "FDR Opening" && this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB') {
            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                titleName="FDR Account Opening -Without Operative Account-NTB"
                                jsonFormMisRelated={CBDataJsonFormForFDR}
                                commonJsonForm={MAKERJsonFormForNewFdrForm}
                                commonJsonFormLast={MAKERJsonFormForNewFdrFormLasts}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "FDR Opening") {
            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1="FDR Account Opening - with Existing Operative Account"
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                titleName="FDR Account Opening - with Existing Operative Account"
                                commonJsonForm={MakerJsonFormForCasaIndividualTagFdr2}
                                commonJsonFormLast={MakerJsonFormForCasaIndividualTagFdr2Last}
                                appId={this.props.appUid}/>)

        } else if (this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                titleName="DPS Account Opening -with Existing Operative Account"
                                commonJsonForm={MakerJsonFormForCasaIndividualTagDPS}
                                commonJsonFormLast={MakerJsonFormForCasaIndividualTagDPSLast}
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening') {

            return (<OpeningSDM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                category={this.props.category}
                                delStatus={this.props.delStatus}
                                serviceType={this.props.serviceType}
                                freeFlag1="DPS Account Opening -with Existing Operative Account"
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                cbNumber={this.props.cbNumber}
                                accountNumber={this.props.accountNumber}
                                subServiceType={this.props.subServiceType}
                                caseId={this.props.CaseId}
                                solId={this.props.solId}
                                titleName="DPS Account Opening -with Existing Operative Account"
                                commonJsonForm={MakerJsonFormForCasaIndividualTagDPS}
                                commonJsonFormLast={MakerJsonFormForCasaIndividualTagDPSLast}
                                appId={this.props.appUid}/>)
        }


    };

    Encashment = () => {
        if (this.props.freeFlag1 === "BOND") {
            return (
                <VerifyMakerSanchoypatraInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                  category={this.props.category}
                                                  delStatus={this.props.delStatus}
                                                  serviceType={this.props.serviceType}
                                                  freeFlag1={this.props.freeFlag1}
                                                  freeFlag2={this.props.freeFlag2}
                                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                  cbNumber={this.props.cbNumber}
                                                  accountNumber={this.props.accountNumber} department="BOND"
                                                  subServiceType={this.props.subServiceType}
                                                  caseId={this.props.CaseId}
                                                  solId={this.props.solId}
                                                  titleName={this.props.subserviceType}
                                                  commonJsonForm={MakerJsonFormForEncashmentBond}
                                                  appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyMakerSanchoypatraInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                  category={this.props.category}
                                                  delStatus={this.props.delStatus}
                                                  serviceType={this.props.serviceType}
                                                  freeFlag1={this.props.freeFlag1}
                                                  freeFlag2={this.props.freeFlag2}
                                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                  cbNumber={this.props.cbNumber}
                                                  accountNumber={this.props.accountNumber} department="BOND"
                                                  subServiceType={this.props.subServiceType}
                                                  caseId={this.props.CaseId}
                                                  solId={this.props.solId}
                                                  titleName={this.props.subserviceType}
                                                  commonJsonForm={MakerJsonFormForEncashmentSanchaypatra}
                                                  appId={this.props.appUid}/>)
        }

    };

    issuance = () => {
        if (this.props.freeFlag1 === "BOND") {
            return (
                <VerifyMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             category={this.props.category}
                             delStatus={this.props.delStatus}
                             serviceType={this.props.serviceType}
                             freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             cbNumber={this.props.cbNumber}
                             accountNumber={this.props.accountNumber} department="BOND"
                             subServiceType={this.props.subServiceType}
                             caseId={this.props.CaseId}
                             solId={this.props.solId} titleName={this.props.subserviceType}
                             commonJsonForm={MakerJsonFormForIssuanceBond}
                             appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                             category={this.props.category}
                             delStatus={this.props.delStatus}
                             serviceType={this.props.serviceType}
                             freeFlag1={this.props.freeFlag1}
                             freeFlag2={this.props.freeFlag2}
                             freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                             recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                             cbNumber={this.props.cbNumber}
                             accountNumber={this.props.accountNumber} department="BOND"
                             subServiceType={this.props.subServiceType}
                             caseId={this.props.CaseId}
                             solId={this.props.solId} titleName={this.props.subserviceType}
                             commonJsonForm={MakerJsonFormForIssuanceSanchaypatra}
                             appId={this.props.appUid}/>)
        }


    };
    acesEnrollmentMaintenance = () => {

        if (this.props.freeFlag1 === "BOND") {
            return (
                <VerifyMakerSanchoypatraInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                  category={this.props.category}
                                                  delStatus={this.props.delStatus}
                                                  serviceType={this.props.serviceType}
                                                  freeFlag1={this.props.freeFlag1}
                                                  freeFlag2={this.props.freeFlag2}
                                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                  cbNumber={this.props.cbNumber}
                                                  accountNumber={this.props.accountNumber} department="BOND"
                                                  subServiceType={this.props.subServiceType}
                                                  caseId={this.props.CaseId}
                                                  solId={this.props.solId}
                                                  titleName={this.props.subserviceType}
                                                  commonJsonForm={MakerJsonFormForAcesEnrollmentBond}
                                                  appId={this.props.appUid}/>)
        } else {
            return (
                <VerifyMakerSanchoypatraInboxCase closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                                  category={this.props.category}
                                                  delStatus={this.props.delStatus}
                                                  serviceType={this.props.serviceType}
                                                  freeFlag1={this.props.freeFlag1}
                                                  freeFlag2={this.props.freeFlag2}
                                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                                  cbNumber={this.props.cbNumber}
                                                  accountNumber={this.props.accountNumber} department="BOND"
                                                  subServiceType={this.props.subServiceType}
                                                  caseId={this.props.CaseId}
                                                  solId={this.props.solId}
                                                  titleName={this.props.subserviceType}
                                                  commonJsonForm={MakerJsonFormForAcesEnrollmentSanchaypatra}
                                                  appId={this.props.appUid}/>)
        }

    };
    fundTransferSd = () => {
     if (this.props.subCategory === "Custom Duty Payment") {
            return (
                <CustomDutyPaymentMaker
                    closeModal={this.props.closeModal}
                    closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    cbNumber={this.props.cbNumber}
                    accountNumber={this.props.accountNumber}
                    solId={this.props.solId}
                    customerName={this.props.customerName}
                    freeFlag3={this.props.freeFlag3}
                    freeFlag4={this.props.freeFlag4}
                    subServiceType={this.props.subServiceType}
                    maintenanceType={this.props.maintenanceType}
                    appId={this.props.appUid}
                    caseId={this.props.CaseId}
                />
            )
        }

        if (this.props.serviceType === "FundTransfer" && this.props.subServiceType === "NewFundTransfer") {
            return (<ServiceDeliveryMaker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          category={this.props.category}
                                          delStatus={this.props.delStatus}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                          cbNumber={this.props.cbNumber}
                                          accountNumber={this.props.accountNumber} department="SD"
                                          subServiceType={this.props.subServiceType}
                                          caseId={this.props.CaseId}
                                          solId={this.props.solId}
                                          titleName={this.props.subserviceType}
                                          appId={this.props.appUid}/>)
        }

    };
    lockerClosing = () => {
        if (this.props.subServiceType === 'LockerClosing') {
            return (
                <MakerLockerSurrender
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon} category={this.props.category}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}
                    cbNumber={this.props.cbNumber}
                    accountNumber={this.props.accountNumber}
                    subServiceType={this.props.subServiceType}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    titleName="Locker Closing"
                    appId={this.props.appUid}
                />
            )
        }
    };

    lockerMaintenance = () => {
        if (this.props.subServiceType === 'LockerMaintenance') {
            return (
                <MakerLockerMaintenance
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon} category={this.props.category}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}
                    cbNumber={this.props.cbNumber}
                    accountNumber={this.props.accountNumber}
                    subServiceType={this.props.subServiceType}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    titleName="Locker Mainentance"
                    appId={this.props.appUid}
                />
            )
        }
    };

    casaOtherMaintenance = () => {
        if (this.props.freeFlag1 === 'CASA Other Maintenance' && this.props.serviceType === "Maintenance") {
            return (<Others closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            taskTitle={this.props.taskTitle}
                            serviceType={this.props.serviceType}
                            freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                            cbNumber={this.props.cbNumber}
                            solId={this.props.solId}
                            subServiceType={this.props.subServiceType}
                            caseId={this.props.CaseId}
                            titleName="CASA Static Other Maintenance"
                            appId={this.props.appUid}/>)
        }
    };

    customerStatusUpdate = () => {
        return(
            <MakerViewForCustomStatusUpdate closeModal={this.props.closeModal}
                                            closeIcon={this.props.closeIcon}
                                            subCategory={this.props.subCategory}
                //caseId={this.props.CaseId}
                                            solId={this.props.solId}
                                            category={this.props.category}
                                            serviceType={this.props.serviceType}
                                            freeFlag1={this.props.freeFlag1}
                                            freeFlag2={this.props.freeFlag2}
                                            freeFlag3={this.props.freeFlag3}
                                            freeFlag4={this.props.freeFlag4}
                                            customerName={this.props.customerName}
                                            cbNumber={this.props.cbNumber}
                                            accountNumber={this.props.accountNumber}
                                            subServiceType={this.props.subServiceType}
                                            caseId={this.props.CaseId}
                                            appId={this.props.appUid}/>
        )
    };

    inboxCase = () => {

        if (this.props.serviceType === "Account Opening") {
            return (
                this.casa()
            )
        }
        else if(this.props.subCategory === "Customer Status Update"){
            return (
                this.customerStatusUpdate()
            )
        }
        else if (this.props.freeFlag1 === "CASA Other Maintenance" && this.props.serviceType === "Maintenance") {
            return (
                this.casaOtherMaintenance()
            )
        } else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {
            return (
                this.fdr()
            )
        } else if (this.props.serviceType === "Locker Opening") {
            return (
                this.locker()
            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                this.remetance()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "ENCASHMENT PAYMENT" || this.props.freeFlag2 === "INTEREST PAYMENT")) {
            return (
                this.Encashment()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && (this.props.freeFlag2 === "NOMINEE CHANGE" || this.props.freeFlag2 === "ACES ENROLLMENT" || this.props.freeFlag2 === "ACCOUNT NUMBER CHANGE" || this.props.freeFlag2 === "DUPLICATE ISSUANCE" || this.props.freeFlag2 === "CERTIFICATE")) {
            return (
                this.acesEnrollmentMaintenance()
            )
        } else if (this.props.serviceType === "bond_issuance_serve" && this.props.freeFlag3 === "ISSUANCE") {

            return (
                this.issuance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "AccountMaintenance") {
            return (
                this.casaMaintenance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "FDRMaintenance") {
            return (
                this.fdrMaintenance()
            )
        } else if (this.props.serviceType === "Maintenance" && this.props.subServiceType === "MandateMaintenance") {
            return (
                this.mandateMaintenance()
            )
        } else if (this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casaExist()
            )
        } else if (this.props.serviceType === "FundTransfer") {
            return (
                this.fundTransferSd()
            )
        } else if (this.props.subServiceType === "LockerClosing") {
            return (
                this.lockerClosing()
            )
        } else if (this.props.subServiceType === "LockerMaintenance") {
            return (
                this.lockerMaintenance()
            )
        }
    };
    close = () => {
        this.props.closeModal();
    };
    closeIcon = () => {
        this.props.closeIcon();
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    renderHeaderInformation = () => {
        return (
            <p style={{fontSize: "16px"}}> (<b> Customer
                Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                <b> CB
                    Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                    Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p>
        )
    };
    masterdataToString=(data)=>{
        if(data!==undefined && data.key!==undefined){
            return data.value;
        }
        else{
            return data;
        }
    }
    renderTitle = () => {
        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} >
                    <b>{this.props.freeFlag2 === "withKyc" ? "( FDR Account Opening - Without operative CASA )" : "( FDR Account Opening - Using Different CASA )"}</b>{this.renderHeaderInformation()}
                </h4>

            )
        } else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (
                <h4 style={{color: "white"}} >Fund Transfer-{this.props.subCategory === "SME/Corporate" ? "Corporate" : this.props.subCategory}<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                <h4 style={{color: "white"}} >Remittance<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b> {this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.subServiceType === "FDRMaintenance" && this.props.serviceType === "Maintenance") {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon,this.props.CaseId);
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "CASA Closer") {
            return MaintenanceUtility.renderCloserMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} >CASA CLoser Request<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></h4>
            //
            // );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && (this.props.category === "DPS Closer" || this.props.category === "DPS Maintenance")) {
            return (
                <h4 style={{color: "white"}} >DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "Deceased DPS Closer") {
            return (
                <h4 style={{color: "white"}} >Deceased DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} ><b>CASA Static Data Maintenance </b><p style={{fontSize:"16px"}}>(<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
            //         {this.props.freeFlag2 === "linkedCB" ? <>
            //             <b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}</> : <>
            //          <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> AC SOL Id:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //         }}/></a></p></h4>
            //
            // );
        } else if (this.props.subCategory === "Opening Individual") {
            return (
                <h4 style={{color: "white"}} >Individual Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subCategory === "Opening Joint") {
            return (
                <h4 style={{color: "white"}} >Joint Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "MandateMaintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
            // return (
            //     <h4 style={{color: "white"}} ><b>Mandate/Signatory Update </b><p style={{fontSize:"16px"}}>(<b> Customer
            //         Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
            //      <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
            //         <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> CB:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}<b> SOLId:</b>{this.props.freeFlag3 !== undefined ? this.props.freeFlag3 : ""}</>})<a><CloseIcon onClick={this.closeIcon} style={{
            //         position: 'absolute',
            //         right: 10,
            //         color: "#000000"
            //     }}/></a></p></h4>
            //
            // );
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>FDR Account Opening -with NTB Operative Account </b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>DPS Account Opening -with NTB Operative Account </b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Existing
                    Customer
                    Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>{this.renderHeaderInformation()}
                </h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>New Customer Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>{this.renderHeaderInformation()}
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.serviceType}</b>{this.renderHeaderInformation()}</h4>

            )

        }
    };

    render() {
        const {classes} = this.props;
        if (this.props.freeFlag3 === "ISSUANCE" || this.props.freeFlag3 === "ENCASHMENT" || this.props.freeFlag3 === "MAINTENANCE") {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >{this.props.category + " " + this.props.subCategory}<a><CloseIcon
                                onClick={this.closeIcon}
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>

                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={8}>

                                    {this.inboxCasePhoto()}

                                </Grid>


                                <Grid item xs={4}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )

        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') &&
            (this.props.category === "CASA" &&
                (this.props.subCategory === "CB Update"
                    || this.props.subCategory === "Account Update"
                    || this.props.subCategory === "CB and Account Update"
                    || this.props.subCategory === "Tp and Kyc Update"))) {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>

                                    {this.inboxCasePhoto()}

                                </Grid>


                                <Grid item xs={5}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )
        } else if (this.props.freeFlag1 === "CASA Other Maintenance") {
            return (
                <div>
                    <Grid item xs={12}>
                        {this.inboxCase()}
                    </Grid>
                </div>

            )
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>

                                    {this.inboxCasePhoto()}

                                </Grid>


                                <Grid item xs={5}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )

        }
    }

}

export default MakerInboxCase;