import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponent from "../../JsonForm/DateComponent";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../JsonForm/SelectComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import AccountNoGenerate from "../AccountNoGenerate";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../../MyValidation";
import GridList from "@material-ui/core/GridList";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import loader from "../../../Static/loader.gif";
import WaiverList from "../CommonComponent/WaiverList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import ErrorModal from "../CommonComponent/ErrorModal";
import DeferalList from "../CommonComponent/DeferalList";
import AccountStatement from "./AccountStatement";
import SignatureButton from "../Maintenance/SignatureButton";
import FunctionForGetAcDetails from "../CommonComponent/FunctionForGetAcDetails";
import Button from "@material/react-button";
import SdnAndDedupeResultShowing from "../CommonComponent/SdnAndDedupeResultShowing";


let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Please Specify",

    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferal Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

class OpeningBOM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            alowApiCall: true,
            getNewCase: false,
            varValue: [],
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            loading: false,
            confirmAlert: false,
            actionType: "",
            errorModalBoolean: false,
            errorTittle: "",
            history: false,
            accountNumber: "",
            solId: "",
            customerName: "",
            accountStatement: false,
            maturityAmt_amountValue: true,
            SdnAndDedupeResultShowing: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event, value) => {

        this.state.inputData["csDeferal"] = value;
        this.updateComponent();
        if (value === "YES") {

            let values = [];
            values.push(Math.floor(Math.random() * 100000000000));

            this.setState({values: values, deferalNeeded: true});

        } else {
            this.setState({
                values: [],
                deferalNeeded: false
            })
        }
    };


    addDeferalForm() {


        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3.5}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3.5}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3.5}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right"}}
                        className="btn btn-outline-primary"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '')
                delete clone[prop];
        return clone;
    };
    DedupDataSaveApi = (subServiceType) => {
        console.log(this.props.individualDedupData);
        console.log(this.props.companyDedupData);
        console.log(this.props.jointDedupData);
        if (subServiceType === "INDIVIDUAL" || subServiceType === "Individual A/C") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Joint Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "New FDR Opening") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Proprietorship A/C" || subServiceType === "NONINDIVIDUAL") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "individualDedupData": this.props.individualDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        individualDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (subServiceType === "Company Account") {
            let Dedupurl = backEndServerURL + "/dedup/save";
            axios.post(Dedupurl, {
                "jointDedupData": this.props.jointDedupData,
                "dedupType": subServiceType
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    axios.post(Dedupurl, {
                        "companyDedupData": this.props.companyDedupData,
                        "dedupType": subServiceType
                    }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                companyDataSaveId: response.data
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(" dedup save data");
                    console.log(response.data);
                    this.setState({
                        jointDataSaveId: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
        }
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(bomRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        if (actionType === "submitApprove") {

            this.setState({
                actionType: actionType,
                confirmAlert: true,

            })

        } else if (actionType === "submitReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "bomRemarks",
                "type": "textArea",
                "required": true,
                "label": "Bom Remarks",
                "grid": 12
            });

            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "submitApprove") {
            let bom_approval = 'APPROVED';
            this.handleSubmit(bom_approval);
        } else if (data === "YES" && this.state.actionType === "submitReturn") {
            let bom_approval = 'RETURN';
            this.handleSubmit(bom_approval);
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    /* submitApprove = () => {
         let bom_approval = 'APPROVED';
         this.handleSubmit(bom_approval);
     }
     submitReturn = () => {
         let bom_approval = 'RETURN';
         this.handleSubmit(bom_approval);
     }*/
    handlePayableAtMaturity = (event, type) => {
        event.preventDefault();
        if ((this.state.inputData.afterAccountGenerateButtonForBom) === "finalApproval") {
            this.setState({
                loading: true,
            });
            let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
            axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    let varValue = this.state.varValue;
                    console.log(response.data);
                    inputData.maturityAmt_amountValue = response.data.depositAccount.maturityAmt_amountValue;
                    inputData.afterAccountGenerateButtonForBom = "finalApproval";
                    this.variableSetFunction(inputData);
                    this.setState({
                        accountDetailsModal: true,
                        maturityAmt_amountValue: true,
                        loading: false,

                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error)
                    })
                })


        }


    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    {(this.state.inputData.afterAccountGenerateButtonForBom) === "finalApproval" &&
                    <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handlePayableAtMaturity(event, "submitApprove")}
                            // onClick={this.submitApprove}
                        > PAYABLE AT MATURITY
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.afterAccountGenerateButtonForBom) === "finalApproval" && <>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(event) => this.handleCommon(event, "submitApprove")}

                        >Final Approval
                        </button>
                        &nbsp;&nbsp;</>}

                    {(this.state.inputData.afterAccountGenerateButtonForBom) !== "finalApproval" && <>
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleCommon(event, "submitApprove")}

                        >
                            Approve
                        </button>
                        &nbsp;&nbsp;</>}


                    <button
                        className="btn btn-outline-primary  btn-sm"
                        style={{}}
                        onClick={(event) => this.handleCommon(event, "submitReturn")}

                    >
                        Return
                    </button>

                </div>

            )
        }
    };

    componentDidMount() {
        this.setState({
            loaderNeeded: false,
            loading: true,
        });

        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        let varValue = [];

        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    response.data.map((data) => {
                                        if (data.remarks !== 'undefined') {
                                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                        }

                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "YES";
                    console.log(response.data);
                    let varValue = this.emptyValueRemove(this.getSearchvalue(response.data));
                    let inputData = this.emptyValueRemove(this.getSearchvalue(response.data));
                    if (inputData.fdValueDate !== undefined && inputData.fdValueDate !== null && inputData.fdValueDate !== "" && inputData.fdValueDate !== -1) {
                        inputData.fdValueDate = (inputData.fdValueDate).split(',')[0];
                        varValue.fdValueDate = (varValue.fdValueDate).split(',')[0];
                    }
                    if (inputData.installmentStartDate !== undefined && inputData.installmentStartDate !== null && inputData.installmentStartDate !== "" && inputData.installmentStartDate !== -1) {
                        inputData.installmentStartDate = (inputData.installmentStartDate).split(',')[0];
                        varValue.installmentStartDate = (varValue.installmentStartDate).split(',')[0];
                    }
                    FunctionForGetAcDetails.getAccountInfo(inputData.debitAccountNumber)
                        .then((response) => {
                            inputData.availableAmount = response.data.acctEftvBalance;
                            varValue.availableAmount = response.data.acctEftvBalance;
                            inputData.bomRemarks = undefined;
                            varValue.bomRemarks = undefined;
                            this.setState({
                                getData: true,
                                loading: false,
                                varValue: varValue,
                                appData: this.getSearchvalue(response.data),
                                inputData: this.emptyValueRemove(inputData),
                                showValue: true,
                                appId: this.props.appId,
                                loaderNeeded: true
                            });
                        }).catch((error) => {
                        this.setState({
                            loading: false,
                        })
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Deferral Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Deferral Type", "Raise By", "Raise Date", "Expire Date", "Status"]}
                        tableData={this.state.getDeferalList}
                        tableAllign={['left', 'left']}
                    />
                    <br/>
                </div>

            )
        }

    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderAddButtonShow = () => {

        return (
            <button
                className="btn btn-outline-primary"

                style={{
                    width: 150,
                    float: 'right',
                    verticalAlign: 'right',

                }}

                type='button' value='add more'
                onClick={this.addClick.bind(this)}


            >Add Defferal</button>
        )

    };

    dynamicDeferral = (i) => {
        let deferalType = "deferalType" + i;
        let expireDate = "expireDate" + i;
        let defferalOther = "defferalOther" + i;
        let arrayData = [];
        /*arrayData.push({deferalType,expireDate,defferalOther});
        this.setState({
            getAllDefferal:arrayData
        })*/
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponent.date(this.state, this.updateComponent, field);
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {

        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, "", this.onKeyDownChange)
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
  delete clone[prop];*/
        return clone;
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    variableSetFunction = (object,direction) => {
        console.log(object);

        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    inputData: this.emptyValueRemove(object),
                    varValue: this.emptyValueRemove(object),
                    loading: false,
                    alowApiCall: true
                })
                if(direction==="getTermDepositAccountMaturityMalue"){
                    this.getTermDepositAccountMaturityMalue()
                }
               else if(direction==="sendingEmail"){
                    let url=backEndServerURL+"/emailAttachmentFileFdrDps/"+this.props.appId;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                        })
                        .catch((error)=>{
                            console.log(error)
                        })
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
            })

    };
    testGettermDeposite=()=>{
        let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
    }
    getTermDepositAccountMaturityMalue=()=>{
        let updateUrl = backEndServerURL + "/getTermDepositAccountFDR/" + this.state.inputData.accountNumber;
        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let inputData = this.state.inputData;
                let varValue = this.state.varValue;
                this.setState({
                    maturityAmt_amountValue:false
                })
                inputData.maturityAmt_amountValue = response.data.depositAccount.maturityAmt_amountValue;
                 this.variableSetFunction(inputData,"sendingEmail");


            })
            .catch((error) => {
                let url=backEndServerURL+"/emailAttachmentFileFdrDps/"+this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                    })
                    .catch((error)=>{
                        console.log(error)
                    })
            })

    }
    handleSubmit = (bom_approval) => {

        if (this.state.alowApiCall === true) {
            this.setState({
                alowApiCall: false,
                loading: true
            });
            this.state.inputData.temporaryButtonObjectafterAccountGenerateButtonForBom="finalApproval";
            let variableSetUrls = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrls, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

            let dependencyField = [];
            let saveDataDeferralRemarksAndRoute = {};
            if (bom_approval === "RETURN") {
                dependencyField.push({

                    "varName": "bomRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "Bom Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }

            if (this.state.inputData.bomRemarks !== undefined && this.state.inputData.bomRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bomRemarks;
            }

            this.state.inputData.bom_approval = bom_approval;
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (bom_approval !== "RETURN" && (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account" || this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account")) {
                        var alowOpeningUrl = backEndServerURL + "/TermDepositAccountAllowedForBranch/" + this.props.appId;
                        if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
                            alowOpeningUrl = backEndServerURL + "/DPSAccountAllowedForBranch/" + this.props.appId;
                        }
                        axios.get(alowOpeningUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (bom_approval === 'APPROVED' && this.state.inputData.afterAccountGenerateButtonForBom === "finalApproval") {
                                    this.renderRoutingFunction(saveDataDeferralRemarksAndRoute)
                                }
                                else if (bom_approval === 'APPROVED' && response.data === true && (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account")) {
                                    let data = {};
                                    data.digitTinChange = true;
                                    data.tin = this.state.inputData.etin;
                                    data.cbNumber = this.state.inputData.cbNumber;
                                    data.onlyTinUpdate = true;
                                    let variableSetUrl = backEndServerURL + "/casaStaticMaintenanceUpdate";
                                    axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        });


                                    let openingUrl = backEndServerURL + "/openTermDepositAccountFDRInBranch/" + this.props.appId;

                                    axios.get(openingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            let inputData = this.state.inputData;
                                            let varValue = this.state.varValue;
                                            let newAccountNumber = response.data.accountNumber;

                                            this.state.inputData.accountNumber = response.data.accountNumber;
                                            this.state.inputData.newSchemecode = response.data.schemeCode;
                                            this.state.inputData.branchingAccountNumber = response.data.accountNumber;
                                            this.state.inputData.solId = response.data.solId;
                                            this.state.inputData.recpmtid = response.data.recpmtid;
                                            this.state.inputData.afterAccountGenerateButtonForBom = "finalApproval";


                                            this.state.varValue.branchingAccountNumber = response.data.accountNumber;
                                            this.state.varValue.accountNumber = response.data.accountNumber;
                                            this.state.varValue.solId = response.data.solId;
                                            this.state.varValue.recpmtid = response.data.recpmtid;
                                            this.state.varValue.afterAccountGenerateButtonForBom = "finalApproval";


                                            inputData.accountNumber = response.data.accountNumber;
                                            inputData.newSchemecode = response.data.schemeCode;
                                            inputData.branchingAccountNumber = response.data.accountNumber;
                                            inputData.solId = response.data.solId;
                                            inputData.recpmtid = response.data.recpmtid;
                                            inputData.afterAccountGenerateButtonForBom = "finalApproval";
                                            inputData.accountsSolIds= response.data.solId;
                                            // this.state.inputData.FdrDpsApiButton= "finalApproval"
                                            // this.state.inputData.inputData.buttonCount=1;
                                            this.setState({
                                                loading: false,
                                                generateNewAccountNumber: newAccountNumber,
                                                accountDetailsModal: true

                                            });

                                            this.variableSetFunction(inputData,"getTermDepositAccountMaturityMalue")


                                            /*  this.setState({
                                                  generateNewAccountNumber:newAccountNumber,
                                                  title: "Successfull!",
                                                  notificationMessage: "Successfully Save!",
                                                  alert: true,
                                                  loading: false,
                                                  accountDetailsModal: true
                                              })*/


                                        })

                                        .catch((error) => {
                                            this.setState({
                                                alowApiCall: true,
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: this.errorObjectCheck(error)
                                            })
                                        })
                                }
                                else if (bom_approval === 'APPROVED' && response.data === true && (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account")) {
                                    let openingUrl = backEndServerURL + "/openTermDepositAccountDPSInBranch/" + this.props.appId;
                                    let data = {};
                                    data.digitTinChange = true;
                                    data.tin = this.state.inputData.etin;
                                    data.cbNumber = this.state.inputData.cbNumber;
                                    data.onlyTinUpdate = true;
                                    let variableSetUrl = backEndServerURL + "/casaStaticMaintenanceUpdate";
                                    axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        });
                                    axios.get(openingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            let inputData = this.state.inputData;
                                            let varValue = this.state.varValue;
                                            let newAccountNumber = response.data.accountNumber;
                                            console.log(response.data);
                                            this.state.inputData.newSchemecode = response.data.schemeCode;
                                            this.state.inputData.accountNumber = response.data.accountNumber;
                                            this.state.inputData.solId = response.data.solId;
                                            this.state.inputData.recpmtid = response.data.recpmtid;
                                            this.state.inputData.afterAccountGenerateButtonForBom = "finalApproval";
                                             inputData.accountsSolIds= response.data.solId;
                                            this.state.varValue.accountNumber = response.data.accountNumber;
                                            this.state.varValue.solId = response.data.solId;
                                            this.state.varValue.recpmtid = response.data.recpmtid;
                                            this.state.varValue.afterAccountGenerateButtonForBom = "finalApproval";
                                            // let countNumber=inputData.buttonCount;
                                            // inputData.FdrDpsApiButton = inputData.buttonFlow[countNumber];
                                            // inputData.buttonCount=this.buttonCount(countNumber);
                                            inputData.newSchemecode = response.data.schemeCode;
                                            inputData.accountNumber = response.data.accountNumber;
                                            inputData.solId = response.data.solId;
                                            inputData.accountsSolIds= response.data.solId;
                                            inputData.recpmtid = response.data.recpmtid;
                                            inputData.afterAccountGenerateButtonForBom = "finalApproval";
                                            this.setState({
                                                loading: false,
                                                generateNewAccountNumber: newAccountNumber,
                                                accountDetailsModal: true

                                            });


                                            this.variableSetFunction(inputData,"getTermDepositAccountMaturityMalue")

                                            /*  this.setState({
                                                  generateNewAccountNumber:newAccountNumber,
                                                  title: "Successfull!",
                                                  notificationMessage: "Successfully Save!",
                                                  alert: true,
                                                  loading: false,
                                                  accountDetailsModal: true
                                              })*/


                                        })

                                        .catch((error) => {
                                            this.setState({
                                                alowApiCall: true,
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: this.errorObjectCheck(error)
                                            })
                                        })
                                }
                                else {
                                    if ((bom_approval === "RETURN" || bom_approval === 'APPROVED')) {
                                        this.renderRoutingFunction(saveDataDeferralRemarksAndRoute)
                                    }

                                    /*
                                                else if ((bom_approval==='APPROVED' && /!*this.state.inputData.nomineeType!=="Nominee Same As Operative Account" && this.state.inputData.nomineeExistNot!=="Y" &&*!/ this.state.inputData.preventialRateChange!=="Y" && this.state.inputData.taxWaiverChange!=="Y") && ((this.state.inputData.nomineeType === "Nominee Same As Operative Account" || this.state.inputData.nomineeSameOperativeAccount === true) && (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account" || this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account")) || this.state.inputData.accountCbNumber === this.state.inputData.cbNumber) {

                                                        let updateUrl = backEndServerURL + "/openTermDepositAccountBOM/" + this.state.appId;
                                                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((response) => {
                                                                 this.state.inputData.accountNumber=response.data.accountNumber
                                                                this.state.inputData.solId=response.data.solId
                                                                this.state.varValue.accountNumber=response.data.accountNumber
                                                                this.state.varValue.solId=response.data.solId
                                                                this.forceUpdate()
                                                                console.log(response)
                                                                this.setState({
                                                                    generateNewAccountNumber:response.data.accountNumber,
                                                                    title: "Successfull!",
                                                                    notificationMessage: "Successfully Save!",
                                                                    alert: true,
                                                                    loading: false,
                                                                    accountDetailsModal: true
                                                                })

                                                                let url = backEndServerURL + "/case/route/" + this.props.appId;
                                                                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                    .then((response) => {

                                                                        this.setState({
                                                                            title: "Successfull!",
                                                                            notificationMessage: "Successfully Routed!",
                                                                            alert: true,
                                                                            loading: false
                                                                        })

                                                                    })
                                                                    .catch((error) => {
                                                                        console.log(error);
                                                                        this.setState({
                                                                            loading: false
                                                                        })
                                                                    });

                                                            })
                                                            .catch((error) => {
                                                                console.log("lllllljjkk");
                                                               // console.log(error);
                                                                this.setState({
                                                                    // title: "Successfull!",
                                                                    //notificationMessage: "Successfully Save!",
                                                                    //alert: true,
                                                                    loading: false,
                                                                    //getInitiateData: true,
                                                                    errorModalBoolean: true,
                                                                    errorTittle: this.errorObjectCheck(error)
                                                                })
                                                            })
                                                    }
                                    */

                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    alowApiCall: true,
                                    loading: false
                                })
                            })
                    }
                    else {
                        if (bom_approval === "RETURN" || bom_approval === 'APPROVED') {
                            this.renderRoutingFunction(saveDataDeferralRemarksAndRoute)
                        }
                    }


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                })
                .catch((error)=>{
                    console.log(error)
                })
        } else {

        }

    };
    renderRoutingFunction = (saveDataDeferralRemarksAndRoute) => {
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false,
                    alowApiCall: true,
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    alowApiCall: true,
                })
            });


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
            )
        }

    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,
            errorModalBoolean: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({


            uploadModal: false
        })
    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        })


    };


    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderDedupComponent = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };
    renderSdnComponent = () => {
        if (this.props.appId !== undefined) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.freeFlag3} appId={this.props.appId}/>
                </div>

            )
        }

    };


    signature = () => {
        if (this.state.getData) {
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff"}}
                      onClick={() => this.openSignature(this.state.inputData.debitAccountNumber)}> Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };
    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <div>
                                <ThemeProvider theme={theme}>
                                    <Grid container spacing={1}>
                                        {this.renderNotification()}
                                        <Grid item xs={12}>
                                            {this.signature()}
                                        </Grid>
                                        {this.returnJsonForm()}

                                        <Grid item xs={12}>
                                            <DeferalList appId={this.props.appId}/>
                                        </Grid>
                                        <Grid item={12}>

                                            <WaiverList appId={this.props.appId}/>
                                        </Grid>
                                        <Grid item xs='12'>
                                            {this.renderRemarksData()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderDedupComponent()}
                                            {this.renderDedupComponentMandateAndBeneficiary()}
                                            {this.renderSdnComponent()}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <button
                                                style={{
                                                    width: 150,
                                                }}
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={this.mappingAllImage}
                                            >
                                                Preview Document
                                            </button>
                                        </Grid>
                                        {this.renderRemarks()}
                                        <Grid item xs='12'>
                                        </Grid>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.accountStatement}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <AccountStatement closeModal={this.closeStatement}
                                                                  accountNumber={this.state.inputData.debitAccountNumber}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.errorModalBoolean}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <ErrorModal title={this.state.errorTittle}
                                                            closeModal={this.closeModal}
                                                            subServiceType={this.props.subServiceType}
                                                            appId={this.props.appId}
                                                />

                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="xl"
                                            open={this.state.getMappingAllImage}>
                                            <DialogContent>
                                                <FileMappingReadOnly appId={this.props.appId}
                                                                     closeModal={this.closeModal}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            open={this.state.accountDetailsModal}>
                                            <DialogContent>

                                                <AccountNoGenerate
                                                    dps={true}
                                                    maturityAmt_amountValue={this.state.maturityAmt_amountValue!==false? this.state.inputData.maturityAmt_amountValue:undefined}
                                                    recpmtid={this.state.inputData.recpmtid}
                                                    cbNumber={this.state.inputData.cbNumber}
                                                    schemeCode={this.state.inputData.newSchemecode}
                                                    cbAndScheme={true}
                                                    freeFlag1={this.props.freeFlag1}
                                                    generateNewAccountNumber={this.state.inputData.accountNumber}
                                                    solId={this.state.inputData.solId}
                                                    customerName={this.state.inputData.customerName}
                                                    generateNewCB={this.state.generateNewCB}
                                                    closeModal={this.accountDetailsModal}/>
                                            </DialogContent>
                                        </Dialog>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.confirmAlert}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <ConfirmAlert
                                                    validationForHandleConfirm={this.validationForHandleConfirm}
                                                    closeModal={this.closeConfirmAlert}/>

                                            </DialogContent>
                                        </Dialog>
                                        <center>
                                            {this.renderSubmitButton()}
                                        </center>
                                    </Grid>
                                </ThemeProvider>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        );
    }

}

export default withStyles(styles)(OpeningBOM);
