import React, {forwardRef} from "react";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ArrowUpward,   PictureAsPdf } from '@material-ui/icons';
 
 

let localization = {
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}',
    },
    toolbar: {
        nRowsSelected: '{0} selected',
    },
    header: {
        actions: 'Actions'
    },
    body: {
        emptyDataSourceMessage: 'No records to display',
        filterRow: {
            filterTooltip: 'Filter'
        }
    }
};

let options = {
    selection: true,
    sorting: true,
    rowStyle: rowData => ({
        backgroundColor: rowData.tableData.checked
            ? "#37b15933"
            : ""
    }),
    toolbarButtonAlignment: 'right',
    emptyRowsWhenPaging: false,
    pageSize: 100,
    selectionProps: rowData => ({
        color: 'primary'
      }),
   headerStyle: {backgroundColor: "rgb(232, 228, 227)"},
   headerStyle: {
    position: "sticky",
    top: "0",
  },
  maxBodyHeight: "400px"
    
};

let tabActive = {
    backgroundColor: "#142398",
    color: "white",
    minWidth: '50px', padding: ".92857143em 6px", fontSize: "13px",
    textAlign: "center",
    display: "block"
};

let tabInactive = {
    backgroundColor: "white",
    color: "black",
    minWidth: '50px', padding: ".92857143em 6px", fontSize: "13px",
    textAlign: "center",
    display: "block"
};

let commonInboxTabActive = {
    color: "#142398",
    cursor: "pointer",
    fontWeight: "bold",
    borderBottom: "5px solid #142398",
    paddingBottom: "15px"
};

let commonInboxTabInactive = {
    backgroundColor: "white",
    color: "black",
    cursor: "pointer",
    paddingBottom: "15px"
};

let commonInboxButton = {
    "backgroundColor": "#142398",
    "boxShadow": "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "padding": " 6px 16px",
    "font-weight": "bold",
    "line-height": "1.75",
    "border-radius": "4px",
    "letter-spacing": "0.02857em",
    "border": "none",
    "margin-left": "10px",
    "color": "#ffffff",
    "margin-bottom": "4px",
};

let tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref}/>
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref}/>
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)


     
      
      
      
       
      
      
       
       
      
};

let buttonStyle = {
    backgroundColor: "#142398",
    //backgroundColor: "#142398",
    color: "white",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    borderRadius: 3,
    border: 1
};

export {
    tabInactive,
    tabActive,
    options,
    localization,
    tableIcons,
    buttonStyle,
    commonInboxTabActive,
    commonInboxTabInactive,
    commonInboxButton
};