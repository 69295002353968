import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import Button from '@material/react-button';
import MaterialTable from 'material-table';
import {createMuiTheme} from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import loader from "../../../Static/loader.gif";
import TablePagination from "@material-ui/core/TablePagination";
import {buttonStyle, localization, options, tableIcons} from "../../DeliverableManagement/configuration";
import ConfirmationModal from "../FundTransfer/ConfirmationModal";
import {ThemeProvider} from "@material-ui/styles";
import {Menu} from "semantic-ui-react";
import CardMaintenanceRequest from "../CardCallCategory/CardMaintenanceRequest";
import ChangeTimeFormat from "../CommonComponent/ChangeTimeFormat";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '1px 4px',
            }
        },

    }
});

const filteringJsonForm = [
    {
        varName: "startDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        errorMessage: "Error",
    },

    {
        varName: "endDate",
        type: "date",
        label: "End Date",
        grid: 2,
        errorMessage: "Error",
    },
    {
        "varName": "caseId",
        "type": "text",
        "grid": 2,
        "label": "Case Id",
    },
    {
        "varName": "clientId",
        "type": "text",
        "grid": 3,
        "label": "Client Id",
    },
    // {
    //     "varName": "branchName",
    //     "type": "autoCompleteValueReturn",
    //     "grid": 2,
    //     "label": "Branch Name",
    // },
    {
        "varName": "customerCategory",
        "type": "select",
        "onKeyDown": true,
        "label": "Customer Category",
        "enum": [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        "grid": 3,
    },
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "label": "Call Category",
        "grid": 3,
    },
    {
        varName: "status",
        type: "select",
        enum: [
            "Open",
            "Locked",
            "Solved",
            "Cancelled",
            "In progress",
            "Return"
        ],
        validation: "sting",
        label: "Status",
        grid: 2,
    },
    {
        "varName": "priority",
        "type": "select",
        "label": "Priority",
        "enum": [
            "High",
            "Regular"
        ],
        "grid": 3,
    },
    {
        "varName": "executorId",
        "type": "text",
        "lowerCase": true,
        "label": "Executor Id",
        "grid": 2,
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    },
};


class UnlockComponentForLineManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            selectedDate: {},
            fileUploadData: {},
            responseData: [],
            responseActualArray: [],
            activeItem: 'All',
            backgroundColor: 'red',
            showValue: false,
            uploadModal: false,
            detailsModal: false,
            viewModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "View",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            alert: false,
            loader: null,
            dialogHeader: "",
            err: false,
            supportDocumentArray: [],
            loading: false,
            confirmationAlert: false,
            errorArray: {},
            errorMessages: {},
            autoComplete: {},
            pageNumber: 0,
            totalRow: 0,
            showData: true,
            rowsPerPage: 50,
            varValue: {},
            selectedCount: 0,
            searchItem: "",
            confirmationType: "",
            confirmationData: [],
            allData: [],
            lockedColumns: [
                {title: 'Case Id', field: 'appId',},
                {title: 'Client Id', field: 'clientId',},
                {title: 'Category', field: 'customerCategory'},
                {title: 'Call Type', field: 'calltype'},
                {title: 'Created BY', field: 'createdby'},
                {title: 'Assign To', field: 'assignto'},
                {title: 'SLA Time', field: 'slaTime'},
                {title: 'SLA Violation Status', field: 'slaViolated'},
                {title: 'Creation Date', field: 'date'},
                {title: 'Priority', field: 'priority'},
                {title: 'Modified Date', field: 'modifiedDateLine'},
                {title: 'Status', field: 'status'},
                {title: 'Action', field: 'action'},            ],
            allColumns: [
                {title: 'Case Id', field: 'appId',},
                {title: 'Client Id', field: 'clientId',},
                {title: 'Category', field: 'customerCategory'},
                {title: 'Call Type', field: 'calltype'},
                {title: 'Created BY', field: 'createdby'},
                {title: 'Assign To', field: 'assignto'},
                {title: 'SLA Time', field: 'slaTime'},
                {title: 'SLA Violation Status', field: 'slaViolated'},
                {title: 'Creation Date', field: 'date'},
                {title: 'Priority', field: 'priority'},
                {title: 'Modified Date', field: 'modifiedDateLine'},
                {title: 'Status', field: 'status'},
                // {title: 'Executor Id', field: 'currentUserName'},
                {title: 'Action', field: 'action'},
            ],
            totalPages: 0,
            pageForOnChange: 1
        };
    }

    dateConverter = (value) => {
        let date = value.split("T");
        return date[0] + " " + date[1]
    };

    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {

                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete.callCategory = callCategory;
                        this.setState({
                            autoComplete: {...this.state.autoComplete, callCategory},
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    functionForLock = (status, pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.activeItem)
        } else {
            this.setState({
                loader: true
            });

            let open_url;

            if (status === "Locked") {
                open_url = backEndServerURL + "/getAllLineManagerRequest/" + page;
            } else if (status === "All") {
                open_url = backEndServerURL + "/getCardMaintenance/All/" + page;
            } else if (status === "In Progress") {
                open_url = backEndServerURL + "/inbox/opened/In Progress/" + page;
            } else if (status === "Open") {
                open_url = backEndServerURL + "/cardClaimable/" + page;
            }

            axios.get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let array = [];
                    let rowData;
                    let responseArray = [];
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            id: response.data.content[i].id,
                            currentUserName: response.data.content[i].currentUserName,
                            clientId: response.data.content[i].clientId,
                            appId: response.data.content[i].caseId,
                            slaTime: response.data.content[i].slaViolation,
                            slaViolated: response.data.content[i].slaViolated,
                            uniqueId: response.data.content[i].appId,
                            customerCategory: response.data.content[i].freeFlag1,
                            calltype: response.data.content[i].freeFlag2,
                            createdby: response.data.content[i].initiatedBy,
                            assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                            date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                            priority: response.data.content[i].priority,
                            modifiedDateLine: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                            status: response.data.content[i].delStatus === "SAVED" ? "In Progress"
                                : response.data.content[i].delStatus === "LOCKED" ? response.data.content[i].delStatus
                                    : response.data.content[i].delStatus === "OPEN" ? response.data.content[i].delStatus
                                        : response.data.content[i].delInboxStatus,
                            action: <Button onClick={(e) => {
                                this.getRow(e, response.data.content[i], "view")
                            }} style={{
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1
                            }}>View</Button>
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData)
                    }


                    this.setState(
                        {
                            allData: array,
                            loader: false,
                            pageNumber: page,
                            responseData: responseArray,
                            totalRow: response.data.totalElements,
                            totalPages: response.data.totalPages,
                        }
                    );
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    getRow = (event, data, buttonName) => {
        event.preventDefault();
        this.open(data);
    };

    open = (data) => {
        this.setState({
            dataPassArray: data,
            viewModal: true
        })
    };

    closeModal = (value) => {
        this.setState({
            viewModal: false
        });
    };

    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.viewModal}>
                    <DialogContent>
                        <CardMaintenanceRequest appId={this.state.dataPassArray.appId}
                                                arrayValue={this.state.dataPassArray} closeModal={this.closeModal}
                                                headerValue={this.state.activeItem}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    componentDidMount() {
        this.functionForLock("All", 0);
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    headerSelect = (event, value) => {
        event.preventDefault();
        this.forceUpdate();
        this.setState({
            loader: true,
            activeItem: value,
            allData: [],
            responseData: [],
        });
        if (this.state.searchItem) {
            setTimeout(() => {
                this.functionForLock(value, 0)
            }, 300)
        } else {
            this.functionForLock(value, 0)
        }
    };

    renderFilterForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent, "", this.onKeyDownChange)
        )
    };

    searchData = (e, activeItem, page) => {
        this.setState({
            loader: true,
        });
        let status;
        if (activeItem === "Locked") {
            status = "waiting";
        } else if (activeItem === "saved") {
            status = "In Progress";
        } else {
            status = activeItem.toLowerCase();
        }

        let inbox_url;
        if (page === undefined) {
            this.setState({
                loader: true,
                pageNumber: 0
            });
            inbox_url = backEndServerURL + "/filterApplication/" + status + "/0";
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
            });
            inbox_url = backEndServerURL + "/filterApplication/" + status + "/" + page;
        }


        let postData = {
            fromDate: this.state.inputData.startDate,
            toDate: this.state.inputData.endDate,
            caseId: this.state.inputData.caseId,
            branchName: this.state.inputData.branchName,
            customerCategory: this.state.inputData.customerCategory,
            callCategory: this.state.inputData.callCategory,
            clientId: this.state.inputData.clientId,
            status: this.state.inputData.status,
            priority: this.state.inputData.priority,
            userType: "Line Manager",
            executorId: this.state.inputData.executorId
        };

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let rowData;
                for (let i = 0; i < response.data.content.length; i++) {

                    rowData = {
                        id: response.data.content[i].id,
                        currentUserName: response.data.content[i].currentUserName,
                        appId: response.data.content[i].caseId,
                        slaTime: response.data.content[i].slaViolation,
                        clientId: response.data.content[i].clientId,
                        uniqueId: response.data.content[i].appId,
                        customerCategory: response.data.content[i].freeFlag1,
                        calltype: response.data.content[i].freeFlag2,
                        createdby: response.data.content[i].initiatedBy,
                        assignto: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                        date: ChangeTimeFormat.convertTime(response.data.content[i].appInitTime),
                        modifiedDateLine: ChangeTimeFormat.convertTime(response.data.content[i].delInitTime),
                        priority: response.data.content[i].priority,
                        status: response.data.content[i].delStatus === "SAVED" ? "In Progress"
                            : response.data.content[i].delStatus === "LOCKED" ? response.data.content[i].delStatus
                                : response.data.content[i].delStatus === "OPEN" ? response.data.content[i].delStatus
                                    : response.data.content[i].delInboxStatus,
                        action: <Button onClick={(e) => {
                            this.getRow(e, response.data.content[i], "view")
                        }} style={{
                            backgroundColor: "green",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1
                        }}>{this.state.buttonName}</Button>
                    };
                    array.push(rowData)

                }


                this.setState(
                    {
                        allData: array,
                        loading: false,
                        pageNumber: this.state.pageNumber,
                        responseActualArray: response.data.content,
                        totalRow: response.data.totalElements,
                        totalPages: response.data.totalPages,
                        searchItem: this.state.activeItem,
                        loader: false,
                    }
                );
                console.log(this.state.allData)
            })
            .catch(error => {
                console.log(error);
            });
    };


    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: ' ',
                    }}>
                        <h4 style={{color: "black"}}>Card Maintenance</h4>
                    </CardHeader>
                    <CardBody style={{
                        backgroundColor: "white"
                    }}>
                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>
                                {this.renderFilterForm()}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => {
                                        this.searchData(e, this.state.activeItem)
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        display: "block",
                                        height: 30,
                                        marginTop: 20,
                                        paddingBottom: 5
                                    }}
                                    onClick={e => window.location.reload()}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>

                        </Grid>
                        <br></br>
                        <React.Fragment>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <div>
                                <Menu pointing>
                                    <Menu.Item
                                        name='All'
                                        active={activeItem === 'All'}
                                        style={activeItem === 'All' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'All')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Open'
                                        active={activeItem === 'Open'}
                                        style={activeItem === 'Open' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Open')
                                        }}
                                    />
                                    <Menu.Item
                                        name='Locked'
                                        active={activeItem === 'Locked'}
                                        style={activeItem === 'Locked' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'Locked')
                                        }}
                                    />
                                    <Menu.Item
                                        name='In Progress'
                                        active={activeItem === 'In Progress'}
                                        style={activeItem === 'In Progress' ? {
                                            backgroundColor: "red",
                                            color: "white",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        } : {
                                            backgroundColor: "white",
                                            color: "black",
                                            minWidth: 120,
                                            textAlign: "center",
                                            display: "block"
                                        }}
                                        onClick={(e) => {
                                            this.headerSelect(e, 'In Progress')
                                        }}
                                    />
                                </Menu>
                                {this.tableShow(this.state.activeItem)}
                                {this.renderNotification()}
                                {this.dialogBoxShow()}
                            </div>
                        </React.Fragment>
                    </CardBody>
                </React.Fragment>
            );
        }
    }

    unlockFunction = (event, rowData, props) => {
        let allSelectValue = (rowData, props.data);
        let rowIdArray = [];
        if (allSelectValue != null) {
            for (let i = 0; i < allSelectValue.length; i++) {
                rowIdArray.push(allSelectValue[i].uniqueId)
            }

            this.setState({
                title: "Do you want to confirm?",
                confirmationAlert: true,
                confirmationType: "Unlock",
                confirmationData: rowIdArray
            });

        }

    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loader: true,
            });

            if (this.state.confirmationType === "Unlock") {
                let single_url = backEndServerURL + "/case/unClaim/";
                axios.post(single_url, this.state.confirmationData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.functionForLock(this.state.activeItem, 0)
                    }).catch((error) => {
                    console.log(error)
                })
            }


        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    rowColor = (rowData) => {
        if (rowData.slaViolated === "YES") {
            return {backgroundColor: "rgba(249, 0, 11, 0.3)"};
        } else if (rowData.tableData.checked) {
            return {backgroundColor: "rgba(55, 177, 89, 0.2)"};
        }
    };


    tableShow = (value) => {
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={this.state.loader}>
                    <DialogContent>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (value === "All") {
            return (
                <div>
                    <ThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    console.log("clicked");
                                                    this.functionForLock("All", page)
                                                }
                                                }
                                            />
                                        </>
                                    ),
                                }}

                            />
                        </div>
                    </ThemeProvider>
                </div>
            )
        } else if (value === "Open") {
            return (
                <div>
                    <ThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={""}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    console.log("clicked");
                                                    this.functionForLock("Open", page)
                                                }
                                                }
                                            />
                                        </>
                                    ),
                                }}
                            />
                        </div>
                    </ThemeProvider>
                </div>
            )
        } else if (value === "Locked" || value === "In Progress") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: '100%'}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.lockedColumns}
                                data={this.state.allData}
                                title={this.state.activeItem + " List"}
                                options={{
                                    ...options,
                                    maxBodyHeight: "450px",
                                    pageSize: this.state.rowsPerPage,
                                    rowStyle: rowData => {
                                        return this.rowColor(rowData)
                                    }
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <>
                                            <TablePagination
                                                {...props}
                                                rowsPerPageOptions={[this.state.rowsPerPage]}
                                                count={this.state.totalRow}
                                                page={this.state.pageNumber}
                                                onChangePage={(e, page) => {
                                                    this.functionForLock("Locked", page)
                                                }
                                                }
                                            />
                                        </>
                                    ),
                                    Actions: props => (
                                        <>
                                            <Button style={buttonStyle}
                                                    onClick={(event, rowData) => this.unlockFunction(event, rowData, props)}
                                            >
                                                Unlock
                                            </Button>
                                        </>
                                    ),

                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        }
    };


}

export default withStyles(styles)(UnlockComponentForLineManager);
