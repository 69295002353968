import React, {Component} from "react";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";

class FunctionForGetAcDetails extends Component {
    static getAccountInfo(acNumber) {
        let url = backEndServerURL + "/GetCustomAcctDetailsRequest/" + acNumber;
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                resolve(response)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default FunctionForGetAcDetails
