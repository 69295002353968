import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Components/Grid/GridItem.jsx";
import axios from 'axios';
import {backEndServerURL} from '../../Common/Constant';
import GridContainer from "../../Components/Grid/GridContainer.jsx";
import Table from "../../Components/Table/Table";
import Card from "../../Components/Card/Card.jsx";
import Button from '@material-ui/core/Button';
import CardHeader from "../../Components/Card/CardHeader.jsx";
import CardBody from "../../Components/Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Notification from "../NotificationMessage/Notification";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    fab: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    }
};

class ViewUserRole extends Component {


    constructor(props) {
        super(props);
        this.state = {
            addRole: false,
            roleView: false,
            addUser: false,
            unAssign: false,
            userView: false,
            tableData: [[" ", " "]],
            roleId: '',
            alert: false,
            redirectLogin: false,
        };


    }


    createTableData = (id, username, authority, removeIndex) => {
        return ([id, username, authority,
            <Button onClick={() => this.unassigned(id, removeIndex)} color="primary">
                Unassigned User
            </Button>,

        ]);

    };
    notification = () => {
        if (this.state.alert) {

            return (<Notification stopNotification={this.stopNotification} title="Unassigned User!!!"
                                  message="successfully Unassigned!!"/>)
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    unassigned = (id, removeIndex) => {


        let url = backEndServerURL + "/user/unassignRole/" + id;

        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (this.state.tableData !== undefined) {
                    console.log("inside ta");


                    for (let i in this.state.tableData) {
                        if (this.state.tableData[i][0] === id) {
                            this.state.tableData.splice(i, 1);
                            this.forceUpdate();
                        }
                    }
                }
                this.setState({alert: true})
            })

            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
    };

    closeModal = () => {
        this.setState({
            addRole: false,
            addUser: false,
            roleView: false,
            userView: false,
        });
    };

    componentDidMount() {

        let url = backEndServerURL + "/users/getByRoleId/" + this.props.id;

        const tableData = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({roles: response.data});
                response.data.map((role, removeIndex) => {
                    //console.log();
                    tableData.push(this.createTableData(role.id, role.username, role.authority, removeIndex));

                });


                this.setState({
                    tableData: tableData
                });


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }

    render() {
        {

            Functions.redirectToLogin(this.state)

        }

        const {classes} = this.props;
        return (
            <>
                {this.notification()}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Role user View </h4>

                            </CardHeader>

                            <CardBody>

                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Id", "user name", "Role Name", "Unassign "]}

                                    tableData={this.state.tableData}
                                    tableAllign={['left', 'left', 'left', 'left']}
                                /><br/><br/><br/>


                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </>
        );
    }
}

export default withStyles(styles)(ViewUserRole);
