import React from "react";
import Table from "../../Table/Table";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import Functions from "../../../Common/Functions";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import {AbhApprovalJsonFormForAgentIndividual} from '../WorkflowJsonForm4';
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import loader from "../../../Static/loader.gif";

let abhApprovalRemarks = [
    {
        "varName": "abhApprovalRemarks",
        "type": "textArea",
        "label": "Abh Approval Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class AbhApproval extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: true,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false

    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };
    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    componentDidMount() {
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                response.data.map((data) => {

                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error)
            });
        var remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log("llll");
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Waiting For Approval"
                                }

                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            this.setState({
                                getDeferalList: tableArray
                            })


                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    console.log(response.data);
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: response.data,
                        inputData: response.data,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    /* if(error.response.status===452){
                         Functions.removeCookie();

                         this.setState({
                             redirectLogin:true
                         })

                     }*/
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };
    handleSubmit = (event, data) => {
        event.preventDefault();

        var appId = this.props.appId;
        var approval = data;
        console.log(appId);
        console.log(approval);
        let deferalUrl = backEndServerURL + "/deferral/approval";
        axios.post(deferalUrl, {
            appId,
            approval
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        console.log(this.state.inputData);
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.abhApprovalRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });

        this.state.inputData.deferal_approval = data;
        this.state.inputData.abs_deferal = "NO";
        this.state.inputData.abhApprovalRemarks = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log(error);

                    });

            })
            .catch((error) => {
                console.log(error)
            });


    };


    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, AbhApprovalJsonFormForAgentIndividual, this.updateComponent)

            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, abhApprovalRemarks, this.updateComponent)

            )
        }

    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVE")}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "REJECT")}
                    >Return
                    </button>
                </div>


            )
        }
    };

    renderImageLink = () => {

        if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>


                    <div>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            open={this.state.getMappingAllImage}>
                            <DialogContent>
                                <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>

                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>
                                {this.renderNotification()}
                                {this.renderForm()}
                            </Grid>
                            <Grid item={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <br/>
                            <Grid item={12}>

                                {this.renderRemarksData()}
                            </Grid>

                            <br/>
                            <Grid item xs={12}>
                                <button
                                    style={{
                                        width: 120,

                                    }}
                                    className="btn btn-outline-primary btn-sm"

                                    onClick={this.mappingAllImage}

                                >
                                    Assigned Image
                                </button>
                            </Grid>
                            <br/>
                            {this.renderRemarks()}
                            <br/>
                            <div>
                                {this.renderSubmitButton()}
                            </div>
                        </ThemeProvider>

                    </div>


                </GridItem>
            </GridContainer>
        );


    }


}

export default withStyles(styles)(AbhApproval);
