import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL, frontEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../../Static/loader.gif";
import Label from "../static/Label";
import ImageCrop from "../../CASA/ImageCrop";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import DedupResultFunction from "../../../DedupResultFunction";
import {CBDataJsonForm} from "../../WorkflowJsonForm4";
import SdnResultFunction from "../../../SdnResultFunction";
import SecondaryCbFormDetails from "../../CASA/SecondaryCbFormDetails";
import ExistingMandateDetails from "../static/ExistingMandateDetails";
import CBNotFound from "../../CASA/CBNotFound";
import CardHeader from "../../../Card/CardHeader";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureCardDelete from "../static/SignatureCardDelete";

let jsonArrayForMod = [
    {
        "varName": "mandateOption",
        "type": "text",
        "label": "Mandate/Related Party Add or Remove",
        "readOnly": true,
        "grid": 6,
    },
    {
        "varName": "numberOfNewMandate",
        "type": "text",
        "label": " Number of New Mandate/Beneficial Owner/Signatory",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "mandateOption",
        "conditionalVarValue": "Add Mandate/Related Party",
    },
    {
        "varName": "schemeCodeChangemodeOfOperation",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Mode of Operation",
        "grid": 6,
    },
];

let dataArray = {};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "required": true,
        "label": "Remarks",
        "grid": 12
    }]
;


class VerifyDocumentBOM2 extends React.Component {
    state = {
        message: "",
        getData: false,
        varValue: {},
        selectedDate: {},
        title: "",
        notificationMessage: "",
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: true,
        imageCropModal: false,
        getMappingCropImage: false,
        nationalityApiData: [],
        objectForDedup: [],
        objectForExistingMandate: [],
        dedupForm: false,
        currentTask: "",
        disabled: false,
        getTagList: [],
        tagCbDetailsModal: false,
        tagCb: "",
        mandateTableArray: [],
        mandateModal: false,
        existingMandateCb: 0,
        requestType: "",
        despatchmodeList: [],
        linkrelationCode: 0,
        customerNumber: 0,
        confirmationAlert: false,
        submitButtonData: "",
        tagCbNumber: 0,
        getTagData: false,
        signatureDeleteButton: false
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = jsonArrayForMod;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                jsonArrayForMod[i].enum = getValue;

            }
        }
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        if (value !== undefined && value !== null && Array.isArray(jsonArray) && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                console.log(jsonObject);
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }


    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let codeUrl = backEndServerURL + "/finMasterData/modOfOperation";
                    axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((masterDataResponse) => {
                            this.findByVarNameApiData("schemeCodeChangemodeOfOperation", masterDataResponse.data);
                            this.setState({
                                despatchmodeList: masterDataResponse.data
                            });

                            dataArray = response.data.existingMandateFlag ? response.data.existingMandateFlag : {};
                            this.renderExistingMandateApi(response.data.accountNumber, dataArray, response.data.checker_approval);
                            let inputData = response.data;
                            let tableArrayData = [];
                            let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
                            axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    response.data.map((data, index) => {
                                        tableArrayData.push(this.createTaggingData(data));

                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });


                            let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                            axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            console.log(response.data);
                                            this.setState({
                                                getImageLink: response.data,
                                                getImageBoolean: true
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                    console.log(response.data);
                                    let tableArray = [];
                                    var status = "";
                                    response.data.map((deferal) => {
                                        if (deferal.status === "ACTIVE") {
                                            status = "Approved By BM"
                                        }
                                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                                    });
                                    this.setState({
                                        getDeferalList: tableArray
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                            console.log(response.data);
                                            response.data.map((data) => {

                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(response.data.modeOfOper, this.state.despatchmodeList);

                            this.setState({
                                getTagList: tableArrayData,
                                getTagData: true,
                                varValue: this.removeNullValue(inputData),
                                inputData: this.removeNullValue(inputData),
                                getData: true,
                                showValue: true,
                                currentTask: response.data.currentTask
                            });

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error);

                });

        }

    }

    renderExistingMandateApi = (acNumber, value, checker_approval) => {
        let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + acNumber;
        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let mandateTableArray = [];
                    if (response.data.relPartyList) {
                        response.data.relPartyList.map((mandate, index) => {
                            if (mandate.relPartyType !== "M") {
                                let exFlag;
                                if (mandate.recDelFlg === "Y") {
                                    exFlag = "CB Deleted"
                                } else if (mandate.recDelFlg === "N") {
                                    exFlag = "CB Existed"
                                } else {
                                    exFlag = mandate.recDelFlg;
                                }
                                mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, mandate.relPartyTypeDesc, this.triggerExistingMandate(mandate.custId), exFlag, this.triggerExistingMandateForDelete(mandate.custId, index, mandate.recDelFlg, value, checker_approval)))
                            }
                        });
                    }
                    if (value !== undefined) {
                        this.setState({
                            mandateTableArray: mandateTableArray,
                            loading: false
                        })
                    } else {
                        this.setState({
                            mandateTableArray: mandateTableArray,
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                })

            });
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };


    onKeyDownForDedup = (event, data) => {


    };

    onKeyDownChange = (data) => {

    };


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createTaggingData = (data) => {
        return (
            [data.cbNumber, data.customerName, data.relationCode, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.cbNumber, data.relationCode, data.requestType)}
            >Edit
            </button>, <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                    marginLeft: "10px"
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDelete(event, data.id)}
            >Delete
            </button>]]

        )
    };

    tagCbDelete = (event, id) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/secondaryCB/delete/" + id;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArrayData = [];
                let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
                axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        response.data.map((data, index) => {
                            tableArrayData.push(this.createTaggingData(data));
                        });
                        this.setState({
                            loading: false,
                            getTagList: tableArrayData,
                        });
                        this.forceUpdate();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(error);
                    })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error);
            })

    };

    tagCbDetailsModal = (event, cb, cbNumber, relationCode, requestType) => {
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb,
            tagCbNumber: cbNumber,
            requestType: requestType,
            customerNumber: cb,
            linkrelationCode: relationCode
        })

    };

    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false
        })
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });

            let data = this.state.submitButtonData;
            this.state.inputData.existingMandateFlag = dataArray;
            let postData = this.state.inputData;
            postData.maker_approval = data;
            let remarksData = {};
            remarksData.remark = this.state.inputData.makerRemarks;
            remarksData.map = {...postData, makerRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    disabled: false,
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };


    handleSubmit = (event, data) => {
        event.preventDefault();
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(makerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return false;
            }
        }
        this.setState({
            submitButtonData: data,
            title: "Do you want to confirm?",
            confirmationAlert: true
        });
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };

    handleSubmitDraft = () => {
        this.setState({
            disabled: true,
            loading: true,
        });
        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        let data = this.state.inputData;
        data.makerSaveFlag = true;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Draft!",
                    alert: true,
                    disabled: false,
                    loading: false,
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false,
                });
            });
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <>
                    <br/>
                    {/*<Grid item xs={12}>*/}
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'

                        onClick={(event) => this.handleSubmit(event, "APPROVED")}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'

                        onClick={this.handleSubmitDraft}
                    >Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}

                    >Return
                    </button>
                    {/*</Grid>*/}
                </>

            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)
            )
        }
    };

    cropImage = (event) => {
        event.preventDefault();
        this.setState({
            imageCropModal: true
        })
    };

    renderSignatureCardCrop = () => {
        if (this.state.getData) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }
    };

    renderCropedImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Signature Card
                </button>

            )
        }
    };
    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };
    closeCropImage = () => {
        this.setState({
            getMappingCropImage: false
        })
    };

    closeModal = (account) => {
        this.setState({
            renderCumModalopen: false,
            getMappingCropImage: false,
            errorModalBoolean: false,
            imageCropModal: false,
            imageModalBoolean: false
        });
        this.renderImageCrop()
    };

    renderImageCrop = () => {
        if ((backEndServerURL + "/signaturePhoto/" + this.props.appId + "/SIGNATURE CARD" !== undefined)) {
            console.log(backEndServerURL + "/signaturePhoto/" + this.props.appId + "/SIGNATURE CARD");
            return (

                <img width='100%' src={backEndServerURL + "/signaturePhoto/" + this.props.appId + "/SIGNATURE CARD"}
                     alt=""/>
            )
        }


    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    signature = () => {
        if (this.state.getData) {
            // return (
            //     <>
            //         <br/>
            //         <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}
            //                          signatureType="multiple"/>
            //     </>
            // )
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff", "margin-top": "15px"}}
                      onClick={() => this.openSignature(this.state.inputData.accountNumber)}>Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };

    renderModOfOperationField = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonArrayForMod, this.updateComponent);
        }
    };

    renderLabel = () => {
        if (this.state.getData) {
            return (
                <>
                    <Label stopLoading={this.stopLoading} accountNumber={this.state.inputData.accountNumber}
                           cbNumber={this.state.inputData.cbNumber} style={{margin: "0 auto"}} classes={this.props}
                           crmStatus={this.state.inputData.crmStatus}/>
                </>
            )
        }
    };

    renderTagging = () => {
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));

                })
            })
            .catch((error) => {
                console.log(error);
            });

        this.setState({
            getTagList: tableArrayData
        });
        this.forceUpdate();


    };
    getTaggingData = () => {
        this.setState({
            getTagData: false
        });
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    getTagList: tableArrayData,
                    getTagData: true
                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            });
        this.forceUpdate();
    };

    renderDedupComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                         processType="Mandate"
                                         removingTaggingData={this.removingTaggingData}
                                         taging={this.renderTagging}
                                         renderTagging={this.renderTagging}
                                         getTaggingData={this.getTaggingData}
                                         jointAccountCustomerNumber={this.state.inputData.numberOfNewMandate}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };

    renderTagListPrevious = () => {
        if (this.state.getTagData && this.state.getTagList.length > 0) {
            return (
                <div style={{marginTop: 40, marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>
                        </CardHeader>
                    </paper>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}
                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left']}
                    />
                </div>
            )
        }
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Related Party:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Designation Code", "Action", "Related CB Status", "Delete"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createMandateTableData = (sl, cb, name, relationship, designation, action, existingMandateDelete, del) => {

        return ([
            sl, cb, name, relationship, designation, action, existingMandateDelete, del
        ])

    };

    triggerExistingMandate = (cbNumber) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.triggerExistingMandateDialog(cbNumber)}
            >
                Edit
            </button>
        )
    };

    triggerExistingMandateForDelete = (cbNumber, index, selectedValue, value, checker_approval) => {
        let existingSelect;
        if (checker_approval !== undefined) {
            existingSelect = value[cbNumber]
        } else {
            existingSelect = selectedValue;
            dataArray[cbNumber] = selectedValue;
        }
        return (
            <select name={cbNumber} id={cbNumber}
                    onChange={() => this.functionForMandateDelete(cbNumber)}>
                <option value="Y" selected={existingSelect === "Y"}>Y</option>
                <option value="N" selected={existingSelect === "N"}>N</option>
            </select>
        )
    };

    functionForMandateDelete = (id) => {
        dataArray[id] = document.getElementById(id).value;
        this.state.inputData.existingMandateFlag = dataArray;
        this.forceUpdate();
    };

    triggerExistingMandateDialog = (cbNumber) => {
        this.setState({
            mandateModal: true,
            existingMandateCb: cbNumber
        });
    };

    checkListClose = () => {
        this.setState({
            mandateModal: false,
        });
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        });
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    renderSignatureCardDelete = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={this.handleDeleteSignature}
                >Delete Existing Signature
                </button>

            )
        }
    };

    handleDeleteSignature = () => {
        this.setState(prev => ({
            signatureDeleteButton: !prev.signatureDeleteButton
        }))
    };

    handleSignatureDelete = (inputValue, selectedArray) => {
        this.setState(prev => ({
            inputData: {...inputValue, signatureDeleteFlag: selectedArray},
            signatureDeleteButton: false
        }));
        console.log(this.state.inputData);
    };

    render() {

        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <SecondaryCbFormDetails customerNumber={this.state.tagCb} appId={this.props.appId}
                                                    relationCode={this.state.linkrelationCode}
                                                    processType="Mandate"
                                                    customerName={this.state.inputData.customerName}
                                                    accountNumber={this.state.inputData.account}
                                                    solId={this.state.inputData.solid}
                                                    freeFlag4={this.state.inputData.schemeCode}
                                                    renderTagging={this.getTaggingData}
                                                    requestType={this.state.requestType} makerEnd={true}
                                                    cbNumber={this.state.tagCbNumber}
                                                    tagCb={this.state.tagCb} tagForm={CBDataJsonForm}
                                                    closeModal={this.tagCbDetailsModalClose}/>
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.imageCropModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ImageCrop subServiceType={this.props.subServiceType}
                                       appId={this.props.appId}
                                       closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.getMappingCropImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage subCategory={this.props.subCategory}
                                               serviceType={this.props.serviceType}
                                               subServiceType={this.props.subServiceType}
                                               appId={this.props.appId}
                                               closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmationAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmation}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.mandateModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ExistingMandateDetails closeModal={this.checkListClose} appId={this.props.appId}
                                                    cbNumber={this.state.existingMandateCb}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.signatureDeleteButton}>
                        <DialogContent className={classes.dialogPaper}>
                            <SignatureCardDelete
                                editMode={true}
                                accountNumber={this.state.inputData.accountNumber}
                                inputData={this.state.inputData}
                                handleSignatureDelete={this.handleSignatureDelete}
                                handleDeleteSignature={this.handleDeleteSignature}
                            />
                        </DialogContent>
                    </Dialog>
                    {this.renderLabel()}
                    {this.signature()}
                    <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {this.renderModOfOperationField()}
                    </ThemeProvider>
                    </Grid>
                    <Grid item={12}>
                        {this.renderMandateData()}
                    </Grid>
                    {this.renderDedupComponent()}
                    {this.renderSdnComponent()}
                    <Grid item xs={12}></Grid>
                    {this.renderTagListPrevious()}
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderFlowSummeryButton()}
                        </Grid>
                        {this.renderDefferalData()}
                        {this.renderRemarksData()}
                        <div style={{marginTop: "5px"}}>
                            {this.renderSignatureCardCrop()}
                            &nbsp;
                            {this.renderCropedImage()}
                            &nbsp;
                            {this.renderSignatureCardDelete()}
                        </div>
                        {this.renderNotification()}
                        {this.renderRemarks()}
                        {this.renderSubmitButton()}
                    </ThemeProvider>
                </div>
            </GridList>
        )

    }
}

export default withStyles(styles)(VerifyDocumentBOM2);
