function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}


let CsPartialPaymentJsonFrom = [
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        required: true,
        "enum": [],
        "onKeyDown": true,
        label: "Loan Accounts",
        grid: 3,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "casaAccountStatus",
        "type": "text",
        "label": "Casa Account Status",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "disbursementDate",
        "type": "date",
        "label": "Account Open Date",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Fund Availability",
        "type": "text",
        "varName": "fundAvailability",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Partial Payment Amount",
        "type": "textApiCall",
        validation: "numeric",
        "varName": "partialPaymentAmount",
        "grid": 2,
        "required": true,
    },
    {

        "label": "Rate of PF",
        type: "select",
        "onKeyDown": true,
        "varName": "rateOfPf",
        "grid": 2,
        "enum": [
            "0",".5","1", "2", "3", "4"
        ],
        "required": true,
        "conditional": true,
        "conditionalVarName": "showRateOfPf",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "fee",
        "type": "text",
        "label": "Fee",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfpfCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "vat",
        "type": "text",
        "label": "Vat",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "rateOfpfCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Mention Changes",
        "type": "select",
        "varName": "mentionChanges",
        "grid": 2,
        "enum": [
            "Reduce EMI Size",
            "Reduce Tenor"
        ],
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12,
    },

];

let CsPartialPaymentJsonFromReadOnly = {};
CsPartialPaymentJsonFromReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(CsPartialPaymentJsonFrom)));


export {
    CsPartialPaymentJsonFrom,
    CsPartialPaymentJsonFromReadOnly
};