import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import Table from "../../Table/Table";
import Signature from "./Signature";
import {
    linkAccountChangeRequestReadOnly,
    lockerJsonFormOneReadOnly,
    lockerJsonFormThreeReadOnly,
    lockerJsonFormTwoReadOnly,
    siDateChangeRequestReadOnly
} from '../WorkflowJsonFormRashed';
import MyValidation from "../../JsonForm/MyValidation";
import GridList from "@material-ui/core/GridList";

let csRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12,
        "required": true,
    }];

class MakerLockerMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getData: false,
            signature: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            getRemarks: []
        }


    }


    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.makerRemarks = null;

                this.setState({
                    inputData: this.copyJson(response.data),
                    varValue: response.data,
                    getData: true
                });


            })
            .catch((error) => {
                console.log(error);
            });

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            });

        var remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            response.data.map((data) => {
                if (data.remarks !== 'undefined') {
                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                }
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })

    }

    renderExistingNomineeForm = () => {

        if (this.state.getData) {
            var sl;
            var objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfOldNominee; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Existing  Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeNameOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Name",
                        "grid": 6,
                        "readOnly": true
                    },

                    {
                        "varName": "nomineeNameNew" + sl,
                        "type": "text",
                        "label": "New Nominee Name",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "dob1Old" + sl,
                        "type": "date",
                        "label": "Existing D.O.B",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "dob1New" + sl,
                        "type": "date",
                        "label": "New D.O.B",
                        "grid": 6,
                        "readOnly": true
                    },

                    {
                        "varName": "percentageNomineeOld" + sl,
                        "type": "text",
                        "label": "Existing Percentage %",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "percentageNomineeNew" + sl,
                        "type": "text",
                        "label": "New Percentage %",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "nomineeMinorOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Minor",
                        "grid": 6,
                        "readOnly": true
                    },
                    {
                        "varName": "guardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true

                    },

                    {
                        "varName": "guardianCode" + sl,
                        "type": "text",
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },

                    {
                        "varName": "address4" + sl,
                        "type": "text",
                        "label": "Address",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },

                    {
                        "varName": "cityCode2" + sl,
                        "type": "select",
                        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
                        "label": "City Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },

                    {
                        "varName": "stateCode2" + sl,
                        "type": "text",
                        "label": "State Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },

                    {
                        "varName": "postalCode2" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },

                    {
                        "varName": "country2" + sl,
                        "type": "select",
                        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
                        "label": "Country",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinorOld" + sl,
                        "conditionalVarValue": "Yes",
                        "readOnly": true
                    },
                    {
                        "varName": "nomineeMinorNew" + sl,
                        "type": "text",
                        "label": "New Nominee Minor",
                        "grid": 6,
                        "readOnly": true
                    },
                )

            }

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)

            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    LockerSearchOne = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormOneReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };

    LockerSearchTwo = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormTwoReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };

    LockerSearchThree = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormThreeReadOnly, this.updateComponent)
                        }
                    </Grid>

                </Grid>
            )
        }
    };


    renderNewNominee = () => {
        if (this.state.inputData.numberOfNewNominee > 0) {
            var sl;
            var objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNewNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "New Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "dob1" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        required: true,
                    },


                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        required: true,
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["Yes", "No"],
                        "grid": 6,
                        required: true,
                    },
                )

            }

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)

            )
        }
    };

    linkAccount = () => {
        if (this.state.getData && this.state.inputData.linkAccountChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, linkAccountChangeRequestReadOnly, this.updateComponent)
                    }
                </ThemeProvider>
            )
        }
    };

    changeNomineeRequest = () => {
        if (this.state.getData && this.state.inputData.nomineeChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        this.renderExistingNomineeForm()
                    }

                    {
                        this.renderNewNominee()
                    }
                </ThemeProvider>
            )
        }
    };

    changeSiDateRequest = () => {
        if (this.state.getData && this.state.inputData.siDateChangeRequest === true) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, siDateChangeRequestReadOnly, this.updateComponent)
                    }
                </ThemeProvider>
            )
        }
    };


    approveHandler = (e) => {
        // alert(this.state.inputData.lockerNumber)
        e.preventDefault();

        this.state.inputData.maker_update_all_info_send_to = "CHECKER";
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data)
                })
                    .catch((error) => {
                        console.log(error)
                    });
                this.state.inputData.makerRemarks = undefined;

                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });
                        this.props.closeModal()
                        //

                    })
                    .catch((error) => {
                        console.log(error);

                    });

            })
            .catch((error) => {
                console.log(error)
            });
    };

    returnHandler = (e) => {
        e.preventDefault();

        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {

            this.state.inputData.maker_update_all_info_send_to = "CS";
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;
                    axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                        console.log(response.data)
                    })
                        .catch((error) => {
                            console.log(error)
                        });
                    this.state.inputData.makerRemarks = undefined;

                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true
                            });
                            this.props.closeModal()
                            //

                        })
                        .catch((error) => {
                            console.log(error);

                        });
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };


    render() {
        console.log('MakerLockerMaintance');
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>

                        <CardBody>

                            <Grid container>

                                {
                                    this.LockerSearchOne()
                                }

                                {
                                    this.linkAccount()
                                }

                                {
                                    this.LockerSearchTwo()
                                }

                                {
                                    this.changeNomineeRequest()
                                }

                                {
                                    this.LockerSearchThree()
                                }

                                {
                                    this.changeSiDateRequest()
                                }


                                {
                                    this.renderNotification()
                                }
                                <Grid item xs={12}>

                                </Grid>

                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        width: '140px'
                                    }}
                                    type="submit"
                                    onClick={this.signatureOpen}


                                >
                                    Signature Card

                                </button>

                                <Grid item xs={12}>

                                </Grid>

                                <Dialog
                                    fullWidth="true"
                                    maxWidth="md"
                                    open={this.state.signature}>
                                    <DialogContent>
                                        <Signature closeModal={this.signatureClose} appId={this.props.appId}/>
                                    </DialogContent>
                                </Dialog>
                                <br/>
                                <Grid item xs='12'>
                                    {this.renderRemarksData()}
                                </Grid>

                            </Grid>
                            <br/>
                            <ThemeProvider theme={theme}>
                                <Grid container>
                                    {this.renderRemarks()}

                                </Grid>
                            </ThemeProvider>


                            <center>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                    }}
                                    onClick={this.approveHandler}
                                    type="submit"

                                >
                                    Submit

                                </button>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginLeft: '10px'
                                    }}
                                    onClick={this.returnHandler}
                                    type="submit"

                                >
                                    Return

                                </button>
                            </center>
                        </CardBody>


                    </Card>
                </div>
            </GridList>

        )
    }

}

export default MakerLockerMaintenance;
