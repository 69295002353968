import React from 'react';
import validator from "validator";

class MyValidation {
    static defaultValidation(jsonForm, state) {
        let error = false;
        jsonForm.map((field) => {
            let localError = false;
            let getData = state.inputData[field.varName];

            if (field.required) {
                if (field.conditional === true) {
                    if (state.inputData[field.conditionalVarName] === field.conditionalVarValue) {
                        if (getData === undefined || getData === null) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        } else if (validator.isEmpty(getData)) {
                            localError = true;
                            state.errorMessages[field.varName] = "Required";
                            console.log(field.varName);
                            error = true;
                        }
                    } else {
                        return;
                    }
                } else {
                    if (getData === undefined || getData === null) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        //console.log(field.varName);
                        error = true;
                    } else if (getData !== undefined && getData !== null && validator.isEmpty(getData)) {
                        localError = true;
                        state.errorMessages[field.varName] = "Required";
                        //console.log(field.varName);
                        error = true;
                    }
                }
            }

            if (field.validation !== undefined && field.validation === "text") {
                if (getData !== undefined && getData !== null && (
                    !validator.isAlpha(getData))
                ) {
                    localError = true;
                    state.errorMessages[field.varName] = "Required Alpha Value";
                    error = true;
                }

            }

            if (field.validation !== undefined && field.validation === "numeric") {
                if (getData !== undefined && getData !== null && (
                    !validator.isNumeric(getData))
                ) {
                    localError = true;
                    state.errorMessages[field.varName] = "Required Numeric Value";
                    error = true;
                }

            }

            if (field.length !== undefined) {
                if (getData === undefined || getData === null) {

                    localError = true;
                    state.errorMessages[field.varName] = "Length";
                    //console.log(field.varName);
                    error = true;
                } else if (getData !== undefined && getData !== null && !validator.isLength(getData, {
                    min: field.length,
                    max: field.length
                })) {
                    localError = true;
                    state.errorMessages[field.varName] = "Length";
                    console.log(field.varName);
                    error = true;
                }
            }

            if (field.validation !== undefined && field.validation === "nid") {
                if (getData === undefined || getData === null) {

                    localError = true;
                    state.errorMessages[field.varName] = "NID";
                    //console.log(field.varName);
                    error = true;
                } else if (getData !== undefined && getData !== null && (
                    !validator.isLength(getData, {min: 10, max: 10})) && !validator.isLength(getData, {
                    min: 13,
                    max: 13
                }) && !validator.isLength(getData, {min: 17, max: 17})
                ) {
                    localError = true;
                    state.errorMessages[field.varName] = "NID";
                    console.log(field.varName);
                    error = true;
                }
            }

            if (field.validation !== undefined && field.validation === "phone") {
                if (getData === undefined || getData === null) {

                    localError = true;
                    state.errorMessages[field.varName] = "Phone";
                    //console.log(field.varName);
                    error = true;
                } else if (getData !== undefined && getData !== null && (
                    !validator.isLength(getData, {min: 11, max: 11}) || !validator.isNumeric(getData))) {
                    localError = true;
                    state.errorMessages[field.varName] = "Phone";
                    console.log(field.varName);
                    error = true;
                }
            }


            state.errorArray[field.varName] = localError;
            if (!localError) {
                state.errorMessages[field.varName] = ""
            }
        });

        return error;
    }
}

export default MyValidation;