import React, {Component} from "react";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';

import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import AccountNoGenerate from "./AccountNoGenerate";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

function makeReadOnlyObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {

            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;
    }

}

let fileUpload = [
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateDob",
        "type": "text",
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianDetailsBankRelationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGurdianCifNumber",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGuardianSelection",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNationalIdCardnumber",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "text",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "countryOfIssue",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "text",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNumber",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "placeOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "countryOfBirthfreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],

        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residingCountry",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "nonResident",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "minorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTinNumber(PAN/GIR No)",
        "type": "text",
        "label": "E-Tin Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "varName": "professionEmploymentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "nameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "monthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "contactNumber1",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField3",
        "type": "text",
        "label": "Mailing Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressField3",
        "type": "text",
        "label": "Home Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressField3",
        "type": "text",
        "label": "Work Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "deleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
];
let selectFileName = {
    "varName": "fileName",
    "type": "dropdown",
    "required": true,
    "label": "This photo name",

    "grid": 6
};

class CheckerTagCBDetails extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        multipleScanningphotoShow: null,
        inputData: {},
        dropdownSearchData: {},
        selectedDate: {},

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    close = () => {
        this.props.closeModal();
    };


    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >CB Details<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <br/>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.accountNo}>
                                <DialogContent className={classes.dialogPaper}>
                                    <AccountNoGenerate taging={this.props.taging}
                                                       tagingModalCbnumber={this.props.tagingModalCbnumber}
                                                       closeModal={this.closeModal}/>

                                </DialogContent>
                            </Dialog>
                            <ThemeProvider theme={theme}>
                                <div>
                                    <Grid container spacing={3}>
                                        {CommonJsonFormComponent.renderJsonForm(this.state, makeReadOnlyObject(fileUpload), this.updateComponent)}

                                    </Grid>
                                </div>


                            </ThemeProvider>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(CheckerTagCBDetails);