import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import loader from '../../../Static/loader.gif';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import AutoCompleteComplete from "../../JsonForm/AutoCompleteComponent";
import ErrorModal from "./ErrorModal";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ConfirmationModal from "../FundTransfer/ConfirmationModal";
import SelectComponent from "../../JsonForm/SelectComponent";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});
const arrayListIndividual = [

    {label: '1'},
    {label: '2'},
    {label: '3'},
    {label: '4'},
    {label: '5'},
    {label: '6'},
    {label: '7'},
    {label: '8'},
    {label: '9'},
    {label: '10'},
    {label: '11'},
    {label: '12'},
    {label: '13'},
    {label: '14'},
    {label: '15'},
    {label: '16'},
    {label: '17'},
    {label: '18'},
    {label: '19'},
    {label: '20'},
    {label: '21'},
    {label: '22'},
    {label: '23'},
    {label: '24'},
    {label: '25'},


];
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".pdf",
    "label": "Scan and Upload File",
    "grid": 12
};

var rotateOption = {
    "type": "select",
    "label": "Rotate",
    "enum": [
        "90",
        "180",
        "-90",
        "-180",
    ],

};

class DefferalMappingImageReadOnly extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        loader: true,
        inputData: {},
        dropdownSearchData: {},
        autoComplete: {},
        showValue: true,
        varValue: {},
        getData: false,
        errorModalBoolean: false,
        errorMessages: {},
        errorArray: {},
        selected: [],
        confirmationAlert: false,
        title: ""

    };

    constructor(props) {
        super(props);

    }


    updateComponent = () => {
        this.forceUpdate();
    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    };

    close = () => {

        this.props.closeModal();
    };

    componentDidMount() {

        let imageUrl = backEndServerURL + "/deferral/files/" + this.props.deferralId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let tableArray = [];
                let tableArrays = [];
                var status = "";
                response.data.map((deferal) => {

                    tableArray.push(deferal)
                    // this.renderDropdownData(response.data.deferalList.type)


                });
                response.data.map((deferal) => {

                    tableArrays.push(deferal.fileName)
                    // this.renderDropdownData(response.data.deferalList.type)


                });
                console.log(tableArray);

                 this.renderDropdownData(tableArrays);


                this.setState({
                    getData: true,
                    tableData: tableArray,
                    getSplitFile: tableArray,
                    getImageLink: response.data,
                    loader: false,
                 })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loader: false
                })
            })

    }

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false
            });
            this.functionForFileDelete('all')
        } else {
            this.setState({
                confirmationAlert: false
            });
        }
    };


    handleSubmit = (event, value) => {
        event.preventDefault();
        if (value === "all") {
            this.setState({
                title: "Do you want to confirm?",
                confirmationAlert: true,
            });
        } else {
            this.functionForFileDelete()
        }
    };


    functionForFileDelete = (value) => {
        this.setState({
            loader: true
        });
        let mapData = {};
        let rotateData = {};
        for (let item in this.state.inputData) {
            if (item.indexOf("ROTATE_") !== -1) {
                rotateData[item] = this.state.inputData[item]
            } else {
                mapData[item] = this.state.inputData[item]
            }
        }
        let deleteUrl = backEndServerURL + "/case/deleteAndRenameFiles";
        axios.post(deleteUrl, {
            appId: this.props.appId,
            fileNames: mapData,
            deleteList: value !== undefined ? this.state.getSplitFile : this.state.selected
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let url = backEndServerURL + "/case/files/rotate";
                axios.post(url, rotateData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((rotateResponse) => {
                        this.props.closeModal(response.data);
                        this.setState({
                            loader: false
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            loader: false
                        })
                    });
                /* let url = backEndServerURL + "/case/renameFiles";
                 axios.post(url, {
                     appId: this.props.appId,
                     fileNames: this.state.inputData
                 }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then((response) => {
                         this.props.closeModal(response.data);
                         this.setState({
                             loader: false
                         })
                     })
                     .catch((error) => {
                         let duplicateUrl = backEndServerURL + "/caseFile/checkDuplicacy";
                         axios.post(duplicateUrl, {
                             appId: this.props.appId,
                             fileNames: this.state.inputData
                         }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                             .then((response) => {
                                 console.log(response.data);
                                 let errorArray = {};
                                 let errorMessages = {};
                                 response.data.map((fileName) => {
                                     errorArray[fileName] = true;
                                     errorMessages[fileName] = "Duplicate file name";
                                 })

                                 this.setState({
                                     errorArray: errorArray,
                                     errorMessages: errorMessages,
                                     loader: false,
                                     errorModalBoolean: true

                                 })

                             })
                             .catch((error) => {
                                 console.log("dedup problem")
                                 console.log(error);

                                 this.setState({

                                     loader: false

                                 })
                             })


                     })


 */
            })
            .catch((error) => {
                console.log(error);
                let duplicateUrl = backEndServerURL + "/caseFile/checkDuplicacy";
                axios.post(duplicateUrl, {
                    appId: this.props.appId,
                    fileNames: this.state.inputData,
                    deleteList: this.state.selected
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        let errorArray = {};
                        let errorMessages = {};
                        response.data.map((fileName) => {
                            errorArray[fileName] = true;
                            errorMessages[fileName] = "Duplicate file name";
                        });

                        this.setState({
                            errorArray: errorArray,
                            errorMessages: errorMessages,
                            loader: false,
                            errorModalBoolean: true
                        })

                    })
                    .catch((error) => {
                        this.setState({
                            loader: false
                        })
                    })
            })
    };

    handleChangeuploadFile = (event, data) => {
        //event.preventDefault();
        const {selected} = this.state;
        const selectedIndex = selected.indexOf(data);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, data);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({selected: newSelected});
        console.log(this.state.selected)
    };

    renderDropdownData = (data) => {

        data.map((name, index) => {

            //this.state.autoComplete[name] =arrayListIndividual;
           console.log("name"+name)
           if(name!==null && name!==undefined){
               let nameShow = name.split('_')[1].split('.')[0];

               this.state.autoComplete[name] = arrayListIndividual;

               this.state.varValue[name] = {label: nameShow};
               this.state.inputData[name] = nameShow;
           }



        })


    };

    renderCommonForm = (data, index) => {

        if (this.state.getData && data!==undefined && data!==null) {
            let field = JSON.parse(JSON.stringify(rotateOption));
            field.varName = "ROTATE_" + data;
            return (
                <Grid item xs={4}>
                    <div>
                        <br/>
                         <div>
                            {AutoCompleteComplete.renderSelect(this.state, this.updateComponent, {
                                "label": "Your Photo Name",
                                "varName": data
                            })}
                            {LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER" &&  LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "BOM" && SelectComponent.select(this.state, this.updateComponent, field)}
                            <br/>
                        </div>

                        {LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER" &&  LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "BOM" && <FormControlLabel
                            control={
                                <CheckBox
                                    //checked={this.state.inputData.uploadFileAlertRequest[index]}
                                    //defaultValue={this.state.inputData.uploadFileAlertRequest[index]}
                                    value={data}
                                    name="fileMappingDelete"
                                    onChange={(event) => this.handleChangeuploadFile(event, data)}/>
                            }
                            label="Delete"
                        />}

                    </div>
                    <img width='90%'
                         src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")} alt=""/>

                    <br/>
                </Grid>


            )
        }


    };
    renderloader = () => {

         if (this.state.loader === false) {
            return (
                <Grid container spacing={1}>
                    {
                        this.state.getSplitFile.map((data, index) => {
                             return (

                                this.renderCommonForm(data.fileName, index)


                            )

                        })

                    }
                </Grid>
            )
        }

    };

    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.errorModalBoolean}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title="Duplicate Name" closeModal={this.closeModal}
                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <h4 style={{color: "white"}} >Scanning File<a><CloseIcon onClick={this.close}
                                                           style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <br/>

                            <ThemeProvider theme={theme}>
                                <div>
                                    {this.state.loader === false && <div style={{
                                        "display": "flex",
                                        "justify-content": "right",
                                        "padding-right": "20px"
                                    }}>
                                        {LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER" &&  LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "BOM" &&  <button
                                            className="btn btn-outline-primary"
                                            style={{
                                                verticalAlign: 'right',
                                            }}
                                            type='button' value='add more'
                                            onClick={(e) => this.handleSubmit(e, 'all')}
                                        >Delete All Document
                                        </button>}
                                    </div>}
                                    <Grid container spacing={3}>
                                        {this.renderloader()}
                                    </Grid>
                                </div>


                                <div>

                                    <br/>
                                    <br/>
                                    <center>
                                        {LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "CHECKER" &&  LocalstorageEncrypt.encryptStorageFunction.getItem("roles") !== "BOM" && <button
                                            className="btn btn-outline-primary"
                                            style={{
                                                verticalAlign: 'right',

                                            }}

                                            type='button' value='add more'
                                            onClick={(e) => this.handleSubmit(e)}
                                        >Submit
                                        </button>}
                                    </center>

                                </div>
                            </ThemeProvider>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(DefferalMappingImageReadOnly);