import VerifyDocumentBOM from "./CASA/VerifyDocumentBOM";
import {closerRequestFormBm, closerRequestFormBom} from "./WorkflowJsonForm3";

import {maintenanceForCallAndBom as BOMAccountMaintenance} from "./Maintenance/static/JsonForCasaMaintenance"

import {dpsJsonBomFormReadOnly} from "./WorkflowJsonFormRashed";

import {
    AbhCheckerJsonFormForAgentIndividual,
    AbhCheckerJsonFormForAgentNonIndividual,
    AdcJsonFormForVerify,
    BOMJsonFormForCasaCompany,
    BOMJsonFormForCasaCompanyExist,
    BOMJsonFormForCasaIndividual,
    BOMJsonFormForCasaIndividualExist,
    BOMJsonFormForCasaJoint,
    BOMJsonFormForCasaJointExist,
    BOMJsonFormForCasaProprietorship,
    BOMJsonFormForCasaProprietorshipExist,
    BOMJsonFormForInstaDebitCard
} from "./WorkflowJsonForm4";
import {
    BOMinWardJasonFormRemitance,
    BOMJsonFormForCasaIndividualDPS,
    BOMJsonFormForCasaIndividualFdr,
    BOMJsonFormForCasaIndividualTagFdr,
    BOMJsonFormForNewWithExistCB,
    BOMLockerForm,
    fttJasonFormRemitanceBOM,
    MedicalFileJsonFormBOM,
    outwardJasonFormRemitanceBOM,
    RemittanceServiceOtherJsonFormBOM,
} from "./WorkflowJsonFormArin";
import React, {Component} from "react";
import OpeningBom from "./fdr/OpeningBom";
import {assetOpsBackEndServerURL, backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import VerifyMakerPhoto from "./VerifyMakerPhoto";
import Grid from "@material-ui/core/Grid";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import BomOpening from './Card/BomOpening'
import ApprovalBOM from "./REMITENCE/ApprovalBOM";
import AbhChecker from "./AGENT/AbhChecker";
import FundTransfer from "./FundTransfer/BomInbox";
import BOMLocker from "./LOCKER/BOMLocker";
import VerifyDocumentBOM2 from "./Maintenance/static/VerifyDocumentBOM2";
import BOMLockerSurrander from './LOCKER/BOMLockerSurrander'
import BOMLockerMaintenance from './LOCKER/BOMLockerMaintenance';
import DebitCardSplitInbox from "../DeliverableManagement/DebitCard/DebitCardSplitInbox";
import ChequeBookSplit from "../DeliverableManagement/ChequeBook/ChequeBookSplit";
 import ChequeBookSplits from "../Greendelta/stampVerify";
import InstaPackSplit from "../DeliverableManagement/InstaChequeBook/InstaPackSplit";
import InstaCardSpilt from "../DeliverableManagement/InstaDebitCard/InstaCardSpilt";
import VerifyInstaDebitCardBOM from "./InstaDebitcard/VerifyInstaDebitCardBOM";

import CloserBomApproval from "./Maintenance/casa/CloserBomApproval";
import DPSCloserBomApproval from "./Maintenance/dps/CloserBomApproval";
import FdrBomApproval from "./Maintenance/FDR/FdrBomApproval";

import BomApproval from "../DeliverableManagement/CreditCardChequeBook/BomApproval";

import MandateApproval from "./Maintenance/signatory/BomApproval";
import VerifyBomSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/BomSecuredLoanClosing";

import BomPayOrder from "./AssetOps/PayOrder/BomPayOrder";
import BomOutstandingCertificate from "./AssetOps/OutstandingCertificate/BomOutstandingCertificate";
import Others from "./Maintenance/staticOthers/Others";
import AdcServiceBOM from "./Adc/AdcServiceBOM";
import {maintenanceForBom} from "./Maintenance/FDR/JsonForFdrMaintenance"
import MaintenanceUtility from "./Maintenance/static/MaintenanceUtility";
import FDRMaintenanceUtility from "./Maintenance/FDR/MaintenanceUtility";
import BomInbox from "../DeliverableManagement/GIftCard/BomInbox";
import GiftCardTagging from "../DeliverableManagement/GIftCard/cardTagging";
import BomInboxView from "./FundTransfer/customDuty/BomInboxView";
import BomInboxViewForCustomerStatus from "./Maintenance/CustomerStatusUpdate/BomInboxView"
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import StampSaleRequest from "../Greendelta/stampSaleVerify";

class BOMInboxCase extends Component {
    state = {
        documentList: [],
        getDocument: false
    };
    ReadANdGrid = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
            returnObjectVariables[i]["grid"] = 6;
        }
        return returnObject;

    };
    casa = () => {

        if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {

            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaIndividual}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Joint Account") {

            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaJoint}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaProprietorship}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Agent Account Opening") {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaIndividual}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "Account Opening" && this.props.subServiceType === "Company Account") {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaCompany}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={this.ReadANdGrid(AdcJsonFormForVerify)}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {

            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaIndividual}
                                       appId={this.props.appUid}/>)
        } else {


        }

    };

    Locker = () => {
        if (this.props.serviceType === 'Locker Opening') {
            return (<BOMLocker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               freeFlag1={this.props.freeFlag1}
                               freeFlag2={this.props.freeFlag2}
                               freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                               recpmtid={this.props.recpmtid}
                               customerName={this.props.customerName}
                               subServiceType={this.props.subServiceType}
                               titleName="Locker Opening"
                               commonJsonForm={BOMLockerForm}
                               appId={this.props.appUid}/>)
        }
    };
    remetance = () => {

        if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Student File') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={outwardJasonFormRemitanceBOM}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Medical File') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={MedicalFileJsonFormBOM}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Other Service') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={RemittanceServiceOtherJsonFormBOM}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FTT') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={fttJasonFormRemitanceBOM}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'FDD') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={fttJasonFormRemitanceBOM}
                                 appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Remetance' && this.props.subServiceType === 'Inward') {
            return (<ApprovalBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                 serviceType={this.props.serviceType}
                                 freeFlag1={this.props.freeFlag1}
                                 freeFlag2={this.props.freeFlag2}
                                 freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                 recpmtid={this.props.recpmtid}
                                 customerName={this.props.customerName}

                                 subServiceType={this.props.subServiceType}
                                 titleName="Account Maintenance"
                                 commonJsonForm={BOMinWardJasonFormRemitance}
                                 appId={this.props.appUid}/>)
        }
    };
    card = () => {
        if (this.props.serviceType === 'Card Cheque' && (this.props.subServiceType === "Card Cheque Request")) {

            return (<BomOpening closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}

                                subServiceType={this.props.subServiceType}
                                titleName="BOM Opening"
                                appId={this.props.appUid}/>)
        }
    };

    casaExist = () => {
        if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")) {

            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaIndividualExist}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Joint Account") {

            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       jointAccountCustomerNumber={this.props.jointAccountCustomerNumber}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaJointExist}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaProprietorshipExist}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Agent Account Opening") {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaIndividualExist}
                                       appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'ExistAccountOpening' && this.props.subServiceType === "Company Account") {
            return (<VerifyDocumentBOM closeModal={this.props.closeModal} customerName={this.props.customerName}
                                       subCategory={this.props.subCategory}
                                       category={this.props.category}
                                       caseId={this.props.CaseId}
                                       solId={this.props.solId}
                                       accountNumber={this.props.accountNumber}
                                       cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="New Account Opening"
                                       jsonForm={BOMJsonFormForCasaCompanyExist}
                                       appId={this.props.appUid}/>)
        } else {


        }


    };
    stampType = () => {
         if (this.props.category === "Stamp Issuance" &&
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "Chequebook" &&
            this.state.getDocument
        ) {
            return (
                <StampSaleRequest
                    closeModal={this.props.closeModal} customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Chequebook Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        }
        else if (
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "Chequebook" &&
            this.state.getDocument
        ) {
            return (
                <ChequeBookSplits
                    closeModal={this.props.closeModal} customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Chequebook Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        }
    }

    DeliverableManage = () => {
        if (
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "Requisition" &&
            this.state.getDocument
        ) {
            return (
                <DebitCardSplitInbox
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Debit Card Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        } else if (
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "Chequebook" &&
            this.state.getDocument
        ) {
            return (
                <ChequeBookSplit
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Chequebook Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        } else if (
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "Instapack" &&
            this.state.getDocument
        ) {
            return (
                <InstaPackSplit
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Instapack Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        } else if (
            this.props.serviceType === "Debit Card" &&
            this.props.subServiceType === "InstaDebitCard" &&
            this.state.getDocument
        ) {
            return (
                <InstaCardSpilt
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="InstaDebitCard Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        }
    };

    creditCardChequeBook = () => {
        if (
            this.props.serviceType === "Credit Card" &&
            this.props.subServiceType === "Chequebook" &&
            this.state.getDocument
        ) {
            return (
                <BomApproval
                    closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}

                    subServiceType={this.props.subServiceType}
                    titleName="Credit Card Cheque Book Requisition"
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        }
    };


    payOrder = () => {
        if (this.props.category === "Pay Order") {
            return (
                <BomPayOrder
                    closeModal={this.props.closeModal} customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}
                    subServiceType={this.props.subServiceType}
                    file={this.state.documentList[0]}
                    appId={this.props.appUid}
                />
            );
        }
    };


    fdr = () => {
        if (this.props.freeFlag1 === 'FDR Account Opening - with Existing CB Only') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid}
                                customerName={this.props.customerName}


                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForNewWithExistCB}
                                titleName="Individual FDR Opening"
                                appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening -Without Operative Account-NTB') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid}
                                customerName={this.props.customerName}

                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualFdr}
                                titleName="Individual FDR Opening"
                                appId={this.props.appUid}/>)
        } else if (this.props.freeFlag1 === 'FDR Account Opening - with Existing Operative Account') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid}
                                customerName={this.props.customerName}

                                subserviceType="Individual Tag FDR Opening"
                                commonJsonForm={BOMJsonFormForCasaIndividualTagFdr}
                                titleName="Tag FDR Opening"
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening' && this.props.freeFlag1 === 'DPS Account Opening -with Existing Operative Account') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid}
                                customerName={this.props.customerName}

                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualDPS}
                                titleName="DPS Opening"
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'DPS Opening' || this.props.serviceType === 'FDR Opening') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                serviceType={this.props.serviceType}
                                freeFlag1={this.props.freeFlag1}
                                freeFlag2={this.props.freeFlag2}
                                freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                recpmtid={this.props.recpmtid}
                                customerName={this.props.customerName}

                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualDPS}
                                titleName="DPS Opening"
                                appId={this.props.appUid}/>)
        }
        /*else if (this.props.freeFlag1 === 'FDR Opening' && this.props.subServiceType === 'Joint Account') {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
   freeFlag1={this.props.freeFlag1}
   freeFlag2={this.props.freeFlag2}
   freeFlag3={this.props.freeFlag3}  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}  customerName={this.props.customerName}
                               
                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualTagFdr}
                                titleName="Non Individual FDR Opening"
                                appId={this.props.appUid}/>)
        }*/
        /*else if (this.props.serviceType === 'FDR Opening' && (this.props.subServiceType === "Proprietorship A/C" || this.props.subServiceType === "NONINDIVIDUAL")) {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
   freeFlag1={this.props.freeFlag1}
   freeFlag2={this.props.freeFlag2}
   freeFlag3={this.props.freeFlag3}  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}  customerName={this.props.customerName}
                               
                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualFdr}
                                titleName="Account Opening"
                                appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'FDR Opening' && this.props.subServiceType === "Company Account") {
            return (<OpeningBom closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
   freeFlag1={this.props.freeFlag1}
   freeFlag2={this.props.freeFlag2}
   freeFlag3={this.props.freeFlag3}  freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}  customerName={this.props.customerName}
                               
                                subserviceType={this.props.subServiceType}
                                commonJsonForm={BOMJsonFormForCasaIndividualFdr}
                                titleName="Account Opening"
                                appId={this.props.appUid}/>)
        }
*/
    };
    maintenance = () => {
        if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && (this.props.category === "CASA Closer" || this.props.category === "DPS Closer" || this.props.category === "Deceased DPS Closer")) {

            return (<CloserBomApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                       serviceType={this.props.serviceType}

                                       subServiceType={this.props.subServiceType}
                                       titleName="CASA Closer Maintenance"
                                       maintenanceType={this.props.maintenanceType}
                                       jsonForm={closerRequestFormBom}
                                       appId={this.props.appUid}/>)
        } else if ((this.props.serviceType === 'Maintenance' && this.props.subServiceType === 'AccountMaintenance') && this.props.category === "DPS Maintenance") {

            return (<DPSCloserBomApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType}
                                          subServiceType={this.props.subServiceType}
                                          maintenanceType={this.props.maintenanceType}
                                          jsonForm={dpsJsonBomFormReadOnly}
                                          appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "AccountMaintenance") {

            return (<VerifyDocumentBOM2 closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType}

                                        subServiceType={this.props.subServiceType}
                                        titleName="Account Maintenance"
                                        maintenanceType={this.props.maintenanceType}
                                        jsonForm={BOMAccountMaintenance}
                                        appId={this.props.appUid}/>)
        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "FDRMaintenance") {

            return (<FdrBomApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                    serviceType={this.props.serviceType}

                                    subServiceType={this.props.subServiceType}
                                    titleName="FDR Maintenance"
                                    subCategory={this.props.subCategory}
                                    jsonForm={maintenanceForBom}
                                    appId={this.props.appUid}/>)

        } else if (this.props.serviceType === 'Maintenance' && this.props.subServiceType === "MandateMaintenance") {

            return (<MandateApproval closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                     serviceType={this.props.serviceType}

                                     subServiceType={this.props.subServiceType}
                                     titleName="Mandate/Signatory Update"
                                     subCategory={this.props.subCategory}
                                     appId={this.props.appUid}/>)

        }
    };
    instaDebitCard = () => {
        return (<VerifyInstaDebitCardBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}

                                         subServiceType={this.props.subServiceType}
                                         titleName="Debit Card Issuance"
                                         jsonForm={BOMJsonFormForInstaDebitCard}
                                         appId={this.props.appUid}/>)

    };
    instantCard = () => {
        return (<BomInbox closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}
                                         subServiceType={this.props.subServiceType}
                                         appId={this.props.appUid}/>)

    };

    gitCardTagging = () => {
        return <GiftCardTagging closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                         serviceType={this.props.serviceType}
                         subServiceType={this.props.subServiceType}
                         appId={this.props.appUid}/>
    };
    agent = () => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
            return (
                <AbhChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType}

                            subServiceType={this.props.subServiceType}
                            titleName={this.props.serviceType}
                            jsonForm={AbhCheckerJsonFormForAgentIndividual}
                            appId={this.props.appUid}/>
            )
        } else if (this.props.subServiceType === "Company Account") {
            return (
                <AbhChecker closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType}

                            subServiceType={this.props.subServiceType}
                            titleName={this.props.serviceType}
                            jsonForm={AbhCheckerJsonFormForAgentNonIndividual}
                            appId={this.props.appUid}/>
            )
        }
    };


    fundTransfer = () => {
        if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (<FundTransfer closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid}
                                  customerName={this.props.customerName}

                                  subserviceType={this.props.subServiceType}
                                  titleName="Fund Transfer Request"
                                  appId={this.props.appUid}/>)
        }

    };
    LockerClosing = () => {
        if (this.props.subServiceType === 'LockerClosing') {
            return (<BOMLockerSurrander closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                        serviceType={this.props.serviceType}
                                        freeFlag1={this.props.freeFlag1}
                                        freeFlag2={this.props.freeFlag2}
                                        freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                        recpmtid={this.props.recpmtid}
                                        customerName={this.props.customerName}

                                        subServiceType={this.props.subServiceType}
                                        titleName="Locker Closeing"
                                        appId={this.props.appUid}/>)
        }
    };

    LockerMaintenance = () => {
        if (this.props.subServiceType === 'LockerMaintenance') {
            return (<BOMLockerMaintenance closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                          serviceType={this.props.serviceType}
                                          freeFlag1={this.props.freeFlag1}
                                          freeFlag2={this.props.freeFlag2}
                                          freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                          recpmtid={this.props.recpmtid}
                                          customerName={this.props.customerName}

                                          subServiceType={this.props.subServiceType}
                                          titleName="Locker Maintenance"
                                          appId={this.props.appUid}/>)
        }
    };

    casaOtherMaintenance = () => {
        if (this.props.freeFlag1 === 'CASA Other Maintenance' && this.props.serviceType === "Maintenance") {
            return (<Others closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                            serviceType={this.props.serviceType}
                            freeFlag1={this.props.freeFlag1}
                            freeFlag2={this.props.freeFlag2}
                            freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                            recpmtid={this.props.recpmtid}
                            customerName={this.props.customerName}
                            taskTitle={this.props.taskTitle}

                            subServiceType={this.props.subServiceType}
                            titleName="CASA Static Other Maintenance"
                            appId={this.props.appUid}/>)
        }
    };
    adc = () => {
        if (this.props.serviceType === "adc_service") {
            return (
                <AdcServiceBOM closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                               serviceType={this.props.serviceType}
                               subServiceType={this.props.subServiceType}
                               titleName="CS after BM Approval"
                               jsonForm={closerRequestFormBm}
                               appId={this.props.appUid}/>
            )
        }
    };
    securedLoan = () => {
        console.log("Load: BomInboxCase");
        console.log(this.props);

        console.log(this.props.tastTitle);
        return (
            <VerifyBomSecuredLoanClosing closeModal={this.props.closeModal} customerName={this.props.customerName}
                                         subCategory={this.props.subCategory}
                                         category={this.props.category}
                                         caseId={this.props.CaseId}
                                         solId={this.props.solId}
                                         accountNumber={this.props.accountNumber}
                                         cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                                         serviceType={this.props.serviceType}

                                         subServiceType={this.props.subServiceType}
                                         titleName="CS after BM Approval"
                                         jsonForm={closerRequestFormBm}
                                         appId={this.props.appUid}/>
        )
    };

    customDutyPayment = () => {
        return <BomInboxView
            closeModal={this.props.closeModal}
            customerName={this.props.customerName}
            subCategory={this.props.subCategory}
            category={this.props.category}
            caseId={this.props.CaseId}
            solId={this.props.solId}
            accountNumber={this.props.accountNumber}
            cbNumber={this.props.cbNumber}
            closeIcon={this.props.closeIcon}
            serviceType={this.props.serviceType}
            subServiceType={this.props.subServiceType}
            appId={this.props.appUid}/>
    };

    customerStatusUpdate = () => {
        return <BomInboxViewForCustomerStatus
            closeModal={this.props.closeModal}
            customerName={this.props.customerName}
            subCategory={this.props.subCategory}
            category={this.props.category}
            caseId={this.props.CaseId}
            solId={this.props.solId}
            accountNumber={this.props.accountNumber}
            cbNumber={this.props.cbNumber}
            closeIcon={this.props.closeIcon}
            serviceType={this.props.serviceType}
            subServiceType={this.props.subServiceType}
            appId={this.props.appUid}/>
    };

    inboxCase = () => {

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "ABHCHECKER") {

            return (
                this.agent()
            )
        }
        if ((this.props.serviceType === "adc_service" && this.props.freeFlag3 === "sms_alert") || (this.props.serviceType === "adc_service" && this.props.subCategory === "A/C Opening")) {

            return (
                this.adc()
            )
        } else if (this.props.category === "Secured Loan Closing") {

            return (
                this.securedLoan()
            )


        } else if (((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === 'DSTBOM') && this.props.serviceType === "Account Opening") || (this.props.category === "CASA" && (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening"))) {
            return (
                this.casa()
            )
        } else if (((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "DSTBOM" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "BOM")) && this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casaExist()
            )
        } else if (this.props.subCategory === "Custom Duty Payment") {
            return (
                this.customDutyPayment()
            )
        } else if (this.props.serviceType === "FDR Opening" || this.props.serviceType === "DPS Opening") {

            return (
                this.fdr()
            )
        } else if (this.props.category === "Pay Order") {

            return (
                this.payOrder()
            )
        } else if (this.props.freeFlag1 === "CASA Other Maintenance" && this.props.serviceType === "Maintenance") {
            return (
                this.casaOtherMaintenance()
            )
        } else if (this.props.subServiceType === "Instant Debit Card") {
            return (
                this.instantCard()
            )
        }else if (this.props.subServiceType === "Gift Card Issuance" || this.props.subServiceType === "Prepaid Card Issuance") {
            return (
                this.gitCardTagging()
            )
        }else if (this.props.serviceType === "Maintenance") {
            return (
                this.maintenance()
            )
        } else if (this.props.category === "Debit Card Issuance") {
            return (
                this.instaDebitCard()
            )
        } else if (this.props.subServiceType === "LockerClosing") {
            return (
                this.LockerClosing()
            )
        } else if (this.props.subServiceType === "LockerMaintenance") {
            return (
                this.LockerMaintenance()
            )
        } else if (this.props.serviceType === "FundTransfer") {
            return (
                this.fundTransfer()
            )
        } else if (this.props.serviceType === "Card Cheque") {
            return (
                this.card()
            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                this.remetance()
            )
        } else if (this.props.serviceType === "Locker Opening") {
            return (
                this.Locker()
            )
        } else if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch") || this.props.serviceType === "Account Opening") {

            return (
                this.casa()
            )
        } else if (this.props.serviceType === "internet_banking" || this.props.serviceType === "ExistAccountOpening") {
            return (
                this.casaExist()
            )
        } else if (this.props.serviceType === "Debit Card" && this.props.freeFlag1 === "FDD") {
            return this.stampType();
        } else if (this.props.serviceType === "Debit Card") {
            return this.DeliverableManage();
        } else if (this.props.serviceType === "Credit Card") {
            return this.creditCardChequeBook();
        } else if (this.props.category === "Outstanding Certificate") {
            return (
                <BomOutstandingCertificate
                    closeModal={this.props.closeModal} customerName={this.props.customerName}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    caseId={this.props.CaseId}
                    solId={this.props.solId}
                    accountNumber={this.props.accountNumber}
                    cbNumber={this.props.cbNumber} closeIcon={this.props.closeIcon}
                    appId={this.props.appUid}
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    serviceType={this.props.serviceType}
                    freeFlag1={this.props.freeFlag1}
                    freeFlag2={this.props.freeFlag2}
                    freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4} recpmtid={this.props.recpmtid}
                    customerName={this.props.customerName}
                    subServiceType={this.props.subServiceType}
                />
            )
        }


    };

    componentDidMount() {
        let fileUrl= (this.props.asset ===true) ? assetOpsBackEndServerURL + "/case/files/" + this.props.appUid
            : backEndServerURL + "/case/files/" + this.props.appUid;
        // let fileUrl = backEndServerURL + "/case/files/" + this.props.appUid;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(":d");
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    close = () => {
        this.props.closeModal();
    };
    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  serviceType={this.props.serviceType}

                                  subServiceType={this.props.subServiceType}

                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };
    closeIcon = () => {
        this.props.closeIcon();
    };
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    masterdataToString = (data) => {
        console.log(data)
        if ((data !== undefined && data !==null) && (data.key !== undefined && data.key !==null)) {
            return data.value;
        } else {
            return data;
        }
    }
    renderTitle = () => {
        if (this.props.category === "Debit Card Issuance") {
            return (
                <h4 style={{color: "white"}} ><b>Insta Debit Card </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.category === "Chequebook") {
            return (
                <h4 style={{color: "white"}} ><b>Cheque Book Requisition </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.subServiceType === "Instant Debit Card") {
            return (
                <h4 style={{color: "white"}} >{this.props.subCategory}<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        }
      else if (this.props.category === "Stamp Issuance" && this.props.serviceType === "Debit Card" && this.props.freeFlag1 === "FDD") {
            return (
                <h4 style={{color: "white"}} >New Request (Stamp Issuance)<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.category === "Notification" && this.props.serviceType === "Debit Card" && this.props.freeFlag1 === "FDD") {
            return (
                <h4 style={{color: "white"}} >Dispatch Receiver<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.serviceType === "Debit Card" && this.props.freeFlag1 === "FDD") {
            return (
                <h4 style={{color: "white"}} >Stamp Requisition<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        }else if (this.props.subCategory === "Bulk Insta Debit Card") {
            return (
                <h4 style={{color: "white"}} >Bulk Insta Debit Card<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.subCategory === "Instapack") {
            return (
                <h4 style={{color: "white"}} >Instapack Requisition<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.subCategory === "Opening Individual") {
            return (
                <h4 style={{color: "white"}} >Individual Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.subCategory === "Opening Joint") {
            return (
                <h4 style={{color: "white"}} >Joint Locker Opening<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (
                <h4 style={{color: "white"}} >Fund Transfer-{this.props.subCategory === "SME/Corporate" ? "Corporate" : this.props.subCategory}<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.serviceType === "internet_banking") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}>(<b>Internet
                    Banking:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} >
                    <b>{this.props.freeFlag2 === "withKyc" ? "( FDR Account Opening - Without operative CASA )" : "( FDR Account Opening - Using Different CASA )"}</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB
                            Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.freeFlag1}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>FDR Account Opening -with NTB Operative Account </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? "" : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}<b> SOLId:</b>{this.masterdataToString(this.props.solId)}</>}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p></h4>

            );
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>DPS Account Opening -with NTB Operative Account </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}{<>
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? "" : ""}
                    <b> CB
                        Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}<b> SOLId:</b>{this.masterdataToString(this.props.solId)}</>}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                    onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p></h4>

            );
        } else if (this.props.subServiceType === "FDRMaintenance" && this.props.serviceType === "Maintenance") {
            return FDRMaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon,this.props.CaseId);
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "CASA Closer") {
            return MaintenanceUtility.renderCloserMaintenanceHeader(this.props.freeFlag3, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && (this.props.category === "DPS Closer" || this.props.category === "DPS Maintenance")) {
            return (
                <h4 style={{color: "white"}} >DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "Deceased DPS Closer") {
            return (
                <h4 style={{color: "white"}} >Deceased DPS/Scheme Closer Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance" && this.props.category === "DPS Closer") {
            return (
                <h4 style={{color: "white"}} >DPS CLoser Request<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subServiceType === "AccountMaintenance" && this.props.serviceType === "Maintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
        } else if (this.props.subServiceType === "MandateMaintenance") {
            return MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.CaseId, this.props.freeFlag3, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.closeIcon);
        } else if (this.props.serviceType === "Remetance") {
            return (
                <h4 style={{color: "white"}} >Remittance<a><CloseIcon onClick={this.closeIcon} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.serviceType === "adc_service") {
            return (
                <h4 style={{color: "white"}} ><b>{this.props.subCategory}</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )
        } else if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Existing
                    Customer
                    Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>New Customer Account Opening
                    - {this.props.subServiceType === "Company Account" ? "Partnership Account" : this.props.subServiceType} </b>
                    <p
                        style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                        <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                        <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                        <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                            Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                            ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                            onClick={this.closeIcon} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></p></h4>
            )
        } else if (this.props.serviceType === "Credit Card") {
            return (
                <h4 style={{color: "white"}} ><b> Credit Card Cheque Requisition </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> Card Number:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )

        } else {
            return (
                <h4 style={{color: "white"}} ><b> {this.props.serviceType} </b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                    <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                    <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                    <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                        Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                        ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} )<a><CloseIcon
                        onClick={this.closeIcon} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>

            )

        }
    };

    render() {
        if (this.props.serviceType === "Pay Order" || this.props.serviceType === "Card Cheque" || this.props.serviceType === "Credit Card" || this.props.serviceType === "Debit Card" || this.props.category === "Debit Card Issuance" || this.props.serviceType === "adc_service" || this.props.subServiceType === "Gift Card Issuance" || this.props.subServiceType === "Prepaid Card Issuance") {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >{this.renderTitle()}</h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>


                                <Grid item xs={12}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )
        } else if (this.props.serviceType === "Pay Order" || this.props.category === "Secured Loan Closing"  || this.props.category === "Outstanding Certificate" || this.props.freeFlag1 === "CASA Other Maintenance") {
            return (
                <div>
                    <Grid item xs={12}>
                        {this.inboxCase()}
                    </Grid>
                </div>

            )
        } else {
            return (
                <div>

                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            {this.renderTitle()}

                        </CardHeader>
                        <CardBody>
                            <Grid container>

                                <Grid item xs={8}>

                                    {this.inboxCasePhoto()}

                                </Grid>


                                <Grid item xs={4}>
                                    {this.inboxCase()}
                                </Grid>


                            </Grid>
                        </CardBody>
                    </Card>
                </div>

            )
        }
    }

}

export default BOMInboxCase;