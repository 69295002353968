import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


class Forward extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            title: "",
            count: 0,
            notificationMessage: "",
            loading: true,
        };
    }


    componentDidMount() {
        let url = backEndServerURL + "/intgr/getNTBCardChequeCount";
        axios.post(url, this.props.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    count: response.data,
                    loading: false,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderMessage = () => {
        return (
            <div style={{"margin": "0 auto", "text-align": "center"}}>
                <h4 style={{color: "white"}} >{this.state.count} Request available.</h4>
                <h4 style={{color: "white"}} >Do you want to confirm?</h4>
            </div>
        )
    };

    handleSubmit = () => {
        this.props.parentClose(this.props.inputData);
        this.props.closeModal();
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <CardBody>
                        {this.renderMessage()}
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                <div style={{"margin": "0 auto"}}>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={this.handleSubmit}
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                    >
                                        Yes
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={this.props.closeModal}
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                    >
                                        No
                                    </button>
                                </div>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </div>
            );
        }

    }

}

export default withStyles(styles)(Forward);
