import React, {Component, forwardRef} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import EFTNEdit from "../../workflow/AssetEFTN/EFTNEdit";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref}/>
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref}/>
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5',
            }
        },

    }
});
const table_style = {
    row: {
        border: "none",
        height: 10,
        "&:nth-of-type(odd)": {
            backgroundColor: "red"
        }
    }
};
let SearchForm = [
    {
        varName: "cblLoanAcNo",
        type: "text",
        label: "Cbl Loan A/c No",
        grid: 2,
    },
    {
        varName: "fileRefNum",
        type: "text",
        label: "File Ref Number",
        grid: 2,
    },
    // {
    //     varName: "status",
    //     type: "select",
    //     label: "Status",
    //     grid: 2,
    //     enum: [
    //         "Active",
    //         "InActive",
    //     ]
    // }
];

class EFTNExcelDatabase extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            commonUrl: "/eftn/getAllByFilter/page/",
            appData: "",
            editValue: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {
                mydatelabel: "My Date"
            },
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All EFTN",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            loader: false,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            loading: false,
            viewModal: false,
            allData: [],
            columns: [
                {title: "Cbl Loan A/c No", field: "cblLoanAcNo"},
                {title: "File Ref Number", field: "fileRefNum", removable: false},
                {title: "Customer Name with Other Bank", field: "customerNameOB"},
                {title: "Customer A/C No with Other Bank", field: "customerAcOB"},
                {title: "Other Bank Routing Number", field: "obRoutingNum"},
                {title: "Cbl Casa A/c No", field: "cblCaseAcNo"},
                {title: "Installment Amount", field: "installmentAmount"},
                {title: "Installment Start Date", field: "installmentStartDate"},
                {title: "Status", field: "status"},
                {title: 'Action', field: 'action', type: 'Button'},
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        // this.setState({
        //     loader: true,
        // });
        // if (value === "All") {
        //     this.fetchDataFromAPI('All','getBranchAll/page/0')
        // }
        // else{
        //     this.fetchDataFromAPI(value)
        // }
    };

    getRow(event, value) {
        event.preventDefault();
        console.log(this.getOrginalValue(value.id));
        return 0;

    }

    getOrginalValue(id) {
        let orginalValue;
        this.state.responseData.map((data, index) => {
            if (id === data.id) {
                this.setState({
                    editValue: data,
                    viewModal: true
                })
            }
        });
        return orginalValue;
    }

    getBranchAll() {
        let autoComplete = {};
        let branch = [];
        let branchUrl = backEndServerURL + "/branchMaster/getAll";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map((item) => {
                    branch.push({label: item.branchName})
                });

                autoComplete.branch = branch;
                this.setState({
                    autoComplete: autoComplete,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }


    searchData = (e) => {
        e.preventDefault();

        this.setState({
            loading: true,
        });

        let inbox_url = backEndServerURL + this.state.commonUrl + "0";

        axios
            .post(inbox_url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log('..............');
                console.log(response);
                console.log('..............');
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i];
                    rowData = {
                        id: response.data.content[i].id,
                        cblLoanAcNo: response.data.content[i].cblLoanAcNo,
                        fileRefNum: response.data.content[i].fileRefNum,
                        customerNameOB: response.data.content[i].customerNameOB,
                        customerAcOB: response.data.content[i].customerAcOB,
                        obRoutingNum: response.data.content[i].obRoutingNum,
                        cblCaseAcNo: response.data.content[i].cblCaseAcNo,
                        installmentAmount: response.data.content[i].installmentAmount,
                        installmentStartDate: this.dateConverter(response.data.content[i].installmentStartDate),
                        status: response.data.content[i].status,
                        action: <Button onClick={(e) => {
                            this.getRow(e, response.data.content[i])
                        }} style={{
                            backgroundColor: "red",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1
                        }}>Edit</Button>
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    activeItem: 'All',
                    pageNumber: 0,
                    totalRow: response.data.totalElements,
                    loading: false,
                    inputData: {}
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    dateConverter = (value) => {

        let dateString = value.substr(0, 10);
        let dataArray = dateString.split("-");
        return dataArray[2] + "/" + dataArray[1] + "/" + dataArray[0];
    };

    fetchDataFromAPI = (status, url) => {

        this.setState({
            activeItem: status,
            loader: true,
        });

        let urlSegment;
        let inbox_url;
        if (url === undefined) {
            urlSegment = 'getBranchChequebookStatus';
        } else {
            urlSegment = url;
        }

        if (status === 'All') {
            inbox_url = backEndServerURL + "/" + urlSegment;
        } else {
            inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
        }
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i];
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        numberOfChequebook: response.data.content[i].numberOfChequeBook,
                        status: response.data.content[i].status,
                        deliveryType: response.data.content[i].deliveryType,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].appId,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    closeModal = () => {
        this.getDidMountData();
        this.setState({
            viewModal: false
        })
    };
    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.viewModal}>
                    <DialogContent>
                        <EFTNEdit data={this.state.editValue} closeModal={this.closeModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    handleChangePage = (page, status) => {

        let inbox_url;
        inbox_url = backEndServerURL + this.state.commonUrl + page;

        axios.post(inbox_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response.data);

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        accountNumber: response.data.content[i].accountNumber,
                        customerName: response.data.content[i].customerName,
                        accountStatus: response.data.content[i].accountStatus,
                        accountType: response.data.content[i].accountType,
                        pageofChequebook: response.data.content[i].pageofChequebook,
                        status: response.data.content[i].status,
                        deliveryType: response.data.content[i].deliveryType,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].appId,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    activeItem: 'All',
                    pageNumber: page,
                    totalRow: response.data.totalElements,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });

    };

    componentDidMount() {
        this.getDidMountData();
    }

    getDidMountData() {
        this.setState({
            loader: true
        });
        let inbox_url = backEndServerURL + this.state.commonUrl + "0";
        axios
            .post(inbox_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i];

                    rowData = {
                        id: response.data.content[i].id,
                        cblLoanAcNo: response.data.content[i].cblLoanAcNo,
                        fileRefNum: response.data.content[i].fileRefNum,
                        customerNameOB: response.data.content[i].customerNameOB,
                        customerAcOB: response.data.content[i].customerAcOB,
                        obRoutingNum: response.data.content[i].obRoutingNum,
                        cblCaseAcNo: response.data.content[i].cblCaseAcNo,
                        installmentAmount: response.data.content[i].installmentAmount,
                        installmentStartDate: this.dateConverter(response.data.content[i].installmentStartDate),
                        status: response.data.content[i].status,
                        action: <Button onClick={(e) => {
                            this.getRow(e, response.data.content[i])
                        }} style={{
                            backgroundColor: "red",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1
                        }}>Edit</Button>
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log("array Data :");

                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true,
            loading: false,
        });
    };

    render() {
        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        const {classes, onClose} = this.props;
        console.log(this.state.inputData.callStatus);
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search EFTN Database
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.searchData(e)
                                        }}
                                    >
                                        Search
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >EFTN Database</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <React.Fragment>
                                    <div>
                                        {this.tableChoice()}
                                        {this.dialogBoxShow()}
                                    </div>
                                </React.Fragment>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    };

    actionForDownload = (event, rowData, props) => {
        let allSelectValue = (rowData, props.data);

        if (allSelectValue === undefined) return 0;
        this.setState({
            loading: true,
        });
        let idList = [];
        allSelectValue.map((data, index) => {
            idList.push(data.id)
        });

        let download_url = backEndServerURL + "/eftn/OpsdExcel/" + idList.toString().replace("[", "");
        functionForFileDownload.downloadFile(download_url, 'get', {}, "asset_eftn_excel.xlsx");
        this.setState({
            loading: false,
        })
    };

    tableChoice() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (!this.state.loader) {
            return (
                <div>
                    <br></br>
                    <React.Fragment>
                        <div>
                            <Menu pointing>
                                <Menu.Item
                                    name='All EFTN'
                                    active={this.state.activeItem === 'All EFTN'}
                                    style={this.state.activeItem === 'All EFTN' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    //     onClick={this.handleItemClick}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'All EFTN')
                                    }}
                                />
                                <Menu.Item
                                    name='Active'
                                    active={this.state.activeItem === 'Active'}
                                    style={this.state.activeItem === 'Active' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    //     onClick={this.handleItemClick}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'Active')
                                    }}
                                />
                                <Menu.Item
                                    name='InActive'
                                    active={this.state.activeItem === 'InActive'}
                                    style={this.state.activeItem === 'InActive' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'InActive')
                                    }}
                                />
                                <Menu.Item
                                    name='EFTN Returned'
                                    active={this.state.activeItem === 'EFTN Returned'}
                                    style={this.state.activeItem === 'EFTN Returned' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    onClick={(e) => this.headerSelect(e, 'EFTN Returned')}
                                />

                            </Menu>

                            {/*<Segment>*/}

                            <MuiThemeProvider theme={themeO}>
                                <div style={{maxWidth: "100%"}}>
                                    <MaterialTable
                                        columns={this.state.columns}
                                        data={this.state.allData}
                                        title={" "}
                                        options={{
                                            selection: true,
                                            rowStyle: rowData => ({
                                                backgroundColor: rowData.tableData.checked
                                                    ? "#37b15933"
                                                    : ""
                                            }),
                                            emptyRowsWhenPaging: false,
                                            pageSize: 100
                                        }}
                                        localization={{
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} of {count}',
                                            },
                                            toolbar: {
                                                nRowsSelected: '{0} selected',
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'No records to display',
                                                filterRow: {
                                                    filterTooltip: 'Filter'
                                                }
                                            }
                                        }}
                                        icons={tableIcons}
                                        components={{
                                            Actions: props => (
                                                <Button
                                                    style={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingTop: 3,
                                                        paddingBottom: 3,
                                                        borderRadius: 3,
                                                        border: 1
                                                    }}
                                                    onClick={(event, rowData) => this.actionForDownload(event, rowData, props)}
                                                >
                                                    Download
                                                </Button>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[100]}
                                                    count={this.state.totalRow}
                                                    page={this.state.pageNumber}
                                                    onChangePage={(e, page) => {
                                                        this.handleChangePage(page, this.state.activeItem)
                                                    }
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </MuiThemeProvider>

                        </div>

                    </React.Fragment>


                </div>

                //new button for microsoft word download
            );
        } else {
            return (
                <div>
                    <br></br>
                    <React.Fragment>
                        <div>
                            <Menu pointing>
                                <Menu.Item
                                    name='All EFTN'
                                    active={this.state.activeItem === 'All EFTN'}
                                    style={this.state.activeItem === 'All EFTN' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    //     onClick={this.handleItemClick}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'All EFTN')
                                    }}
                                />
                                <Menu.Item
                                    name='Active'
                                    active={this.state.activeItem === 'Active'}
                                    style={this.state.activeItem === 'Active' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    //     onClick={this.handleItemClick}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'Active')
                                    }}
                                />
                                <Menu.Item
                                    name='InActive'
                                    active={this.state.activeItem === 'InActive'}
                                    style={this.state.activeItem === 'InActive' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    onClick={(e) => {
                                        this.headerSelect(e, 'InActive')
                                    }}
                                />
                                <Menu.Item
                                    name='EFTN Returned'
                                    active={this.state.activeItem === 'EFTN Returned'}
                                    style={this.state.activeItem === 'EFTN Returned' ? {
                                        backgroundColor: "red",
                                        color: "white",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    } : {
                                        backgroundColor: "white",
                                        color: "black",
                                        minWidth: 120,
                                        textAlign: "center",
                                        display: "block"
                                    }}
                                    onClick={(e) => this.headerSelect(e, 'EFTN Returned')}
                                />

                            </Menu>

                            {/*<Segment>*/}

                            <MuiThemeProvider theme={themeO}>
                                <div style={{maxWidth: "100%"}}>
                                    <MaterialTable
                                        columns={this.state.columns}
                                        data={this.state.allData}
                                        title={" "}
                                        options={{
                                            selection: true,
                                            rowStyle: rowData => ({
                                                backgroundColor: rowData.tableData.checked
                                                    ? "#37b15933"
                                                    : ""
                                            }),
                                            emptyRowsWhenPaging: false,
                                            pageSize: 100
                                        }}
                                        localization={{
                                            pagination: {
                                                labelDisplayedRows: '{from}-{to} of {count}',
                                            },
                                            toolbar: {
                                                nRowsSelected: '{0} selected',
                                            },
                                            header: {
                                                actions: 'Actions'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'No records to display',
                                                filterRow: {
                                                    filterTooltip: 'Filter'
                                                }
                                            }
                                        }}
                                        icons={tableIcons}
                                        components={{
                                            Actions: props => (
                                                <Button
                                                    style={{
                                                        backgroundColor: "green",
                                                        color: "white",
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        paddingTop: 3,
                                                        paddingBottom: 3,
                                                        borderRadius: 3,
                                                        border: 1
                                                    }}
                                                    onClick={(event, rowData) => this.actionForDownload(event, rowData, props)}
                                                >
                                                    Download
                                                </Button>
                                            ),
                                            Pagination: props => (
                                                <TablePagination
                                                    {...props}
                                                    rowsPerPageOptions={[100]}
                                                    count={this.state.totalRow}
                                                    page={this.state.pageNumber}
                                                    onChangePage={(e, page) => {
                                                        this.handleChangePage(page, this.state.activeItem)
                                                    }
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            </MuiThemeProvider>


                            {/*</Segment>*/}
                        </div>
                    </React.Fragment>


                </div>
            );
        }
    }
}

export default withStyles(styles)(EFTNExcelDatabase);
