import Table from "../Table/Table.jsx";
import React, {Component} from "react";
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import SearchIcon from '@material-ui/icons/Search';
import withStyles from "@material-ui/core/styles/withStyles";
import {fade} from '@material-ui/core/styles/colorManipulator';
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from '../../Common/Constant';
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import UserAddEditDelete from "./UserAddEditDelete";
import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }, search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: theme.spacing.unit * 2,
        marginRight: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },

    rightToolbar: {
        marginLeft: 'auto',
        marginRight: -12,
    },
    inputRoot: {
        color: '#FF0000',
        width: '100%',

    }, inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },

    },

});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class User extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addUser: false,
            editUser: false,
            tableData: [[" ", " "]],
            userUid: "",
            open: true,
            username: '',
            deleteUser: false,
            redirectLogin: false,
        }
    }

    deleteUser(id) {
        this.setState({
            deleteUser: true,
            userUid: id,
        });
    }

    handleDelete = (event, userUid) => {
        event.preventDefault();
        let url = backEndServerURL + "/user/delete/" + userUid;


        axios.delete(url,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    deleteUser: false
                })
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    };
    createTableData = (id, username, firstName, lastName, email) => {

        return ([username, firstName, lastName, email,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editUser(username)}>
                Edit</button>,
            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.deleteUser(id)}>
                Delete</button>
        ]);

    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            let searchText = event.target.value;
            const tableData = [];
            let url = backEndServerURL + '/user/search/' + searchText;


            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((user) => {
                        tableData.push(this.createTableData(user.id, user.username, user.firstName, user.lastName, user.email));
                    });


                    this.setState({
                        tableData: tableData
                    });

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({errorMessage: "Not Found"});
                })
        }
    };

    editUser = (username) => {

        this.setState({
            editUser: true,
            username: username
        })
    };


    addUser() {
        this.setState({
            addUser: true,
        });
    }


    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {

        this.setState({
            addUser: false,
            editUser: false,

        });

    };
    closeConfirmModal = () => {

        this.setState({deleteUser: false});
    };
    userAddModal = () => {

        return (
            <UserAddEditDelete onAdd={this.onAdd} closeModal={this.closeModal}
                               name="Add New User"/>
        )
    };
    userEditModal = () => {
        return (
            <UserAddEditDelete name="Edit user" username={this.state.username} closeModal={this.closeModal}
            />


        )
    };
    onAdd = (object) => {

        this.setState({
            addUser: false

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editUser: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addUser}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.userAddModal()}
                    </DialogContent>


                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editUser}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.userEditModal()}
                    </DialogContent>


                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    open={this.state.deleteUser}
                    onClose={this.closeConfirmModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogTitle id="title">{"Comfirm Delete?"}</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeConfirmModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(event) => {
                            this.handleDelete(event, this.state.userUid)
                        }} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>User List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>

                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addUser: true
                                            })
                                        }
                                        }
                                    >
                                        Add New User
                                    </button>
                                    <AppBar position="static" style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                        <Toolbar>
                                            <section className={classes.rightToolbar}>
                                                <div className={classes.search}>
                                                    <div className={classes.searchIcon}>
                                                        <SearchIcon/>
                                                    </div>

                                                    <InputBase
                                                        placeholder="Search…"
                                                        classes={{
                                                            root: classes.inputRoot,
                                                            input: classes.inputInput,
                                                        }}
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={this.handleChange}
                                                    />

                                                </div>
                                            </section>
                                        </Toolbar>
                                    </AppBar>
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["User Name", "First Name", "Last Name", "Email", "Edit", "Delete"]}
                                        tableData={this.state.tableData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(User);
