import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";

let populateForm = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];

class DebitCardFileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileUploadData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            type: "",
        };
    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type={this.state.type}
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    fileUpload = () => {
        let error = MyValidation.fileValidation(populateForm[0], this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        }

        if (this.state.fileUploadData.fileName !== undefined) {

            const formData = new FormData();
            formData.append("file", this.state.fileUploadData['fileName']);

            axios({
                method: "post",
                url: backEndServerURL + "/debitcardfileUpload/add",
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.status === 200 && response.data.message === "Wrong Excel Format!") {
                        this.setState({
                            title: "Failed!",
                            type: "warning",
                            notificationMessage: "Failed to Upload Wrong Excel Format",
                            alert: true
                        });
                    } else if (response.status === 200 && response.data.rightStatus === 0) {
                        this.setState({
                            title: "Failed!",
                            type: "warning",
                            notificationMessage: "Failed to Upload Wrong Status",
                            alert: true
                        });
                    } else if (response.status === 200) {
                        this.setState({
                            title: "Success!",
                            type: "success",
                            notificationMessage: "File Upload Successful !",
                            alert: true
                        });
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        title: "failed!",
                        type: "warning",
                        notificationMessage: "Failed to upload File",
                        alert: true
                    });
                });
        }
    };

    render() {
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >File Upload (Debit Card)</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                populateForm,
                                                this.updateComponent
                                            )}

                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                onClick={this.fileUpload}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default DebitCardFileUpload;
