import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import SignatureButton from "../Maintenance/SignatureButton";
import UploadComponent from "../Maintenance/casa/UploadComponent";


let lockerJsonForm = [
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 12
    }
];

let lockerJsonFormAfterSearch = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3
    },
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 3
    },
    {
        "varName": "lockerKey",
        "type": "text",
        "label": "Locker Key",
        "grid": 3
    },
    {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "grid": 3
    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];


const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'CITY LOCKER APPLICATION FORM'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };


class CSLockerSurrander extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            showValue: false,
            inputData: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            fileUploadData: {},
            selectedDate: {},
            uploadModal: false,
            getMappingAllImage: false,
            loaderNeeded: null,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            lockerGetData: false,
            beforeSearch: true,
            signatureCard: false,
            remarks: false,
            getRemarkList: [],
            cbNotFound: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.csRemarks = null;

                this.setState({
                    inputData: this.copyJson(response.data),
                    varValue: response.data,
                    showValue: true,
                    getData: true,
                    lockerGetData: true
                });


            })
            .catch((error) => {
                console.log(error);
            });

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let remarkData = [];

                response.data.map((res) => {

                    remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                });

                this.setState({
                    getRemarkList: remarkData,
                    remarks: true
                })

                //console.log(this.state.getRemarkList)


            })
            .catch((error) => {
                console.log(error)
            })


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    lockerSearch = () => {
        this.setState({
            lockerGetData: true,
            beforeSearch: false
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.inputData.csRemarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data)
            })
                .catch((error) => {
                    console.log(error)
                })
        }
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

        let data = this.state.inputData;
        // data.csRemarks = undefined;
        data.cs_deferal = 'NO';
        data.serviceType = 'Locker';
        data.subServiceType = 'LockerClosing';
        data.category = 'Locker';
        data.subCategory = 'Closing';
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

            var url = backEndServerURL + "/case/route/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                console.log("Successfully Routed!");

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })

                .catch((error) => {
                    console.log(error);
                });
        })
            .catch((error) => {
                console.log(error)
            });
    };
    handleSubmitDraft = (event) => {
        event.preventDefault();

        var variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        let data = this.state.inputData;
        data.serviceType = 'Locker';
        data.subServiceType = 'LockerClosing';
        data.category = 'Locker';
        data.subCategory = 'Closing';

        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);

            this.setState({
                title: "Successfull!",
                notificationMessage: "Successfully Save!",
                alert: true
            });
            this.close()
        })
            .catch((error) => {
                console.log(error)
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };


    close = () => {
        this.props.closeModal();
    };

    afterLockerSearch = () => {
        if (this.state.lockerGetData) {
            return (
                <Grid container spacing={1}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormAfterSearch, this.updateComponent)
                    }

                    <Grid item xs={12}>
                        {this.signature()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.uploadFunction()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarksTable()}
                    </Grid>
                    <Grid item xs='12'>
                        {this.renderRemarks()}
                    </Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {(this.state.inputData.bom_approval === "RETURN" || this.state.inputData.maker_update_all_info_send_to === "CS") &&
                        <button className="btn btn-outline-info btn-sm" onClick={this.handleClose}
                        >Close
                        </button>}
                    </Grid>
                </Grid>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                    classes={this.props}/>;
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        return <UploadComponent appId={this.props.appId} classes={classes}/>
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {

        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >
                        Locker Closing
                        <a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {
                            this.renderNotification()
                        }

                        {
                            this.afterLockerSearch()
                        }
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(CSLockerSurrander);
