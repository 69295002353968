import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axiosDownload from '../FunctionForFileDownload';
import TablePagination from "@material-ui/core/TablePagination";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import MailNotification from "../../workflow/CommonComponent/MailNotification";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import NTBModal from "./NTBModal";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '10px',
                fontSize: '0.8rem'
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        label: "Client Id",
        grid: 2,
    },
    {
        varName: "cardNumber",
        type: "text",
        label: "Card Number",
        grid: 2,
    },
    {
        varName: "cardProductType",
        type: "select",
        enum: [],
        label: "Card Cheque Type",
        grid: 2,
    },
    {
        varName: "cardIssueType",
        type: "select",
        enum: [
            "ETB",
            "NTB"
        ],
        label: "Card Issue Type",
        grid: 2,
    },
];

class CreditCardChequeBookManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            searchItem: "",
            mailModal: false,
            selectedRowIdArray: [],
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            loader: true,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            ntbModal: false,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 200,
            selectedCount: 0,
            allData: [],
            allColumns: [
                // {title: "Case ID", field: "caseId"},
                {title: "Row Id", field: "rowId"},
                {title: "Status", field: "status"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "cardNumber"},
                {title: "Customer Name", field: "customerName"},
                {title: "Card Product type", field: "cardChequeType"},
                {title: "Card Issue type", field: "cardIssueType"},
                {title: "Processing Date", field: "issueDate"},
                {title: "Requested By", field: "requestedBy"},
                {title: "Verified By", field: "verifiedBy"},
                {title: "Leaf option", field: "leafOption"},
                {title: "Cheque Type", field: "chequeType"},
                {title: "Currency", field: "currency"},
                {title: "Approval Date", field: "bomApprovalDate"}
            ],
            columns: [
                // {title: "Case ID", field: "caseId"},
                {title: "Row Id", field: "rowId"},
                {title: "Status", field: "status"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "cardNumber"},
                {title: "Customer Name", field: "customerName"},
                {title: "Card Product type", field: "cardChequeType"},
                {title: "Card Issue type", field: "cardIssueType"},
                {title: "Contract Date", field: "contractDate"},
                {title: "Requested By", field: "requestedBy"},
                {title: "Verified By", field: "verifiedBy"},
                {title: "Leaf option", field: "leafOption"},
                {title: "Cheque Type", field: "chequeType"},
                {title: "Currency", field: "currency"},
                {title: "Approval Date", field: "bomApprovalDate"}
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        this.fetchDataFromAPI(value)
    };

    dateConverter = (value) => {
        return value ? value.substr(0, 10) : value;
    };

    componentDidMount() {
        this.functionForGetProductType();
        let inbox_url = backEndServerURL + "/getCreditCardChequebookAll/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        rowId: response.data.content[i].id,
                        clientId: response.data.content[i].clientId,
                        cardNumber: response.data.content[i].cardNo,
                        status: response.data.content[i].status,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].caseId,
                        customerName: response.data.content[i].customerName,
                        cardChequeType: response.data.content[i].cardChequeType,
                        cardIssueType: response.data.content[i].ntbOrEtb,
                        issueDate: this.dateConverter(response.data.content[i].issueDate),
                        contractDate: this.dateConverter(response.data.content[i].contractDate),
                        requestedBy: response.data.content[i].requestedBy,
                        verifiedBy: response.data.content[i].verifiedBy,
                        leafOption: response.data.content[i].leafOption,
                        chequeType: response.data.content[i].chequeType,
                        currency: response.data.content[i].currency,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    loader: false
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    functionForGetProductType = () => {
        let inbox_url = backEndServerURL + "/cardCheque/getProductType";
        axios.get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                for (let item of SearchForm) {
                    if (item.varName === "cardProductType") {
                        item.enum = response.data
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {
        let postData = this.removeNullValue(this.state.inputData);

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        this.setState({
            loader: true,
            pageNumber: page,
            activeItem: status,
        });
        let inbox_url = backEndServerURL + "/filterCreditCardChequebook/" + status + "/" + page;
        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        rowId: response.data.content[i].id,
                        clientId: response.data.content[i].clientId,
                        cardNumber: response.data.content[i].cardNo,
                        status: response.data.content[i].status,
                        bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                        caseId: response.data.content[i].caseId,
                        customerName: response.data.content[i].customerName,
                        cardChequeType: response.data.content[i].cardChequeType,
                        cardIssueType: response.data.content[i].ntbOrEtb,
                        issueDate: this.dateConverter(response.data.content[i].issueDate),
                        contractDate: this.dateConverter(response.data.content[i].contractDate),
                        requestedBy: response.data.content[i].requestedBy,
                        verifiedBy: response.data.content[i].verifiedBy,
                        leafOption: response.data.content[i].leafOption,
                        chequeType: response.data.content[i].chequeType,
                        currency: response.data.content[i].currency,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    totalRow: response.data.totalElements,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    closeNtbModal = (value) => {
        this.setState({
            ntbModal: false,
            loader: true,
        });
        if (this.state.activeItem === "Card Service" && value !== undefined) {
            let single_url = backEndServerURL + "/intgr/getNTBCardCheque";
            axios.post(single_url, value, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForNotificationMessage("Successful", "NTB Card Generate Successful!");
                    this.fetchDataFromAPI('Card Service')
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false
                    });
                    this.functionForNotificationMessage("Failed", "NTB Card Generate Failed!");
                })
        } else if (this.state.activeItem === "Personalization & Logistic" && value !== undefined) {
            let downloadTxt = backEndServerURL + "/getCreditCardChequebookTextFile";
            axiosDownload.downloadFile(downloadTxt, 'POST', value, `${value.cardProductType}.txt`).then((res) => {
                this.fetchDataFromAPI('Personalization & Logistic');
                this.functionForNotificationMessage("Successful", "Download Successful!");
            }).catch((err) => {
                this.fetchDataFromAPI('Personalization & Logistic');
                this.functionForNotificationMessage("Failed", "Download Failed!");
            })
        } else {
            this.setState({
                loader: false
            })
        }
    };


    render() {
        const {classes} = this.props;
        const {activeItem} = this.state;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search Credit Card Cheque Book
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, 'All', '0');
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Credit Card Cheque Book)</h4>
                    </CardHeader>
                    <CardBody>
                        {
                            this.mailNotification()
                        }
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.ntbModal}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <NTBModal closeModal={this.closeNtbModal}
                                                      modalType={activeItem === "Card Service" ? "NTB" : "Download"}/>
                                        </DialogContent>
                                    </Dialog>
                                    <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                                        <Menu.Item
                                            name="All"
                                            active={activeItem === "All"}
                                            style={
                                                activeItem === "All"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            //     onClick={this.handleItemClick}
                                            onClick={e => {
                                                this.headerSelect(e, "All");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Card Service"
                                            active={activeItem === "Card Service"}
                                            style={
                                                activeItem === "Card Service"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Card Service");
                                            }}
                                        />
                                        <Menu.Item
                                            name="On Processing"
                                            active={activeItem === "On Processing"}
                                            style={
                                                activeItem === "On Processing"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "On Processing");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Failed"
                                            active={activeItem === "Failed"}
                                            style={
                                                activeItem === "Failed"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Failed");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Personalization and Logistic"
                                            active={activeItem === "Personalization & Logistic"}
                                            style={
                                                activeItem === "Personalization & Logistic"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            //     onClick={this.handleItemClick}
                                            onClick={e => {
                                                this.headerSelect(e, "Personalization & Logistic");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Download"
                                            active={activeItem === "Download"}
                                            style={
                                                activeItem === "Download"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Download");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Send to Vendor"
                                            active={activeItem === "Send to Vendor"}
                                            style={
                                                activeItem === "Send to Vendor"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Vendor");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Return from Vendor"
                                            active={activeItem === "Return from Vendor"}
                                            style={
                                                activeItem === "Return from Vendor"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Return from Vendor");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Delivered from Vendor"
                                            active={activeItem === "Delivered from Vendor"}
                                            style={
                                                activeItem === "Delivered from Vendor"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Delivered from Vendor");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Send to Courier"
                                            active={activeItem === "Send to Courier"}
                                            style={
                                                activeItem === "Send to Courier"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Courier");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Delivered"
                                            active={activeItem === "Delivered"}
                                            style={
                                                activeItem === "Delivered"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Delivered");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Return"
                                            active={activeItem === "Return"}
                                            style={
                                                activeItem === "Return"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Return");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Send to Call Center"
                                            active={activeItem === "Send to Call Center"}
                                            style={
                                                activeItem === "Send to Call Center"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Send to Call Center");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Not Reached"
                                            active={activeItem === "Not Reached"}
                                            style={
                                                activeItem === "Not Reached"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Not Reached");
                                            }}
                                        />


                                        <Menu.Item
                                            name="Destroy Request"
                                            active={activeItem === "Destroy Request"}
                                            style={
                                                activeItem === "Destroy Request"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy Request");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Destroy"
                                            active={activeItem === "Destroy"}
                                            style={
                                                activeItem === "Destroy"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy");
                                            }}
                                        />
                                    </Menu>

                                    {/*<Segment>*/}
                                    {this.tableChoice()}
                                    {/*</Segment>*/}
                                    {
                                        this.renderNotification()
                                    }
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, status, '0')
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (status === "All") {
                urlSegment = 'getCreditCardChequebookAll/0';
            } else {
                urlSegment = "getCreditCardChequebookStatus";
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            id: response.data.content[i].id,
                            rowId: response.data.content[i].id,
                            clientId: response.data.content[i].clientId,
                            cardNumber: response.data.content[i].cardNo,
                            status: response.data.content[i].status,
                            bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                            caseId: response.data.content[i].caseId,
                            customerName: response.data.content[i].customerName,
                            cardChequeType: response.data.content[i].cardChequeType,
                            cardIssueType: response.data.content[i].ntbOrEtb,
                            issueDate: this.dateConverter(response.data.content[i].issueDate),
                            contractDate: this.dateConverter(response.data.content[i].contractDate),
                            requestedBy: response.data.content[i].requestedBy,
                            verifiedBy: response.data.content[i].verifiedBy,
                            leafOption: response.data.content[i].leafOption,
                            chequeType: response.data.content[i].chequeType,
                            currency: response.data.content[i].currency,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };


    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page)
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true
                });
                console.log(status);
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/getCreditCardChequebookAll/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getCreditCardChequebookStatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                id: response.data.content[i].id,
                                rowId: response.data.content[i].id,
                                clientId: response.data.content[i].clientId,
                                cardNumber: response.data.content[i].cardNo,
                                status: response.data.content[i].status,
                                bomApprovalDate: this.dateConverter(response.data.content[i].modifiedDate),
                                caseId: response.data.content[i].caseId,
                                customerName: response.data.content[i].customerName,
                                cardChequeType: response.data.content[i].cardChequeType,
                                cardIssueType: response.data.content[i].ntbOrEtb,
                                issueDate: this.dateConverter(response.data.content[i].issueDate),
                                contractDate: this.dateConverter(response.data.content[i].contractDate),
                                requestedBy: response.data.content[i].requestedBy,
                                verifiedBy: response.data.content[i].verifiedBy,
                                leafOption: response.data.content[i].leafOption,
                                chequeType: response.data.content[i].chequeType,
                                currency: response.data.content[i].currency,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: status,
                            totalRow: response.data.totalElements,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            loader: false
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                    });
            }
        }
    };

    actionForSendToCourierForDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let download_url =
                    backEndServerURL +
                    "/getCreditCardChequebookExcelResource/Send to Courier";

                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, 'cheque-book.xlsx').then((res) => {
                    this.setState({
                        loader: false
                    });
                }).catch((err) => {
                    this.setState({
                        loader: false
                    });
                })

            }
        }
    };

    actionForCardServiceToLogistic = (event, rowData, props) => {
        if (this.state.activeItem === "Card Service" || this.state.activeItem === "Failed") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            console.log(allSelectValue[0].id);
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url = backEndServerURL + "/intgr/createCardCheque";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        // this.functionForNotificationMessage("Successful", `${response.data.successCount} status change successful and ${response.data.failCount} status change failed!`);
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI(this.state.activeItem)
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForCardServiceToNTB = (event, rowData, props) => {
        if (this.state.activeItem === "Card Service") {
            this.setState({
                ntbModal: true,
            });
        }
    };

    actionForLogisticToTextDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Personalization & Logistic") {
            // let allSelectValue = (rowData, props.data);
            // if (allSelectValue === undefined) return 0;
            this.setState({
                ntbModal: true
            });
            // let rowIdArray = [];
            //
            // if (allSelectValue != null) {
            //     for (let i = 0; i < allSelectValue.length; i++) {
            //         rowIdArray.push(allSelectValue[i].id);
            //     }
            //
            //     let single_url = backEndServerURL + "/setCreditCardChequebookStatus/Download";
            //     axios.post(single_url, rowIdArray, {
            //         headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            //     })
            //         .then(response => {
            //             this.functionForNotificationMessage("Successful", "Download Successful!");
            //             let downloadTxt = backEndServerURL + "/getCreditCardChequebookTextFile";
            //             axiosDownload.downloadFile(downloadTxt, 'POST', rowIdArray, 'card-cheque.txt');
            //             this.fetchDataFromAPI('Personalization & Logistic')
            //         })
            //         .catch(error => {
            //             console.log(error);
            //             this.setState({
            //                 loader: false
            //             });
            //             this.functionForNotificationMessage("Failed", "Download Failed!");
            //         })
            // }
        }
    };

    actionForLogisticToCourier = (event, rowData, props) => {
        if (this.state.activeItem === "Delivered from Vendor") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                mailModal: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                    this.setState({
                        selectedRowIdArray: rowIdArray
                    })
                }
            }
        }
    };

    actionForCallCenterToResend = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Call Center" || this.state.activeItem === "Destroy Request" || this.state.activeItem === "Not Reached") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Send to Courier";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Call Center')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForCourierToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Courier')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForReturnFromVendor = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Vendor") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Return from Vendor";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Vendor')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };
    actionForDeliveredFromVendor = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Vendor") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Delivered from Vendor";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Vendor')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForReturnToCallCenter = (event, rowData, props) => {
        if (this.state.activeItem === "Return") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Send to Call Center";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Return')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForCourierToReturn = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Courier") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Return";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Courier')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForCallCenterToNotReached = (event, rowData, props) => {
        if (this.state.activeItem === "Send to Call Center") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Not Reached";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Send to Call Center')
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    })
            }
        }
    };

    actionForNotReachedToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Not Reached" || this.state.activeItem === "Send to Call Center") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Not Reached')
                    })
                    .catch(error => {
                        console.log(error);
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                        this.setState({
                            loader: false
                        });
                    })
            }
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }


                let stop_cheque_book =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Destroy";

                axios
                    .post(stop_cheque_book, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Destroy Request')
                        // let single_url =
                        //     backEndServerURL +
                        //     "/updateChequebookStatus/Destroy";
                        // axios
                        //     .post(single_url, rowIdArray, {
                        //         headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }
                        //     })
                        //     .then(response => {
                        //
                        //     })
                        //     .catch(error => {
                        //         console.log(error);
                        //     });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    });

            }
        }
    };

    actionForDestroyRequestToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        console.log(error);
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    });
            }
        }
    };

    actionForDownloadToSendToVendor = (event, rowData, props) => {
        if (this.state.activeItem === "Download") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            this.setState({
                loader: true
            });
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/setCreditCardChequebookStatus/Send to Vendor";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful", "Status Change Successful!");
                        this.fetchDataFromAPI('Download')
                    })
                    .catch(error => {
                        console.log(error);
                        this.functionForNotificationMessage("Failed", "Status Change Failed!");
                    });
            }
        }
    };

    closeMailModal = (value) => {
        if (value === "cancel") {
            this.setState({
                mailModal: false
            })
        } else {
            this.setState({
                mailModal: false,
                loader: true
            });
            let postData = value;
            postData.idList = this.state.selectedRowIdArray;
            let mailUrl = backEndServerURL + "/creditCardChequebookSendMail";
            axios
                .post(mailUrl, postData, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                })
                .then(response => {
                    this.functionForNotificationMessage('Successful', "Mail Send Successful!");
                    if (this.state.activeItem === "Delivered from Vendor") {
                        let single_url =
                            backEndServerURL +
                            "/setCreditCardChequebookStatus/Send to Courier";
                        axios.post(single_url, this.state.selectedRowIdArray, {
                            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        })
                            .then(response => {
                                this.fetchDataFromAPI(this.state.activeItem)
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    }
                    if (response.status === 200) {
                        this.setState({
                            selectedRowIdArray: [],
                            loader: false
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.functionForNotificationMessage('Failed', "Mail Send Failed!");
                });
        }
    };


    mailNotification = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.mailModal}>
                <DialogContent className={classes.dialogPaper}>
                    <MailNotification closeModal={this.closeMailModal} processName="CARD CHEQUE BOOK"/>
                </DialogContent>
            </Dialog>
        )
    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Card Service") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCardServiceToNTB(event, rowData, props)}
                                            >
                                                NTB Cheque Book Request
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCardServiceToLogistic(event, rowData, props)}
                                            >
                                                Submit
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Failed") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCardServiceToLogistic(event, rowData, props)}
                                            >
                                                Submit
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Personalization & Logistic") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage, selection: false}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForLogisticToTextDownload(event, rowData, props)}
                                            >
                                                Download Txt
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Download") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDownloadToSendToVendor(event, rowData, props)}
                                            >
                                                Send to Vendor
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Send to Vendor") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForReturnFromVendor(event, rowData, props)}
                                            >
                                                Return from Vendor
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDeliveredFromVendor(event, rowData, props)}
                                            >
                                                Delivered from Vendor
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Delivered from Vendor") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForLogisticToCourier(event, rowData, props)}
                                            >
                                                Send to Courier
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Send to Courier") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForSendToCourierForDownload(event, rowData, props)}
                                            >
                                                Download Excel
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCourierToDelivered(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCourierToReturn(event, rowData, props)}
                                            >
                                                Return
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === 'Delivered') {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === 'Return') {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForReturnToCallCenter(event, rowData, props)}
                                            >
                                                Send to Call Center
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Send to Call Center") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCallCenterToResend(event, rowData, props)}
                                            >
                                                Resend
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCallCenterToNotReached(event, rowData, props)}
                                            >
                                                Not Reached
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNotReachedToDestroyRequest(event, rowData, props)}
                                            >
                                                Destroy Request
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Not Reached") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForNotReachedToDestroyRequest(event, rowData, props)}
                                            >
                                                Destroy Request
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCallCenterToResend(event, rowData, props)}
                                            >
                                                Resend
                                            </Button>

                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Destroy Request") {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyRequestToDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForCallCenterToResend(event, rowData, props)}
                                            >
                                                Resend
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === 'Destroy') {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={theme}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(CreditCardChequeBookManage);
