import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class AccountHolder extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            inputData: {},
            showValue: true,
            varValue: {},
            getData: false,
            cbNotFound: false,
            loading: false,
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            notificationMessage: "",
            alert: false,
            getAccountInformation: []
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };


    componentDidMount() {

        let accountInformationArray = [];
        accountInformationArray.push(this.createAccountInformationTable("Main CB", 1234567, "Rashed Alam"));
        this.setState({
            getAccountInformation: accountInformationArray,
            getData: true
        })
        // let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
        // axios
        //     .get(start_url, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
        //     .then(response => {
        // remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
        //         console.log(response.data.id);
        //         this.setState({
        //             appId: response.data.id
        //         });
        //         // alert(this.state.appId)
        //     })
        //     .catch(error => {
        //         console.log(error);
        //     });
    }

    createAccountInformationTable = (a, b, c) => {
        return (
            [a, b, c]
        )
    };

    renderAccountData = () => {
        if (this.state.getAccountInformation.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["CB Type", "CB Number", "Account Title"]}
                            tableData={this.state.getAccountInformation}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >
                                        Account Holder Information
                                        <a>
                                            <CloseIcon
                                                onClick={this.props.closeModal}
                                                style={{
                                                    position: "absolute",
                                                    right: 10,
                                                    color: "#000000"
                                                }}
                                            />
                                        </a>
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={1}>

                                            <Grid item xs={12}>
                                                {
                                                    this.renderAccountData()
                                                }
                                            </Grid>
                                        </Grid>

                                    </ThemeProvider>

                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>

                </React.Fragment>
            );
        } else {
            return ''
        }
    }
}

export default withStyles(styles)(AccountHolder);
