import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios/index";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import Notification from "../../../NotificationMessage/Notification";
import Table from "../../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../../JsonForm/MyValidation";
import CardHeader from "../../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import {CsPartialPaymentJsonFromReadOnly} from "./JsonFormPartialPayment";
import CommonApi from "../../Maintenance/common/CommonApi";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import ReferenceModal from "../../../DeliverableManagement/ReferenceModal";
import ErrorModal from "../Common/ErrorModal";
import Functions from "../../../../Common/Functions";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let remarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }];

class CommonApprovalPartialPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            selectedDate: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true,
            currentTask: "",
            confirmAlert: false,
            actionType: "",
            reference: false,
            referenceTitle: "",
            errorModal: false,

        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.makerRemarks = undefined;
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            getData: true,
                            showValue: true,
                            currentTask: response.data.currentTask,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            errorModal: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModal: true,
                        title: error.response.data.message,
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    errorModal: true,
                    title: error.response.data.message,
                    loading: false
                })
            });
        }
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    funtionForCaseRoute = (postData, isChecker, message) => {
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                    reference: isChecker,
                    referenceTitle: message,
                });
                if (!isChecker) {
                    this.props.closeModal();
                }

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    }
    functionForChargeCollection = () => {
        return new Promise((resolve, reject) => {
            let debitAmount = parseFloat(this.state.inputData.fee) + parseFloat(this.state.inputData.vat);
            let doFinacleTransferRequestBody = {
                "debitAcId": this.state.inputData.casaAccount,
                "debitTransactionParticulars": "fee & vat collection",
                "debitAmount": debitAmount,
                "currencyCode": "BDT",
                "numberOfCreditPart": "2",
                "creditAcId1": this.state.inputData.loanAccountSol + "0173500004", //will be add next
                "creditAcId2": this.state.inputData.loanAccountSol + "0149145008", //will be add next
                "creditTransactionParticulars1": "fee collection",
                "creditTransactionParticulars2": "vat colection",
                "creditAmount1": this.state.inputData.fee,
                "creditAmount2": this.state.inputData.vat,
            }
            let doFinacleTransferUrl = assetOpsBackEndServerURL + "/intgr/multipleFiTransaction";
            axios.post(doFinacleTransferUrl, doFinacleTransferRequestBody, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    };
    functionForPrePayment = () => {
        return new Promise((resolve, reject) => {
            let enteredLaAmt = parseInt(this.state.inputData.fee) + parseInt(this.state.inputData.vat) + parseInt(this.state.inputData.partialPaymentAmount)
            let partialPaymentApprovalRequestBody = {
                "crNumber": this.state.inputData.accountNumber,
                "drNumber": this.state.inputData.casaAccount,
                "enteredLaAmt": enteredLaAmt,
            }
            let PartialPaymentApprovalApiUrl = assetOpsBackEndServerURL + "/doLoanPrePayment/";
            axios
                .post(PartialPaymentApprovalApiUrl, partialPaymentApprovalRequestBody, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    };

    approveHandler = () => {
        let inputData = {...this.state.inputData};
        this.setState({
            loading: true,
        })
        let postData = {};
        if (this.state.currentTask === "bm_emi_date_change") {
            if (inputData.apiStage !== undefined) {
                postData = {bm_approved: "YES"}
            } else {
                postData = {bm_approved: "YES", apiStage: "chargeCollection"}
            }
        } else if (this.state.currentTask === "disbursement_maker_emi_date_change") {
            postData = {disbursement_maker_approved: "YES"}
        } else if (this.state.currentTask === "disbursement_checker_emi_date_change") {
            postData = {disbursement_checker_approved: "YES"}
        }
        if (this.state.currentTask === "disbursement_maker_emi_date_change") {
            if (inputData.apiStage === "chargeCollection") {
                this.functionForChargeCollection().then(feeCollectionResponse => {
                    inputData.apiStage = "prePayment"
                    CommonApi.setVariablesAwf(inputData, this.props.appId).then(setVarialbleResponse => {
                        this.setState({
                            loading: false,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                        })
                    }).catch(error => {
                        console.log(error);
                        this.setState({
                            errorModal: true,
                            title: this.errorObjectCheck(error),
                            loading: false,
                        })
                    });
                }).catch(error => {
                    console.log(error);
                    this.setState({
                        errorModal: true,
                        title: this.errorObjectCheck(error),
                        loading: false,
                    })
                });
            } else if (inputData.apiStage === "prePayment") {
                this.functionForPrePayment().then(prePaymentResponse => {
                    this.funtionForCaseRoute(postData, true, prePaymentResponse.data)
                }).catch(error => {
                    console.log(error);
                    this.setState({
                        errorModal: true,
                        title: this.errorObjectCheck(error),
                        loading: false,
                    })
                });
            }

        } else {
            this.funtionForCaseRoute(postData, false)

        }
    };
    returnHandler = () => {
        this.setState({
            loading: true,
        })
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        let postData = {};
        if (this.state.currentTask === "disbursement_maker_emi_date_change") {
            postData = {disbursement_maker_approved: "NO"}
        } else if (this.state.currentTask === "disbursement_checker_emi_date_change") {
            postData = {disbursement_checker_approved: "NO"}
        } else if (this.state.currentTask === "bm_emi_date_change") {
            postData = {bm_approved: "NO"}
        }
        saveDataDeferralRemarksAndRoute.map = postData;
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()
            })
            .catch(error => {
                this.setState({
                    loading: false,
                })
                console.log(error);
            });


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
    };


    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>

            )
        }

    };

    createDataTable = (key, value) => {
        return (
            [key, value]
        )
    };

    buildUpTable = () => {
        return (
            [
                this.createDataTable('Loan Type', this.state.inputData.loanType),
                this.createDataTable('Product Code', 'CSLAB'),
                this.createDataTable('Date of Disbursement', ''),
                this.createDataTable('Loan Expiry Date', this.state.inputData.loanAccountExpiryDate),
                this.createDataTable('Maturity', ''),
                this.createDataTable('LOAN', this.state.inputData.loanAccountBalance),
                this.createDataTable('Amount', this.state.inputData.loanAccountBalance),
                this.createDataTable('Fee', ''),
                this.createDataTable('Vat', this.state.inputData.vat),
                this.createDataTable('Total',),
                this.createDataTable('CASA BALANCE', '')
            ]
        )

    };

    renderStaticDataTable = () => {
        return (
            <div style={{width: "100%"}}>
                <br/>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Key", "Value"]}
                        tableData={this.buildUpTable()}
                        tableAllign={['left', 'left']}
                    />
                </div>

            </div>
        )

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    closeConfirmAlert = (value) => {
        this.setState({
            confirmAlert: false,
        });
        if (value === "ok" && this.state.actionType === "approveHandler") {
            this.approveHandler()
        } else if (value === "ok" && this.state.actionType === "returnHandler") {
            this.returnHandler()
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;

    };
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();
        if (errorRemarks === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "approveHandler") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })


        } else if (actionType === "returnHandler") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "makerRemarks",
                "type": "textArea",
                "label": "Remarks",
                "grid": 12,
                "required": true,
            });
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {
            this.validationForHandleConfirm(type)
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <React.Fragment>

                    {this.state.currentTask !== "disbursement_maker_emi_date_change" &&
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={(event) => this.handleCommon(event, "approveHandler")}
                        >
                            Submit
                        </button>
                    }
                    {this.state.currentTask === "disbursement_maker_emi_date_change" && this.state.inputData.apiStage === "chargeCollection" &&
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={(event) => this.handleCommon(event, "approveHandler")}
                        >
                            Fee & Charge Collection
                        </button>
                    }
                    {this.state.currentTask === "disbursement_maker_emi_date_change" && this.state.inputData.apiStage === "prePayment" &&
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={(event) => this.handleCommon(event, "approveHandler")}
                        >
                            Submit
                        </button>
                    }

                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleCommon(event, "returnHandler")}
                    >
                        Return
                    </button>
                </React.Fragment>
            )
        }
    }
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CsPartialPaymentJsonFromReadOnly, this.updateComponent)
            )
        }
    }
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Partial Payment</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Partial Payment{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    renderFlowSUmmeryButton = () => {

        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
            signatureModal: false,
            reference: false,
            errorModal: false,
        })
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmAlert}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.reference}>
                        <DialogContent className={classes.dialogPaper}>
                            <ReferenceModal
                                closeModal={this.closeModalCBNotFound}
                                referenceTitle={this.state.referenceTitle}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.errorModal}>
                        <DialogContent className={classes.dialogPaper}>

                            <ErrorModal
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderForm()}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                        </Grid>
                        {this.renderRemarksData()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderFlowSUmmeryButton()}
                        {this.renderRemarks()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderSubmitButton()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        )
    }

}

export default withStyles(styles)(CommonApprovalPartialPayment);
