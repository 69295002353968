import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import loader from "../../../../Static/loader.gif";
import Label from "./CasaCloserLabel";
import SignatureButton from "../SignatureButton";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import PreviewButton from "../PreviewButton";
import PreviewMappingImage from "./PreviewMappingImage";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import AccountStatement from "../../fdr/AccountStatement";

let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};


class CheckerInbox extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        customerNumber: "",
        loading: true,
        getRemarks: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        getCropedImage: false,
        previewDocument: false,
        confirmationAlert: false,
        apiType: "",
        cbNotFound: false,
        accountStatement: false,
        buttonType: ""
    };


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            let remarksArray = [];
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            });
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    this.setState({
                        inputData: this.removeNullValue(response.data),
                        varValue: this.removeNullValue(response.data),
                        appData: response.data,
                        showValue: true,
                        getData: true,
                        loaderNeeded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event, checker_approval) => {
        event.preventDefault();

        if (checker_approval === "RETURN") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            buttonType: checker_approval
        });
    };


    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>

                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "APPROVED")}
                        >Approve
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN")}
                        >Return
                        </button>
                    </div>


                </Grid>

            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    previewButton = () => {
        if (this.state.getData && this.state.inputData.accountNumber) {
            return (
                <Grid container spacing={0}>
                    <Grid item xs={12}><br/></Grid>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.state.appId} classes={this.props}/>
                </Grid>
            )
        }
    };

    onKeyDownChange = (event, data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        } else if (data) {
            this.setState({
                apiType: data,
                title: "Do you want to confirm?",
                confirmationAlert: true
            })
        }
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                loading: true,
            });
            if (this.state.buttonType === "APPROVED" || this.state.buttonType === "RETURN") {
                this.state.inputData.checker_approval = this.state.buttonType;
                let remarksData = {};
                remarksData.remark = this.state.inputData.checkerRemarks;
                remarksData.map = {...this.state.inputData, checkerRemarks: undefined};
                let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    });
                    this.props.closeModal();
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
            } else {
                this.functionForApiCall()
            }
        } else {
            this.setState({
                apiType: "",
                confirmationAlert: false
            })
        }
    };


    functionForApiCall = () => {
        console.log(this.state.apiType);
        if (this.state.apiType) {
            let url;
            let postData = {};
            let notificationMessage = "";

            if (this.state.apiType === "hacm") {
                url = backEndServerURL + "/updateCasaCloseTransferLimit/" + this.state.inputData.accountNumber;
                postData.cashDebitLimitException = this.state.inputData.cashDebitLimitException;
                postData.clearingExceptionLimit = this.state.inputData.clearingExceptionLimit;
                postData.transferExceptionLimit = this.state.inputData.transferExceptionLimit;
                notificationMessage = "Transfer Limit Update Successful!";
                this.casaCloserAPi(url, postData, notificationMessage)
            } else if (this.state.apiType === "hccfm") {
                url = backEndServerURL + "/accounts/common/casaClosureChargeCollection/" + this.state.inputData.accountNumber;
                notificationMessage = "Charge Collection Successful!";
                this.casaCloserAPi(url, postData, notificationMessage)
            } else if (this.state.apiType === "CASAEDT") {
                url = backEndServerURL + "/accounts/common/casaEd/" + this.state.inputData.accountNumber;
                notificationMessage = "ED Successful!";
                this.casaCloserAPi(url, postData, notificationMessage)
            } else if (this.state.apiType === "hcacc") {
                url = backEndServerURL + "/cASAAcctClosure";
                postData.status = this.state.inputData.hcaccTransactionType;
                postData.accountNumber = this.state.inputData.accountNumber;
                postData.xferacctNumber = this.state.inputData.hcaccTransferAcId;
                notificationMessage = "Successfully Closed!";
                this.casaCloserAPi(url, postData, notificationMessage)
            } else {
                this.setState({
                    loading: false
                })
            }
        }
    };

    casaCloserAPi = (url, postData, notificationMessage) => {
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: notificationMessage,
                    alert: true
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    title: error.response.data.message,
                    cbNotFound: true
                })
            });
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label input={this.state.inputData} stopLoading={this.stopLoading} classes={this.props}/>
                    {this.previewButton()}
                    {CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, "", this.onKeyDownChange)}
                </React.Fragment>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };


    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmationAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmation}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.accountStatement}>
                                <DialogContent className={classes.dialogPaper}>
                                    <AccountStatement closeModal={this.closeStatement}
                                                      accountNumber={this.state.inputData.accountNumber}/>
                                </DialogContent>
                            </Dialog>
                            <ThemeProvider theme={theme}>
                                <Grid container spacing={0}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </Grid>
                                {this.renderRemarksData()}
                                <Grid item xs={12}><br/></Grid>
                                {this.viewAttachedImages()}
                                {this.renderRemarks()}
                                {this.renderSubmitButton()}
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>

        )
    }
}

export default withStyles(styles)(CheckerInbox);
