import React, {Component} from 'react';
import FormSample from "./FormSample";
import {ThemeProvider} from "@material-ui/styles";
import theme from "./CustomeTheme";
import Grid from "@material-ui/core/Grid";
import CommonJsonFormComponent from "./CommonJsonFormComponent";


var jsonFormForName = [
    {
        "varName": "name",
        "type": "text",
        "label": "name"
    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth"
    },
    {
        "varName": "password",
        "type": "password",
        "label": "Password"
    },
    {
        "varName": "smsService",
        "type": "checkbox",
        "label": "Sms Service"

    },
    {
        "varName": "skill",
        "type": "radio",
        "label": "skill",
        "enum": [
            "A",
            "B"
        ]
    },
    {
        "varName": "country",
        "type": "dropdown",
        "label": "country",

    },
    {
        "varName": "bom_approval",
        "type": "select",
        "label": "Approval ?",
        "enum": [
            "APPROVED",
            "NOTAPPROVED"
        ]
    }

];
var formSample = {
    "variables": [
        {
            "varName": "name",
            "type": "text",
            "label": "name"
        },
        {
            "varName": "dob",
            "type": "date",
            "label": "Date Of Birth"
        },
        {
            "varName": "password",
            "type": "password",
            "label": "Password"
        },
        {
            "varName": "smsService",
            "type": "checkbox",
            "label": "Sms Service"

        },
        {
            "varName": "skill",
            "type": "radio",
            "label": "skill",
            "enum": [
                "A",
                "B"
            ]
        },
        {
            "varName": "country",
            "type": "dropdown",
            "label": "country",

        },
        {
            "varName": "bom_approval",
            "type": "select",
            "label": "Approval ?",
            "enum": [
                "APPROVED",
                "NOTAPPROVED"
            ]
        }

    ]

};

class TestForm extends Component {
    state = {
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {}
    };

    componentDidMount() {
        this.state.dropdownSearchData["country"] = [
            {
                "label": "Bangladesh",
                "value": "BD"
            },
            {
                "label": "Banewdf",
                "value": "43"
            },
            {
                "label": "Bfret",
                "value": "Bey"
            },
            {
                "label": "Bedhd",
                "value": "D"
            },
        ];

        this.updateComponent();
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
    };

    render() {
        return (

            <div>
                <Grid container spacing={3}>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, jsonFormForName, this.updateComponent)
                        }
                    </ThemeProvider>
                </Grid>

                <br/>
                <br/>
                <button onClick={this.handleSubmit}>Submit</button>
                <FormSample grid="12" buttonName="Search"
                            validation="INDIVIDUAL" jsonForm={formSample}/>

            </div>


        )
    }

}

export default TestForm;