import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "callCategory",
        type: "text",
        label: "Call Category",
        validation: "string",
        grid: 2,
        required: true,
        multiline: true
    },
    {
        varName: "slaValue",
        type: "text",
        label: "SLA DAY",
        validation: "numeric",
        grid: 2,
        required: true,
        multiline: true
    }
];

const customerCategoryList = [
    {title: "Account"},
    {title: "AMEX"},
    {title: "VISA/Master"},
    {title: "Others"},
];

class CreateCallCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: false,
            alert: false,
            title: "",
            notificationMessage: "",
            disabled:false,
        };
    }


    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleChangeMultipleSelect = (event, option) => {
        event.preventDefault();
        let customerCategory = [];
        if (option !== null) {
            for (let item of option) {
                customerCategory.push(item.title)
            }
        }
        this.state.inputData.customerCategory = customerCategory
    };

    handleSubmit = () => {
        this.setState({
            disabled:true
        })
        let customerCategory = [
            {
                varName: "customerCategory",
                required: true
            }
        ];
        let error = MyValidation.defaultValidation(jsonForm.concat(customerCategory), this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let url = backEndServerURL + "/AddNewCardCallCategory";
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let failedString = "";
                    let successString = "";
                    if (response.data.failedList.length > 0) {
                        response.data.failedList.map(item => {
                            if (failedString.length > 0) {
                                failedString = failedString + "," + item
                            } else {
                                failedString = item
                            }
                        })
                    }
                    if (response.data.successList.length > 0) {
                        response.data.successList.map(item => {
                            if (successString.length > 0) {
                                successString = successString + "," + item
                            } else {
                                successString = item
                            }
                        })
                    }
                    this.setState({
                        disabled: false,
                        title: "Successful",
                        notificationMessage: successString + " " + failedString,
                        alert: true
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 5000)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false,
                        title: "Warning",
                        notificationMessage: "Failed to Create Call Category!",
                        alert: true
                    })
                })
        }
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Add New Call Category
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                {this.renderNotification()}
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent
                                    )}
                                    <Grid item xs={2}>

                                        <Grid item>
                                            <label className="input-label-common">Customer Category *</label>
                                        </Grid>
                                        <Grid item>
                                            <Autocomplete
                                                multiple
                                                id="tags-standard"
                                                helperText={this.state.errorMessages["customerCategory"]}
                                                error={this.state.errorArray["customerCategory"]}
                                                required={true}
                                                options={customerCategoryList}
                                                onChange={(event, option) => this.handleChangeMultipleSelect(event, option)}
                                                getOptionLabel={option => option.title}
                                                size="small"
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        helperText={this.state.errorMessages["customerCategory"]}
                                                        error={this.state.errorArray["customerCategory"]}
                                                        variant="outlined" fullWidth
                                                    />

                                                )}
                                            />
                                        </Grid>

                                    </Grid>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                            marginLeft: "12px"
                                        }}
                                        onClick={this.handleSubmit}
                                        disabled={this.state.disabled}
                                    >
                                        Submit
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(CreateCallCategory);
