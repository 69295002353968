import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "../../Maintenance/static/LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            inputValue: {},
            showValue: false,
            labelData: [],
            linkedCB: [],
            getData: false,
        }
    }

    componentDidMount() {
        this.accountInfoDetails();
    }

    accountInfoDetails = () => {
        let getAccountUrl = assetOpsBackEndServerURL + "/getAccountInfo/" + this.props.accountNumber;
        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAcResponse) => {
                // let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequestWithAccountNumberList/" + this.props.accountNumber;
                // axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                //     .then((acResponse) => {
                        let labelData = [];
                        if (getAcResponse.data.crcashlim) {
                            labelData.push({
                                name: 'Account Limit', value: "",
                                popup: true,
                                popupValue: 'accountLimit'
                            })
                        }
                        // if (acResponse.data.lienDetails && acResponse.data.lienDetails.length > 0) {
                        //     labelData.push({name: 'Lien', value: "", popup: true, popupValue: 'Lien'})
                        // }
                        // if (acResponse.data.freezeCode || acResponse.data.freezeReasonCode) {
                        //     labelData.push({
                        //         name: 'Freeze status',
                        //         value: "",
                        //         popup: true,
                        //         popupValue: 'Freeze'
                        //     })
                        // }
                        // if (acResponse.data.acctStatus === "D") {
                        //     labelData.push({name: 'Dormancy', value: "", popup: true, popupValue: 'Dormancy'})
                        // }
                        setTimeout(() => {
                            this.setState({
                                labelData: labelData,
                                // inputValue: acResponse.data,
                                inputValue: getAcResponse.data,
                                customerTypeCB: getAcResponse.data.customerTypeCB,
                                showValue: true,
                                getData: true,
                            }, this.parentLoad);
                        }, 1000)
                    // })
                    // .catch((error) => {
                    //     console.log(error);
                    //     this.props.stopLoading(error)
                    // });
            })
            .catch((error) => {
                console.log(error);
                this.props.stopLoading(error)
            });
    }

    parentLoad = () => {
        if (this.props.stopLoading !== undefined) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (
                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "10px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>
                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                               labelName={this.state.labelName} data={this.state.labelData}
                                               inputValue={this.state.inputValue}
                                               accountNumber={this.props.accountNumber}
                                               crmStatus={this.state.customerTypeCB}
                                               cbNumber={this.props.cbNumber} create={this.props.create}/>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        } else {
            return ""
        }
    }


}

export default Label;
