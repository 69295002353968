import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import ShowDocument from './ShowDocuments'
import {
    CsCreditTransactionJsonFormForCard,
    CsDebitTransactionJsonFormForCard,
} from '../../workflow/WorkflowJsonFormRashed';
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class CsOpening extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            varValue: {},
            showValue: false,
            appId: 0,
            getCheckerList: [],
            checkerListShow: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            message: "",
            notificationMessage: "",
            getData: false,
            documentModal: false,
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    close = () => {
        this.props.closeModal()
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    open = (e) => {
        e.preventDefault();
        this.setState({
            documentModal: true
        })
    };

    documentModal = () => {
        this.setState({
            documentModal: false
        })
    };


    componentDidMount() {
        console.log(this.props.appId);

        if (this.props.appId !== undefined) {
            let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.get(variablesUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    this.setState({
                        inputData: this.copyJson(response.data),
                        varValue: response.data,
                        getData: true,
                        showValue: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            let url = backEndServerURL + "/startCase/cs_card_cheque";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);


                    this.setState({
                        appId: response.data.id,
                        getData: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }

    renderJsonFormForDebit = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, CsDebitTransactionJsonFormForCard, this.updateComponent)

            )
        }
    };

    renderJsonFormForCredit = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, CsCreditTransactionJsonFormForCard, this.updateComponent)

            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);


        let error = MyValidation.defaultValidation(CsDebitTransactionJsonFormForCard, this.state);
        let errorTwo = MyValidation.defaultValidation(CsCreditTransactionJsonFormForCard, this.state);
        this.forceUpdate();

        if (error === true || errorTwo === true) {
            return 0;
        }


        var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

        let data = this.state.inputData;
        data.serviceType = "Card Cheque";
        data.subServiceType = "Card Cheque Request";
        data.cbNumber = this.state.inputData.debitCardMemberName;
        data.customerName = this.state.inputData.debitCardMemberName;
        data.accountNumber = this.state.inputData.creditAccountNumber;
        data.category = "Card";
        data.subCategory = "Card Cheque";
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log("Successfully Routed!");

                        if (this.props.appId !== undefined) {
                            this.close()
                        }
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            alert: true

                        });

                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    render() {

        const {classes} = this.props;
        if (this.state.loading === true) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h6>New
                            Customer
                            Account Opening<a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h6>

                    </CardHeader>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                </Card>


            )
        } else if (this.props.closeModal) {
            return (

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                <h4 style={{color: "white"}} >Card Cheque <a><CloseIcon onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <h4 style={{
                                        marginTop: "15px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>Debit Transaction</h4>
                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>

                                            {
                                                this.renderJsonFormForDebit()
                                            }

                                            <button
                                                style={{
                                                    marginTop: '10px'
                                                }}
                                                className="btn btn-outline-primary btn-sm"
                                                type='submit'
                                                onClick={this.open}
                                            >
                                                Signature
                                            </button>

                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="md"
                                                open={this.state.documentModal}>
                                                <DialogContent>
                                                    <ShowDocument closeModal={this.documentModal}
                                                                  appId={this.props.appId}/>
                                                </DialogContent>
                                            </Dialog>


                                            {
                                                this.renderNotification()
                                            }

                                        </ThemeProvider>

                                    </Grid>
                                </div>
                                <div>
                                    <h4 style={{
                                        marginTop: "20px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>Credit Transaction</h4>
                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>


                                            {
                                                this.renderJsonFormForCredit()
                                            }

                                        </ThemeProvider>

                                    </Grid>
                                </div>


                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: "10px",
                                    }}
                                    onClick={this.handleSubmit}

                                >
                                    Submit
                                </button>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        } else {
            return (

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>


                                <h4 style={{color: "white"}} >Card Cheque</h4>
                            </CardHeader>
                            <CardBody>

                                <div>
                                    <h4 style={{
                                        marginTop: "15px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>Debit Transaction</h4>
                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>

                                            {
                                                this.renderJsonFormForDebit()
                                            }


                                            {
                                                this.renderNotification()
                                            }

                                        </ThemeProvider>

                                    </Grid>
                                </div>
                                <div>
                                    <button
                                        style={{
                                            marginTop: '10px'
                                        }}
                                        className="btn btn-outline-primary btn-sm"
                                        type='submit'
                                        onClick={this.open}
                                    >
                                        Signature
                                    </button>

                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        open={this.state.documentModal}>
                                        <DialogContent>
                                            <ShowDocument closeModal={this.documentModal} appId={this.props.appId}/>
                                        </DialogContent>
                                    </Dialog>
                                    <h4 style={{
                                        marginTop: "20px",
                                        marginBottom: '15px',
                                        textTransform: "capitalize",
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}>Credit Transaction</h4>
                                </div>

                                <div>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>


                                            {
                                                this.renderJsonFormForCredit()
                                            }

                                        </ThemeProvider>

                                    </Grid>
                                </div>


                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: "10px",
                                    }}
                                    onClick={this.handleSubmit}

                                >
                                    Submit
                                </button>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        }


    }


}

export default withStyles(styles)(CsOpening);
