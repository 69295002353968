import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class VerifyMerchantPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            loading: false,
            // getData: true,
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
        };


    }


    componentDidMount = () => {

        console.log(this.props.documentList);

    };


    renderImage = () => {

        // if (LocalstorageEncrypt.encryptStorageFunction.getItem("username") === "call33") {
        //     let merchantApprovalMemo;
        //
        //     this.props.documentList.map((document) => {
        //         if (document.indexOf("MERCHANT APPROVAL MEMO") > -1) {
        //             merchantApprovalMemo = document;
        //         }
        //     });
        //     return (
        //         <React.Fragment>
        //             <img width='100%'
        //                  src={backEndServerURL + "/file/" + merchantApprovalMemo + "/" + sessionStorage.getItem("accessToken")}
        //                  alt=""/>
        //         </React.Fragment>
        //
        //     )
        // } else {
        return (
            <Grid container spacing={1}>
                {
                    this.props.documentList.map((data, index) => {
                        return (
                            this.renderDataShow(data, index)
                        )

                    })

                }
            </Grid>
        )
        // }

    };

    renderDataShow = (data, index) => {
        let splitData = (data.split("_")[1]).split(".")[0];
        return (
            <div>
                <center>
                    <b>{splitData}</b>
                </center>
                <img width='90%' src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                     alt=""/>
            </div>
        )
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    {this.renderImage()}
                </div>
            </GridList>

        )
    }


}

export default withStyles(styles)(VerifyMerchantPhoto);
