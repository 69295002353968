import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL, frontEndServerURL} from "../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Badge, Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {buttonStyle, commonInboxTabActive, commonInboxTabInactive, localization, options, tabActive, tabInactive, tableIcons} from "../DeliverableManagement/configuration";
import Notification from "../NotificationMessage/Notification";
import Forward from "../DeliverableManagement/Forward";
import FailedListModal from "../DeliverableManagement/WelcomeLetter/FailedListModal";
import InsuranceMasterData from "./InsuranceMasterData";
import EditRequestInitialization from "./EditRequestInitialization";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5',
            }
        },

    }
});

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
  
];

class CsChecqueBookManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            autoComplete: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: true,
            uploadModal: false,
            searchItem: "",
            merchantModal: false,
            caseId: "",
            title: "",
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            loader: true,
            totalRow: 0,
            rowsPerPage: 100,
            selectedCount: 0,
            forwardModal: false,
            forwardArray: [],
            loading: false,
            failedList: [],
            failedModal: false,
            allData: [],
            columns: [
 
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
                {title: "Remarks", field: "remarks"},
                {title: "Status", field: "status"},
                {title: "Branch Name", field: "branchName"},
                {title: "Action", field: "action"},
               
            ],
            withoutActionBranchColumnname: [
 
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
                {title: "Remarks", field: "remarks"},
                {title: "Status", field: "status"},
                {title: "Branch Name", field: "branchName"},
               //{title: "action", field: "action"},
               
            ],
            branchReceivedColumns: [
                {title: "Action", field: "action"},           
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},                 
                {title: "Branch Name", field: "branchName"},
                {title: "Remarks", field: "remarks"},
                {title: "Status", field: "status"},
                
            ],
            branchReceivedWithoutBranchColumns: [
                {title: "Status", field: "status"},     
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
                {title: "Remarks", field: "remarks"},                 
                {title: "Action", field: "action"},
            ],
            withoutActionBranchColumnname: [
                             
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
               // {title: "Remarks", field: "remarks"},
                //{title: "Branch Name", field: "branchName"},
                {title: "Status", field: "status"},
                {title: "Action", field: "action"},
             ],
             withoutBranchColumnname: [
                             
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
                {title: "Remarks", field: "remarks"},
                {title: "Status", field: "status"},
                {title: "Action", field: "action"}
             ],
             withoutActionColumnname: [
                             
                {title: "Stamp Type", field: "stampType"},
                {title: "Quantity", field: "quantity"},
                {title: "Remarks", field: "remarks"},
                {title: "Branch Name", field: "branchName"},
                 {title: "Status", field: "status"},
                // {title: "action", field: "action"}
             ]
             
             
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        this.setState({
            loader: true,
            pageNumber: 0
        });
        if (value === "All") {
            // this.fetchDataFromAPI('All', 'getBranchAll/page/0')
            this.fetchDataFromAPI('All', 'getStampAll/page/0')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, page, status) => {

        let input = {...this.state.inputData};
        input.currency = this.state.inputData.currency ? this.state.inputData.currency.value : "";
        // input.branch = this.state.inputData.branch ? this.state.inputData.branch.value : "";
        let postData = this.removeNullValue(input);
        this.forceUpdate();

        if (Object.keys(postData).length === 0) {
            return 0;
        }

        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }
        let inbox_url;
        if (status === "All") {
            inbox_url = backEndServerURL + "/filterChequebookDateCustomerAccountBranch/" + status + "/" + page;
        } else {
            inbox_url = backEndServerURL + "/filterBranchChequebookDateCustomerAccount/" + status + "/" + page;
        }

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        stampType: response.data.content[i].stampType,
                        quantity: response.data.content[i].quantity,
                        remarks: response.data.content[i].remarks,
                        branchName:response.data.content[i].branchName,
                        status: response.data.content[i].status,
                        action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    loader: false,
                })
            });
    };

    dateConverter = (value) => {

        let dateString = value.substr(0, 10);
        return dateString
    };

    handleChanges = (id,quantity) => {
       if (  this.state.activeItem === "New" || this.state.activeItem === "Goods Received" || this.state.activeItem === "Purchasing"  || this.state.activeItem === "Purchasin" || this.state.activeItem=== "Purchase Request") {
            return <a className="btn btn-sm alert-primary" style={{
                backgroundColor:'#142398',
                color: "white",
            }}
                      onClick={() => this.editData(id)}>Edit</a>
        }

    };

    editData = (id) => {
       this.setState({
           editMode:true,
           getId:id
       })
    };


    fetchDataFromAPI = (status, url) => {
        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true,
            });

            let urlSegment;
            let inbox_url;
           
            if (url === undefined) {
                urlSegment = 'getstampstatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else if (status === "Goods Received") {
                inbox_url = backEndServerURL + "/getstampstatus/Purchase Request/0"

            }else if (status === "Purchase Request") {
                inbox_url = backEndServerURL + "/getTotalstampstatus/Purchase Request/0"
            }
            else if (status === "Purchasing") {
                inbox_url = backEndServerURL + "/getTotalstampstatus/Purchasing/0"
            } else if (status === "Allocated") {
                inbox_url = backEndServerURL + "/getstampstatusForRequisitionTab/Allocated/0"
            }
            else if (status === "GR By Branch") {
                inbox_url = backEndServerURL + "/getstampstatus/GR By Branch/0"
            }
            else if (status === "Available Stock") {
                inbox_url = backEndServerURL + "/getAvailableStock/Available Stock/0"
            }
            else if (status === "Sold") {
                inbox_url = backEndServerURL + "/getAvailableStock/Sold/0"
            }
            else if (status === "Re Order") {
                inbox_url = backEndServerURL + "/getReorderdataList/0"
            }
            else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        if(status === "Available Stock"){
                            rowData = {
                                id: response.data.content[i].id,
                                stampType: response.data.content[i].stampType,
                                quantity: response.data.content[i].availableStock,
                                remarks: response.data.content[i].remarks,
                                status: response.data.content[i].status,
                                branchName:response.data.content[i].branchName,
                                action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                            };
                        }
                        else if(status === "Sold"){
                            rowData = {
                                id: response.data.content[i].id,
                                stampType: response.data.content[i].stampType,
                                quantity: response.data.content[i].stock,
                                remarks: response.data.content[i].remarks,
                                status: response.data.content[i].status,
                                branchName:response.data.content[i].branchName,
                                action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                            };
                        }
                        else if(status === "Purchase Request" || status === "Goods Received" ||    status === "Purchasing"){
                            rowData = {
                                id: response.data.content[i].id,
                                stampType: response.data.content[i].stampType,
                                quantity: response.data.content[i].quantity,
                                remarks: response.data.content[i].remarks,
                                status: response.data.content[i].status,
                                branchName:response.data.content[i].branchName,
                                action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                            };
                        }
                        else {
                            rowData = {
                                id: response.data.content[i].id,
                                stampType: response.data.content[i].stampType,
                                quantity: response.data.content[i].availableStock,
                                remarks: response.data.content[i].remarks,
                                status: response.data.content[i].status,
                                branchName:response.data.content[i].branchName,
                                action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                            };
                        }

                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false,
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.state.searchItem !== "") {
            this.searchData(this, page, status);
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true
                });
                let urlSegment;
                if (status === undefined) {
                    urlSegment = 'getstampstatus';
                } else {
                    urlSegment = status;
                }
    
                if (status === 'All') {
                    inbox_url = backEndServerURL + "/" + urlSegment;
                } else if (status === "Goods Received") {
                    inbox_url = backEndServerURL + "/getstampstatus/Purchase Request/0"
                }else if (status === "Purchase Request") {
                    inbox_url = backEndServerURL + "/getTotalstampstatus/Purchase Request/0"
                }
                else if (status === "Purchasing") {
                    inbox_url = backEndServerURL + "/getTotalstampstatus/Purchasing/0"
                } else if (status === "Allocated") {
                    inbox_url = backEndServerURL + "/getstampstatusForRequisitionTab/Allocated/0"
                }
                else if (status === "GR By Branch") {
                    inbox_url = backEndServerURL + "/getstampstatus/GR By Branch/0"
                }
                else if (status === "Available Stock") {
                    inbox_url = backEndServerURL + "/getAvailableStock/Available Stock/0"
                }
                else if (status === "Sold") {
                    inbox_url = backEndServerURL + "/getAvailableStock/Sold/0"
                }
                else if (status === "Re Order") {
                    inbox_url = backEndServerURL + "/getReorderdataList/0"
                }
                else {
                    inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            if(status === "Available Stock"){
                                rowData = {
                                    id: response.data.content[i].id,
                                    stampType: response.data.content[i].stampType,
                                    quantity: response.data.content[i].availableStock,
                                    remarks: response.data.content[i].remarks,
                                    status: response.data.content[i].status,
                                    branchName:response.data.content[i].branchName,
                                    action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                                };
                            }
                            else if(status === "Sold"){
                                rowData = {
                                    id: response.data.content[i].id,
                                    stampType: response.data.content[i].stampType,
                                    quantity: response.data.content[i].stock,
                                    remarks: response.data.content[i].remarks,
                                    status: response.data.content[i].status,
                                    branchName:response.data.content[i].branchName,
                                    action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                                };
                            }
                            else {
                                rowData = {
                                    id: response.data.content[i].id,
                                    stampType: response.data.content[i].stampType,
                                    quantity: response.data.content[i].quantity,
                                    remarks: response.data.content[i].remarks,
                                    status: response.data.content[i].status,
                                    branchName:response.data.content[i].branchName,
                                    action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                                };
                            }
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: 'All',
                            pageNumber: page,
                            totalRow: response.data.totalElements,
                            loader: false
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    });
            }
        }
    };

    componentDidMount() {
        //inbox call
        // this.getBranchAll();
        this.dynamicApiCall("currency");

        // {
        //     this.dynamicApiCall("branch")
        // }
        // let inbox_url = backEndServerURL + "/getBranchAll/page/0";
        let inbox_url = backEndServerURL + "/getStampAll/page/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                console.log(response);
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        id: response.data.content[i].id,
                        stampType: response.data.content[i].stampType,
                        quantity: response.data.content[i].quantity,
                        remarks: response.data.content[i].remarks,
                        status: response.data.content[i].status,
                        branchName:response.data.content[i].branchName,
                        action: this.handleChanges(response.data.content[i].id,response.data.content[i].quantity)
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    dynamicApiCall = (apiType) => {
        let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    // if(form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch"){
                    //     form.enum = response.data
                    // }
                    if (form.type === "autoCompleteValueReturn" && form.varName === "currency" && apiType === "currency") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    resetHandler = () => {
        window.location.reload();
    };

    closeModal=()=>{
        this.setState({
            editMode:false
        })
       this.fetchDataFromAPI(this.state.activeItem)
    }


    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
            <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
               {/* // permission, activeItem, 0, name */}
                  {this.renderMenuItem("All", "All", this.state.getCountGroupInbox, "All Request")}
                  {this.renderMenuItem("New", "New", this.state.getCountGroupInbox, "New Request")}
                  {this.renderMenuItem("Purchase Request", "Purchase Request", this.state.getCountGroupInbox, "Purchase Request")}
                  {/* New tab purchasing*/}
                  {this.renderMenuItem("Purchasing", "Purchasing", this.state.getCountGroupInbox, "Purchasing")}
                  {this.renderMenuItem("Goods Received", "Goods Received", this.state.getCountGroupInbox, "Goods Received")}
                  {/* {this.renderMenuItem("Allocated", "Allocated", this.state.getCountGroupInbox, "Allocated")} */}
                  {this.renderMenuItem("GR By Branch", "GR By Branch", this.state.getCountGroupInbox, "GR By Branch")}                         
                  {this.renderMenuItem("Available Stock", "Available Stock", this.state.getCountGroupInbox, "Available Stock")}
                  {this.renderMenuItem("Sold", "Sold", this.state.getCountGroupInbox, "Sold")}
                  {this.renderMenuItem("Re Order", "Re Order", this.state.getCountGroupInbox, "Re Order")}      
                      </Menu> 
            </div>
        );
    };
    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge  color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge  color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };
    renderMenuItem = (permission, activeItem, badge, name) => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("menus")).indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                    {this.renderBadge(badge, name)}
                </Menu.Item>
            )
        }

    };
    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        console.log(this.state.inputData.callStatus);
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )

        } else {
            return (
                <div>
                       <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{

                                color: "white",
                            }} >Stamp Tracker</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="deliverable">
                                {this.failedListModal()}
                                <React.Fragment>
                                    <div>
                                        {this.renderMenu()}
                                      

                                        {/*<Segment>*/}
                                        {this.tableChoice()}
                                        {/*</Segment>*/}
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="md"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.forwardModal}>
                                            <DialogContent className={classes.dialogPaper}>
                                                <Forward closeModal={this.closeForwardModal}/>
                                            </DialogContent>
                                        </Dialog>

                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            open={this.state.editMode}>
                                            <DialogContent>
                                                <EditRequestInitialization close={this.closeModal} getId={this.state.getId}
                                                                     closeModal={this.closeModal}/>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                </React.Fragment>
                                {
                                    this.renderNotification()
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    };

    closeForwardModal = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                forwardModal: false,
                loader: true
            });
            let single_url = backEndServerURL + "/forwardChequeBookToAnotherBranch";
            let postData = {
                forwardTo: value.branchName.key,
                idList: this.state.forwardArray
            };

            axios.post(single_url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetchDataFromAPI('Purchase Request')
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.setState({
                forwardModal: false,
            });
        }
    };


    actionForDeliverToBranchToBranchReceived = (event, rowData, props) => {
        if (this.state.activeItem === "New") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                this.setState({
                    loader: true
                });
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/distinctStamprequisitionsave";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('New')
                        }
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Successful!", "Status Change Failed");
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Purchase Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {

                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url = backEndServerURL + "/updateChequebookStatus/Purchase Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Purchase Request')
                        }
                    })
                    .catch(error => {
                        this.functionForNotificationMessage("Failed!", "Status Change Failed");
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToForward = (event, rowData, props) => {
        if (this.state.activeItem === "Ready for Purchasing") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    forwardArray: rowIdArray,
                    forwardModal: true
                })
            }
        }
    };

    actionForPurchasing = (event, rowData, props) => {
        if (this.state.activeItem === "Purchasing") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/totalStampStatusChangeRequest/Purchasing";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };
    actionPurchasing = (event, rowData, props) => {
        if (this.state.activeItem === "Purchase Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/totalStampStatusChangeRequest/Purchase Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };
    actionForGoodReceive = (event, rowData, props) => {
        if (this.state.activeItem === "Purchasing") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/totalStampStatusChangeRequest/Purchasing";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };
    actionForAllocation = (event, rowData, props) => {
        if (this.state.activeItem === "Goods Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/createStampStatusChangeRequest/Goods Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };
    actionForGRByBranch = (event, rowData, props) => {
        if (this.state.activeItem === "GR By Branch") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/createStampStatusChangeRequest/GR By Branch";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Purchase Request" || this.state.activeItem === "Allocated") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateChequebookStatus/Cheque Acknowledgement";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI(this.state.activeItem)
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Allocated") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/acknowledgeChequebook/Waiting for Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.status === 200 && response.data.failedAccountNumberList.length > 0) {
                                this.setState({
                                    failedList: response.data.failedAccountNumberList,
                                    failedModal: true,
                                })
                            }
                            if (response.status === 200 && response.data.successful > 0) {
                                this.functionForNotificationMessage("Successful!", `${response.data.successful} Status Update Successful and ${response.data.failed} Status Update Failed`);
                            }
                            this.fetchDataFromAPI(this.state.activeItem);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    closeFailedModal = () => {
        this.setState({
            failedList: [],
            failedModal: false
        })
    };

    failedListModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.failedModal}>
                <DialogContent className={classes.dialogPaper}>
                    <FailedListModal titleHeader="Cheque Book Acknowledge Failed List"
                                     closeModal={this.closeFailedModal}
                                     failedList={this.state.failedList}/>
                </DialogContent>
            </Dialog>
        )
    };


    actionForDestroyRequestToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Allocated") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let single_url = backEndServerURL + "/updateChequebookStatus/Delivered to Customer";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Change Successful");
                            this.fetchDataFromAPI('Allocated')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Allocated") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let ababilArray = [];
            let finacleArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    if (allSelectValue[i].source === "ABABIL") {
                        ababilArray.push(allSelectValue[i].id);
                    } else if (allSelectValue[i].source === "FINACLE") {
                        finacleArray.push(allSelectValue[i].id);
                    }
                }
                if (finacleArray.length > 0) {
                    let stop_cheque_book =
                        backEndServerURL +
                        "/stopchequenumber/";

                    axios
                        .post(stop_cheque_book, finacleArray, {
                            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        })
                        .then(response => {
                            if (response.status === 200) {
                                this.functionForNotificationMessage("Successful!", "Cheque Book Destroy Successful");
                                this.fetchDataFromAPI('Allocated')
                            }
                        })
                        .catch(error => {
                            this.functionForNotificationMessage("Failed!", "Cheque Book Destroy Failed");
                            console.log(error);
                        });
                }
                if (ababilArray.length > 0) {
                    let single_url = backEndServerURL + "/updateChequebookStatus/Destroy";

                    axios
                        .post(single_url, ababilArray, {
                            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                        })
                        .then(response => {
                            if (response.status === 200) {
                                this.functionForNotificationMessage("Successful!", "Cheque Book Destroy Successful");
                                this.fetchDataFromAPI('Allocated')
                            }
                        })
                        .catch(error => {
                            this.functionForNotificationMessage("Failed!", "Cheque Book Destroy Failed");
                            console.log(error);
                        });
                }
            }
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
            loading:false
        })

    };

    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.withoutActionColumnname}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //New button for microsoft word download
            );
        } else if (this.state.activeItem === "New" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDeliverToBranchToBranchReceived(event, rowData, props)}
                                            >
                                                {/* Purchase Request */}
                                                Purchase Request
                                            </Button>

                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'New')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        else if (this.state.activeItem === "Purchase Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.withoutActionBranchColumnname}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                       
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionPurchasing(event, rowData, props)}
                                            >
                                                Purchasing
                                                {/* Goods Received */}
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Purchase Request')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        else if (this.state.activeItem === "Purchasing" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.withoutActionBranchColumnname}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                       
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForGoodReceive(event, rowData, props)}
                                            >
                                                {/* Purchasing */}
                                                Goods Received
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Purchasing')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        // else if (this.state.activeItem === "Goods Received" && !this.state.loader) {
        //     return (
        //         <div>
        //             <MuiThemeProvider theme={themeO}>
        //                 <div style={{maxWidth: "100%"}}>
        //                     <MaterialTable
        //                         style={{whiteSpace: "nowrap"}}
        //                         columns={this.state.withoutBranchColumnname}
        //                         data={this.state.allData}
        //                         title={" "}
        //                         options={options}
        //                         localization={localization}
        //                         icons={tableIcons}
        //                         components={{
        //                             Actions: props => (
        //                                 <React.Fragment>
                       
        //                                     <Button
        //                                         style={buttonStyle}
        //                                         onClick={(event, rowData) => this.actionForGoodReceive(event, rowData, props)}
        //                                     >
        //                                         {/* Goods Receive */}
        //                                         Allocated
        //                                     </Button>
        //                                 </React.Fragment>
        //                             ),
        //                             Pagination: props => (
        //                                 <TablePagination
        //                                     {...props}
        //                                     rowsPerPageOptions={[100]}
        //                                     count={this.state.totalRow}
        //                                     page={this.state.pageNumber}
        //                                     onChangePage={(e, page) => {
        //                                         this.handleChangePage(page, 'Goods Received')
        //                                     }
        //                                     }
        //                                 />
        //                             ),
        //                         }}
        //                     />
        //                 </div>
        //             </MuiThemeProvider>
        //         </div>
        //     );
        // }
        else if (this.state.activeItem === "Goods Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                       {/*                     &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDestroyRequest(event, rowData, props)}
                                            >
                                                Allocated
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Cheque Acknowledgement
                                            </Button>
                                            &nbsp;*/}
                                           <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForAllocation(event, rowData, props)}
                                            >
                                                {/* Goods Receive */}
                                                Allocated
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Allocated')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
        else if (this.state.activeItem === "GR By Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.withoutActionColumnname}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                       {/*                     &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDestroyRequest(event, rowData, props)}
                                            >
                                                Allocated
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Cheque Acknowledgement
                                            </Button>
                                            &nbsp;*/}
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForGRByBranch(event, rowData, props)}
                                            >
                                                Acknowledge
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Allocated')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Allocated" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.branchReceivedColumns}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Cheque Acknowledgement
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={{
                                                    backgroundColor: "green",
                                                    color: "white",
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    paddingTop: 3,
                                                    paddingBottom: 3,
                                                    borderRadius: 3,
                                                    border: 1
                                                }}
                                                onClick={(event, rowData) => this.actionForDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                            &nbsp;
                                            {/*<Button*/}
                                            {/*    style={{*/}
                                            {/*        backgroundColor: "green",*/}
                                            {/*        color: "white",*/}
                                            {/*        paddingLeft: 10,*/}
                                            {/*        paddingRight: 10,*/}
                                            {/*        paddingTop: 3,*/}
                                            {/*        paddingBottom: 3,*/}
                                            {/*        borderRadius: 3,*/}
                                            {/*        border: 1*/}
                                            {/*    }}*/}
                                            {/*    onClick={(event, rowData) => this.actionForDestroyRequestToDelivered(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Delivered*/}
                                            {/*</Button>*/}
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Allocated')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.withoutActionColumnname}
                                data={this.state.allData}
                                title={" "}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            {/*<Button*/}
                                            {/*    style={{*/}
                                            {/*        backgroundColor: "green",*/}
                                            {/*        color: "white",*/}
                                            {/*        paddingLeft: 10,*/}
                                            {/*        paddingRight: 10,*/}
                                            {/*        paddingTop: 3,*/}
                                            {/*        paddingBottom: 3,*/}
                                            {/*        borderRadius: 3,*/}
                                            {/*        border: 1*/}
                                            {/*    }}*/}
                                            {/*    onClick={(event, rowData) => this.actionForDestroyRequestToDestroy(event, rowData, props)}*/}
                                            {/*>*/}
                                            {/*    Destroy*/}
                                            {/*</Button>*/}
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(CsChecqueBookManage);
