import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import {csReturnGiftCardRequisition, giftCardIssuanceCsAfterSearch, giftCardIssuanceCsSearch} from "./JsonForm"
import CBNotFound from "../../workflow/CASA/CBNotFound";
import CommonApi from "../../workflow/Maintenance/common/CommonApi";

const upload = [
    {
        type: "empty",
        grid: 12
    },
    {
        varName: "nid",
        type: "file",
        label: "NID Copy",
        accept: ".pdf",
        grid: 2,
    },

];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class GiftCardIssuance extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            err: false,
            inputData: {},
            errorArray: {},
            selectedDate: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            getData: false,
            searchData: false,
            notificationMessage: "",
            alert: false,
            title: '',
            getImageLink: [],
            getImageBoolean: false,
            cbNotFound: false,
            imageName: [],
            loading: true,
            getRemarks: [],
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForGetRemarks();
                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        searchData: true,
                        loading: false,
                        appId: this.props.appId,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    removeReadOnly = (data, bool) => {
        if (this.state.inputData.selectCustomerType === "New Customer") {
            for (let item of data) {
                if (item.varName === "nationalId" || item.varName === "smartCard") {
                    item.readOnly = bool;
                    item.validateReadOnly = bool;
                }
            }
        }
    };

    handleSearch = () => {
        let error = MyValidation.defaultValidation(this.copyJson(giftCardIssuanceCsSearch), this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        }
        let inputData = {};
        inputData.selectCustomerType = this.state.inputData.selectCustomerType;
        inputData.showNID = this.state.inputData.selectCustomerType === "New Customer";
        inputData.clientId = this.state.inputData.clientId;

        this.setState({
            inputData: inputData,
            varValue: this.copyJson(inputData),
            loading: true,
            getData: false,
            searchData: false,
        });
        let getAccountUrl = backEndServerURL + "/intgr/instantGiftCard/" + this.state.inputData.clientId;
        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let item of giftCardIssuanceCsSearch) {
                    item.readOnly = true
                }

                let cardList = [];
                for (let item of response.data) {
                    cardList.push({key: item.cardNumber, value: item.maskedCardNumber, cardType: item.cardType})
                }

                for (let item of giftCardIssuanceCsAfterSearch) {
                    if (item.varName === "cardNumber") {
                        item.enum = cardList
                    }
                }

                inputData.cbNumber = this.state.inputData.clientId;
                inputData.dob = new Date("01/01/2099");
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    showValue: true,
                    getData: true,
                    searchData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                });
            });
    };

    functionForGetAccountInfo = (event, data) => {
        if (data === "casaAccountNumber") {
            this.setState({
                loading: true
            });
            this.state.inputData.showAccountInfo = false;
            this.state.inputData.showNID = false;
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.casaAccountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.message === "No Account Found!") {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: 'No Account Found!'
                            });
                        } else {
                            let inputData = this.state.inputData;
                            inputData.showAccountInfo = true;
                            inputData.showNID = true;
                            inputData.accountNumber = response.data.accountNumber;
                            inputData.customerName = response.data.customerName;
                            inputData.CBNumber = response.data.cbNumber;
                            inputData.casaSolId = response.data.solid;
                            inputData.solId = response.data.solid;
                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + response.data.cbNumber;
                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.data.COMMPH1BLOCK) {
                                        inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode
                                    }
                                    if (response.data.COMMEMLBLOCK) {
                                        inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                    }
                                    if (response.data.NIDBLOCK) {
                                        inputData.nationalId = response.data.NIDBLOCK.referenceNum;
                                    }
                                    if (response.data.RSNIDBLOCK) {
                                        inputData.smartCard = response.data.RSNIDBLOCK.referenceNum;
                                    }
                                    this.setState({
                                        inputData: inputData,
                                        varValue: this.copyJson(inputData),
                                        showValue: true,
                                        getData: true,
                                        searchData: true,
                                        loading: false
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        loading: false,
                                        cbNotFound: true,
                                        title: error.response.data.message
                                    });
                                });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "cardNumber") {
            this.state.inputData.showCardType = false;
            this.setState({
                loading: true,
            });
            this.state.inputData.cardType = this.state.inputData.cardNumber["cardType"];
            this.state.varValue.cardType = this.state.inputData.cardNumber["cardType"];
            this.forceUpdate();
            setTimeout(() => {
                this.state.inputData.showCardType = true;
                this.setState({
                    loading: false
                })
            }, 1000)
        }
    };

    getExistingClientId = () => {
        return new Promise((resolve, reject) => {
            let postData = {};
            postData.nid = this.state.inputData.nationalId;
            postData.smartCard = this.state.inputData.smartCard;
            CommonApi.checkEtbOrNtb(this.copyJson(postData)).then((etbResponse) => {
                let inputData = {...this.state.inputData};
                if (etbResponse.data.etb === false) {
                    inputData.customerType = "New Customer"
                } else {
                    inputData.customerType = "Existing Customer";
                    inputData.existingClientId = etbResponse.data.clientId
                }
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                });
                resolve(inputData)
            }).catch((error) => {
                reject(error)
            })
        })
    };

    functionForValidityCheck = () => {
        return new Promise((resolve, reject) => {
            let postData = {};
            postData.nid = this.state.inputData.nationalId;
            postData.clientId = this.state.inputData.clientId;
            postData.smartCard = this.state.inputData.smartCard;
            let url = backEndServerURL + "/giftcardAvailabilityCheck";
            axios.post(url, this.copyJson(postData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })
    };


    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let error = MyValidation.defaultValidation(giftCardIssuanceCsAfterSearch, this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false
            });
            return 0;
        } else {
            if (("nationalId" in this.state.inputData) || ("smartCard" in this.state.inputData)) {
                this.getExistingClientId().then((existingClientResponse) => {
                    this.functionForValidityCheck().then((response) => {
                        if (response.data === "Y") {
                            let {nid} = this.state.fileUploadData;
                            if (this.state.inputData.bom_approval !== "RETURN" && nid === undefined) {
                                this.setState({
                                    cbNotFound: true,
                                    title: "National Id Upload Required!",
                                    loading: false
                                })
                            } else if (this.state.fileUploadData !== undefined && Object.keys(this.state.fileUploadData).length > 0) {
                                let files = [];
                                if (nid !== undefined) {
                                    files.push({file: nid, type: "NID Upload"})
                                }
                                let formData = new FormData();
                                formData.append("appId", this.state.appId);
                                files.map((item, index) => {
                                    console.log(item.file, item.type);
                                    formData.append("file", item.file);
                                    formData.append("type", item.type)
                                });
                                let fileUploadPath = backEndServerURL + "/case/upload";
                                axios({
                                    method: 'post',
                                    url: fileUploadPath,
                                    data: formData,
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                                        'content-type': 'multipart/form-data'
                                    }
                                }).then((response) => {
                                    this.functionForCaseRoute()
                                }).catch((error) => {
                                    this.setState({
                                        cbNotFound: true,
                                        title: "File Upload failed!",
                                        loading: false
                                    })
                                })
                            } else {
                                this.functionForCaseRoute()
                            }
                        } else {
                            this.setState({
                                cbNotFound: true,
                                title: "Daily Limit Expired for this NID!",
                                loading: false
                            })
                        }
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false
                        })
                    });
                }).catch((error) => {
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        loading: false
                    })
                })
            } else {
                this.setState({
                    cbNotFound: true,
                    title: "NID or Smart Card Required!",
                    loading: false
                })
            }
        }
    };


    functionForCaseRoute = () => {
        let postData = {...this.state.inputData};
        postData.accountNumber = this.state.inputData.selectCustomerType === "Existing Customer" ? this.state.inputData.accountNumber : this.state.inputData.cardAccountNumber;
        postData.category = "Gift Card";
        postData.subCategory = "Gift Card Issuance";
        postData.serviceType = "Gift Card";
        postData.subServiceType = "Gift Card Issuance";
        postData.appId = this.state.appId;
        let remarksData = {};
        remarksData.remark = this.state.inputData.csRemarks;
        remarksData.map = {...postData, csRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                });
                if (this.props.appId !== undefined) {
                    this.props.closeModal();
                } else {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Gift Card Issuance
                    <a>
                        <CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "#000000"
                            }}
                        />
                    </a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Gift Card Issuance
                </h4>
            )
        }
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });

    };

    functionForFileDownload = () => {
        if (this.props.appId !== undefined) {
            return (
                <Grid item xs={12} style={{marginTop: "15px", marginBottom: "15px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("NID Upload")}
                    >
                        Download National ID
                    </button>
                </Grid>
            )
        }
    };


    functionForFileGet = (type) => {
        this.setState({
            loading: true
        });
        let imageUrl = backEndServerURL + "/case/files/" + type + "/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    let url = backEndServerURL + "/file/" + response.data.toString() + "/" + sessionStorage.getItem("accessToken");
                    window.open(url);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "File Not Found!"
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };

    renderSearchData = () => {
        if (this.state.getData === true && this.state.searchData === true) {
            return (
                <>
                    {this.props.appId === undefined && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        giftCardIssuanceCsAfterSearch,
                        this.updateComponent,
                        this.functionForGetAccountInfo,
                        this.onKeyDownChange
                    )}
                    {this.props.appId !== undefined && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        csReturnGiftCardRequisition,
                        this.updateComponent,
                        this.functionForGetAccountInfo,
                        this.onKeyDownChange
                    )}
                    {this.functionForFileDownload()}
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        upload,
                        this.updateComponent
                    )}
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                        <br/>
                        {
                            this.renderRemarks()
                        }
                    </Grid>
                    <Grid item xs={3} style={{marginTop: "20px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        {this.props.appId !== undefined && <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>}
                    </Grid>
                </>
            )
        }
    };

    renderSearch = () => {
        if (this.props.appId === undefined) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        giftCardIssuanceCsSearch,
                        this.updateComponent
                    )}
                    <Grid item xs={8}>
                        <button
                            style={{
                                marginTop: "18px"
                            }}
                            onClick={this.handleSearch}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        &nbsp;
                        <button
                            style={{
                                marginTop: "16px"
                            }}
                            onClick={() => window.location.reload()}
                            className="btn btn-danger btn-sm">
                            Reset
                        </button>
                    </Grid>
                </>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderSearch()}
                                {this.renderSearchData()}
                            </ThemeProvider>
                        </Grid>
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(GiftCardIssuance);
