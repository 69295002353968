import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import Table from "../../Table/Table";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../Static/loader.gif";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "../CommonComponent/WaiverList";
import DeferalList from "../CommonComponent/DeferalList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import ErrorModal from "../CommonComponent/ErrorModal";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import AccountStatement from "../fdr/AccountStatement";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }]
;

class VerifyDocumentBOM extends React.Component {
    state = {

        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "Successfully Routed!",
        selectedDate: {},
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: false,
        confirmAlert: false,
        actionType: "",
        errorModalBoolean: false,
        errorTittle: "System Error!",

        customerName: "",
        accountStatement: false
    };
    renderEditForm = (() => {

        if (this.state.getData) {


            return (

                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent, "", this.onKeyDownChange)

            )
        }

    });

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*   for (let prop in clone)
               if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                   delete clone[prop];*/
        return clone;
    };

    componentDidMount() {

        var remarksArray = [];
        this.setState({
            loading: true
        });
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    /*  let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                      axios.get(deferalListUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                          .then((response) => {*/
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(response.data);
                    /*   let tableArray = [];
                       var status = "";
                       response.data.map((deferal) => {
                           if (deferal.status === "ACTIVE") {
                               status = "Approved By BM"
                           }
                           else{
                               status = deferal.status
                           }
                           tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                       });
                       this.setState({
                           getDeferalList: tableArray
                       })*/
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        });


                    /* })
                     .catch((error) => {
                         console.log(error);
                         this.setState({
                             loading:false
                         })
                     })*/
                    let inputData = this.emptyValueRemove(response.data);
                    let varValue = this.emptyValueRemove(response.data);
                    inputData.bomRemarks = undefined;
                    varValue.bomRemarks = undefined;
                    if (inputData.dob !== undefined && inputData.dob !== null && inputData.dob !== "" && inputData.dob !== -1) {
                        inputData.dob = (inputData.dob).split(',')[0];
                        varValue.dob = (varValue.dob).split(',')[0];
                    }
                    if (inputData.fdValueDate !== undefined && inputData.fdValueDate !== null && inputData.fdValueDate !== "" && inputData.fdValueDate !== -1) {
                        inputData.fdValueDate = (inputData.fdValueDate).split(',')[0];
                        varValue.fdValueDate = (varValue.fdValueDate).split(',')[0];
                    }
                    this.setState({
                        getData: true,
                        varValue: this.emptyValueRemove(varValue),
                        inputData: this.emptyValueRemove(inputData),
                        appData: response.data,
                        showValue: true,
                        loading: false


                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "APPROVED") {
            this.handleSubmit("APPROVED")
        } else if (data === "YES" && this.state.actionType === "RETURN") {
            this.handleSubmit("RETURN")
        }

    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };

    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(bomRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("Not working");
            return 0;
        }
        let dependencyField = [];
        if (actionType === "RETURN") {
            dependencyField.push({

                "varName": "bomRemarks",
                "type": "textArea",
                "required": true,
                "label": "Bom Remarks",
                "grid": 12
            })
        }
          error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }

        this.setState({
            confirmAlert: true,
            actionType: actionType
        })
    };

    handleSubmit = (data) => {
        //event.preventDefault();
        this.state.inputData.bom_approval = data;
        /*  let dependencyField = [];
          if(data==="RETURN"){
              dependencyField.push({

                  "varName": "bomRemarks",
                  "type": "textArea",
                  "required":true,
                  "label": "Bom Remarks",
                  "grid": 12
              })
          }
          let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state)
          this.forceUpdate();
          if (error === true) {
              return 0;
          };*/
        this.setState({
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        if ((this.state.inputData.bomRemarks !== undefined && this.state.inputData.bomRemarks !== null) /* && (data==="APPROVED" || data==="RETURN")*/) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bomRemarks;

        }

        let postDataForCheckbook = {};
        let checqueBooApiFlag = null;
        let postDataForInstaDebitCard = {};
        let instaDebitCardApiFlag = null;
        let postDataForDebitCard = {};
        let debitCardApiFlag = null;
        let postData = {};
        if (data !== "RETURN") {
            if (this.state.inputData.chequeBookRequest === true && this.state.inputData.checqueBooApiFlag === "YES") {
                //checkbook api call

                postDataForCheckbook.accountStatus = "A";
                postDataForCheckbook.accountNumber = this.state.inputData.accountNumber;
                postDataForCheckbook.customerName = this.state.inputData.customerName;
                postDataForCheckbook.accountType = this.state.inputData.accType;
                postDataForCheckbook.mobileNumber = this.state.inputData.designatedMobileNumber;
                postDataForCheckbook.numberOfChequeBook = this.state.inputData.numberOfChequeBook;
                postDataForCheckbook.customerEmail = this.state.inputData.designatedEmail;
                postDataForCheckbook.pageofChequebook = this.state.inputData.pageofChequebook;
                postDataForCheckbook.deliveryType = this.state.inputData.chequeBookDeliveryType;
                postDataForCheckbook.customerAddress = this.state.inputData.customerAddress;
                postDataForCheckbook.customerType = this.state.inputData.customerType;
                postDataForCheckbook.checqueBookDesign = this.state.inputData.numberOfChequeBook;
                postDataForCheckbook.chequeBookType = this.state.inputData.chequeBookType;
                postDataForCheckbook.branchName = this.state.inputData.checkbookBranchName.value;
                postDataForCheckbook.deliveryBranchSolId = this.state.inputData.checkbookBranchName.key;
                postDataForCheckbook.requisitionBranch = this.state.inputData.requisitionSolId;
                postDataForCheckbook.acctCurrency = this.state.inputData.currency.key;
                postDataForCheckbook.appId = this.props.appId;
                postDataForCheckbook.requestType = "Finacle";
                postDataForCheckbook.homeBranchSolId = this.state.inputData.solId;
                postData.chequebookSave = postDataForCheckbook;
                checqueBooApiFlag = true;
                /* let routeTaskUrl = backEndServerURL + "/checquebookrequisitionsave";
                 axios
                     .post(routeTaskUrl, postDataForCheckbook, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                     .then(response => {
                         this.state.inputData.checqueBooApiFlag = "NO"
                         /!*    if (response.status === 200) {

                             }
                             else {
                                 this.setState({
                                     cbNotFound: true,
                                     title: 'Unable to Save Cheque Book!'
                                 });
                             }*!/

                     })
                     .catch(error => {
                         console.log(error);
                         this.setState({
                             errorModalBoolean: true,
                             errorTittle: this.errorObjectCheck(error),
                         })
                     });*/
            }
            if (this.state.inputData.instaDebitCard === true && this.state.inputData.instaDebitCardApiFlag === "YES") {

                postDataForInstaDebitCard.rmCode = this.state.inputData.rmCode;
                postDataForInstaDebitCard.rmName = this.state.inputData.rmCodeName;
                postDataForInstaDebitCard.email = this.state.inputData.designatedEmail;
                postDataForInstaDebitCard.mobileNumber = this.state.inputData.designatedMobileNumber;
                postDataForInstaDebitCard.cardType = this.state.inputData.instaCardType;
                postDataForInstaDebitCard.ccep = this.state.inputData.ccepCode;
                postDataForInstaDebitCard.passportNumber = this.state.inputData.passport;
                //postData.passportExpiryDate=
                //postData.passportIssueDate=
                //postData.passportIssuePlace=
                postDataForInstaDebitCard.accountStatus = "A";
                postDataForInstaDebitCard.accountNumber = this.state.inputData.accountNumber;
                postDataForInstaDebitCard.customerName = this.state.inputData.customerName;
                postDataForInstaDebitCard.productType = this.state.inputData.instaDebitCardProductType;
                postDataForInstaDebitCard.nameOnCard = this.state.inputData.instaDebitCardName;
                postDataForInstaDebitCard.clientId = this.state.inputData.instaClientId;
                postDataForInstaDebitCard.cardNumber = this.state.inputData.instaCardNumber;
                postDataForInstaDebitCard.appId = this.props.appId;
                postData.debitCardUpdate = postDataForInstaDebitCard;
                instaDebitCardApiFlag = true;
                /*   let routeTaskUrl = backEndServerURL + "/updateInstantDebitCard";
                   axios
                       .post(routeTaskUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                       .then(response => {
                           this.state.inputData.instaDebitCardApiFlag = "NO"
                           var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                           axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                               .then((response) => {
                                   this.setState({
                                       title: "Successful!",
                                       notificationMessage: "Successfully Routed!",
                                       alert: true,
                                       loading: false
                                   });
                                   this.props.closeModal()
                               })
                               .catch((error) => {
                                   this.setState({
                                       loading: false
                                   });
                               });

                       })
                       .catch(error => {
                           console.log(error);
                           this.setState({
                               loading:false,
                               errorModalBoolean: true,
                               errorTittle: this.errorObjectCheck(error),
                           })
                           return 0
                       });*/
            }
            if (this.state.inputData.debitCard === true && this.state.inputData.debitCardApiFlag === "YES") {
                // debit card api call

                postDataForDebitCard.rmCode = this.state.inputData.rmCode;
                postDataForDebitCard.accountStatus = "A";
                postDataForDebitCard.accountNumber = this.state.inputData.accountNumber;
                postDataForDebitCard.customerName = this.state.inputData.customerName;
                postDataForDebitCard.accountType = this.state.inputData.accType === "SAVINGS" ? "Savings" : "Current";
                postDataForDebitCard.ccep = this.state.inputData.ccepCode;
                postDataForDebitCard.customerMobile = this.state.inputData.designatedMobileNumber;
                postDataForDebitCard.customerEmail = this.state.inputData.designatedEmail;
                postDataForDebitCard.cardType = this.state.inputData.cardType;
                postDataForDebitCard.productType = this.state.inputData.productType;
                postDataForDebitCard.nameOnCard = this.state.inputData.nameOnCard;
                postDataForDebitCard.deliveryType = this.state.inputData.debitCardDeliveryTypes;
                postDataForDebitCard.branchName = this.state.inputData.debitCardBranchName.value;
                postDataForDebitCard.deliverBranchSolId = this.state.inputData.debitCardBranchName.key;
                postDataForDebitCard.appId = this.props.appId;
                postDataForDebitCard.customerType = this.state.inputData.debitCardcustomerType;
                postDataForDebitCard.cbNumber = this.state.inputData.cbNumber;
                postDataForDebitCard.currency = this.state.inputData.currency.key;
                postDataForDebitCard.casaSolId = this.state.inputData.solId;
                postData.debitCardSave = postDataForDebitCard;
                debitCardApiFlag = true;
            }
            var url = backEndServerURL + "/saveOrUpdateChequebookDebitCard/" + this.props.appId;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.debitCardApiFlag = "NO";
                    if(this.state.inputData.smsAlertRequest==="Y" && this.state.inputData.smsAlertFirstTime==undefined){
                        this.state.inputData.sms_alert_first_time=true ;
                        this.state.inputData.smsAlertFirstTime=true ;

                    }
                    else{
                        this.state.inputData.sms_alert_first_time=false;
                    }
                    if(this.state.inputData.cityTouchRequest===true  && this.state.inputData.cityTouchFirstTime==undefined){
                        this.state.inputData.city_touch_first_time=true ;
                        this.state.inputData.cityTouchFirstTime=true ;
                    }
                    else{
                        this.state.inputData.city_touch_first_time=false ;
                    }

                    saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
                    var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.props.closeModal()
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });

                        })
                        .catch((error) => {
                            this.setState({
                                loading: false
                            });
                        });
                })
                .catch((error) => {
                    /*  this.setState({
                          errorModalBoolean: true,
                          errorTittle: this.errorObjectCheck(error),
                      });
                      this.setState({
                          loading: false
                      });*/
                    saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
                    var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.props.closeModal()
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });

                        })
                        .catch((error) => {
                            this.setState({
                                loading: false
                            });
                        });
                })

        } else {
            saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
            var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.closeModal()
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    });

                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                });


        }

    };

    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
    };

    handleChange = (event) => {
        event.target.name = event.target.value;

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{}}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "APPROVED")}

                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{}}

                        type='button' value='add more'
                        onClick={(event) => this.handleCommon(event, "RETURN")}

                    >Return
                    </button>
                </div>

            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };

    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };
    closeModal = () => {
        this.setState({
            imageModalBoolean: false
        })
    };
    renderImageLink = () => {

        if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }

    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            errorModalBoolean: false

        })
    };
    renderMappingImageButton = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 150,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Image
                </button>
            )
        }
    };
    renderDedupComponent = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData/* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton
                    subCategory={this.props.subCategory}
                    category={this.props.category}
                    serviceType={this.props.serviceType}
                    cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.solId}/>
            )
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        if (this.state.loading === true) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

            )
        } else {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <div>


                        <Grid container spacing={1}>
                            <ThemeProvider theme={theme}>
                                {this.renderNotification()}

                                {this.renderEditForm()}


                            </ThemeProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <DeferalList appId={this.props.appId}/>
                        </Grid>
                        <Grid item={12}>

                            <WaiverList appId={this.props.appId}/>
                        </Grid>
                        <br/>

                        <Grid item xs={12}>
                            {this.renderDedupComponent()}
                            {this.renderDedupComponentMandateAndBeneficiary()}
                            {this.renderSdnComponent()}
                        </Grid>
                        {this.renderMappingImageButton()}

                        <br/>
                        <br/>
                        <Grid item xs={12}>
                            {this.renderFlowSUmmeryButton()}
                        </Grid>
                        <br/>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            open={this.state.getMappingAllImage}>
                            <DialogContent>
                                <FileMappingReadOnly appId={this.props.appId}
                                                     closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.errorModalBoolean}>
                            <DialogContent className={classes.dialogPaper}>
                                <ErrorModal title={this.state.errorTittle}
                                            closeModal={this.closeModal}
                                            subServiceType={this.props.subServiceType}
                                            appId={this.props.appId}
                                />

                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.history}>
                            <DialogContent className={classes.dialogPaper}>
                                <SubmittedCaseHistory
                                    customerName={this.state.customerName}
                                                      accountNumber={this.state.accountNumber} solId={this.state.solId}
                                                      globalSearch={true} closeIcon={this.closeHistoryModal}
                                                      appId={this.state.appId} closeModal={this.closeHistoryModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.accountStatement}>
                            <DialogContent className={classes.dialogPaper}>
                                <AccountStatement closeModal={this.closeStatement}
                                                  accountNumber={this.state.inputData.accountNumber}/>
                            </DialogContent>
                        </Dialog>
                         <Grid item xs={12}>
                            {this.renderRemarksData()}
                        </Grid>
                        <ThemeProvider theme={theme}>
                            {this.renderRemarks()}
                        </ThemeProvider>
                        <br/>
                        <br/>
                        <br/>

                        {this.renderSubmitButton()}


                    </div>
                </GridList>

            )
        }
    }
}

export default withStyles(styles)(VerifyDocumentBOM);
