import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import withStyles from "@material-ui/core/styles/withStyles";
import Forward from "../Forward";
import Notification from "../../NotificationMessage/Notification";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To date",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        grid: 2,
        onKeyDown: true,
        errorMessage: "Error",
        enum: []
    },
    {
        varName: "productType",
        type: "select",
        enum: [],
        label: "Product Type",
        grid: 2,
        conditional: true,
        conditionalVarName: "showProductType",
        conditionalVarValue: true,
    },
    {
        varName: "currency",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Currency",
        grid: 2,
    },
];

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

class CsDebitCardManage extends Component {
    state = {activeItem: "home", backgroundColor: "red"};

    constructor(props) {
        super(props);
        this.state = {
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            searchItem: "",
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            autoComplete: {},
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            forwardModal: false,
            forwardArray: false,
            selectedCount: 0,
            loader: true,
            allData: [],
            allColumns: [
                {title: "Sl", field: "sl"},
                {title: "Bom Approval Date", field: "bomApprovalDate"},
                {title: "Status", field: "status"},
                {title: "Source", field: "source"},
                {title: "Initiating Branch SOL ID", field: "initiatingBranchSolId"},
                {title: "Card to be Delivered SOL ID", field: "deliveryBranchSolId"},
                {title: "Batch No", field: "batchNo"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "cardNumber"},
                {title: "Customer Name", field: "customerName"},
                {title: "Name on card", field: "nameOnCard"},
                {title: "Account Number", field: "accountNumber"},
                {title: "Customer Type", field: "customerType"},
                {title: "CB No", field: "cbNumber"},
                {title: "Account Type", field: "accountType"},
                {title: "Account Currency Type", field: "accountCurrencyType"},
                {title: "RM Code", field: "rmCode"},
                // {title: "Source Branch/Sol id", field: "sourceBranchSolId"},
                {title: "CASA SOL ID", field: "casaSolId"},
                {title: "Emil id", field: "emailId"},
                {title: "Cell number", field: "cellNumber"},
                {title: "Card Type", field: "cardType"},
                {title: "Product Type", field: "productType"},
                {title: "CCEP Name/Campaign Name", field: "ccep"},
                {title: "Passport Number", field: "passportNumber"},
                {title: "Passport expiry date", field: "passportExpiryDate"},
                {title: "Passport Issue date", field: "passportIssueDate"},
                {title: "Passport Issue Place", field: "passportIssuePlace"},
            ],
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        console.log(value);
        if (value === "All") {
            // this.fetchDataFromAPI('All', 'getBranchDebitCardAll/page/0')
            this.fetchDataFromAPI('All', 'getDebitCardAll/page/0')
        } else if (value === "Forward") {
            this.fetchDataFromAPI('Forward', 'getForwardedDebitCardFromThisBranch/0')
        } else {
            this.fetchDataFromAPI(value)
        }
    };

    getBranchAll() {
        let autoComplete = {};
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({label: item.name})
                });
                autoComplete.branchName = branch;
                this.setState({
                    autoComplete: autoComplete,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    functionForGetCurrency = () => {
        let branchUrl = backEndServerURL + "/finMasterData/currency";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "currency") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        this.getBranchAll();
        this.getCardType();
        this.functionForGetCurrency();
        //inbox call
        // let inbox_url = backEndServerURL + "/getBranchDebitCardAll/page/0";
        let inbox_url = backEndServerURL + "/getDebitCardAll/page/0";
        axios
            .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        sl: i + 1,
                        id: response.data.content[i].id,
                        bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                        status: response.data.content[i].status,
                        cardNumber: response.data.content[i].maskingDebitCardNumber,
                        clientId: response.data.content[i].clientId,
                        batchNo: response.data.content[i].batchNo,
                        customerName: response.data.content[i].customerName,
                        nameOnCard: response.data.content[i].nameOnCard,
                        accountNumber: response.data.content[i].accountNumber,
                        customerType: response.data.content[i].customerType,
                        cbNumber: response.data.content[i].cbNumber,
                        accountType: response.data.content[i].accountType,
                        accountCurrencyType: response.data.content[i].currency,
                        rmCode: response.data.content[i].rmCode,
                        initiatingBranchSolId: response.data.content[i].initialSolId,
                        source: response.data.content[i].source,
                        sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                        casaSolId: response.data.content[i].casaSolId,
                        emailId: response.data.content[i].customerEmail,
                        cellNumber: response.data.content[i].customerMobile,
                        deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                        cardType: response.data.content[i].cardType,
                        productType: response.data.content[i].productType,
                        ccep: response.data.content[i].ccep,
                        passportNumber: response.data.content[i].passportNumber,
                        passportExpiryDate: response.data.content[i].passportExpiryDate,
                        passportIssueDate: response.data.content[i].passportIssueDate,
                        passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    loader: false,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    }

    getCardType() {
        let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataCardTypes";
        axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of SearchForm) {
                    if (form.type === "select" && form.varName === "cardType") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    functionForGetProductType = (data) => {
        if (data.varName === "cardType") {
            this.setState({
                loading: true
            });
            this.state.inputData.productType = "";
            this.state.inputData.showProductType = false;
            this.forceUpdate();
            let productTypeUrl = backEndServerURL + "/cmsDebitCardMasterDataProductTypes/" + this.state.inputData.cardType;
            axios.get(productTypeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let form of SearchForm) {
                            if (form.type === "select" && form.varName === "productType") {
                                form.enum = response.data
                            }
                        }
                        this.state.inputData.showProductType = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Product Type Not Found!",
                        loading: false
                    });
                })
        }
    };

    fetchDataFromAPI = (status, url) => {

        if (this.state.searchItem !== "") {
            this.searchData(this, status, 0)
        } else {
            this.setState({
                activeItem: status,
                loader: true,
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                urlSegment = 'getBranchDebitCardStatus';
            } else {
                urlSegment = url;
            }

            if (status === 'All' || status === 'Forward') {
                inbox_url = backEndServerURL + "/" + urlSegment;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/0";
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response);
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        rowData = {
                            sl: i + 1,
                            id: response.data.content[i].id,
                            bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                            status: response.data.content[i].status,
                            cardNumber: response.data.content[i].maskingDebitCardNumber,
                            clientId: response.data.content[i].clientId,
                            batchNo: response.data.content[i].batchNo,
                            customerName: response.data.content[i].customerName,
                            nameOnCard: response.data.content[i].nameOnCard,
                            accountNumber: response.data.content[i].accountNumber,
                            customerType: response.data.content[i].customerType,
                            cbNumber: response.data.content[i].cbNumber,
                            accountType: response.data.content[i].accountType,
                            accountCurrencyType: response.data.content[i].currency,
                            rmCode: response.data.content[i].rmCode,
                            initiatingBranchSolId: response.data.content[i].initialSolId,
                            source: response.data.content[i].source,
                            sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                            casaSolId: response.data.content[i].casaSolId,
                            emailId: response.data.content[i].customerEmail,
                            cellNumber: response.data.content[i].customerMobile,
                            deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                            cardType: response.data.content[i].cardType,
                            productType: response.data.content[i].productType,
                            ccep: response.data.content[i].ccep,
                            passportNumber: response.data.content[i].passportNumber,
                            passportExpiryDate: response.data.content[i].passportExpiryDate,
                            passportIssueDate: response.data.content[i].passportIssueDate,
                            passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        loader: false,
                    });
                    console.log(this.state.allData);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page)
        } else {
            let inbox_url;
            if (this.state.activeItem === status) {
                this.setState({
                    loader: true
                });
                if (status === 'All') {
                    // inbox_url = backEndServerURL + "/getBranchDebitCardAll/page/" + page;
                    inbox_url = backEndServerURL + "/getDebitCardAll/page/" + page;
                } else if (status === 'Forward') {
                    inbox_url = backEndServerURL + "/getForwardedDebitCardFromThisBranch/" + page;
                } else {
                    inbox_url = backEndServerURL + "/getBranchDebitCardStatus/" + status + "/" + page;
                }
                axios
                    .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                sl: i + 1,
                                id: response.data.content[i].id,
                                bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                                status: response.data.content[i].status,
                                cardNumber: response.data.content[i].maskingDebitCardNumber,
                                clientId: response.data.content[i].clientId,
                                batchNo: response.data.content[i].batchNo,
                                customerName: response.data.content[i].customerName,
                                nameOnCard: response.data.content[i].nameOnCard,
                                accountNumber: response.data.content[i].accountNumber,
                                customerType: response.data.content[i].customerType,
                                cbNumber: response.data.content[i].cbNumber,
                                accountType: response.data.content[i].accountType,
                                accountCurrencyType: response.data.content[i].currency,
                                rmCode: response.data.content[i].rmCode,
                                initiatingBranchSolId: response.data.content[i].initialSolId,
                                source: response.data.content[i].source,
                                sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                                casaSolId: response.data.content[i].casaSolId,
                                emailId: response.data.content[i].customerEmail,
                                cellNumber: response.data.content[i].customerMobile,
                                deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                                cardType: response.data.content[i].cardType,
                                productType: response.data.content[i].productType,
                                ccep: response.data.content[i].ccep,
                                passportNumber: response.data.content[i].passportNumber,
                                passportExpiryDate: response.data.content[i].passportExpiryDate,
                                passportIssueDate: response.data.content[i].passportIssueDate,
                                passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            activeItem: status,
                            totalRow: response.data.totalElements,
                            rowsPerPage: response.data.numberOfElements,
                            pageNumber: response.data.number,
                            loader: false
                        });
                        console.log(this.state.allData);
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            loader: false
                        });
                    });
            }

        }
    };

    dateConverter = (value) => {
        if (value) {
            let dateString = value.split("T");
            let time = dateString[1];
            return dateString[0] + " " + time.split(".")[0]
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, pageNumber) => {
        let postData = this.removeNullValue(this.state.inputData);
        if (Object.keys(postData).length === 0) {
            return 0;
        }

        let inbox_url;
        let page = pageNumber || 0;
        this.setState({
            loader: true,
            pageNumber: page,
            activeItem: status,
        });
        if (status === "All") {
            inbox_url = backEndServerURL + "/filterDebitCard/" + status + "/" + page;
        } else {
            inbox_url = backEndServerURL + "/filterBranchDebitCard/" + status + "/" + page;
        }

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].appId;
                    rowData = {
                        sl: i + 1,
                        id: response.data.content[i].id,
                        bomApprovalDate: this.dateConverter(response.data.content[i].createdDate),
                        status: response.data.content[i].status,
                        cardNumber: response.data.content[i].maskingDebitCardNumber,
                        clientId: response.data.content[i].clientId,
                        batchNo: response.data.content[i].batchNo,
                        customerName: response.data.content[i].customerName,
                        nameOnCard: response.data.content[i].nameOnCard,
                        accountNumber: response.data.content[i].accountNumber,
                        customerType: response.data.content[i].customerType,
                        cbNumber: response.data.content[i].cbNumber,
                        accountType: response.data.content[i].accountType,
                        accountCurrencyType: response.data.content[i].currency,
                        rmCode: response.data.content[i].rmCode,
                        initiatingBranchSolId: response.data.content[i].initialSolId,
                        source: response.data.content[i].source,
                        sourceBranchSolId: response.data.content[i].sourceBranchSolId,
                        casaSolId: response.data.content[i].casaSolId,
                        emailId: response.data.content[i].customerEmail,
                        cellNumber: response.data.content[i].customerMobile,
                        deliveryBranchSolId: response.data.content[i].deliverBranchSolId,
                        cardType: response.data.content[i].cardType,
                        productType: response.data.content[i].productType,
                        ccep: response.data.content[i].ccep,
                        passportNumber: response.data.content[i].passportNumber,
                        passportExpiryDate: response.data.content[i].passportExpiryDate,
                        passportIssueDate: response.data.content[i].passportIssueDate,
                        passportIssuePlace: response.data.content[i].passportPlaceOfIssue,
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    totalRow: response.data.totalElements,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    searchItem: status,
                });
                console.log(this.state.allData);
            })
            .catch(error => {
                console.log(error);
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    render() {
        const {activeItem} = this.state;
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search Debit Card
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent,
                                    "",
                                    this.functionForGetProductType
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, 'All');
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management (Debit Card)</h4>
                    </CardHeader>
                    <CardBody>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>
                                        <Menu.Item
                                            name="All"
                                            active={activeItem === "All"}
                                            style={
                                                activeItem === "All"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "All");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Deliver to Branch"
                                            active={activeItem === "Deliver to Branch"}
                                            style={
                                                activeItem === "Deliver to Branch"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Deliver to Branch");
                                            }}
                                        />

                                        <Menu.Item
                                            name="Branch Received"
                                            active={activeItem === "Branch Received"}
                                            style={
                                                activeItem === "Branch Received"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Branch Received");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Forward"
                                            active={activeItem === "Forward"}
                                            style={
                                                activeItem === "Forward"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Forward");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Destroy Request"
                                            active={activeItem === "Destroy Request"}
                                            style={
                                                activeItem === "Destroy Request"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy Request");
                                            }}
                                        />
                                        <Menu.Item
                                            name="Destroy"
                                            active={activeItem === "Destroy"}
                                            style={
                                                activeItem === "Destroy"
                                                    ? tabActive
                                                    : tabInactive
                                            }
                                            onClick={e => {
                                                this.headerSelect(e, "Destroy");
                                            }}
                                        />
                                    </Menu>

                                    {/*<Segment>*/}
                                    {this.tableChoice()}
                                    {this.renderNotification()}
                                    {/*</Segment>*/}
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.forwardModal}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <Forward closeModal={this.closeForwardModal}/>
                                        </DialogContent>
                                    </Dialog>
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );

    }

    actionForOnTransitToBranchReceive = (event, rowData, props) => {
        if (this.state.activeItem === "Deliver to Branch") {
            this.setState({
                loader: true
            });
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            console.log(allSelectValue[0].id);
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    //         console.log(allSelectValue[i].tableData.id)
                    rowIdArray.push(allSelectValue[i].id);
                }
                console.log("Something:" + rowIdArray);

                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Branch Received";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('Deliver to Branch')
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        }
    };

    actionForBranchReceivedToDestroyRequest = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Destroy Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToCallCenter = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Send to Call Center";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToForward = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                this.setState({
                    forwardArray: rowIdArray,
                    forwardModal: true
                })
            }
        }
    };

    closeForwardModal = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                forwardModal: false,
                loader: true
            });
            let single_url = backEndServerURL + "/forwardDebitCardToAnotherBranch";
            let postData = {
                forwardTo: value.branchName.key,
                idList: this.state.forwardArray
            };

            axios.post(single_url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetchDataFromAPI('Branch Received')
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            this.setState({
                forwardModal: false,
            });
        }
    };

    actionForBranchReceivedToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('Branch Received')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForDestroyRequestToDestroy = (event, rowData, props) => {
        if (this.state.activeItem === "Destroy Request") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;

            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateDebitCardStatus/Destroy";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.functionForNotificationMessage("Successful!", "Status Change Successful");
                        this.fetchDataFromAPI('Destroy Request')
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };


    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };


    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={""}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}

                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Deliver to Branch" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForOnTransitToBranchReceive(event, rowData, props)}
                                            >
                                                Branch Received
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Deliver to Branch")
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Destroy Request" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForDestroyRequestToDestroy(event, rowData, props)}
                                            >
                                                Destroy
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Destroy Request")
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Branch Received" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToForward(event, rowData, props)}
                                            >
                                                Forward
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToCallCenter(event, rowData, props)}
                                            >
                                                Send to Call Center
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDestroyRequest(event, rowData, props)}
                                            >
                                                Destroy Request
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Branch Received')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                style={{whiteSpace: "nowrap"}}
                                columns={this.state.allColumns}
                                data={this.state.allData}
                                title={" "}
                                options={{...options, pageSize: this.state.rowsPerPage,}}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Actions: props => (
                                        <React.Fragment>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={() => this.renderUrl()}
                                            >
                                                Load All Data
                                            </Button>
                                        </React.Fragment>
                                    ),
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.state.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, this.state.activeItem)
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        }
    }
}

export default withStyles(styles)(CsDebitCardManage)