import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import CloseIcon from '@material-ui/icons/Close';


import MyValidation from "../../JsonForm/MyValidation";

import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import Functions from "../../../Common/Functions";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly.jsx";

const arrayListBond = [

    {label: 'APPLICANT PASSPORT'},
    {label: 'APPLICANT EMPLOYMENT AGREEMENTS'},
    {label: 'APPLICANT WORK PERMITS'},
    {label: 'APPLICANT ETN'},
    {label: 'OTHERS'},


];
const arrayListSanchaypatra = [

    {label: 'APPLICANT BB FORM'},
    {label: 'APPLICANT DEBIT INSTRUCTION'},
    {label: 'APPLICANT NID'},
    {label: 'APPLICANT ETN'},
    {label: 'APPLICANT NOMINEE NID'},


];


let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "multiline": true,
        "label": "Remarks",
        "grid": 12
    }];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const request = [
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "readOnly": true,
        "label": "Account Number",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "text",
        "required": true,
        "readOnly": true,
        "label": "Customer Name",
        "grid": 2,
    },


    {
        "varName": "mobileNumber",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "smsAlertRequest",
        "conditionalVarValue": true,
        "label": "Mobile Number",
        "grid": 2,
    },
    // {
    //     "varName": "smsAlertRequest",
    //     "type": "checkbox",
    //     "readOnly":true,
    //     "label": "Sms Alert Request",
    //     "grid": 12,
    // },
    // {
    //     "varName": "cityTouchRequest",
    //     "type": "checkbox",
    //     "readOnly":true,
    //     "label": "City Toych Request",
    //     "grid": 12,
    // },

    {
        "varName": "email",
        "type": "text",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "Email *",
        "grid": 2,
    },

    /*{
        "varName": "debitCard",
        "type": "text",
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "Debit Card",
        "grid": 2,
    },*/
    // {
    //     "varName": "cityTouchUserId",
    //     "type": "text",
    //     "conditional": true,
    //     "conditionalVarName": "cityTouchRequest",
    //     "conditionalVarValue": true,
    //     "label": "CityTouch UserID",
    //     "grid": 2,
    // },


];
const requestRequired = [
    {
        "varName": "accountNumber",
        "type": "textApiCall",
        "required": true,
        "label": "Account Number",
        "grid": 2,
    },

    {
        "varName": "customerName",
        "type": "text",
        "required": true,
        "label": "Customer Name",
        "required": true,
        "conditional": true,
        "conditionalVarName": "accountNumber",
        "conditionalVarValue": true,
        "grid": 2,
    },

    // {
    //     "varName": "mobileNumber",
    //     "type": "text",
    //     "required":true,
    //     "conditional": true,
    //     "conditionalVarName": "accountNumber",
    //     "conditionalVarValue": true,
    //     "label": "Mobile Number",
    //     "grid": 2,
    // },
    // {
    //     "varName": "smsAlertRequest",
    //     "type": "checkbox",
    //     "label": "Sms Alert Request",
    //     "grid": 12,
    // },
    // {
    //     "varName": "cityTouchRequest",
    //     "type": "checkbox",
    //     "label": "City Toych Request",
    //     "grid": 12,
    // },

    {
        "varName": "email",
        "type": "text",
        "required": true,
        "conditional": true,
        "conditionalVarName": "cityTouchRequest",
        "conditionalVarValue": true,
        "label": "Email",
        "grid": 2,
    },

    /* {
         "varName": "debitCard",
         "type": "text",
         "required":true,
         "conditional": true,
         "conditionalVarName": "cityTouchRequest",
         "conditionalVarValue": true,
         "label": "Debit Card",
         "grid": 2,
     },*/
    // {
    //     "varName": "cityTouchUserId",
    //     "type": "text",
    //     "required":true,
    //     "conditional": true,
    //     "conditionalVarName": "cityTouchRequest",
    //     "conditionalVarValue": true,
    //     "label": "CityTouch UserID",
    //     "grid": 2,
    // },


];
const PhoneJsonFrom = [{
    "varName": "designatedMobileNumber",
    "type": "text",
    "readOnly": true,
    "label": "Mobile Number",
    "grid": 2,
},];
var fileUpload = {
    "varName": "fileUpload",
    "type": "file",
    "label": "File Upload",
    "grid": 12
};

class AdcServiceBOM extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: this.props.appId,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            message: "",
            notificationMessage: "",
            getData: false,
            getRemarks: [],
            varValue: [],
            appData: [],
            showValue: false,
            loading: true,
            smsAlertRequest: false,
            getMappingAllImage: false,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };
    caseStart = () => {

        this.setState({
            selectedDate: {},
            inputData: {},
            appId: 0,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            message: "",
            notificationMessage: "",
            getData: false,
            getRemarks: [],
            varValue: [],
            appData: [],
            showValue: false,
            loading: true
        });
        let url = backEndServerURL + "/startCase/adc_service_start";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);


                this.setState({
                    appId: response.data.id,
                    getData: true,
                    loading: false
                });
            })
            .catch((error) => {
                console.log(error);
            })

    };

    componentDidMount() {

        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);

                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                fileUploadData: response.data,

                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });


                    let remarksArray = [];
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    console.log(remarksArray);

                    console.log(response.data);
                    let varValue = response.data;
                    this.setState({
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        inputData: response.data,
                        showValue: true,
                        appId: this.props.appId,
                        loading: false
                    });
                    if (varValue.smsAlertRequest === 'Y') {
                        this.setState({
                            smsAlertRequest: true
                        })
                    }

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });
        }
        // else{
        //     let url = backEndServerURL + "/startCase/adc_service_start";
        // axios.get(url, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
        //     .then((response) => {
        //         console.log(response.data)


        //         this.setState({
        //             appId: response.data.id,
        //             getData:true,
        //             loading: false
        //          });
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })

        // }
    }


    updateComponent = () => {
        this.forceUpdate();
    };

    onKeyDownChange = (data) => {
        console.log(data);
        //    alert("onKeyDownChange")
    };
    onKeyDownForDedup = (data) => {
        console.log(data);
        //    alert("onKeyDownForDedup")
    };

    renderJsonForm = () => {
        if (this.state.getData && this.props.appId) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, request, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                </React.Fragment>
            )
        } else if (this.state.getData) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, request, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                </React.Fragment>
            )
        }
    };
    renderPhoneJsonForm = () => {
        if (this.state.inputData.smsAlertRequest === 'Y') {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, PhoneJsonFrom, this.updateComponent)
                    }
                </React.Fragment>
            )
        }
    };
    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={4}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }

    };
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });
        let error = false;
        if (this.state.inputData.cityTouchRequest === true) {
            error = MyValidation.defaultValidation(requestRequired, this.state)

        } else {
            error = MyValidation.defaultValidation(request, this.state)

        }
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");

        if (error === true) {
            return 0;
        }
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;
        if (this.state.fileUploadData.fileUpload !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.fileUpload;
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.state.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log("data sss");
                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }

        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

        let data = {};
        data = this.state.inputData;
        if (data.smsAlertRequest === 'Y') {

        } else if (data.cityTouchRequest === true) {

        }
        data.bom_approval = "APPROVED";
        console.log(data);
        /*this.state.inputData.next_user = this.state.inputData.maker_send_to;
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";*/
        // data.subServiceType = this.state.inputData.selectTypeOfProject;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        console.log("Successfully Routed!");
                        // this.state.inputData.selectTypeOfProject = undefined;
                        // this.state.inputData.selectTypeOfRequest = undefined;
                        this.setState({

                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                            loading: false,
                        });
                        if (this.props.appId !== undefined) {
                            this.props.closeModal()
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    handleRetrun = (event) => {

        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        let data = this.state.inputData;
        console.log(data);
        let dependencyField = [];
        dependencyField.push({

            "varName": "bomRemarks",
            "type": "textArea",
            "required": true,
            "label": "BOM Remarks",
            "grid": 12
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false,
            });
            return 0;
        }

        data.bom_approval = "RETURN";
        /*this.state.inputData.next_user = this.state.inputData.maker_send_to;
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";*/
        this.setState({
            loading: true,
        });

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.bomRemarks = undefined;

        data.subServiceType = this.state.inputData.selectTypeOfProject;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        console.log("Successfully Routed!");
                        this.state.inputData.selectTypeOfProject = undefined;
                        this.state.inputData.selectTypeOfRequest = undefined;
                        this.setState({

                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,


                        });
                        if (this.props.appId !== undefined) {
                            this.props.closeModal()
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }


    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={this.handleSubmit}

                >
                    Submit
                </button>

            )
        }
    };

    renderReturnButton = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={this.handleRetrun}

                >
                    Return
                </button>

            )
        }
    };

    /* renderMappingImageEditModal = () => {

         if (this.state.inputData.selectTypeOfRequest === "BOND") {

             return (
                 <FileMappingEdit dropDownOption={arrayListBond}
                                  serviceType={this.state.inputData.selectTypeOfRequest}
                                  appId={this.state.appId}
                                  closeModal={this.closeUploadModal}/>
             )
         } else {

             return (
                 <FileMappingEdit dropDownOption={arrayListSanchaypatra}
                                  serviceType={this.state.inputData.selectTypeOfRequest}
                                  appId={this.state.appId}
                                  closeModal={this.closeUploadModal}/>
             )
         }
     };*/
    headerTitle = () => {
        if (this.props.appId !== undefined) {
            return (
                <h6>ADC Request <a><CloseIcon onClick={this.close} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h6>
            )
        } else {
            return (
                <h6>Adc Request </h6>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };

    renderMappingImageButton = () => {
        if (this.state.getData && this.state.fileUploadData.length > 0) {
            return (
                <div>
                    <br></br>
                    <a href={backEndServerURL + "/file/" + this.state.fileUploadData[this.state.fileUploadData.length - 1] + "/" + sessionStorage.getItem("accessToken")}
                       target={"_blank"}>

                        <button
                            style={{
                                width: 150,

                            }}
                            className="btn btn-outline-primary btn-sm"

                            // onClick={this.mappingAllImage}

                        >
                            Preview Image
                        </button>
                    </a>
                    <br/>
                </div>

            )
        }
    };

    render() {

        const {classes} = this.props;


        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        {/* <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.headerTitle()}

                        </CardHeader> */}

                        <CardBody>

                            <div>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getMappingAllImage}>
                                    <DialogContent>
                                        <FileMappingReadOnly appId={this.state.appId}
                                                             closeModal={this.closeModal}/>
                                    </DialogContent>
                                </Dialog>
                                <Grid container>
                                    <ThemeProvider theme={theme}>
                                        <Dialog
                                            fullWidth="true"
                                            maxWidth="sm"
                                            className={classes.modal}
                                            classes={{paper: classes.dialogPaper}}
                                            open={this.state.loading}>
                                            <DialogContent className={classes.dialogPaper}>

                                                <center>
                                                    <img src={loader} alt=""/>
                                                </center>
                                            </DialogContent>
                                        </Dialog>
                                        {this.renderNotification()}
                                        {this.renderJsonForm()}
                                        <br></br>
                                        {this.renderPhoneJsonForm()}
                                        {/* <Grid item xs={12}>
                                            {this.renderFileUpload()}
                                        </Grid> */}
                                        <Grid item xs={12}>

                                            {this.renderRemarksData()}

                                        </Grid>

                                        {this.renderMappingImageButton()}

                                        {this.renderRemarks()}

                                    </ThemeProvider>


                                </Grid>
                            </div>

                            <br/>

                            {this.renderSubmitButton()}&nbsp;
                            {this.renderReturnButton()}

                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }


}

export default withStyles(styles)(AdcServiceBOM);
