import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";


let populateForm = [
    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 4,
        errorMessage: "Error",
        enum: [
            "City Maxx Instant debit card",
            "CUP Instant debit card",
            "City Gem Instant debit card",
            "City Alo Instant Debit Card",
            "City MaxX Islamic instant debit card",
            "Visa City Alo Islamic Instant Debit Card",
            "Mastercard Islamic Instant Debit Card"
        ]
    },
    {
        type: "empty",
        grid: 8,
    },

    // {
    //     varName: "cardType",
    //     type: "select",
    //     label: "Card Type",
    //     required: true,
    //     grid: 2,
    //     errorMessage: "Error",
    //     conditionalOrValue: true,
    //     conditionalVarName: "productType",
    //     conditionalOrList: [
    //         "CITY MAXX STAFF",
    //         "CITY MAXX GENERAL",
    //         "CITY MAXX CORPORATE 1st year free",
    //         "CITY MAXX KIOSK CAMPAIGN",
    //         "CITY MAXX CITY GEMS (PRIORITY BANKING)",
    //         "CITY MAXX YOUTH",
    //         "Citymaxx Corporate 2nd Year Tk. 300",
    //         "CITYMAXX corporate lifetime free",
    //         "MaxX City Gems Sapphire",
    //         "Easy City Maxx Debit Card",
    //         "Easy Plus City Maxx Debit Card",
    //         "City MaxX Corporate 1st Year & 2nd Year Free"
    //     ],
    //     enum:["CITY MAXX DEBIT CARD"]
    // },
    // {
    //     varName: "cardType",
    //     type: "select",
    //     label: "Card Type",
    //     required: true,
    //     grid: 2,
    //     errorMessage: "Error",
    //     conditionalOrValue: true,
    //     conditionalVarName: "productType",
    //     conditionalOrList: [
    //         "MASTER CARD PLATINUM STAFF",
    //         "MASTER CARD PLATINUM GENERAL",
    //         "MASTER CARD DEBIT CARD STAFF",
    //         "MASTER CARD DEBIT CARD GENERAL",
    //         "MASTER DEBIT CARD CORPORATE 1st year free",
    //         "MASTER CARD DEBIT CARD YOUTH",
    //         "MASTER CARD DEBIT CARD PLATINUM CITY GEMS",
    //         "Master Debit Card Corporate 2nd year tk 300",
    //         "Master Debit Card corporate lifetime free",
    //         "Master Platinum Gems staff card",
    //         "MC Platinum Debit Card - City Gems - Sapphire",
    //         "Master Manarah Debit card general",
    //         "Master Manarah Debit card Corporate",
    //         "Master Card Corporate 1st Year & 2nd Year Free",
    //     ],
    //     enum:["MASTER CARD DEBIT CARD"]
    // },
    // {
    //     varName: "cardType",
    //     type: "select",
    //     label: "Card Type",
    //     required: true,
    //     grid: 2,
    //     errorMessage: "Error",
    //     conditionalOrValue: true,
    //     conditionalVarName: "productType",
    //     conditionalOrList: [
    //         "VISA DEBIT CARD STAFF",
    //         "VISA DEBIT CARD GENERAL",
    //         "VISA CORPORATE 1st year free",
    //         "VISA DEBIT CARD YOUTH",
    //         "VISA Debit Card corporate 2nd year tk 300",
    //         "VISA Debit Card corporate lifetime free",
    //         "VISA Debit Card - City Alo",
    //         "Easy VISA Debit card",
    //         "Easy Plus VISA Debit card",
    //         "Visa Corporate 1st Year & 2nd Year Free",
    //         "Debit Card Fee - University Campaign [172.5,345]"
    //     ],
    //     enum:["VISA DEBIT CARD"]
    // },
    {
        varName: "quantity",
        type: "text",
        label: "Quantity",
        validation: "numeric",
        required: true,
        grid: 4,
        errorMessage: "Error",

    },
    {
        type: "empty",
        grid: 8,
    },
    // {
    //     varName: "remarks",
    //     type: "text",
    //     label: "Remarks",
    //     multiline: true,
    //     grid: 4,
    //     errorMessage: "Error",
    //
    // },
    // {
    //     type: "empty",
    //     grid: 8,
    // },
];

// const upload = [
//     {
//         varName: "fileUpload",
//         type: "file",
//         label: "Upload",
//         accept: ".pdf",
//         required: true,
//         grid: 2,
//         errorMessage: "Error"
//     },

// ];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class InstaDebitCardRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            getData: false,
            notificationMessage: "",
            alert: false,
            title: '',
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            getRemarks: [],
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    handleChange = event => {
        this.setState({appData: event.target.value});
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForGetRemarks();

                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        loading: false,
                        appId: this.props.appId,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        getImageLink: response.data,
                        getImageBoolean: true,
                        imageName: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    console.log(response.data.id);
                    this.setState({
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Preview Attached Document
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };


    saveHandler = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let error = MyValidation.defaultValidation(populateForm, this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false
            });
            return 0;
        } else {
            console.log("in the method");
            // let uploadUrl = backEndServerURL + "/case/upload";
            // let formData = new FormData();
            // formData.append("appId", this.state.appId);
            // formData.append("file", this.state.fileUploadData["fileUpload"]);
            // formData.append("type", "file");

            // axios({
            //     method: "post",
            //     url: uploadUrl,
            //     data: formData,
            //     headers: {
            //         Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            //         "content-type": "multipart/form-data"
            //     }
            // })
            //     .then(response => {

            //     })
            //     .catch(error => {
            //         console.log(error);
            //         console.log('file Missing')
            //     });

            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    this.setState({
                        disabled: false,
                    });
                    console.log(error)
                });
            this.state.inputData.csRemarks = undefined;

            let postData = this.state.inputData;
            postData.category = "Debit Card";
            postData.subCategory = "Bulk Insta Debit Card";
            postData.serviceType = "Debit Card";
            postData.subServiceType = "InstaDebitCard";
            postData.customerName = this.state.inputData.customerName;
            postData.appId = this.state.appId;

            let url = backEndServerURL + "/variables/" + this.state.appId;
            axios
                .post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let caseRouteUrl =
                        backEndServerURL + "/case/route/" + this.state.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            console.log("Case Route Successful");
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });

                            if (this.props.appId !== undefined) {
                                this.props.closeModal();
                            } else {
                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({
                                loading: false
                            });
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Bulk Insta Debit Card Requisition
                    <a>
                        <CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "#000000"
                            }}
                        />
                    </a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Bulk Insta Debit Card Requisition
                </h4>
            )
        }
    };


    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });

    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {
                                this.renderHeader()
                            }
                        </CardHeader>
                        <CardBody>

                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        populateForm,
                                        this.updateComponent
                                    )}
                                    <Grid item xs={12}>
                                        {this.renderRemarksData()}
                                        <br/>
                                        {
                                            this.renderRemarks()
                                        }
                                    </Grid>
                                </ThemeProvider>
                            </Grid>

                            <Grid item xs="3" style={{marginTop: "20px"}}>
                                {/*<center>*/}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.saveHandler}
                                >
                                    Submit
                                </button>
                                &nbsp;&nbsp;
                                {this.props.appId !== undefined && <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={this.handleClose}
                                >
                                    Close
                                </button>}
                            </Grid>
                            {
                                this.renderNotification()
                            }
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }
}

export default withStyles(styles)(InstaDebitCardRequisition);
