import React, {Component} from "react";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";

class FunctionForGetAcStatus extends Component {
    static getAccountStatus(acNumber) {
        return (
            axios({
                url: backEndServerURL + "/GetCustomAcctDetailsRequest/" + acNumber,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'application/json'
                }
            }).then((response) => {
                if (response.status === 200 && response.data.acctStatus) {
                    return {status: "found", value: response.data.acctStatus};
                } else {
                    return {status: "Not Found!", value: ""}
                }
            }).catch((error) => {
                return {status: "error", value: error}
            })
        )
    }
}

export default FunctionForGetAcStatus
