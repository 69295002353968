import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import validator from "validator";
import MyValidation from "../../../JsonForm/MyValidation";
import Label from '../static/Label';
import loader from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import Table from "../../../Table/Table";
import GridContainer from "../../../Grid/GridContainer";
import SelectComponent from "../../../JsonForm/SelectComponent";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import UploadComponent from "../casa/UploadComponent";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import SignatureButton from "../SignatureButton";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import CommonDedup from "../../CommonComponent/CommonDedup";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",
    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferral Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

let searchFieldList = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No",
        "required": true,
        "validation": "numeric",
        "grid": 2,

    },
];

let signatoryForm = [
    {
        "varName": "numberOfNewMandate",
        "type": "select",
        "label": " Number of New Mandate/Beneficial Owner/Signatory",
        "onKeyDown": true,
        "required": true,
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10"
        ],
        "grid": 3,
    },
];
let mandateOption = [
    {
        "varName": "mandateOption",
        "type": "select",
        "label": "Mandate/Related Party Add or Remove",
        "onKeyDown": true,
        "required": true,
        "enum": [
            "Add Mandate/Related Party",
            "Remove Mandate/Related Party",
        ],
        "grid": 3,
    },
];



class MandateUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchData: false,
            cbNotFound: false,
            inputData: {},
            getData: false,
            varValue: {},
            title: "",
            loading: true,
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            disabled: false,
            renderDedupFrom: false,
            showSignatoryForm: false,
            mandateTableArray: [],
            objectForNewMandate: [],
            handleChangeJoint: 0,
            jointSearchTable: [],
            sdnData: [],
            values: [],
            jointDedupData: {},
            searchTableData: false,
            searchTableSdnData: false,
            AddDeferal: false,
            getDeferalList: [],
            fileUploadData: [],
            phoneCountryCode: [],
            nationalityApiData: [],
            getRemarks: [],
            appId: 0,
            jointDataSaveId: '',
            caseId: 0,
            showDedupComponent: false,
            showSubmitOption: false,
        };
    }


    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null' || clone[prop] === '-1')
                delete clone[prop];
        return clone;
    };


    createTableData = (sl, cb, name, relationship, designation, existingDeleteFlag) => {

        return ([
            sl, cb, name, relationship, designation, existingDeleteFlag
        ])

    };


    componentDidMount() {
        this.dynamicApiCall("nationality", "country");
        this.dynamicApiCall("phoneCountryCode", "phoneCountryCode");
        let url = backEndServerURL + "/startCase/cs_maintenance";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    caseId: response.data.caseId,
                    appId: response.data.id,
                    loading: false,
                });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })

    }

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    if (apiType === "country") {
                        this.setState({
                            nationalityApiData: response.data
                        })
                    }
                    if (apiType === "phoneCountryCode") {
                        this.setState({
                            phoneCountryCode: response.data
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };


    updateComponent = () => {
        this.forceUpdate();
    };

    renderSearchForm = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, searchFieldList, this.updateComponent, this.onKeyDownForDedup)
                        }
                        <Grid item xs={3}>
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={this.handleSearch}
                                className="btn btn-danger btn-sm">
                                Search
                            </button>
                            &nbsp;&nbsp;
                            <button
                                style={{
                                    marginTop: "18px"
                                }}
                                onClick={() => window.location.reload()}
                                className="btn btn-outline-primary btn-sm">
                                Reset
                            </button>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </React.Fragment>
        )
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSearch();
        }
    };


    handleSearch = () => {
        let error = MyValidation.defaultValidation(searchFieldList, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            this.setState({
                searchData: false,
                loading: true,
            });

            let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + this.state.inputData.accountNumber;
            axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((acResponse) => {
                    if (acResponse.data.acctStatus === "D" && this.props.match.params.requestType === undefined) {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            getData: false,
                            searchData: false,
                            title: "Dormant account is not acceptable!"
                        });
                    } else {
                        let mandateTableArray = [];
                        if (acResponse.data.relPartyList) {
                            acResponse.data.relPartyList.map((mandate, index) => {
                                if (mandate.relPartyType !== "M") {
                                    let exFlag;
                                    if (mandate.recDelFlg === "Y") {
                                        exFlag = "CB Deleted"
                                    } else if (mandate.recDelFlg === "N") {
                                        exFlag = "CB Existed"
                                    } else {
                                        exFlag = mandate.recDelFlg;
                                    }
                                    mandateTableArray.push(this.createTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, mandate.relPartyTypeDesc, exFlag))
                                }
                            });
                        }
                        let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
                        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.data.message === "No Account Found!") {
                                    this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                    this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                    this.setState({
                                        loading: false,
                                        cbNotFound: true,
                                        title: 'No Account Found!'
                                    });
                                }
                                let inputData = {...response.data};
                                let getCbType = backEndServerURL + "/getCbTypes/" + inputData.cbNumber;
                                axios.get(getCbType, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((cbTypeResponse) => {
                                        if (cbTypeResponse.data === "RETAIL") {
                                            inputData.uploadFileAlertRequest = true;
                                            inputData.customerName = response.data.customerName;
                                            inputData.freeFlag3 = response.data.solid;
                                            inputData.schemeCodeChangemodeOfOperation = response.data.modeOfOper;
                                            inputData.schmType = acResponse.data.schmType;

                                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        inputData.crmStatus = response.data.custStatus;
                                                        inputData.motherAccountSolId = response.data.branchCode;
                                                        inputData.solId = response.data.branchCode;
                                                        if (response.data.COMMPH1BLOCK) {
                                                            inputData.existingPhoneNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                        }
                                                        this.setState({
                                                            inputData: inputData,
                                                            varValue: this.copyJson(inputData),
                                                            mandateTableArray: mandateTableArray,
                                                            loading: false,
                                                            // otpModal: true,
                                                            showValue: true,
                                                            getData: true,
                                                            searchData: true,
                                                        });
                                                    }
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.setState({
                                                        getData: false,
                                                        loading: false,
                                                        cbNotFound: true,
                                                        title: error.response.data.message
                                                    })
                                                });
                                        }
                                        else {
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: 'Not a Retail Account!'
                                            });
                                        }
                                    }).catch((error) => {
                                        this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                        this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                        this.setState({
                                            loader: false,
                                            loading: false,
                                            cbNotFound: true,
                                            title: error.response.data.message
                                        });
                                    });

                            })
                            .catch((error) => {
                                console.log(error);
                                this.state.varValue['eqmNumber'] = this.state.inputData.eqmNumber;
                                this.state.varValue['accountNumber'] = this.state.inputData.accountNumber;
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message,
                                    showValue: true,
                                });
                            })
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        getData: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false
            })
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Related Party:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Designation Code", "Related CB Status"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };


    renderMandateOption = () => {
        if (!this.state.loading  && this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, mandateOption, this.updateComponent, "", this.onKeyDownChange)
        }
    };



    renderSignatoryForm = () => {
        if (!this.state.loading && this.state.showSignatoryForm) {
            return CommonJsonFormComponent.renderJsonForm(this.state, signatoryForm, this.updateComponent, "", this.onKeyDownChange)
        }
    };



    onKeyDownChange = (data) => {
        if (data.varName === "numberOfNewMandate") {
            if (this.state.inputData.numberOfNewMandate && this.state.showSignatoryForm) {
                this.setState({
                    showDedupComponent: false,
                    showSubmitOption: false,
                    loading: true
                });
                let inputData = this.state.inputData;
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showDedupComponent: true,
                        loading: false
                    });
                }, 100);
                this.forceUpdate();
            }
        }
        if (data.varName === "mandateOption") {
            console.log(this.state.inputData.mandateOption);
            if (this.state.inputData.mandateOption && this.state.inputData.mandateOption ==="Add Mandate/Related Party") {
                this.setState({
                    showDedupComponent: false,
                    loading: true,
                    showSignatoryForm: false,
                    showSubmitOption:false,
                });
                let inputData = this.state.inputData;
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showDedupComponent: true,
                        loading: false,
                        showSignatoryForm: true
                    });
                }, 100);
                this.forceUpdate();
            }
            else if (this.state.inputData.mandateOption && this.state.inputData.mandateOption ==="Remove Mandate/Related Party") {
                this.setState({
                    showDedupComponent: false,
                    showSubmitOption:false,
                    loading: true,
                });
                let inputData = this.state.inputData;
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false,
                        showSignatoryForm: false,
                        showSubmitOption: true,
                    });
                }, 100);
                this.forceUpdate();
            }
            else if(this.state.inputData.mandateOption ===undefined || this.state.inputData.mandateOption === null ){
                this.setState({
                    loading: true,
                });
                let inputData = this.state.inputData;
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showDedupComponent: false,
                        loading: false,
                        showSignatoryForm: false,
                        showSubmitOption: false,

                    });
                }, 100);
                this.forceUpdate();
            }
        }

    };


    renderCustomerDetails = () => {
        if (this.state.getData && !validator.isEmpty(this.state.inputData["accountNumber"]) && this.state.searchData) {
            return (
                <React.Fragment>
                    <Label accountNumber={this.state.inputData.accountNumber} cbNumber={this.state.inputData.cbNumber}
                           stopLoading={this.stopLoading} classes={this.props}
                           crmStatus={this.state.inputData.crmStatus}/>
                </React.Fragment>
            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Deferral</button>
        )

    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };


    viewImageModal = (event) => {
        event.preventDefault();
        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })
    };


    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));

        this.state.inputData["csDeferal"] = "YES";
    }

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>


                </Grid>
            </React.Fragment>
        )

    }

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }

    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} showSignature={true} appId={this.state.appId}
                                         classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };


    renderData = () => {
        if (this.state.getData && !this.state.loading && this.state.showSubmitOption) {
            return (
                <React.Fragment>
                    <ThemeProvider theme={theme}>
                        <Grid container>

                            <Grid item xs='12'>
                                {
                                    this.renderAddButtonShow()
                                }
                            </Grid>


                            <Grid item xs='12'>

                            </Grid>
                            {
                                this.addDeferalForm()
                            }
                            <Grid item xs={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <Grid item xs={12}>

                                {this.renderRemarksData()}

                            </Grid>
                            <Grid item xs={12}>
                                {this.uploadFunction()}
                            </Grid>

                            <Grid item xs='12'>
                            </Grid>
                            {this.renderRemarks()}

                            <center>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{}}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.disabled}
                                >
                                    Submit
                                </button>
                            </center>
                        </Grid>
                    </ThemeProvider>
                </React.Fragment>
            )
        }
    };

    handleDedupSearchData = (value, searchValue, input) => {
        // let dedeupSearch = {};
        // if (searchValue !== undefined) {
        //     let sl = 0;
        //     for (let item of searchValue) {
        //         for (let singleItem in item) {
        //             if(singleItem === "phone"){
        //                 dedeupSearch[singleItem + sl] = item[singleItem].substring(2);
        //             }
        //             else if(singleItem === "dob" && item[singleItem] === "1000-01-01"){
        //                 dedeupSearch[singleItem + sl] = ""
        //             }
        //             else{
        //                 dedeupSearch[singleItem + sl] = item[singleItem]
        //             }
        //         }
        //         sl++;
        //     }
        // }
        let cloneDedup = this.copyJson(input);
        // console.log(cloneDedup);
        if (value !== undefined) {
            this.setState({
                jointDedupData: value,
                inputData: {...this.state.inputData, ...cloneDedup},
                showSubmitOption: true
            })
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.state.inputData.mandateOption ==="Add Mandate/Related Party"){
            let error = MyValidation.defaultValidation(signatoryForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        let error = MyValidation.defaultValidation(mandateOption, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "Please Upload File!"
                })
            } else {
                this.setState({
                    loading: true,
                    disabled: true
                });
                if (this.state.inputData["csDeferal"] === "YES") {
                    let defType = [];
                    let expDate = [];
                    var deferralData = {};
                    let appId = this.state.appId;
                    for (let i = 0; i < this.state.values.length; i++) {
                        let value = this.state.values[i];
                        let deferalType = this.state.inputData["deferalType" + value];
                        if (deferalType === "other") {
                            deferalType = this.state.inputData["deferalOther" + value];
                        }
                        defType.push(deferalType);
                        let expireDate = this.state.inputData["expireDate" + value];
                        expDate.push(expireDate);

                    }
                    deferralData.appId = appId;
                    deferralData.type = defType;
                    deferralData.dueDate = expDate
                }
                let Dedupurl = backEndServerURL + "/dedup/save";
                axios.post(Dedupurl, {
                    "jointDedupData": this.state.jointDedupData,
                    "dedupType": "MandateMaintenance",
                    "appId": this.state.appId
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let data = this.state.inputData;
                        data.cs_deferal = this.state.inputData.csDeferal;
                        data.jointDedupData = response.data;
                        data.serviceType = "Maintenance";
                        data.subServiceType = "MandateMaintenance";
                        data.category = "Mandate";
                        data.freeFlag5 = "Maintenance";
                        data.subCategory = "Mandate Update";
                        data.freeFlag6 = "Mandate/Related Party Maintenance";
                        if (this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) {
                            data.cs_deferal = "NO";
                            data.cs_bearer = "NO";
                        } else if (this.state.inputData.csDeferal === "YES") {
                            data.cs_deferal = "YES";
                            data.cs_bearer = "NO";
                        }
                        data.freeFlag1 = "Mandate/Signatory";
                        let remarksData = {};
                        remarksData.remark = this.state.inputData.csRemarks;
                        remarksData.map = {...data, csRemarks: undefined};
                        remarksData.bulkDeferralDTO = deferralData;
                        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                routeCase: true,
                                alert: true,
                                disabled: false,
                                loading: false
                            });
                            if (this.props.appId !== undefined) {
                                this.props.closeModal();
                            } else {
                                setTimeout(function () {
                                    window.location.reload()
                                }, 1000);
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false,
                                cbNotFound: true,
                                loading: false,
                                title: error.response.data.message
                            })
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            disabled: false,
                            cbNotFound: true,
                            loading: false,
                            title: error.response.data.message
                        })
                    });
            }
        }
    };

    renderSignature = () => {
        if (this.state.getData && !this.state.loading) {
            return (
                <Grid item xs={12}>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}
                                     signatureType="multiple"/>
                    <br/>
                </Grid>
            )
        }
    };

    closeDedupComponent = () => {
        this.setState({
            showDedupComponent: false,
            showSubmitOption: false,

        })
    };
    showSubmitOption = () => {
        this.setState({
            showSubmitOption: true
        })
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Mandate/Related Party Maintenance</h4>
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    {this.renderSearchForm()}
                    {this.renderNotification()}
                    {this.renderCustomerDetails()}
                    {this.renderMandateData()}
                    {this.renderSignature()}
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {this.renderMandateOption()}
                            {this.renderSignatoryForm()}
                        </ThemeProvider>
                    </Grid>
                    {(this.state.inputData.numberOfNewMandate && this.state.showDedupComponent === true) &&
                    <CommonDedup numberOfDedup={this.state.inputData.numberOfNewMandate}
                                 closeDedupComponent={this.closeDedupComponent}
                                 nationalityApiData={this.state.nationalityApiData}
                                 handleDedupSearchData={this.handleDedupSearchData}
                                 phoneCountryCode={this.state.phoneCountryCode} appId={this.state.appId}/>}
                    {this.renderData()}
                </CardBody>
            </Card>
        );

    }

}

export default withStyles(styles)(MandateUpdate);
