import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import '../../Static/css/login.css';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});

class AddMenu extends Component {

    state = {
        menus: [],
        // menuId: 1,
        message: "",
        checked: "",
        name: "",
        position: "",

    };


    componentDidMount() {

        let menuId = this.props.menuId;
        // let menuId = 10;
        console.log(menuId);
        this.setState({menuId: menuId});
    }

    handleChange = event => {

        this.setState({[event.target.name]: event.target.value});

    };


    render() {
        const {classes} = this.props;
        return (
            <GridContainer>


                <GridItem xs={6} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>Add Menu</h4>
                        </CardHeader>
                        <CardBody>
                            <div className={classes.root}>
                                <form onSubmit={this.handlesubmit} className={classes.form}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} sm={3}>

                                            <FormControl margin="normal" fullWidth>
                                                <InputLabel htmlFor="name">Menu Name</InputLabel>
                                                <Input onChange={this.handleChange} id="name" name="name"
                                                       autoComplete="name" autoFocus/>
                                            </FormControl>
                                            <FormControl margin="normal" fullWidth>
                                                Position
                                                <select name="sources" id="position">
                                                    <option value="RIGHT" onChange={this.handleChange}>RIGHT</option>
                                                    <option value="CHILD" onChange={this.handleChange}>CHILD</option>
                                                </select>
                                            </FormControl>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </Grid>
                                    </Grid>
                                    <center>
                                        <Button
                                            type="primary" htmlType="submit" className={classes.Buttoncolorchange}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Create
                                        </Button>
                                    </center>
                                </form>
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        );
    }
}

export default withStyles(styles)(AddMenu);
