//##########MainTenance##############
//CS Maintenance With Print File
function makeReadOnly(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject.variables;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let csSendTo = {
    "varName": "cs_send_to",
    "type": "select",
    "label": "Send to?",
    "enum": [
        "BOM",
        "BM"
    ],
};
let bomSendTo = {
    "varName": "bom_send_to",
    "type": "select",
    "label": "Send to?",

    "enum": [
        "CS",
        "BM"
    ]
};
let bomApproval = {
    "varName": "bom_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let bmSendTo = {
    "varName": "bm_send_to",
    "type": "select",
    "label": "Send to?",

    "enum": [
        "BOM",
        "CS"
    ]
};
let bmApprove = {
    "varName": "bm_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};

let makerApprove = {
    "varName": "maker_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let checkerApprove = {
    "varName": "checker_approval",
    "type": "select",
    "label": "Approve?",

    "enum": [
        "APPROVED",
        "NOTAPPROVED"
    ]
};
let JsonFormMaintenanceWithPrintFileAddress = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldAddress",
            "type": "text",
            "label": "Old Address",
        },
        {
            "varName": "newAddress",
            "type": "text",
            "label": "New Address",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }
    ],

};
let JsonFormMaintenanceWithPrintFileContact = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldContact",
            "type": "text",
            "label": "Old Contact",
        },
        {
            "varName": "newContact",
            "type": "text",
            "label": "New Contact",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileEmail = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldEmail",
            "type": "text",
            "label": "Old Email",
        },
        {
            "varName": "newEmail",
            "type": "text",
            "label": "New Email",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileNominee = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldNominee",
            "type": "text",
            "label": "Old Nominee",
        },
        {
            "varName": "newNominee",
            "type": "text",
            "label": "New Nominee",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileTenor = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldTenor",
            "type": "text",
            "label": "Old Tenor",
        },
        {
            "varName": "newTenor",
            "type": "text",
            "label": "New Tenor",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileScheme = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldScheme",
            "type": "text",
            "label": "Old Scheme",
        },
        {
            "varName": "newScheme",
            "type": "text",
            "label": "New Scheme",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileMaturity = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldMaturity",
            "type": "text",
            "label": "Old Maturity",
        },
        {
            "varName": "newMaturity",
            "type": "text",
            "label": "New Maturity",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileTitle = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldTitle",
            "type": "text",
            "label": "Old Title",
        },
        {
            "varName": "newTitle",
            "type": "text",
            "label": "New Title",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileLink = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldLink",
            "type": "text",
            "label": "Old Link",
        },
        {
            "varName": "newLink",
            "type": "text",
            "label": "New Link",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithPrintFileEtin = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldEtin",
            "type": "text",
            "label": "Old Etin",
        },
        {
            "varName": "newEtin",
            "type": "text",
            "label": "New Etin",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ]

};
let JsonFormMaintenanceWithPrintFileDormant = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldDormant",
            "type": "text",
            "label": "Old Dormant",
        },
        {
            "varName": "newDormant",
            "type": "text",
            "label": "New Dormant",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }

    ],

};
let JsonFormMaintenanceWithPrintFileInputFiled = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },

        {
            "varName": "oldInputFiled",
            "type": "text",
            "label": "Old InputFiled",
        },
        {
            "varName": "newInputFiled",
            "type": "text",
            "label": "New InputFiled",
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};
let JsonFormMaintenanceWithFiles = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB number"
        },
        {
            "varName": "file",
            "type": "file",
            "label": "Attach File",
        }


    ],

};


//CS Maintenance with print file
let CSJsonFormMaintenanceWithPrintFileAddress = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileAddress));
CSJsonFormMaintenanceWithPrintFileAddress.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileContact = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileContact));
CSJsonFormMaintenanceWithPrintFileContact.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileEmail = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileEmail));
CSJsonFormMaintenanceWithPrintFileEmail.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileNominee = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileNominee));
CSJsonFormMaintenanceWithPrintFileNominee.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileTenor = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileTenor));
CSJsonFormMaintenanceWithPrintFileTenor.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileScheme = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileScheme));
CSJsonFormMaintenanceWithPrintFileScheme.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileMaturity = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileMaturity));
CSJsonFormMaintenanceWithPrintFileMaturity.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileTitle = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileTitle));
CSJsonFormMaintenanceWithPrintFileTitle.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileLink = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileLink));
CSJsonFormMaintenanceWithPrintFileLink.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileEtin = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileEtin));
CSJsonFormMaintenanceWithPrintFileEtin.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileDormant = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileDormant));
CSJsonFormMaintenanceWithPrintFileDormant.variables.push(csSendTo);
let CSJsonFormMaintenanceWithPrintFileInputFiled = JSON.parse(JSON.stringify(JsonFormMaintenanceWithPrintFileInputFiled));
CSJsonFormMaintenanceWithPrintFileInputFiled.variables.push(csSendTo);

//CS Maintenance With File
let CSJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
CSJsonFormMaintenanceWithFile.variables.push(csSendTo);

//BOM Maintenance With Print File
let BOMJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BOMJsonFormMaintenanceWithPrintFileContact.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileContact.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BOMJsonFormMaintenanceWithPrintFileLink.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileLink.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bomSendTo);
let BOMJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bomApproval);
BOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bomSendTo);
//BOM Maintenance With File
let BOMJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
BOMJsonFormMaintenanceWithFile.variables.push(bomApproval);
BOMJsonFormMaintenanceWithFile.variables.push(bomSendTo);


//Maker Maintenance With Print File
let MAKERJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
let MAKERJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
let MAKERJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
let MAKERJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
let MAKERJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
let MAKERJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
let MAKERJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
let MAKERJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
let MAKERJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
let MAKERJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
let MAKERJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
let MAKERJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
//Maker Maintenance With File
let MAKERJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));


//Checker Maintenance With Print File
let CHECKERJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
CHECKERJsonFormMaintenanceWithPrintFileAddress.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
CHECKERJsonFormMaintenanceWithPrintFileContact.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
CHECKERJsonFormMaintenanceWithPrintFileEmail.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
CHECKERJsonFormMaintenanceWithPrintFileNominee.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
CHECKERJsonFormMaintenanceWithPrintFileTenor.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
CHECKERJsonFormMaintenanceWithPrintFileScheme.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
CHECKERJsonFormMaintenanceWithPrintFileMaturity.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
CHECKERJsonFormMaintenanceWithPrintFileTitle.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
CHECKERJsonFormMaintenanceWithPrintFileLink.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
CHECKERJsonFormMaintenanceWithPrintFileEtin.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
CHECKERJsonFormMaintenanceWithPrintFileDormant.variables.push(checkerApprove);

let CHECKERJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
CHECKERJsonFormMaintenanceWithPrintFileInputFiled.variables.push(checkerApprove);

//CHECKER Maintenance With File
let CHECKERJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
CHECKERJsonFormMaintenanceWithFile.variables.push(checkerApprove);


//BM  Maintenance With print File
let BMfromCSJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BMfromCSJsonFormMaintenanceWithPrintFileAddress.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileAddress = makeReadOnly(JsonFormMaintenanceWithPrintFileAddress);
BMfromBOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileAddress.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BMfromCSJsonFormMaintenanceWithPrintFileContact.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileContact = makeReadOnly(JsonFormMaintenanceWithPrintFileContact);
BMfromBOMJsonFormMaintenanceWithPrintFileContact.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileContact.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BMfromCSJsonFormMaintenanceWithPrintFileEmail.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileEmail = makeReadOnly(JsonFormMaintenanceWithPrintFileEmail);
BMfromBOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileEmail.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BMfromCSJsonFormMaintenanceWithPrintFileNominee.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileNominee = makeReadOnly(JsonFormMaintenanceWithPrintFileNominee);
BMfromBOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileNominee.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BMfromCSJsonFormMaintenanceWithPrintFileTenor.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileTenor = makeReadOnly(JsonFormMaintenanceWithPrintFileTenor);
BMfromBOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileTenor.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BMfromCSJsonFormMaintenanceWithPrintFileScheme.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileScheme = makeReadOnly(JsonFormMaintenanceWithPrintFileScheme);
BMfromBOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileScheme.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BMfromCSJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileMaturity = makeReadOnly(JsonFormMaintenanceWithPrintFileMaturity);
BMfromBOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileMaturity.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BMfromCSJsonFormMaintenanceWithPrintFileTitle.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileTitle = makeReadOnly(JsonFormMaintenanceWithPrintFileTitle);
BMfromBOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileTitle.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BMfromCSJsonFormMaintenanceWithPrintFileLink.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileLink = makeReadOnly(JsonFormMaintenanceWithPrintFileLink);
BMfromBOMJsonFormMaintenanceWithPrintFileLink.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileLink.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BMfromCSJsonFormMaintenanceWithPrintFileEtin.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileEtin = makeReadOnly(JsonFormMaintenanceWithPrintFileEtin);
BMfromBOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileEtin.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BMfromCSJsonFormMaintenanceWithPrintFileDormant.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileDormant = makeReadOnly(JsonFormMaintenanceWithPrintFileDormant);
BMfromBOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileDormant.variables.push(bmSendTo);
let BMfromCSJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BMfromCSJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled = makeReadOnly(JsonFormMaintenanceWithPrintFileInputFiled);
BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmApprove);
BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled.variables.push(bmSendTo);

//BM Maintenance With File
let BMfromCSJsonFormMaintenanceWithFile = JSON.parse(JSON.stringify(JsonFormMaintenanceWithFiles));
BMfromCSJsonFormMaintenanceWithFile.variables.push(bmApprove);
let BMfromBOMJsonFormMaintenanceWithFile = JsonFormMaintenanceWithFiles;
BMfromBOMJsonFormMaintenanceWithFile.variables.push(bmSendTo);
BMfromBOMJsonFormMaintenanceWithFile.variables.push(bmApprove);

//######Existing Account Opening individual#########

let CommonExistJsonFormIndividualAccountOpening = [
    {
        "varName": "customerNameOld",
        "type": "text",
        "label": "Customer Name(Old)",
        "readOnly": true
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name(New)"
    },
    /* {
         "varName": "dobOld",
         "type": "date",
         "label": "Date OF Birth(Old)",
         "readOnly": true
     },*/
    {
        "varName": "dob",
        "type": "date",
        "label": "Date OF Birth(New)",
    },
    {
        "varName": "nidOld",
        "type": "text",
        "label": "NID No(Old)",
        "readOnly": true
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No(New)",
    },
    {
        "varName": "passportOld",
        "type": "text",
        "label": "Passport Number(Old)",
        "readOnly": true
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number(New)"
    },
    {
        "varName": "phoneOld",
        "type": "text",
        "label": "Phone No(Old)",
        "readOnly": true
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Phone No(New)",
    },
    {
        "varName": "birthCertificateOld",
        "type": "text",
        "label": "Birth Certificate(Old)",
        "readOnly": true
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth Certificate(New)",
    },
    {
        "varName": "drivingLicenseOld",
        "type": "text",
        "label": "Driving License(Old)",
        "readOnly": true
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License(New)"
    },
    {
        "varName": "emailOld",
        "type": "text",
        "label": "Email(Old)",
        "readOnly": true
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email(New)",
    },
    {
        "varName": "comAddressOld",
        "type": "text",
        "label": "Communication Address(Old)",
        "readOnly": true
    },
    {
        "varName": "comAddress",
        "type": "text",
        "label": "Communication Address(New)",
    },


    {
        "varName": "permanentAddressOld",
        "type": "text",
        "label": "Permanent Address(Old)",
        "readOnly": true
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address(New)"
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
    },
    {
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer CB",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "e-statement",
        "type": "select",
        "label": "E-Statement",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "chequeBookRequest",
        "type": "select",
        "label": "Cheque Book Request",
        "enum": [
            "YES",
            "NO"
        ]
    }

];

let commonJsonFormIndividualAccountOpeningForm = [
    {
        "varName": "productCode",
        "type": "text",
        "label": "Product Code",

    },

    /*{
        "varName": "accountType",
        "type": "select",
        "label": "Account Type",
        "enum": [
            "Instrapack",
            "NonInstrapack"
        ]
    },*/
    {
        "varName": "sbsCode",
        "type": "select",
        "label": "SBS Code",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "debitCard",
        "type": "select",
        "label": "Debit Card",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "cityTouchID",
        "type": "select",
        "label": "City Touch ID Request",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "lockerService",
        "type": "select",
        "label": "Locker Service Request",
        "enum": [
            "YES",
            "NO"
        ]
    }
];
let CSExistJsonFormIndividualAccountOpening = {};
CSExistJsonFormIndividualAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSExistJsonFormIndividualAccountOpening.variables.push(csSendTo);


//######New Account Opening individual#########

let CommonNewJsonFormIndividualAccountOpening = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number  "
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name"
    },
    /* {
         "varName": "dob",
         "type": "date",
         "label": "Date OF Birth",
     },*/
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number"
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License"
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth Certificate",
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Phone No",
    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
    },
    {
        "varName": "comAddress",
        "type": "text",
        "label": "Communication Address",
    },


    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address"
    },
    {
        "varName": "rmCode",
        "type": "text",
        "label": "RM Code",
    },
    {
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer CB",
    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "e-statement",
        "type": "select",
        "label": "E-Statement",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "chequeBookRequest",
        "type": "select",
        "label": "Cheque Book Request",
        "enum": [
            "YES",
            "NO"
        ]
    }

];
let CSNewJsonFormIndividualAccountOpening = {};
CSNewJsonFormIndividualAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormIndividualAccountOpening.variables.push(csSendTo);

//######BOM Account Opening individual#########
let BOMNewJsonFormIndividualAccountOpening = {};
BOMNewJsonFormIndividualAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BOMNewJsonFormIndividualAccountOpening.variables.push(bomApproval);
BOMNewJsonFormIndividualAccountOpening.variables.push(bomSendTo);

//######BM Account Opening individual#########
let BMJsonFormIndividualAccountOpening = {};
BMJsonFormIndividualAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormIndividualAccountOpening.variables.push(bmApprove);
BMJsonFormIndividualAccountOpening.variables.push(bmSendTo);


//####SD Account Opening individual#######
let SDCommonNewJsonFormIndividualAccountOpeningFinacle1 = [

    {
        // seal
        "varName": "relationshipBranchName",
        "type": "text",
        "label": "Relationship Branch Name",

    },
    {
        "varName": "aOFDate",
        "type": "date",
        "label": "Date",

    }, {
        "varName": "aOFBranchName",
        "type": "text",
        "label": "Branch Name",

    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",

    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",

    },
    {
        "varName": "title",
        "type": "text",
        "label": "Title ",

    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Short Name",

    },
    {
        // tick
        "varName": "accountType",
        "type": "text",
        "label": "Type Of Account",

    },
    {

        "varName": "purposeOfOpening",
        "type": "text",
        "label": "Purpose Of Opening",

    },
    {
        // tick
        "varName": "natureOfAccount",
        "type": "text",
        "label": "Nature Of Account",

    },
    {
        // tick
        "varName": "currency",
        "type": "text",
        "label": "Currency",

    },
    {
        // Numeric & Check Box
        "varName": "initialDeposit",
        "type": "text",
        "label": "Initial Deposit",

    },
    {
        "varName": "depositType",
        "type": "text",
        "label": "Deposit Type",

    },
    {
        "varName": "commAddressSelection",
        "type": "text",
        "label": "Comm. Address Selection",

    },
    {
        "varName": "email",
        "type": "text",
        "label": "Desig. Email ID",

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Desig. Mobile Number",

    },
    {

        "varName": "adcRequest",
        "type": "title",
        "label": "ADC Request",
    },
    {
        // tick
        "varName": "requiredService",
        "type": "text",
        "label": "Required Service(s)",

    },
    {
        "varName": "emailForCityTouchService",
        "type": "text",
        "label": "Email for City Touch Service",

    },
    {
        "varName": "preferredCityTouchUserID",
        "type": "text",
        "label": "Preferred City Touch User ID",

    },
    {
        "varName": "desiredCreditCardNumberToBeTaggedWithCitytouch",
        "type": "text",
        "label": "Desired Credit Card Number to be tagged with Citytouch",

    },
    {
        "varName": "facilitiesDetails",
        "type": "title",
        "label": "Facilities Details",
    },
    {
        // tick
        "varName": "chequeBook",
        "type": "text",
        "label": "Cheque Book",

    }, {
        // tick
        "varName": "debitCrad",
        "type": "text",
        "label": "Debit Crad",

    }, {
        // tick
        "varName": "lockerFacility",
        "type": "text",
        "label": "Locker Facility",

    },
    {
        "varName": "statementFacilities",
        "type": "text",
        "label": "Statement Facilities",

    },
    {
        "varName": "cardDetails",
        "type": "title",
        "label": "Card Details",
    },
    {
        // tick
        "varName": "cardApplicant",
        "type": "text",
        "label": "Card Applicant",

    },
    {
        // tick
        "varName": "cardType",
        "type": "text",
        "label": "Card Type",

    },
    {
        // tick
        "varName": "customerCategory",
        "type": "text",
        "label": "Customer Category",

    },
    {
        "varName": "nameOfCard",
        "type": "text",
        "label": "Name of Card",

    },
    {
        "varName": "operatingOn",
        "type": "text",
        "label": "Operating On",

    },
    {
        "varName": "cardReceiveThrough",
        "type": "text",
        "label": "Card Receive Through",

    },
    {
        "varName": "introducerInformation",
        "type": "title",
        "label": "Introducer Information",
    },
    {
        "varName": "introName",
        "type": "text",
        "label": "Introducer Name",

    },
    {
        "varName": "introAccountNumber",
        "type": "text",
        "label": "Introducer Account Number",

    },
    {
        // Alpha Numeric
        "varName": "introducerCB",
        "type": "text",
        "label": "Introducer Customer ID",

    },
    {
        "varName": "relationshipWithApplicant",
        "type": "text",
        "label": "Relationship with Applicant",

    },
    {
        "varName": "nomineeInformation",
        "type": "title",
        "label": "Nominee Information",
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",

    },
    {
        "varName": "nomineeFathersName",
        "type": "text",
        "label": "Nominee Fathers Name",

    }, {
        "varName": "nomineeMothersName",
        "type": "text",
        "label": "Nominee Mothers Name",

    },
    {
        "varName": "nomineeSopuseName",
        "type": "text",
        "label": "Nominee Sopuse Name",

    },
    {
        "varName": "nomineeOccupation",
        "type": "text",
        "label": "Nominee Occupation",

    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",

    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",

    }, {
        "varName": "nomineeBirthCertificate",
        "type": "text",
        "label": "Birth Certificate",

    }, {
        "varName": "nomineeNid",
        "type": "text",
        "label": "NID",

    },
    {
        "varName": "nomineePassport",
        "type": "text",
        "label": "Passport",

    },
    {
        "varName": "nomineePassportExpDate",
        "type": "text",
        "label": "Passport Exp Date",

    },
    {
        "varName": "nomineeDrivingLicense",
        "type": "text",
        "label": "Driving License",

    },
    {
        "varName": "DLExpDate",
        "type": "text",
        "label": "DL. Exp Date",

    },
    {
        "varName": "nomineeOtherPhotoID",
        "type": "text",
        "label": "Other Photo ID",

    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Permanent/Present Address",

    },
    {
        "varName": "nomineePostcode",
        "type": "text",
        "label": "Postcode",

    },
    {
        "varName": "nomineePhoneNumber",
        "type": "text",
        "label": "Phone Number",

    },
    {
        "varName": "gaurdianInformation",
        "type": "title",
        "label": "Gaurdian Information",
    },
    {
        "varName": "gaurdianName",
        "type": "text",
        "label": "Gaurdian Name",

    },
    {
        "varName": "Fathers/HusbandName",
        "type": "text",
        "label": "Fathers/Husband Name",

    },
    {
        "varName": "gaurdianDob",
        "type": "date",
        "label": "DOB",

    },
    {
        "varName": "relationshipWithNominee",
        "type": "text",
        "label": "Relationship With Nominee",

    },
    {
        "varName": "legalGaurdianPhotoInformation",
        "type": "text",
        "label": "Legal Gaurdian Photo Information",

    },
    {
        "varName": "forBankUseOnly",
        "type": "title",
        "label": "For Bank Use Only",
    },
    {
        "varName": "RelationshipNoCustomerID",
        "type": "text",
        "label": "Relationship No/ Customer ID",

    },
    {
        "varName": "forBankUseOnlySectorCode",
        "type": "text",
        "label": "Sector Code",

    },
    {
        "varName": "depositTypeCode",
        "type": "text",
        "label": "Deposit Type Code",

    },
    {
        "varName": "accountOpeningChecklist",
        "type": "text",
        "label": "Account Opening Checklist",

    }, {
        "varName": "identityVerifiedBy",
        "type": "text",
        "label": "Identity Verified By ",

    }, {
        "varName": "addressVerifiedBy",
        "type": "text",
        "label": "Address Verified By",

    },
    {
        "varName": "resultofSanctionBlackListScreening",
        "type": "text",
        "label": "Result of Sanction/Black List Screening",

    },
    {
        // tick
        "varName": "howWasTheACOpened",
        "type": "text",
        "label": "How was the A/C opened",

    },
    {
        "varName": "nameOfDMEBrEmployee",
        "type": "text",
        "label": "Name of DME/Br. Employee",

    },
    {
        // tick
        "varName": "taxApplicable",
        "type": "text",
        "label": "Tax Applicable",

    },
    {
        "varName": "forBranch",
        "type": "title",
        "label": "For Branch",
    },
    {
        "varName": "savings/CurrentProductCode",
        "type": "text",
        "label": "Savings/Current Product Code",

    },
    {
        "varName": "valueDate",
        "type": "date",
        "label": "Value Date",

    },
    {
        "varName": "forBranchOthers",
        "type": "text",
        "label": "Others",

    },
    {
        "varName": "fDProductCode",
        "type": "text",
        "label": "FD Product Code",

    },
    {
        "varName": "branchSol",
        "type": "text",
        "label": "Branch Sol",

    },
    {
        "varName": "workflowInput",
        "type": "title",
        "label": "Workflow Input",
    },
    {
        "varName": "debitCard",
        "type": "text",
        "label": "Debit Card",

    },
    {
        "varName": "phone1",
        "type": "text",
        "label": "Phone 1",

    },
    {
        "varName": "phone2",
        "type": "text",
        "label": "Phone 2",

    },
    {
        "varName": "smsAlertRequest",
        "type": "select",
        "label": "Sms Alert Request",
        "enum": [
            "YES",
            "NO"
        ]

    }, {
        "varName": "companyCode",
        "type": "text",
        "label": "Company Code",

    },
    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",

    },
    {
        "varName": "kyc",
        "type": "title",
        "label": "KYC",

    },
    {
        "varName": "kycSourceOfFund",
        "type": "text",
        "label": "Source of Fund",

    }, {
        "varName": "dOCCollect",
        "type": "text",
        "label": "DOC Collect",

    }, {
        "varName": "collectedDOCHaveBeenVerified ",
        "type": "text",
        "label": "Collected DOC have been Verified",

    },
    {
        "varName": "howTheAddressIsVerified",
        "type": "text",
        "label": "How the Address is verified",

    },
    {
        "varName": "hasBeneficialOwnerBeenIdentified",
        "type": "text",
        "label": "Has the Beneficial Owner of the a/c been identified",

    },
    {
        "varName": "identification",
        "type": "title",
        "label": "Identification",

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport No",

    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",

    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",

    },
    {
        "varName": "identificationEtin",
        "type": "text",
        "label": "E-TIN",

    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License No",

    },
    {
        "varName": "identificationOtherDocumentation",
        "type": "text",
        "label": "Other Documentation",

    },
    {

        "varName": "reasonForA/COpeningOfForeignCompany ",
        "type": "text",
        "label": "Reason for A/C opening of Foreign Company",

    },
    {
        "varName": "typeOfVISA",
        "type": "text",
        "label": "Type Of VISA",

    },
    {
        "varName": "identificationExpDate",
        "type": "date",
        "label": "Exp Date",

    },
    {
        "varName": "workPermitAndPermission",
        "type": "text",
        "label": "Work Permit and Permission",

    },

    {
        "varName": "PEP/IP",
        "type": "text",
        "label": "PEP/IP ?",

    },
    {
        "varName": "iFYESThen",
        "type": "text",
        "label": "IF YES Then",

    },

    {
        "varName": "anyMatchOfTerroristActivity",
        "type": "text",
        "label": "Any Match Of Terrorist Activity",

    },
    {
        "varName": "riskRating",
        "type": "title",
        "label": "Risk Rating",
    },
    {
        // tick
        "varName": "businessCustomerEngaged",
        "type": "text",
        "label": "What does the Customer do/in What Type of Business Is The Customer Engaged",

    },
    {
        // tick
        "varName": "customerMonthlyIncome",
        "type": "text",
        "label": "Customer's Monthly Income",

    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "text",
        "label": "Expected Amount of Monthly Total Transaction",

    },
    {
        "varName": "expectedAmountOfMonthlyCashTransaction",
        "type": "text",
        "label": "Expected Amount of Monthly Cash Transaction",

    },
    {
        "varName": "totalRiskScore",
        "type": "text",
        "label": "Total Risk Score",

    },
    {
        "varName": "riskRatingComments",
        "type": "text",
        "label": "Comments",

    },
    {
        "varName": "tp",
        "type": "title",
        "label": "TP",
    },
    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",

    },
    {
        "varName": "deposit",
        "type": "title",
        "label": "Deposit",
    },
    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",

    }, {
        "varName": "depositByTransfer",
        "type": "text",
        "label": "Deposit by Transfer",

    },
    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",

    },
    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income from Export",

    },
    {
        "varName": "Deposit/TransferFromBOA/C",
        "type": "text",
        "label": "Deposit/Transfer from BO A/C",

    },
    {
        "varName": "depositOthers",
        "type": "text",
        "label": "Others",

    },
    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",

    },
    {
        "varName": "withdraw",
        "type": "title",
        "label": "Withdraw",
    },
    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",

    },
    {
        "varName": "withdrawalThroughTransfer/Instrument",
        "type": "text",
        "label": "Withdrawal Through Transfer/Instrument",

    },
    {

        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",

    },
    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",

    },
    {
        "varName": "deposit/TransferToBO",
        "type": "text",
        "label": "Deposit/Transfer to BO A/C",

    },
    {

        "varName": "withdrawOthers",
        "type": "text",
        "label": "Others",

    },
    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",

    },
    {
        "varName": "discripancy",
        "type": "Select",
        "label": "Discripancy",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "deferal",
        "type": "Select",
        "label": "Deferal",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "discripancy",
        "type": "select",
        "label": "Discripancy ?",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferal Type",
        "enum": [
            "A",
            "B"
        ]
    },
    {
        "varName": "expireDate",
        "type": "date",
        "label": "Expire Date Type",
    },
    {
        "varName": "maker_update_all_info_send_to",
        "type": "select",
        "label": "Approved ?",
        "enum": [
            "APPROVED",
            "RETURN"
        ]
    },

];
let SDCommonNewJsonFormIndividualAccountOpeningFinacle = [


    {
        // seal
        "varName": "relationshipBranchName",
        "type": "text",
        "label": "Relationship Branch Name",
        "grid": 6
    },
    {
        "varName": "aOFDate",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "aOFBranchName",
        "type": "text",
        "label": "Branch Name",
        "grid": 6
    },

    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",
        "grid": 6
    },
    {
        "varName": "title",
        "type": "text",
        "label": "Title ",
        "grid": 6
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Short Name",
        "grid": 6
    },
    {
        // tick
        "varName": "accountType",
        "type": "select",
        "label": "Type Of Account",
        "grid": 6,
        "enum": [
            "General Savings A/C",
            "Current A/C",
            "SND A/C",
            "Alo General Savings A/C",
            "Alo Savings Delight",
            "Alo High Value Savings",
            "Seniors' Savings A/C",
            "Savings Delight A/C",
            "Fixed Deposit A/C",
            "RFCD A/C",
            "Foreign Currency A/C",
            "Students Savings A/C School Plan",
            "Students Savings A/C College Plan",
            "Others"
        ],
    },
    {

        "varName": "purposeOfOpening",
        "type": "text",
        "label": "Purpose Of Opening",
        "grid": 6
    },
    {
        // tick
        "varName": "natureOfAccount",
        "type": "select",
        "label": "Nature Of Account",
        "grid": 6,
        "enum": [
            "Individual",
            "Joint"
        ]
    },
    {
        // tick
        "varName": "currency",
        "type": "select",
        "label": "Currency",
        "grid": 6,
        "enum": [
            "BDT",
            "USD",
            "Euro",
            "GBP",
            "Others"
        ]
    },
    {
        // Numeric & Check Box
        "varName": "initialDeposit",
        "type": "text",
        "label": "Initial Deposit",
        "grid": 6
    },
    {
        "varName": "depositType",
        "type": "select",
        "label": "Deposit Type",
        "grid": 6,
        "enum": [
            "Cash",
            "Cheque",
            "Transfer"
        ]
    },
    {
        "varName": "commAddressSelection",
        "type": "select",
        "label": "Comm. Address Selection",
        "grid": 6,
        "enum": [
            "Present Address(Residence)",
            "Office/Business Address",
            "Permanent Address"
        ]
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Desig. Email ID",
        "grid": 6
    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Desig. Mobile Number",
        "grid": 6
    },
    {

        "varName": "adcRequest",
        "type": "title",
        "label": "ADC Request",
        "grid": 12
    },
    {
        // tick
        "varName": "requiredService",
        "type": "select",
        "label": "Required Service(s)",
        "grid": 6,
        "enum": [
            "City Touch",
            "Call Center",
            "SMS Alert",
            "Others"
        ],
    },
    {
        "varName": "emailForCityTouchService",
        "type": "text",
        "label": "Email for City Touch Service",
        "grid": 6
    },
    {
        "varName": "preferredCityTouchUserID",
        "type": "text",
        "label": "Preferred City Touch User ID",
        "grid": 6
    },
    {
        "varName": "desiredCreditCardNumberToBeTaggedWithCitytouch",
        "type": "title",
        "label": "Desired Credit Card Number to be tagged with CityTouch Id",
        "grid": 12
    },
    {
        "varName": "creditCardNumber",
        "type": "text",
        "label": "Credit Card Number(s)",
        "grid": 6
    },
    {
        "varName": "clientID",
        "type": "text",
        "label": "Client ID",
        "grid": 6
    },
    {
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 6,
        "enum": [
            "AMEX",
            "VISA",
            "Others"
        ]
    },
    {
        "varName": "facilitiesDetails",
        "type": "title",
        "label": "Facilities Details",
        "grid": 12
    },
    {
        // tick
        "varName": "chequeBook",
        "type": "select",
        "label": "Cheque Book",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    }, {
        // tick
        "varName": "debitCrad",
        "type": "select",
        "label": "Debit Crad",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    }, {
        // tick
        "varName": "lockerFacility",
        "type": "select",
        "label": "Locker Facility",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "statementFacilities",
        "type": "select",
        "label": "Statement Facilities",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    /////////end of 1 page \\\\\\\\\\\\\\\
    {
        "varName": "cardDetails",
        "type": "title",
        "label": "Card Details",
        "grid": 12
    },
    {
        // tick
        "varName": "cardApplicant",
        "type": "select",
        "label": "Card Applicant",
        "grid": 6,
        "enum": [
            "1st Applicant",
            "2nd Applicant",
            "Others"
        ]
    },
    {
        // tick
        "varName": "cardType",
        "type": "select",
        "label": "Card Type",
        "grid": 6,
        "enum": [
            "MC Debit Card",
            "VISA Debit Card",
            "City Max Card",
            "Others"
        ]
    },
    {
        // tick
        "varName": "customerCategory",
        "type": "select",
        "label": "Customer Category",
        "grid": 6,
        "enum": [
            "Individual",
            "Staff",
            "Corporate-Name of the Organization"
        ]
    },
    {
        "varName": "nameOfCard",
        "type": "text",
        "label": "Name of Card",
        "grid": 6
    },
    {
        "varName": "operatingOn",
        "type": "text",
        "label": "Operating On",
        "grid": 6
    },
    {
        "varName": "cardReceiveThrough",
        "type": "text",
        "label": "Card Receive Through",
        "grid": 6
    },
    {
        "varName": "introducerInformation",
        "type": "title",
        "label": "Introducer Information",
        "grid": 12
    },
    {
        "varName": "introName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6
    },
    {
        "varName": "introAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6
    },
    {
        // Alpha Numeric
        "varName": "introducerCB",
        "type": "text",
        "label": "Customer ID",
        "grid": 6
    },
    {
        "varName": "relationshipWithApplicant",
        "type": "text",
        "label": "Relationship with Applicant",
        "grid": 6
    },
    {
        "varName": "nomineeInformation",
        "type": "title",
        "label": "Nominee Information",
        "grid": 12
    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6
    },
    {
        "varName": "nomineeFathersName",
        "type": "text",
        "label": "Fathers Name",
        "grid": 6
    }, {
        "varName": "nomineeMothersName",
        "type": "text",
        "label": "Mothers Name",
        "grid": 6
    },
    {
        "varName": "nomineeSopuseName",
        "type": "text",
        "label": "Sopuse Name",
        "grid": 6
    },
    {
        "varName": "nomineeOccupation",
        "type": "text",
        "label": "Occupation",
        "grid": 6
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "grid": 6
    }, {
        "varName": "nomineeBirthCertificate",
        "type": "text",
        "label": "Birth Certificate",
        "grid": 6
    }, {
        "varName": "nomineeNid",
        "type": "text",
        "label": "NID",
        "grid": 6
    },
    {
        "varName": "nomineePassport",
        "type": "text",
        "label": "Passport",
        "grid": 6
    },
    {
        "varName": "nomineePassportExpDate",
        "type": "text",
        "label": "Passport Exp Date",
        "grid": 6
    },
    {
        "varName": "nomineeDrivingLicense",
        "type": "text",
        "label": "Driving License",
        "grid": 6
    },
    {
        "varName": "DLExpDate",
        "type": "text",
        "label": "DL. Exp Date",
        "grid": 6
    },
    {
        "varName": "nomineeOtherPhotoID",
        "type": "text",
        "label": "Other Photo ID",
        "grid": 6
    },
    {
        "varName": "nomineePresentAddress",
        "type": "text",
        "label": "Permanent/Present Address",
        "grid": 6
    },
    {
        "varName": "nomineePostcode",
        "type": "text",
        "label": "Postcode",
        "grid": 6
    },
    {
        "varName": "nomineePhoneNumber",
        "type": "text",
        "label": "Phone Number",
        "grid": 6
    },
    {
        "varName": "gaurdianInformation",
        "type": "title",
        "label": "Gaurdian Information",
        "grid": 12
    },
    {
        "varName": "gaurdianName",
        "type": "text",
        "label": "Gaurdian Name",
        "grid": 6
    },
    {
        "varName": "Fathers/HusbandName",
        "type": "text",
        "label": "Fathers/Husband Name",
        "grid": 6
    },
    {
        "varName": "gaurdianDob",
        "type": "date",
        "label": "DOB",
        "grid": 6
    },
    {
        "varName": "relationshipWithNominee",
        "type": "text",
        "label": "Relationship With Nominee",
        "grid": 6
    },
    {
        "varName": "legalGaurdianPhotoInformation",
        "type": "text",
        "label": "Legal Gaurdian Photo Information",
        "grid": 6
    },
    //////////  end 2 page //////////
    {
        "varName": "forBankUseOnly",
        "type": "title",
        "label": "For Bank Use Only",
        "grid": 12
    },
    {
        "varName": "RelationshipNoCustomerID",
        "type": "text",
        "label": "Relationship No/ Customer ID",
        "grid": 6
    },
    {
        "varName": "forBankUseOnlySectorCode",
        "type": "text",
        "label": "Sector Code",
        "grid": 6
    },
    {
        "varName": "depositTypeCode",
        "type": "text",
        "label": "Deposit Type Code",
        "grid": 6
    },
    {
        "varName": "accountOpeningChecklist",
        "type": "text",
        "label": "Account Opening Checklist",
        "grid": 6
    }, {
        "varName": "identityVerifiedBy",
        "type": "select",
        "label": "Identity Verified By ",
        "grid": 6,
        "enum": [
            "Passport",
            "Birth Certificate",
            "National ID Card",
            "Other"
        ],
    }, {
        "varName": "addressVerifiedBy",
        "type": "text",
        "label": "Address Verified By",
        "grid": 6
    },
    {
        "varName": "resultofSanctionBlackListScreening",
        "type": "title",
        "label": "Result of Sanction/Black List Screening",
        "grid": 12
    },
    {
        "varName": "uNSecurityCouncil",
        "type": "select",
        "label": "UN Security Council Sanction list",
        "grid": 6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        "varName": "blackListCreditDepartment",
        "type": "select",
        "label": "Black List provided by the Credit Department",
        "grid": 6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        "varName": "restrictionByLocalLaw",
        "type": "select",
        "label": "Restriction by Local Law/Bangladesh Bank to Open an A/C",
        "grid": 6,
        "enum": [
            "Match Found",
            "No Match Found"]
    },
    {
        // tick
        "varName": "howWasTheACOpened",
        "type": "select",
        "label": "How was the A/C opened",
        "grid": 6,
        "enum": [
            "By RM/Branch Employee",
            "By DME",
            "Internet",
            "Walk-in/Unsolicited"
        ],
    },
    {
        "varName": "nameOfDMEBrEmployee",
        "type": "text",
        "label": "Name of DME/Br. Employee",
        "grid": 6
    },
    {
        // tick
        "varName": "taxApplicable",
        "type": "select",
        "label": "Tax Applicable",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    ///  for branch  ////////////
    {
        "varName": "forBranch",
        "type": "title",
        "label": "For Branch",
        "grid": 12
    },
    {
        "varName": "savings/CurrentProductCode",
        "type": "checkbox",
        "label": "Savings/Current Product Code",
        "grid": 6
    },
    {
        "varName": "fDProductCode",
        "type": "checkbox",
        "label": "FD Product Code",
        "grid": 6
    },
    {
        "varName": "valueDate",
        "type": "checkbox",
        "label": "Value Date",
        "grid": 4
    },
    {
        "varName": "branchSol",
        "type": "checkbox",
        "label": "Branch Sol",
        "grid": 4
    },
    {
        "varName": "forBranchOthers",
        "type": "checkbox",
        "label": "Others",
        "grid": 4
    },


    /// end 4 page  ///
    {
        "varName": "tp",
        "type": "title",
        "label": "TP",
        "grid": 12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid": 6
    },
    {
        "varName": "AcTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6
    },
    {
        "varName": "sourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6
    },
    {
        "varName": "monthlyProbableTournover",
        "type": "text",
        "label": "Monthly Probable Tournover",
        "grid": 6
    },
    {
        "varName": "monthlyProbableIncome",
        "type": "text",
        "label": "Monthly Probable Income",
        "grid": 6
    },
    {
        "varName": "deposit",
        "type": "title",
        "label": "Deposit",
        "grid": 12
    },
    {
        "varName": "cashDeposit",
        "type": "text",
        "label": "Cash Deposit",
        "grid": 6
    }, {
        "varName": "depositByTransfer",
        "type": "text",
        "label": "Deposit by Transfer",
        "grid": 6
    },
    {
        "varName": "foreignInwardRemittance",
        "type": "text",
        "label": "Foreign Inward Remittance",
        "grid": 6
    },
    {
        "varName": "depositIncomeFromExport",
        "type": "text",
        "label": "Deposit Income from Export",
        "grid": 6
    },
    {
        "varName": "Deposit/TransferFromBOA/C",
        "type": "text",
        "label": "Deposit/Transfer from BO A/C",
        "grid": 6
    },
    {
        "varName": "depositOthers",
        "type": "text",
        "label": "Others",
        "grid": 6
    },
    {
        "varName": "totalProbableDeposit",
        "type": "text",
        "label": "Total Probable Deposit",
        "grid": 6
    },
    {
        "varName": "withdraw",
        "type": "title",
        "label": "Withdraw",
        "grid": 12
    },
    {
        "varName": "cashWithdrawal",
        "type": "text",
        "label": "Cash Withdrawal",
        "grid": 6
    },
    {
        "varName": "withdrawalThroughTransfer/Instrument",
        "type": "text",
        "label": "Withdrawal Through Transfer/Instrument",
        "grid": 6
    },
    {

        "varName": "foreignOutwardRemittance",
        "type": "text",
        "label": "Foreign Outward Remittance",
        "grid": 6
    },
    {
        "varName": "paymentAgainstImport",
        "type": "text",
        "label": "Payment Against Import",
        "grid": 6
    },
    {
        "varName": "deposit/TransferToBO",
        "type": "text",
        "label": "Deposit/Transfer to BO A/C",
        "grid": 6
    },
    {

        "varName": "withdrawOthers",
        "type": "text",
        "label": "Others",
        "grid": 6
    },
    {
        "varName": "totalProbableWithdrawal",
        "type": "text",
        "label": "Total Probable  Withdrawal",
        "grid": 6
    },
    ////end page//////////////
    {
        "varName": "title",
        "type": "title",
        "label": "Individual Information Form",
        "grid": 12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid": 6
    },
    {
        "varName": "relationWithTheAC",
        "type": "select",
        "label": "Relation with the A/C",
        "grid": 6,
        "enum": [
            "1st Applicant",
            "2nd Applicant",
            "3rd Applicant",
            "director",
            "A/C Operator",
            "Partner",
            "Beneficial Owner",
            "Minor",
            "Guardian",
            "Trustee",
            "Attorney Holder",
            "Proprietor",
            "Others"
        ],
    },
    {
        "varName": "accountName",
        "type": "text",
        "label": "Account Name",
        "grid": 6
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer's Name",
        "grid": 6
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Relation",
        "grid": 12
    },
    {
        "varName": "fatherName",
        "type": "text",
        "label": "Father's Name",
        "grid": 6
    },
    {
        "varName": "motherName",
        "type": "text",
        "label": "Mother's Name",
        "grid": 6
    },
    {
        "varName": "spouseName",
        "type": "text",
        "label": "Spouse' Name",
        "grid": 6
    }, {
        "varName": "dob",
        "type": "date",
        "label": "DOB",
        "grid": 6
    },
    {
        "varName": "religion",
        "type": "text",
        "label": "Religion",
        "grid": 6
    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender",
        "grid": 6,
        "enum": [
            "Male",
            "Female",
            "Third Gender",
            "Address proof"
        ],
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 6
    },
    {
        "varName": "birthCertificate",
        "type": "text",
        "label": "Birth certificate",
        "grid": 6
    },
    {
        "varName": "residentStatus",
        "type": "select",
        "label": "Resident status",
        "grid": 6,
        "enum": [
            "Resident",
            "Non-Resident"
        ],
    },
    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6
    },
    {
        "varName": "passportIssueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6
    },
    {
        "varName": "passportExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid": 6
    },
    {
        "varName": "passportPlaceOfIssue",
        "type": "text",
        "label": "Place of issue",
        "grid": 6
    },
    {
        "varName": "drivingLicenseNo",
        "type": "text",
        "label": "Driving License no",
        "grid": 6
    },
    {
        "varName": "drivingLicenseExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid": 6
    },
    {
        "varName": "otherPhotoId",
        "type": "text",
        "label": "Other photo id",
        "grid": 6
    },
    {
        "varName": "etin",
        "type": "text",
        "label": "E-TIN",
        "grid": 6
    },
    {
        "varName": "nationality",
        "type": "select",
        "label": "Nationality",
        "grid": 6,
        "enum": [
            "Bangladeshi",
            "Others"
        ],
    },
    {
        "varName": "placeOfBirth",
        "type": "text",
        "label": "Place of Birth",
        "grid": 6
    },
    {
        "varName": "countryOfBirth",
        "type": "text",
        "label": "Country of Birth",
        "grid": 6
    },
    {
        "varName": "profession",
        "type": "text",
        "label": "Profession",
        "grid": 6
    },
    {
        "varName": "nameOfTheOrganization",
        "type": "text",
        "label": "Name of the Organization",
        "grid": 6
    },
    {
        "varName": "designation",
        "type": "text",
        "label": "Designation",
        "grid": 6
    },
    {
        "varName": "natureOfBusiness",
        "type": "text",
        "label": "Nature of Business",
        "grid": 6
    },
    ////end page//////
    {
        "varName": "presentAddress",
        "type": "text",
        "label": "Present Address",
        "grid": 6
    },
    {
        "varName": "presentAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {
        "varName": "presentAddressCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {

        "varName": "presentAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid": 6
    },
    {
        "varName": "professionalAddress",
        "type": "text",
        "label": "Professional Address",
        "grid": 6
    },
    {

        "varName": "professionalAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {
        "varName": "professionalAddressCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {
        "varName": "professionalAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid": 6
    },
    {
        "varName": "permanentAddress",
        "type": "text",
        "label": "Permanent Address",
        "grid": 6
    },
    {
        "varName": "permanentAddressPostCode",
        "type": "text",
        "label": "Post Code",
        "grid": 6
    },
    {
        "varName": "permanentAddressCountry",
        "type": "text",
        "label": "Country",
        "grid": 6
    },
    {
        "varName": "permanentAddressLandmark",
        "type": "text",
        "label": "Landmark",
        "grid": 6
    },
    {
        "varName": "phoneNumberResidence",
        "type": "text",
        "label": "Phone Number(Residence)",
        "grid": 6
    },
    {
        "varName": "phoneNumberOffice",
        "type": "text",
        "label": "Phone Number(Office)",
        "grid": 6
    },
    {
        "varName": "mobile",
        "type": "text",
        "label": "Mobile",
        "grid": 6
    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email ID",
        "grid": 6
    },
    {
        "varName": "emergencyContact",
        "type": "title",
        "label": "Emergency Contact",
        "grid": 12
    },
    {
        "varName": "emergencyContactName",
        "type": "text",
        "label": "Name",
        "grid": 6
    },
    {
        "varName": "emergencyContactAddress",
        "type": "text",
        "label": "Address",
        "grid": 6
    },
    {
        "varName": "emergencyContactEmailId",
        "type": "text",
        "label": "Email ID",
        "grid": 6
    },
    {
        "varName": "emergencyContactMobile",
        "type": "text",
        "label": "Mobile",
        "grid": 6
    },
    {
        "varName": "emergencyContactRelationshipWithAcHolder",
        "type": "text",
        "label": "Relationship With A/C Holder",
        "grid": 6
    },
    {
        "varName": "creditCardInformation",
        "type": "title",
        "label": "Credit Card Information of Account Holder",
        "grid": 12
    },
    {
        "varName": "issuedByLocal",
        "type": "text",
        "label": "Issued By (Local)",
        "grid": 6
    },
    {
        "varName": "issuedByInternational",
        "type": "text",
        "label": "Issued By(International)",
        "grid": 6
    },
    {
        "varName": "fatcaInformation",
        "type": "text",
        "label": "FATCA Information",
        "grid": 6
    },
    ////end page //////////////
    {
        "varName": "kyc",
        "type": "title",
        "label": "KYC",
        "grid": 12
    },
    {
        "varName": "date",
        "type": "date",
        "label": "Date",
        "grid": 6
    },
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Unique Customer ID",
        "grid": 6
    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "A/C No.",
        "grid": 6
    },
    {
        "varName": "AcTitle",
        "type": "text",
        "label": "A/C Title",
        "grid": 6
    },
    {
        "varName": "typeOfAc",
        "type": "select",
        "label": "Type of A/C",
        "grid": 6,
        "enum": [
            "Savings A/C",
            "Current A/C",
            "SND A/C",
            "NFCD A/C",
            "RFCD A/C",
            "Foreign Currency A/c",
            "other"
        ],
    },
    {
        "varName": "customerOccupation",
        "type": "text",
        "label": "Customer occupation",
        "grid": 6
    },
    {
        "varName": "customerProbableMonthlyIncome ",
        "type": "text",
        "label": "Customer Probable Monthly Income",
        "grid": 6
    },
    {
        "varName": "kycSourceOfFund",
        "type": "text",
        "label": "Source of Fund",
        "grid": 6
    },
    {
        "varName": "dOCCollect",
        "type": "text",
        "label": "DOC collect to Ensure Source of Fund",
        "grid": 6
    },
    {
        "varName": "collectedDOCHaveBeenVerified ",
        "type": "select",
        "label": "Collected DOC have been Verified",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "hasBeneficialOwnerBeenIdentified",
        "type": "select",
        "label": "Has the Beneficial Owner of the a/c been identified",
        "grid": 6,
        "enum": [
            "YES",
            "NO",
            "Not Applicable"
        ]
    },
    {
        "varName": "howTheAddressIsVerified",
        "type": "text",
        "label": "How the Address is verified",
        "grid": 6
    },

    {
        "varName": "identification",
        "type": "title",
        "label": "Identification",
        "grid": 12
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport No",
        "grid": 12
    },
    {
        "varName": "passportReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "passportVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {
        "varName": "nid",
        "type": "text",
        "label": "NID No",
        "grid": 12
    },
    {
        "varName": "nidReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "nidVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {
        "varName": "birthCertificateNo",
        "type": "text",
        "label": "Birth Certificate No",
        "grid": 12
    },
    {
        "varName": "birthCertificateReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "birthCertificateVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {
        "varName": "etin",
        "type": "text",
        "label": "E-TIN",
        "grid": 12
    },
    {
        "varName": "etinReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "etinVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {
        "varName": "drivingLicense",
        "type": "text",
        "label": "Driving License No",
        "grid": 12
    },
    {
        "varName": "drivingLicenseReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "drivingLicenseVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {
        "varName": "otherDocumentation",
        "type": "text",
        "label": "Other Documentation",
        "grid": 12
    },
    {
        "varName": "otherDocumentationReceivedCopy",
        "type": "checkbox",
        "label": "Received Copy",
        "grid": 6
    },
    {
        "varName": "otherDocumentationVerified",
        "type": "checkbox",
        "label": "Verified",
        "grid": 6
    },
    {

        "varName": "reasonForA/COpeningOfForeignCompany ",
        "type": "text",
        "label": "Reason for A/C opening of Foreign Company",
        "grid": 6
    },
    {
        "varName": "typeOfVISA",
        "type": "text",
        "label": "Type Of VISA",
        "grid": 6
    },
    {
        "varName": "identificationExpDate",
        "type": "date",
        "label": "Exp Date",
        "grid": 6
    },
    {
        "varName": "workPermitAndPermission",
        "type": "select",
        "label": "Work Permit and Permission",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "PEP/IP",
        "type": "text",
        "label": "PEP/IP ?",
        "grid": 6
    },
    {
        "varName": "iFYESThen",
        "type": "text",
        "label": "IF YES Then",
        "grid": 6
    },

    {
        "varName": "anyMatchOfTerroristActivity",
        "type": "text",
        "label": "Any Match Of Terrorist Activity",
        "grid": 6,
        "enum": [
            "YES",
            "NO"
        ]
    },
    ///// end page //////////////
    {
        "varName": "riskRating",
        "type": "title",
        "label": "Risk Rating",
        "grid": 12,

    },
    {
        // tick
        "varName": "businessCustomerEngaged",
        "type": "select",
        "label": "What does the Customer do/in What Type of Business Is The Customer Engaged",
        "grid": 6,
        "enum": [
            "Jewelry/ Gold Business/Valuable Metal Business",
            "Money Changer/ Courier Service/Mobile Banking Agent",
            "Real Estate Developer/Agent",
            "Promoter of Construction Project/Constructor",
            "Art/Antic Dealer",
            "Restaurant/Bar/Night club/Residential Hotel/Parlor Business",
            "Import/Export",
            "Manpower Export Business",
            "Arms Business",
            "Garments Business/Garments Accessories/Buying House",
            "Pilot/Flight Attended",
            "Trusty",
            "Share/Stock Business Investor",
            "Software Business/information & Technology Business",
            "Expatriate(Foreign Working in Bangladesh)",
            "Travel Agent",
            "Investor with Annual More than 1 Crore Investment",
            "Freight/Shipping/Cargo Agent",
            "Auto Business (New/Reconditioned Car) Business",
            "Business(Leather and Leather Related Goods)",
            "House Construction Materials Business",
            "Professional (journalist,Lawyer,Doctor,Engineer,Chartered Accountant)",
            "Director(Private/Public Limited Company)",
            "High level Official of Multinational Organization",
            "Housewives",
            "Employment in Information & Technology",
            "Player/Media Celebrity/Producer/Director",
            "Freelance Software Developer",
            "Business Agent",
            "Government Job",
            "Landlord",
            "Thread Business/Jute Business",
            "Transport Operator",
            "Tobacco & Cigarette Business",
            "Amusement Organization/Park",
            "Motor Parts/Workshop Business",
            "Private Service Managerial",
            "Teacher(Government/Private/Autonomous Educational Institution)",
            "Service(Private)",
            "Small Business(Yearly Turnover Under 50 Lakh)",
            "Self-employed Professional",
            "Computer/Mobile Phone Dealer",
            "Manufacturer(Except Firearms)",
            "Student",
            "Retired from Service",
            "Farmer/Worker/Fisher",
            "Other"

        ]
    },
    {
        // tick
        "varName": "customerMonthlyIncome",
        "type": "select",
        "label": "Customer's Monthly Income",
        "grid": 6,
        "enum": [
            "Upto 1 lakh",
            ">1 to 3 lakh",
            "more than 3 lakh"
        ]
    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "title",
        "label": "Expected Amount of Monthly Total Transactions",
        "grid": 12,

    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "select",
        "label": "Amount of Transaction in Current A/C (in Lacs)",
        "grid": 6,
        "enum": [
            "0-10",
            ">10-20",
            ">20"
        ]


    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "select",
        "label": "Amount of Transaction in Savings A/C (in Lacs)",
        "grid": 6,
        "enum": [
            "0-5",
            ">5-10",
            ">10"
        ]

    },
    {
        "varName": "expectedAmountOfMonthlyCashTransaction",
        "type": "title",
        "label": "Expected Amount of Monthly Cash Transaction",
        "grid": 12
    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "select",
        "label": "Amount of Cash Transaction in Current A/C (in Lacs)",
        "grid": 6,
        "enum": [
            "0-5",
            ">5-10",
            ">10"
        ]


    },
    {
        "varName": "expectedAmountOfMonthlyTotalTransaction",
        "type": "select",
        "label": "Amount of Cash Transaction in Saving A/C (in Lacs)",
        "grid": 6,
        "enum": [
            "0-2",
            ">2-5",
            ">5"
        ]


    },
    {
        "varName": "totalRiskScore",
        "type": "select",
        "label": "Total Risk Score",
        "grid": 6,
        "enum": [
            "HIGH",
            "LOW"
        ]
    },
    {
        "varName": "riskRatingComments",
        "type": "text",
        "label": "Comments",
        "grid": 6
    },
    {
        "varName": "discripancy",
        "type": "Select",
        "label": "Discripancy",
        "enum": [
            "YES",
            "NO"
        ]
    },

    {
        "varName": "deferal",
        "type": "Select",
        "label": "Deferal",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "discripancy",
        "type": "select",
        "label": "Discripancy ?",
        "enum": [
            "YES",
            "NO"
        ]
    },
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferal Type",
        "enum": [
            "A",
            "B"
        ]
    },
    {
        "varName": "expireDate",
        "type": "date",
        "label": "Expire Date Type",
    },
    {
        "varName": "maker_update_all_info_send_to",
        "type": "select",
        "label": "Approved ?",
        "enum": [
            "APPROVED",
            "RETURN"
        ]
    }

];


let MAKERJsonFormIndividualAccountOpening = {};
MAKERJsonFormIndividualAccountOpening["variables"] = JSON.parse(JSON.stringify(SDCommonNewJsonFormIndividualAccountOpeningFinacle));
/*MAKERJsonFormIndividualAccountOpening.variables.push(makerApprove);*/

let CHECKERNewJsonFormIndividualAccountOpeningFinacle = {};
CHECKERNewJsonFormIndividualAccountOpeningFinacle["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(SDCommonNewJsonFormIndividualAccountOpeningFinacle)));
CHECKERNewJsonFormIndividualAccountOpeningFinacle.variables.push(checkerApprove);

//######Existing Account Opening Joint#########


let CSExistJsonFormJointAccountOpening = {};
CSExistJsonFormJointAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSExistJsonFormJointAccountOpening.variables.push(csSendTo);


let CSNewJsonFormJointAccountOpening = {};
CSNewJsonFormJointAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormJointAccountOpening.variables.push(csSendTo);

//######BOM Account Opening Joint#########
let BOMNewJsonFormJointAccountOpening = {};
BOMNewJsonFormJointAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BOMNewJsonFormJointAccountOpening.variables.push(bomApproval);
BOMNewJsonFormJointAccountOpening.variables.push(bomSendTo);

//######BM Account Opening Joint#########
let BMJsonFormJointAccountOpening = {};
BMJsonFormJointAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormJointAccountOpening.variables.push(bmApprove);
BMJsonFormJointAccountOpening.variables.push(bmSendTo);


//######Existing Account Opening Proprietorship#########


let CSExistJsonFormProprietorshipAccountOpening = {};
CSExistJsonFormProprietorshipAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonExistJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSExistJsonFormProprietorshipAccountOpening.variables.push(csSendTo);


let CSNewJsonFormProprietorshipAccountOpening = {};
CSNewJsonFormProprietorshipAccountOpening["variables"] = JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm)));
CSNewJsonFormProprietorshipAccountOpening.variables.push(csSendTo);

//######BOM Account Opening Proprietorship#########
let BOMNewJsonFormProprietorshipAccountOpening = {};
BOMNewJsonFormProprietorshipAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BOMNewJsonFormProprietorshipAccountOpening.variables.push(bomApproval);
BOMNewJsonFormProprietorshipAccountOpening.variables.push(bomSendTo);

//######BM Account Opening Proprietorship#########
let BMJsonFormProprietorshipAccountOpening = {};
BMJsonFormProprietorshipAccountOpening["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonNewJsonFormIndividualAccountOpening.concat(commonJsonFormIndividualAccountOpeningForm))));
BMJsonFormProprietorshipAccountOpening.variables.push(bmApprove);
BMJsonFormProprietorshipAccountOpening.variables.push(bmSendTo);

//##DebitCard  Finacle #####
let CommonJsonFormForDebitCard = [


    {
        "varName": "name",
        "type": "text",
        "label": "Customer Name",

    },
    {
        "varName": "gender",
        "type": "select",
        "label": "Gender ?",
        "enum": [
            "MALE",
            "FEMALE",

        ]

    },
    {
        "varName": "document",
        "type": "text",
        "label": "Document",

    },
    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of birth",

    },
    {
        "varName": "accountNo",
        "type": "text",
        "label": "Account No",

    },

    {
        "varName": "accountType",
        "type": "select",
        "label": "Account Type ?",
        "enum": [
            "SAVINGS",
            "CURRENT",
            "RFCD",
            "OTHER"

        ]

    },
    {
        "varName": "Transliteration",
        "type": "text",
        "label": "Transliteration",

    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",

    },
    {
        "varName": "email",
        "type": "text",
        "label": "E-mail",
        "email": true
    },
    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile phone"
    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality"
    },
    {
        "varName": "dse/rm",
        "type": "text",
        "label": "DSE/RM Code"
    },
    {
        "varName": "applicationSource",
        "type": "text",
        "label": "Application Source"
    },
    {
        "varName": "branchNameReceiveCard",
        "type": "text",
        "label": "Branch Name to Receive Card"
    },
    {
        "varName": "corporatename",
        "type": "text",
        "label": "Corporate Name"
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport Number"
    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport name"
    },
    {
        "varName": "passportExpiredate",
        "type": "date",
        "label": "Passport Expiry Date"
    },
    {
        "varName": "passportIssuedate",
        "type": "date",
        "label": "Passport Issue Date"
    },
    {
        "varName": "limitProfile",
        "type": "date",
        "label": "Limit profile"
    },
    {
        "varName": "cardType",
        "type": "select",
        "label": "Card type ",
        "enum": [
            "MASTER",
            "VISA",
            "MAXX"
        ]
    },
    {
        "varName": "productType",
        "type": "select",
        "label": "Product type",
        "enum": [
            "GENERAL",
            "STAFF",
            "CORPORATE",
            "OTHER"
        ]
    },
    {
        "varName": "initialBranchSolId",
        "type": "text",
        "label": "Initiating Branch SOL ID"
    },
    {
        "varName": "casaSolId",
        "type": "text",
        "label": "CASA SOL ID "
    }


];
//## CS DebitCard ####
let CSJsonFormForDebitCard = {};
CSJsonFormForDebitCard["variables"] = JSON.parse(JSON.stringify(CommonJsonFormForDebitCard));
CSJsonFormForDebitCard.variables.push(csSendTo);

//## BOM DebitCard ####
let BOMJsonFormForDebitCard = {};
BOMJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
BOMJsonFormForDebitCard.variables.push(bomApproval);
BOMJsonFormForDebitCard.variables.push(bomSendTo);

//## BM DebitCard ####
let BMJsonFormForDebitCard = {};
BMJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
BMJsonFormForDebitCard.variables.push(bmApprove);
BMJsonFormForDebitCard.variables.push(bmSendTo);

//## Maker DebitCard ####
let MAKERJsonFormForDebitCard = {};
MAKERJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));

//## Checker DebitCard ####
let CHECKERJsonFormForDebitCard = {};
CHECKERJsonFormForDebitCard["variables"] = makeReadOnlyObject(JSON.parse(JSON.stringify(CommonJsonFormForDebitCard)));
CHECKERJsonFormForDebitCard.variables.push(checkerApprove);

//#####DDeup Search Individual Form#############
let CSjsonFormIndividualAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "min": 9,
            "max": 9

        },

        {
            "varName": "nid",
            "type": "text",
            "label": "NID",
            "nid": true

        },
        {
            "varName": "passport",
            "type": "text",
            "label": "Passport",


        },
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
            "required": true

        },
        {
            "varName": "dob",
            "type": "date",
            "label": "Date Of Birth",
            "required": true


        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",

            "email": true,


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone Number",
            "required": true


        },

        {
            "varName": "tin",
            "type": "text",
            "label": "eTin",


        },


        {
            "varName": "registrationNo",
            "type": "text",
            "label": "Birth Certificate/Driving License",


        },
        {
            "varName": "nationality",
            "type": "text",
            "label": "Nationality",
            "required": true


        },


    ],

};

//#####DDeup Search NonIndividual Form#############
let CSjsonFormNonIndividualAccountOpeningSearch = {
    "variables": [

        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,
            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone",
            "required": false

        },

        {
            "varName": "companyEtin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,
            "readOnly": false


        },
        {
            "varName": "certificate",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};
//#####DDeup Search NonIndividual Form#############
let CSjsonFormIndividualJointAccountOpeningSearch = {
    "variables": [

        {
            "varName": "numberOfCustomer",
            "type": "text",
            "label": "Number Of Customer ?",
            "required": true,
            "number": true,
        }
    ]

};


//#####DDeup Search NonIndividual/Propritorship Form#############
let CSjsonFormNonIndividualProprietorshipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,
            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Partnership Form#############
let CSjsonFormNonIndividualPartnershipAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": true,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,
            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Limited Company Acc Form#############
let CSjsonFormNonIndividualLimitedAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,
            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};

//#####DDeup Search NonIndividual/Others  Form#############
let CSjsonFormNonIndividualOthersAccountOpeningSearch = {
    "variables": [
        {
            "varName": "cbNumber",
            "type": "text",
            "label": "CB Number",
            "required": false,
            "readOnly": false
        },

        {
            "varName": "companyName",
            "type": "text",
            "label": "Company Name",
            "required": false,
            "readOnly": false

        },

        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": false,
            "email": true,
            "readOnly": false


        },

        {
            "varName": "phone",
            "type": "text",
            "label": "Phone",
            "required": false

        },

        {
            "varName": "tin",
            "type": "text",
            "label": "Company ETin",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Trade License",
            "required": false,


        },
        {
            "varName": "tradeLicense",
            "type": "text",
            "label": "Certificate Of Incorporation",
            "required": false,


        },


    ],

};


//13-october-2019
//Sd maker Update All information Json Form #######
let MAKERUpdateAllInformation = {
    "variables": [

        {
            "varName": "discripancy",
            "type": "Select",
            "label": "Discripancy",
            "enum": [
                "YES",
                "NO"
            ]
        },

        {
            "varName": "deferal",
            "type": "Select",
            "label": "Deferal",
            "enum": [
                "YES",
                "NO"
            ]
        },

        {
            "varName": "deferalType",
            "type": "text",
            "label": "Deferal Type",
        },
        {
            "varName": "expireDate",
            "type": "date",
            "label": "Expire Date Type",
        },
        {
            "varName": "maker_update_all_information_send_tO",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "RETURN"
            ]
        },

    ]

};

//Sd maker Signiture and photo upload Json Form #######
let MAKERsigniturePhotoUpload = {
    "variables": [

        {
            "varName": "MAKERsigniturePhotoUpload",
            "type": "text",
            "label": "Signiture and Photo Upload ?",
        },

    ]

};


//Sd Checker Update All information Json Form #######
let CHECKERupdateAllInformation = {
    "variables": [
        {
            "varName": "checker_update_all_information_approval",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "NOAPPROVED"
            ]
        },

    ]

};

//Sd Checker Signiture and photo upload Json Form #######

let checkersigniturePhotoUpload = {
    "variables": [
        {
            "varName": "checker_signiture_photo_upload_approval",
            "type": "Select",
            "label": "Approved ?",
            "enum": [
                "APPROVED",
                "NOAPPROVED"
            ]
        },

    ]

};


module.exports = {
    CSJsonFormMaintenanceWithPrintFileAddress,
    CSJsonFormMaintenanceWithPrintFileContact,
    CSJsonFormMaintenanceWithPrintFileEmail,
    CSJsonFormMaintenanceWithPrintFileNominee,
    CSJsonFormMaintenanceWithPrintFileTenor,
    CSJsonFormMaintenanceWithPrintFileScheme,
    CSJsonFormMaintenanceWithPrintFileMaturity,
    CSJsonFormMaintenanceWithPrintFileTitle,
    CSJsonFormMaintenanceWithPrintFileLink,
    CSJsonFormMaintenanceWithPrintFileEtin,
    CSJsonFormMaintenanceWithPrintFileDormant,
    CSJsonFormMaintenanceWithPrintFileInputFiled,
    CSJsonFormMaintenanceWithFile,


    BOMJsonFormMaintenanceWithPrintFileAddress,
    BOMJsonFormMaintenanceWithPrintFileContact,
    BOMJsonFormMaintenanceWithPrintFileEmail,
    BOMJsonFormMaintenanceWithPrintFileNominee,
    BOMJsonFormMaintenanceWithPrintFileTenor,
    BOMJsonFormMaintenanceWithPrintFileScheme,
    BOMJsonFormMaintenanceWithPrintFileMaturity,
    BOMJsonFormMaintenanceWithPrintFileTitle,
    BOMJsonFormMaintenanceWithPrintFileLink,
    BOMJsonFormMaintenanceWithPrintFileEtin,
    BOMJsonFormMaintenanceWithPrintFileDormant,
    BOMJsonFormMaintenanceWithPrintFileInputFiled,
    BOMJsonFormMaintenanceWithFile,

    BMfromCSJsonFormMaintenanceWithPrintFileAddress,
    BMfromCSJsonFormMaintenanceWithPrintFileContact,
    BMfromCSJsonFormMaintenanceWithPrintFileEmail,
    BMfromCSJsonFormMaintenanceWithPrintFileNominee,
    BMfromCSJsonFormMaintenanceWithPrintFileTenor,
    BMfromCSJsonFormMaintenanceWithPrintFileScheme,
    BMfromCSJsonFormMaintenanceWithPrintFileMaturity,
    BMfromCSJsonFormMaintenanceWithPrintFileTitle,
    BMfromCSJsonFormMaintenanceWithPrintFileLink,
    BMfromCSJsonFormMaintenanceWithPrintFileEtin,
    BMfromCSJsonFormMaintenanceWithPrintFileDormant,
    BMfromCSJsonFormMaintenanceWithPrintFileInputFiled,
    BMfromCSJsonFormMaintenanceWithFile,

    BMfromBOMJsonFormMaintenanceWithPrintFileAddress,
    BMfromBOMJsonFormMaintenanceWithPrintFileContact,
    BMfromBOMJsonFormMaintenanceWithPrintFileEmail,
    BMfromBOMJsonFormMaintenanceWithPrintFileNominee,
    BMfromBOMJsonFormMaintenanceWithPrintFileTenor,
    BMfromBOMJsonFormMaintenanceWithPrintFileScheme,
    BMfromBOMJsonFormMaintenanceWithPrintFileMaturity,
    BMfromBOMJsonFormMaintenanceWithPrintFileTitle,
    BMfromBOMJsonFormMaintenanceWithPrintFileLink,
    BMfromBOMJsonFormMaintenanceWithPrintFileEtin,
    BMfromBOMJsonFormMaintenanceWithPrintFileDormant,
    BMfromBOMJsonFormMaintenanceWithPrintFileInputFiled,
    BMfromBOMJsonFormMaintenanceWithFile,

    MAKERJsonFormMaintenanceWithPrintFileAddress,
    MAKERJsonFormMaintenanceWithPrintFileContact,
    MAKERJsonFormMaintenanceWithPrintFileEmail,
    MAKERJsonFormMaintenanceWithPrintFileNominee,
    MAKERJsonFormMaintenanceWithPrintFileTenor,
    MAKERJsonFormMaintenanceWithPrintFileScheme,
    MAKERJsonFormMaintenanceWithPrintFileMaturity,
    MAKERJsonFormMaintenanceWithPrintFileTitle,
    MAKERJsonFormMaintenanceWithPrintFileLink,
    MAKERJsonFormMaintenanceWithPrintFileEtin,
    MAKERJsonFormMaintenanceWithPrintFileDormant,
    MAKERJsonFormMaintenanceWithPrintFileInputFiled,
    MAKERJsonFormMaintenanceWithFile,

    CHECKERJsonFormMaintenanceWithPrintFileAddress,
    CHECKERJsonFormMaintenanceWithPrintFileContact,
    CHECKERJsonFormMaintenanceWithPrintFileEmail,
    CHECKERJsonFormMaintenanceWithPrintFileNominee,
    CHECKERJsonFormMaintenanceWithPrintFileTenor,
    CHECKERJsonFormMaintenanceWithPrintFileScheme,
    CHECKERJsonFormMaintenanceWithPrintFileMaturity,
    CHECKERJsonFormMaintenanceWithPrintFileTitle,
    CHECKERJsonFormMaintenanceWithPrintFileLink,
    CHECKERJsonFormMaintenanceWithPrintFileEtin,
    CHECKERJsonFormMaintenanceWithPrintFileDormant,
    CHECKERJsonFormMaintenanceWithPrintFileInputFiled,
    CHECKERJsonFormMaintenanceWithFile,

    MAKERJsonFormIndividualAccountOpening,
    CHECKERNewJsonFormIndividualAccountOpeningFinacle,

    CSExistJsonFormIndividualAccountOpening,
    CSNewJsonFormIndividualAccountOpening,
    BOMNewJsonFormIndividualAccountOpening,
    BMJsonFormIndividualAccountOpening,


    CSExistJsonFormJointAccountOpening,
    CSNewJsonFormJointAccountOpening,
    BOMNewJsonFormJointAccountOpening,
    BMJsonFormJointAccountOpening,


    CSExistJsonFormProprietorshipAccountOpening,
    CSNewJsonFormProprietorshipAccountOpening,
    BOMNewJsonFormProprietorshipAccountOpening,
    BMJsonFormProprietorshipAccountOpening,


    CSjsonFormIndividualAccountOpeningSearch,
    CSjsonFormIndividualJointAccountOpeningSearch,
    CSjsonFormNonIndividualAccountOpeningSearch,
    CSjsonFormNonIndividualProprietorshipAccountOpeningSearch,
    CSjsonFormNonIndividualPartnershipAccountOpeningSearch,
    CSjsonFormNonIndividualLimitedAccountOpeningSearch,
    CSjsonFormNonIndividualOthersAccountOpeningSearch,


    CSJsonFormForDebitCard,
    BOMJsonFormForDebitCard,
    BMJsonFormForDebitCard,
    MAKERJsonFormForDebitCard,
    CHECKERJsonFormForDebitCard,


    MAKERUpdateAllInformation,
    MAKERsigniturePhotoUpload,

    CHECKERupdateAllInformation

};

