import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import Label from "./Label";
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import loader from "../../../../Static/loader.gif";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import CheckList from "./CheckList";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

var callRemarks = [
    {
        "varName": "callRemarks",
        "type": "textArea",
        "label": "Call Remarks",
        "required": true,
        "grid": 12,
    },
];

let bearer = [
    {
        "varName": "csBearer",
        "type": "text",
        "label": "Bearer",
        "grid": 6,
        "readOnly": true,
    },
    {
        "varName": "bearerApproval",
        "type": "text",
        "label": "Bearer Approval",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES"
    },
];

let nomineeForm = [
    {
        "varName": "NomineeUpdate",
        "type": "title",
        "label": "Nominee Update",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true

    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing No of Nominee",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
    {
        "varName": "nomineeRegNo",
        "type": "text",
        "label": "Registration No",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeChange",
        "conditionalVarValue": true
    },
];


class VerifyCallCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            csBearer: '',
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            SelectedData: '',
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            oldAccountData: [],
            loading: true,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            NonIndividualabel: "",
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            numberOfJointMember: "",
            csDeferralPage: "",
            appId: '',
            csDataCapture: '',
            getNewCase: false,
            caseId: "",
            type: [],
            dueDate: '',
            fileUploadData: {},
            AddDeferral: false,
            debitCard: "",
            getDeferralList: [],
            deferalNeeded: false,
            uploadModal: false,
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            accountDetailsModal: false,
            maintenanceType: '',
            getDocument: false,
            // customerName: 'Jamal',
            // cbNumber: 12345,
            digitTIN: false,
            titleChange: false,
            nomineeUpdate: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            getMappingAllImage: false,
            AddDeferal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            getImageLink: [],
            getImageBoolean: false,
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            jointAccountCustomerNumber: 0,
            objectForJoinAccount: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            getTaggingList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            imageCropModal: false,
            getRemarks: [], err: false,
            errorArray: {},
            errorMessages: {},
            getMappingCropImage: false,
            returnData: false,
            inputData: {},
            disabled: false,
            objectForInitialNewNominee: [],
            labelData: [],
            customerDetails: {},
            cbNotFound: false,
            checkList: false,
            linkedCB: false
        }
    }

    handleChange = (event) => {
        event.target.name = event.target.value;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>
                    <br/>


                </div>

            )
        }
    };

    copyJsonObject = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        linkedCB: response.data.freeFlag2 === "linkedCB"
                    });

                    // if (response.data.nomineeChange === true && parseInt(response.data.numberOfNominee) > 0) {
                    //
                    //     var sl;
                    //     let newSl;
                    //     let objectForNominee = [];
                    //     let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                    //     for (var i = 0; i < response.data.numberOfNominee; i++) {
                    //         newSl = i + 1;
                    //         sl = oldNominee + (i + 1);
                    //         objectForNominee.push(
                    //             {
                    //                 "varName": "Nominee " + i,
                    //                 "type": "title",
                    //                 "label": " New Nominee  " + newSl,
                    //                 "grid": 12,
                    //
                    //             },
                    //             {
                    //                 "varName": "nomineeName" + sl,
                    //                 "type": "text",
                    //                 "label": "Nominee Name",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeDob" + sl,
                    //                 "type": "date",
                    //                 "label": "D.O.B",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //             },
                    //             {
                    //                 "varName": "percentageNominee" + sl,
                    //                 "type": "text",
                    //                 "label": "Percentage %",
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //             },
                    //
                    //             {
                    //                 "varName": "nomineeMinor" + sl,
                    //                 "type": "select",
                    //                 "label": "Nominee Minor",
                    //                 "enum": ["Yes", "No"],
                    //                 "grid": 6,
                    //                 "readOnly": true,
                    //             },
                    //         )
                    //
                    //
                    //     }
                    //     this.setState({
                    //         objectForInitialNewNominee: objectForNominee,
                    //     })
                    //
                    // }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        getImageLink: response.data,
                                        getImageBoolean: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                            console.log(response.data);
                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    let varValue = this.copyJsonObject(response.data);
                    let inputData = this.copyJsonObject(response.data);
                    this.setState({
                        appData: response.data,
                        varValue: varValue,
                        inputData: inputData,
                        getData: true,
                        showValue: true,
                    }, this.stopLoadingForLinkedCB);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }

    stopLoadingForLinkedCB = () => {
        if (this.state.inputData.showLabel) {
            this.setState({
                loading: false
            })
        }
    };
    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };
    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {

            var sl;
            let objectForExistingNominee = [];
            for (var i = 0; i < this.state.inputData.nomineeNumberOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    // {
                    //     "varName": "nomineecifId" + sl,
                    //     "type": "text",
                    //     "label": "CIF ID/Individual Id",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": [],
                        "label": "Relationship",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    // {
                    //     "varName": "nomineeAddress1" + sl,
                    //     "type": "text",
                    //     "label": "Address Field 1",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "nomineeAddress2" + sl,
                    //     "type": "text",
                    //     "label": "Address Field 2",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "nomineeCityCode" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "City",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "nomineeStateCode" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "State",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "nomineePostalCode" + sl,
                    //     "type": "text",
                    //     "label": "Postal Code",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "nomineeCountry" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "Country",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    // {
                    //     "varName": "photoIdNumberRegNo" + sl,
                    //     "type": "text",
                    //     "label": "Photo Id No/Registration No.",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "text",
                        "label": "Nominee Minor",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeChange",
                        "conditionalVarValue": true
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardian's name",
                        "grid": 6,
                        "readOnly": true,
                        "multiline": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                        "conditionalArrayValue": [true, "YES"],
                    },
                    // {
                    //     "varName": "nomineeGuardianCode" + sl,
                    //     "type": "text",
                    //     "label": "Guardian Code",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "guardianPhotoId" + sl,
                    //     "type": "text",
                    //     "label": "Guardian Photo Id",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianAddress" + sl,
                    //     "type": "text",
                    //     "label": "Address 1",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianAddress2" + sl,
                    //     "type": "text",
                    //     "label": "Address Two",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianCityCode" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "City",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianStateCode" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "State",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianPostalCode" + sl,
                    //     "type": "text",
                    //     "label": "Postal Code",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "multiline": true,
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "nomineeGuardianCountry2" + sl,
                    //     "type": "autoCompleteValueReturn",
                    //     "label": "Country",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "enum": [],
                    //     "conditionalArray": true,
                    //     "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl,],
                    //     "conditionalArrayValue": [true, "Yes"],
                    // },
                    // {
                    //     "varName": "percentageNominee" + sl,
                    //     "type": "text",
                    //     "label": "Percentage %",
                    //     "grid": 6,
                    //     "readOnly": true,
                    //     "conditional": true,
                    //     "conditionalVarName": "nomineeChange",
                    //     "conditionalVarValue": true
                    // },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)
            )

        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = () => {
        this.setState({
            disabled: true
        });
        this.state.inputData.returnData = true;

        let data = {};
        data.cs_bearer = "NO";
        data.call_center_approval = "APPROVED";

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.callRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        this.state.inputData.callRemarks = undefined;


        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;


        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            if (response.status === 200) {
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    disabled: false
                                });
                                this.props.closeModal()
                            } else {
                                this.setState({
                                    disabled: false
                                });
                            }

                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                disabled: false
                            });
                        });
                }
            })
            .catch((error) => {
                this.setState({
                    disabled: false
                });
                console.log(error)
            });


    };

    renderEditForm = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, callRemarks, this.updateComponent)
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                        <br/>
                    </div>
                    <br/>
                </>
            )
        }

    };

    handleSubmitReturn = () => {
        // event.preventDefault();

        let error = MyValidation.defaultValidation(callRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.callRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
            this.state.inputData.callRemarks = undefined;

            // this.state.inputData.cs_bearer = "CALL_CENTER";
            this.state.inputData.call_center_approval = "RETURN";
            this.state.inputData.returnData = true;

            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    disabled: false
                                });
                                this.props.closeModal()
                            } else {
                                this.setState({
                                    disabled: false
                                });
                            }

                        })
                        .catch((error) => {
                            this.setState({
                                disabled: false
                            });
                            console.log(error);

                        });
                })
                .catch((error) => {
                    this.setState({
                        disabled: false
                    });
                    console.log(error)
                });
        }

    };

    renderButton = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Approve
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmitReturn}
                    >Return
                    </button>
                </Grid>

            )
        }
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData && this.state.inputData.previewImage) {
            return (
                <Grid item xs={12}>
                    <PreviewMappingImage appId={this.props.appId} classes={classes}/>
                </Grid>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.getData) {
            return (
                <Label linkedCB={this.state.linkedCB} stopLoading={this.stopLoading} classes={this.props}
                       cbNumber={this.state.inputData.cbNumber}
                       accountNumber={this.state.inputData.accountNumber} crmStatus={this.state.inputData.crmStatus}/>
            )
        }
    };


    // renderIntialNewNomineeForm = () => {
    //     if (this.state.inputData.nomineeChange === true && this.state.objectForInitialNewNominee.length > 0) {
    //
    //         return (
    //             <>
    //                 <Grid item xs={12}>
    //                     <br/>
    //                 </Grid>
    //                 {
    //                     CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForInitialNewNominee, this.updateComponent)
    //                 }
    //                 <Grid item xs={12}>
    //                     <br/>
    //                 </Grid>
    //             </>
    //         )
    //
    //     }
    // };

    renderLabelAndSignature = () => {
        if (!this.state.inputData.showLabel && this.state.getData) {
            return (
                <>
                    {
                        this.renderCustomerDetails()
                    }
                    <br/>
                    <Grid container spacing={0}>
                        {this.renderSignature()}
                    </Grid>
                    <br/>
                </>
            )
        } else {
            return this.renderSignature();
        }
    };

    renderSignature = () => {
        return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                classes={this.props}/>
    };

    renderBearerForm = () => {
        if (!this.state.loading) {
            return CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)

        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    viewCheckList = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false
        })
    };

    render() {

        const {classes} = this.props;

        {

            Functions.redirectToLogin(this.state)

        }


        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>

                        <CardBody style={{paddingLeft: '13px', paddingRight: '13px'}}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>

                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                open={this.state.checkList}>
                                <DialogContent>
                                    <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                                </DialogContent>
                            </Dialog>
                            {this.renderLabelAndSignature()}
                            <ThemeProvider theme={theme}>
                                <div>
                                    <Grid container spacing={0}>
                                        {this.renderEditForm()}
                                        {/*{*/}
                                        {/*    CommonJsonFormComponent.renderJsonForm(this.state, nomineeForm, this.updateComponent)*/}
                                        {/*}*/}
                                        {
                                            this.renderExistingNomineeForm()
                                        }
                                        {/*{*/}
                                        {/*    this.renderIntialNewNomineeForm()*/}
                                        {/*}*/}

                                        {this.renderBearerForm()}
                                        <Grid item xs={12}>
                                            {this.renderDefferalData()}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.renderRemarksData()}
                                        </Grid>
                                        {
                                            this.viewAttachedImages()
                                        }
                                        <br/>
                                        {this.viewCheckList()}
                                        {this.renderNotification()}
                                        {this.renderRemarks()}
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        {this.renderButton()}
                                    </Grid>

                                </div>
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        );


    }

}

export default withStyles(styles)(VerifyCallCenter);
