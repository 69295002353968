import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import FileMapping from "../CommonComponent/FileMapping";
import Signature from './Signature';

import {linkAccountChangeRequest,} from '../WorkflowJsonFormRashed';


let lockerJsonFormOne = [
    {
        "varName": "linkAccountChangeRequest",
        "type": "checkbox",
        "label": "Link Account Change Request",
        "grid": 4
    },
];

let lockerJsonFormTwo = [
    {
        "varName": "nomineeChangeRequest",
        "type": "checkbox",
        "label": "Nominee Change Request",
        "grid": 4
    },
];

let lockerJsonFormThree = [
    {
        "varName": "keyReplacement",
        "type": "checkbox",
        "label": "Key Replacement",
        "grid": 4
    },
];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}


];
const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'CITY LOCKER APPLICATION FORM'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];

const gdCopy = [
    {label: 'GD Copy One'},
    {label: 'GD Copy Two'},
    {label: 'GD Copy Three'},
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };


class CsLockerMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            showValue: false,
            inputData: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            fileUploadData: {},
            gdFileUploadData: {},
            selectedDate: {},
            uploadModal: false,
            getMappingAllImage: false,
            gdGetMappingAllImage: false,
            loaderNeeded: null,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            lockerGetData: false,
            beforeSearch: true,
            signatureCard: false,
            numberOfNomineeOld: 2,
            existingNominee: [],
            nominee: false,
            gdUploadModal: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.csRemarks = null;

                let varValue = response.data;
                varValue.lockerChangeRequest = response.data.lockerChangeRequest;

                let inputData = this.copyJson(response.data);
                inputData.lockerChangeRequest = response.data.lockerChangeRequest;

                this.setState({
                    inputData: inputData,
                    varValue: varValue,
                    showValue: true,
                    getData: true,
                    lockerGetData: true
                });


            })
            .catch((error) => {
                console.log(error);
            });

        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                this.setState({
                    documentList: response.data,
                    getDocument: true
                })


            })
            .catch((error) => {
                console.log(error);
            });

        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let remarkData = [];

                response.data.map((res) => {

                    remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                });

                this.setState({
                    getRemarkList: remarkData,
                    remarks: true
                })

                //console.log(this.state.getRemarkList)


            })
            .catch((error) => {
                console.log(error)
            })


    }

    updateComponent = () => {
        this.forceUpdate();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };


    handleSubmit = (event) => {

        event.preventDefault();

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error)
            });
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

        let data = this.state.inputData;
        // data.csRemarks = undefined;
        data.cs_deferal = 'NO';
        data.serviceType = 'Locker';
        data.subServiceType = 'LockerMaintenance';
        data.category = 'Locker';
        data.subCategory = 'Maintenance';
        data.numberOfOldNominee = this.state.numberOfNomineeOld;
        data.numberOfNewNominee = this.state.inputData["numberOfNominee"];
        data.accountNumber = '';
        data.customerName = '';
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

            var url = backEndServerURL + "/case/route/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                console.log("Successfully Routed!");

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                setTimeout(function () {
                    window.location.reload()
                }, 1000);
            })

                .catch((error) => {
                    console.log(error);
                });
        })
            .catch((error) => {
                console.log(error)
            });
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    close = () => {
        this.props.closeModal();
    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    gdUploadModal = () => {
        this.setState({
            gdUploadModal: true
        })
    };


    renderUploadButton = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                onClick={this.uploadModal}
            >
                Upload File
            </button>
        )
    };

    renderGDUploadButton = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                onClick={this.gdUploadModal}
            >
                Upload GD
            </button>
        )
    };


    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,

        })
    };

    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };

    gdCloseUploadModal = (data) => {
        this.setState({
            gdUploadButtonClick: true,
            gdUploadModal: false,
            gdGetMappingAllImage: false
        })
    };

    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    gdMappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            gdGetMappingAllImage: true
        })
    };

    renderAssignedImage = () => {
        if (this.state.uploadButtonClick || this.state.fileUploadData.length > 1) {
            return (
                <button
                    style={{
                        width: 120,
                    }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.mappingAllImage}
                >
                    Assigned Image
                </button>
            )
        }
    };

    renderGDAssignedImage = () => {
        if (this.state.gdUploadButtonClick || this.state.gdFileUploadData.length > 1) {
            return (
                <button
                    style={{
                        width: 120,
                    }}
                    className="btn btn-outline-primary btn-sm"
                    onClick={this.gdMappingAllImage}
                >
                    Assigned Image
                </button>
            )
        }
    };


    signatureCard = () => {
        this.setState({
            signatureCard: true
        })
    };

    signatureClose = () => {
        this.setState({
            signatureCard: false
        })
    };

    renderMappingImageEditModal = () => {
        return (
            <FileMappingEdit dropDownOption={arrayListIndividual}
                             appId={this.props.appId}
                             closeModal={this.closeUploadModal}/>
        )
    };

    renderMappingImageModal = () => {
        return (
            <FileMapping dropDownOption={arrayListIndividual}
                         appId={this.props.appId}
                         closeModal={this.closeUploadModal}/>
        )

    };

    renderGDMappingImageEditModal = () => {
        return (
            <FileMappingEdit dropDownOption={gdCopy}
                             appId={this.state.appId}
                             closeModal={this.gdCloseUploadModal}/>
        )
    };

    renderGDMappingImageModal = () => {
        return (
            <FileMapping dropDownOption={gdCopy}
                         appId={this.state.appId}
                         closeModal={this.gdCloseUploadModal}/>
        )

    };

    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.remarks === true && this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };

    onKeyDownForDedup = (event, data) => {
        event.preventDefault()
    };

    LockerSearchOne = () => {
        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormOne, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )

    };

    LockerSearchTwo = () => {

        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormTwo, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )

    };

    LockerSearchThree = () => {
        return (
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormThree, this.updateComponent, this.onKeyDownForDedup)
                    }
                </Grid>

            </Grid>
        )
    };

    renderExistingNomineeForm = () => {

        if (this.state.inputData.nomineeChangeRequest === true) {
            var sl;
            var objectForExistingNominee = [];
            for (var i = 0; i < this.state.numberOfNomineeOld; i++) {
                sl = i + 1;
                objectForExistingNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Existing  Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeNameOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Name",
                        "grid": 2,
                    },

                    {
                        "varName": "nomineeNameNew" + sl,
                        "type": "text",
                        "label": "New Nominee Name",
                        "grid": 2,
                    },
                    {
                        "varName": "dob1Old" + sl,
                        "type": "date",
                        "label": "Existing D.O.B",
                        "grid": 2,
                    },
                    {
                        "varName": "dob1New" + sl,
                        "type": "date",
                        "label": "New D.O.B",
                        "grid": 2,
                    },

                    {
                        "varName": "percentageNomineeOld" + sl,
                        "type": "text",
                        "label": "Existing Percentage %",
                        "grid": 2,
                    },
                    {
                        "varName": "percentageNomineeNew" + sl,
                        "type": "text",
                        "label": "New Percentage %",
                        "grid": 2,
                    },
                    {
                        "varName": "nomineeMinorOld" + sl,
                        "type": "text",
                        "label": "Existing Nominee Minor",
                        "grid": 2,
                    },
                    {
                        "varName": "nomineeMinorNew" + sl,
                        "type": "text",
                        "label": "New Nominee Minor",
                        "grid": 2,
                    },
                )

            }

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, objectForExistingNominee, this.updateComponent)

            )
        }
    };

    renderNewNominee = () => {
        if (this.state.inputData.nomineeChangeRequest === true) {
            return (
                <Grid item xs={12}>
                    <button
                        onClick={this.handleChangeNominee}
                        className="btn btn-danger btn-sm">
                        Number Of New Nominee
                    </button>
                </Grid>
            )
        }
    };

    renderNomineeForm = () => {
        if (this.state.getNominee) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent)
            )
        }
    };

    handleChangeNominee = (event) => {
        event.preventDefault();
        this.setState({
            getNumberofNominee: true
        })
    };

    renderNumberOfNominee = () => {
        if (this.state.getNumberofNominee) {
            return (
                <Grid item xs={2}>
                    <React.Fragment>
                        <Grid item>
                            <label className="input-label-common">Number Of Nominee</label>
                        </Grid>
                        <Grid item>
                            <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                //defaultValue={this.state.inputData["numberOfNominee"]}
                                          options={numberOfNominee} getOptionLabel={option => option.title}
                                          renderInput={(params) => <TextField {...params} variant="outlined"

                                                                              style={{paddingRight: 20}} fullWidth/>}
                            />
                        </Grid>
                    </React.Fragment>
                </Grid>
            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();

        if (option !== null) {
            this.state.inputData["numberOfNominee"] = option.title;
            this.forceUpdate();

            if (option.title > 0) {
                var sl;
                let objectForNominee = [];
                for (var i = 0; i < option.title; i++) {
                    sl = i + 1;
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "New Nominee  " + sl,
                            "grid": 12,

                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 2,
                            required: true,
                        },

                        {
                            "varName": "dob1" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 2,
                            required: true,
                        },


                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 2,
                            required: true,
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["Yes", "No"],
                            "grid": 2,
                            required: true,
                        },
                    )

                }

                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true

                });
                this.renderNomineeForm();
            }
        } else if (option === null) {
            this.setState({
                objectForNominee: [],
                getNominee: true,
                getNumberofNominee: false,

            });
            this.renderNomineeForm();
        }

    };

    lastSegments = () => {
        if (this.state.inputData.linkAccountChangeRequest === true || this.state.inputData.keyReplacement === true || this.state.inputData.nomineeChangeRequest) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs='12'>
                    </Grid>
                    <Grid item xs={2}>
                        {this.renderUploadButton()}
                    </Grid>
                    <Grid item xs={1}>
                        {this.renderAssignedImage()}
                    </Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.signatureCard}
                        >
                            Signature
                        </button>
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={this.state.signatureCard}>
                        <DialogContent>
                            <Signature closeModal={this.signatureClose} appId={this.props.appId}/>
                        </DialogContent>
                    </Dialog>
                    <Grid item xs={12}>
                        {
                            this.renderRemarksTable()
                        }
                    </Grid>

                    <Grid item xs='12'>
                        {this.renderRemarks()}
                    </Grid>
                    <Grid item xs='12'>
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            {this.renderMappingImageEditModal()}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.uploadModal}>
                        <DialogContent>
                            {this.renderMappingImageModal()}
                        </DialogContent>
                    </Dialog>
                    <Grid item xs='12'>
                    </Grid>
                    <center>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    </center>
                </Grid>
            )
        }
    };

    linkAccount = () => {
        if (this.state.inputData.linkAccountChangeRequest === true) {
            return CommonJsonFormComponent.renderJsonForm(this.state, linkAccountChangeRequest, this.updateComponent, this.onKeyDownForDedup)
        }
    };

    keyReplacement = () => {
        if (this.state.inputData.keyReplacement === true) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        {this.renderGDUploadButton()}
                    </Grid>
                    <Grid item xs={1}>
                        {this.renderGDAssignedImage()}
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.gdGetMappingAllImage}>
                        <DialogContent>
                            {this.renderGDMappingImageEditModal()}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.gdUploadModal}>
                        <DialogContent>
                            {this.renderGDMappingImageModal()}
                        </DialogContent>
                    </Dialog>
                </Grid>
            )

        }

    };

    afterLockerSearch = () => {
        return (
            <Grid container spacing={1}>

                {
                    this.LockerSearchOne()
                }

                {
                    this.linkAccount()
                }

                {
                    this.LockerSearchTwo()
                }

                {
                    this.renderExistingNomineeForm()
                }


                {
                    this.renderNewNominee()
                }

                {
                    this.renderNumberOfNominee()
                }

                {
                    this.renderNomineeForm()
                }

                {
                    this.LockerSearchThree()
                }

                {
                    this.keyReplacement()
                }

                {
                    this.lastSegments()
                }
            </Grid>
        )

    };

    render() {

        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Locker Maintenance<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <ThemeProvider theme={theme}>
                            {
                                this.renderNotification()
                            }

                            {
                                this.afterLockerSearch()
                            }

                        </ThemeProvider>
                    </CardBody>
                </Card>
            );
        } else {
            return ''
        }
    }

}

export default withStyles(styles)(CsLockerMaintenance);
