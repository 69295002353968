import React from "react";
import FormSample from '../JsonForm/FormSample';
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from '../../Common/Constant';
import axios from 'axios';
import Notification from "../NotificationMessage/Notification";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [

        {
            "varName": "username",
            "type": "text",
            "label": "User Name",
            "required": true,

        },
        {
            "varName": "firstName",
            "type": "text",
            "label": "First Name",
            "required": true,

        },
        {
            "varName": "lastName",
            "type": "text",
            "label": "Last Name",
            "required": true,

        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": true,
            "email": true

        },
        {
            "varName": "password",
            "type": "password",
            "label": "password",
            "required": true,


        },


    ],

};
const jsonFormEdit = {
    "variables": [

        {
            "varName": "username",
            "type": "text",
            "label": "User Name",
            "readOnly": true,


        },
        {
            "varName": "firstName",
            "type": "text",
            "label": "First Name",
            "required": true,

        },
        {
            "varName": "lastName",
            "type": "text",
            "label": "Last Name",
            "required": true,

        },
        {
            "varName": "email",
            "type": "text",
            "label": "Email",
            "required": true,
            "email": true

        },
        {
            "varName": "password",
            "type": "password",
            "label": "password",
            "required": true,


        },


    ],

};

class UserAddEditDelete extends React.Component {
    renderForm = (() => {
        if (this.state.getUserData === true) {
            return (
                <FormSample close={this.props.close} showValue={true} varValue={this.state.varValue} grid="12"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonFormEdit}/>

            )
        } else if (this.props.username === undefined) {

            return (
                <FormSample close={this.props.close} grid="12" buttonName="Submit" onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm}/>
            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            user: [],
            varValue: [],

            username: '',
            getData: [],
            getUserData: false,
            showPassword: false,
            title: '',
            message: '',
            alert: false,
            redirectLogin: false,
        }
    }

    componentDidMount() {

        if (this.props.username !== undefined) {
            let url = backEndServerURL + '/user/get/' + this.props.username;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let varValue = [];
                    varValue["username"] = response.data.username;
                    varValue["firstName"] = response.data.firstName;
                    varValue["lastName"] = response.data.lastName;
                    varValue["email"] = response.data.email;
                    varValue["password"] = response.data.password;
                    this.setState({
                        varValue: varValue,
                        getUserData: true
                    })

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                });

        }

    }

    notification = () => {
        if (this.state.alert) {

            return (<Notification stopNotification={this.stopNotification} title={this.state.title}
                                  message={this.state.message}/>)
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {
        if (this.props.username === undefined) {
            let url = backEndServerURL + '/user/add';
            axios.post(url,
                {

                    username: object.username,
                    firstName: object.firstName,
                    lastName: object.lastName,
                    email: object.email,
                    password: object.password
                },
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        title: "User Create",
                        message: "Successfully  Create",
                        alert: true
                    });
                    this.props.closeModal();

                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    else {
                        this.setState({
                            alert: true,
                            message: Functions.errorObjectCheck(error),
                            title: "Error!",
                        });
                    }
                });
        } else if (this.props.username !== undefined) {
            let url = backEndServerURL + '/user/edit';
            axios.post(url,
                {

                    username: object.username,
                    firstName: object.firstName,
                    lastName: object.lastName,
                    email: object.email,
                    password: object.password
                },
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "User Edited",
                        message: "Successfully  Edited",
                        alert: true
                    });
                    this.props.closeModal()
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();
                        this.setState({
                            redirectLogin: true
                        })
                    }
                });

        } else {

        }

    };

    render() {


        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.notification()}
                                {this.renderForm()}

                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(UserAddEditDelete);
