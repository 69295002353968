import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL, frontEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import Table from "../../Table/Table";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import {FundTransferJsonFormOne} from '../WorkflowJsonFormRashed'
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import FileTypeComponent from '../../JsonForm/FileTypeComponent';
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from "@material-ui/icons/Close";
import CardBody from "../../Card/CardBody";
import ConfirmationModal from "./ConfirmationModal";
import CBNotFound from "../CASA/CBNotFound";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import SelectComponent from "../../JsonForm/SelectComponent";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import DateComponentDeferal from "../../JsonForm/DateComponentDeferal";
import GridContainer from "../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import CommonApi from "../Maintenance/common/CommonApi";
import FullSdnDedupeResult from "../../FullSdnDedupeResult";

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Remarks",

};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Hard copy missing",
        "Amount mismatch",
        "BEFTN agreement",
        "Signature missing",
        "Mismatch in amount",
        "Wrong Routing No",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".xlsx,.xls,.exl,xlsb,xlxb",
    "label": "Upload Excel",
    "fileType": "multiple",
    "grid": 12
};

let CustomerServiceSearch = [
    {
        "label": "Request Type",
        "type": "select",
        "varName": "requestType",
        "enum": [
            "Single Transaction",
            "Bulk Transaction"
        ],
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
        "required": true,
    },
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 2,
        "readOnly": true
    },
];
let CustomerServiceJsonFormOne = [
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 2,
        "readOnly": true,
        "required": true,
        "multiline": true,
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        // "required": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
    },
];
let CustomerServiceTransferType = [
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "branch",
    },
];
let CustomerServiceJsonFormTwo = [
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "grid": 2,
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
];

let CustomerServiceJsonFormForBranch = [
  /*  {
        "label": "Source",
        "varName": "source",
        "type": "select",
        "enum": [
            "Finacle",
            "Ababil"
        ],
        "readOnly": true,
        "grid": 2,
    },*/
    {
        "label": "A/C Number",
        "type": "text",
        "varName": "accountNumber",
        "grid": 2,
        "readOnly": true
    },
    {
        "label": "Customer Name",
        "type": "text",
        "varName": "customerName",
        "grid": 2,
        "readOnly": true,
        "multiline": true
    },
    {
        "label": "A/C Balance",
        "type": "text",
        "varName": "accountBalance",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "showAccountBalance",
        "conditionalVarValue": true,
        // "required": true
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "sd",
    },
    {
        "label": "Transfer Type",
        "type": "select",
        "varName": "transferType",
        "enum": [
            "RTGS",
            "BEFTN",
        ],
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "requisitionType",
        "conditionalVarValue": "branch",
    },
    {
        "label": "Transfer Amount",
        "type": "textApiCall",
        "varName": "transferAmount",
        "validation": "compareValue",
        "compareVarName": "accountBalance",
        "compareLabel": "Account Balance",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "transferType",
        "conditionalOrList": ["RTGS", "BEFTN", "Salary"],
        "required": true,
    },
    {
        "label": "Amount in Word",
        "type": "text",
        "varName": "amountInWord",
        "grid": 2,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "showAmountConvertField",
        "conditionalVarValue": true,
        "readOnly": true,
    },
    {
        "label": "Beneficiary Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 2,
        "validation": "beneficiaryAccount",
        "required": true,
    },
    {
        "label": "Beneficiary Name",
        "type": "text",
        "varName": "beneficiaryDetails",
        "grid": 2,
        "required": true,
    },
    {
        "type": "empty",
        "grid": 12
    },
    {
        "label": "Beneficiary Bank Name",
        "type": "select",
        "enum": [],
        "varName": "beneficiaryBankName",
        "grid": 4,
        "onKeyDown": true,
        "required": true,
    },
    {
        "label": "Branch Name",
        "type": "select",
        "onKeyDown": true,
        "enum": [],
        "varName": "branchName",
        "grid": 4,
        "required": true,
    },
    {
        "label": "Routing Number",
        "type": "text",
        "varName": "routingNumber",
        "grid": 4,
        "required": true,
        "readOnly": true,
        // "validation": "numeric"
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

const documentType = [
    {label: 'Page 1'},
    {label: 'Page 2'},
    {label: 'Page 3'},
    {label: 'Page 4'},
    {label: 'Page 5'},
    {label: 'Page 6'},
    {label: 'Page 7'},
    {label: 'Page 8'},
    {label: 'Page 9'},
    {label: 'Page 10'},
    {label: 'Page 11'},
    {label: 'Page 12'},
    {label: 'Page 13'},
    {label: 'Page 14'},
    {label: 'Page 15'},
    {label: 'Page 16'},
    {label: 'Page 17'},
    {label: 'Page 18'},
    {label: 'Page 19'},
    {label: 'Page 20'},
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class CsSplitForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getDeferalList: [],
            searchValue: false,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            bulkUpload: false,
            signature: false,
            getRemarks: [],
            documentList: [],
            getDocument: false,
            loading: true,
            disabled: false,
            confirmation: false,
            values: [],
            selectedDate: {},
            SdnForFundTransfer: false

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        CommonApi.getDeferralDetails(this.props.appId)
            .then((tableArray) => {
                this.setState({
                    getDeferalList: tableArray
                });
                CommonApi.getFiles(this.props.appId).then((fileData) => {
                    this.setState({
                        getImageLink: fileData,
                        getImageBoolean: true,
                        preview: fileData.length > 0
                    });
                    CommonApi.getRemarkDetails(this.props.appId)
                        .then((remarksArray) => {
                            this.setState({
                                getRemarks: remarksArray
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }).catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });

        this.functionForFileGet();

        CommonApi.getVariables(this.props.appId)
            .then((response) => {
                let inputData = {...response.data};
                inputData.source = "Finacle"
                if (inputData.source === "Ababil") {
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((responseAcc) => {
                        inputData.accountBalance = responseAcc.data.acctEftvBalance;

                        if (response.data.requisitionType === "branch") {
                            this.getBankName();
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                            });
                        } else {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                                loading: false
                            });
                        }

                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.responseAcc.data.message,
                            loading: false
                        })
                    });
                } else {
                    CommonApi.getCustAccountDetails(response.data.accountNumber).then((responseAcc) => {
                        inputData.accountBalance = responseAcc.data.acctEftvBalance;
                        if (response.data.requisitionType === "branch") {
                            this.getBankName();
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                            });
                        } else {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                getData: true,
                                loading: false
                            });
                        }
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.responseAcc.data.message,
                            loading: false
                        })
                    });
                }

            })
            .catch((error) => {
                console.log(error)
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            })

    }

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-cs-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    functionForExlDownload = (document) => {
        let download_url = backEndServerURL + "/file/" + document;
        axiosDownload.downloadFile(download_url, 'GET', {}, document);
    };

    renderBulkFile = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.customerType) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForExlDownload(document)}
                            >Branch File {index + 1}</button>
                        </React.Fragment>
                    )
                })
            );
        }
    };

    getBankName = () => {
        let url = backEndServerURL + "/finMasterData/custom/otherBanksWithTransfer/" + this.state.inputData.transferType;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    var url = backEndServerURL + "/finMasterData/custom/otherBranchesWithTransfer/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.transferType;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                let branchName = [];
                                for (let item of response.data) {
                                    branchName.push(item.value)
                                }
                                for (let field of CustomerServiceJsonFormForBranch) {
                                    if (field.varName === "branchName") {
                                        field.enum = branchName
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    let bankName = [];
                    for (let bank of response.data) {
                        bankName.push(bank.value)
                    }
                    for (let field of CustomerServiceJsonFormForBranch) {
                        if (field.varName === "beneficiaryBankName") {
                            field.enum = bankName
                        }
                    }
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })
    };

    functionForGettingRoutingNumber = (data) => {
        if (this.state.inputData.requisitionType === "branch") {
            this.setState({
                loading: true
            });

            if (data.varName === "transferType") {
                this.getBankName()
            }

            var url = backEndServerURL + "/finMasterData/custom/otherBranchesWithTransfer/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.transferType;
            let inputData = this.state.inputData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let branchName = [];
                        for (let item of response.data) {
                            branchName.push(item.value)
                        }
                        for (let field of CustomerServiceJsonFormForBranch) {
                            if (field.varName === "branchName") {
                                field.enum = branchName
                            }
                        }
                        if (this.state.inputData.beneficiaryBankName && this.state.inputData.branchName) {
                            let routingUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.branchName;

                            axios.get(routingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.status === 200) {
                                        inputData.routingNumber = response.data.toString();

                                        this.setState({
                                            inputData: inputData,
                                            varValue: inputData,
                                            loading: false
                                        });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({
                                        inputData: inputData,
                                        varValue: inputData,
                                        loading: false
                                    });
                                    console.log(error)
                                });
                        } else {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false
                    });
                    console.log(error)
                });
            this.forceUpdate();
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <Grid container>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormOne, this.updateComponent)
                    }
                </Grid>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title} message={this.state.notificationMessage}/>
            )
        }
    };

    customerInformation = () => {
        if (this.state.getData && !this.state.inputData.branchEnd) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceSearch, this.updateComponent)
                    }
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceJsonFormOne, this.updateComponent)
                    }

                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceTransferType, this.updateComponent)
                    }

                    {this.customerType()}
                </ThemeProvider>
            )
        } else if (this.state.getData && this.state.inputData.branchEnd) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceJsonFormForBranch, this.updateComponent, this.amountConvert, this.functionForGettingRoutingNumber)
                    }
                </ThemeProvider>
            )
        }
    };

    amountConvert = (event, data) => {
        this.setState({
            loading: true
        });
        this.state.inputData.showAmountConvertField = false;
        if (data === "transferAmount") {
            let form = [
                {
                    "varName": "transferAmount",
                    "type": "text",
                    "validation": "length",
                    "maxLength": 14,
                    "required": true
                }
            ];
            let error = MyValidation.defaultValidation(form, this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            } else {
                let inputData = this.state.inputData;
                let amount = this.state.inputData.transferAmount.split(".");

                if (2 < amount.length) {
                    this.setState({
                        inputData: inputData,
                        varValue: inputData,
                        loading: false,
                        cbNotFound: true,
                        title: "Invalid Amount!"
                    })
                } else {
                    let url = backEndServerURL + "/GetWordFromDecimalNumber";
                    let postData = {};
                    postData.number = this.state.inputData.transferAmount;
                    axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            inputData.amountInWord = response.data;
                            this.state.inputData.showAmountConvertField = true;
                            setTimeout(() => {
                                this.setState({
                                    inputData: inputData,
                                    varValue: inputData,
                                    loading: false
                                })
                            }, 1000)
                        })
                        .catch((error) => {
                            this.setState({
                                inputData: inputData,
                                varValue: inputData,
                                loading: false
                            });
                        });
                }
            }
        }
    };

    customerType = () => {
        if (this.state.inputData.transferType !== undefined && this.state.inputData.transferType !== null) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, CustomerServiceJsonFormTwo, this.updateComponent, this.amountConvert)
                    }

                    <Grid item xs={12}>

                    </Grid>

                </ThemeProvider>
            )
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent indexList={documentType} previewImage={this.previewImage}
                                         appId={this.props.appId} classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };

    customerDocumentType = () => {
        if (this.state.inputData.transferType) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid item xs={12}>
                        {this.uploadFunction()}
                    </Grid>
                    <Grid item xs={12}>

                    </Grid>
                    {this.state.inputData.requisitionType !== "branch" && FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                    <Grid item xs={12}>
                        {this.renderBulkFile()}
                    </Grid>
                </ThemeProvider>
            )
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.forceUpdate();
        let error = false;
        if (this.state.inputData.branchEnd) {
            error = MyValidation.defaultValidation(CustomerServiceJsonFormForBranch, this.state);
            this.forceUpdate();

        } else {
            error = MyValidation.defaultValidation(CustomerServiceJsonFormTwo, this.state);
            this.forceUpdate();
        }

        var amountNotApplicable = false;
        if (this.state.inputData.transferType === "RTGS" && parseInt(this.state.inputData.transferAmount) < 100000) {
            amountNotApplicable = true;
            this.setState({
                cbNotFound: true,
                disabled: false,
                title: "Minimum RTGS Payment is 1 Lac"
            });
        }
        if (error === true || amountNotApplicable === true) {
            return 0;
        } else {
            if ((this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN") && parseInt(this.state.inputData.transferAmount) >= 50000000) {
                this.setState({
                    disabled: false,
                    title: "For payment of Tk. 5 crore and above, please take approval from Treasury Division.Did you take the approval ?",
                    confirmation: true,
                });
            } else if (this.state.inputData.transferType === "RTGS") {
                this.setState({
                    title: "Charge and VAT applicable as per Schedule of Charges",
                    confirmation: true
                })
            } else {
                this.functionForRoute();
            }
        }
    };

    functionForRoute = () => {
        this.setState({
            disabled: true,
        });
        if (this.state.inputData.requisitionType === "branch") {
            this.state.inputData.requestType = "Bulk Transaction"
        }

        if (this.state.inputData.uploadFileAlertRequest && (this.state.inputData.previewImage === undefined || !this.state.preview)) {
            this.setState({
                disabled: false,
                cbNotFound: true,
                title: "Please Upload File!"
            })
        } else {
            let formData = new FormData();
            if (this.state.fileUploadData.scanningFile !== undefined) {
                this.state.fileUploadData.scanningFile.map((item, index) => {
                    formData.append("file", item);
                    formData.append("type", "fundTransfer-cs-attachment")
                });
                let fileUploadPath = backEndServerURL + "/case/upload";
                formData.append("appId", this.props.appId);
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {

                    })
                    .catch((error) => {
                        this.setState({
                            disabled: false,
                        });
                        console.log(error)
                    })
            }

            if (this.state.inputData["csDeferal"] === "YES") {
                let defType = [];
                let expDate = [];
                var deferralData = {};
                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let deferalType = this.state.inputData["deferalType" + value];
                    if (deferalType === "other") {
                        deferalType = this.state.inputData["deferalOther" + value];
                    }
                    defType.push(deferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);

                }
                deferralData.appId = appId;
                deferralData.type = defType;
                deferralData.dueDate = expDate
            }
            let postData = {...this.state.inputData};
            postData.serviceType = "FundTransfer";
            postData.category = this.state.inputData.transferType;
            postData.subCategory = this.state.inputData.requestType;
            postData.subServiceType = "NewFundTransfer";
            postData.cs_deferal = this.state.inputData["csDeferal"] === "YES" ? "YES" : "NO";
            let remarksData = {};
            remarksData.remark = postData.csRemarks;
            remarksData.map = {...postData, csRemarks: undefined};
            remarksData.bulkDeferralDTO = deferralData;
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    confirmation: false,
                    disabled: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderSubmitButton = () => {
        return (
            <>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        marginTop: "10px"
                    }}
                    onClick={this.handleSubmit}
                    disabled={this.state.disabled}
                >
                    Submit
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        marginTop: "10px"
                    }}
                    onClick={this.handleClose}
                    disabled={this.state.disabled}
                >
                    Close
                </button>
            </>
        )
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    confirmationClose = (value) => {
        if (value === "ok") {
            this.functionForRoute()
        } else {
            this.setState({
                disabled: false,
                confirmation: false
            })
        }
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple" requestType={this.state.inputData.source} classes={this.props}/>;
        }
    };

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {this.dynamicDeferral(el)}
                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDate(el)}
                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}
                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )
    }


    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Deferral</button>
        )
    };

    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));
        this.state.inputData["csDeferal"] = "YES";
    }

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                    </div>
                </div>
            )
        }
    };

    handleSDNSearchButton = () => {
        if (this.state.inputData.transferType === "RTGS") {
            return (<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleChangeSdnResult(event)}
                    >
                        SDN View
                    </button>
                </div>
            )
        }
    }
    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnForFundTransfer: true
        })

    };
    handleCloseSdnView = (event) => {
        event.preventDefault();
        this.closeModalForSdnResult()
    }
    closeModalForSdnResult = () => {
        this.setState({
            SdnForFundTransfer: false
        })
    }


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Fund Transfer (
                                <b> Case ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""}
                                <b> Customer Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                                <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                                <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                                <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""})<a><CloseIcon
                                    onClick={this.props.closeModal} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        open={this.state.confirmation}>
                                        <DialogContent>
                                            <ConfirmationModal closeModal={this.confirmationClose}
                                                               title={this.state.title}/>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.SdnForFundTransfer}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <FullSdnDedupeResult category={this.props.category}
                                                                 subServiceType={'INDIVIDUAL'} appId={this.props.appId}/>
                                        </DialogContent>
                                        <center>
                                            <button
                                                className="btn btn-outline-primary  btn-sm"
                                                style={{}}
                                                onClick={(event) => this.handleCloseSdnView(event)}
                                            >Close
                                            </button>
                                        </center>
                                    </Dialog>

                                    <Grid container>
                                        {this.customerInformation()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.signature()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.handleSDNSearchButton()}
                                    </Grid>
                                    {this.renderAddButtonShow()}
                                    {this.addDeferalForm()}
                                    {this.customerDocumentType()}
                                    <Grid item xs={12}>
                                        {this.renderDefferalData()}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.renderRemarksData()}
                                        <br/>
                                        {this.renderRemarks()}
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    {this.renderSubmitButton()}
                                    {this.renderNotification()}
                                </ThemeProvider>
                            </Grid>
                            <br/>
                        </CardBody>
                    </Card>
                </div>
            )
        }
    }


}

export default withStyles(styles)(CsSplitForm);
