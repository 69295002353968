import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import MyValidation from "../../JsonForm/MyValidation";


let searchFormOne = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
        required: true,
        maxDate: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
        required: true,
        maxDate: true
    },
    {
        varName: "userGroup",
        type: "select",
        enum: [
            "CS",
            "BOM"
        ],
        label: "Report Type",
        grid: 2,
        required: true,
    },
];
let searchFormTwo = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
        required: true,
        maxDate: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
        required: true,
        maxDate: true
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class CityTouchReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            selectedDate: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            cbNotFound: false,
            alert: false,
            title: "",
            notificationMessage: "",
            loading: false,
            currentDate: 0,
            reportType: this.props.match.params.reportType
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    componentDidMount() {
        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);

        this.setState({
            currentDate: currentDate
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.reportType !== prevProps.match.params.reportType) {
            this.setState({
                inputData: {},
                varValue: {},
                err: false,
                errorArray: {},
                errorMessages: {},
                reportType: this.props.match.params.reportType,
            })
        }
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "-1" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    downloadReport = () => {
        let error = MyValidation.defaultValidation(this.copyJson(searchFormTwo), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let download_url = backEndServerURL + "/cityTouchExcelReport";
            axiosDownload.downloadFile(download_url, 'POST', this.state.inputData, `${this.state.currentDate}-city-touch-report.xlsx`).then((result) => {
                if (result) {
                    this.setState({
                        loading: false
                    });
                    if (result === "File Not Found") {
                        this.setState({
                            cbNotFound: true,
                            title: "No Data Found!"
                        })
                    }
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            })
        }
    };


    renderLoader = () => {
        if (this.state.loading) {
            const {classes} = this.props;
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


            )
        }
    };

    renderJsonForm = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, searchFormTwo, this.updateComponent)
    };

    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Generate Report for City Touch
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {
                            this.renderLoader()
                        }
                        <Grid item xs={12}>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.renderJsonForm()}
                                    <Grid item xs={6} style={{marginTop: "18px"}}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.downloadReport}
                                        >
                                            Download Report
                                        </button>
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(CityTouchReport);
