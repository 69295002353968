import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import FileReqInitiatorFormDialogLabel from "./FileReqInitiatorFormDialogLabel";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const jsonForm = [

    {
        varName: "purpose",
        type: "select",
        label: "Purpose",
        required: true,
        grid: 4,
        "enum": [
            "NOC Issuance",
            "Closing",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "departmentName",
        type: "text",
        label: "Department Name",
        required: true,
        grid: 4,
    },
    {
        type: "empty",
        grid: 12,
    },

    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 8
    },
    {
        type: "empty",
        grid: 12,
    },


];


class FileReqInitiatorFormDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                fileReferenceNo: this.props.fileReferenceNo,
                applicantName: this.props.applicantName,
                assetOpsNumber: this.props.assetOpsNumber,
                accountNumber: this.props.accountNumber,
                requisitionBy: this.props.requisitionBy,
                requisitionDate: this.props.requisitionDate,
            },
            fileName: this.props.fileName,
            activeItem: this.props.activeItem,
            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {
                customerName: "FAISAL AHMED",
                cbNumber: "CB1401933",
                accountNumber: "2251401933001",
                address: "Gulshan-2,dhaka",
                nomineeNumberOld: "2",
                tinUpdateOld: "784312990820",
                titleOld: "FAISAL AHMED",
                nomineeNameOld: "Salam",
                existingNID: "123333456676777",
                existingPassport: "1234435553",
                existingTradeLicense: "3345552234455",
                existingResidenceNumber: "0171302983",
                existingOfficeNumber: "01713234325",
                existingMobileNumber: "01779393110",
                existingEmailId: "faisal@gmail.com",
                existingPermanentAddress: "Dhaka",
                existingPresentAddress: "Dhaka",
                existingOfficeAddress: "Dhaka",
                photoIdOld: "234325365",
                dobOld: "21-10-1991",
                fatherOld: "Mr. Anisur Rahman",
                motherOld: "Rehana Begum",
                spouseOld: "Mukta Rahman",
                professionOld: "Businessman",
                employerOld: "Salman Rahman",
                existingLinkAccount: "2134241212456",
                amountExisting: "33321421",
                tenorExisting: "244242",
                valueDateExisting: "01-01-2020",
                maturityDateExisting: "01-01-2021",
                existingMandate: "24324",
                existingCurrency: "BD",
                motherAccountSolId: "1234567",
                nomineeMinor1: "Yes"
            },
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            loading: false,

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        // this.renderStartCase();
        // this.updateComponent()


    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderCustomerDetails = () => {

        return (
            <FileReqInitiatorFormDialogLabel data={this.state.inputData} classes={this.props}
                                             labelGrid={[3, 3, 3, 3, 3, 3]}/>
        )

    };


    renderForm = () => {
        return (

            CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)

        )

    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return (

            <div>


                <ThemeProvider theme={theme}>
                    <Grid container>
                        {this.renderForm()}
                        <Grid item xs='12'>
                        </Grid>


                    </Grid>
                </ThemeProvider>

            </div>


        )


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    handleSubmitButton = () => {


        return (
            <ThemeProvider>
                <Grid>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{}}
                        onClick={this.handleSubmit}

                    >
                        Submit
                    </button>

                </Grid>
            </ThemeProvider>
        )


    };
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData;

        this.setState({
            loading: true,
        });

        let url = backEndServerURL + "/archiveFile/requisition/";


        axios
            .post(
                url,
                {
                    fileReferenceNo: object.fileReferenceNo,
                    purpose: object.purpose,
                    departmentName: object.departmentName,
                    remarks: object.remarks,
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                }
            )
            .then((response) => {
                console.log('Response Data : ');
                console.log(response.data);
                this.setState({
                    loading: false,
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Added",
                    alert: true,
                });
                console.log("Added Successfully");
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
            });


    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };


    render() {

        console.log("inputData");
        console.log(this.state.inputData);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.croppedImage}>
                    <DialogContent className={classes.dialogPaper}>
                        <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>

                    </DialogContent>
                </Dialog>

                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >
                        File Requisition Initiation<a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h4>
                </CardHeader>
                <CardBody>

                    {this.renderNotification()}
                    {this.renderCustomerDetails()}

                    <br/>

                    {this.commonData()}


                    {this.handleSubmitButton()}

                </CardBody>
            </Card>
        );


    }

}

export default withStyles(styles)(FileReqInitiatorFormDialog);
