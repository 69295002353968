import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DebitCardCheckerSplit from "../DebitCard/DebitCardCheckerSplit";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import MyValidation from "../../JsonForm/MyValidation";
import SignatureButtonForClientId from "../../workflow/CommonComponent/SignatureButtonForClientId";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
let pdfForm = [
    {
        varName: "clintId",
        type: "textApiCall",
        readOnly: true,
        validation: "numeric",
        label: "Credit Card Client Id",
        grid: 2
    },
    {
        varName: "eqmNumber",
        type: "text",
        label: "EQM Number",
        grid: 2,
        readOnly: true,
        validation: "numeric"
    },
    {
        varName: "cardNumber",
        type: "autoCompleteValueReturn",
        label: "Card Number",
        onKeyDown: true,
        readOnly: true,
        grid: 2
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showCustName",
        conditionalVarValue: true
    },
    {
        varName: "cardProductName",
        type: "text",
        label: "Card Product Name",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "cardChequeType",
        type: "text",
        label: "Card Cheque Type",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "chequeType",
        type: "text",
        label: "Cheque Type",
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "showChequeType",
        conditionalVarValue: true
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        readOnly: true,
        conditional: true,
        conditionalVarName: "showCustAddress",
        conditionalVarValue: true,
        grid: 2
    },
];

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
        "required": true
    }];

class BomApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            documentList: [],
            getDocument: false,
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getData: false,
            getRemarks: [],
            loading: true
        };
    }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    getRemarks = () => {

    };


    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                this.setState({
                    varValue: response.data,
                    appData: this.copyJson(response.data),
                    inputData: this.copyJson(response.data),
                });

                let remarksArray = [];
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log(response.data);
                        response.data.map((data) => {

                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error);
            });

        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            })

    }

    inboxCase = () => {
        return (
            <DebitCardCheckerSplit
                closeModal={this.props.closeModal}
                documentList={this.state.documentList}
                appId={this.props.appId}
                form={this.renderFormWithData}
            />
        );
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    approveHandler = event => {
        event.preventDefault();
        let url = backEndServerURL + "/variables/" + this.props.appId;

        let approval={};
        if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
            approval.call_center_checker_approval="APPROVED";
        }
        else{
            approval.bom_approval= "APPROVED";
        }
        axios
            .post(url, approval, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let routeTaskUrl = backEndServerURL + "/creditCardChequebookRequisitionSave/" + this.props.appId;
                axios.get(routeTaskUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                        axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {


                            })
                            .catch(error => {
                                console.log(error)
                            });

                        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios
                            .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then(response => {
                                this.setState({
                                    alert: true,
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                });
                                this.props.closeModal()

                            })
                            .catch(error => {
                                console.log(error);
                            });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };
    returnHandler = event => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(bomRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let inputData={};
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
                inputData.call_center_checker_approval="RETURN";
            }
            else{
                inputData.bom_approval="RETURN";
            }


            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios
                .post(url, inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
                    axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {


                        })
                        .catch(error => {
                            console.log(error)
                        });
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                alert: true,
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                            });
                            this.props.closeModal()
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
    };


    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document Preview
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <div>
                    {/*<Card>*/}
                    {/*    <CardBody>*/}
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                pdfForm,
                                this.updateComponent
                            )}
                            <Grid item xs={12}></Grid>
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}><br/></Grid>}
                        </ThemeProvider>
                        <Grid container spacing={0}>
                            <ThemeProvider theme={theme}>
                                {this.state.inputData.clintId !== undefined &&
                                <SignatureButtonForClientId clientId={this.state.inputData.clintId} classes={this.props}/>}
                                {
                                    this.renderRemarksData()
                                }
                                <Grid item xs={12}></Grid>
                                {this.renderRemarks()}
                            </ThemeProvider>
                        </Grid>
                    </Grid>

                    <Grid item xs="3" style={{marginTop: "20px"}}>
                        {/*<center>*/}
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.returnHandler}
                        >
                            Return
                        </button>
                    </Grid>
                    {
                        this.renderNotification()
                    }
                    {/*    </CardBody>*/}
                    {/*</Card>*/}
                </div>
            );
        } else if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }

}

export default withStyles(styles)(BomApproval);
