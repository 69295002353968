import React, {Component} from "react";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";

class FirstLoginPage extends Component {

    componentDidMount() {
        console.log(LocalstorageEncrypt.encryptStorageFunction.getItem("reload"));
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("reload") !== null && LocalstorageEncrypt.encryptStorageFunction.getItem("reload") !== undefined) {
            LocalstorageEncrypt.encryptStorageFunction.removeItem("reload");
            window.location.reload();
        }
    }

    render() {
        return (
            <div style={{position: "absolute", top: "calc(100% - 50%)", left: "25%"}}>
                <h1 style={{
                    textAlign: "center", fontSize: "3rem",
                    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                    fontWeight: "bold"
                }}>Welcome to Green Delta Insurance</h1>
            </div>
        )
    }
}

export default FirstLoginPage