import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import MakerCumInput from "./MakerCumInput";
import AssignedCropImage from "./AssignedCropImage";
import DedupResultFunction from "../../DedupResultFunction";
import ImageCrop from "./ImageCrop";
import SecondaryCbFormDetails from "./SecondaryCbFormDetails";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ErrorModal from "../CommonComponent/ErrorModal";
import MyValidation from "../../JsonForm/MyValidation";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import WaiverList from "../CommonComponent/WaiverList";
import DeferalList from "../CommonComponent/DeferalList";
import SdnResultFunction from "../../SdnResultFunction";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import CardHeader from "../../Card/CardHeader";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import DedupeResultFunctionForAgentBanking from "../../DedupeResultFunctionForAgentBanking";
import WithoutDedupeComponent from "../CommonComponent/WithoutDedupeComponent";
import FileMapping from "../CommonComponent/FileMapping";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CommonApi from "../Maintenance/common/CommonApi";
import AssignedCropImageEdit from "./AssignedCropImageEdit";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const arrayListIndividualCondition = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},

];
const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'OTHERS'},

];
let taggingForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
        "length": 9,


    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,


        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,


    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status as on Date",
        "grid": 6
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "AC Manager",
        "grid": 6,


    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,


        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,


        required: true,
    },

    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "text",
        "label": "Staff Flag",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,


        required: true,
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,


    },

    {
        "varName": "nonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,


        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,


        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National ID Card",
        "grid": 6,


        required: true,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of Birth",
        "grid": 6,


    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer Id",
        "grid": 6,
        "length": 9,

        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,


    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,


    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father",
        "grid": 6,


        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,


        required: true,
    },

    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "grid": 6,


        required: true,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address1",
        "grid": 6,


    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address2",
        "grid": 6,


    },

    {
        "varName": "city1",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country1",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,
        "length": 11,

        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address1",
        "grid": 6,


    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address2",
        "grid": 6,


    },

    {
        "varName": "city2",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country2",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo222",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address1",
        "grid": 6,


    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address2",
        "grid": 6,


    },

    {
        "varName": "city3",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,


    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,


    },

    {
        "varName": "tds",
        "type": "text",
        "label": "TDS",
        "grid": 6,


    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PANGIR No",
        "grid": 6,


    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,


    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,


    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,


        required: true,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,


    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,


    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text2",
        "grid": 6,


    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,


    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,


    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text13",
        "grid": 6,


    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text14",
        "grid": 6,


    },

    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text15",
        "grid": 6,


    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code1",
        "grid": 6,


    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code3",
        "grid": 6,


    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,


    },
];
let objectForNominee = [];
let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "grid": 12
    }];
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'},
    {title: 'NA'}


];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class VerifyMakerInboxCase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            appData: {},
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {tagList: {}},
            selectedDate: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            values: [],
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            requestType: "",
            getCheckerList: [],
            getAllDefferal: [],
            getDeferalList: [],
            occupationCodeList: [],
            loading: true,
            jointAccountCustomerNumber: 0,
            customerNumber: 0,
            linkrelationCode: 0,
            objectForNominee: [],
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            uploadModal: false,
            getMappingAllImage: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            imageCropModal: false,
            errorModalBoolean: false,
            cbGenerateModal: false,
            getRemarks: [], err: false,
            errorArray: {},
            errorMessages: {},
            getMappingCropImage: false,
            nomineeNumber: 0,
            getNominee: false,
            errorMessage: "Invalid Code",
            type: "successs",
            city: [],
            dispatchMode: [],
            getCity: false,
            stateCode: [],
            getGuardianCode: [],
            savingsSchemeCodeList: [],
            currentSchemeCodeList: [],
            getState: false,
            country: [],
            titleList: [],
            chequebookWaiverType: [],
            rmCodeList: [],
            phoneEmailType: [],
            bankRelationTypeList: [],
            phoneOrEmail: [],
            getCountry: false,
            currency: [],
            relationshipList: [],
            constitutionList: [],
            customerStatusList: [],
            waiverFieldList: [],
            ccepCodeList: [],
            introducerStatus: [],
            relationCodeList: [],
            nomineeRelationShipCodeList: [],
            getCurrency: false,
            confirmAlert: false,
            actionType: "",
            despatchmodeList: [],
            sectorCodeList: [],
            subsectorCodeList: [],
            depositCodeList: [],
            tradeServiceList: [],
            agentBankingOutletList: [],
            subSegmentList: [],
            segmentList: [],
            maritalStatusList: [],
            cbDataAfterSet: false,
            accountDataAfterSet: false,
            kycAutopopulateFlag: false,
            TpAutopopulateFlag: false,
            phoneNumCountryCodeList: [],
            getDownloadListFlag: false,
            downLoadFileList: [],
            getnonResident: [],
            getfreecode7: [],
            fileNotFoundTittle: "",
            employmentTypeList: [],
            placeOfIssueList: [],
            despatchList: [],


        };


    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined") {
                clone[prop] = undefined
            }

        return clone;
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = this.props.commonJsonForm;
        let jsonArrayLast = this.props.commonJsonFormLast;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                this.props.commonJsonForm[i].enum = getValue;

            }
        }
        for (let i = 0; i < jsonArrayLast.length; i++) {
            let jsonObjectLast = jsonArrayLast[i];
            if (jsonObjectLast.varName === searchVarname) {
                this.props.commonJsonFormLast[i].enum = getValue;

            }
        }
        for (let i = 0; i < jsonArrayLast.length; i++) {
            let jsonObjectLast = jsonArrayLast[i];
            if (jsonObjectLast.varName === searchVarname) {
                this.props.commonJsonFormLast[i].enum = getValue;

            }
        }
        /* if (searchVarname === "") {
             let tpArrayList = this.props.tpAndKyc;
             for (let i = 0; i < tpArrayList.length; i++) {
                 let jsonObjectLast = tpArrayList[i];
                 if (jsonObjectLast.varName === searchVarname) {
                     this.props.tpAndKyc[i].enum = getValue;

                 }
             }
         }*/
       // if (searchVarname === "howTheAddressOfTheAccountHolderHasBeenVerified" || searchVarname === "customersOccupation" || searchVarname === "documentsCollectedToEnsureTheSourceOfFund" || searchVarname === "howTheSourcesOfFundHaveBeenVerified" || searchVarname === "whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged" || searchVarname === "customerMonthlyIncome" || searchVarname === "howWasAccountOpened" || searchVarname === "howTheSourcesOfFundHaveBeenVerified" || searchVarname === "nonResidentCustomerRisk") {
            let kycArrayList = this.props.tpAndKyc;
            for (let i = 0; i < kycArrayList.length; i++) {
                let jsonObjectLast = kycArrayList[i];
                if (jsonObjectLast.varName === searchVarname) {
                    this.props.tpAndKyc[i].enum = getValue;

                }
            }
       // }

    };
    findByVarNameKycMasterDataApiData = (searchVarname, getValue) => {
             let kycArrayList = this.props.tpAndKyc;
            for (let i = 0; i < kycArrayList.length; i++) {
                let jsonObjectLast = kycArrayList[i];
                if (jsonObjectLast.varName === searchVarname) {
                    this.props.tpAndKyc[i].enum = getValue;

                }
            }

    };
    dynamicApiCallBankAndBranch = (fieldName, bankName, branchName) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + bankName + "/" + branchName;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    };
    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let uniqueDocTypeResponse=  LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterData"+apiType);
        if(uniqueDocTypeResponse===undefined || uniqueDocTypeResponse===null) {
            let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterData"+apiType, response.data);
                    if (apiType === "documentCodeDocumentType") {
                        // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                        //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                        //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                        //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                        // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                        // this.findByVarNameApiData("etindocumentType", response.data)
                        this.findByVarNameApiData("photoIddocumentType", response.data)

                    } else if (apiType === "documentCodeDocumentTypeDesc") {
                        // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                        //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                        // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                        //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                        //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                        //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                        this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else{
            if (apiType === "documentCodeDocumentType") {
                // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                // this.findByVarNameApiData("etindocumentType", response.data)
                this.findByVarNameApiData("photoIddocumentType", uniqueDocTypeResponse)

            } else if (apiType === "documentCodeDocumentTypeDesc") {
                // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                this.findByVarNameApiData("photoIddocumentTypeDescription", uniqueDocTypeResponse)

            }
        }
    };
    dynamicApiDataSet= (apiType,fieldName,responseData) => {
        if (apiType === "city") {
            this.findByVarNameApiData("maillingAdresscity", responseData);
            this.findByVarNameApiData("homeAddresscity", responseData);
            this.findByVarNameApiData("workAddresscity", responseData);
            this.findByVarNameApiData("presentAddresscity", responseData);
            this.findByVarNameApiData("nreAddresscity", responseData);
            this.findByVarNameApiData("mailingAddresscity1", responseData);
            this.findByVarNameApiData("homeAddresscity1", responseData);
            this.findByVarNameApiData("workAddresscity1", responseData);
            this.findByVarNameApiData("workAddresscity2", responseData);


            this.findByVarNameApiData("mailingAddresscity", responseData);
            this.findByVarNameApiData("companyRegisteredcity", responseData);
            this.findByVarNameApiData("factoryAddresscity", responseData);
            this.findByVarNameApiData("officeAddresscity", responseData);
            this.findByVarNameApiData("homeAddressaddresscity", responseData);
            this.findByVarNameApiData("workAddresscity", responseData);
            this.findByVarNameApiData("presentAddresscity", responseData);
            this.findByVarNameApiData("nreAddresscity", responseData);


            this.setState({
                city:responseData,
                getCity: true
            })

        }
        else if (apiType === "state") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatastate", responseData);
            this.findByVarNameApiData("maillingAdressstate", responseData);
            this.findByVarNameApiData("homeAddressstate", responseData);
            this.findByVarNameApiData("workAddressstate", responseData);
            this.findByVarNameApiData("presentAddressstate", responseData);
            this.findByVarNameApiData("nreAddressstate", responseData);
            this.findByVarNameApiData("mailingAddressstate1", responseData);
            this.findByVarNameApiData("homeAddressstate1", responseData);
            this.findByVarNameApiData("workAddressstate1", responseData);
            this.findByVarNameApiData("workAddressstate2", responseData);


            this.findByVarNameApiData("mailingAddressstate", responseData);
            this.findByVarNameApiData("companyRegisteredstate", responseData);
            this.findByVarNameApiData("factoryAddressstate", responseData);
            this.findByVarNameApiData("officeAddressstate", responseData);
            this.findByVarNameApiData("mailingAddressstate", responseData);
            this.findByVarNameApiData("homeAddressaddressstate", responseData);
            this.findByVarNameApiData("workAddressstate", responseData);
            this.findByVarNameApiData("presentAddressstate", responseData);
            this.findByVarNameApiData("nreAddressstate", responseData);


            this.setState({
                stateCode: responseData,
                getState: true,
            })
        }
        else if (apiType === "country") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacountry", responseData);
            this.findByVarNameApiData("passportNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("maillingAdresscountry", responseData);
            this.findByVarNameApiData("homeAddresscountry", responseData);
            this.findByVarNameApiData("workAddresscountry", responseData);
            this.findByVarNameApiData("Customersnationality", responseData);
            this.findByVarNameApiData("presentAddresscountry", responseData);
            this.findByVarNameApiData("nreAddresscountry", responseData);
            this.findByVarNameApiData("mailingAddresscountry1", responseData);
            this.findByVarNameApiData("homeAddresscountry1", responseData);
            this.findByVarNameApiData("workAddresscountry1", responseData);
            this.findByVarNameApiData("workAddresscountry2", responseData);
            //this.findByVarNameApiData("passportNumberplaceOfIssue", responseData)


            this.findByVarNameApiData("mailingAddresscountry", responseData);
            this.findByVarNameApiData("companyRegisteredcountry", responseData);
            this.findByVarNameApiData("factoryAddresscountry", responseData);
            this.findByVarNameApiData("officeAddresscountry", responseData);
            this.findByVarNameApiData("mailingAddresscountry", responseData);
            this.findByVarNameApiData("homeAddressaddresscountry", responseData);
            this.findByVarNameApiData("workAddresscountry", responseData);
            this.findByVarNameApiData("presentAddresscountry", responseData);
            this.findByVarNameApiData("nreAddresscountry", responseData);
            this.findByVarNameApiData("residenceCertificatenationality", responseData);
            this.findByVarNameApiData("registrationNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("certificateOfIncorporationcountryOfIssue", responseData);
            this.findByVarNameApiData("certificateOfCommencementcountryOfIssue", responseData);
            this.findByVarNameApiData("ngoBureauCertificatecountryOfIssue", responseData);
            this.findByVarNameApiData("societyRegistrationNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("clubRegistrationNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("governmentPermissionLettercountryOfIssue", responseData);
            this.findByVarNameApiData("otherIdCorporatecountryOfIssue", responseData);
            this.findByVarNameApiData("registrationNumbercountryOfIssue", responseData);
            this.findByVarNameApiData("tradeLicensecountryOfIssue", responseData);


            this.setState({
                country: responseData,
                getCountry: true
            })
        }
        else if (apiType === "currency") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacurrency", responseData);

            this.findByVarNameApiData("workAddressCurrency", responseData);
            this.findByVarNameApiData("homeAddressCurrency", responseData);
            //this.findByVarNameApiData("aof1currencyCodeCcy", responseData)
            this.setState({
                currency: responseData,
                getCurrency: true
            })
        } else if (apiType === "phoneCountryCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataphoneCountryCode", responseData);
            this.findByVarNameApiData("countryCodecontactNumberphoneNo1", responseData);
            this.findByVarNameApiData("countryCodecontactNumberphoneNo2", responseData);
            this.findByVarNameApiData("countryCodecontactNumberphoneNo3", responseData);
            this.setState({
                phoneNumCountryCodeList: responseData
            })
        } /*else if (apiType === "customerStatus") {
                    this.setState({
                        customerStatusList:responseData,
                    })
                }*/ else if (apiType === "savingsSchemeCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasavingsSchemeCode", responseData);
            this.setState({
                savingsSchemeCodeList: responseData,
            })
        } else if (apiType === "currentSchemeCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacurrentSchemeCode", responseData);
            this.setState({
                currentSchemeCodeList: responseData,
            })
        } else if (apiType === "placeOfIssue") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataplaceOfIssue", responseData);
            this.findByVarNameApiData("passportNumberplaceOfIssue", responseData);
            this.findByVarNameApiData("registrationNumberplaceOfIssue", responseData);
            this.findByVarNameApiData("certificateOfIncorporationplaceOfIssue", responseData);
            this.findByVarNameApiData("certificateOfCommencementplaceOfIssue", responseData);
            this.findByVarNameApiData("ngoBureauCertificateplaceOfIssue", responseData);
            this.findByVarNameApiData("societyRegistrationNumberplaceOfIssue", responseData);
            this.findByVarNameApiData("clubRegistrationNumberplaceOfIssue", responseData);
            this.findByVarNameApiData("governmentPermissionLetterplaceOfIssue", responseData);
            this.findByVarNameApiData("otherIdCorporateplaceOfIssue", responseData);
            this.findByVarNameApiData("registrationNumberplaceOfIssue", responseData);
            this.findByVarNameApiData("tradeLicenseplaceOfIssue", responseData);
            this.setState({
                placeOfIssueList: responseData
            })
        } else if (apiType === "despatchMode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatadespatchMode", responseData);
            this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", responseData);
            this.setState({
                despatchList: responseData
            })
        } else if (apiType === "nomineeRelationCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatanomineeRelationCode", responseData);
            this.findByVarNameApiData("guardianDetailsrelation1", responseData);
            this.setState({
                nomineeRelationShipCodeList: responseData,
            })
        } else if (apiType === "sourceOfFund") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasourceOfFund", responseData);
            this.findByVarNameApiData("sourceOfFund", responseData);
            this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", responseData)
            //  this.findByVarNameApiData("howTheSourcesOfFundHaveBeenVerified", responseData)

        } else if (apiType === "constitution") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataconstitution", responseData);
            this.findByVarNameApiData("misconstitution", responseData);
            this.setState({
                constitutionList: responseData
            })

        } else if (apiType === "monthlyIncome") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatamonthlyIncome", responseData);
            this.findByVarNameApiData("customerMonthlyIncome", responseData)


        } else if (apiType === "accountOpen") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataaccountOpen", responseData);
            this.findByVarNameApiData("howWasAccountOpened", responseData)
            // this.findByVarNameApiData("howTheSourcesOfFundHaveBeenVerified", responseData)


        } else if (apiType === "designationCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatadesignationCode", responseData);
            this.findByVarNameApiData("relatedCbTaggingdesignationCode", responseData)

        } else if (apiType === "tradeService") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatatradeService", responseData);
            this.findByVarNameApiData("misavailedTradeServices", responseData);
            this.setState({
                tradeServiceList: responseData
            })

        } else if (apiType === "modOfOperation") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatamodOfOperation", responseData);
            this.findByVarNameApiData("schemeCodeChangemodeOfOperation", responseData);
            this.setState({
                despatchmodeList: responseData
            })

        } else if (apiType === "introducerStatus") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataintroducerStatus", responseData);
            this.findByVarNameApiData("primryiintroducerStatus", responseData);
            this.setState({
                introducerStatus: responseData
            })
        } else if (apiType === "bbkSegmentCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatabbkSegmentCode", responseData);
            this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", responseData);
            this.setState({
                getfreecode7: responseData
            })

        } else if (apiType === "priorityCenterCode") {

            //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", responseData)
        } else if (apiType === "contactType") {
            //this.findByVarNameApiData("contactNumbercontactType1", responseData)
            // this.findByVarNameApiData("contactNumbercontactType2", responseData)
            //this.findByVarNameApiData("contactNumbercontactType3", responseData)
            // this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)

        } else if (apiType === "addressType") {

            // this.findByVarNameApiData("workAddressaddressType", responseData)
            // this.findByVarNameApiData("maillingAdressaddrssType", responseData)
            // this.findByVarNameApiData("maillingAdresspreferredAddressType", responseData)
            // this.findByVarNameApiData("homeAddressaddrssType", responseData)
            //this.findByVarNameApiData("presentAddressaddrssType", responseData)
            //this.findByVarNameApiData("nreAddressaddrssType", responseData)

        } else if (apiType === "guardianCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataguardianCode", responseData);
            this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", responseData);


            this.setState({
                getGuardianCode: responseData
            })

        } else if (apiType === "accountStatement") {
            //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
            //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
        } else if (apiType === "subSegment") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasubSegment", responseData);
            this.findByVarNameApiData("crmMissubSegment", responseData);
            this.setState({
                subSegmentList: responseData
            })

        } else if (apiType === "subSectorCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasubSectorCode", responseData);
            this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", responseData);
            this.findByVarNameApiData("introducersubSectorCode", responseData);
            this.setState({
                subsectorCodeList: responseData
            })
        } else if (apiType === "sectorCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasectorCode", responseData);
            this.findByVarNameApiData("fincoreDataUpdatesectorCode", responseData);
            this.findByVarNameApiData("introducersectorCode", responseData);
            this.setState({
                sectorCodeList: responseData
            })
        } else if (apiType === "depositCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatadepositCode", responseData);
            this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", responseData);
            this.findByVarNameApiData("introducerdepositCodeFreeCode7", responseData);
            this.setState({
                depositCodeList: responseData
            })
        } else if (apiType === "dispatchMode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatadispatchMode", responseData);
            // this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", responseData)
            this.findByVarNameApiData("fincoreDataUpdatedispatchMode", responseData);
            console.log("llllllllllll");
            console.log(responseData);
            this.setState({
                dispatchMode: responseData
            })
        } else if (apiType === "rmCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatarmCode", responseData);
            this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", responseData);
            this.setState({
                rmCodeList: responseData
            })

        } else if (apiType === "accountStatement") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataaccountStatement", responseData);
            this.findByVarNameApiData("aof1statement", responseData)
            //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", responseData)
        } else if (apiType === "statementFrequency") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatastatementFrequency", responseData);
            this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", responseData);
            this.findByVarNameApiData("aof1statement", responseData)
        } else if (apiType === "chequebookWaiverFunctionCode") {
            // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", responseData)
            // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", responseData)
        } else if (apiType === "chequebookWaiverType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatachequebookWaiverType", responseData);
            this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", responseData);
            this.setState({
                chequebookWaiverType: responseData
            })
        } else if (apiType === "branch") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatabranch", responseData);
            this.findByVarNameApiData("otherBankbranchName", responseData)

        } else if (apiType === "relationCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatarelationCode", responseData);
            this.findByVarNameApiData("relatedCbTaggingrelationCode", responseData);
            this.setState({
                relationCodeList: responseData
            })
        } else if (apiType === "customerStatus") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacustomerStatus", responseData);
            //this.findByVarNameApiData("relatedCbTaggingrelationCode", responseData)
            this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", responseData);
            this.setState({
                customerStatusList: responseData
            })
        } else if (apiType === "addressFormat") {
            // this.findByVarNameApiData("maillingAdressaddressFormat", responseData)
            //this.findByVarNameApiData("homeAddressaddressFormat", responseData)
            // this.findByVarNameApiData("workAddressaddressFormat", responseData)
            //this.findByVarNameApiData("presentAddressaddressFormat", responseData)
            //this.findByVarNameApiData("nreAddressaddressFormat", responseData)
            // this.findByVarNameApiData("mailingAddressaddressFormat1", responseData)
            // this.findByVarNameApiData("homeAddressaddressFormat1", responseData)
            // this.findByVarNameApiData("workAddressaddressFormat1", responseData)
            // this.findByVarNameApiData("workAddressaddressFormat2", responseData)

        } else if (apiType === "agentBankingOutlet") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataagentBankingOutlet", responseData);
            this.findByVarNameApiData("misagentBankingOutletFreeCode1", responseData);
            this.setState({
                agentBankingOutletList: responseData
            })
        } else if (apiType === "waiverField") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatawaiverField", responseData);
            this.findByVarNameApiData("miswaiverFieldFreeCode10", responseData);
            this.setState({
                waiverFieldList: responseData
            })
            // this.findByVarNameApiData("misdebitCardIssueFreeCode10", responseData)

        } else if (apiType === "ccepCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataccepCode", responseData);
            this.findByVarNameApiData("misccepCodeFreeCode9", responseData);
            this.setState({
                ccepCodeList: responseData
            })

        } else if (apiType === "nonResident") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatanonResident", responseData);
            this.findByVarNameApiData("chairmanCertificatenonResident", responseData);
            //this.findByVarNameApiData("nonResidentCustomerRisk", responseData);
            this.setState({
                getnonResident: responseData
            })
        } else if (apiType === "agentBankingOutlet") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataagentBankingOutlet", responseData);
            this.findByVarNameApiData("misagentBankingOutletFreeCode1", responseData);
            this.setState({
                agentBankingOutletList: responseData
            })
        } else if (apiType === "maritalStatus") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatamaritalStatus", responseData);
            this.findByVarNameApiData("basicInformationUpdatemaritalStatus", responseData);
            this.findByVarNameApiData("basicInformationmaritalStatus", responseData);
            this.setState({
                maritalStatusList: responseData
            })

        } else if (apiType === "employmentType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataemploymentType", responseData);
            this.findByVarNameApiData("employerDataprofessionEmploymentType", responseData);
            this.findByVarNameApiData("etinprofessionEmploymentType", responseData);
            this.findByVarNameApiData("employerDataemploymentType", responseData);
            this.setState({
                employmentTypeList: responseData
            })
        } else if (apiType === "relationship") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatarelationship", responseData);
            this.findByVarNameApiData("guardianDetailsrelation", responseData);

            this.setState({
                relationshipList: responseData
            })
        } else if (apiType === "relationType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatarelationType", responseData);
            this.findByVarNameApiData("relatedCbTaggingrelationType", responseData)

        } else if (apiType === "contactType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacontactType", responseData);
            // this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)
            /*  this.findByVarNameApiData("contactNumbercontactType1", responseData)
              this.findByVarNameApiData("contactNumbercontactType", responseData)
              this.findByVarNameApiData("contactNumbercontactType2", responseData)
              this.findByVarNameApiData("contactNumbercontactType3", responseData)
              this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)
              this.findByVarNameApiData("contactNumberpreferredContactNoType1", responseData)*/


        } else if (apiType === "title") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatatitle", responseData);
            this.findByVarNameApiData("mistitle", responseData);
            this.findByVarNameApiData("relatedCbTaggingtitle", responseData);
            this.findByVarNameApiData("aCTitle", responseData);
            this.setState({
                titleList: responseData
            })


        } else if (apiType === "documentCode") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataphotoIddocumentCode", responseData);
            // this.findByVarNameApiData("natioanlIdCarddocumentCodeN", responseData)
            //  this.findByVarNameApiData("passportNumberdocumentCodeP", responseData)
            // this.findByVarNameApiData("birthCertificatedocumentCodeB", responseData)
            // this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", responseData)
            //  this.findByVarNameApiData("smartIdCarddocumentCodeS", responseData)
            // this.findByVarNameApiData("nationalIdCarddocumentCode", responseData)
            //  this.findByVarNameApiData("smartlIdCarddocumentCode", responseData)
            //  this.findByVarNameApiData("passportNumberdocumentCode", responseData)
            //  this.findByVarNameApiData("birthCertificatedocumentCode", responseData)
            //  this.findByVarNameApiData("chairmanCertificatedocumentCode", responseData)
            //  this.findByVarNameApiData("etindocumentCode", responseData)
            this.findByVarNameApiData("photoIddocumentCode", responseData)

        } else if (apiType === "bankName") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatabankName", responseData);
            this.findByVarNameApiData("otherBankbankName", responseData)


        } else if (apiType === "segment") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatasegment", responseData);
            this.findByVarNameApiData("crmMissegment", responseData);

            this.setState({
                segmentList: responseData
            })
        } else if (apiType === "phoneOrEmailId") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataphoneOrEmailId", responseData);
            this.findByVarNameApiData("contactNumberphoneNoEmailId1", responseData);
            this.findByVarNameApiData("contactNumberphoneNoEmailId2", responseData);
            this.findByVarNameApiData("contactNumberphoneNoEmailId3", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", responseData);
            this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", responseData);
            this.setState({
                phoneOrEmail: responseData
            })
        } else if (apiType === "customerOccupation") {

            // this.findByVarNameApiData("customersOccupation", responseData)

        } else if (apiType === "bankRelationType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatabankRelationType", responseData);
            this.findByVarNameApiData("guardianDetailsbankRelationType", responseData);
            this.setState({
                bankRelationTypeList: responseData
            })

        } else if (apiType === "emailType") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDataemailType", responseData);
            this.findByVarNameApiData("contactNumberEmailemailType1", responseData);
            this.findByVarNameApiData("contactNumberEmailemailType2", responseData);
            this.findByVarNameApiData("contactNumberEmailemailType", responseData);
            this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", responseData);
            this.setState({
                phoneEmailType: responseData
            })
        } else if (apiType === "addressVariety") {

            //this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", responseData)

        } else if (apiType === "customerBusiness") {
            //LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterDatacustomerBusiness", responseData);
            this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", responseData)

        }
        else  if (apiType === "23") {
            this.findByVarNameKycMasterDataApiData("selectFromTheList", responseData);
            this.findByVarNameKycMasterDataApiData("listBusinessService", responseData);
            this.findByVarNameKycMasterDataApiData("listBusinessService1", responseData);
            this.findByVarNameKycMasterDataApiData("listBusinessService2", responseData);
        } else if (apiType === "8") {
            this.findByVarNameKycMasterDataApiData("passportCopyReceived", responseData);
            this.findByVarNameKycMasterDataApiData("nationalIdCardNoCopyReceived", responseData);
            this.findByVarNameKycMasterDataApiData("birthCertificateNoCopyReceived", responseData);
            this.findByVarNameKycMasterDataApiData("electronicTaxIdCopyReceived", responseData);
            this.findByVarNameKycMasterDataApiData("otherDocumentationCopyReceived", responseData);
        }
        else if (apiType === "9") {
            this.findByVarNameKycMasterDataApiData("passportVerified", responseData);
            this.findByVarNameKycMasterDataApiData("nationalIdCardNoVerified", responseData);
            this.findByVarNameKycMasterDataApiData("birthCertificateNoVerified", responseData);
            this.findByVarNameKycMasterDataApiData("electronicTaxIdVerified", responseData);
            this.findByVarNameKycMasterDataApiData("otherDocumentationVerified", responseData);
        }
        else if (apiType === "1" || apiType === "2" || apiType === "3" || apiType === "4" || apiType === "5" || apiType === "6" || apiType === "7" || apiType === "10" || apiType === "11" || apiType === "12" || apiType === "13" || apiType === "14" || apiType === "15" || apiType === "16" || apiType === "17" || apiType === "18" || apiType === "19" || apiType === "20" || apiType === "21" || apiType === "22") {
            this.findByVarNameKycMasterDataApiData(fieldName, responseData);
        }


    };

    dynamicApiCall = (fieldName, apiType) => {
        let getLocalstorageData=LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterData"+apiType);
        if (apiType === "city" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "state" && getLocalstorageData!==undefined && getLocalstorageData!==null) {

            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }


        else if (apiType === "country" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "currency" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else if (apiType === "phoneCountryCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "phoneCountryCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "savingsSchemeCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "currentSchemeCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "placeOfIssue" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "despatchMode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "nomineeRelationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "sourceOfFund" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "constitution" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "monthlyIncome" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "accountOpen" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "designationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "tradeService" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "modOfOperation" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "introducerStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bbkSegmentCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "guardianCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "subSegment" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "subSectorCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "sectorCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "depositCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "dispatchMode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "rmCode" &&getLocalstorageData!==undefined &&getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterDatarmCode"));
        }
        else if (apiType === "accountStatement" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "statementFrequency" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "chequebookWaiverType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "branch" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "agentBankingOutlet" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "waiverField" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "ccepCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "nonResident" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "agentBankingOutlet" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "maritalStatus" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "employmentType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationship" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "relationType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "contactType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "title" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "documentCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bankName" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else if (apiType === "segment" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "phoneOrEmailId" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "bankRelationType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "emailType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerBusiness" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "priorityCenterCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressType" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "accountStatement" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "chequebookWaiverFunctionCode" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressFormat" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "customerOccupation" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (apiType === "addressVariety" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }

        else {
            let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(fieldName);
                    console.log(response);
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterData" + apiType, response.data);
                    if (apiType === "city") {
                        this.findByVarNameApiData("maillingAdresscity", response.data);
                        this.findByVarNameApiData("homeAddresscity", response.data);
                        this.findByVarNameApiData("workAddresscity", response.data);
                        this.findByVarNameApiData("presentAddresscity", response.data);
                        this.findByVarNameApiData("nreAddresscity", response.data);
                        this.findByVarNameApiData("mailingAddresscity1", response.data);
                        this.findByVarNameApiData("homeAddresscity1", response.data);
                        this.findByVarNameApiData("workAddresscity1", response.data);
                        this.findByVarNameApiData("workAddresscity2", response.data);


                        this.findByVarNameApiData("mailingAddresscity", response.data);
                        this.findByVarNameApiData("companyRegisteredcity", response.data);
                        this.findByVarNameApiData("factoryAddresscity", response.data);
                        this.findByVarNameApiData("officeAddresscity", response.data);
                        this.findByVarNameApiData("homeAddressaddresscity", response.data);
                        this.findByVarNameApiData("workAddresscity", response.data);
                        this.findByVarNameApiData("presentAddresscity", response.data);
                        this.findByVarNameApiData("nreAddresscity", response.data);


                        this.setState({
                            city: response.data,
                            getCity: true
                        })

                    } else if (apiType === "state") {

                        this.findByVarNameApiData("maillingAdressstate", response.data);
                        this.findByVarNameApiData("homeAddressstate", response.data);
                        this.findByVarNameApiData("workAddressstate", response.data);
                        this.findByVarNameApiData("presentAddressstate", response.data);
                        this.findByVarNameApiData("nreAddressstate", response.data);
                        this.findByVarNameApiData("mailingAddressstate1", response.data);
                        this.findByVarNameApiData("homeAddressstate1", response.data);
                        this.findByVarNameApiData("workAddressstate1", response.data);
                        this.findByVarNameApiData("workAddressstate2", response.data);


                        this.findByVarNameApiData("mailingAddressstate", response.data);
                        this.findByVarNameApiData("companyRegisteredstate", response.data);
                        this.findByVarNameApiData("factoryAddressstate", response.data);
                        this.findByVarNameApiData("officeAddressstate", response.data);
                        this.findByVarNameApiData("mailingAddressstate", response.data);
                        this.findByVarNameApiData("homeAddressaddressstate", response.data);
                        this.findByVarNameApiData("workAddressstate", response.data);
                        this.findByVarNameApiData("presentAddressstate", response.data);
                        this.findByVarNameApiData("nreAddressstate", response.data);


                        this.setState({
                            stateCode: response.data,
                            getState: true,
                        })
                    } else if (apiType === "country") {
                        this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("maillingAdresscountry", response.data);
                        this.findByVarNameApiData("homeAddresscountry", response.data);
                        this.findByVarNameApiData("workAddresscountry", response.data);
                        this.findByVarNameApiData("Customersnationality", response.data);
                        this.findByVarNameApiData("presentAddresscountry", response.data);
                        this.findByVarNameApiData("nreAddresscountry", response.data);
                        this.findByVarNameApiData("mailingAddresscountry1", response.data);
                        this.findByVarNameApiData("homeAddresscountry1", response.data);
                        this.findByVarNameApiData("workAddresscountry1", response.data);
                        this.findByVarNameApiData("workAddresscountry2", response.data);
                        //this.findByVarNameApiData("passportNumberplaceOfIssue", response.data)


                        this.findByVarNameApiData("mailingAddresscountry", response.data);
                        this.findByVarNameApiData("companyRegisteredcountry", response.data);
                        this.findByVarNameApiData("factoryAddresscountry", response.data);
                        this.findByVarNameApiData("officeAddresscountry", response.data);
                        this.findByVarNameApiData("mailingAddresscountry", response.data);
                        this.findByVarNameApiData("homeAddressaddresscountry", response.data);
                        this.findByVarNameApiData("workAddresscountry", response.data);
                        this.findByVarNameApiData("presentAddresscountry", response.data);
                        this.findByVarNameApiData("nreAddresscountry", response.data);
                        this.findByVarNameApiData("residenceCertificatenationality", response.data);
                        this.findByVarNameApiData("registrationNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("certificateOfIncorporationcountryOfIssue", response.data);
                        this.findByVarNameApiData("certificateOfCommencementcountryOfIssue", response.data);
                        this.findByVarNameApiData("ngoBureauCertificatecountryOfIssue", response.data);
                        this.findByVarNameApiData("societyRegistrationNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("clubRegistrationNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("governmentPermissionLettercountryOfIssue", response.data);
                        this.findByVarNameApiData("otherIdCorporatecountryOfIssue", response.data);
                        this.findByVarNameApiData("registrationNumbercountryOfIssue", response.data);
                        this.findByVarNameApiData("tradeLicensecountryOfIssue", response.data);


                        this.setState({
                            country: response.data,
                            getCountry: true
                        })
                    } else if (apiType === "currency") {

                        this.findByVarNameApiData("workAddressCurrency", response.data);
                        this.findByVarNameApiData("homeAddressCurrency", response.data);
                        //this.findByVarNameApiData("aof1currencyCodeCcy", response.data)
                        this.setState({
                            currency: response.data,
                            getCurrency: true
                        })
                    } else if (apiType === "phoneCountryCode") {

                        this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                        this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                        this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                        this.setState({
                            phoneNumCountryCodeList: response.data
                        })
                    } /*else if (apiType === "customerStatus") {
                    this.setState({
                        customerStatusList:response.data,
                    })
                }*/ else if (apiType === "savingsSchemeCode") {

                        this.setState({
                            savingsSchemeCodeList: response.data,
                        })
                    } else if (apiType === "currentSchemeCode") {

                        this.setState({
                            currentSchemeCodeList: response.data,
                        })
                    } else if (apiType === "placeOfIssue") {
                        this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                        this.findByVarNameApiData("registrationNumberplaceOfIssue", response.data);
                        this.findByVarNameApiData("certificateOfIncorporationplaceOfIssue", response.data);
                        this.findByVarNameApiData("certificateOfCommencementplaceOfIssue", response.data);
                        this.findByVarNameApiData("ngoBureauCertificateplaceOfIssue", response.data);
                        this.findByVarNameApiData("societyRegistrationNumberplaceOfIssue", response.data);
                        this.findByVarNameApiData("clubRegistrationNumberplaceOfIssue", response.data);
                        this.findByVarNameApiData("governmentPermissionLetterplaceOfIssue", response.data);
                        this.findByVarNameApiData("otherIdCorporateplaceOfIssue", response.data);
                        this.findByVarNameApiData("registrationNumberplaceOfIssue", response.data);
                        this.findByVarNameApiData("tradeLicenseplaceOfIssue", response.data);
                        this.setState({
                            placeOfIssueList: response.data
                        })
                    } else if (apiType === "despatchMode") {
                        this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                        this.setState({
                            despatchList: response.data
                        })
                    } else if (apiType === "nomineeRelationCode") {
                        this.findByVarNameApiData("guardianDetailsrelation1", response.data);
                        this.setState({
                            nomineeRelationShipCodeList: response.data,
                        })
                    } else if (apiType === "sourceOfFund") {
                        this.findByVarNameApiData("sourceOfFund", response.data);
                        this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)
                        //  this.findByVarNameApiData("howTheSourcesOfFundHaveBeenVerified", response.data)

                    } else if (apiType === "constitution") {
                        this.findByVarNameApiData("misconstitution", response.data);
                        this.setState({
                            constitutionList: response.data
                        })

                    } else if (apiType === "monthlyIncome") {
                        this.findByVarNameApiData("customerMonthlyIncome", response.data)


                    } else if (apiType === "accountOpen") {
                        this.findByVarNameApiData("howWasAccountOpened", response.data)
                        // this.findByVarNameApiData("howTheSourcesOfFundHaveBeenVerified", response.data)


                    } else if (apiType === "designationCode") {
                        this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                    } else if (apiType === "tradeService") {
                        this.findByVarNameApiData("misavailedTradeServices", response.data);
                        this.setState({
                            tradeServiceList: response.data
                        })

                    } else if (apiType === "modOfOperation") {
                        this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                        this.setState({
                            despatchmodeList: response.data
                        })

                    } else if (apiType === "introducerStatus") {
                        this.findByVarNameApiData("primryiintroducerStatus", response.data);
                        this.setState({
                            introducerStatus: response.data
                        })
                    } else if (apiType === "bbkSegmentCode") {
                        this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                        this.setState({
                            getfreecode7: response.data
                        })

                    } else if (apiType === "priorityCenterCode") {
                        //this.findByVarNameApiData("mispriorityCenterCodeFreeCode1", response.data)
                    } else if (apiType === "contactType") {
                        //this.findByVarNameApiData("contactNumbercontactType1", response.data)
                        // this.findByVarNameApiData("contactNumbercontactType2", response.data)
                        //this.findByVarNameApiData("contactNumbercontactType3", response.data)
                        // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)

                    } else if (apiType === "addressType") {

                        // this.findByVarNameApiData("workAddressaddressType", response.data)
                        // this.findByVarNameApiData("maillingAdressaddrssType", response.data)
                        // this.findByVarNameApiData("maillingAdresspreferredAddressType", response.data)
                        // this.findByVarNameApiData("homeAddressaddrssType", response.data)
                        //this.findByVarNameApiData("presentAddressaddrssType", response.data)
                        //this.findByVarNameApiData("nreAddressaddrssType", response.data)

                    } else if (apiType === "guardianCode") {
                        this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);


                        this.setState({
                            getGuardianCode: response.data
                        })

                    } else if (apiType === "accountStatement") {
                        //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                        //  this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    } else if (apiType === "subSegment") {
                        this.findByVarNameApiData("crmMissubSegment", response.data);
                        this.setState({
                            subSegmentList: response.data
                        })

                    } else if (apiType === "subSectorCode") {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                        this.findByVarNameApiData("introducersubSectorCode", response.data);
                        this.setState({
                            subsectorCodeList: response.data
                        })
                    } else if (apiType === "sectorCode") {
                        this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                        this.findByVarNameApiData("introducersectorCode", response.data);
                        this.setState({
                            sectorCodeList: response.data
                        })
                    } else if (apiType === "depositCode") {
                        this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                        this.findByVarNameApiData("introducerdepositCodeFreeCode7", response.data);
                        this.setState({
                            depositCodeList: response.data
                        })
                    } else if (apiType === "dispatchMode") {
                        // this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data)
                        this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                        console.log("llllllllllll");
                        console.log(response.data);
                        this.setState({
                            dispatchMode: response.data
                        })
                    } else if (apiType === "rmCode") {
                        this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                        this.setState({
                            rmCodeList: response.dat
                        })

                    } else if (apiType === "accountStatement") {
                        this.findByVarNameApiData("aof1statement", response.data)
                        //this.findByVarNameApiData("fincoreDataUpdateaccountStatement", response.data)
                    } else if (apiType === "statementFrequency") {
                        this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                        this.findByVarNameApiData("aof1statement", response.data)
                    } else if (apiType === "chequebookWaiverFunctionCode") {
                        // this.findByVarNameApiData("misfunctionCodeForChequeBookWaiver", response.data)
                        // this.findByVarNameApiData("schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack", response.data)
                    } else if (apiType === "chequebookWaiverType") {
                        this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                        this.setState({
                            chequebookWaiverType: response.data
                        })
                    } else if (apiType === "branch") {
                        this.findByVarNameApiData("otherBankbranchName", response.data)

                    } else if (apiType === "relationCode") {
                        this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                        this.setState({
                            relationCodeList: response.data
                        })
                    } else if (apiType === "customerStatus") {
                        //this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data)
                        this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                        this.setState({
                            customerStatusList: response.data
                        })
                    } else if (apiType === "addressFormat") {
                        // this.findByVarNameApiData("maillingAdressaddressFormat", response.data)
                        //this.findByVarNameApiData("homeAddressaddressFormat", response.data)
                        // this.findByVarNameApiData("workAddressaddressFormat", response.data)
                        //this.findByVarNameApiData("presentAddressaddressFormat", response.data)
                        //this.findByVarNameApiData("nreAddressaddressFormat", response.data)
                        // this.findByVarNameApiData("mailingAddressaddressFormat1", response.data)
                        // this.findByVarNameApiData("homeAddressaddressFormat1", response.data)
                        // this.findByVarNameApiData("workAddressaddressFormat1", response.data)
                        // this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                    } else if (apiType === "agentBankingOutlet") {
                        this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                        this.setState({
                            agentBankingOutletList: response.data
                        })
                    } else if (apiType === "waiverField") {
                        this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                        this.setState({
                            waiverFieldList: response.data
                        })
                        // this.findByVarNameApiData("misdebitCardIssueFreeCode10", response.data)

                    } else if (apiType === "ccepCode") {
                        this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                        this.setState({
                            ccepCodeList: response.data
                        })

                    } else if (apiType === "nonResident") {
                        this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                        //this.findByVarNameApiData("nonResidentCustomerRisk", response.data);
                        this.setState({
                            getnonResident: response.data
                        })
                    } else if (apiType === "agentBankingOutlet") {
                        this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                        this.setState({
                            agentBankingOutletList: response.data
                        })
                    } else if (apiType === "maritalStatus") {
                        this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                        this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                        this.setState({
                            maritalStatusList: response.data
                        })

                    } else if (apiType === "employmentType") {
                        this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                        this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                        this.findByVarNameApiData("employerDataemploymentType", response.data);
                        this.setState({
                            employmentTypeList: response.data
                        })
                    } else if (apiType === "relationship") {
                        this.findByVarNameApiData("guardianDetailsrelation", response.data);

                        this.setState({
                            relationshipList: response.data
                        })
                    } else if (apiType === "relationType") {
                        this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                    } else if (apiType === "contactType") {
                        // this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                        /*  this.findByVarNameApiData("contactNumbercontactType1", response.data)
                          this.findByVarNameApiData("contactNumbercontactType", response.data)
                          this.findByVarNameApiData("contactNumbercontactType2", response.data)
                          this.findByVarNameApiData("contactNumbercontactType3", response.data)
                          this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)
                          this.findByVarNameApiData("contactNumberpreferredContactNoType1", response.data)*/


                    } else if (apiType === "title") {
                        this.findByVarNameApiData("mistitle", response.data);
                        this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                        this.findByVarNameApiData("aCTitle", response.data);
                        this.setState({
                            titleList: response.data
                        })


                    } else if (apiType === "documentCode") {
                        // this.findByVarNameApiData("natioanlIdCarddocumentCodeN", response.data)
                        //  this.findByVarNameApiData("passportNumberdocumentCodeP", response.data)
                        // this.findByVarNameApiData("birthCertificatedocumentCodeB", response.data)
                        // this.findByVarNameApiData("chairmanCertificatedocumentCodeCC", response.data)
                        //  this.findByVarNameApiData("smartIdCarddocumentCodeS", response.data)
                        // this.findByVarNameApiData("nationalIdCarddocumentCode", response.data)
                        //  this.findByVarNameApiData("smartlIdCarddocumentCode", response.data)
                        //  this.findByVarNameApiData("passportNumberdocumentCode", response.data)
                        //  this.findByVarNameApiData("birthCertificatedocumentCode", response.data)
                        //  this.findByVarNameApiData("chairmanCertificatedocumentCode", response.data)
                        //  this.findByVarNameApiData("etindocumentCode", response.data)
                        this.findByVarNameApiData("photoIddocumentCode", response.data)

                    } else if (apiType === "bankName") {
                        this.findByVarNameApiData("otherBankbankName", response.data)


                    } else if (apiType === "segment") {
                        this.findByVarNameApiData("crmMissegment", response.data);

                        this.setState({
                            segmentList: response.data
                        })
                    } else if (apiType === "phoneOrEmailId") {
                        this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                        this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                        this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                        this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                        this.setState({
                            phoneOrEmail: response.data
                        })
                    } else if (apiType === "customerOccupation") {

                        // this.findByVarNameApiData("customersOccupation", response.data)

                    } else if (apiType === "bankRelationType") {

                        this.findByVarNameApiData("guardianDetailsbankRelationType", response.data);
                        this.setState({
                            bankRelationTypeList: response.data
                        })

                    } else if (apiType === "emailType") {

                        this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                        this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                        this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                        this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                        this.setState({
                            phoneEmailType: response.data
                        })
                    } else if (apiType === "addressVariety") {

                        //this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                    } else if (apiType === "customerBusiness") {

                        this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                    }
                    /*  else if (apiType === "accountOpen") {

                          this.findByVarNameApiData("", response.data)

                      }
                      else if (apiType === "monthlyIncome") {

                          this.findByVarNameApiData("", response.data)

                      }*/


                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };
    dynamicKycMasterDataApiCall = (fieldName, apiType, code) => {
        let getLocalstorageData=LocalstorageEncrypt.encryptStorageFunction.getItem("getMasterData"+code)
        if (code === "1" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "2" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "3" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "4" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "5" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "6" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "7" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "10" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "11" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "12" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "13" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "14" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "15" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "16" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "17" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "18" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "19" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "20" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "21" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "22" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "23" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(code,fieldName,getLocalstorageData);
        }
        else if (code === "8" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else if (code === "9" && getLocalstorageData!==undefined && getLocalstorageData!==null) {
            this.dynamicApiDataSet(apiType,fieldName,getLocalstorageData);
        }
        else {
            let customerTypeurl = backEndServerURL + "/finMasterData/kycIndividual/customerType/" + code;
            axios.get(customerTypeurl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getMasterData"+code,response.data)
                    if (code === "23") {
                        this.findByVarNameKycMasterDataApiData("selectFromTheList", response.data);
                        this.findByVarNameKycMasterDataApiData("listBusinessService", response.data);
                        this.findByVarNameKycMasterDataApiData("listBusinessService1", response.data);
                        this.findByVarNameKycMasterDataApiData("listBusinessService2", response.data);
                    } else if (code === "8") {
                        this.findByVarNameKycMasterDataApiData("passportCopyReceived", response.data);
                        this.findByVarNameKycMasterDataApiData("nationalIdCardNoCopyReceived", response.data);
                        this.findByVarNameKycMasterDataApiData("birthCertificateNoCopyReceived", response.data);
                        this.findByVarNameKycMasterDataApiData("electronicTaxIdCopyReceived", response.data);
                        this.findByVarNameKycMasterDataApiData("otherDocumentationCopyReceived", response.data);
                    } else if (code === "9") {
                        this.findByVarNameKycMasterDataApiData("passportVerified", response.data);
                        this.findByVarNameKycMasterDataApiData("nationalIdCardNoVerified", response.data);
                        this.findByVarNameKycMasterDataApiData("birthCertificateNoVerified", response.data);
                        this.findByVarNameKycMasterDataApiData("electronicTaxIdVerified", response.data);
                        this.findByVarNameKycMasterDataApiData("otherDocumentationVerified", response.data);
                    } else {
                        this.findByVarNameKycMasterDataApiData(fieldName, response.data);

                    }


                })
                .catch((error) => {
                    console.log(error)
                });
        }

    }
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);

        if (value !== undefined && value !== null && Array.isArray(jsonArray) && jsonArray.length > 0) {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                console.log(jsonObject);
                if (value === jsonObject.key) {
                    return jsonObject;
                    i = jsonArray.length;

                }
            }
        }


    };
    accountOtherInfo = (data, inputData) => {
        inputData.fincoreDataUpdatestatementFrequency2 = data.statementStartDay ? data.statementStartDay : null;
        inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.tradeServiceAvailed, this.state.tradeServiceList);
        inputData.mispepStatusFreeText10 = data.pepStatus ? data.pepStatus : null;
        inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.isSmartCardVerified ? data.isSmartCardVerified : null;
        inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.passportPlaceOfIssue, this.state.placeOfIssueList);
        switch (data.statementFrequency) {
            case "H":
                inputData.fincoreDataUpdatestatementFrequency1 = "Half Yearly";
                break;
            case "Y":
                inputData.fincoreDataUpdatestatementFrequency1 = "Yearly";
                break;
            case "Q":
                inputData.fincoreDataUpdatestatementFrequency1 = "Quarterly";
                break;
            case "M":
                inputData.fincoreDataUpdatestatementFrequency1 = "Monthly";
                break;
            default:
                inputData.fincoreDataUpdatestatementFrequency1 = null;
        }

        switch (data.statementHolidayStat) {
            case "N":
                inputData.fincoreDataUpdatestatementFrequency3 = "Next Day";
                break;
            case "H":
                inputData.fincoreDataUpdatestatementFrequency3 = "Holiday";
                break;
            case "P":
                inputData.fincoreDataUpdatestatementFrequency3 = "Previous Day";
                break;
            case "S":
                inputData.fincoreDataUpdatestatementFrequency3 = "Skip";
                break;
            default:
                inputData.fincoreDataUpdatestatementFrequency3 = null;
        }

        return inputData;
    };
    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {

            if (accountRelated === "accountRelated") {

                inputData.misagentBankingOutletFreeCode1 = this.findByVarNameGetKeyValue(data.agentbankingoutlet, this.state.agentBankingOutletList); //acco

                inputData.upDateSchemeCode = data.schemeCode;
                inputData.fincoreDataUpdatecashLimitDr = data.drcashlim;
                inputData.functionCode = "ADD";
                inputData.TPcustomerType = {
                    "key": "I",
                    "value": "I - Individual"
                };
                inputData.TpEntityType = "INDIVIDUL";
                inputData.fincoreDataUpdatecashLimitCr = data.crcashlim;
                inputData.fincoreDataUpdateclearingLimitDr = data.drclrlim;
                inputData.fincoreDataUpdateclearingLimitCr = data.crclrlim;
                inputData.fincoreDataUpdatetransferLimitDr = data.drtxlim;
                inputData.fincoreDataUpdatetransferLimitCr = data.crtxlim;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.miscustomerIdCifId = data.cbNumber;
                inputData.misaCNumber = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;


                // inputData.fincoreDataUpdateaccountStatement=data. ;
                // inputData.fincoreDataUpdatestatementFrequency=data. ;
                inputData.fincoreDataUpdatedispatchMode = data.despatchmode;
                inputData.fincoreDataUpdatenextPrintDate = data.acctStmtNxtPrintDt;
                // inputData.fincoreDataUpdateaCStatus=data. ;
                inputData.fincoreDataUpdatedormantActivationCharge = data.dormchrg;
                // inputData.fincoreDataUpdatenomineeSelect=data. ;
                // inputData.fincoreDataUpdateaCInformation=data. ;
                inputData.fincoreDataUpdatesectorCode = this.findByVarNameGetKeyValue(data.sectorcode, this.state.sectorCodeList);
                inputData.fincoreDataUpdatesubSectorCode = this.findByVarNameGetKeyValue(data.subsectorcode, this.state.subsectorCodeList);

                inputData.sectorCodeApicall = true;
                inputData.fincoreDataUpdateoccupationCode = data.occupationcode;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = data.rmcode;
                /* if(data.rmcode!==undefined && data.rmcode!==null){
                     inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall=true
                 }*/
                //inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false
                // inputData.fincoreDataUpdatefreeText3=data. ;
                // inputData.fincoreDataUpdatefreeCode6=data. ;
                inputData.fincoreDataUpdatedepositCodeFreeCode7 = this.findByVarNameGetKeyValue(data.depositcode, this.state.depositCodeList);
                // inputData.fincoreDataUpdatefreeCode8=data. ;
                inputData.miswaiverFieldFreeCode10 = this.findByVarNameGetKeyValue(data.waiverfield, this.state.waiverFieldList);

                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.aof1smsAlertFreeText11 = data.smsalert;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(data.modeOfOper, this.state.despatchmodeList);
                //kyc
                inputData.customerAccount = data.accountNumber;

                inputData.aCTitle = data.accountName;
                inputData.missolId = data.solid;
                inputData.accountType = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.TpCustomerAccount = data.accountNumber;
                inputData.TpAccountName = data.accountName;
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.TpAccountType = "SAVINGS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.savingsSchemeCodeList)

                } else {
                    inputData.accountType = "CURRENTS";
                    inputData.TpAccountType = "CURRENTS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.currentSchemeCodeList)
                }

                inputData.TpAccountNumber = data.accountNumber;
                if (data.acctStmtMode === "S") {
                    inputData.fincoreDataUpdateaccountStatement = "S-STATEMENT";

                } else {
                    inputData.fincoreDataUpdateaccountStatement = "NONE"

                }

                inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                /*   if (data.despatchmode==="E") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "Email only",
                          "value": "Email only"
                      }

                  } else if (getInputData.statementFacility === "E-Statement") {


                  }
              else if (getInputData.statementFacility === "NO") {
                      inputData.aof1despatchModePrintedEStatementBoth = {
                          "masterDataName": "dispatchMode",
                          "key": "No Despatch",
                          "value": "No Despatch"
                      }

                  }*/

            } else if (accountRelated === "waiverType") {
                inputData.miswaiverTypeForChequeBookWaiver = this.findByVarNameGetKeyValue(data, this.state.chequebookWaiverType)

            } else if (accountRelated === "cbExtraInformation") {
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.introducercifType = data.intrd_cif_type;
                //remove
               // inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.freecode7, this.state.getfreecode7);              //
                //remove
                //inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            } else if (accountRelated === "cbRelated") {
                inputData.mispriorityCenterCodeFreeCode1 = data.priorityCenterCode
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.bBKSegCode, this.state.getfreecode7);
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misoccupationCodePriorityCodeStaff = data.occupationCode;
                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.miscorporateName = data.customerName;//ok
                inputData.misshortName = data.shortName;//ok
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissegment = this.findByVarNameGetKeyValue(data.segment, this.state.segmentList);
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                // inputData.misfunctionCodeForChequeBookWaiver = "A"
                inputData.aof1currencyCodeCcy = data.currency;
                 inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 500)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

                if (data.GUARDIANBLOCK !== undefined) {
                    inputData.guardianDetailsrelation = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationshipCategory, this.state.relationshipList);
                    inputData.guardianDetailsbankRelationType = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.parentEntity, this.state.bankRelationTypeList);
                    inputData.guardianDetailsrelation1 = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.relationShip, this.state.nomineeRelationShipCodeList);
                    inputData.guardianDetailsguardianSelectionGurdian = this.findByVarNameGetKeyValue(data.GUARDIANBLOCK.guardCode, this.state.getGuardianCode);
                    inputData.guardianDetailsgurdianCifNumberCustomer = data.GUARDIANBLOCK.parentCIFID;
                    // inputData.guardianDetailslastName = data.GUARDIANBLOCK.parentCIFID;
                    //inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";

                }

                //delete flag start
                inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                inputData.natioanlIdCardDelete = "N";
                inputData.smartIdCardDelete = "N";
                inputData.passportNumberDelete = "N";
                inputData.drivingLicenseNumberDelete = "N";
                inputData.residentNumberDelete = "N";
                inputData.birthCertificateDelete = "N";
                inputData.chairmanCertificateDelete = "N";
                inputData.photoIdDelete = "N";
                inputData.etinDelete = "N";
                inputData.contactNumber2Delete = "N";
                inputData.contactNumber3Delete = "N";
                inputData.contactNumberEmailemailId2Delete = "N";
                inputData.homeAddressDelete = "N";
                inputData.workAddressDelete = "N";
                inputData.nreAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                inputData.presentAddressDelete = "N";
                //delete flag End

                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.natioanlIdCarddocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "NID",
                    "value": "NID CARD IDENTITY"
                };
                //Passport
                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentCodeP = {
                    "masterDataName": "documentCode",
                    "key": "PASSPORT",
                    "value": "PASSPORT IDENTITY"
                };

                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentCodeS = {
                    "masterDataName": "documentCode",
                    "key": "RSNID",
                    "value": "RSNID"
                };

                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentCodeB = {
                    "masterDataName": "documentCode",
                    "key": "BC",
                    "value": "BIRTH CERTIFICATE"
                };
                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentCodeCC = {
                    "masterDataName": "documentCode",
                    "key": "CHAC",
                    "value": "CHAIRMAN CERTIFICATE"
                };


                inputData.certificateOfCommencementdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.ngoBureauCertificatedocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.societyRegistrationNumberdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.clubRegistrationNumberdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.governmentPermissionLetterdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.otherIdCorporatedocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                //

                inputData.certificateOfCommencementdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "COB",
                    "value": "COMMENCEMENT OF BUSINESS"
                };
                inputData.ngoBureauCertificatedocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "NGO",
                    "value": "NGO BUREAU CERTIFICATE"
                };
                inputData.societyRegistrationNumberdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "SCT",
                    "value": "SOCIETY REG. NUMBER"
                };
                inputData.clubRegistrationNumberdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "CLB",
                    "value": "CLUB REG. NUMBER"
                };
                inputData.governmentPermissionLetterdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "GOVP",
                    "value": "GOVT. PERMISSION LETTER"
                };
                inputData.otherIdCorporatedocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "OTHC",
                    "value": "OTHER ID CORPORATE"
                };
                //
                inputData.etindocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "ETIN",
                    "value": "TIN CARD IDENTITY"
                };
                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumbercontactType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };
                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType2 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH2",
                    "value": "COMMPH2"
                };
                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumbercontactType3 = {
                    "masterDataName": "contactType",
                    "key": "WORKPH1",
                    "value": "WORKPH1"
                };
                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                //inputData.contactNumberphoneNo3=data. ;

                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                /*  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };*/
                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.homeAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Home",
                    "value": "Home"
                };
                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.workAddressaddressType = {
                    "masterDataName": "addressType",
                    "key": "Work",
                    "value": "Work"
                };
                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };


                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.companyRegisteredaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.factoryAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };

                inputData.officeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };


                inputData.presentAddressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Future/OnSite",
                    "value": "Future/OnSite"
                };
                inputData.tradeLicensedocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.tradeLicensedocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "TL",
                    "value": "TRADE LICENSE"
                };
                inputData.certificateOfIncorporationdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.certificateOfIncorporationdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "COI",
                    "value": "CERTIFICATE OF INCORPORATION"
                };
                inputData.registrationNumberdocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.registrationNumberdocumentCode = {
                    "masterDataName": "documentCode",
                    "key": "REG",
                    "value": "REGISTRATION NUMBER"
                };
                inputData.companyRegisteredaddressType = {
                    "masterDataName": "Registered",
                    "key": "Registered",
                    "value": "Registered"
                };
                if (data.RegisteredBLOCK !== undefined) {
                    inputData.companyRegisteredaddressLabel = {
                        "masterDataName": "Registered",
                        "key": "Registered",
                        "value": "Registered"
                    };
                }
                inputData.factoryAddressaddressType = {
                    "masterDataName": "Head Office",
                    "key": "Head Office",
                    "value": "Head Office"
                };
                if (data.HeadOfficeBLOCK !== undefined) {
                    inputData.factoryAddressaddressLabel = {
                        "masterDataName": "Head Office",
                        "key": "Head Office",
                        "value": "Head Office"
                    };
                }

                inputData.officeAddressaddressType = {
                    "masterDataName": "Alt Lang Add",
                    "key": "Alt Lang Add",
                    "value": "Alt Lang Add"
                };
                if (data.AltLangAddBLOCK !== undefined) {
                    inputData.officeAddressaddressLabel = {
                        "masterDataName": "Alt Lang Add",
                        "key": "Alt Lang Add",
                        "value": "Alt Lang Add"
                    };
                }

                if (data.RegisteredBLOCK !== undefined) {
                    inputData.companyRegisteredaddressField1 = data.RegisteredBLOCK.addrLine1;
                    inputData.companyRegisteredaddressField2 = data.RegisteredBLOCK.addrLine2;
                    inputData.companyRegisteredcity = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.city, this.state.city);
                    inputData.companyRegisteredstate = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.state, this.state.stateCode);
                    inputData.companyRegisteredpostalCode = data.RegisteredBLOCK.postalCode;
                    inputData.companyRegisteredcountry = this.findByVarNameGetKeyValue(data.RegisteredBLOCK.country, this.state.country);
                }

                if (data.HeadOfficeBLOCK !== undefined) {
                    inputData.factoryAddressaddressField1 = data.HeadOfficeBLOCK.addrLine1;
                    inputData.factoryAddressaddressField2 = data.HeadOfficeBLOCK.addrLine2;
                    inputData.factoryAddresscity = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.city, this.state.city);
                    inputData.factoryAddressstate = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.state, this.state.stateCode);
                    inputData.factoryAddresspostalCode = data.HeadOfficeBLOCK.postalCode;
                    inputData.officeAddresscountry = this.findByVarNameGetKeyValue(data.HeadOfficeBLOCK.country, this.state.country);
                }
                if (data.AltLangAddBLOCK !== undefined) {
                    inputData.officeAddressaddressField1 = data.AltLangAddBLOCK.addrLine1;
                    inputData.officeAddressaddressField2 = data.AltLangAddBLOCK.addrLine2;
                    inputData.officeAddresscity = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.city, this.state.city);
                    inputData.officeAddressstate = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.state, this.state.stateCode);
                    inputData.officeAddresspostalCode = data.AltLangAddBLOCK.postalCode;
                    inputData.officeAddresscountry = this.findByVarNameGetKeyValue(data.AltLangAddBLOCK.country, this.state.country);
                }
                if (data.COIBLOCK !== undefined) {
                    inputData.certificateOfIncorporationcertificateOfIncorporationNumberUniqueId = data.COIBLOCK.referenceNum;
                    inputData.certificateOfIncorporationplaceOfIssue = this.findByVarNameGetKeyValue(data.COIBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.certificateOfIncorporationcountryOfIssue = this.findByVarNameGetKeyValue(data.COIBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.certificateOfIncorporationissueDate = data.COIBLOCK.issueDt;
                    //inputData.certificateOfIncorporationexpiryDate=data.COIBLOCK.issueDt;
                }

                if (data.COBBLOCK !== undefined) {
                    inputData.certificateOfCommencementcertificateOfCommencementNumberUniqueId = data.COBBLOCK.referenceNum;
                    inputData.certificateOfCommencementplaceOfIssue = this.findByVarNameGetKeyValue(data.COBBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.certificateOfCommencementcountryOfIssue = this.findByVarNameGetKeyValue(data.COBBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.certificateOfCommencementissueDate = data.COBBLOCK.issueDt;
                    //inputData.certificateOfCommencementexpiryDate=data.COIBLOCK.issueDt;
                }
                if (data.NGOBLOCK !== undefined) {
                    inputData.ngoBureauCertificatengoBureauCertificateNumberUniqueId = data.NGOBLOCK.referenceNum;
                    inputData.ngoBureauCertificateplaceOfIssue = this.findByVarNameGetKeyValue(data.NGOBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.ngoBureauCertificatecountryOfIssue = this.findByVarNameGetKeyValue(data.NGOBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.ngoBureauCertificateissueDate = data.NGOBLOCK.issueDt;
                    //inputData.ngoBureauCertificateexpiryDate=data.COIBLOCK.issueDt;
                }
                if (data.SCTBLOCK !== undefined) {
                    inputData.societyRegistrationNumbersocietyRegistrationNumberNumberUniqueId = data.SCTBLOCK.referenceNum;
                    inputData.societyRegistrationNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.SCTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.societyRegistrationNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.SCTBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.societyRegistrationNumberissueDate = data.SCTBLOCK.issueDt;
                    //inputData.societyRegistrationNumberexpiryDate=data.SCTBLOCK.issueDt;
                }
                if (data.CLBBLOCK !== undefined) {
                    inputData.clubRegistrationNumberclubRegistrationNumberNumberUniqueId = data.CLBBLOCK.referenceNum;
                    inputData.clubRegistrationNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.CLBBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.clubRegistrationNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.CLBBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.clubRegistrationNumberissueDate = data.CLBBLOCK.issueDt;
                    //inputData.clubRegistrationNumberexpiryDate=data.CLBBLOCK.issueDt;
                }
                if (data.GOVPBLOCK !== undefined) {
                    inputData.governmentPermissionLettergovernmentPermissionLetterNumberUniqueId = data.GOVPBLOCK.referenceNum;
                    inputData.governmentPermissionLetterplaceOfIssue = this.findByVarNameGetKeyValue(data.GOVPBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.governmentPermissionLettercountryOfIssue = this.findByVarNameGetKeyValue(data.GOVPBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.governmentPermissionLetterissueDate = data.GOVPBLOCK.issueDt;
                    //inputData.governmentPermissionLetterexpiryDate=data.GOVPBLOCK.issueDt;
                }
                if (data.OTHCBLOCK !== undefined) {
                    inputData.otherIdCorporateotherIdCorporateNumberUniqueId = data.OTHCBLOCK.referenceNum;
                    inputData.otherIdCorporateplaceOfIssue = this.findByVarNameGetKeyValue(data.OTHCBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.otherIdCorporatecountryOfIssue = this.findByVarNameGetKeyValue(data.OTHCBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.otherIdCorporateissueDate = data.OTHCBLOCK.issueDt;
                    //inputData.otherIdCorporateexpiryDate=data.OTHCBLOCK.issueDt;
                }

                if (data.REGBLOCK !== undefined) {
                    inputData.registrationNumbercertificateOfIncorporationNumberUniqueId = data.REGBLOCK.referenceNum;
                    inputData.registrationNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.REGBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.registrationNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.REGBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.registrationNumberissueDate = data.REGBLOCK.issueDt;
                    //inputData.registrationNumberexpiryDate=data.REGBLOCK.issueDt;
                }
                if (data.TLBLOCK !== undefined) {
                    inputData.tradeLicensetlNumberUniqueId = data.TLBLOCK.referenceNum;
                    inputData.tradeLicenseplaceOfIssue = this.findByVarNameGetKeyValue(data.TLBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    inputData.tradeLicensecountryOfIssue = this.findByVarNameGetKeyValue(data.TLBLOCK.countryOfIssue, this.state.getCountry);
                    inputData.tradeLicenseissueDate = data.TLBLOCK.issueDt;
                    //inputData.tradeLicenseexpiryDate=data.TLBLOCK.issueDt;
                }
                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                }
                if (data.RSNIDBLOCK !== undefined) {

                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }


                }
                inputData.residentdocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "RESID",
                    "value": "RESIDENT ID CARD"
                };

                inputData.drivingLicensedocumentCodeN = {
                    "masterDataName": "documentCode",
                    "key": "DL",
                    "value": "DRIVING LICENSE"

                };

                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                }
                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                }
                if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.PASSPORTBLOCK !== undefined) {

                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                }


                //inputData.passportNumberexpiryDate=data. ;
                if (data.BCBLOCK !== undefined) {

                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;

                }
                if (data.chairmanCertificate !== undefined) {

                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                }
                // inputData.chairmanCertificateplaceOfBirthFreeText14=data. ;
                //inputData.chairmanCertificatecountryOfBirthFreeText15=data. ;
                //  inputData.chairmanCertificatenationality=data. ;
                // inputData.chairmanCertificatenonResident=data. ;
                // inputData.chairmanCertificateturnedNonResidentOn=data. ;
                if (data.ETINBLOCK !== undefined) {

                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;


                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);

                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }
                if (data.COMMEMLBLOCK !== undefined) {
                    //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                    /* if(data.COMMEMLBLOCK.phoneNumCountryCode==="88"){
                         inputData.mobileTypecontactNumberphoneNo2 ="88";
                     }
                     else{
                         inputData.mobileTypecontactNumberphoneNo2 ="0";
                     }*/
                }
                if (data.COMMPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo1 = this.findByVarNameGetKeyValue(data.COMMPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)
                    }
                }
                if (data.WORKPH1BLOCK !== undefined) {

                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.COMMPH2BLOCK !== undefined) {

                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;

                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo2 = this.findByVarNameGetKeyValue(data.COMMPH2BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                }
                if (data.HOMEEMLBLOCK !== undefined) {


                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }
                if (data.MailingBLOCK !== undefined) {


                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }
                if (data.HomeBLOCK !== undefined) {

                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                }
                if (data.WorkBLOCK !== undefined) {

                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                }

            } else if (accountRelated === "Dormant") {
                if (data.acctStatus === "A") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "A",
                        "value": "Active"
                    }
                }
                if (data.acctStatus === "D") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "D",
                        "value": "Dormant"
                    }
                }
                if (data.acctStatus === "I") {

                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "I",
                        "value": "Inactive"
                    }

                }
            }

        }


        return inputData;
    };
    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                 <FlowSummeryButton   subServiceType={this.props.subServiceType!==undefined?this.props.subServiceType:(this.props.subserviceType!==undefined?this.props.subserviceType:this.state.inputData.subserviceType)}     serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                    category={this.props.category!==undefined?this.props.category:this.state.inputData.category}
                    subCategory={this.props.subCategory!==undefined?this.props.subCategory:(this.props.subcategory!==undefined?this.props.subcategory:this.state.inputData.subCategory)}
                    caseId={this.props.caseId!==undefined?this.props.caseId:""} cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };
    functionForSetInquiry = (finalInputValue, finalVarValue) => {
        CommonApi.setCaseInquiry(finalInputValue, this.props.appId)
            .then((saveResponse) => {

            })
            .catch((error) => {
            console.log(error);
        })
    };
    componentDidMount() {

        this.setState({
            loading: true
        });
        {
            this.renderTagging()
        }

        /*{
            this.dynamicApiCallBankAndBranch("bankName", "bankName","branchName")
        }*/

        {
            this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType")
        }
        {
            this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc")
        }
        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        {
            this.dynamicApiCall("city2", "city")
        }
        {
            this.dynamicApiCall("state2", "state",)
        }
        {
            this.dynamicApiCall("currency", "currency")
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
        {
            this.dynamicApiCall("placeOfIssue", "placeOfIssue")
        }
        {
            this.dynamicApiCall("despatchMode", "despatchMode")
        }
        {
            this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund")
        }
        {
            this.dynamicApiCall("customersOccupation", "customerOccupation")
        }
        {
            this.dynamicApiCall("addressVariety", "addressVariety")
        }
        {
            this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness")
        }
        {
            this.dynamicApiCall("monthlyIncome", "monthlyIncome")
        }
        {
            this.dynamicApiCall("howWasAccountOpened", "accountOpen")
        }
        {
            this.dynamicApiCall("constitution", "constitution")
        }
        {
            this.dynamicApiCall("waiverField", "waiverField")
        }
        {
            this.dynamicApiCall("bankRelationType", "bankRelationType")
        }
        {
            this.dynamicApiCall("customerStatus", "customerStatus")
        }
        /* {
             this.dynamicApiCall("emailType", "emailType")
         }*/
        /*  {
              this.dynamicApiCall("phoneOrEmailId", "phoneOrEmailId")
          }*/

        {
            this.dynamicApiCall("ccepCode", "ccepCode")
        }
        {
            this.dynamicApiCall("maritalStatus", "maritalStatus")
        }

        /* {
             this.dynamicApiCall("bankName", "bankName")
         }*/
        {
            this.dynamicApiCall("title", "title")
        }
        {

            this.dynamicApiCall("segment", "segment")
        }
        {
            this.dynamicApiCall("documentCode", "documentCode")
        }
        {
            this.dynamicApiCall("relationship", "relationship")
        }
        /*  {
               this.dynamicApiCall("contactType", "contactType")
           }*/
        {
            this.dynamicApiCall("employmentType", "employmentType")
        }
        {
            this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")
        }
        {
            this.dynamicApiCall("nonResident", "nonResident")
        }
        {
            this.dynamicApiCall("addressFormat", "addressFormat")
        }
        /* {
             this.dynamicApiCall("branch", "branch")
         }*/
        {
            this.dynamicApiCall("relationCode", "relationCode")
        }
        {
            this.dynamicApiCall("relationType", "relationType")
        }
        {
            this.dynamicApiCall("dispatchMode", "dispatchMode")
        }
        {
            this.dynamicApiCall("statementFrequency", "statementFrequency")
        }
        {
            this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode")
        }
        {
            this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType")
        }
        /*  {
              this.dynamicApiCall("rmCode", "rmCode")
          }*/
        {
            this.dynamicApiCall("subSegment", "subSegment")
        }

        {
            this.dynamicApiCall("designationCode", "designationCode")
        }
        {
            this.dynamicApiCall("sectorCode", "sectorCode")
        }
        {
            this.dynamicApiCall("subSectorCode", "subSectorCode")
        }
        {
            this.dynamicApiCall("depositCode", "depositCode")
        }
        {
            this.dynamicApiCall("tradeService", "tradeService")
        }
        {
            this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode")
        }
        {
            this.dynamicApiCall("addressType", "addressType")
        }
        {
            this.dynamicApiCall("guardianCode", "guardianCode")
        }
        {
            this.dynamicApiCall("accountStatement", "accountStatement")
        }

        {
            this.dynamicApiCall("modOfOperation", "modOfOperation")
        }
        {
            this.dynamicApiCall("introducerStatus", "introducerStatus")
        }
        {
            this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("priorityCenterCode", "priorityCenterCode")
        }
        {
            this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode")
        }
        {
            this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode")
        }
        {
            this.dynamicApiCall("phoneCountryCode", "phoneCountryCode")
        }
        {this.dynamicKycMasterDataApiCall("TPcustomerType","", "1")}
        {this.dynamicKycMasterDataApiCall("TPandKycAccountType","", "2")}
        {this.dynamicKycMasterDataApiCall("customersOccupation", "","3")}
        {this.dynamicKycMasterDataApiCall("howTheSourcesOfFundHaveBeenVerified","", "4")}
        {this.dynamicKycMasterDataApiCall("collectedDocumentsHaveBeenVerifiedOrNot","", "5")}
        {this.dynamicKycMasterDataApiCall("howTheAddressOfTheAccountHolderHasBeenVerified","", "6")}
        {this.dynamicKycMasterDataApiCall("hasTheBeneficialOwnerOfTheAccountBeenIdentified","", "7")}
        {this.dynamicKycMasterDataApiCall("CopyReceived","", "8")}
        {this.dynamicKycMasterDataApiCall("Verified","", "9")}
        {this.dynamicKycMasterDataApiCall("customerNonresidentForeigner","", "10")}
        {this.dynamicKycMasterDataApiCall("isWorkPermitPermissionOpenAc","", "11")}
        {this.dynamicKycMasterDataApiCall("isTheCustomerPepHeadHigOfficialIntlOrg","", "12")}
        {this.dynamicKycMasterDataApiCall("customerFamily","", "13")}
        {this.dynamicKycMasterDataApiCall("customerFamilyIsThereAnyMatchToTheCustomer","", "14")}
        {this.dynamicKycMasterDataApiCall("typeProductService","", "15")}
        {this.dynamicKycMasterDataApiCall("typeOnboardingAccountOpening","", "16")}
        {this.dynamicKycMasterDataApiCall("nonResidentCustomerRisk","", "17")}
        {this.dynamicKycMasterDataApiCall("forForeignNationals","", "18")}
        {this.dynamicKycMasterDataApiCall("accordingBfiuCustomer","", "19")}
        {this.dynamicKycMasterDataApiCall("accordingBfiuFamily","", "20")}
         {this.dynamicKycMasterDataApiCall("customerProvidedReliableInformation","", "22")}
        {this.dynamicKycMasterDataApiCall("selectFromTheList","", "23")}




        /*  {
              this.dynamicApiCall("contactType", "contactType")
          }*/

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("lllllllllllllllllpppp");
                console.log(response.data);
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.findByVarNameApiData("FincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error)
            });



        var remarksArray = [];

        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);
                response.data.map((data) => {

                    remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                });
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error)
            });
        if (this.props.appId !== undefined) {
            this.setState({
                loading: true
            });

            let url = backEndServerURL + '/variables/' + this.props.appId;
            let getInputData = {};
            let getVarValue = {};

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let getVaribaleData = response.data;
                    for (let i = 0; i < 20; i++) {
                        delete getVaribaleData["imageRemarks" + i];
                        delete getVaribaleData["deleteFlag" + i];
                    }
                    if (this.props.appId !== undefined && response.data.checker_approval === undefined && response.data.dataSaved == undefined) {

                        let getCbnumber = response.data.cbNumber;
                        let getAccountnumber = response.data.accountNumber;
                        if( response.data.accountNumber!==undefined && this.props.accountNumber!==undefined  && response.data.accountNumber!==this.props.accountNumber){
                            alert("Account Number is differ!")
                            this.setState({
                                loading: false
                            });
                            return 0;
                        }
                        else if( response.data.cbNumber!==undefined && this.props.cbNumber!==undefined  && response.data.cbNumber!==this.props.cbNumber){
                            alert("CB Number is differ!")
                            this.setState({
                                loading: false
                            });
                            return 0;
                        }
                        console.log("getVaribaleData");
                        console.log(getVaribaleData);
                        if (getCbnumber !== undefined) {

                            console.log(getVaribaleData);
                            console.log("getVaribaleData");
                            let inputData = this.emptyValueRemove(getVaribaleData);
                            let varValue = this.emptyValueRemove(getVaribaleData);
                            inputData.makerRemarks = undefined;
                            varValue.makerRemarks = undefined;

                            inputData.makerEndTpAndKyc = true;
                            varValue.makerEndTpAndKyc = true;
                            url = backEndServerURL + "/GetCustomAcctStatus/" + getAccountnumber;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    inputData = this.apiDataSet(response.data, inputData, "Dormant");
                                    varValue = this.apiDataSet(response.data, inputData, "Dormant")
                                })
                                .catch((error) => {
                                    console.log(error);

                                });

                            let url = backEndServerURL + "/cbsCustomerGet/" + getCbnumber;/*getCbnumber"CB1187408"*/
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                //api 1 then
                                .then((cbDatResponse) => {
                                     if (cbDatResponse.status === 200) {
                                         setTimeout(() => {
                                            let dataInputs = this.apiDataSet(cbDatResponse.data, inputData, "cbRelated");
                                            let datavarValues = this.apiDataSet(cbDatResponse.data, varValue, "cbRelated");
                                            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + getAccountnumber;/*getAccountnumber""2801187408001""*/
                                            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((accountDataResponse) => {
                                                     //api 2 then
                                                    let dataInput = this.apiDataSet(accountDataResponse.data, dataInputs, "accountRelated");
                                                    let datavarValue = this.apiDataSet(accountDataResponse.data, datavarValues, "accountRelated");

                                                    let getAccountUrl = backEndServerURL + "/getAccountChequeWaive/" + getAccountnumber;/*"2801187408001"*/
                                                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                        .then((accountDataResponse) => {
                                                            //api 3 then
                                                             let accountDataInputData = this.apiDataSet(accountDataResponse.data, dataInput, "waiverType");
                                                            let accountDataVarvalue = this.apiDataSet(accountDataResponse.data, datavarValue, "waiverType");

                                                            let getAccountUrl = backEndServerURL + "/accCifOthrInfo/" + getAccountnumber;/*"2801187408001"*/
                                                            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((acCifResponse) => {
                                                                     let finalInputValue = this.accountOtherInfo(acCifResponse.data, accountDataInputData);
                                                                    let finalVarValue = this.accountOtherInfo(acCifResponse.data, accountDataVarvalue);

                                                                      let getCBUrl2 = backEndServerURL + "/getCustomerCIFDetails/" + getCbnumber;/*"2801187408001"*/
                                                                  axios.get(getCBUrl2, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                      .then((cb2DataResponse) => {
                                                                          setTimeout(() => {
                                                                               let dataInput = this.apiDataSet(cb2DataResponse.data, finalInputValue, "cbExtraInformation");
                                                                              let datavarValue = this.apiDataSet(cb2DataResponse.data, finalVarValue, "cbExtraInformation");
                                                                              this.functionForSetInquiry(this.emptyValueRemove(dataInput), datavarValue)

                                                                              this.setState({
                                                                                  getNewCase: true,
                                                                                  //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                                                                  inputData: this.emptyValueRemove(dataInput),
                                                                                  varValue: datavarValue,
                                                                                  showValue: true,
                                                                                  getData: true,
                                                                                  loading: false,
                                                                                  accountDataAfterSet: true,
                                                                                  cbDataAfterSet: true,
                                                                              })
                                                                          }, 200)
                                                                          //api 4 thencrmMissubSegment


                                                                      })
                                                                      //api 4 catch
                                                                      .catch((error) => {
                                                                          this.setState({
                                                                              loading: false,
                                                                              accountDataAfterSet: true,
                                                                              cbDataAfterSet: true,
                                                                              errorMessage: this.errorObjectCheck(error),
                                                                              errorModalBoolean: true

                                                                          })




                                                                              //api 3 catch

                                                                              .catch((error) => {
                                                                                  this.functionForSetInquiry(this.emptyValueRemove(dataInput), datavarValue)

                                                                                  this.setState({
                                                                                      getNewCase: true,
                                                                                      //inputData:JSON.parse(JSON.stringify(this.props.searchValue)),
                                                                                      inputData: this.emptyValueRemove(dataInput),
                                                                                      varValue: datavarValue,
                                                                                      showValue: true,
                                                                                      getData: true,
                                                                                      loading: false,
                                                                                      accountDataAfterSet: true,
                                                                                      cbDataAfterSet: true,
                                                                                  })
                                                                              })


                                                                      })
                                                                })
                                                                .catch((error)=>{
                                                                    this.setState({
                                                                        loading: false,
                                                                        accountDataAfterSet: true,
                                                                        cbDataAfterSet: true,
                                                                        errorMessage: this.errorObjectCheck(error),
                                                                        errorModalBoolean: true

                                                                    })
                                                                })



                                                        })

                                                        //api 2 catch
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.setState({
                                                                loading: false,
                                                                accountDataAfterSet: true,
                                                                cbDataAfterSet: true,
                                                                errorMessage: this.errorObjectCheck(error),
                                                                errorModalBoolean: true
                                                            })
                                                        })


                                                })
                                                //api 1 catch
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.setState({
                                                        loading: false,
                                                        cbDataAfterSet: true,
                                                        errorMessage: "Account Inquiry Api Error!",
                                                        errorModalBoolean: true
                                                    })
                                                })

                                        }, 100)
                                    }
                                })
                                .catch((error) => {
                                    this.setState({
                                        loading: false,
                                        cbDataAfterSet: true,
                                        errorMessage: "CB Inquiry Api Error!",
                                        errorModalBoolean: true
                                    })
                                })
                        }

                    } else {


                        let objectForJoinAccount = [];
                        var sl;
                        getInputData = this.emptyValueRemove(getVaribaleData);
                        getVarValue = getVaribaleData;
                        console.log("getVaribaleData");
                        console.log(getVaribaleData);
                        let inputData = this.emptyValueRemove(getVaribaleData);
                        let varValue = this.emptyValueRemove(getVaribaleData);
                        inputData.makerRemarks = undefined;
                        varValue.makerRemarks = undefined;

                        inputData.makerEndTpAndKyc = true;
                        varValue.makerEndTpAndKyc = true;

                        /*   inputData.missolId = this.props.solId;
                           varValue.missolId = this.props.solId;
                           inputData.misprimarySolId = this.props.solId;
                           varValue.misprimarySolId = this.props.solId;*/
                        this.setState({
                            getDedupData: response.data,
                            objectForJoinAccount: objectForJoinAccount,
                            getgenerateForm: true

                        });
                        console.log(response.data);


                        console.log(response.data);

                        /*  })*/


                        console.log("pppppppppppppppppppppppppp");
                        console.log(this.state.city);
                        inputData.customerTitle = inputData.mistitle;
                        inputData.homeAddressFieldShow = true;
                        varValue.homeAddressFieldShow = true;
                        inputData.totalProbableNumberOfCashApiCall = true;
                        inputData.totalProbableTotalCashApiCall = true;
                        inputData.totalProbableMaximumCashApiCall = true;
                        inputData.totalProbableNumberofWithdrawalApiCall = true;
                        inputData.totalProbableTotalWithdrawalApiCall = true;
                        inputData.totalProbablemaximumAmountApiCall = true;
                        inputData.totalProbableNumberOfCashApiCall = true;
                        inputData.totalProbableTotalCashApiCall = true;
                        inputData.totalProbableMaximumCashApiCall = true;
                        inputData.totalProbableNumberofWithdrawalApiCall = true;
                        inputData.totalProbableTotalWithdrawalApiCall = true;
                        inputData.totalProbablemaximumAmountApiCall = true;
                        /*     if (getInputData.statementFacility === "Printed Statement") {
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "Courier",
                                     "value": "Courier"
                                 }
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "Courier",
                                     "value": "Courier"
                                 }
                             } else if (getInputData.statementFacility === "E-Statement") {
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "Email only",
                                     "value": "Email only"
                                 }
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "Email only",
                                     "value": "Email only"
                                 }
                             } else if (getInputData.statementFacility === "NO") {
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "No Despatch",
                                     "value": "No Despatch"
                                 }
                                 inputData.aof1despatchModePrintedEStatementBoth = {
                                     "masterDataName": "dispatchMode",
                                     "key": "No Despatch",
                                     "value": "No Despatch"
                                 }
                             }
                             if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                                 inputData.miscustomerIdCifId = getInputData.cbNumber
                                 inputData.misaCNumber = getInputData.accountNumber

                                 inputData.miscustomerNameLastName = getInputData.customerName
                                 inputData.misshortName = getInputData.shortName
                                 inputData.misaCNumber = getInputData.accountNumber
                                 inputData.mistitle = getInputData.customerTitle
                                 if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
                                     inputData.misconstitution = {"masterDataName": "constitution", "key": "IND", "value": "IND"}
                                 } else if (this.props.subServiceType === "Joint Account") {
                                     inputData.misconstitution = {"masterDataName": "constitution", "key": "JNT", "value": "JNT"}
                                 }
                                 if (getInputData.customerTitle === "MR.") {
                                     inputData.misgender = "M";
                                 } else {
                                     inputData.misgender = getInputData.gender;
                                 }

                                 if (inputData.customerStatus === "Staff") {
                                     inputData.misstaffIndicatorStatus = "Y"
                                 } else {
                                     inputData.misstaffIndicatorStatus = "N"
                                 }
                                 inputData.miswaiverTypeForChequeBookWaiver = {
                                     "masterDataName": "chequebookWaiverType",
                                     "key": "F",
                                     "value": "F-First Chque"
                                 }
                                 inputData.miscustomerStatusNormalStaffPriorityEtc = getInputData.customerStatus


                                 if (this.state.inputData.occupationCodeNormal !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodeNormal
                                 } else if (this.state.inputData.occupationCodePriority !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodePriority
                                 } else if (this.state.inputData.occupationCodeStaff !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodeStaff
                                 }

                                 inputData.misstaffIdNumber = getVarValue.staffIdNumber
                                 inputData.mispriorityCenterCodeFreeCode1 = getVarValue.priorityCenterCode;
                                 inputData.mispriorityMonitoringRmCodeFreeCode3 = getVarValue.monitoringRMCode
                                 inputData.misprimaryCbHolderForPriorityFreeText8 = getVarValue.primaryCBHolderforPriority
                                 inputData.misrelationshipWithPrimaryCbHolderFreeText9 = getVarValue.relationshipwithPrimaryCBHolder;
                                 inputData.misfunctionCodeForChequeBookWaiver = getVarValue.functionCodeforChequeBookWaiver
                                 inputData.waiverTypeforChequeBookWaiver = getVarValue.functionCodeforChequeBookWaiver
                                 inputData.miswaiverFieldFreeCode10 = getVarValue.waiverField
                                 inputData.misccepCodeFreeCode9 = getVarValue.ccepCode
                                 inputData.aof1currencyCodeCcy = getVarValue.currency
                                 inputData.aof1emailDesignatedEmailAddress = getVarValue.designatedEmail
                                 inputData.aof1designatedMobileNumberPhoneNo1 = getVarValue.designatedMobileNumber
                                 inputData.primryintroducerCustomerIdCifId = getVarValue.introducerId
                                 inputData.primryiintroducerNameLastName = getVarValue.introducerName
                                 inputData.nationalIdCardNumberUniqueId = getVarValue.nid
                                 inputData.birthCertificatebirthCertificateNumberUniqueId = getVarValue.birthCertificateNo
                                 inputData.etineTinNumber = getVarValue.eTin
                                 inputData.maillingAdressmailingAddressField1 = getVarValue.communicationAddress1
                                 inputData.maillingAdressmailingAddressField2 = getVarValue.communicationAddress2
                                 inputData.maillingAdresscity = getVarValue.city2
                                 inputData.maillingAdressstate = getVarValue.state2
                                 inputData.maillingAdresspostalCode = getVarValue.postalCode3
                                 inputData.maillingAdresscountry = getVarValue.nationality
                                 inputData.fincoreDataUpdatesectorCode = getVarValue.nationality
                                 inputData.fincoreDataUpdatesubSectorCode = getVarValue.subSectorCode
                                 inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = getVarValue.rmCode
                                 inputData.basicInformationUpdatedob = getInputData.dob
                                 var x = 18;
                                 currentDate = new Date();

                                 someDate = new Date();
                                 numberOfDaysToAdd = x
                                 someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                                 date = someDate.getDate();
                                 month = someDate.getMonth() + 1;
                                 year = someDate.getFullYear();
                                 convertDate = year + "-" + month + "-" + date;
                                 newDate = new Date(convertDate);
                                 let stringToDate = new Date(newDate.toDateString());

                                 let inputDate = new Date(getInputData.dob);

                                 if (stringToDate <= inputDate) {
                                     inputData.basicInformationUpdatedobApiCall = "YES"
                                     inputData.basicInformationUpdatedobApiCall = "YES"
                                     inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                                     inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"

                                 } else {
                                     inputData.basicInformationUpdatedobApiCall = "YES"
                                     inputData.basicInformationUpdatedobApiCall = "YES"
                                     inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                                     inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                                 }
                                 x = 60;
                                 currentDate = new Date();
                                 someDate = new Date();
                                 numberOfDaysToAdd = x
                                 someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                                 date = someDate.getDate();
                                 month = someDate.getMonth() + 1;
                                 year = someDate.getFullYear();
                                 convertDate = year + "-" + month + "-" + date;
                                 newDate = new Date(convertDate);
                                 stringToDate = new Date(newDate.toDateString());

                                 inputDate = new Date(getInputData.dob);
                                 if (stringToDate >= inputDate) {
                                     inputData.basicInformationUpdateseniorCitizenIndicator = "Y"
                                     inputData.basicInformationUpdateseniorCitizenIndicator = "Y"
                                 } else {
                                     inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                                     inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                                 }
                                 inputData.miscustomerIdCifId = getVarValue.cbNumber
                                 inputData.misaCNumber = getInputData.accountNumber
                                 if (inputData.customerStatus === "Priority") {
                                     inputData.miswaiverFieldFreeCode10 = {
                                         "masterDataName": "waiverField",
                                         "key": "WAVPR",
                                         "value": "WAIVER SERVICE CHG - PRIORITY BANKING"
                                     }
                                 }
                                 inputData.misavailedTradeServices = {"masterDataName": "tradeService", "key": "N", "value": "N"}
                                 inputData.aof1emailDesignatedEmailAddress = getInputData.designatedEmail;
                                 if (inputData.customerStatus === "Staff") {
                                     inputData.primryiintroducerStatus = {
                                         "masterDataName": "introducerStatus",
                                         "key": "STF",
                                         "value": "STF"
                                     }
                                 } else {
                                     inputData.primryiintroducerStatus = {
                                         "masterDataName": "introducerStatus",
                                         "key": "EXC",
                                         "value": "EXC"
                                     }

                                 }
                                 inputData.primryicifTypePrimaryIntroducerDetails = "Retail"
                                 inputData.schemeCodeChangetrialMode = "N"
                                 inputData.misccepCodeFreeCode9 = getInputData.ccepCode
                                 inputData.aof1smsAlertFreeText11 = getInputData.smsAlertRequest
                                 inputData.guardianDetailsrelation = {
                                     "masterDataName": "relationship",
                                     "key": "Social",
                                     "value": "Social"
                                 }
                                 inputData.natioanlIdCarddocumentTypeN = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.natioanlIdCarddocumentTypeDescriptionN = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.natioanlIdCarddocumentCodeN = {
                                     "masterDataName": "documentCode",
                                     "key": "NID",
                                     "value": "NID CARD IDENTITY"
                                 }
                                 inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
                                 inputData.smartIdCarddocumentTypeS = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.smartIdCarddocumentTypeDescriptionS = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.smartIdCarddocumentCodeS = {
                                     "masterDataName": "documentCode",
                                     "key": "RSNID",
                                     "value": "RSNID"
                                 }
                                 inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = "Y"
                                 inputData.passportNumberdocumentTypeP = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.passportNumberdocumentTypeDescriptionP = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.passportNumberdocumentCodeP = {
                                     "masterDataName": "documentCode",
                                     "key": "PASSPORT",
                                     "value": "PASSPORT IDENTITY"
                                 }
                                 inputData.passportNumberplaceOfIssue = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
                                 inputData.birthCertificatedocumentTypeB = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.birthCertificatedocumentTypeDescriptionB = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.birthCertificatedocumentCodeB = {
                                     "masterDataName": "documentCode",
                                     "key": "BC",
                                     "value": "BIRTH CERTIFICATE"
                                 }
                                 inputData.chairmanCertificatedocumentTypeCC = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.chairmanCertificatedocumentCodeCC = {
                                     "masterDataName": "documentCode",
                                     "key": "COMC",
                                     "value": "COMMISSIONER CERTIFICATE"
                                 }
                                 inputData.chairmanCertificatenationality = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
                                 inputData.chairmanCertificatenonResident = {
                                     "masterDataName": "nonResident",
                                     "key": "N",
                                     "value": "N"
                                 }
                                 inputData.etindocumentType = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 inputData.etindocumentTypeDescription = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 inputData.etindocumentCode = {
                                     "masterDataName": "documentCode",
                                     "key": "ETIN",
                                     "value": "TIN CARD IDENTITY"
                                 }
                                 inputData.contactNumberpreferredContactNoType1 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH1",
                                     "value": "COMMPH1"
                                 }
                                 inputData.contactNumbercontactType1 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH1",
                                     "value": "COMMPH1"
                                 }
                                 inputData.contactNumberphoneNoEmailId1 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 inputData.contactNumberphoneNo1 = "88"
                                 inputData.contactNumbercontactType2 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH2",
                                     "value": "COMMPH2"
                                 }
                                 inputData.contactNumberphoneNoEmailId2 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 inputData.contactNumberphoneNo2 = "88"
                                 inputData.contactNumbercontactType3 = {
                                     "masterDataName": "contactType",
                                     "key": "WORKPH1",
                                     "value": "WORKPH1"
                                 }
                                 inputData.contactNumberphoneNoEmailId3 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 inputData.contactNumberphoneNo3 = "88"
                                 inputData.contactNumberEmailpreferedEmailIdType = {
                                     "masterDataName": "emailType",
                                     "key": "COMMEML",
                                     "value": "COMMEML"
                                 }
                                 inputData.contactNumberEmailemailType1 = {
                                     "masterDataName": "emailType",
                                     "key": "COMMEML",
                                     "value": "COMMEML"
                                 }
                                 inputData.contactNumberEmailphoneNoEmailId4 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "EMAIL",
                                     "value": "EMAIL"
                                 }
                                 inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = getInputData.designatedEmail
                                 inputData.contactNumberEmailemailType = {
                                     "masterDataName": "emailType",
                                     "key": "HOMEEML",
                                     "value": "HOMEEML"
                                 }
                                 inputData.contactNumberEmailphoneNoEmailId = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "EMAIL",
                                     "value": "EMAIL"
                                 }
                                 inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = getInputData.wealthManagerCode
                                 inputData.maillingAdresspreferredAddressType = {
                                     "masterDataName": "addressType",
                                     "key": "Mailing",
                                     "value": "Mailing"
                                 }
                                 inputData.maillingAdressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 inputData.maillingAdressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Mailing",
                                     "value": "Mailing"
                                 }
                                 inputData.homeAddressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 inputData.homeAddressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Home",
                                     "value": "Home"
                                 }
                                 inputData.workAddressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 inputData.workAddressaddressType = {
                                     "masterDataName": "addressType",
                                     "key": "Work",
                                     "value": "Work"
                                 }
                                 inputData.workAddressworkAddressField1 = getInputData.ccepCode
                                 inputData.workAddressworkAddressField2 = getInputData.ccepCode
                                 inputData.workAddresscountry = {"masterDataName": "country", "key": "BD", "value": "BANGLADESH"}

                                 inputData.presentAddressaddressFormat = {
                                     "masterDataName": "addressType",
                                     "key": "Work",
                                     "value": "Work"
                                 }
                                 inputData.presentAddressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Future/OnSite",
                                     "value": "Future/OnSite"
                                 }
                                 inputData.presentAddresspresentAddressField1 = getInputData.ccepCode
                                 inputData.presentAddresscountry = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
                                 //inputData.fincoreDataUpdateaccountStatement={"masterDataName":"accountStatement","key":"Email","value":"Email"}
                                 inputData.fincoreDataUpdatestatementFrequency = {
                                     "masterDataName": "statementFrequency",
                                     "key": "C502010",
                                     "value": "HALFYEARLY"
                                 }
                                 inputData.fincoreDataUpdateoccupationCode = inputData.miscustomerStatusNormalStaffPriorityEtc;
                                 inputData.fincoreDataUpdatesectorCode = inputData.sectorCode;
                                 inputData.fincoreDataUpdatesubSectorCode = inputData.subSectorCode;
                                 if (getInputData.statementFacility === "Printed Statement") {
                                     inputData.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "Courier",
                                         "value": "Courier"
                                     }
                                 } else if (getInputData.statementFacility === "E-Statement") {
                                     inputData.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "Email only",
                                         "value": "Email only"
                                     }
                                 } else if (getInputData.statementFacility === "NO") {
                                     inputData.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "No Despatch",
                                         "value": "No Despatch"
                                     }
                                 }


                                 inputData.miscustomerNameLastName = getInputData.customerName
                                 inputData.misshortName = getVarValue.shortName
                                 inputData.misaCNumber = getVarValue.accountNumber
                                 inputData.mistitle = getVarValue.customerTitle
                                 if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
                                     inputData.misconstitution = {"masterDataName": "constitution", "key": "IND", "value": "IND"}
                                 } else if (this.props.subServiceType === "Joint Account") {
                                     inputData.misconstitution = {"masterDataName": "constitution", "key": "JNT", "value": "JNT"}
                                 }
                                 if (getInputData.customerTitle === "MR.") {
                                     inputData.misgender = "M";
                                 } else {
                                     inputData.misgender = getInputData.gender;
                                 }
                                 if (inputData.customerStatus === "Staff") {
                                     inputData.misstaffIndicatorStatus = "Y"
                                 } else {
                                     inputData.misstaffIndicatorStatus = "N"
                                 }
                                 inputData.miswaiverTypeForChequeBookWaiver = {
                                     "masterDataName": "chequebookWaiverType",
                                     "key": "F",
                                     "value": "F-First Chque"
                                 }
                                 inputData.miscustomerStatusNormalStaffPriorityEtc = getVarValue.customerStatus
                                 if (this.state.inputData.occupationCodeNormal !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodeNormal
                                 } else if (this.state.inputData.occupationCodePriority !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodePriority
                                 } else if (this.state.inputData.occupationCodeStaff !== undefined) {
                                     inputData.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodeStaff
                                 }


                                 ////vervalue

                                 varValue.miscustomerIdCifId = getInputData.cbNumber
                                 varValue.misaCNumber = getInputData.accountNumber

                                 varValue.miscustomerNameLastName = getInputData.customerName
                                 varValue.misshortName = getInputData.shortName
                                 varValue.misaCNumber = getInputData.accountNumber
                                 varValue.mistitle = getInputData.customerTitle
                                 if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
                                     varValue.misconstitution = {"masterDataName": "constitution", "key": "IND", "value": "IND"}
                                 } else if (this.props.subServiceType === "Joint Account") {
                                     varValue.misconstitution = {"masterDataName": "constitution", "key": "JNT", "value": "JNT"}
                                 }
                                 if (getInputData.customerTitle === "MR.") {
                                     varValue.misgender = "M";
                                 } else {
                                     varValue.misgender = getInputData.gender;
                                 }

                                 if (varValue.customerStatus === "Staff") {
                                     varValue.misstaffIndicatorStatus = "Y"
                                 } else {
                                     varValue.misstaffIndicatorStatus = "N"
                                 }
                                 varValue.miswaiverTypeForChequeBookWaiver = {
                                     "masterDataName": "chequebookWaiverType",
                                     "key": "F",
                                     "value": "F-First Chque"
                                 }
                                 varValue.miscustomerStatusNormalStaffPriorityEtc = getInputData.customerStatus


                                 if (this.state.varValue.occupationCodeNormal !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodeNormal
                                 } else if (this.state.varValue.occupationCodePriority !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodePriority
                                 } else if (this.state.varValue.occupationCodeStaff !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getInputData.occupationCodeStaff
                                 }

                                 varValue.misstaffIdNumber = getVarValue.staffIdNumber
                                 varValue.mispriorityCenterCodeFreeCode1 = getVarValue.priorityCenterCode;
                                 varValue.mispriorityMonitoringRmCodeFreeCode3 = getVarValue.monitoringRMCode
                                 varValue.misprimaryCbHolderForPriorityFreeText8 = getVarValue.primaryCBHolderforPriority
                                 varValue.misrelationshipWithPrimaryCbHolderFreeText9 = getVarValue.relationshipwithPrimaryCBHolder;
                                 varValue.misfunctionCodeForChequeBookWaiver = getVarValue.functionCodeforChequeBookWaiver
                                 varValue.waiverTypeforChequeBookWaiver = getVarValue.functionCodeforChequeBookWaiver
                                 varValue.miswaiverFieldFreeCode10 = getVarValue.waiverField
                                 varValue.misccepCodeFreeCode9 = getVarValue.ccepCode
                                 varValue.aof1currencyCodeCcy = getVarValue.currency
                                 varValue.aof1emailDesignatedEmailAddress = getVarValue.designatedEmail
                                 varValue.aof1designatedMobileNumberPhoneNo1 = getVarValue.designatedMobileNumber
                                 varValue.primryintroducerCustomerIdCifId = getVarValue.introducerId
                                 varValue.primryiintroducerNameLastName = getVarValue.introducerName
                                 varValue.nationalIdCardNumberUniqueId = getVarValue.nid
                                 varValue.birthCertificatebirthCertificateNumberUniqueId = getVarValue.birthCertificateNo
                                 varValue.etineTinNumber = getVarValue.eTin
                                 varValue.maillingAdressmailingAddressField1 = getVarValue.communicationAddress1
                                 varValue.maillingAdressmailingAddressField2 = getVarValue.communicationAddress2
                                 varValue.maillingAdresscity = getVarValue.city2
                                 varValue.maillingAdressstate = getVarValue.state2
                                 varValue.maillingAdresspostalCode = getVarValue.postalCode3
                                 varValue.maillingAdresscountry = getVarValue.nationality
                                 varValue.fincoreDataUpdatesectorCode = getVarValue.nationality
                                 varValue.fincoreDataUpdatesubSectorCode = getVarValue.subSectorCode
                                 varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = getVarValue.rmCode
                                 varValue.basicInformationUpdatedob = getInputData.dob
                                 x = 18;
                                 let currentDate = new Date();

                                 let someDate = new Date();
                                 let numberOfDaysToAdd = x
                                 someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                                 let date = someDate.getDate();
                                 let month = someDate.getMonth() + 1;
                                 let year = someDate.getFullYear();
                                 let convertDate = year + "-" + month + "-" + date;
                                 let newDate = new Date(convertDate);
                                 stringToDate = new Date(newDate.toDateString());

                                 inputDate = new Date(getInputData.dob);

                                 if (stringToDate <= inputDate) {
                                     varValue.basicInformationUpdatedobApiCall = "YES"
                                     varValue.basicInformationUpdatedobApiCall = "YES"
                                     varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                                     varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"

                                 } else {
                                     varValue.basicInformationUpdatedobApiCall = "YES"
                                     varValue.basicInformationUpdatedobApiCall = "YES"
                                     varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                                     varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                                 }
                                 x = 60;
                                 currentDate = new Date();
                                 someDate = new Date();
                                 numberOfDaysToAdd = x
                                 someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                                 date = someDate.getDate();
                                 month = someDate.getMonth() + 1;
                                 year = someDate.getFullYear();
                                 convertDate = year + "-" + month + "-" + date;
                                 newDate = new Date(convertDate);
                                 stringToDate = new Date(newDate.toDateString());

                                 inputDate = new Date(getInputData.dob);
                                 if (stringToDate >= inputDate) {
                                     varValue.basicInformationUpdateseniorCitizenIndicator = "Y"
                                     varValue.basicInformationUpdateseniorCitizenIndicator = "Y"
                                 } else {
                                     varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                                     varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                                 }
                                 varValue.miscustomerIdCifId = getVarValue.cbNumber
                                 varValue.misaCNumber = getInputData.accountNumber
                                 if (varValue.customerStatus === "Priority") {
                                     varValue.miswaiverFieldFreeCode10 = {
                                         "masterDataName": "waiverField",
                                         "key": "WAVPR",
                                         "value": "WAIVER SERVICE CHG - PRIORITY BANKING"
                                     }
                                 }
                                 varValue.misavailedTradeServices = {"masterDataName": "tradeService", "key": "N", "value": "N"}
                                 varValue.aof1emailDesignatedEmailAddress = getInputData.designatedEmail;
                                 if (varValue.customerStatus === "Staff") {
                                     varValue.primryiintroducerStatus = {
                                         "masterDataName": "introducerStatus",
                                         "key": "STF",
                                         "value": "STF"
                                     }
                                 } else {
                                     varValue.primryiintroducerStatus = {
                                         "masterDataName": "introducerStatus",
                                         "key": "EXC",
                                         "value": "EXC"
                                     }

                                 }
                                 varValue.primryicifTypePrimaryIntroducerDetails = "Retail"
                                 varValue.schemeCodeChangetrialMode = "N"
                                 varValue.misccepCodeFreeCode9 = getInputData.ccepCode
                                 varValue.aof1smsAlertFreeText11 = getInputData.smsAlertRequest
                                 varValue.guardianDetailsrelation = {
                                     "masterDataName": "relationship",
                                     "key": "Social",
                                     "value": "Social"
                                 }
                                 varValue.natioanlIdCarddocumentTypeN = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.natioanlIdCarddocumentTypeDescriptionN = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.natioanlIdCarddocumentCodeN = {
                                     "masterDataName": "documentCode",
                                     "key": "NID",
                                     "value": "NID CARD IDENTITY"
                                 }
                                 varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
                                 varValue.smartIdCarddocumentTypeS = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.smartIdCarddocumentTypeDescriptionS = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.smartIdCarddocumentCodeS = {
                                     "masterDataName": "documentCode",
                                     "key": "RSNID",
                                     "value": "RSNID"
                                 }
                                 varValue.smartIdCardnidVerificationConfirmationIsDocumentVerified = "Y"
                                 varValue.passportNumberdocumentTypeP = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.passportNumberdocumentTypeDescriptionP = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.passportNumberdocumentCodeP = {
                                     "masterDataName": "documentCode",
                                     "key": "PASSPORT",
                                     "value": "PASSPORT IDENTITY"
                                 }
                                 varValue.passportNumberplaceOfIssue = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
                                 varValue.birthCertificatedocumentTypeB = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.birthCertificatedocumentTypeDescriptionB = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.birthCertificatedocumentCodeB = {
                                     "masterDataName": "documentCode",
                                     "key": "BC",
                                     "value": "BIRTH CERTIFICATE"
                                 }
                                 varValue.chairmanCertificatedocumentTypeCC = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.chairmanCertificatedocumentTypeDescriptionCC = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.chairmanCertificatedocumentCodeCC = {
                                     "masterDataName": "documentCode",
                                     "key": "COMC",
                                     "value": "COMMISSIONER CERTIFICATE"
                                 }
                                 varValue.chairmanCertificatenationality = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
                                 varValue.chairmanCertificatenonResident = {
                                     "masterDataName": "nonResident",
                                     "key": "N",
                                     "value": "N"
                                 }
                                 varValue.etindocumentType = {
                                     "masterDataName": "documentCodeDocumentType",
                                     "key": "IDENTIFICATION PROOF",
                                     "value": "IDENTIFICATION PROOF"
                                 }
                                 varValue.etindocumentTypeDescription = {
                                     "masterDataName": "documentCodeDocumentTypeDesc",
                                     "key": "IDENTIFICATION",
                                     "value": "IDENTIFICATION"
                                 }
                                 varValue.etindocumentCode = {
                                     "masterDataName": "documentCode",
                                     "key": "ETIN",
                                     "value": "TIN CARD IDENTITY"
                                 }
                                 varValue.contactNumberpreferredContactNoType1 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH1",
                                     "value": "COMMPH1"
                                 }
                                 varValue.contactNumbercontactType1 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH1",
                                     "value": "COMMPH1"
                                 }
                                 varValue.contactNumberphoneNoEmailId1 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 varValue.contactNumberphoneNo1 = "88"
                                 varValue.contactNumbercontactType2 = {
                                     "masterDataName": "contactType",
                                     "key": "COMMPH2",
                                     "value": "COMMPH2"
                                 }
                                 varValue.contactNumberphoneNoEmailId2 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 varValue.contactNumberphoneNo2 = "88"
                                 varValue.contactNumbercontactType3 = {
                                     "masterDataName": "contactType",
                                     "key": "WORKPH1",
                                     "value": "WORKPH1"
                                 }
                                 varValue.contactNumberphoneNoEmailId3 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "PHONE",
                                     "value": "PHONE"
                                 }
                                 varValue.contactNumberphoneNo3 = "88"
                                 varValue.contactNumberEmailpreferedEmailIdType = {
                                     "masterDataName": "emailType",
                                     "key": "COMMEML",
                                     "value": "COMMEML"
                                 }
                                 varValue.contactNumberEmailemailType1 = {
                                     "masterDataName": "emailType",
                                     "key": "COMMEML",
                                     "value": "COMMEML"
                                 }
                                 varValue.contactNumberEmailphoneNoEmailId4 = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "EMAIL",
                                     "value": "EMAIL"
                                 }
                                 varValue.contactNumberEmaildesignatedEmailAddressEmailId1 = getInputData.designatedEmail
                                 varValue.contactNumberEmailemailType = {
                                     "masterDataName": "emailType",
                                     "key": "HOMEEML",
                                     "value": "HOMEEML"
                                 }
                                 varValue.contactNumberEmailphoneNoEmailId = {
                                     "masterDataName": "phoneOrEmailId",
                                     "key": "EMAIL",
                                     "value": "EMAIL"
                                 }
                                 varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = getInputData.wealthManagerCode
                                 varValue.maillingAdresspreferredAddressType = {
                                     "masterDataName": "addressType",
                                     "key": "Mailing",
                                     "value": "Mailing"
                                 }
                                 varValue.maillingAdressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 varValue.maillingAdressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Mailing",
                                     "value": "Mailing"
                                 }
                                 varValue.homeAddressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 varValue.homeAddressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Home",
                                     "value": "Home"
                                 }
                                 varValue.workAddressaddressFormat = {
                                     "masterDataName": "addressFormat",
                                     "key": "FREE_TEXT_FORMAT",
                                     "value": "FREE_TEXT_FORMAT"
                                 }
                                 varValue.workAddressaddressType = {
                                     "masterDataName": "addressType",
                                     "key": "Work",
                                     "value": "Work"
                                 }
                                 varValue.workAddressworkAddressField1 = getInputData.ccepCode
                                 varValue.workAddressworkAddressField2 = getInputData.ccepCode
                                 varValue.workAddresscountry = {"masterDataName": "country", "key": "BD", "value": "BANGLADESH"}

                                 varValue.presentAddressaddressFormat = {
                                     "masterDataName": "addressType",
                                     "key": "Work",
                                     "value": "Work"
                                 }
                                 varValue.presentAddressaddrssType = {
                                     "masterDataName": "addressType",
                                     "key": "Future/OnSite",
                                     "value": "Future/OnSite"
                                 }
                                 varValue.presentAddresspresentAddressField1 = getInputData.ccepCode
                                 varValue.presentAddresscountry = {
                                     "masterDataName": "country",
                                     "key": "BD",
                                     "value": "BANGLADESH"
                                 }
         //varValue.fincoreDataUpdateaccountStatement={"masterDataName":"accountStatement","key":"Email","value":"Email"}
                                 varValue.fincoreDataUpdatestatementFrequency = {
                                     "masterDataName": "statementFrequency",
                                     "key": "C502010",
                                     "value": "HALFYEARLY"
                                 }
                                 varValue.fincoreDataUpdateoccupationCode = varValue.miscustomerStatusNormalStaffPriorityEtc;
                                 varValue.fincoreDataUpdatesectorCode = varValue.sectorCode;
                                 varValue.fincoreDataUpdatesubSectorCode = varValue.subSectorCode;
                                 if (getInputData.statementFacility === "Printed Statement") {
                                     varValue.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "Courier",
                                         "value": "Courier"
                                     }
                                 } else if (getInputData.statementFacility === "E-Statement") {
                                     varValue.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "Email only",
                                         "value": "Email only"
                                     }
                                 } else if (getInputData.statementFacility === "NO") {
                                     varValue.fincoreDataUpdatedispatchMode = {
                                         "masterDataName": "dispatchMode",
                                         "key": "No Despatch",
                                         "value": "No Despatch"
                                     }
                                 }


                                 varValue.miscustomerNameLastName = getInputData.customerName
                                 varValue.misshortName = getVarValue.shortName
                                 varValue.misaCNumber = getVarValue.accountNumber
                                 varValue.mistitle = getVarValue.customerTitle
                                 if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
                                     varValue.misconstitution = {"masterDataName": "constitution", "key": "IND", "value": "IND"}
                                 } else if (this.props.subServiceType === "Joint Account") {
                                     varValue.misconstitution = {"masterDataName": "constitution", "key": "JNT", "value": "JNT"}
                                 }
                                 if (getInputData.customerTitle === "MR.") {
                                     varValue.misgender = "M";
                                 } else {
                                     varValue.misgender = getInputData.gender;
                                 }
                                 if (varValue.customerStatus === "Staff") {
                                     varValue.misstaffIndicatorStatus = "Y"
                                 } else {
                                     varValue.misstaffIndicatorStatus = "N"
                                 }
                                 varValue.miswaiverTypeForChequeBookWaiver = {
                                     "masterDataName": "chequebookWaiverType",
                                     "key": "F",
                                     "value": "F-First Chque"
                                 }
                                 varValue.miscustomerStatusNormalStaffPriorityEtc = getVarValue.customerStatus
                                 if (this.state.varValue.occupationCodeNormal !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodeNormal
                                 } else if (this.state.varValue.occupationCodePriority !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodePriority
                                 } else if (this.state.varValue.occupationCodeStaff !== undefined) {
                                     varValue.miscustomerStatusNormalStaffPriorityEtc = getVarValue.occupationCodeStaff
                                 }

                             } else {
                                 inputData.miscustomerIdCifId = getInputData.cbNumber
                                 inputData.misaCNumber = getInputData.accountNumber
                                 inputData.miscorporateName = getInputData.customerName
                                 inputData.misshortName = getInputData.shortName
                                 inputData.misstatus = getInputData.customerStatus
                                 inputData.misentityClassOccupationCode = getInputData.occupationCodes
                                 inputData.currencyCodecurrencyCodeCcy = getInputData.currency
                                 inputData.mailingAddresspreferredAddressType = getInputData.SelectCommunicationAddress
                                 inputData.mailingAddressaddressField1 = getInputData.mailingAddress1
                                 inputData.mailingAddressaddressField2 = getInputData.mailingAddress2
                                 inputData.mailingAddresscity = getInputData.city2
                                 inputData.mailingAddressstate = getInputData.state2
                                 inputData.mailingAddresspostalCode = getInputData.postalCode
                                 inputData.mailingAddresscountry = getInputData.nationality
                                 inputData.contactNumberphoneNo = getInputData.contactNumber1
                                 inputData.contactNumberphoneNo = getInputData.contactNumber2
                                 inputData.contactNumberEmaildesignatedEmailAddressEmailId = getInputData.designatedEmailAddress
                                 inputData.tradeLicensetlNumberUniqueId = getInputData.tradeLicense
                                 inputData.certificateOfIncorporationcertificateOfIncorporationNumberUniqueId = getInputData.certificateIncorporation
                                 inputData.etinetinNumber = getInputData.companyEtin
                                 inputData.introducerintroducerCustomerIdLastNameOrCorporateName = getInputData.introducerId
                                 inputData.introducerintroducerNameLastNameOrCorporateName = getInputData.introducerName
                                 inputData.introducersectorCode = getInputData.sectorCode
                                 inputData.introducersubSectorCode = getInputData.subSectorCode
                                 inputData.introducerrmCodeFreeCode3 = getInputData.rmCode


                                 varValue.miscustomerIdCifId = getVarValue.cbNumber
                                 varValue.misaCNumber = getVarValue.accountNumber
                                 varValue.miscorporateName = getVarValue.customerName
                                 varValue.misshortName = getVarValue.shortName
                                 varValue.misstatus = getVarValue.customerStatus
                                 varValue.misentityClassOccupationCode = getVarValue.occupationCodes
                                 varValue.currencyCodecurrencyCodeCcy = getVarValue.currency
                                 varValue.mailingAddresspreferredAddressType = getVarValue.SelectCommunicationAddress
                                 varValue.mailingAddressaddressField1 = getVarValue.mailingAddress1
                                 varValue.mailingAddressaddressField2 = getVarValue.mailingAddress2
                                 varValue.mailingAddresscity = getVarValue.city2
                                 varValue.mailingAddressstate = getVarValue.state2
                                 varValue.mailingAddresspostalCode = getVarValue.postalCode
                                 varValue.mailingAddresscountry = getVarValue.nationality
                                 varValue.contactNumberphoneNo = getVarValue.contactNumber1
                                 varValue.contactNumberphoneNo = getVarValue.contactNumber2
                                 varValue.contactNumberEmaildesignatedEmailAddressEmailId = getVarValue.designatedEmailAddress
                                 varValue.tradeLicensetlNumberUniqueId = getVarValue.tradeLicense
                                 varValue.certificateOfIncorporationcertificateOfIncorporationNumberUniqueId = getVarValue.certificateIncorporation
                                 varValue.etinetinNumber = getVarValue.companyEtin
                                 varValue.introducerintroducerCustomerIdLastNameOrCorporateName = getVarValue.introducerId
                                 varValue.introducerintroducerNameLastNameOrCorporateName = getVarValue.introducerName
                                 varValue.introducersectorCode = getVarValue.sectorCode
                                 varValue.introducersubSectorCode = getVarValue.subSectorCode
                                 varValue.introducerrmCodeFreeCode3 = getVarValue.rmCode
                                 }
         */


                        console.log("inputadaaaaaaaa");
                        console.log(inputData);
                        this.setState({
                            //getTagList: tableArrayData,
                            jointAccountCustomerNumber: response.data.jointAccountCustomerNumber,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(varValue),
                            showValue: true,
                            getData: true,
                            appData: response.data,
                            loading: false,
                            accountDataAfterSet: true,
                            cbDataAfterSet: true,
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        accountDataAfterSet: true,
                        cbDataAfterSet: true,
                    })

                });


        }


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];*/

        return clone;
    };

    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmitReturn") {
            this.handleSubmitReturn()
        } else if (data === "YES" && this.state.actionType === "handleSubmitSave") {
            this.handleSubmitSave()
        } else if (data === "YES" && this.state.actionType === "handleSubmit") {
            this.handleSubmit()
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()
        this.validationForHandleConfirm(type)


    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        let dependencyField = [];
        this.setState({
            loading: true
        });
        if (actionType === "handleSubmitReturn") {
            dependencyField.push({

                "varName": "makerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Maker Remarks",
                "grid": 12
            })


        }
        else if (actionType === "handleSubmit") {
            console.log(this.state.inputData);
            console.log(this.state.varValue);
            let percentage = 0;
            dependencyField = (this.props.commonJsonForm.concat(this.props.commonJsonFormLast, objectForNominee, this.state.inputData.addTpandKycCheckbox===true && this.props.tpAndKyc, /*this.props.tpAndKyc*/));
            if (this.state.inputData["numberOfNominee"] !== undefined) {
                for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                    percentage += Number(this.state.inputData["percentageNominee" + (i + 1)]);

                }

                /*  if (this.state.inputData.miscustomerStatusNormalStaffPriorityEtc === "STAFF") {
                      dependencyField.push({
                              "varName": "misstaffIndicatorStatus",
                              "type": "select",
                              "enum": [
                                  "Y",
                                  "N"
                              ],
                              "label": "Staff Indicator/Status",
                              //inputData.misstaffIndicatorStatus=inputData.staffIndicator
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
                              "conditionalVarValue": "STAFF",
                              "required": true,


                          },
                          {
                              "varName": "misstaffIdNumber",
                              "type": "text",
                              "label": "Staff ID Number",
                              "validation": "staffId",
                              //inputData.misstaffIdNumber=inputData.staffIdNumber
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "miscustomerStatusNormalStaffPriorityEtc",
                              "conditionalVarValue": "STAFF",
                              "required": true,
                          },
                      )
                  }
                  if (this.state.inputData.misstaffIndicatorStatus === "Y") {
                      dependencyField.push({
                          "varName": "misstaffIdNumber",
                          "type": "text",
                          "label": "Staff ID Number",
                          //inputData.misstaffIdNumber=inputData.staffIdNumber
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "mispriorityCenterCodeFreeCode1",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.mispriorityCenterCodeFreeCode1=inputData.priorityCenterCode;
                          "label": "Priority Center Code/Free Code 1",
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "mispriorityMonitoringRmCodeFreeCode3",
                          "type": "textApiCall",
                          "enum": [],
                          //inputData.mispriorityMonitoringRmCodeFreeCode3=inputData.monitoringRMCode
                          "label": "Priority Monitoring  RM Code/Free Code 3",
                          "grid": 6,
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "misfunctionCodeForChequeBookWaiver",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.misfunctionCodeForChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
                          "label": "Function Code for Cheque Book Waiver",
                          "grid": 6,
                          "conditional": true,
                          "conditionalVarName": "customerStatus",
                          "conditionalVarValue": "Priority",
                          "required": true,
                      })
                  }
                  if (this.state.inputData.customerStatus === "Priority") {
                      dependencyField.push({
                          "varName": "miswaiverTypeForChequeBookWaiver",
                          "type": "autoCompleteValueReturn",
                          "enum": [],
                          //inputData.waiverTypeforChequeBookWaiver=inputData.functionCodeforChequeBookWaiver
                          "label": "Waiver Type  for Cheque Book Waiver",
                          "grid": 6,
                          "conditional": true,
                          "conditionalVarName": "customerStatus",
                          "conditionalVarValue": "Priority",
                          "required": true,
                      })
                  }
                  if (this.state.inputData.basicInformationUpdateminorStatusMinorIndicator === "Y") {
                      dependencyField.push({
                              "varName": "guardianDetailsrelation",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Relation",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsbankRelationType",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Bank Relation Type",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsrelation1",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Relation",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailsguardianSelectionGurdian",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Guardian Selection/Gurdian",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                          },

                          {
                              "varName": "guardianDetailsgurdianCifNumberCustomer",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Gurdian CIF Number/Customer ",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },

                          {
                              "varName": "guardianDetailslastName",
                              "type": "autoCompleteValueReturn",
                              "enum": [],
                              "label": "Last Name",
                              "grid": 6,
                              "conditional": true,
                              "conditionalVarName": "basicInformationUpdateminorStatusMinorIndicator",
                              "conditionalVarValue": "Y",
                              "required": true,
                          },)
                  }*/

                if (percentage !== 0) {
                    if (percentage !== 100) {
                        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                            dependencyField.push({
                                "varName": "percentageNominee" + (i + 1),
                                "type": "text",
                                "label": "Percentage %",
                                "grid": 6,
                                required: true,
                            })

                        }
                        this.setState({
                            loading: false,
                            errorModalBoolean: true,
                            errorMessage: "Nominee Percentage should be 100%",
                        });
                        return 0;

                    }
                }
            }
            /*       let error = MyValidation.defaultValidation(this.emptyValueRemove(data), this.state)

                   this.forceUpdate();
                   console.log(this.state.inputData);

                   console.log("Not working");

                   if (error === true) {
                       console.log("validation")
                       return 0;
                   }

                   this.setState({
                       loading:false,
                       confirmAlert: true,
                       actionType: actionType
                   })*/
        }
        this.setState({
            loading: false,
        });

        //let data = (this.props.commonJsonForm.concat(this.props.commonJsonFormLast));
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();

        if (error === true) {
            console.log("validation");
            return 0;
        } else {
             if(actionType === "handleSubmit"){
                 if(this.state.inputData.numberOfNominee===undefined || this.state.inputData.numberOfNominee===null){

                     this.setState({
                         loading: false,
                         errorModalBoolean: true,
                         errorMessage: "Number Of Nominee Field Mandatory!",
                     });
                     return 0;

                }
             /*   if (this.state.inputData.riskRatingCalculation !== true) {
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorMessage: "Please Add Risk Rating Blog",
                    })
                    return 0;
                }*/
            }
            this.setState({
                confirmAlert: true,
                loading: false,
                actionType: actionType
            })

        }

    };

    handleSubmit = () => {
        // event.preventDefault();
        let saveDataDeferralRemarksAndRoute = {};
        console.log(this.state.agentBankingOutletList);
        let percentage = 0;
        let data = (this.props.commonJsonForm.concat(this.props.commonJsonFormLast,this.state.inputData.riskRatingCalculation===true && this.props.tpAndKyc, this.props.tpAndKyc));
        if (this.state.inputData["numberOfNominee"] !== undefined) {
            for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                percentage += Number(this.state.inputData["percentageNominee" + (i + 1)]);

            }
        }

        /* let error = MyValidation.defaultValidation(this.emptyValueRemove(data), this.state)
         this.forceUpdate();
         if (error === true) {
             //  return 0;
         } */
        this.setState({
            loading: true
        });
        if ((this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null)) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        /*  if (this.state.inputData["deferalNeed"] === "YES") {
              var defType = [];
              var expDate = [];
              var defOther = [];
              let appId = this.props.appId;
              for (let i = 0; i < this.state.values.length; i++) {
                  let value = this.state.values[i];
                  let defferalType = this.state.inputData["defferalType" + value];
                  if (defferalType === "other") {
                      defferalType = this.state.inputData["defferalOther" + value];
                  }
                  defType.push(defferalType);
                  let expireDate = this.state.inputData["expireDate" + value];
                  expDate.push(expireDate);

                  console.log(expDate)
              }

              let deferalRaisedUrl = backEndServerURL + "/deferral/raisedSd/Bulk";
              axios.post(deferalRaisedUrl, {
                  appId: appId,
                  type: defType,
                  dueDate: expDate
              }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                  .then((response) => {
                      console.log(response.data);
                  })
                  .catch((error) => {
                      console.log(error);
                  })
          }*/
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";

        let array = [];
        let arrayCustomerList = [];


        for (let Tagging in this.state.getTaggingList) {

            array.push(this.state.getTaggingList[Tagging]);
        }

        this.state.inputData.tagging = this.state.getTaggingList;
        //this.state.inputData.accountNumber = "2302753028001";


        let inputData = this.emptyValueRemove(this.state.inputData);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        inputData.apiButton = "updateAccountInfo";

        saveDataDeferralRemarksAndRoute.map = inputData;

        data = {};
        data.accountType = this.state.inputData.accType;
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
            data.accountCategory = "individual";
            data.accountType = this.state.inputData.accType;
        } else {
            data.accountCategory = "nonindividual";
            data.accountType = this.state.inputData.accTypes;
        }

        data.cbNumber = this.state.inputData.cbNumber;


        data.accountNumber = this.state.inputData.accountNumber;
        console.log(JSON.stringify(this.state.inputData));
        console.log(JSON.stringify(this.state.varValue));
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal()
                //
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    handleSubmitSave = () => {
        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        this.setState({
            loading: true,
        });
        let data = this.state.inputData;
        data.makerRemarks = undefined;
        data.dataSaved = true;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Saved!",
                    alert: true,
                    loading: false,

                });
                this.props.closeModal()
                //


            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,

                })
            });

    };
    renderCumModalopen = () => {
        this.setState({
            renderCumModalopen: true
        })
    };
    tagging = () => {
        this.setState({
            generateAccountNo: ""
        })
    };


    handleSubmitReturn = () => {
        var percentage = 0;
        let dependencyField = [];
        let saveDataDeferralRemarksAndRoute = {};

        /*  dependencyField.push({

              "varName": "makerRemarks",
              "type": "textArea",
              "required": true,
              "label": "Maker Remarks",
              "grid": 12
          })

          let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state)
          this.forceUpdate();
          if (error === true) {
              return 0;
          }*/
        //this.state.inputData.next_user = this.state.inputData.cs_send_to;
        this.state.inputData.maker_update_all_info_send_to = "CS";
        this.state.inputData.apiButton = "UPDATE";
        this.state.varValue.apiButton = "UPDATE";


        this.setState({
            loading: true,
        });
        if (this.state.inputData.makerRemarks !== undefined && this.state.inputData.makerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.makerRemarks;
        }
        saveDataDeferralRemarksAndRoute.map = this.state.inputData;
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false,
                });
                this.props.closeModal()

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });


    };


    renderJsonFormFirst = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };
    rendertpAndKycFieldShowCheckbox = () => {
        if (this.state.getData  ) {
            return (
                <FormControlLabel

                    control={

                        <CheckBox
                    checked={this.state.inputData.addTpandKycCheckbox}
                    defaultValue={this.state.inputData.addTpandKycCheckbox}
                    //  name="smsAlertRequest"
                    label="TP And KYC Field"
                    onChange={(event) => this.handleTPPopulate(event)}/>

                    }

                    label="Add TP/KYC"

                />
            )
        }
    };
    handleTPPopulate = (event) => {
        event.preventDefault();
        let data = event.target.checked;
        this.state.inputData.addTpandKycCheckbox = data;
        this.updateComponent();
        if( event.target.checked===true){
            this.setState({
                loading: true,
                TpAutopopulateFlag: false,
                showValue: false,
            });
            //let inputData = this.state.inputData;
            //let varValue = this.state.varValue;
            setTimeout(() => {
                this.state.inputData.customerProbableMonthlyIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;
                this.state.varValue.customerProbableMonthlyIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;

                this.state.inputData.TpMonthlyProbableIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;
                this.state.inputData.TpAccountName = this.state.inputData.miscustomerNameLastName;
                this.state.varValue.TpMonthlyProbableIncome = this.state.inputData.employerDatamonthlyIncomeGrossIncome;
                this.state.varValue.TpAccountName = this.state.inputData.miscustomerNameLastName;
                this.forceUpdate();
                this.setState({
                     //inputData: this.emptyValueRemove(this.state.inputData),
                   // varValue: this.emptyValueRemove(this.state.varValue),
                    showValue: true,
                    getValue: true,
                    loading: false,
                    TpAutopopulateFlag: true,
                })

            }, 500)
        }




    };
    handleRiskRating = () => {
        this.setState({
            loading: true,
        });
        this.state.inputData.riskRatingCalculation = false;
        this.state.varValue.riskRatingCalculation = false;
        this.forceUpdate();
        let url =backEndServerURL+"/getKycRiskScoreCalculate/"+this.state.inputData.yearlyPropableTransaction;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData.averageAmountOfYearlyTransactions=response.data.risk;
                this.state.varValue.averageAmountOfYearlyTransactions=response.data.risk;
                setTimeout(() => {
                   // this.state.inputData.accordingBfiuFamily=this.state.inputData.customerFamily;
                   // this.state.varValue.accordingBfiuFamily=this.state.varValue.customerFamily;
                    if (this.state.inputData.customerFamily !==undefined && this.state.inputData.customerFamily.value=== "Yes") {
                        this.state.inputData.accordingBfiuFamily = {
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                        this.state.varValue.accordingBfiuFamily ={
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                    } else if (this.state.inputData.customerFamily !==undefined && this.state.inputData.customerFamily.value=== "No") {
                        this.state.inputData.accordingBfiuFamily = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                        this.state.varValue.accordingBfiuFamily = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                    }

                    //this.state.inputData.accordingBfiuCustomer=this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg;
                   // this.state.varValue.accordingBfiuCustomer=this.state.varValue.isTheCustomerPepHeadHigOfficialIntlOrg;
                   if (this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg!==undefined && this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg.value === "Yes") {
                        this.state.inputData.accordingBfiuCustomer = {
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                        this.state.varValue.accordingBfiuCustomer = {
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                    } else if (this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg!==undefined && this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg.value === "No") {
                        this.state.inputData.accordingBfiuCustomer = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                        this.state.varValue.accordingBfiuCustomer = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                    }
                    console.log(this.state.inputData.accountType)
                    console.log(this.state.varValue.accountType)
                    this.state.inputData.typeProductService = this.state.inputData.TPandKycAccountType;
                    this.state.varValue.typeProductService = this.state.inputData.TPandKycAccountType;
                    this.state.inputData.riskRatingCalculation = true;
                    this.state.varValue.riskRatingCalculation = true;
                    this.forceUpdate();

                    // }
                    this.setState({
                        //inputData: inputData,
                        //varValue: varValue,
                        showValue: true,
                        getValue: true,
                        loading: false,
                    })
                }, 500);
             })
            .catch((error)=>{
                console.log(error);
                setTimeout(() => {
                   // this.state.inputData.accordingBfiuFamily=this.state.inputData.customerFamily;
                   // this.state.varValue.accordingBfiuFamily=this.state.varValue.customerFamily;
                    if (this.state.inputData.customerFamily === "Yes") {
                        this.state.inputData.accordingBfiuFamily ={
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                        this.state.varValue.accordingBfiuFamily ={
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                    } else if (this.state.inputData.customerFamily === "No") {
                        this.state.inputData.accordingBfiuFamily =  {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                        this.state.varValue.accordingBfiuFamily =  {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                    }

                   // this.state.inputData.accordingBfiuCustomer=this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg;
                  //  this.state.varValue.accordingBfiuCustomer=this.state.varValue.isTheCustomerPepHeadHigOfficialIntlOrg;
                   if (this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg!==undefined && this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg.value === "Yes") {
                        this.state.inputData.accordingBfiuCustomer = {
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                        this.state.varValue.accordingBfiuCustomer = {
                            "key": "5",
                            "value": "YES(Risk-5)"
                        };
                    } else if (this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg && this.state.inputData.isTheCustomerPepHeadHigOfficialIntlOrg.value === "No") {
                        this.state.inputData.accordingBfiuCustomer = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                        this.state.varValue.accordingBfiuCustomer = {
                            "key": "0",
                            "value": "NO(Risk-0)"
                        };
                    }
                    console.log(this.state.inputData.accountType)
                    console.log(this.state.varValue.accountType)
                    this.state.inputData.typeProductService = this.state.inputData.TPandKycAccountType;
                    this.state.varValue.typeProductService = this.state.inputData.TPandKycAccountType;
                    this.state.inputData.riskRatingCalculation = true;
                    this.state.varValue.riskRatingCalculation = true;
                    this.forceUpdate();

                    // }
                    this.setState({
                        //inputData: inputData,
                        //varValue: varValue,
                        showValue: true,
                        getValue: true,
                        loading: false,
                    })
                }, 500);
            })



    };
    handleTPPopulateSummation = () => {
        this.setState({
            loading: true,
        });
        //let inputData = this.state.inputData;
        //let varValue = this.state.varValue;
        // else if (data === "cashDepositNumberOfCash" || data === "depositbyNumberOfCash" || data === "foreignInwardNumberOfCash" || data === "depositofIncomeNumberOfCash" || data === "depositTransferNumberOfCash" || data === "othersNumberOfCash") {
        this.state.inputData.totalProbableNumberOfCashApiCall = false;
        this.state.inputData.totalProbableTotalCashApiCall = false;
        this.state.inputData.totalProbableMaximumCashApiCall = false;
        this.state.inputData.totalProbableNumberofWithdrawalApiCall = false;
        this.state.inputData.totalProbableTotalWithdrawalApiCall = false;
        this.state.inputData.totalProbablemaximumAmountApiCall = false;

        this.forceUpdate();
        setTimeout(() => {
            let a = this.state.inputData.cashDepositNumberOfCash;
            let b = this.state.inputData.depositbyNumberOfCash;
            let c = this.state.inputData.foreignInwardNumberOfCash;
            let d = this.state.inputData.depositofIncomeNumberOfCash;
            let e = this.state.inputData.depositTransferNumberOfCash;
            let f = this.state.inputData.othersNumberOfCash;

            if (a === undefined) {
                b = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }

            let total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableNumberOfCashApiCall = true;
            this.state.inputData.totalProbableNumberOfCash = total;
            this.state.varValue.totalProbableNumberOfCash = total;
            // }
            // else if (data === "cashDepositTotalCash" || data === "depositbyTotalCash" || data === "foreignInwardTotalCash" || data === "depositofIncomeTotalCash" || data === "depositTransferTotalCash" || data === "othersTotalCash") {

            a = this.state.inputData.cashDepositTotalCash;
            b = this.state.inputData.depositbyTotalCash;
            c = this.state.inputData.foreignInwardTotalCash;
            d = this.state.inputData.depositofIncomeTotalCash;
            e = this.state.inputData.depositTransferTotalCash;
            f = this.state.inputData.othersTotalCash;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            let totalProbableTotalCash = 0;
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableTotalCashApiCall = true;
            this.state.inputData.totalProbableTotalCash = total;
            this.state.varValue.totalProbableTotalCash = total;
            console.log(this.state.inputData.totalProbableTotalCash);
            totalProbableTotalCash = total;
            // }
            //else if (data === "cashDepositMaximumCash" || data === "depositbyMaximumCash" || data === "foreignInwardMaximumCash" || data === "depositofIncomeMaximumCash" || data === "depositTransferMaximumCash" || data === "othersMaximumCash") {

            a = this.state.inputData.cashDepositMaximumCash;
            b = this.state.inputData.depositbyMaximumCash;
            c = this.state.inputData.foreignInwardMaximumCash;
            d = this.state.inputData.depositofIncomeMaximumCash;
            e = this.state.inputData.depositTransferMaximumCash;
            f = this.state.inputData.othersMaximumCash;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableMaximumCashApiCall = true;
            this.state.inputData.totalProbableMaximumCash = total;
            this.state.varValue.totalProbableMaximumCash = total;
            console.log(this.state.inputData.totalProbableNumberOfCash);

            //  }
            // else if (data === "cashWithdrawalNumberofWithdrawal" || data === "withdrawalthroughNumberofWithdrawal" || data === "foreignOutwardNumberofWithdrawal" || data === "paymentAgainstNumberofWithdrawal" || data === "depositTransferNumberofWithdrawal" || data === "othersNumberofWithdrawal") {

            a = this.state.inputData.cashWithdrawalNumberofWithdrawal;
            b = this.state.inputData.withdrawalthroughNumberofWithdrawal;
            c = this.state.inputData.foreignOutwardNumberofWithdrawal;
            d = this.state.inputData.paymentAgainstNumberofWithdrawal;
            e = this.state.inputData.depositTransferNumberofWithdrawal;
            f = this.state.inputData.othersNumberofWithdrawal;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableNumberofWithdrawalApiCall = true;
            this.state.inputData.totalProbableNumberofWithdrawal = total;
            this.state.varValue.totalProbableNumberofWithdrawal = total;
            console.log(this.state.inputData.totalProbableNumberofWithdrawal);

            // }
            // else if (data === "cashWithdrawalTotalWithdrawal" || data === "withdrawalthroughTotalWithdrawal" || data === "foreignOutwardTotalWithdrawal" || data === "paymentAgainstTotalWithdrawal" || data === "depositTransferTotalWithdrawal" || data === "othersTotalWithdrawal") {

            a = this.state.inputData.cashWithdrawalTotalWithdrawal;
            b = this.state.inputData.withdrawalthroughTotalWithdrawal;
            c = this.state.inputData.foreignOutwardTotalWithdrawal;
            d = this.state.inputData.paymentAgainstTotalWithdrawal;
            e = this.state.inputData.depositTransferTotalWithdrawal;
            f = this.state.inputData.othersTotalWithdrawal;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            let totalProbableTotalWithdrawal = 0;
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbableTotalWithdrawalApiCall = true;
            this.state.inputData.totalProbableTotalWithdrawal = total;
            this.state.varValue.totalProbableTotalWithdrawal = total;
            console.log(this.state.inputData.totalProbableTotalWithdrawal);
            totalProbableTotalWithdrawal = total;
            // }
            // else if (data === "cashWithdrawalmaximumAmount" || data === "withdrawalthroughmaximumAmount" || data === "foreignOutwardmaximumAmount" || data === "paymentAgainstmaximumAmount" || data === "depositTransfermaximumAmount" || data === "othersmaximumAmount") {

            a = this.state.inputData.cashWithdrawalmaximumAmount;
            b = this.state.inputData.withdrawalthroughmaximumAmount;
            c = this.state.inputData.foreignOutwardmaximumAmount;
            d = this.state.inputData.paymentAgainstmaximumAmount;
            e = this.state.inputData.depositTransfermaximumAmount;
            f = this.state.inputData.othersmaximumAmount;
            if (a === undefined) {
                a = 0
            }
            if (b === undefined) {
                b = 0
            }
            if (c === undefined) {
                c = 0
            }
            if (d === undefined) {
                d = 0
            }
            if (e === undefined) {
                e = 0
            }
            if (f === undefined) {
                f = 0
            }
            total = Number(a) + Number(b) + Number(c) + Number(d) + Number(e) + Number(f);
            this.state.inputData.totalProbablemaximumAmountApiCall = true;
            this.state.inputData.totalProbablemaximumAmount = total;
            this.state.varValue.totalProbablemaximumAmount = total;
            let yearLyPropable = (Number(totalProbableTotalCash) + (totalProbableTotalWithdrawal)) * 12;
            this.state.inputData.yearlyPropableTransaction = yearLyPropable;
            this.state.varValue.yearlyPropableTransaction = yearLyPropable;
            this.forceUpdate();

            // }
            this.setState({
                //inputData: inputData,
                //varValue: varValue,
                showValue: true,
                getValue: true,
                loading: false,
            })
        }, 500);


    };
    handleKycPopulate = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
            kycAutopopulateFlag: false,
        });
        //let inputData = this.state.inputData;
        // let varValue = this.state.varValue;
        /*  inputData.passportNo = inputData.passportNumberpassportNumberUniqueId;
          varValue.passportNo = varValue.passportNumberpassportNumberUniqueId;
          inputData.nationalIdCardNo = inputData.nationalIdCardNumberUniqueId;
          varValue.nationalIdCardNo = varValue.nationalIdCardNumberUniqueId;
          inputData.nameOfOfficerOpeningTheAccount = inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
          varValue.nameofOfficerOpeningtheAccount = varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
          inputData.sourceOfFundKyc = inputData.TpSourceofFundForTransaction;
          varValue.sourceOfFundKyc = varValue.TpSourceofFundForTransaction;




          inputData.customerAccount=inputData.

              inputData.aCTitle=inputData.

              inputData.accountType=inputData.



              inputData.customerProbableMonthlyIncome=inputData.

              inputData.accountOrReference=inputData.

              inputData.nameofOfficerOpeningtheAccount=inputData.

              inputData.sourceOfFundKyc=inputData.

              inputData.passportNo=inputData.

              inputData.nationalIdCardNo=inputData.

              inputData.birthCertificateNo=inputData.

              inputData.electronicTaxId=inputData.*/

        //inputData.drivingLicenceNo=inputData.

        setTimeout(() => {
            this.state.inputData.customerProbableMonthlyIncome = this.state.inputData.TpMonthlyProbableIncome;
            this.state.inputData.nameofOfficerOpeningtheAccount = this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            this.state.inputData.sourceOfFundKyc = this.state.inputData.TpSourceofFundForTransaction;
            this.state.inputData.aCTitle = this.state.inputData.miscustomerNameLastName;
            this.state.inputData.passportNo = this.state.inputData.passportNumberpassportNumberUniqueId;
            this.state.inputData.nationalIdCardNo = this.state.inputData.nationalIdCardNumberUniqueId;
            this.state.inputData.birthCertificateNo = this.state.inputData.birthCertificatebirthCertificateNumberUniqueId;
            this.state.inputData.electronicTaxId = this.state.inputData.etineTinNumber;
            this.state.inputData.electronicTaxId = this.state.inputData.etineTinNumber;
            this.state.inputData.expectedAmountOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableTotalCash) + Number(this.state.inputData.totalProbableTotalWithdrawal);
            this.state.inputData.expectedNumberOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableNumberOfCash) + Number(this.state.inputData.totalProbableNumberOfCash);
            this.state.inputData.expectedAmountOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositTotalCash) + Number(this.state.inputData.cashWithdrawalTotalWithdrawal);
            this.state.inputData.expectedNumberOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositNumberOfCash) + Number(this.state.inputData.cashWithdrawalNumberofWithdrawal);

            this.state.varValue.customerProbableMonthlyIncome = this.state.inputData.TpMonthlyProbableIncome;
            this.state.varValue.nameofOfficerOpeningtheAccount = this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            this.state.varValue.sourceOfFundKyc = this.state.inputData.TpSourceofFundForTransaction;
            this.state.varValue.aCTitle = this.state.inputData.miscustomerNameLastName;
            this.state.varValue.passportNo = this.state.inputData.passportNumberpassportNumberUniqueId;
            this.state.varValue.nationalIdCardNo = this.state.inputData.nationalIdCardNumberUniqueId;
            this.state.varValue.birthCertificateNo = this.state.inputData.birthCertificatebirthCertificateNumberUniqueId;
            this.state.varValue.electronicTaxId = this.state.inputData.etineTinNumber;

            this.state.varValue.expectedAmountOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableTotalCash) + Number(this.state.inputData.totalProbableTotalWithdrawal);
            this.state.varValue.expectedNumberOfMonthlyTotalTransactions = Number(this.state.inputData.totalProbableNumberOfCash) + Number(this.state.inputData.totalProbableNumberOfCash);
            this.state.varValue.expectedAmountOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositTotalCash) + Number(this.state.inputData.cashWithdrawalTotalWithdrawal);
            this.state.varValue.expectedNumberOfMonthlyCashTransactions = Number(this.state.inputData.cashDepositNumberOfCash) + Number(this.state.inputData.cashWithdrawalNumberofWithdrawal);
            this.forceUpdate();
            this.setState({
                //inputData: inputData,
                //varValue: varValue,
                showValue: true,
                getValue: true,
                loading: false,
                kycAutopopulateFlag: true,
            })
        }, 500)


    };
    autopopulateButtonKyC = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleKycPopulate(event)}

                    >Add KYC
                    </button>
                </div>

            )
        }
    };

    renderRiskRatingBlog = () => {
        if (this.state.TpAutopopulateFlag && this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleRiskRating(event)}

                    >Risk Rating calculation
                    </button>
                </div>

            )
        }
    };
    autopopulateSummationTP = () => {
        if (this.state.TpAutopopulateFlag && this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleTPPopulateSummation(event)}

                    >Auto Population Summation
                    </button>
                </div>

            )
        }
    };

    renderJsonFormLast = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& this.state.getCity && this.state.getState && this.state.getCountry && this.state.getCurrency*/) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormLast, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };

    renderTPForm = () => {
        if (this.state.inputData.addTpandKycCheckbox===true && this.state.showValue===true &&   this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.tpAndKyc, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNominee"] = option.title;
            this.forceUpdate()
        } else {
            this.state.inputData["numberOfNominee"] = option;
            this.forceUpdate()
        }

    };


    renderNomineeNumber = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {

            //if (this.props.delStatus !== "Return" && this.props.delStatus !== "RETURN") {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={this.state.inputData.numberOfNominee}
                                      options={numberOfNominee} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"

                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>


            )

        }
    };
    renderRemarksData = () => {


        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet && this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };

    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0 && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {

            return (
                <div style={{marginTop: "5px"}}>

                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    renderButton = () => {
        if (this.state.loading === false) {
            if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
                return (

                    <div style={{marginTop: "5px", marginBottom: "5px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                        >Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleCommon(event, "handleSubmitSave")}
                        >Save
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleCommon(event, "handleSubmitReturn")}

                        >Return
                        </button>
                    </div>

                )
            }
        }
    };

    closeModal = (account) => {

        this.setState({
            renderCumModalopen: false,
            getMappingCropImage: false,
            errorModalBoolean: false,
            cbGenerateModal: false,
            uploadModal: false,
            getMappingAllImage: false,
            generateAccountNo: account,
            imageCropModal: false
        });
        this.renderImageCrop()
    };
    renderWithoutDedupeComponent = () => {
        if (this.state.getData) {
            return (
                <WithoutDedupeComponent freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                                        freeFlag4={this.props.freeFlag4}
                                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                        introducerName={this.state.inputData.primryiintroducerNameLastName}
                                        appId={this.props.appId}
                                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                        removingTaggingData={this.removingTaggingData}
                                        renderTagging={this.renderTagging}
                                        getTaggingData={this.getTaggingData}
                                        subServiceType={this.props.subServiceType} appId={this.props.appId}/>
            )
        }
    };

    renderDedupComponent = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet && this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.props.appId}
                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        } else if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.props.appId}
                        category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentForAgentBanking = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupeResultFunctionForAgentBanking
                        freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                        accountNumber={this.props.accountNumber} solId={this.props.solId}
                        freeFlag4={this.props.freeFlag4}
                        appId={this.props.appId}
                        introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                        introducerName={this.state.inputData.primryiintroducerNameLastName} appId={this.props.appId}
                        radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                        removingTaggingData={this.removingTaggingData}
                        renderTagging={this.renderTagging}
                        getTaggingData={this.getTaggingData}
                        subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                                 accountNumber={this.props.accountNumber} solId={this.props.solId}
                                                 freeFlag4={this.props.freeFlag4}
                                                 introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                                 introducerName={this.state.inputData.primryiintroducerNameLastName}
                                                 appId={this.props.appId} category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction freeFlag1={this.props.freeFlag1} customerName={this.props.customerName}
                                       accountNumber={this.props.accountNumber} solId={this.props.solId}
                                       freeFlag4={this.props.freeFlag4}
                                       introducerId={this.state.inputData.primryintroducerCustomerIdCifId}
                                       introducerName={this.state.inputData.primryiintroducerNameLastName}
                                       appId={this.props.appId} category={this.props.category}
                                       taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };


    createTaggingData = (data) => {
        return (
            [data.cbNumber, data.customerName, data.relationCode, <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
            >Edit
            </button>,
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDelete(event, data.id)}
                >Delete
                </button>
            ]


        )
    };

    renderTagging = () => {
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));

                });
                this.setState({
                    getTagList: tableArrayData
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            });
        console.log(tableArrayData)


    };

    renderTagListPrevious = () => {

        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet /*&& (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/ && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>

                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left']}
                    />


                </div>


            )
        }

    };
    renderTagList = () => {

        if (this.state.getData && /*(this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C") && */ this.state.getTagList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Tagging List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer No", "CB Number", "Action"]}

                            tableData={this.state.getTagList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>


                    </div>

                </div>

            )
        }

    };
    renderImageCrop = () => {
        if ((backEndServerURL + "/signaturePhoto/" + this.props.appId + "/signature" !== undefined)) {
            return (

                <img width='100%' src={backEndServerURL + "/signaturePhoto/" + this.props.appId + "/signature"} alt=""/>
            )
        }


    };

    getTaggingData = (index, data, customerNo) => {

        /*  console.log(index)
          console.log(data)
          console.log(customerNo);
          let customerList=[];
          customerList.push(data);
          customerList.push(customerNo);
          this.state.getTaggingList["customer"+index] = customerList;
          //this.state.getTaggingCustomerList[index] = customerNo;
          this.setState({
              tagClick: true
          })
          console.log(this.state.getTaggingList);*/
        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });
                this.setState({
                    getTagList: tableArrayData,

                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    cropImage = (event) => {
        event.preventDefault();
        this.setState({
            imageCropModal: true
        })
    };
    renderRemarks = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {

            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}

                </div>

            )
        }

    };
    renderCropedImage = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (

                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Croped Image
                </button>

            )
        }
    };
    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };
    closeCropImage = () => {
        this.setState({
            getMappingCropImage: false
        })
    };
    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false

        })
    };
    tagCbDetailsModal = (event, customer, requestType, cb, relationCode) => {
        event.preventDefault();
        this.setState({
            requestType: requestType,
            tagCbDetailsModal: true,
            tagCb: cb,
            customerNumber: customer,
            linkrelationCode: relationCode
        })

    };
    tagCbDelete = (event, id) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let url = backEndServerURL + "/secondaryCB/delete/" + id;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArrayData = [];
                let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
                axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        response.data.map((data, index) => {
                            tableArrayData.push(this.createTaggingData(data));
                        });
                        this.setState({
                            loading: false,
                            getTagList: tableArrayData,

                        });

                        this.forceUpdate();
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                        });
                        console.log(error);
                    })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                });
                console.log(error);
            })

    };

    renderSignatureCardCrop = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }
    };


    renderNomineeFormReturn = () => {
        if (this.state.getData && this.state.accountDataAfterSet && this.state.cbDataAfterSet) {
            var sl;
            objectForNominee = [];
            let inputData = this.state.inputData;
            let varValue = this.state.varValue;
            if (this.state.inputData["numberOfNominee"] !== undefined && this.state.inputData["numberOfNominee"] !== null) {
                objectForNominee.push(
                    {
                        "type": "title",
                        "label": "Nominee",
                        "grid": 12,
                    },
                    {
                        "varName": "nomineeRegNo",
                        "type": "text",
                        "label": "Registration No",
                        "grid": 6,
                        "required": true,

                    },
                )
            }

            for (var i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                sl = i + 1;
                //inputData["nomineecifIdApiCall"+i]=false
                //varValue["nomineecifIdApiCall"+i]=false
                objectForNominee.push(
                    {
                        "varName": "Nominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "label": "Input CIF ID/INFORMATION ?",
                        "grid": 6,


                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nomineeRelationShipCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    /*  {
                            "varName": "nomineenomineephotoIdNo" + i,
                            "type": "text",
                            "label": "NOMINEE PHOTO ID NO.",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "nomineecifIdApiCall" + i,
                            "conditionalVarValue": true,
                        },
        */
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {

                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "validationB": "length",
                        "maxLength": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "validationB": "length",
                        "maxLength": 6,
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "text",
                        "label": "D.O.B(YYYY-MM-DD)",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        /*    "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",*/
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        /*  "conditional": true,
                          "conditionalVarName": "search" + sl,
                          "conditionalVarValue": "INFORMATION",*/
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "validationB": "length",
                        "maxLength": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["CIF ID", "YES"],
                        "required": true,
                    },

                    //Information guardian details
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "validationB": "length",
                        "maxLength": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeMinor" + sl],
                        "conditionalArrayValue": ["INFORMATION", "YES"],
                        "required": true,
                    },
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    onKeyDownChange = (data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 500);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }

        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "autoPopulationSummation") {
            this.handleTPPopulateSummation()
        } else if (data.varName === "riskRatingcalculation") {
            this.handleRiskRating()
        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.varValue.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                console.log(stringToDate);
                console.log(this.state.inputData.basicInformationUpdatedob);
                console.log(inputDate);


                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }


                this.forceUpdate()

            }, 500)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data, secondVarName) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.mailingAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    renderUploadButton = () => {
        if (this.state.getData) {
            return (

                <button

                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }

    };
    submitModal = (data) => {
        this.state.inputData.previewImage = true;
        this.updateComponent();
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };
    renderMappingImageEditModal = () => {


        return (
            <FileMappingEdit dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.props.appId}
                             closeModal={this.closeModal}/>
        )

    };
    renderMappingImageModal = () => {
        return (
            <FileMapping condition={true} dropDownConditionOption={arrayListIndividualCondition}
                         dropDownOption={arrayListIndividual}
                         submitModal={this.submitModal}
                         appId={this.props.appId}
                         closeModal={this.closeModal}/>
        )

    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.renderCumModalopen}>
                        <DialogContent>
                            <MakerCumInput closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <SecondaryCbFormDetails freeFlag1={this.props.freeFlag1}
                                                    customerName={this.props.customerName}
                                                    accountNumber={this.props.accountNumber} solId={this.props.solId}
                                                    freeFlag4={this.props.freeFlag4}
                                                    relationCode={this.state.linkrelationCode}
                                                    requestType={this.state.requestType}
                                                    customerNumber={this.state.tagCb} appId={this.props.appId}
                                                    tagCb={this.state.tagCb}
                                                    customerNumber={this.state.customerNumber}
                                                    makerEnd={true}
                                                    cbNumber={this.state.tagCb}
                                                    tagForm={CBDataJsonForm}
                                                    renderTagging={this.renderTagging}
                                                    closeModal={this.tagCbDetailsModalClose}/>
                            {/*  <MakerCumInput customerNumber={this.state.getCustomerNumber} appId={this.props.app_uid} generateAccountNo={this.state.generateAccountNo} tagingModalCbnumber={this.state.tagCb} taging={this.taging} closeModal={this.tagCbDetailsModalClse}/>*/}
                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="lg"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.imageCropModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ImageCrop subServiceType={this.props.subServiceType} appId={this.props.appId}
                                       closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>

                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.getMappingCropImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImageEdit caseId={this.props.caseId} subCategory={this.props.subCategory}
                                               serviceType={this.props.serviceType}
                                               subServiceType={this.props.subServiceType} appId={this.props.appId}
                                               closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.errorModalBoolean}>
                        <DialogContent className={classes.dialogPaper}>
                            <ErrorModal title={this.state.errorMessage} closeModal={this.closeModal}
                                        subServiceType={this.props.subServiceType} appId={this.props.appId}
                            />

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbGenerateModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ErrorModal title="Your New CB Number Is : " closeModal={this.closeModal}
                                        subServiceType={this.props.subServiceType} appId={this.props.appId}
                            />

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.history}>
                        <DialogContent className={classes.dialogPaper}>
                            <SubmittedCaseHistory customerName={this.state.customerName}
                                                  accountNumber={this.state.accountNumber} solId={this.state.solId}
                                                  globalSearch={true} closeIcon={this.closeHistoryModal}
                                                  appId={this.state.appId} closeModal={this.closeHistoryModal}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.uploadModal}>
                        <DialogContent>
                            {this.renderMappingImageModal()}

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            {this.renderMappingImageEditModal()}

                        </DialogContent>
                    </Dialog>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {this.renderNotification()}

                            <Grid item xs={12}>
                                {this.renderDownLoadFileLink()}
                            </Grid>
                            {this.renderJsonFormFirst()}


                            <Grid item xs={6}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderNomineeFormReturn()}
                            {this.renderJsonFormLast()}
                            <Grid item xs={12}>
                                {this.rendertpAndKycFieldShowCheckbox()}
                            </Grid>


                            {this.renderTPForm()}
                            {/*   <Grid item xs={12}>
                                {this.autopopulateSummationTP()}
                            </Grid>*/}
                            {/* <Grid item xs={12}>
                                {this.autopopulateButtonKyC()}
                            </Grid>
                            {this.renderKYCForm()}*/}
                         {/*   <Grid item xs={12}>
                                {this.renderRiskRatingBlog()}
                            </Grid>
*/}

                        </ThemeProvider>
                    </Grid>

                    {this.renderDedupComponentForAgentBanking()}
                    {this.renderDedupComponent()}

                    {this.renderDedupComponentMandateAndBeneficiary()}
                    {this.renderTagListPrevious()}
                    {this.renderSdnComponent()}
                    {/* {this.renderTagList()}*/}


                    <ThemeProvider theme={theme}>
                        <div style={{marginTop: "5px"}}>
                            {this.renderSignatureCardCrop()}
                            &nbsp;
                            {this.renderCropedImage()}
                        </div>
                        <br/>
                        <div style={{marginTop: "5px"}}>
                            {this.renderUploadButton()}
                            &nbsp;
                            {this.renderAssignedImage()}
                        </div>
                        <br/>
                        {this.renderFlowSUmmeryButton()}
                        <br/>
                        <DeferalList appId={this.props.appId}/>
                        <br/>
                        <WaiverList appId={this.props.appId}/>
                        {this.renderDefferalData()}
                        {this.renderRemarksData()}
                        {this.renderRemarks()}
                    </ThemeProvider>

                    {this.renderButton()}

                </div>
            </GridList>
        )

    }


}

export default withStyles(styles)(VerifyMakerInboxCase);

