import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class CustomerCreditCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            getData: false,
            renderCustomerCreditCardSummeryInfo: false,
            redirectLogin: false,
        }
    }

    componentDidMount() {
        console.log(this.props.cardNo);
        if (this.props.cardNo !== undefined) {

            let url = backEndServerURL + "/demographic/card/" + this.props.cardNo;

            let varValue = {};


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(this.props.idClient);
                    console.log(response.data);


                    if (response.data !== null && response.data !== undefined) {
                        let data = response.data;
                        /* varValue.LASTSTROUTSTANDINGBDT = data.LASTSTROUTSTANDINGBDT;
                         varValue.CARDHOLDERNAME = data.CARDHOLDERNAME;

                         varValue.CONTRACTSTATUS = data.CONTRACTSTATUS;
                         varValue.LASTSTATEMENTDATE =data.LASTSTATEMENTDATE;

                         varValue.LASTSTROUTSTANDINGUSD =data.LASTSTROUTSTANDINGUSD;
                         varValue.CURRENTOUTSTANDINGBDT = data.CURRENTOUTSTANDINGBDT;

                         varValue.LASTPAYMENTUSD = data.LASTPAYMENTUSD;
                         varValue.CONTRACTTYPE = data.CONTRACTTYPE;

                         varValue.CONTRACTNO = data.CONTRACTNO;
                         varValue.CURRENTOUTSTANDINGUSD = data.CURRENTOUTSTANDINGUSD;

                         varValue.CLIENTID = data.CLIENTID;
                         varValue.LASTPAYMENTBDT = data.LASTPAYMENTBDT;

 */


                        this.setState({
                            varValue: data,
                            renderCustomerCreditCardSummeryInfo: true

                        })


                    } else {
                        this.setState({
                            varValue: {},
                            renderCustomerCreditCardSummeryInfo: true

                        })
                    }


                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                    this.setState({
                        renderCustomerCreditCardSummeryInfo: true

                    })
                });
        } else {

        }
    }

    renderCustomerCreditCardSummeryInfo = () => {
        if (this.state.renderCustomerCreditCardSummeryInfo === true) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Card Holder Name", this.state.varValue.CARDHOLDERNAME],
                        ["Card NO", this.state.varValue.CARDNO],
                        ["Outstanding Balance BDT", this.state.varValue.CURRENTOUTSTANDINGBDT],
                        ["Outstanding Balance USD", this.state.varValue.CURRENTOUTSTANDINGUSD],
                        ["Client ID", this.state.varValue.CLIENTID],
                        ["Minmimum Payment BDT", this.state.varValue.MINIMUMTPAYMENTBDT],
                        ["Minmimum Payment USD", this.state.varValue.MINIMUMPAYMENTUSD],
                        ["Last Outstanding BDT", this.state.varValue.LASTSTROUTSTANDINGBDT],
                        ["Last Outstanding USD", this.state.varValue.LASTSTROUTSTANDINGUSD],
                        ["RM Code", this.state.varValue.RMCODE],
                        // ["Minimum Payment Due", this.state.varValue[""] ],
                        // ["Non Payment Of Minimum Payment", this.state.varValue[""] ],
                        ["Last Payment BDT", this.state.varValue.LASTPAYMENTBDT],
                        ["Last Payment USD", this.state.varValue.LASTPAYMENTUSD],
                        // ["Interest Accrued", this.state.varValue[""] ],


                    ]}

                    tableAllign={['left', 'left']}
                />
            )
        } else if (this.state.renderCustomerCreditCardSummeryInfo === false) {

            return (
                <CircularProgress style={{marginLeft: '50%'}}/>
            )
        } else {

        }

    };
    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >Card Details<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderCustomerCreditCardSummeryInfo()}

                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(CustomerCreditCard);
