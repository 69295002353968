import React, {Component} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import SignatureButton from "../../Maintenance/SignatureButton.jsx";
import {InterestTransferCsoForm} from "./InterestTransferJsonForm";
import CommonApi from "../../Maintenance/common/CommonApi";
import Functions from "../../../../Common/Functions";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CBNotFound from "../../CASA/CBNotFound";
import functionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class CsoRequisition extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            uploadStatus: "",
            submitButtonClick: false,
            cbNotFound: false,
            actionType: "",
            confirmAlert: false,
            loanMasterData: [],
            submission: false,
        };


    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    componentDidMount() {

        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then(response => {
                let inputData = response.data;
                inputData.csRemarks = undefined;

                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileData) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            varValue: this.copyJson(inputData),
                            inputData: this.copyJson(inputData),
                            appId: this.props.appId,
                            getImageLink: fileData,
                            getImageBoolean: true,
                            imageName: fileData,
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            loading: false,
                            submission: true,
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }).catch(error => {
                console.log(error);
            });
        } else {
            let start_url = assetOpsBackEndServerURL + "/startCase/cs_case_start_interest_transfer_request";
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        getData: true,
                        showValue: true,
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.state.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false,
            loading: true,
        });
        if (status === "success" || status === "No File Found") {
            let saveDataDeferralRemarksAndRoute = {};
            if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
            }
            let postData = this.state.inputData;
            postData.category = "Other Service Request";
            postData.subCategory = this.state.inputData.interestRequestType;
            postData.serviceType = "Other Service Request";
            postData.subServiceType = "Other Service Request";
            saveDataDeferralRemarksAndRoute.map = postData;
            let caseRouteUrl =
                assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios
                .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: response.data,
                        alert: true,
                        getData: false,
                        loading: false,
                    });
                    if (this.props.appId === undefined) {
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.props.closeModal();
                    }

                })
                .catch(error => {
                    this.setState({
                        loading: false,
                    })
                    console.log(error);
                });


        } else {
            this.setState({
                cbNotFound: true,
                loading: false,
                title: "Failed to Upload File."
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    saveHandler = () => {
        this.setState({
            submitButtonClick: true
        });

    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Other Service Request</b><p style={{fontSize: "16px"}}>(<b> Customer
                    Name:</b>{this.state.inputData.customerName !== undefined ? this.state.inputData.customerName : ""}
                    <b> A/C:</b>{this.state.inputData.accountNumber !== undefined ? this.state.inputData.accountNumber : ""}
                    <b> CB Number:</b>{this.state.inputData.cbNumber !== undefined ? this.state.inputData.cbNumber : ""}
                    <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""} <b> Case
                        ID:</b>{this.props.caseId !== undefined ? this.props.caseId : ""} )<a><CloseIcon
                        onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Other Service Request
                </h4>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = InterestTransferCsoForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                InterestTransferCsoForm[i].enum = getValue;
            }
        }


    };


    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                submission: false,
                loading: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;


            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
                loanMasterData: [],

            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getAllLoanAndOdAccounts/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    let loanMasterData = response.data.loanMasterData;
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        submission: false,
                        loanMasterData: loanMasterData,
                        showValue: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        submission: false,
                        title: error.response.data.message,
                    })
                })
        }
    }
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };
    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    getCasaAccountDetails(casaAccount) {
        let inputData = {...this.state.inputData};
        this.setState({
            inputData: inputData,
            varValue: this.removeNullValue(inputData),
            loading: true
        });
        CommonApi.getCustAccountDetails(casaAccount).then((response) => {
            inputData.casaAccountStatus = response.data.acctStatus;
            inputData.loanAccountsCBApiCall = "YES";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
            })
            // this.getAccountInfo(casaAccount);

        }).catch((error) => {
            this.setState({
                cbNotFound: true,
                title: error.response.data.message,
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false
            })
        });
    }

    findDataFromMasterData = (value, masterData) => {
        let jsonArray = masterData;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.loanAccount) {
                return jsonObject;
            }
        }
    };
    onKeyDownChange = (data) => {

        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
        }

        else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            let loanAccountmasterData = this.findDataFromMasterData(this.state.inputData[data.varName], this.state.loanMasterData);
            if (loanAccountmasterData.schemeType === "LAA") {
                let lonAccountInfoUrl = assetOpsBackEndServerURL + "/getLoanAccountInfo/" + this.state.inputData[data.varName];
                axios.get(lonAccountInfoUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((loanAccountInfoResponse) => {
                        inputData.accountNumber = this.state.inputData[data.varName];
                        inputData.loanOutstanding = loanAccountInfoResponse.data.closingBal;
                        inputData.loanAccountStatus = loanAccountInfoResponse.data.accStatus;
                        inputData.loanAccountsCBApiCall = "YES";
                        this.setState({
                            inputData: inputData,
                            varValue: this.removeNullValue(inputData),
                            showValue: true,
                            submission: true,
                            loading: false
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            submission: false,
                            title: error.response.data.message,
                        })
                    })
            } else {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    submission: false,
                    title: "Scheme type is not LAA",
                })
            }
        }
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, InterestTransferCsoForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    }
    closeConfirmAlert = (value) => {
        if (value === "ok" && this.state.actionType === "saveHandler") {
            this.saveHandler()
        }
        this.setState({
            confirmAlert: false,
        });
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "saveHandler") {

            let error = MyValidation.defaultValidation(InterestTransferCsoForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        this.validationForHandleConfirm(type)
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false) {
            return (
                <div>
                    {this.state.submission && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginRight: "10px",
                        }}
                        onClick={(event) => this.handleCommon(event, "saveHandler")}
                    >
                        Submit
                    </button>}
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            marginLeft: "10px"
                        }}
                        onClick={this.printLoanClosingForm}
                    >
                        Print
                    </button>
                </div>
            )
        }
    };
    printLoanClosingForm = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        let inputData = this.state.inputData;
        let url = assetOpsBackEndServerURL + "/getOtherServiceRequestForm";
        functionForFileDownload.downloadFile(url, "POST", inputData, "Other_Service_Request_Form.pdf").then((response) => {
            this.setState({
                loading: false
            })
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })


    }

    renderSignature = () => {
        if (this.state.inputData.casaAccount && this.state.getData) {
            return (
                <div>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.casaAccount} signatureType="multiple"
                                     classes={this.props}/>
                </div>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }

        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderForm()}
                                    {/*{this.renderSignature()}*/}
                                    {/*{this.state.getImageBoolean && <Grid item xs={12}></Grid>}*/}
                                    {this.mappingPhoto()}
                                    {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                                    {this.renderDynamicFileUpload()}
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.confirmAlert}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ConfirmationModal
                                                closeModal={this.closeConfirmAlert}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderRemarksData()}
                                    {this.renderFlowSUmmeryButton()}
                                    <Grid item xs={12}></Grid>
                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            {this.renderSubmitButton()}
                        </Grid>
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(CsoRequisition);
