import React, {Component} from "react";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../Common/Constant";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import CBNotFound from "../workflow/CASA/CBNotFound";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../Static/loader.gif";
import axiosDownload from "../DeliverableManagement/FunctionForFileDownload";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Reports from "../Greendelta/Reports.js";

let workplaceUnits=[];
let searchFormOneOutGoing = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 3,
        required: true,
        maxDate: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 3,
        required: true,
        maxDate: true
    },
    {
        varName: "status",
        type: "select",
        label: "Current Status",
        enum: ["All","New","Received","Allocated","Picked","Cancel","Lost","Delivered"],
        grid: 2,
    },
 
    {
        varName: "senderName",
        type: "text",
        label: "Sender Name",
        grid: 2,
    },
    {
        varName: "docType",
        type: "select",
        enum: [],
        label: "Type of Document",
        grid: 2,
    }

];
let searchFormOneInComing = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 3,
        required: true,
        maxDate: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 3,
        required: true,
        maxDate: true
    },
    {
        varName: "status",
        type: "select",
        label: "Current Status",
        enum: ["All", "My Request", "Return", "Delivered"],
        grid: 2,
    },
    {
        varName: "docType",
        type: "select",
        label: "Document Type",
        enum: ["Officials", "By Name"],
        grid: 2,
    },
    {
        varName: "reciverName",
        type: "text",
        label: "Receiver Name",
        grid: 2,
    } 

];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class GreenDeltaRepost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            selectedDate: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            cbNotFound: false,
            alert: false,
            title: "",
            notificationMessage: "",
            loading: false,
            currentDate: 0,
            SelectedDropdownSearchData: null,
            autoComplete: {},
            reportType: this.props.match.params.reportType,
            userObject: {},
            getData: false,
            dropdownSearchData: {},
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    findByVarNameKycMasterDataApiData = (searchVarname, getValue) => {
        let kycArrayList = searchFormOneOutGoing;
        for (let i = 0; i < kycArrayList.length; i++) {
          let jsonObjectLast = kycArrayList[i];
          if (jsonObjectLast.varName === searchVarname) {
            searchFormOneOutGoing[i].enum = getValue;
          }
        }
      };

      componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.reportType !== prevProps.match.params.reportType) {
            this.setState({
                inputData: {},
                varValue: {},
                err: false,
                errorArray: {},
                errorMessages: {},
                getData:false,
                reportType: this.props.match.params.reportType,
            })
            if(this.props.match.params.reportType==="outgoing"){
               
                let urls = backEndServerURL + "/get-document-types";
                axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                    .then((documentTypes) => {
                        this.findByVarNameKycMasterDataApiData("docType", documentTypes.data);
    
        
                        setTimeout(()=>{
                            this.setState({
                                getData:true,
                            })
                        },1000)
                     
                      
                    })
                    .catch(()=>{
        
                    })
            }else{
                setTimeout(()=>{
                    this.setState({
                        getData:true,
                    })
                },1000)
             
            }
        }
    }
   

    componentDidMount() {
        this.setState({
            inputData:{},
            varValue:{},
            getData:false,
        })
       
        if(this.props.match.params.reportType==="outgoing"){
            this.setState({
                inputData:{},
                varValue:{},
                getData:false,
            })
            let urls = backEndServerURL + "/get-document-types";
            axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((documentTypes) => {
                    this.findByVarNameKycMasterDataApiData("docType", documentTypes.data);

    
                    setTimeout(()=>{
                        this.setState({
                            getData:true,
                        })
                    },1000)
                 
                  
                })
                .catch(()=>{
    
                })
        }else{
            setTimeout(()=>{
                this.setState({
                    getData:true,
                })
            },1000)
         
        }
      
    };

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     // if (this.props.match.params.reportType !== prevProps.match.params.reportType) {
    //     //     this.setState({
    //     //         inputData: {},
    //     //         varValue: {},
    //     //         err: false,
    //     //         errorArray: {},
    //     //         errorMessages: {},
    //     //         reportType: this.props.match.params.reportType,
    //     //     })
    //     // }
    // }

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "-1" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    downloadReport = () => {
        let jsonFom;
        if(this.props.match.params.reportType==="outgoing"){
            jsonFom =   searchFormOneOutGoing  ;
        }else{
            jsonFom =   searchFormOneInComing  ;

        }
        let error = MyValidation.defaultValidation(this.copyJson(jsonFom), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let inputData=this.state.inputData;

            inputData.status= (inputData.status===undefined || inputData.status===null )? "" : inputData.status;   
            inputData.docType= (inputData.docType===undefined || inputData.docType===null)? "" : inputData.docType; 
            if(this.props.match.params.reportType === "outgoing"){
                inputData.senderName= (inputData.senderName===undefined || inputData.senderName===null) ? "" : inputData.senderName;   

            }else{
                inputData.reciverName= (inputData.reciverName===undefined || inputData.reciverName===null) ? "" : inputData.reciverName;   

            }
                   
            let download_url = this.props.match.params.reportType === "outgoing" ? backEndServerURL + "/getGreenDeltaReport/outgoing" : backEndServerURL + "/getGreenDeltaReport/incoming";
            let excel_name = this.props.match.params.reportType === "outgoing" ? "outgoing_details_report.xlsx" : "incoming_details_report.xlsx";
            axiosDownload.downloadFile(download_url, 'POST', inputData, `${excel_name}`).then((result) => {
                if (result) {
                    this.setState({
                        loading: false
                    });
                    if (result === "File Not Found") {
                        this.setState({
                            cbNotFound: true,
                            title: "No Data Found!"
                        })
                    }
                }
            }).catch((error) => {
                this.setState({
                    loading: false
                });
            })
        }
    };


    renderLoader = () => {
        if (this.state.loading) {
            const {classes} = this.props;
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


            )
        }
    };

    renderJsonForm = () => {
        let jsonForm;
        if(this.props.match.params.reportType==="outgoing"){
            jsonForm =   searchFormOneOutGoing  ;
        }else{
            jsonForm =   searchFormOneInComing  ;

        }
        return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
    };

     // start render table data
     getApiUrl = () => {
        let url = "";
        const data = [];
        // url = 'https://mocki.io/v1/8b3e49c8-b556-4769-a42e-19405054b496';
        url = backEndServerURL + '/getGreenDeltaReportDataForRequisition/0';
        this.setState({
            activeItem: 'ACTIVE',
        });
        return url;
    };
    
       
     fetchTableData = (url, page) => {
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            console.log("response data",response.data.content)
            response.data.content.map((userData, index) => {
                console.log('userdata',userData)
                tableData.push({   
                   
                Branch :userData[0],
                Dates: userData[1], 
                productType: userData[2],        
                OpendingBalance: userData[3],
                Sold: userData[4],
                Addtion: userData[5],
                Transfer: userData[6],
                ClosingBalance: userData[7], 
                ReorderQty: userData[8],       
 		 
                   // action:this.disableButton(message.anotherRolePermission,message.username)
                });
            });
            console.log("tableData",tableData)
           // console.log(tableData)
            this.setState({
                //  page: response.data.number,
                //  totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };
    
    
     renderTableColumn=()=>{
         //Branch,  Dates,  productType,  OpendingBalance,  Sold,   Addtion,  Transfer,  ClosingBalance, ReorderQty
           // ["PRINCIPAL OFFICE1","2022-11-02","20 TK","19","1",0,0,"19",0]
        const columns = [
            
            { title: 'Branch', field: 'Branch'},             
            { title: 'Dates', field: 'Dates'},   
            { title: 'Product Type', field: 'productType'},
            { title: 'Opending Balance',  field: 'OpendingBalance'},
            { title: 'Sold', field: 'Sold'},
            { title: 'Addtion', field: 'Addtion'},
            { title: 'Transfer', field: 'Transfer'},
            { title: 'Closing Balance', field: 'ClosingBalance' },
            { title: 'Reorder Qty', field: 'ReorderQty' },
          ];
          return columns
    }
    
    
     // end render table data
    render() {
        const {classes} = this.props;

        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            { this.state.getData && this.props.match.params.reportType==="outgoing" ? "Outgoing Details Report": "Incoming Details Report"}
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {
                            this.renderLoader()
                        }
                        <Grid item xs={12}>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {this.state.getData && this.renderJsonForm()}
                                    <Grid item xs={4} style={{marginTop: "18px"}}>
                                        <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={this.downloadReport}
                                        >
                                            Download Report
                                        </button>
                                    </Grid>
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                    </CardBody>
                    {/* <Reports tableData={this.state.tableData} 
                    tableColumns={this.renderTableColumn()}
                                onRowClick={this.onRowClick}
                                loader={this.state.loading} maxBodyHeight="450px"
                               totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                                handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                                /> */}
  
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(GreenDeltaRepost);
