import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import FailedListModal from "../../DeliverableManagement/WelcomeLetter/FailedListModal";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let populateFormForMaker = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];


class InwardFileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileUploadData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            failedModal: false,
            failedList: [],
            uploadType: "",
            disabled: false,
            loading: false,
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    componentDidMount() {
        this.setState({
            uploadType: this.props.match.params.uploadType
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.uploadType !== this.props.match.params.uploadType) {
            this.setState({
                uploadType: nextProps.match.params.uploadType
            })
        }
    }

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    fileUpload = () => {
        let error = MyValidation.fileValidation(populateFormForMaker[0], this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        }

        if (this.state.fileUploadData.fileName !== undefined) {
            this.setState({
                disabled: true,
                loading: true,
            });
            let url = this.props.match.params.uploadType === "maker" ? backEndServerURL + "/RemittanceFileUpload/add" : backEndServerURL + "/RemittanceFileUploadAndChangeStatus/add";
            const formData = new FormData();
            formData.append("file", this.state.fileUploadData['fileName']);

            axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.data.message === "Wrong Excel Format!") {
                        this.setState({
                            title: "Warning!",
                            notificationMessage: "Wrong Excel Format!",
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                    }
                    if (response.data.rightStatus > 0) {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: `${response.data.rightStatus} Upload Successful and ${response.data.wrongStatus} Upload failed`,
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                    }
                    if (response.data.invalidReferenceNumberList && response.data.invalidReferenceNumberList.length > 0) {
                        this.setState({
                            failedList: response.data.invalidReferenceNumberList,
                            failedModal: true,
                            disabled: false,
                            loading: false,
                        })
                    }
                    else{
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    }
                })
                .catch((error) => {
                    if (error) {
                        this.setState({
                            title: "Failed!",
                            notificationMessage: "File Upload Failed",
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                    }
                });

        }
    };

    closeFailedModal = () => {
        this.setState({
            failedList: [],
            failedModal: false
        })
    };

    failedListModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.failedModal}>
                <DialogContent className={classes.dialogPaper}>
                    <FailedListModal titleHeader="Inward Remittance Failed List" closeModal={this.closeFailedModal}
                                     failedList={this.state.failedList}/>
                </DialogContent>
            </Dialog>
        )
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    render() {
        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >File Upload Inward Remittance</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        {this.failedListModal()}
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                populateFormForMaker,
                                                this.updateComponent
                                            )}
                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                disabled={this.state.disabled}
                                                onClick={this.fileUpload}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <div></div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(InwardFileUpload);
