import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Table from "../Table/Table";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

class CustomerLoan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: false,
            productName: '',

            loanLoading: null,
            redirectLogin: false,

        }
    }

    renderLoading = () => {
        if (this.state.loanLoading) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={[" ", " "
                    ]}
                    tableData={[
                        ["Account No", this.state.varValue["ACCOUNT"]],
                        ["Product Name", this.state.varValue["PRODUCTNAME"]],
                        ["Currency", this.state.varValue["CURRENCY"]],
                        ["Tenor", this.state.varValue["TENOR"]],
                        ["Outstanding Balance", this.state.varValue["OUTSTANDINGBALANCE"]],

                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)

        }
    };
    renderLoading2 = () => {
        if (this.state.loanLoading) {
            return (
                <Table
                    tableHeaderColor="primary"
                    tableHead={["View Data", "Value"
                    ]}
                    tableData={[
                        ["Next Installment Date", this.state.varValue["NEXTINSTALLMENTDATE"]],
                        ["Currency Cycle Interest Accrued", this.state.varValue[""]],
                        ["Number Of Installment Remaining", this.state.varValue["NOOFINSREMAINING"]],
                        ["Amount Of Unrealized Due Installment", this.state.varValue["AMTOFUNREALIZEDDUEINS"]]

                    ]}
                    tableAllign={['left', 'left']}
                />
            )
        } else {
            return (<CircularProgress style={{marginLeft: '50%'}}/>)


        }
    };


    componentDidMount() {
        this.setState({loanLoading: false});
        if (this.props.ACCOUNTNO !== undefined && this.props.type !== undefined) {
            let url = backEndServerURL + "/demographic/account/" + this.props.type + "/" + this.props.ACCOUNTNO;
            console.log(url);
            let varValue = [];


            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(true);
                    console.log(response.data);
                    if (response.data[0] !== null) {


                        varValue["ACCOUNT"] = response.data[0].ACCOUNT;
                        varValue["CURRENCY"] = response.data[0].CURRENCY;
                        varValue["TENOR"] = response.data[0].TENOR;

                        varValue["NOOFINSREMAINING"] = response.data[0].NOOFINSREMAINING;
                        varValue["AMTOFUNREALIZEDDUEINS"] = response.data[0].AMTOFUNREALIZEDDUEINS;
                        varValue["OUTSTANDINGBALANCE"] = response.data[0].OUTSTANDINGBALANCE;
                        varValue["PRODUCTNAME"] = response.data[0].PRODUCTNAME;
                        let nextInstallmentDate = response.data[0].NEXTINSTALLMENTDATE;
                        if (nextInstallmentDate !== undefined && nextInstallmentDate !== null) {
                            varValue["NEXTINSTALLMENTDATE"] = nextInstallmentDate.split('T')[0];
                        } else {
                            varValue["NEXTINSTALLMENTDATE"] = nextInstallmentDate;
                        }

                    } else {

                    }

                    this.setState({
                        varValue: varValue,
                        loanLoading: true

                    })


                })
                .catch((error) => {
                    console.log(error);


                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }

                    this.setState({loanLoading: true})
                });
        } else {

        }
    }

    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >Loan Summery<a><CloseIcon onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>
                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>
                                                    <Paper className={classes.paper}>
                                                        {this.renderLoading()}

                                                    </Paper>

                                                </Grid>
                                                {/* <Grid item xs={6}>

                                                    <Paper className={classes.paper}>

                                                        {this.renderLoading2()}
                                                    </Paper>

                                                </Grid>
*/}
                                            </Grid>
                                        </Grid>
                                    </div>


                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>


        );
    }
}

export default withStyles(styles)(CustomerLoan);
