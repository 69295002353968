import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loading from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import TableComponent from "../../CommonComponent/TableComponent";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import BeneficiaryAdd from "./BeneficiaryAdd";
import {commonInboxButton} from "../../../DeliverableManagement/configuration";
import Button from "@material/react-button";
import DisableBeneficiaryList from "./DisableBeneficiaryList";
import ActionComponent from "./ActionComponent";

const filterField = [
    {
        varName: "routingNumber",
        label: "Routing Number",
        type: "text",
        grid: 2,
    },
    {
        varName: "bankName",
        label: "Beneficiary Bank Name",
        type: "autoCompleteValueReturn",
        onKeyDown: true,
        enum: [],
        grid: 2,
    },
    {
        varName: "branchName",
        label: "Beneficiary Branch Name",
        type: "autoCompleteValueReturn",
        enum: [],
        grid: 2,
    },
    {
        varName: "transferType",
        type: "select",
        enum: [
            "BEFTN Enabled",
            "RTGS Enabled",
            "BEFTN Disabled",
            "RTGS Disabled",
            "BEFTN Temporary Disabled",
            "RTGS Temporary Disabled"
        ],
        label: "BEFTN/RTGS Status",
        grid: 2,
    },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class BeneficiaryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            openDisableModal: false,
            showTable: false,
            errorArray: {},
            errorMessages: {},
            rowInput: {},
            showModal: false,
            update: false,
            openActionModal: false,
            buttonType: "",
            tableData: [],
            totalRow: 0,
            rowsPerPage: 200,
            tableColumns: [
                {field: "sl", title: "SL"},
                {field: "bank_name", title: "Bank Name"},
                {field: "branch_name", title: "Branch Name"},
                {field: "district_name", title: "District Name"},
                {field: "routing_number", title: "Routing Number"},
                {field: "rtgs_status", title: "RTGS Status"},
                {field: "beftn_status", title: "BEFTN Status"},
                // {field: "status", title: "Status"},
                {field: "action", title: "Action"},
            ],
            page: 0,
        }
    }

    componentDidMount() {
        let url = backEndServerURL + "/finMasterData/custom/otherBanks/all";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let field of filterField) {
                    if (field.varName === "bankName") {
                        field.enum = response.data
                    }
                }
                this.setState({
                    loading: false,
                    getData: true,
                    showTable: true
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    getData: true,
                    showTable: true
                })
            })
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >RTGS BEFTN BRANCH UPDATE</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            showModal: false,
            cbNotFound: false,
        })
    };

    closeModal = () => {
        this.setState({
            showModal: false,
        });
        this.handleChangePage(this.state.page)
    };

    functionForStatus = (value) => {
        if (value === 1) {
            return "Enable"
        } else if (value === 2) {
            return "Temporary Disable"
        } else if (value === 0) {
            return "Disable"
        } else {
            return "";
        }
    };


    handleChangePage = (pageNumber) => {
        let input = {...this.state.inputData};
        let postData = this.copyJson(input);
        if (Object.keys(postData).length === 0) {
            return 0;
        } else {
            this.setState({
                loading: true,
                showTable: false,
            });
            let url = backEndServerURL + "/finBankBranchMasterData/getSearchFilterByField/" + pageNumber;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let tableData = [];
                    let rowData;
                    let sl = 0;
                    for (let i = 0; i < response.data.length; i++) {
                        let res = response.data[i];
                        sl = sl + 1;
                        rowData = {
                            "sl": sl,
                            "bank_name": res.bankName,
                            "branch_name": res.branchName,
                            "district_name": res.distName,
                            "routing_number": res.routingNo,
                            "rtgs_status": this.functionForStatus(res.rtgsFlag),
                            "beftn_status": this.functionForStatus(res.beftnFlag),
                            "action": this.renderEditButton({
                                bank_name: res.bankName,
                                branch_name: res.branchName,
                                district_name: res.distName,
                                routing_number: res.routingNo,
                            }),
                        };
                        tableData.push(rowData);
                    }
                    this.setState({
                        page: 0,
                        rowsPerPage: response.data.length,
                        totalRow: response.data.length,
                        tableData: tableData,
                        getData: true,
                        showTable: true,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: "Please input valid data",
                        showTable: true,
                        loading: false,
                        getData: false
                    })
                });
        }
    };

    renderEditButton = (rowData) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.onRowClick(event, rowData)}
                >
                    Update
                </Button>
            </>
        )
    };

    copyJson = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === null || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    updateStatus = (value, status, transferType) => {
        let postData = {};
        postData.idList = value;
        postData.isActive = status;
        postData.transferType = transferType;
        let url = backEndServerURL + "/finBankBranchMasterData/changeIdListByStatus";
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.handleChangePage(this.state.page);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: "Something Went Wrong!",
                    loading: false,
                })
            });
    };


    triggerDisableRTGSFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 0, "RTGS");
        }
    };
    triggerDisableBEFTNFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 0, "BEFTN");
        }
    };
    triggerEnableRTGSFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 1, "RTGS");
        }
    };
    triggerEnableBEFTNFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 1, "BEFTN");
        }
    };

    triggerTemporaryDisableRtgsFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 2, "RTGS");
        }
    };

    triggerTemporaryDisableEftnFunction = (event, data) => {
        if (data === undefined) return 0;
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        if (idList.length > 0) {
            this.setState({
                loading: true
            });
            this.updateStatus(idList, 2, "BEFTN");
        }
    };

    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setState({
            rowInput: rowData,
            showModal: true,
            update: true
        });

    };

    openModal = () => {
        this.setState({
            update: false,
            showModal: true
        })
    };

    onKeyDownChange = (data) => {
        console.log(data);
        if (data.varName === "bankName") {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + "/finMasterData/custom/otherBranches/all/" + this.state.inputData.bankName["key"];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        for (let field of filterField) {
                            if (field.varName === "branchName") {
                                field.enum = response.data
                            }
                        }
                        this.setState({
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    console.log(error)
                });
        }
    };

    openDisableModal = () => {
        this.setState(prev => ({
            openDisableModal: !prev.openDisableModal,
        }));
        this.handleChangePage(0)
    };

    openDisableModalRTGS = () => {
        this.setState(prev => ({
            openDisableModal: !prev.openDisableModal,
            buttonType: "RTGS"
        }))
    };

    openDisableModalBEFTN = () => {
        this.setState(prev => ({
            openDisableModal: !prev.openDisableModal,
            buttonType: "BEFTN"
        }))
    };

    closeActionModal = () => {
        this.setState(prev => ({
            openActionModal: !prev.openActionModal,
        }))
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.showModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <BeneficiaryAdd closeModal={this.closeModal} update={this.state.update}
                                            rowData={this.state.rowInput}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.openDisableModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <DisableBeneficiaryList closeModal={this.openDisableModal}
                                                    transferType={this.state.buttonType}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.openActionModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <ActionComponent closeModal={this.closeActionModal}
                                             handleChangePage={this.handleChangePage}/>
                        </DialogContent>
                    </Dialog>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, "", this.onKeyDownChange)}
                            <Grid item xs={3}>
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() => this.handleChangePage(0)}
                                    style={{
                                        marginTop: 18,
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={() => window.location.reload()}
                                    style={{
                                        marginTop: 18,
                                    }}
                                >
                                    Reset
                                </button>
                            </Grid>
                            <Grid item xs={12}><br/></Grid>
                            {this.renderNotification()}
                        </ThemeProvider>
                    </Grid>
                    <ThemeProvider theme={theme}>
                        {(this.state.getData && this.state.showTable) &&
                        <TableComponent tableData={this.state.tableData} tableColumns={this.state.tableColumns}
                                        loader={this.state.loading} maxBodyHeight="450px"
                                        totalRow={this.state.totalRow}
                                        page={this.state.page} selection={true}
                                        handleChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        tableButton={[{
                                            name: "Branch Add",
                                            triggerFunction: this.openModal
                                        }, {
                                            name: "RTGS Temporary Disable",
                                            triggerFunction: this.triggerTemporaryDisableRtgsFunction
                                        }, {
                                            name: "EFTN Temporary Disable",
                                            triggerFunction: this.triggerTemporaryDisableEftnFunction
                                        },
                                            {
                                                name: "RTGS Enable",
                                                triggerFunction: this.triggerEnableRTGSFunction
                                            }, {
                                                name: "EFTN Enable",
                                                triggerFunction: this.triggerEnableBEFTNFunction
                                            },
                                            // {
                                            //     name: "View Disable RTGS",
                                            //     triggerFunction: this.openDisableModalRTGS
                                            // }, {
                                            //     name: "View Disable BEFTN",
                                            //     triggerFunction: this.openDisableModalBEFTN
                                            // },
                                            {
                                                name: "RTGS Disable",
                                                triggerFunction: this.triggerDisableRTGSFunction
                                            }, {
                                                name: "EFTN Disable",
                                                triggerFunction: this.triggerDisableBEFTNFunction
                                            }, {
                                                name: "Action",
                                                style: commonInboxButton,
                                                triggerFunction: this.closeActionModal
                                            }]}
                        />}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(BeneficiaryList);
