import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import MultipleSignatureCard from "../CommonComponent/MultipleSignatureCard";
import {backEndServerURL} from "../../../Common/Constant";
import axios from 'axios/index';
import withStyles from "@material-ui/core/styles/withStyles";
import CBNotFound from "../CASA/CBNotFound";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class SignatureView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            cbNotFound: false,
            title: "",
            dormant: false,
            showButton: false
        }
    }


    componentDidMount() {
         if (this.props.match.params.requestType === "Ababil") {
            let accountInquiryUrl = backEndServerURL + "/accountInquiryAbabil/" + this.state.inputData.accountNumber;
            axios.get(accountInquiryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = {...this.state.inputData};
                    if (response.data.acctStatus === "A" || response.data.acctStatus === "") {
                        this.setState({
                            showButton: true,
                        })
                    } else if (response.data.acctStatus === "D") {
                        this.setState({
                            dormant: true
                        })
                    } else {
                        this.setState({
                            showButton: false,
                            dormant: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        showButton: false,
                        dormant: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });
        } else {
            let url = backEndServerURL + "/GetCustomAcctStatus/" + this.props.match.params.accountNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (response.data.acctStatus === "A" || response.data.acctStatus === "") {
                        this.setState({
                            showButton: true,
                        })
                    } else if (response.data.acctStatus === "D") {
                        this.setState({
                            dormant: true
                        })
                    } else {
                        this.setState({
                            showButton: false,
                            dormant: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        showButton: false,
                        dormant: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });
        }

    }

    signatureCard = () => {
        this.setState({
            signatureCard: true
        })
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderAlertModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="sm"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.cbNotFound}>
                <DialogContent className={classes.dialogPaper}>
                    <CBNotFound
                        closeModal={this.closeModalCBNotFound}
                        title={this.state.title}
                    />
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        if (this.state.dormant) {
            return (
                <div>
                    <p style={{color: "#000", fontSize: "14px", fontWeight: "bold"}}>Signature is not Allowed</p>
                    {this.renderAlertModal()}
                </div>
            )
        } else if (this.state.showButton) {
            return (
                <div style={{"width": "750px", "margin": "25px auto"}}>
                    <MultipleSignatureCard signatureSource={this.props.match.params.requestType}  id={this.props.match.params.accountNumber}/>
                </div>
            );
        } else {
            return (
                <>
                    <CircularProgress style={{marginLeft: '50%'}}/>
                </>
            )
        }
    }


}

export default withStyles(styles)(SignatureView);