import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../Static/loader.gif";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../JsonForm/MyValidation";

let bmRemarks = [
    {
        "varName": "bmRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }]
;

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};


class CityTouchApprovalOfficer extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        other: [],
        getDeferalList: [],
        loading: true,
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        getRemarks: [],
        getMappingAllImage: false

    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    componentDidMount() {
        var remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.setState({
                        getRemarks: remarksArray
                    });
                    let inputData = this.removeNullValue(response.data);
                    let varValue = this.removeNullValue(response.data);
                    /* if (response.data.phone !== undefined) {
                         inputData.mobileNumber = response.data.phone
                         varValue.mobileNumber = response.data.phone
                     }*/
                    if (response.data.smsAlertRequest === "Y" || response.data.smsAlertRequest === "AB" || response.data.smsAlertRequest === "CCP") {
                        inputData.smsAlertRequest = true;
                        inputData.varValue = true;
                    } else {
                        inputData.smsAlertRequest = false;
                        inputData.varValue = false;
                    }
                    inputData.mobileNumber = response.data.designatedMobileNumber;
                    inputData.email = response.data.designatedEmail;
                    varValue.mobileNumber = response.data.designatedMobileNumber;
                    varValue.email = response.data.designatedEmail;

                    console.log(response.data);
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: varValue,
                        inputData: inputData,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    /* if(error.response.status===452){
                         Functions.removeCookie();

                         this.setState({
                             redirectLogin:true
                         })

                     }*/
                });
        }

    }


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    handleSubmit = (event, data) => {
        event.preventDefault();
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });
        let dependencyField = [];
        if (data === "RETURN") {
            dependencyField.push({

                "varName": "bmRemarks",
                "type": "textArea",
                "required": true,
                "label": "BM Remarks",
                "grid": 12
            })
        }
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false,
            });
            return 0;
        }

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bmRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        var appId = this.props.appId;
        var approval = data;


        this.state.inputData.adc_service = data;
        this.state.inputData.internet_banking_approval = data;


        console.log(this.state.inputData);


        this.state.inputData.bmRemarks = undefined;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {


                        console.log(response.data);
                        if (data === "CLOSED") {
                            this.setState({
                                loading: false,
                                title: "Successfull!",
                                notificationMessage: "Successfully Close!",
                                alert: true
                            })
                        } else {
                            this.setState({
                                title: "Successfull!",
                                loading: false,
                                notificationMessage: "Successfully Return!",
                                alert: true
                            })
                        }

                        this.props.closeModal();
                        //
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                        })

                    });

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });


    };


    renderForm = () => {
        if (this.state.getData) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent)

            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bmRemarks, this.updateComponent)

            )
        }

    };


    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "CLOSED")}
                    >Complete
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "RETURN")}
                    >Return
                    </button>
                </div>


            )
        }
    };
    renderMultipleDebitCardForm = () => {


        if (this.state.getData) {

            var sl;
            let objectForDebitCard = [];
            for (var i = 0; i < this.state.inputData.numberOfDebitCard; i++) {
                sl = i + 1;
                objectForDebitCard.push(
                    {
                        "varName": "customer" + sl,
                        "type": "title",
                        "label": "Debit Card " + sl,
                        "grid": 12
                    },
                    {
                        "varName": "customerName" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Name On Card",
                        "grid": 2,


                    },

                    {
                        "varName": "cardType" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Card Type",
                        "grid": 2,
                        "enum": [
                            "VISA",
                            "MASTER",
                            "CITYMAXX",
                        ],


                    },
                    {
                        "varName": "debitCardDeliveryType" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Delivery Type",
                        "enum": [
                            "Courier",
                            "Branch"
                        ],
                        "grid": 2,


                    },

                    {
                        "varName": "debitRequestkBranch" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Branch Name",
                        "enum": [
                            "GULSHAN 1",
                            "MOTHIJHEEL 1",
                            "DHANMONDI",
                        ],
                        "grid": 2,
                        "conditional": true,
                        "conditionalVarName": "debitCardDeliveryType" + sl,
                        "conditionalVarValue": "Branch",

                    },
                )
            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForDebitCard, this.updateComponent)
            )

        }
    };
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Debit Card</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfDebitCard"]}
                            name="numberOfDebitCard"
                            //label={field.label}
                            InputProps={{
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };
    renderPdfDownload = () => {

        if (this.state.getData && this.state.getImageBoolean) {
            return this.state.getImageLink.map((data) => {
                return (
                    <div>
                        <a target="_blank" href={backEndServerURL + "/file/" + data}>View Image</a>

                    </div>
                )

            })

        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({
            getMappingAllImage: false,

        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderMappingImageButton = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 150,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Image
                </button>
            )
        }
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        if (this.state.loading === true) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>

            )
        } else {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            <FileMappingReadOnly appId={this.props.appId}
                                                 closeModal={this.closeModal}/>
                        </DialogContent>
                    </Dialog>

                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.renderForm()}
                            {/*  <Grid item xs={2}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderMultipleDebitCardForm()}
*/}
                        </Grid>
                        <br/>

                        <Grid item={12}>

                            {this.renderRemarksData()}
                        </Grid>
                        <br/>
                        {this.renderMappingImageButton()}
                        <br/>
                        {/*  <br/>
                        {this.renderPdfDownload()}
                        <br/>*/}
                        {this.renderRemarks()}
                        <br/>
                        <div>
                            {this.renderSubmitButton()}
                        </div>
                    </ThemeProvider>

                </div>


            )
        }
    }

}

export default withStyles(styles)(CityTouchApprovalOfficer);
