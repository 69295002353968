import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import ShowDocuments from './ShowDocuments';
import loader from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import UploadComponent from "../Maintenance/casa/UploadComponent";
import MyValidation from "../../JsonForm/MyValidation";


let lockerJsonForm = [
    {
        "varName": "lockerNumber",
        "type": "textDedup",
        "validation": "numeric",
        "required": true,
        "label": "Locker Number",
        "grid": 2
    }
];

let lockerJsonFormAfterSearch = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3
    },
    {
        "varName": "lockerNumber",
        "type": "text",
        "label": "Locker Number",
        "grid": 3
    },
    {
        "varName": "lockerKey",
        "type": "text",
        "label": "Locker Key",
        "grid": 3
    },
    {
        "varName": "lockerSize",
        "type": "text",
        "label": "Locker Size",
        "grid": 3
    },
    {
        "varName": "lockerKey",
        "type": "text",
        "label": "Locker Key Number",
        "grid": 3
    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];


const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'CITY LOCKER APPLICATION FORM'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",

    };


class CSOLockerSurrander extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            showValue: false,
            inputData: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            fileUploadData: {},
            selectedDate: {},
            uploadModal: false,
            getMappingAllImage: false,
            loaderNeeded: null,
            loading: true,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            lockerGetData: false,
            beforeSearch: true,
            signatureCard: false,
            appId: 0,
            cbNotFound: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {

        let url = backEndServerURL + "/startCase/cs_data_capture";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data);
            this.setState({
                appId: response.data.id,
                loading: false
            });
        })
            .catch((error) => {
                console.log(error);
            })

    }

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.lockerSearch(event);

        }

    };

    lockerSearch = () => {

        let error = MyValidation.defaultValidation(lockerJsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });

            let url = backEndServerURL + "/locker/branch/" + this.state.inputData.lockerNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                if (response.data.status !== "Rented") {
                    this.state.varValue["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.state.inputData["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.setState({
                        loading: false,
                        showValue: true,
                        cbNotFound: true,
                        title: "There is already a pending request."
                    });
                } else if (response.data.status === "Unrented") {
                    this.state.varValue["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.state.inputData["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.setState({
                        loading: false,
                        showValue: true,
                        cbNotFound: true,
                        title: "This locker is not rented yet.."
                    });
                } else {
                    console.log(response.data);
                    let varValue = response.data;
                    varValue.lockerSize = response.data.lockerType;
                    varValue.lockerName = response.data.customerName;

                    let inputData = this.copyJson(response.data);
                    inputData.lockerSize = response.data.lockerType;
                    inputData.lockerName = response.data.customerName;

                    this.setState({
                        varValue: varValue,
                        inputData: inputData,
                        loading: false,
                        showValue: true,
                        lockerGetData: true,
                        beforeSearch: false
                    });

                }
            })
                .catch((error) => {
                    this.state.varValue["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.state.inputData["lockerNumber"] = this.state.inputData.lockerNumber;
                    this.setState({
                        loading: false,
                        showValue: true,
                        cbNotFound: true,
                        title: "Locker Not Found"
                    });
                })
        }

    };

    handleSubmit = (event) => {
        //console.log(this.state.appId);
        event.preventDefault();

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error)
            });
        var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;

        let data = this.state.inputData;
        // data.csRemarks = undefined;
        data.cs_deferal = 'NO';
        data.serviceType = 'Locker';
        data.subServiceType = 'LockerClosing';
        data.category = 'Locker';
        data.subCategory = 'Closing';
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

            var lockerStatusUrl = backEndServerURL + "/changeLockerStatus/" + this.state.inputData.lockerNumber + "/REQUEST_IN_PROGRESS_FOR_SURRENDER";
            axios.get(lockerStatusUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

                var url = backEndServerURL + "/case/route/" + this.state.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    console.log("Successfully Routed!");

                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                })

                    .catch((error) => {
                        console.log(error);
                    });
            })

                .catch((error) => {
                    console.log(error);
                });
        })
            .catch((error) => {
                console.log(error)
            });
    };
    // handleSubmitDraft = (event) => {
    //     event.preventDefault();
    //
    //     var variableSetUrl = backEndServerURL + "/save/" + this.state.appId;
    //     let data = this.state.inputData;
    //     data.serviceType = 'Locker';
    //     data.subServiceType = 'LockerCLosing';
    //     data.category = 'Locker';
    //     data.subCategory = 'Closing';
    //
    //     axios.post(variableSetUrl, data, { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } }).then((response) => {
    //         console.log(response.data);
    //
    //         this.setState({
    //             title: "Successfull!",
    //             notificationMessage: "Successfully Save!",
    //             alert: true
    //         })
    //         this.close()
    //     })
    //         .catch((error) => {
    //             console.log(error)
    //         });
    // }
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.lockerGetData) {
            return <UploadComponent appId={this.state.appId} indexList={arrayListIndividual} classes={classes}/>
        }
    };

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false
        })
    };

    signatureCard = () => {
        this.setState({
            signatureCard: true
        })
    };

    signatureClose = () => {
        this.setState({
            signatureCard: false
        })
    };

    beforeLockerSearch = () => {
        if (this.state.beforeSearch) {
            return (
                <Grid container spacing={1}>


                    <Grid item xs='12'></Grid>

                    {/*<Grid item xs='3'>*/}
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonForm, this.updateComponent, this.onKeyDownForDedup)
                    }
                    {/*</Grid>*/}
                    <Grid item xs='3'>
                        <button className="btn btn-outline-primary btn-sm" onClick={this.lockerSearch}
                                style={{marginTop: '15px'}}>Search
                        </button>
                    </Grid>
                </Grid>
            )
        }
    };

    afterLockerSearch = () => {
        if (this.state.lockerGetData) {
            return (
                <Grid container spacing={1}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, lockerJsonFormAfterSearch, this.updateComponent)
                    }
                    <Grid item xs={12}>{this.uploadFunction()}</Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.signatureCard}
                        >
                            Preview Documents
                        </button>
                    </Grid>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        open={this.state.signatureCard}>
                        <DialogContent>
                            <ShowDocuments closeModal={this.signatureClose} appId={this.state.appId}/>
                        </DialogContent>
                    </Dialog>
                    <Grid item xs='12'>
                        {this.renderRemarks()}
                    </Grid>

                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                    </Grid>
                </Grid>
            )
        }
    };

    render() {

        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Locker Closing</h4>
                    </CardHeader>
                    <CardBody>
                        {/* <div> */}
                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {
                                this.renderNotification()
                            }

                            {
                                this.beforeLockerSearch()
                            }

                            {
                                this.afterLockerSearch()
                            }

                        </ThemeProvider>
                        {/* </div> */}
                    </CardBody>
                </Card>
            );
        }
    }

}

export default withStyles(styles)(CSOLockerSurrander);
