import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import axios from 'axios/index';
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import {FundTransferJsonFormOne} from "../WorkflowJsonFormRashed";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import GridList from "@material-ui/core/GridList";
import axiosDownload from "../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../Maintenance/SignatureButton";
import MyValidation from "../../JsonForm/MyValidation";
import CommonApi from "../Maintenance/common/CommonApi";
import FullSdnDedupeResult from "../../FullSdnDedupeResult";

const fundTransferFormForBranch = [
    {
        "label": "Beneficiary Account Number",
        "type": "text",
        "varName": "creditAccountNumber",
        "grid": 6,
        "readOnly": true,
    },
    {
        "label": "Beneficiary Name",
        "type": "text",
        "varName": "beneficiaryDetails",
        "grid": 6,
        "readOnly": true,
    },
    {
        "label": "Beneficiary Bank Name",
        "type": "text",
        "varName": "beneficiaryBankName",
        "grid": 6,
        "multiline": true,
        "validation": "string",
        "readOnly": true,
    },
    {
        "label": "Branch Name",
        "type": "text",
        "varName": "branchName",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "validation": "string"
    },
    {
        "label": "Routing Number",
        "type": "text",
        "multiline": true,
        "varName": "routingNumber",
        "grid": 6,
        "readOnly": true,
    },
];

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class BomInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getDeferalList: [],
            getData: false,
            signature: false,
            documentList: [],
            getDocument: false,
            documentListSDN: [],
            getDocumentSDN: false,
            getRemarks: [],
            cbNotFound: false,
            loading: true,
            disabled: false,
            selectedDate: {},
            dedupData: [],
            searchTableData: false,
            SdnForFundTransfer: false,
        }
    }

    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };

    updateComponent = () => {
        this.forceUpdate();
    };
    createSdnTableData = (country, dob, matchType, name, type) => {
        return ([name, dob, matchType, type, country])
    };

    componentDidMount() {
        this.functionForFileGet();
        CommonApi.getRemarkDetails(this.props.appId)
            .then((remarksArray) => {
                this.setState({
                    getRemarks: remarksArray
                })
            })
            .catch((error) => {
                console.log(error)
            });

        CommonApi.getDeferralDetails(this.props.appId)
            .then((tableArray) => {
                this.setState({
                    getDeferalList: tableArray
                });
                CommonApi.getFiles(this.props.appId).then((fileData) => {
                    this.setState({
                        getImageLink: fileData,
                        getImageBoolean: true
                    });
                })
            })
            .catch((error) => {
                console.log(error);
            });

        let sdnArray = [];
        CommonApi.getVariables(this.props.appId)
            .then((response) => {
                let inputData = {...response.data};
                inputData.source = "Finacle"
                if (inputData.source === "Ababil") {
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false,
                        });
                    });
                } else {
                    CommonApi.getCustAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            getData: true,
                            loading: false
                        });
                    }).catch((error) => {
                        this.setState({
                            cbNotFound: true,
                            title: error.response.data.message,
                            loading: false,
                        });
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
    }

    handleChangeSdnResult = (event) => {
        event.preventDefault();
        this.setState({
            SdnForFundTransfer: true
        })

    };
    handleSDNSearchButton = () => {
        if (this.state.inputData.transferType === "RTGS") {
            return (<div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={(event) => this.handleChangeSdnResult(event)}
                    >
                        SDN View
                    </button>
                </div>
            )
        }
    }


    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-cs-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };


    functionForSDNFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/fundTransfer-sdn-attachment/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentListSDN: response.data,
                    getDocumentSDN: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    downloadSDNFile = () => {
        if (this.state.getDocumentSDN && this.state.documentListSDN.length > 0) {
            return (
                this.state.documentListSDN.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForSDNDownload(document)}
                            >SDN File {index + 1}</button>
                        </React.Fragment>
                    )
                })
            );
        }
    };

    functionForSDNDownload = (value) => {
        let url = backEndServerURL + "/file/" + value + "/" + sessionStorage.getItem("accessToken");
        window.open(url);
    };



    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title} message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    jsonFormForBranch = () => {
        if (this.state.inputData.branchEnd) {
            return CommonJsonFormComponent.renderJsonForm(this.state, fundTransferFormForBranch, this.updateComponent)
        }
    };

    formWithData = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, FundTransferJsonFormOne, this.updateComponent)
                    }
                    {this.jsonFormForBranch()}
                </ThemeProvider>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    closeModalForSdnResult = () => {
        this.setState({
            SdnForFundTransfer: false
        })
    }

    functionForCaseClose = () => {
        let url = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        loading: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Closed!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal()
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    disabled: false
                });
                console.log(error)
            });
    };

    functionForHandleSubmit = () => {
        this.setState({
            loading: true,
            disabled: true
        });

        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    disabled: false
                });
            });
        this.state.inputData.bomRemarks = undefined;
        this.state.inputData.bom_approval = "APPROVED";
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.branchEnd === true) {
                    let apiUrl = this.state.inputData.transferType === "BEFTN" ? backEndServerURL + "/intgr/beftnTransaction/" + this.props.appId : backEndServerURL + "/intgr/rtgsTransaction/" + this.props.appId;

                    axios.post(apiUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.functionForCaseClose()
                        })
                        .catch((error) => {
                            console.log(error);
                            if (this.state.inputData.transferType === "BEFTN") {
                                this.setState({
                                    loading: false,
                                    disabled: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                });
                            } else {
                                if (error.response.data.message === "Operation Successful from Query") {
                                    this.functionForCaseClose();
                                } else if (error.response.data.message === "Operation Not Successful.") {
                                    this.setState({
                                        loading: false,
                                        disabled: false,
                                        cbNotFound: true,
                                        title: "Operation Not Successful.Please try again after some time!"
                                    });
                                } else {
                                    this.setState({
                                        loading: false,
                                        disabled: false,
                                        cbNotFound: true,
                                        title: error.response.data.message
                                    });
                                }
                            }
                        });
                } else {
                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                this.setState({
                                    loading: false,
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    disabled: false
                                });
                                this.props.closeModal()
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                disabled: false
                            });
                        });
                }


            })
            .catch((error) => {
                this.setState({
                    disabled: false
                });
                console.log(error)
            });
    };

    approveHandler = (e) => {
        e.preventDefault();

        if (this.state.inputData.requestType === "Single Transaction") {
            let error = MyValidation.defaultValidation(FundTransferJsonFormOne, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                this.functionForHandleSubmit()
            }
        } else {
            this.functionForHandleSubmit()
        }
    };

    returnHandler = (e) => {
        e.preventDefault();

        let dependencyField = [
            {varName: "bomRemarks", type: "textArea", required: true}
        ];

        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                disabled: true
            });
            let postData = {...this.state.inputData};
            postData.bom_approval = "RETURN";
            let remarksData = {};
            remarksData.remark = postData.bomRemarks;
            remarksData.map = {...postData, bomRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    disabled: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false
                })
            });
        }
    };

    functionForExlDownload = (document) => {
        let download_url = backEndServerURL + "/file/" + document;
        axiosDownload.downloadFile(download_url, 'GET', {}, document);
    };

    renderBulkFile = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.customerType) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <button className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                        marginTop: '12px',
                                        marginRight: '10px',
                                    }}
                                    onClick={() => this.functionForExlDownload(document)}
                            >Branch File {index + 1}</button>
                        </React.Fragment>
                    )
                })
            );
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)}
                </ThemeProvider>
            )
        }
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    signature = () => {
        if (this.state.getData) {
            return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple" requestType={this.state.inputData.source} classes={this.props}/>;
        }
    };
    handleClose = (event) => {
        event.preventDefault();
        this.closeModalForSdnResult()
    }

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Deferral List:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                        </div>
                    </div>
                </>
            )
        }

    };

    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Grid container>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.SdnForFundTransfer}>
                            <DialogContent className={classes.dialogPaper}>
                                <FullSdnDedupeResult category={this.props.category}
                                                     subServiceType={'INDIVIDUAL'} appId={this.props.appId}/>
                            </DialogContent>
                            <center>
                                <button
                                    className="btn btn-outline-primary  btn-sm"
                                    style={{}}
                                    onClick={(event) => this.handleClose(event)}
                                >Close
                                </button>
                            </center>
                        </Dialog>

                        {this.formWithData()}
                        <Grid item xs={12}>
                            {this.signature()}
                        </Grid>
                        <Grid item xs={12}>
                            {this.handleSDNSearchButton()}
                        </Grid>
                        {this.renderNotification()}
                        {this.renderBulkFile()}
                        <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                        <br/>
                        {this.renderRemarks()}
                    </Grid>
                    <Grid item xs={12}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                marginTop: '12px',
                            }}
                            onClick={this.approveHandler}
                            type="submit"
                            disabled={this.state.disabled}
                        >
                            Submit
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'middle',
                                marginTop: '12px',
                                marginLeft: '10px'
                            }}
                            disabled={this.state.disabled}
                            onClick={this.returnHandler}
                            type="submit"
                        >
                            Return
                        </button>
                    </Grid>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(BomInbox);
