import React from 'react';
import validator from "validator";

class MyValidationDedupeWithCompany {
    static trimFunction = (emptyString) => {
        /*if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            }
            else if(typeOfString === "object") {
                return string.key;
            }
            else {
                return ""
            }


        }
        else {
            return ""
        }*/
        if (emptyString !== null && emptyString !== undefined) {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }

        } else {
            return ""
        }

    };

    static CustomValidationIndividual(jsonForm, state) {
        let error = false;
        //state.errorArray = {}
        //state.errorMessages = {}

        if (state.inputData["cbNumber"] !== "" && state.inputData["cbNumber"] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber"]))) {

            jsonForm.map((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
            )
        } else if (state.inputData["accountNumber"] !== "" && state.inputData["accountNumber"] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["accountNumber"]))) {

            jsonForm.map((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
            )
        } else {

            if ((state.inputData["nid"] === undefined || state.inputData["nid"] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid"]))) && (state.inputData["passport"] === undefined || state.inputData["passport"] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport"]))) && (state.inputData["registrationNo"] === undefined || state.inputData["registrationNo"] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo"])))) {
                state.errorArray["nid"] = true;
                state.errorMessages["nid"] = "NID or Passport or Birth Certificate number required";

                state.errorArray["passport"] = true;
                state.errorMessages["passport"] = "NID or Passport or Birth Certificate number required";


                state.errorArray["registrationNo"] = true;
                state.errorMessages["registrationNo"] = "NID or Passport or Birth Certificate number required";

                state.error = true;
                state.errorText = true;
                error = true;
            } else {

                state.errorArray["nid"] = false;
                state.errorArray["passport"] = false;
                state.errorArray["registrationNo"] = false;

                state.errorMessages["nid"] = "";
                state.errorMessages["passport"] = "";
                state.errorMessages["registrationNo"] = "";
                state.error = false;
                state.errorText = false;
                error = false;
            }
            if (state.inputData["customerName"] === undefined || state.inputData["customerName"] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName"]))) {
                state.errorArray["customerName"] = true;
                state.errorMessages["customerName"] = "Customer Name Required";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            if (state.inputData["customerName"] !== undefined && state.inputData["customerName"] !== null && state.inputData["customerName"] !== "" && validator.isNumeric(state.inputData["customerName"])) {
                state.errorArray["customerName"] = true;
                state.errorMessages["customerName"] = "Only String value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            if (state.inputData["nid"] !== undefined && state.inputData["nid"] !== null && state.inputData["nid"] !== "" && !validator.isNumeric(state.inputData["nid"])) {
                state.errorArray["nid"] = true;
                state.errorMessages["nid"] = "Only Numeric value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            /* if(state.inputData["passport"]!==undefined  && state.inputData["passport"]!==null && state.inputData["passport"]!=="" && !validator.isNumeric(state.inputData["passport"])){
                 state.errorArray["passport"] = true;
                 state.errorMessages["passport"] = "Only String value Allowed"
                 state.error = true;
                 state.errorText = true;
                 error = true;

             }
 */


            if (state.inputData["dob"] === undefined || state.inputData["dob"] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob"]))) {
                state.errorArray["dob"] = true;
                state.errorMessages["dob"] = "Date Of Birth Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }

            if (state.inputData["email"] !== undefined && state.inputData["email"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email"])) && state.inputData["email"] !== null && (
                    !validator.isEmail(state.inputData["email"]))
            )) {
                state.errorArray["email"] = true;
                state.errorMessages["email"] = "Email is Not Valid";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["nationality"] === undefined || state.inputData["nationality"] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality"]))) {
                state.errorArray["nationality"] = true;
                state.errorMessages["nationality"] = "Nationality Required";

                state.error = true;
                state.errorText = true;
                error = true;
            }

            if (state.inputData["nid"] !== undefined && state.inputData["nid"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid"])) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {
                min: 10,
                max: 10
            }) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {
                min: 13,
                max: 13
            }) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {min: 17, max: 17}))) {
                state.errorArray["nid"] = true;
                state.errorMessages["nid"] = "NID should be 10/13/17 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["registrationNo"] !== undefined && state.inputData["registrationNo"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo"])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo"]), {
                min: 0,
                max: 17
            })))) {
                state.errorArray["registrationNo"] = true;
                state.errorMessages["registrationNo"] = "Birth certificate Number should be upto 17 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["passport"] !== undefined && state.inputData["passport"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport"])) && (!validator.isLength(this.trimFunction(state.inputData["passport"]), {
                min: 1,
                max: 12
            })))) {
                state.errorArray["passport"] = true;
                state.errorMessages["passport"] = "Passport should be upto 12  digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["tin"] !== undefined && state.inputData["tin"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin"])) && (!validator.isLength(this.trimFunction(state.inputData["tin"]), {
                min: 12,
                max: 12
            })))) {
                state.errorArray["tin"] = true;
                state.errorMessages["tin"] = "E-Tin should be 12 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["phone"] === undefined || state.inputData["phone"] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone"]))) {
                state.errorArray["phone"] = true;
                state.errorMessages["phone"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone"])) && !validator.isNumeric(this.trimFunction(state.inputData["phone"])))) {
                state.errorArray["phone"] = true;
                state.errorMessages["phone"] = "Mobile Number should be Only number digit";

                state.error = true;
                state.errorText = true;
                error = true;
            } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone"])) && !validator.isLength(this.trimFunction(state.inputData["phone"]), {
                min: 11,
                max: 11
            }))) {
                state.errorArray["phone"] = true;
                state.errorMessages["phone"] = "Mobile Number should be 11 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }


        }

        return error;
    };

    static CustomValidationIndividualmandate(jsonForm, state) {
        let error = false;
        //state.errorArray = {}
        // state.errorMessages = {}

        if (state.inputData["mandatecbNumber"] !== "" && state.inputData["mandatecbNumber"] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["mandatecbNumber"]))) {

            jsonForm.map((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
            )
        } else if (state.inputData["mandateaccountNumber"] !== "" && state.inputData["mandateaccountNumber"] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["mandateaccountNumber"]))) {

            jsonForm.map((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
            )
        } else {

            if ((state.inputData["mandatenid"] === undefined || state.inputData["mandatenid"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatenid"]))) && (state.inputData["mandatepassport"] === undefined || state.inputData["mandatepassport"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatepassport"]))) && (state.inputData["mandateregistrationNo"] === undefined || state.inputData["mandateregistrationNo"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandateregistrationNo"])))) {
                state.errorArray["mandatenid"] = true;
                state.errorMessages["mandatenid"] = "nid or epassport or Birth Certificate number required";

                state.errorArray["mandatepassport"] = true;
                state.errorMessages["mandatepassport"] = "nid or epassport or Birth Certificate number required";


                state.errorArray["mandateregistrationNo"] = true;
                state.errorMessages["mandateregistrationNo"] = "nid or epassport or Birth Certificate number required";

                state.error = true;
                state.errorText = true;
                error = true;
            } else {

                state.errorArray["mandatenid"] = false;
                state.errorArray["mandatepassport"] = false;
                state.errorArray["mandateregistrationNo"] = false;

                state.errorMessages["mandatenid"] = "";
                state.errorMessages["mandatepassport"] = "";
                state.errorMessages["mandateregistrationNo"] = "";
                state.error = false;
                state.errorText = false;
                error = false;
            }
            if (state.inputData["mandatecustomerName"] === undefined || state.inputData["mandatecustomerName"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatecustomerName"]))) {
                state.errorArray["mandatecustomerName"] = true;
                state.errorMessages["mandatecustomerName"] = "Customer Name Required";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            if (state.inputData["mandatecustomerName"] !== undefined && state.inputData["mandatecustomerName"] !== null && state.inputData["mandatecustomerName"] !== "" && validator.isNumeric(state.inputData["mandatecustomerName"])) {
                state.errorArray["mandatecustomerName"] = true;
                state.errorMessages["mandatecustomerName"] = "Only String value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            if (state.inputData["mandatenid"] !== undefined && state.inputData["mandatenid"] !== null && state.inputData["mandatenid"] !== "" && !validator.isNumeric(state.inputData["mandatenid"])) {
                state.errorArray["mandatenid"] = true;
                state.errorMessages["mandatenid"] = "Only Numeric value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }
            /* if(state.inputData["mandatepassport"]!==undefined  && state.inputData["mandatepassport"]!==null && state.inputData["mandatepassport"]!=="" && !validator.isNumeric(state.inputData["mandatepassport"])){
                 state.errorArray["mandatepassport"] = true;
                 state.errorMessages["mandatepassport"] = "Only String value Allowed"
                 state.error = true;
                 state.errorText = true;
                 error = true;

             }
    */


            if (state.inputData["mandatemandatedob"] === undefined || state.inputData["mandatedob"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatedob"]))) {
                state.errorArray["mandatedob"] = true;
                state.errorMessages["mandatedob"] = "Date Of Birth Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }

            if (state.inputData["mandateemail"] !== undefined && state.inputData["mandateemail"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["mandateemail"])) && state.inputData["mandateemail"] !== null && (
                    !validator.isEmail(state.inputData["mandateemail"]))
            )) {
                state.errorArray["mandateemail"] = true;
                state.errorMessages["mandateemail"] = "Email is Not Valid";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["mandatenationality"] === undefined || state.inputData["mandatenationality"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatenationality"]))) {
                state.errorArray["mandatenationality"] = true;
                state.errorMessages["mandatenationality"] = "Nationality Required";

                state.error = true;
                state.errorText = true;
                error = true;
            }

            if (state.inputData["mandatenid"] !== undefined && state.inputData["mandatenid"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["mandatenid"])) && !validator.isLength(this.trimFunction(state.inputData["mandatenid"]), {
                min: 10,
                max: 10
            }) && !validator.isLength(this.trimFunction(state.inputData["mandatenid"]), {
                min: 13,
                max: 13
            }) && !validator.isLength(this.trimFunction(state.inputData["mandatenid"]), {min: 17, max: 17}))) {
                state.errorArray["mandatenid"] = true;
                state.errorMessages["mandatenid"] = "mandatenid should be 10/13/17 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["mandateregistrationNo"] !== undefined && state.inputData["mandateregistrationNo"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["mandateregistrationNo"])) && (!validator.isLength(this.trimFunction(state.inputData["mandateregistrationNo"]), {
                min: 0,
                max: 17
            })))) {
                state.errorArray["mandateregistrationNo"] = true;
                state.errorMessages["mandateregistrationNo"] = "Birth certificate Number should be upto 17 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["mandatepassport"] !== undefined && state.inputData["mandatepassport"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["mandatepassport"])) && (!validator.isLength(this.trimFunction(state.inputData["mandatepassport"]), {
                min: 1,
                max: 12
            })))) {
                state.errorArray["mandatepassport"] = true;
                state.errorMessages["mandatepassport"] = "mandatepassport Number should be 12 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["mandatetin"] !== undefined && state.inputData["mandatetin"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["mandatetin"])) && (!validator.isLength(this.trimFunction(state.inputData["mandatetin"]), {
                min: 12,
                max: 12
            })))) {
                state.errorArray["mandatetin"] = true;
                state.errorMessages["mandatetin"] = "E-tin should be 12 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["mandatephone"] === undefined || state.inputData["mandatephone"] === "" || validator.isEmpty(this.trimFunction(state.inputData["mandatephone"]))) {
                state.errorArray["mandatephone"] = true;
                state.errorMessages["mandatephone"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            } else if ((!validator.isEmpty(this.trimFunction(state.inputData["mandatephone"])) && !validator.isNumeric(this.trimFunction(state.inputData["mandatephone"])))) {
                state.errorArray["mandatephone"] = true;
                state.errorMessages["mandatephone"] = "Mobile Number should be Only number digit";

                state.error = true;
                state.errorText = true;
                error = true;
            } else if ((!validator.isEmpty(this.trimFunction(state.inputData["mandatephone"])) && !validator.isLength(this.trimFunction(state.inputData["mandatephone"]), {
                min: 11,
                max: 11
            }))) {
                state.errorArray["mandatephone"] = true;
                state.errorMessages["mandatephone"] = "Mobile Number should be 11 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }


        }

        return error;
    };

    static CustomValidationJoint(jsonForm, state) {
        let error = false;
        // state.errorArray = {}
        // state.errorMessages = {}
        let arrayReturn = {};
        let count = 0;

        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (state.inputData["cbNumber" + j] !== "" && state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber" + j]))) {

                state.errorArray["nid" + j] = false;
                state.errorMessages["nid" + j] = "";
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";

                state.errorArray["passport" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["phone" + j] = "Required";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;
            } else if (state.inputData["accountNumber" + j] !== "" && state.inputData["accountNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["accountNumber" + j]))) {

                state.errorArray["nid" + j] = false;
                state.errorMessages["nid" + j] = "";
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";

                state.errorArray["passport" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["phone" + j] = "Required";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;
            } else {

                if ((state.inputData["nid" + j] === undefined || state.inputData["nid" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid" + j]))) && (state.inputData["smartCard" + j] === undefined || state.inputData["smartCard" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["smartCard" + j]))) && (state.inputData["passport" + j] === undefined || state.inputData["passport" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport" + j]))) && (state.inputData["registrationNo" + j] === undefined || state.inputData["registrationNo" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID or Passport or Birth Certificate number required";
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "NID or Passport or Birth Certificate number required";

                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "NID or Passport or Birth Certificate number required";


                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "NID or Passport or Birth Certificate number required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {

                    state.errorArray["nid" + j] = false;
                    state.errorArray["smartCard" + j] = false;
                    state.errorArray["passport" + j] = false;
                    state.errorArray["registrationNo" + j] = false;

                    state.errorMessages["nid" + j] = "";
                    state.errorMessages["smartCard" + j] = "";
                    state.errorMessages["passport" + j] = "";
                    state.errorMessages["registrationNo" + j] = "";
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
                if (state.inputData["customerName" + j] === undefined || state.inputData["customerName" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName" + j]))) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["customerName" + j] !== undefined && state.inputData["customerName" + j] !== null && state.inputData["customerName" + j] !== "" && validator.isNumeric(state.inputData["customerName" + j])) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Only String value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && state.inputData["nid" + j] !== "" && !validator.isNumeric(state.inputData["nid" + j])) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["smartCard" + j] !== undefined && state.inputData["smartCard" + j] !== null && state.inputData["smartCard" + j] !== "" && !validator.isNumeric(state.inputData["smartCard" + j])) {
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                /* if(state.inputData["passport"]!==undefined  && state.inputData["passport"]!==null && state.inputData["passport"]!=="" && !validator.isNumeric(state.inputData["passport"])){
                     state.errorArray["passport"] = true;
                     state.errorMessages["passport"] = "Only String value Allowed"
                     state.error = true;
                     state.errorText = true;
                     error = true;

                 }
     */

                if (state.inputData["dob" + j] === "Invalid Date") {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Invalid Date";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["dob" + j] === undefined || state.inputData["dob" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob" + j]))) {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


                if (state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email" + j])) && state.inputData["email" + j] !== null && (
                        !validator.isEmail(state.inputData["email" + j]))
                )) {
                    state.errorArray["email" + j] = true;
                    state.errorMessages["email" + j] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality" + j] === undefined || state.inputData["nationality" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality" + j]))) {
                    state.errorArray["nationality" + j] = true;
                    state.errorMessages["nationality" + j] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid" + j])) && !validator.isLength(this.trimFunction(state.inputData["nid" + j]), {
                    min: 13,
                    max: 13
                }) && !validator.isLength(this.trimFunction(state.inputData["nid" + j]), {min: 17, max: 17}))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID should be 13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["smartCard" + j] !== undefined && state.inputData["smartCard" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["smartCard" + j])) && !validator.isLength(this.trimFunction(state.inputData["smartCard" + j]), {
                    min: 10,
                    max: 10
                }))) {
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "Smart Card should be 10 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["registrationNo" + j] !== undefined && state.inputData["registrationNo" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo" + j]), {
                    min: 0,
                    max: 17
                })))) {
                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "Birth certificate Number should be upto 17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["passport" + j] !== undefined && state.inputData["passport" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport" + j])) && (!validator.isLength(this.trimFunction(state.inputData["passport" + j]), {
                    min: 1,
                    max: 12
                })))) {
                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "Passport should be upto 12  digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["tin" + j] !== undefined && state.inputData["tin" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin" + j])) && (!validator.isLength(this.trimFunction(state.inputData["tin" + j]), {
                    min: 12,
                    max: 12
                })))) {
                    state.errorArray["tin" + j] = true;
                    state.errorMessages["tin" + j] = "E-Tin should be 12 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["mobileTypeIndividual" + j] === "Local") {
                    if (state.inputData["phone" + j] === undefined || state.inputData["phone" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone" + j]))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isNumeric(this.trimFunction(state.inputData["phone" + j])))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Mobile Number should be Only number digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isLength(this.trimFunction(state.inputData["phone" + j]), {
                        min: 11,
                        max: 11
                    }))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Mobile Number should be 11 digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                }


            }

            arrayReturn[j] = error;
        }

        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (arrayReturn[j] === false) {
                count++;
            }
        }
        console.log(state.errorArray);
        console.log(state.errorMessages);
        if (Number(count) === Number(state.handleChangeJoint)) {
            return false;
        } else {
            return true;
        }
    };

    static CustomValidationJointS(jsonForm, state) {
        let error = false;
        // state.errorArray = {}
        // state.errorMessages = {}
        let arrayReturn = {};
        let count = 0;

        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (state.inputData["cbNumber" + j] !== "" && state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber" + j]))) {

                state.errorArray["nid" + j] = false;
                state.errorMessages["nid" + j] = "";
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";

                state.errorArray["passport" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["phone" + j] = "Required";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;
            } else if (state.inputData["accountNumber" + j] !== "" && state.inputData["accountNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["accountNumber" + j]))) {

                state.errorArray["nid" + j] = false;
                state.errorMessages["nid" + j] = "";
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";

                state.errorArray["passport" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["phone" + j] = "Required";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;
            } else {

                if ((state.inputData["nid" + j] === undefined || state.inputData["nid" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid" + j]))) && (state.inputData["smartCard" + j] === undefined || state.inputData["smartCard" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["smartCard" + j]))) && (state.inputData["passport" + j] === undefined || state.inputData["passport" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport" + j]))) && (state.inputData["registrationNo" + j] === undefined || state.inputData["registrationNo" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID or Passport or Birth Certificate number required";
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "NID or Passport or Birth Certificate number required";

                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "NID or Passport or Birth Certificate number required";


                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "NID or Passport or Birth Certificate number required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {

                    state.errorArray["nid" + j] = false;
                    state.errorArray["smartCard" + j] = false;
                    state.errorArray["passport" + j] = false;
                    state.errorArray["registrationNo" + j] = false;

                    state.errorMessages["nid" + j] = "";
                    state.errorMessages["smartCard" + j] = "";
                    state.errorMessages["passport" + j] = "";
                    state.errorMessages["registrationNo" + j] = "";
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
                if (state.inputData["customerName" + j] === undefined || state.inputData["customerName" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName" + j]))) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["customerName" + j] !== undefined && state.inputData["customerName" + j] !== null && state.inputData["customerName" + j] !== "" && validator.isNumeric(state.inputData["customerName" + j])) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Only String value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && state.inputData["nid" + j] !== "" && !validator.isNumeric(state.inputData["nid" + j])) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["smartCard" + j] !== undefined && state.inputData["smartCard" + j] !== null && state.inputData["smartCard" + j] !== "" && !validator.isNumeric(state.inputData["smartCard" + j])) {
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                /* if(state.inputData["passport"]!==undefined  && state.inputData["passport"]!==null && state.inputData["passport"]!=="" && !validator.isNumeric(state.inputData["passport"])){
                     state.errorArray["passport"] = true;
                     state.errorMessages["passport"] = "Only String value Allowed"
                     state.error = true;
                     state.errorText = true;
                     error = true;

                 }
     */

                if (state.inputData["dob" + j] === "Invalid Date") {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Invalid Date";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["dob" + j] === undefined || state.inputData["dob" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob" + j]))) {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


                if (state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email" + j])) && state.inputData["email" + j] !== null && (
                        !validator.isEmail(state.inputData["email" + j]))
                )) {
                    state.errorArray["email" + j] = true;
                    state.errorMessages["email" + j] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality" + j] === undefined || state.inputData["nationality" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality" + j]))) {
                    state.errorArray["nationality" + j] = true;
                    state.errorMessages["nationality" + j] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid" + j])) && !validator.isLength(this.trimFunction(state.inputData["nid" + j]), {
                    min: 13,
                    max: 13
                }) && !validator.isLength(this.trimFunction(state.inputData["nid" + j]), {min: 17, max: 17}))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID should be 13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["smartCard" + j] !== undefined && state.inputData["smartCard" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["smartCard" + j])) && !validator.isLength(this.trimFunction(state.inputData["smartCard" + j]), {
                    min: 10,
                    max: 10
                }))) {
                    state.errorArray["smartCard" + j] = true;
                    state.errorMessages["smartCard" + j] = "Smart Card should be 10 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["registrationNo" + j] !== undefined && state.inputData["registrationNo" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo" + j]), {
                    min: 0,
                    max: 17
                })))) {
                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "Birth certificate Number should be upto 17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["passport" + j] !== undefined && state.inputData["passport" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport" + j])) && (!validator.isLength(this.trimFunction(state.inputData["passport" + j]), {
                    min: 1,
                    max: 12
                })))) {
                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "Passport should be upto 12  digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["tin" + j] !== undefined && state.inputData["tin" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin" + j])) && (!validator.isLength(this.trimFunction(state.inputData["tin" + j]), {
                    min: 12,
                    max: 12
                })))) {
                    state.errorArray["tin" + j] = true;
                    state.errorMessages["tin" + j] = "E-Tin should be 12 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["mobileTypeIndividual" + j] === "Local") {
                    if (state.inputData["phone" + j] === undefined || state.inputData["phone" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone" + j]))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isNumeric(this.trimFunction(state.inputData["phone" + j])))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Mobile Number should be Only number digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isLength(this.trimFunction(state.inputData["phone" + j]), {
                        min: 11,
                        max: 11
                    }))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Mobile Number should be 11 digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                }


            }

            arrayReturn[j] = error;


        }
        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (arrayReturn[j] === false) {
                count++;
            }
        }
        console.log(state.errorArray);
        console.log(state.errorMessages);
        if (Number(count) === Number(state.handleChangeJoint)) {
            return false;
        } else {
            return true;
        }
    };

    static CustomValidationProprietorship(jsonForm, state) {
        let error = false;
        //  state.errorArray = {}
        // state.errorMessages = {}


        if (state.inputData["companyCbNumber"] !== undefined && state.inputData["companyCbNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyCbNumber"]))) {
            state.errorArray["companyEtin"] = false;
            state.errorMessages["companyEtin"] = "";
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["companyTradeLicense"] = false;
            state.errorMessages["companyTradeLicense"] = "";
            /*  state.errorArray["certificate"] = false;
              state.errorMessages["certificate"] = "";*/
            state.error = false;
            state.errorText = false;
            //error = false;

        } else if (state.inputData["companyAccountNumber"] !== undefined && state.inputData["companyAccountNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyAccountNumber"]))) {
            state.errorArray["companyEtin"] = false;
            state.errorMessages["companyEtin"] = "";
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["companyTradeLicense"] = false;
            state.errorMessages["companyTradeLicense"] = "";
            /*  state.errorArray["certificate"] = false;
              state.errorMessages["certificate"] = "";*/
            state.error = false;
            state.errorText = false;
            //error = false;

        } else {


            if (state.inputData["companyName"] === undefined || state.inputData["companyName"] === "" || validator.isEmpty(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["companyName"] !== undefined && state.inputData["companyName"] !== null && state.inputData["companyName"] !== "" && validator.isNumeric(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Only String value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }

            /* if (state.inputData["companyEtin"] === undefined || state.inputData["companyEtin"] === "" || validator.isEmpty(this.trimFunction(state.inputData["companyEtin"]))) {
                 state.errorArray["companyEtin"] = true;
                 state.errorMessages["companyEtin"] = "Required";
                 state.error = true;
                 state.errorText = true;
                 error = true;
             }*/
            if (state.inputData["companyEtin"] !== undefined && state.inputData["companyEtin"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["companyEtin"])) && (!validator.isLength(this.trimFunction(state.inputData["companyEtin"]), {
                min: 12,
                max: 12
            })))) {
                state.errorArray["companyEtin"] = true;
                state.errorMessages["companyEtin"] = "E-Tin should be 12 digit";

                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["companyTradeLicense"] === undefined || state.inputData["companyTradeLicense"] === "" || validator.isEmpty(this.trimFunction(state.inputData["companyTradeLicense"]))) {

                state.errorArray["companyTradeLicense"] = true;
                state.errorMessages["companyTradeLicense"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            /*   if (state.inputData["certificate"] === undefined || state.inputData["certificate"] === "" || validator.isEmpty(this.trimFunction(state.inputData["certificate"]))) {

                   state.errorArray["certificate"] = true;
                   state.errorMessages["certificate"] = "Required";
                   state.error = true;
                   state.errorText = true;
                   error = true;
               }*/

        }
        /*   if (state.individualDedupeCheckbox && state.inputData["cbNumber"] !== "" && state.inputData["cbNumber"] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber"]))) {

               jsonForm.map((field) => {
                       state.errorMessages[field.varName] = ""
                       state.errorArray[field.varName] = false;
                       state.error = false;
                       state.errorText = false;
                       error = false;
                   }
               )
           }
           else {

               if ((state.inputData["nid"] === undefined || state.inputData["nid"] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid"]))) && (state.inputData["passport"] === undefined || state.inputData["passport"] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport"]))) && (state.inputData["registrationNo"] === undefined || state.inputData["registrationNo"] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo"])))) {
                   state.errorArray["nid"] = true;
                   state.errorMessages["nid"] = "NID or Passport or Birth Certificate number required"

                   state.errorArray["passport"] = true;
                   state.errorMessages["passport"] = "NID or Passport or Birth Certificate number required"


                   state.errorArray["registrationNo"] = true;
                   state.errorMessages["registrationNo"] = "NID or Passport or Birth Certificate number required"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               } else {

                   state.errorArray["nid"] = false;
                   state.errorArray["passport"] = false;
                   state.errorArray["registrationNo"] = false;

                   state.errorMessages["nid"] = "";
                   state.errorMessages["passport"] = "";
                   state.errorMessages["registrationNo"] = "";
                   state.error = false;
                   state.errorText = false;
                   error = false;
               }
               if (state.inputData["customerName"] === undefined || state.inputData["customerName"] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName"]))) {
                   state.errorArray["customerName"] = true;
                   state.errorMessages["customerName"] = "Customer Name Required"
                   state.error = true;
                   state.errorText = true;
                   error = true;

               }
               if(state.inputData["customerName"]!==undefined  && state.inputData["customerName"]!==null && state.inputData["customerName"]!=="" && validator.isNumeric(state.inputData["customerName"])){
                   state.errorArray["customerName"] = true;
                   state.errorMessages["customerName"] = "Only String value Allowed"
                   state.error = true;
                   state.errorText = true;
                   error = true;

               }
               if(state.inputData["nid"]!==undefined  && state.inputData["nid"]!==null && state.inputData["nid"]!=="" && !validator.isNumeric(state.inputData["nid"])){
                   state.errorArray["nid"] = true;
                   state.errorMessages["nid"] = "Only Numeric value Allowed"
                   state.error = true;
                   state.errorText = true;
                   error = true;

               }


               if (state.inputData["dob"] === undefined || state.inputData["dob"] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob"]))) {
                   state.errorArray["dob"] = true;
                   state.errorMessages["dob"] = "Date Of Birth Required"
                   state.error = true;
                   state.errorText = true;
                   error = true;
               }

               if (state.inputData["email"] !== undefined && state.inputData["email"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email"])) && state.inputData["email"] !== null && (
                       !validator.isEmail(state.inputData["email"]))
               )) {
                   state.errorArray["email"] = true;
                   state.errorMessages["email"] = "Email is Not Valid"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }
                if (state.inputData["nationality"] === undefined || state.inputData["nationality"] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality"]))) {
                    state.errorArray["nationality"] = true;
                    state.errorMessages["nationality"] = "Nationality Required"

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


               if (state.inputData["nid"] !== undefined && state.inputData["nid"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid"])) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {
                   min: 10,
                   max: 10
               }) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {
                   min: 13,
                   max: 13
               }) && !validator.isLength(this.trimFunction(state.inputData["nid"]), {min: 17, max: 17}))) {
                   state.errorArray["nid"] = true;
                   state.errorMessages["nid"] = "NID should be 10/13/17 digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }
               if (state.inputData["registrationNo"] !== undefined && state.inputData["registrationNo"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo"])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo"]), {
                   min: 0,
                   max: 17
               })))) {
                   state.errorArray["registrationNo"] = true;
                   state.errorMessages["registrationNo"] = "Birth certificate Number should be upto 17 digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }
               if (state.inputData["passport"] !== undefined && state.inputData["passport"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport"])) && (!validator.isLength(this.trimFunction(state.inputData["passport"]), {
                   min: 0,
                   max: 12
               })))) {
                   state.errorArray["passport"] = true;
                   state.errorMessages["passport"] =  "Passport should be upto 12  digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }
               if (state.inputData["tin"] !== undefined && state.inputData["tin"] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin"])) && (!validator.isLength(this.trimFunction(state.inputData["tin"]), {
                   min: 12,
                   max: 12
               })))) {
                   state.errorArray["tin"] = true;
                   state.errorMessages["tin"] = "E-Tin should be 12 digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }
               if (state.inputData["phone"] === undefined || state.inputData["phone"] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone"]))) {
                   state.errorArray["phone"] = true;
                   state.errorMessages["phone"] = "Required"
                   state.error = true;
                   state.errorText = true;
                   error = true;
               } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone"])) && !validator.isNumeric(this.trimFunction(state.inputData["phone"])))) {
                   state.errorArray["phone"] = true;
                   state.errorMessages["phone"] = "Mobile Number should be Only number digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone"])) && !validator.isLength(this.trimFunction(state.inputData["phone"]), {
                   min: 11,
                   max: 11
               }))) {
                   state.errorArray["phone"] = true;
                   state.errorMessages["phone"] = "Mobile Number should be 11 digit"

                   state.error = true;
                   state.errorText = true;
                   error = true;
               }


           }*/


        return error;
    };

    static CustomValidationCompany(jsonForm, state) {
        //let getData = state.inputData[field.varName];
        let error = false;
        // state.errorArray = {}
        // state.errorMessages = {}
        let arrayReturn = {};
        let count = 0;

        if (state.inputData["companyCbNumber"] !== undefined && state.inputData["companyCbNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyCbNumber"]))) {
            state.errorArray["companyEtin"] = false;
            state.errorMessages["companyEtin"] = "";
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["companyTradeLicense"] = false;
            state.errorMessages["companyTradeLicense"] = "";
            state.errorArray["certificate"] = false;
            state.errorMessages["certificate"] = "";
            state.error = false;
            state.errorText = false;
            //error = false;

        } else if (state.inputData["companyAccountNumber"] !== undefined && state.inputData["companyAccountNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyAccountNumber"]))) {
            state.errorArray["companyEtin"] = false;
            state.errorMessages["companyEtin"] = "";
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["companyTradeLicense"] = false;
            state.errorMessages["companyTradeLicense"] = "";
            state.errorArray["certificate"] = false;
            state.errorMessages["certificate"] = "";
            state.error = false;
            state.errorText = false;
            //error = false;

        } else {


            if (state.inputData["companyName"] === undefined || state.inputData["companyName"] === "" || validator.isEmpty(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["companyName"] !== undefined && state.inputData["companyName"] !== null && state.inputData["companyName"] !== "" && validator.isNumeric(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Only String value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }

            if (state.inputData["companyEtin"] === undefined || state.inputData["companyEtin"] === "" || validator.isEmpty(this.trimFunction(state.inputData["companyEtin"]))) {
                state.errorArray["companyEtin"] = true;
                state.errorMessages["companyEtin"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["companyTradeLicense"] === undefined || state.inputData["companyTradeLicense"] === "" || validator.isEmpty(this.trimFunction(state.inputData["companyTradeLicense"]))) {

                state.errorArray["companyTradeLicense"] = true;
                state.errorMessages["companyTradeLicense"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            /* if (state.inputData["certificate"] === undefined || state.inputData["certificate"] === "" || validator.isEmpty(this.trimFunction(state.inputData["certificate"]))) {

                 state.errorArray["certificate"] = true;
                 state.errorMessages["certificate"] = "Required";
                 state.error = true;
                 state.errorText = true;
                 error = true;
                 alert("certificate")
             }*/

        }

        for (let j = 0; j < state.handleChangeJoint; j++) {


            if (state.inputData["cbNumber" + j] !== "" && state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber" + j]))) {
                state.errorMessages["nid" + j] = "";
                state.errorArray["nid" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["passport" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["email" + j] = "";
                state.errorArray["email" + j] = false;
                state.errorMessages["phone" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["tin" + j] = "";
                state.errorArray["tin" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["nationality" + j] = "";
                state.errorArray["nationality" + j] = false;
                state.error = false;
                state.errorText = false;
                //error = false;

            } else if (state.inputData["accountNumber" + j] !== "" && state.inputData["accountNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["accountNumber" + j]))) {

                state.errorArray["nid" + j] = false;
                state.errorMessages["nid" + j] = "";
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";

                state.errorArray["passport" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["phone" + j] = "Required";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;
            } else {

                if ((state.inputData["nid" + j] === undefined || state.inputData["nid" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid" + j]))) && (state.inputData["passport" + j] === undefined || state.inputData["passport" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport" + j]))) && (state.inputData["registrationNo" + j] === undefined || state.inputData["registrationNo" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID or Passport or Birth Certificate number required";

                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "NID or Passport or Birth Certificate number required";


                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "NID or Passport or Birth Certificate number required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {

                    state.errorArray["nid" + j] = false;
                    state.errorArray["passport" + j] = false;
                    state.errorArray["registrationNo" + j] = false;

                    state.errorMessages["nid" + j] = "";
                    state.errorMessages["passport" + j] = "";
                    state.errorMessages["registrationNo" + j] = "";
                    state.error = false;
                    state.errorText = false;
                    //error = false;
                }
                if (state.inputData["customerName" + j] === undefined || state.inputData["customerName" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName" + j]))) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["customerName" + j] !== undefined && state.inputData["customerName" + j] !== null && state.inputData["customerName" + j] !== "" && validator.isNumeric(state.inputData["customerName" + j])) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Only String value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && state.inputData["nid" + j] !== "" && !validator.isNumeric(state.inputData["nid" + j])) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }


                if (state.inputData["dob" + j] === undefined || state.inputData["dob" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob" + j]))) {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email" + j])) && state.inputData["email" + j] !== null && (
                        !validator.isEmail(state.inputData["email" + j]))
                )) {
                    state.errorArray["email" + j] = true;
                    state.errorMessages["email" + j] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["registrationNo" + j] !== undefined && state.inputData["registrationNo" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo" + j]), {
                    min: 0,
                    max: 17
                })))) {
                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "Birth certificate Number should be upto 17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["passport" + j] !== undefined && state.inputData["passport" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport" + j])) && (!validator.isLength(this.trimFunction(state.inputData["passport" + j]), {
                    min: 0,
                    max: 12
                })))) {
                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "Passport should be upto 12  digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["tin" + j] !== undefined && state.inputData["tin" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin" + j])) && (!validator.isLength(this.trimFunction(state.inputData["tin" + j]), {
                    min: 12,
                    max: 12
                })))) {
                    state.errorArray["tin" + j] = true;
                    state.errorMessages["tin" + j] = "E-Tin should be 12 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality" + j] === undefined || state.inputData["nationality" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality" + j]))) {
                    state.errorArray["nationality" + j] = true;
                    state.errorMessages["nationality" + j] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid" + j])) && !validator.isLength(state.inputData["nid" + j], {
                    min: 10,
                    max: 10
                }) && !validator.isLength(state.inputData["nid" + j], {
                    min: 13,
                    max: 13
                }) && !validator.isLength(state.inputData["nid" + j], {min: 17, max: 17}))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID should be 10/13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["phone" + j] === undefined || state.inputData["phone" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone" + j]))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isNumeric(state.inputData["phone" + j]))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Mobile Number should be Only number digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isLength(state.inputData["phone" + j], {
                    min: 11,
                    max: 11
                }))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Mobile Number should be 11 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


            }
            arrayReturn[j] = error;


        }


        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (arrayReturn[j] === false) {
                count++;
            }
        }
        console.log(state.errorArray);
        console.log(state.errorMessages);
        if (Number(count) === Number(state.handleChangeJoint)) {
            return false;
        } else {
            return true;
        }
    };

    static CustomValidationCompanyNGO(jsonForm, state) {
        //let getData = state.inputData[field.varName];
        let error = false;
        // state.errorArray = {}
        // state.errorMessages = {}
        let arrayReturn = {};
        let count = 0;


        if (state.inputData["companyCbNumber"] !== undefined && state.inputData["companyCbNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyCbNumber"]))) {
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["certificate"] = false;
            state.errorMessages["certificate"] = "";
            state.error = false;
            state.errorText = false;
            //error = false;

        } else if (state.inputData["companyAccountNumber"] !== undefined && state.inputData["companyAccountNumber"] !== "" && !validator.isEmpty(this.trimFunction(state.inputData["companyAccountNumber"]))) {
            state.errorArray["companyName"] = false;
            state.errorMessages["companyName"] = "";
            state.errorArray["certificate"] = false;
            state.errorMessages["certificate"] = "";
            state.error = false;
            state.errorText = false;
            //error = false;

        } else {


            if (state.inputData["companyName"] === undefined || state.inputData["companyName"] === "" || validator.isEmpty(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }
            if (state.inputData["companyName"] !== undefined && state.inputData["companyName"] !== null && state.inputData["companyName"] !== "" && validator.isNumeric(state.inputData["companyName"])) {
                state.errorArray["companyName"] = true;
                state.errorMessages["companyName"] = "Only String value Allowed";
                state.error = true;
                state.errorText = true;
                error = true;

            }


            if (state.inputData["certificate"] === undefined || state.inputData["certificate"] === "" || validator.isEmpty(this.trimFunction(state.inputData["certificate"]))) {

                state.errorArray["certificate"] = true;
                state.errorMessages["certificate"] = "Required";
                state.error = true;
                state.errorText = true;
                error = true;
            }

        }
        for (let j = 0; j < state.handleChangeJoint; j++) {


            if (state.inputData["cbNumber" + j] !== "" && state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["cbNumber" + j]))) {
                state.errorMessages["nid" + j] = "";
                state.errorArray["nid" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["passport" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["email" + j] = "";
                state.errorArray["email" + j] = false;
                state.errorMessages["phone" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["tin" + j] = "";
                state.errorArray["tin" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["nationality" + j] = "";
                state.errorArray["nationality" + j] = false;
                state.error = false;
                state.errorText = false;
                //error = false;
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;

            } else if (state.inputData["accountNumber" + j] !== "" && state.inputData["accountNumber" + j] !== undefined && !validator.isEmpty(this.trimFunction(state.inputData["accountNumber" + j]))) {
                state.errorMessages["nid" + j] = "";
                state.errorArray["nid" + j] = false;
                state.errorMessages["passport" + j] = "";
                state.errorArray["passport" + j] = false;
                state.errorMessages["customerName" + j] = "";
                state.errorArray["customerName" + j] = false;
                state.errorMessages["dob" + j] = "";
                state.errorArray["dob" + j] = false;
                state.errorMessages["email" + j] = "";
                state.errorArray["email" + j] = false;
                state.errorMessages["phone" + j] = "";
                state.errorArray["phone" + j] = false;
                state.errorMessages["tin" + j] = "";
                state.errorArray["tin" + j] = false;
                state.errorMessages["registrationNo" + j] = "";
                state.errorArray["registrationNo" + j] = false;
                state.errorMessages["nationality" + j] = "";
                state.errorArray["nationality" + j] = false;
                state.error = false;
                state.errorText = false;
                //error = false;
                state.errorArray["smartCard" + j] = false;
                state.errorMessages["smartCard" + j] = "";
                state.errorArray["mobileTypeJoint" + j] = false;
                state.errorMessages["mobileTypeJoint" + j] = "Required";
                state.error = false;
                state.errorText = false;
                error = false;

            } else {

                if ((state.inputData["nid" + j] === undefined || state.inputData["nid" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nid" + j]))) && (state.inputData["passport" + j] === undefined || state.inputData["passport" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["passport" + j]))) && (state.inputData["registrationNo" + j] === undefined || state.inputData["registrationNo" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID or Passport or Birth Certificate number required";

                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "NID or Passport or Birth Certificate number required";


                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "NID or Passport or Birth Certificate number required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {

                    state.errorArray["nid" + j] = false;
                    state.errorArray["passport" + j] = false;
                    state.errorArray["registrationNo" + j] = false;

                    state.errorMessages["nid" + j] = "";
                    state.errorMessages["passport" + j] = "";
                    state.errorMessages["registrationNo" + j] = "";
                    state.error = false;
                    state.errorText = false;
                    //error = false;
                }
                if (state.inputData["customerName" + j] === undefined || state.inputData["customerName" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["customerName" + j]))) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["customerName" + j] !== undefined && state.inputData["customerName" + j] !== null && state.inputData["customerName" + j] !== "" && validator.isNumeric(state.inputData["customerName" + j])) {
                    state.errorArray["customerName" + j] = true;
                    state.errorMessages["customerName" + j] = "Only String value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }
                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && state.inputData["nid" + j] !== "" && !validator.isNumeric(state.inputData["nid" + j])) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "Only Numeric value Allowed";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }


                if (state.inputData["dob" + j] === undefined || state.inputData["dob" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["dob" + j]))) {
                    state.errorArray["dob" + j] = true;
                    state.errorMessages["dob" + j] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["email" + j])) && state.inputData["email" + j] !== null && (
                        !validator.isEmail(state.inputData["email" + j]))
                )) {
                    state.errorArray["email" + j] = true;
                    state.errorMessages["email" + j] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["registrationNo" + j] !== undefined && state.inputData["registrationNo" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["registrationNo" + j])) && (!validator.isLength(this.trimFunction(state.inputData["registrationNo" + j]), {
                    min: 0,
                    max: 17
                })))) {
                    state.errorArray["registrationNo" + j] = true;
                    state.errorMessages["registrationNo" + j] = "Birth certificate Number should be upto 17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["passport" + j] !== undefined && state.inputData["passport" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["passport" + j])) && (!validator.isLength(this.trimFunction(state.inputData["passport" + j]), {
                    min: 0,
                    max: 12
                })))) {
                    state.errorArray["passport" + j] = true;
                    state.errorMessages["passport" + j] = "Passport should be upto 12  digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["tin" + j] !== undefined && state.inputData["tin" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["tin" + j])) && (!validator.isLength(this.trimFunction(state.inputData["tin" + j]), {
                    min: 12,
                    max: 12
                })))) {
                    state.errorArray["tin" + j] = true;
                    state.errorMessages["tin" + j] = "E-Tin should be 12 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality" + j] === undefined || state.inputData["nationality" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["nationality" + j]))) {
                    state.errorArray["nationality" + j] = true;
                    state.errorMessages["nationality" + j] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== "" && (!validator.isEmpty(this.trimFunction(state.inputData["nid" + j])) && !validator.isLength(state.inputData["nid" + j], {
                    min: 10,
                    max: 10
                }) && !validator.isLength(state.inputData["nid" + j], {
                    min: 13,
                    max: 13
                }) && !validator.isLength(state.inputData["nid" + j], {min: 17, max: 17}))) {
                    state.errorArray["nid" + j] = true;
                    state.errorMessages["nid" + j] = "NID should be 10/13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["phone" + j] === undefined || state.inputData["phone" + j] === "" || validator.isEmpty(this.trimFunction(state.inputData["phone" + j]))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isNumeric(state.inputData["phone" + j]))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Mobile Number should be Only number digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if ((!validator.isEmpty(this.trimFunction(state.inputData["phone" + j])) && !validator.isLength(state.inputData["phone" + j], {
                    min: 11,
                    max: 11
                }))) {
                    state.errorArray["phone" + j] = true;
                    state.errorMessages["phone" + j] = "Mobile Number should be 11 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


            }

            arrayReturn[j] = error;
        }


        for (let j = 0; j < state.handleChangeJoint; j++) {
            if (arrayReturn[j] === false) {
                count++;
            }
        }
        console.log(state.errorArray);
        console.log(state.errorMessages);
        if (Number(count) === Number(state.handleChangeJoint)) {
            return false;
        } else {
            return true;
        }

    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    }


}

export default MyValidationDedupeWithCompany;