import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import loading from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import TableComponent from "../../CommonComponent/TableComponent";
import BeneficiaryAdd from "./BeneficiaryAdd";
import {commonInboxButton} from "../../../DeliverableManagement/configuration";
import Button from "@material/react-button";
import CloseIcon from "@material-ui/icons/Close";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class DisableBeneficiaryList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            openDisableModal: false,
            showTable: false,
            errorArray: {},
            errorMessages: {},
            rowInput: {},
            showModal: false,
            update: false,
            tableData: [],
            totalRow: 0,
            rowsPerPage: 200,
            tableColumns: [
                {field: "sl", title: "SL"},
                {field: "bank_name", title: "Bank Name"},
                {field: "branch_name", title: "Branch Name"},
                {field: "district_name", title: "District Name"},
                {field: "routing_number", title: "Routing Number"},
                {field: "rtgs_status", title: "RTGS Status"},
                {field: "beftn_status", title: "BEFTN Status"},
                // {field: "status", title: "Status"},
                {field: "action", title: "Action"},
            ],
            page: 0,
        }
    }

    componentDidMount() {
        this.handleChangePage();
    }

    functionForStatus = (value) => {
        if(value === 1){
            return "Enable"
        }
        else if(value === 2){
            return "Temporary Disable"
        }
        else{
            return "Disable"
        }
    };

    handleChangePage = () => {
        let url = backEndServerURL + "/finBankBranchMasterData/getDisabledlist/" + this.props.transferType;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                let rowData;
                let sl = 0;
                for (let i = 0; i < response.data.length; i++) {
                    let res = response.data[i];
                    sl = sl + 1;
                    rowData = {
                        "sl": sl,
                        "bank_name": res.bankName,
                        "branch_name": res.branchName,
                        "district_name": res.distName,
                        "routing_number": res.routingNo,
                        "transfer_type": res.transferType,
                        "rtgs_status": this.functionForStatus(res.rtgsFlag),
                        "beftn_status": this.functionForStatus(res.beftnFlag),
                        "action": this.renderEditButton({
                            bank_name: res.bankName,
                            branch_name: res.branchName,
                            district_name: res.distName,
                            routing_number: res.routingNo,
                        }),
                    };
                    tableData.push(rowData);
                }
                this.setState({
                    page: 0,
                    rowsPerPage: response.data.length,
                    totalRow: response.data.length,
                    tableData: tableData,
                    getData: true,
                    showTable: true,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >RTGS BEFTN BRANCH UPDATE <a><CloseIcon onClick={this.props.closeModal}
                                                       style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
            </h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            showModal: false,
            cbNotFound: false,
        })
    };


    renderEditButton = (rowData) => {
        return (
            <>
                <Button
                    style={commonInboxButton}
                    onClick={(event) => this.onRowClick(event, rowData)}
                >
                    Update
                </Button>
            </>
        )
    };

    copyJson = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    updateStatus = (value, status, transferType) => {
        let postData = {};
        postData.idList = value;
        postData.isActive = status;
        postData.transferType = transferType;
        let url = backEndServerURL + "/finBankBranchMasterData/changeIdListByStatus";
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.handleChangePage();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: "Something Went Wrong!",
                    loading: false,
                })
            });
    };

    triggerEnableFunction = (event, data) => {
        this.setState({
            loading: true
        });
        let idList = [];
        for (let item of data) {
            idList.push(item.routing_number)
        }
        this.updateStatus(idList, 1, this.props.transferType);
    };

    onRowClick = (event, rowData) => {
        event.preventDefault();
        this.setState({
            rowInput: rowData,
            showModal: true,
            update: true
        });
    };

    closeModal = () => {
        this.handleChangePage();
        this.setState({
            showModal: false,
            getData: false,
            showTable: false,
        })
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.showModal}>
                        <DialogContent className={classes.dialogPaper}>
                            <BeneficiaryAdd closeModal={this.closeModal} update={this.state.update}
                                            rowData={this.state.rowInput}/>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {(this.state.getData && this.state.showTable) &&
                        <TableComponent tableData={this.state.tableData} tableColumns={this.state.tableColumns}
                                        loader={this.state.loading} maxBodyHeight="450px"
                                        totalRow={this.state.totalRow}
                                        page={this.state.page} selection={true}
                                        handleChangePage={this.handleChangePage}
                                        rowsPerPage={this.state.rowsPerPage}
                                        tableButton={[{
                                            name: "Enable",
                                            triggerFunction: this.triggerEnableFunction
                                        }]}
                        />}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(DisableBeneficiaryList);
