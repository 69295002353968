import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Components/Grid/GridItem.jsx";
import axios from 'axios';
import {backEndServerURL} from '../../Common/Constant';
import GridContainer from "../../Components/Grid/GridContainer.jsx";
import Table from "../../Components/Table/Table";
import Card from "../../Components/Card/Card.jsx";
import Button from '@material-ui/core/Button';
import CardHeader from "../../Components/Card/CardHeader.jsx";
import CardBody from "../../Components/Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import {Dialog, Grow} from "@material-ui/core";
import AssignUserRole from "./AssignUserRole";
import ViewUserRole from "./ViewUserRole";
import AssignMenuToRole from './AssignMenuToRole';
import ViewMenu from './ViewMenu';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    fab: {
        backgroundColor: '#ff3443',
        '&:hover': {
            backgroundColor: "#ff3443"
        }
    }
};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class RoleList extends Component {


    constructor(props) {
        super(props);
        this.state = {
            addRole: false,
            roleView: false,
            addUser: false,
            userView: false,
            tableData: [[" ", " "]],
            roleId: '',
            assignMenuToRole: '',
            viewMenu: '',
            notificationFlag: false,
            alert: false,
            redirectLogin: false,

        };


    }

    addUser = (id) => {
        this.setState({
            addUser: true,
            roleId: id

        });
    };

    createTableData = (id, authority) => {

        return ([authority,
            /* <Button onClick={() => this.addUser(id)} color="primary">
                 Assign User
             </Button>,
             <Button onClick={() => this.roleUserView(id)} color="primary">
                 View User
             </Button>,*/
            <Button onClick={() => this.assignMenuToRole(id)} color="primary">
                Assign Menu
            </Button>,
            <Button onClick={() => this.ViewMenu(id)} color="primary">
                View Menu
            </Button>
        ]);

    };
    roleUserView = (id) => {
        this.setState({
            roleView: true,
            roleId: id
        })
    };

    assignMenuToRole = (id) => {
        this.setState({
            assignMenuToRole: true,
            roleId: id
        })
    };
    ViewMenu = (id) => {

        this.setState({
            viewMenu: true,
            roleId: id
        })
    };

    closeModal = () => {
        this.setState({
            addRole: false,
            addUser: false,
            roleView: false,
            userView: false,
            roleMenuView: false,
            assignMenuToRole: false,
            viewMenu: false,
        });
    };

    componentDidMount() {
        let url = backEndServerURL + "/role/get";

        const tableData = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("role");
                console.log(response.data);

                this.setState({roles: response.data});
                response.data.map((role) => {
                    //console.log();
                    tableData.push(this.createTableData(role.id, role.authority));

                });


                this.setState({
                    tableData: tableData
                });

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }


    stopNotification = () => {
        this.setState({
            notificationFlag: false
        })
    };

    render() {

        {

            Functions.redirectToLogin(this.state)

        }
        const {classes} = this.props;
        return (
            <>

                <Dialog
                    open={this.state.addUser}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>

                            <AssignUserRole id={this.state.roleId} closeModal={this.closeModal}/>

                        </center>


                    </div>
                </Dialog>
                <Dialog
                    open={this.state.roleView}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>

                            <ViewUserRole id={this.state.roleId}/>
                        </center>

                    </div>
                </Dialog>

                <Dialog
                    open={this.state.assignMenuToRole}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>
                            <AssignMenuToRole roleId={this.state.roleId} closeModal={this.closeModal}/>
                        </center>

                    </div>
                </Dialog>
                <Dialog
                    open={this.state.viewMenu}
                    fullWidth="true"
                    maxWidth="md"
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                >
                    <div style={{'max-height': 'calc(100vh )', 'overflow-y': 'auto'}}>
                        <center>
                            <ViewMenu roleId={this.state.roleId} closeModal={this.closeModal}/>
                        </center>

                    </div>
                </Dialog>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4  style={{color:'white'}} className={classes.cardTitleWhite}>Role List </h4>

                            </CardHeader>

                            <CardBody>
                                <Table
                                    tableHovor="yes"
                                    tableHeaderColor="primary"
                                    tableHead={["Role Name"/*, "Assign User", "View User"*/, "Assign Menu", "View Menu"]}

                                    tableData={this.state.tableData}
                                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                                /><br/><br/><br/>


                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </>
        );
    }
}

export default withStyles(styles)(RoleList);
