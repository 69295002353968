import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL, frontEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import TextField from "@material-ui/core/TextField";
import Label from "./Label";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import CBNotFound from "../../CASA/CBNotFound";
import {CHECKERJsonFormForCasaIndividual, CHECKERJsonFormForCasaIndividualLastForRashed} from "../../WorkflowJsonForm4";
import CheckList from "./CheckList";
import ExistingMandateDetails from "./ExistingMandateDetails";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import SignatureCardDelete from "./SignatureCardDelete";
import {jsonForOnlyLinkedCBChecker} from "./JsonForCasaMaintenance";


let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "required": true,
        "grid": 12
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
let bearer = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer Status/Customer Visited",
        "enum": [
            "YES",
            "NO"
        ],
        "readOnly": true,
        "grid": 6
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];

class VerifyCheckerAccountMaintenance extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        showSignatureButton: true,
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        customerNumber: "",
        loading: true,
        getRemarks: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        getCropedImage: false,
        labelData: [],
        disabled: false,
        objectForNominee: [],
        objectForExistingNominee: [],
        cbNotFound: false,
        customerDetails: {},
        numberOfSignatureCard: 0,
        selectedImageArrayList: [],
        documentList: [],
        getDocument: false,
        checkList: false,
        mandateModal: false,
        existingMandateCb: 0,
        mandateTableArray: [],
        getMandateList: [],
        needCheckForExistingMandate: [],
        alertButtonArray: [],
        checkerApproval: "",
        confirmationAlert: false,
        linkedCB: false,
        cbApproved: false,
        accountApproved: false,
        onlyDormantApproved: false,
        signatureApproved: false,
        finalApproved: false,
        apiButtonType: "",
        confirmationAlertTwo: false,
        signatureDeleteButton: false,
        signatureButtonShowInImageCropedPage: false,
        deleteSignature: false,
        cbAndAccountUpdate: false,
        showDeleteSignature: false
    };

    handleChange = (event) => {
        event.preventDefault();
        this.state.inputData[event.target.name] = event.target.value;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    highLightsEmptyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["highlight"] = false;
        }
        return returnObject;


    };
    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined" || clone[prop] === "-1")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        let remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        cbAndAccountUpdate: response.data.cbAndAccountUpdate,
                        onlyDormantApproved: response.data.onlyDormantApproved,
                        cbApproved: response.data.cbApproved,
                        accountApproved: response.data.accountApproved,
                        signatureApproved: response.data.signatureApproved,
                        deleteSignature: response.data.deleteSignature,
                        finalApproved: response.data.finalApproved,
                        linkedCB: response.data.freeFlag2 === "linkedCB"
                    });
                    let objectForExistingNominee = [];
                    if (response.data.nomineeChange) {
                        let sl;
                        for (let i = 0; i < response.data.nomineeNumberOld; i++) {
                            sl = i + 1;
                            objectForExistingNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "nomineeChange",
                                    "conditionalVarValue": true
                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID/Individual Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "nomineeChange",
                                    "conditionalVarValue": true
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "validation": "string",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "enumType": "relationCode",
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address Field 1",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address Field 2",
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "City",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": true,
                                    "enumType": "city",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "State",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": true,
                                    "enumType": "state",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "required": true,
                                    "enumType": "country",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id No/Registration No.",
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "maxDate": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "deleteOldNominee" + sl,
                                    "type": "select",
                                    "enum": ["Y", "N"],
                                    "label": "Delete This Nominee",
                                    "required": true,
                                    "readOnly": true,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": [true, true]
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                },
                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "multiline": true,
                                },
                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "readOnly": true,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "readOnly": true,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": [true, "YES", true],
                                    "required": true,
                                },
                            )
                        }
                        this.setState({
                            objectForExistingNominee: objectForExistingNominee
                        })
                    }
                    let objectForNominee = [];
                    if (response.data.numberOfNominee) {
                        var sl;
                        let newSl;
                        let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                        for (var i = 0; i < response.data.numberOfNominee; i++) {
                            newSl = i + 1;
                            sl = oldNominee + (i + 1);
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    "readOnly": true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "readOnly": true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    "readOnly": true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                /*  {
                                        "varName": "nomineenomineephotoIdNo" + i,
                                        "type": "text",
                                        "label": "NOMINEE PHOTO ID NO.",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "nomineecifIdApiCall" + i,
                                        "conditionalVarValue": true,
                                    },
                    */
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": true,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )
                        }
                        this.setState({
                            objectForNominee: objectForNominee
                        })
                    }
                    let allJsonForm = this.props.commonJsonForm.concat(CHECKERJsonFormForCasaIndividual, objectForExistingNominee, objectForNominee, CHECKERJsonFormForCasaIndividualLastForRashed);

                     let commonJsonFormLast =   CHECKERJsonFormForCasaIndividual;

                    for (let i = 0; i < commonJsonFormLast.length; i++) {
                        commonJsonFormLast[i].highlight = false
                    }
                    for (let i = 0; i < CHECKERJsonFormForCasaIndividual.length; i++) {
                        CHECKERJsonFormForCasaIndividual[i].highlight = false
                    }
                    for (let i = 0; i < CHECKERJsonFormForCasaIndividualLastForRashed.length; i++) {

                        CHECKERJsonFormForCasaIndividualLastForRashed[i].highlight = false
                    }


                        let makerEdit = backEndServerURL + '/case/fields/updatedByMaker/' + this.props.appId;
                        axios.get(makerEdit,
                            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((fieldResponse) => {
                                if (fieldResponse.status === 200) {
                                    let fieldVarName = fieldResponse.data;

                                    for (let item of allJsonForm) {
                                        for (let singleItem of fieldVarName) {
                                            if (singleItem === item.varName) {
                                                item.highlight = true
                                            }
                                        }
                                    }


                                    let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + response.data.accountNumber;
                                    axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((acResponse) => {
                                            if (acResponse.status === 200) {
                                                let mandateTableArray = [];
                                                if (acResponse.data.relPartyList) {
                                                    acResponse.data.relPartyList.map((mandate, index) => {
                                                        if (mandate.relPartyType !== "M") {
                                                            mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, this.triggerExistingMandate(mandate.custId)))
                                                        }
                                                    });
                                                }
                                                this.setState({
                                                    mandateTableArray: mandateTableArray,
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                getData: false,
                                                loading: false,
                                                cbNotFound: true,
                                                title: error.response.data.message
                                            })

                                        });
                                    if (response.data.titleChange === true) {
                                        this.functionForFileGet()
                                    }
                                    // if (response.data.signatureCard || response.data.projectRelatedDataUpdateADUP) {
                                    let file = backEndServerURL + "/applicationKeyValue/get";
                                    let data = {};
                                    data.appId = this.props.appId;
                                    data.key = "getSignatureCardNumber";
                                    axios.post(file, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((keyValueResponse) => {
                                            if (keyValueResponse.status === 200) {
                                                this.setState({
                                                    numberOfSignatureCard: Number(keyValueResponse.data.value),
                                                });
                                            }
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        });
                                    // }
                                    if (response.data.projectRelatedDataUpdateADUP === true || response.data.dormantAccountActivation === true) {
                                        this.getTExistingMandateData();
                                    }
                                    let checkerListUrl = backEndServerURL + "/checkers";
                                    axios.get(checkerListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((checkerResponse) => {
                                            let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                                            axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((deferralResponse) => {
                                                    let tableArray = [];
                                                    let status = "";
                                                    deferralResponse.data.map((deferal) => {
                                                        if (deferal.status === "ACTIVE") {
                                                            status = "Approved By BM"
                                                        } else {
                                                            status = deferal.status
                                                        }
                                                        tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                                                    });
                                                    this.setState({
                                                        getDeferalList: tableArray
                                                    })
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                });
                                            this.setState({
                                                getCheckerList: checkerResponse.data
                                            });
                                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((remarksResponse) => {
                                                    remarksResponse.data.map((data) => {
                                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                                    });
                                                    this.setState({
                                                        getRemarks: remarksArray
                                                    })
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });

                                    let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
                                    axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((fileResponse) => {
                                            let string = "SIGNATURE CARD";
                                            let imageArrayLink = [];
                                            fileResponse.data.map((document, index) => {
                                                if (document.indexOf(string) !== -1) {
                                                    let name = document.split('_')[1].split('.')[0];
                                                    imageArrayLink.push(name.split(" ")[1] + name.split(" ")[2]);
                                                }
                                            });

                                            this.setState({
                                                selectedImageArrayList: imageArrayLink,
                                            });


                                        })
                                        .catch((error) => {
                                            console.log("error");
                                            console.log(error);
                                        });

                                    setTimeout(() => {
                                        this.setState({
                                            inputData: this.removeNullValue(response.data),
                                            varValue: this.removeNullValue(response.data),
                                            appData: response.data,
                                            showValue: true,
                                            getData: true,
                                        }, this.stopLoadingForLinkedCB);
                                    }, 1000)
                                }
                            }).catch((error) => {
                            console.log(error)
                        });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    }

    stopLoadingForLinkedCB = () => {
        if (this.state.inputData.showLabel) {
            this.setState({
                showValue: true,
                getData: true,
                loading: false
            })
        }
    };

    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                // getData: true,
                // showValue: true,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForExistingNominee, this.updateComponent)
            )

        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            disabled: false,
            getCropedImage: false,
            signatureButtonShowInImageCropedPage: false
        })
    };


    functionForApprove = () => {
        let updateUrl = backEndServerURL + "/updateCustomerInfo/" + this.props.appId;
        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData.cbApproved = true;
                this.functionForVariableSetAPI().then((response) => {
                    this.setState({
                        cbApproved: true,
                        loading: false,
                        disabled: false
                    })
                }).catch((error) => {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        disabled: false
                    });
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbApproved: false,
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false
                });
            });
    };

    closeConfirmationTwo = (value) => {
        if (value === "ok") {
            this.setState({
                loading: true,
                confirmationAlertTwo: false
            });
            this.functionForSmsAPI().then((response) => {
                this.caseRoute()
            }).catch((error) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "SMS Send Failed",
                    disabled: false
                });
            })
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlertTwo: false
            });
        }
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                disabled: true,
                loading: true,
                confirmationAlert: false
            });
            if (this.state.checkerApproval === "SEND_TO_CS" || this.state.checkerApproval === "RETURN") {
                this.caseRoute()
            } else {
                if (this.state.needCheckForExistingMandate.length === this.state.getMandateList.length) {
                    if (this.state.apiButtonType === "Update CB") {
                        this.functionForApprove()
                    } else if (this.state.apiButtonType === "Update TP KYC") {
                        this.functionForUpdateTpAndKyc().then((response) => {
                            this.setState({
                                loading: false,
                                disabled: false
                            })
                        }).catch((error) => {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        })
                    } else if (this.state.apiButtonType === "Update Account") {
                        let accountUpdateUrl = backEndServerURL + "/updateAccountInfoMaintenance/" + this.props.appId + "/" + this.state.inputData.accountType;
                        axios.get(accountUpdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.state.inputData.accountApproved = true;
                                this.state.accountApproved = true;
                                if (this.state.inputData.titleChange) {
                                    this.functionForAccountTitleChange().then((res) => {
                                        this.functionForDormant()
                                    }).catch((err) => {
                                        this.setState({
                                            accountApproved: false,
                                            loading: false,
                                            cbNotFound: true,
                                            title: err.response.data.message,
                                            disabled: false
                                        });
                                    });
                                } else {
                                    this.functionForDormant()
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    accountApproved: false,
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message,
                                    disabled: false
                                });
                            })
                    } else if (this.state.apiButtonType === "Delete Signature") {
                        this.handleDeleteSignature();
                    } else if (this.state.apiButtonType === "Update Signature") {
                        this.renderImageCrop();
                    } else if (this.state.apiButtonType === "Final Approve") {
                        let title = "Request has been executed in Finacle as per below Action";
                        let alertButtonArray = [];
                        if (this.state.inputData.cbApproved) {
                            alertButtonArray.push("Update CB Data");
                        }
                        if (this.state.inputData.accountApproved) {
                            alertButtonArray.push("Update Account Data")
                        }
                        if (this.state.inputData.deleteSignature) {
                            alertButtonArray.push("Delete Signature Card")
                        }
                        if (this.state.inputData.signatureApproved) {
                            alertButtonArray.push("Update Signature Card")
                        }
                        this.setState({
                            alertButtonArray: alertButtonArray,
                            loading: false,
                            title: title,
                            confirmationAlertTwo: true
                        });
                    }
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "Please Approve All Mandate or Signatory Update Request",
                        disabled: false
                    });
                }
            }
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlert: false
            })
        }
    };

    handleSubmit = (event, checker_approval, apiButtonType) => {
        event.preventDefault();
        this.state.checkerApproval = checker_approval;
        this.state.inputData.checker_approval = checker_approval;
        if (checker_approval === "RETURN" || checker_approval === "SEND_TO_CS") {
            let error = MyValidation.defaultValidation(checkerRemarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            apiButtonType: apiButtonType
        })
    };

    functionForDormant = () => {
        if (this.state.inputData.dormantAccountActivation === true || this.state.inputData.onlyDormantAccountActivation === true) {
            this.setState({
                showSignatureButton: false
            });
            let url = backEndServerURL + "/dormantAccountActivation";
            let postData = {};
            postData.accountNumber = this.state.inputData.accountNumber;
            if (this.state.inputData.dormantAccountActivation === true || this.state.inputData.onlyDormantAccountActivation === true) {
                postData.accountStatus = this.state.inputData.fincoreDataUpdateaCStatus.key;
                postData.dormantActivationCharge = this.state.inputData.fincoreDataUpdatedormantActivationCharge;
            } else {
                postData.accountStatus = this.state.inputData.accountStatus;
                postData.dormantActivationCharge = this.state.inputData.dormantActivationCharge;
            }
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.onlyDormantApproved = true;
                    this.functionForVariableSetAPI().then((response) => {
                        this.setState({
                            showSignatureButton: true,
                            onlyDormantApproved: true,
                            accountApproved: true,
                            loading: false,
                            disabled: false
                        })
                    }).catch((error) => {
                        this.setState({
                            showSignatureButton: true,
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                            disabled: false
                        });
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        showSignatureButton: true,
                        accountApproved: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        disabled: false
                    });
                });
        } else {
            this.functionForVariableSetAPI().then((response) => {
                this.setState({
                    accountApproved: true,
                    loading: false,
                    disabled: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false
                });
            })
        }
    };

    functionForAccountTitleChange = () => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/UpdateAccountName/" + this.state.inputData.accountNumber;
            let postData = {};
            postData.fullName = this.state.inputData.miscustomerNameLastName;
            postData.shortName = this.state.inputData.misshortName;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        })
    };

    staticMaintenanceUpdate = () => {
        let variableSetUrl = backEndServerURL + "/casaStaticMaintenanceUpdate";
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.titleChange === true) {
                    this.functionForAccountTitleChange().then((res) => {
                        this.state.inputData.cbAndAccountUpdate = true;
                        this.functionForVariableSetAPI().then((response) => {
                            this.setState({
                                cbAndAccountUpdate: true,
                                loading: false,
                                // title: "Account and CB Update Successful!",
                                // cbNotFound: true,
                                disabled: false
                            })
                        }).catch((error) => {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                                disabled: false
                            });
                        })
                    }).catch((err) => {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: err.response.data.message,
                            disabled: false
                        });
                    })
                } else {
                    this.state.inputData.cbAndAccountUpdate = true;
                    this.functionForVariableSetAPI().then((response) => {
                        this.setState({
                            cbAndAccountUpdate: true,
                            loading: false,
                            // title: "Account and CB Update Successful!",
                            // cbNotFound: true,
                            disabled: false
                        })
                    }).catch((error) => {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: error.response.data.message,
                            disabled: false
                        });
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message,
                    disabled: false
                });
            });
    };

    signatureCardApi = () => {
        if (this.state.numberOfSignatureCard > 0) {
            this.setState({
                loading: true
            });
            let postData = [];
            this.state.selectedImageArrayList.map((type, i) => {
                let object = {
                    appId: this.props.appId,
                    customerId: this.state.inputData.cbNumber,
                    accountNo: this.state.inputData.accountNumber,
                    type: "CARD" + (i + 1),
                    remarks: this.state.inputData["imageRemarks" + i]
                };
                if (this.state.inputData["deleteFlag" + i] === "NO") {
                    postData.push(object);
                }
            });
            let signatureUpload = backEndServerURL + "/signatureImage/uploadToFinacleBulk";
            axios.post(signatureUpload, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.signatureApproved = true;
                    this.functionForVariableSetAPI().then((response) => {
                        this.setState({
                            signatureApproved: true,
                            loading: false,
                            disabled: false,
                        })
                    }).catch((error) => {
                        this.setState({
                            signatureApproved: false,
                            loading: false,
                            disabled: false,
                        })
                    })
                })
                .catch((error) => {
                    this.setState({
                        signatureApproved: false,
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        disabled: false
                    });
                });


        }
    };

    functionForSmsAPI = () => {
        return new Promise((resolve, reject) => {
            let sendSmsUrl = backEndServerURL + "/accountMaintenance/sendSms/" + this.state.inputData.accountNumber;
            let postData = {};
            postData.photoIdChange = this.state.inputData.photoIdChange;
            postData.otherContactNumberChange = this.state.inputData.otherContactNumberChange;
            postData.titleChange = this.state.inputData.titleChange;
            postData.nomineeChange = this.state.inputData.nomineeChange;
            postData.addressChange = this.state.inputData.addressChange;
            postData.otherInformationChange = this.state.inputData.otherInformationChange;
            postData.signatureCard = this.state.inputData.signatureCard;
            postData.dormantAccountActivation = this.state.inputData.dormantAccountActivation;
            postData.projectRelatedDataUpdateADUP = this.state.inputData.projectRelatedDataUpdateADUP;
            postData.mobileNumber = this.state.inputData.existingPhoneNumber;
            axios.post(sendSmsUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        });
    };

    functionForSignatureDelete = () => {
        if (this.state.inputData.signatureDeleteFlag !== undefined && this.state.inputData.signatureDeleteFlag.length > 0) {
            return new Promise((resolve, reject) => {
                this.setState({
                    loading: true
                });
                let url = backEndServerURL + "/intgr/delete/signature";
                axios.post(url, this.state.inputData.signatureDeleteFlag, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.inputData.deleteSignature = true;
                        this.functionForVariableSetAPI().then((response) => {
                            this.setState({
                                signatureDeleteButton: false,
                                showDeleteSignature: false,
                                deleteSignature: true,
                                loading: false,
                                disabled: false,
                            })
                        }).catch((error) => {
                            this.setState({
                                deleteSignature: false,
                                signatureDeleteButton: false,
                                showDeleteSignature: false,
                                cbNotFound: true,
                                loading: false,
                                disabled: false,
                                title: error.response.data.message
                            })
                        })
                    })
                    .catch((error) => {
                        this.setState({
                            loading: false,
                            deleteSignature: false,
                            signatureDeleteButton: false,
                            showDeleteSignature: false,
                            disabled: false,
                            cbNotFound: true,
                            title: error.response.data.message
                        });
                        console.log(error);
                        reject(error)
                    });
            })
        }
    };

    caseRoute = () => {
        if (this.state.inputData.checkerRemarks !== undefined) {
            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.props.appId;
            axios.post(commentsUrl, {remark: this.state.inputData.checkerRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                });
        }

        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        this.state.inputData.checkerRemarks = undefined;
        this.functionForVariableSetAPI().then((response) => {
            this.functionForCaseRouteApi();
        }).catch((error) => {
            this.setState({
                disabled: false,
                loading: false
            })
        })
    };

    functionForVariableSetAPI = () => {
        return new Promise((resolve, reject) => {
            let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error)
                });
        });
    };


    functionForCaseRouteApi = () => {
        let url = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    disabled: false
                });
                this.props.closeModal()
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    disabled: false
                });
            });
    };


    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }
    };

    renderApproveButton = () => {
        return (
            <>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={(event) => this.handleSubmit(event, "APPROVED", "Update CB")}
                >Update CB Data
                </button>
                &nbsp;&nbsp;&nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Account")}
                >Update Account Data
                </button>
                &nbsp;&nbsp;&nbsp;
                {(this.state.inputData.signatureDeleteFlag !== undefined && this.state.inputData.signatureDeleteFlag.length > 0) && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Delete Signature")}
                    >Delete Existing Signature
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
                {this.state.numberOfSignatureCard > 0 && <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                            marginTop: 20
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={(event) => this.handleSubmit(event, "APPROVED", "Update Signature")}
                    >Update Signature Card
                    </button>
                    &nbsp;&nbsp;&nbsp;</>}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                        marginTop: 20
                    }}
                    disabled={this.state.disabled}
                    type='button' value='add more'
                    onClick={(event) => this.handleSubmit(event, "APPROVED", "Final Approve")}
                >Approve
                </button>
                &nbsp;&nbsp;&nbsp;
            </>
        )
    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <div>
                        {this.renderApproveButton()}
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "SEND_TO_CS", "Return")}
                        >Return to CS
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}
                            disabled={this.state.disabled}
                            type='button' value='add more'
                            onClick={(event) => this.handleSubmit(event, "RETURN", "Return")}
                        >Return to Maker
                        </button>
                    </div>
                </Grid>
            )
        }
    };

    renderImageCrop = () => {
        this.setState({
            loading: false,
            getCropedImage: true,
            signatureButtonShowInImageCropedPage: true
        })
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{
                        "border-top-style": "ridge",
                        "border-right-style": "ridge",
                        "border-bottom-style": "ridge",
                        "border-left-style": "ridge"
                    }}>

                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Due Date", "Created By", "Application Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                    </div>
                </div>
            )
        }
    };


    renderForm = () => {
        if (this.state.getData && this.state.inputData.freeFlag2 !== "linkedCB" && this.state.inputData.nomineeChange === true) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, CHECKERJsonFormForCasaIndividual, this.updateComponent)}
                    {this.renderNomineeUpdateForm()}
                    {CommonJsonFormComponent.renderJsonForm(this.state, CHECKERJsonFormForCasaIndividualLastForRashed, this.updateComponent)}
                </>
            )
        } else if (this.state.getData && this.state.inputData.freeFlag2 === "linkedCB") {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonForOnlyLinkedCBChecker, this.updateComponent)}
                </>
            )
        }
    };

    renderLabel = () => {
        if (this.state.inputData.accountNumber) {
            return (
                <React.Fragment>
                    <Label linkedCB={this.state.linkedCB} stopLoading={this.stopLoading} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber}
                           accountNumber={this.state.inputData.accountNumber}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <Grid item xs={12}></Grid>
                    {this.renderSignature()}
                </React.Fragment>
            )
        } else {
            this.renderSignature()
        }
    };

    renderSignature = () => {
        // if (this.state.getData && this.state.showSignatureButton) {
        //     return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
        //                             classes={this.props}/>
        // }
        if (this.state.getData && this.state.showSignatureButton) {
            return <a className="btn btn-sm btn-danger"
                      style={{"color": "#ffffff", "margin-top": "10px", "margin-bottom": "10px"}}
                      onClick={() => this.openSignature(this.state.inputData.accountNumber)}>Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    {this.renderLabel()}

                    {this.renderForm()}
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)
                    }
                </React.Fragment>
            )
        }
    };

    renderNomineeUpdateForm = () => {
        return (
            <>
                {this.renderExistingNomineeForm()}
                <Grid item xs={12}></Grid>
                <Grid item xs={6}>
                    {this.renderNomineeNumber()}
                </Grid>
                {this.renderNomineeForm()}
            </>
        )
    };


    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)
            )
        }
    };

    renderNomineeForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent)
            )

        }
    };

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/Attachments-for-title-change/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    renderBulkFileForTitleChange = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.titleChange) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <a className="btn btn-outline-primary btn-sm"
                                   style={{
                                       verticalAlign: 'middle',
                                       marginTop: '12px',
                                       marginRight: '10px',
                                   }}
                                   target="_blank"
                                   href={backEndServerURL + "/file/" + document + "/" + sessionStorage.getItem("accessToken")}>Document {index + 1}</a>
                            </Grid>
                        </React.Fragment>
                    )
                })
            );
        }
    };

    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common" style={{"font-size": "15px", "font-weight": "bold"}}>Number
                            Of New Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            InputProps={{
                                readOnly: true
                            }}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    viewCheckList = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false,
            mandateModal: false,
        })
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Mandate:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Action"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createMandateTableData = (sl, cb, name, relationship, action) => {
        return ([
            sl, cb, name, relationship, action
        ])
    };

    triggerExistingMandate = (cbNumber) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.triggerExistingMandateDialog(cbNumber)}
            >
                View
            </button>
        )
    };

    triggerExistingMandateDialog = (cbNumber) => {
        this.setState({
            mandateModal: true,
            existingMandateCb: cbNumber
        });
    };

    getTExistingMandateData = () => {
        let tableArrayData = [];
        let tableArray = [];
        let cbTagUrl = backEndServerURL + "/getExistingMandateInfo/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    }
                    tableArrayData.push(data);
                });
                this.setState({
                    getMandateList: tableArrayData,
                    needCheckForExistingMandate: tableArray
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    handleDeleteSignature = () => {
        this.setState(prev => ({
            disabled: false,
            loading: false,
            signatureDeleteButton: !prev.signatureDeleteButton,
            showDeleteSignature: !prev.showDeleteSignature
        }))
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.getCropedImage}>
                            <DialogContent className={classes.dialogPaper}>
                                <AssignedCropImage subServiceType={this.props.subServiceType}
                                                   handleSignatureCard={this.signatureCardApi}
                                                   signatureButtonShowInImageCropedPage={this.state.signatureButtonShowInImageCropedPage}
                                                   appId={this.props.appId}
                                                   closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.signatureDeleteButton}>
                            <DialogContent className={classes.dialogPaper}>
                                <SignatureCardDelete
                                    editMode={false}
                                    deleteCheckedSignature={this.functionForSignatureDelete}
                                    showDeleteSignature={this.state.showDeleteSignature}
                                    accountNumber={this.state.inputData.accountNumber}
                                    inputData={this.state.inputData}
                                    handleDeleteSignature={this.handleDeleteSignature}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            open={this.state.checkList}>
                            <DialogContent>
                                <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.mandateModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <ExistingMandateDetails readOnly={true} closeModal={this.checkListClose}
                                                        appId={this.props.appId}
                                                        getTExistingMandateData={this.getTExistingMandateData}
                                                        cbNumber={this.state.existingMandateCb}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmation}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlertTwo}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmationTwo}
                                    alertButtonArray={this.state.alertButtonArray}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                    </ThemeProvider>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={0}>
                            {this.renderJsonFormFirst()}
                        </Grid>
                        {this.renderMandateData()}
                        {this.renderBulkFileForTitleChange()}
                        <Grid item xs={12}>
                            <br/>
                            {this.renderFlowSummeryButton()}
                        </Grid>
                        {this.viewCheckList()}
                        {this.renderDefferalData()}
                        <br/>
                        {this.renderRemarksData()}
                        {this.renderRemarks()}
                        {this.renderSubmitButton()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(VerifyCheckerAccountMaintenance);
