import React, {Component} from "react";
import axios from "axios";
import {processmakerServerURL} from '../Common/Constant';
import {CSVLink} from "react-csv";

class WelcomeLetterGenerate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            errorMessage: " ",
            datas: []

        }
    }

    handlestartDate = event => {
        this.setState({
            startDate: event.target.value
        })
    };
    handleendDate = event => {
        this.setState({
            endDate: event.target.value
        })
    };

    handlesubmit = event => {
        event.preventDefault();

        axios.get(processmakerServerURL + '/api/1.0/workflow/extrarest/cases/welcome/letter/' + this.state.startDate + '/' + this.state.endDate, {})
            .then(response => {

                let responseData = [];
                if (Array.isArray(response.data))
                    responseData = response.data;
                this.setState({

                    datas: responseData

                })


            })
            .catch(error => {
                this.setState({errorMessage: "Not Found"});

            })


    };

    render() {
        console.log(Array.isArray(this.state.datas));
        const {startDate, endDate, datas} = this.state;
        const name = datas.map(function (data, id) {
            return <p key={id}>{data.customer_name}</p>;

        });


        const phone = datas.map(function (data, id) {
            return <p key={id}>{data.customer_phone}</p>;
        });


        return (

            <div>
                <center>
                    <h1> Welcome letter generate </h1>
                    <form onSubmit={this.handlesubmit}>
                        <div>
                            StartDate : <input type="date" onChange={this.handlestartDate} value={startDate}/>
                        </div>
                        <div>
                            <br/>EndDate : <input type="date" onChange={this.handleendDate} value={endDate}/>
                        </div>

                        <div>
                            <br/>
                            <button type="submit">submit</button>
                        </div>

                        <div>
                            <br/> <CSVLink data={datas}>Download All Generate file</CSVLink>
                        </div>


                    </form>
                    ,

                    <table className="table table-bordered table-definition mb-10">
                        <thead className="table-warning ">

                        <tr>


                            <th>Customer Name</th>
                            <th>Customer phone</th>


                        </tr>
                        </thead>
                        <tbody>

                        <tr>
                            <td>

                                {name}

                            </td>
                            <td>
                                {phone}

                            </td>


                        </tr>


                        </tbody>

                    </table>

                </center>

            </div>
        )
    }
}

export default WelcomeLetterGenerate;