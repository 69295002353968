import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import FileMapping from "../../workflow/CommonComponent/FileMapping";
import FileMappingEdit from "../../workflow/CommonComponent/FileMappingEdit";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import GridItem from "../../Grid/GridItem";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";

let populateForm = [
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        grid: 2
    },
    {
        varName: "accountStatus",
        type: "select",
        label: "Account Status",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Active"]
    },


    {
        varName: "productType",
        type: "select",
        label: "Product Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["VISA",
            "MASTER",
            "CITYMAAX",
        ]
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card",
        required: true,
        grid: 2,
        errorMessage: "Error"
    },

    {
        varName: "deliveryType",
        type: "select",
        label: "Delivery Type",
        required: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["Branch", "Courier"]
    },

    {
        varName: "branchName",
        type: "select",
        label: "Branch Name",
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Branch",
        grid: 2,
        errorMessage: "Error",
        enum: ["Gulshan", "Banani", "Dhanmondi"]
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        required: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
        errorMessage: "Error"
    },
    {
        varName: "customerMobile",
        type: "text",
        label: "Mobile",
        required: true,
        grid: 2,
        errorMessage: "Error"
    },

    {
        varName: "customerEmail",
        type: "text",
        label: "Email",
        required: true,
        grid: 2,
        errorMessage: "Error"
    },
    {
        varName: "fileUpload",
        type: "file",
        label: "Upload",
        required: true,
        grid: 2,
        errorMessage: "Error"
    },
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

const documentType = [

    {label: 'RTGS FORM'},
    {label: 'BEFTN FORM'},

];


class DebitCardCheckerSplit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            showValue: true,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: false,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            bulkUpload: false,
            signature: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);


    }

    signatureOpen = () => {
        this.setState({
            signature: true
        })
    };

    signatureClose = () => {
        this.setState({
            signature: false
        })
    };

    modalClose = () => {
        this.props.closeModal();
    };

    componentDidMount() {

        console.log(this.props.appId);

        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    varValue: response.data,
                    inputData: response.data,
                    getData: true
                })


            })
            .catch((error) => {
                console.log(error)
            })

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderFormWithData = () => {
        if (this.state.getData) {
            return (
                <Grid container>
                    {

                    }

                </Grid>
            )
        }
    };


    approveHandler = () => {


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    customerInformation = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, populateForm, this.updateComponent)
                    }


                </ThemeProvider>
            )
        }
    };


    handleSubmit = (event) => {
        event.preventDefault();
        this.forceUpdate();

        if (this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }


        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

        let postData = this.state.inputData;
        postData.serviceType = "FundTransfer";
        postData.category = "Fund Transfer";
        postData.subCategory = this.state.inputData.customerType;
        postData.subServiceType = "NewFundTransfer";
        postData.cs_deferal = "NO";

        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("variable set successfully");
                console.log(response.data);
                var url = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        console.log("case route successful !");

                        this.setState({

                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true,
                        });
                        this.props.closeModal()

                    })
                    .catch((error) => {
                        console.log(error);
                        console.log("error from case route")

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    documentModal = () => {
        this.setState({
            pastDocumentModal: false
        })
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderPastUploadDocuments = () => {
        this.setState({
            pastDocumentModal: true
        })
    };

    documentModal = () => {
        this.setState({
            pastDocumentModal: false
        })
    };

    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };
    uploadModal = () => {
        this.setState({
            uploadModal: true,
            preview: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({

            getMappingAllImage: false,

        })
    };

    renderAssignedImage = () => {

        if (this.state.preview) {
            return (
                <button
                    style={{
                        width: 120,
                        marginTop: '12px',
                        marginLeft: '10px'
                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }

    };
    renderSubmitButton = () => {

        return (

            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                    marginTop: "10px"
                }}
                onClick={this.handleSubmit}

            >
                Submit
            </button>

        )

    };

    renderMappingImageModal = () => {

        return (
            <FileMapping dropDownOption={documentType}
                         serviceType=""
                         appId={this.props.appId}
                         closeModal={this.closeUploadModal}/>
        )

    };
    renderMappingImageEditModal = () => {

        return (
            <FileMappingEdit dropDownOption={documentType}
                             serviceType=""
                             appId={this.props.appId}
                             closeModal={this.closeUploadModal}/>
        )

    };


    render() {

        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <GridItem xs={12} sm={12} md={12}>


                    <Grid container>
                        {
                            this.customerInformation()
                        }
                    </Grid>

                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'middle',
                            marginTop: '12px',
                        }}
                        type="submit"
                        onClick={this.signatureOpen}


                    >
                        Signature Card

                    </button>

                    <Grid item xs={12}>

                    </Grid>


                    {
                        this.customerDocumentType()
                    }

                    {
                        this.renderAssignedImage()
                    }


                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.uploadModal}>
                        <DialogContent>
                            {this.renderMappingImageModal()}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            {this.renderMappingImageEditModal()}
                        </DialogContent>
                    </Dialog>


                    <Grid item xs={12}>

                    </Grid>


                    {
                        this.renderSubmitButton()
                    }


                    {
                        this.renderNotification()
                    }

                </GridItem>
            </GridList>

        )

    }


}

export default withStyles(styles)(DebitCardCheckerSplit);
