import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import loader from '../../../Static/loader.gif';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import AutoCompleteComplete from "../../JsonForm/AutoCompleteComponent";
import ErrorModal from "./ErrorModal";
import MyValidation from "../../JsonForm/MyValidation";
import SelectComponent from "../../JsonForm/SelectComponent";

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".pdf",
    "label": "Scan and Upload File",
    "grid": 12
};

var rotateOption = {
    "type": "select",
    "label": "Rotate",
    "enum": [
        "90",
        "180",
        "-90",
        "-180",
    ],

};


class FileMapping extends Component {
    state = {
        fileUploadData: {},
        getSplitFile: [],
        loader: null,
        inputData: {},
        dropdownSearchData: {},
        autoComplete: {},
        showValue: true,
        varValue: {},
        getData: false,
        errorMessages: {},
        errorArray: {},
        errorModalBoolean: false


    };

    constructor(props) {
        super(props);

    }


    updateComponent = () => {
        this.forceUpdate();
    };

    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    };
    close = () => {

        this.props.closeModal();
    };
    handleSubmit = (event) => {

        event.preventDefault();
        let error = MyValidation.fileValidation(fileUpload, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loader: true
            });
            if (this.state.loader === null) {

                const formData = new FormData();
                formData.append("appId", this.props.appId);
                formData.append("file", this.state.fileUploadData[fileUpload.varName]);
                axios({
                    method: 'post',
                    url: this.props.eadviceFlag===true ? backEndServerURL + '/case/splitbond':backEndServerURL + '/case/split',
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        console.log(response.data);
                        if (this.props.condition === true) {
                            this.renderDropdownConditionData(response.data);

                        } else {
                            this.renderDropdownData(response.data);
                        }


                        this.setState({
                            getSplitFile: response.data,
                            loader: false,
                            getData: true
                        })

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({

                            loader: false

                        })
                    })
            } else {
                let mapData = {};
                let rotateData = {};
                for (let item in this.state.inputData) {
                    if (item.indexOf("ROTATE_") !== -1) {
                        rotateData[item] = this.state.inputData[item]
                    } else {
                        mapData[item] = this.state.inputData[item]
                    }
                }
                let url = backEndServerURL + "/case/files/rotate";
                axios.post(url, rotateData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let url = backEndServerURL + "/case/mapFiles";
                        axios.post(url, {
                            appId: this.props.appId,
                            fileNames: mapData
                        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (this.props.viewFileName !== undefined) {
                                    this.props.viewFileName(this.state.fileUploadData.scanningFile);
                                }
                                if (this.props.submitModal !== undefined) {
                                    this.props.submitModal("SUBMIT");
                                } else {
                                    this.props.closeModal()
                                }
                                this.setState({
                                    loader: false
                                })

                            })
                            .catch((error) => {
                                let duplicateUrl = backEndServerURL + "/caseFile/checkDuplicacy";
                                axios.post(duplicateUrl, {
                                    appId: this.props.appId,
                                    fileNames: mapData
                                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response.data);
                                        let errorArray = {};
                                        let errorMessages = {};
                                        response.data.map((fileName) => {
                                            errorArray[fileName] = true;
                                            errorMessages[fileName] = "Duplicate file name";
                                        });

                                        this.setState({
                                            errorArray: errorArray,
                                            errorMessages: errorMessages,
                                            loader: false,
                                            errorModalBoolean: true
                                        })

                                    })
                                    .catch((error) => {
                                        this.setState({
                                            loader: false
                                        })
                                    })
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loader: false
                        })
                    })
            }
        }

    };
    renderDropdownData = (data) => {
        data.map((name, index) => {
            if (this.props.dropDownOption.length > index) {
                //this.state.autoComplete[name] =arrayListIndividual;
                this.state.autoComplete[name] = this.props.dropDownOption;
                if (this.props.autoMapping) {
                    this.state.varValue[name] = this.props.dropDownOption[index];
                    this.state.inputData[name] = this.props.dropDownOption[index].label;
                }


            } else {
                this.state.autoComplete[name] = this.props.dropDownOption;

            }
        })


    };
    renderDropdownConditionData = (data) => {
        data.map((name, index) => {
            if (this.props.dropDownConditionOption.length > index) {
                //this.state.autoComplete[name] =arrayListIndividual;
                this.state.autoComplete[name] = this.props.dropDownOption;
                if (this.props.autoMapping) {
                    this.state.varValue[name] = this.props.dropDownConditionOption[index];
                    this.state.inputData[name] = this.props.dropDownConditionOption[index].label;
                }


            } else {
                this.state.autoComplete[name] = this.props.dropDownOption;

            }
        })


    };


    renderCommonIndividual = (data, index) => {

        if (this.state.getData) {

            let field = JSON.parse(JSON.stringify(rotateOption));
            field.varName = "ROTATE_" + data;

            console.log(field.varName);

            return (
                <Grid item xs={4}>
                    {this.props.fileName !== false &&
                    <div>
                        <br/>
                        {AutoCompleteComplete.renderSelect(this.state, this.updateComponent, {
                            "label": "Your Photo Name",
                            "varName": data
                        })}
                        {SelectComponent.select(this.state, this.updateComponent, field)}
                        <br/>
                    </div>
                    }
                    <img width='90%'
                         src={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")+"?"+Math.floor(Math.random() * 100000000000)} alt=""/>

                    <br/>
                </Grid>


            )
        }


    };
    renderloader = () => {

        /*(this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C")*/
        if (this.state.loader === false) {
            return (
                <Grid container spacing={1}>
                    {
                        this.state.getSplitFile.map((data, index) => {
                            //console.log(index)
                            return (

                                this.renderCommonIndividual(data, index)


                            )

                        })

                    }
                </Grid>
            )
        }

    };
    renderFile = () => {
        if (this.state.getData) {
            return (
                this.renderloader()
            )

        } else {
            return (
                <div>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                    {this.renderloader()}
                </div>
            )
        }
    };

    render() {
        const {classes} = this.props;

        return (
            <GridContainer>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.errorModalBoolean}>
                    <DialogContent className={classes.dialogPaper}>
                        <ErrorModal title="Duplicate Name" closeModal={this.closeModal}
                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                        />

                    </DialogContent>
                </Dialog>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                            <h4 style={{color: "white"}} >Scanning File<a><CloseIcon onClick={this.close}
                                                           style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <br/>

                            <ThemeProvider theme={theme}>
                                <div>

                                    <Grid container spacing={3}>
                                        {this.renderFile()}
                                    </Grid>
                                </div>


                                <div>

                                    <br/>
                                    <br/>
                                    <center>
                                        {this.state.loader !== true && <button
                                            className="btn btn-outline-primary"
                                            style={{
                                                verticalAlign: 'right',

                                            }}

                                            type='button' value='add more'
                                            onClick={this.handleSubmit}
                                        >Submit
                                        </button>}
                                    </center>

                                </div>
                            </ThemeProvider>


                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(FileMapping);