import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../Common/Constant";
import axios from "axios";
import Functions from '../../Common/Functions';
import theme from "../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Notification from "../NotificationMessage/Notification";
import loading from "../../Static/loader.gif";
import CBNotFound from "./CASA/CBNotFound";
import TableComponent from "./CommonComponent/TableComponent";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import {Menu} from "semantic-ui-react";
import {commonInboxButton, commonInboxTabActive, commonInboxTabInactive} from "../DeliverableManagement/configuration";
import Badge from "@material-ui/core/Badge";
import {
    remittanceGroupInbox,
    rows,
    rowsBranch,
    rowsCS,
    rowsCSInbox,
    rowsCSWithReturned,
    rowsWithGroupInbox,
    rowsWithReturned
} from "./ColumnNameForInbox"
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ClosingCheckerFormLoanClosing from "./AssetOps/LoanClosing/ClosingCheckerFormLoanClosing";
import NocMakerLoanClosing from "./AssetOps/LoanClosing/NocMakerLoanClosing";
import DocumentMakerLoanClosing from "./AssetOps/LoanClosing/DocumentMakerLoanClosing";
import InterestTransferChecker from "./AssetOps/OtherServiceRequest/CheckerApproval";
import ClosingCheckerSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/ClosingCheckerSecuredLoanClosing";
import ClosingMakerLienMarking from "./AssetOps/LienMarking/ClosingMakerLienMarking";
import MakerRecoveryAmount from "./AssetOps/RecoveryAmount/MakerRecoveryAmount";
import ClosingCheckerDeceasedMark from "./AssetOps/DeceasedMark/ClosingCheckerDeceasedMark";
import ClosingCheckerWaverRequest from "./AssetOps/WaiverRequest/CommonApprovalWaiverRequest";
import ClosingMakerWaverRequest from "./AssetOps/WaiverRequest/CommonApprovalWaiverRequest";
import NocMakerWaverRequest from "./AssetOps/WaiverRequest/CommonApprovalWaiverRequest";
import NocCheckerWaverRequest from "./AssetOps/WaiverRequest/CommonApprovalWaiverRequest";
import InterestTransferMaker from "./AssetOps/OtherServiceRequest/MakerApproval";
import ClosingMakerSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/ClosingMakerSecuredLoanClosing";
import ClosingMakerDeceasedMark from "./AssetOps/DeceasedMark/ClosingMakerDeceasedMark";
import NocMakerSecuredLoanClosing from "./AssetOps/SecuredLoanClosing/NocMakerSecuredLoanClosing";
import HomeLoanDocWaverRequest from "./AssetOps/WaiverRequest/HomeLoanDocWaiverRequest";
import MakerInbox from "./AssetOperation/RetailLoanArchiving/DocumentationInbox";
import DisbursementMakerApproval from "./AssetOps/PartialPayment/CommonApprovalPartialPayment";
import MakerPayOrderStart from "./AssetOps/PayOrder/MakerPayOrderStart";
import MakerApproval from "./AssetOps/EmiDataChange/CommonApprovalEmiDateChange";
import CheckerApproval from "./AssetOps/EmiDataChange/CommonApprovalEmiDateChange";
import LoanAccountTenorMaker from "./AssetOps/LoanAccountTenorChange/MakerApproval";
import DocumentationCheckerInbox from "./AssetOperation/RetailLoanArchiving/DocumentationCheckerInbox";
import LoanAccountTenorChecker from "./AssetOps/LoanAccountTenorChange/CheckerApproval";

const filterField = [
    {
        "varName": "caseId",
        "type": "textDedup",
        "label": "AWF Case Number",
        "grid": 2,
    },
    {
        "varName": "referenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 2,
    },
    {
        "varName": "category",
        "type": "select",
        "enum": [
            "Loan Closing", "Secured Loan Closing" , "Outstanding Certificate", "Partial Payment", "EMI", "Loan Account","Other Service Request",
            "Lien Marking","Waiver Request","Deceased Mark","Recovery Amount","Pay Order"
        ],
        "label": "Category",
        "grid": 2,
    },
    {
        "varName": "subCategory",
        "type": "select",
        "enum": [
            "Loan Closing", "Secured Loan Closing", "Outstanding Certificate",  "Partial Payment", "Date Change", "Tenor Change",
            "Interest transfer request","Partial Security release","Security replacement","Duplicate Sanctioned Letter","Duplicate NOC","Interest transfer request","Link account Changes","EFTN Account Change",
            "Lien Marking", "Waiver Request", "Deceased Mark", "Recovery Amount", "Pay Order"
        ],
        "label": "Sub Category",
        "grid": 2,
    },
    {
        "varName": "branch_id",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Branch",
        "grid": 2,
    },
    {
        "varName": "cbNumber",
        "type": "textDedup",
        "label": "CB Number",
        "grid": 2,
    },
    {
        "varName": "accountNumber",
        "type": "textDedup",
        "label": "Account No.",
        "grid": 2,
    },
    {
        "varName": "customerName",
        "type": "textDedup",
        "label": "Customer name",
        "grid": 2,
    },
    {
        "varName": "fromDate",
        "type": "date",
        "label": "From Date",
        "grid": 2,
    },
    {
        "varName": "toDate",
        "type": "date",
        "label": "To Date",
        "grid": 2,
    },
    {
        "varName": "urgency",
        "type": "select",
        "enum": [
            "High",
            "Medium",
            "Low",
        ],
        "label": "Urgency",
        "grid": 2,
    },
    {
        "varName": "cutOffTime",
        "type": "select",
        "enum": [
            "Before",
            "After",
        ],
        "label": "Cut Off Time",
        "grid": 2,
    },
    {
        "varName": "releatedUser",
        "type": "text",
        "label": "User Id",
        "grid": 2,
    },
];


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class AssetUserInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: true,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            searchData: false,
            activeItem: "Group Inbox",
            showTable: false,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            totalRow: 0,
            rowsPerPage: 50,
            tableColumns: [],
            page: 0,
            showInbox: false,
            serviceType: "",
            subServiceType: "",
            category: "",
            subCategory: "",
            solId: "",
            appUid: "",
            delStatus: "",
            taskTitle: "",
            status: "",
            freeFlag1: "",
            freeFlag2: "",
            initBranchName: "",
            freeFlag3: "",
            freeFlag4: "",
            recpmtid: "",
            cb_number: "",
            account_number: "",
            customer_name: "",
            CaseId: "",
            urgency: "",
            sentByUsername: "",
            jointAccountCustomerNumber: "",

        }
    }


    renderTableColumn = () => {
        let tableColumn = [];
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let workflowPlace = LocalstorageEncrypt.encryptStorageFunction.getItem("workplace");
        let workflowPlaceUnit = LocalstorageEncrypt.encryptStorageFunction.getItem("workplaceUnits");
        if (this.state.activeItem === "Return" && (workflowGroup === "CS" || workflowGroup === "DSTCS")) {
            tableColumn = [...rowsCSWithReturned]
        } else if (workflowGroup === "CS" || workflowGroup === "DSTCS") {
            tableColumn = [...rowsCS]
        } else if (this.state.activeItem === "Inbox") {
            if (workflowPlaceUnit === "Remetance" || workflowPlaceUnit === "Remittence SD Dhaka") {
                tableColumn = [...remittanceGroupInbox]
            } else {
                tableColumn = [...rowsCSInbox]
            }
        } else if (workflowGroup !== "CS" && workflowGroup !== "DSTCS" && workflowPlace === "BRANCH") {
            tableColumn = [...rowsBranch]
        } else if (this.state.activeItem === "Return") {
            tableColumn = [...rowsWithReturned]
        } else if (this.state.activeItem === "Group Inbox") {
            tableColumn = [...rowsWithGroupInbox]
        } else {
            tableColumn = [...rows]
        }
        if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
            tableColumn.push({field: 'makerId', title: 'Maker ID'},)
        }
        return tableColumn;
    };


    getApiUrl = () => {
        let url = "";
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "CS") {
            url = assetOpsBackEndServerURL + '/workspaceClaimable/0';
        } else if (workFlowGroup === "BM" || workFlowGroup === "BOM") {
            url = assetOpsBackEndServerURL + '/serviceWise/claimable/withoutRectified/' + workFlowGroup + "/NOT_REMITTANCE_BRANCH/" + 0;
            this.setState({
                activeItem: 'Group Inbox',
            })
        } else if (workFlowGroup === "CS") {
            url = assetOpsBackEndServerURL + '/csGroupWiseReturnRequest/0';
            this.setState({
                activeItem: 'Return',
            })
        } else {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf("GROUP LOCK") !== -1) {
                url = assetOpsBackEndServerURL + '/claimable/withoutRectified/0';
            }
        }
        return url;
    };


    componentDidMount() {
        let branch = [];
        let branchUrl = assetOpsBackEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.name})
                });
                for (let form of filterField) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch_id") {
                        form.enum = branch
                    }
                }
                this.fetchTableData(this.getApiUrl(), 0)

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    getData: true,
                    showTable: true,
                    loading: false,
                });
            })
    }


    fetchTableData = (url, page) => {
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            console.log(response.data)
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: page > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * page)) : index + 1,
                    CaseId: message.caseId,
                    currency: message.freeFlag1,
                    amount: message.freeFlag2,
                    reference: message.freeFlag3,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory, message.serviceType),
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    initUser: message.initiatedBy,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >All Inbox</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchData) {
            this.searchHandler("", pageNumber)
        } else {
            this.functionForGetTabWiseUrl(this.state.activeItem, pageNumber);
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'null' || clone[prop] === null || clone[prop] === 'undefined')
                delete clone[prop];
        return clone;
    };


    returnService = (priorityDesc, freeFlag1, freeFlag6) => {
        return priorityDesc;
    }

    returnCategory = (branchingCategory, freeFlag5, category) => {
        if (branchingCategory !== undefined && branchingCategory !== null && branchingCategory !== "") {
            return branchingCategory
        } else if (freeFlag5 !== undefined && freeFlag5 !== null && freeFlag5 !== "") {
            return freeFlag5
        } else {
            return category
        }
    };

    returnSubCategory = (branchingSubCategory, freeFlag6, subCategory, serviceType) => {
        console.log(serviceType);
        if (branchingSubCategory !== undefined && branchingSubCategory !== null && branchingSubCategory !== "") {
            return branchingSubCategory
        } else if (freeFlag6 !== undefined && freeFlag6 !== null && freeFlag6 !== "") {
            return freeFlag6
        } else {
            return subCategory
        }
    };


    manageStatusForSearch = () => {
        switch (this.state.activeItem) {
            case "Group Inbox":
                return "Open";
            case "Inbox":
                return "Locked";
            case "Return":
                return "Return";
            default:
                return this.state.activeItem;
        }
    };

    searchHandler = (event, pageNumber) => {
        this.setState({
            page: 0,
            totalRow: 0,
            tableData: [],
            loading: true,
            showTable: false,
        });
        let pageN = pageNumber ? pageNumber : 0;
        let postData = this.removeNullValue(this.state.inputData);
        if (this.state.activeItem === "Group Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf("INTERNET_BANKING") !== -1) {
            postData.tabName = "INTERNET_BANKING"
        } else if (this.manageStatusForSearch() === "Open" || this.manageStatusForSearch() === "Return") {
            postData.tabName = "Group Inbox"
        }

        let url = assetOpsBackEndServerURL + "/filterUserInbox/" + this.manageStatusForSearch() + "/" + pageN;
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then(response => {
            let tableData = [];
            response.data.content.map((message, index) => {
                tableData.push({
                    SL: pageNumber > 0 ? ((index + 1) + (Number(this.state.rowsPerPage) * pageNumber)) : index + 1,
                    CaseId: message.caseId,
                    customer_name: message.customerName,
                    cb_number: message.cbNumber,
                    currency: message.freeFlag1,
                    amount: message.freeFlag2,
                    reference: message.freeFlag3,
                    account_number: message.accountNumber,
                    businessSegment: message.businessSegment,
                    category_type: this.returnCategory(message.branchingCategory, message.freeFlag5, message.category),
                    sub_category_type: this.returnSubCategory(message.branchingSubCategory, message.freeFlag6, message.subCategory, message.serviceType),
                    Service: this.returnService(message.priorityDesc, message.freeFlag1, message.freeFlag6),
                    Date: message.delInitTime.replace("T", " "),
                    currentUserName: message.currentUserName,
                    branch_name: message.branchName,
                    Status: message.delStatus,
                    freeFlag1: message.freeFlag1,
                    freeFlag2: message.freeFlag2,
                    freeFlag3: message.freeFlag3,
                    freeFlag4: message.freeFlag4,
                    recpmtid: message.recpmtid,
                    freeFlag5: message.freeFlag5,
                    freeFlag6: message.freeFlag6,
                    appId: message.appId,
                    serviceType: message.serviceType,
                    subServiceType: message.subServiceType,
                    taskTitle: message.taskTitle,
                    solId: message.solId,
                    urgency: message.urgency,
                    returnedBack: message.returnBackTo ? message.returnBackTo : "",
                    returnedBy: message.sentByUsername,
                    jointAccountCustomerNumber: message.jointAccountCustomerNumber,
                    requestStatus: message.status,
                    makerId: message.lastMakerUser,
                    initUser: message.initiatedBy,
                });
            });
            this.setState({
                page: response.data.number,
                totalRow: response.data.totalElements,
                tableData: tableData,
                getData: true,
                showTable: true,
                searchData: true,
                loading: false
            })

        }).catch(error => {
            console.log(error);
            this.setState({
                loading: false,
                showTable: true,
                searchData: false,
            })
        });
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchHandler(event, 0);
        }
    };


    renderFilterField = () => {
        return (
            <Grid container>
                {CommonJsonFormComponent.renderJsonForm(this.state, filterField, this.updateComponent, this.onKeyDownForDedup)}
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={(e) => this.searchHandler(e, 0)}
                    type="submit"
                >
                    Search
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={() => window.location.reload()}
                    type="submit"
                >
                    Reset
                </button>
            </Grid>
        );
    };


    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            searchData: false,
            activeItem: value,
            loading: true,
            getData: false,
            showTable: false,
            page: 0,
            tableData: [],
        }, this.functionForGetTabWiseUrl(value, 0));
    };

    functionForGetTabWiseUrl = (value, page) => {
        this.forceUpdate();
        let urls = "";
        let workflowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (value === "Inbox") {
            urls = assetOpsBackEndServerURL + '/inbox/waiting/' + page;
        } else if (value === "Return") {
            // urls = assetOpsBackEndServerURL + '/inbox/return/' + page;
            if (workflowGroup === "MAKER" || workflowGroup === "CHECKER") {
                urls = assetOpsBackEndServerURL + '/getGroupWiseReturnRequest/' + page;
            } else {
                urls = assetOpsBackEndServerURL + '/csGroupWiseReturnRequest/' + page;
            }
        } else if (value === "Group Inbox") {
            if (workflowGroup === "MERCHANT" || workflowGroup === "CS" || workflowGroup === "INTERNET_BANKING" || workflowGroup === "ADC") {
                urls = assetOpsBackEndServerURL + '/workspaceClaimable/' + page;
            } else if (workflowGroup === "BM" || workflowGroup === "BOM") {
                urls = assetOpsBackEndServerURL + '/serviceWise/claimable/withoutRectified/' + workflowGroup + "/NOT_REMITTANCE_BRANCH/" + page;
            } else {
                urls = assetOpsBackEndServerURL + '/claimable/withoutRectified/' + page;
            }

        }
        this.fetchTableData(urls, page)
    };


    renderMenu = () => {
        return (
            <div style={{marginRight: "8px"}}>
                <Menu pointing style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    {this.renderMenuItem("GROUP LOCK", "Group Inbox", this.state.getCountGroupInbox, "Group Inbox")}

                    {this.renderMenuItem("INBOX", "Inbox", this.state.getCountInbox, "Self-Inbox")}

                    {this.renderMenuItem("RETURN", "Return", this.state.getCountReturn, "Returned Requests")}

                </Menu>
            </div>
        );
    };

    renderMenuItem = (permission, activeItem) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("menus").indexOf(permission) !== -1) {
            return (
                <Menu.Item
                    name={activeItem}
                    active={this.state.activeItem === activeItem}
                    style={this.state.activeItem === activeItem ? commonInboxTabActive : commonInboxTabInactive}
                    onClick={e => {
                        this.headerSelect(e, activeItem);
                    }}
                >
                </Menu.Item>
            )
        }

    };

    renderBadge = (value, name) => {
        if (value !== undefined && value > 0) {
            return (
                <Badge badgeContent={value} color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else if (value === 0) {
            return (
                <Badge badgeContent={0} color="primary" showZero>
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        } else {
            return (
                <Badge color="primary">
                    <div style={{marginTop: "8px"}}>
                        {name}
                    </div>
                </Badge>
            )
        }
    };

    renderInboxCase = () => {

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CLOSING_CHECKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <ClosingCheckerSecuredLoanClosing delStatus={this.state.delStatus}
                                                      closeModal={this.closeModal}
                                                      closeIcon={this.closeIcon}
                                                      appId={this.state.appUid}
                                                      subCategory={this.state.subCategory}
                                                      category={this.state.category}
                                                      serviceType={this.state.serviceType}
                                                      freeFlag1={this.state.freeFlag1}
                                                      taskTitle={this.state.taskTitle}
                                                      subServiceType={this.state.subServiceType}
                                                      solId={this.state.solId}
                                                      caseId={this.state.CaseId}/>)
            } else if (this.state.category === "Lien Marking") {
                return (
                    <ClosingMakerLienMarking closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType} solId={this.state.solId}
                                             caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Recovery Amount") {
                return (
                    <MakerRecoveryAmount closeModal={this.closeModal}
                                         closeIcon={this.closeIcon}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category}
                                         serviceType={this.state.serviceType}
                                         subServiceType={this.state.subServiceType} solId={this.state.solId}
                                         caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Deceased Mark") {
                return (
                    <ClosingCheckerDeceasedMark delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                closeIcon={this.closeIcon}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category}
                                                serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subServiceType}
                                                solId={this.state.solId}
                                                caseId={this.state.CaseId}/>)

            } else if (this.state.category === "Waiver Request") {
                return (
                    <ClosingCheckerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                closeIcon={this.closeIcon}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category}
                                                serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subServiceType}
                                                solId={this.state.solId}
                                                caseId={this.state.CaseId}/>)

            } else if (this.state.category === "Loan Closing") {
                return (
                    <ClosingCheckerFormLoanClosing delStatus={this.state.delStatus}
                                                   closeModal={this.closeModal}
                                                   closeIcon={this.closeModal}
                                                   appId={this.state.appUid} caseId={this.state.CaseId}
                                                   subCategory={this.state.subCategory} category={this.state.category}
                                                   serviceType={this.state.serviceType} freeFlag1={this.state.freeFlag1}
                                                   freeFlag2={this.state.freeFlag2}
                                                   freeFlag3={this.state.freeFlag3} freeFlag4={this.state.freeFlag4}
                                                   recpmtid={this.state.recpmtid}
                                                   accountNumber={this.state.account_number}
                                                   cbNumber={this.state.cb_number}
                                                   customerName={this.state.customer_name}
                                                   solId={this.state.solId}
                                                   initBranchName={this.state.initBranchName}
                                                   subServiceType={this.state.subServiceType}
                                                   taskTitle={this.state.taskTitle}/>
                )
            }

        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('CLOSING_MAKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           closeIcon={this.closeIcon}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}
                                           solId={this.state.solId}
                                           caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <ClosingMakerSecuredLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                    closeIcon={this.closeIcon}
                                                    appId={this.state.appUid}
                                                    subCategory={this.state.subCategory}
                                                    category={this.state.category}
                                                    serviceType={this.state.serviceType}
                                                    freeFlag1={this.state.freeFlag1}
                                                    taskTitle={this.state.taskTitle}
                                                    subServiceType={this.state.subServiceType}
                                                    solId={this.state.solId}
                                                    caseId={this.state.CaseId}/>)
            } else if (this.state.category === "Lien Marking") {
                return (
                    <ClosingMakerLienMarking closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType} solId={this.state.solId}
                                             caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Recovery Amount") {
                return (
                    <MakerRecoveryAmount closeModal={this.closeModal}
                                         closeIcon={this.closeIcon}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category}
                                         serviceType={this.state.serviceType}
                                         subServiceType={this.state.subServiceType} solId={this.state.solId}
                                         caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Deceased Mark") {
                return (
                    <ClosingMakerDeceasedMark delStatus={this.state.delStatus} closeModal={this.closeModal}
                                              closeIcon={this.closeIcon}
                                              appId={this.state.appUid}
                                              subCategory={this.state.subCategory}
                                              category={this.state.category}
                                              serviceType={this.state.serviceType}
                                              freeFlag1={this.state.freeFlag1}
                                              taskTitle={this.state.taskTitle}
                                              subServiceType={this.state.subServiceType}
                                              solId={this.state.solId}
                                              caseId={this.state.CaseId}/>)

            } else if (this.state.category === "Waiver Request") {
                return (
                    <ClosingMakerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                              closeIcon={this.closeIcon}
                                              appId={this.state.appUid}
                                              subCategory={this.state.subCategory}
                                              category={this.state.category}
                                              serviceType={this.state.serviceType}
                                              freeFlag1={this.state.freeFlag1}
                                              taskTitle={this.state.taskTitle}
                                              subServiceType={this.state.subServiceType}
                                              solId={this.state.solId}
                                              caseId={this.state.CaseId}/>)
            }
            else if (this.state.category === "EMI") {
                return (
                    <CheckerApproval delStatus={this.state.delStatus}
                                     closeModal={this.closeModal}
                                     closeIcon={this.closeIcon}
                                     appId={this.state.appUid}
                                     subCategory={this.state.subCategory}
                                     category={this.state.category}
                                     serviceType={this.state.serviceType}
                                     subServiceType={this.state.subServiceType}
                                     solId={this.state.solId}
                                     caseId={this.state.CaseId}
                                     accountNumber={this.props.accountNumber}
                                     cbNumber={this.props.cbNumber}
                                     customerName={this.props.customerName}
                    />
                )
            }
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('NOC_MAKER_AWS') !== -1) {

            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           closeIcon={this.closeIcon}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}
                                           solId={this.state.solId}
                                           caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Loan Closing") {
                return (
                    <NocMakerLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                         closeIcon={this.closeIcon}
                                         appId={this.state.appUid}
                                         subCategory={this.state.subCategory}
                                         category={this.state.category} serviceType={this.state.serviceType}
                                         freeFlag1={this.state.freeFlag1}
                                         taskTitle={this.state.taskTitle}
                                         subServiceType={this.state.subServiceType}
                                         solId={this.state.solId}
                                         caseId={this.state.CaseId}

                    />
                )
            } else if (this.state.category === "Secured Loan Closing") {
                return (
                    <NocMakerSecuredLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                closeIcon={this.closeIcon}
                                                appId={this.state.appUid}
                                                subCategory={this.state.subCategory}
                                                category={this.state.category} serviceType={this.state.serviceType}
                                                freeFlag1={this.state.freeFlag1}
                                                taskTitle={this.state.taskTitle}
                                                subServiceType={this.state.subServiceType}
                                                solId={this.state.solId}
                                                caseId={this.state.CaseId}

                    />
                )
            } else if (this.state.category === "Waiver Request") {
                return (
                    <NocMakerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                          closeIcon={this.closeIcon}
                                          appId={this.state.appUid}
                                          subCategory={this.state.subCategory}
                                          category={this.state.category}
                                          serviceType={this.state.serviceType}
                                          freeFlag1={this.state.freeFlag1}
                                          taskTitle={this.state.taskTitle}
                                          subServiceType={this.state.subServiceType}
                                          solId={this.state.solId}
                                          caseId={this.state.CaseId}/>)
            }
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('NOC_CHECKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Waiver Request") {
                return (
                    <NocCheckerWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                            closeIcon={this.closeIcon}
                                            appId={this.state.appUid}
                                            subCategory={this.state.subCategory}
                                            category={this.state.category} serviceType={this.state.serviceType}
                                            freeFlag1={this.state.freeFlag1}
                                            taskTitle={this.state.taskTitle}
                                            subServiceType={this.state.subServiceType}
                                            solId={this.state.solId}
                                            caseId={this.state.CaseId}

                    />
                )
            }

        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DOCUMENT_MAKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           closeIcon={this.closeIcon}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}
                                           solId={this.state.solId}
                                           caseId={this.state.CaseId}
                    />
                )
            } else if (this.state.category === "Loan Closing") {
                return (
                    <DocumentMakerLoanClosing delStatus={this.state.delStatus} closeModal={this.closeModal}
                                              closeIcon={this.closeIcon}
                                              appId={this.state.appUid}
                                              subCategory={this.state.subCategory}
                                              category={this.state.category} serviceType={this.state.serviceType}
                                              freeFlag1={this.state.freeFlag1}
                                              taskTitle={this.state.taskTitle}
                                              subServiceType={this.state.subServiceType}
                                              solId={this.state.solId}
                                              caseId={this.state.CaseId}
                                              accountNumber={this.props.accountNumber}
                                              cbNumber={this.props.cbNumber}
                                              customerName={this.props.customerName}

                    />
                )
            } else if (this.state.category === "Waiver Request") {
                return (
                    <HomeLoanDocWaverRequest delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category} serviceType={this.state.serviceType}
                                             freeFlag1={this.state.freeFlag1}
                                             taskTitle={this.state.taskTitle}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                                             accountNumber={this.props.accountNumber}
                                             cbNumber={this.props.cbNumber}
                                             customerName={this.props.customerName}
                    />
                )
            } /*else if (this.state.category === "SME Loan Tracking") {
                return (
                    <DocumentMakerSmeLoanTracking delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                  closeIcon={this.closeIcon}
                                                  appId={this.state.appUid}
                                                  subCategory={this.state.subCategory}
                                                  category={this.state.category} serviceType={this.state.serviceType}
                                                  freeFlag1={this.state.freeFlag1}
                                                  taskTitle={this.state.taskTitle}
                                                  subServiceType={this.state.subServiceType}
                                                  solId={this.state.solId}
                                                  caseId={this.state.CaseId}
                                                  accountNumber={this.props.accountNumber}
                                                  cbNumber={this.props.cbNumber}
                                                  customerName={this.props.customerName}
                    />
                )
            }*/ else if (this.state.category === "Retail" && this.state.subCategory === "Loan Archiving") {
                return (
                    <MakerInbox appId={this.state.appUid}
                                subCategory={this.state.subCategory}
                                category={this.state.category} serviceType={this.state.serviceType}
                                taskTitle={this.state.taskTitle}
                                subServiceType={this.state.subServiceType}
                                solId={this.state.solId}
                                caseId={this.state.CaseId}
                                accountNumber={this.props.accountNumber}
                                cbNumber={this.props.cbNumber}
                                customerName={this.props.customerName}
                                closeModal={this.closeModal}
                                closeIcon={this.closeIcon}/>
                )
            }
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DISBURSMENT_MEAKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           closeIcon={this.closeIcon}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category}
                                           serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType}
                                           solId={this.state.solId}
                                           caseId={this.state.CaseId}
                                           accountNumber={this.props.accountNumber}
                                           cbNumber={this.props.cbNumber}
                                           customerName={this.props.customerName}
                    />
                )
            } else if (this.state.category === "Partial Payment") {
                return (
                    <DisbursementMakerApproval closeModal={this.closeModal}
                                               closeIcon={this.closeIcon}
                                               appId={this.state.appUid}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category}
                                               serviceType={this.state.serviceType}
                                               subServiceType={this.state.subServiceType}
                                               solId={this.state.solId}
                                               caseId={this.state.CaseId}
                                               accountNumber={this.props.accountNumber}
                                               cbNumber={this.props.cbNumber}
                                               customerName={this.props.customerName}
                    />
                )
            } else if (this.state.category === "Pay Order") {
                return (
                    <MakerPayOrderStart finalApprove={true} closeModal={this.closeModal}
                                        closeIcon={this.closeIcon}
                                        appId={this.state.appUid}
                                        subCategory={this.state.subCategory}
                                        category={this.state.category}
                                        serviceType={this.state.serviceType}
                                        subServiceType={this.state.subServiceType} solId={this.state.solId}
                                        caseId={this.state.CaseId}
                                        accountNumber={this.props.accountNumber}
                                        cbNumber={this.props.cbNumber}
                                        customerName={this.props.customerName}
                    />
                )
            } else if (this.state.category === "EMI") {
                return (
                    <MakerApproval delStatus={this.state.delStatus}
                                   closeModal={this.closeModal}
                                   closeIcon={this.closeIcon}
                                   appId={this.state.appUid}
                                   subCategory={this.state.subCategory}
                                   category={this.state.category}
                                   serviceType={this.state.serviceType}
                                   subServiceType={this.state.subServiceType} solId={this.state.solId}
                                   caseId={this.state.CaseId}
                                   accountNumber={this.props.accountNumber}
                                   cbNumber={this.props.cbNumber}
                                   customerName={this.props.customerName}
                    />
                )
            } else if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorMaker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                           closeIcon={this.closeIcon}
                                           appId={this.state.appUid}
                                           subCategory={this.state.subCategory}
                                           category={this.state.category} serviceType={this.state.serviceType}
                                           subServiceType={this.state.subServiceType} solId={this.state.solId}
                                           caseId={this.state.CaseId}
                                           accountNumber={this.props.accountNumber}
                                           cbNumber={this.props.cbNumber}
                                           customerName={this.props.customerName}

                    />
                )
            } /*else if (this.state.category === "SME Loan Tracking") {
                return (
                    <DisbursementMakerSmeLoanTracking delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                      closeIcon={this.closeIcon}
                                                      appId={this.state.appUid}
                                                      subCategory={this.state.subCategory}
                                                      category={this.state.category}
                                                      serviceType={this.state.serviceType}
                                                      freeFlag1={this.state.freeFlag1}
                                                      taskTitle={this.state.taskTitle}
                                                      subServiceType={this.state.subServiceType}
                                                      solId={this.state.solId}
                                                      caseId={this.state.CaseId}
                                                      accountNumber={this.props.accountNumber}
                                                      cbNumber={this.props.cbNumber}
                                                      customerName={this.props.customerName}
                    />
                )
            }*/ else if (this.state.category === "Retail" && this.state.subCategory === "Loan Archiving") {
                return (
                    <DocumentationCheckerInbox appId={this.state.appUid}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category} serviceType={this.state.serviceType}
                                               taskTitle={this.state.taskTitle}
                                               subServiceType={this.state.subServiceType}
                                               solId={this.state.solId}
                                               caseId={this.state.CaseId}
                                               closeModal={this.closeModal}
                                               closeIcon={this.closeIcon}
                                               accountNumber={this.props.accountNumber}
                                               cbNumber={this.props.cbNumber}
                                               customerName={this.props.customerName}
                    />
                )
            }
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('DISBURSMENT_CHECKER_AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                                             accountNumber={this.props.accountNumber}
                                             cbNumber={this.props.cbNumber}
                                             customerName={this.props.customerName}


                    />
                )
            } else if (this.state.category === "Partial Payment") {
                return (
                    <DisbursementMakerApproval closeModal={this.closeModal}
                                               closeIcon={this.closeIcon}
                                               appId={this.state.appUid}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category}
                                               serviceType={this.state.serviceType}
                                               subServiceType={this.state.subServiceType}
                                               solId={this.state.solId}
                                               caseId={this.state.CaseId}
                                               accountNumber={this.props.accountNumber}
                                               cbNumber={this.props.cbNumber}
                                               customerName={this.props.customerName}

                    />
                )
            } /*else if (this.state.category === "SME Loan Tracking") {
                return (
                    <DisbursementCheckerSmeLoanTracking delStatus={this.state.delStatus} closeModal={this.closeModal}
                                                        closeIcon={this.closeIcon}
                                                        appId={this.state.appUid}
                                                        subCategory={this.state.subCategory}
                                                        category={this.state.category}
                                                        serviceType={this.state.serviceType}
                                                        freeFlag1={this.state.freeFlag1}
                                                        taskTitle={this.state.taskTitle}
                                                        subServiceType={this.state.subServiceType}
                                                        solId={this.state.solId}
                                                        caseId={this.state.CaseId}
                                                        accountNumber={this.props.accountNumber}
                                                        cbNumber={this.props.cbNumber}
                                                        customerName={this.props.customerName}
                    />
                )
            }*/
            else if (this.state.subCategory === "Tenor Change") {
                return (
                    <LoanAccountTenorChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category} serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                                             accountNumber={this.props.accountNumber}
                                             cbNumber={this.props.cbNumber}
                                             customerName={this.props.customerName}

                    />
                )
            } else if (this.state.category === "EMI") {
                return (
                    <CheckerApproval delStatus={this.state.delStatus}
                                     closeModal={this.closeModal}
                                     closeIcon={this.closeIcon}
                                     appId={this.state.appUid}
                                     subCategory={this.state.subCategory}
                                     category={this.state.category}
                                     serviceType={this.state.serviceType}
                                     subServiceType={this.state.subServiceType}
                                     solId={this.state.solId}
                                     caseId={this.state.CaseId}
                                     accountNumber={this.props.accountNumber}
                                     cbNumber={this.props.cbNumber}
                                     customerName={this.props.customerName}
                    />
                )
            } else if (this.state.category === "Retail" && this.state.subCategory === "Loan Archiving") {
                return (
                    <DocumentationCheckerInbox appId={this.state.appUid}
                                               caseId={this.state.caseId}
                                               subCategory={this.state.subCategory}
                                               category={this.state.category} serviceType={this.state.serviceType}
                                               taskTitle={this.state.taskTitle}
                                               closeModal={this.closeModal}
                                               subServiceType={this.state.subServiceType}
                                               solId={this.state.solId}
                                               closeIcon={this.closeIcon}
                                               accountNumber={this.props.accountNumber}
                                               cbNumber={this.props.cbNumber}
                                               customerName={this.props.customerName}
                    />
                )
            }
        }
        else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup").indexOf('Document Checker AWS') !== -1) {
            if (this.state.subServiceType === "Other Service Request") {
                return (
                    <InterestTransferChecker delStatus={this.state.delStatus} closeModal={this.closeModal}
                                             closeIcon={this.closeIcon}
                                             appId={this.state.appUid}
                                             subCategory={this.state.subCategory}
                                             category={this.state.category}
                                             serviceType={this.state.serviceType}
                                             subServiceType={this.state.subServiceType}
                                             solId={this.state.solId}
                                             caseId={this.state.CaseId}
                    />
                )
            }
        }


    };

    onRowClick = (event, rowData) => {
        event.preventDefault();
        if ((this.state.activeItem === "Group Inbox" || this.state.activeItem === "Return")) {

        } else if (this.state.activeItem === "Group Inbox") {
            this.setState({
                loading: true
            });
            let url = assetOpsBackEndServerURL + "/case/claimReturn/" + rowData.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setInboxProps({...rowData, appId: response.data})
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.setInboxProps(rowData)
        }
    };

    setInboxProps = (rowData) => {
        console.log(rowData);
        this.setState({
            initBranchName: rowData.branch_name,
            CaseId: rowData.CaseId,
            serviceType: rowData.serviceType,
            subServiceType: rowData.subServiceType,
            category: rowData.category_type,
            subCategory: rowData.sub_category_type,
            solId: rowData.solId,
            appUid: rowData.appId,
            delStatus: rowData.Status,
            taskTitle: rowData.taskTitle,
            status: rowData.requestStatus,
            freeFlag1: rowData.freeFlag1,
            freeFlag2: rowData.freeFlag2,
            freeFlag3: rowData.freeFlag3,
            freeFlag4: rowData.freeFlag4,
            recpmtid: rowData.recpmtid,
            cb_number: rowData.cb_number,
            account_number: rowData.account_number,
            customer_name: rowData.customer_name,
            urgency: rowData.urgency,
            sentByUsername: rowData.returnedBy,
            jointAccountCustomerNumber: rowData.jointAccountCustomerNumber,
            showInbox: true,
            loading: false
        });
    };

    functionForCaseLockOrUnlock = (event, data) => {
        event.preventDefault();
        let selectedAppId = [];
        for (let item of data) {
            selectedAppId.push(item.appId)
        }
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        let single_url = "";
        if (this.state.activeItem === "Return" || this.state.activeItem === "Group Inbox") {
            single_url = assetOpsBackEndServerURL + "/case/claim/";
        } else {
            single_url = assetOpsBackEndServerURL + "/case/unClaim/";
        }

        axios.post(single_url, selectedAppId, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page);
            }).catch((error) => {
            console.log(error);
            this.setState({
                loading: false,
                getData: true,
                showTable: true
            })
        })
    };

    renderTopLabelButton = () => {
        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CHECKER") && (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === 'Maintenance Group Inbox' || this.state.activeItem === "Remittance Group Inbox SD" || this.state.activeItem === "Return")) {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if (this.state.activeItem === "DocumentUpload" || this.state.activeItem === "Group Inbox" || this.state.activeItem === "Remittance Group Inbox" || this.state.activeItem === "Dormant Activation Group Inbox" || this.state.activeItem === "Maintenance Group Inbox" || this.state.activeItem === "Account Opening Group Inbox" || this.state.activeItem === "Return") {
            return [{
                name: "LOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        } else if ((this.state.activeItem === "Inbox" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER") || (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "MAKER" && this.state.activeItem === "Return")) {
            return [{
                name: "UNLOCK",
                style: {...commonInboxButton},
                triggerFunction: this.functionForCaseLockOrUnlock
            }]
        }
    };

    renderTable = () => {
        return (
            (this.state.getData && this.state.showTable) &&
            <TableComponent tableData={this.state.tableData} tableColumns={this.renderTableColumn()}
                            onRowClick={this.onRowClick} loader={this.state.loading} maxBodyHeight="450px"
                            totalRow={this.state.totalRow} page={this.state.page} whiteSpace={"wrap"}
                            selection={(this.state.activeItem === "Group Inbox" || this.state.activeItem === "Inbox" || this.state.activeItem === "Return")}
                            handleChangePage={this.handleChangePage} rowsPerPage={this.state.rowsPerPage}
                            tableButton={this.renderTopLabelButton()}
            />
        )
    };

    closeModal = () => {
        this.setState({
            showInbox: false,
            getData: false,
            showTable: false,
            loading: true,
        }, this.functionForGetTabWiseUrl(this.state.activeItem, this.state.page))
    };

    renderInboxComponent = () => {
        return this.renderInboxCase()
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loading} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.showInbox}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderInboxComponent()}
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        {this.renderFilterField()}
                        <Grid item xs={12}><br/></Grid>
                        {this.renderMenu()}
                        {this.renderTable()}
                        {this.renderNotification()}
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }

}

export default withStyles(styles)(AssetUserInbox);
