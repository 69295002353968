import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";

import FormSample from '../JsonForm/FormSample';
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";

import {backEndServerURL} from "../../Common/Constant";
import Notification from "../NotificationMessage/Notification";
import axios from "axios";
import Functions from '../../Common/Functions';
import CloseIcon from "@material-ui/icons/Close";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const jsonForm = {
    "variables": [

        {
            "varName": "branchName",
            "type": "text",
            "label": "Branch Name",
            "required": true,
            "number": false,
            "grid": 2,
        },
        {

            "type": "emptyData",

        },
        {
            "varName": "solId",
            "type": "text",
            "label": "Sol Id",
            "required": true,
            "number": true,
            "grid": 2,

        },
        {

            "type": "emptyData",

        },
        {
            "varName": "status",
            "type": "select",
            "label": "Status",
            "required": true,
            "grid": 2,
            "enum": [
                "ACTIVE",
                "INACTIVE"
            ]
        },
        {

            "type": "emptyData",

        },
    ],

};


class BranchAddEditDelete extends React.Component {
    renderEditForm = (() => {
        console.log(this.state.varValue);
        if (this.state.getBranchData) {
            return (
                <FormSample close={this.props.close} showValue={true} varValue={this.state.varValue} grid="6"
                            buttonName="Submit"
                            onSubmit={this.getSubmitedForm} jsonForm={jsonForm}/>
            )
        } else if (this.props.id === undefined) {
            return (
                <FormSample close={this.props.close} grid="6" buttonName="Submit" onSubmit={this.getSubmitedForm}
                            jsonForm={jsonForm}/>
            )
        } else {

        }
    });

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getBranchData: false,
            title: "",
            notificationMessage: "",

            alert: false,
            redirectLogin: false,

        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    getSubmitedForm = (object) => {


        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/branchMaster/update/" + this.props.id;
            axios.post(url, {
                "branchName": this.firstLetterUpperCase(object.branchName),
                "solId": object.solId,
                "status": object.status,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Updated",
                        alert: true
                    });

                    console.log("Successfully Edited");

                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            let url = backEndServerURL + "/branchMaster/add";

            axios.post(url,
                {

                    branchName: this.firstLetterUpperCase(object.branchName),
                    solId: object.solId,
                    status: object.status,

                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.props.close();
                    this.setState({
                        title: "Sucessfully!!",
                        notificationMessage: "Sucessfully Added",
                        alert: true
                    });
                    console.log("Added Successfully");

                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    componentDidMount() {
        if (this.props.id !== undefined) {
            let url = backEndServerURL + "/branchMaster/get/" + this.props.id;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let varValue = [];
                    varValue["branchName"] = response.data.branchName;
                    varValue["solId"] = response.data.solId;
                    varValue["status"] = response.data.status;
                    this.setState({
                        varValue: varValue,
                        getBranchData: true

                    })
                })
                .catch((error) => {
                    console.log(error);

                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }

    }

    close = () => {


        this.props.close()
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <GridContainer>
                {this.renderNotification()}
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}<a><CloseIcon onClick={this.close}
                                                                                                  style={{
                                                                                                      position: 'absolute',
                                                                                                      right: 10,
                                                                                                      color: "#000000"
                                                                                                  }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}
                            </div>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        )
    }

}

export default withStyles(styles)(BranchAddEditDelete);
