import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import DebitAuthorityLetter from "../../../../assets/DebitAuthorityLetter.pdf";
import ErrorModal from "../Common/ErrorModal";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const jsonForm = [

    {
        varName: "fileStatus",
        type: "select",
        label: "File Status",
        required: true,
        grid: 2,
        "enum": [
            "Under process",
            "Pending at Legal",
            "Received from Legal",
            "Completed but Yet to Deliver",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "documentReleaseCharge",
        type: "text",
        label: "Document Release -Charge",
        required: true,
        grid: 2,
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "csmsTerminationStatus",
        type: "select",
        label: "CSMS Termination Status",
        required: true,
        grid: 2,
        "enum": [
            "Active",
            "Inactive",
        ]
    },
    {
        type: "empty",
        grid: 12,
    },


    {
        varName: "uploadSroToken",
        type: "file",
        label: "Upload SRO Token",
        required: true,
        grid: 2,
    },
    {
        type: "empty",
        grid: 12,
    },


];


let hldRemarks = [
    {
        "varName": "hldRemarks",
        "type": "textArea",
        "label": "HLD Remarks",
        "grid": 12
    }]
;


class HomeLoanDocWaiverRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passingData: '',
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                hld_approved: '',
            },

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            fileUploadData: {},
            submitButtonClick: false,
            errorModal: false,
            actionType: "",
            confirmAlert: false,


        }

    }


    componentDidMount() {

        var remarksArray = [];

        if (this.props.appId !== undefined) {


            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    if (data.remarks !== 'undefined') {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    }
                });
                this.setState({
                    getRemarks: remarksArray
                })
            })
                .catch((error) => {
                    console.log(error)
                });

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {


                    let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true
                            })
                            /*let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    })
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })


                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                    this.setState({
                        getDeferalList: tableArray
                    })*/

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    let inputData=response.data;
                    inputData.hldRemarks=undefined;
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: this.removeNullValue(inputData),
                        inputData: this.removeNullValue(inputData),
                        appId: this.props.appId,
                        appData: response.data,
                        loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });

        }


    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderForm = () => {
        if (this.state.getData) {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)

            )
        }

    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return this.renderForm()


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={"Successful"}
                              message={"Successfully Routed!"}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSave = () => {
        console.log('in handleSave');

        let uploadUrl = backEndServerURL + "/case/upload";
        let types = 'Attachments';
        let files = this.state.fileUploadData.uploadSroToken; //multiple files
        console.log(files);
        let length = Object.values(this.state.fileUploadData).length;


        let formData = new FormData();
        formData.append("appId", this.props.appId);
        console.log(this.state.fileUploadData);
        console.log(formData);
        console.log(this.props.appId);
        if (Object.values(this.state.fileUploadData).length === 1) {
            formData.append("file", this.state.fileUploadData['uploadSroToken']);
            formData.append("type", "file1");
        }

        console.log(formData);


        /*let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;

        //let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
            .then((response) => {
                console.log("Before case route in HLD to CS");
                console.log(this.state.inputData);

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });*/

        axios({
            method: 'post',
            url: uploadUrl,
            data: formData,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                'content-type': 'multipart/form-data'
            }
        })
            .then((response) => {

                let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
                axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log("Before case route in HLD");
                        console.log(this.state.inputData);
                        var url = backEndServerURL + "/case/route/" + this.props.appId;
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {


                                console.log(response.data);
                                this.setState({
                                    title: "Successfull!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    loading: false
                                });

                                this.props.closeModal();
                                //
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })

                            });

                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });


            })
            .catch((error) => {
                console.log("image error");
                console.log(error);
            });


        this.props.closeModal();

    };
    closeConfirmAlert = (data, appId) => {


        this.setState({

            confirmAlert: false,

        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmit") {

            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "handleReturn") {

            this.handleSubmit("NO")
        }
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(hldRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            dependencyField.push({
                "varName": "hldRemarks",
                "type": "textArea",
                "required": true,
                "label": "Remarks",
                "grid": 12
            })
            let error = MyValidation.defaultValidation(dependencyField, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    handleSubmitButton = () => {
        if (this.state.getData) {

            return (
                <ThemeProvider>
                    <Grid>

                        <Button target={"_blank"} href={DebitAuthorityLetter} style={{
                            backgroundColor: "red",
                            color: "white",
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 3,
                            paddingBottom: 3,
                            borderRadius: 3,
                            border: 1,
                            textDecoration: "none"
                        }} target={"_blank"}>Generate debit Authority letter</Button>

                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid>

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSave}

                        >
                            Save
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            //onClick={(event) => this.handleSubmit(event, "YES")}
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}

                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            //onClick={(event) => this.handleSubmit(event, "NO")}
                            onClick={(event) => this.handleCommon(event, "handleReturn")}

                        >
                            Return
                        </button>

                    </Grid>
                </ThemeProvider>
            )
        }
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    renderFormTable = () => {

        return (

            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>

                     <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>

                        <tr>
                            <th scope="row">Loan Type</th>
                            <td>{this.state.inputData.loanType}</td>

                        </tr>
                        <tr>
                            <th scope="row">Product Code</th>
                            <td>CSLAB</td>
                        </tr>

                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.state.inputData.loanAccountExpiryDate}</td>
                        </tr>

                        <tr>
                            <th scope="row">Maturity</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">LOAN</th>
                             <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>

                        <tr>
                            <th scope="row">AMOUNT</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                         </tr>

                        <tr>
                            <th scope="row">FEE</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                         </tr>

                        <tr>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                         </tr>

                        <tr>
                            <th scope="row">Total</th>
                            <td></td>
                        </tr>

                        <tr>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                         </tr>


                    </table>


                                 </div>
            </div>
        )
    };

    handleSubmit = (data) => {
        this.setState({
            passingData: data,
            submitButtonClick: true
        });


    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };

    renderRemarks = () => {


        return (

            CommonJsonFormComponent.renderJsonForm(this.state, hldRemarks, this.updateComponent)

        )

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: '100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={backEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };


    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.props.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };


    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false
        });
        if (status === "success" || status === "No File Found") {
            if( this.state.fileUploadData.uploadSroToken!==undefined &&  this.state.fileUploadData.uploadSroToken!==null){
                let uploadUrl = backEndServerURL + "/case/upload";
                let types = 'Attachments';
                let files = this.state.fileUploadData.uploadSroToken; //multiple files
                console.log(files);
                let length = Object.values(this.state.fileUploadData).length;


                let formData = new FormData();
                formData.append("appId", this.props.appId);
                console.log(this.state.fileUploadData);
                console.log(formData);
                console.log(this.props.appId);
                if (Object.values(this.state.fileUploadData).length === 1) {
                    formData.append("file", this.state.fileUploadData['uploadSroToken']);
                    formData.append("type", "file1");
                }

                console.log(formData);

                this.file.current.functionForFileUpload();
                let saveDataDeferralRemarksAndRoute = {};
                if (this.state.inputData.hldRemarks !== undefined && this.state.inputData.hldRemarks !== null) {
                    saveDataDeferralRemarksAndRoute.remark = this.state.inputData.hldRemarks;
                }

                var appId = this.props.appId;
                var approval = this.state.passingData;

                this.state.inputData.hld_approved = this.state.passingData;

                 axios({
                    method: 'post',
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
                        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                this.setState({
                                    title: "Successfull!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    loading: false
                                });

                                this.props.closeModal();
                                //
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })

                            });


                    })
                    .catch((error) => {
                        console.log("image error");
                        console.log(error);
                    })
            }
            else{
                     let saveDataDeferralRemarksAndRoute = {};
                if (this.state.inputData.hldRemarks !== undefined && this.state.inputData.hldRemarks !== null) {
                    saveDataDeferralRemarksAndRoute.remark = this.state.inputData.hldRemarks;
                }

                var appId = this.props.appId;
                var approval = this.state.passingData;
                this.state.inputData.hld_approved = this.state.passingData;
                console.log(this.state.inputData);
                saveDataDeferralRemarksAndRoute.map = this.emptyValueRemove(this.state.inputData);
                        var url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                this.setState({
                                    title: "Successfull!",
                                    notificationMessage: "Successfully Routed!",
                                    alert: true,
                                    loading: false
                                });

                                this.props.closeModal();
                                //
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false
                                })

                            });



            }

        } else {
            this.setState({
                errorModal: true,
                title: "Failed to Upload File."
            })
        }
    };

    closeErrorModal = () => {
        this.setState({
            errorModal: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Home Loan Documentation Waiver Request</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.state.inputData.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Home Loan Documentation Waiver Request{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    render() {

        console.log("inputData");
        console.log(this.state.varValue);
        console.log(this.state.inputData);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }


            return (
                <Card>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loader}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.croppedImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>

                        </DialogContent>
                    </Dialog>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>

                    <CardBody>

                        {this.renderNotification()}


                        <br/>

                        <ThemeProvider theme={theme}>
                            <Grid container spacing={1}>

                                {this.commonData()}
                                {this.renderDynamicFileUpload()}
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.confirmAlert}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                      closeModal={this.closeConfirmAlert}

                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModal}>
                                    <DialogContent className={classes.dialogPaper}>

                                        <ErrorModal
                                            closeModal={this.closeErrorModal}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Grid item xs={12}></Grid>
                                {this.mappingPhoto()}


                                <br/>
                                {this.renderRemarksData()}
                                <br/>

                                {this.renderRemarks()}


                                {this.handleSubmitButton()}

                            </Grid>
                        </ThemeProvider>

                    </CardBody>

                </Card>
            );
        }


}

export default withStyles(styles)(HomeLoanDocWaiverRequest);
