import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import SignatureCard from "./SignatureCardByClientId";
import withStyles from "@material-ui/core/styles/withStyles";
import CBNotFound from "../CASA/CBNotFound";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class SignatureButtonForClientId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
            cbNotFound: false,
            title: "",
        }
    }


    componentDidMount() {
    }

    signatureCard = () => {
        this.setState({
            signatureCard: true
        })
    };

    closeSignatureCard = () => {
        this.setState({
            signatureCard: false
        })
    };

    renderSignatureImage = () => {
        return <SignatureCard closeModal={this.closeSignatureCard} id={this.props.clientId}/>
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderAlertModal = () => {
        const {classes} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="sm"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.cbNotFound}>
                <DialogContent className={classes.dialogPaper}>
                    <CBNotFound
                        closeModal={this.closeModalCBNotFound}
                        title={this.state.title}
                    />
                </DialogContent>
            </Dialog>
        )
    };

    render() {
        const {classes} = this.props.classes;
        return (
            <div>
                <button
                    onClick={this.signatureCard}
                    className="btn btn-danger btn-sm">
                    Signature Card View
                </button>

                {this.renderAlertModal()}
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.signatureCard}>
                    <DialogContent className={classes.dialogPaper}>
                        {this.renderSignatureImage()}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }


}

export default withStyles(styles)(SignatureButtonForClientId);