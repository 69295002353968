import React, {Component} from 'react';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import {Button, Dialog} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";
import ErrorModal from "../CommonComponent/ErrorModal";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../../MyValidation";
import Table from "../../Table/Table";
import CommonValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import SelectComponent from "../../JsonForm/SelectComponent";
import TextFieldComponent from "../../JsonForm/TextFieldComponent";
import TextField from "@material-ui/core/TextField";
import SignatureButton from "../Maintenance/SignatureButton";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";
 var deferalCommForm = [
    {
        "varName": "deferalDstId",
        "type": "text",
        "label": "Dst Id",
        "required": true,
        "grid": 2,

    }, {
        "varName": "deferalRmCode",
        "type": "text",
        "label": "Rm Code",
        "required": true,
        "grid": 2,
    },
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonFormTID = [

    {
        "varName": "TID",
        "type": "textDedup",
        "label": "Merchant ID",
        "grid": 2,
        "required": true,
    },
];

let jsonFromDST = [
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "grid": 3,
        "label": "Call Category",
        "required": true,
        "readOnly": true

    },
    {
        "varName": "MID",
        "type": "text",
        "label": "MID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "RID",
        "type": "text",
        "label": "RID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "DBAName",
        "type": "text",
        "label": "DBA Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "ownerName",
        "type": "text",
        "label": "Owner Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MCC",
        "type": "text",
        "label": "Mcc",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "posSerial",
        "type": "text",
        "label": "Pos Serial",
        "grid": 3,
        "readOnly": true
    },

];

let jsonFromFRM = [
    {
        "varName": "callCategory",
        "type": "text",
        "grid": 3,
        "label": "Call Category",
        "readOnly": true

    },
    {
        "varName": "TID",
        "type": "text",
        "label": "TID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MID",
        "type": "text",
        "label": "MID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "RID",
        "type": "text",
        "label": "RID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "DBAName",
        "type": "text",
        "label": "DBA Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "ownerName",
        "type": "text",
        "label": "Owner Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MCC",
        "type": "text",
        "label": "Mcc",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "posSerial",
        "type": "text",
        "label": "Pos Serial",
        "grid": 3,
        "readOnly": true
    },

];
let jsonFromMOU = [
    {
        "varName": "callCategory",
        "type": "text",
        "grid": 3,
        "label": "Call Category",
        "readOnly": true

    },
    {
        "varName": "TID",
        "type": "text",
        "label": "TID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MID",
        "type": "text",
        "label": "MID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "RID",
        "type": "text",
        "label": "RID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "DBAName",
        "type": "text",
        "label": "DBA Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "ownerName",
        "type": "text",
        "label": "Owner Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MCC",
        "type": "text",
        "label": "Mcc",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "posSerial",
        "type": "text",
        "label": "Pos Serial",
        "grid": 3,
        "readOnly": true
    },

];
let jsonFromSandA = [
    {
        "varName": "callCategory",
        "type": "text",
        "grid": 3,
        "label": "Call Category",
        "readOnly": true

    },
    {
        "varName": "TID",
        "type": "text",
        "label": "TID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MID",
        "type": "text",
        "label": "MID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "RID",
        "type": "text",
        "label": "RID",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "DBAName",
        "type": "text",
        "label": "DBA Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "address",
        "type": "text",
        "label": "Address",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "ownerName",
        "type": "text",
        "label": "Owner Name",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "MCC",
        "type": "text",
        "label": "Mcc",
        "grid": 3,
        "readOnly": true
    },
    {
        "varName": "posSerial",
        "type": "text",
        "label": "Pos Serial",
        "grid": 3,
        "readOnly": true
    },

];
let jsonFormFile = [
    {
        "varName": "attachFileOne",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 01",
        "grid": 12,
    },
    {
        "varName": "attachFileTwo",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 02",
        "grid": 12,
    },
    {
        "varName": "attachFileThree",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 03",
        "grid": 12,
    },
];

let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12,
    }]
;

var deferalOther =
    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "grid": 12
    };

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Trade License",
        "NID or Readable Passport",
        "Passport Size Photograph",
        "E-TIN Certificate",
        "EFTN Form",
        "18-A Form",
        "Visiting Card",
        "other"
    ],
    "grid": 12
};

var days = {
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "10",
        "15",
        "20"
    ],
    "grid": 4
};
var deferalCategory={
    "varName": "deferalCategory",
    "type": "select",
    "label": "Category",
    "enum": [
        "G",
        "R"
    ],

};
var deferalSubCategoryG={
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "30",
        "60",
        "90",
        "180",
    ],

};
var deferalSubCategoryR={
    "varName": "expireDays",
    "type": "select",
    "label": "Expire Days",
    "enum": [
        "30",
        "60",
        "90"
    ],

};
class MerchantMaintenance extends Component {


    constructor(props) {
        super(props);
        this.state = {
            inputData: {
                deferal: "NO",
                mbu_deferal: "NO"
            },
            autoComplete: {},
            showValue: true,
            fileUploadData: {},
            uploadModal: false,
            caseId: "",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            imageModel: false,
            loaderNeeded: false,
            loading: true,
            getRemarks: [],
            getTargets: [],
            merchantSupportDocumentArray: [],
            getImageLink: [],
            getImageBoolean: false,
            imageModalBoolean: false,
            getMerchantValue: false,
            getFileValue: false,
            closeDialogBox: false,
            getValue: false,
            imageName: [],
            selectImage: "",
            uniqueId: 0,
            alert: false,
            error: false,
            successbloo: false,
            requiredMessage: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            showBody: false,
            remarks: "",
            varValue: {
                "reza": {label: 'The Godfather', year: 1972},
                /* "MID": "09713456734",
                 "RID": "09713456734",
                 "merchantName": "Rashedul Islam",
                 "DBAName": "Rashedul Islam",
                 "address": "Merul Badda,Dhaka-1215",
                 "ownerName": "Rashedul Islam",
                 "accountNumber": "1287625342765",
                 "MCC": "Rashed",
                 "posSerial": "1234567543",*/

            },
            getDeferalList: [],
            defferalData: false,
            values: [],
            AddDeferal: false,
            errorModalBoolean: false,
            errorTittle:  "",
        }


    }

    componentDidMount() {
        let workPlace = LocalstorageEncrypt.encryptStorageFunction.getItem("workplace");
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");

        jsonFormTID[0].readOnly = workFlowGroup === "HOMBU";

        if (workPlace === "FRM" || workPlace === "S_AND_A" || workFlowGroup === "CARD_DIVISION" || workPlace === "MOU" || workFlowGroup === "MBU_DFF" || workFlowGroup === "HOMBU") {
            remarks[0].required = true
        } else {
            if (("required" in remarks[0])) {
                delete remarks[0].required;
            }
        }


        //***********merchant call category get***********
        this.getMerchantCallCategory();

        if (this.props.appId !== undefined) {
            //***************get variable***********************
            this.getVariables();
            //***************get file***********************
            this.getFile();

            this.getRemark();

            this.setState({
                showBody: true,
            })

        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
            //****************case start by dst******************
            this.caseStart();
        }

    }

    getRemark = () => {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    console.log(response.data);
                    response.data.map((data) => {

                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };

    getVariables() {
        let url = backEndServerURL + '/variables/' + this.props.appId;
        this.setState(
            {
                caseId: this.props.appId,
                loaderNeeded: false
            }
        );
        axios.get(url,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let inputData = {...response.data};
                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
                    inputData.callCategory = {label: response.data.callCategory};
                } else {
                    jsonFromDST[0].type = "text";
                    inputData.callCategory = response.data.callCategory["label"] || response.data.callCategory;
                }

                let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        //console.log(response.data)
                        if (response.data === '') {
                            // console.log('empty')
                        } else {
                            let deferealData = [];

                            response.data.map((deferal) => {

                                var status;
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "APPROVAL_WAITING") {
                                    status = "Pending"
                                } else {
                                    status = "Not Approved";
                                }


                                deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                            });

                            this.setState({
                                getDeferalList: deferealData,
                                defferalData: true
                            })
                        }

                    })
                    .catch((error) => {
                        console.log(error)
                    });
                this.setState({
                    varValue: inputData,
                    appData: this.copyJson(inputData),
                    inputData: this.copyJson(inputData),
                    appId: this.props.appId,
                    uniqueId: response.data.caseId,
                    loaderNeeded: true,
                    loading: false,
                    showValue: true,
                    getValue: true,
                });
            })
            .catch((error) => {
                console.log(error);

            })
    }

    caseStart() {
        let start_url = backEndServerURL + "/startCase/mbu_maintenance";
        axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData.caseId = response.data.caseId;
                this.setState({
                    caseId: response.data.id,
                    uniqueId: response.data.caseId,
                    getValue: true,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    getMerchantCallCategory() {
        let autoComplete = {};
        let merchant_callCategory_url = backEndServerURL + "/merchant/callCategory";
        axios.get(merchant_callCategory_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                autoComplete.callCategory = response.data;


                this.setState({
                    //    merchantSupportDocumentArray:response.data,
                    autoComplete: autoComplete,
                    loaderNeeded: true,
                    loading: false,
                    getMerchantValue: true,
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };
    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px', marginBottom: "10px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '10px'}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Type", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };

    // merchantSupportingDocument(){
    //     for (let i = 0; i < this.state.merchantSupportDocumentArray.length; i++) {
    //         if (this.state.inputData.merchantCallCategory === this.state.merchantSupportDocumentArray[i].label){
    //             return this.state.merchantSupportDocumentArray[i].supportingDocuments;
    //         }
    //     }
    // }

    updateComponent = () => {
        this.forceUpdate();
    };


    saveHandler = (event, value, status) => {
        event.preventDefault();
        // this.state.inputData.merchantMaintenance = value;

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
            if (this.state.inputData.deferal === "YES" && this.props.appId===undefined) {
                this.state.inputData.mbu_deferal = "YES";
            } else {
                this.state.inputData.mbu_deferal = "NO";
            }
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MOU") {
            this.state.inputData.merchantMaintenance = value;
        }
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "FRM") {
            let error = MyValidation.defaultValidation(remarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0
            } else {
                this.state.inputData.frm_approval = status;
            }
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CARD_DIVISION") {
            let error = MyValidation.defaultValidation(remarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0
            } else {
                this.state.inputData.sandA_approval = status;
            }
        }


        let TIDFromError;
        let dstFromError;
        let deferralEror;
        if (this.state.inputData.callCategory === undefined || this.state.inputData.callCategory === "" || this.state.inputData.TID === undefined || this.state.inputData.TID === "") {
            if (this.state.values.length > 0) {
                deferralEror= MyValidation.defaultValidation(deferalCommForm, this.state);
            }
            TIDFromError = MyValidation.defaultValidation(jsonFormTID, this.state);
            dstFromError = MyValidation.defaultValidation(jsonFromDST, this.state);
            let fileErrorOne = CommonValidation.fileValidation(jsonFormFile[0], this.state);
            let fileErrorTwo = CommonValidation.fileValidation(jsonFormFile[1], this.state);
            let fileErrorThree = CommonValidation.fileValidation(jsonFormFile[2], this.state);
            this.forceUpdate();
            if (TIDFromError === true || deferralEror=== true) {
                return 0;
            }
            if (dstFromError === true || fileErrorOne === true || fileErrorTwo === true || fileErrorThree === true) {
                return 0;
            }

        } else {
            this.setState({
                showBody: true,
                getValue: true,
                getMerchantValue: true,

            })
        }
        this.getFlow();

    };

    caseClose() {
        //************case close***********
        let case_close_url = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(case_close_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response);

                this.setState({
                    notificationType: "Close",
                    notificationTitle: "Success Massage",
                    notificationMessage: "Case Closed",
                    loaderNeeded: true,
                    loader: false,
                    alert: true
                });
                this.props.closeModal();

            })
            .catch((error) => {
                console.log(error)
            })
    }

    cancelOrReturnHandler = (event) => {
        event.preventDefault();
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU" && this.props.appId === undefined) {
            window.location.reload();
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU" && this.props.appId !== undefined) {
            this.caseClose();
        } else {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MOU") {
                let error = MyValidation.defaultValidation(remarks, this.state);
                this.forceUpdate();
                if (error === true) {
                    return 0
                } else {
                    this.state.inputData.mou_approval = "RETURN";
                    this.state.inputData.merchantMaintenance = "";
                    this.setVariable();
                }
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "FRM") {
                let error = MyValidation.defaultValidation(remarks, this.state);
                this.forceUpdate();
                if (error === true) {
                    return 0
                } else {
                    this.state.inputData.frm_approval = "RETURN_MOU_MAKER";
                    this.setVariable();
                }
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CARD_DIVISION") {
                let error = MyValidation.defaultValidation(remarks, this.state);
                this.forceUpdate();
                if (error === true) {
                    return 0
                } else {
                    this.state.inputData.sandA_approval = "RETURN_MOU_MAKER";
                    this.setVariable();
                }
            }
        }

    };

    closeSuccessModal = () => {
        this.setState({
            successbloo: false
        });
        if (this.props.appId === undefined) {
            window.location.reload();
        } else {
            this.props.closeModal()
        }
    };
    closeDialogBox = () => {
        this.setState({
            closeDialogBox: false
        })
    };

    getFlow() {
        this.setState({
            loaderNeeded: false,
            loader: true,
        });

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
            let callCategory = this.props.appId !== undefined ? this.state.inputData.callCategory["label"] : this.state.inputData.callCategory;
            let merchant_flow_url = backEndServerURL + "/merchant/maintenance/flow";
            // let merchant_flow_url = backEndServerURL + "/getMerchantDetails/"+this.state.inputData.TID;
            axios.post(merchant_flow_url, callCategory, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'Content-Length': 0,
                    'Content-Type': 'text/plain'
                }
            })
                .then((response) => {
                    this.state.inputData.frm = response.data.frm;
                    this.state.inputData.SandA = response.data.SandA;
                    this.state.inputData.hombu = response.data.hombu;
                    this.state.inputData.properietorName = LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                    this.state.inputData.serviceType = "merchant";
                    this.state.inputData.subServiceType = "maintenance";
                    this.state.inputData.category = "merchant";
                    this.state.inputData.subCategory = "maintenance";
                    /*    this.state.inputData.MID = response.data.merchantId;
                        this.state.inputData.RID = response.data.merchantId;
                        this.state.inputData.merchantName = response.data.merchantName;
                        this.state.inputData.DBAName = response.data.merchantName;
                        this.state.inputData.address = response.data.address;
                        this.state.inputData.ownerName = response.data.merchantName;
                        this.state.inputData.accountNumber = response.data.accountNumber;
                        this.state.inputData.email = response.data.email;
                        this.state.inputData.MCC = response.data.merchantName;
                        this.state.inputData.posSerial = response.data.merchantId;
                        this.state.inputData.customerName=response.data.merchantName;*/

                    this.state.varValue.frm = response.data.frm;
                    this.state.varValue.SandA = response.data.SandA;
                    this.state.varValue.hombu = response.data.hombu;
                    this.state.varValue.properietorName = LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                    this.state.varValue.serviceType = "merchant";
                    this.state.varValue.subServiceType = "maintenance";
                    this.state.varValue.category = "merchant";
                    this.state.varValue.subCategory = "maintenance";
                    /*  this.state.varValue.MID = response.data.merchantId;
                      this.state.varValue.RID = response.data.merchantId;
                      this.state.varValue.merchantName = response.data.merchantName;
                      this.state.varValue.DBAName = response.data.merchantName;
                      this.state.varValue.address = response.data.address;
                      this.state.varValue.ownerName = response.data.merchantName;
                      this.state.varValue.accountNumber = response.data.accountNumber;
                      this.state.varValue.email = response.data.email;
                      this.state.varValue.MCC = response.data.merchantName;
                      this.state.varValue.posSerial = response.data.merchantId;
                      this.state.varValue.customerName=response.data.merchantName;*/
                    this.setVariable();
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MOU") {
            this.state.inputData.mou_approval = "APPROVED";
            this.setVariable();
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "FRM") {
            this.state.inputData.frm_approval = "APPROVED";
            this.setVariable();
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CARD_DIVISION") {
            this.state.inputData.sandA_approval = "APPROVED";
            this.setVariable();
        }

    }

    deferralSaveHandler = () => {
        if (this.state.inputData["deferal"] === "YES") {
            var defType = [];
            var expDate = [];
            var deferalCategory = [];
            var deferalSubCategory = [];
            let appId = this.state.caseId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let defferalType = this.state.inputData["deferalType" + value];
                if (defferalType === "other") {
                    defferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(defferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);
                deferalCategory.push(this.state.inputData["deferalCategory" + value])
                deferalSubCategory.push(this.state.inputData["expireDays" + value])
                console.log(expDate)
            }

            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate,
                deferralCategory: deferalCategory,
                deferralSubCategory: deferalSubCategory,
                deferralDstId: this.state.inputData.deferalDstId,
                deferralRmCode: this.state.inputData.deferalRmCode,
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log('Deferral Create Successfully');
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                })
        }
    };

    setVariable() {
        this.setState({
            comments: this.state.inputData.remarks
        });

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
            this.deferralSaveHandler()
        }

        let variables_url = backEndServerURL + "/variables/" + this.state.caseId;
        let postData = this.state.inputData;
        postData.customerName = postData.merchantName;
        postData.remarks = undefined;

        axios.post(variables_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                if (Object.values(this.state.fileUploadData).length < 1) {
                    this.remarkAndRoute();
                } else {
                    this.fileUpload();
                }
            }).catch((error) => {
            console.log(error);
        })
    }

    fileUpload() {
        let uploadUrl = backEndServerURL + "/case/upload";
        let formData = new FormData();
        formData.append("appId", this.state.caseId);
        if (Object.values(this.state.fileUploadData).length === 1) {
            formData.append("file", this.state.fileUploadData['attachFileOne']);
            formData.append("type", "file1");
        } else if (Object.values(this.state.fileUploadData).length === 2) {
            formData.append("file", this.state.fileUploadData['attachFileOne']);
            formData.append("type", "file1");
            formData.append("file", this.state.fileUploadData['attachFileTwo']);
            formData.append("type", "file2");
        } else if (Object.values(this.state.fileUploadData).length === 3) {
            formData.append("file", this.state.fileUploadData['attachFileOne']);
            formData.append("type", "file1");
            formData.append("file", this.state.fileUploadData['attachFileTwo']);
            formData.append("type", "file2");
            formData.append("file", this.state.fileUploadData['attachFileThree']);
            formData.append("type", "file3");
        }
        axios({
            method: 'post',
            url: uploadUrl,
            data: formData,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                'content-type': 'multipart/form-data'
            }
        })
            .then((response) => {

                this.remarkAndRoute();
            })
            .catch((error) => {
                console.log("image error");
                console.log(error);
            })
    }

    remarkAndRoute() {
        if (this.state.comments !== "") {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.comments + "/" + this.state.caseId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let route_url = backEndServerURL + "/case/route/" + this.state.caseId;
                    axios.get(route_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response);
                            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
                                this.setState({
                                    notificationType: "success",
                                    notificationTitle: "Success Massage",
                                    notificationMessage: "Reference number : " + this.state.uniqueId,
                                    loaderNeeded: true,
                                    loader: false,
                                    successbloo: true,
                                    alert: true

                                })
                            } else {
                                this.setState({
                                    notificationType: "success",
                                    notificationTitle: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                    loaderNeeded: true,
                                    loader: false,
                                    alert: true

                                });
                                this.props.closeModal()
                            }

                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            let route_url = backEndServerURL + "/case/route/" + this.state.caseId;
            axios.get(route_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
                        this.setState({
                            notificationType: "success",
                            notificationTitle: "Success Massage",
                            notificationMessage: "Reference number : " + this.state.uniqueId,
                            loaderNeeded: true,
                            loader: false,
                            successbloo: true,
                            alert: true
                        })
                    } else {
                        this.setState({
                            notificationType: "success",
                            notificationTitle: "Successful!",
                            notificationMessage: "Successfully Routed!",
                            loaderNeeded: true,
                            loader: false,
                            alert: true

                        });
                        this.props.closeModal();
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.searchField(event);

        }

    };

    searchField = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
            getMerchantValue:false,
            showBody:false,
        });
        let error = MyValidation.defaultValidation(jsonFormTID, this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false
            });
            return 0;
        } else {
            let inputData=this.state.inputData;
            let varValue=this.state.varValue;
            let merchant_flow_url = backEndServerURL + "/getMerchantDetails/"+this.state.inputData.TID;
            axios.get(merchant_flow_url, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'Content-Length': 0,
                    'Content-Type': 'text/plain'
                }
            })
                .then((response) => {

                    /*    inputData.frm = response.data.frm;
                   inputData.SandA = response.data.SandA;
                   inputData.hombu = response.data.hombu;*/
                    inputData.freeFlag5 =response.data.merchantId;
                    inputData.freeFlag6 =response.data.merchantName;
                    inputData.properietorName = LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                    inputData.serviceType = "merchant";
                    inputData.subServiceType = "maintenance";
                    inputData.category = "merchant";
                    inputData.subCategory = "maintenance";
                    inputData.MID = response.data.merchantId;
                    inputData.RID = response.data.merchantId;
                    inputData.merchantName = response.data.merchantName;
                    inputData.DBAName = response.data.merchantName;
                    inputData.address = response.data.address;
                    inputData.ownerName = response.data.merchantName;
                    inputData.accountNumber = response.data.accountNumber;
                    inputData.email = response.data.email;
                    inputData.MCC = response.data.merchantName;
                    inputData.posSerial = response.data.merchantId;
                    inputData.customerName=response.data.merchantName;

                    /*   varValue.frm = response.data.frm;
                       varValue.SandA = response.data.SandA;
                       varValue.hombu = response.data.hombu;*/
                    varValue.freeFlag5 = response.data.merchantId;
                    varValue.freeFlag6 =response.data.merchantName;
                    varValue.properietorName = LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                    varValue.serviceType = "merchant";
                    varValue.subServiceType = "maintenance";
                    varValue.category = "merchant";
                    varValue.subCategory = "maintenance";
                    varValue.MID = response.data.merchantId;
                    varValue.RID = response.data.merchantId;
                    varValue.merchantName = response.data.merchantName;
                    varValue.DBAName = response.data.merchantName;
                    varValue.address = response.data.address;
                    varValue.ownerName = response.data.merchantName;
                    varValue.accountNumber = response.data.accountNumber;
                    varValue.email = response.data.email;
                    varValue.MCC = response.data.merchantName;
                    varValue.posSerial = response.data.merchantId;
                    varValue.customerName=response.data.merchantName;
                    this.setState({
                        loading: false,
                        showBody: true,
                        getValue: true,
                        showValue:true,
                        getMerchantValue: true,
                        inputData:inputData,
                        varValue:varValue,

                    })



                })
                .catch((error) => {
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle:  this.errorObjectCheck(error),
                        loading: false,
                        // showBody: true,
                        getValue: true,
                        showValue:true,
                        // getMerchantValue: true,
                        inputData:inputData,
                        varValue:varValue,

                    })
                })
        }

    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false,


        })
    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };
    headerRender() {
        if (this.props.appId !== undefined) {
            return (
                <div><h4 style={{color: "white"}} >Merchant Maintenance Request<a><CloseIcon onClick={this.props.closeModal} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4></div>
            )
        } else {
            return (
                <h5>Merchant Maintenance Request</h5>
            )
        }
    }

    searchBody() {

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU" && this.state.getValue) {
            return (
                <React.Fragment>
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonFormTID, this.updateComponent, this.onKeyDownForDedup)}
                    {LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" &&
                    <Grid item xs={2}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginTop: '15px',
                            }}
                            onClick={this.searchField}
                        >
                            Search
                        </button>
                    </Grid>}
                </React.Fragment>

            );
        }
    }


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)

        )

    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferralCategory = (i) => {
        let field = JSON.parse(JSON.stringify(deferalCategory));
        field.varName = "deferalCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferralSubCategory = (i) => {
        let field = JSON.parse(JSON.stringify(deferalSubCategoryG));
        if (this.state.inputData["deferalCategory" + i]==="R"){
            field = JSON.parse(JSON.stringify(deferalSubCategoryR));
        }
        field.varName = "deferalSubCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };
    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDays = (i) => {
        let field = JSON.parse(JSON.stringify(deferalSubCategoryG));
        if (this.state.inputData["deferalCategory" + i]==="R"){
            field = JSON.parse(JSON.stringify(deferalSubCategoryR));
        }
        field.varName = "expireDays" + i;
        var exDate = "expireDate" + i;

        if (this.state.inputData[field.varName] !== undefined && this.state.inputData[field.varName] !== "") {
            var someDate = new Date();
            var x = this.state.inputData[field.varName];
            let numberOfDaysToAdd = parseInt(x, 10);

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            let fullDate = month + '/' + date + '/' + year;

            fullDate === "NaN/NaN/NaN" ? this.state.inputData[exDate] = "" : this.state.inputData[exDate] = fullDate;

            console.log(fullDate);
            console.log(this.state.inputData[exDate])

            // this.state.inputData[exDate] = fullDate

        }
        return (

            <React.Fragment>
                <Grid item xs={2}>
                    {
                        SelectComponent.select(this.state, this.updateComponent, field)
                    }
                </Grid>

                <Grid item xs={2}>
                    {
                        this.dynamicDate(this.state.inputData[exDate])
                    }
                </Grid>
            </React.Fragment>

        );
    };

    dynamicDate = (i) => {
        return (
            <React.Fragment>
                <Grid item>
                    <label className="input-label-common">Expire Date</label>
                </Grid>
                <Grid item>
                    <TextField
                        variant="outlined"
                        value={i}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>
        )

    };

    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <React.Fragment>
                    <Grid item xs={2}>
                        {
                            this.dynamicDeferral(el)
                        }
                    </Grid>

                </React.Fragment>


                {/* <Grid item xs={5}> */}
                <Grid item xs={1.5}>
                    {
                        this.dynamicDeferralCategory(el)
                    }
                </Grid>


                {
                    this.dynamicDays(el)
                }

                {/* </Grid> */}


                < React.Fragment>
                    <Grid item xs={2}>
                        {this.dynamicDeferralOther(el)}

                    </Grid>
                    <Grid item xs={2}>
                        <button
                            style={{float: "left", marginTop: "15px"}}
                            className="btn btn-outline-primary btn-sm"
                            type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                        >
                            Remove
                        </button>


                    </Grid>
                </React.Fragment>
            </React.Fragment>
        )

    }
    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],

        }));

        this.state.inputData["deferal"] = "YES";
    }

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["deferal"] = "YES"
            } else {
                this.state.inputData["deferal"] = "NO"
            }
        }


    }

    renderAddButtonShow = () => {

        return (
            <div>
                <button
                    className="btn btn-outline-primary btn-sm"

                    style={{
                        width: 100,
                        marginRight: "10px"
                    }}

                    type='button' value='add more'
                    onClick={this.addClick.bind(this)}


                >Add Deferral
                </button>
            </div>
        )

    };
    addDeferalCommonForm() {
        if (this.state.values.length > 0) {
            return (
                <React.Fragment>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state,deferalCommForm, this.updateComponent)
                    }
                </React.Fragment>
            );

        }
    }

    deferralFunction = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
            return (
                <>
                    {this.renderAddButtonShow()}
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    {this.addDeferalForm()}

                </>
            )
        }
    };

    signature = () => {
        if(this.state.inputData.accountNumber!==undefined){
            return (
                <>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                    />
                    <br/>
                </>
            )
        }

    };


    bodyRender() {
        console.log(LocalstorageEncrypt.encryptStorageFunction.getItem("workplace"));
        if (this.state.showBody === true && this.state.inputData.TID !== undefined && this.state.inputData.TID !== "") {
            if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MBU") {
                return (
                    <React.Fragment>
                        <Grid xs='12' style={{marginTop: "5px"}}>
                        </Grid>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromDST, this.updateComponent)}
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        {this.deferralFunction()}
                        {
                            this.addDeferalCommonForm()
                        }
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        {this.signature()}
                        {LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" && <Grid xs='12' style={{marginTop: "5px"}}>
                            {CommonJsonFormComponent.renderJsonForm(this.state, jsonFormFile, this.updateComponent)}
                        </Grid>}
                        <Grid xs='12' style={{marginTop: "0px"}}>
                        </Grid>
                        {this.getFilesButtons()}
                    </React.Fragment>
                )
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "FRM") {
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromFRM, this.updateComponent)}
                        <Grid item xs={12}></Grid>
                        {this.signature()}
                        {this.getFilesButtons()}
                    </React.Fragment>
                )
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MOU") {
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromMOU, this.updateComponent)}
                        <Grid item xs={12}></Grid>
                        {this.signature()}
                        <br/>
                        {this.getFilesButtons()}
                    </React.Fragment>
                )
            } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CARD_DIVISION") {
                return (
                    <React.Fragment>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromSandA, this.updateComponent)}
                        <Grid item xs={12}></Grid>
                        {this.signature()}
                        {this.getFilesButtons()}
                    </React.Fragment>
                )
            }
        }
    }

    approveHandler = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU_DFF") {
            this.state.inputData.dff_approval = "APPROVED";
            let deferalApproval = backEndServerURL + "/deferral/approval";
            let appId = this.props.appId;
            let approval = "APPROVE";
            let mbu_deferal = "NO";

            axios.post(deferalApproval, {
                appId: appId,
                approval: approval,
                mbu_deferal: mbu_deferal
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setVariable();
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "HOMBU") {
            this.state.inputData.hombu_approval = "APPROVED";
            this.setVariable();
        }
    };

    returnHandler = () => {
        let error = MyValidation.defaultValidation(remarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0
        }
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU_DFF") {
            this.state.inputData.dff_approval = "RETURN";
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "HOMBU") {
            this.state.inputData.hombu_approval = "RETURN";
        }
        let deferalApproval = backEndServerURL + "/deferral/approval";
        let appId = this.props.appId;
        let approval = "REJECT";
        let mbu_deferal = "NO";

        axios.post(deferalApproval, {
            appId: appId,
            approval: approval,
            mbu_deferal: mbu_deferal
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setVariable();
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };

    getFilesButtons() {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "HOMBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU_DFF") {
            return (
                <React.Fragment>

                    <Grid xs='12' style={{marginTop: "5px"}}></Grid>
                    {this.mappingPhoto()}
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderDeferalApprovalTableData()}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.renderRemarksData()
                            }
                        </Grid>
                    </ThemeProvider>

                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    {
                        this.renderRemarks()
                    }
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <Grid item xs={12} style={{marginTop: "5px", marginBottom: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-grey btn-sm"
                            style={{
                                color: '#ffffff',
                                background: '#808080',
                                border: '2px solid #808080',
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                            }}
                            onClick={this.returnHandler}
                        >
                            Return
                        </button>
                    </Grid>
                </React.Fragment>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "MOU") {
            return (
                <React.Fragment>

                    <Grid xs='12' style={{marginTop: "5px"}}></Grid>
                    {this.mappingPhoto()}
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderDeferalApprovalTableData()}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.renderRemarksData()
                            }
                        </Grid>
                    </ThemeProvider>

                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    {
                        this.renderRemarks()
                    }
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <Grid item xs={12} style={{marginTop: "5px", marginBottom: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={(e) => {
                                this.saveHandler(e, 'close')
                            }}
                        >
                            Done
                        </button>
                        <button
                            className="btn btn-grey btn-sm"
                            style={{
                                color: '#ffffff',
                                background: '#808080',
                                border: '2px solid #808080',
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                            }}
                            onClick={this.cancelOrReturnHandler}
                        >
                            Return
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={(e) => {
                                this.saveHandler(e, 'frm')
                            }}
                        >
                            Send To FRM
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                // marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={(e) => {
                                this.saveHandler(e, 'sandA')
                            }}
                        >
                            Send To S&A
                        </button>
                    </Grid>
                </React.Fragment>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "FRM") {
            return (
                <React.Fragment>

                    <Grid xs='12' style={{marginTop: "5px"}}></Grid>
                    {this.mappingPhoto()}
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderDeferalApprovalTableData()}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.renderRemarksData()
                            }
                        </Grid>
                    </ThemeProvider>
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    {
                        this.renderRemarks()
                    }
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <Grid item xs='3' style={{marginTop: "5px", marginBottom: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={(e) => {
                                this.saveHandler(e, 'frm', 'APPROVED')
                            }}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-grey btn-sm"
                            style={{
                                color: '#ffffff',
                                background: '#808080',
                                border: '2px solid #808080',
                                verticalAlign: 'TIDdle',
                            }}
                            onClick={this.cancelOrReturnHandler}
                        >
                            Return
                        </button>
                    </Grid>
                </React.Fragment>
            )
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") === "S_AND_A" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CARD_DIVISION") {
            return (
                <React.Fragment>

                    <Grid xs='12' style={{marginTop: "5px"}}></Grid>
                    {this.mappingPhoto()}
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderDeferalApprovalTableData()}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.renderRemarksData()
                            }
                        </Grid>
                    </ThemeProvider>
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    {
                        this.renderRemarks()
                    }

                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <Grid item xs='3' style={{marginTop: "5px", marginBottom: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'TIDdle',
                                marginRight: '10px',
                                // marginLeft: '10px'
                            }}
                            onClick={(e) => {
                                this.saveHandler(e, 'sandA', 'APPROVED')
                            }}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-grey btn-sm"
                            style={{
                                color: '#ffffff',
                                background: '#808080',
                                border: '2px solid #808080',
                                verticalAlign: 'TIDdle',
                            }}
                            onClick={this.cancelOrReturnHandler}
                        >
                            Return
                        </button>
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>

                    <Grid xs='12' style={{marginTop: "5px"}}></Grid>
                    {this.mappingPhoto()}
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>
                            {this.renderDeferalApprovalTableData()}
                        </Grid>
                        <Grid item xs={12}>
                            {
                                this.renderRemarksData()
                            }
                        </Grid>
                    </ThemeProvider>
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    {
                        this.renderRemarks()
                    }
                    <Grid xs='12' style={{marginTop: "0px"}}></Grid>
                    <Grid item xs='3' style={{marginTop: "5px", marginBottom: "10px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={(e) => {
                                this.saveHandler(e, 'mou_maker')
                            }}
                        >
                            Submit
                        </button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    dialogBoxShow() {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.successbloo}>
                    <DialogContent>
                        <ErrorModal title={this.state.notificationMessage} closeModal={this.closeSuccessModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }


    getFile() {
        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data,
                    getFileValue: true,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    mappingPhoto() {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={backEndServerURL + "/file/" + data + "/" + `${sessionStorage.getItem("accessToken")}`}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none",
                                    height: "20px"
                                }} target={"_blank"}>Preview Document {index + 1}</Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    }

    render() {
        const {classes, onClose} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.headerRender()}
                    </CardHeader>
                    <cardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.errorModalBoolean}>
                            <DialogContent className={classes.dialogPaper}>
                                <ErrorModal title={this.state.errorTittle}
                                            closeModal={this.closeModal}
                                            subServiceType={this.props.subServiceType}
                                            appId={this.props.appId}
                                />

                            </DialogContent>
                        </Dialog>
                        <Grid container style={{padding: "10px"}}>
                            <ThemeProvider theme={theme}>
                                {this.searchBody()}
                                {this.bodyRender()}
                                {this.renderNotification()}
                                {this.dialogBoxShow()}
                            </ThemeProvider>
                        </Grid>
                    </cardBody>
                </Card>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(MerchantMaintenance);
