
function makeConditionalGridObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));
        let returnObjectVariables = returnObject;
        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 4) {
                returnObjectVariables[i]["grid"] = 2;
            }
        }
        return returnObject;
    }
}
function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}

let waverRequestJsonForm = [

    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },

    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        required: true,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "loanOutstanding",
        "type": "textApiCall",
        "required": true,
        "label": "Loan Outstanding",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        type: "empty",
        grid: 12,
    },

];

let waverRequestReadOnlyForm = {};
waverRequestReadOnlyForm = makeReadOnlyObject(JSON.parse(JSON.stringify(waverRequestJsonForm)));

const nocMakerWaiverJsonForm = makeConditionalGridObject(JSON.parse(JSON.stringify(waverRequestReadOnlyForm.concat([

    {
        varName: "dateOfTheDelivery",
        type: "date",
        label: "Date of the Delivery",
        required: true,
        grid: 4,
    },
    {
        varName: "nocCheckerId",
        type: "text",
        label: "NOC Checker ID",
        required: true,
        onKeyDown: true,
        lowerCase: true,
        grid: 4,
    },

    //
    // {
    //     varName: "fileDeliveryStatus",
    //     type: "select",
    //     label: "NOC Status (File Delivery)",
    //     required: true,
    //     grid: 4,
    //     "enum": [
    //         "Under Process",
    //         "Ready to Deliver",
    //         "Delivered",
    //         "Acknowledgement",
    //         "Send Back to Archive",
    //     ]
    // },
    {
        varName: "chequeStatus",
        type: "select",
        label: "NOC ISSUING TYPE",
        required: true,
        "onKeyDown": true,
        grid: 4,
        "enum": [
            "With Cheque other then Auto Loan",
            "Without Cheque",
            "Without Cheque for Auto Loan",
            "With Cheque for Auto Loan",
            "With Security Details",
            "For Secured Loan",
        ]
    },

//With Cheque other then Auto Loan
    {
        varName: "nocIssueDate",
        type: "date",
        label: "NOC Issue Date",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "udcNo",
        type: "text",
        label: "UDC No.",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "bankNameUdc",
        type: "text",
        label: "BANK NAME (UDC)",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "pdcNo",
        type: "text",
        label: "PDC. No.",
        required: true,
        grid: 4,
        "conditional": true,
        conditionalVarName: "chequeStatus",
        conditionalVarValue: "With Cheque other then Auto Loan",
    },
    {
        varName: "bankNamePdc",
        type: "text",
        label: "BANK NAME (PDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque other then Auto Loan",
    },
    //Without Cheque for Auto Loan

    {
        varName: "customerTitle",
        type: "text",
        label: "Customer Title",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },

    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority ",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "engineNo",
        type: "text",
        label: "Engine No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    {
        varName: "ownerName",
        type: "text",
        label: "Owner Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "Without Cheque for Auto Loan",
    },
    //With Cheque for Auto Loan

    {
        varName: "nocIssueDate",
        type: "date",
        label: "NOC issue date",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },

    {
        varName: "customerTitle",
        type: "text",
        label: "Customer Title",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "udcNo",
        type: "text",
        label: "UDC No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "bankNameUdc",
        type: "text",
        label: "BANK NAME (UDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "pdcNo",
        type: "text",
        label: "PDC. No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "bankNamePdc",
        type: "text",
        label: "BANK NAME  (PDC)",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "brtaAuthority",
        type: "text",
        label: "BRTA Authority",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "chassisNo",
        type: "text",
        label: "Chassis No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "registrationNo",
        type: "text",
        label: "Registration No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "engineNo",
        type: "text",
        label: "Engine No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    {
        varName: "ownerName",
        type: "text",
        label: "Owner Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Cheque for Auto Loan",
    },
    //With Security Details
    {
        varName: "businessName",
        type: "text",
        label: "Business Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName":"chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "proprietorName",
        type: "text",
        label: "Proprietor Name",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "chequeNO",
        type: "text",
        label: "Cheque No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },
    {
        varName: "numOfSecurityDetails",
        type: "select",
        enum: ["1","2","3","4","5","6","7","8","9","10"],
        label: "Number of Security",
        required: true,
        "onKeyDown": true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "With Security Details",
    },

    //For Seacured Loan

    {
        varName: "issuingBranch",
        type: "text",
        label: "Issuing Branch",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "For Secured Loan",
    },
    {
        varName: "securityAcNo",
        type: "text",
        label: "Security A/C No.",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "For Secured Loan",
    },
    {
        varName: "dateOfissue",
        type: "date",
        label: "Date of Issue",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "For Secured Loan",
    },
    {
        varName: "securityAmount",
        type: "text",
        label: "Security Amount",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "For Secured Loan",
    },
    {
        varName: "securityStatus",
        type: "text",
        label: " Security Status",
        required: true,
        grid: 4,
        "conditional": true,
        "conditionalVarName": "chequeStatus",
        "conditionalVarValue": "For Secured Loan",
    },
    {
        type: "empty",
        grid: 12,
    },


]))));
const nocCheckerWaiverJsonFormReadOnly = makeReadOnlyObject(JSON.parse(JSON.stringify(nocMakerWaiverJsonForm)));

module.exports = {
    waverRequestJsonForm,
    waverRequestReadOnlyForm,
    nocMakerWaiverJsonForm,
    nocCheckerWaiverJsonFormReadOnly
};