import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid/index";
import withStyles from "@material-ui/core/styles/withStyles";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';

import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import AccountNoGenerate from "./AccountNoGenerate";
import GridList from "@material-ui/core/GridList";
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import MyValidation from "../../JsonForm/MyValidation";
import loader from "../../../Static/loader.gif";
import Functions from "../../../Common/Functions";
import ErrorModal from "../CommonComponent/ErrorModal";
import {FdrAndDpsTagForm} from "../WorkflowJsonForm4";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    button: {
        margin: theme.spacing(1),
    }
});

let jsonForm = [
    {
        "varName": "customerId",
        "type": "text",
        "label": "Customer Id",
        "grid": 6,
        "length": 9,


    },

    {
        "varName": "title",
        "type": "text",
        "label": "Title",
        "grid": 6,


        required: true,
    },

    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "length": 80,


    },

    {
        "varName": "shortName",
        "type": "text",
        "label": "Short Name",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "status",
        "type": "text",
        "label": "Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "statusAsOnDate",
        "type": "date",
        "label": "Status as on Date",
        "grid": 6
    },

    {
        "varName": "acManager",
        "type": "text",
        "label": "AC Manager",
        "grid": 6,


    },

    {
        "varName": "occupationCode",
        "type": "text",
        "label": "Occuoation Code",
        "grid": 6,


        required: true,
    },

    {
        "varName": "constitution",
        "type": "text",
        "label": "Constitution",
        "grid": 6,


        required: true,
    },

    {
        "varName": "gender",
        "type": "text",
        "label": "Gender",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffFlag",
        "type": "text",
        "label": "Staff Flag",
        "grid": 6,


        required: true,
    },

    {
        "varName": "staffNumber",
        "type": "text",
        "label": "Staff Number",
        "grid": 6,


        required: true,
    },

    {
        "varName": "minor",
        "type": "text",
        "label": "Minor",
        "grid": 6,


    },

    {
        "varName": "nonResident",
        "type": "text",
        "label": "Non Resident",
        "grid": 6,


        required: true,
    },

    {
        "varName": "trade",
        "type": "text",
        "label": "Trade",
        "grid": 6,


        required: true,
    },

    {
        "varName": "nationalIdCard",
        "type": "text",
        "label": "National ID Card",
        "grid": 6,


        required: true,
    },

    {
        "varName": "dateOfBirth",
        "type": "date",
        "label": "Date of Birth",
        "grid": 6,


    },

    {
        "varName": "introducerCustomerId",
        "type": "text",
        "label": "Introducer Customer Id",
        "grid": 6,
        "length": 9,

        required: true,
    },

    {
        "varName": "introducerName",
        "type": "text",
        "label": "Introducer Name",
        "grid": 6,


    },

    {
        "varName": "introducerStaff",
        "type": "text",
        "label": "Introducer Staff",
        "grid": 6,


    },

    {
        "varName": "maritialStatus",
        "type": "text",
        "label": "Maritial Status",
        "grid": 6,


        required: true,
    },

    {
        "varName": "father",
        "type": "text",
        "label": "Father",
        "grid": 6,


        required: true,
    },

    {
        "varName": "mother",
        "type": "text",
        "label": "Mother",
        "grid": 6,


        required: true,
    },

    {
        "varName": "spouse",
        "type": "text",
        "label": "Spouse",
        "grid": 6,


        required: true,
    },

    {
        "varName": "communicationAddress1",
        "type": "text",
        "label": "Communication Address1",
        "grid": 6,


    },

    {
        "varName": "communicationAddress2",
        "type": "text",
        "label": "Communication Address2",
        "grid": 6,


    },

    {
        "varName": "city1",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state1",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode1",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country1",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo11",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,
        "length": 11,

        required: true,
    },

    {
        "varName": "phoneNo21",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo1",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email1",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "permanentAddress1",
        "type": "text",
        "label": "Permanent Address1",
        "grid": 6,


    },

    {
        "varName": "permanentAddress2",
        "type": "text",
        "label": "Permanent Address2",
        "grid": 6,


    },

    {
        "varName": "city2",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state2",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode2",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country2",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo12",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo222",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo2",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email2",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "employerAddress1",
        "type": "text",
        "label": "Employer Address1",
        "grid": 6,


    },

    {
        "varName": "employerAddress2",
        "type": "text",
        "label": "Employer Address2",
        "grid": 6,


    },

    {
        "varName": "city3",
        "type": "text",
        "label": "City",
        "grid": 6,


        required: true,
    },

    {
        "varName": "state3",
        "type": "text",
        "label": "State",
        "grid": 6,


        required: true,
    },

    {
        "varName": "postalCode3",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,


    },

    {
        "varName": "country",
        "type": "text",
        "label": "Country",
        "grid": 6,


        required: true,
    },

    {
        "varName": "phoneNo13",
        "type": "text",
        "label": "Phone No1",
        "grid": 6,


    },

    {
        "varName": "phoneNo23",
        "type": "text",
        "label": "Phone No2",
        "grid": 6,


    },

    {
        "varName": "telexNo",
        "type": "text",
        "label": "Telex No",
        "grid": 6,


    },

    {
        "varName": "email3",
        "type": "text",
        "label": "Email",
        "grid": 6,

        email: true,

    },

    {
        "varName": "faxNo",
        "type": "text",
        "label": "Fax No",
        "grid": 6,


    },

    {
        "varName": "combineStatement",
        "type": "text",
        "label": "Combine Statement",
        "grid": 6,


    },

    {
        "varName": "tds",
        "type": "text",
        "label": "TDS",
        "grid": 6,


    },

    {
        "varName": "pangirNo",
        "type": "text",
        "label": "PANGIR No",
        "grid": 6,


    },

    {
        "varName": "passportNo",
        "type": "text",
        "label": "Passport No",
        "grid": 6,


    },

    {
        "varName": "issueDate",
        "type": "date",
        "label": "Issue Date",
        "grid": 6,


    },

    {
        "varName": "passportDetails",
        "type": "text",
        "label": "Passport Details",
        "grid": 6,


        required: true,
    },

    {
        "varName": "expiryDate",
        "type": "date",
        "label": "Expiry Date",
        "grid": 6,


    },

    {
        "varName": "purgedAllowed",
        "type": "text",
        "label": "Purged Allowed",
        "grid": 6,


    },

    {
        "varName": "freeText2",
        "type": "text",
        "label": "Free Text2",
        "grid": 6,


    },

    {
        "varName": "freeText5",
        "type": "text",
        "label": "Free Text 5",
        "grid": 6,
        "length": 10,


    },

    {
        "varName": "freeText8",
        "type": "text",
        "label": "Free Text 8",
        "grid": 6,


    },

    {
        "varName": "freeText9",
        "type": "text",
        "label": "Free Text 9",
        "grid": 6,


    },

    {
        "varName": "freeText13",
        "type": "text",
        "label": "Free Text13",
        "grid": 6,


    },

    {
        "varName": "freeText14",
        "type": "text",
        "label": "Free Text14",
        "grid": 6,


    },

    {
        "varName": "freeText15",
        "type": "text",
        "label": "Free Text15",
        "grid": 6,


    },

    {
        "varName": "freeCode1",
        "type": "text",
        "label": "Free Code1",
        "grid": 6,


    },

    {
        "varName": "freeCode3",
        "type": "text",
        "label": "Free Code3",
        "grid": 6,


    },

    {
        "varName": "freeCode7",
        "type": "text",
        "label": "Free Code 7",
        "grid": 6,


    },
];
let selectFileName = {
    "varName": "fileName",
    "type": "dropdown",
    "required": true,
    "label": "This photo name",

    "grid": 6
};

class SecondaryJointCbTagging extends Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        showValue: false,
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {tagList: {}},
        selectedDate: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getAllDefferal: [],
        getDeferalList: [],
        occupationCodeList: [],
        loading: true,
        jointAccountCustomerNumber: 0,
        objectForNominee: [],
        getgenerateForm: false,
        renderCumModalopen: false,
        generateAccountNo: '',
        getDedupData: {},
        jointDedupData: {},
        jointSearchTableData: [],
        propritorshipData: [],
        dedupData: [],
        tagClick: false,
        getTaggingList: {},
        getTaggingCustomerList: {},
        taggingData: [],
        relatedData: {},
        searchTableData: false,
        searchTableRelatedData: false,
        imageCropModal: false,
        errorModalBoolean: false,
        cbGenerateModal: false,
        getRemarks: [], err: false,
        errorArray: {},
        errorMessages: {},
        getMappingCropImage: false,
        nomineeNumber: 0,
        getNominee: false,
        errorMessage: "Invalid Code",
        type: "successs",
        city: [],
        dispatchMode: [],
        getCity: false,
        stateCode: [],
        getGuardianCode: [],
        savingsSchemeCodeList: [],
        currentSchemeCodeList: [],
        getState: false,
        country: [],
        titleList: [],
        chequebookWaiverType: [],
        rmCodeList: [],
        phoneEmailType: [],
        phoneOrEmail: [],
        getCountry: false,
        currency: [],
        relationshipList: [],
        constitutionList: [],
        customerStatusList: [],
        waiverFieldList: [],
        ccepCodeList: [],
        introducerStatus: [],
        relationCodeList: [],
        getCurrency: false,
        confirmAlert: false,
        actionType: "",
        despatchmodeList: [],
        sectorCodeList: [],
        subsectorCodeList: [],
        depositCodeList: [],
        tradeServiceList: [],
        agentBankingOutletList: [],
        subSegmentList: [],
        maritalStatusList: [],
        cbDataAfterSet: false,
        accountDataAfterSet: false,
        kycAutopopulateFlag: false,
        TpAutopopulateFlag: false,
        phoneNumCountryCodeList: [],
        documentList: [],
        getDocument: false,
        getnonResident: [],
        getfreecode7: [],
        employmentTypeList: [],
        placeOfIssueList: [],

    };

    constructor(props) {
        super(props);


    }


    updateComponent = () => {
        this.forceUpdate();
    };


    /*  close = () => {
          this.props.closeModal();
      }*/
    close = () => {
        this.props.closeModal("", "");
        this.setState({
            accountNo: false
        })
    };
    closeModal = (account) => {
        this.props.taging(this.props.tagingModalCbnumber);
        this.props.closeModal(account, "");
        this.setState({
            accountNo: false
        })
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        /*  for (let prop in clone)
              if (clone[prop] === '' || clone[prop] === ' '   || clone[prop] === "null"   ||  clone[prop] === "undefined" )
  delete clone[prop];*/
        return clone;
    };
    handleSubmit = (event) => {

        event.preventDefault();
        /* this.state.inputData={};
          this.state.varValue={}*/
        let inputData = this.emptyValueRemove(this.state.inputData);
        let varValue = this.emptyValueRemove(this.state.varValue);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        varValue.employerDataemploymentType = varValue.employerDataprofessionEmploymentType;

        let error = MyValidation.defaultValidation(this.emptyValueRemove(FdrAndDpsTagForm), this.state);
        this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");

        if (error === true) {
            return 0;

        }
        inputData.handleSubmitButton = true;
        let index = Number(this.props.tagingModalCbnumber) + 1;

        let object = {};
        object.appId = this.props.appId;
        if (this.props.editMode === true) {
            object.person = Number(this.props.tagingModalCbnumber) + 1;
            inputData.customer = Number(this.props.tagingModalCbnumber) + 1;
            if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {
                object.relatedPartyType = this.props.relatedPartyType;
                inputData.relatedPartyType = this.props.relatedPartyType;
                object.person = "MANDATE";
                inputData.customer = "MANDATE"
            } else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
                object.relatedPartyType = this.props.relatedPartyType;
                inputData.relatedPartyType = this.props.relatedPartyType;
                object.person = "BENEFICIAL";
                inputData.customer = "BENEFICIAL"
            } else {
                object.relatedPartyType = "JOINT";
                inputData.relatedPartyType = "JOINT";

            }
            inputData.relationCode = this.state.inputData.relatedCbTaggingrelationCode.value;//yes

            /*  if(this.props.generateAccountNo==="NEW"){


              }
              else if(this.props.cbNumber["tagging"+index]!==undefined){
                  object.person = this.props.tagingModalCbnumber+1;
              }
              else{
                  object.person =this.props.tagingModalCbnumber+1;
              }*/
            inputData.editMode = true;
            inputData.requestCharacter = "true";
            inputData.cbNumber = this.props.cbNumber


        } else {
            object.person = this.props.customerNumber;
            inputData.customer = this.props.customerNumber;

        }
        inputData.person = object.person;
        object.appData = inputData;
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });


        let urlsecondaryCB = backEndServerURL + "/secondaryCB";
        object.requestType = "Tag";
        object.requestCharacter = "true";
        if (this.props.checkerEnd === true) {
            object.requestType = "Completed";
        }
        object.cb = this.props.cbNumber;
        axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.props.closeModal();
                this.props.taging(this.props.cbNumber);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    errorMessage: "Finacle System Error!",
                    errorModalBoolean: true
                })
            })


    };
    handleSave = (event) => {

        event.preventDefault();
        /* this.state.inputData={};
          this.state.varValue={}*/
        let inputData = this.emptyValueRemove(this.state.inputData);
        let varValue = this.emptyValueRemove(this.state.varValue);
        inputData.employerDataemploymentType = inputData.employerDataprofessionEmploymentType;
        varValue.employerDataemploymentType = varValue.employerDataprofessionEmploymentType;

        // let error = MyValidation.defaultValidation(this.emptyValueRemove(CBDataJsonFormNew), this.state)
        // this.forceUpdate();
        console.log(this.state.inputData);

        console.log("Not working");


        inputData.handleSubmitButton = true;
        let index = Number(this.props.tagingModalCbnumber) + 1;

        let object = {};
        object.appId = this.props.appId;
        if (this.props.editMode === true) {
            object.person = Number(this.props.tagingModalCbnumber) + 1;
            inputData.customer = Number(this.props.tagingModalCbnumber) + 1;
            if (this.props.relatedPartyType === "Mandate" || this.props.relatedPartyType === "MANDATE" || this.props.relatedPartyType === "Mandate1" || this.props.relatedPartyType === "MANDATE1") {
                object.relatedPartyType = this.props.relatedPartyType;
                inputData.relatedPartyType = this.props.relatedPartyType;
                object.person = "MANDATE";
                inputData.customer = "MANDATE"
            } else if (this.props.relatedPartyType === "Beneficial" || this.props.relatedPartyType === "BENEFICIAL" || this.props.relatedPartyType === "Beneficial1" || this.props.relatedPartyType === "BENEFICIAL1") {
                object.relatedPartyType = this.props.relatedPartyType;
                inputData.relatedPartyType = this.props.relatedPartyType;
                object.person = "BENEFICIAL";
                inputData.customer = "BENEFICIAL"
            } else {
                object.relatedPartyType = "JOINT";
                inputData.relatedPartyType = "JOINT";

            }
            inputData.relationCode = this.state.inputData.relatedCbTaggingrelationCode.value;//yes

            /*  if(this.props.generateAccountNo==="NEW"){


              }
              else if(this.props.cbNumber["tagging"+index]!==undefined){
                  object.person = this.props.tagingModalCbnumber+1;
              }
              else{
                  object.person =this.props.tagingModalCbnumber+1;
              }*/
            inputData.editMode = true;
            inputData.requestCharacter = "true";
            inputData.cbNumber = this.props.cbNumber


        } else {
            object.person = this.props.customerNumber;
            inputData.customer = this.props.customerNumber;

        }
        inputData.person = object.person;
        object.appData = inputData;
        console.log(this.state.inputData);
        this.setState({
            loading: true,
        });


        let urlsecondaryCB = backEndServerURL + "/secondaryCB";
        object.cb = this.props.cbNumber;
        axios.post(urlsecondaryCB, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.props.closeModal();
                this.props.taging();
                this.setState({
                    loading: false,
                })
                //this.props.taging(this.props.tagingModalCbnumber, this.props.cbNumber);
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    errorMessage: "Finacle System Error!",
                    errorModalBoolean: true
                })
            })


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "System Error!"
                }
            } else {
                return "System Error!"
            }
        } else {
            return "System Error!"
        }
    };
    renderImage = () => {
        if (this.state.getDocument) {
            // return (
            //     <SecondaryCB customerNumber={this.props.customerNumber} closeModal={this.props.closeModal}
            //                  documentList={this.state.documentList}
            //     />
            // )
            return (<VerifyMakerPhoto documentList={this.state.documentList}/>)
        }
    };


    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeS", response.data)
                    //  this.findByVarNameApiData("passportNumberdocumentTypeP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeB", response.data)
                    // this.findByVarNameApiData("chairmanCertificatedocumentTypeCC", response.data)
                    // this.findByVarNameApiData("etindocumentType", response.data)
                    this.findByVarNameApiData("photoIddocumentType", response.data)

                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    // this.findByVarNameApiData("natioanlIdCarddocumentTypeDescriptionN", response.data)
                    //  this.findByVarNameApiData("smartIdCarddocumentTypeDescriptionS", response.data)
                    // this.findByVarNameApiData("passportNumberdocumentTypeDescriptionP", response.data)
                    //  this.findByVarNameApiData("birthCertificatedocumentTypeDescriptionB", response.data)
                    //  this.findByVarNameApiData("chairmanCertificatedocumentTypeDescriptionCC", response.data)
                    //  this.findByVarNameApiData("etindocumentTypeDescription", response.data)
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    componentDidMount() {
        this.setState({
            loading: true
        });
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            });
        {
            this.dynamicApiCall("designationCode", "designationCode")
        }
        {
            this.dynamicApiCall("relationCode", "relationCode")
        }
        {
            this.dynamicApiCall("relationType", "relationType")
        }
        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("lllllllllllllllllpppp");
                console.log(response.data);
                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                this.setState({
                    occupationCodeList: response.data
                })
                //this.findByVarNameApiData("customersOccupation", response.data)

            })
            .catch((error) => {
                console.log(error)
            });

        var remarksArray = [];


        if (this.props.appId !== undefined) {
            this.setState({
                loading: true
            });
            let url = backEndServerURL + '/variables/' + this.props.appId;

            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (this.props.editMode === true) {
                        let inputData = {};
                        let varValue = {};

                        let url = backEndServerURL + "/cbsCustomerGet/" + this.props.cbNumber;
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                inputData = this.emptyValueRemove(response.data);
                                varValue = this.emptyValueRemove(response.data);
                                let dataInputs = this.apiDataSet(response.data, inputData);
                                let datavarValues = this.apiDataSet(response.data, varValue);
                                setTimeout(() => {
                                    this.setState({
                                        inputData: dataInputs,
                                        varValue: datavarValues,
                                        showValue: true,
                                        tagData: true,
                                        getData: true,
                                        loading: false

                                    });
                                }, 500)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    getData: true,
                                    loading: false
                                })
                            })
                    } else {
                        let inputData = {};
                        let varValue = {};

                        let variablesUrl = backEndServerURL + "/secondaryCB/" + this.props.appId + "/" + this.props.customerNumber;
                        axios.get(variablesUrl,
                            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                console.log(response.data);
                                inputData = this.emptyValueRemove(response.data);
                                varValue = this.emptyValueRemove(response.data);
                                this.setState({
                                    inputData: inputData,
                                    varValue: varValue,
                                    showValue: varValue,
                                    getData: true,
                                    loading: false
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                            })

                    }
                })
                .catch((error) => {
                    this.setState({
                        getData: true
                    })
                })


        }


    }

    minorFlagCalculate = (basicInformationUpdatedob) => {
        this.state.inputData.basicInformationUpdatedobApiCall = "NO";
        this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
        this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
        //this.updateComponent()
        //if(data.value==="basicInformationUpdatedob"){
        setTimeout(() => {
            let x = 18;
            var currentDate = new Date();

            let someDate = new Date();
            let numberOfDaysToAdd = x;
            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
            let date = someDate.getDate();
            let month = someDate.getMonth() + 1;
            let year = someDate.getFullYear();
            let convertDate = year + "-" + month + "-" + date;
            let newDate = new Date(convertDate);
            let stringToDate = new Date(newDate.toDateString());

            let inputDate = new Date(basicInformationUpdatedob);

            if (stringToDate >= inputDate) {
                this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
            } else {
                this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
            }

            x = 60;
            currentDate = new Date();
            someDate = new Date();
            numberOfDaysToAdd = x;
            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
            date = someDate.getDate();
            month = someDate.getMonth() + 1;
            year = someDate.getFullYear();
            convertDate = year + "-" + month + "-" + date;
            newDate = new Date(convertDate);
            stringToDate = new Date(newDate.toDateString());

            inputDate = new Date(basicInformationUpdatedob);
            if (stringToDate >= inputDate) {
                this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


            } else {

                this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "N"
            }

            this.forceUpdate()

        }, 1000)

    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = FdrAndDpsTagForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];

            if (jsonObject.varName === searchVarname) {
                FdrAndDpsTagForm[i].enum = getValue;

            }
        }


    };
    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        console.log(value);
        console.log(getKeyValue);


        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            console.log(jsonObject);
            if (value === jsonObject.key) {
                return jsonObject;
                i = jsonArray.length;

            }
        }


    };
    /*  apiDataSet = (data, inputData, accountRelated) => {
          if (data !== undefined && data !== null && data !== "") {
              if(accountRelated==="New Customer"){

                   if (this.props.relatedPartyType === "Mandate") {
                      //inputData.d=mandatecbNumber
                      inputData.miscustomerNameLastName=data.mandatecustomerName
                      inputData.nationalIdCardNumberUniqueId=data.mandatenid
                      inputData.smartCardNumberUniqueId=data.mandatesmartCard
                      inputData.passportNumberpassportNumberUniqueId=data.mandatepassport
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.mandateregistrationNo
                      //inputData.d=mandateDrivingLicense
                      inputData.basicInformationUpdatedob=data.mandatedob
                      //inputData.d=mobileTypeMandate
                      //inputData.d=countryCodeMandate
                      inputData.contactNumberphoneNo1=data.mandatephone
                      inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.mandateemail
                      //inputData.d=mandatetin
                      //inputData.d=mandatenationality

                  }
                  if (this.props.relatedPartyType === "Beneficiary") {
                      //inputData.d=beneficiarycbNumber
                        inputData.miscustomerNameLastName=data.beneficiarycustomerName
                      inputData.nationalIdCardNumberUniqueId=data.beneficiarynid
                      inputData.smartCardNumberUniqueId=data.beneficiarysmartCard
                      inputData.passportNumberpassportNumberUniqueId=data.beneficiarypassport
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.beneficiaryregistrationNo
                      //inputData.d=beneficiaryDrivingLicense
                      inputData.basicInformationUpdatedob=data.beneficiarydob
                      //inputData.d=mobileTypeBeneficiary
                      //inputData.d=countryCodeBeneficiary
                      inputData.contactNumberphoneNo1=data.beneficiaryphone
                       inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.beneficiaryemail
                      //inputData.d=beneficiarytin
                      //inputData.d=beneficiarynationality

                  }
                  else {
                  /!*     let i=(this.props.tagingModalCbnumber)-1
                     // inputData.d=inputData.cbNumber + i
                   inputData.miscustomerNameLastName=data.customerName0
                      inputData.nationalIdCardNumberUniqueId=data.nid0
                      inputData.smartCardNumberUniqueId=data.smartCard + i
                      inputData.passportNumberpassportNumberUniqueId=data.passport + i
                      inputData.birthCertificatebirthCertificateNumberUniqueId=data.registrationNo + i
                      //inputData.d=inputData.drivingLicense
                  /!*    if((data.dob + i)!==undefined && (data.dob + i)!==null){
                          inputData.basicInformationUpdatedob=data.dob + i
                      }*!/


                       inputData.contactNumberphoneNo1=data.phone + i
                       inputData.contactNumberEmaildesignatedEmailAddressEmailId1=data.email + i*!/
                     // inputData.d=inputData.tin + i
                     // inputData.d=inputData.nationality + i
                  }

              }
              else{
                  inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall=true
                  inputData.hasTheBeneficialOwnerOfTheAccountBeenIdentified=true
                  inputData.passportNumberpassportNumberUniqueIdApiCall=true
                  inputData.nationalIdCardNumberUniqueIdApiCall=true
                  inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall=true
                  inputData.electronicTaxIdApiCall=true
                  inputData.missolId = data.branchCode;
                  inputData.misprimarySolId = data.branchCode;
                  inputData.basicInformationUpdateprimarySolId = data.branchCode;
                  inputData.miscustomerNameLastName = data.customerName
                  inputData.misshortName = data.shortName
                  inputData.misaCNumber = data.accountNumber
                  inputData.customerAccount = data.accountNumber
                  inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList)
                  inputData.aCTitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList)
                  inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus,this.state.customerStatusList)
                  inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList)
                  inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode
                  inputData.misfunctionCodeForChequeBookWaiver = "A"
                  inputData.aof1currencyCodeCcy = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency)
                  inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency)
                  inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T"

                  inputData.basicInformationUpdategender = data.gender;
                  inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                  inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                  inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                  inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                  inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                  inputData.basicInformationUpdatedob = data.birthDate;
                  let birthDate=data.birthDate
                  if (birthDate && birthDate !== -1) {
                      let date = (birthDate).split('T')[0].split("-");
                      inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`
                      /!* this.state.inputData.basicInformationUpdatedobApiCall = "NO";
                       this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                       this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                       this.updateComponent()
                       Functions.sleep(500).then(() => {
                           let x = 18;
                           var currentDate = new Date();
                           let someDate = new Date();
                           let numberOfDaysToAdd = x
                           someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                           let date = someDate.getDate();
                           let month = someDate.getMonth() + 1;
                           let year = someDate.getFullYear();
                           let convertDate = year + "-" + month + "-" + date;
                           let newDate = new Date(convertDate);
                           let stringToDate = new Date(newDate.toDateString());

                           let inputDate = new Date(`${date[1]}/${date[2]}/${date[0]}`);

                           if (stringToDate <= inputDate) {
                               this.state.inputData.basicInformationUpdatedobApiCall = "YES"
                               this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                               this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                           } else {
                               this.state.inputData.basicInformationUpdatedobApiCall = "YES"
                               this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N"
                               this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                           }

                           x = 60;
                           currentDate = new Date();
                           someDate = new Date();
                           numberOfDaysToAdd = x
                           someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                           date = someDate.getDate();
                           month = someDate.getMonth() + 1;
                           year = someDate.getFullYear();
                           convertDate = year + "-" + month + "-" + date;
                           newDate = new Date(convertDate);
                           stringToDate = new Date(newDate.toDateString());

                           inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                           if (stringToDate >= inputDate) {
                               this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "Y"
                               this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "Y"
                           } else {
                               this.state.inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                               this.state.varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                           }
                           this.forceUpdate()

                       })
   *!/
                  }
                  inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                  inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                  inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

                  inputData.primryintroducerCustomerIdCifId = data.introducerCifId;


                  inputData.natioanlIdCarddocumentTypeN = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };

                  inputData.natioanlIdCarddocumentTypeDescriptionN = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  }
                  inputData.natioanlIdCarddocumentCodeN = {
                      "masterDataName": "documentCode",
                      "key": "NID",
                      "value": "NID CARD IDENTITY"
                  }
                  //Passport
                  inputData.passportNumberdocumentTypeP = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.passportNumberdocumentTypeDescriptionP = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };

                  inputData.passportNumberdocumentCodeP = {
                      "masterDataName": "documentCode",
                      "key": "PASSPORT",
                      "value": "PASSPORT IDENTITY"
                  };

                  inputData.smartIdCarddocumentTypeS = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.smartIdCarddocumentTypeDescriptionS = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.smartIdCarddocumentCodeS = {
                      "masterDataName": "documentCode",
                      "key": "RSNID",
                      "value": "RSNID"
                  };

                  inputData.birthCertificatedocumentTypeB = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.birthCertificatedocumentTypeDescriptionB = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.birthCertificatedocumentCodeB = {
                      "masterDataName": "documentCode",
                      "key": "BC",
                      "value": "BIRTH CERTIFICATE"
                  };
                  inputData.chairmanCertificatedocumentTypeCC = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  inputData.chairmanCertificatedocumentCodeCC = {
                      "masterDataName": "documentCode",
                      "key": "COMC",
                      "value": "COMMISSIONER CERTIFICATE"
                  };

                  inputData.etindocumentType = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.etindocumentTypeDescription = {
                      "masterDataName": "documentCodeDocumentType",
                      "key": "IDENTIFICATION PROOF",
                      "value": "IDENTIFICATION PROOF"
                  };
                  ;
                  inputData.etindocumentCode = {
                      "masterDataName": "documentCode",
                      "key": "ETIN",
                      "value": "TIN CARD IDENTITY"
                  }
                  inputData.contactNumberEmailpreferedEmailIdType = {
                      "masterDataName": "emailType",
                      "key": "COMMEML",
                      "value": "COMMEML"
                  }
                  inputData.contactNumberEmailemailType1 = {
                      "masterDataName": "emailType",
                      "key": "COMMEML",
                      "value": "COMMEML"
                  }
                  inputData.contactNumberEmailphoneNoEmailId4 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "EMAIL",
                      "value": "EMAIL"
                  }
                  inputData.contactNumberpreferredContactNoType1 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH1",
                      "value": "COMMPH1"
                  }
                  inputData.contactNumbercontactType1 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH1",
                      "value": "COMMPH1"
                  }
                  inputData.contactNumberphoneNoEmailId1 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  }
                  inputData.contactNumbercontactType2 = {
                      "masterDataName": "contactType",
                      "key": "COMMPH2",
                      "value": "COMMPH2"
                  };
                  inputData.contactNumberphoneNoEmailId2 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  };
                  inputData.contactNumbercontactType3 = {
                      "masterDataName": "contactType",
                      "key": "WORKPH1",
                      "value": "WORKPH1"
                  };
                  inputData.contactNumberphoneNoEmailId3 = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "PHONE",
                      "value": "PHONE"
                  };
                  //inputData.contactNumberphoneNo3=data. ;

                  inputData.contactNumberEmailemailType = {
                      "masterDataName": "emailType",
                      "key": "HOMEEML",
                      "value": "HOMEEML"
                  };
                  inputData.contactNumberEmailphoneNoEmailId = {
                      "masterDataName": "phoneOrEmailId",
                      "key": "EMAIL",
                      "value": "EMAIL"
                  };
                  inputData.employerDataprofessionEmploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.employerDataemploymentType = {
                      "masterDataName": "employmentType",
                      "key": "MEDIA CELEBRITY",
                      "value": "MEDIA CELEBRITY"
                  };
                  inputData.maillingAdresspreferredAddressType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.maillingAdressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.maillingAdressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.maillingAdressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Mailing",
                      "value": "Mailing"
                  };
                  inputData.homeAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.homeAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Home",
                      "value": "Home"
                  };
                  inputData.homeAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Home",
                      "value": "Home"
                  };
                  inputData.workAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };
                  inputData.workAddressaddressType = {
                      "masterDataName": "addressType",
                      "key": "Work",
                      "value": "Work"
                  };
                  inputData.workAddressaddressType = {
                      "masterDataName": "addressType",
                      "key": "Work",
                      "value": "Work"
                  };
                  inputData.presentAddressaddressFormat = {
                      "masterDataName": "addressFormat",
                      "key": "FREE_TEXT_FORMAT",
                      "value": "FREE_TEXT_FORMAT"
                  };

                  inputData.presentAddressaddrssType = {
                      "masterDataName": "addressType",
                      "key": "Future/OnSite",
                      "value": "Future/OnSite"
                  };
                  if (data.NIDBLOCK !== undefined) {


                      inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                      inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                      inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                      let natioanlIdCardissueDateN=data.NIDBLOCK.issueDt
                      if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                          let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                          inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                      }
                  }


                  if (data.RSNIDBLOCK !== undefined) {

                       inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                      inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.RSNIDBLOCK.isDocumentVerified;
                      let smartIdCardissueDateS=data.RSNIDBLOCK.issueDt
                      if (smartIdCardissueDateS && smartIdCardissueDateS !== -1) {
                          let date = (smartIdCardissueDateS).split('T')[0].split("-");
                          inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                      }
                  }

                  if (data.PASSPORTBLOCK !== undefined) {

                      inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                      inputData.passportNo = data.PASSPORTBLOCK.referenceNum;
                      inputData.passportNumberplaceOfIssue = data.PASSPORTBLOCK.isDocumentVerified;
                      let passportNumberissueDate=data.PASSPORTBLOCK.issueDt
                      if (passportNumberissueDate && passportNumberissueDate !== -1) {
                          let date = (passportNumberissueDate).split('T')[0].split("-");
                          inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                      }
                      inputData.passportNumbercountryOfIssue = data.PASSPORTBLOCK.countryOfIssue;
                  }


                  //inputData.passportNumberexpiryDate=data. ;
                  if (data.BCBLOCK !== undefined) {

                      inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                      inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                  }
                  if (data.chairmanCertificate !== undefined) {

                      inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                  }
                  // inputData.chairmanCertificateplaceOfBirthFreeText14=data. ;
                  //inputData.chairmanCertificatecountryOfBirthFreeText15=data. ;
                  //  inputData.chairmanCertificatenationality=data. ;
                  // inputData.chairmanCertificatenonResident=data. ;
                  // inputData.chairmanCertificateturnedNonResidentOn=data. ;
                  if (data.ETINBLOCK !== undefined) {

                      inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                  }
                  if (data.nameOfEmployer !== undefined) {

                      inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                  }
                  if (data.COMMEMLBLOCK !== undefined) {
                      //inputData.employerDatamonthlyIncomeGrossIncome=data. ;

                      inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                  }
                  if (data.COMMPH1BLOCK !== undefined) {

                      inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNum
                  }
                  if (data.COMMPH2BLOCK !== undefined) {

                      inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNum;
                  }
                  if (data.HOMEEMLBLOCK !== undefined) {


                      inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                  }
                  if (data.MailingBLOCK !== undefined) {


                      inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                      inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                      inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                      inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                      inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                      inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                  }
                  if (data.HomeBLOCK !== undefined) {

                      inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                      inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                      inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);
                      ;
                      inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                      inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                      inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                  }
                  if (data.WorkBLOCK !== undefined) {

                      inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                      inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                      inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                      inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                      inputData.postalCode = data.WorkBLOCK.postalCode;
                      inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.countryCode, this.state.country);

                  }
                  if (data.FutureOnSiteBLOCK !== undefined) {

                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                }
                 if (data.NRERelativeBLOCK !== undefined) {

                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }
              }

          }



          return inputData;
      }*/
    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {
            inputData.miscustomerIdCifId = this.props.cbNumber;//yes
            inputData.miscustomerNameLastName = data.customerName;//yes
            inputData.mappingData = true


        }


        return inputData;
    };
    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(fieldName);
                console.log(response);
                if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)//yes

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);//yes
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)//yes

                }

            })
            .catch((error) => {
                console.log(error);
            })
    };
    renderSubmitButton = () => {
        console.log(this.props.hideButton);
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSave}
                    >Save
                    </button>
                </div>
            )
        }
    };
    onKeyDownChange = (data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;


                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "fincoreDataUpdatesectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData.fincoreDataUpdatesectorCode["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    {
                        this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    }
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()


                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName == "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            //this.updateComponent()
            //if(data.value==="basicInformationUpdatedob"){
            setTimeout(() => {
                let x = 18;
                var currentDate = new Date();

                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                currentDate = new Date();
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"


                } else {

                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }

                this.forceUpdate()

            }, 1000)

        }
        /*    for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                if (data === "nomineecifId" + i) {
                    this.setState({
                        loading: true
                    })
                    this.state.inputData["nomineecifIdApiCall"+i] = false;
                    this.state.varValue["nomineecifIdApiCall"+i] = false;
                             console.log(response.data)
                             let x = 60;
                            let currentDate = new Date();
                            let someDate = new Date();
                            let numberOfDaysToAdd = x
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year +"-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());

                            let inputDate = new Date(response.data.birthDate);
                            this.state.inputData["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            this.state.varValue["nomineeDob" + i]=(response.data.birthDate).split("T")[0]
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + i] = "YES";
                                this.state.varValue["nomineeMinor" + i] = "YES";

                                // this.state.varValue["nomineeMinor" + i] == "NO";
                            } else {

                                this.state.inputData["nomineeMinor" + i] = "NO";
                                this.state.varValue["nomineeMinor" + i] = "NO";

                                //this.state.varValue["nomineeMinor" + i] == "YES";
                            }

                            /!*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                            this.state.inputData["nomineeGuardianCode" + i] =response.data.
                            this.state.inputData["guardianPhotoId" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                            this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                            this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                            this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                           this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                          this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
    *!/
                            this.state.inputData["nomineecifIdApiCall"+i] = true;
                            this.state.varValue["nomineecifIdApiCall"+i] = true;
                            this.forceUpdate()

                            console.log(this.state.inputData)


                }
            }*/


    };
    onKeyDownForDedup = (event, data) => {
        for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {

            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });

                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);

                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 18;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";


                        }

                        /*this.state.inputData["nomineeGuardiansName" + i] =response.data.
                        this.state.inputData["nomineeGuardianCode" + i] =response.data.
                        this.state.inputData["guardianPhotoId" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress" + i] =response.data.
                        this.state.inputData["nomineeGuardianAddress2" + i]=response.data.
                        this.state.inputData["nomineeGuardianCityCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.city)
                        this.state.inputData["nomineeGuardianStateCode" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.state)
                       this.state.inputData["nomineeGuardianPostalCode" + i]=response.data.
                      this.state.inputData["nomineeGuardianCountry2" + i]=this.findByVarNameGetKeyValue(response.data.,this.state.country)
*/
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();

                        console.log(this.state.inputData);
                        this.setState({
                            //varValue: this.state.varValue,
                            //inputData: this.state.inputData,
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,


                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.mispriorityMonitoringRmCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.primryintroducerCustomerIdCifId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Introducer ID",

                    })
                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorTittle: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.guardianDetailsgurdianCifNumberCustomer;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    renderJsonform = () => {
        if (this.state.getData && this.props.checkerEnd === true) {
            return (
                <ThemeProvider theme={theme}>
                    <GridList cellHeight={300} cols={1}>
                        <div>
                            <Grid container>
                                {CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(FdrAndDpsTagForm), this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)}

                            </Grid>
                        </div>


                    </GridList>
                </ThemeProvider>

            )
        } else if (this.state.getData && (this.props.makerEnd === true || this.props.FdrDps === true)) {
            return (
                <ThemeProvider theme={theme}>
                    <GridList cellHeight={300} cols={1}>
                        <div>
                            <Grid container>
                                {CommonJsonFormComponent.renderJsonForm(this.state, FdrAndDpsTagForm, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)}

                            </Grid>
                        </div>


                    </GridList>
                </ThemeProvider>


            )
        }

    };
    closeModalError = () => {
        this.setState({
            errorModalBoolean: false
        })
    };

    render() {
        const {classes} = this.props;

        return (

            <div>

                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} ><b>{this.props.relationCode !== undefined ? this.props.relationCode : ""}</b><p
                            style={{fontSize: "16px"}}>(<b> Customer
                            Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                            <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                            <b> CB Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}
                            <b> SOLID:</b>{this.props.solId !== undefined ? this.props.solId : ""} <b> Scheme
                                Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""})<a><CloseIcon
                                onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={1}>

                            <Grid item xs={8}>

                                {this.renderImage()}

                            </Grid>


                            <Grid item xs={4}>


                                <React.Fragment>
                                    <br/>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="md"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.accountNo}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <AccountNoGenerate taging={this.props.taging}
                                                               tagingModalCbnumber={this.props.tagingModalCbnumber}
                                                               customerName={this.state.customerName}
                                                               solId={this.state.solId}
                                                               closeModal={this.closeModal}/>

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="xl"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.errorModalBoolean}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ErrorModal title={this.state.errorMessage}
                                                        closeModal={this.closeModalError}
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                            />

                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    {this.renderJsonform()}


                                    <div>

                                        <br/>
                                        <br/>
                                        <center>
                                            {
                                                this.renderSubmitButton()
                                            }
                                        </center>

                                    </div>

                                </React.Fragment>


                            </Grid>


                        </Grid>
                    </CardBody>
                </Card>
            </div>

        )
    }

}

export default withStyles(styles)(SecondaryJointCbTagging);
