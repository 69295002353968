import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import Label from "./Label";
import CardBody from "../../../Card/CardBody";
import Card from "../../../Card/Card";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import PreviewDocument from "../PreviewButton";
import SignatureButton from "../SignatureButton";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import MyValidation from "../../../JsonForm/MyValidation";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "required": true,
        "grid": 12
    }];
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}
];


var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};


class MakerInbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            values: [],
            customerName: [],
            loading: true,
            objectForNominee: [],
            fileUploadData: {},
            errorModalBoolean: false,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            type: "successs",
            previewDocument: false,
            getInitialNewNominee: 0,
            objectForExistingNominee: [],
            objectForInitialNewNominee: [],
            city: [],
            stateCode: [],
            country: [],
            guardianCode: [],
            relationCode: [],
            nomineeNumber: 0,
            getNominee: false,
            existingObjectForNominee: [],
            existingNominee: false,
            oldNomineeData: [],
        };
    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = response.data;
                    if (response.data.dpsCloserRequest === "DPS Nominee Change" && parseInt(response.data.nomineeNumberOld)) {
                        let sl;
                        let objectForExistingNominee = [];
                        for (let i = 0; i < parseInt(inputData.nomineeNumberOld); i++) {
                            sl = i + 1;
                            console.log(inputData["nomineecifId" + sl]);
                            let cifIdExist = inputData["nomineecifId" + sl] !== undefined;
                            console.log(cifIdExist);
                            // let cifIdExist = false;
                            inputData["nomineecifIdApiCall" + sl] = true;
                            let x = 18;
                            let someDate = new Date();
                            let numberOfDaysToAdd = x;
                            someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year + "-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());
                            let inputDate = new Date(inputData["nomineeDob" + sl]);
                            if ((stringToDate >= inputDate) || inputData["nomineeDob" + sl] === "null") {
                                inputData["nomineeDobApiCall" + sl] = false;
                            } else {
                                inputData["nomineeDobApiCall" + sl] = true;
                            }

                            objectForExistingNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID/Individual Id",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "validation": "string",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": [],
                                    "enumType": "relationCode",
                                    "label": "Relationship",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address Field 1",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address Field 2",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "City",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": cifIdExist,
                                    "enumType": "city",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "State",
                                    "grid": 6,
                                    "required": true,
                                    "readOnly": cifIdExist,
                                    "enumType": "state",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "required": true,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "required": true,
                                    "enumType": "country",
                                    "enum": [],
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                // {
                                //     "varName": "nomineenomineephotoIdNo" + i,
                                //     "type": "text",
                                //     "label": "NOMINEE PHOTO ID NO.",
                                //     "grid": 6,
                                //     "multiline": true,
                                //     "conditional": true,
                                //     "conditionalVarName": "nomineeChange",
                                //     "conditionalVarValue": true
                                // },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id No/Registration No.",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "onKeyDown": true,
                                    "maxDate": true,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "deleteOldNominee" + sl,
                                    "type": "select",
                                    "enum": ["Y", "N"],
                                    "label": "Delete This Nominee",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true]
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "readOnly": cifIdExist,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": cifIdExist,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "readOnly": cifIdExist,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["DPS Nominee Change", true],
                                    "required": true,
                                },
                            )

                        }
                        setTimeout(() => {
                            this.setState({
                                oldNomineeData: objectForExistingNominee,
                                oldNominee: true,
                            });
                        }, 1000)
                    }

                    if (response.data.dpsCloserRequest === "DPS Nominee Change" && parseInt(inputData.numberOfNominee) > 0) {
                        var sl;
                        let newSl;
                        let objectForNominee = [];
                        let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                        for (var i = 0; i < parseInt(inputData.numberOfNominee); i++) {
                            newSl = i + 1;
                            sl = oldNominee + (i + 1);
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "required": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": [],
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                /*  {
                                        "varName": "nomineenomineephotoIdNo" + i,
                                        "type": "text",
                                        "label": "NOMINEE PHOTO ID NO.",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "nomineecifIdApiCall" + i,
                                        "conditionalVarValue": true,
                                    },
                    */
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    /*    "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "CIF ID",*/
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    /*  "conditional": true,
                                      "conditionalVarName": "search" + sl,
                                      "conditionalVarValue": "INFORMATION",*/
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": [],
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": [],
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": [],
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": [],
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )

                        }
                        this.setState({
                            existingObjectForNominee: objectForNominee,
                            existingNominee: true,
                            getInitialNewNominee: response.data.numberOfNominee ? response.data.numberOfNominee : 0,
                        });
                    }

                    setTimeout(() => {
                        if (response.data.dpsCloserRequest === "DPS Nominee Change") {
                            this.dynamicApiCallForNomineeUpdate("city", "city");
                            this.dynamicApiCallForNomineeUpdate("state", "state");
                            this.dynamicApiCallForNomineeUpdate("country", "country");
                            this.dynamicApiCallForNomineeUpdate("guardianCode", "guardianCode");
                            this.dynamicApiCallForNomineeUpdate("relationCode", "nomineeRelationCode");
                        }
                    }, 1000);

                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    this.setState({
                        getData: true,
                        varValue: this.removeNullValue(response.data),
                        inputData: this.removeNullValue(response.data),
                        showValue: true,
                        // loading: false
                    });

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }


    }

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }


    };

    dynamicApiCallForNomineeUpdate = (enumType, apiType) => {
        let jsonForm = this.state.oldNomineeData.concat(this.state.existingObjectForNominee, this.state.objectForNominee);
        let apiUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    console.log(jsonForm);
                    for (let i = 0; i < jsonForm.length; i++) {
                        let jsonObject = jsonForm[i] ? jsonForm[i] : {};
                        if (jsonObject.enumType === enumType) {
                            jsonObject.enum = response.data;
                        }
                    }
                    if (enumType === "city") {
                        this.setState({
                            city: response.data
                        });
                    }
                    if (enumType === "state") {
                        this.setState({
                            stateCode: response.data
                        })
                    }
                    if (enumType === "guardianCode") {
                        this.setState({
                            guardianCode: response.data
                        })
                    }
                    if (enumType === "relationCode") {
                        this.setState({
                            relationCode: response.data
                        })
                    }
                    if (enumType === "country") {
                        this.setState({
                            country: response.data
                        })
                    }
                    // this.setState({
                    //     getNominee: true,
                    // });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(this.state.objectForNominee, this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        } else {
            if (this.state.inputData.titleChange && this.state.fileUploadData.scanningFile !== undefined) {
                let fileUploadPath = backEndServerURL + "/case/upload";
                let types = 'Attachments';
                let files = this.state.fileUploadData.scanningFile;
                let formData = new FormData();
                formData.append("appId", this.props.appId);
                formData.append("file", files);
                formData.append("type", types);
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }


            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.makerRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error)
                });

            this.state.inputData.maker_update_all_info_send_to = "CHECKER";

            this.state.inputData.makerRemarks = undefined;
            this.state.inputData.maker_approval = "APPROVED";
            var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
            axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    var url = backEndServerURL + "/case/route/" + this.props.appId;

                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                            });
                            this.props.closeModal()
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };


    handleSubmitReturn = (event) => {
        event.preventDefault();
        var percentage = 0;

        //this.state.inputData.next_user = this.state.inputData.cs_send_to;
        this.state.inputData.maker_approval = "RETURN";

        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, {maker_approval: "RETURN"}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var url = backEndServerURL + "/case/route/" + this.props.appId;

                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            title: "Successfull!",
                            notificationMessage: "Successfully Routed!",
                            alert: true
                        });
                        this.props.closeModal()

                    })
                    .catch((error) => {
                        console.log(error);

                    });
            })
            .catch((error) => {
                console.log(error)
            });
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };


    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label stopLoading={this.stopLoading}
                           accountNumber={this.state.inputData.accountNumber}
                           style={{margin: "0 auto"}} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber}/>
                    <br/>

                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>&nbsp;
                        <PreviewDocument appId={this.props.appId} classes={this.props}/>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </Grid>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
                    }

                </React.Fragment>
            )
        }
    };


    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    renderFileUpload = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>
            )
        }
    };

    renderButton = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleSubmitReturn}
                    >Return
                    </button>
                </div>

            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}

                </div>
            )
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && this.state.oldNominee && this.state.oldNomineeData.length > 0) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.oldNomineeData, this.updateComponent, this.onKeyDownForExistingNominee, this.onKeyChangeForExistingNominee)
            )

        }
    };

    onKeyChangeForExistingNominee = (data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        for (let i = 0; i < oldNominee; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    console.log(stringToDate);
                    console.log(inputDate);
                    console.log(stringToDate >= inputDate);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }
    };

    onKeyDownForExistingNominee = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;

        for (let i = 0; i < oldNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true,
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                            this.state.inputData["nomineeGuardiansName" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCode" + (i + 1)] = "";
                            this.state.inputData["guardianPhotoId" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianAddress" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianAddress2" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCityCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianStateCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianPostalCode" + (i + 1)] = "";
                            this.state.inputData["nomineeGuardianCountry2" + (i + 1)] = "";
                        }
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: error.response.data.message,
                            errorModalBoolean: true,
                        })
                    })

            }
        }
    };

    onKeyDownForDedup = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["search" + (i + 1)] = this.state.inputData["search" + (i + 1)];
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let currentDate = new Date();
                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        }
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        console.log(this.state.inputData);
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })

            }
        }
    };

    onKeyDownChange = (data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data.varName === "search" + (i + 1)) {
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["nomineecifId" + (i + 1)] = "";
                this.state.varValue["nomineecifId" + (i + 1)] = "";
                this.state.inputData["nomineeName" + (i + 1)] = "";
                this.state.inputData["nomineeAddress1" + (i + 1)] = "";
                this.state.inputData["nomineeAddress2" + (i + 1)] = "";
                this.state.inputData["nomineeCityCode" + (i + 1)] = "";
                this.state.inputData["nomineeStateCode" + (i + 1)] = "";
                this.state.inputData["nomineePostalCode" + (i + 1)] = "";
                this.state.inputData["nomineeCountry" + (i + 1)] = "";
                this.state.inputData["percentageNominee" + (i + 1)] = "";
                this.state.inputData["nomineeRelationship" + (i + 1)] = "";
                this.state.varValue["percentageNominee" + (i + 1)] = "";
                this.state.varValue["nomineeRelationship" + (i + 1)] = "";
                this.state.inputData["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeName" + (i + 1)] = "";
                this.state.varValue["nomineeAddress1" + (i + 1)] = "";
                this.state.varValue["nomineeAddress2" + (i + 1)] = "";
                this.state.varValue["nomineeCityCode" + (i + 1)] = "";
                this.state.varValue["nomineeStateCode" + (i + 1)] = "";
                this.state.varValue["nomineePostalCode" + (i + 1)] = "";
                this.state.varValue["nomineeCountry" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.inputData["nomineeMinor" + (i + 1)] = "";
                this.state.varValue["nomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let currentDate = new Date();
                    let someDate = new Date();
                    let numberOfDaysToAdd = x;
                    someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    console.log(stringToDate, inputDate);
                    console.log(stringToDate >= inputDate);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }
                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })
            }
        }
    };

    renderNomineeForm = () => {
        if (this.state.objectForNominee.length > 0 && this.state.getNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    renderNewNomineeForm = () => {
        if (this.state.existingObjectForNominee.length > 0 && this.state.existingNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.existingObjectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNominee"] = parseInt(this.state.getInitialNewNominee) + parseInt(option.title);
            this.forceUpdate();
            if (option.title > 0) {
                var sl;
                let newSl;
                let objectForNominee = [];
                let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
                for (var i = 0; i < option.title; i++) {
                    newSl = i + 1;
                    sl = oldNominee + parseInt(this.state.getInitialNewNominee) + (i + 1);
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "Nominee  " + sl,
                            "grid": 12,
                        },
                        {
                            "varName": "search" + sl,
                            "type": "select",
                            "enum": [
                                "CIF ID",
                                "INFORMATION",
                            ],
                            "required": true,
                            "onKeyDown": true,
                            "label": "Input CIF ID/INFORMATION ?",
                            "grid": 6,


                        },
                        {
                            "varName": "nomineecifId" + sl,
                            "type": "textApiCall",
                            "label": "CIF ID",
                            "grid": 6,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",
                        },

                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",

                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "required": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],


                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },

                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        /*  {
                                "varName": "nomineenomineephotoIdNo" + i,
                                "type": "text",
                                "label": "NOMINEE PHOTO ID NO.",
                                "grid": 6,
                                "conditional": true,
                                "conditionalVarName": "nomineecifIdApiCall" + i,
                                "conditionalVarValue": true,
                            },
            */
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "text",
                            "label": "D.O.B(YYYY-MM-DD)",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 6,
                            "onKeyDown": true,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },


                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            /*    "conditional": true,
                                "conditionalVarName": "search" + sl,
                                "conditionalVarValue": "CIF ID",*/
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            /*  "conditional": true,
                              "conditionalVarName": "search" + sl,
                              "conditionalVarValue": "INFORMATION",*/
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,


                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },
                    )

                }
                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true
                });
            }
        } else {
            let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
            let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
            for (let i = 0; i < totalNominee; i++) {
                this.state.inputData["nomineeSearch" + (i + 1)] = false;
                this.state.varValue["nomineeSearch" + (i + 1)] = false;
            }
            this.state.inputData["numberOfNominee"] = parseInt(this.state.getInitialNewNominee);
            this.setState({
                objectForNominee: [],
                getNominee: false
            });
            this.forceUpdate();
        }
    };


    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of New Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={this.state.inputData["numberOfNominee"]} options={numberOfNominee}
                                      getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"
                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderNomineeUpdateForm = () => {
        if (this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            return (
                <>
                    {this.renderExistingNomineeForm()}
                    {this.renderNewNomineeForm()}
                    <Grid item xs={12}><br/></Grid>
                    <Grid item xs={6}>
                        {this.renderNomineeNumber()}
                    </Grid>
                    {this.renderNomineeForm()}
                </>
            )
        }
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Card>
                        <CardBody>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                    {this.renderNomineeUpdateForm()}
                                </ThemeProvider>
                            </Grid>
                            <ThemeProvider theme={theme}>
                                {this.renderFileUpload()}
                                {this.renderRemarksData()}
                                <br/>
                                {this.viewAttachedImages()}
                                {this.renderRemarks()}
                            </ThemeProvider>
                            {this.renderButton()}
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(MakerInbox);


