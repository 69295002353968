import React, {Component} from "react";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import CardHeader from "../../Card/CardHeader";
import CloseIcon from '@material-ui/icons/Close';
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import AbhChecker from "../AGENT/AbhChecker";
import {AbhCheckerJsonFormForAgentIndividual} from '../WorkflowJsonForm4';

let initiateBondrequest = [
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 12,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 12
    },
    {
        "varName": "dob",
        "type": "date",
        "label": "Date Of Birth",
        "grid": 12

    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "Nominee Date Of Birth",
        "grid": 12
    },
    {
        "varName": "amount",
        "type": "text",
        "label": "Amount",
        "grid": 12
    }
];
let comments = [
    {
        "varName": "other",
        "type": "text",
        "label": "Comments",
        "grid": 12,
    },

];
let SearchFormIndividual = [

    {
        "varName": "nid",
        "type": "text",
        "label": "NID",
        "grid": 6,

    },
    {
        "varName": "passport",
        "type": "text",
        "label": "Passport",
        "grid": 6,


    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "required": true,
        "grid": 6,

    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "email": true,
        "grid": 6,

    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone Number",
        "required": true,
        "grid": 6,


    },

    {
        "varName": "tin",
        "type": "text",
        "label": "eTin",
        "grid": 6,

    },


    {
        "varName": "registrationNo",
        "type": "text",
        "label": "Birth Certificate/Driving License",
        "grid": 6,

    },
    {
        "varName": "nationality",
        "type": "text",
        "label": "Nationality",
        "required": true,
        "grid": 6,


    },];
let SearchFormNonIndividual = [


    {
        "varName": "companyName",
        "type": "text",
        "label": "Company Name",
        "required": false,
        "readOnly": false,
        "grid": 6


    },

    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "required": false,
        "email": true,
        "readOnly": false,
        "grid": 6


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Phone",
        "required": false,
        "grid": 6

    },

    {
        "varName": "companyEtin",
        "type": "text",
        "label": "Company ETin",
        "required": false,
        "grid": 6


    },
    {
        "varName": "tradeLicense",
        "type": "text",
        "label": "Trade License",
        "required": false,
        "readOnly": false,
        "grid": 6


    },
    {
        "varName": "certificate",
        "type": "text",
        "label": "Certificate Of Incorporation",
        "required": false,
        "grid": 6


    }


];
let SearchFormJoint = [

    {
        "varName": "cbNumber",
        "type": "text",
        "label": "Cb Number",
        "grid": 6


    },
    {
        "varName": "accountSource",
        "type": "text",
        "label": "Account Source",
        "grid": 6


    },
    {
        "varName": "accountTitle",
        "type": "text",
        "label": "Account TItle",
        "grid": 6


    },
    {
        "varName": "email",
        "type": "text",
        "label": "Email",
        "grid": 6,

        "email": true,


    },

    {
        "varName": "phone",
        "type": "text",
        "label": "Mobile Number",
        "required": true,
        "grid": 6


    }
];

class CheckerInboxCase extends Component {
    state = {
        sendTo: false,

        documentList: [],
        getDocument: false

    };

    constructor(props) {
        super(props);


    }


    componentDidMount() {
        let fileUrl = backEndServerURL + "/case/files/" + this.props.appUid;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} serviceType={this.props.serviceType}
                                  subServiceType={this.props.subServiceType}
                                  commonJsonForm={SearchFormIndividual}
                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)

    };


    agent = () => {

        if (this.props.serviceType === 'Account Opening') {

            return (<AbhChecker closeModal={this.props.closeModal} serviceType={this.props.serviceType}
                                subServiceType={this.props.subServiceType}
                                titleName="Checker Update All Information"
                                commonJsonForm={AbhCheckerJsonFormForAgentIndividual}

                                appId={this.props.appUid}/>)
        }
    };

    inboxCase = () => {
        if (this.props.serviceType === "Account Opening") {
            return (
                this.agent()
            )
        }


    };
    close = () => {
        this.props.closeModal();
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >{this.props.serviceType}<a><CloseIcon onClick={this.close} style={{
                            position: 'absolute',
                            right: 10,
                            color: "#000000"
                        }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container spacing={1}>
                            <Grid item xs={9}>
                                {this.inboxCasePhoto()}

                            </Grid>
                            <Grid item xs={3}>
                                {this.inboxCase()}
                            </Grid>


                        </Grid>
                    </CardBody>
                </Card>
            </div>


        )
    }

}

export default CheckerInboxCase;