import React, {Component} from "react";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import axios from "axios/index";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import DebitCardCheckerSplit from "./DebitCardCheckerSplit";
import SignatureButton from "../../workflow/Maintenance/SignatureButton";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import loader from "../../../Static/loader.gif";
import Table from "../../Table/Table";
import Functions from "../../../Common/Functions";
import MyValidation from "../../JsonForm/MyValidation";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let pdfForm = [
    {
        varName: "source",
        type: "select",
        enum: ["Finacel","Ababil"],
        label: "Source",
        grid: 2,
        readOnly: true
    },
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerName",
        type: "text",
        label: "Customer Name",
        grid: 2,
        readOnly: true,
        multiline: true
    },
    {
        varName: "nameOnCard",
        type: "text",
        label: "Name on Card",
        multiline: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "accountStatus",
        type: "text",
        label: "Account Status",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerType",
        type: "text",
        label: "Customer Type",
        grid: 2,
        readOnly: true
    },
    {
        varName: "schemeCode",
        type: "text",
        label: "Scheme Code",
        grid: 2,
        readOnly: true
    },
    {
        varName: "cbNumber",
        type: "text",
        label: "CB Number",
        grid: 2,
        readOnly: true
    },
    {
        varName: "accountType",
        type: "text",
        label: "Account Type",
        grid: 2,
        readOnly: true
    },
    {
        varName: "currency",
        type: "text",
        label: "Currency",
        grid: 2,
        readOnly: true
    },
    {
        varName: "rfcdConfirmation",
        type: "select",
        enum: [
            "RFCD",
            "Foreign Currency"
        ],
        label: "RFCD/ FCY Confirmation",
        grid: 2,
        readOnly: true,
        conditional: true,
        conditionalVarName: "showRfcd",
        conditionalVarValue: true
    },
    {
        varName: "rmCode",
        type: "text",
        label: "RM Code",
        grid: 2,
        readOnly: true,
    },
    {
        varName: "rmName",
        type: "text",
        label: "RM Name",
        multiline: true,
        conditional: true,
        conditionalVarName: "showRmName",
        conditionalVarValue: true,
        readOnly: true,
        grid: 2,
    },
    {
        varName: "casaSolId",
        type: "text",
        label: "CASA SOL ID",
        grid: 2,
        readOnly: true
    },
    {
        varName: "customerEmail",
        type: "text",
        label: "Email",
        multiline: true,
        readOnly: true,
        // required: true,
        grid: 2,
    },
    {
        varName: "customerMobile",
        type: "text",
        label: "Mobile",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "deliveryType",
        type: "select",
        label: "Delivery Type",
        readOnly: true,
        grid: 2,
        enum: ["Branch"]
    },

    {
        varName: "branchName",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Branch Name",
        readOnly: true,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Branch",
        grid: 2,
    },
    {
        varName: "customerAddress",
        type: "text",
        label: "Customer Address",
        multiline: true,
        readOnly: true,
        grid: 2,
        conditional: true,
        conditionalVarName: "deliveryType",
        conditionalVarValue: "Courier",
    },
    {
        varName: "cardType",
        type: "select",
        label: "Card Type",
        readOnly: true,
        grid: 2,
        errorMessage: "Error",
        enum: ["VISA DEBIT CARD", "MASTER CARD DEBIT CARD", "CITY MAXX DEBIT CARD"]
    },
    {
        varName: "productType",
        type: "select",
        enum: [],
        label: "Product Type",
        readOnly: true,
        grid: 2
    },
    {
        varName: "otherCardAvailed",
        type: "text",
        label: "Other card availed? (Y/N)",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "ccep",
        type: "text",
        label: "CCEP",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportNumber",
        type: "text",
        label: "Passport Number",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportExpiryDate",
        type: "date",
        label: "Passport Exp Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssueDate",
        type: "date",
        label: "Passport Issue Date",
        readOnly: true,
        grid: 2,
    },
    {
        varName: "passportIssuePlace",
        type: "text",
        label: "Passport Issue Place",
        readOnly: true,
        multiline: true,
        grid: 2,
    },
    {
        varName: "applicationSource",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Application Source",
        readOnly: true,
        grid: 2,
    },
];
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }]

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};

class DebitCardSplitInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: [],
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            searchValue: true,
            appId: 0,
            getMappingAllImage: false,
            fileUploadData: {},
            uploadModal: false,
            preview: false,
            pastDocumentModal: false,
            documentList: [],
            selectedDate: {},
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            getDocument: false,
            getData: false,
            cbNotFound: false,
            loading: true,
            getRemarks: [],
            disabled: false,


        };
    }
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined" ||
                clone[prop] === null
            )
                delete clone[prop];
        return clone;
    };

    getVariables() {
        let url = backEndServerURL + "/variables/" + this.props.appId;
        axios
            .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {


                let remarksArray = [];
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((Remarkresponse) => {
                        Remarkresponse.data.map((data) => {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            varValue: this.copyJson(response.data),
                            inputData: this.copyJson(response.data),
                            getRemarks: remarksArray,
                            showValue: true,
                            getData: true,
                            loading: false
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    getImageLink: response.data,
                    getImageBoolean: true,
                    imageName: response.data,
                })
            })
            .catch((error) => {
                console.log(error);
            });
        this.getVariables();
    }

    inboxCase = () => {
        return (
            <DebitCardCheckerSplit
                closeModal={this.props.closeModal}
                documentList={this.state.documentList}
                appId={this.props.appId}
                form={this.renderFormWithData}
            />
        );
    };

    approveHandler = event => {
        this.setState({
            disabled: true
        });
        event.preventDefault();

        let url = backEndServerURL + "/variables/" + this.props.appId;
        let approval={};
        if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
            approval.call_center_checker_approval="APPROVED";
        }
        else{
            approval.bom_approval= "APPROVED";
        }

        axios
            .post(url, approval, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let DeliveryBranchIfo = this.state.inputData.branchName;
                let postData = this.state.inputData;
                postData.appId = this.props.appId;
                postData.branchName = DeliveryBranchIfo.value;
                postData.deliverBranchSolId = DeliveryBranchIfo.key;

                let routeTaskUrl="";
                routeTaskUrl = backEndServerURL + "/debitcardrequisitionsave";
                axios
                    .post(routeTaskUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then(response => {
                        let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                        axios
                            .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then(response => {
                                this.setState({
                                    disabled: false,
                                    alert: true,
                                    title: "Successful!",
                                    notificationMessage: "Successfully Routed!",
                                });

                                this.props.closeModal()
                            })
                            .catch(error => {
                                console.log(error);
                                this.setState({
                                    disabled: false,
                                    cbNotFound: true,
                                    title: "Case Route Failed!",
                                });
                            });
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({
                            disabled: false,
                            cbNotFound: true,
                            title: "Debit Card Save Failed!",
                        });
                    });
            })
            .catch(error => {
                console.log(error);
            });
    };

    returnHandler = event => {

        event.preventDefault();
        let dependencyField = [
            {
                varName: "bomRemarks",
                type: "textArea",
                required: true
            }
        ];
        let error = MyValidation.defaultValidation(dependencyField, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        else{
            this.setState({
                loading: true
            });

            let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    Functions.loginRedirection(error);
                });

            this.state.inputData.bomRemarks = undefined;

            let url = backEndServerURL + "/variables/" + this.props.appId;

            let approval={};
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_CHECKER"){
                approval.call_center_checker_approval="RETURN";
            }
            else{
                approval.bom_approval= "RETURN";
            }
            axios
                .post(url, approval, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                    axios
                        .get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then(response => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                            });

                            this.props.closeModal()
                        })
                        .catch(error => {
                            console.log(error);
                        });
                })
                .catch(error => {
                    console.log(error);
                });
        }


    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Document {index + 1}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    signature = () => {
        if (this.state.getData) {
            return (
                <>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                                     classes={this.props}/>
                </>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderRemarksData = () => {

        console.log(this.state.getRemarks.length)

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)

            )
        }
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            {CommonJsonFormComponent.renderJsonForm(
                                this.state,
                                pdfForm,
                                this.updateComponent
                            )}
                            <Grid item xs={12}>
                                {this.signature()}
                            </Grid>
                            {this.mappingPhoto()}

                            <Grid item xs={12}>
                                {this.renderRemarksData()}
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderRemarks()}
                            </Grid>
                        </ThemeProvider>
                    </Grid>


                    <Grid item xs="3" style={{marginTop: "20px"}}>
                        {/*<center>*/}
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            disabled={this.state.disabled}
                            onClick={this.approveHandler}
                        >
                            Approve
                        </button>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.returnHandler}
                        >
                            Return
                        </button>
                    </Grid>

                    {this.renderNotification()}
                </div>
            );
        }
    }


}

export default withStyles(styles)(DebitCardSplitInbox);
