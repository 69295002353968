import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import ShowDocuments from "./ShowDocuments";


class PreviewButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            showValue: true,
        }
    }


    componentDidMount() {

        console.log(this.props.appId)

    }

    preview = () => {
        this.setState({
            preview: true
        })
    };

    closePreview = () => {
        this.setState({
            preview: false
        })
    };

    render() {
        const {classes} = this.props.classes;
        return (

            <div>
                <button
                    onClick={this.preview}
                    className="btn btn-outline-primary btn-sm">
                    Preview All Document
                </button>


                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.preview}>
                    <DialogContent className={classes.dialogPaper}>
                        <ShowDocuments closeModal={this.closePreview} appId={this.props.appId}
                                       title={'Preview Document'}/>
                    </DialogContent>
                </Dialog>
            </div>

        );
    }


}

export default PreviewButton;