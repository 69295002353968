import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import Functions from '../../../Common/Functions';
import GridList from "@material-ui/core/GridList/index";
import Grid from "@material-ui/core/Grid/index";
import CommonJsonFormComponent from '../../JsonForm/CommonJsonFormComponent';
import GridItem from "../../Grid/GridItem.jsx";
import Table from "../../Table/Table";
import axios from 'axios/index';
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import MyValidation from "../../JsonForm/MyValidation";

const MerchantjsonFormReadOnly = [
    {
        "label": "Merchant type",
        "type": "text",
        "varName": "merchantType",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "Merchant Name",
        "type": "text",
        "varName": "merchantName",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "Proprietor Name",
        "type": "text",
        "varName": "proprietorName",
        "grid": 12,
        "readOnly": true
    },
    {
        "varName": "dob",
        "type": "date",
        "label": "DOB",
        "grid": 12,
        // "readOnly" : true
    },
    {
        "label": "NID",
        "type": "text",
        "varName": "nid",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "Passport",
        "type": "text",
        "varName": "passport",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "TIN",
        "type": "text",
        "varName": "tin",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "Nationality",
        "type": "select",
        "varName": "nationality",
        "grid": 12,
        "enum": [
            "Bangladesh",
            "Japan",
            "India"
        ],
        "readOnly": true
    },

    {
        "label": "Mobile Number",
        "type": "text",
        "varName": "mobileNumber",
        "grid": 12,
        "readOnly": true
    },

    {
        "label": "Email",
        "type": "text",
        "varName": "email",
        "grid": 12,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "Birth Certificate",
        "type": "text",
        "varName": "birthCertificate",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "Address",
        "type": "text",
        "varName": "address",
        "grid": 12,
        "multiline": true,
        "readOnly": true
    },
    {
        "label": "DST Name",
        "type": "text",
        "varName": "dstName",
        "grid": 12,
        "readOnly": true
    },
    {
        "label": "DST ID",
        "type": "text",
        "varName": "dstID",
        "grid": 12,
        "readOnly": true
    },
];

let checkerRemarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "required": true,
        "grid": 12
    }];


const getPassForm = [
    {
        "varName": "getPassType",
        "type": "select",
        "enum": [
            "Delivered",
            "Undelivered"
        ],
        "grid": 6,
        "label": "Select Get Pass Type",
        "required": true
    },
    {
        "varName": "deliveredDate",
        "type": "date",
        "grid": 6,
        "label": "Delivered Date",
        "required": true,
        "conditional": true,
        "conditionalVarName": "getPassType",
        "conditionalVarValue": "Delivered"
    }
];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class splitApprovalForGatePass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            sendTo: false,
            documentList: [],
            getDocument: false,
            selectedDate: {},
            agreementSignUpDate: "",
            remarks: false,
            getRemarkList: [],
            loading: true
        };


    }

    modalClose = () => {
        this.props.closeModal();
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {
        //console.log(this.props.appId)


        let url = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.remarks = null;
                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    getData: true,
                    loading: false
                });

                let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {

                        this.setState({
                            // images: response.data,
                            documentList: response.data,
                            getDocument: true
                        });

                        let defferalUrl = backEndServerURL + "/case/deferral/" + this.props.appId;

                        axios.get(defferalUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                console.log(response.data);
                                if (response.data == '') {
                                    console.log('empty')
                                } else {
                                    let deferealData = [];

                                    response.data.map((deferal) => {

                                        var status;
                                        if (deferal.status === "ACTIVE") {
                                            status = "Approved"
                                        } else if (deferal.status === "APPROVAL_WAITING") {
                                            status = "NOT APPROVED"
                                        } else {
                                            status = "Not Approved";
                                        }

                                        deferealData.push(this.createTableForDeferal(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))
                                    });

                                    this.setState({
                                        getDeferalList: deferealData,
                                        defferalData: true
                                    })
                                }

                            })
                            .catch((error) => {
                                console.log(error)
                            })

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            });


        let remarksUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;

        axios.get(remarksUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (response.data.length > 0) {
                    let remarkData = [];


                    response.data.map((res) => {

                        remarkData.push(this.createTableForRemarks(res.remarks, res.createByUserName, res.applicationRemarksDate, res.createByUserRole))

                    });

                    this.setState({
                        getRemarkList: remarkData,
                        remarks: true
                    });

                    console.log(this.state.getRemarkList)
                } else {
                    this.setState({
                        remarks: false
                    })
                }


            })
            .catch((error) => {
                console.log(error)
            })
    }


    createTableForRemarks = (remarks, name, a, b) => {
        return ([
            remarks, name, a, b
        ])
    };

    renderRemarksTable = () => {

        if (this.state.getRemarkList.length > 0) {
            return (
                <div style={{marginBottom: '20px', marginTop: '20px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '20px 10px 10px'}}>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarkList}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>
            )
        }
    };


    createTableForDeferal = (id, type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status) => {
        return ([
            type, category,subCategory,dueDate, appliedBy, applicationDate, dstId,rmCode,status
        ])
    };

    renderDeferalApprovalTableData = () => {

        if (this.state.defferalData === true && this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: '10px'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: "10px"}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderFormWithData = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, MerchantjsonFormReadOnly, this.updateComponent)
        }
    };

    renderRemarks = () => {

        return (

            CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent)

        )
    };

    submitHandler = () => {


        let error = MyValidation.defaultValidation(getPassForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        }

        let variablesUrl = backEndServerURL + "/variables/" + this.props.appId;

        let postData = this.state.inputData;
        if (this.props.taskTitle === "get_pass" && this.state.inputData.getPassType === "Delivered") {
            postData.delivery_successful = "YES"
        } else if (this.props.taskTitle === "get_pass" && this.state.inputData.getPassType === "Undelivered") {
            postData.delivery_successful = "NO"
        } else if (this.props.taskTitle === "undelivered" && this.state.inputData.getPassType === "Undelivered") {
            postData.deliver_again = "NO";
            postData.delivery_successful = "NO"
        } else if (this.props.taskTitle === "undelivered" && this.state.inputData.getPassType === "Delivered") {
            postData.deliver_again = "YES";
            postData.delivery_successful = "YES";
        }

        axios.post(variablesUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                let caseRouteUrl = backEndServerURL + "/case/route/" + this.props.appId;
                axios.get(caseRouteUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log('Case Route Successful');
                        this.modalClose();

                        this.setState({
                            title: "Successfull!",
                           // notificationMessage: "Approved Successfully!",
                            notificationMessage: "Successfully Routed!",
                            alert: true

                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            });

        if (this.state.inputData.remarks !== null) {
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    //console.log(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    render() {
        console.log('....SplitApproval......');
        console.log(this.props.appId);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        if (this.state.getData) {
            return (
                <GridList cellHeight={window.innerHeight} cols={1}>
                    <GridItem xs={12} sm={12} md={12}>
                        <Grid container>
                            {
                                this.renderFormWithData()
                            }

                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, getPassForm, this.updateComponent)
                            }
                            {
                                this.renderDeferalApprovalTableData()
                            }
                            {
                                this.renderRemarksTable()
                            }
                            {
                                this.renderRemarks()
                            }
                            {
                                this.renderNotification()
                            }
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: '10px',
                                    marginTop: '10px'
                                }}

                                onClick={this.submitHandler}

                            >
                                Submit
                            </button>

                        </Grid>
                    </GridItem>
                </GridList>

            )

        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        }
    }


}

export default withStyles(styles)(splitApprovalForGatePass);
