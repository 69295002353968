import Table from "../Table/Table.jsx";
import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};


class GroupingApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGroup: false,
            addMember: false,
            deactiveMember: false,
            tableData: [[" ", " "]],
            groupId: "",
            memberId: "",
            groupData: [[" ", " "]],
            open: true,
            searchTableData: false,
            groupingDTO: {
                "membersId": [],
                "status": "NOTAPPROVED"
            },
            redirectLogin: false,
        }
    }

    handleChangeCheckbox = membersId => event => {

        if (event.target.checked === true) {
            this.state.groupingDTO.membersId = this.state.groupingDTO.membersId.concat(membersId);
        } else {
            for (let i = 0; i < membersId.length; i++) {

                var index = this.state.groupingDTO.membersId.indexOf(membersId[i]);
                if (index > -1) {
                    this.state.groupingDTO.membersId.splice(index, 1);
                }
            }

        }

        console.log(this.state.groupingDTO.membersId)

    };

    createTableData = (id, customerId, groupId, customerName, role, status, membersId) => {

        return ([<FormControlLabel
            control={
                <Checkbox onChange={this.handleChangeCheckbox(membersId)}/>
            }
        />, customerId, customerName, role, status,


        ]);


    };
    /*<div>
                       <button
                           className="btn btn-outline-primary"

                           onClick={() => this.approve(membersId,"ACTIVE")}>
                           Approve</button>&nbsp;
                       <button
                           className="btn btn-outline-primary"

                           onClick={() => this.approve(membersId,"NOTAPPROVED")}>
                           Not Approve</button>
                   </div> */

    /* approve = (membersId, status) => {
         const arrayData = [];
         arrayData.push({"membersId": membersId, "status": status});

         let url = backEndServerURL + "/grouping/approve";
         axios.post(url, arrayData[0]


             , {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
             .then((response) => {
                 console.log(response.data);


             })
             .catch((error) => {
                 console.log(error);
             })
         notification.open({
             message: 'Successfully ' + status
         });


     }*/

    /*   cancelSubSectorModal = () => {
           this.setState({addGroup: false});
       }*/
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    componentDidMount() {
        let url = backEndServerURL + "/grouping/getForApproval";

        const tableArray = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((groupMember) => {
                    let getAllCustomerName = groupMember.keyCustomerName;
                    groupMember.addMemberDTOS.map((memberName) => {
                        getAllCustomerName = getAllCustomerName + '\n' + memberName.customerName;
                    });

                    tableArray.push(this.createTableData(groupMember.id, groupMember.keyCustomerId, groupMember.groupUid, getAllCustomerName, groupMember.role, groupMember.status, groupMember.membersId));


                });
                this.setState({
                    tableData: tableArray,
                    searchTableData: true
                })

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
                this.setState({
                    tableData: tableArray
                })
            });


    }

    approveCheckboxData = (approve) => {


        this.state.groupingDTO.status = approve;


        let url = backEndServerURL + "/grouping/approve";
        axios.post(url, this.state.groupingDTO


            , {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(this.state.groupingDTO);
                this.setState({
                    groupingDTO: {
                        "membersId": [],
                        "status": "NOTAPPROVED"
                    },
                })

            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });
        notification.open({
            message: 'Successfully'
        });


    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <center>
                                    <h4 className={classes.cardTitleWhite}>Customer Group and Member Add Request</h4>
                                </center>
                            </CardHeader>
                            <CardBody>
                                <div>

                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Checkbox", "Key-CustomerId", "Key-Customer-Name", "Type", "Status"]}
                                        tableData={this.state.tableData}
                                        tableAllign={['left', 'left', 'right', 'right']}
                                    />
                                </div>
                                <br/>
                                <center>
                                    <div>
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{width: 120}}
                                            onClick={() => this.approveCheckboxData("ACTIVE")}
                                        >
                                            Approve
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{width: 120}}
                                            onClick={() => this.approveCheckboxData('NOTAPPROVED')}
                                        >
                                            Not Approve
                                        </button>
                                    </div>
                                </center>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>

            </section>
        );
    }
}

export default withStyles(styles)(GroupingApproval);
