import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import Notification from "../../NotificationMessage/Notification";
import 'semantic-ui-offline/semantic.min.css';
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";

let populateForm = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


class CreditCardChequeBookFileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileUploadData: {},
            showValue: true,
            title: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            notificationMessage: "",
            alert: false,
            showData: true,
            cbNotFound: false,
            loading: false
        };
    }

    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    uploadFile = () => {

        let error = MyValidation.fileValidation(populateForm[0], this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            const formData = new FormData();
            formData.append("file", this.state.fileUploadData['fileName']);

            axios({
                method: "post",
                url: backEndServerURL + "/creditCardChequebookFileUpload/add",
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    "content-type": "multipart/form-data"
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            title: "Successful",
                            notificationMessage: "Successfully File Uploaded",
                            alert: true,
                            loading: false
                        });
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.setState({
                            loading: false,
                            title: "Failed!",
                            notificationMessage: "Failed to Upload File",
                            alert: true
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        loading: false,
                        title: "Failed!",
                        notificationMessage: "Failed to Upload File",
                        alert: true
                    });
                });
        }
    };


    renderAllDialog = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            </>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >File Upload (Credit Card Cheque Book)</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}
                        {this.renderAllDialog()}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardBody>
                                        <div>
                                            {CommonJsonFormComponent.renderJsonForm(
                                                this.state,
                                                populateForm,
                                                this.updateComponent
                                            )}

                                            <br/>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                onClick={this.uploadFile}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(CreditCardChequeBookFileUpload);
