import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import CBNotFound from "../../CASA/CBNotFound";
import Table from "../../../Table/Table";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import MyValidation from "../../../JsonForm/MyValidation";
import Notification from "../../../NotificationMessage/Notification";
import CloseIcon from "@material-ui/icons/Close";
import SelectComponent from "../../../JsonForm/SelectComponent";
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";

var deferalOther = {
    "varName": "deferalOther",
    "type": "text",
    "label": "Others",
};

var deferal = {
    "varName": "deferalType",
    "type": "select",
    "label": "Deferral Type",
    "enum": [
        "Applicant Photograph",
        "Nominee Photograph",
        "Passport",
        "Address proof",
        "Transaction profile",
        "Others"
    ],

};

var date = {
    "varName": "expireDate",
    "type": "text",
    "minDate": true,
    "label": "Expire Date",

};

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let remarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

let jsonForm = [
    {
        varName: "requestType",
        type: "select",
        enum: [
            "Title Rectification",
            "Currency Add",
            "Trade Facilitation",
            "Staff Account Generation/Generalization/Block",
            "CTR Limit Update",
            "Temporary Dormant Activation",
            "Freeze/Unfreeze Mark",
            "Citytouch Tagging",
            "Priority Marking",
            "Unconfirmed Address Marking",
            "Deceased Marking",
            "SBS Code Update/Correction",
            "AMLD/Branch Support",
        ],
        label: "Request Type",
        grid: 2,
    },
];

const fileUpload = {
    "varName": "fileName",
    "type": "file",
    "accept": ".xlsx,xls,.pdf",
    "label": "Upload File",
    "grid": 12
};

class Others extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            appId: 0,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            varValue: {},
            inputData: {},
            loading: true,
            showValue: false,
            alert: false,
            cbNotFound: false,
            getRemarks: [],
            documentList: [],
            title: "",
            notificationMessage: "",
            values: [],
            getDeferalList: []
        };
    }


    componentDidMount() {
        if (this.props.taskTitle === "cs_maintenance" || this.props.taskTitle !== undefined) {
            jsonForm[0].readOnly = true
        }
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {

                        let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                        axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {

                                let tableArray = [];
                                var status = "";
                                response.data.map((deferal) => {
                                    if (deferal.status === "ACTIVE") {
                                        status = "Approved"
                                    } else if (deferal.status === "NOTAPPROVED") {
                                        status = "NOT APPROVED"
                                    } else {
                                        status = deferal.status;
                                    }
                                    tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                                });
                                this.setState({
                                    getDeferalList: tableArray
                                });

                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        let documentsUrl = backEndServerURL + "/case/files/" + this.props.appId;
                        axios.get(documentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        documentList: response.data,
                                    })
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        let inputData = response.data;
                        let remarksArray = [];
                        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    response.data.map((data) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            });
                        this.setState({
                            appId: this.props.appId,
                            inputData: inputData,
                            varValue: this.removeNullValue(inputData),
                            getData: true,
                            showValue: true,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            let url = backEndServerURL + "/startCase/cs_maintenance";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            appId: response.data.id,
                            showValue: true,
                            getData: true,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": "100%"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "width": '100%'}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, remarks, this.updateComponent)
            )
        }
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    handleSubmit = () => {

        if (this.state.fileUploadData['fileName'] !== undefined) {

            let error = MyValidation.fileValidation(fileUpload, this.state);
            this.forceUpdate();

            if (error === true) {
                return 0;
            }

            let fileUploadPath = backEndServerURL + "/case/upload";
            let formData = new FormData();
            formData.append("file", this.state.fileUploadData['fileName']);
            formData.append("type", "Attachments");
            formData.append("appId", this.state.appId);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    if (response.status === 200) {
                        let data = {...this.state.inputData};
                        data.remarks = undefined;
                        data.serviceType = "Maintenance";
                        data.subServiceType = "AccountMaintenance";
                        data.category = "CASA";
                        data.subCategory = "Maintenance";
                        data.freeFlag1 = "CASA Other Maintenance";
                        data.cs_deferal = this.state.inputData.csDeferal === "YES" ? "YES" : "NO";
                        data.cs_bearer = "NO";
                        this.caseRouteApi(data);
                    } else {
                        this.setState({
                            title: "Failed!",
                            loading: false,
                            notificationMessage: "Failed to Upload File!",
                            alert: true,
                            disabled: false
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        title: "Failed!",
                        loading: false,
                        notificationMessage: "Failed to Upload File!",
                        alert: true,
                        disabled: false
                    });
                    console.log(error)
                })
        } else if (this.state.documentList.length > 0) {
            let data = {...this.state.inputData};
            data.remarks = undefined;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            data.category = "CASA";
            data.subCategory = "Maintenance";
            data.freeFlag1 = "CASA Other Maintenance";
            data.cs_deferal = this.state.inputData.csDeferal === "YES" ? "YES" : "NO";
            data.cs_bearer = "NO";
            this.caseRouteApi(data);
        } else {
            this.setState({
                title: "File Missing",
                cbNotFound: true
            })
        }

    };


    callRemarksApi = () => {
        let commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.state.appId;
        axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {

                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    caseRouteApi = (postData) => {
        if (this.props.taskTitle === undefined || this.props.taskTitle === "cs_maintenance") {
            this.callDeferralApi()
        }
        this.callRemarksApi();
        let variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
        axios.post(variableSetUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            loading: true,
                        });
                        var url = backEndServerURL + "/case/route/" + this.state.appId;
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    if (this.props.appId !== undefined) {
                                        this.setState({
                                            title: "Successful!",
                                            loading: false,
                                            notificationMessage: "Case Route Successful!",
                                            alert: true,
                                            disabled: false
                                        });
                                        this.props.closeModal();
                                    } else {
                                        this.setState({
                                            inputData: {},
                                            varValue: {},
                                            title: "Successful!",
                                            notificationMessage: "Case Route Successful!",
                                            alert: true,
                                            getData: false,
                                            disabled: false,
                                            loading: false
                                        });
                                        setTimeout(function () {
                                            window.location.reload()
                                        }, 1000);
                                    }
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    disabled: false
                                });
                            });
                    }
                }
            )
            .catch((error) => {
                this.setState({
                    disabled: false
                });
            });
    };

    callDeferralApi = () => {
        if (this.state.inputData["csDeferal"] === "YES") {
            var defType = [];
            var expDate = [];

            let appId = this.state.appId;
            for (let i = 0; i < this.state.values.length; i++) {
                let value = this.state.values[i];
                let deferalType = this.state.inputData["deferalType" + value];
                if (deferalType === "other") {
                    deferalType = this.state.inputData["deferalOther" + value];
                }
                defType.push(deferalType);
                let expireDate = this.state.inputData["expireDate" + value];
                expDate.push(expireDate);

            }
            let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";
            axios.post(deferalRaisedUrl, {
                appId: appId,
                type: defType,
                dueDate: expDate
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderCommonData = () => {
        if (this.state.inputData.requestType) {
            return (
                <>
                    <Grid item xs={12}>
                        {this.renderDefferalData()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                    </Grid>
                    <Grid item xs={12}>
                        {this.renderRemarks()}
                    </Grid>
                    {this.renderButton()}
                </>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    submitHandler = (data) => {
        if (data === "RETURN") {
            let error = MyValidation.defaultValidation(remarks, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
        }

        let postData = {...this.state.inputData};
        postData.remarks = undefined;

        switch (this.props.taskTitle) {
            case "bm_maintenance_approval" :
                postData.cs_deferal = "NO";
                postData.csDeferal = "NO";
                postData.bm_approval = data;
                this.functionForDeferralApprove(data);
                break;
            case "bom_maintenance_approval" :
                postData.bom_maintenance_approval = data;
                postData.send_to_maker = (data === "APPROVED");
                break;
            case "sd_maker_maintenance_approval":
                postData.maker_approval = data;
                break;
            case "sd_checker_maintenance_approval":
                postData.checker_approval = data;
                break;
            default:
                postData.bom_maintenance_approval = data;
                postData.send_to_maker = (data === "APPROVED");
        }

        this.caseRouteApi(postData);
    };


    functionForDeferralApprove = (data) => {
        let appId = this.props.appId;
        let approval;

        data === "APPROVED" ? approval = "APPROVE" : approval = "REJECT";

        let deferralUrl = backEndServerURL + "/deferral/approval";
        axios.post(deferralUrl, {
            appId,
            approval
        }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };

    renderButton = () => {
        if (this.state.inputData.requestType && (this.props.taskTitle === "cs_maintenance" || this.props.taskTitle === undefined)) {
            return (
                <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            height: 30,
                            marginTop: 8,
                        }}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </button>
                    &nbsp;
                    {this.props.taskTitle === "cs_maintenance" && <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            height: 30,
                            marginTop: 8,
                        }}
                        onClick={this.handleClose}
                    >
                        Close
                    </button>}
                </>
            )
        } else if (this.state.inputData.requestType && (this.props.taskTitle !== "cs_maintenance" && this.props.taskTitle !== undefined)) {
            return (
                <>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            height: 30,
                            marginTop: 8,
                        }}
                        onClick={() => this.submitHandler("APPROVED")}
                    >
                        Approve
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            height: 30,
                            marginTop: 8,
                        }}
                        onClick={() => this.submitHandler("RETURN")}
                    >
                        Return
                    </button>
                </>
            )
        }
    };

    renderFileUpload = () => {
        if (this.state.inputData.requestType && (this.props.taskTitle === "cs_maintenance" || this.props.taskTitle === undefined)) {
            return (
                <>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                </>
            )
        }
    };

    renderFileDownload = () => {
        if (this.state.documentList.length > 0 && (this.props.taskTitle !== undefined)) {
            return (this.state.documentList.map((document) => {
                return (
                    <>
                        <Grid item xs={12}>

                        </Grid>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={backEndServerURL + "/file/" + document + "/" + sessionStorage.getItem("accessToken")}
                        >
                            Download File
                        </a>
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                    </>
                )
            }))
        }
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Bulk Maintenance<a><CloseIcon onClick={() => this.props.closeModal()} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >Bulk Maintenance</h4>
            )
        }
    };

    addClick = () => {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));

        this.state.inputData["csDeferal"] = "YES";
    };

    dynamicDeferral = (i) => {

        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };

    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }
    }


    addDeferalForm() {

        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}

                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    }

    renderDeferralFunctionality = () => {
        if (this.state.inputData.requestType && (this.props.taskTitle === "cs_maintenance" || this.props.taskTitle === undefined)) {
            return (
                <>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            type='button' value='add more'
                            onClick={this.addClick}
                        >Add Deferral
                        </button>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {this.addDeferalForm()}
                    <Grid item xs={12}></Grid>
                </>
            )
        }
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>

                            <ThemeProvider theme={theme}>
                                <Grid container>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent
                                    )}
                                    {this.renderDeferralFunctionality()}
                                    {this.renderFileUpload()}
                                    {this.renderFileDownload()}
                                    {this.renderCommonData()}
                                    {this.renderNotification()}
                                </Grid>
                            </ThemeProvider>

                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>

                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(Others);
