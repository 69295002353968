import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "../casa/LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            inputValue: {},
            showValue: false,
            labelData: [],
            linkedCB: [],
            getData: false,
        }
    }

    dateConverter = (value) => {
        return value.substr(0, 10);
    };


    componentDidMount() {
        let getDataByAc = backEndServerURL + "/getTermDepositAccountFDR/" + this.props.accountNumber;
        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let labelData = [
                    {
                        name: 'CB Number', value: this.props.cbNumber
                    },
                    {
                        name: 'Customer Name', value: response.data.depositAccount.accountShortName
                    },
                    {
                        name: 'DPS Schema (Product Code)', value: response.data.depositAccount.schemeCode
                    },
                    {
                        name: 'Mother Branch', value: response.data.customDataList[0].solid
                    },
                    {
                        name: 'Citygem Center Name', value: ""
                    },
                    {
                        name: 'DPS Open Date', value: this.dateConverter(response.data.depositAccount.accountOpenDate)
                    },
                    {
                        name: 'Installment Start Date', value: ""
                    },
                    {
                        name: 'Maturity Value', value: response.data.depositAccount.maturityAmt_amountValue
                    },
                    {
                        name: 'Amount per Installment', value: ""
                    },
                    {
                        name: 'DPS Current Balance', value: response.data.depositAccount.acctBalAmt
                    },
                    {
                        name: 'Number of Installment Pending', value: ""
                    },
                    {
                        name: 'Period of Scheme', value: ""
                    },
                    {
                        name: 'Link /Debit Account Number', value: response.data.depositAccount.account
                    },
                    {
                        name: 'Last Renewal (Value) Dater', value: ""
                    },
                    {
                        name: 'Maturity Date', value: this.dateConverter(response.data.depositAccount.maturityDt)
                    },
                    {
                        name: 'Matured/ Premature Closure', value: ""
                    },
                    {
                        name: 'E-TIN status', value: response.data.eTinNumber,
                    },
                    {
                        name: 'Lien status', value: "", popup: true, popupValue: 'Lien'
                    },
                    {
                        name: 'Freeze status', value: "", popup: true, popupValue: 'Freeze'
                    },
                    {
                        name: 'Deferral Status', value: ""
                    },
                ];

                setTimeout(() => {
                    this.setState({
                        labelData: labelData,
                        inputValue: response.data,
                        showValue: true,
                        getData: true,
                    }, this.parentLoad);
                }, 1000)
            })
            .catch((error) => {
                console.log(error);
                this.props.stopLoading(error)
            });
    }


    parentLoad = () => {
        if (this.props.stopLoading !== undefined) {
            this.props.stopLoading("yes")
        }
    };

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0 && this.state.getData) {
            return (

                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "10px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>
                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>

                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                               labelName={this.state.labelName} data={this.state.labelData}
                                               inputValue={this.state.inputValue}
                                               accountNumber={this.props.accountNumber} crmStatus={this.props.crmStatus}
                                               cbNumber={this.props.cbNumber} create={this.props.create}/>
                        </DialogContent>
                    </Dialog>
                </div>
            );
        } else {
            return ""
        }
    }


}

export default Label;