import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import FileTypeComponent from "../../JsonForm/FileTypeComponent";
import DialogContent from "@material-ui/core/DialogContent/index";
import {Dialog} from "@material-ui/core/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../Static/loader.gif";
import AssignedCropImage from "./AssignedCropImage";
import SecondaryCbFormDetails from "./SecondaryCbFormDetails";
import TextField from "@material-ui/core/TextField";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "../CommonComponent/WaiverList";
import DeferalList from "../CommonComponent/DeferalList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import ErrorModal from "../CommonComponent/ErrorModal";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm, TpKycForIndividual} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import CardHeader from "../../Card/CardHeader";
import SignatureButton from "../Maintenance/SignatureButton";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import FileMappingEdit from "../CommonComponent/FileMappingEdit";
import FileMapping from "../CommonComponent/FileMapping";
import CommonApi from "../Maintenance/common/CommonApi";

const arrayListIndividualCondition = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},

];
const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'OTHERS'},

];
const tagForm = [
    {
        "type": "title",
        "label": "BASIC INFORMATION UPDATE",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateGender",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Gender",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateFatherName",
        "type": "text",
        "label": "Father Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMotherName",
        "type": "text",
        "label": "Mother Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateMaritalStatus",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Marital Status",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateSpouseName",
        "type": "text",
        "label": "Spouse Name",
        "grid": 6,
    },

    {
        "varName": "basicInformationUpdateDob",
        "type": "text",
        "label": "DOB",
        "grid": 6,
    },
    {
        "varName": "basicInformationUpdateDobMinor",
        "type": "select",
        "enum": [
            "YES",
            "NO",
        ],
        "label": "minor",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "GUARDIAN DETAILS",
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "grid": 12,

    },
    {
        "varName": "guardianDetailsBankRelationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Bank Relation Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGurdianCifNumber",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Gurdian CIF Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsGuardianSelection",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "conditional": true,
        "conditionalVarName": "dobMinor",
        "conditionalVarValue": "YES",
        "label": "Guardian Selection",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNationalIdCardnumber",
        "type": "text",
        "label": "National ID CardNumber",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsDocumentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code ",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsSmartCardNumber",
        "type": "text",
        "label": "Smart Card Number",
        "grid": 6,
    },

    {
        "varName": "guardianDetailsNidVerificationConfirmationisDocumentVerified",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "NID Verification Confirmation/Is Document Verified",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "passportNumber",
        "type": "text",
        "label": "Passport Number",
        "grid": 6,
    },

    {
        "varName": "issueDate",
        "type": "text",
        "label": "Issue Date",
        "grid": 6,
    },

    {
        "varName": "countryOfIssue",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country of Issue",
        "grid": 6,
    },

    {
        "varName": "expiryDate",
        "type": "text",
        "label": "Expiry Date",
        "grid": 6,
    },

    {
        "varName": "documentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Type",
        "grid": 6,
    },

    {
        "varName": "documentCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Document Code",
        "grid": 6,
    },

    {
        "varName": "birthCertificateNumber",
        "type": "text",
        "label": "Birth Certificate Number",
        "grid": 6,
    },

    {
        "varName": "placeOfBirthfreeText14",
        "type": "text",
        "label": "Place of Birth/Free Text 14",
        "grid": 6,
    },

    {
        "varName": "countryOfBirthfreeText15",
        "type": "text",
        "label": "Country of Birth/Free Text 15",
        "grid": 6,
    },

    {
        "varName": "nationality",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],

        "label": "Nationality",
        "grid": 6,
    },

    {
        "varName": "residingCountry",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Residing Country",
        "grid": 6,
    },

    {
        "varName": "nonResident",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Non Resident",
        "grid": 6,
    },

    {
        "varName": "minorStatus",
        "type": "text",
        "label": "Minor Status",
        "grid": 6,
    },

    {
        "varName": "eTinNumber(PAN/GIR No)",
        "type": "text",
        "label": "E-Tin Number (PAN/GIR No)",
        "grid": 6,
    },

    {
        "varName": "professionEmploymentType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Profession /Employment Type",
        "grid": 6,
    },

    {
        "varName": "nameOfEmployer",
        "type": "text",
        "label": "Name of Employer",
        "grid": 6,
    },

    {
        "varName": "monthlyIncome",
        "type": "text",
        "label": "Monthly Income",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "contactType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Contact Type",
        "grid": 6,
    },

    {
        "varName": "phoneNoemailId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Phone No./Email ID",
        "grid": 6,
    },

    {
        "varName": "phoneNo",
        "type": "text",
        "label": "Phone No",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emaildesignatedEmailAddress1",
        "type": "text",
        "label": "Email/Designated Email Address 1",
        "grid": 6,
    },

    {
        "varName": "emailType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Email Type",
        "grid": 6,
    },

    {
        "varName": "emailId2",
        "type": "text",
        "label": "Email ID 2",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField1",
        "type": "text",
        "label": "Mailing Address Field 1",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField2",
        "type": "text",
        "label": "Mailing Address Field 2",
        "grid": 6,
    },

    {
        "varName": "mailingAddressField3",
        "type": "text",
        "label": "Mailing Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "homeAddressField1",
        "type": "text",
        "label": "Home Address Field 1",
        "grid": 6,
    },

    {
        "varName": "homeAddressField2",
        "type": "text",
        "label": "Home Address Field 2",
        "grid": 6,
    },

    {
        "varName": "homeAddressField3",
        "type": "text",
        "label": "Home Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "addressFormat",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Address Format",
        "grid": 6,
    },

    {
        "varName": "workAddressField1",
        "type": "text",
        "label": "Work Address Field 1",
        "grid": 6,
    },

    {
        "varName": "workAddressField2",
        "type": "text",
        "label": "Work Address Field 2",
        "grid": 6,
    },

    {
        "varName": "workAddressField3",
        "type": "text",
        "label": "Work Address Field 3",
        "grid": 6,
    },

    {
        "varName": "city",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "City",
        "grid": 6,
    },

    {
        "varName": "state",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "State",
        "grid": 6,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
    },

    {
        "varName": "country",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Country",
        "grid": 6,
    },

    {
        "varName": "addressValidForm",
        "type": "text",
        "label": "Address Valid Form",
        "grid": 6,
    },

    {
        "varName": "relationType",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Type",
        "grid": 6,
    },

    {
        "varName": "relationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Relation Code",
        "grid": 6,
    },

    {
        "varName": "designationCode",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "Designation Code",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "select",
        "enum": [
            "A",
            "B",
        ],
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "deleteFlag",
        "type": "text",
        "label": "Delete Flag",
        "grid": 6,
    },
];
let checkerRemarks = [
    {
        "varName": "checkerRemarks",
        "type": "textArea",
        "label": "Checker Remarks",
        "grid": 12
    }];
let objectForNominee = [];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
const firstStyle = {
    color: '#212F3D',
    background: '#f8f9fa'
};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "grid": 12
};


class VerifyMakerInboxCase extends React.Component {
    state = {
        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        alert: false,
        inputData: {},
        SelectedDropdownSearchData: null,
        dropdownSearchData: {},
        values: [],
        selectedDate: {},
        showValue: false,
        customerName: [],
        deferalType: [],
        expireDate: [],
        other: [],
        getCheckerList: [],
        getDeferalList: [],
        fileUploadData: {},
        loaderNeeded: null,
        objectForJoinAccount: [],
        getgenerateForm: false,
        getTagList: [],
        tagCbDetailsModal: false,
        customerNumber: "",
        linkrelationCode: 0,
        loading: true,
        getRemarks: [],
        objectForNominee: [],
        err: false,
        errorArray: {},
        errorMessages: {},
        numberOfSignatureCard: "",
        signatureCardError: false,
        getCropedImage: false,
        uploadModal: false,
        getMappingAllImage: false,
        signatureButtonShowInImageCropedPage: false,
        confirmAlert: false,
        actionType: "",
        errorModalBoolean: false,
        errorTittle: "",
        getDownloadListFlag: false,
        downLoadFileList: [],
        needCheckForTag: [],
        fileNotFoundTittle: "",
        requestType: "",
        tagCbNumber: 0,
        signatureButtonFlag: false,
    };

    handleChange = (event) => {

        event.preventDefault();

        this.state.inputData[event.target.name] = event.target.value;


    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        /* for (var prop in clone)
             if (clone[prop] === ''|| clone[prop] === "null" || clone[prop] === "undefined")
                 //delete clone[prop];*/
        return clone;
    };
    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )

        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };
    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };
    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };
    highLightsEmptyObject = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["highlight"] = false;
        }
        return returnObject;


    };
    componentDidMount() {

        var remarksArray = [];

        let objectForJoinAccount = [];
        var sl;

        let downLoadFileList = [];
        let url = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((data, index) => {
                    downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                });
                console.log(response.data);
                this.setState({
                    getDownloadListFlag: true,
                    downLoadFileList: downLoadFileList
                })

            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    fileNotFoundTittle: this.errorObjectCheck(error),
                })
            });
        this.setState({
            objectForJoinAccount: objectForJoinAccount,
            getgenerateForm: true

        });

        if (this.props.appId !== undefined) {

            let jsonForm = this.props.commonJsonForm.concat(objectForNominee, this.props.commonJsonFormLast);

            let commonJsonForm = this.props.commonJsonForm ;
             let commonJsonFormLast =   this.props.commonJsonFormLast;

            for (let i = 0; i < commonJsonForm.length; i++) {
                commonJsonForm[i].highlight = false
            }


            for (let i = 0; i < commonJsonFormLast.length; i++) {
                commonJsonFormLast[i].highlight = false
            }
             let url = backEndServerURL + '/variables/' + this.props.appId;
                    let variableData = [];
                    axios.get(url,
                        {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let fileUrl = backEndServerURL + "/applicationKeyValue/get";
                            let data = {};
                            data.appId = this.props.appId;
                            data.key = "getSignatureCardNumber";
                            axios.post(fileUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    let dataList = [];
                                    dataList = Number(response.data.value);
                                    if (dataList !== undefined && dataList !== null && dataList > 0) {
                                        this.setState({
                                            signatureButtonFlag: true,
                                        })
                                    }
                                    this.setState({
                                        numberOfSignatureCard: Number(response.data.value),

                                    })

                                })
                                .catch((error) => {
                                    console.log("error");

                                });
                            variableData = response.data;
                            let checkerListUrl = backEndServerURL + "/checkers";
                            axios.get(checkerListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    /*    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                                       axios.get(deferalListUrl, {headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                                           .then((response) => {
                                               console.log(response.data);

                                               let tableArray = [];
                                               let status = "";
                                               response.data.map((deferal) => {
                                                   if (deferal.status === "ACTIVE") {
                                                       status = "Approved By BM"
                                                   } else {
                                                       status = deferal.status
                                                   }
                                                   tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                                               });
                                               this.setState({
                                                   getDeferalList: tableArray
                                               })

                                           })
                                           .catch((error) => {
                                               console.log(error);
                                           })*/
                                    this.setState({
                                        getCheckerList: response.data
                                    });
                                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {

                                            console.log(response.data);
                                            response.data.map((data) => {

                                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                            });
                                            this.setState({
                                                getRemarks: remarksArray
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })


                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            let makerEdit = backEndServerURL + '/case/fields/updatedByMaker/' + this.props.appId;

                            axios.get(makerEdit,
                                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((responseForFields) => {

                                    let fieldVarName = responseForFields.data;


                                    let highLightArray = [];
                                    for (let i = 0; i < fieldVarName.length; i++) {
                                        let obj = jsonForm.find(item => item.varName === fieldVarName[i]);
                                        if (obj !== undefined) {
                                            highLightArray.push(obj)
                                        }
                                    }
                                    setTimeout(()=>{
                                    },1000)
                                    for (let i = 0; i < highLightArray.length; i++) {
                                        highLightArray[i].highlight = true
                                    }
                                    console.log("highLightArray"+highLightArray)
                                    this.renderTaggingApi();
                                    let inputData = this.removeNullValue(response.data);
                                    let varValue = this.removeNullValue(response.data);
                                    inputData.checkerRemarks = undefined;
                                    varValue.checkerRemarks = undefined;

                                    inputData.makerEndTpAndKyc = false;
                                    varValue.makerEndTpAndKyc = false;
                                    //delete flag start
                                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                                    inputData.natioanlIdCardDelete = "N";
                                    inputData.smartIdCardDelete = "N";
                                    inputData.passportNumberDelete = "N";
                                    inputData.drivingLicenseNumberDelete = "N";
                                    inputData.residentNumberDelete = "N";
                                    inputData.birthCertificateDelete = "N";
                                    inputData.chairmanCertificateDelete = "N";
                                    inputData.photoIdDelete = "N";
                                    inputData.etinDelete = "N";
                                    inputData.contactNumber2Delete = "N";
                                    inputData.contactNumber3Delete = "N";
                                    inputData.contactNumberEmailemailId2Delete = "N";
                                    inputData.homeAddressDelete = "N";
                                    inputData.workAddressDelete = "N";
                                    inputData.nreAddressDelete = "N";
                                    inputData.presentAddressDelete = "N";
                                    inputData.presentAddressDelete = "N";


                                    varValue.contactNumberEmaildesignatedEmailAddressEmailId1Delete = "N";

                                    varValue.natioanlIdCardDelete = "N";
                                    varValue.smartIdCardDelete = "N";
                                    varValue.passportNumberDelete = "N";
                                    varValue.drivingLicenseNumberDelete = "N";
                                    varValue.residentNumberDelete = "N";
                                    varValue.birthCertificateDelete = "N";
                                    varValue.chairmanCertificateDelete = "N";
                                    varValue.photoIdDelete = "N";
                                    varValue.etinDelete = "N";
                                    varValue.contactNumber2Delete = "N";
                                    varValue.contactNumber3Delete = "N";
                                    varValue.contactNumberEmailemailId2Delete = "N";
                                    varValue.homeAddressDelete = "N";
                                    varValue.workAddressDelete = "N";
                                    varValue.nreAddressDelete = "N";
                                    varValue.presentAddressDelete = "N";
                                    varValue.presentAddressDelete = "N";
                                    //delete flag End
                                    // inputData.apiButton = "addAccountTpThenKYC";
                                    // varValue.apiButton = "addAccountTpThenKYC";
                                    this.setState({
                                        getData: true,
                                        showValue: true,
                                        varValue: this.removeNullValue(inputData),
                                        inputData: this.removeNullValue(varValue),
                                        appData: response.data,
                                        loaderNeeded: true,
                                        loading: false,
                                    });


                                }).catch((error) => {
                                console.log(error)
                            });


                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                            /* if(error.response.status===452){
                                 Functions.removeCookie();

                                 this.setState({
                                     redirectLogin:true
                                 })

                             }*/
                        });


        }

    }

    renderTaggingApi = () => {
        let tableArrayData = [];
        let tableArray = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    if (data.requestType === "Completed") {
                        tableArray.push(data)
                    }
                    tableArrayData.push(this.createTaggingData(data));

                });

                this.setState({
                    needCheckForTag: tableArray,
                    getTagList: tableArrayData,
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal();
    };
    closeModal = () => {
        this.setState({
            getCropedImage: false,
            errorModalBoolean: false,
            signatureButtonShowInImageCropedPage: false,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    closeConfirmAlert = (data) => {
        this.setState({
            confirmAlert: false
        });
        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "APPROVED") {
            this.handleSubmit("APPROVED")
        } else if (data === "YES" && this.state.actionType === "NOTAPPROVED") {
            this.handleSubmit("NOTAPPROVED")
        } else if (data === "YES" && this.state.actionType === "RETURN_TO_CS") {
            this.handleSubmit("RETURN_TO_CS")
        }

    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    handleSignatureCard = (event, type) => {
        event.preventDefault();
        if (this.state.signatureButtonFlag === true) {
            this.setState({
                loading: true,
            });
            let objectList = [];
            for (let i = 0; i < this.state.numberOfSignatureCard; i++) {
                let object = {
                    appId: this.props.appId,
                    customerId: this.state.inputData.cbNumber,
                    type: "CARD" + (i + 1),
                    accountNo: this.state.inputData.accountNumber,
                    remarks: this.state.inputData["imageRemarks" + i]
                };
                if (this.state.inputData["deleteFlag" + i] === "NO") {
                    objectList.push(object)
                }

            }

            console.log(objectList);
            let signatureUpload = backEndServerURL + "/signatureImage/uploadToFinacleBulk";
            axios.post(signatureUpload, objectList, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = this.state.inputData;
                    inputData.signatureButtonAction = true;
                    this.variableSetFunction(inputData)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        signatureCardError: false,
                        errorModalBoolean: true,
                        errorTittle: this.errorObjectCheck(error),
                    })
                })
        }


    };
    validationForHandleConfirm = (actionType) => {
        let errors = MyValidation.defaultValidation(checkerRemarks, this.state);
        this.forceUpdate();

        if (errors === true) {
            console.log("Not working");
            return 0;
        }
        this.setState({
            loading: true,
        });
        let dependencyField = [];
        if (actionType === "NOTAPPROVED" || actionType === "RETURN_TO_CS") {
            dependencyField.push({

                "varName": "checkerRemarks",
                "type": "textArea",
                "required": true,
                "label": "Checker Remarks",
                "grid": 12
            })

        }
        this.setState({
            loading: false,
        });
        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            loading: false,
            confirmAlert: true,
            actionType: actionType
        })


    };
    variableSetFunction = (object) => {
        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    alowApiCall: true
                })
            })
            .catch((error) => {
                console.log(error)
            })

    };
    handleSubmit = (checker_approval) => {
        let saveDataDeferralRemarksAndRoute = {};
        if (checker_approval === "APPROVED") {
            if (this.state.needCheckForTag.length === this.state.getTagList.length) {

            } else {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Please Approve All Related Party Request!",
                });
                return 0;
            }
        }

        this.setState({
            loading: true
        });
        if (this.state.inputData.checkerRemarks !== undefined && this.state.inputData.checkerRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.checkerRemarks;
        }

        this.state.inputData.checker_approval = checker_approval;

        if ((this.state.inputData.apiButton === "finalApproval" || checker_approval !== "APPROVED") && this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = 'Attachments';
            let files = this.state.fileUploadData.scanningFile;
            console.log("dfghjk");
            console.log(files);
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }


        if (checker_approval === "NOTAPPROVED" || checker_approval === "RETURN_TO_CS") {
            this.state.inputData.apiButton = "UPDATE";
        }
        this.state.inputData.FormCasaFlow = "YES";

        let variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.emptyValueRemove(this.state.inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = {};
                data.accountType = this.state.inputData.accType;
                if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C" || this.props.subServiceType === "Joint Account") {
                    data.accountCategory = "individual";
                    data.accountType = this.state.inputData.accType;
                } else {
                    data.accountCategory = "nonindividual";
                    data.accountType = this.state.inputData.accTypes;
                }

                data.cbNumber = this.state.inputData.cbNumber;


                data.accountNumber = this.props.accountNumber;
                console.log(this.state.inputData);
                console.log(response.data);

                if (checker_approval === "APPROVED") {
                    if (this.state.inputData.apiButton === "updateAccountInfo") {
                        console.log(response.data);
                        let accountUpdateUrl = backEndServerURL + "/updateAccountInfo/" + this.props.appId + "/" + this.state.inputData.accType;
                        axios.get(accountUpdateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let object = {};
                                object.accountNumber = this.state.inputData.accountNumber;
                                object.dormantActivationCharge = this.state.inputData.fincoreDataUpdatedormantActivationCharge;
                                if (this.state.inputData.fincoreDataUpdateaCStatus !== undefined) {
                                    object.accountStatus = (this.state.inputData.fincoreDataUpdateaCStatus.key);
                                }

                                let url = backEndServerURL + "/dormantAccountActivation";
                                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {

                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    });

                                if (this.state.inputData.misfunctionCodeForChequeBookWaiver === "A" || this.state.inputData.misfunctionCodeForChequeBookWaiver === "M") {
                                    let url = backEndServerURL + "/doAccountCheqeWaiver/" + this.state.inputData.accountNumber + "/" + this.state.inputData.misfunctionCodeForChequeBookWaiver + "/" + this.state.inputData.miswaiverTypeForChequeBookWaiver.key;
                                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((response) => {
                                            let object = {};
                                            object.accountNumber = this.state.inputData.accountNumber;
                                            object.currency = this.state.inputData.aof1currencyCodeCcy;
                                            object.sol = (this.state.inputData.basicInformationUpdateprimarySolId);

                                            if (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== undefined && this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== null) {
                                                CommonApi.getAccountDetails(this.state.inputData.accountNumber).then((response) => {
                                                    if(response.data.schemeCode===this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack.key){
                                                        let inputData = this.state.inputData;
                                                        inputData.apiButton = "updateCustomerInfo";
                                                        this.variableSetFunction(inputData)
                                                    }
                                                    else{
                                                        object.schemeCode = (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack.key);
                                                        let url = backEndServerURL + "/account/changeScheme";
                                                        axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                            .then((response) => {
                                                                let inputData = this.state.inputData;
                                                                inputData.apiButton = "updateCustomerInfo";
                                                                this.variableSetFunction(inputData)
                                                            })
                                                            .catch((error) => {

                                                                this.setState({
                                                                    loading: false,
                                                                    errorModalBoolean: true,
                                                                    errorTittle: this.errorObjectCheck(error),
                                                                })

                                                            })
                                                    }
                                                })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        this.setState({
                                                            loading: false,
                                                            errorModalBoolean: true,
                                                            errorTittle: this.errorObjectCheck(error),
                                                        })
                                                    })


                                            }

                                            else {
                                                let inputData = this.state.inputData;
                                                inputData.apiButton = "updateCustomerInfo";
                                                this.variableSetFunction(inputData)
                                            }


                                        })
                                        .catch((error) => {
                                            this.setState({
                                                loading: false,
                                                errorModalBoolean: true,
                                                errorTittle: this.errorObjectCheck(error),
                                            })

                                        })
                                } else {
                                    let object = {};
                                    object.accountNumber = this.state.inputData.accountNumber;
                                    object.currency = this.state.inputData.aof1currencyCodeCcy;
                                    object.sol = (this.state.inputData.basicInformationUpdateprimarySolId);
                                    if (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== undefined && this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack !== null) {
                                        object.schemeCode = (this.state.inputData.schemeCodeChangetargetSchemeCodeInCaseOfInstaPack.key);

                                        let url = backEndServerURL + "/account/changeScheme";
                                        axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                            .then((response) => {
                                                let inputData = this.state.inputData;
                                                inputData.apiButton = "updateCustomerInfo";
                                                this.variableSetFunction(inputData)
                                            })
                                            .catch((error) => {
                                                this.setState({
                                                    loading: false,
                                                    errorModalBoolean: true,
                                                    errorTittle: this.errorObjectCheck(error),
                                                })

                                            })
                                    } else {
                                        let inputData = this.state.inputData;
                                        inputData.apiButton = "updateCustomerInfo";
                                        this.variableSetFunction(inputData)
                                    }

                                }

                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })
                    }
                    else if (this.state.inputData.apiButton === "updateCustomerInfo") {

                        let updateUrl = backEndServerURL + "/updateCustomerInfo/" + this.props.appId;
                        if (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C" && this.props.subServiceType !== "Joint Account") {

                            updateUrl = backEndServerURL + "/updateCorporateCustomer/" + this.props.appId;
                        }
                        axios.get(updateUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                let inputData = this.state.inputData;
                                if(this.state.inputData.riskRatingCalculation===true){
                                    inputData.apiButton = "addAccountTpThenKYC";
                                }
                                else{
                                    inputData.apiButton = "finalApproval";
                                }

                                this.variableSetFunction(inputData)
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })


                    } else if (this.state.inputData.apiButton === "addAccountTpThenKYC") {
                        let tpKycApiUrl = backEndServerURL + "/addAccountTpThenKYC/" + this.props.appId;
                        axios.get(tpKycApiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.state.inputData.overallRiskFieldShow=false;
                                this.state.varValue.overallRiskFieldShow=false;
                                this.updateComponent()
                                let tpKycApiUrl = backEndServerURL + "/overallRiskCalculationForIndividualAccount/" + this.props.appId;
                                axios.get(tpKycApiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        let inputData = this.state.inputData;
                                        inputData.overallRiskAssesment=response.data.overallRiskAssesment;
                                       if(response.data.overallRiskAssesment!==undefined){
                                           if(Number(response.data.overallRiskAssesment)<15){
                                               inputData.riskType="Low";
                                           }
                                           else if(Number(response.data.overallRiskAssesment)>=15){
                                               inputData.riskType="High";
                                           }
                                       }

                                        inputData.overallRiskFieldShow=true;
                                        inputData.apiButton = "finalApproval";
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            inputData:inputData,
                                            varValue:this.emptyValueRemove(inputData),
                                            showValue:true
                                        })
                                    })
                                    .catch((error)=>{
                                        console.log(error);
                                        let inputData = this.state.inputData;

                                        inputData.overallRiskFieldShow=true;
                                        inputData.apiButton = "finalApproval";
                                        this.variableSetFunction(inputData);
                                        this.setState({
                                            inputData:inputData,
                                            varValue:this.emptyValueRemove(inputData),
                                            showValue:true
                                        })
                                    })


                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: this.errorObjectCheck(error),
                                })
                            })

                    } else if (this.state.inputData.apiButton === "finalApproval") {
                        if (this.state.signatureButtonFlag) {
                            if (this.state.inputData.signatureButtonAction === true) {
                                let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                                axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((response) => {
                                        console.log(response.data);
                                        this.setState({
                                            title: "Successfull!",
                                            notificationMessage: "Successfully Routed!",
                                            alert: true,
                                            loading: false
                                        });
                                        this.props.closeModal()
                                        //
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            loading: false
                                        })
                                    })
                            } else {
                                this.setState({
                                    loading: false,
                                    errorModalBoolean: true,
                                    errorTittle: "Please Upload The Signature Card!",
                                });
                                return 0;
                            }


                        } else {
                            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
                            axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    console.log(response.data);
                                    this.setState({
                                        title: "Successfull!",
                                        notificationMessage: "Successfully Routed!",
                                        alert: true,
                                        loading: false
                                    });
                                    this.props.closeModal()
                                    //
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false
                                    })
                                })
                        }


                    }

                } else {
                    let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;

                    axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                title: "Successfull!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false
                            });
                            this.props.closeModal()
                            //
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false
                            })
                        })
                }
                /* })
                 .catch((error)=>{
                     console.log(error);
                     })
*/
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };

    /*renderForm = () => {
         if (this.state.getData) {

             return (
                 CommonJsonFormComponent.renderJsonForm(this.state, this.props.jsonForm, this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)

             )
         }
         return;
     } */
    onKeyDownChange = (data) => {};
    onKeyDownForDedup = (event, data, secondVarName) =>{};
    renderFileUpload = () => {
        if (this.state.getData) {
            return (

                <Grid item xs={12}>
                    {FileTypeComponent.file(this.state, this.updateComponent, fileUpload)}
                </Grid>

            )
        }

    };
    renderSignatureCardView = () => {
        return (
            <SignatureButton casaFlow={true} accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
                             classes={this.props}/>
        )


    };

    renderSubmitButton = () => {
        if (this.state.getData) {
            if (this.state.inputData.apiButton === "UPDATE" && this.state.loading === false) {
                return (
                    <Grid item xs={12}>

                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Approve
                            </button>

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "NOTAPPROVED")}

                            >Return To Maker
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "RETURN_TO_CS")}

                            >Return To CS
                            </button>
                        </div>


                    </Grid>

                )
            } else {
                return (
                    <Grid item xs={12}>

                        <div>

                            {this.state.inputData.apiButton === "updateCustomerInfo" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >CB update
                            </button>}
                            {this.state.inputData.apiButton === "updateAccountInfo" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Account Update
                            </button>}
                            {this.state.inputData.apiButton === "addAccountTpThenKYC" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >TP & KYC Update
                            </button>}
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.state.signatureButtonFlag === true && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={this.handleImageCrop}
                            >Signature Card Upload
                            </button>

                            }
                            &nbsp;
                            {this.state.inputData.signatureButtonAction === true && this.state.loading === false &&
                            this.renderSignatureCardView()

                            }
                            &nbsp;&nbsp;
                            {this.state.inputData.apiButton === "finalApproval" && this.state.loading === false &&
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "APPROVED")}

                            >Final Approval
                            </button>}

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "NOTAPPROVED")}

                            >Return To Maker
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}
                                checkerApproval

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "RETURN_TO_CS")}

                            >Return To CS
                            </button>


                        </div>


                    </Grid>

                )
            }
        } else {
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div>
            )
        }


    };
    renderImageCrop = (event) => {
        event.preventDefault();
        this.setState({
            getCropedImage: true
        })


    };
    handleImageCrop = (event) => {
        event.preventDefault();
        this.setState({
            getCropedImage: true,
            signatureButtonShowInImageCropedPage: true,
        })


    };
    renderDedupComponent = () => {
        if (this.state.getData  /* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         renderTagging={this.renderTagging}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <MandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <SdnResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                 <FlowSummeryButton   subServiceType={this.props.subServiceType!==undefined?this.props.subServiceType:(this.props.subserviceType!==undefined?this.props.subserviceType:this.state.inputData.subserviceType)}     serviceType={this.props.serviceType!==undefined?this.props.serviceType:this.state.inputData.serviceType}
                    category={this.props.category!==undefined?this.props.category:this.state.inputData.category}
                    subCategory={this.props.subCategory!==undefined?this.props.subCategory:(this.props.subcategory!==undefined?this.props.subcategory:this.state.inputData.subCategory)}
                    caseId={this.props.caseId!==undefined?this.props.caseId:""} cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   solId={this.props.solId}/>
            )
        }
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    renderUploadButton = () => {
        if (this.state.getData) {
            return (

                <button

                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }

    };
    submitModal = (data) => {
        this.state.inputData.previewImage = true;
        this.updateComponent();
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })

    };
    renderMappingImageEditModal = () => {


        return (
            <FileMappingEdit dropDownOption={arrayListIndividual}
                             submitModal={this.submitModal}
                             appId={this.props.appId}
                             closeModal={this.closeModal}/>
        )

    };
    renderMappingImageModal = () => {
        return (
            <FileMapping condition={true} dropDownConditionOption={arrayListIndividualCondition}
                         dropDownOption={arrayListIndividual}
                         submitModal={this.submitModal}
                         appId={this.props.appId}
                         closeModal={this.closeModal}/>
        )

    };
    renderDefferalData = () => {


        if (this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferal Type", "Due Date", "Created By", "Application Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };
    tagCbDetailsModalClse = () => {
        this.setState({
            tagCbDetailsModal: false
        })
    };
    tagCbDetailsModal = (event, customerNumber, requestType, cbNumber, relationCode) => {
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            customerNumber: customerNumber,
            requestType: requestType,
            tagCbNumber: cbNumber,
            linkrelationCode: relationCode
        });
        console.log(cbNumber)

    };
    createTaggingData = (data) => {
        if (this.props.subServiceType === "INDIVIDUAL" || this.props.subServiceType === "Individual A/C") {
            return (
                [data.cbNumber, data.customerName, data.relationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
                >View
                </button>]]


            )
        } else {
            return (
                [data.cbNumber, data.customerName, data.relationCode, [<button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={(event) => this.tagCbDetailsModal(event, data.customer, data.requestType, data.cbNumber, data.relationCode)}
                >View
                </button>]]


            )
        }
    };


    renderTagList = () => {

        if (this.state.getData /*&& (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/ && this.state.getTagList.length > 0) {

            return (

                <div style={{marginBottom: 40}}>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >Link CB List</h4>

                        </CardHeader>
                    </paper>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["CB Number", "Customer Name", "Relation Code", "Action"]}

                        tableData={this.state.getTagList}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />


                </div>


            )
        }

    };


    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)


            )
        }
    };
    renderJsonFormLast = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonFormLast, this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)


            )
        } else {

        }
    };
    renderJsonFormTpKycFor = () => {
        if (this.state.getData && this.state.inputData.riskRatingCalculation===true ) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(TpKycForIndividual), this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)


            )
        } else {

        }
    };


    renderRemarks = () => {
        if (this.state.getData) {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, checkerRemarks, this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)

            )
        }

    };
    renderPreviewImage = () => {
        if (this.state.getData) {
            return (
                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.renderImageCrop}>
                    Croped Image
                </button>

            )
        }
    };
    renderNomineeForm = () => {
        if (this.state.getData) {
            var sl;
            objectForNominee = [];


            if (this.state.inputData["numberOfNominee"] !== undefined && this.state.inputData["numberOfNominee"] !== null) {
                objectForNominee.push(
                    {
                        "type": "title",
                        "label": "Nominee",
                        "grid": 12,
                    },
                    {
                        "varName": "nomineeRegNo",
                        "type": "text",
                        "label": "Registration No",
                        "grid": 6,
                        "required": true,

                    },
                )
            }

            for (var i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                sl = i + 1;
                //inputData["nomineecifIdApiCall"+i]=false
                //varValue["nomineecifIdApiCall"+i]=false
                objectForNominee.push(
                    {
                        "varName": "Nominee " + sl,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,
                    },
                    {
                        "varName": "search" + sl,
                        "type": "select",
                        "enum": [
                            "CIF ID",
                            "INFORMATION",
                        ],
                        "label": "Input CIF ID/INFORMATION ?",
                        "grid": 6,


                    },
                    {
                        "varName": "nomineecifId" + sl,
                        "type": "textApiCall",
                        "label": "CIF ID",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "CIF ID",

                    },

                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",

                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.relationCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "required": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],


                    },
                    {
                        "varName": "nomineeRelationship" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.relationCodeList,
                        "label": "Relationship",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        required: true,

                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                        required: true,

                    },

                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress1" + sl,
                        "type": "text",
                        "label": "Address 1 ",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        required: true,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "multiline": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    /*  {
                            "varName": "nomineenomineephotoIdNo" + i,
                            "type": "text",
                            "label": "NOMINEE PHOTO ID NO.",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "nomineecifIdApiCall" + i,
                            "conditionalVarValue": true,
                        },
        */
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineePostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },

                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeCountry" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        required: true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },

                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "photoIdNumberRegNo" + sl,
                        "type": "text",
                        "label": "Photo Id Number/Reg No",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "text",
                        "label": "D.O.B(YYYY-MM-DD)",
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                    },
                    {
                        "varName": "nomineeDob" + sl,
                        "type": "date",
                        "label": "D.O.B",
                        "grid": 6,
                        "onKeyDown": true,
                        "required": true,
                        "conditional": true,
                        "conditionalVarName": "search" + sl,
                        "conditionalVarValue": "INFORMATION",
                    },


                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                        "conditionalArrayValue": ["CIF ID", true],
                        /*    "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",*/
                    },
                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "label": "Nominee Minor",
                        "enum": ["YES", "NO"],
                        "grid": 6,
                        "readOnly": true,
                        "conditionalArray": true,
                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                        "conditionalArrayValue": ["INFORMATION", true],
                        /*  "conditional": true,
                          "conditionalVarName": "search" + sl,
                          "conditionalVarValue": "INFORMATION",*/
                    },
                    {
                        "type": "title",
                        "label": "Guardian Details  " + sl,
                        "grid": 12,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                    },
                    {
                        "varName": "nomineeGuardiansName" + sl,
                        "type": "text",
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "multiline": true,
                        "required": true,


                    },

                    {
                        "varName": "nomineeGuardianCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.getGuardianCode,
                        "label": "Guardian Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "required": true,

                    },
                    {
                        "varName": "guardianPhotoId" + sl,
                        "type": "text",
                        "label": "Guardian Photo Id",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",

                    },

                    {
                        "varName": "nomineeGuardianAddress" + sl,
                        "type": "text",
                        "label": "Address 1",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "multiline": true,
                        "required": true,
                    },
                    {
                        "varName": "nomineeGuardianAddress2" + sl,
                        "type": "text",
                        "label": "Address 2",
                        "validationB": "length",
                        "maxLength": 45,
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "multiline": true,
                    },

                    {
                        "varName": "nomineeGuardianCityCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.city,
                        "label": "City Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianStateCode" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.stateCode,
                        "label": "State Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianPostalCode" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "required": true,
                    },

                    {
                        "varName": "nomineeGuardianCountry2" + sl,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.country,
                        "label": "Country",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "YES",
                        "required": true,
                    },
                )

            }

            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.makeReadOnlyObjec(objectForNominee), this.updateComponent,this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }


    };
    makeReadOnlyObjec = (object) => {
        let returnObject = JSON.parse(JSON.stringify(object));

        let returnObjectVariables = returnObject;

        for (let i = 0; i < returnObjectVariables.length; i++) {
            returnObjectVariables[i]["readOnly"] = true;
        }
        return returnObject;


    };
    renderNomineeNumber = () => {
        if (this.state.getData) {
            return (

                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            defaultValue={this.state.inputData["numberOfNominee"]}
                            name="numberOfNominee"
                            //label={field.label}
                            InputProps={{
                                //style:Object.assign(styles,firstStyle),
                                readOnly: true
                            }}
                            //InputLabelProps={{shrink:true}}
                            fullWidth
                            style={{paddingRight: 20}}
                        />
                    </Grid>
                </React.Fragment>


            )
        }
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Details:</b>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "User Name", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />

                    <br/>


                </div>

            )
        }

    };
    renderMakerCumInput = () => {
        return (
            <SecondaryCbFormDetails freeFlag1={this.props.freeFlag1}
                                    customerName={this.props.customerName}
                                    accountNumber={this.props.accountNumber}
                                    solId={this.props.solId} freeFlag4={this.props.freeFlag4}
                                    tagForm={tagForm} appId={this.props.appId}
                                    customerNumber={this.state.customerNumber}
                                    relationCode={this.state.linkrelationCode}
                                    tagingModalCbnumber={this.state.customerNumber}
                                    closeModal={this.tagCbDetailsModalClse}
                                    requestType={this.state.requestType}
                                    checkerEnd={true}
                                    cbNumber={this.state.tagCbNumber}
                                    getTaggingData={this.renderTaggingApi}/>
        )

    };

    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>

                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        fullScreen={true}
                        open={this.state.tagCbDetailsModal}>
                        <DialogContent className={classes.dialogPaper}>
                            {this.renderMakerCumInput()}

                        </DialogContent>

                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.getCropedImage}>
                        <DialogContent className={classes.dialogPaper}>
                            <AssignedCropImage handleSignatureCard={this.handleSignatureCard}
                                               signatureButtonShowInImageCropedPage={this.state.signatureButtonShowInImageCropedPage}
                                               subServiceType={this.props.subServiceType} appId={this.props.appId}
                                               closeModal={this.closeModal}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.errorModalBoolean}>
                        <DialogContent className={classes.dialogPaper}>
                            <ErrorModal title={this.state.errorTittle} closeModal={this.closeModal}
                                        subServiceType={this.props.subServiceType} appId={this.props.appId}
                            />

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert closeModal={this.closeConfirmAlert}/>

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.uploadModal}>
                        <DialogContent>
                            {this.renderMappingImageModal()}

                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            {this.renderMappingImageEditModal()}

                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            <Grid item xs={12}>
                                {this.renderDownLoadFileLink()}
                            </Grid>
                            {this.renderJsonFormFirst()}
                            <Grid item xs={6}>
                                {this.renderNomineeNumber()}
                            </Grid>
                            {this.renderNomineeForm()}

                            {this.renderJsonFormLast()}
                            {this.renderJsonFormTpKycFor()}


                            <Grid item xs={12}>


                            </Grid>

                        </Grid>
                    </ThemeProvider>
                    <center>

                        <Grid item xs={12}>
                            {this.renderDefferalData()}

                        </Grid>

                        {this.renderDedupComponent()}

                        {this.renderDedupComponentMandateAndBeneficiary()}
                        <Grid item xs={12}>

                            {this.renderTagList()}
                        </Grid>
                        {this.renderSdnComponent()}


                    </center>
                    <ThemeProvider theme={theme}>
                        {this.renderRemarksData()}
                        <br/>
                        {/*{this.renderPreviewImage()}*/}

                        {/*<br/>*/}
                        <br/>
                        {this.renderDefferalData()}
                        <div style={{marginTop: "5px"}}>
                            {this.renderUploadButton()}
                            &nbsp;
                            {this.renderAssignedImage()}
                        </div>
                        <br/>
                        {this.renderFlowSUmmeryButton()}

                        <WaiverList appId={this.props.appId}/>
                        <DeferalList appId={this.props.appId}/>
                        <br/>

                        {this.renderRemarks()}
                        <br/>
                        {this.renderSubmitButton()}

                    </ThemeProvider>


                </div>
            </GridList>

        )

    }

}

export default withStyles(styles)(VerifyMakerInboxCase);
