import React from "react";
import CardHeader from "./Card/CardHeader";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import GridItem from "./Grid/GridItem";
import GridContainer from "./Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from 'axios';
import Table from "./Table/Table";
import {backEndServerURL, frontEndServerURL} from "../Common/Constant";
import MenuItem from "@material-ui/core/MenuItem";
import CommonJsonFormComponent from "./JsonForm/CommonJsonFormComponent";
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Dialog, Popover, TextField} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from '@material-ui/core/Paper';
import Pageview from '@material-ui/icons/Pageview';
import Customer from "./360View/Customer";
import Notification from "./NotificationMessage/Notification";
import loader from '../Static/loader.gif';
import CBNotFound from "./workflow/CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import FinacleService from "./workflow/FinacleService";
import {LockerJsonForm} from "./workflow/WorkflowJsonFormRashed"
import Locker from "./workflow/LOCKER/OpeningCSLocker";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "./JsonForm/CustomeTheme2";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import MyValidationDedup from "./JsonForm/MyValidationDedup";
import validator from "validator";
import MyValidation from "./JsonForm/MyValidation";
import LocalstorageEncrypt from "./JsonForm/LocalstorageEncrypt";


const customerOneJsonForm = [
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 2,
        "validation": "numeric",
        "required": true
    },
];


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

const numberOfCustomer = [
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'},


];

class CustomerDedupSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            tableData: [],
            accountData: [[" ", " "]],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            SelectedData: '',
            tabMenuSelect: 'INDIVIDUAL',
            nationalityApiData: [],
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            searchTableData: null,
            searchTableRelatedData: null,
            oldAccountData: [],
            inputData: {},
            dataNotFound: false,
            CustomerModal: false,
            selectedDate: {},
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            alert: false,
            value: "INDIVIDUAL",
            NonIndividualabel: "",
            individualLabel: "Individual A/C",
            content: "INDIVIDUAL",
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            objectForJoinAccount: {},
            handleChangeJoint: 0,
            cbNotFound: false,
            loading: false,
            jointSearchTable: [],
            propritorship: false,
            propritor: false,
            individualDedupData: {},
            jointDedupData: {},
            companyDedupData: {},
            title: "CB Not Found",
            err: false,
            errorArray: {},
            errorMessages: {},
            handleData: false,
            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!"


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClickIndividual = this.handleClickIndividual.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    };


    handleClickIndividual(event) {

        event.stopPropagation();

        this.setState({
            anchorE2: event.currentTarget,
            individualDropdownOpen: true
        });
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: false
        });
    }

    reloadPage = () => {
        this.setState({
            varValue: {},
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            oldAccountData: [],
            inputData: {},
            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!"

        })
    };

    handleMenuItemClick(menuItem) {
        this.handleClose();

        this.setState({
            NonIndividualabel: menuItem,
            content: menuItem,
            value: "More",
            initialization: true
        });
    }

    closeInitialization = () => {
        this.setState({
            initialization: false
        });
    };

    handleMenuItemClickIndividual(menuItem) {

        this.handleClose();
        this.setState({
            individualLabel: menuItem,
            content: menuItem,
            value: "More",
            initialization: true
        });

    }

    renderExistingAccountOpeningForm = (customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        let arrayData = {
            nid: nid,

            passport: passport,

            customerName: customerName,
            phone: phone,
            dob: dob,
            email: email,
            registration: registration
        };

        this.setState({
            existingAcoountOpeningModal: true,
            oldAccountData: arrayData,
            getCustomerId: customerId,
            getAccountType: accountSource,
            newAcoountOpeningModal: false,

        })
    };
    renderNewAccountOpeingForm = () => {
        this.setState({
            newAcoountOpeningModal: true,
            getAccountNumber: this.state.inputData.accountNumber
        })
    };
    CustomerModal = (uniqueId, customerName, customerId, id) => {
        let data = {};
        data.identificationNumber = uniqueId;
        data.cbNumber = customerId;
        data.customerName = customerName;
        data.identity = id;
        LocalstorageEncrypt.encryptStorageFunction.setItem(id, JSON.stringify(data));
        let url = "/Customer/" + id;
        window.open(frontEndServerURL + url)
        /* this.setState({
             CustomerModal: true,
             customerName: customerName,
             cbNumber: customerId,
             IDENTIFICATION_NO: uniqueId

         })*/
    };
    closeCustomerModal = () => {
        this.setState({
            CustomerModal: false,

        })
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false

        })
    };
    closeModal = () => {
        this.setState({
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
        });
        this.state = {
            varValue: [],
            getDedupData: false,
            dedupData: [[" ", " "]],
            propritorshipData: [[" ", " "]],
            relatedData: [[" ", " "]],
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            SelectedData: '',
            tabMenuSelect: 'INDIVIDUAL',
            existingAcoountOpeningModal: false,
            newAcoountOpeningModal: false,
            searchTableData: null,
            searchTableRelatedData: null,
            oldAccountData: [],
            inputData: {},
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            getRadioButtonData: 0,
            id: '',
            alert: false,
            value: "INDIVIDUAL",
            NonIndividualabel: "",
            individualLabel: "Individual A/C",
            content: "INDIVIDUAL",
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            objectForJoinAccount: {},
            handleChangeJoint: 0,
            cbNotFound: false,
            loading: false,
            jointSearchTable: [],
            propritorship: false,
            propritor: false,
            individualDedupData: {},
            jointDedupData: {},
            companyDedupData: {},
            initialization: false,
            getResultPropritor: false,
            notificationMessage: "CB number / NID / Passport / Birth Certificate or Driving License is Required!!",
            getAccountNumber: 0

        }
    };
    radioButtonChange = (event) => {
        this.setState({
            getRadioButtonData: event.target.value,
        })
    };

    createTableData = (randomNumber, id, uniqueId, customerId, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchType) => {
        //this.renderCustomerNumber(sl);
        if (this.state.content === 'Joint Account') {
            if (accountSource === "TRANZWARE") {
                return ([<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                                value={customerId}/>, uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType, "", ""
                        // <button
                        //     className="btn btn-outline-primary btn-sm"
                        //     style={{
                        //         verticalAlign: 'middle',
                        //     }}
                        //
                        //     onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        // >
                        //     {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                        //
                        //     <Pageview/>
                        //     {/*</Fab>*/}
                        //
                        // </button>
                    ]
                )
            } else {
                return (

                    [<input type="radio" onChange={(event) => this.radioButtonChange(event)} name={randomNumber}
                            value={customerId}/>, uniqueId, customerId, accountSource, customerName, nid, passport, tin, matchType,
                        // <button
                        //     className="btn btn-outline-primary btn-sm"
                        //     style={{
                        //         verticalAlign: 'middle',
                        //     }}
                        //
                        //     onClick={() => this.CustomerModal(uniqueId, customerName, customerId, id)}
                        // >
                        //     {/*<Fab size="small" color="secondary" aria-label="pageview">*/}
                        //
                        //     <Pageview/>
                        //     {/*</Fab>*/}
                        //
                        // </button>


                    ])
            }
        }

    };


    createRelatedTableData = (id, uniqueId, mainCB, relatedCB, relatedCustomerName, relationship) => {

        if (this.state.content === 'Joint Account') {
            return ([id, uniqueId, mainCB, relatedCB])

        } else {
            return ([mainCB, relatedCB, relatedCustomerName, relationship, ,

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}

                    onClick={() => this.CustomerModal(uniqueId)}
                >
                    {/*<Fab size="small" color="secondary" aria-label="pageview">*/}

                    <Pageview/>
                    {/*</Fab>*/}

                </button>])

        }
    };

    renderReloading(status) {
        this.setState({

            searchTableData: status,
            searchTableRelatedData: status,

        })
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="error" stopNotification={this.stopNotification} title="Required!!"
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    trimFunction = (emptyString) => {
        if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }


        } else {
            return ""
        }

    };

    emptyStringSet = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let i = 0; i < clone.length; i++) {
            let data = clone[i]["varName"].trim();

            if (this.state.inputData[data] !== null && this.state.inputData[data] !== undefined && !validator.isEmpty(this.trimFunction(this.state.inputData[data]))) {
                /* let getData=this.state.inputData[data];
                 if (getData !== null && getData !== undefined && !validator.isEmpty(this.trimFunction(getData))) {
                     let typeOfString = typeof getData;
                     if (typeOfString === "string" || typeOfString === "number") {
                         return getData.trim()
                     }
                     else if(typeOfString === "object") {
                         return getData.key;
                     }

                 } else {
                     getData = ""
                 }*/
                let getData = this.state.inputData[data];
                let typeOfString = typeof getData;
                if (typeOfString === "string" || typeOfString === "number") {
                    this.state.inputData[data] = (this.state.inputData[data]).trim()
                } else if (typeOfString === "object") {
                    this.state.inputData[data] = (this.state.inputData[data]);
                }

            } else {
                this.state.inputData[data] = ""
            }


        }
        return clone;
    };

    trimFunctionInputData = (emptyString) => {

        if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }


        } else {
            return ""
        }

    };

    getSubmitedForm = (object) => {
        var dependencyField = [];
        var error = false;
        var errorTwo = false;
        let s1 = false;

        if (this.state.content === 'Joint Account') {
            error = MyValidationDedup.CustomValidationJoint(this.emptyStringSet(this.state.objectForJoinAccount), this.state);
            errorTwo = MyValidation.defaultValidation(customerOneJsonForm, this.state);

        }


        this.forceUpdate();

        if (error === true || errorTwo === true) {
            this.setState({
                loading: false
            });
            return 0;

        }


        this.setState({
            loading: true,
            searchTableData: false,
            searchTableRelatedData: false,
            title: "Applicant CB Not Found",
            getError: false,

        });

        console.log(this.state.inputData);
        this.renderReloading(null);


        var postData = {};
        var postDataProprietorship = {};

        if (this.state.content === 'Joint Account') {

            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < this.state.handleChangeJoint; j++) {

                if (this.state.inputData["dob" + j] !== undefined && this.state.inputData["dob" + j] !== -1) {
                    let da = this.state.inputData["dob" + j].split(",");

                    var dateString;

                    if (da != "" && da.length > 0) {

                        let monthDateYear = da[0].split("/");
                        let month, date;

                        if (monthDateYear[0].length === 1)
                            month = "0" + monthDateYear[0];
                        else
                            month = monthDateYear[0];
                        if (monthDateYear[1].length === 1)
                            date = "0" + monthDateYear[1];
                        else date = monthDateYear[1];
                        dateString = monthDateYear[2] + "-" + month + "-" + date;

                    } else {
                        dateString = "1000-01-01";
                    }
                } else {
                    dateString = "1000-01-01";
                }

                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": this.trimFunctionInputData((this.state.inputData["cbNumber" + j])),
                    "nid": this.trimFunctionInputData((this.state.inputData["nid" + j])),
                    "passport": this.trimFunctionInputData((this.state.inputData["passport" + j])),
                    "customerName": this.trimFunctionInputData((this.state.inputData["customerName" + j])),
                    "dob": dateString,
                    "gender": "",
                    "fathersName": "",
                    "mothersName": "",
                    "nationality": this.trimFunctionInputData((this.state.inputData["nationality" + j])),
                    "email": this.trimFunctionInputData((this.state.inputData["email" + j])),
                    "phone": "88" + this.trimFunctionInputData((this.state.inputData["phone" + j])),
                    "tin": this.trimFunctionInputData((this.state.inputData["tin" + j])),
                    "registrationNo": this.trimFunctionInputData((this.state.inputData["registrationNo" + j])),
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;

        }

        this.renderReloading(false);
        if (this.state.content === 'Joint Account') {
            let tableArray = [];
            let relatedTableArray = [];

            //set 02/02/1983, 00:00:00 to 1983-02-02 format

            let url = backEndServerURL + "/jointDedup";
            console.log(postData);

            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                        console.log(response.data);
                        this.setState({
                            sourceMappingData: response.data.sourceMapping,
                            jointDedupData: response.data

                        });

                        let jointDedupSearchTable = [];
                        let sdnTableArray = [];
                        var notFound = 0;
                        for (let i = 0; i < this.state.handleChangeJoint; i++) {
                            let number = i + 1;
                            if (this.state.inputData["cbNumber" + i] !== "" && this.state.inputData["cbNumber" + i] !== undefined && this.state.inputData["cbNumber" + i] !== null && !validator.isEmpty(this.trimFunction(this.state.inputData["cbNumber" + i])) && (response.data.highMatchCustomers[i].length < 1 && response.data.mediumMatchCustomers[i].length < 1)) {
                                this.setState({
                                    cbNotFound: true,
                                    getError: true,

                                    title: this.numberToCharacter(number + 1) + " Applicant " + " CB Not Found",
                                    getResultPropritor: true,
                                    searchTableData: false,
                                    searchTableRelatedData: false,
                                    loading: false,
                                    jointSearchTable: [],
                                    relatedData: [],
                                });
                                notFound = this.state.handleChangeJoint;
                            }

                        }

                        if (notFound !== this.state.handleChangeJoint) {
                            console.log(".....one........");
                            if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {
                                console.log(".....one if........");
                                var randomNumber = 0;

                                response.data.highMatchCustomers.map((dedup, i) => {
                                    let customer = [];
                                    randomNumber = "joint" + randomNumber + 1;
                                    dedup.map((dedup) => {

                                        customer.push(this.createTableData(randomNumber, dedup.id, dedup.identification_no, dedup.maincustid, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, "Higher-Match"));
                                    });
                                    jointDedupSearchTable.push(customer);

                                });
                                console.log(jointDedupSearchTable);

                                this.setState({
                                    jointSearchTable: jointDedupSearchTable,
                                })

                            }
                        }


                        if (!this.state.cbNotFound) {
                            console.log(".....two........");
                            if (response.data.relatedCustomers !== null && Array.isArray(response.data.relatedCustomers)) {
                                response.data.relatedCustomers.map((dedup) => {

                                    relatedTableArray.push(this.createRelatedTableData(dedup.id, dedup.identificationNoMod, dedup.mainCB, dedup.relatedCB, dedup.relatedCustomerName, dedup.relationship));

                                });
                            }
                            if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                response.data.sdnResult.map((data) => {
                                    sdnTableArray.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));

                                });
                            }

                            this.setState({
                                relatedData: relatedTableArray,
                                sdnData: sdnTableArray,
                                searchTableData: true,
                                searchTableSdnData: true,
                                searchTableRelatedData: true,
                                loading: false,
                                getsearchValue: this.state.inputData,

                            })
                        }

                    }
                )
                .catch((error) => {

                    this.setState({
                        // jointSearchTable: [],
                        relatedData: [],
                        sdnData: [],
                        searchTableSdnData: true,
                        searchTableData: true,
                        searchTableRelatedData: true,
                        loading: false,
                    })

                });
        }

    };

    handleChangeJoint = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            let x = option.title - 1;
            this.setState({
                handleChangeJoint: x
            })
        }

    };

    numberToCharacter = (i) => {
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };

    componentDidMount() {
        this.dynamicApiCall("nationality", "country")
    }

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    nationalityApiData: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    onKeyDownForDedup = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.getSubmitedForm(event);

        }

    };

    handleSubmitJointAccountForm = () => {

        if (this.state.handleChangeJoint > 0) {
            let objectForJoinAccount = [];

            var sl;
            for (var i = 0; i < this.state.handleChangeJoint; i++) {
                sl = i + 2;
                /*nationalityApiData*/

                objectForJoinAccount.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " Applicant ",
                        "grid": 12,

                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "textDedup",
                        "label": "CB Number",
                        "grid": 2,

                    },
                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "grid": 2,

                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",

                        "grid": 2,


                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,


                    },
                    {
                        "varName": "registrationNo" + i,
                        "type": "textDedup",
                        "validation": "birthCertificateNumber",
                        "label": "Birth Cer./Driving License +",
                        "grid": 2,


                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "grid": 2,


                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "phonePrefix": true,
                        "validation": "phone",
                        "label": "Phone Number *",
                        "asteriks": true,
                        "grid": 2,

                    },

                    {
                        "varName": "email" + i,
                        "type": "textDedup",
                        "validation": "email",
                        "label": "Email",

                        "grid": 2,
                    },


                    {
                        "varName": "tin" + i,
                        "type": "textDedup",
                        "validation": "etin",
                        "label": "E-Tin",
                        "grid": 2,


                    },
                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.state.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                )


            }
            this.setState({
                objectForJoinAccount: objectForJoinAccount,
                content: "Joint Account"

            });
            this.renderSearchForm();

        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    individualLocker = () => {

        let error = MyValidation.defaultValidation(customerOneJsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {

                        if (response.data.message === "No Account Found!") {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: 'No Account Found!'
                            });
                        } else {
                            this.setState({
                                loading: false,
                                newAcoountOpeningModal: true,
                                getAccountNumber: this.state.inputData.accountNumber
                            })
                        }
                    } else {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'Unable to connect with Finacle API!'
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: 'Unable to connect with Finacle API!'
                    });
                })
        }

    };

    renderSearchForm = () => {

        if (this.state.content === 'INDIVIDUAL' || this.state.content === 'Individual A/C') {
            return (

                <Grid item xs={12}>
                    <Grid container>

                        <ThemeProvider theme={theme}>

                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, customerOneJsonForm, this.updateComponent)
                            }
                            <Grid item xs={9}>

                                <button style={{
                                    marginTop: "18px"
                                }} onClick={this.individualLocker} className="btn btn-outline-primary btn-sm">
                                    Search
                                </button>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>


                        </ThemeProvider>


                    </Grid>


                </Grid>

            )


        } else if (this.state.content === 'Joint A/C') {
            return (
                <Grid item xs={12}>
                    <Grid container>

                        <ThemeProvider theme={theme}>
                            <Grid item xs={12}>
                                <label className="input-label-common">Number Of Customer</label>
                            </Grid>
                            <Grid item xs={2}>
                                <Autocomplete onChange={(event, option) => this.handleChangeJoint(event, option)}
                                    //defaultValue={this.state.inputData["numberOfNominee"]}
                                              options={numberOfCustomer} getOptionLabel={option => option.title}
                                              renderInput={(params) => <TextField {...params} variant="outlined"

                                                                                  style={{paddingRight: 20}}
                                                                                  fullWidth/>}
                                />
                            </Grid>


                            <Grid item xs={9}>

                                <button

                                    onClick={this.handleSubmitJointAccountForm}
                                    className="btn btn-danger btn-sm">
                                    GO
                                </button>
                            </Grid>
                            <Grid item xs={12}>
                            </Grid>


                        </ThemeProvider>


                    </Grid>


                </Grid>

            )
            /* return (
                 <Grid item xs={12}>
                     <TextField name="jointAccount" label="Number Of Customer" onChange={this.handleChangeJoint}/>

                     <div>
                         <br/>
                         <br/>
                         <center>
                             <button onClick={this.handleSubmitJointAccountForm} className="btn btn-danger">
                                 GO
                             </button>
                         </center>
                     </div>
                 </Grid>
             )*/
        } else if (this.state.content === 'Joint Account') {

            return (

                <section>
                    <h4 style={{color: 'red', textAlign: 'center'}}>1st Applicant</h4>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, customerOneJsonForm, this.updateComponent)
                    }
                    <Grid container spacing={0}>
                        {CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForJoinAccount, this.updateComponent, this.onKeyDownForDedup)}
                        <Grid item xs={2}>
                            {
                                this.renderSearchButton()
                            }
                        </Grid>
                    </Grid>
                </section>
            )
        } else {
        }
    };
    handleChange = (name, value) => {

        if (value === 'INDIVIDUAL') {
            this.setState({individualLabel: 'Individual A/C', initialization: true})
        }
        if (value === 'NONINDIVIDUAL') {
            this.setState({
                NonIndividualabel: 'Proprietorship A/C',
                "individualLabel": "",
                initialization: true
            })
        }
        this.setState({

            tabMenuSelect: value, content: value,
            searchTableData: null,
            searchTableRelatedData: null,
        })
    };

    renderJointSearchtabledata = () => {
        console.log(this.state.jointSearchTable);
        return (
            this.state.jointSearchTable.map((tableData, index) => {
                return (

                    <div>
                        <center>
                            <br/>
                            <Grid item xs={12}>
                                <h3>{this.numberToCharacter(index + 2)} Applicant</h3>
                            </Grid>
                        </center>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Select", "Customer ID", "CB No", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match-Type"]}

                            tableData={tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />
                    </div>

                )
            })
        )


    };


    searchTableData = (style) => {
        if (this.state.searchTableRelatedData && this.state.content === 'Joint Account') {

            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Search Result</h5>

                        </CardHeader>
                    </paper>
                    <div>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'left',
                                position: "absolute",
                                marginTop: "5px",
                                right: 10,

                            }}
                            onClick={() => this.renderNewAccountOpeingForm()}>
                            New Locker
                        </button>

                    </div>
                    <br/>
                    <br/>
                    <Table

                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Account Number", "Applicant Name", "Mobile Number", "NID"]}

                        tableData={this.state.relatedData}
                        tableAllign={['left', 'left', 'left']}
                    />


                    <br/>


                </div>


            )


        }


    };
    searchTableRelatedData = (style) => {
        if (this.state.searchTableRelatedData && (this.state.content === 'Joint Account' && this.state.content !== 'Individual A/C' && this.state.content !== 'INDIVIDUAL')) {
            return (
                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>Individual Result</h5>

                        </CardHeader>
                    </paper>
                    {this.renderJointSearchtabledata()}
                    <br/>
                </div>


            )


        }
    };
    searchTableSDNData = (style) => {

        if (this.state.searchTableRelatedData && this.state.content === 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>


                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.sdnData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>

            )
        } else if (this.state.searchTableRelatedData && this.state.content !== 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>SDN Verification</h5>

                        </CardHeader>
                    </paper>


                    <Table
                        tableHeaderColor="primary"

                        tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                        tableData={this.state.sdnData}
                        tableAllign={['left', 'left', 'left', 'left', 'left']}
                    />


                </div>

            )
        }

    };
    searchTableNidVerification = (style) => {

        if (this.state.searchTableRelatedData && this.state.content === 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID Verification </h5>

                        </CardHeader>
                    </paper>

                    <div>

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'left',
                                position: "absolute",
                                right: 10,
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        >
                            NID Check

                        </button>

                    </div>
                    <br/>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Father Name", "NID"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />


                </div>

            )
        } else if (this.state.searchTableRelatedData && this.state.content !== 'Joint Account') {

            return (

                <div>
                    <paper>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h5>NID Verification </h5>

                        </CardHeader>
                    </paper>

                    <div>

                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'left',
                                position: "absolute",
                                right: 10,
                                marginTop: "5px",
                                marginBottom: "5px",
                            }}
                        >
                            NID Check

                        </button>

                    </div>
                    <br/>
                    <br/>
                    <Table
                        tableHeaderColor="primary"
                        tableHead={["Customer Name", "Father Name", "NID"]}
                        tableData={[
                            ["", "", ""],


                        ]}
                        tableAllign={['left', 'left', 'left']}
                    />


                </div>

            )
        } else {

        }

    };

    renderSearchButton = () => {
        if (this.state.content === "Joint Account") {
            return (
                <button
                    className="btn btn-outline-primary  btn-sm"
                    style={{
                        marginTop: "15px"
                    }}
                    onClick={this.getSubmitedForm}

                >
                    Search
                </button>
            )
        }
    };

    render() {

        const {value} = this.state;
        const open = Boolean(this.state.anchorEl);

        const {classes, onClose} = this.props;
        return (
            <section>
                {/*{this.renderNotification()}*/}
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.CustomerModal}

                >
                    <DialogContent className={classes.dialogPaper}>

                        <Customer cbNumber={this.state.cbNumber} customerName={this.state.customerName}
                                  closeModal={this.closeCustomerModal}
                                  IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}/>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    //fullScreen={true}
                    open={this.state.existingAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <FinacleService
                            closeModal={this.closeModal}
                            removeSearchResult={this.removeSearchResult}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            AccountType="Existing"
                            serviceType="ExistAccountOpening"
                            accountOpeningType="ExistAccountOpening"
                            subServiceType={this.state.content}
                            customerType={this.state.content}
                            getType={this.props.getType}
                            getCustomerId={this.state.getCustomerId}
                            individualDedupData={this.state.individualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            searchValue={this.state.getsearchValue}

                        />
                        {/*  <DedupLiabilityService
                            closeModal={this.closeModal}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            getType={this.props.getType}
                            individualDedupData={this.state.individualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            searchValue={this.state.getsearchValue}

                        />*/}
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    //fullScreen={true}
                    open={this.state.newAcoountOpeningModal}>
                    <DialogContent className={classes.dialogPaper}>

                        <Locker
                            selected={this.state.getRadioButtonData}
                            closeModal={this.closeModal}
                            getAccountType={this.state.getAccountType}
                            accountType={this.state.tabMenuSelect}
                            jointAccountCustomerNumber={this.state.handleChangeJoint}
                            workplace={LocalstorageEncrypt.encryptStorageFunction.getItem('workplace')}
                            serviceType="Locker Opening"
                            title="Locker Opening"
                            subServiceType={this.state.content}
                            individualDedupData={this.state.individualDedupData}
                            jointDedupData={this.state.jointDedupData}
                            companyDedupData={this.state.companyDedupData}
                            searchValue={this.state.getsearchValue}
                            commonJsonForm={LockerJsonForm}
                            accountNumber={this.state.getAccountNumber}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>

                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            getAccountType={this.state.getAccountType}
                            title={this.state.title}
                            accountType={this.state.tabMenuSelect}
                            serviceType="Account Opening"
                            subServiceType={this.state.content}
                            searchValue={this.state.getsearchValue}

                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>

                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <paper>

                                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                        <h5>Locker Account Opening </h5>


                                    </CardHeader>
                                </paper>
                                <CardHeader color="red">

                                    <h6 color="red" className={classes.cardTitleWhite}>


                                        <Paper>
                                            <Tabs
                                                indicatorColor="secondary"
                                                textColor="secondary"
                                                aria-label="icon label tabs example"
                                                value={this.state.tabMenuSelect}
                                                onChange={this.handleChange}
                                            >
                                                <Tab
                                                    classes={{wrapper: classes.Tab}}
                                                    value="INDIVIDUAL"
                                                    label={<div>
                                                        <a>Select Account Type</a><br/>
                                                        <a style={{color: "#999999"}}> {this.state.individualLabel + "    "} </a>
                                                    </div>}
                                                    onClick={this.handleClickIndividual}
                                                    /*  value="INDIVIDUAL"
                                                      label="INDIVIDUAL A/C Opening"
                                                      onClick={() => this.setState({content: "INDIVIDUAL"})}*/

                                                />

                                                <Popover
                                                    open={this.state.individualDropdownOpen}
                                                    anchorEl={this.state.anchorE2}
                                                    onClose={this.handleClose}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => this.handleMenuItemClickIndividual("Individual A/C")}>
                                                        Individual A/C
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => this.handleMenuItemClickIndividual("Joint A/C")}>
                                                        Joint A/C
                                                    </MenuItem>


                                                </Popover>
                                            </Tabs>
                                        </Paper>
                                    </h6>
                                </CardHeader>
                            </div>
                            <Paper square>
                                <CardBody>
                                    <ThemeProvider theme={theme}>
                                        {this.renderSearchForm()}
                                    </ThemeProvider>
                                </CardBody>
                            </Paper>

                        </Card>
                    </GridItem>

                </GridContainer>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>


                            {this.searchTableData()}

                        </Card>
                    </GridItem>

                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableRelatedData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableSDNData()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>


                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>

                                {this.searchTableNidVerification()}
                            </div>
                        </Card>
                    </GridItem>

                </GridContainer>

            </section>
        )


    }

}


export default withStyles(styles)(CustomerDedupSearch);

