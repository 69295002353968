import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import Notification from "../../NotificationMessage/Notification";
import loading from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import TableComponent from "../CommonComponent/TableComponent";
import CreateHierarchy from "./CreateHierarchy";

const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let searchInput = [
    {
        "varName": "requestType",
        "type": "select",
        "required": true,
        "onKeyDown": true,
        "label": "Select Request Type",
        "enum": [
            "Region",
            "Cluster",
            "Branch"
        ],
        "grid": 2,

    },
];

class BusinessHierarcy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            loading: false,
            cbNotFound: false,
            getData: false,
            varValue: {},
            title: "",
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            openHierarchy: false,
            err: false,
            totalRow: 0,
            errorArray: {},
            errorMessages: {},
            tableData: [],
            rowsPerPage: 500,
            tableColumns: [
                {title: "SL", field: "sl"},
                {title: "Hierarchy Name", field: "hierarchy_name"},
                {title: "Parent Name", field: "parent_name"},
                {title: "Status", field: "status"},
            ]
        }
    }

    functionForGetData = (data) => {
        if (data.varName === "requestType") {
            this.fetchTableData()
        }
    };

    fetchTableData = () => {
        this.setState({
            loading: true,
            getData: false
        });
        let segmentValue = this.state.inputData.requestType === "Region" ? "REGION" : this.state.inputData.requestType === "Cluster" ? "CLUSTER" : "BRANCH";
        let url = backEndServerURL + "/workplaceUnit/get/" + segmentValue;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let data = [];
                response.data.map((item, index) => {
                    data.push({
                        sl: index + 1,
                        hierarchy_name: item.unitName,
                        parent_name: item.parentName,
                        status: item.status
                    })
                });
                this.setState({
                    totalRow: response.data.length,
                    tableData: data,
                    getData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    getData: false
                })
            });
    };


    convertDate = (value) => {
        if (value && value !== -1) {
            let date = value.split('T')[0].split("-");
            return `${date[1]}/${date[2]}/${date[0]}`
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderCloseIcon = () => {
        return (
            <h4 style={{color: "white"}} >Business Hierarchy</h4>
        )
    };

    closeModalCBNotFound = () => {
        this.setState({
            openHierarchy: false,
            cbNotFound: false
        })
    };
    closeModal = () => {
        this.setState({
            openHierarchy: false,
        }, this.fetchTableData)
    };

    triggerCreateFunction = (event, data) => {
        event.preventDefault();
        this.setState({
            openHierarchy: true
        })
    };

    handleChangePage = (pageNumber) => {

    };

    searchForm = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, searchInput, this.updateComponent, "", this.functionForGetData)

    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderCloseIcon()}
                </CardHeader>
                <CardBody>
                    <ThemeProvider theme={theme}>
                        {this.searchForm()}
                        <Grid item xs={12}>
                            <br/>
                        </Grid>
                        {(this.state.inputData.requestType && this.state.getData) &&
                        <TableComponent loader={this.state.loading} tableData={this.state.tableData}
                                        rowsPerPage={this.state.rowsPerPage} tableColumns={this.state.tableColumns}
                                        totalRow={this.state.totalRow} selection={false} page={0}
                                        handleChangePage={this.handleChangePage} onRowClick={() => {
                            return false
                        }} tableButton={[{name: "Create", triggerFunction: this.triggerCreateFunction}]}
                        />}
                        <Grid container>
                            {this.renderNotification()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.openHierarchy}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CreateHierarchy
                                        closeModal={this.closeModal}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loading} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>

        );


    }

}

export default withStyles(styles)(BusinessHierarcy);
