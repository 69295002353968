import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DataCaptureCS from "./DataCaptureCS";
import {CSJsonFormMaintenanceWithFile} from './WorkflowJsonForm';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "280",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#142398",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};


class MaintenanceWIthFile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCategory: 'null', SelectedData: "",
        }

    }


    handleChangeSelected = (name, value) => {
        this.setState({
            SelectedData: value,
        });


    };

    renderSelectMenu = () => {
        return (
            <FormControl style={{minWidth: 280}}>
                <InputLabel>Maintenance Type</InputLabel>

                <Select name="select" value={this.state.SelectedData}
                        onChange={event => this.handleChangeSelected(event.target.name, event.target.value)}>
                    <MenuItem key="address" value="address">Address Change</MenuItem>
                    <MenuItem key="contact" value="contact">Contact Number Change</MenuItem>,
                    <MenuItem key="email" value="email">Email Address Change</MenuItem>
                    <MenuItem key="nominee" value="nominee">Change Of Nominee</MenuItem>
                    <MenuItem key="tenor" value="tenor">FD Tenor Change</MenuItem>
                    <MenuItem key="scheme" value="scheme">Scheme Change</MenuItem>
                    <MenuItem key="maturity" value="maturity">Maturity/Disposal Instruction Change</MenuItem>
                    <MenuItem key="title" value="title">Account Title Change</MenuItem>
                    <MenuItem key="link" value="link">Link Account Change</MenuItem>
                    <MenuItem key="etin" value="etin">E-Tin Update</MenuItem>
                    <MenuItem key="dormant" value="dormant">Dormant Account Reactivation</MenuItem>
                    <MenuItem key="inputFiled" value="inputFiled">Account Maintenance With Input Field</MenuItem>
                </Select>
            </FormControl>

        )
    };
    renderJsonForm = () => {
        if (this.state.SelectedData) {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="MaintenanceWithPrintFile"
                                   subserviceType={this.state.SelectedData} jsonForm={CSJsonFormMaintenanceWithFile}/>);
        }

    };

    render() {
        const {classes} = this.props;


        return (
            <section>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Maintenance </h4>

                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderSelectMenu()}
                                    {this.renderJsonForm()}
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );


    }

}

export default withStyles(styles)(MaintenanceWIthFile);
