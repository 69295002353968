import React from "react";
import CardHeader from "../Card/CardHeader";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../Common/Constant";
import axios from 'axios';
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
};


const jsonForm = {
    "variables": [
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
        },
        {
            "varName": "customerId",
            "type": "text",
            "label": "Customer Id",
            "required": true,

        }, {
            "varName": "DescriptionOfGrouping",
            "type": "text",
            "label": "Description Of Grouping",
            "required": true,

        },


    ],

};
const jsonFormAddMember = {
    "variables": [
        {
            "varName": "customerName",
            "type": "text",
            "label": "Customer Name",
        },
        {
            "varName": "customerId",
            "type": "text",
            "label": "Customer Id",
            "required": true,

        }


    ],

};

class GroupView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            varValue: [],
            getData: [],
            dropdownSearchData: [],
            getSubSectorData: false,
            getSectorData: false,
            redirectLogin:false,

        }
    }

    firstLetterUpperCase = (string) => {
        var str = string.trim();
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    };

    getSubmitedForm = (object) => {
        if (this.props.member === 'yes') {
            let url = backEndServerURL + "/grouping/addMember";

            axios.post(url, {
                "customerName": object.customerName,
                "customerId": object.customerId,
                "groupUid": this.props.groupUid,

            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.onAdd(object);


                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }
                })
        }


    };

    render() {
        {

            Functions.redirectToLogin(this.state)

        }


        const {classes} = this.props;
        return (
            <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 className={classes.cardTitleWhite}>{this.props.name}</h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                {this.renderEditForm()}

                            </div>

                        </CardBody>
                    </Card>
                </GridItem>


            </GridContainer>

        )
    }
}

export default withStyles(styles)(GroupView);
