import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../Table/Table";
import {csReturnPrepaidCardRequisition, prepaidCardIssuanceCsAfterSearch, prepaidCardIssuanceCsSearch} from "./JsonForm"
import FunctionForGetAcStatus from "../../workflow/CommonComponent/FunctionForGetAcStatus";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import CommonApi from "../../workflow/Maintenance/common/CommonApi";

const upload = [
    {
        type: "empty",
        grid: 12
    },
    {
        type: "empty",
        grid: 12
    },
    {
        varName: "photo",
        type: "file",
        label: "Photo Upload",
        accept: ".jpeg,.jpg",
        grid: 2,
    },
    {
        varName: "applicationForm",
        type: "file",
        label: "Application Form Upload",
        accept: ".pdf",
        grid: 2,
    },
    {
        varName: "nationalIdUpload",
        type: "file",
        label: "National ID Upload",
        accept: ".pdf",
        grid: 2,
    },
    {
        varName: "kyc",
        type: "file",
        label: "KYC Upload",
        accept: ".pdf",
        grid: 2,
    },
    {
        varName: "signature",
        type: "file",
        label: "Signature Upload",
        accept: ".jpeg,.jpg",
        grid: 2,
    },
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class PrepaidCardIssuance extends Component {
    constructor() {
        super();
        this.state = {
            appData: "",
            fileUploadData: {},
            appId: "",
            err: false,
            inputData: {},
            selectedDate: {},
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            getData: false,
            searchData: false,
            notificationMessage: "",
            alert: false,
            title: '',
            getImageLink: [],
            cardList: [],
            getImageBoolean: false,
            imageName: [],
            city: [],
            state: [],
            cbNotFound: false,
            loading: true,
            getRemarks: [],
            showCity: true,
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    functionForGetRemarks = () => {
        let remarksArray = [];
        let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
        axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            response.data.map((data) => {
                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
            });
            this.setState({
                getRemarks: remarksArray
            })
        })
            .catch((error) => {
                console.log(error)
            })
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    dynamicApiCall = (enumType, apiType, url) => {
        let apiUrl = backEndServerURL + "/" + url;
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    if (enumType === "city") {
                        this.findByVarNameApiData("city", response.data);
                        this.setState({
                            city: response.data
                        })
                    }
                    if (enumType === "state") {
                        this.findByVarNameApiData("region", response.data);
                        this.setState({
                            state: response.data
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = prepaidCardIssuanceCsAfterSearch;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                prepaidCardIssuanceCsAfterSearch[i].enum = getValue;
            }
        }
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        if (value && (typeof value !== "string")) {
            return value
        } else {
            for (let i = 0; i < jsonArray.length; i++) {
                let jsonObject = jsonArray[i];
                if (value === jsonObject.key) {
                    return jsonObject;
                }
            }
        }
    };


    componentDidMount() {
        // this.dynamicApiCall("city", "city", "getCityNameForGiftCard");
        this.dynamicApiCall("state", "state", "getRegionNameForGiftCard");
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + "/variables/" + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.functionForGetRemarks();
                    this.setState({
                        varValue: response.data,
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        getData: true,
                        searchData: true,
                        loading: false,
                        appId: this.props.appId,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    this.setState({
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    functionForGettingRmName = (event, data) => {
        if (data === "rmCode") {
            this.setState({
                loading: true
            });
            this.state.inputData.showRmName = false;
            this.forceUpdate();
            let getRmName = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.rmCode;
            axios.get(getRmName, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.rmName = response.data;
                    this.state.varValue.rmName = response.data;
                    this.state.inputData.showRmName = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.copyJson(this.state.inputData),
                        invalidRm: false,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        invalidRm: true,
                        cbNotFound: true,
                        title: "Invalid RM Code!",
                        loading: false
                    });
                })
        }
    };

    getExistingClientId = (postData) => {
        return new Promise((resolve, reject) => {
            CommonApi.checkEtbOrNtb(this.copyJson(postData)).then((etbResponse) => {
                let inputData = {...this.state.inputData};
                if (etbResponse.data.etb === false) {
                    inputData.customerType = "New Customer"
                } else {
                    inputData.customerType = "Existing Customer";
                    inputData.existingClientId = etbResponse.data.clientId
                }
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                });
                resolve(inputData)
            }).catch((error) => {
                reject(error)
            })
        })
    };

    handleSearch = () => {
        let error = MyValidation.defaultValidation(this.copyJson(prepaidCardIssuanceCsSearch), this.state);
        this.forceUpdate();
        if (error) {
            return 0;
        }
        this.setState({
            loading: true,
            getData: false,
            searchData: false,
        });
        let inputData = {...this.state.inputData};
        if (this.state.inputData.selectCustomerType === "New Customer") {
            this.getClientInfo().then((clientResponse) => {
                inputData.cbNumber = this.state.inputData.clientId;
                for (let item of prepaidCardIssuanceCsSearch) {
                    item.readOnly = true
                }
                this.setState({
                    inputData: inputData,
                    cardList: clientResponse,
                    varValue: this.copyJson(inputData),
                    showValue: true,
                    getData: true,
                    searchData: true,
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: error.response.data.message
                });
            });
        } else {
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNumber;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    FunctionForGetAcStatus.getAccountStatus(response.data.accountNumber).then((acStatus) => {
                        if (acStatus.status === "found" && acStatus.value === "A") {
                            inputData.accountStatus = acStatus.value;
                            inputData.accountNumber = response.data.accountNumber;
                            inputData.customerName = response.data.customerName;
                            inputData.nameOnCard = response.data.customerName;
                            inputData.CBNumber = response.data.cbNumber;
                            inputData.cbNumber = this.state.inputData.clientId;
                            inputData.casaSolId = response.data.solid;
                            inputData.solId = response.data.solid;
                            let getDataByCB = backEndServerURL + "/cbsCustomerGet/" + response.data.cbNumber;
                            axios.get(getDataByCB, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (response.data.NIDBLOCK) {
                                        let postData = {};
                                        postData.nid = response.data.NIDBLOCK.referenceNum;
                                        postData.smartCard = response.data.RSNIDBLOCK ? response.data.RSNIDBLOCK.referenceNum : undefined;
                                        postData.clientId = this.state.inputData.clientId;
                                        inputData.nationalId = response.data.NIDBLOCK.referenceNum;
                                        this.functionForValidityCheck(postData).then((eResponse) => {
                                            if (eResponse.data !== "Prepaid Card Exists") {
                                                this.getExistingClientId(postData).then((etbResponse) => {
                                                    inputData = {...inputData, ...etbResponse};
                                                    inputData.fatherName = response.data.fatherName;
                                                    inputData.motherName = response.data.motherName;
                                                    if (response.data.COMMPH1BLOCK) {
                                                        inputData.mobileNumber = response.data.COMMPH1BLOCK.phoneNumLocalCode;
                                                        inputData.countryCode = response.data.COMMPH1BLOCK.phoneNumCountryCode
                                                    }
                                                    if (response.data.COMMEMLBLOCK) {
                                                        inputData.email = response.data.COMMEMLBLOCK.emailInfo;
                                                    }
                                                    if (response.data.MailingBLOCK) {
                                                        let tmpAdd = response.data.MailingBLOCK.addrLine1 ? ", " + response.data.MailingBLOCK.addrLine1 : "";
                                                        inputData.mailingAddress = response.data.MailingBLOCK.addrLine1 + tmpAdd;
                                                    }
                                                    if (response.data.PASSPORTBLOCK) {
                                                        inputData.passportNumber = response.data.PASSPORTBLOCK.referenceNum;
                                                    }
                                                    inputData.dob = response.data.birthDate;
                                                    this.getClientInfo().then((clientResponse) => {
                                                        for (let item of prepaidCardIssuanceCsSearch) {
                                                            item.readOnly = true
                                                        }
                                                        this.setState({
                                                            inputData: inputData,
                                                            cardList: clientResponse,
                                                            varValue: this.copyJson(inputData),
                                                            showValue: true,
                                                            getData: true,
                                                            searchData: true,
                                                            loading: false
                                                        })
                                                    }).catch((error) => {
                                                        this.setState({
                                                            loading: false,
                                                            cbNotFound: true,
                                                            title: error.response.data.message
                                                        });
                                                    });
                                                })
                                            } else {
                                                this.setState({
                                                    cbNotFound: true,
                                                    title: "Prepaid card already Exist for this NID!",
                                                    disabled: false,
                                                    loading: false
                                                })
                                            }
                                        }).catch((err) => {
                                            this.setState({
                                                loading: false,
                                                cbNotFound: true,
                                                title: err.response.data.message
                                            });
                                        });

                                    } else {
                                        this.setState({
                                            loading: false,
                                            cbNotFound: true,
                                            title: "NID number not found!"
                                        });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({
                                        loading: false,
                                        cbNotFound: true,
                                        title: error.response.data.message
                                    });
                                });
                        } else {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: "Account is not active!"
                            });
                        }
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }
    };

    getClientInfo = () => {
        return new Promise((resolve, reject) => {
            let getAccountUrl = backEndServerURL + "/intgr/instantGiftCard/" + this.state.inputData.clientId;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let cardList = [];
                    for (let item of response.data) {
                        cardList.push({
                            key: item.cardNumber,
                            value: item.maskedCardNumber,
                            status: item.cardState
                        })
                    }
                    for (let item of prepaidCardIssuanceCsAfterSearch) {
                        if (item.varName === "cardNumber") {
                            item.enum = cardList
                        }
                    }
                    resolve(cardList);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        })
    };


    functionForValidityCheck = (postData) => {
        return new Promise((resolve, reject) => {
            let url = backEndServerURL + "/prepaidCardAvailabilityCheckByNid";
            axios.post(url, this.copyJson(postData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    console.log(error);
                    reject(error)
                });
        })
    };


    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let error = MyValidation.defaultValidation(prepaidCardIssuanceCsAfterSearch, this.state);
        let fileError = MyValidation.fileValidation(upload[2], this.state);
        let fileErrorTwo = MyValidation.fileValidation(upload[6], this.state);
        this.forceUpdate();
        if (error === true || fileError === true || fileErrorTwo === true) {
            this.setState({
                loading: false
            });
            return 0;
        } else if (this.state.inputData.nameOnCard.length > 19) {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: 'Name on Card Not More Than 19 Character'
            });
            return 0;
        } else {
            let postData = {};
            postData.nid = this.state.inputData.nationalId;
            postData.smartCard = this.state.inputData.smartCard;
            postData.clientId = this.state.inputData.clientId;
            let {nationalIdUpload, photo, applicationForm, kyc, signature} = this.state.fileUploadData;
            if (this.state.inputData.bom_approval !== "RETURN" && (photo === undefined || nationalIdUpload === undefined || applicationForm === undefined || kyc === undefined || signature === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "National Id, Photo, Application form, KYC, and Signature upload required!",
                    loading: false
                })
            } else if (this.state.fileUploadData !== undefined && Object.keys(this.state.fileUploadData).length > 0) {
                if (("nationalId" in this.state.inputData) || ("smartCard" in this.state.inputData)) {
                    this.checkExistOrNot(postData)
                        .then((response) => {
                            this.getExistingClientId(postData)
                                .then((existingClientResponse) => {
                                    let files = [];
                                    if (photo !== undefined) {
                                        files.push({file: photo, type: "Photo Upload"})
                                    }
                                    if (applicationForm !== undefined) {
                                        files.push({file: applicationForm, type: "Application Form Upload"})
                                    }
                                    if (nationalIdUpload !== undefined) {
                                        files.push({file: nationalIdUpload, type: "National ID Upload"})
                                    }
                                    if (kyc !== undefined) {
                                        files.push({file: kyc, type: "KYC Upload"})
                                    }
                                    if (signature !== undefined) {
                                        files.push({file: signature, type: "Signature Upload"})
                                    }

                                    let formData = new FormData();
                                    formData.append("appId", this.state.appId);
                                    files.map((item, index) => {
                                        console.log(item.file, item.type);
                                        formData.append("file", item.file);
                                        formData.append("type", item.type)
                                    });

                                    let fileUploadPath = backEndServerURL + "/case/upload";
                                    axios({
                                        method: 'post',
                                        url: fileUploadPath,
                                        data: formData,
                                        headers: {
                                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                                            'content-type': 'multipart/form-data'
                                        }
                                    }).then((response) => {
                                        this.functionForCaseRoute()
                                    }).catch((error) => {
                                        this.setState({
                                            cbNotFound: true,
                                            title: "File Upload failed!",
                                            loading: false
                                        })
                                    })
                                })
                                .catch((error) => {
                                    this.setState({
                                        cbNotFound: true,
                                        title: error.response.data.message,
                                        loading: false
                                    })
                                })
                        })
                } else {
                    this.setState({
                        cbNotFound: true,
                        title: "NID or Smart Card Required!",
                        loading: false
                    })
                }
            } else {
                if (("nationalId" in this.state.inputData) || ("smartCard" in this.state.inputData)) {
                    this.checkExistOrNot(postData)
                        .then((response) => {
                            this.getExistingClientId(postData)
                                .then((existingClientResponse) => {
                                    this.functionForCaseRoute()
                                })
                                .catch((error) => {
                                    this.setState({
                                        cbNotFound: true,
                                        title: error.response.data.message,
                                        loading: false
                                    })
                                })
                        })
                } else {
                    this.setState({
                        cbNotFound: true,
                        title: "NID or Smart Card Required!",
                        loading: false
                    })
                }
            }

        }
    };

    checkExistOrNot = (postData) => {
        return new Promise((resolve, reject) => {
            this.functionForValidityCheck(postData).then((eResponse) => {
                if (eResponse.data !== "Prepaid Card Exists") {
                    resolve(true)
                } else {
                    reject(false);
                    this.setState({
                        cbNotFound: true,
                        title: "Prepaid card already Exist for this NID or Smart Card!",
                        disabled: false,
                        loading: false
                    })
                }
            }).catch((err) => {
                reject(false);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: err.response.data.message
                });
            });
        })
    };

    functionForCaseRoute = () => {
        let postData = {...this.state.inputData};
        postData.category = "Prepaid Card";
        postData.subCategory = "Prepaid Card Issuance";
        postData.serviceType = "Prepaid Card";
        postData.subServiceType = "Prepaid Card Issuance";
        postData.appId = this.state.appId;
        let remarksData = {};
        remarksData.remark = this.state.inputData.csRemarks;
        remarksData.map = {...postData, csRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                });
                if (this.props.appId !== undefined) {
                    this.props.closeModal();
                } else {
                    this.setState({
                        getData: false,
                        searchData: false
                    });
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Prepaid Card Issuance
                    <a>
                        <CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: "absolute",
                                right: 10,
                                color: "#000000"
                            }}
                        />
                    </a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Prepaid Card Issuance
                </h4>
            )
        }
    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px", "margin-top": "10px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true,
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                });
            });

    };

    functionForFileDownload = () => {
        if (this.props.appId !== undefined) {
            return (
                <Grid item xs={12} style={{marginTop: "15px", marginBottom: "15px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Photo Upload")}
                    >
                        Download Photo
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Application Form Upload")}
                    >
                        Download Application Form
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("National ID Upload")}
                    >
                        Download National ID
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("KYC Upload")}
                    >
                        Download KYC
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => this.functionForFileGet("Signature Upload")}
                    >
                        Download Signature
                    </button>
                </Grid>
            )
        }
    };

    onKeyDownChange = (data) => {
        if (data.varName === "cardNumber") {
            this.state.inputData.showCardStatus = false;
            this.setState({
                loading: true,
            });
            this.state.inputData.cardStatus = this.state.inputData.cardNumber["status"];
            this.state.varValue.cardStatus = this.state.inputData.cardNumber["status"];
            this.forceUpdate();
            setTimeout(() => {
                this.state.inputData.showCardStatus = true;
                this.setState({
                    loading: false
                })
            }, 1000)
        } else if (data.varName === "region") {
            let url = "getCityNameForGiftCard" + "/" + this.state.inputData.region.key;
            let inputData = {...this.state.inputData};
            inputData.city = undefined;

            this.setState({
                showCity: false,
                loading: true,
                inputData: inputData,
                varValue: {...inputData}
            });
            this.forceUpdate();

            this.dynamicApiCall("city", "city", url);
            setTimeout(() => {
                this.setState({
                    loading: false,
                    showCity: true
                })
            }, 500);
             this.forceUpdate();


        }
    };

    renderSearchData = () => {
        if (this.state.getData === true && this.state.searchData === true) {
            return (
                <>
                    {this.props.appId === undefined && this.state.showCity && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        prepaidCardIssuanceCsAfterSearch,
                        this.updateComponent,
                        this.functionForGettingRmName,
                        this.onKeyDownChange
                    )}
                    {this.props.appId !== undefined && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        csReturnPrepaidCardRequisition,
                        this.updateComponent,
                        this.functionForGettingRmName,
                    )}
                    {this.functionForFileDownload()}
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        upload,
                        this.updateComponent
                    )}
                    <Grid item xs={12}>
                        {this.renderRemarksData()}
                        <br/>
                        {
                            this.renderRemarks()
                        }
                    </Grid>
                    <Grid item xs={3} style={{marginTop: "20px"}}>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;
                        {this.props.appId !== undefined && <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={this.handleClose}
                        >
                            Close
                        </button>}
                    </Grid>
                </>
            )
        }
    };

    renderSearch = () => {
        if (this.props.appId === undefined) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        prepaidCardIssuanceCsSearch,
                        this.updateComponent
                    )}
                    <Grid item xs={4}>
                        <button
                            style={{
                                marginTop: "16px"
                            }}
                            onClick={this.handleSearch}
                            className="btn btn-danger btn-sm">
                            Search
                        </button>
                        &nbsp;
                        <button
                            style={{
                                marginTop: "16px"
                            }}
                            onClick={() => window.location.reload()}
                            className="btn btn-danger btn-sm">
                            Reset
                        </button>
                    </Grid>
                </>
            )
        }
    };

    functionForFileGet = (type) => {
        this.setState({
            loading: true
        });
        let imageUrl = backEndServerURL + "/case/files/" + type + "/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    let url = backEndServerURL + "/file/" + response.data.toString() + "/" + sessionStorage.getItem("accessToken");
                    window.open(url);
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: "File Not Found!"
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {this.renderHeader()}
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderSearch()}
                                <Grid item xs={12}><br/></Grid>
                                {this.renderSearchData()}
                            </ThemeProvider>
                        </Grid>
                        {this.renderNotification()}
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(PrepaidCardIssuance);
