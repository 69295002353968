import React, {Component, createRef} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL, backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import FunctionForFileDownload from "../../../DeliverableManagement/FunctionForFileDownload";
import SignatureButton from "../../Maintenance/SignatureButton";
import {
    CsOutstandingCertificateJsonFrom,
    CsOutstandingCertificateJsonFromTaxReturn
} from "../../WorkflowjsonFormForAssetOps";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import Functions from "../../../../Common/Functions";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import CBNotFound from "../../CASA/CBNotFound";
import AssetCommonFunctions from "../AssetCommonFunctions";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
let certificateTypeJsonForm = [
    {
        "label": "Certificate Type",
        "type": "select",
        "varName": "certificateType",
        "grid": 2,
        "enum": [
            "General",
            "Tax Return Submission",
            "Take Over",
        ],
        "onKeyDown": true,
        "required": true,
    },
];

class CsOutstandingCertificate extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            inputData: {},
            confirmAlert: false,
            actionType: "",
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            approvedFromChecker: false,
            uploadStatus: "",
            printFile: ""
        };

        this.file = createRef();
        this.functionForCheck = this.functionForCheck.bind(this)
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>)
        }
    };
    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "closeHandler") {

            this.closeHandler()
        } else if (data === "YES" && this.state.actionType === "saveHandler") {

            this.saveHandler()
        }
    };
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (errorRemarks === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "closeHandler") {
            let errorCertificateType = MyValidation.defaultValidation(certificateTypeJsonForm, this.state);
            let error = this.state.inputData.certificateType === "Tax Return Submission" ? MyValidation.defaultValidation(CsOutstandingCertificateJsonFromTaxReturn, this.state) :
                MyValidation.defaultValidation(CsOutstandingCertificateJsonFrom, this.state);
            this.forceUpdate();
            if (errorCertificateType === true || error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })


        } else if (actionType === "saveHandler") {
            let errorCertificateType = MyValidation.defaultValidation(certificateTypeJsonForm, this.state);
            let error = this.state.inputData.certificateType === "Tax Return Submission" ? MyValidation.defaultValidation(CsOutstandingCertificateJsonFromTaxReturn, this.state) :
                MyValidation.defaultValidation(CsOutstandingCertificateJsonFrom, this.state);
            this.forceUpdate();
            if (errorCertificateType === true || error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()
        {
            this.validationForHandleConfirm(type)
        }
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = CsOutstandingCertificateJsonFrom;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                CsOutstandingCertificateJsonFrom[i].enum = getValue;
            }
        }
    };
    findByVarNameApiDataTaxReturn = (searchVarname, getValue) => {
        let jsonArray = CsOutstandingCertificateJsonFromTaxReturn;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                CsOutstandingCertificateJsonFromTaxReturn[i].enum = getValue;
            }
        }
    };

    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            // inputData.customerCB = this.state.inputData.customerCB;
            inputData.certificateType = this.state.inputData.certificateType;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                submission: false,
                loading: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;
            inputData.casaAccount = undefined;

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        submission: false,
                        showValue: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        submission: false,
                        title: error.response.data.message,
                    })
                })
        }
    };
    //separate blur function for separate findByVarNameApiDataTaxReturn and that is for separate form json
    onBlurFunctionForApiCallTaxReturn = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            this.setState({
                loading: true
            });
            let inputData = {};
            // inputData.customerCB = this.state.inputData.customerCB;
            inputData.certificateType = this.state.inputData.certificateType;

            this.setState({
                inputData: inputData,
                varValue: inputData,
                submission: false,
                loading: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;
            inputData.casaAccount = undefined;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getAllLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiDataTaxReturn("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        submission: false,
                        showValue: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        submission: false,
                        title: error.response.data.message,
                    })
                })
        }
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };
    removeNullValue = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    checkvalue = (str) => {
        str = JSON.stringify(str);
        if (str === '' || str === "null" || str === "undefined")
            return false;
        return true;
    };
    onKeyDownChange = (data) => {

        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.certificateType = this.state.inputData.certificateType;
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.cbNumber=this.state.inputData.cbNumber;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                submission: false,
                loading: false,
            });
        }

        else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            inputData.showCasaDetails = "NO";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
                submission: false,
            });
            let postData = {
                "loanAccounts": this.state.inputData[data.varName],
                "cbNumber": this.state.inputData.cbNumber,
                "loanAccountDetails": "Y",
                "loanGeneralInquiry": "Y",
                "loanAccountInfo": "Y",
                "getCustomAcctDetails": "Y"
            };
            AssetCommonFunctions.getCustLoanAccountDetails(postData)
                .then((custLoanAccountDetailsResponse) => {
                    inputData.productName = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                    inputData.dailyInterest = custLoanAccountDetailsResponse.data.loanGeneralInquiryResponse.dlyIntFact;
                    inputData.monthlyInstallment = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.installmentAmount;

                    let originalTenureMonths = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.originalTenureMonths);
                    let tenureBalanceYears = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.tenureBalanceYears);
                    let tenureBalanceMonths = parseInt(custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.tenureBalanceMonths);
                    inputData.tenorCompleted = originalTenureMonths - (tenureBalanceYears * 12 + tenureBalanceMonths);
                    inputData.casaAccount = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.operAcctId;
                    inputData.loanAccountStatus = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.accStatus;
                    inputData.loanAmt = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanAmt;
                    inputData.accountNumber = this.state.inputData[data.varName];
                    let disbursement = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.acctOpenDt;
                    if (disbursement) {
                        let dateD = (disbursement).split('T')[0].split("-");
                        inputData.disbursementDate = `${dateD[0]}/${dateD[1]}/${dateD[2]}`;
                    }
                    inputData.sanctionedLimit = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanAmt;
                    inputData.tenor = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.loanPeriodMonths;
                    inputData.loanOutstanding = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.closingBal;
                    inputData.overDue = custLoanAccountDetailsResponse.data.loanGeneralInquiryResponse.totDue;
                    inputData.casaBalance = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctBalance;
                    inputData.casaAccountStatus = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctStatus;
                    inputData.showCasaDetails = "YES";
                    inputData.loanAccountsCBApiCall = "YES";

                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        showValue: true,
                        getData: true,
                        submission: true,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }

    };
    onKeyDownChangeTaxReturn = (data) => {

        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.certificateType = this.state.inputData.certificateType;
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                submission: false,
                loading: false,
            });
        } else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
            });

            inputData.accountNumber = this.state.inputData[data.varName];
            inputData.loanAccountsCBApiCall = "YES";
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.removeNullValue(inputData),
                    showValue: true,
                    submission: false,
                    loading: false
                })
            }, 100)
        } else if ((data.varName === "fiscalYearStart" || data.varName === "fiscalYearEnd") && (this.state.inputData.fiscalYearStart !== undefined && this.state.inputData.fiscalYearEnd !== undefined)) {
            let inputData = {...this.state.inputData};
            inputData.taxReturnApiCall = "NO";
            inputData.presentAddress = undefined;
            inputData.disbursementDate = undefined;
            inputData.sanctionedLimit = undefined;
            inputData.loanOutstanding = undefined;
            inputData.principalPaid = undefined;
            inputData.interestPaid = undefined;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                submission: false,
                loading: true,
            });
            let postData = {
                loanAccount: inputData.accountNumber,
                startDate: inputData.fiscalYearStart,
                endDate: inputData.fiscalYearEnd,
            }
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getTaxReturnSubmission";
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                .then((taxReturnResponse) => {
                    inputData.presentAddress = taxReturnResponse.data.presentAddress;
                    inputData.disbursementDate = taxReturnResponse.data.disbursementDate;
                    inputData.sanctionedLimit = taxReturnResponse.data.sanctionedLimit;
                    inputData.loanOutstanding = taxReturnResponse.data.loanOutstanding;
                    inputData.interestPaid = taxReturnResponse.data.interest;
                    inputData.principalPaid = taxReturnResponse.data.principal;
                    inputData.taxReturnApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        submission: true,
                        loading: false,
                    });
                }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            })

        }
    };
    onKeyDownChangeCertificateType = (data) => {
        if (data.varName === "certificateType") {
            this.setState({
                loading: true,
            });
            let inputData = {};
            inputData.certificateType = this.state.inputData.certificateType;
            console.log(inputData);
            this.forceUpdate();
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.removeNullValue(inputData),
                    loading: false
                })
            }, 100)
        }
    };



    getAccountInfo(acctId) {
        let inputData = {...this.state.inputData};
        let getAccountUrl = backEndServerURL + "/getAccountInfo/" + acctId;
        axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((getAcResponse) => {
                console.log(getAcResponse)
                inputData.drclrlim = getAcResponse.data.drclrlim;
                inputData.add1 = this.checkvalue(getAcResponse.data.mailingAddress.addrLine1) ? getAcResponse.data.mailingAddress.addrLine1 + ", " : "";
                inputData.add2 = this.checkvalue(getAcResponse.data.mailingAddress.addrLine2) ? getAcResponse.data.mailingAddress.addrLine2 + ", " : "";
                inputData.addCity = this.checkvalue(getAcResponse.data.mailingAddress.city) ? getAcResponse.data.mailingAddress.city + ", " : "";
                inputData.addCountry = this.checkvalue(getAcResponse.data.mailingAddress.country) ? getAcResponse.data.mailingAddress.country : "";
                inputData.presentAddress = inputData.add1 + inputData.add2 + inputData.addCity + inputData.addCountry;
                this.setState({
                    inputData: inputData,
                    varValue: this.removeNullValue(inputData),
                });
            }).catch((error) => {
            this.setState({
                cbNotFound: true,
                title: error.response.data.message,
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false
            })
        });
    }

    componentDidMount() {
        if (this.props.appId !== undefined) {

            let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true,
                                imageName: response.data,
                                submission: true
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    console.log(response.data);
                    if (response.data.bom_approved === "YES") {
                        console.log("Approved from checker");
                        this.setState({
                            approvedFromChecker: true
                        })
                    }
                    let inputData = response.data;
                    inputData.csRemarks = undefined;
                    this.setState({
                        varValue: this.copyJson(inputData),
                        inputData: this.copyJson(inputData),
                        appId: this.props.appId
                    });


                    let remarksArray = [];
                    let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true,
                                showValue: true,
                                loading: false
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = assetOpsBackEndServerURL + "/startCase/cs_case_start_outstanding_certificate";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let inputData = response.data;
                    let varValue = response.data;
                    this.setState({
                        varValue: this.emptyValueRemove(varValue),
                        inputData: inputData,
                        getData: true,
                        showValue: true,
                        appId: response.data.id,
                        loading: false
                    });


                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    saveHandler = () => {
        let saveDataDeferralRemarksAndRoute = {};
        this.setState({
            loading: true
        });
        if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }
        let postData = this.state.inputData;
        postData.serviceType = "Outstanding Certificate";
        postData.category = "Outstanding Certificate";
        postData.subCategory = "Outstanding Certificate";
        postData.subServiceType = "Outstanding Certificate";
        saveDataDeferralRemarksAndRoute.map = postData;

        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
        axios
            .post(routeUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: response.data,
                    alert: true,
                    getData: false
                });
                if (this.props.appId === undefined) {
                    setTimeout(function () {
                        window.location.reload()
                    }, 1000);
                } else {
                    this.props.closeModal();
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    };


    printHandler = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let printUrl = assetOpsBackEndServerURL + "/getOutstandingCertificateLetter";
        let data = this.state.inputData;
        FunctionForFileDownload.downloadFile(printUrl, "post", data, "outstanding-certificate.pdf").then((response) => {
            this.setState({
                loading: false,
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                title: "Something Went Wrong!",
                cbNotFound: true,
            })
        })

    };

    closeHandler = () => {
        this.setState({
            approvedFromChecker: false,
            loading: true
        });
        let saveDataDeferralRemarksAndRoute = {};
        if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks;
        }
        saveDataDeferralRemarksAndRoute.map = {cs_approved: "YES"}
        let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios
            .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                this.setState({
                    alert: true,
                    loading: false,
                    title: "Successful!",
                    notificationMessage: response.data,
                });
                this.props.closeModal()

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                })
            });
    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Outstanding Certificate</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}<b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Outstanding Certificate
                </h4>
            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    functionForCheck = (value) => {
        console.log(value)
    };

    renderButtonSet = () => {
        if (this.state.loading === false) {
            if (this.state.approvedFromChecker) {
                return (
                    <React.Fragment>
                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: "10px",
                                }}
                                onClick={(event) => this.printHandler(event)}
                            >
                                Print Outstanding Certificate
                            </button>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    marginRight: "10px",
                                }}
                                //onClick={this.closeHandler}
                                onClick={(event) => this.handleCommon(event, "closeHandler")}
                            >
                                Submit
                            </button>
                        </div>

                    </React.Fragment>

                )
            } else {
                return (
                    <Grid item xs="3" style={{marginTop: "10px"}}>
                        {this.state.submission && <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                marginRight: "10px",
                            }}
                            // onClick={this.saveHandler}
                            onClick={(event) => this.handleCommon(event, "saveHandler")}

                        >
                            Submit
                        </button>}
                    </Grid>

                )
            }
        }
    };

    renderCertificateType = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, certificateTypeJsonForm, this.updateComponent, "", this.onKeyDownChangeCertificateType)
            )
        }
    }
    renderForm = () => {

        if (this.state.getData && this.state.inputData.certificateType === "Tax Return Submission") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CsOutstandingCertificateJsonFromTaxReturn, this.updateComponent, this.onBlurFunctionForApiCallTaxReturn, this.onKeyDownChangeTaxReturn)
            )
        } else if (this.state.getData && (this.state.inputData.certificateType === "General" || this.state.inputData.certificateType === "Take Over")) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, CsOutstandingCertificateJsonFrom, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    }
    renderSignature = () => {
        if (this.state.inputData.casaAccount && this.state.getData) {
            return (
                <div>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.casaAccount} signatureType="multiple"
                                     classes={this.props}/>
                </div>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderNotification()}
                    <br/>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderCertificateType()}
                            {this.renderForm()}
                            {this.renderSignature()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                  closeModal={this.closeConfirmAlert}
                                    />
                                </DialogContent>
                            </Dialog>
                            {this.renderRemarksData()}
                            {this.renderFlowSUmmeryButton()}
                            {this.renderRemarks()}
                            {this.renderButtonSet()}
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>
        );

    }
}

export default withStyles(styles)(CsOutstandingCertificate);
