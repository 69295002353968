import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import functionForFileDownload from "../../DeliverableManagement/FunctionForFileDownload";
import CBNotFound from "../CASA/CBNotFound";
import CommonApi from "../Maintenance/common/CommonApi";
import axios from "axios"
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [];
let searchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
        required: true
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
        required: true
    },
];

class CommonReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            title: "",
            notificationMessage: "",
            loading: false,
            cbNotFound: false,
            url: "",
            pageTitle: "",
            getData: true,
            showValue: true
        };
    }

    componentDidMount() {
        this.changeUrl()
    }

    changeUrl = () => {
        this.setState({
            inputData: {},
            varValue: {},
            getData: false,
            showValue: false
        });
        let url;
        let pageTitle;
        jsonForm = [...searchForm];
        let inputData = {...this.state.inputData};
        switch (this.props.match.params.requestType) {
            case "prepaidCardBranchForwarding" :
                jsonForm = [];
                this.setState({
                    loading: true
                });
                CommonApi.getBranchList().then((response) => {
                    jsonForm.push(
                        {
                            "varName": "date",
                            "type": "date",
                            "required": true,
                            "label": "Select Date",
                            "grid": 2,
                        },
                        {
                            "varName": "branchName",
                            // "required": true,
                            "type": "autoCompleteValueReturn",
                            "enum": response,
                            "label": "Select Branch",
                            "grid": 2,
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                url = backEndServerURL + "/getPrepaidCardPdfFile";
                pageTitle = "Prepaid Card Branch Forwarding Report";
                break;
            case "giftCardBranchForwarding" :
                jsonForm = [];
                this.setState({
                    loading: true
                });
                CommonApi.getBranchList().then((response) => {
                    jsonForm.push(
                        {
                            "varName": "date",
                            "type": "date",
                            "required": true,
                            "label": "Select Date",
                            "grid": 2,
                        },
                        {
                            "varName": "branchName",
                            // "required": true,
                            "type": "autoCompleteValueReturn",
                            "enum": response,
                            "label": "Select Branch",
                            "grid": 2,
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                url = backEndServerURL + "/getGiftCardPdfFile";
                pageTitle = "Gift Card Branch Forwarding Report";
                break;
            case "getGiftCardPinPdfFile" :
                inputData.format = "pdf";
                jsonForm = [];
                this.setState({
                    inputData: inputData,
                    loading: true
                });
                CommonApi.getBranchList().then((response) => {
                    jsonForm.push(
                        {
                            "varName": "date",
                            "type": "date",
                            "required": true,
                            "label": "Select Date",
                            "grid": 2,
                        },
                        {
                            "varName": "branchName",
                            // "required": true,
                            "type": "autoCompleteValueReturn",
                            "enum": response,
                            "label": "Select Branch",
                            "grid": 2,
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                url = backEndServerURL + "/getGiftCardPinPdfFile";
                pageTitle = "Gift Card Branch Forwarding PIN Delivery Report";
                break;
            case "bondBranchWiseIncome" :
                this.setState({
                    loading: true
                });
                CommonApi.getBranchList().then((response) => {
                    jsonForm.push(
                        {
                            "varName": "branchName",
                            "type": "autoCompleteValueReturn",
                            "enum": response,
                            "label": "Select Branch",
                            "grid": 2,
                        },
                        {
                            "varName": "category",
                            "type": "select",
                            "enum": [
                                "BOND",
                                "SANCHAYPATRA"
                            ],
                            "label": "Request Type",
                            "grid": 2,
                        },
                        {
                            "varName": "product",
                            "type": "select",
//                             "required": true,
                            "enum": [
                                "Wage Earners Development Bond",
                                "U.S. Dollar Investment Bond",
                                "U.S. Dollar Premium Bond"
                            ],
                            "label": "Select Type of Project",
                            "grid": 3,
                            "conditional": true,
                            "conditionalVarName": "category",
                            "conditionalVarValue": "BOND"
                        },
                        {
                            "varName": "product",
                            "type": "select",
//                             "required": true,
                            "enum": [
                                "5 Years Bangladesh Sanchaya Patra",
                                "3 Months Interest Based Sanchaya Patra",
                                "Family Sanchaya Patra",
                                "Pensioners Savings Certificate",
                            ],
                            "label": "Select Type of Project",
                            "grid": 3,
                            "conditional": true,
                            "conditionalVarName": "category",
                            "conditionalVarValue": "SANCHAYPATRA"
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                url = backEndServerURL + "/bondSonchoyBranchWiseIncomeReport";
                pageTitle = "Bond/Sanchaypatra Branch Wise Income Report";
                break;
            case "bondReturnReport" :
                url = backEndServerURL + "/bondSonchoyReturnReport";
                pageTitle = "Bond/Sanchaypatra Return Report";
                break;
            case "bondMonthlyReport" :
                jsonForm = [];
                jsonForm.push(
                    {
                        varName: "year",
                        type: "select",
                        label: "Select Year",
                        enum: ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"],
                        grid: 2,
                        required: true
                    },
                    {
                        varName: "month",
                        type: "autoCompleteValueReturn",
                        label: "Select Month",
                        enum: [
                            {key: "1", value: "January"},
                            {key: "2", value: "February"},
                            {key: "3", value: "March"},
                            {key: "4", value: "April"},
                            {key: "5", value: "May"},
                            {key: "6", value: "Jun"},
                            {key: "7", value: "July"},
                            {key: "8", value: "August"},
                            {key: "9", value: "September"},
                            {key: "10", value: "October"},
                            {key: "11", value: "November"},
                            {key: "12", value: "December"},
                        ],
                        grid: 2,
                        required: true
                    }
                );
                url = backEndServerURL + "/bondSonchoyMonthlyVolume";
                pageTitle = "Bond/Sanchaypatra Monthly Volume Report";
                break;
            case "chequeBookVolume" :
                url = backEndServerURL + "/chequeBookFinacleReport";
                pageTitle = "Cheque Book Volume Report";
                break;
            case "bondYearlySummaryReport" :
                url = backEndServerURL + "/GetBondSanchayapatraYearlySummaryReport";
                pageTitle = "Bond/Sanchaypatra Yearly Summary Report";
                break;
            case "cardChequeVolume" :
                url = backEndServerURL + "/chequeBookCardChequeReport";
                pageTitle = "Card Cheque Volume Report";
                break;
            case "debitCardVolume" :
                url = backEndServerURL + "/debitCardReport";
                pageTitle = "Debit Card Volume Report";
                break;
            case "instantDebitCardIssuanceVolume" :
                url = backEndServerURL + "/instantDebitCardIssuanceReport";
                pageTitle = "Instant Debit Card Issuance Volume Report";
                break;
            case "studentDetails" :
                url = backEndServerURL + "/StudentFileDetailsReport";
                pageTitle = "Download File Details Report";
                break;
            case "studentVolume" :
                url = backEndServerURL + "/StudentFileVolumeReport";
                pageTitle = "Download Student Volume Report";
                break;
            case "casaMaintenance" :
                jsonForm.push({
                    varName: "requestType",
                    type: "select",
                    label: "Request Type",
                    enum: ["Email Address Change", "Designated Contact Number Change"],
                    grid: 2,
                    required: true
                });
                url = backEndServerURL + "/CasaMaintenanceChangeReport";
                pageTitle = "Customer Information Update";
                break;
            case "chequeBook" :
                pageTitle = "Download Cheque Book Report";
                url = backEndServerURL + "/ChequeBookRequisitionReport";
                break;
            case "fdrReturnedReport" :
                pageTitle = "Download FDR Return Report";
                url = backEndServerURL + "/getFDRReturnReport";
                break;
            case "dpsReturnedReport" :
                pageTitle = "Download DPS Return Report";
                url = backEndServerURL + "/getDPSReturnReport";
                break;
            case "ntbDebitCardCreationReport" :
                pageTitle = "NTB debit card creation Report";
                url = backEndServerURL + "/regularDebitCardReport";
                break;
            case "instantDebitCardCreationReport" :
                pageTitle = "Instant debit card creation Report";
                url = backEndServerURL + "/instantDebitCardReport";
                break;
            case "casaReturnReport" :
                pageTitle = "Casa Return Report";
                url = backEndServerURL + "/casaReturnReport";
                break;
            case "sanchayPatraRequisitionReport" :
                pageTitle = "Sanchaypatra Requisition Report";
                url = backEndServerURL + "/sanchayPatraRequisitionReport";
                break;
            case "fundTransferDeferralReport" :
                pageTitle = "Fund Transfer Deferral Report";
                url = backEndServerURL + "/fundTransferDeferralReport";
                break;
            case "fundTransferSLAReport" :
                pageTitle = "Fund Transfer SLA Report";
                url = backEndServerURL + "/fundTransferSLAReport";
                break;
            case "secondCardChequeCreationDetailsReport" :
                pageTitle = "Card Cheque Creation Details Report";
                url = backEndServerURL + "/secondCardChequeCreationDetailsReport";
                break;
            case "ChequebookDeliverableBranchReport" :
                pageTitle = "ChequeBook Deliver In Branch Report";
                url = backEndServerURL + "/getChequeBookDeliverInBranchReport";
                break;

            case "DebitCardDeliverableBranchReport" :
                 pageTitle = "Debit Card Deliver In Branch Report";
                 url = backEndServerURL + "/getDebitCardDeliverInBranchReport";
                 break;

            case "regularDebitSummaryReport" :
                pageTitle = "Regular Debit Card Summary Report";
                url = backEndServerURL + "/regularDebitSummaryReport";
                break;
            case "fundTransferSLABreachReport" :
                pageTitle = "Fund Transfer SLA Breach Report";
                url = backEndServerURL + "/fundTransferSLABreachReport";
                break;
            case "GetBondSanchayapatraUserOperationReport" :
                pageTitle = "Bond Sanchayapatra User Operation Report";
                url = backEndServerURL + "/GetBondSanchayapatraUserOperationReport";
                break;
            case "GetBondSanchayapatraSLASupervisionReport" :
                pageTitle = "Bond Sanchayapatra SLA Supervision Report";
                url = backEndServerURL + "/GetBondSanchayapatraSLASupervisionReport";
                break;
            case "getChequeBookPrioritySapphireReport" :
                pageTitle = "Cheque Book Priority Sapphire Report";
                url = backEndServerURL + "/getChequeBookPrioritySapphireReport";
                break;
            case "getTotalProcessedAccounts" :
                pageTitle = "Total Processed Accounts Report";
                url = backEndServerURL + "/getTotalProcessedAccounts";
                break;
            case "getProcessedAccountsWithLinkCb" :
                pageTitle = "Processed Accounts With Link CB Report";
                url = backEndServerURL + "/getProcessedAccountsWithLinkCb";
                break;

            case "getTotalReceivedReport" :
                pageTitle = "Total Received Report";
                url = backEndServerURL + "/getTotalReceivedReport";
                break;

            case "RemittanceOutwardReport" :
                pageTitle = "Remittance Outward Report";
                url = backEndServerURL + "/RemittanceOutwardReport";
                break;

            case "RemittanceOutwardReportSLA" :
                pageTitle = "Remittance Outward Report(SLA)";
                url = backEndServerURL + "/RemittanceOutwardReportSLA";
                break;

            case "MaintenanceReports" :
                jsonForm.push({
                    "varName": "type",
                    "type": "select",
                    "enum": [
                        "Customer Information Update and Dormant Activation",
                        "Mandate Update"
                    ],
                    "label": "Report Type",
                    "required": true,
                    "grid": 2,
                })
                pageTitle = "Maintenance Reports";
                url = backEndServerURL + "/getMaintenanceReports";
                break;

            case "cardChequebookRequisitionReport" :
                jsonForm.push({
                    varName: "format",
                    type: "select",
                    label: "Request Format",
                    enum: ["pdf", "xlsx"],
                    grid: 2,
                    required: true
                });
                pageTitle = "Card Chequebook Requisition Report";
                url = backEndServerURL + "/requisitionReports/creditCardChequebook";
                break;

            case "chequebookRequisitionReport" :
                jsonForm.push({
                    varName: "format",
                    type: "select",
                    label: "Request Format",
                    enum: ["pdf", "xlsx"],
                    grid: 2,
                    required: true
                });
                pageTitle = "Chequebook Requisition Report";
                url = backEndServerURL + "/requisitionReports/chequebook";
                break;

            case "debitCardRequisitionReport" :
                jsonForm.push({
                    varName: "format",
                    type: "select",
                    label: "Request Format",
                    enum: ["pdf", "xlsx"],
                    grid: 2,
                    required: true
                });
                pageTitle = "Debit Card Requisition Report";
                url = backEndServerURL + "/requisitionReports/debitCard";
                break;
            case "chequeBookDestructionCertificateReport" :
                jsonForm = [];
                jsonForm.push({
                    varName: "date",
                    type: "date",
                    label: "Select Date",
                    grid: 2,
                    required: true
                });
                pageTitle = "Cheque Book Destruction Certificate Report";
                url = backEndServerURL + "/chequeBookDestructionCertificateReport";
                break;
            case "regularDebitBranchWiseReport" :
                this.setState({
                    loading: true
                });
                CommonApi.getBranchList().then((response) => {
                    jsonForm.push(
                        {
                            "varName": "branchName",
                            "type": "autoCompleteValueReturn",
                            "enum": response,
                            "label": "Select Branch",
                            "grid": 2,
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                pageTitle = "Regular Debit Card Branch Wise Report";
                url = backEndServerURL + "/regularDebitBranchWiseReport";
                break;
            case "regularDebitCourierWiseReport" :
                this.setState({
                    loading: true
                });
                CommonApi.getProcessWiseVendorName("DEBIT CARD").then((response) => {
                    jsonForm.push(
                        {
                            "varName": "courierName",
                            "type": "select",
                            "enum": response,
                            "label": "Courier Name",
                            "grid": 2,
                        },
                    );
                    this.setState({
                        loading: false
                    })
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
                pageTitle = "Regular Debit Card Courier Wise Report";
                url = backEndServerURL + "/regularDebitCourierWiseReport";
                break;

            case "cardMaintenanceReport" :
                this.setState({
                    loading: true
                });
                jsonForm.push(
                    {
                        "label": "Call Status",
                        "type": "select",
                        "varName": "callStatus",
                        "grid": 2,
                        "enum": [
                            "Open",
                            "Cancel",
                            "Solved",
                            "Locked",
                            "Forward",
                            "Return",
                            "In Progress"
                        ]
                    },

                    {
                        "label": "SLA",
                        "type": "select",
                        "varName": "sla",
                        "grid": 2,
                        "enum": [
                            "YES",
                            "NO"
                        ]
                    },
                )
                let jsonFormForUnit = [
                    {
                        varName: "unitName",
                        type: "autoCompleteValueReturn",
                        label: "Unit",
                        grid: 2,
                    }
                ];

                let jsonFormForCallCategory = [
                    {
                        varName: "calLCategory",
                        type: "select",
                        label: "Call Category",
                        grid: 2,
                    }
                ];

                let callCategoryUrl=backEndServerURL + "/GetAllCallCategoryGroup"
                axios.get(callCategoryUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response)=>{
                        console.log(response);

                        jsonFormForUnit[0].enum=response.data;
                        let url=backEndServerURL + "/getAllCallCategories"
                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((callCategoryResponse)=>{
                                console.log(callCategoryResponse.data);
                                jsonFormForCallCategory[0].enum=callCategoryResponse.data;
                                if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CALL_CENTER_MAKER"
                                    && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CS"
                                    && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") !== "CARD_DIVISION_LINE_MANAGER"){
                                          this.state.inputData.user=LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                                        this.state.varValue.user=LocalstorageEncrypt.encryptStorageFunction.getItem("username");
                                       jsonForm.push(
                                           {
                                               "label": "User",
                                               "type": "text",
                                               "varName": "user",
                                               "grid": 2,
                                               "readOnly": true
                                           },
                                       )
                                        this.setState({
                                            inputData:{...this.state.inputData},
                                            varValue:this.state.varValue,
                                            loading: false,
                                            getData: true,
                                             showValue: true

                                        })

                                }
                                else{
                                    jsonForm.push(
                                        {
                                            "label": "User",
                                            "type": "text",
                                            "varName": "user",
                                            "grid": 2,
                                            "readOnly": false
                                        },
                                    )
                                    this.setState({
                                        inputData:{...this.state.inputData},
                                        varValue:this.state.varValue,
                                        loading: false,
                                        getData: true,
                                        showValue: true

                                    })
                                }

                            })
                            .catch((error)=>{
                                console.log(error)
                                this.setState({
                                    loading: false
                                })
                            })
                    })
                    .catch((error)=>{
                        console.log(error)
                        this.setState({
                            loading: false
                        })
                    })
                jsonForm=jsonForm.concat(jsonFormForUnit);
                jsonForm=jsonForm.concat(jsonFormForCallCategory);

                pageTitle = "Card Maintenance Report";
                url = backEndServerURL + "/cardMaintenanceReport";
                setTimeout(() => {
                    this.setState({
                         url: url,
                        pageTitle: pageTitle,
                     })
                }, 300)
                break;
            default:
                pageTitle = "Download Report";
                url = backEndServerURL + "/StudentFileDetailsReport";
        }
        if(this.props.match.params.requestType!=="cardMaintenanceReport"){
            setTimeout(() => {
                this.setState({
                    getData: true,
                    url: url,
                    pageTitle: pageTitle,
                    showValue: true
                })
            }, 300)
        }

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (this.state.inputData.format === undefined || this.state.inputData.format === null) {
            this.state.inputData.format = "xlsx";
        }
        let format = this.state.inputData.format || "xlsx";
        if (this.state.pageTitle !== "ChequeBook Deliver In Branch Report" && this.state.pageTitle !== "Debit Card Deliver In Branch Report" && error === true) {
            return false
        } else {
            this.setState({
                loading: true
            });
            console.log(this.state.inputData);
            functionForFileDownload.downloadFile(this.state.url, "POST", this.state.inputData, `report.${format}`).then((response) => {
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    title: "Something Went Wrong!",
                    cbNotFound: true,
                })
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.requestType !== this.props.match.params.requestType) {
            this.changeUrl();
        }
    }

    renderJsonForm = () => {
        if (this.state.getData && this.state.showValue) {
            return (
                <>
                    {((this.state.pageTitle !== "ChequeBook Deliver In Branch Report" && this.state.pageTitle !== "Debit Card Deliver In Branch Report") && this.state.getData) && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        jsonForm,
                        this.updateComponent
                    )}
                    {(this.state.pageTitle === "ChequeBook Deliver In Branch Report" || this.state.pageTitle === "Debit Card Deliver In Branch Report") && CommonJsonFormComponent.renderJsonForm(
                        this.state,
                        [
                            {

                                varName: "reportName",
                                type: "select",
                                enum: [
                                    "Branch Receive pending"
                                ],
                                label: "Report Name",
                                grid: 3,
                                required: true

                            }
                        ],
                        this.updateComponent
                    )}
                    &nbsp;&nbsp;
                    {this.state.pageTitle !== "ChequeBook Deliver In Branch Report" && this.state.pageTitle !== "Debit Card Deliver In Branch Report"  && <button
                        className="btn btn-outline-primary  btn-sm"
                        onClick={this.handleSubmit}
                        style={{
                            height: "25px",
                            marginTop: "18px"
                        }}
                    >
                        Submit
                    </button>}
                    {(this.state.pageTitle === "ChequeBook Deliver In Branch Report" || this.state.pageTitle === "Debit Card Deliver In Branch Report") && this.state.inputData.reportName === "Branch Receive pending" &&
                    <button
                        className="btn btn-outline-primary  btn-sm"
                        onClick={this.handleSubmit}
                        style={{
                            height: "25px",
                            marginTop: "20px"
                        }}
                    >
                        Download
                    </button>}
                </>
            )
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >{this.state.pageTitle}</h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderJsonForm()}
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(CommonReport);
