import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {localization, options, tableIcons,} from "../../DeliverableManagement/configuration";
import Notification from "../../NotificationMessage/Notification";
import Button from "@material/react-button";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

let SearchForm = [
    {
        varName: "Case ID",
        type: "text",
        label: "Case ID",
        validation: "numeric",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        validation: "numeric",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "cardNumber",
        type: "text",
        validation: "numeric",
        label: "Card Number",
        grid: 2,
    },
    {
        varName: "mobileNumber",
        type: "text",
        validation: "phone",
        label: "Mobile Number",
        grid: 2,
    },
    {
        varName: "status",
        type: "text",
        validation: "sting",
        label: "Status",
        grid: 2,
    },
    {
        varName: "customerCategory",
        type: "text",
        label: "CustomerCategory",
        grid: 2,
    },
    {
        varName: "callCategory",
        type: "text",
        label: "Call Category",
        grid: 2,
    },
];


class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            autoComplete: {},
            allData: [],
            pageNumber: 0,
            totalRow: 0,
            loading: false,
            rowsPerPage: 100,
            showValue: false,
            alert: false,
            history: false,
            appId: 0,
            title: "",
            notificationMessage: "",
            columns: [
                {title: 'SL', field: 'sl',},
                {title: 'Process Name', field: 'processName',},
                {title: 'Vendor Name', field: 'vendorName',},
                {title: 'Vendor Code', field: 'vendorCode'},
                {title: 'Vendor Email', field: 'vendorEmail'},
                {title: 'Status', field: 'status'},
                {title: 'Action', field: 'action'},
            ],
        };
    }


    dateConverter = (value) => {

        return value.substr(0, 10);
    };

    componentDidMount() {
        this.fetchDataFromAPI('0')
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    fetchDataFromAPI = (pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        this.setState({
            loading: true,
            pageNumber: page,
        });
        let searchUrl = backEndServerURL + "/getAllVendorEmailInfo/" + page;
        axios.get(searchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].appId;
                        rowData = {
                            sl: i + 1,
                            id: response.data.content[i].id,
                            processName: response.data.content[i].processType,
                            vendorName: response.data.content[i].vendorName,
                            vendorCode: response.data.content[i].vendorCode,
                            vendorEmail: response.data.content[i].vendorEmail,
                            status: response.data.content[i].vendorStatus,
                            action: <Button onClick={(e) => {
                                this.triggerAction(e, response.data.content[i].id, response.data.content[i].vendorStatus)
                            }} style={{
                                backgroundColor: "green",
                                color: "white",
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 3,
                                paddingBottom: 3,
                                borderRadius: 3,
                                border: 1
                            }}>{response.data.content[i].vendorStatus === "ACTIVE" ? "Inactive" : 'Active'}</Button>
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }
                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        showValue: true,
                        loading: false,
                        pageNumber: this.state.pageNumber,
                        totalRow: response.data.totalElements,
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    showValue: true,
                    loading: false
                })
            });

    };

    triggerAction = (e, id, currentStatus) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        let postData = [];
        postData.push(id);
        let status = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";
        let searchUrl = backEndServerURL + "/changeVendorStatus/" + status;
        axios.post(searchUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        alert: true,
                        title: "Successful",
                        notificationMessage: "Status Change Successful!",
                        loading: false
                    });
                } else {
                    this.setState({
                        alert: true,
                        title: "Failed",
                        notificationMessage: "Status Change Failed!",
                        loading: false
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    showValue: true,
                    loading: false
                })
            });
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Mail Configuration List
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <MuiThemeProvider theme={themeO}>
                                    <div style={{maxWidth: "100%"}}>
                                        <MaterialTable
                                            columns={this.state.columns}
                                            data={this.state.allData}
                                            title={" "}
                                            options={{...options, selection: false}}
                                            localization={localization}
                                            icons={tableIcons}
                                            components={{
                                                Pagination: props => (
                                                    <TablePagination
                                                        {...props}
                                                        rowsPerPageOptions={[100]}
                                                        count={this.state.totalRow}
                                                        page={this.state.pageNumber}
                                                        onChangePage={(e, page) => {
                                                            this.fetchDataFromAPI(page)
                                                        }
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(Search);
