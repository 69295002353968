import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from '@material-ui/icons/Close';
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../../MyValidation";
import ErrorModal from "../CommonComponent/ErrorModal";
import CustomValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";
import ConfirmationModal from "../FundTransfer/ConfirmationModal";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let jsonFormCustomerCategory = [
    {
        "varName": "customerCategory",
        "type": "select",
        "onKeyDown": true,
        "required": true,
        "label": "Customer Category",
        "multiline": true,
        "enum": [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        "grid": 2,
    },
];
let jsonFromAccount = [
    {
        "varName": "accountNo",
        "type": "textApiCall",
        "label": "Provide Account No",
        "validation": "numeric",
        "required": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "Account",
        "grid": 2,
        "multiline": true,
    },
    {
        "varName": "cardNumber",
        "type": "select",
        "label": "Card Number",
        "onKeyDown": true,
        "enum": [],
        "conditional": true,
        "conditionalVarName": "showCardNumber",
        "conditionalVarValue": true,
        "grid": 2,
    },
    {
        "varName": "clientId",
        "type": "text",
        "readOnly": true,
        "label": "Client ID",
        "conditional": true,
        "conditionalVarName": "showClientId",
        "conditionalVarValue": true,
        // "required": true,
        "grid": 2,
    },
    {
        "varName": "priority",
        "type": "select",
        "enum": ["High", "Regular"],
        "label": "Priority",
        "required": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "Account",
        "grid": 2,
    },
];
let jsonFromAMEX = [
    {
        "varName": "clientId",
        "type": "textApiCall",
        "required": true,
        "label": "Client ID",
        "multiline": true,
        "validation": "numeric",
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "AMEX",
        "grid": 2,
    },
    {
        "varName": "cardNumber",
        "type": "select",
        "required": true,
        "label": "Card Number",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "AMEX",
        "grid": 2,
    },
    {
        "varName": "priority",
        "type": "select",
        "enum": ["High", "Regular"],
        "label": "Priority",
        "required": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "AMEX",
        "grid": 2,
    },
];
let jsonFromVISA = [
    {
        "varName": "clientId",
        "type": "textApiCall",
        "required": true,
        "label": "Client ID",
        "multiline": true,
        "validation": "numeric",
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "VISA/Master",
        "grid": 2,
    },
    {
        "varName": "cardNumber",
        "type": "select",
        "required": true,
        "label": "Card Number",
        "enum": [],
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "VISA/Master",
        "grid": 2,
    },
    {
        "varName": "priority",
        "type": "select",
        "enum": ["High", "Regular"],
        "label": "Priority",
        "required": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "VISA/Master",
        "grid": 2,
    },
];

let jsonFromOthers = [
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "condition": "text",
        "validation": "text",
        "required": true,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "Others",
        "grid": 2,
    },
    {
        "varName": "priority",
        "type": "select",
        "enum": ["High", "Regular"],
        "label": "Priority",
        "required": true,
        "conditional": true,
        "conditionalVarName": "customerCategory",
        "conditionalVarValue": "Others",
        "grid": 2,
    },
];
let jsonFormCardCallCategory = [
    {
        "varName": "callCategory",
        "type": "autoComplete",
        "grid": 2,
        "label": "Call Category",
        "multiline": true,
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "customerCategory",
        "conditionalOrList": ["Account", "AMEX", "VISA/Master", "Others"],

    },
];
let jsonFormFile = [
    {
        "varName": "attachFileOne",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 01 (accept .pdf)",
        "grid": 12,
    },
    {
        "varName": "attachFileTwo",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 02 (accept .pdf)",
        "grid": 12,
    },
    {
        "varName": "attachFileThree",
        "type": "file",
        "accept": ".pdf",
        "label": "Attach File 03 (accept .pdf)",
        "grid": 12,
    },
];
let JsonFormAlwaysShow = [

    {
        "varName": "receivingBranchName",
        "type": "select",
        "label": "Receiving Branch",
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "homeAddress",
        "type": "text",
        "label": "Home Address",
        "multiline": true,
        "grid": 2,
    },

    {
        "varName": "reasonForReissue",
        "type": "select",
        "label": "Reason For Reissue",
        "multiline": true,
        "enum": [
            "File Missing",
            "Wrong Information"
        ],
        "grid": 2,
    },
    {
        "varName": "travelQuotaLimit",
        "type": "text",
        "label": "Travel Quota Limit",
        "multiline": true,
        "grid": 2,
    },
    {
        "varName": "contactNo",
        "type": "text",
        "required": true,
        "multiline": true,
        "label": "Contact No",
        "validation": "phone",
        "grid": 2,
    },
    {
        "varName": "securityVerified",
        "type": "select",
        "required": true,
        "label": "Security Verified",
        "enum": [
            "Yes",
            "No",
        ],
        "grid": 2,
    },
    {
        "varName": "callType",
        "type": "select",
        "required": true,
        "label": "Call Type",
        "multiline": true,
        "enum": [
            "Information",
            "Request in progress",
        ],
        "default": "Request in progress",
        "grid": 2,
    },
    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",
        "multiline": true,
        "grid": 2,
    },

];

class MaintenanceRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputData: {
                "callType": "Request in progress",
                "priority": "Regular"
            },
            autoComplete: {},
            showValue: true,
            getValue: false,
            fileUploadData: {},
            uploadModal: false,
            caseId: "",
            notificationType: "",
            notificationTitle: "",
            notificationMessage: "",
            imageModel: false,
            getRemarks: [],
            getTargets: [],
            supportDocumentArray: [],
            merchantSupportDocumentArray: [],
            clientCardDetailsList: [],
            getImageLink: [],
            getImageBoolean: false,
            imageModalBoolean: false,
            imageName: [],
            selectImage: "",
            uniqueId: 0,
            alert: false,
            err: false,
            error: false,
            loading: true,
            successbloo: false,
            requiredMessage: "",
            requiredMessageTwo: "",
            errorCardPartOne: false,
            errorCardPartTwo: false,
            errorArray: {},
            errorMessages: {},
            cbNotFound: false,
            confirmAlert: false,
            varValue: {
                "callType": "Request in progress",
                "priority": "Regular"
            }


        }
    }


    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    close = (e) => {
        e.preventDefault();
        this.props.closeModal = false
    };


    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    createTargetsTable = (customerCategory, callCategory, currentUserName, delStatus, delInitTime, delFinishTime) => {
        return (
            [customerCategory, callCategory, currentUserName, delStatus, delInitTime, delFinishTime]
        )
    };

    getBranchAll() {
        let branchUrl = backEndServerURL + "/workplaceUnit/getInitialUnits";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let value = [];
                response.data.map((item) => {
                    value.push(item.name)
                });
                for (let item of JsonFormAlwaysShow) {
                    if (item.varName === "receivingBranchName" && item.type === "select") {
                        item.enum = value
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    componentDidMount() {
        this.getBranchAll();
        var remarksArray = [];
        var targetsArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.approval === "RETURN") {
                        if (response.data.customerCategory === "Account") {
                            jsonFormCustomerCategory[0].readOnly = true;
                            this.functionForGetCardList(response.data.acCbNumber);
                            for (let item of jsonFromAccount) {
                                if (item.varName === "accountNo") {
                                    item.readOnly = true;
                                    item.type = "text"
                                }
                            }
                        } else if (response.data.customerCategory === "AMEX") {
                            this.getCardListByClientId(response.data.clientId);
                            for (let item of jsonFromAMEX) {
                                if (item.varName === "clientId") {
                                    item.readOnly = true;
                                    item.type = "text"
                                }
                            }
                        } else if (response.data.customerCategory === "VISA/Master") {
                            this.getCardListByClientId(response.data.clientId);
                            for (let item of jsonFromVISA) {
                                if (item.varName === "clientId") {
                                    item.readOnly = true;
                                    item.type = "text"
                                }
                            }
                        }
                    } else {
                        jsonFormCustomerCategory[0].readOnly = false
                    }

                    let cCategory = response.data.customerCategory === "VISA/Master" ? "visaMaster" : response.data.customerCategory.toLowerCase();
                    let url = backEndServerURL + "/getCallCategory/" + cCategory;

                    let autoComplete = {};
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response);
                            if (response.status === 200) {
                                autoComplete.callCategory = response.data;
                                this.setState(
                                    {
                                        supportDocumentArray: response.data
                                    }
                                );
                                this.setState({
                                    autoComplete: autoComplete,
                                    getValue: true,
                                    loading: false
                                })
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                        });


                    //**************get remarks****************
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    let getTargetUrl = backEndServerURL + "/perticipatedHistory/" + this.props.appId;
                    axios.get(getTargetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data, inbox) => {

                                targetsArray.push(this.createTargetsTable(response.data[inbox].freeFlag1, response.data[inbox].freeFlag2, response.data[inbox].currentUserName === "Despatch" ? "Despatch" : response.data[inbox].currentUserName, response.data[inbox].delStatus === "SAVED" ? "In Progress" : response.data[inbox].delStatus, response.data[inbox].delInitTime.replace("T", " ").slice(0, 19), response.data[inbox].delFinishTime === null ? "" : response.data[inbox].delFinishTime.replace("T", " ").slice(0, 19)))
                            });
                            this.setState({
                                getTargets: targetsArray,
                                getData: true
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });

                    // let varValue = response.data;
                    this.setState({
                        varValue: response.data,
                        appData: this.copyJson(response.data),
                        inputData: this.copyJson(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        uniqueId: response.data.caseId,
                        loading: true,
                        caseId: this.props.appId
                    });

                    this.state.varValue["callCategory"] = {label: response.data.callCategory};
                })
                .catch((error) => {
                    console.log(error);

                });

            //**************file************
            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.setState({
                        getImageLink: response.data,
                        getImageBoolean: true,
                        imageName: response.data
                    })
                })
                .catch((error) => {
                    console.log(error);
                })

        } else {
            jsonFormCustomerCategory[0].readOnly = false;
            for (let item of jsonFromAccount) {
                if (item.varName === "accountNo") {
                    item.readOnly = false;
                    item.type = "textApiCall"
                }
            }

            for (let item of jsonFromAMEX) {
                if (item.varName === "clientId") {
                    item.readOnly = false;
                    item.type = "textApiCall"
                }
            }
            for (let item of jsonFromVISA) {
                if (item.varName === "clientId") {
                    item.readOnly = false;
                    item.type = "textApiCall"
                }
            }
            let start_url="";
            if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_MAKER"){
                start_url = backEndServerURL + "/startCase/call_center_from_maker_start";
            }
            else{
                start_url = backEndServerURL + "/startCase/card_maintenance_start";
            }
            axios.get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data.id);
                    this.state.inputData.caseId = response.data.caseId;
                    this.setState({
                        caseId: response.data.id,
                        uniqueId: response.data.caseId,
                        getData: true,
                        loading: false,
                    });
                    this.state.inputData["callType"] = "Request in progress";
                })
                .catch((error) => {
                    console.log(error)
                })
        }

    }


    cardCallCreatedHandler = () => {
        let errorOne = CustomValidation.defaultValidation(jsonFormCustomerCategory.concat(jsonFormCardCallCategory, JsonFormAlwaysShow, jsonFromAccount, jsonFromOthers, jsonFromAMEX, jsonFromVISA), this.state);
        this.forceUpdate();
        let errorFileOne = CustomValidation.fileValidation(jsonFormFile[0], this.state);
        this.forceUpdate();
        let errorFileTwo = CustomValidation.fileValidation(jsonFormFile[1], this.state);
        this.forceUpdate();
        let errorFileThree = CustomValidation.fileValidation(jsonFormFile[2], this.state);
        this.forceUpdate();

        if (errorOne === true || errorFileOne === true || errorFileTwo === true || errorFileThree === true) {
            this.setState({
                notificationType: "error",
                notificationTitle: "Error Massage",
                notificationMessage: "Please Select & fill Required field",
                alert: true
            });
            return 0;
        } else {
            this.setState({
                loading: true
            });

            if (this.state.inputData.callCategory !== undefined && this.state.inputData.callCategory !== "") {
                console.log(this.supportingDocument());
                if (this.supportingDocument() === "Y") {
                    if (this.props.appId !== undefined && this.state.getImageLink.length > 0) {
                        MyValidation.defaultValidation(jsonFormCustomerCategory.concat(jsonFormCardCallCategory, JsonFormAlwaysShow), this.state);
                        this.dataSubmit();
                    } else if (Object.values(this.state.fileUploadData).length > 0) {
                        if (this.state.inputData.cardNumberFirst !== undefined && this.state.inputData.cardNumberFirst !== "" &&
                            this.state.inputData.cardNumberSecond !== undefined && this.state.inputData.cardNumberSecond !== "" &&
                            this.state.inputData.contactNo !== undefined && this.state.inputData.contactNo !== ""
                            && this.state.inputData.securityVerified !== undefined && this.state.inputData.securityVerified !== "") {
                            this.dataSubmit();
                        } else {
                            MyValidation.defaultValidation(JsonFormAlwaysShow.concat(jsonFormCustomerCategory, jsonFormCardCallCategory), this.state);
                            this.setState({
                                notificationType: "error",
                                notificationTitle: "Error Massage",
                                notificationMessage: "Required input missing",
                                loading: false,
                                alert: true,
                                error: true,
                                requiredMessage: "required"
                            });
                        }
                    } else {
                        this.setState({
                            notificationType: "error",
                            notificationTitle: "Error Massage",
                            notificationMessage: "Required File missing",
                            loading: false,
                            alert: true

                        })
                    }
                } else if (this.supportingDocument() === "N") {
                    if (this.state.inputData.contactNo !== undefined && this.state.inputData.contactNo !== ""
                        && this.state.inputData.securityVerified !== undefined && this.state.inputData.securityVerified !== "") {
                        this.dataSubmit();
                    } else {
                        MyValidation.defaultValidation(jsonFormCustomerCategory.concat(jsonFormCardCallCategory, JsonFormAlwaysShow), this.state);

                        this.setState({
                            notificationType: "error",
                            notificationTitle: "Error Massage",
                            notificationMessage: "Required input missing",
                            loading: false,
                            alert: true,
                            error: true,
                            requiredMessage: "required"

                        });
                    }
                }
            } else {
                MyValidation.defaultValidation(jsonFormCustomerCategory.concat(jsonFormCardCallCategory, JsonFormAlwaysShow, jsonFromAccount), this.state);
                this.setState({
                    notificationType: "error",
                    notificationTitle: "Error Massage",
                    notificationMessage: "Please Select & fill Required field",
                    loading: false,
                    alert: true

                })
            }
        }
    };

    dataSubmit = () => {
        this.state.inputData.subServiceType = this.state.inputData.customerCategory;
        this.state.inputData.category = "CARD";
        this.state.inputData.subCategory = "Maintenance";
        this.state.inputData.freeFlag1 = this.state.inputData.customerCategory;
        this.state.inputData.freeFlag2 = this.state.inputData.callCategory;
        this.state.inputData.freeFlag3 = this.state.inputData.priority === "High" ? "1" : "0";
        this.state.inputData.serviceType = this.state.inputData.callCategory;
        this.state.inputData.cbNumber = this.state.inputData.clientId;
        this.state.inputData.accountNumber = this.state.inputData.cardNumber;
        if(LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup")==="CALL_CENTER_MAKER"){
            this.state.inputData.call_center_maker_approval_to  = "CARD_DIVISION";
        }
        let variables_url = backEndServerURL + "/variables/" + this.state.caseId;
        axios.post(variables_url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                if (Object.values(this.state.fileUploadData).length < 1) {
                    this.remarkAndRoute();
                } else {
                    let uploadUrl = backEndServerURL + "/case/upload";
                    let formData = new FormData();
                    formData.append("appId", this.state.caseId);
                    if (Object.values(this.state.fileUploadData).length === 1) {
                        formData.append("file", this.state.fileUploadData['attachFileOne']);
                        formData.append("type", "file1");
                    } else if (Object.values(this.state.fileUploadData).length === 2) {
                        formData.append("file", this.state.fileUploadData['attachFileOne']);
                        formData.append("type", "file1");
                        formData.append("file", this.state.fileUploadData['attachFileTwo']);
                        formData.append("type", "file2");
                    } else if (Object.values(this.state.fileUploadData).length === 3) {
                        formData.append("file", this.state.fileUploadData['attachFileOne']);
                        formData.append("type", "file1");
                        formData.append("file", this.state.fileUploadData['attachFileTwo']);
                        formData.append("type", "file2");
                        formData.append("file", this.state.fileUploadData['attachFileThree']);
                        formData.append("type", "file3");
                    }
                    axios({
                        method: 'post',
                        url: uploadUrl,
                        data: formData,
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                        .then((response) => {
                            this.remarkAndRoute();
                        })
                        .catch((error) => {
                            console.log(error);
                        })

                }


            }).catch((error) => {

            console.log(error);
        })

    };


    remarkAndRoute() {
        if (this.state.inputData.remarks !== undefined) {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.remarks + "/" + this.state.caseId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (this.state.inputData.callType === "Information") {
            let url = backEndServerURL + "/case/close/" + this.state.caseId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        notificationType: "success",
                        notificationTitle: "Successful",
                        notificationMessage: "Case Route Successful",
                        alert: true
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error)
                });
        } else {
            let url = backEndServerURL + "/SetSlaValueFromCardCallCategory/" + this.state.caseId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let route_url = backEndServerURL + "/case/route/" + this.state.caseId;
                    axios.get(route_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response);

                            this.setState({
                                notificationType: "success",
                                notificationTitle: "Success Massage",
                                notificationMessage: "Reference Number : " + this.state.uniqueId,
                                loading: false,
                                successbloo: true,
                                alert: true

                            });
                            // setTimeout(function () {
                            //     window.location.reload();
                            // }, 1000);
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                });
        }

    }

    supportingDocument() {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workplace") !== "BRANCH") {
            return "N";
        } else {
            for (let i = 0; i < this.state.supportDocumentArray.length; i++) {
                if (this.state.inputData.callCategory === this.state.supportDocumentArray[i].label) {
                    return this.state.supportDocumentArray[i].supportingDocuments;
                }
            }
        }
    }


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.notificationType} stopNotification={this.stopNotification}
                              title={this.state.notificationTitle}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    dialogBoxShow = () => {
        return (
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.successbloo}>
                    <DialogContent>
                        <ErrorModal title={this.state.notificationMessage} closeModal={this.closeSuccessModal}/>
                    </DialogContent>
                </Dialog>
            </div>
        )
    };

    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFormCustomerCategory, this.updateComponent, "", this.onKeyDownChange)}
                    </Grid>

                    {this.renderAlwaysShow()}
                </React.Fragment>

            )
        }
    };

    onKeyDownForDedup = () => {
        if (this.state.inputData.customerCategory === "Account") {
            this.state.inputData.showCardNumber = false;
            this.setState({
                loading: true
            });
            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.state.inputData.accountNo;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    if (response.data.message === "No Account Found!") {
                        this.setState({
                            loading: false,
                            cbNotFound: true,
                            title: 'No Account Found!'
                        });
                    } else {
                        let inputData = this.state.inputData;
                        inputData.accountNumber = response.data.accountNumber;
                        inputData.cbNumber = response.data.cbNumber;
                        inputData.acCbNumber = response.data.cbNumber;
                        inputData.customerName = response.data.customerName;
                        this.functionForGetCardList(inputData.cbNumber);
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    });
                })
        }
    };


    functionForGetCardList = (cbNumber) => {
        let start_url = backEndServerURL + "/getCustomerDebitCards/" + cbNumber;
        axios
            .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {

                let cardNumber = [];
                response.data.clientCardDetailsList.map((item) => {
                    cardNumber.push(item.cardNoMasked);
                });

                for (let item of jsonFromAccount) {
                    if (item.varName === "cardNumber" && item.type === "select") {
                        item.enum = cardNumber
                    }
                }
                this.state.inputData.showCardNumber = true;
                this.setState({
                    clientCardDetailsList: response.data.clientCardDetailsList,
                    loading: false
                })

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "Can't Fetch Existing Card!"
                })
            });
    };


    onKeyDownChange = (data) => {
        this.state.inputData.showClientId = false;
        this.state.inputData.showCardNumber = false;
        delete this.state.inputData.clientId;
        delete this.state.inputData.cardNumber;
        delete this.state.varValue.cardNumber;
        delete this.state.varValue.clientId;
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            this.setState({
                loading: true
            });
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        autoComplete.callCategory = response.data;
                        this.setState(
                            {
                                supportDocumentArray: response.data
                            }
                        );
                        this.setState({
                            autoComplete: autoComplete,
                            getValue: true,
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    });
                });

        }
    };

    functionForGetCardNumber = () => {
        this.getCardListByClientId(this.state.inputData.clientId)
    };


    getCardListByClientId = (clientId) => {
        if(clientId !== undefined){
            this.setState({
                loading: true,
            });
            let urlSegment;
            if (this.state.inputData.customerCategory === "VISA/Master") {
                urlSegment = "VISA/MASTER"
            } else if (this.state.inputData.customerCategory === "AMEX") {
                urlSegment = "AMEX";
            }

            let clintUrl = backEndServerURL + "/intgr/cardList/" + clientId + "/" + urlSegment;
            axios
                .get(clintUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (this.state.inputData.customerCategory === "AMEX") {
                        for (let item of jsonFromAMEX) {
                            if (item.varName === "cardNumber") {
                                item.enum = response.data.cardNoList
                            }
                        }
                    }
                    if (this.state.inputData.customerCategory === "VISA/Master") {
                        for (let item of jsonFromVISA) {
                            if (item.varName === "cardNumber") {
                                item.enum = response.data.cardNoList
                            }
                        }
                    }
                    setTimeout(() => {
                        this.setState({
                            loading: false,
                        });
                    }, 500)

                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: 'Client Id Not Found'
                    });
                });
        }
    };

    getClientId = (data) => {
        if (data.varName === "cardNumber" && this.state.inputData.cardNumber) {
            this.state.inputData.showClientId = false;
            this.setState({
                loading: true
            });
            let result = this.state.clientCardDetailsList.find(item => item.cardNoMasked === this.state.inputData.cardNumber);
            this.state.inputData["clientId"] = result.clientId;
            this.state.varValue["clientId"] = result.clientId;
            this.state.inputData.showClientId = true;
            setTimeout(() => {
                this.setState({
                    showValue: true,
                    loading: false
                });
            }, 500)
        }
    };

    renderAlwaysShow() {
        if (this.state.inputData.customerCategory === "Account" || this.state.inputData.customerCategory === "AMEX" || this.state.inputData.customerCategory === "VISA/Master"
            || this.state.inputData.customerCategory === "Merchants" || this.state.inputData.customerCategory === "Others") {
            MyValidation.defaultValidation(jsonFormCustomerCategory, this.state);
            return (
                <React.Fragment>
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromAccount, this.updateComponent, this.onKeyDownForDedup, this.getClientId)}
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromAMEX, this.updateComponent, this.functionForGetCardNumber)}
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromVISA, this.updateComponent, this.functionForGetCardNumber)}
                    {CommonJsonFormComponent.renderJsonForm(this.state, jsonFromOthers, this.updateComponent)}
                    <Grid item xs={12}>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFormCardCallCategory, this.updateComponent)}
                    </Grid>
                    {CommonJsonFormComponent.renderJsonForm(this.state, JsonFormAlwaysShow, this.updateComponent)}
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <div style={{marginTop: 10}}>
                        {CommonJsonFormComponent.renderJsonForm(this.state, jsonFormFile, this.updateComponent)}
                    </div>
                </React.Fragment>
            )
        }
    }

    handleCommon = (event) => {
        event.preventDefault();
        this.validationForHandleConfirm()
    };

    validationForHandleConfirm = () => {
        this.setState({
            confirmAlert: true,
            title: "Do you want to permanently close the case?"
        });
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderSubmit = () => {
        if (this.state.inputData.customerCategory) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        marginTop: '10px',
                        marginRight: '10px',
                    }}
                    onClick={this.cardCallCreatedHandler}
                >
                    Submit
                </button>
            )
        }
    };

    renderCancel = () => {
        if (this.props.appId !== undefined) {
            return (
                <button
                    className="btn btn-grey btn-sm"
                    style={{
                        color: '#ffffff',
                        background: '#808080',
                        border: '2px solid #808080',
                        marginTop: '10px',
                        verticalAlign: 'middle',
                    }}
                    onClick={(event) => this.handleCommon(event)}

                >
                    Cancel
                </button>
            )
        }
    };

    renderHeader() {
        if (this.props.appId !== undefined) {
            return (
                <div><h4 style={{color: "white"}} >Maintenance Request<a><CloseIcon onClick={this.props.closeModal} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4></div>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >Maintenance Request</h4>
            )
        }
    }


    closeSuccessModal = () => {
        this.setState({
            successbloo: false
        });
        window.location.reload();
    };


    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };
    renderTargetsData = () => {


        if (this.state.getTargets.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Track Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Customer Category", "Call Category", "Open By", "Status", "Starting Time", "Ending Time"]}
                            tableData={this.state.getTargets}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                return (
                    <React.Fragment>
                        <Button href={backEndServerURL + "/file/" + data + `${sessionStorage.getItem("accessToken")}`}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }} target={"_blank"}>Preview Document {index + 1}</Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    closeConfirmAlert = (value) => {
        if (value === "ok"){
            let url = backEndServerURL + "/case/close/" + this.props.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        notificationType: "success",
                        notificationTitle: "Successful",
                        notificationMessage: "Case Route Successful",
                        alert: true,
                    });
                    this.props.closeModal();
                })
                .catch((error) => {
                    console.log(error)
                });
        }
        this.setState({
            confirmAlert: false,
        });
    };

    render() {
        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmAlert}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Grid container>
                        <ThemeProvider theme={theme}>
                            {this.renderJsonForm()}
                            {this.renderNotification()}
                            {this.dialogBoxShow()}
                        </ThemeProvider>
                    </Grid>

                    <Grid item xs='12' style={{marginTop: "0px"}}>
                        {this.mappingPhoto()}
                        <br></br>
                        {this.renderRemarksData()}
                        <br/>
                        {this.renderTargetsData()}
                        {this.renderSubmit()}
                        &nbsp;&nbsp;
                        {this.renderCancel()}
                    </Grid>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(MaintenanceRequest);
