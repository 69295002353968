import React, {Component} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "./LabelDetails";
import {Dialog} from "@material-ui/core";
import Box from '@material-ui/core/Box';

class Label extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {},
            showValue: true,
            labelData: this.props.data,
            getData: false,
        }
    }


    componentDidMount() {

        // console.log(this.props.data)

    }

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        if (this.state.labelData.length > 0) {
            return (

                <div style={{width: '100%'}}>
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="flex-start"
                         bgcolor="background.paper"
                         style={{marginTop: "15px", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>

                        {
                            this.state.labelData.map((label) => {
                                return (
                                    <Box p={1} bgcolor="background.paper">
                                        <label><font size="2"><b
                                            style={{color: label.popupValue ? "red" : "black"}}>
                                            {label.popupValue ? <a onClick={(event) => {
                                                this.labelDetails(label.popupValue)
                                            }}>{label.name}</a> : label.name} {label.popupValue ? "" : ": "}
                                        </b></font></label>
                                        {label.popupValue ? "" : label.value}
                                    </Box>

                                )
                            })
                        }
                    </Box>
                    <Dialog
                        fullWidth="true"
                        maxWidth="md"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.labelOpen}>
                        <DialogContent className={classes.dialogPaper}>
                            <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                               labelName={this.state.labelName} data={this.state.labelData}/>

                        </DialogContent>
                    </Dialog>
                </div>

            );
        } else {
            return ""
        }
    }


}

export default Label;
