import React, {Component} from "react";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import Notification from "../../NotificationMessage/Notification";
import CBNotFound from "../../workflow/CASA/CBNotFound";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import MyValidation from "../../JsonForm/MyValidation";
import functionForFileDownload from "../FunctionForFileDownload";
import axios from "axios";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};
const jsonForm = [
    {
        varName: "date",
        type: "date",
        label: "Date",
        grid: 2,
        required: true
    },
    {
        varName: "branch",
        type: "autoCompleteValueReturn",
        label: "Branch",
        grid: 2,
    },
];

class BranchSummaryReport extends Component {
    constructor() {
        super();
        this.state = {
            err: false,
            inputData: {},
            errorArray: {},
            errorMessages: {},
            showValue: true,
            varValue: {},
            selectedDate: {},
            notificationMessage: "",
            alert: false,
            loading: false,
            title: "",
            cityGem: "",
            cupInstant: "",
            cityMaxx: "",
            cbNotFound: false,
        };
    }

    componentDidMount() {
        this.dynamicApiCall("branch");
    }

    dynamicApiCall = (apiType) => {
        let branchUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                for (let form of jsonForm) {
                    if (form.type === "autoCompleteValueReturn" && form.varName === "branch" && apiType === "branch") {
                        form.enum = response.data
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    renderHeader = () => {
        return (
            <h4 style={{color: "white"}} >
                Instant Debit Card Summary Report
            </h4>
        )
    };


    renderJsonForm = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });

            let postData = {};
            postData.date = this.state.inputData.date;
            if (this.state.inputData.branch) {
                postData.solId = this.state.inputData.branch.key;
            }

            let url = backEndServerURL + "/getInstaDebitCardPdfFile";
            functionForFileDownload.downloadFile(url, "POST", postData, "summary-report.pdf").then((response) => {
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false,
                    title: "Something Went Wrong!",
                    cbNotFound: true,
                })
            })
        }
    };


    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {
                            this.renderHeader()
                        }
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {this.renderJsonForm()}
                                <Grid item xs={2} style={{marginTop: "15px"}}>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={this.handleSubmit}
                                    >
                                        Search
                                    </button>
                                </Grid>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                    {
                        this.renderNotification()
                    }
                </Card>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(BranchSummaryReport);
