import React from "react";
import validator from "validator";

class FormValidation {
    static getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    static defaultValidation(state, props, updateComponent) {
        let error = false;
        props.jsonForm.variables.forEach((field) => {

            state.errorArray[field.varName] = false;

            let assignError = false;

            if (field.required) {
                let getData = state.inputData[field.varName];
                if (validator.isEmpty(getData)) {
                    state.errorArray[field.varName] = true;
                    state.error = true;
                    state.errorText = true;

                    error = true;
                    state.errorMessages[field.varName] = "Required"
                    //console.log(field.varName);
                } else {

                    state.errorMessages[field.varName] = ""
                    //console.log("Not Required");
                }
            }
            if (field.number === true && state.inputData[field.varName] !== "") {

                if (validator.isNumeric(state.inputData[field.varName])) {
                    //console.log("number is Valid");
                } else {
                    state.error = true;
                    error = true;
                    state.errorMessages[field.varName] = "Invalid number";
                    state.errorArray[field.varName] = true;

                }

            } else if (field.email === true && state.inputData[field.varName] !== "") {

                if (validator.isEmail(state.inputData[field.varName])) {
                    //console.log("Email is Valid");
                } else {
                    state.error = true;
                    error = true;
                    state.errorMessages[field.varName] = "Invalid Email";
                    state.errorArray[field.varName] = true;

                }

            } else if (field.min !== undefined || field.max !== undefined) {


                let variable = (state.inputData[field.varName]);
                if (field.min === undefined) {
                    field.min = 0;
                }
                if (validator.isLength(variable, field.min, field.max)) {
                    //console.log("minimum and maximum is valid");
                } else {
                    state.error = true;
                    error = true;
                    state.errorMessages[field.varName] = "Minimum and maximum Length fulfil";
                    state.errorArray[field.varName] = true;
                    //console.log(state.inputData[field.varName])


                }


            } else if (field.dates === true) {

                if (validator.toDate(state.inputData[field.varName])) {
                    //console.log("Date is valid");
                } else {
                    state.error = true;
                    error = true;
                    state.errorArray[field.varName] = true;
                    state.errorMessages["passport"] = "Required"
                    //console.log("invalids")
                    //console.log(state.inputData[field.varName])
                }
            }
        });

        updateComponent();
        return error;
    }

    static customValidationIndividual(props, state, updateComponent) {
        let error = false;
        if (props.validation === "INDIVIDUAL" && state.inputData !== undefined) {

            if (state.inputData["cbNumber"] !== undefined && !validator.isEmpty(state.inputData["cbNumber"])) {
                props.jsonForm.variables.forEach((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;

                });

            } else {
                if ((state.inputData["nid"] === undefined && state.inputData["passport"] === undefined && state.inputData["registrationNo"] === undefined) || (validator.isEmpty(state.inputData["nid"]) && validator.isEmpty(state.inputData["passport"]) && validator.isEmpty(state.inputData["registrationNo"]))) {
                    state.errorArray["nid"] = true;
                    state.errorMessages["nid"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";

                    state.errorArray["passport"] = true;
                    state.errorMessages["passport"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";


                    state.errorArray["registrationNo"] = true;
                    state.errorMessages["registrationNo"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {

                    state.errorArray["nid"] = false;
                    state.errorArray["passport"] = false;
                    state.errorArray["registrationNo"] = false;

                    state.errorMessages["nid"] = "";
                    state.errorMessages["passport"] = "";
                    state.errorMessages["registrationNo"] = "";
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }
                if (state.inputData["customerName"] === undefined || state.inputData["cbNumber"] === undefined || validator.isEmpty(state.inputData["customerName"])) {
                    state.errorArray["customerName"] = true;
                    state.errorMessages["customerName"] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }


                if (state.inputData["dob"] === undefined || validator.isEmpty(state.inputData["dob"])) {
                    state.errorArray["dob"] = true;
                    state.errorMessages["dob"] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["email"] === undefined || (!validator.isEmpty(state.inputData["email"]) && state.inputData["email"] !== undefined && state.inputData["email"] !== null && (
                        !validator.isEmail(state.inputData["email"]))
                )) {
                    state.errorArray["email"] = true;
                    state.errorMessages["email"] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality"] === undefined || validator.isEmpty(state.inputData["nationality"])) {
                    state.errorArray["nationality"] = true;
                    state.errorMessages["nationality"] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid"] === undefined || (!validator.isEmpty(state.inputData["nid"]) && !validator.isLength(state.inputData["nid"], {
                    min: 10,
                    max: 10
                }) && !validator.isLength(state.inputData["nid"], {
                    min: 13,
                    max: 13
                }) && !validator.isLength(state.inputData["nid"], {min: 17, max: 17}))) {
                    state.errorArray["nid"] = true;
                    state.errorMessages["nid"] = "NID should be 10/13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["phone"] === undefined || validator.isEmpty(state.inputData["phone"])) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if (state.inputData["phone"] === undefined || (!validator.isEmpty(state.inputData["phone"]) && !validator.isNumeric(state.inputData["phone"]))) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "phone should be Only number digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if (state.inputData["phone"] === undefined || (!validator.isEmpty(state.inputData["phone"]) && !validator.isLength(state.inputData["phone"], {
                    min: 11,
                    max: 11
                }))) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "phone should be 11 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


            }

        }


        updateComponent();
        return error;
    };

    static customValidationProprietorship(props, state, updateComponent) {
        let error = false;
        if (props.validation === "PROPRIETORSHIP") {

            if (state.inputData["cbNumber"] !== undefined && !validator.isEmpty(state.inputData["cbNumber"])) {
                props.jsonForm.variables.forEach((field) => {
                    state.errorMessages[field.varName] = "";
                    state.errorArray[field.varName] = false;
                    state.error = false;
                    state.errorText = false;
                    error = false;

                });

            } else {


                if ((state.inputData["nid"] === undefined && state.inputData["passport"] === undefined && state.inputData["registrationNo"] === undefined) || (validator.isEmpty(state.inputData["nid"]) && validator.isEmpty(state.inputData["passport"]) && validator.isEmpty(state.inputData["registrationNo"]))) {
                    state.errorArray["nid"] = true;

                    state.errorMessages["nid"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";

                    state.errorArray["passport"] = true;
                    state.errorMessages["passport"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";


                    state.errorArray["registrationNo"] = true;
                    state.errorMessages["registrationNo"] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else {


                    state.errorArray["nid"] = false;
                    state.errorArray["passport"] = false;
                    state.errorArray["registrationNo"] = false;

                    state.errorMessages["nid"] = "";
                    state.errorMessages["passport"] = "";
                    state.errorMessages["registrationNo"] = "";
                    state.error = false;
                    state.errorText = false;
                    error = false;
                }

                if (state.inputData["customerName"] === undefined || validator.isEmpty(state.inputData["customerName"])) {
                    state.errorArray["customerName"] = true;
                    state.errorMessages["customerName"] = "Customer Name Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;

                }


                if (state.inputData["dob"] === undefined || validator.isEmpty(state.inputData["dob"])) {
                    state.errorArray["dob"] = true;
                    state.errorMessages["dob"] = "Date Of Birth Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["email"] === undefined || (!validator.isEmpty(state.inputData["email"]) && state.inputData["email"] !== undefined && state.inputData["email"] !== null && (
                        !validator.isEmail(state.inputData["email"]))
                )) {
                    state.errorArray["email"] = true;
                    state.errorMessages["email"] = "Email is Not Valid";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["nationality"] === undefined || validator.isEmpty(state.inputData["nationality"])) {
                    state.errorArray["nationality"] = true;
                    state.errorMessages["nationality"] = "Nationality Required";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

                if (state.inputData["nid"] === undefined || (!validator.isEmpty(state.inputData["nid"]) && !validator.isLength(state.inputData["nid"], {
                    min: 10,
                    max: 10
                }) && !validator.isLength(state.inputData["nid"], {
                    min: 13,
                    max: 13
                }) && !validator.isLength(state.inputData["nid"], {min: 17, max: 17}))) {
                    state.errorArray["nid"] = true;
                    state.errorMessages["nid"] = "NID should be 10/13/17 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["phone"] === undefined || validator.isEmpty(state.inputData["phone"])) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "Phone Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if (state.inputData["phone"] === undefined || (!validator.isEmpty(state.inputData["phone"]) && !validator.isNumeric(state.inputData["phone"]))) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "phone should be Only number digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                } else if (state.inputData["phone"] === undefined || (!validator.isEmpty(state.inputData["phone"]) && !validator.isLength(state.inputData["phone"], {
                    min: 11,
                    max: 11
                }))) {
                    state.errorArray["phone"] = true;
                    state.errorMessages["phone"] = "phone should be 11 digit";

                    state.error = true;
                    state.errorText = true;
                    error = true;
                }


            }
            if (state.inputData["companyCbNumber"] !== undefined && !validator.isEmpty(state.inputData["companyCbNumber"])) {
                state.errorArray["companyEtin"] = false;
                state.errorMessages["companyEtin"] = "";
                state.errorArray["companyName"] = false;
                state.errorMessages["companyName"] = "";
                state.errorArray["companyTradeLicense"] = false;
                state.errorMessages["companyTradeLicense"] = "";
                state.errorArray["certificate"] = false;
                state.errorMessages["certificate"] = "";
                state.error = false;
                state.errorText = false;
                error = false;

            } else {


                if (state.inputData["companyName"] === undefined || validator.isEmpty(state.inputData["companyName"])) {
                    state.errorArray["companyName"] = true;
                    state.errorMessages["companyName"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["companyEtin"] === undefined || validator.isEmpty(state.inputData["companyEtin"])) {
                    state.errorArray["companyEtin"] = true;
                    state.errorMessages["companyEtin"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["companyTradeLicense"] === undefined || validator.isEmpty(state.inputData["companyTradeLicense"])) {

                    state.errorArray["companyTradeLicense"] = true;
                    state.errorMessages["companyTradeLicense"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["certificate"] === undefined || validator.isEmpty(state.inputData["certificate"])) {

                    state.errorArray["certificate"] = true;
                    state.errorMessages["certificate"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }

            }


        }


        updateComponent();
        return error;
    };

    static customValidationJoint(props, state, updateComponent) {
        let error = false;

        if (props.validation === "JOINT") {

            for (var j = 0; j < props.arrayLength; j++) {
                if (state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(state.inputData["cbNumber" + j])) {
                    props.jsonForm.variables.forEach((field) => {
                        state.errorMessages[field.varName] = "";
                        state.errorArray[field.varName] = false;
                        state.error = false;
                        state.errorText = false;
                        error = false;

                    });

                } else {
                    if ((state.inputData["nid" + j] === undefined && state.inputData["passport" + j] === undefined && state.inputData["registrationNo" + j] === undefined) || (validator.isEmpty(state.inputData["nid" + j]) && validator.isEmpty(state.inputData["passport" + j]) && validator.isEmpty(state.inputData["registrationNo" + j]))) {
                        state.errorArray["nid" + j] = true;
                        state.errorMessages["nid" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";

                        state.errorArray["passport" + j] = true;
                        state.errorMessages["passport" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";


                        state.errorArray["registrationNo" + j] = true;
                        state.errorMessages["registrationNo" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    } else {
                        state.errorArray["nid" + j] = false;
                        state.errorArray["passport" + j] = false;
                        state.errorArray["registrationNo" + j] = false;

                        state.errorMessages["nid" + j] = "";
                        state.errorMessages["passport" + j] = "";
                        state.errorMessages["registrationNo" + j] = ""
                    }
                    if (state.inputData["customerName" + j] === undefined || validator.isEmpty(state.inputData["customerName" + j])) {
                        state.errorArray["customerName" + j] = true;
                        state.errorMessages["customerName" + j] = "Customer Name Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }
                    if (state.inputData["nid" + j] === undefined || (!validator.isEmpty(state.inputData["nid" + j]) && state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && (
                            !validator.isLength(state.inputData["nid" + j], {
                                min: 10,
                                max: 10
                            })) && !validator.isLength(state.inputData["nid" + j], {
                            min: 13,
                            max: 13
                        }) && !validator.isLength(state.inputData["nid" + j], {min: 17, max: 17})
                    )) {
                        state.errorArray["nid" + j] = true;

                        state.errorMessages["nid" + j] = "NID should be 10/13/17 digit";
                        //console.log(field.varName);
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["email" + j] === undefined || (!validator.isEmpty(state.inputData["email" + j]) && state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== null && (
                            !validator.isEmail(state.inputData["email" + j]))
                    )) {
                        state.errorArray["email" + j] = true;
                        state.errorMessages["email" + j] = "Email is Not Valid";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["phone" + j] === undefined || validator.isEmpty(state.inputData["phone" + j])) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    } else if (state.inputData["phone" + j] === undefined || (!validator.isEmpty(state.inputData["phone" + j]) && !validator.isNumeric(state.inputData["phone" + j]))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "phone should be Only number digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if (state.inputData["phone" + j] === undefined || ((!validator.isEmpty(state.inputData["phone" + j]) && !validator.isLength(state.inputData["phone" + j], {
                        min: 11,
                        max: 11
                    })))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "phone should be 11 digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["dob" + j] === undefined || validator.isEmpty(state.inputData["dob" + j])) {
                        state.errorArray["dob" + j] = true;
                        state.errorMessages["dob" + j] = "Date Of Birth Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }
                    if (state.inputData["nationality" + j] === undefined || validator.isEmpty(state.inputData["nationality" + j])) {
                        state.errorArray["nationality" + j] = true;
                        state.errorMessages["nationality" + j] = "Nationality Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }
                }
            }


        }

        updateComponent();
        return error;


    };

    static customValidationCompany(props, state, updateComponent) {
        let error = false;

        if (props.validation === "COMPANY") {
            if (state.inputData["companyCbNumber"] !== undefined && !validator.isEmpty(state.inputData["companyCbNumber"])) {
                state.errorArray["companyEtin"] = false;
                state.errorMessages["companyEtin"] = "";
                state.errorArray["companyName"] = false;
                state.errorMessages["companyName"] = "";
                state.errorArray["companyTradeLicense"] = false;
                state.errorMessages["companyTradeLicense"] = "";
                state.errorArray["certificate"] = false;
                state.errorMessages["certificate"] = "";
                state.error = false;
                state.errorText = false;
                error = false;

            } else {


                if (state.inputData["companyName"] === undefined || validator.isEmpty(state.inputData["companyName"])) {
                    state.errorArray["companyName"] = true;
                    state.errorMessages["companyName"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["companyEtin"] === undefined || validator.isEmpty(state.inputData["companyEtin"])) {
                    state.errorArray["companyEtin"] = true;
                    state.errorMessages["companyEtin"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["companyTradeLicense"] === undefined || validator.isEmpty(state.inputData["companyTradeLicense"])) {

                    state.errorArray["companyTradeLicense"] = true;
                    state.errorMessages["companyTradeLicense"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
                if (state.inputData["certificate"] === undefined || validator.isEmpty(state.inputData["certificate"])) {

                    state.errorArray["certificate"] = true;
                    state.errorMessages["certificate"] = "Required";
                    state.error = true;
                    state.errorText = true;
                    error = true;
                }
            }
            for (var j = 0; j < props.arrayLength; j++) {
                if (state.inputData["companyCbNumber"] !== undefined && !validator.isEmpty(state.inputData["companyCbNumber"])) {
                    state.errorArray["companyEtin"] = false;
                    state.errorMessages["companyEtin"] = "";
                    state.errorArray["companyName"] = false;
                    state.errorMessages["companyName"] = "";
                    state.errorArray["companyTradeLicense"] = false;
                    state.errorMessages["companyTradeLicense"] = "";
                    state.errorArray["certificate"] = false;
                    state.errorMessages["certificate"] = "";
                    state.error = false;
                    state.errorText = false;
                    error = false;

                } else {


                    if (state.inputData["companyName"] === undefined || validator.isEmpty(state.inputData["companyName"])) {
                        state.errorArray["companyName"] = true;
                        state.errorMessages["companyName"] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["companyEtin"] === undefined || validator.isEmpty(state.inputData["companyEtin"])) {
                        state.errorArray["companyEtin"] = true;
                        state.errorMessages["companyEtin"] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["companyTradeLicense"] === undefined || validator.isEmpty(state.inputData["companyTradeLicense"])) {

                        state.errorArray["companyTradeLicense"] = true;
                        state.errorMessages["companyTradeLicense"] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["certificate"] === undefined || validator.isEmpty(state.inputData["certificate"])) {

                        state.errorArray["certificate"] = true;
                        state.errorMessages["certificate"] = "Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                }
                if (state.inputData["cbNumber" + j] !== undefined && !validator.isEmpty(state.inputData["cbNumber" + j])) {
                    props.jsonForm.variables.forEach((field) => {
                        state.errorMessages[field.varName] = "";
                        state.errorArray[field.varName] = false;
                        state.error = false;
                        state.errorText = false;
                        error = false;

                    });

                } else {

                    if ((state.inputData["nid" + j] === undefined && state.inputData["passport" + j] === undefined && state.inputData["registrationNo" + j] === undefined) || (validator.isEmpty(state.inputData["nid" + j]) && validator.isEmpty(state.inputData["passport" + j]) && validator.isEmpty(state.inputData["registrationNo" + j]))) {
                        state.errorArray["nid" + j] = true;
                        state.errorMessages["nid" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";
                        state.errorArray["passport" + j] = true;
                        state.errorMessages["passport" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";
                        state.errorArray["registrationNo" + j] = true;
                        state.errorMessages["registrationNo" + j] = "NID OR PASSPORT OR REGISTRATION NO ANY ONE FILL UP";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    } else {
                        state.errorArray["nid" + j] = false;
                        state.errorArray["passport" + j] = false;
                        state.errorArray["registrationNo" + j] = false;

                        state.errorMessages["nid" + j] = "";
                        state.errorMessages["passport" + j] = "";
                        state.errorMessages["registrationNo" + j] = ""
                    }
                    if (state.inputData["customerName" + j] === undefined || validator.isEmpty(state.inputData["customerName" + j])) {
                        state.errorArray["customerName" + j] = true;
                        state.errorMessages["customerName" + j] = "Customer Name Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }
                    if (state.inputData["nid" + j] === undefined || (!validator.isEmpty(state.inputData["nid" + j]) && state.inputData["nid" + j] !== undefined && state.inputData["nid" + j] !== null && (
                            !validator.isLength(state.inputData["nid" + j], {
                                min: 10,
                                max: 10
                            })) && !validator.isLength(state.inputData["nid" + j], {
                            min: 13,
                            max: 13
                        }) && !validator.isLength(state.inputData["nid" + j], {min: 17, max: 17})
                    )) {
                        state.errorArray["nid" + j] = true;

                        state.errorMessages["nid" + j] = "NID should be 10/13/17 digit";
                        //console.log(field.varName);
                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["email" + j] === undefined || (!validator.isEmpty(state.inputData["email" + j]) && state.inputData["email" + j] !== undefined && state.inputData["email" + j] !== null && (
                        !validator.isEmail(state.inputData["email" + j])))
                    ) {
                        state.errorArray["email" + j] = true;
                        state.errorMessages["email" + j] = "Email is Not Valid";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["phone" + j] === undefined || validator.isEmpty(state.inputData["phone" + j])) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "Phone Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    } else if (state.inputData["phone" + j] === undefined || (!validator.isEmpty(state.inputData["phone" + j]) && !validator.isNumeric(state.inputData["phone" + j]))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "phone should be Only number digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    } else if (state.inputData["phone" + j] === undefined || (!validator.isEmpty(state.inputData["phone" + j]) && !validator.isLength(state.inputData["phone" + j], {
                        min: 11,
                        max: 11
                    }))) {
                        state.errorArray["phone" + j] = true;
                        state.errorMessages["phone" + j] = "phone should be 11 digit";

                        state.error = true;
                        state.errorText = true;
                        error = true;
                    }
                    if (state.inputData["dob" + j] === undefined || validator.isEmpty(state.inputData["dob" + j])) {
                        state.errorArray["dob" + j] = true;
                        state.errorMessages["dob" + j] = "Date Of Birth Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }
                    if (state.inputData["nationality" + j] === undefined || validator.isEmpty(state.inputData["nationality" + j])) {
                        state.errorArray["nationality" + j] = true;
                        state.errorMessages["nationality" + j] = "Nationality Required";
                        state.error = true;
                        state.errorText = true;
                        error = true;

                    }


                }

            }

        }

        updateComponent();
        return error;
    };
}

export default FormValidation;