import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import FileRequisitionProcessFormLabel from "./FileRequisitionProcessFormLabel";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let csRemarks = [
    {
        "varName": "remarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class FileRequisitionProcessForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                fileReferenceNo: this.props.fileReferenceNo,
                applicantName: this.props.applicantName,
                assetOpsNumber: this.props.assetOpsNumber,
                accountNumber: this.props.accountNumber,
                requisitionBy: this.props.requisitionBy,
                requisitionDate: this.props.requisitionDate,
                purpose: this.props.purpose,
                departmentName: this.props.departmentName,
            },
            fileName: this.props.fileName,
            activeItem: this.props.activeItem,
            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            fileUploadData: [],
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            status: '',

        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        // this.renderStartCase();
        // this.updateComponent()
        console.log('i am here....please talk to me');
        console.log(this.props.activeItem);
        var remarksArray = [];
        this.setState({
            loading: true
        });

        if (this.props.fileRequisitionId !== undefined) {
            let getCommentsUrl = backEndServerURL + "/fileRequisitionRemarks/search/" + this.props.fileRequisitionId;
            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);
                response.data.map((data) => {
                    if (data.remarks !== 'undefined') {
                        remarksArray.push(this.createRemarksTable(data.remarks, data.remarksBy, data.remarksTime))
                    }
                });
                this.setState({
                    getRemarks: remarksArray,
                    loading: false
                })
            })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        } else {
            this.setState({
                loading: false
            })
        }


    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderCustomerDetails = () => {

        return (
            <FileRequisitionProcessFormLabel data={this.state.inputData} classes={this.props}
                                             labelGrid={[3, 3, 3, 3, 3, 3, 3, 3]}/>
        )

    };


    renderRemarks = () => {

        if (this.props.fileName === "FileRequisitionProcess" && (this.props.activeItem === "Sent" || this.props.activeItem === "Received by Initiator" || this.props.activeItem === "History")) {

        } else {
            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )

        }


    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return (

            <div>


                <ThemeProvider theme={theme}>
                    <Grid container>
                        {this.renderRemarks()}
                        <Grid item xs='12'>
                        </Grid>


                    </Grid>
                </ThemeProvider>

            </div>


        )


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    handleSubmitButton = () => {

        if (this.props.fileName === "FileRequisitionProcess" && (this.props.activeItem === "Sent" || this.props.activeItem === "Received by Initiator" || this.props.activeItem === "History")) {

        } else {
            if (this.props.activeItem === "Requisition") {

                return (
                    <ThemeProvider>
                        <Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{}}
                                onClick={this.handleSubmit}

                            >
                                Send File
                            </button>

                        </Grid>
                    </ThemeProvider>
                )

            } else if (this.props.activeItem === "Sent") {

                return (
                    <ThemeProvider>
                        <Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{}}
                                onClick={this.handleSubmit}

                            >
                                Receive File
                            </button>

                        </Grid>
                    </ThemeProvider>
                )
            } else if (this.props.activeItem === "Received by Initiator") {

                return (
                    <ThemeProvider>
                        <Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{}}
                                onClick={this.handleSubmit}

                            >
                                Return File
                            </button>

                        </Grid>
                    </ThemeProvider>
                )
            } else if (this.props.activeItem === "Returned by Initiator") {

                return (
                    <ThemeProvider>
                        <Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{}}
                                onClick={this.handleSubmit}

                            >
                                Receive File
                            </button>

                        </Grid>
                    </ThemeProvider>
                )
            } else if (this.props.activeItem === "In Archive" || this.props.activeItem === "In Archive After NOC") {

                return (
                    <ThemeProvider>
                        <Grid>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{}}
                                onClick={this.handleSubmit}

                            >
                                Save
                            </button>

                        </Grid>
                    </ThemeProvider>
                )
            }


        }


    };
    handleSubmit = (event) => {
        event.preventDefault();
        console.log(this.state.inputData);
        let object = this.state.inputData;
        console.log(this.state.activeItem);
        this.setState({
            loading: true,
        });

        let url = backEndServerURL + "";
        if (this.props.activeItem === "Requisition") {
            console.log("requisition to sent");
            url = backEndServerURL + "/archiveFile/sent/";
        } else if (this.props.activeItem === "Sent") {
            console.log("sent to received");
            url = backEndServerURL + "/archiveFile/received/";
        } else if (this.props.activeItem === "Received by Initiator") {

            console.log("received to returned");
            url = backEndServerURL + "/archiveFile/returned/";
        } else if (this.props.activeItem === "Returned by Initiator") {

            console.log("returned to returnReceived");
            url = backEndServerURL + "/archiveFile/returnReceived/";
        } else if (this.props.activeItem === "In Archive" || this.props.activeItem === "In Archive After NOC") {

            console.log("In Archive");
            url = backEndServerURL + "/archiveFile/save/";
        }


        axios
            .post(
                url,
                {

                    fileReferenceNo: object.fileReferenceNo,
                    remarks: object.remarks,
                    status: this.props.activeItem === "In Archive" ? 'returnReceived' : this.props.activeItem === "In Archive After NOC" ? 'returnReceivedNOC' : ''
                },
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                }
            )
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Sucessfully!!",
                    notificationMessage: "Sucessfully Done",
                    alert: true,
                });
                console.log("Added Successfully");
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
            });


    };


    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };
    uploadPrintModal = () => {
        this.setState({
            uploadPrintModal: true
        });
        this.closeModal();
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };


    createRemarksTable = (remarks, name, a) => {
        return (
            [remarks, name, a]
        )
    };
    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };


    render() {

        console.log("inputData");
        console.log(this.state.inputData);
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.croppedImage}>
                    <DialogContent className={classes.dialogPaper}>
                        <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>

                    </DialogContent>
                </Dialog>

                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >
                        File Requisition<a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a></h4>
                </CardHeader>
                <CardBody>

                    {this.renderNotification()}
                    {this.renderCustomerDetails()}


                    <br/>

                    {this.renderRemarksData()}

                    <br/>

                    {this.commonData()}


                    {this.handleSubmitButton()}

                </CardBody>
            </Card>
        );


    }

}

export default withStyles(styles)(FileRequisitionProcessForm);
