import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import {BOMsecuredLoanClosingJsonForm} from "../../WorkflowjsonFormForAssetOps";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import MyValidation from "../../../JsonForm/MyValidation";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import CBNotFound from "../../CASA/CBNotFound";
import CommonApi from "../../Maintenance/common/CommonApi";


const styles = {

    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }]
;


class BomSecuredLoanClosing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {
                bom_approved: '',
            },

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            fileUploadData: {},
            actionType: "",
            confirmAlert: false,
            cbNotFound: false,
            disabled: false,
            insufficientBalance: false,


        }

    }

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then((response) => {
                let inputData = {...response.data};
                inputData.bomRemarks = undefined;
                inputData.closureStatus = (inputData.closureStatus === undefined || inputData.closureStatus === null) ? "Default" : inputData.closureStatus
                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileResponse) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            varValue: this.removeNullValue(inputData),
                            inputData: this.removeNullValue(inputData),
                            appId: this.props.appId,
                            getImageLink: fileResponse,
                            getImageBoolean: true,
                            loading: false
                        })
                    }).catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
                }).catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    }

    handleClose = () => {
        this.setState({
            setOpen: false
        })

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, BOMsecuredLoanClosingJsonForm, this.updateComponent)

            )
        }

    };


    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    commonData = () => {


        return this.renderForm()


    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={"Successful"}
                              message={this.state.notificationMessage}/>
            )
        }


    };
    errorObjectCheck = (error) => {
        if (error.response !== undefined) {
            if (error.response.data !== undefined) {
                if (error.response.data.message !== undefined) {
                    return error.response.data.message
                } else {
                    return "Finacle System Error!"
                }
            } else {
                return "Finacle System Error!"
            }
        } else {
            return "Finacle System Error!"
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    closeConfirmAlert = (data, appId) => {
        this.setState({
            confirmAlert: false,
        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "handleSubmit") {

            this.handleSubmit("YES")
        } else if (data === "YES" && this.state.actionType === "handleReturn") {

            this.handleSubmit("NO")
        }
    };
    validationForHandleConfirm = (actionType) => {
        let errorRemarks = MyValidation.defaultValidation(bomRemarks, this.state);
        if (errorRemarks === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })

        } else if (actionType === "handleReturn") {
            let dependencyField = [];
            if (actionType === "handleReturn") {
                dependencyField.push({

                    "varName": "bomRemarks",
                    "type": "textArea",
                    "required": true,
                    "label": "BOM Remarks",
                    "grid": 12
                })
            }
            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }


            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };
    handleCommon = (event, type) => {
        event.preventDefault();
        {
            this.validationForHandleConfirm(type)
        }
    };

    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (clone[prop] === '' || clone[prop] === ' ' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    handleSubmit = (data) => {
        this.handleCaseRoute(data)
    }

    handleCaseRoute = (data) => {
        let saveDataDeferralRemarksAndRoute = {};
        this.state.inputData.bom_approved = data;
        saveDataDeferralRemarksAndRoute.map = {...this.state.inputData};
        if (this.state.inputData.bomRemarks !== undefined && this.state.inputData.bomRemarks !== null) {
            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.bomRemarks;
        }
        var url = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                console.log(response.data);
                this.setState({
                    title: "Successfull!",
                    notificationMessage: response.data,
                    alert: true,
                    loading: false
                });

                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })

            });

    };
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        });
        this.closeModal();
    };


    renderRemarks = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, bomRemarks, this.updateComponent)
        )
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: '100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };


    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            //let i=0;
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <Button target={"_blank"}
                                href={assetOpsBackEndServerURL + "/file/" + data + '/' + sessionStorage.getItem("accessToken")}
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    borderRadius: 3,
                                    border: 1,
                                    textDecoration: "none"
                                }}
                                target={"_blank"}>{splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </Button>&nbsp;&nbsp;
                    </React.Fragment>
                )
            })
        }
    };


    dateModifier = (date) => {
        if (date !== undefined && date != null) {
            let splittedDate = (date).split("/");
            return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`;
        }
    }

    renderFormTable = () => {
        return (
            <div style={{padding: "10px 5px 20px", width: '100%'}}>
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <table className="assetTable" style={{borderCollapse: 'collapse', "width": "100%"}}>
                        <tr>
                            <th scope="row">Product Name</th>
                            <td>{this.state.inputData.productName}</td>
                            <th scope="row">Loan Amount</th>
                            <td>{this.state.inputData.loanAmt}</td>
                        </tr>
                        <tr>
                            <th scope="row">Scheme Code</th>
                            <td>{this.state.inputData.schemeCode}</td>
                            <th scope="row">Pay Of Balance</th>
                            <td>{this.state.inputData.loanAccountBalance}</td>
                        </tr>
                        <tr>
                            <th scope="row">Date of Disbursement</th>
                            <td>{this.dateModifier(this.state.inputData.disbursementDate)}</td>
                            <th scope="row">Early Settlement Fee</th>
                            <td>{this.state.inputData.earlySettlementFee}</td>
                        </tr>
                        <tr>
                            <th scope="row">Loan Expiry Date</th>
                            <td>{this.dateModifier(this.state.inputData.loanAccountExpiryDate)}</td>
                            <th scope="row">VAT</th>
                            <td>{this.state.inputData.vat}</td>
                        </tr>
                        <tr>
                            <th scope="row">Maturity Status</th>
                            <td>{this.state.inputData.maturityStatus}</td>
                            <th scope="row">Excise Duty</th>
                            <td>{this.state.inputData.exciseDuty}</td>
                        </tr>

                        <tr>
                            <th scope="row">Total Settlement Amount</th>
                            <td>{this.state.inputData.totalAmount}</td>
                            <th scope="row">CASA BALANCE</th>
                            <td>{this.state.inputData.casaBalance}</td>
                        </tr>

                    </table>
                </div>
            </div>
        )
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>Secured Loan Closing</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}<b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.close}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Secured Loan Closing{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.close} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    renderSubmitButtonForOD = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={12}>
                    <div>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'right',
                                    marginTop: 20

                                }}

                                type='button' value='add more'
                                onClick={(event) => this.handleCommon(event, "handleSubmit")}

                            >Submit
                            </button>

                        &nbsp;&nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: 'right',
                                marginTop: 20

                            }}

                            type='button' value='add more'
                            onClick={(event) => this.handleCommon(event, "handleReturn")}
                        >Return
                        </button>
                    </div>
                </Grid>
            )
        }
    }
    handleSms = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        if (this.state.getData && this.state.insufficientBalance && this.state.shortageAmount !== undefined) {
            let postData = {
                "cbNumber": this.state.inputData.cbNumber,
                "msg": " Dear Sir/ Madam,\n We are unable to process the closure request due to insufficient fund. Please arrange to deposit " + this.state.shortageAmount + " (Shortage amount) to close the account today. This amount will vary in future date. ",
                "mbNo": "01684538832",
            };
            let url = assetOpsBackEndServerURL + "/insufficientBalanceSms";
            axios.post(url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},
            }).then((response) => {
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: response.data,
                });
            }).catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false
                })
            });

        }
    };


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <Card>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.croppedImage}>
                    <DialogContent className={classes.dialogPaper}>
                        <AssignedCropImage closeModal={this.closeCroppedModal} appId={this.state.appId}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderNotification()}
                    <br/>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                          closeModal={this.closeConfirmAlert}
                                          asset={true}
                            />

                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderForm()}
                            <Grid item xs={12}></Grid>
                            {this.mappingPhoto()}
                            <Grid item xs={12}></Grid>
                            {/*{this.renderFormTable()}*/}
                            <br/>
                            {this.renderFlowSUmmeryButton()}
                            <br/>
                            {this.renderRemarksData()}
                            <br/>
                            {this.renderRemarks()}
                            {this.renderSubmitButtonForOD()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>
        );
    }


}

export default withStyles(styles)(BomSecuredLoanClosing);
