import React, {Component} from 'react';
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import {backEndServerURL, frontEndServerURL} from "../../../../Common/Constant";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';
import axios from "axios";
import Table from "../../../Table/Table";
import CommonApi from "../common/CommonApi";


class LabelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            labelData: [],
            inputData: {},
            showValue: true,
            getDeferalList: [],
            siTableArray: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            loader: true,
            errorArray: {},
            errorMessages: {},
        }
    }


    componentDidMount() {
        if (this.props.labelName === "ADUP") {
            let cifUrl = backEndServerURL + "/getCustomerCIFDetails/" + this.props.cbNumber;
            axios.get(cifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((cifResponse) => {
                    let getAccountUrl = backEndServerURL + "/getAccountInfo/" + this.props.accountNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let inputData = {
                                ...cifResponse.data, ...this.props.inputValue,
                                ccepCode: response.data.ccepcode,
                                acqRmCode: response.data.rmcode
                            };
                            this.setState({
                                inputData: inputData,
                                labelData: this.props.data,
                                getData: true,
                                loader: false,
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                inputData: this.props.inputValue,
                                labelData: this.props.data,
                                getData: true,
                                loader: false,
                            })
                        });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        inputData: this.props.inputValue,
                        labelData: this.props.data,
                        getData: true,
                        loader: false,
                    })
                });
        } else if (this.props.labelName === "accountLimit") {
            let url = backEndServerURL + '/getAccountInfo/' + this.props.accountNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((acResponse) => {
                    let inputData = {};
                    inputData.fincoreDataUpdatecashLimitDr = acResponse.data.drcashlim;
                    inputData.fincoreDataUpdatecashLimitCr = acResponse.data.crcashlim;
                    inputData.fincoreDataUpdateclearingLimitDr = acResponse.data.drclrlim;
                    inputData.fincoreDataUpdateclearingLimitCr = acResponse.data.crclrlim;
                    inputData.fincoreDataUpdatetransferLimitDr = acResponse.data.drtxlim;
                    inputData.fincoreDataUpdatetransferLimitCr = acResponse.data.crtxlim;
                    this.setState({
                        inputData: inputData,
                        labelData: this.props.data,
                        getData: true,
                        loader: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        inputData: this.props.inputValue,
                        labelData: this.props.data,
                        getData: true,
                        loader: false,
                    })
                });
        } else if (this.props.labelName === "SI") {
            let url = backEndServerURL + "/DPS/getAllSINumbers/" + this.props.accountNumber + "/" + this.props.cbNumber;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((siResponse) => {
                let siTableArray = [];
                siResponse.data.map((item, index) => {
                    siTableArray.push(this.createSiTableData(index + 1, item))
                });
                this.setState({
                    siTableArray: siTableArray,
                    inputData: this.props.inputValue,
                    labelData: this.props.data,
                    getData: true,
                    loader: false,
                })
            }).catch((error) => {
                this.setState({
                    inputData: this.props.inputValue,
                    labelData: this.props.data,
                    getData: true,
                    loader: false,
                })
            })
        } else {
            this.setState({
                inputData: this.props.inputValue,
                labelData: this.props.data,
                getData: true,
                loader: false,
            })
        }
    }

    detailsInformationlabelWise = () => {
        if (this.state.getData) {
            if (this.props.labelName === "CTR") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Account Number :</b></font></label>

                                {this.state.inputData.acctNumber}
                            </Box>

                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Account Name :</font></b> </label>

                                {this.state.inputData.acctName}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Free Text 12 (Fincore) :</b></font></label>

                                {this.state.inputData.freeText12}
                            </Box>


                        </Box>
                    </div>
                )
            } else if (this.props.labelName === "ADUP") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>

                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Account Number :</b></font></label>

                                {this.state.inputData.acctNumber}
                            </Box>

                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Account Name :</font></b> </label>
                                {this.state.inputData.acctName}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Customer Status :</b></font></label>
                                {this.props.crmStatus}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>CTR Status :</b></font></label>
                                {this.state.inputData.free_text_12}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Pep Status :</b></font></label>
                                {this.state.inputData.free_text_11}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Priority Center :</b></font></label>
                                {this.state.inputData.freecode1}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Monitoring RM :</b></font></label>
                                {this.state.inputData.freecode3}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Acquiring RM :</b></font></label>
                                {this.state.inputData.acqRmCode}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>CCEP Code :</b></font></label>
                                {this.state.inputData.ccepCode}
                            </Box>
                        </Box>
                    </div>
                )
            } else if (this.props.labelName === "accountLimit") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Cash Limit (Dr) :</b></font></label>
                                {this.state.inputData.fincoreDataUpdatecashLimitDr}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Cash Limit (Cr) :</font></b> </label>
                                {this.state.inputData.fincoreDataUpdatecashLimitCr}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Clearing Limit (Dr) :</b></font></label>
                                {this.state.inputData.fincoreDataUpdateclearingLimitDr}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Clearing Limit (Cr) :</b></font></label>
                                {this.state.inputData.fincoreDataUpdateclearingLimitCr}
                            </Box>
                        </Box>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Transfer Limit (Dr) :</b></font></label>
                                {this.state.inputData.fincoreDataUpdatetransferLimitDr}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Transfer Limit (Cr) :</b></font></label>
                                {this.state.inputData.fincoreDataUpdatetransferLimitCr}
                            </Box>
                        </Box>
                    </div>
                )
            } else if (this.props.labelName === "Dormancy") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>

                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Account Number :</b></font></label>

                                {this.state.inputData.acctNumber}
                            </Box>

                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Account Name :</font></b> </label>

                                {this.state.inputData.acctName}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> A/C Status:</b></font></label>

                                {this.state.inputData.acctStatus}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Dormant Activation Charge:</b></font></label>

                                {this.state.inputData.dormActvCharge}
                            </Box>


                        </Box>
                    </div>
                )
            } else if (this.props.labelName === "Freeze") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>

                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Account Number :</b></font></label>

                                {this.state.inputData.acctNumber}
                            </Box>

                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Account Name :</font></b> </label>

                                {this.state.inputData.acctName}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Code:</b></font></label>

                                {this.state.inputData.freezeCode}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Reason Code:</b></font></label>

                                {this.state.inputData.freezeReasonCode}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Remarks:</b></font></label>

                                {this.state.inputData.freezeRemarks}
                            </Box>


                        </Box>
                    </div>

                )
            } else if (this.props.labelName === "linkedCB") {

                let item = this.state.labelData.find((item) => item.popupValue === "linkedCB");
                console.log(item);
                let labelData = item.value;
                return (
                    <ThemeProvider theme={theme}>
                        {labelData.map((item) => {
                            if (item.relPartyType !== "M") {
                                return (
                                    <div style={{width: '100%'}}>
                                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                                             bgcolor="background.paper"
                                             style={{
                                                 marginTop: "20px",
                                                 borderTop: "2px solid #000",
                                                 borderBottom: "2px solid #000"
                                             }}>

                                            {this.props.create && <Box p={1} bgcolor="background.paper">
                                                <a onClick={() => {
                                                    this.detailsView(item.custId, this.props.accountNumber)
                                                }}>
                                                    <label style={{cursor: "pointer"}}><font size="2"><b> CB Number
                                                        :</b></font></label>
                                                    {item.custId}
                                                </a>
                                            </Box>}
                                            {!this.props.create && <Box p={1} bgcolor="background.paper">
                                                <label style={{cursor: "pointer"}}><font size="2"><b> CB Number
                                                    :</b></font></label>
                                                {item.custId}
                                            </Box>}
                                            <Box p={1} bgcolor="background.paper">
                                                <label><font size="2"><b>Customer Name:</b></font></label>

                                                {item.name}
                                            </Box>
                                            <Box p={1} bgcolor="background.paper">
                                                <label><font size="2"><b>Relation Code:</b></font></label>

                                                {item.relPartyCode}
                                            </Box>
                                            <Box p={1} bgcolor="background.paper">
                                                <label><font size="2"><b>Relationship Description:</b></font></label>

                                                {item.relPartyTypeDesc}
                                            </Box>
                                        </Box>
                                    </div>
                                )
                            }
                        })}
                    </ThemeProvider>
                )
            } else {
                return "";
            }
        }
    };

    detailsView = (cbNumber, acNumber) => {
        let url = "/LinkedAccountMaintenance/" + cbNumber + "/" + acNumber;
        window.open(frontEndServerURL + url)
    };

    loaderFun = () => {
        if (this.state.loader) {
            return (
                <div>
                    <CircularProgress style={{marginLeft: '50%'}}/>
                </div>
            )
        }
    };


    render() {
        if (this.state.loader && !this.state.getData) {
            return (
                <div>
                    <CircularProgress style={{marginLeft: '50%'}}/>
                </div>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Details Information - {this.props.name}<a><CloseIcon onClick={this.props.closeModal}
                                                                                         style={{
                                                                                             position: 'absolute',
                                                                                             right: 10,
                                                                                             color: "#000000"
                                                                                         }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <>
                                    {
                                        this.state.inputData.lienDetails !== undefined && this.state.inputData.lienDetails !== null && this.state.inputData.lienDetails.length > 0 && this.props.labelName === "Lien" && this.state.inputData.lienDetails.map((item) => {
                                            return (
                                                <div style={{width: '100%'}}>
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap"
                                                         bgcolor="background.paper"
                                                         style={{
                                                             marginTop: "20px",
                                                             borderTop: "2px solid #000",
                                                             borderBottom: "2px solid #000"
                                                         }}>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b> Account Number
                                                                : </b></font></label>

                                                            {item.acctNumber}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><b><font size="2">Account Name : </font></b>
                                                            </label>

                                                            {item.accountName}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Lien
                                                                Amount: </b></font></label>

                                                            {item.lienAmount}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Lien
                                                                Remarks: </b></font></label>

                                                            {item.lienRemarks}
                                                        </Box>


                                                    </Box>

                                                </div>)
                                        })
                                    }
                                    {
                                        this.state.inputData.linkedLoanDetails!==undefined && this.state.inputData.linkedLoanDetails!==null && this.state.inputData.linkedLoanDetails.length > 0 && this.props.labelName === "Loan" && this.state.inputData.linkedLoanDetails.map((item) => {
                                            return (
                                                <div style={{width: '100%'}}>
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap"
                                                         bgcolor="background.paper"
                                                         style={{
                                                             marginTop: "20px",
                                                             borderTop: "2px solid #000",
                                                             borderBottom: "2px solid #000"
                                                         }}>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b> Account Number
                                                                : </b></font></label>

                                                            {item.loanAccount}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><b><font size="2">Account Name : </font></b> </label>

                                                            {item.loanAccountName}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Loan Amount:</b></font></label>
                                                            {item.loanAmount}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Type of Loan:</b></font></label>
                                                            {item.loanType}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Close Status:</b></font></label>
                                                            {item.loanCloseFlag}
                                                        </Box>
                                                    </Box>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        this.state.inputData.accountInfoList !== undefined  && this.state.inputData.accountInfoList !== null && this.state.inputData.accountInfoList.tdAccountList.length > 0 && this.props.labelName === "FDR" && this.state.inputData.accountInfoList.tdAccountList.map((item) => {
                                            return (
                                                <div style={{width: '100%'}}>
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap"
                                                         bgcolor="background.paper"
                                                         style={{
                                                             marginTop: "20px",
                                                             borderTop: "2px solid #000",
                                                             borderBottom: "2px solid #000"
                                                         }}>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b> Account Number
                                                                : </b></font></label>
                                                            {item.acctId}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><b><font size="2">Account Name : </font></b> </label>
                                                            {item.acctShortName}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Amount:</b></font></label>
                                                            {item.acctCurrCode} {item.tdAmount}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Close Status:</b></font></label>
                                                            {item.acctStatus}
                                                        </Box>
                                                        {/*{!this.props.linkedCB && <Box p={1} bgcolor="background.paper">*/}
                                                        {/*    <label><font size="2"><b>Relationship:</b></font></label>*/}
                                                        {/*    {item.relations}*/}
                                                        {/*</Box>}*/}
                                                    </Box>

                                                </div>)
                                        })
                                    }
                                    {
                                        this.state.inputData.accountInfoList !== undefined && this.state.inputData.accountInfoList !== null && this.state.inputData.accountInfoList.casaAccountList.length > 0 && this.props.labelName === "relatedAccount" && this.state.inputData.accountInfoList.casaAccountList.map((item) => {
                                            return (
                                                <div style={{width: '100%'}}>
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap"
                                                         bgcolor="background.paper"
                                                         style={{
                                                             marginTop: "20px",
                                                             borderTop: "2px solid #000",
                                                             borderBottom: "2px solid #000"
                                                         }}>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b> Account Number
                                                                : </b></font></label>
                                                            {item.acctId}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><b><font size="2">Account Name : </font></b> </label>
                                                            {item.lastName}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Account Balance
                                                                :</b></font></label>
                                                            {item.acctCurrCode} {item.tdAmount}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Close Status:</b></font></label>
                                                            {item.acctStatus}
                                                        </Box>
                                                        {/*{!this.props.linkedCB && <Box p={1} bgcolor="background.paper">*/}
                                                        {/*    <label><font size="2"><b>Relationship:</b></font></label>*/}
                                                        {/*    {item.relations}*/}
                                                        {/*</Box>}*/}
                                                    </Box>

                                                </div>)
                                        })
                                    }
                                    {
                                        this.detailsInformationlabelWise()
                                    }
                                </>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }


}

export default LabelDetails;