import React, {useEffect, useState} from "react";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../../CASA/CBNotFound";
import Grid from "@material-ui/core/Grid";
import loader from "../../../../Static/loader.gif";
import Notification from "../../../NotificationMessage/Notification";
import theme from "../../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import CommonApi from "../../Maintenance/common/CommonApi";
import SignatureButton from "../../Maintenance/SignatureButton";
import {jsonForBom} from "./CustomDutyJsonForm"
import GridList from "@material-ui/core/GridList";
import Table from "../../../Table/Table";
import MyValidation from "../../../JsonForm/MyValidation";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import SdnForFundTransfer from "../SdnForFundTransfer";

let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];


const CheckerInboxView = (props) => {
    const [stateValue, setStateValue] = useState({
        showSearchField: true,
        inputData: {},
        varValue: {},
        showValue: false,
        showRequisitionField: false,
        loading: true,
        title: "",
        notificationMessage: "",
        cbNotFound: false,
        disabled: false,
        getData: false,
        SdnForFundTransfer: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        appId: 0
    });


    useEffect(() => {
        setStateValue((prevState => {
            return {
                ...prevState,
                getData: true
            }
        }))
    }, []);


    const updateComponent = () => {

    };


    const resetState = (title, bool, showValue, inputData, loading, showRequisitionField) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                inputData: inputData || stateValue.inputData,
                varValue: inputData || stateValue.inputData,
                showValue: showValue || stateValue.showValue,
                getData: true,
                showRequisitionField: showRequisitionField,
                loading: loading || false,
                disabled: false,
                title: title,
                cbNotFound: bool,
            }
        }));
    };

    const renderNotification = () => {
        if (stateValue.alert) {
            return (
                <Notification type="success" stopNotification={stopNotification} title={stateValue.title}
                              message={stateValue.notificationMessage}/>
            )
        }
    };


    const stopNotification = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                alert: false,
            }
        }));
    };

    useEffect(() => {
        CommonApi.getRemarkDetails(props.appId)
            .then((remarksArray) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        getRemarks: remarksArray
                    }
                }));
            })
            .catch((error) => {
                console.log(error)
            });


        CommonApi.getVariables(props.appId)
            .then((response) => {
                let inputData = {...response.data};
                if (inputData.source === "Ababil") {
                    CommonApi.ababilAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        setStateValue((prevState => {
                            return {
                                ...prevState,
                                inputData: inputData,
                                varValue: inputData,
                                showValue: true,
                                getData: true,
                                showRequisitionField: true,
                                loading: false
                            }
                        }));
                    }).catch((error) => {
                        errorModal(error)
                    });
                } else {
                    CommonApi.getCustAccountDetails(response.data.accountNumber).then((response) => {
                        inputData.accountBalance = response.data.acctEftvBalance;
                        setStateValue((prevState => {
                            return {
                                ...prevState,
                                inputData: inputData,
                                varValue: inputData,
                                showValue: true,
                                getData: true,
                                showRequisitionField: true,
                                loading: false
                            }
                        }));
                    }).catch((error) => {
                        errorModal(error)
                    });
                }

            })
            .catch((error) => {
                errorModal(error)
            })
    }, [props.appId]);

    const errorModal = (error) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                cbNotFound: true,
                title: error.response.data.message,
                loading: false,
            }
        }));
    };

    const signature = () => {
        if (stateValue.getData) {
            return <SignatureButton accountNumber={stateValue.inputData.accountNumber} signatureType="multiple"
                                    classes={props} requestType={stateValue.inputData.source}/>;
        }
    };

    const functionForCaseClose = () => {
        let url = backEndServerURL + "/case/close/" + props.appId;
        axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        title: "Successful!",
                        notificationMessage: "Successfully Closed!",
                        alert: true,
                        disabled: false,
                        loading: false,
                    }
                }));
                props.closeModal();
            })
            .catch((error) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        cbNotFound: true,
                        title: error.response.data.message,
                        disabled: false,
                        loading: false,
                    }
                }));
            });
    };

    const handleSubmit = () => {
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));

        let postData = {...stateValue.inputData};
        postData.sdnData = undefined;
        let url = backEndServerURL + "/customDutyTransaction";
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            functionForCaseClose();
        }).catch((error) => {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    cbNotFound: true,
                    title: error.response.data.message,
                    loading: false,
                }
            }));
        })
    };

    const renderRequisitionField = () => {

        if (stateValue.getData && stateValue.showRequisitionField) {
            return <>
                {CommonJsonFormComponent.renderJsonForm(stateValue, jsonForBom, updateComponent)}
                <Grid item xs={12}>{signature()}</Grid>
                <Grid item xs={12}>{handleSDNSearchButton()}</Grid>

                <Grid item xs={12}>
                    {renderRemarksData()}
                </Grid>
                <Grid item
                      xs={12}>{CommonJsonFormComponent.renderJsonForm(stateValue, bomRemarks, updateComponent)}
                </Grid>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={handleSubmit}
                    type="submit"
                >
                    Approve
                </button>
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginTop: '15px',
                    }}
                    onClick={returnHandler}
                    type="submit"
                >
                    Return
                </button>
            </>
        }
    };

    const renderRemarksData = () => {
        if (stateValue.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks</b>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={stateValue.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };


    const returnHandler = (e) => {
        e.preventDefault();
        setStateValue((prevState => {
            return {
                ...prevState,
                loading: true,
            }
        }));
        let dependencyField = [
            {varName: "bomRemarks", type: "textArea", required: true}
        ];
        let error = MyValidation.defaultValidation(dependencyField, stateValue);
        if (error === true) {
            setStateValue((prevState => {
                return {
                    ...prevState,
                    loading: false,
                }
            }));
            return 0;
        } else {
            let postData = {...stateValue.inputData};
            postData.bom_approval = "RETURN";
            let remarksData = {};
            remarksData.remark = postData.bomRemarks;
            remarksData.map = {...postData, bomRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                setStateValue((prevState => {
                    return {
                        ...prevState,
                        disabled: false,
                        title: "Successful!",
                        notificationMessage: "Successfully Routed!",
                        alert: true,
                        loading: false
                    }
                }));
                props.closeModal()
            }).catch((error) => {
                console.log(error);
                errorModal(error)
            });
        }
    };

    const handleChangeSdnResult = (bool) => {
        setStateValue((prevState => {
            return {
                ...prevState,
                SdnForFundTransfer: bool
            }
        }));
    };

    const handleSDNSearchButton = () => {
        return (<div>
                <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => handleChangeSdnResult(true)}
                >
                    SDN View
                </button>
            </div>
        )
    };


    return (
        <GridList cellHeight={window.innerHeight} cols={1}>
            <div>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={stateValue.cbNotFound}>
                    <DialogContent>
                        <CBNotFound
                            closeModal={() => resetState(undefined, false, true, stateValue.inputData, false, true)}
                            title={stateValue.title}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    open={stateValue.loading}>
                    <DialogContent>
                        <center><img src={loader} alt=""/></center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={stateValue.SdnForFundTransfer}>
                    <DialogContent>
                        <SdnForFundTransfer
                            closeModal={() => handleChangeSdnResult(false)}
                            subServiceType="Fund Transfer"
                            searchTableData={true}
                            dedupData={stateValue.inputData.sdnData}
                        />
                    </DialogContent>
                </Dialog>
                <Grid container spacing={0}>
                    <ThemeProvider theme={theme}>
                        {renderRequisitionField()}
                    </ThemeProvider>
                </Grid>
                <br/>
                {renderNotification()}
            </div>
        </GridList>
    )
};

export default CheckerInboxView;