import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
    CSJsonFormMaintenanceWithPrintFileAddress,
    CSJsonFormMaintenanceWithPrintFileContact,
    CSJsonFormMaintenanceWithPrintFileDormant,
    CSJsonFormMaintenanceWithPrintFileEmail,
    CSJsonFormMaintenanceWithPrintFileEtin,
    CSJsonFormMaintenanceWithPrintFileInputFiled,
    CSJsonFormMaintenanceWithPrintFileLink,
    CSJsonFormMaintenanceWithPrintFileMaturity,
    CSJsonFormMaintenanceWithPrintFileNominee,
    CSJsonFormMaintenanceWithPrintFileScheme,
    CSJsonFormMaintenanceWithPrintFileTenor,
    CSJsonFormMaintenanceWithPrintFileTitle
} from './WorkflowJsonForm';
import DataCaptureCS from "./DataCaptureCS";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "280",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#142398",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};


class MaintenanceWIthPrintFile extends Component {

    constructor(props) {
        super(props);
        this.state = {SelectedData: ""}

    }

    handleChangeSelected = (name, value) => {
        this.setState({
            SelectedData: value
        });
    };
    renderSelectMenu = () => {
        return (
            <FormControl style={{minWidth: 280}}>
                <InputLabel>Maintenance Type</InputLabel>

                <Select name="select" value={this.state.SelectedData}
                        onChange={event => this.handleChangeSelected(event.target.name, event.target.value)}>
                    <MenuItem key="address" value="address">Address Change</MenuItem>
                    <MenuItem key="contact" value="contact">Contact Number Change</MenuItem>,
                    <MenuItem key="email" value="email">Email Address Change</MenuItem>
                    <MenuItem key="nominee" value="nominee">Change Of Nominee</MenuItem>
                    <MenuItem key="tenor" value="tenor">FD Tenor Change</MenuItem>
                    <MenuItem key="scheme" value="scheme">Scheme Change</MenuItem>
                    <MenuItem key="maturity" value="maturity">Maturity/Disposal Instruction Change</MenuItem>
                    <MenuItem key="title" value="title">Account Title Change</MenuItem>
                    <MenuItem key="link" value="link">Link Account Change</MenuItem>
                    <MenuItem key="etin" value="etin">E-Tin Update</MenuItem>
                    <MenuItem key="dormant" value="dormant">Dormant Account Reactivation</MenuItem>
                    <MenuItem key="inputFiled" value="inputFiled">Account Maintenance With Input Field</MenuItem>
                </Select>
            </FormControl>

        )
    };
    renderJsonForm = () => {
        if (this.state.SelectedData === 'address') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Address" jsonForm={CSJsonFormMaintenanceWithPrintFileAddress}
                                   titleName="Address Change"/>);
        } else if (this.state.SelectedData === 'contact') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Contact" jsonForm={CSJsonFormMaintenanceWithPrintFileContact}
                                   titleName="Contact Number Change"/>);
        } else if (this.state.SelectedData === 'email') {
            return (
                <DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance" subserviceType="Email"
                               jsonForm={CSJsonFormMaintenanceWithPrintFileEmail} titleName="Email Change"/>);
        } else if (this.state.SelectedData === 'nominee') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Nominee" jsonForm={CSJsonFormMaintenanceWithPrintFileNominee}
                                   titleName="Nominee Change"/>);
        } else if (this.state.SelectedData === 'tenor') {
            return (
                <DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance" subserviceType="Tenor"
                               jsonForm={CSJsonFormMaintenanceWithPrintFileTenor} titleName="Tenor Change"/>);
        } else if (this.state.SelectedData === 'scheme') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Scheme" jsonForm={CSJsonFormMaintenanceWithPrintFileScheme}
                                   titleName="Scheme Change"/>);
        } else if (this.state.SelectedData === 'maturity') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Maturity" jsonForm={CSJsonFormMaintenanceWithPrintFileMaturity}
                                   titleName="Maturity Change"/>);
        } else if (this.state.SelectedData === 'title') {
            return (
                <DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance" subserviceType="Title"
                               jsonForm={CSJsonFormMaintenanceWithPrintFileTitle} titleName="Title Change"/>);
        } else if (this.state.SelectedData === 'link') {
            return (
                <DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance" subserviceType="Link"
                               jsonForm={CSJsonFormMaintenanceWithPrintFileLink} titleName="Link Change"/>);
        } else if (this.state.SelectedData === 'etin') {
            return (
                <DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance" subserviceType="Etin"
                               jsonForm={CSJsonFormMaintenanceWithPrintFileEtin} titleName="E-TIN Change"/>);
        } else if (this.state.SelectedData === 'dormant') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="Dormant" jsonForm={CSJsonFormMaintenanceWithPrintFileDormant}
                                   titleName="Dormant Change"/>);
        } else if (this.state.SelectedData === 'inputFiled') {
            return (<DataCaptureCS renderSelectMenu={this.renderSelectMenu} serviceType="Maintenance"
                                   subserviceType="InputField" jsonForm={CSJsonFormMaintenanceWithPrintFileInputFiled}
                                   titleName="Input Field Change"/>);
        } else {
            return '';
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <section>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Maintenance</h4>

                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderSelectMenu()}
                                    {this.renderJsonForm()}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        );

    }


}

export default withStyles(styles)(MaintenanceWIthPrintFile);
