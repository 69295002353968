import React, { Component } from "react";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../JsonForm/CustomeTheme2";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";
import CommonJsonFormComponent from "../JsonForm/CommonJsonFormComponent";
import Notification from "../NotificationMessage/Notification";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { backEndServerURL } from "../../Common/Constant";
import MyValidation from "../JsonForm/MyValidation";
import axios from "axios";
import Functions from "../../Common/Functions";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../Static/loader.gif";
import SelectComponent from "../JsonForm/SelectComponent";
import TextFieldComponent from "../JsonForm/TextFieldComponent";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import ReferenceModal from "../DeliverableManagement/ReferenceModal";
import AutoCompleteComponentValueReturn from "../JsonForm/AutoCompleteComponentValueReturn";
import ErrorModal from "../workflow/CommonComponent/ErrorModal";
let renderJsonForm = [];
let Remarks = [
  {
    varName: "remarks",
    type: "textArea",
    label: "Remarks",
    grid: 12,
  },
];
var stampType = [
  {
    varName: "stampType",
    type: "select",
    enum: [],
    required: true,
    label: "Stamp Type",
  },
];
var quantity = [
  {
    varName: "quantity",
    type: "text",
    label: "Quantity",
    required: true,
  },
];
let Form = [
  {
    "varName": "insurancType",
    "type": "select",
    "label": "Insurance Type",
    "enum":[

        "A – Sea Insurance",
        "B – Goods, Merchandise Personal Effects, Crops and Other Property Loss/Damage",
        "C – Accident & Sickness Insurance",
        "D – Against liability to pay compensation",

    ],
    "required": true,
    "grid": 2
},
  // {
  //   varName: "insurancType",
  //   type: "select",
  //   label: "Insuranc Type",
  //   enum: [
  //     "A – Sea Insurance",
  //     "B – Fire and Other",
  //     "C – Accident & Sickness",
  //     "D – Indemnity against liability",
  //   ],
  //   required: true,
  //   grid: 2,
  // },
  {
    varName: "code",
    type: "text",
    label: "Code",
    required: true,
    grid: 2,
  },
  {
    varName: "amountCalculation",
    type: "select",
    enum: ["Incremental"],
    label: "Amount Calculation",
    required: true,
    grid: 2,
  },
  {
    varName: "baseValueStart",
    type: "text",
    label: "Base Value Start",
    required: true,
    grid: 2,
  },
  {
    varName: "baseValueEnd",
    type: "text",
    label: "Base Value End",
    required: true,
    grid: 2,
  },
  {
    varName: "stampValue",
    type: "text",
    label: "Stamp Value",
    required: true,
    grid: 2,
  },
  {
    varName: "incrementalAmount",
    type: "text",
    label: "Incremental Amount",
    required: true,
    grid: 2,
  },
  {
    varName: "incrementalAmountValue",
    type: "text",
    label: "Incremental Amount Value",
    required: true,
    grid: 2,
  },
];
let workplace = [
  { title: "BRANCH" },
  { title: "OPERATIONS" },
  { title: "CSU" },
];
let groupList = [];
let workplaceTypeList = [];

class StampSaleRequest extends Component {
  state = {
    inputData: {},
    selectedDate: {},
    getData: false,
    workstation: false,
    role: false,
    showValue: false,
    SelectedDropdownSearchData: null,
    dropdownSearchData: {},
    values: [],
    err: false,
    varValue: [],
    errorArray: {},
    errorMessages: {},
    errorWorkPlace: false,
    errorgroup: false,
    title: "",
    notificationMessage: "",
    alert: false,
    getGroupListData: false,
    getworkplaceTypeListData: false,
    workplace: "",
    type: "success",
    submitButton: false,
    referenceTitle: "",
    reference: false,
  };
  emptyValueRemove = (data) => {
    if (data === "" || data === " " || data === "null" || data === "undefined")
      data = "";
    return data;
  };
  reload = () => {
    if (this.props.appId !== undefined) {
      this.setState({
        submitButton: false,
        appId: this.props.appID,
        getData: false,
        showValue: false,
        values:[],
      });
    } else {
      this.setState({
         values:[],
        submitButton: false,
        getData: false,
        showValue: false,
      });
      this.componentDidMount()
    }
    this.renderJsonForm();
    this.getMasterdataAll();

      this.renderJsonForm();     
      this.returnAddButton();    
      this.addForm();
      this.renderRemarks();    
      this.renderButton();
    // {this.dynamicForm(el)}
    // {this.dynamicForm1(el)}
     //this.handleSubmit()
    
    
       
     
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let validationData = [
      {
        varName: "insurancyType",
        type: "select",
        required: true,
        label: "Insurance Type",
        grid: 3,
      },
      // {
      //     "varName": "amountCalculationType",
      //     "type": "select",
      //     "enum":[
      //         "Incremental",
      //         "Slab Based",
      //     ],
      //     "label": "Amount Calculation",
      //     "required": true,
      //     "grid": 3
      // },
      {
        varName: "insuranceAmount",
        type: "text",
        required: true,
        validation: "numeric",
        label: "Insurance Amount",
        grid: 3,
      },
    ];
    for (let i = 0; i < this.state.values.length; i++) {
      let value = this.state.values[i];
      validationData.push(
        {
          varName: "stampType" + value,
          required: true,
          label: "Stamp Type",
        },
        {
          varName: "quantity" + value,
          type: "text",
          validation: "numeric",
          label: "Quantity",
          required: true,
        }
      );
    }
    let error = MyValidation.defaultValidation(
      this.emptyValueRemove(validationData),
      this.state
    );
    this.forceUpdate();

    if (
      error === true ||
      this.state.inputData.workplaceType === null ||
      this.state.inputData.groupName === null
    ) {
      console.log("Not working");
      this.setState({
        loading: false,
      });
      return 0;
    }
    error = MyValidation.defaultValidation(
      this.emptyValueRemove(Remarks),
      this.state
    );
    this.forceUpdate();

    if (error === true) {
      console.log("Not working");
      this.setState({
        loading: false,
      });
      return 0;
    }
    this.setState({
      loading: true,
      reloadFlag: true,
    });
    let total = 0;
    for (let i = 0; i < this.state.values.length; i++) {
      let value = this.state.values[i];
      let stampType = this.state.inputData["stampType" + value];
      let quantity = this.state.inputData["quantity" + value];

      if (stampType !== undefined && stampType.key !== undefined) {
        total += Number(stampType.key) * Number(quantity);
      }
    }
    let data = this.state.inputData;
    data.total = total;
    let urls = backEndServerURL + "/allowStampRequisition";
    axios
      .post(urls, data, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        if (response.data === false) {
          this.setState({
            loading: false,
            reference: true,
            reloadFlag: false,
            referenceTitle: response.data.message,
          });
        } else {
          let remarksData = this.state.inputData.remarks;
          let postData = this.state.inputData;
          postData.values = this.state.values;

          postData.freeFlag1 = "FDD";
          postData.category = "Stamp Issuance";
          postData.subCategory = "Requisition";
          postData.serviceType = "Debit Card";
          postData.subServiceType = "Chequebook";
          postData.customerName = this.state.inputData.customerName;
          postData.appId = this.state.appId;
          postData.remarks = undefined;

          let url = backEndServerURL + "/variables/" + this.state.appId;
          axios
            .post(url, postData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem(
                  "accessToken"
                )}`,
              },
            })
            .then((response) => {
              let caseRouteUrl =
                backEndServerURL + "/case/route/" + this.state.appId;
              axios
                .get(caseRouteUrl, {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                      "accessToken"
                    )}`,
                  },
                })
                .then((response) => {
                  let remark_url =
                    backEndServerURL +
                    "/appRemarkSave/" +
                    remarksData +
                    "/" +
                    this.state.appId;
                  axios
                    .post(
                      remark_url,
                      {},
                      {
                        headers: {
                          Authorization: `Bearer ${sessionStorage.getItem(
                            "accessToken"
                          )}`,
                        },
                      }
                    )
                    .then((response) => {
                      this.reload();
                      console.log("remark save", response.data.message);
                      this.setState({
                        type: "success",
                        title: "success!",
                        notificationMessage: "Forword to Branch Checker!",
                        alert: true,
                        loading: false,
                      });
                    })
                    .catch((error) => {
                      this.reload();
                      this.setState({
                        type: "error",
                        title: "error!!",
                        notificationMessage: error.response.data.message,
                        // notificationMessage: "Insurance Type Amount And Requisition amount Not Same or  Stock not Avavilable",
                        alert: true,
                        loading: false,
                      });
                      // console.log(error)
                    });
                  this.reload();
                  this.setState({
                    referenceTitle: "Reference Number : " + this.state.caseId,
                    // reference: true,
                    title: "Success",
                    notificationMessage: "Successfully Routed!",
                    // alert: true,
                    // getData: false,
                    // requestType: "",
                    // inputData: {},
                    //  varValue: {},
                    errorArray: {},
                    loading: false,
                    err: false,
                    errorMessages: {},
                  });
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({
                    type: "error",
                    title: "error!!",
                    notificationMessage: error.response.data.message,
                    // notificationMessage: "Insurance Type Amount And Requisition amount Not Same or  Stock not Avavilable",
                    alert: true,
                    loading: false,
                  });
                });
            })
            .catch((error) => {
              this.setState({
                type: "error",
                title: "error!!",
                notificationMessage: error.response.data.message,
                // notificationMessage: "Insurance Type Amount And Requisition amount Not Same or  Stock not Avavilable",
                alert: true,
                loading: false,
              });
              console.log("Cheque Book request error");
            });
          //  }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          type: "warning",
          title: "error!!",
          notificationMessage: error.response.data.message,
          // notificationMessage: "Insurance Type Amount And Requisition amount Not Same or  Stock not Avavilable",
          alert: true,
          loading: false,
        });
        // this.reload()
        // this.setState({
        //     loading: false,
        //     referenceTitle: "Insurance Type Amount And Requisition amount Not Same or  Stock not Avavilable",
        //     reference: true
        // })
      });
  };
  closeModal = () => {
    this.setState({
      loading: false,
      errorModalBoolean: false,
    });
  };
  renderButton = () => {
    // if(this.state.submitButton){
    return (
      <button
        className="btn btn-outline-primary  btn-sm"
        style={{
          marginTop: "18px",
        }}
        onClick={this.handleSubmit}
      >
        Submit
      </button>
    );
    // }
  };

  getMasterdataAll = () => {
    renderJsonForm = [];
    let url = backEndServerURL + "/get/insuranceValueCalculationMasterDataList";
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        let stampTypeList = [];
        response.data.map((data) => {
          stampTypeList.push(data.insurancType);
        });

        renderJsonForm = [
          {
            varName: "insurancyType",
            type: "select",
            required: true,
            enum: stampTypeList,
            label: "Insurance Type",
            grid: 4,
          },
          // {
          //     "varName": "amountCalculationType",
          //     "type": "select",
          //     "enum":[
          //         "Incremental",
          //         "Slab Based",
          //     ],
          //     "label": "Amount Calculation",
          //     "required": true,
          //     "grid": 3
          // },
          {
            varName: "insuranceAmount",
            type: "text",
            required: true,
            label: "Insurance Amount",
            validation: "numeric",
            grid: 4,
          },
        ];

        url = backEndServerURL + "/get/insuranceMasterDataList";
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
          })
          .then((response) => {
            let stampPrice = [];
            response.data.map((data) => {
              stampPrice.push({
                key: data.valueAmount,
                value: data.stampDescription,
              });
            });

            this.setState({
              stampPrice: stampPrice,
              getData: true,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    if (this.props.appId !== undefined) {
      this.setState({
        submitButton: false,
        appId: this.props.appId,
      });
    } else {
      this.setState({
        submitButton: false,
      });
    }

    let start_url = backEndServerURL + "/startCase/cheque_book_request_start";
    axios
      .get(start_url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      })
      .then((response) => {
        this.getMasterdataAll();
        this.setState({
          appId: response.data.id,
          caseId: response.data.caseId,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateComponent = () => {
    this.forceUpdate();
  };

  renderJsonForm = () => {
    if (this.state.getData) {
      return CommonJsonFormComponent.renderJsonForm(
        this.state,
        renderJsonForm,
        this.updateComponent
      );
    }
  };

  renderNotification = () => {
    if (this.state.alert) {
      return (
        <Notification
          type={this.state.type}
          stopNotification={this.stopNotification}
          title={this.state.title}
          message={this.state.notificationMessage}
        />
      );
    }
  };

  stopNotification = () => {
    this.setState({
      alert: false,
    });
  };
  renderHeader = () => {
    if (this.props.appId !== undefined) {
      return (
        <h4 style={{ color: "white" }}>
          New Request (Stamp Issuance)
          <a>
            <CloseIcon
              onClick={this.close}
              style={{
                position: "absolute",
                right: 10,
                color: "white",
              }}
            />
          </a>
        </h4>
      );
    } else {
      return <h4 style={{ color: "white" }}>New Request (Stamp Issuance)</h4>;
    }
  };
  returnAddButton = () => {
    return (
      <button
        className="btn btn-outline-primary btn-sm"
        style={{
          float: "left",
          verticalAlign: "left",
        }}
        type="button"
        value="add more"
        onClick={this.addClick.bind(this)}
      >
        Add Stamp Type and Quantity
      </button>
    );
  };

  addClick() {
    let randomNumber = Math.floor(Math.random() * 100000000000);
    this.setState((prevState) => ({
      setAllDeferalData: true,
      values: [...prevState.values, randomNumber],
    }));
  }
  removeClick(i, event) {
    event.preventDefault();
    let pos = this.state.values.indexOf(i);
    if (pos > -1) {
      this.state.values.splice(pos, 1);
      this.updateComponent();
    }
  }

  dynamicForm = (i) => {
    let field = JSON.parse(
      JSON.stringify({
        varName: "stampType",
        type: "autoCompleteValueReturn",
        enum: this.state.stampPrice,
        required: true,
        label: "Stamp Type",
      })
    );
    field.varName = "stampType" + i;
    field.enum = this.state.stampPrice;
    return AutoCompleteComponentValueReturn.select(
      this.state,
      this.updateComponent,
      field
    );
  };
  dynamicForm1 = (i) => {
    let field = JSON.parse(
      JSON.stringify({
        varName: "quantity",
        type: "text",
        label: "Quantity",
        required: true,
      })
    );
    field.varName = "quantity" + i;
    return TextFieldComponent.text(this.state, this.updateComponent, field);
  };
  addForm() {
    if (this.state.values !== undefined) {
      return this.state.values.map((el, i) => (
        <React.Fragment>
          <Grid item xs={3}>
            {this.dynamicForm(el)}
          </Grid>
          <Grid item xs={3}>
            {this.dynamicForm1(el)}
          </Grid>

          <Grid item xs={3}>
            <button
              style={{
                float: "left",
                marginTop: "16px",
              }}
              className="btn btn-outline-primary btn-sm"
              type="button"
              value="remove"
              onClick={this.removeClick.bind(this, el)}
            >
              Remove
            </button>
          </Grid>
          <Grid item xs={12}></Grid>
        </React.Fragment>
      ));
    }
  }
  closeModalCBNotFound = () => {
    this.setState({
      cbNotFound: false,
      signatureModal: false,
      reference: false,
    });
  };
  renderRemarks = () => {
    if (this.state.getData) {
      return CommonJsonFormComponent.renderJsonForm(
        this.state,
        Remarks,
        this.updateComponent
      );
    }
  };
  render() {
    return (
      <Card>
        <CardHeader
          style={{
            backgroundColor: "#142398",
            //color: '#ffffff'
          }}
        >
          {this.renderHeader()}
        </CardHeader>
        <CardBody>
          <Grid item xs={12}>
            <Dialog fullWidth="true" maxWidth="sm" open={this.state.loading}>
              <DialogContent>
                <center>
                  <img src={loader} alt="" />
                </center>
              </DialogContent>
            </Dialog>

            <ThemeProvider theme={theme}>
              <Grid container>
                <Dialog
                  fullWidth="true"
                  maxWidth="sm"
                  open={this.state.reference}
                >
                  <DialogContent>
                    <ReferenceModal
                      reload={this.state.reloadFlag}
                      closeModal={this.closeModalCBNotFound}
                      referenceTitle={this.state.referenceTitle}
                    />
                  </DialogContent>
                </Dialog>
                <Dialog fullWidth="true" open={this.state.errorModalBoolean}>
                  <DialogContent>
                    <ErrorModal
                      title={this.state.errorMessage}
                      closeModal={this.closeModal}
                      subServiceType={this.props.subServiceType}
                      appId={this.props.appId}
                    />
                  </DialogContent>
                </Dialog>
                {this.renderNotification()}
                {this.renderJsonForm()}

                <Grid item xs={12}>
                  <div className="my-2"> {this.returnAddButton()}</div>
                </Grid>
                {this.addForm()}
                <Grid item xs="8">
                  {this.renderRemarks()}
                </Grid>
                <Grid item xs={12}>
                  {this.renderButton()}
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>
        </CardBody>
      </Card>
    );
  }
}

export default StampSaleRequest;
