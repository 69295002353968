import Table from "../Table/Table.jsx";
import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import CountryAddEditDelete from './CountryAddEditDelete';
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [

        {
            "varName": "countryName",
            "type": "text",
            "label": "Country Name"
        },
        {
            "varName": "createdBy",
            "type": "text",
            "label": "Created By",
        },
        {
            "varName": "modifiedBy",
            "type": "text",
            "label": "Modified By",
        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class Country extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addCountry: false,
            editCountry: false,
            countryName: '',
            open: true,
            name: "Add Country",
            editName: "Edit Country",
            tableData: [[" ", " "]],
            countryId: '',
            countryData: [[" ", " "]],
            redirectLogin: false,
        }
    }

    addCountry() {
        this.setState({
            addCountry: true,
        });
    }

    editCountry = (id) => {

        this.setState({
            editCountry: true,
            countryId: id
        });
    };
    cancelCountryModal = () => {
        this.setState({addCountry: false});
    };
    cancelEditCountryModal = () => {
        this.setState({editCountry: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {
        this.setState({
            addCountry: false,
            editCountry: false,
        });
    };
    createTableData = (id, countryName, createdBy, modifiedBy, createdDate, modifiedDate, status) => {

        return ([countryName, createdBy, modifiedBy, createdDate, modifiedDate, status,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editCountry(id)}>
                Edit</button>
        ]);

    };
    getSubmitedForm = (object) => {
        console.log(object.data);
        let objectTable = {};
        let tableArray = [];
        for (let variable in object) {
            let trimData = object[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }

        this.state.tableData.map((country) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== country[variable])
                    showable = false;
            }
            if (showable)
                tableArray.push(this.createTableData(country.id, country.countryName, country.createdBy, country.modifiedBy, country.createdDate, country.modifiedDate, country.status));


        });
        this.setState({
            countryData: tableArray
        })
    };
    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="12" buttonName="Filtering" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    componentDidMount() {
        let url = backEndServerURL + "/countryMaster/getAll";

        const getAllCountry = [];

        axios.get(url, {withCredentials: true})
            .then((response) => {

                response.data.map((branch) => {

                    getAllCountry.push(branch);
                });
                this.setState({
                    tableData: getAllCountry
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }

    countryAddModal = () => {

        return (
            <CountryAddEditDelete onAdd={this.onAdd} close={this.closeModal} data={this.state.tableData}
                                  name="Add New Country"/>
        )
    };
    countryEditModal = () => {
        return (
            <CountryAddEditDelete onUpdate={this.onUpdate} close={this.closeModal} data={this.state.tableData}
                                  name="Edit Country" id={this.state.countryId}/>
        )
    };
    onAdd = (object) => {

        this.setState({
            addCountry: false

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editCountry: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addCountry}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.countryAddModal()}
                    </DialogContent>

                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editCountry}
                    onClose={this.closeModal}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>

                        {this.countryEditModal()}
                    </DialogContent>

                </Dialog>

                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Country List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderFilterForm()}
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addCountry: true
                                            })
                                        }
                                        }
                                    >

                                        Add New Country
                                    </button>

                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Country Name", "Created by", "Modified by", "Created Date", "Modified Date", "Status", "Action"]}
                                        tableData={this.state.countryData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(Country);
