//let backEndServerURL = "https://workflow.thecitybank.com/citybank";
//let frontEndServerURL = "https://workflow.thecitybank.com/cms/#";
let assetOpsBackEndServerURL = "https://asetl.thecitybank.com/cbl-awf";


 //let backEndServerURL = "http://cblx.babl.xyz/citybank";

// let backEndServerURL = "http://192.168.10.56:8096"; //Biplob

  let frontEndServerURL = "http://36.255.68.99/#/";
 //let backEndServerURL = "http://localhost:8096";

let startReturnSubmission=2017;

let backEndServerURL = "https://dispatch.green-delta.com/api-services";
  //let frontEndServerURL = "http://192.168.96.220:8082/cms/#";
// let assetOpsBackEndServerURL = "http://192.168.96.220:9081/cbl-awf";



let accessToken;

let browserHistory;
module.exports = {
    backEndServerURL,
    accessToken,
    browserHistory,
    frontEndServerURL,
    assetOpsBackEndServerURL,
    startReturnSubmission
};
