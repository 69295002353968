import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Customer360View from "./Customer360View";
import DemographicsView from "./DemographicsView";
import {Paper} from "@material-ui/core";
import axios from "axios";
import Box from '@material-ui/core/Box';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {backEndServerURL} from "../../Common/Constant";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={2}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        backgroundColor: theme.palette.background.paper,
    },
    tabRoot: {
        minWidth: '50%',
        fontSize: 20,
    },
});

class Customer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IDENTIFICATION_NO: 0,
            value: true,
            tabMenuSelect: 'Customer360View',
            redirectLogin: false,
            cbNumber: 0,
            customerName: '',
            identificationNumber: 0,
            getData: false,
            prioritize360: {},
            cardSummary: {},
            accountSummary: {},
            relatedCB: [[" ", " "]],
            getAllGroupMember: {},
            getAllProduct: [],
            groupServiceHoldings: [],
            getAllService: [],
            groupProductHoldings: [],
            getAllOccupationCode: [],
            getGroupDetails: [],
            dataSet: true,

            booleanPrioritize360: false,
            booleanCardSummary: false,
            booleanAccountSummary: false,
            booleanRelatedCB: false,
            booleanGetAllGroupMember: false,
            booleanGetAllProduct: false,
            booleanGetAllService: false,
            booleanGroupProductHoldings: false,
            booleanGetAllOccupationCode: false,
        }
    }

    createTableData = (id, mainCb, relatedCb, relatedCustomerName, relatedCustomerDesignation) => {

        return ([mainCb, relatedCb, relatedCustomerName, relatedCustomerDesignation]);

    };
    createTableDataGrouping = (id, customerId, customerName, type, rvValue, totlalRvvalue) => {

        return ([customerId, customerName, type, rvValue, totlalRvvalue]);

    };

    componentDidMount() {
        let getUrl = window.location.href;
        getUrl = getUrl.split("Customer/")[1];
        let identity = getUrl.split("/")[0];
        let dedupData = (LocalstorageEncrypt.encryptStorageFunction.getItem(identity));
        let identificationNumber = dedupData.identificationNumber;

        let cbNumber = dedupData.cbNumber;
        let customerName = dedupData.customerName;
        this.setState({
            cbNumber: cbNumber,
            customerName: customerName,
            identificationNumber: identificationNumber,
            IDENTIFICATION_NO: identificationNumber,
            dataSet: true
        });


        let prioritize360 = [];
        let cardSummary = [];
        let accountSummary = [];
        let relatedCB = [[" ", " "]];
        let getAllGroupMember = [];
        let getAllProduct = [];
        let getAllService = [];
        let groupProductHoldings = [];
        let getAllOccupationCode = [];
        let groupServiceHoldings = [];

        let url = backEndServerURL + "/view/360Prioritize/" + identificationNumber + "/" + cbNumber;
        // let url = backEndServerURL + "/view/360/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log("sdfghj");
                console.log(response.data);
                let varValue = [];
                let getoccupationCode = response.data.coreValue[0].OUTOCCUPATION;
                if (getoccupationCode !== undefined && getoccupationCode !== null) {
                    url = backEndServerURL + "/occupationCode/get/" + getoccupationCode;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            varValue["OUTOCCUPATION"] = response.data.refDesc;
                            this.setState({
                                booleanGetAllOccupationCode: true,
                            })
                        })
                        .catch((error) => {
                            this.setState({
                                booleanGetAllOccupationCode: true,
                            })
                        })
                }
                let threeSixty = response.data;

                ///360 view start

                if (threeSixty.coreValue[0] !== null) {


                    console.log(threeSixty.coreValue[0].HOMEBRANCH);

                    varValue["CUSTOMERNAME"] = threeSixty.coreValue[0].CUSTOMERNAME;
                    varValue["IDENTIFICATION_NO"] = threeSixty.coreValue[0].IDENTIFICATION_NO;
                    varValue["IDENTIFICATION_NO_MOD"] = threeSixty.coreValue[0].IDENTIFICATION_NO_MOD;
                    varValue["RVCB"] = threeSixty.rvcb;
                    varValue["rvDate"] = threeSixty.rvDate;
                    varValue["CUSTOMERBAND"] = threeSixty.coreValue[0].CUSTOMERBAND;
                    let threeSixtyBand = Number(threeSixty.coreValue[0].CUSTOMERBAND);
                    let segmentvalue = "";
                    if (threeSixtyBand > 0 && threeSixtyBand < 4) {
                        segmentvalue = "HNI";

                    } else if (threeSixtyBand > 3 && threeSixtyBand <= 6) {
                        segmentvalue = "Affluent";

                    } else if (threeSixtyBand === 7) {
                        segmentvalue = "Mass";

                    } else {
                        segmentvalue = "";

                    }
                    this.setState({
                        CustomerSegment: segmentvalue
                    });

                    varValue["HOMEBRANCH"] = threeSixty.coreValue[0].HOMEBRANCH;


                    varValue["CUSTOMERCONSTITUTION"] = threeSixty.coreValue[0].CUSTOMERCONSTITUTION;
                    varValue["CUSTSTAFF"] = threeSixty.coreValue[0].CUSTSTAFF;
                    let custStaffValue = threeSixty.coreValue[0].STAFF_FLG;
                    if (custStaffValue === "N") {
                        varValue["CUSTSTAFF"] = "NO"
                    } else if (custStaffValue === "Y") {
                        varValue["CUSTSTAFF"] = "YES"
                    } else {
                        varValue["CUSTSTAFF"] = ""
                    }

                    varValue["CAAMB"] = threeSixty.coreValue[0].CAAMB;
                    varValue["SBAMB"] = threeSixty.coreValue[0].SBAMB;
                    varValue["DPSBALANCE"] = threeSixty.coreValue[0].DPSBALANCE;
                    varValue["TWOWHEELEROUTSTANDING"] = threeSixty.coreValue[0].TWOWHEELEROUTSTANDING;
                    varValue["PERSONALLOAN"] = threeSixty.coreValue[0].PERSONALLOAN;
                    varValue["RELATIONSHIPVALUE"] = threeSixty.coreValue[0].RELATIONSHIPVALUE;
                    varValue["CAABL"] = threeSixty.coreValue[0].CAABL;
                    varValue["SBABL"] = threeSixty.coreValue[0].SBABL;
                    varValue["SNDBALANCE"] = threeSixty.coreValue[0].SNDBALANCE;
                    varValue["SMELOANOUTSTANDING"] = threeSixty.coreValue[0].SMELOANOUTSTANDING;
                    varValue["HOMELOANOUTSTANDING"] = threeSixty.coreValue[0].HOMELOANOUTSTANDING;
                    varValue["AUTOLOANOUTSTANDING"] = threeSixty.coreValue[0].AUTOLOANOUTSTANDING;
                    varValue["SECUREDLOAN"] = threeSixty.coreValue[0].SECUREDLOAN;

                    varValue["MONITORINGRM"] = threeSixty.coreValue[0].MONITORINGRM;
                    let customerSince = threeSixty.coreValue[0].CUST_OPN_DATE;
                    if (customerSince !== undefined && customerSince !== null) {
                        varValue["CUSTOPNDATE"] = customerSince.split('T')[0]
                    } else {
                        varValue["CUSTOPNDATE"] = customerSince;

                    }

                    varValue["FDBALANCE"] = threeSixty.coreValue[0].FDBALANCE;
                    varValue["NID"] = threeSixty.coreValue[0].NID;
                    varValue["TRADELICENSE"] = threeSixty.coreValue[0].TRADELICENSE;
                    varValue["FATHERNAME"] = threeSixty.coreValue[0].FATHERNAME;
                    varValue["SPOUSNAME"] = threeSixty.coreValue[0].SPOUSNAME;
                    //varValue["CUSTSTAFF"] = threeSixty.coreValue[0].STAFF_FLG;
                    let dateOfBirth = threeSixty.coreValue[0].DT_BIRTH;

                    if (dateOfBirth !== undefined && dateOfBirth !== null) {
                        let splitDate = dateOfBirth.split('T')[0];
                        varValue["DATEOFBIRTH"] = splitDate;
                    } else {
                        varValue["DATEOFBIRTH"] = dateOfBirth;
                    }
                    varValue["GENDER"] = threeSixty.coreValue[0].GENDER;

                    if (threeSixty.coreValue[0].GENDER === "M") {
                        varValue["GENDER"] = "Male"
                    } else if (threeSixty.coreValue[0].GENDER === "F") {
                        varValue["GENDER"] = "Female"
                    } else {
                        varValue["GENDER"] = ""
                    }

                    varValue["PASSPORT"] = threeSixty.coreValue[0].PASSPORT;
                    varValue["TRADELICENSE"] = threeSixty.coreValue[0].TRADELICENSE;
                    varValue["MOTHERNAME"] = threeSixty.coreValue[0].MOTHERNAME;
                    varValue["INTRODUCERCB"] = threeSixty.coreValue[0].INTRODUCERCB;
                    varValue["INTRODUCERNAME"] = threeSixty.introducerName;
                    varValue["ADDRESS1"] = threeSixty.coreValue[0].ADDRESS1;
                    varValue["CITY"] = threeSixty.coreValue[0].CITY;
                    varValue["COUNTRY"] = threeSixty.coreValue[0].COUNTRY;
                    varValue["MOBNO"] = threeSixty.coreValue[0].MOBNO;
                    varValue["OFFPHONENUM"] = threeSixty.coreValue[0].OFFPHONENUM;
                    varValue["RESPHONENUM"] = threeSixty.coreValue[0].RESPHONENUM;
                    varValue["EMAILADDRESS"] = threeSixty.coreValue[0].EMAILADDRESS;
                    varValue["ADDRESS2"] = threeSixty.coreValue[0].ADDRESS2;
                    varValue["STATE"] = threeSixty.coreValue[0].STATE;
                    varValue["CUSTOMERCONTITUTION"] = threeSixty.coreValue[0].CUSTOMERCONTITUTION;
                    //varValue["CUSTOMERBAND"] = threeSixty.coreValue[0].CUSTOMERBAND;
                    varValue["PERMANENT_ADD1"] = threeSixty.coreValue[0].CUST_PERM_ADDR1;
                    varValue["PERMANENT_ADD2"] = threeSixty.coreValue[0].CUST_PERM_ADDR2;
                    varValue["PERMANENT_CITY"] = threeSixty.coreValue[0].CUST_PERM_CITY_CODE;
                    varValue["PERMANENT_STATE"] = threeSixty.coreValue[0].CUST_PERM_STATE_CODE;
                    varValue["CITYGEM_FLAG"] = threeSixty.citygem;

                    let band = Number(threeSixty.coreValue[0].CUSTOMERBAND);
                    if (band > 0 && band < 4) {
                        segmentvalue = "HNI";

                    } else if (band > 3 && band <= 6) {
                        segmentvalue = "Affluent";

                    } else if (band === 7) {
                        segmentvalue = "Mass";

                    } else {
                        segmentvalue = "";

                    }
                    varValue["CUSTOMERSEGMENT"] = segmentvalue;
                    varValue["CUSTOMERTYPE"] = threeSixty.coreValue[0].CUSTOMERTYPE;

                    /* varValue["OUTOCCUPATION"] = threeSixty.coreValue[0].OUTOCCUPATION;*/
                    varValue["SECTOR"] = threeSixty.coreValue[0].SECTOR;
                    varValue["ACQUIRINGRM"] = threeSixty.coreValue[0].ACQUIRINGRM;
                    varValue["SUBSECTOR"] = threeSixty.coreValue[0].SUBSECTOR;
                    if (threeSixty.coreValue[0].E_STATEMENT === "1") {
                        this.state.groupServiceHoldings.E_STATEMENT = "E-Statement"
                    }
                    if (threeSixty.coreValue[0].CITYTOUCH === "1") {
                        this.state.groupServiceHoldings.CITYTOUCH = "City Touch"
                    }

                    this.setState({
                        prioritize360: varValue,
                        booleanPrioritize360: true
                    })
                }


                //demographic end


            })
            .catch((error) => {
                this.setState({
                    booleanPrioritize360: true
                })
            });


        url = backEndServerURL + "/view/360/cardSummary/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                let varValue = [];
                let data = response.data;
                varValue["DEBITCARD"] = data.debitCard;
                varValue["DEBITCARDTYPE"] = data.debitCardType;
                varValue["CREDITCARD"] = data.creditCard;
                varValue["CREDITCARDTYPE"] = data.creditCardVariant;
                let creditCardType = data.creditCardTypeList;
                varValue["CREDITCARDPRODUCT"] = data.creditCardTypeList;
                varValue["creditCardLimitBDT"] = data.creditCardLimitBDT;
                varValue["creditCardLimitUSD"] = data.creditCardLimitUSD;
                if (data.debitCard === "YES") {
                    this.state.groupServiceHoldings.debitCard = "Debit Card";
                }
                if (data.creditCard === "YES") {
                    this.state.groupServiceHoldings.creditCard = "Credit Card";
                }
                this.setState({
                    cardSummary: varValue,
                    booleanCardSummary: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanCardSummary: true,
                })
            });
        url = backEndServerURL + "/demographic/accountSummary/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                //demographic Account summary start
                let accountSummary = response.data;
                let rmCode = accountSummary.getAcquringRmCode;
                if (rmCode !== undefined && rmCode !== null) {
                    rmCode = rmCode + "-" + accountSummary.getAcquringRmName
                }

                let lastTransactionDates = accountSummary.lastTransactionDate;
                if (lastTransactionDates !== undefined && lastTransactionDates !== null) {
                    lastTransactionDates = lastTransactionDates.split("T")[0];
                    lastTransactionDates = lastTransactionDates
                }
                let accountSummaryArray = [];
                accountSummaryArray.ACQUIRINGRM = rmCode;
                accountSummaryArray.lastTransactionDates = lastTransactionDates;

                //demographic Account summary end
                this.setState({
                    accountSummary: accountSummaryArray,
                    booleanAccountSummary: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanAccountSummary: true,
                })
            });
        url = backEndServerURL + "/demographic/relatedCB/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                console.log("Related Data");
                console.log(response.data);
                response.data.map((demo, i) => {


                    relatedCB.push(this.createTableData(i, demo.mainCb, demo.relatedCb, demo.relatedCustomerName, demo.relatedCustomerDesignation));
                });
                this.setState({
                    relatedCB: relatedCB,
                    booleanRelatedCB: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanRelatedCB: true,
                })
            });
        url = backEndServerURL + "/getAllGroupMember/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);

                let groupUid = '';
                let groupDetails = [];

                response.data.map((group) => {
                    groupUid = group.groupUid;
                    groupDetails.push(group.customerId + ", ");
                    getAllGroupMember.push(this.createTableDataGrouping(group.id, group.customerId, group.customerName, group.role, group.rvValue, ""));
                });
                let getGroupDetails = [];
                getGroupDetails.groupId = groupUid;
                getGroupDetails.groupDetails = groupDetails;
                this.setState({
                    getAllGroupMember: getAllGroupMember,
                    getGroupDetails: getGroupDetails,
                    booleanGetAllGroupMember: true,
                })
            })
            .catch((error) => {

                this.setState({
                    getAllGroupMember: [],
                    getGroupDetails: [],
                    booleanGetAllGroupMember: true,
                })
            });
        url = backEndServerURL + "/servicePropensity/getAll/PRODUCT/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map(p => {
                    getAllProduct.push([p]);
                });

                this.setState({
                    getAllProduct: getAllProduct,
                    booleanGetAllProduct: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanGetAllProduct: true,
                })
            });
        url = backEndServerURL + "/servicePropensity/getAll/SERVICE/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map(q => {
                    getAllService.push([q]);
                });
                this.setState({
                    getAllService: getAllService,
                    booleanGetAllService: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanGetAllService: true,
                })
            });
        url = backEndServerURL + "/view/360/groupProductHoldings/" + identificationNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map(g => {
                    groupProductHoldings.push([g]);
                });

                this.setState({
                    groupProductHoldings: groupProductHoldings,
                    booleanGroupProductHoldings: true,
                })
            })
            .catch((error) => {
                this.setState({
                    booleanGroupProductHoldings: true,
                })
            });
        this.setState({
            getData: true
        })


    }


    /*this.state.booleanPrioritize360 &&  this.state.booleanCardSummary &&  this.state.booleanAccountSummary && this.state.booleanRelatedCB && this.state.booleanGetAllGroupMember
&& this.state.booleanGetAllProduct && this.state.booleanGetAllService && this.state.booleanGroupProductHoldings && this.state.booleanGetAllOccupationCode */

    renderSearchForm = () => {

        if (this.state.booleanPrioritize360 && this.state.booleanCardSummary && this.state.booleanAccountSummary && this.state.booleanRelatedCB && this.state.booleanGetAllGroupMember && this.state.booleanGetAllProduct && this.state.booleanGetAllService && this.state.booleanGroupProductHoldings && this.state.booleanGetAllOccupationCode) {
        }
        let data = (this.state.booleanPrioritize360 && this.state.booleanCardSummary && this.state.booleanAccountSummary && this.state.booleanRelatedCB && this.state.booleanGetAllGroupMember && this.state.booleanGetAllProduct && this.state.booleanGetAllService && this.state.booleanGroupProductHoldings && this.state.booleanGetAllOccupationCode);
        if (this.state.tabMenuSelect === 'Customer360View' && this.state.IDENTIFICATION_NO !== 0 && this.state.IDENTIFICATION_NO !== null) {
            return (
                <Customer360View customerName={this.state.customerName} cbNumber={this.state.cbNumber}
                                 IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}
                                 prioritize360={this.state.prioritize360} cardSummary={this.state.cardSummary}
                                 accountSummary={this.state.accountSummary}
                                 relatedCB={this.state.relatedCB} getAllGroupMember={this.state.getAllGroupMember}
                                 getAllProduct={this.state.getAllProduct}
                                 getAllService={this.state.getAllService}
                                 getGroupDetails={this.state.getGroupDetails}
                                 groupServiceHoldings={this.state.groupServiceHoldings}
                                 groupProductHoldings={this.state.groupProductHoldings}
                                 getAllOccupationCode={this.state.getAllOccupationCode}
                                 booleanPrioritize360={this.state.booleanPrioritize360}
                                 booleanCardSummary={this.state.booleanCardSummary}
                                 booleanAccountSummary={this.state.booleanAccountSummary}
                                 booleanRelatedCB={this.state.booleanRelatedCB}
                                 booleanGetAllGroupMember={this.state.booleanGetAllGroupMember}
                                 booleanGetAllProduct={this.state.booleanGetAllProduct}
                                 booleanGetAllService={this.state.booleanGetAllService}
                                 booleanGroupProductHoldings={this.state.booleanGroupProductHoldings}
                                 booleanGetAllOccupationCode={this.state.booleanGetAllOccupationCode}
                />
            )
        } else if (this.state.tabMenuSelect === 'DemographicsView' && this.state.IDENTIFICATION_NO !== 0 && this.state.IDENTIFICATION_NO !== null) {
            return (
                <DemographicsView customerName={this.state.customerName} cbNumber={this.state.cbNumber}
                                  prioritize360={this.state.prioritize360} cardSummary={this.state.cardSummary}
                                  accountSummary={this.state.accountSummary}
                                  relatedCB={this.state.relatedCB} getAllGroupMember={this.state.getAllGroupMember}
                                  getAllProduct={this.state.getAllProduct}
                                  getAllService={this.state.getAllService}
                                  getGroupDetails={this.state.getGroupDetails}
                                  groupProductHoldings={this.state.groupProductHoldings}
                                  getAllOccupationCode={this.state.getAllOccupationCode}
                                  groupServiceHoldings={this.state.groupServiceHoldings}
                                  IDENTIFICATION_NO={this.state.IDENTIFICATION_NO}
                                  booleanPrioritize360={this.state.booleanPrioritize360}
                                  booleanCardSummary={this.state.booleanCardSummary}
                                  booleanAccountSummary={this.state.booleanAccountSummary}
                                  booleanRelatedCB={this.state.booleanRelatedCB}
                                  booleanGetAllGroupMember={this.state.booleanGetAllGroupMember}
                                  booleanGetAllProduct={this.state.booleanGetAllProduct}
                                  booleanGetAllService={this.state.booleanGetAllService}
                                  booleanGroupProductHoldings={this.state.booleanGroupProductHoldings}
                                  booleanGetAllOccupationCode={this.state.booleanGetAllOccupationCode}
                />
            )

        } else {

        }

    };
    handleChange = (name, value) => {
        this.setState({
            tabMenuSelect: value
        })

    };

    /* handleChangeIndex = index => {
         this.setState({value: index});
     };*/
    close = () => {
        this.props.closeModal()
    };

    render() {

        const {classes} = this.props;

        return (
            <Card>
                <paper>

                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                        <h4 style={{color: "white"}} >Customer Info <b>({this.state.cbNumber}) {this.state.customerName} </b></h4>

                    </CardHeader>
                </paper>
                <CardHeader color="red">
                    <center>
                        <h4 color="red" className={classes.cardTitleWhite}>
                            <Paper square>
                                <Tabs indicatorColor="secondary"
                                      textColor="secondary"
                                      aria-label="icon label tabs example"
                                      value={this.state.tabMenuSelect}
                                      onChange={this.handleChange}>
                                    <Tab key="Customer360View" value="Customer360View"
                                         label="Customer 360 View" classes={{root: classes.tabRoot}}/>
                                    <Tab key="DemographicsView" value="DemographicsView"
                                         label="Demographics View" classes={{root: classes.tabRoot}}/>

                                </Tabs>
                            </Paper>

                        </h4>
                    </center>
                </CardHeader>

                <Paper square>
                    <CardBody>
                        {this.renderSearchForm()}
                    </CardBody>
                </Paper>
            </Card>


        )

    }
}

export default withStyles(styles, {withTheme: true})(Customer);