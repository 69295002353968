import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Grow from "@material-ui/core/Grow";
import Functions from '../../../Common/Functions';
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../NotificationMessage/Notification";
import loader from "../../../Static/loader.gif";
import PreviewMappingImage from "../Maintenance/casa/PreviewMappingImage";
import DedupResultFunction from "../../DedupResultFunction";
import {taggingForm} from "../WorkflowJsonFormRashed"
import MyValidation from "../../JsonForm/MyValidation";
import GridList from "@material-ui/core/GridList";

const arrayListIndividual = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},
    {label: 'PASSPORT'},
    {label: 'DRIVING LICENSE'},
    {label: 'BIRTH CERTIFICATE'},
    {label: 'NOMINEE NID 1'},
    {label: 'NOMINEE NID 2'},
    {label: 'NOMINEE NID 3'},
    {label: 'NOMINEE NID 4'},
    {label: 'NOMINEE NID 5'},
    {label: 'NOMINEE NID 6'},
    {label: 'NOMINEE PASSPORT 1'},
    {label: 'NOMINEE PASSPORT 2'},
    {label: 'NOMINEE PASSPORT 3'},
    {label: 'NOMINEE PASSPORT 4'},
    {label: 'NOMINEE PASSPORT 5'},
    {label: 'NOMINEE DRIVING LICENSE 1'},
    {label: 'NOMINEE DRIVING LICENSE 2'},
    {label: 'NOMINEE DRIVING LICENSE 3'},
    {label: 'NOMINEE DRIVING LICENSE 4'},
    {label: 'NOMINEE DRIVING LICENSE 5'},
    {label: 'NOMINEE BIRTH CERTIFICATE 1'},
    {label: 'NOMINEE BIRTH CERTIFICATE 2'},
    {label: 'NOMINEE BIRTH CERTIFICATE 3'},
    {label: 'NOMINEE BIRTH CERTIFICATE 4'},
    {label: 'NOMINEE BIRTH CERTIFICATE 5'},
    {label: 'OTHERS'},

];

let csRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12,
        "required": true,
    }];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

function Transition(props) {
    return <Grow in={true} timeout="auto" {...props} />;
}


class BOMLocker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            getData: false,
            getNewCase: false,
            varValue: [],
            loading: true,
            caseId: "",
            tagCbDetailsModal: false,
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            inputData: {
                csDeferal: "NO",
                accountType: "INSTAPACK",
                priority: "GENERAL"
            },
            fileUploadData: {},
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            getTutionFeeList: [],
            getLivingExpenceList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            IndividualDedupModal: false,
            JointDedupModal: false,
            individualDataSaveId: '',
            jointDataSaveId: '',
            companyDataSaveId: '',
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: []


        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    submitApprove = (event) => {
        event.preventDefault();
        let bom_approval = 'APPROVED';
        this.handleSubmit(bom_approval);
    };
    submitReturn = (event) => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let bom_approval = 'RETURN';
            this.handleSubmit(bom_approval);
        }
    };
    renderSubmitButton = () => {
        if (this.state.getData) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitApprove}
                    > Approve
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.submitReturn}
                    > Return
                    </button>
                </div>

            )
        }
    };

    componentDidMount() {
        this.setState({
            loaderNeeded: false
        });
        var remarksArray = [];
        this.state.inputData["csDeferal"] = "NO";
        let varValue = [];
        if (this.props.appId !== undefined) {

            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data);

                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    console.log(response.data);
                    response.data.map((data) => {
                        if (data.remarks !== 'undefined') {
                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        }
                    });
                    this.setState({
                        getRemarks: remarksArray
                    })
                })
                    .catch((error) => {
                        console.log(error)
                    });
                console.log(response.data);
                let varValue = response.data;
                this.setState({
                    getData: true,
                    varValue: this.removeNullValue(varValue),
                    inputData: this.removeNullValue(response.data),
                    showValue: true,
                    appId: this.props.appId,
                    loaderNeeded: true,
                    loading: false
                });
            })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 652) {
                        Functions.removeCookie();
                        this.setState({
                            redirectLogin: true
                        })
                    }
                });
        }
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div style={{"border-style": "groove", "border-width": "1px"}}>
                    <b>Remarks Data</b>
                    <Table
                        tableHovor="yes"
                        tableHeaderColor="primary"
                        tableHead={["Remarks", "Raised By", "Date", "Role"]}
                        tableData={this.state.getRemarks}
                        tableAllign={['left', 'left', 'left', 'left']}
                    />
                    <br/>
                </div>
            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeComments = () => {

    };
    returnJsonForm = () => {
        if (this.state.showValue) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent)
            )
        }
    };

    handleSubmit = (bom_approval) => {

        console.log(this.state.inputData["accountNumber"]);

        this.state.inputData.bom_approval = bom_approval;
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;

        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {

            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.bomRemarks + "/" + this.props.appId;
            axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                console.log(response.data)
            })
                .catch((error) => {
                    console.log(error)
                });
            this.state.inputData.bomRemarks = undefined;
            let sendData = {};
            sendData.accountNumber = this.state.inputData["accountNumber"];
            sendData.customerName = this.state.inputData["applicantName"];
            sendData.lockerNumber = this.state.inputData["availableLockerNo"];


            if (bom_approval === "APPROVED") {
                var lockerUrl = backEndServerURL + "/rentLocker";
                axios.post(lockerUrl, sendData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    if (response.status === 200) {
                        if (response.status === 200) {
                            this.caseRoute()
                        }
                    }
                })
                    .catch((error) => {
                        console.log(error)
                    });
            } else {
                var lockerStatusUrl = backEndServerURL + "/changeLockerStatus/" + this.state.inputData.availableLockerNo + "/Unrented";
                axios.get(lockerStatusUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                    if (response.status === 200) {
                        this.caseRoute()
                    }
                })
                    .catch((error) => {
                        console.log(error)
                    });
            }

        })
            .catch((error) => {
                console.log(error)
            });
    };

    caseRoute = () => {
        var url = backEndServerURL + "/case/route/" + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            if (response.status === 200) {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                this.props.closeModal();
            }
        })
            .catch((error) => {
                console.log(error)
            });
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }

    };
    close = () => {
        this.props.closeModal();
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };

    renderUploadButton = () => {
        if (!this.state.deferalNeeded) {
            return (
                <button
                    style={{
                        width: 150,
                    }}
                    className="btn btn-outline-primary"
                    onClick={this.uploadModal}
                >
                    Upload File
                </button>
            )
        }
    };

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            IndividualDedupModal: false,
            JointDedupModal: false,

        })
    };
    closeUploadModal = (data) => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    accountDetailsModal = () => {
        this.setState({
            accountDetailsModal: false
        });
        this.close()
    };

    IndividualDedupModal = () => {
        this.setState({
            IndividualDedupModal: true
        })
    };
    JointDedupModal = () => {
        this.setState({
            JointDedupModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    renderNomineeForm = () => {
        if (this.state.getData) {
            var sl;
            let objectForNominee = [];
            for (var i = 0; i < this.state.inputData.numberOfNominee; i++) {
                sl = i + 1;
                objectForNominee.push(
                    {
                        "varName": "Nominee " + i,
                        "type": "title",
                        "label": "Nominee  " + sl,
                        "grid": 12,

                    },
                    {
                        "varName": "nomineeName" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Nominee Name",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "relationship" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Relationship",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "dob1" + sl,
                        "type": "date",
                        "readOnly": true,
                        "label": "D.O.B",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "address11" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Address 1 ",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "address22" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Address 2",
                        "grid": 6,
                    },

                    {
                        "varName": "cityCode1" + sl,
                        "type": "select",
                        "readOnly": true,
                        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
                        "label": "City Code",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "stateCode1" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "State Code",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "postalCode1" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Postal Code",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "country1" + sl,
                        "type": "select",
                        "readOnly": true,
                        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
                        "label": "Country",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "regNo" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Reg No",
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "nomineeMinor" + sl,
                        "type": "select",
                        "readOnly": true,
                        "label": "Nominee Minor",
                        "enum": ["Yes", "No"],
                        "grid": 6,
                        required: true,
                    },

                    {
                        "varName": "guardiansName" + sl,
                        "type": "text",
                        "readOnly": true,
                        "label": "Guardians Name",
                        "grid": 6,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",

                    },

                    {
                        "varName": "guardianCode" + sl,
                        "type": "text",
                        "label": "Guardian Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "address4" + sl,
                        "type": "text",
                        "label": "Address",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "cityCode2" + sl,
                        "type": "select",
                        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
                        "label": "City Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "stateCode2" + sl,
                        "type": "text",
                        "label": "State Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "postalCode2" + sl,
                        "type": "text",
                        "label": "Postal Code",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "country2" + sl,
                        "type": "select",
                        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "nomineeMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "applicantMinor" + sl,
                        "type": "select",
                        "label": "Applicant Minor",
                        "grid": 6,
                        "readOnly": true,
                        "enum": ["Yes", "No"],
                    },
                    {
                        "varName": "gurdian" + sl,
                        "type": "text",
                        "label": "Gurdian",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "gurdianName" + sl,
                        "type": "text",
                        "label": "Gurdian Name",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "address23" + sl,
                        "type": "text",
                        "label": "Address",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "city1" + sl,
                        "type": "select",
                        "enum": ["Dhaka", "Chittagong", "Barishal", "Rajshahi", "Khulna"],
                        "label": "City",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "state1" + sl,
                        "type": "text",
                        "label": "State",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "postal" + sl,
                        "type": "text",
                        "label": "Postal",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },

                    {
                        "varName": "country3" + sl,
                        "type": "select",
                        "enum": ["Bangaladesh", "India", "Srilanka", "USA", "Canada"],
                        "label": "Country",
                        "grid": 6,
                        "readOnly": true,
                        "conditional": true,
                        "conditionalVarName": "applicantMinor" + sl,
                        "conditionalVarValue": "Yes",
                        required: true,
                    },
                    {
                        "varName": "percentageNominee" + sl,
                        "type": "text",
                        "label": "Percentage %",
                        "grid": 6,
                        "readOnly": true,
                        required: true,
                    }
                )

            }


            return (
                CommonJsonFormComponent.renderJsonForm(this.state, objectForNominee, this.updateComponent)
            )

        }
    };

    getTaggingData = (index, data, customerNo) => {

        let tableArrayData = [];
        let cbTagUrl = backEndServerURL + "/secondaryCB/" + this.props.appId;
        axios.get(cbTagUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                response.data.map((data, index) => {
                    tableArrayData.push(this.createTaggingData(data));
                });

                this.setState({
                    getTagList: tableArrayData,

                });

                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error);
            })

    };

    createTaggingData = (data) => {
        return (
            ["Customer " + data.customer, data.cbNumber, [<button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={(event) => this.tagCbDetailsModal(event, data.customer)}
            >View
            </button>]]


        )
    };

    tagCbDetailsModalClose = () => {
        this.setState({
            tagCbDetailsModal: false

        })
    };

    tagCbDetailsModal = (event, cb) => {
        console.log(cb);
        event.preventDefault();
        this.setState({
            tagCbDetailsModal: true,
            tagCb: cb
        })

    };

    renderDedupComponent = () => {
        console.log(this.props.appId);
        if (this.props.subServiceType !== "INDIVIDUAL" && this.state.getData && !this.state.loading) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData} taggingForm={taggingForm}
                                         hideButton={true} subServiceType={this.props.subServiceType}
                                         appId={this.props.appId}/>
                </div>

            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (!this.state.loading) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderNotification()}
                            {this.returnJsonForm()}
                        </Grid>
                        {this.renderDedupComponent()}
                        <Grid item xs='12'>
                            {this.renderRemarksData()}
                        </Grid>
                    </ThemeProvider>
                    <br/>
                    {
                        this.viewAttachedImages()
                    }
                    <ThemeProvider theme={theme}>
                        <Grid>
                            {this.renderRemarks()}
                            <Grid item xs='12'>
                            </Grid>
                            <center>
                                <div>
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'right',
                                        }}
                                        type='button' value='add more'
                                        onClick={this.submitApprove}
                                    > Approve
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            verticalAlign: 'right',
                                        }}
                                        type='button' value='add more'
                                        onClick={this.submitReturn}
                                    > Return
                                    </button>
                                </div>
                            </center>
                        </Grid>
                    </ThemeProvider>
                </div>
            </GridList>

        );

    }

}

export default withStyles(styles)(BOMLocker);
