import React, {Component} from 'react';
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box';


class LabelDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            labelData: [],
            inputData: {},
            showValue: true,
            getDeferalList: [],
            defferalData: false,
            getData: false,
            images: [],
            title: "",
            notificationMessage: "",
            alert: false,
            err: false,
            loader: true,
            errorArray: {},
            errorMessages: {},
        }
    }


    componentDidMount() {
        this.setState({
            inputData: this.props.inputValue,
            labelData: this.props.data,
            getData: true,
            loader: false,
        })
    }

    detailsInformationlabelWise = () => {
        if (this.state.getData) {
            if (this.props.labelName === "Freeze") {
                return (
                    <div style={{width: '100%'}}>
                        <Box display="flex" flexDirection="row" flexWrap="wrap"
                             bgcolor="background.paper"
                             style={{
                                 marginTop: "20px",
                                 borderTop: "2px solid #000",
                                 borderBottom: "2px solid #000"
                             }}>

                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b> Account Number :</b></font></label>

                                {this.state.inputData.acctNumber}
                            </Box>

                            <Box p={1} bgcolor="background.paper">
                                <label><b><font size="2">Account Name :</font></b> </label>

                                {this.state.inputData.acctName}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Code:</b></font></label>

                                {this.state.inputData.freezeCode}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Reason Code:</b></font></label>

                                {this.state.inputData.freezeReasonCode}
                            </Box>
                            <Box p={1} bgcolor="background.paper">
                                <label><font size="2"><b>Freeze Remarks:</b></font></label>

                                {this.state.inputData.freezeRemarks}
                            </Box>


                        </Box>
                    </div>

                )
            } else {
                return "";
            }
        }
    };

    render() {
        if (this.state.loader && !this.state.getData) {
            return (
                <div>
                    <CircularProgress style={{marginLeft: '50%'}}/>
                </div>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 style={{color: "white"}} >Details Information - {this.props.name}<a><CloseIcon onClick={this.props.closeModal}
                                                                                         style={{
                                                                                             position: 'absolute',
                                                                                             right: 10,
                                                                                             color: "#000000"
                                                                                         }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <>
                                    {
                                        this.state.inputData.lienDetails.length > 0 && this.props.labelName === "Lien" && this.state.inputData.lienDetails.map((item) => {
                                            return (
                                                <div style={{width: '100%'}}>
                                                    <Box display="flex" flexDirection="row" flexWrap="wrap"
                                                         bgcolor="background.paper"
                                                         style={{
                                                             marginTop: "20px",
                                                             borderTop: "2px solid #000",
                                                             borderBottom: "2px solid #000"
                                                         }}>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b> Account Number
                                                                : </b></font></label>

                                                            {item.acctNumber}
                                                        </Box>

                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><b><font size="2">Account Name : </font></b>
                                                            </label>

                                                            {item.accountName}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Lien
                                                                Amount: </b></font></label>

                                                            {item.lienAmount}
                                                        </Box>
                                                        <Box p={1} bgcolor="background.paper">
                                                            <label><font size="2"><b>Lien
                                                                Remarks: </b></font></label>

                                                            {item.lienRemarks}
                                                        </Box>


                                                    </Box>

                                                </div>)
                                        })
                                    }
                                    {
                                        this.detailsInformationlabelWise()
                                    }
                                </>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }


}

export default LabelDetails;