import TextField from "@material-ui/core/TextField";
import React from "react";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";

class TextFieldComponent {

    static handleChange(event, field, state, update) {
        // let inputData = state.inputData;
        // inputData[event.target.name] = event.target.value;
        let string = event.target.value;

        state.inputData[event.target.name] = string

        //update(inputData);
    }

    static returnDefaultValue(field, state) {
        if (state.showValue) {
            if (state.varValue === undefined || state.varValue == null)
                return "";
            return state.varValue[field.varName];
        }
    }


    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <FormLabel>{field.label} *</FormLabel>
            )
        } else {
            return (
                <FormLabel>{field.label}</FormLabel>
            )
        }
    };

    static password(state, update, field) {

        return (
            <React.Fragment>
                {
                    this.asteriksFunction(field)
                }

                <Grid item>
                    <TextField
                        type='password'
                        helperText={state.errorMessages[field.varName]}
                        error={state.errorArray[field.varName]}
                        variant="outlined"
                        defaultValue={this.returnDefaultValue(field, state)}
                        required={field.readOnly === true ? false : field.required}
                        key={field.varName}
                        name={field.varName}

                        //label={field.label}
                        multiline={field.multiline === true ? true : false}
                        onChange={(event) => this.handleChange(event, field, state, update)}
                        InputProps={{}}

                        //InputLabelProps={{shrink:true}}
                        fullWidth
                        style={{paddingRight: 20}}
                    />
                </Grid>
            </React.Fragment>

        )


    }

}

export default TextFieldComponent;
