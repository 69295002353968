import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import SelectComponent from "../../JsonForm/SelectComponent"
import AutoCompleteComponent from "../../JsonForm/AutoCompleteComponent";
import Table from "../../Table/Table";
import CBNotFound from "../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let jsonForCustomerCategory = {
    type: "select",
    enum: ["Account", "AMEX", "VISA/Master", "Others"],
    label: "Select Customer category",
    onKeyDown: true,
    grid: 2,
};

let jsonForCallCategory = {
    type: "autoComplete",
    label: "Select Call category",
    grid: 2,
};

let jsonForm = [
    {
        varName: "groupId",
        type: "autoCompleteValueReturn",
        label: "Group Name",
        onKeyDown: true,
        required: true,
        grid: 2,
    }
];


class modifyExistingGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: false,
            alert: false,
            loading: true,
            title: "",
            autoComplete: {},
            notificationMessage: "",
            dynamicField: [],
            existingCategory: [],
            showExistingCategory: false,
            showLastSegment: false,
            cbNotFound: false
        };
    }

    componentDidMount() {
        let url = backEndServerURL + "/GetAllCallCategoryGroup";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    jsonForm[0].enum = response.data
                }
                this.setState({
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }


    functionForGetData = (data) => {
        if (data.varName === "groupId" && this.state.inputData.groupId) {
            this.functionForExistingCategory()
        }
    };

    functionForExistingCategory = () => {
        let inputData = {...this.state.inputData};
        this.setState({
            inputData: inputData,
            varValue: this.copyJson(inputData),
            showValue: true,
            loading: true
        });
        let url = backEndServerURL + "/GetCallCategoryByGroupId/" + this.state.inputData.groupId.key;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let existingCategory = [];
                    response.data.map((item, index) => {
                        existingCategory.push(this.createCallCategoryTableData(index + 1, item.customerCategory, item.callCategory, this.triggerExistingCategoryDelete(item.id)))
                    });
                    this.setState({
                        existingCategory: existingCategory,
                        showExistingCategory: true,
                        showLastSegment: true,
                        loading: false
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                });
            });
    };

    triggerExistingCategoryDelete = (value) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.deleteCategory(value)}
            >
                Disable
            </button>
        )
    };

    deleteCategory = (value) => {
        console.log(value);
        let url = backEndServerURL + "/DeleteCallCategoryFromGroup/" + this.state.inputData.groupId.key + "/" + value;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    existingCategory: [],
                    showExistingCategory: false,
                    showLastSegment: false
                }, this.functionForExistingCategory);
                this.setState({
                    alert: true,
                    title: "Success",
                    notificationMessage: "Category Unassigned Successful!"
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: error.response.data.message,
                })
            });
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    addClick = () => {
        let randomNumber = Math.floor(Math.random() * 100000000000);
        this.setState(prevState => ({
            dynamicField: [...prevState.dynamicField, randomNumber],
        }));
    };

    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    float: 'left',
                    verticalAlign: 'left',
                }}
                type='button' value='add more'
                onClick={this.addClick}
            >+ New</button>
        )

    };

    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };

    removeClick(i, event) {
        event.preventDefault();
        console.log(i);
        let formExist = this.state.dynamicField.indexOf(i);
        if (formExist !== -1) {
            this.setState({
                showLastSegment: false
            });
            delete this.state.inputData["customerCategory" + i];
            delete this.state.inputData["callCategory" + i];
            delete this.state.varValue["customerCategory" + i];
            delete this.state.varValue["callCategory" + i];
            delete this.state.autoComplete["callCategory" + i];
            this.state.dynamicField.splice(formExist, 1);
            this.updateComponent();
            let allInputData = {...this.state.inputData};
            for (let item of this.state.dynamicField) {
                allInputData["callCategory" + item] = {label: this.state.inputData["callCategory" + item]};
                console.log(allInputData["callCategory" + item])
            }

            setTimeout(() => {
                this.setState({
                    varValue: this.copyJson(allInputData),
                    showValue: true,
                    showLastSegment: true
                });
            }, 1000)
        }
    };


    dynamicCustomerCategoryForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForCustomerCategory));
        field.varName = "customerCategory" + i;
        return SelectComponent.select(this.state, this.updateComponent, field, this.onKeyDownChange);
    };

    onKeyDownChange = (data) => {
        console.log(jsonForCustomerCategory);
        let randomNumber = data.varName.replace(/[^0-9]/g, '');
        if (data && this.state.inputData[data.varName]) {
            let cCategory = this.state.inputData[data.varName] === "VISA/Master" ? "visaMaster" : this.state.inputData[data.varName] === "Others" ? "other" : this.state.inputData[data.varName].toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let callCategory = [];
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (response.status === 200) {
                        response.data.map((item) => {
                            callCategory.push({label: item.label})
                        });
                        autoComplete["callCategory" + randomNumber] = callCategory;
                        console.log(autoComplete);
                        this.setState({
                            autoComplete: {...this.state.autoComplete, ...autoComplete},
                        });
                        console.log(this.state.autoComplete)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    dynamicCallCategoryForm = (i) => {
        let field = JSON.parse(JSON.stringify(jsonForCallCategory));
        field.varName = "callCategory" + i;
        return AutoCompleteComponent.renderSelect(this.state, this.updateComponent, field);
    };

    addFileTypeForm() {
        return this.state.dynamicField.map((el, i) =>
            <React.Fragment>
                <Grid xs='12'>
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicCustomerCategoryForm(el)
                    }
                </Grid>
                <Grid item xs={2}>
                    {
                        this.dynamicCallCategoryForm(el)
                    }
                </Grid>
                <Grid item xs={1.5}>
                    <button
                        style={{float: "right", marginTop: "15px"}}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            let url = backEndServerURL + "/AssignGroupToCardCallCategory/" + this.state.inputData.groupId.key;
            axios.post(url, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        alert: true,
                        title: "Success",
                        notificationMessage: "Category Assign Successful!"
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                });
        }
    };

    createCallCategoryTableData = (sl, customerCategory, callCategory, action) => {

        return ([
            sl, customerCategory, callCategory, action
        ])

    };

    renderExistingData = () => {
        if (this.state.existingCategory.length > 0 && this.state.showExistingCategory) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Customer Category and Call Category Mapping:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "Customer Category", "Call Category", "Action"]}
                                tableData={this.state.existingCategory}
                                tableAllign={['left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    renderLastSegments = () => {
        if (this.state.showLastSegment) {
            return (
                <>
                    <Grid item xs={12}>
                        {this.renderExistingData()}
                    </Grid>
                    <Grid item xs='12'>
                        {this.renderAddButtonShow()}
                    </Grid>
                    {this.addFileTypeForm()}
                    <Grid item xs='12'>
                    </Grid>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            height: 30,
                            marginTop: 20,
                        }}
                        onClick={this.handleSubmit}
                    >
                        Submit
                    </button>
                </>
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Modify Existing Group
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbNotFound}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <CBNotFound
                                            closeModal={this.closeModalCBNotFound}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        jsonForm,
                                        this.updateComponent,
                                        "",
                                        this.functionForGetData
                                    )}
                                    {this.renderLastSegments()}
                                </ThemeProvider>
                                {this.renderNotification()}
                            </Grid>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(modifyExistingGroup);
