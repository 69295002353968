import React, {Component} from 'react'


import 'antd/dist/antd.css';

import {Layout, Menu} from 'antd';


const {
    Header
} = Layout;


class HeaderContent extends Component {
    render() {
        return (
            <Header className="header">
                <div className="logo"/>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    style={{lineHeight: '64px'}}

                >

                    <Menu.Item key="1">Home</Menu.Item>
                    <Menu.Item key="2">Other1</Menu.Item>
                    <Menu.Item key="3">Other2</Menu.Item>


                </Menu>
            </Header>
        )
    }
}

export default HeaderContent;