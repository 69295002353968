import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import "../../Static/css/RelationShipView.css";
import '../../Static/css/login.css';
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import cookie from "react-cookies";
import 'antd/dist/antd.css';
import {Input} from 'antd';
import Button from "@material-ui/core/Button";
import {Dialog} from "@material-ui/core";
import EditUserProfile from "./EditUserProfile";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Notification from '../NotificationMessage/Notification';
import Functions from '../../Common/Functions';

const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        background: '#f6f1ff'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 400,
    },
});


class SdProcessFlowMasterBranchSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            errorMessage: " ",
            datas: [],
            title: "",
            messgae: "Successfull!!",
            notificationFlag: false,
            type: "success",
            redirectLogin: false,
        }
    }


    handleChange = (event) => {
        this.setState({
            [event.target.cbnumber]: event.target.value
        });
    };
    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            let searchText = event.target.value;

            let urls = backEndServerURL + '/api/1.0/workflow/groups';
            let authHeader = "Bearer " + cookie.load("accessToken");

            axios.get(urls,
                {headers: {"Authorization": authHeader}})
                .then((response) => {
                    let filteredData = response.data.filter(function (group) {
                        return (group.grp_title === searchText.toUpperCase());
                    });

                    filteredData.map((group) => {
                        this.setState({
                            groupUid: group.grp_uid
                        })
                    });
                    if (this.state.groupUid.length !== 0) {
                        let url = backEndServerURL + '/api/1.0/workflow/groups/' + this.state.groupUid + '/users';
                        let authHeader1 = "Bearer " + cookie.load("accessToken");

                        axios.get(url,
                            {headers: {"Authorization": authHeader1}})
                            .then((response) => {
                                let responseData = [];
                                if (Array.isArray(response.data))
                                    responseData = response.data;
                                this.setState({
                                    datas: responseData
                                })

                            })
                            .catch((error) => {
                                console.log(error);
                                if (error.response.status === 452) {
                                    Functions.removeCookie();

                                    this.setState({
                                        redirectLogin: true
                                    })

                                }

                                this.setState({errorMessage: "Not Found"});
                            })
                    } else {

                    }

                })
                //assign user to group

                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 452) {
                        Functions.removeCookie();

                        this.setState({
                            redirectLogin: true
                        })

                    }

                })


            // single group user list show


        }
    };
    handlesubmit = event => {

        event.preventDefault();

        axios.get(backEndServerURL + '/api/1.0/workflow/extrarest/cases/search/' + this.state.cbnumber, {
            headers: {
                "Authorization": "Bearer " + cookie.load("accessToken")
            }
        })
            .then(response => {
                let responseData = [];
                if (Array.isArray(response.data))
                    responseData = response.data;
                this.setState({
                    datas: responseData
                })
            })
            .catch(error => {
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }

                this.setState({errorMessage: "Not Found"});

            })
    };

    deleteNotification = () => {

        this.setState({
            title: "Deleted",
            message: "Successfully Deleted",
            type: "danger"
        });


    };

    closeConfirmModal = () => {

        this.setState({deleteUser: false});
    };
    closeModal = () => {
        this.setState({editUser: false});


    };
    editedModal = () => {

        this.setState({
            notificationFlag: true
        })

    };
    notificationMessages = () => {

        this.setState({
            notificationFlag: false
        })

    };
    notificationMessage = () => {
        if (this.state.notificationFlag === true) {
            return (
                <Notification notificationMessages={this.notificationMessages} title="Updated"
                              message="Successfully Updated!!" type="success"/>

            )

        }


    };


    handleDelete = (event, userUid) => {
        event.preventDefault();
        let url = backEndServerURL + "/api/1.0/workflow/group/" + this.state.groupUid + "/user/" + userUid;
        console.log(userUid);
        let authHeader = "Bearer " + cookie.load("accessToken");

        axios.delete(url,
            {headers: {"Authorization": authHeader}})
            .then((response) => {
                console.log(response.data);
                this.closeConfirmModal();
                this.deleteNotification();
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }

            });

    };

    render() {
        const {fullScreen} = this.props;
        const {classes} = this.props;
        const {Search} = Input;

        const {datas} = this.state;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    open={this.state.editUser}
                >
                    <EditUserProfile name="Edit Profile" userUid={this.state.userUid} closeModal={this.closeModal}
                                     editCloseModal={this.editedModal}/>

                </Dialog>


                <Dialog
                    fullScreen={fullScreen}

                    open={this.state.deleteUser}
                    onClose={this.closeConfirmModal}
                    aria-labelledby="title"
                >
                    <DialogTitle id="title">{"Comfirm Delete?"}</DialogTitle>

                    <DialogActions>
                        <Button onClick={this.closeConfirmModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={(event) => {
                            this.handleDelete(event, this.state.userUid)
                        }} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>SD Group Search</h4>
                            </CardHeader>
                            <CardBody>

                                <div className={classes.root}>
                                    {this.notificationMessage()}
                                    <form onSubmit={this.handlesubmit}>
                                        <Search

                                            placeholder="input search text"

                                            style={{marginLeft: 750, width: 300}}
                                            onKeyDown={this.handleKeyDown}
                                            onChange={this.handleChange}
                                            onClick="submit"

                                        />

                                    </form>
                                    <br/><br/>

                                    <ReactNotification ref={this.notificationDOMRef}/>


                                    <center>
                                        <table className="table table-bordered table-definition mb-10">
                                            <thead style={{backgroundColor: "#FF5733", color: "#FFFFFF"}}>
                                            <tr>
                                                <th>User Name</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                datas.map((group) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                {group.usr_username}

                                                            </td>
                                                            <td>
                                                                {group.usr_firstname}

                                                            </td>
                                                            <td>
                                                                {group.usr_lastname}

                                                            </td>
                                                            <td>
                                                                {group.usr_email}

                                                            </td>
                                                            <td>
                                                                <Button
                                                                    style={{
                                                                        backgroundColor: "#FF5733",
                                                                        color: "#FFFFFF"
                                                                    }}


                                                                    onClick={() => {

                                                                        this.setState({
                                                                            userUid: group.usr_uid,
                                                                            firstName: group.usr_firstname,
                                                                            lastName: group.usr_lastname,
                                                                            email: group.usr_email,
                                                                            editUser: true,

                                                                        })

                                                                    }
                                                                    }>Edit</Button>

                                                            </td>
                                                            <td>
                                                                <Button
                                                                    style={{
                                                                        backgroundColor: "#FF5733",
                                                                        color: "#FFFFFF"
                                                                    }}


                                                                    onClick={() => {
                                                                        this.setState({
                                                                            userUid: group.usr_uid,
                                                                            deleteUser: true

                                                                        })
                                                                    }}>Delete

                                                                </Button>

                                                            </td>
                                                        </tr>
                                                    )

                                                })
                                            }

                                            </tbody>
                                        </table>
                                    </center>
                                </div>


                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </section>

        );
    }
}

export default withStyles(styles)(SdProcessFlowMasterBranchSearch);
