import React from "react";

class FileTypeComponent {

    static handleChange(event, state, update, field) {

        let x = [];

        if (field.fileType === "multiple") {
            let eventValue = event.target.files;
            for (let i = 0; i < eventValue.length; i++) {
                x.push(eventValue[i])
            }
            state.fileUploadData[field.varName] = x;

        } else {
            state.fileUploadData[field.varName] = event.target.files[0];
        }
        update();

    }

    static asteriksFunction = (field) => {
        if (field.required === true && field.asteriks === undefined) {
            return (
                <label htmlFor="">{field.label} *</label>
            )
        } else {
            return (
                <label htmlFor="">{field.label}</label>
            )
        }
    };

    static renderErrorMessage = (field, state) => {
        if (state.errorArray[field.varName] && state.errorMessages[field.varName] === "Invalid File Type") {
            return <label style={{color: "red"}}>File Not in Required Format</label>
        } else if (state.errorArray[field.varName]) {
            if (state.errorMessages[field.varName] !== undefined && state.errorMessages[field.varName] !== null && state.errorMessages[field.varName].length > 0) {
                return <label style={{color: "red"}}>{state.errorMessages[field.varName]}</label>
            } else {
                return <label style={{color: "red"}}>File Not Found or Not in Required Format</label>
            }

        }
    };


    static file(state, update, field) {
        return (
            <div>
                {
                    this.asteriksFunction(field)
                }

                <br/>
                <input
                    type="file"
                    name={field.varName} label={field.label}
                    multiple={field.fileType === "multiple" ? "multiple" : ""}
                    onChange={(event) => this.handleChange(event, state, update, field)}
                />
                <br/>
                {this.renderErrorMessage(field, state)}
            </div>
        )


    }

    static restrictedFile(state, update, field) {
        return (
            <div>
                <label htmlFor="">{field.label}</label>
                <br/>
                <input
                    type="file"
                    name={field.varName} label={field.label}
                    accept={field.accept}
                    multiple={field.fileType === "multiple" ? "multiple" : ""}
                    onChange={(event) => this.handleChange(event, state, update, field)}
                />
                <br/>
                {this.renderErrorMessage(field, state)}
            </div>
        )


    }

}

export default FileTypeComponent;