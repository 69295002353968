import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../SignatureButton";
import Label from "../casa/Label";
import TextField from "@material-ui/core/TextField";
import PreviewButton from "../PreviewButton";
import UploadComponent from "../casa/UploadComponent";
import {dpsRequestTypeForMaintenance} from "../../WorkflowJsonFormRashed"
import GridContainer from "../../../Grid/GridContainer";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};


let accountMaintenanceSearch = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "validation": "numeric",
        "label": "EQM Number",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "readOnly": true,
        "validation": "numeric",
        "label": "Account No",
        "grid": 2,

    },
    {
        "varName": "dpsCloserRequest",
        "type": "select",
        "label": "Select Request Type",
        "grid": 2,
        "readOnly": true,
        "enum": [
            'DPS Closure',
            'Deceased DPS Closure',
            'DPS Encashment Certificate',
        ]
    }
];

let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

let dpsRequestType = [
    {
        "varName": "closerRequestName",
        "type": "select",
        "label": "Input Transfer Account Number",
        "grid": 3,
        "required": true,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
            "DPS Encashment Certificate"
        ]
    }];

const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}
];

let closerRequestForm = [

    {
        "varName": "inputAccountNumber",
        "type": "text",
        "validation": "numeric",
        "label": "Account Number",
        "grid": 3,
    },
    {
        "varName": "inputAccountName",
        "type": "text",
        "label": "Account Name",
        "validation": "string",
        "readOnly": true,
        "grid": 3,
    }
];


class CsInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            inputData: {},
            appId: '',
            message: "",
            appData: {},
            getData: false,
            varValue: {},
            routeCase: false,
            caseId: "",
            title: "",
            notificationMessage: "",
            alert: false,
            redirectLogin: false,
            type: [],
            searchData: false,
            fileUploadData: [],
            selectedDate: {},
            showValue: false,
            getDeferalList: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            returnData: false,
            returnDataBm: false,
            objectForNominee: [],
            getNominee: false,
            maintenanceRequest: false,
            previewDocument: false,
            getInitialNewNominee: 0,
            objectForExistingNominee: [],
            objectForInitialNewNominee: [],
        };

    }

    getSearchvalue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {
        if (this.state.inputData["csBearer"] === "NO") {
            this.state.inputData["csBearer"] = "";
        }
        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.dpsCloserRequest === "DPS Nominee Change") {
                        var sl;
                        let objectForExistingNominee = [];
                        for (var i = 0; i < response.data.nomineeNumberOld; i++) {
                            sl = i + 1;
                            objectForExistingNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                    "readOnly": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "registrationNumber" + sl,
                                    "type": "text",
                                    "label": "Registration No",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "individualId" + sl,
                                    "type": "text",
                                    "label": "CIF ID/Individual Id",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineeNameOld" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "relationship" + sl,
                                    "type": "date",
                                    "label": "Relationship",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "percentageNomineeOld" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "addressFieldOne" + sl,
                                    "type": "text",
                                    "label": "Address Field 1",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "addressFieldTwo" + sl,
                                    "type": "text",
                                    "label": "Address Field 2",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "city" + sl,
                                    "type": "text",
                                    "label": "City",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "state" + sl,
                                    "type": "text",
                                    "label": "State",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "postalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "country" + sl,
                                    "type": "text",
                                    "label": "Country",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "photoIdNumberOrRegistrationNumber" + sl,
                                    "type": "text",
                                    "label": "Photo Id No/Registration No.",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "dob1Old" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["Yes", "No"],
                                    "grid": 3,
                                    "conditional": true,
                                    "conditionalVarName": "dpsCloserRequest",
                                    "conditionalVarValue": "DPS Nominee Change"
                                },
                                {
                                    "varName": "minorGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardian's name",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianCode" + sl,
                                    "type": "text",
                                    "label": "Guardian Code",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianAddressOne" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianAddressTwo" + sl,
                                    "type": "text",
                                    "label": "Address Two",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianCity" + sl,
                                    "type": "text",
                                    "label": "City",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianState" + sl,
                                    "type": "text",
                                    "label": "State",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                                {
                                    "varName": "minorGuardianCountry" + sl,
                                    "type": "text",
                                    "label": "Country",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["dpsCloserRequest", "nomineeMinor" + sl,],
                                    "conditionalArrayValue": ["DPS Nominee Change", "Yes"],
                                },
                            )

                        }
                        this.setState({
                            objectForExistingNominee: objectForExistingNominee,
                        })
                    }

                    if (response.data.dpsCloserRequest === "DPS Nominee Change") {

                        var sl;
                        let objectForNominee = [];
                        for (var i = 0; i < response.data.numberOfNominee; i++) {
                            sl = i + 1;
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "New Nominee  " + sl,
                                    "grid": 12,

                                },
                                {
                                    "varName": "nomineeNameNew" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "dobNew" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "relationShipNew" + sl,
                                    "type": "text",
                                    "label": "Relationship",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "addressOneNew" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "addressTwoNew" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "cityCodeNew" + sl,
                                    "type": "text",
                                    "label": "City Code",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "stateCodeNew" + sl,
                                    "type": "text",
                                    "label": "State Code",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "postalCodeNew" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "countryNew" + sl,
                                    "type": "text",
                                    "label": "Country",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "registrationNew" + sl,
                                    "type": "text",
                                    "label": "Reg No",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "nomineeMinorNew" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["Yes", "No"],
                                    "grid": 3,
                                    required: true,
                                },
                                {
                                    "varName": "minorGuardiansNameNew" + sl,
                                    "type": "text",
                                    "label": "Guardian's name",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianCodeNew" + sl,
                                    "type": "text",
                                    "label": "Guardian Code",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianPhotoIdNew" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianAddressOneNew" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianAddressTwoNew" + sl,
                                    "type": "text",
                                    "label": "Address Two",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianCityNew" + sl,
                                    "type": "text",
                                    "label": "City",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianStateNew" + sl,
                                    "type": "text",
                                    "label": "State",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianPostalCodeNew" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                                {
                                    "varName": "minorGuardianCountryNew" + sl,
                                    "type": "text",
                                    "label": "Country",
                                    "grid": 3,
                                    "multiline": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["nomineeMinorNew" + sl,],
                                    "conditionalArrayValue": ["Yes"],
                                },
                            )


                        }
                        this.setState({
                            objectForInitialNewNominee: objectForNominee,
                        })

                    }


                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    this.setState({
                                        fileUploadData: response.data,
                                        appId: response.data.id,
                                        appData: response.data.inputData,
                                        showValue: true,
                                        getData: true,
                                        getDocument: true,
                                        loader: false,
                                        searchData: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });

                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    response.data.map((data) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    this.state.inputData["csDeferal"] = "NO";
                    let varValue = response.data;
                    this.setState({
                        getInitialNewNominee: response.data.numberOfNominee,
                        getData: true,
                        varValue: varValue,
                        appData: response.data,
                        maintenanceRequest: response.data.maintenanceRequest,
                        inputData: this.getSearchvalue(response.data),
                        showValue: true,
                        appId: this.props.appId,
                        loading: false

                    });

                })
                .catch((error) => {
                    console.log(error);

                });
        }


    }

    updateComponent = () => {
        this.forceUpdate();
    };

    renderCustomerDetails = () => {
        if (this.state.inputData.accountNumber && this.state.searchData === true) {
            return (
                <Label data={this.state.boxLabels} classes={this.props}/>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    renderSearchForm = (root) => {
        return (
            <Grid item xs={12}>
                <Grid container>
                    <ThemeProvider theme={theme}>

                        {
                            CommonJsonFormComponent.renderJsonForm(this.state, accountMaintenanceSearch, this.updateComponent)
                        }
                    </ThemeProvider>
                </Grid>
            </Grid>
        )
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmitButton = () => {
        if (this.state.getData) {
            return (
                <ThemeProvider theme={theme}>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            onClick={this.handleSubmit}
                        >
                            Submit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        <button
                            className="btn btn-outline-info btn-sm"
                            style={{}}
                            onClick={this.handleClose}
                        >
                            Close
                        </button>

                    </Grid>
                </ThemeProvider>
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            disabled: true
        });
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true,
                        disabled: false
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false
                });
            });
    };

    renderLocation = () => {
        if (this.state.routeCase === true) {
            //this.state.inputData["accountNumber"]="";
            this.state.inputData["accountNumber"] = "";
            this.setState({
                loader: null,
                routeCase: false,
                getData: false,
            })
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();

        let error = MyValidation.defaultValidation(this.state.objectForExistingNominee, this.state);
        let errorOne = MyValidation.defaultValidation(this.state.objectForInitialNewNominee, this.state);
        let errorTwo = MyValidation.defaultValidation(this.state.objectForNominee, this.state);
        let errorThree = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();

        if (error === true || errorOne === true || errorTwo === true || errorThree === true) {
            return 0;
        } else {
            if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
                this.setState({
                    cbNotFound: true,
                    title: "Please Upload File!"
                })
            } else {
                var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.inputData.csRemarks + "/" + this.props.appId;
                axios.post(commentsUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data)
                    })
                    .catch((error) => {
                        console.log(error)
                    });
                var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
                let data = this.state.inputData;
                data.csRemarks = undefined;
                data.serviceType = "Maintenance";
                data.subServiceType = "AccountMaintenance";
                data.cs_deferal = "NO";

                if ((this.state.inputData.closerRequestType = "DPS Closure") || (this.state.inputData.closerRequestType = "DPS Encashment Certificate")) {
                    data.category = "DPS Closer";
                } else if (this.state.inputData.closerRequestType === "Deceased DPS Closure") {
                    data.category = "Deceased DPS Closer";
                }
                data.subCategory = "Maintenance";
                data.maintenanceValueRequestType = "Closer";

                axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                            var url = backEndServerURL + "/case/route/" + this.props.appId;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                                        this.props.closeModal();
                                    }
                                    this.setState({
                                        title: "Successful!",
                                        notificationMessage: "Successfully Routed!",
                                        alert: true,
                                        routeCase: true,

                                    })

                                })
                                .catch((error) => {

                                    if (error.response && error.response.status === 452) {
                                        Functions.removeCookie();
                                        this.setState({
                                            redirectLogin: true
                                        })

                                    }
                                });

                        }
                    )
                    .catch((error) => {
                        console.log('variable error');
                        console.log(error)
                    });
            }
        }
    };

    handleSubmitDraft = (event) => {
        event.preventDefault();

        let errorTwo = MyValidation.defaultValidation(closerRequestForm, this.state);
        this.forceUpdate();

        if (errorTwo === true) {
            return 0;
        } else {

            var variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
            let data = this.state.inputData;
            data.serviceType = "Maintenance";
            data.subServiceType = "AccountMaintenance";
            if ((this.state.inputData.closerRequestType = "DPS Closure") || (this.state.inputData.closerRequestType = "DPS Encashment Certificate")) {
                data.category = "DPS Closer";
            } else if (this.state.inputData.closerRequestType === "Deceased DPS Closure") {
                data.category = "Deceased DPS Closer";
            }

            data.subCategory = "Maintenance";
            data.maintenanceValueRequestType = "Closer";

            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (this.state.inputData.maker_approval === "RETURN" || this.state.inputData.returnDataBm === true) {
                        this.props.closeModal();
                    }
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Draft!",
                        alert: true

                    });
                    this.close()

                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    previewDocument = () => {
        this.setState({
            previewDocument: true
        })
    };


    signature = () => {
        if (this.state.searchData === true && this.state.getData) {
            return (
                <React.Fragment>
                    {
                        this.previewButton()
                    }
                </React.Fragment>
            )
        }
    };

    requestForm = () => {

        if (this.state.getData && (this.state.inputData.dpsCloserRequest === "DPS Closure" || this.state.inputData.dpsCloserRequest === "Deceased DPS Closure")) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, closerRequestForm, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        } else if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Encashment Certificate") {
            console.log("requestForm else if");
            return ""
        } else {
            console.log("requestForm else");
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, dpsRequestTypeForMaintenance, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };


    renderMaintenanceRequest = () => {
        if (this.state.getData && (this.state.inputData.dpsCloserRequest === "DPS Closure" || this.state.inputData.dpsCloserRequest === "Deceased DPS Closure")) {
            console.log("renderMaintenanceRequest if");
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, dpsRequestType, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>

            )
        }
    };

    previewButton = () => {

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    &nbsp;&nbsp;
                    <PreviewButton appId={this.props.appId} classes={this.props}/>
                </Grid>
            </React.Fragment>
        )

    };
    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} showSignature={false} appId={this.state.appId}
                                         classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };


    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        }
    };


    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && this.state.objectForExistingNominee.length > 0) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForExistingNominee, this.updateComponent)
                            }
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    renderIntialNewNomineeForm = () => {
        if (this.state.getData && this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && this.state.objectForInitialNewNominee.length > 0) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForInitialNewNominee, this.updateComponent)
                            }
                            <br/>
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )

        }
    };

    renderNumberOfNominee = () => {
        if (this.state.inputData.dpsCloserRequest === "DPS Nominee Change") {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <br/>
                        <React.Fragment>
                            <Grid item xs={3}>
                                <Grid item>
                                    <label className="input-label-common">Number Of Nominee</label>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                        //defaultValue={this.state.inputData["numberOfNominee"]}
                                        options={numberOfNominee} getOptionLabel={option => option.title}
                                        renderInput={(params) => <TextField {...params} variant="outlined"

                                                                            style={{paddingRight: 20}} fullWidth/>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                            </Grid>
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();

        if (option !== null) {
            this.state.inputData["numberOfNominee"] = parseInt(this.state.getInitialNewNominee) + parseInt(option.title);
            this.forceUpdate();

            if (option.title > 0) {
                var sl;
                let objectForNominee = [];
                for (var i = 0; i < option.title; i++) {
                    sl = parseInt(this.state.getInitialNewNominee) + (i + 1);
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + sl,
                            "type": "title",
                            "label": "New Nominee  " + sl,
                            "grid": 12,

                        },
                        {
                            "varName": "nomineeNameNew" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "dobNew" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "relationShipNew" + sl,
                            "type": "text",
                            "label": "Relationship",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "addressOneNew" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "addressTwoNew" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "cityCodeNew" + sl,
                            "type": "text",
                            "label": "City Code",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "stateCodeNew" + sl,
                            "type": "text",
                            "label": "State Code",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "postalCodeNew" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "countryNew" + sl,
                            "type": "text",
                            "label": "Country",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "registrationNew" + sl,
                            "type": "text",
                            "label": "Reg No",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "nomineeMinorNew" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["Yes", "No"],
                            "grid": 3,
                            required: true,
                        },
                        {
                            "varName": "minorGuardiansNameNew" + sl,
                            "type": "text",
                            "label": "Guardian's name",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianCodeNew" + sl,
                            "type": "text",
                            "label": "Guardian Code",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianPhotoIdNew" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianAddressOneNew" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianAddressTwoNew" + sl,
                            "type": "text",
                            "label": "Address Two",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianCityNew" + sl,
                            "type": "text",
                            "label": "City",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianStateNew" + sl,
                            "type": "text",
                            "label": "State",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianPostalCodeNew" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                        {
                            "varName": "minorGuardianCountryNew" + sl,
                            "type": "text",
                            "label": "Country",
                            "grid": 3,
                            "multiline": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["nomineeMinorNew" + sl,],
                            "conditionalArrayValue": ["Yes"],
                        },
                    )

                }

                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true

                });
                this.renderNomineeForm();
            }
        } else if (option === null) {
            this.setState({
                objectForNominee: [],
                getNominee: true,
            });
            this.renderNomineeForm();
        }
    };

    renderNomineeForm = () => {
        if (this.state.inputData.dpsCloserRequest === "DPS Nominee Change" && this.state.getNominee) {
            return (
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <React.Fragment>
                            <br/>
                            {CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent)}
                        </React.Fragment>
                    </ThemeProvider>
                </Grid>
            )
        }
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else if (this.state.searchData) {
            return (
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >DPS/Scheme Closer Maintenance
                            <a><CloseIcon onClick={this.close} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                    </CardHeader>
                    <CardBody>
                        {this.renderSearchForm(classes.root)}
                        <br/>
                        {this.renderNotification()}
                        {this.renderCustomerDetails()}
                        <br/>
                        {this.signature()}
                        <br/>
                        {this.renderMaintenanceRequest()}
                        {this.requestForm()}
                        <br/>
                        {this.renderExistingNomineeForm()}
                        {this.renderIntialNewNomineeForm()}
                        {this.renderNumberOfNominee()}
                        {this.renderNomineeForm()}
                        <br/>
                        {this.uploadFunction()}
                        {this.renderRemarksData()}
                        {this.renderRemarks()}
                        <br/>
                        {this.handleSubmitButton()}
                        {this.renderLocation()}
                    </CardBody>
                </Card>
            );
        }
    }
}

export default withStyles(styles)(CsInbox);
