import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import GridContainer from "../../../Grid/GridContainer.jsx";
import "../../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import TextFieldComponent from "../../../JsonForm/TextFieldComponent";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import SelectComponent from "../../../JsonForm/SelectComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import Notification from "../../../NotificationMessage/Notification";
import Label from '../static/Label';
import SignatureButton from "../SignatureButton";
import UploadComponent from "../casa/UploadComponent";
import loader from "../../../../Static/loader.gif";
import CBNotFound from "../../CASA/CBNotFound";
import DedupResultFunction from "../../../DedupResultFunction";
import {CBDataJsonForm} from "../../WorkflowJsonForm4";
import SdnResultFunction from "../../../SdnResultFunction";
import DateComponentDeferal from "../../../JsonForm/DateComponentDeferal";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import MaintenanceUtility from "../static/MaintenanceUtility";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";

var deferalOther =
    {
        "varName": "deferalOther",
        "type": "text",
        "label": "Remarks",
    };

var deferal =
    {
        "varName": "deferalType",
        "type": "select",
        "label": "Deferral Type",
        "enum": [
            "Applicant Photograph",
            "Nominee Photograph",
            "Passport",
            "Address proof",
            "Transaction profile",
            "Others"
        ],

    };

var date = {
    "varName": "expireDate",
    "type": "date",
    "label": "Expire Date",

};

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
let searchFieldList = [
    {
        "varName": "eqmNumber",
        "type": "text",
        "label": "EQM Number",
        "grid": 2,

    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account No",
        "required": true,
        "grid": 2,

    },
];

let signatoryForm = [
    {
        "varName": "numberOfNewMandate",
        "type": "select",
        "label": " Number of New Mandate/Beneficial Owner/Signatory",
        // "onKeyDown": true,
        "readOnly": true,
        "enum": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10"
        ],
        "grid": 3,
    },
];


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];

class SignatoryMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageLink: {},
            tableData: [],
            searchTableData: false,
            getDeferralList: [],
            getDocument: false,
            routeCase: false,
            cbNotFound: false,
            selectImage: "",
            inputData: {
                accountNumber: "",
                csDeferal: "NO",
                csBearer: "NO",
            },
            imageModalBoolean: false,
            getImageLink: [],
            values: [],
            appId: '',
            getData: false,
            varValue: {},
            title: "",
            loading: true,
            notificationMessage: "",
            alert: false,
            type: [],
            fileUploadData: [],
            selectedDate: {},
            AddDeferal: false,
            showValue: false,
            getDeferalList: [],
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            dedupModal: false,
            labelData: [],
            newMandate: false,
            mandateWithExistingCb: [],
            nationality: [],
            mandateChange: 0,
            disabled: false,
            mandateTableArray: [],
            confirmationAlert: false,
        };
    }


    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null' || clone[prop] === '-1')
                delete clone[prop];
        return clone;
    };

    addDeferalForm() {
        return this.state.values.map((el, i) =>
            <React.Fragment>
                <Grid item xs={3}>
                    {
                        this.dynamicDeferral(el)
                    }
                </Grid>

                <Grid item xs={3}>
                    {
                        this.dynamicDate(el)
                    }

                </Grid>
                <Grid item xs={3}>
                    {this.dynamicDeferralOther(el)}

                </Grid>
                <Grid item xs={3}>
                    <button
                        style={{
                            float: "left",
                            marginTop: "15px"
                        }}
                        className="btn btn-outline-primary btn-sm"
                        type='button' value='remove' onClick={this.removeClick.bind(this, el)}
                    >
                        Remove
                    </button>
                </Grid>
            </React.Fragment>
        )

    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    componentDidMount() {

        var remarksArray = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {

                    let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + response.data.accountNumber;
                    axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            if (response.status === 200) {
                                let mandateTableArray = [];
                                if (response.data.relPartyList) {
                                    response.data.relPartyList.map((mandate, index) => {
                                        if (mandate.relPartyType !== "M") {
                                            let exFlag;
                                            if (mandate.recDelFlg === "Y") {
                                                exFlag = "CB Deleted"
                                            } else if (mandate.recDelFlg === "N") {
                                                exFlag = "CB Existed"
                                            } else {
                                                exFlag = mandate.recDelFlg;
                                            }
                                            mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, mandate.relPartyTypeDesc, exFlag))
                                        }
                                    });
                                }
                                this.setState({
                                    mandateTableArray: mandateTableArray,
                                });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                getData: false,
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            })

                        });

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            let imageUrl = backEndServerURL + "/case/files/" + this.props.appId;
                            axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    this.setState({
                                        fileUploadData: response.data,
                                        uploadButtonClick: true
                                    })
                                })
                                .catch((error) => {
                                    console.log(error);
                                });

                            let tableArray = [];
                            var status = "";
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved"
                                } else if (deferal.status === "NOTAPPROVED") {
                                    status = "NOT APPROVED"
                                } else {
                                    status = deferal.status;
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {

                                    console.log(response.data);
                                    response.data.map((data) => {

                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })

                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        appId: this.props.appId,
                        accountNumber: response.data.accountNumber,
                        getData: true,
                        searchTableData: true,
                        showValue: true,
                        inputData: this.copyJson(response.data),
                        varValue: this.copyJson(response.data),
                    });
                })
                .catch((error) => {
                    console.log(error);

                });
        } else {
            this.setState({
                loading: false
            })
        }


    }


    updateComponent = () => {
        this.forceUpdate();
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Remarks", "User Name", "Date", "Role"]}
                                tableData={this.state.getRemarks}
                                tableAllign={['left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }

    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <Table

                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                                tableData={this.state.getDeferalList}
                                tableAllign={['left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }

    };

    renderAddButtonShow = () => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    width: 100,
                    float: 'left',
                    verticalAlign: 'right',
                }}
                type='button' value='add more'
                onClick={this.addClick.bind(this)}
            >Add Deferral</button>
        )

    };

    dynamicDeferral = (i) => {
        let field = JSON.parse(JSON.stringify(deferal));
        field.varName = "deferalType" + i;
        return SelectComponent.select(this.state, this.updateComponent, field);
    };

    dynamicDeferralOther = (i) => {
        if (this.state.inputData["deferalType" + i] === "Others") {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        } else {
            let field = JSON.parse(JSON.stringify(deferalOther));
            field.varName = "deferalOther" + i;
            return TextFieldComponent.text(this.state, this.updateComponent, field);
        }
    };
    dynamicDate = (i) => {
        let field = JSON.parse(JSON.stringify(date));
        field.varName = "expireDate" + i;
        return DateComponentDeferal.date(this.state, this.updateComponent, field);
    };

    removeClick(i, event) {
        event.preventDefault();
        let pos = this.state.values.indexOf(i);
        if (pos > -1) {
            this.state.values.splice(pos, 1);
            this.updateComponent();
            if (this.state.values.length > 0) {
                this.state.inputData["csDeferal"] = "YES"
            } else {
                this.state.inputData["csDeferal"] = "NO"
            }
        }


    }

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
            )
        }
    };


    handleChangeuploadFileAlertRequest = (event) => {
        let data = event.target.checked;
        this.state.inputData.uploadFileAlertRequest = data;
        this.updateComponent();
        if (data === false) {
            this.setState({
                cbNotFound: true,
                title: "Without Document Process!!",
            })
        }
    };

    uploadFunction = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return (
                <GridContainer>
                    <Grid item xs={.5}>
                        <CheckBox
                            style={{marginTop: "-6px"}}
                            checked={this.state.inputData.uploadFileAlertRequest}
                            defaultValue={this.state.inputData.uploadFileAlertRequest}
                            onChange={(event) => this.handleChangeuploadFileAlertRequest(event)}/>
                    </Grid>
                    <Grid item xs={11}>
                        <UploadComponent previewImage={this.previewImage} showSignature={true} appId={this.state.appId}
                                         classes={classes}/>
                    </Grid>
                </GridContainer>
            )
        }
    };

    previewImage = (value) => {
        this.state.inputData["previewImage"] = value === "submit";
        this.forceUpdate();
    };


    addClick() {
        let randomNumber = Math.floor(Math.random() * 100000000000);

        this.setState(prevState => ({
            values: [...prevState.values, randomNumber],
        }));

        this.state.inputData["csDeferal"] = "YES";
    }


    renderSearchForm = () => {
        if (this.props.appId !== undefined) {
            return (
                <React.Fragment>
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {
                                CommonJsonFormComponent.renderJsonForm(this.state, searchFieldList, this.updateComponent)
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderDedupComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <DedupResultFunction radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData} subServiceType={this.props.subServiceType}
                                         appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "43px"}}>
                    <SdnResultFunction category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };


    renderData = () => {
        if (this.state.searchTableData) {
            return (
                <React.Fragment>
                    <ThemeProvider theme={theme}>

                        <Grid container>
                            <Grid item xs='12'>
                                {
                                    this.renderAddButtonShow()
                                }
                            </Grid>


                            <Grid item xs='12'>

                            </Grid>
                            {
                                this.addDeferalForm()
                            }
                            <Grid item xs={12}>
                                {this.renderDefferalData()}
                            </Grid>
                            <Grid item xs={12}>

                                {this.renderRemarksData()}

                            </Grid>

                            <Grid item xs={12}>
                                {this.uploadFunction()}
                            </Grid>
                            {this.renderRemarks()}
                            <Grid item xs='12'>
                            </Grid>
                            <center>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{}}
                                    onClick={this.handleSubmit}
                                    disabled={this.state.disabled}
                                >
                                    Submit
                                </button>
                                {/*{this.props.taskTitle === "cs_maintenance" && <>&nbsp;*/}
                                {/*    <button*/}
                                {/*        className="btn btn-outline-primary btn-sm"*/}
                                {/*        style={{}}*/}
                                {/*        onClick={this.handleClose}*/}
                                {/*        disabled={this.state.disabled}*/}
                                {/*    >*/}
                                {/*        Close*/}
                                {/*    </button>*/}
                                {/*</>}*/}

                            </center>
                        </Grid>
                    </ThemeProvider>
                </React.Fragment>
            )
        }
    };


    stopLoading = (value) => {
        if (value === "yes") {
            this.setState({
                loading: false
            })
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderSignatoryForm = () => {
        if (this.state.getData) {
            return CommonJsonFormComponent.renderJsonForm(this.state, signatoryForm, this.updateComponent)
        }
    };


    renderSearchData = () => {
        if (this.state.getData && this.state.searchTableData) {
            return (
                <React.Fragment>
                    {/*<GridContainer>*/}
                    {/*<GridItem xs={6}>*/}
                    {/*    {this.renderDedupComponent()}*/}
                    {/*</GridItem>*/}
                    {/*<GridItem xs={6}>*/}
                    {/*    {this.renderSdnComponent()}*/}
                    {/*</GridItem>*/}
                    {/*</GridContainer>*/}
                    <Grid container spacing={1}>
                        <ThemeProvider theme={theme}>
                            {this.renderSignatoryForm()}
                            <Grid item xs={12}>
                                <br/>
                                {this.renderFlowSummeryButton()}
                                <br/>
                            </Grid>
                            {
                                this.renderData()
                            }
                        </ThemeProvider>
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderCustomerDetails = () => {
        if (this.state.getData && this.state.inputData.accountNumber && this.state.searchTableData) {
            return (
                <React.Fragment>
                    <Label accountNumber={this.state.inputData.accountNumber} cbNumber={this.state.inputData.cbNumber}
                           classes={this.props} stopLoading={this.stopLoading}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <br/>
                    <Grid container spacing={0}>
                        <SignatureButton accountNumber={this.state.inputData.accountNumber} classes={this.props}
                                         signatureType="multiple"/>
                    </Grid>
                </React.Fragment>
            )
        }
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });
            let variableSetUrl = backEndServerURL + "/case/close/" + this.state.appId;
            axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "Successfully Close!",
                            alert: true,
                            disabled: false
                        });
                        this.props.closeModal();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                });
        } else {
            this.setState({
                confirmationAlert: false
            })
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.inputData.uploadFileAlertRequest && (this.state.fileUploadData.length === 0 && this.state.inputData.previewImage === undefined)) {
            this.setState({
                cbNotFound: true,
                title: "Please Upload File!"
            })
        } else {
            this.setState({
                disabled: true
            });
            if (this.state.inputData["csDeferal"] === "YES") {
                var defType = [];
                var expDate = [];

                let appId = this.state.appId;
                for (let i = 0; i < this.state.values.length; i++) {
                    let value = this.state.values[i];
                    let deferalType = this.state.inputData["deferalType" + value];
                    if (deferalType === "other") {
                        deferalType = this.state.inputData["deferalOther" + value];
                    }
                    defType.push(deferalType);
                    let expireDate = this.state.inputData["expireDate" + value];
                    expDate.push(expireDate);

                }
                let deferalRaisedUrl = backEndServerURL + "/deferral/create/bulk";

                axios.post(deferalRaisedUrl, {
                    appId: appId,
                    type: defType,
                    dueDate: expDate
                }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log("deferal created")
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            disabled: false
                        });
                    })
            }
            var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.state.appId;
            axios.post(commentsUrl, {remark: this.state.inputData.csRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                });
            var variableSetUrl = backEndServerURL + "/variables/" + this.state.appId;
            let data = this.state.inputData;
            data.newMandate = this.state.newMandate;
            data.mandateChange = this.state.mandateWithExistingCb.length + parseInt(this.state.mandateChange);
            data.cs_deferal = this.state.inputData.csDeferal;
            data.csRemarks = undefined;
            data.serviceType = "Maintenance";
            data.subServiceType = "MandateMaintenance";
            data.category = "Mandate";
            data.subCategory = "Mandate Update";

            if (this.state.inputData.csDeferal === "NO" || this.state.inputData.csDeferal === undefined) {
                data.cs_deferal = "NO";
                data.cs_bearer = "NO";
            } else if (this.state.inputData.csDeferal === "YES") {
                data.cs_deferal = "YES";
            }


            axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                        var url = backEndServerURL + "/case/route/" + this.state.appId;

                        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    this.setState({
                                        title: "Successful!",
                                        notificationMessage: "Successfully Routed!",
                                        routeCase: true,
                                        alert: true,
                                        disabled: false
                                    });
                                    if (this.props.appId !== undefined) {
                                        this.props.closeModal();
                                    } else {
                                        setTimeout(function () {
                                            window.location.reload()
                                        }, 1000);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    disabled: false
                                });
                            });

                    }
                )
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        disabled: false
                    });
                });
        }
    };


    close = () => {
        if (this.props.appId !== undefined) {
            this.props.closeModal();
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Related Party:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Designation Code", "Related CB Status"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    createMandateTableData = (sl, cb, name, relationship, designation, existingMandateDeleteFlag) => {
        return ([
            sl, cb, name, relationship, designation, existingMandateDeleteFlag
        ])

    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {MaintenanceUtility.renderMaintenanceHeader(this.props.freeFlag1, this.props.caseId, this.props.solId, this.props.cbNumber, this.props.accountNumber, this.props.customerName, this.close)}
                </CardHeader>
                <CardBody>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.confirmationAlert}>
                        <DialogContent className={classes.dialogPaper}>
                            <ConfirmationModal
                                closeModal={this.closeConfirmation}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.cbNotFound}>
                        <DialogContent className={classes.dialogPaper}>
                            <CBNotFound
                                closeModal={this.closeModalCBNotFound}
                                title={this.state.title}
                            />
                        </DialogContent>
                    </Dialog>
                    {this.renderNotification()}
                    {this.renderCustomerDetails()}
                    <Grid item={12}>
                        {this.renderMandateData()}
                    </Grid>
                    {this.renderSearchData()}
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(SignatoryMaintenance);
