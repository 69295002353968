// import React, {Component} from 'react';
// import {notification} from 'antd';
// import 'antd/dist/antd.css';
// import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

// class Notification extends Component {


//     openNotification = (notificationMessage) => {
        
//         const config = {
//             duration: 4,
//             message: this.props.title,
//             placement: 'bottomRight',
//             bottom: 100,
//             top: 100,
//             description: notificationMessage,
//         };
//         if (this.props.type === 'error') {
//             notification.error(config);
//         } else if (this.props.type === 'success') {
//             notification.success(config);
//         } else {
//             notification.info(config);
//         }


//     };
//     open = () => {
//         if (this.props.message === "Successfully Routed!") {
//             this.openNotification(LocalstorageEncrypt.encryptStorageFunction.getItem("routeMessage"));
//             this.props.stopNotification();
//         } else if (this.props.title) {
//             this.openNotification(this.props.message);
//             this.props.stopNotification();
//         }

//     };

//     render() {

//         return (
//             <div>

//                 {
//                     this.open()
//                 }
//             </div>

//         )
//     }

// }

// export default Notification;




import React, {Component} from 'react';
import {notification} from 'antd';
import 'antd/dist/antd.css';
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";
import Swal from "sweetalert2";
class Notification extends Component {


    openNotification = (notificationMessage) => {
       
        if (this.props.type === 'error') {
            Swal.fire({
         // position: 'bottom-end',
              title: this.props.title,
              text: notificationMessage,
              icon: "error",
              timer: 2500,
              showConfirmButton: false,
            })
        } else if (this.props.type === 'success') {
            Swal.fire({
            //position: 'bottom-end',
                title: this.props.title,
                icon: "success",
                text: notificationMessage,
                timer: 2500,
                showConfirmButton: false,
                // customClass: {
                //     confirmButton: 'sweet-alert-button'
                //   },
            //     showCancelButton: false,
            //    showConfirmButton: false
                
              })
        } else {
          Swal.fire({
            //position: 'bottom-end',
                //title: "Successfull!",
                text: notificationMessage,
                icon: "warning",
                timer: 2500,
                showConfirmButton: false,
                
              })
        }


    };
    open = () => {
        if (this.props.message === "Successfully Routed!") {
            this.openNotification(LocalstorageEncrypt.encryptStorageFunction.getItem("routeMessage"));
            this.props.stopNotification();
        } else if (this.props.title) {
            this.openNotification(this.props.message);
            this.props.stopNotification();
        }

    };

    render() {

        return (
            <div>

                {
                    this.open()
                }
            </div>

        )
    }

}

export default Notification;
