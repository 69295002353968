import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import DashBoardHeader from "../DashBoardHeader";
import Drawer from "@material-ui/core/Drawer";
import {backEndServerURL, frontEndServerURL} from "../../Common/Constant";
import axios from "axios";
import LocalstorageEncrypt from "../JsonForm/LocalstorageEncrypt";

const drawerWidth = 220;

const styles = theme => ({
    root: {
        // display: 'flex',

    },
    drawer: {},
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 1,
        marginLeft: "252px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,

        }),
        /* height: '100vh',
         overflow: 'auto',*/
        //content color
        //backgroundColor: "#f0f0fe"

    },
    contentClose: {
        flexGrow: 1,
        padding: theme.spacing.unit * 1,
        marginLeft: "2px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,

        }),
        /* height: '100vh',
         overflow: 'auto',*/
        //content color
        //backgroundColor: "#f0f0fe"

    },
    drawerPaper: {

        marginTop: "65px",
        paddingBottom: "95px",
    },
    tableContainer: {
        height: 320,


    },
    h5: {
        marginBottom: theme.spacing.unit * 2,

    },
});

class ProtectedLayout extends React.Component {
    state = {
        open: true,
        drawerOpen: true,
        getData: false,
         getUrls: [],
    };

    drawerOpenIndicator = (data) => {
        this.setState({
            drawerOpen: data
        })

    };

    /* renderDrawer = (classes) => {
         if (this.state.drawerOpen===true) {

             return (
                 <Drawer
                     classes={{
                         paper: classes.drawerPaper,

                     }}

                     variant="permanent"

                 >
                     <DashBoardHeader drawerOpenIndicator={this.drawerOpenIndicator} children={this.props.children}/>
                 </Drawer>

             )
         } else {


             return (

                 <DashBoardHeader drawerOpenIndicator={this.drawerOpenIndicator} children={this.props.children}/>

             )

         }
     }*/
    /* checkPermission() {
           let getUrl = window.location.href;
           getUrl = (getUrl.split("#")[1]).toLowerCase();
           let menuList = LocalstorageEncrypt.encryptStorageFunction.getItem("menus");
           if (menuList === null) {
               menuList = [];
           }

           console.log(getUrl)


           if (getUrl === ('/userregistration').toLowerCase() && menuList.includes("User Registration")) {
               return this.props.children;
           }
           else {
               getUrl = getUrl.split("/")[1].toLowerCase();
               if (getUrl === "customer") {
                   return this.props.children;
               } else {
                   return <div></div>;
               }
           }
       }*/

    checkPermission = () => {
        if (this.state.getData) {
            let getUrl = window.location.href;
            getUrl = (getUrl.split("#")[1]).toLowerCase();
            let getPublicUrl = ((window.location.href).split("#/")[1]).toLowerCase();
             getPublicUrl = (getPublicUrl.split("/")[0]).toLowerCase();
            let menuPermissionList = LocalstorageEncrypt.encryptStorageFunction.getItem("menuPermission");
            let menuList = LocalstorageEncrypt.encryptStorageFunction.getItem("menus");
            let allowMenu=false;
            for (var i = 0; i < menuPermissionList.length; i++) {
                if ((((menuPermissionList[i].url).toUpperCase() === getUrl.toUpperCase() && menuList!==undefined &&  menuList.includes((menuPermissionList[i].menu))))) {
                    // return this.props.children;
                    allowMenu=true
                }
                else if ((((menuPermissionList[i].url).toUpperCase() === getPublicUrl.toUpperCase() &&  (menuPermissionList[i].menu).toUpperCase()==="PUBLIC"))) {

                    allowMenu=true
                }
               else if ((((menuPermissionList[i].url).toUpperCase() ==="SPECIAL MENU PERMISSION" &&  (menuPermissionList[i].menu).toUpperCase()==="PUBLIC"))) {
                    allowMenu=true
                }


            }
            if ( allowMenu===true) {
                return this.props.children;
            }
        }
        return this.props.children;

    };


    componentDidMount() {
        if(LocalstorageEncrypt.encryptStorageFunction.getItem("getUrlAndMenuList")===true){
            let countUrl = backEndServerURL + "/getUrlAndMenuList";
            axios.get(countUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    LocalstorageEncrypt.encryptStorageFunction.setItem("getUrlAndMenuList",false);
                    LocalstorageEncrypt.encryptStorageFunction.setItem("menuPermission", response.data);
                      this.setState({
                        getData: true,
                     })
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({
                        getData: true
                    })
                })
        }
        else{
            let pubLicUrl = (window.location.href) === frontEndServerURL + "/Customer/" + LocalstorageEncrypt.encryptStorageFunction.getItem("identity")
            this.setState({
                getData: true,
                pubLicUrl: pubLicUrl,
            })
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div id="protectedLayoutDiv" className={classes.root}>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,

                    }}
                    open={this.state.drawerOpen}
                    variant="permanent"

                >
                    <DashBoardHeader drawerOpenIndicator={this.drawerOpenIndicator} children={this.props.children}/>
                </Drawer>

                <main className={this.state.drawerOpen === true ? classes.content : classes.contentClose}>

                    <div className={classes.appBarSpacer}/>

                    {/* <Typography component="div" className={classes.chartContainer}>

                    </Typography>*/}
                    <br/>

                    <div id="contentHeight" className={classes.tableContainer}>
                        {this.checkPermission()}
                        {/*{this.props.children}*/}
                    </div>


                </main>


            </div>
        )


    }
}

ProtectedLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProtectedLayout);
