import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import "../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Table from "../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import DefferalMaping from "./DefferalMaping";
import DefferalMappingImageReadOnly from "./DefferalMappingImageReadOnly";
import loader from "../../../Static/loader.gif";
import DeferralEditModal from "./DeferralEditModal";
import ErrorModal from "./ErrorModal";
import FDRMaintenanceUtility from "../Maintenance/FDR/MaintenanceUtility";
import MaintenanceUtility from "../Maintenance/static/MaintenanceUtility";
import MyValidation from "../../JsonForm/MyValidation";
import FileMappingReadOnly from "./FileMappingReadOnly";
import VerifyMakerPhoto from "../VerifyMakerPhoto";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const arrayListIndividualCondition = [

    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'AOF5'},
    {label: 'AOF6'},
    {label: 'AOF7'},
    {label: 'AOF8'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'IIF1'},
    {label: 'IIF2'},
    {label: 'NID'},

];
let deferralRemarks = [
    {
        "varName": "deferralRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];
let arrayListIndividual = [
    {label: 'AOF1'},
    {label: 'AOF2'},
    {label: 'AOF3'},
    {label: 'AOF4'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'TP'},
    {label: 'SIGNATURE CARD 1'},
    {label: 'SIGNATURE CARD 2'},
    {label: 'SIGNATURE CARD 3'},
    {label: 'SIGNATURE CARD 4'},
    {label: 'SIGNATURE CARD 5'},
    {label: 'SIGNATURE CARD 6'},
    {label: 'SIGNATURE CARD 7'},
    {label: 'SIGNATURE CARD 8'},
    {label: 'SIGNATURE CARD 9'},
    {label: 'SIGNATURE CARD 10'},
    {label: 'SIGNATURE CARD 11'},
    {label: 'SIGNATURE CARD 12'},
    {label: 'SIGNATURE CARD 13'},
    {label: 'SIGNATURE CARD 14'},
    {label: 'SIGNATURE CARD 15'},
    {label: 'OTHERS'},


];
let commonInformation = [
    {
        "varName": "cbNumber",
        "type": "text",
        "label": "CB Number",
        "grid": 4,
        "readOnly": true,
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 4,
        "readOnly": true,
    },
    {
        "varName": "customerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 4,
        "readOnly": true,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Scan and Upload File",
    "grid": 12
};

class CaseViewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            //alert: true,
            loading: true,
            previewImageForDeferal: false,
            message: "",
            tableData: [],
            file: {},
            data: {},
            getImageData: false,
            deferralEditModal: false,
            arraygetImage: [],
            defferalId: "",
            uploadModal: false,
            uploadImageEditAndReadonly: false,
            getMappingAllImage: false,
            getCaseIdPreviewImage: false,
            notificationMessage: "",
            errorModalBoolean: false,
            errorTittle: "",
            getRemarks: [],
            documentList: [],
            getDocument: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    handleChangeEditDeferralModal = () => {
        this.setState({
            deferralEditModal: true,
        })
    };
    renderEditButton = () => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "CS" || workFlowGroup === "MBU") {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                        width: 113,
                    }}
                    onClick={() => this.handleChangeEditDeferralModal()}>
                    Date Extension
                </button>
            )
        }
    };

    createTableData = (id,  type, deferalCategory, deferalSubCategory,  branch, category, subCategory, dueDate, remarks, approvedBy,deferalDstId, deferalRmCode, status, raiseDate) => {

        return (
            [type, deferalCategory, deferalSubCategory,  branch, category, subCategory, dueDate, remarks, approvedBy,deferalDstId, deferalRmCode, status, raiseDate, [this.renderEditButton()]]

        )

    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };
    renderDeferralList = () => {
        let url = backEndServerURL + "/deferral/infoForClosing/" + this.props.deferralId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableArray = [];
                let upload = response.data;
                console.log(response.data);

                tableArray.push(this.createTableData(upload.id,  upload.type, upload.deferalCategory, upload.deferalSubCategory,  this.props.branch, this.props.category, this.props.subCategory, upload.dueDate, upload.remarks, upload.approvedBy,upload.deferalDstId, upload.deferalRmCode, this.customizeStatus(upload.status),upload.applicationDate));
                this.setState({
                    tableData: tableArray,
                    showValue: true,
                    getData: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            })
    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {
        if (this.state.getData && this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }

    };
    customizeStatus = (status) => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
            if (status === "CLOSE_WAITING") {
                return "Waiting for SD Approval"
            } else if (status === "CLOSE_WAITING_RETURN") {
                return "Return from MOU CHECKER"
            } else if (status === "CLOSE_WAITING_BOM") {
                return "Waiting for MOU MAKER Approval"
            } else if (status === "CLOSE_WAITING_RETURN_BOM") {
                return "Return from MOU MAKER"
            } else if (status === "INACTIVE") {
                return "Closed";
            } else {
                return status;
            }
        } else {
            {


                if (status === "CLOSE_WAITING") {
                    return "Waiting for SD Approval"
                } else if (status === "CLOSE_WAITING_RETURN") {
                    return "Return from Checker"
                } else if (status === "CLOSE_WAITING_BOM") {
                    return "Waiting for BOM Approval"
                } else if (status === "CLOSE_WAITING_RETURN_BOM") {
                    return "Return from BOM"
                } else if (status === "INACTIVE") {
                    return "Closed";
                } else {
                    return status;
                }
            }
        }
    }

    componentDidMount() {

        let fileUrl = backEndServerURL + "/case/files/" + this.props.appId;
        axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(":d");
                console.log(response.data);
                this.setState({
                    documentList: response.data,
                    getDocument: true
                })
            })
            .catch((error) => {
                console.log(error);
            })


        let url = backEndServerURL + '/variables/' + this.props.appId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((responseData) => {
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        console.log(response.data);
                        let remarksArray = [];
                        response.data.map((data) => {
                            if (data.remarks !== 'undefined') {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            }
                        });
                        this.setState({
                            getRemarks: remarksArray
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                let url = backEndServerURL + "/deferral/infoForClosing/" + this.props.deferralId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let tableArray = [];
                        let upload = response.data;
                        let urls = backEndServerURL + "/deferral/expireDateChangeLog/" + this.props.deferralId;
                        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((responseExpireDateLog) => {
                                tableArray.push(this.createTableData(upload.id,  upload.type, upload.deferalCategory, upload.deferalSubCategory,  this.props.branch, this.props.category, this.props.subCategory, upload.dueDate, upload.remarks, upload.approvedBy,upload.deferalDstId, upload.deferalRmCode, this.customizeStatus(upload.status),upload.applicationDate));

                                let inputData = responseData.data;
                                let varValue = responseData.data;
                                inputData.accountNumber = this.props.accountNumber;
                                varValue.accountNumber = this.props.accountNumber;
                                inputData.deferralRemarks = undefined;
                                varValue.deferralRemarks = undefined;
                                this.setState({
                                    tableData: tableArray,
                                    inputData: this.emptyValueRemove(inputData),
                                    varValue: varValue,
                                    showValue: true,
                                    getData: true,
                                    loading: false
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        url = backEndServerURL + /*"/deferral/files/"*/ "/deferral/files/" + this.props.deferralId;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        previewImageForDeferal: true,
                        getImageData: true,
                        arraygetImage: response.data,
                    })
                } else {
                    this.setState({
                        getImageData: true,
                        arraygetImage: response.data,


                    })
                }

            })
            .catch((error) => {

            })

    }

    renderDeferalList = () => {
        if (this.state.getData && (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_MAKER" || LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER")) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"

                            tableHead={["Defferal Type", "Category", "Expire Type","Branch", "Process Category", "Process Sub Category", "Expire date","Remarks", "Approved By" ,"Dst ID" ,"Rm Code", "Status","Deferral Raise Date", "Action"]}
                            // tableHead={["Defferal Type", "Expire date", "Remarks", "Approved By", "Status", "Action"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        } else if (this.state.getData) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"

                            tableHead={["Deferral Doc Type", "Defferal Type", "Category", "Sub Category", "DST ID", "Rm Code", "Process Category", "Process Sub Category", "Deferral Raise Date", "Expire date", "Remarks", "Approved By", "Status", "Action"]}
                            // tableHead={["Defferal Type", "Expire date", "Remarks", "Approved By", "Status", "Action"]}
                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };


    handlePermanentClosed = (event) => {
        event.preventDefault();
        if (this.state.inputData.deferralRemarks !== undefined && this.state.inputData.deferralRemarks !== null) {
            let dependencyField = [];
            dependencyField.push({
                "varName": "deferralRemarks",
                "type": "textArea",
                "required": true,
                "grid": 12
            });

            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }
        }
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");

        this.setState({
            loading: true
        });
        if (this.state.inputData.deferralRemarks !== undefined && this.state.inputData.deferralRemarks !== null) {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.deferralRemarks + "/" + this.props.appId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                    })
                })
        }

        if (workFlowGroup !== undefined) {
            let data = "APPROVED";
            let url = backEndServerURL + "/deferral/forceClosed/" + this.props.deferralId;
            axios.post(url, {data}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.props.closeModal("CLOSED", this.state.inputData, "CLOSED");
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully CloseD!",
                        //alert: true,
                        loading: false,

                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,

                    })
                })
        }

        /* let formData = new FormData();
         formData.append("file", this.state.file[deferralId]);
         formData.append("deferralId", deferralId);

         axios({
             method: 'post',
             url: backEndServerURL + "/deferral/upload/",
             data: formData,
             headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` ,'content-type': 'multipart/form-data'}
         })
             .then((res) => {
                 let url=backEndServerURL+"/deferral/closable";
                 axios.get(url,{headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                     .then((response)=>{
                         console.log(response.data);
                     })
                     .catch((error)=>{
                         console.log(error);
                     })
                 console.log(res)
                 this.setState({
                     notificationMessage: "Deferal Uploaded!!",
                     alert: true
                 })
             })
             .catch((err) => {
                 console.log(err)
             })

 */
    };
    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.inputData.deferralRemarks !== undefined && this.state.inputData.deferralRemarks !== null) {
            let dependencyField = [];
            dependencyField.push({
                "varName": "deferralRemarks",
                "type": "textArea",
                "required": true,
                "grid": 12
            });

            let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
            this.forceUpdate();
            if (error === true) {
                this.setState({
                    loading: false
                });
                return 0;
            }
        }
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (this.state.previewImageForDeferal != true && (workFlowGroup === "CS" || workFlowGroup === "MBU")) {
            this.setState({
                errorModalBoolean: true,
                errorTittle: "File Upload Mandatory !!",
                loading: false
            });

            return 0;
        }
        this.setState({
            loading: true
        });
        if (this.state.inputData.deferralRemarks !== undefined && this.state.inputData.deferralRemarks !== null) {
            let remark_url = backEndServerURL + "/appRemarkSave/" + this.state.inputData.deferralRemarks + "/" + this.props.appId;
            axios.post(remark_url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                    })
                })
        }

        if (workFlowGroup === "CS" || workFlowGroup === "MBU") {
            //this.props.closeModal();
            let data = this.state.inputData;
            let url = backEndServerURL + "/deferral/closeCS/" + this.props.deferralId;
            axios.post(url, {
                deleteList: [],
                appId: this.props.appId,
                fileNames: this.state.inputData
            }, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.setState({
                        notificationMessage: "Forward To BOM!",
                        // alert: true,
                        loader: false,
                    })
                    if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                        this.props.closeModal("MBU", this.state.inputData);
                    } else {
                        this.props.closeModal("CS", this.state.inputData);
                    }

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                    })
                })

        } else if (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER") {
            let data = "APPROVED";
            let url = backEndServerURL + "/deferral/bomApproval/" + this.props.deferralId;
            axios.post(url, {data}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.setState({
                        notificationMessage: "Forward To Checker!",
                        // alert: true,
                        loader: false,
                    })
                    if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                        this.props.closeModal("MOU_MAKER", this.state.inputData);
                    } else {
                        this.props.closeModal("BOM", this.state.inputData, "APPROVED");
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                    })
                })

        } else if (workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER") {
            let data = "APPROVED";
            let url = backEndServerURL + "/deferral/close/" + this.props.deferralId;
            axios.post(url, {data}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                        this.props.closeModal("MOU_CHECKER", this.state.inputData);
                    } else {
                        this.props.closeModal("CHECKER", this.state.inputData, "APPROVED");
                    }
                    this.setState({
                        title: "Successfull!",
                        notificationMessage: "Successfully Close!",
                        //alert: true,
                        loading: false,

                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,

                    })
                })
        }

        /* let formData = new FormData();
         formData.append("file", this.state.file[deferralId]);
         formData.append("deferralId", deferralId);

         axios({
             method: 'post',
             url: backEndServerURL + "/deferral/upload/",
             data: formData,
             headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` ,'content-type': 'multipart/form-data'}
         })
             .then((res) => {
                 let url=backEndServerURL+"/deferral/closable";
                 axios.get(url,{headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }})
                     .then((response)=>{
                         console.log(response.data);
                     })
                     .catch((error)=>{
                         console.log(error);
                     })
                 console.log(res)
                 this.setState({
                     notificationMessage: "Deferal Uploaded!!",
                     alert: true
                 })
             })
             .catch((err) => {
                 console.log(err)
             })

 */
    };
    handleReturn = () => {
        let dependencyField = [];
        dependencyField.push({
            "varName": "deferralRemarks",
            "type": "textArea",
            "required": true,
            "grid": 12
        });

        let error = MyValidation.defaultValidation(this.emptyValueRemove(dependencyField), this.state);
        this.forceUpdate();
        if (error === true) {
            this.setState({
                loading: false
            });
            return 0;
        }
        this.setState({
            loading: true
        });
        let data = "RETURN";
        let user = "CHECKER";
        if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
            user = "MOU_CHECKER";
        }
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        let url = backEndServerURL + "/deferral/close/" + this.props.deferralId;
        if (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER") {
            user = "BOM"
            if (workFlowGroup === "MBU" || workFlowGroup === "MOU_MAKER" || workFlowGroup === "MOU_CHECKER") {
                user = "MOU_MAKER";
            }
            url = backEndServerURL + "/deferral/bomApproval/" + this.props.deferralId;
        }

        axios.post(url, {data}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                // this.close();
                this.setState({
                    title: "Successfull!",
                    notificationMessage: "Forward To CS!",
                    //   alert: true,
                    loading: false,

                })
                this.props.closeModal(user, this.state.inputData, "RETURN");
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,

                })
            })


    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={"Successfull!"}
                              message={this.state.notificationMessage}/>
            )
        }


    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };
    close = () => {
        this.props.closeModal()
    };
    closeIconForHeader = () => {
        this.props.closeIcon()
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, commonInformation, this.updateComponent)
            )
        }
    };
    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeUploadModal = (data) => {

        console.log(data);
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
            getCaseIdPreviewImage: false,
            data: data
        })
    };
    renderUploadButton = () => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if ((workFlowGroup !== "CHECKER" && workFlowGroup !== "MOU_CHECKER")) {
            return (
                <Grid item xs={0}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.uploadModal}

                    >
                        Upload File
                    </button>
                </Grid>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    CaseIdPreviewImage = (event) => {
        event.preventDefault();
        this.setState({
            getCaseIdPreviewImage: true
        })
    };
    handleChangeAssignedImage = (event) => {
        event.preventDefault();
        this.setState({
            uploadImageEditAndReadonly: true,
        })
    };
    renderAssignedImage = () => {
        if (this.state.getData && this.state.previewImageForDeferal) {
            return (
                <Grid item xs={0}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeAssignedImage}

                    >
                        Preview Deferral Document
                    </button>
                </Grid>
            )
        }
        /*  if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "CHECKER") {
              if (this.state.getImageData) {
                  return this.state.arraygetImage.map((data) => {

                      return (
                          <Grid item xs={4}>

                              <h4 style={{color: "white"}} >{data.type}</h4>
                              <img width='90%' src={backEndServerURL + "/file/" + data.fineName} alt=""/>

                              <br/>
                          </Grid>


                      )

                  })

              }*/


    };
    closeModalForImageEditAndReadonly = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            data: data,
            previewImageForDeferal: true,
            loading: false,
        })


    };
    closeModalForImageEditAndReadonlyDeferalType = (data) => {
        this.renderDeferralList();
        this.setState({
            previewImageForDeferal: true,
            deferralEditModal: false,
            loading: false,
        })


    };
    closeIconForeditDeferalType = (data) => {
        this.setState({
            loading: false,
            deferralEditModal: false,
        })


    };
    closeIcon = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            loading: false,
            deferralEditModal: false,
        })


    };

    submitModal = (data) => {
        this.setState({
            uploadImageEditAndReadonly: false,
            uploadModal: false,
            previewImageForDeferal: true,
            getMappingAllImage: false,
            getCaseIdPreviewImage: false,
            uploadSignatureModal: false,
            loading: false,
            deferralEditModal: false,
        })

    };
    renderDeferralEditModal = () => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (workFlowGroup === "CS" || workFlowGroup === "MBU") {
            return (<DeferralEditModal fileUpload={this.state.previewImageForDeferal}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}
                                       deferralId={this.props.deferralId}
                                       backToInbox={this.props.closeModal}
                                       closeIcon={this.closeIconForeditDeferalType}
                                       closeModal={this.closeModalForImageEditAndReadonlyDeferalType}/>

            )
        } else if ((workFlowGroup === "CHECKER") || workFlowGroup === "MOU_CHECKER") {
            return (<DeferralEditModal fileUpload={this.state.previewImageForDeferal}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}
                                       deferralId={this.props.deferralId}
                                       backToInbox={this.props.closeModal}
                                       closeIcon={this.closeIconForeditDeferalType}
                                       closeModal={this.closeModalForImageEditAndReadonlyDeferalType}/>

            )
        }

    };
    renderRemarks = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, deferralRemarks, this.updateComponent)
            )
        }

    };
    renderDefferalImageEditAndReadonly = () => {
        return (<DefferalMappingImageReadOnly subServiceType={this.props.subServiceType} appId={this.props.appId}
                                              deferralId={this.props.deferralId}
                                              closeIcon={this.closeIcon}
                                              closeModal={this.closeModalForImageEditAndReadonly}/>

        )

    };
    renderCaseIdPreviewImage = () => {
        if (this.state.getData) {
            return (
                <Grid item xs={2}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.CaseIdPreviewImage}

                    >
                        Preview Document
                    </button>
                </Grid>
            )
        }
    };
    closeModal = () => {
        this.setState({
            errorModalBoolean: false
        })
    }
    renderSubmitButton = () => {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        onClick={this.handleSubmit}

                    >
                        Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {(workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER") && <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        onClick={this.handleReturn}

                    >
                        Return
                    </button>}
                    <button
                        className="btn btn-outline-primary"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        onClick={this.handlePermanentClosed}

                    >
                        Permanent Closed
                    </button>
                </React.Fragment>

            )
        }
    }
    masterdataToString = (data) => {
        if (data !== null && data !== undefined && data.key !== undefined) {
            return data.value;
        } else {
            return data;
        }
    }
    renderSiCbNumber = () => {
        if (this.props.recpmtid !== undefined && this.props.recpmtid !== null) {
            return (
                <><b> SI CB number:</b>{this.props.recpmtid}</>
            )
        } else {
            return ""

        }
    };
    renderHeaderInformation = () => {
        return (
            <p style={{fontSize: "16px"}}> (<b> Customer
                Name:</b>{this.props.customerName !== undefined ? this.props.customerName : ""}
                <b> A/C:</b>{this.props.accountNumber !== undefined ? this.props.accountNumber : ""}
                <b> CB
                    Number:</b>{this.props.cbNumber !== undefined ? this.props.cbNumber : ""}{this.renderSiCbNumber()}
                <b> SOLID:</b>{this.masterdataToString(this.props.solId)} <b> Scheme
                    Code:</b>{this.props.freeFlag4 !== undefined ? this.props.freeFlag4 : ""}<b> Case
                    ID:</b>{this.props.CaseId !== undefined ? this.props.CaseId : ""} <b> Deferral
                    ID:</b>{this.props.deferralId !== undefined ? this.props.deferralId : ""} )<a><CloseIcon
                    onClick={this.closeIconForHeader} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></p>
        )
    };
    renderTitle = () => {
        if (this.props.freeFlag1 === "FDR Account Opening -Without Operative Account-NTB") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.freeFlag1 === "FDR Account Opening - with Existing CB Only") {
            return (
                <h4 style={{color: "white"}} >
                    <b>Defferal Close</b>{this.renderHeaderInformation()}
                </h4>

            )
        } else if (this.props.serviceType === 'FundTransfer' && this.props.subServiceType === 'NewFundTransfer') {
            return (
                <h4 style={{color: "white"}} >Defferal Close<a><CloseIcon
                    onClick={this.closeIconForHeader} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.serviceType === "Remetance") {
            return (
                <h4 style={{color: "white"}} >Defferal Close<a><CloseIcon onClick={this.closeIconForHeader} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            )
        } else if (this.props.freeFlag1 === "DPS Account Opening -with Existing Operative Account") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b> {this.renderHeaderInformation()}</h4>

            )
        } else if (this.props.subCategory === "Opening Individual") {
            return (
                <h4 style={{color: "white"}} >Defferal Close<a><CloseIcon onClick={this.closeIconForHeader} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.subCategory === "Opening Joint") {
            return (
                <h4 style={{color: "white"}} >Defferal Close<a><CloseIcon onClick={this.closeIconForHeader} style={{
                    position: 'absolute',
                    right: 10,
                    color: "#000000"
                }}/></a></h4>

            );
        } else if (this.props.serviceType === "FDR Opening") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close </b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === "DPS Opening") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}</h4>

            );
        } else if (this.props.serviceType === 'ExistAccountOpening') {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}
                </h4>
            )
        } else if (this.props.serviceType === "Account Opening") {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} ><b>Defferal Close</b>{this.renderHeaderInformation()}</h4>

            )

        }
    };


    inboxCasePhoto = () => {
        return (<VerifyMakerPhoto closeModal={this.props.closeModal} closeIcon={this.props.closeIcon}
                                  customerName={this.props.customerName}
                                  subCategory={this.props.subCategory}
                                  category={this.props.category}
                                  subServiceType={this.props.subServiceType}
                                  caseId={this.props.CaseId}
                                  solId={this.props.solId}
                                  accountNumber={this.props.accountNumber}
                                  cbNumber={this.props.cbNumber}
                                  serviceType={this.props.serviceType}
                                  freeFlag1={this.props.freeFlag1}
                                  freeFlag2={this.props.freeFlag2}
                                  freeFlag3={this.props.freeFlag3} freeFlag4={this.props.freeFlag4}
                                  recpmtid={this.props.recpmtid} customerName={this.props.customerName}
                                  cbNumber={this.props.cbNumber}
                                  accountNumber={this.props.accountNumber}
                                  subServiceType={this.props.subServiceType}
                                  titleName="Maker Update All Information"
                                  documentList={this.state.documentList}/>)


    };


    render() {
        let workFlowGroup = LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup");
        const {classes} = this.props;
        if (workFlowGroup === "BOM" || workFlowGroup === "MOU_MAKER") {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>
                                    {this.inboxCasePhoto()}

                                </Grid>
                                <Grid item xs={5}>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={3}>
                                            {this.renderNotification()}


                                            {this.renderForm()}


                                            <Grid item xs={12}>
                                                {this.renderDeferalList()}

                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderRemarksData()}

                                            </Grid>

                                            <Grid item xs='12'>
                                                {this.renderRemarks()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderSubmitButton()}
                                            </Grid>

                                        </Grid>
                                    </ThemeProvider>

                                </Grid>


                            </Grid>

                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadModal}>
                                <DialogContent>
                                    <DefferalMaping deferralId={this.props.deferralId}
                                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                                                    closeIcon={this.closeIcon}
                                                    closeModal={this.closeModalForImageEditAndReadonly}/>

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadImageEditAndReadonly}>
                                <DialogContent>
                                    {this.renderDefferalImageEditAndReadonly()}
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.getCaseIdPreviewImage}>
                                <DialogContent>
                                    <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeUploadModal}/>

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.deferralEditModal}>
                                <DialogContent>
                                    {this.renderDeferralEditModal()}
                                </DialogContent>
                            </Dialog>

                            <br/>


                        </CardBody>

                    </Card>
                </div>


            )
        } else if ((workFlowGroup === "CHECKER" || workFlowGroup === "MOU_CHECKER")) {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderTitle()}
                        </CardHeader>
                        <CardBody>
                            <Grid container spacing={1}>

                                <Grid item xs={7}>
                                    {this.inboxCasePhoto()}

                                </Grid>
                                <Grid item xs={5}>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <ThemeProvider theme={theme}>
                                        <Grid container spacing={3}>
                                            {this.renderNotification()}


                                            {this.renderForm()}


                                            <Grid item xs={12}>
                                                {this.renderDeferalList()}

                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderRemarksData()}

                                            </Grid>

                                            <Grid item xs='12'>
                                                {this.renderRemarks()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderSubmitButton()}
                                            </Grid>

                                        </Grid>
                                    </ThemeProvider>

                                </Grid>


                            </Grid>

                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadModal}>
                                <DialogContent>
                                    <DefferalMaping deferralId={this.props.deferralId}
                                                    subServiceType={this.props.subServiceType} appId={this.props.appId}
                                                    closeIcon={this.closeIcon}
                                                    closeModal={this.closeModalForImageEditAndReadonly}/>

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.errorModalBoolean}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ErrorModal title={this.state.errorTittle}
                                                closeModal={this.closeModal}
                                                subServiceType={this.props.subServiceType}
                                                appId={this.props.appId}
                                    />

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.uploadImageEditAndReadonly}>
                                <DialogContent>
                                    {this.renderDefferalImageEditAndReadonly()}
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.getCaseIdPreviewImage}>
                                <DialogContent>
                                    <FileMappingReadOnly appId={this.props.appId} closeModal={this.closeUploadModal}/>

                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="xl"
                                open={this.state.deferralEditModal}>
                                <DialogContent>
                                    {this.renderDeferralEditModal()}
                                </DialogContent>
                            </Dialog>

                            <br/>


                        </CardBody>

                    </Card>
                </div>


            )
        } else if (workFlowGroup === "CS" || workFlowGroup === "MBU") {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                {this.renderTitle()}
                                {/*{   <h4 style={{color: "white"}} >Defferal Close <a><CloseIcon onClick={this.closeIconForHeader} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>}*/}
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={3}>
                                        <ThemeProvider theme={theme}>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.loading}>
                                                <DialogContent className={classes.dialogPaper}>

                                                    <center>
                                                        <img src={loader} alt=""/>
                                                    </center>
                                                </DialogContent>
                                            </Dialog>
                                            {this.renderNotification()}
                                            {this.renderForm()}

                                            <Grid item xs={12}>
                                                {this.renderDeferalList()}

                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderRemarksData()}

                                            </Grid>

                                            {this.renderUploadButton()}


                                            {this.renderAssignedImage()}

                                            {this.renderCaseIdPreviewImage()}
                                            <Grid item xs='12'>
                                                {this.renderRemarks()}
                                            </Grid>
                                        </ThemeProvider>


                                    </Grid>
                                </div>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadModal}>
                                    <DialogContent>
                                        <DefferalMaping deferralId={this.props.deferralId}
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                                        closeIcon={this.closeIcon}
                                                        closeModal={this.closeModalForImageEditAndReadonly}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.errorModalBoolean}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <ErrorModal title={this.state.errorTittle}
                                                    closeModal={this.closeModal}
                                                    subServiceType={this.props.subServiceType}
                                                    appId={this.props.appId}
                                        />

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.uploadImageEditAndReadonly}>
                                    <DialogContent>
                                        {this.renderDefferalImageEditAndReadonly()}
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.getCaseIdPreviewImage}>
                                    <DialogContent>
                                        <FileMappingReadOnly appId={this.props.appId}
                                                             closeModal={this.closeUploadModal}/>

                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="xl"
                                    open={this.state.deferralEditModal}>
                                    <DialogContent>
                                        {this.renderDeferralEditModal()}
                                    </DialogContent>
                                </Dialog>

                                <br/>

                                {this.renderSubmitButton()}

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            );
        }


    }
}

export default withStyles(styles)(CaseViewPage);
