import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../../Static/css/RelationShipView.css";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Functions from '../../../../Common/Functions';
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import CloseIcon from '@material-ui/icons/Close';
import Notification from "../../../NotificationMessage/Notification";
import loader from "../../../../Static/loader.gif";
import DynamicFileAttachment from "../Common/DynamicFileAttachment";
import {DescendMarkJsonForm} from "./JsonFormDeceasedMark";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../../Maintenance/SignatureButton";
import CommonApi from "../../Maintenance/common/CommonApi";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import AssetCommonFunctions from "../AssetCommonFunctions";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        root: {
            width: 500,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

let crRemarks = [
    {
        "varName": "crRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }]
;

class CsDeceasedMark extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            setOpen: false,
            loader: null,
            uploadImageModal: false,
            uploadPrintModal: false,
            imageLink: {},
            tableData: [],
            sourceMappingData: [],
            getsearchValue: [],
            getCustomerId: '',
            getAccountType: '',
            accountOpeningFromModal: false,
            newAcoountOpeningModal: false,
            searchTableData: false,
            dataNotFound: false,
            CustomerModal: false,
            uniqueId: '',
            IDENTIFICATION_NO: '',
            id: '',
            anchorEl: null,
            anchorE2: null,
            individualDropdownOpen: null,
            SelectedDropdownSearchData: null,
            csDeferralPage: "",
            getDeferralList: [],
            getDocument: false,
            digitTinChange: false,
            titleChange: false,
            nomineeChange: false,
            updateChangePhotoId: false,
            contactNumberChange: false,
            emailAddressChange: false,
            estatementEnrollment: false,
            addressChange: false,
            otherInformationChange: false,
            signatureCard: false,
            dormantAccountActivation: false,
            dormantAccountDataUpdate: false,
            schemeMaintenanceLinkChange: false,
            schemeMaintenance: false,
            mandateUpdateChange: false,
            cityLive: false,
            projectRelatedDataUpdateADUP: false,
            accountSchemeClose: false,
            lockerSIOpen: false,
            lockerSIClose: false,
            others: false,
            bearerApproval: '',
            csBearer: '',
            // accountNumber:'',

            routeCase: false,
            accountNumberNew: false,
            inputData: {},

            labelOpen: false,
            labelName: "",
            selectImage: "",
            imageModalBoolean: false,
            imgeListLinkSHow: false,
            getImageLink: [],
            getImageBoolean: false,
            SelectedData: false,
            csDeferalPage: "",
            values: [],
            appId: '',
            csDataCapture: '',
            message: "",
            appData: {},
            getData: false,
            getNewCase: false,
            varValue: {},
            caseId: "",
            title: "",
            notificationMessage: "",
            app_uid: "-1",
            alert: false,
            redirectLogin: false,
            type: [],
            dueDate: '',
            selectedDate: {},
            dropdownSearchData: {},
            AddDeferal: false,
            debitCard: "",
            showValue: false,
            getDeferalList: [],
            deferalNeeded: false,
            uploadModal: false,
            getMappingAllImage: false,
            accountDetailsModal: false,
            loaderNeeded: null,
            numberOfCustomer: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            getRemarks: [],
            uploadButtonClick: false,
            returnData: false,
            returnDataBm: false,
            getNumberofNominee: false,
            getNumberofExistingNominee: false,
            objectForNominee: [],
            objectForExistingNominee: [],
            getNominee: false,
            getExistingNominee: false,
            numberOfMultipleSelect: 0,
            croppedImage: false,
            fileUploadData: {},
            submitButtonClick: false,
            cbNotFound: false,
            actionType: "",
            confirmAlert: false,

        }
    }

    componentDidMount() {
        if (this.props.appId !== undefined) {
            CommonApi.getVariablesNew(this.props.appId, assetOpsBackEndServerURL).then(response => {
                let inputData = response.data;
                inputData.crRemarks = undefined;

                CommonApi.getFilesNew(this.props.appId, assetOpsBackEndServerURL).then((fileData) => {
                    CommonApi.getRemarkDetailsNew(this.props.appId, assetOpsBackEndServerURL).then((remarksArray) => {
                        this.setState({
                            varValue: this.copyJson(inputData),
                            inputData: this.copyJson(inputData),
                            appId: this.props.appId,
                            getImageLink: fileData,
                            getImageBoolean: true,
                            imageName: fileData,
                            getRemarks: remarksArray,
                            getData: true,
                            showValue: true,
                            loading: false,
                            submission: true,
                        })
                    }).catch((error) => {
                        console.log(error)
                    });
                }).catch((error) => {
                    console.log(error);
                });
            }).catch(error => {
                console.log(error);
            });
        } else {
            let url = assetOpsBackEndServerURL + "/startCase/cr_case_create_deceased_mark";
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    let inputData = {};
                    inputData.serviceType = "Deceased mark request of retail loan accounts";
                    this.setState({
                        getData: true,
                        showValue: true,
                        varValue: inputData,
                        inputData: inputData,
                        appId: response.data.id,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    copyJson = jsonObject => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };


    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = DescendMarkJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                DescendMarkJsonForm[i].enum = getValue;
            }
        }
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if (varName === "customerCB" && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
        } else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.rateOfpfCBApiCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;
            inputData.casaAccount=undefined;

            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                loading: true,
                submission: false,

            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        inputData: inputData,
                        varValue: this.removeNullValue(inputData),
                        loading: false,
                        showValue: true,
                        submission: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                        submission: false,
                    })
                })
        }

    }

    onKeyDownChange = (data) => {
        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            inputData.cbNumber=this.state.inputData.cbNumber;
            inputData.customerCBApiCall = this.state.inputData.customerCBApiCall;
            inputData.loanAccounts = this.state.inputData.loanAccounts;
            inputData.customerName = this.state.inputData.customerName;
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                loading: false,
                submission: false,
            });
        }
        else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";
            this.setState({
                inputData: inputData,
                varValue: this.removeNullValue(inputData),
                showValue: false,
                submission: false,
                loading: true,
            });

            let lonAccountInfoUrl = assetOpsBackEndServerURL + "/getLoanAccountInfo/" + this.state.inputData[data.varName];
            axios.get(lonAccountInfoUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((loanAccountInfoResponse) => {
                    AssetCommonFunctions.getLoanAccountDetails(this.state.inputData.cbNumber, this.state.inputData[data.varName])
                        .then((loanAccountDetailsResponse) => {
                            inputData.productName = loanAccountDetailsResponse.data.responseData.productName;
                            inputData.casaAccount = loanAccountInfoResponse.data.operAcctId;
                            inputData.acctId = loanAccountInfoResponse.data.acctId;
                            inputData.accountNumber = this.state.inputData[data.varName];
                            inputData.loanOutstanding = loanAccountInfoResponse.data.closingBal;
                            inputData.loanAccountsCBApiCall = "YES";
                            this.setState({
                                inputData: inputData,
                                varValue: this.removeNullValue(inputData),
                                showValue: true,
                                submission: true,
                                loading: false
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                            this.forceUpdate();
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message,
                            })
                        })
                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message,
                    })
                })
        }
    };
    renderForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, DescendMarkJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)
            )
        }
    };
    viewImageModal = (event) => {
        event.preventDefault();

        this.setState({
            selectImage: event.target.value,
            imageModalBoolean: true
        })


    };


    accountDetailsModal = () => {

        this.setState({
            accountDetailsModal: false
        });
        this.closeModal();
    };
    renderImageLink = () => {

        if (this.state.imgeListLinkSHow && this.state.getImageLink !== undefined) {

            return (
                this.state.getImageLink.map((data) => {
                    return (
                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>
                    )
                })

            )


        } else if (this.state.getImageBoolean) {

            return (
                this.state.getImageLink.map((data) => {
                    return (

                        <Grid item={6}>
                            <button type="submit" value={data} onClick={this.viewImageModal}>{data}</button>
                        </Grid>

                    )
                })


            )
        }


    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };


    closeUploadModal = (data) => {
        this.setState({
            uploadButtonClick: true,
            uploadModal: false,
            getMappingAllImage: false,
        })
    };
    closeCroppedModal = () => {
        this.setState({
            croppedImage: false
        })
    };
    handleChangeCroppedImage = (event) => {
        event.preventDefault();
        this.setState({
            croppedImage: true
        })
    };
    renderCroppedImage = () => {
        if (this.state.inputData["signatureCard"] === true) {
            return (
                <Grid item xs={2}>

                    <button
                        style={{
                            marginTop: "18px"
                        }}
                        className="btn btn-outline-primary btn-sm"

                        onClick={this.handleChangeCroppedImage}

                    >
                        Signature Card
                    </button>
                </Grid>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    closeConfirmAlert = (value) => {
        if (value === "ok" && this.state.actionType === "handleSubmit") {
            this.handleSubmit()
        }
        this.setState({
            confirmAlert: false,
        });
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(crRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            confirmAlert: false,
        });
        if (actionType === "handleSubmit") {
            let error = MyValidation.defaultValidation(DescendMarkJsonForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
                title: "Do You want to Confirm?"

            })
        }
    };
    handleSubmitButton = () => {
        if (this.state.getData &&  !this.state.loading && this.state.submission ) {
            return (
                <ThemeProvider>
                    <Grid>
                        <button
                            className="btn btn-outline-primary btn-sm"
                            style={{}}
                            //onClick={this.handleSubmit}
                            onClick={(event) => this.handleCommon(event, "handleSubmit")}
                        >
                            Submit
                        </button>
                    </Grid>
                </ThemeProvider>
            )
        }
    };
    handleSubmit = () => {
        this.setState({
            submitButtonClick: true
        });
    };

    close = () => {
        this.props.closeModal();
    };

    renderRemarks = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, crRemarks, this.updateComponent)
        )

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };
    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: '100%'}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }
    };

    mappingPhoto = () => {
        if (this.state.getImageBoolean) {
            return this.state.getImageLink.map((data, index) => {
                let splitOne = data.split(".");
                let splitTwo = splitOne[0].split("_");
                return (
                    <React.Fragment>
                        <a
                            className="btn btn-outline-primary btn-sm"
                            style={{
                                verticalAlign: "middle",
                                display: "block",
                                marginTop: 20,
                            }}
                            target="_blank"
                            href={assetOpsBackEndServerURL + "/file/" + data + "/" + sessionStorage.getItem("accessToken")}
                        >
                            {splitTwo[1].indexOf("file") > -1 ? `Document Preview ${index + 1}` : splitTwo[1]}
                        </a>
                        &nbsp;&nbsp;
                    </React.Fragment>

                )
            })
        }
    };

    renderDynamicFileUpload = () => {
        if (!this.state.loading) {
            return <DynamicFileAttachment appId={this.state.appId}
                                          submitButtonClick={this.state.submitButtonClick}
                                          functionFoFile={this.functionFoFile}/>
        }
    };

    functionFoFile = (status) => {
        this.setState({
            submitButtonClick: false
        });
        if (status === "success" || status === "No File Found") {
            if (this.state.fileUploadData.loanClosingApplication !== undefined && this.state.fileUploadData.loanClosingApplication !== null) {
                let uploadUrl = assetOpsBackEndServerURL + "/case/upload";
                let types = 'Attachments';
                let files = this.state.fileUploadData.loanClosingApplication; //multiple files
                console.log(files);
                let length = Object.values(this.state.fileUploadData).length;

                let formData = new FormData();
                formData.append("appId", this.state.appId);
                console.log(this.state.fileUploadData);
                console.log(formData);
                console.log(this.state.appId);
                if (Object.values(this.state.fileUploadData).length === 1) {
                    formData.append("file", this.state.fileUploadData['loanClosingApplication']);
                    formData.append("type", "file1");
                }
                axios({
                    method: 'post',
                    url: uploadUrl,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        console.log("image upload");
                        let saveDataDeferralRemarksAndRoute = {};
                        if (this.state.inputData.crRemarks !== undefined && this.state.inputData.crRemarks !== null) {
                            saveDataDeferralRemarksAndRoute.remark = this.state.inputData.crRemarks;
                        }
                        let data = this.state.inputData;
                        //data.cs_deferal = "YES";
                        data.serviceType = "Deceased mark request of retail loan accounts";
                        data.category = "Deceased Mark";
                        data.subCategory = "Deceased Mark";

                        saveDataDeferralRemarksAndRoute.map = data;
                        let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                        axios.post(routeUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,},})
                            .then((response) => {
                                console.log(response);
                                this.setState({
                                    title: "Successful!",
                                    notificationMessage: response.data,
                                    alert: true,
                                    loading: false,
                                    getData: false
                                });

                                if (this.props.appId !== undefined) {
                                    this.props.closeModal();
                                } else {
                                    setTimeout(function () {
                                        window.location.reload()
                                    }, 1000);
                                }

                            })
                            .catch((error) => {
                                console.log(error);
                            });


                    })
                    .catch((error) => {
                        console.log("image error");
                        console.log(error);
                    })
            } else {
                let saveDataDeferralRemarksAndRoute = {};
                if (this.state.inputData.crRemarks !== undefined && this.state.inputData.crRemarks !== null) {
                    saveDataDeferralRemarksAndRoute.remark = this.state.inputData.crRemarks;
                }
                let data = this.state.inputData;
                //data.cs_deferal = "YES";
                data.serviceType = "Deceased mark request of retail loan accounts";
                data.category = "Deceased Mark";
                data.subCategory = "Deceased Mark";
                saveDataDeferralRemarksAndRoute.map = data;
                let routeUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
                axios
                    .post(routeUrl, saveDataDeferralRemarksAndRoute, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        },
                    })
                    .then((response) => {
                        console.log(response);
                        this.setState({
                            title: "Successful!",
                            notificationMessage: response.data,
                            alert: true,
                            loading: false,
                            getData: false
                        });

                        if (this.props.appId !== undefined) {
                            this.props.closeModal();
                        } else {
                            setTimeout(function () {
                                window.location.reload()
                            }, 1000);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
        } else {
            this.setState({
                cbNotFound: true,
                title: "Failed to Upload File."
            })
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b> Deceased mark request of retail loan accounts</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId} <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Deceased mark request of retail loan accounts{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    renderSignature = () => {
        if (this.state.inputData.casaAccount && this.state.getData) {
            return (
                <div>
                    <br/>
                    <SignatureButton accountNumber={this.state.inputData.casaAccount} signatureType="multiple"
                                     classes={this.props}/>
                </div>
            )
        }
    };
    renderFlowSUmmeryButton = () => {
        if (this.state.getData && this.props.appId !== undefined) {
            return (
                <>
                    <Grid item xs={12}><br/></Grid>
                    <FlowSummeryButton cbNumber={this.props.cbNumber} appId={this.props.appId}
                                       caseId={this.props.caseId}
                                       customerName={this.props.customerName} accountNumber={this.props.accountNumber}
                                       serviceType={this.state.inputData.serviceType}
                                       category={this.state.inputData.category}
                                       subCategory={this.state.inputData.subCategory}
                                       solId={this.props.solId}/>
                </>
            )
        }
    };


    render() {
        const {classes} = this.props;
        {
            Functions.redirectToLogin(this.state)
        }
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    {this.renderHeader()}
                </CardHeader>
                <CardBody>
                    {this.renderNotification()}
                    <br/>
                    <ThemeProvider theme={theme}>
                        <Grid container spacing={1}>
                            {this.renderForm()}
                            {this.renderSignature()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.mappingPhoto()}
                            {this.state.getImageBoolean && <Grid item xs={12}></Grid>}
                            {this.renderDynamicFileUpload()}
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.cbNotFound}>
                                <DialogContent className={classes.dialogPaper}>
                                    <CBNotFound
                                        closeModal={this.closeModalCBNotFound}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.confirmAlert}>
                                <DialogContent className={classes.dialogPaper}>
                                    <ConfirmationModal
                                        closeModal={this.closeConfirmAlert}
                                        title={this.state.title}
                                    />
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                fullWidth="true"
                                maxWidth="sm"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.loading}>
                                <DialogContent className={classes.dialogPaper}>
                                    <center>
                                        <img src={loader} alt=""/>
                                    </center>
                                </DialogContent>
                            </Dialog>
                            <Grid item xs={12}></Grid>
                            {this.renderRemarksData()}
                            {this.renderFlowSUmmeryButton()}
                            <Grid item xs={12}></Grid>
                            {this.renderRemarks()}
                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                {this.handleSubmitButton()}
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </CardBody>
            </Card>
        );

    }

}

export default withStyles(styles)(CsDeceasedMark);
