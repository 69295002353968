import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL, frontEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import AssignedCropImage from "../../CASA/AssignedCropImage";
import ImageCrop from "../../CASA/ImageCrop";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ErrorModal from "../../CommonComponent/ErrorModal";
import Label from "./Label";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import GridList from "@material-ui/core/GridList";
import MyValidation from "../../../JsonForm/MyValidation";
import {MAKERJsonFormForCasaIndividual, MAKERJsonFormForCasaIndividualLast} from "../../WorkflowJsonForm4";
import CheckList from "./CheckList";
import ExistingMandateDetails from "./ExistingMandateDetails";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButton";
import SignatureCardDelete from "./SignatureCardDelete";
import {jsonForOnlyLinkedCB} from "./JsonForCasaMaintenance";
import CommonApi from "../common/CommonApi";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "required": true,
        "grid": 12
    }];
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}


];
var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "label": "Upload File",
    "accept": ".pdf",
    "grid": 12
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

let bearer = [
    {
        "type": "empty",
        "grid": 12
    },
    {
        "varName": "csBearer",
        "type": "select",
        "label": "Bearer Status/Customer Visited",
        "enum": [
            "YES",
            "NO"
        ],
        "readOnly": true,
        "grid": 6
    },
    {
        "varName": "bearerApproval",
        "type": "select",
        "label": "Bearer Approval",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "csBearer",
        "conditionalVarValue": "YES",
        "enum": [
            "BM Approval",
            "Call Center Approval",
            "BM & Call Center Approval"

        ]
    }
];

class VerifyMakerAccountMaintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: [],
            showValue: false,
            redirectLogin: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {tagList: {}},
            selectedDate: {},
            values: [],
            getDeferalList: [],
            loading: true,
            objectForNominee: [],
            fileUploadData: {},
            imageCropModal: false,
            errorModalBoolean: false,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            errorMessage: "Invalid RM Code!",
            getMappingCropImage: false,
            nomineeNumber: 0,
            getNominee: false,
            type: "success",
            labelOpen: false,
            labelName: "",
            labelData: [],
            existingObjectForNominee: [],
            existingNominee: false,
            getInitialNewNominee: 0,
            existingRelationCode: "",
            disabled: false,
            oldNomineeData: [],
            employmentTypeList: [],
            // city: [],
            state: [],
            // country: [],
            guardianCode: [],
            relationCode: [],
            oldNominee: false,
            customerDetails: {},
            city: [],
            dispatchMode: [],
            getCity: false,
            stateCode: [],
            getGuardianCode: [],
            savingsSchemeCodeList: [],
            currentSchemeCodeList: [],
            getState: false,
            country: [],
            titleList: [],
            chequebookWaiverType: [],
            rmCodeList: [],
            phoneEmailType: [],
            phoneOrEmail: [],
            getCountry: false,
            currency: [],
            relationshipList: [],
            constitutionList: [],
            waiverFieldList: [],
            ccepCodeList: [],
            introducerStatus: [],
            relationCodeList: [],
            getCurrency: false,
            confirmAlert: false,
            actionType: "",
            despatchmodeList: [],
            agentBankingOutletList: [],
            maritalStatusList: [],
            cbDataAfterSet: false,
            accountDataAfterSet: false,
            kycAutopopulateFlag: false,
            TpAutopopulateFlag: false,
            tradeServiceList: [],
            appData: {},
            SelectedDropdownSearchData: null,
            dropdownSearchData: {},
            customerName: [],
            deferalType: [],
            expireDate: [],
            other: [],
            getCheckerList: [],
            getAllDefferal: [],
            occupationCodeList: [],
            jointAccountCustomerNumber: 0,
            getgenerateForm: false,
            renderCumModalopen: false,
            generateAccountNo: '',
            getDedupData: {},
            jointDedupData: {},
            jointSearchTableData: [],
            propritorshipData: [],
            dedupData: [],
            tagClick: false,
            getTaggingList: {},
            getTaggingCustomerList: {},
            taggingData: [],
            relatedData: {},
            searchTableData: false,
            searchTableRelatedData: false,
            cbGenerateModal: false,
            customerStatusList: [],
            sectorCodeList: [],
            subsectorCodeList: [],
            depositCodeList: [],
            subSegmentList: [],
            checkList: false,
            mandateTableArray: [],
            existingMandateCb: 0,
            mandateModal: false,
            phoneNumCountryCodeList: [],
            documentList: [],
            getDocument: false,
            confirmationAlert: false,
            confirmationAlertTwo: false,
            placeOfIssueList: [],
            despatchList: [],
            linkedCB: false,
            buttonType: "",
            getDownloadListFlag: false,
            downLoadFileList: [],
            getnonResident: [],
            getfreecode7: [],
            fileNotFoundTittle: "",
            signatureDeleteButton: false
        };
    }


    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };
    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>

                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />

                        <br/>
                    </div>

                </div>

            )
        }

    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined" ||
                clone[prop] === "-1"
            )
                delete clone[prop];
        return clone;
    };


    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;

        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }


    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = MAKERJsonFormForCasaIndividual;
        let jsonArrayLast = MAKERJsonFormForCasaIndividualLast;
        let jsonArrayForAll = jsonForOnlyLinkedCB;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                MAKERJsonFormForCasaIndividual[i].enum = getValue;

            }
        }
        for (let i = 0; i < jsonArrayLast.length; i++) {
            let jsonObjectLast = jsonArrayLast[i];
            if (jsonObjectLast.varName === searchVarname) {
                MAKERJsonFormForCasaIndividualLast[i].enum = getValue;

            }
        }
        for (let i = 0; i < jsonArrayForAll.length; i++) {
            let jsonObjectLast = jsonArrayForAll[i];
            if (jsonObjectLast.varName === searchVarname) {
                jsonForOnlyLinkedCB[i].enum = getValue;

            }
        }

    };

    apiDataSet = (data, inputData, accountRelated) => {
        if (data !== undefined && data !== null && data !== "") {

            if (accountRelated === "accountRelated") {
                inputData.misagentBankingOutletFreeCode1 = this.findByVarNameGetKeyValue(data.agentbankingoutlet, this.state.agentBankingOutletList); //acco
                inputData.upDateSchemeCode = data.schemeCode;
                inputData.fincoreDataUpdatecashLimitDr = data.drcashlim;
                inputData.functionCode = "ADD";
                inputData.customerType = "Individual";
                inputData.fincoreDataUpdatecashLimitCr = data.crcashlim;
                inputData.fincoreDataUpdateclearingLimitDr = data.drclrlim;
                inputData.fincoreDataUpdateclearingLimitCr = data.crclrlim;
                inputData.fincoreDataUpdatetransferLimitDr = data.drtxlim;
                inputData.fincoreDataUpdatetransferLimitCr = data.crtxlim;
                inputData.fincoreDataUpdatenotes = data.notes;
                if (inputData.freeFlag2 === "linkedCB") {
                    inputData.miscustomerIdCifId = this.props.cbNumber;
                } else {
                    inputData.miscustomerIdCifId = data.cbNumber;
                }
                inputData.misaCNumber = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.fincoreDataUpdatedispatchMode = data.despatchmode;
                if (data.acctStmtNxtPrintDt !== "") {
                    inputData.fincoreDataUpdatenextPrintDate = data.acctStmtNxtPrintDt;
                } else {
                    let someDate = new Date();
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let newDate = new Date(year, month - 1, date,);
                    if (this.state.inputData["fincoreDataUpdatenextPrintDate"] === undefined) {
                        this.state.selectedDate["fincoreDataUpdatenextPrintDate"] = newDate;
                        inputData.fincoreDataUpdatenextPrintDate = (newDate !== null) ? newDate.toLocaleString('en-US').split(',')[0] : "";
                    }
                }
                if(data.dormchrg!==undefined && data.dormchrg!==null){
                    inputData.fincoreDataUpdatedormantActivationCharge = data.dormchrg;
                }

                inputData.fincoreDataUpdatesectorCode = this.findByVarNameGetKeyValue(data.sectorcode, this.state.sectorCodeList);
                inputData.fincoreDataUpdatesubSectorCode = this.findByVarNameGetKeyValue(data.subsectorcode, this.state.subsectorCodeList);
                inputData.sectorCodeApicall = true;
                inputData.fincoreDataUpdateoccupationCode = data.occupationcode;
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3 = data.rmcode;
                inputData.fincoreDataUpdatedepositCodeFreeCode7 = this.findByVarNameGetKeyValue(data.depositcode, this.state.depositCodeList);
                inputData.miswaiverFieldFreeCode10 = this.findByVarNameGetKeyValue(data.waiverfield, this.state.waiverFieldList);
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.aof1smsAlertFreeText11 = data.smsalert;
                inputData.fincoreDataUpdatenotes = data.notes;
                inputData.schemeCodeChangemodeOfOperation = this.findByVarNameGetKeyValue(data.modeOfOper, this.state.despatchmodeList);
                //kyc
                inputData.customerAccount = data.accountNumber;
                inputData.aCTitle = data.accountName;
                inputData.accountType = data.accountNumber;
                inputData.accountOrReference = data.accountNumber;
                inputData.TpCustomerAccount = data.accountNumber;
                inputData.TpAccountName = data.accountName;
                if (data.schemeType === "SBA") {
                    inputData.accountType = "SAVINGS";
                    inputData.TpAccountType = "SAVINGS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.savingsSchemeCodeList)

                } else {
                    inputData.accountType = "CURRENT";
                    inputData.TpAccountType = "CURRENTS";
                    this.findByVarNameApiData("schemeCodeChangetargetSchemeCodeInCaseOfInstaPack", this.state.currentSchemeCodeList)
                }

                inputData.TpAccountNumber = data.accountNumber;
                if (data.acctStmtMode === "S") {
                    inputData.fincoreDataUpdateaccountStatement = "S-STATEMENT";
                    // inputData.fincoreDataUpdatestatementFrequency1 = "Half Yearly";
                    // inputData.fincoreDataUpdatestatementFrequency2 = "30";
                    // inputData.fincoreDataUpdatestatementFrequency3 = "Next Day";
                } else {
                    inputData.fincoreDataUpdateaccountStatement = "NONE"
                }

                // if (inputData.eStatementEnrollment === true) {
                //     let es = inputData.eStatementFacility === "YES" ? "E" : "N";
                //     inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                // } else {
                    inputData.aof1despatchModePrintedEStatementBoth = this.findByVarNameGetKeyValue(data.despatchmode, this.state.despatchList)
                // }

            } else if (accountRelated === "cbExtraInformation") {
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.introducercifType = data.intrd_cif_type;
              //  inputData.mispriorityCenterCodeFreeCode1 = data.freecode1;
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.freecode3;
                inputData.misprimaryCbHolderForPriorityFreeText8 = data.free_text_8;
                inputData.misrelationshipWithPrimaryCbHolderFreeText9 = data.free_text_9;
                inputData.mismigratedNidVerificationFreeText2 = data.free_text_2;
                inputData.mismigratedSmartCardNumberFreeText5 = data.free_text_5;
                inputData.mispepStatusFreeText10 = data.free_text_11;
                inputData.mismigratedCifsDocumentNoFreeText13 = data.free_text_13;
                inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.trade_services_availed, this.state.tradeServiceList);                   //
                inputData.primryintroducerCustomerIdCifId = data.intrd_cif_id;
                inputData.primryicifTypePrimaryIntroducerDetails = data.cif_type;
                inputData.chairmanCertificateplaceOfBirthFreeText14 = data.free_text_14;
                inputData.chairmanCertificatecountryOfBirthFreeText15 = data.free_text_15;
                inputData.employerDatamonthlyIncomeGrossIncome = data.gross_income;
                inputData.customerProbableMonthlyIncome = data.gross_income;
                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.customer_nre_flg, this.state.getnonResident);  //
                inputData.chairmanCertificateturnedNonResidentOn = data.date_of_becoming_nre
            } else if (accountRelated === "Dormant") {
                if (data.acctStatus === "A") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "A",
                        "value": "Active"
                    }
                }
                if (data.acctStatus === "D") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "D",
                        "value": "Dormant"
                    }
                }
                if (data.acctStatus === "I") {
                    inputData.fincoreDataUpdateaCStatus = {
                        "masterDataName": "accountStatus",
                        "key": "I",
                        "value": "Inactive"
                    }

                }
            }
            else {
                inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
                inputData.passportNumberpassportNumberUniqueIdApiCall = true;
                inputData.nationalIdCardNumberUniqueIdApiCall = true;
                inputData.birthCertificatebirthCertificateNumberUniqueIdApiCall = true;
                inputData.electronicTaxIdApiCall = true;
                inputData.misbbkSegmentCodeFreeCode7 = this.findByVarNameGetKeyValue(data.bBKSegCode, this.state.getfreecode7);
                inputData.misoccupationCodePriorityCodeStaff = data.occupationcode;
                inputData.mispriorityCenterCodeFreeCode1 = data.priorityCenterCode
                inputData.missolId = data.branchCode;
                inputData.misstaffIdNumber = data.staffEmployeeID;
                inputData.misstaffIndicatorStatus = data.staffFlag;
                inputData.misccepCodeFreeCode9 = this.findByVarNameGetKeyValue(data.ccepcode, this.state.ccepCodeList);
                inputData.misprimarySolId = data.branchCode;
                inputData.basicInformationUpdateprimarySolId = data.branchCode;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.miscustomerNameLastName = data.customerName;
                inputData.misshortName = data.shortName;
                inputData.misaCNumber = data.accountNumber;
                inputData.customerAccount = data.accountNumber;
                inputData.crmMissubSegment = this.findByVarNameGetKeyValue(data.subSegment, this.state.subSegmentList);
                inputData.mistitle = this.findByVarNameGetKeyValue(data.salutation, this.state.titleList);
                inputData.aCTitle = data.salutation;
                inputData.miscustomerStatusNormalStaffPriorityEtc = this.findByVarNameGetKeyValue(data.custStatus, this.state.customerStatusList);
                inputData.misconstitution = this.findByVarNameGetKeyValue(data.constitutionCode, this.state.constitutionList);
                inputData.mispriorityMonitoringRmCodeFreeCode3 = data.monitoringRMCode;
                // inputData.misfunctionCodeForChequeBookWaiver = "A";
                inputData.aof1currencyCodeCcy = data.currency;
                 inputData.aof1smsAlertFreeText11 = this.findByVarNameGetKeyValue(data.currencyCode, this.state.currency);
                inputData.primryiintroducerStatus = this.findByVarNameGetKeyValue(data.statusOfIntroducer, this.state.introducerStatus);
                inputData.schemeCodeChangefunctionForSchemeChangeInCaseOfInstaPack = "T";

                inputData.basicInformationUpdategender = data.gender;
                inputData.basicInformationUpdatefatherNamePrefferedName = data.fatherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemotherNameMothersMaidenName = data.motherName;
                inputData.basicInformationUpdatemaritalStatus = this.findByVarNameGetKeyValue(data.maritalStatus, this.state.maritalStatusList);
                inputData.basicInformationUpdatespouseNamePurgeRemarks = data.spouseName;
                let birthDate = data.birthDate;
                if (birthDate && birthDate !== -1) {
                    let date = (birthDate).split('T')[0].split("-");
                    inputData.basicInformationUpdatedob = `${date[1]}/${date[2]}/${date[0]}`;
                    setTimeout(() => {
                        let x = 18;
                        var currentDate = new Date();

                        let someDate = new Date();
                        let numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(birthDate);

                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "N"

                            //varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                        } else {
                            inputData.basicInformationUpdatedobApiCall = "YES";
                            inputData.basicInformationUpdateminorStatusMinorIndicator = "Y"
                            //this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                        }
                        x = 60;
                        currentDate = new Date();
                        someDate = new Date();
                        numberOfDaysToAdd = x;
                        someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                        date = someDate.getDate();
                        month = someDate.getMonth() + 1;
                        year = someDate.getFullYear();
                        convertDate = year + "-" + month + "-" + date;
                        newDate = new Date(convertDate);
                        stringToDate = new Date(newDate.toDateString());

                        inputDate = new Date(birthDate);
                        if (stringToDate >= inputDate) {
                            inputData.basicInformationUpdateseniorCitizenIndicator = "Y"


                        } else {

                            inputData.basicInformationUpdateseniorCitizenIndicator = "N"
                        }
                    }, 1000)
                }
                inputData.basicInformationUpdateminorStatusMinorIndicator = data.isMinor;
                inputData.basicInformationUpdateseniorCitizenIndicator = data.seniorCitizen;
                inputData.fincoreDataUpdatefreeText10 = data.waiverfield;

                inputData.primryintroducerCustomerIdCifId = data.introducerCifId;
                inputData.primryiintroducerNameLastName = data.nameOfIntroducer;
                inputData.introducerIdApiCall = true;
                inputData.drivingLicensedocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.drivingLicensedocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.residentdocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.residentdocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.natioanlIdCarddocumentTypeDescriptionN = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.passportNumberdocumentTypeDescriptionP = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.smartIdCarddocumentTypeS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.smartIdCarddocumentTypeDescriptionS = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };


                inputData.birthCertificatedocumentTypeB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.birthCertificatedocumentTypeDescriptionB = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.chairmanCertificatedocumentTypeCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };
                inputData.chairmanCertificatedocumentTypeDescriptionCC = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };


                inputData.etindocumentType = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.etindocumentTypeDescription = {
                    "masterDataName": "documentCodeDocumentType",
                    "key": "IDENTIFICATION PROOF",
                    "value": "IDENTIFICATION PROOF"
                };

                inputData.contactNumberEmailpreferedEmailIdType = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailemailType1 = {
                    "masterDataName": "emailType",
                    "key": "COMMEML",
                    "value": "COMMEML"
                };
                inputData.contactNumberEmailphoneNoEmailId4 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };
                inputData.contactNumberpreferredContactNoType1 = {
                    "masterDataName": "contactType",
                    "key": "COMMPH1",
                    "value": "COMMPH1"
                };

                inputData.contactNumberphoneNoEmailId1 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId2 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };

                inputData.contactNumberphoneNoEmailId3 = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "PHONE",
                    "value": "PHONE"
                };
                inputData.contactNumberEmailemailType = {
                    "masterDataName": "emailType",
                    "key": "HOMEEML",
                    "value": "HOMEEML"
                };
                inputData.contactNumberEmailphoneNoEmailId = {
                    "masterDataName": "phoneOrEmailId",
                    "key": "EMAIL",
                    "value": "EMAIL"
                };

                inputData.maillingAdresspreferredAddressType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.maillingAdressaddrssType = {
                    "masterDataName": "addressType",
                    "key": "Mailing",
                    "value": "Mailing"
                };
                inputData.homeAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };


                inputData.workAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };


                inputData.presentAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddressFormat = {
                    "masterDataName": "addressFormat",
                    "key": "FREE_TEXT_FORMAT",
                    "value": "FREE_TEXT_FORMAT"
                };
                inputData.nreAddressaddrssType =
                    {
                        "masterDataName": "addressType",
                        "key": "NRERelative",
                        "value": "NRERelative"
                    };


                if (data.RESIDBLOCK !== undefined) {
                    inputData.residentdocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "RESID",
                        "value": "RESIDENT ID CARD"
                    };

                    inputData.existingResidentId = data.RESIDBLOCK.referenceNum;
                    inputData.residentNumberUniqueId = data.RESIDBLOCK.referenceNum;
                } else {
                    //inputData.residentdocumentCodeN = null;
                }


                if (data.DLBLOCK !== undefined) {
                    inputData.drivingLicensedocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "DL",
                        "value": "DRIVING LICENSE"
                    };
                    inputData.existingDrivingLicense = data.DLBLOCK.referenceNum;
                    inputData.drivingLicenseNumberUniqueId = data.DLBLOCK.referenceNum;
                } else {
                   // inputData.drivingLicensedocumentCodeN = null;
                }

                if (data.BCBLOCK !== undefined) {
                    inputData.birthCertificatedocumentCodeB = {
                        "masterDataName": "documentCode",
                        "key": "BC",
                        "value": "BIRTH CERTIFICATE"
                    };
                    inputData.birthCertificatebirthCertificateNumberUniqueId = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                    inputData.birthCertificateNo = data.BCBLOCK.referenceNum;
                } else {
                    //inputData.birthCertificatedocumentCodeB = null;
                }


                if (data.chairmanCertificate !== undefined) {
                    inputData.chairmanCertificatedocumentCodeCC = {
                        "masterDataName": "documentCode",
                        "key": "CHAC",
                        "value": "CHAIRMAN CERTIFICATE"
                    };
                    inputData.chairmanCertificateUniqueId = data.chairmanCertificate;
                } else {
                    //inputData.chairmanCertificatedocumentCodeCC = null;
                }

                if (data.PASSPORTBLOCK !== undefined) {
                    inputData.passportNumberdocumentCodeP = {
                        "masterDataName": "documentCode",
                        "key": "PASSPORT",
                        "value": "PASSPORT IDENTITY"
                    };
                    inputData.passportNumberpassportNumberUniqueId = data.PASSPORTBLOCK.referenceNum;
                    inputData.passportNo = data.PASSPORTBLOCK.referenceNum;

                    inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.placeOfIssue, this.state.placeOfIssueList);
                    let passportNumberissueDate = data.PASSPORTBLOCK.issueDt;
                    if (passportNumberissueDate && passportNumberissueDate !== -1) {
                        let date = (passportNumberissueDate).split('T')[0].split("-");
                        inputData.passportNumberissueDate = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.passportNumbercountryOfIssue = this.findByVarNameGetKeyValue(data.PASSPORTBLOCK.countryOfIssue, this.state.country);
                    inputData.passportNumberexpiryDate = data.PASSPORTBLOCK.expireDt;
                } else {
                   // inputData.passportNumberdocumentCodeP = null
                }

                if (data.ETINBLOCK !== undefined) {
                    inputData.etindocumentCode = {
                        "masterDataName": "documentCode",
                        "key": "ETIN",
                        "value": "TIN CARD IDENTITY"
                    };
                    inputData.etineTinNumber = data.ETINBLOCK.referenceNum;
                    inputData.electronicTaxId = data.ETINBLOCK.referenceNum;
                } else {
                   // inputData.etindocumentCode = null
                }


                inputData.Customersnationality = this.findByVarNameGetKeyValue(data.nationality, this.state.country);
                if (data.NIDBLOCK !== undefined) {
                    inputData.natioanlIdCarddocumentCodeN = {
                        "masterDataName": "documentCode",
                        "key": "NID",
                        "value": "NID CARD IDENTITY"
                    };
                    inputData.nationalIdCardNumberUniqueId = data.NIDBLOCK.referenceNum;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = data.NIDBLOCK.isDocumentVerified;
                    inputData.nationalIdCardNo = data.NIDBLOCK.referenceNum;
                    let natioanlIdCardissueDateN = data.NIDBLOCK.issueDt;
                    if (natioanlIdCardissueDateN && natioanlIdCardissueDateN !== -1) {
                        let date = (natioanlIdCardissueDateN).split('T')[0].split("-");
                        inputData.natioanlIdCardissueDateN = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                  //  inputData.natioanlIdCarddocumentCodeN = null;
                }

                if (data.RSNIDBLOCK !== undefined) {
                    inputData.smartIdCarddocumentCodeS = {
                        "masterDataName": "documentCode",
                        "key": "RSNID",
                        "value": "RSNID"
                    };
                    inputData.smartCardNumberUniqueId = data.RSNIDBLOCK.referenceNum;
                    let smartCardissueDateN = data.RSNIDBLOCK.issueDt;
                    if (smartCardissueDateN && smartCardissueDateN !== -1) {
                        let date = (smartCardissueDateN).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                    inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.RSNIDBLOCK.isDocumentVerified;

                    let smartIdCardissueDateS = data.RSNIDBLOCK.issueDt;
                    if (smartIdCardissueDateS && smartIdCardissueDateS !== -1) {
                        let date = (smartIdCardissueDateS).split('T')[0].split("-");
                        inputData.smartIdCardissueDateS = `${date[1]}/${date[2]}/${date[0]}`
                    }
                } else {
                   // inputData.smartIdCarddocumentCodeS = null;
                }


                if (data.WORKPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType3 = {
                        "masterDataName": "contactType",
                        "key": "WORKPH1",
                        "value": "WORKPH1"
                    };
                    inputData.existingOfficeNumber = data.WORKPH1BLOCK.phoneNumLocalCode;
                    inputData.contactNumberphoneNo3 = data.WORKPH1BLOCK.phoneNumLocalCode;
                    if (data.WORKPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumbercontactType3 = "Local";
                    } else {
                        inputData.mobileTypecontactNumbercontactType3 = "Overseas";
                        inputData.countryCodecontactNumberphoneNo3 = this.findByVarNameGetKeyValue(data.WORKPH1BLOCK.phoneNumCountryCode, this.state.phoneNumCountryCodeList)

                    }
                } else {
                  //  inputData.contactNumbercontactType3 = null;
                }

                if (data.COMMPH1BLOCK !== undefined) {
                    inputData.contactNumbercontactType1 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH1",
                        "value": "COMMPH1"
                    };
                    inputData.contactNumberphoneNo1 = data.COMMPH1BLOCK.phoneNumLocalCode;
                    if (data.COMMPH1BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo1 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo1 = "Overseas";
                    }
                } else {
                    //inputData.contactNumbercontactType1 = null;
                }

                if (data.COMMPH2BLOCK !== undefined) {
                    inputData.contactNumbercontactType2 = {
                        "masterDataName": "contactType",
                        "key": "COMMPH2",
                        "value": "COMMPH2"
                    };
                    inputData.contactNumberphoneNo2 = data.COMMPH2BLOCK.phoneNumLocalCode;
                    if (data.COMMPH2BLOCK.phoneNumCountryCode === "88") {
                        inputData.mobileTypecontactNumberphoneNo2 = "Local";
                    } else {
                        inputData.mobileTypecontactNumberphoneNo2 = "Overseas";
                    }
                } else {
                   // inputData.contactNumbercontactType2 = null;
                }


                if (data.FutureOnSiteBLOCK !== undefined) {
                    inputData.presentAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Future/OnSite",
                        "value": "Future/OnSite"
                    };
                    inputData.presentAddresspresentAddressField1 = data.FutureOnSiteBLOCK.addrLine1;
                    inputData.presentAddresspresentAddressField2 = data.FutureOnSiteBLOCK.addrLine2;
                    inputData.presentAddresscity = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.city, this.state.city);
                    inputData.presentAddressstate = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.state, this.state.stateCode);
                    inputData.presentAddresspostalCode = data.FutureOnSiteBLOCK.postalCode;
                    inputData.presentAddresscountry = this.findByVarNameGetKeyValue(data.FutureOnSiteBLOCK.country, this.state.country);
                } else {
                   // inputData.presentAddressaddrssType = null
                }


                if (data.HomeBLOCK !== undefined) {
                    inputData.homeAddressaddrssType = {
                        "masterDataName": "addressType",
                        "key": "Home",
                        "value": "Home"
                    };
                    inputData.homeAddresshomeAddressField1 = data.HomeBLOCK.addrLine1;
                    inputData.homeAddresshomeAddressField2 = data.HomeBLOCK.addrLine2;
                    inputData.homeAddresscity = this.findByVarNameGetKeyValue(data.HomeBLOCK.city, this.state.city);

                    inputData.homeAddressstate = this.findByVarNameGetKeyValue(data.HomeBLOCK.state, this.state.stateCode);
                    inputData.homeAddresspostalCode = data.HomeBLOCK.postalCode;
                    inputData.homeAddresscountry = this.findByVarNameGetKeyValue(data.HomeBLOCK.country, this.state.country);
                } else {
                   // inputData.homeAddressaddrssType = null
                }
                if (data.WorkBLOCK !== undefined) {
                    inputData.workAddressaddressType = {
                        "masterDataName": "addressType",
                        "key": "Work",
                        "value": "Work"
                    };
                    inputData.workAddressworkAddressField1 = data.WorkBLOCK.addrLine1;
                    inputData.workAddressworkAddressField2 = data.WorkBLOCK.addrLine2;
                    inputData.workAddresscity = this.findByVarNameGetKeyValue(data.WorkBLOCK.city, this.state.city);
                    inputData.workAddressstate = this.findByVarNameGetKeyValue(data.WorkBLOCK.state, this.state.stateCode);
                    inputData.workAddresspostalCode = data.WorkBLOCK.postalCode;
                    inputData.workAddresscountry = this.findByVarNameGetKeyValue(data.WorkBLOCK.country, this.state.country);
                } else {
                   // inputData.workAddressaddressType = null
                }

                if (data.MailingBLOCK !== undefined) {
                    inputData.maillingAdressmailingAddressField1 = data.MailingBLOCK.addrLine1;
                    inputData.maillingAdressmailingAddressField2 = data.MailingBLOCK.addrLine2;
                    inputData.maillingAdresscity = this.findByVarNameGetKeyValue(data.MailingBLOCK.city, this.state.city);
                    inputData.maillingAdressstate = this.findByVarNameGetKeyValue(data.MailingBLOCK.state, this.state.stateCode);
                    inputData.maillingAdresspostalCode = data.MailingBLOCK.postalCode;
                    inputData.maillingAdresscountry = this.findByVarNameGetKeyValue(data.MailingBLOCK.country, this.state.country);

                }

                inputData.chairmanCertificatenonResident = this.findByVarNameGetKeyValue(data.isNRE, this.state.getnonResident);
                if (data.NRERelativeBLOCK !== undefined) {
                    inputData.nreAddressnreAddressField1 = data.NRERelativeBLOCK.addrLine1;
                    inputData.nreAddressnreAddressField2 = data.NRERelativeBLOCK.addrLine2;
                    inputData.nreAddresscity = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.city, this.state.city);
                    inputData.nreAddressstate = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.state, this.state.stateCode);
                    inputData.nreAddresspostalCode = data.NRERelativeBLOCK.postalCode;
                    inputData.nreAddresscountry = this.findByVarNameGetKeyValue(data.NRERelativeBLOCK.country, this.state.country);
                }

                if (data.nameOfEmployer !== undefined) {
                    inputData.employerDataprofessionEmploymentType = this.findByVarNameGetKeyValue(data.employmentStatus, this.state.employmentTypeList);
                    inputData.employerDatanameOfEmployerEmployerName = data.nameOfEmployer;
                }

                if (data.COMMEMLBLOCK !== undefined) {
                    inputData.contactNumberEmaildesignatedEmailAddressEmailId1 = data.COMMEMLBLOCK.emailInfo
                }

                if (data.HOMEEMLBLOCK !== undefined) {
                    inputData.contactNumberEmailemailId2 = data.HOMEEMLBLOCK.emailInfo;
                }

            }
        }
        return inputData;
    };


    createDownloadFileLink = (sl, data) => {
        return (
            <React.Fragment>
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(data)}
                >
                    {sl}
                </button>
                &nbsp;&nbsp;
            </React.Fragment>

        )
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    componentDidMount() {
        let jsonForm = MAKERJsonFormForCasaIndividual.concat(MAKERJsonFormForCasaIndividualLast);
        for (let item of jsonForm) {
            if (item.varName === "schemeCodeChangemodeOfOperation" || item.varName === "aof1despatchModePrintedEStatementBoth" || item.varName === "aof1smsAlertFreeText11" || item.varName === "nidVerified") {
                item.required = false
            }
        }
        if (this.props.appId !== undefined) {
            let downLoadFileList = [];
            let fileUrl = backEndServerURL + "/case/files/pdfFilesFromImages/" + this.props.appId;
            axios.get(fileUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    response.data.map((data, index) => {
                        downLoadFileList.push(this.createDownloadFileLink(`Document ${index + 1}`, data))
                    });
                    this.setState({
                        getDownloadListFlag: true,
                        downLoadFileList: downLoadFileList
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        fileNotFoundTittle: error.response.data.message,
                    })
                });
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        linkedCB: response.data.freeFlag2 === "linkedCB"
                    });
                    if (response.status === 200) {
                        let inputData = response.data;
                        inputData["newCurrency"] = null;
                        inputData["eTinDeleteFlag"] = "N";
                        inputData["nidOnlyDeleteFlag"] = "N";
                        inputData["nidDeleteFlag"] = "N";
                        inputData["emailId1DeleteFlag"] = "N";
                        inputData["emailId2DeleteFlag"] = "N";
                        inputData["presentAddressDeleteFlag"] = "N";
                        inputData["workAddressDeleteFlag"] = "N";
                        inputData["homeAddressDeleteFlag"] = "N";
                        inputData["nreAddressDeleteFlag"] = "N";
                        inputData["passportDeleteFlag"] = "N";
                        inputData["birthCertificateDeleteFlag"] = "N";
                        inputData["chairmanCertificateDeleteFlag"] = "N";
                        inputData["drivingLicenseDeleteFlag"] = "N";
                        inputData["residentIdDeleteFlag"] = "N";
                        inputData["PhoneNumber2DeleteFlag"] = "N";
                        inputData["PhoneNumber3DeleteFlag"] = "N";
                        if (inputData.titleChange === true || inputData.nomineeChange === true || inputData.photoIdChange === true || inputData.otherContactNumberChange === true || inputData.addressChange === true || inputData.otherInformationChange === true || inputData.signatureCard === true || inputData.onlyDormantAccountActivation === true || inputData.dormantAccountActivation === true || inputData.projectRelatedDataUpdateADUP === true || inputData.eStatementEnrollment === true || inputData.customerStatusUpdate === true) {
                            inputData.nomineeChange = true;
                        }

                        if (inputData.nomineeChange === true && parseInt(inputData.nomineeNumberOld)) {
                            let sl;
                            let objectForExistingNominee = [];
                            for (let i = 0; i < parseInt(inputData.nomineeNumberOld); i++) {
                                sl = i + 1;
                                inputData["deleteOldNominee" + sl] = response.data["deleteOldNominee" + sl] ? response.data["deleteOldNominee" + sl] : "N";
                                let cifIdExist = inputData["oldNomineecifIdExist" + sl];
                                inputData["nomineecifIdApiCall" + sl] = true;
                                // let cifIdExist = false;
                                let x = 18;
                                let someDate = new Date();
                                someDate.setFullYear(someDate.getFullYear() - x);
                                let date = someDate.getDate();
                                let month = someDate.getMonth() + 1;
                                let year = someDate.getFullYear();
                                let convertDate = year + "-" + month + "-" + date;
                                let newDate = new Date(convertDate);
                                let stringToDate = new Date(newDate.toDateString());
                                let inputDate = new Date(inputData["nomineeDob" + sl]);
                                if ((stringToDate >= inputDate) || inputData["nomineeDob" + sl] === "null") {
                                    inputData["nomineeDobApiCall" + sl] = false;
                                } else {
                                    inputData["nomineeDobApiCall" + sl] = true;
                                }
                                objectForExistingNominee.push(
                                    {
                                        "varName": "Nominee " + i,
                                        "type": "title",
                                        "label": "Nominee  " + sl,
                                        "grid": 12,
                                        "readOnly": true,
                                        "conditional": true,
                                        "conditionalVarName": "nomineeChange",
                                        "conditionalVarValue": true
                                    },
                                    {
                                        "varName": "nomineecifId" + sl,
                                        "type": "textApiCall",
                                        "label": "CIF ID/Individual Id",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditional": true,
                                        "conditionalVarName": "nomineeChange",
                                        "conditionalVarValue": true
                                    },
                                    {
                                        "varName": "nomineeName" + sl,
                                        "type": "text",
                                        "validation": "string",
                                        "label": "Nominee Name",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "required": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeRelationship" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enum": [],
                                        "enumType": "relationCode",
                                        "label": "Relationship",
                                        "grid": 6,
                                        // "readOnly": cifIdExist,
                                        "required": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "percentageNominee" + sl,
                                        "type": "text",
                                        "label": "Percentage %",
                                        "grid": 6,
                                        // "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeAddress1" + sl,
                                        "type": "text",
                                        "label": "Address Field 1",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "required": true,
                                        "multiline": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeAddress2" + sl,
                                        "type": "text",
                                        "label": "Address Field 2",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "multiline": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "label": "City",
                                        "grid": 6,
                                        "required": true,
                                        "readOnly": cifIdExist,
                                        "enumType": "city",
                                        "enum": [],
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "label": "State",
                                        "grid": 6,
                                        "required": true,
                                        "readOnly": cifIdExist,
                                        "enumType": "state",
                                        "enum": [],
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineePostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "required": true,
                                        "multiline": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeCountry" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "label": "Country",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "required": true,
                                        "enumType": "country",
                                        "enum": [],
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    // {
                                    //     "varName": "nomineenomineephotoIdNo" + i,
                                    //     "type": "text",
                                    //     "label": "NOMINEE PHOTO ID NO.",
                                    //     "grid": 6,
                                    //     "multiline": true,
                                    //     "conditional": true,
                                    //     "conditionalVarName": "nomineeChange",
                                    //     "conditionalVarValue": true
                                    // },
                                    {
                                        "varName": "photoIdNumberRegNo" + sl,
                                        "type": "text",
                                        "label": "Photo Id No/Registration No.",
                                        "grid": 6,
                                        // "readOnly": cifIdExist,
                                        "multiline": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeDob" + sl,
                                        "type": "date",
                                        "label": "D.O.B",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "onKeyDown": true,
                                        "maxDate": true,
                                        // "required": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "deleteOldNominee" + sl,
                                        "type": "select",
                                        "enum": ["Y", "N"],
                                        "label": "Delete This Nominee",
                                        "required": true,
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": [true, true]
                                    },
                                    {
                                        "varName": "nomineeMinor" + sl,
                                        "type": "select",
                                        "label": "Nominee Minor",
                                        "enum": ["YES", "NO"],
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, true],
                                    },
                                    {
                                        "type": "title",
                                        "label": "Guardian Details  " + sl,
                                        "grid": 12,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                    },
                                    {
                                        "varName": "nomineeGuardiansName" + sl,
                                        "type": "text",
                                        "label": "Guardians Name",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "multiline": true,
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "guardianCode",
                                        "enum": [],
                                        "label": "Guardian Code",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "required": true,

                                    },
                                    {
                                        "varName": "guardianPhotoId" + sl,
                                        "type": "text",
                                        "label": "Guardian Photo Id",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                    },

                                    {
                                        "varName": "nomineeGuardianAddress" + sl,
                                        "type": "text",
                                        "label": "Address 1",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "multiline": true,
                                        "required": true,
                                    },
                                    {
                                        "varName": "nomineeGuardianAddress2" + sl,
                                        "type": "text",
                                        "label": "Address 2",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "multiline": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "city",
                                        "enum": [],
                                        "readOnly": cifIdExist,
                                        "label": "City Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "state",
                                        "enum": [],
                                        "label": "State Code",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianPostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        "readOnly": cifIdExist,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCountry2" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "country",
                                        "enum": [],
                                        "readOnly": cifIdExist,
                                        "label": "Country",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["nomineeChange", "nomineeMinor" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": [true, "YES", true],
                                        "required": true,
                                    },
                                )

                            }
                            setTimeout(() => {
                                this.setState({
                                    oldNomineeData: objectForExistingNominee,
                                    oldNominee: true,
                                });
                            }, 1000)
                        }

                        if (inputData.nomineeChange === true && parseInt(inputData.numberOfNominee) > 0) {
                            let sl;
                            let objectForNominee = [];
                            let oldNominee = response.data.nomineeNumberOld ? parseInt(response.data.nomineeNumberOld) : 0;
                            for (let i = 0; i < parseInt(inputData.numberOfNominee); i++) {
                                sl = oldNominee + (i + 1);
                                objectForNominee.push(
                                    {
                                        "varName": "Nominee " + i,
                                        "type": "title",
                                        "label": "Nominee  " + sl,
                                        "grid": 12,
                                    },
                                    {
                                        "varName": "search" + sl,
                                        "type": "select",
                                        "enum": [
                                            "CIF ID",
                                            "INFORMATION",
                                        ],
                                        "required": true,
                                        "onKeyDown": true,
                                        "label": "Input CIF ID/INFORMATION ?",
                                        "grid": 6,


                                    },
                                    {
                                        "varName": "nomineecifId" + sl,
                                        "type": "textApiCall",
                                        "label": "CIF ID",
                                        "grid": 6,
                                        "required": true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "CIF ID",

                                    },

                                    {
                                        "varName": "nomineeName" + sl,
                                        "type": "text",
                                        "label": "Nominee Name",
                                        "grid": 6,
                                        "multiline": true,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeName" + sl,
                                        "type": "text",
                                        "label": "Nominee Name",
                                        "grid": 6,
                                        required: true,
                                        "multiline": true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",

                                    },
                                    {
                                        "varName": "nomineeRelationship" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "relationCode",
                                        "enum": [],
                                        "label": "Relationship",
                                        "grid": 6,
                                        "required": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],


                                    },
                                    {
                                        "varName": "nomineeRelationship" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "relationCode",
                                        "enum": [],
                                        "label": "Relationship",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                        required: true,

                                    },
                                    {
                                        "varName": "percentageNominee" + sl,
                                        "type": "text",
                                        "label": "Percentage %",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        required: true,

                                    },
                                    {
                                        "varName": "percentageNominee" + sl,
                                        "type": "text",
                                        "label": "Percentage %",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                        required: true,

                                    },

                                    {
                                        "varName": "nomineeAddress1" + sl,
                                        "type": "text",
                                        "label": "Address 1 ",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "multiline": true,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeAddress1" + sl,
                                        "type": "text",
                                        "label": "Address 1 ",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        required: true,
                                        "multiline": true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },

                                    {
                                        "varName": "nomineeAddress2" + sl,
                                        "type": "text",
                                        "label": "Address 2",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "multiline": true,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeAddress2" + sl,
                                        "type": "text",
                                        "label": "Address 2",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "multiline": true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },
                                    /*  {
                                            "varName": "nomineenomineephotoIdNo" + i,
                                            "type": "text",
                                            "label": "NOMINEE PHOTO ID NO.",
                                            "grid": 6,
                                            "conditional": true,
                                            "conditionalVarName": "nomineecifIdApiCall" + i,
                                            "conditionalVarValue": true,
                                        },
                        */
                                    {
                                        "varName": "nomineeCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "city",
                                        "enum": [],
                                        "label": "City Code",
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "city",
                                        "enum": [],
                                        "label": "City Code",
                                        "grid": 6,
                                        required: true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },

                                    {
                                        "varName": "nomineeStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "state",
                                        "enum": [],
                                        "label": "State Code",
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "state",
                                        "enum": [],
                                        "label": "State Code",
                                        "grid": 6,
                                        required: true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },

                                    {
                                        "varName": "nomineePostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        required: true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },
                                    {
                                        "varName": "nomineePostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },

                                    {
                                        "varName": "nomineeCountry" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "country",
                                        "enum": [],
                                        "label": "Country",
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeCountry" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "country",
                                        "enum": [],
                                        "label": "Country",
                                        "grid": 6,
                                        required: true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },

                                    {
                                        "varName": "photoIdNumberRegNo" + sl,
                                        "type": "text",
                                        "label": "Photo Id Number/Reg No",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "photoIdNumberRegNo" + sl,
                                        "type": "text",
                                        "label": "Photo Id Number/Reg No",
                                        "grid": 6,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },
                                    {
                                        "varName": "nomineeDob" + sl,
                                        "type": "text",
                                        "label": "D.O.B(YYYY-MM-DD)",
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeDob" + sl,
                                        "type": "date",
                                        "label": "D.O.B",
                                        "grid": 6,
                                        "onKeyDown": true,
                                        // "required": true,
                                        "conditional": true,
                                        "conditionalVarName": "search" + sl,
                                        "conditionalVarValue": "INFORMATION",
                                    },


                                    {
                                        "varName": "nomineeMinor" + sl,
                                        "type": "select",
                                        "label": "Nominee Minor",
                                        "enum": ["YES", "NO"],
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        /*    "conditional": true,
                                            "conditionalVarName": "search" + sl,
                                            "conditionalVarValue": "CIF ID",*/
                                    },
                                    {
                                        "varName": "nomineeMinor" + sl,
                                        "type": "select",
                                        "label": "Nominee Minor",
                                        "enum": ["YES", "NO"],
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        /*  "conditional": true,
                                          "conditionalVarName": "search" + sl,
                                          "conditionalVarValue": "INFORMATION",*/
                                    },
                                    {
                                        "type": "title",
                                        "label": "Guardian Details  " + sl,
                                        "grid": 12,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                    },
                                    {
                                        "varName": "nomineeGuardiansName" + sl,
                                        "type": "text",
                                        "label": "Guardians Name",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "multiline": true,
                                        "required": true,


                                    },

                                    {
                                        "varName": "nomineeGuardianCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "guardianCode",
                                        "enum": [],
                                        "label": "Guardian Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "required": true,

                                    },
                                    {
                                        "varName": "guardianPhotoId" + sl,
                                        "type": "text",
                                        "label": "Guardian Photo Id",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                    },

                                    {
                                        "varName": "nomineeGuardianAddress" + sl,
                                        "type": "text",
                                        "label": "Address 1",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "multiline": true,
                                        "required": true,
                                    },
                                    {
                                        "varName": "nomineeGuardianAddress2" + sl,
                                        "type": "text",
                                        "label": "Address 2",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "multiline": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "city",
                                        "enum": [],
                                        "label": "City Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "state",
                                        "enum": [],
                                        "label": "State Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianPostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCountry2" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "country",
                                        "enum": [],
                                        "label": "Country",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["INFORMATION", true],
                                        "required": true,
                                    },
                                    {
                                        "varName": "nomineeMinor" + sl,
                                        "type": "select",
                                        "label": "Nominee Minor",
                                        "enum": ["YES", "NO"],
                                        "grid": 6,
                                        "readOnly": true,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "type": "title",
                                        "label": "Guardian Details  " + sl,
                                        "grid": 12,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },
                                    {
                                        "varName": "nomineeGuardiansName" + sl,
                                        "type": "text",
                                        "label": "Guardians Name",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "multiline": true,
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "guardianCode",
                                        "enum": [],
                                        "label": "Guardian Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "required": true,

                                    },
                                    {
                                        "varName": "guardianPhotoId" + sl,
                                        "type": "text",
                                        "label": "Guardian Photo Id",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                    },

                                    {
                                        "varName": "nomineeGuardianAddress" + sl,
                                        "type": "text",
                                        "label": "Address 1",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "multiline": true,
                                        "required": true,
                                    },
                                    {
                                        "varName": "nomineeGuardianAddress2" + sl,
                                        "type": "text",
                                        "label": "Address 2",
                                        "validationB": "length",
                                        "maxLength": 45,
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "multiline": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCityCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "city",
                                        "enum": [],
                                        "label": "City Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianStateCode" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "state",
                                        "enum": [],
                                        "label": "State Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianPostalCode" + sl,
                                        "type": "text",
                                        "label": "Postal Code",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "required": true,
                                    },

                                    {
                                        "varName": "nomineeGuardianCountry2" + sl,
                                        "type": "autoCompleteValueReturn",
                                        "enumType": "country",
                                        "enum": [],
                                        "label": "Country",
                                        "grid": 6,
                                        "conditionalArray": true,
                                        "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                        "conditionalArrayValue": ["CIF ID", true],
                                        "required": true,
                                    },
                                )

                            }
                            this.setState({
                                existingObjectForNominee: objectForNominee,
                                existingNominee: true,
                                getInitialNewNominee: response.data.numberOfNominee ? response.data.numberOfNominee : 0,
                            });
                        }

                        setTimeout(() => {
                            if (inputData.nomineeChange) {
                                this.dynamicApiCallForNomineeUpdate("city", "city");
                                this.dynamicApiCallForNomineeUpdate("state", "state");
                                this.dynamicApiCallForNomineeUpdate("country", "country");
                                this.dynamicApiCallForNomineeUpdate("guardianCode", "guardianCode");
                                this.dynamicApiCallForNomineeUpdate("relationCode", "nomineeRelationCode");
                            }
                        }, 1000);

                        // if (inputData.projectRelatedDataUpdateADUP || inputData.dormantAccountActivation) {
                        let getDataByAc = backEndServerURL + "/GetCustomAcctDetailsRequest/" + inputData.accountNumber;
                        axios.get(getDataByAc, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                if (response.status === 200) {
                                    let mandateTableArray = [];
                                    response.data.relPartyList.map((mandate, index) => {
                                        if (mandate.relPartyType !== "M") {
                                            mandateTableArray.push(this.createMandateTableData(index, mandate.custId, mandate.name, mandate.relPartyCode, this.triggerExistingMandate(mandate.custId,mandate.relPartyCode)))
                                        }
                                    });
                                    this.setState({
                                        mandateTableArray: mandateTableArray,
                                    });
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    cbNotFound: true,
                                    title: error.response.data.message
                                })
                            });
                        // }
                        if (inputData.projectRelatedDataUpdateADUP) {
                            for (let i = 0; i < MAKERJsonFormForCasaIndividualLast.length; i++) {
                                if (MAKERJsonFormForCasaIndividualLast[i].varName === "fincoreDataUpdatedormantActivationCharge" || MAKERJsonFormForCasaIndividualLast[i].varName === "fincoreDataUpdateaCStatus") {
                                    MAKERJsonFormForCasaIndividualLast[i].readOnly = true
                                }
                            }
                        }

                        this.dynamicApiCall("placeOfIssue", "placeOfIssue");
                        this.dynamicApiCall("documentCode", "documentCode");
                        this.dynamicApiCallDocumentType("documentCodeDocumentType", "documentCodeDocumentType");
                        this.dynamicApiCallDocumentType("documentCodeDocumentTypeDesc", "documentCodeDocumentTypeDesc");
                        this.dynamicApiCall("phoneCountryCode", "phoneCountryCode");
                        this.dynamicApiCall("city2", "city");
                        this.dynamicApiCall("state2", "state",);
                        this.dynamicApiCall("currency", "currency");
                        this.dynamicApiCall("nomineeRelationCode", "nomineeRelationCode");
                        this.dynamicApiCall("nationality", "country");
                        this.dynamicApiCall("documentsCollectedToEnsureTheSourceOfFund", "sourceOfFund");
                        this.dynamicApiCall("customersOccupation", "customerOccupation");
                        this.dynamicApiCall("addressVariety", "addressVariety");
                        this.dynamicApiCall("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", "customerBusiness");
                        this.dynamicApiCall("monthlyIncome", "monthlyIncome");
                        this.dynamicApiCall("howWasAccountOpened", "accountOpen");
                        this.dynamicApiCall("constitution", "constitution");
                        this.dynamicApiCall("waiverField", "waiverField");
                        this.dynamicApiCall("bankRelationType", "bankRelationType");
                        this.dynamicApiCall("customerStatus", "customerStatus");

                        this.dynamicApiCall("ccepCode", "ccepCode");

                        this.dynamicApiCall("maritalStatus", "maritalStatus");

                        this.dynamicApiCall("title", "title");

                        this.dynamicApiCall("relationship", "relationship");

                        this.dynamicApiCall("employmentType", "employmentType");

                        this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet");

                        this.dynamicApiCall("nonResident", "nonResident");

                        this.dynamicApiCall("relationCode", "relationCode");

                        this.dynamicApiCall("relationType", "relationType");

                        this.dynamicApiCall("dispatchMode", "dispatchMode");

                        this.dynamicApiCall("statementFrequency", "statementFrequency");

                        this.dynamicApiCall("chequebookWaiverFunctionCode", "chequebookWaiverFunctionCode");

                        this.dynamicApiCall("chequebookWaiverType", "chequebookWaiverType");

                        this.dynamicApiCall("subSegment", "subSegment");

                        this.dynamicApiCall("designationCode", "designationCode");

                        this.dynamicApiCall("sectorCode", "sectorCode");

                        this.dynamicApiCall("subSectorCode", "subSectorCode");

                        this.dynamicApiCall("depositCode", "depositCode");
                        this.dynamicApiCall("despatchMode", "despatchMode");

                        this.dynamicApiCall("tradeService", "tradeService");

                        this.dynamicApiCall("guardianCode", "guardianCode");
                        this.dynamicApiCall("addressType", "addressType");

                        this.dynamicApiCall("accountStatement", "accountStatement");

                        this.dynamicApiCall("modOfOperation", "modOfOperation");

                        this.dynamicApiCall("introducerStatus", "introducerStatus");

                        this.dynamicApiCall("bbkSegmentCode", "bbkSegmentCode");

                        this.dynamicApiCall("priorityCenterCode", "priorityCenterCode");

                        this.dynamicApiCall("priorityCenterCode", "priorityCenterCode");

                        this.dynamicApiCall("schemeCodeForSavings", "savingsSchemeCode");

                        this.dynamicApiCall("schemeCodeForCurrent", "currentSchemeCode");


                        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
                        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                            .then((response) => {
                                this.findByVarNameApiData("misoccupationCodePriorityCodeStaff", response.data);
                                this.findByVarNameApiData("fincoreDataUpdateoccupationCode", response.data);
                                this.setState({
                                    occupationCodeList: response.data
                                })
                            })
                            .catch((error) => {
                                console.log(error);
                                this.setState({
                                    loading: false,
                                    errorMessage: error.response.data.message,
                                    errorModalBoolean: true
                                })
                            });

                        this.commonAPiLoad();
                        let getCbnumber = inputData.cbNumber;
                        let getAccountnumber = inputData.accountNumber;
                        if (getCbnumber !== undefined && inputData.checker_approval === undefined && inputData.makerSaveFlag === undefined) {
                            inputData["contactNumberEmaildesignatedEmailAddressEmailId1Delete"] = "N";
                            inputData["natioanlIdCardDelete"] = "N";
                            inputData["smartIdCardDelete"] = "N";
                            inputData["passportNumberDelete"] = "N";
                            inputData["drivingLicenseNumberDelete"] = "N";
                            inputData["residentNumberDelete"] = "N";
                            inputData["birthCertificateDelete"] = "N";
                            inputData["chairmanCertificateDelete"] = "N";
                            inputData["photoIdDelete"] = "N";
                            inputData["etinDelete"] = "N";
                            inputData["contactNumber2Delete"] = "N";
                            inputData["contactNumber3Delete"] = "N";
                            inputData["contactNumberEmailemailId2Delete"] = "N";
                            inputData["homeAddressDelete"] = "N";
                            inputData["workAddressDelete"] = "N";
                            inputData["presentAddressDelete"] = "N";
                            inputData["nreAddressDelete"] = "N";
                            let input = this.copyJson(inputData);
                            let vValue = this.copyJson(inputData);
                            let url = backEndServerURL + "/cbsCustomerGet/" + getCbnumber;
                            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((cbDatResponse) => {
                                    let dataInputs = this.apiDataSet(cbDatResponse.data, input);
                                    let datavarValues = this.apiDataSet(cbDatResponse.data, vValue);
                                    let cifUrl = backEndServerURL + "/getCustomerCIFDetails/" + inputData.cbNumber;
                                    axios.get(cifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                        .then((cifResponse) => {
                                            let cifDataInput = this.apiDataSet(cifResponse.data, dataInputs, "cbExtraInformation");
                                            let cifDataVarValue = this.apiDataSet(cifResponse.data, datavarValues, "cbExtraInformation");
                                            let getAccountUrl = backEndServerURL + "/getAccountInfo/" + getAccountnumber;
                                            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                .then((accountDataResponse) => {
                                                    let dataInput = this.apiDataSet(accountDataResponse.data, cifDataInput, "accountRelated");
                                                    let datavarValue = this.apiDataSet(accountDataResponse.data, cifDataVarValue, "accountRelated");
                                                    let accUrl = backEndServerURL + "/GetCustomAcctStatus/" + getAccountnumber;
                                                    axios.get(accUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                        .then((dormantResponse) => {
                                                            let dormantInput = this.apiDataSet(dormantResponse.data, dataInput, "Dormant");
                                                            let dormantInputs = this.apiDataSet(dormantResponse.data, datavarValue, "Dormant");
                                                            this.forceUpdate();
                                                            let acCifUrl = backEndServerURL + "/accCifOthrInfo/" + getAccountnumber;
                                                            axios.get(acCifUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                                                .then((acCifResponse) => {

                                                                    let finalInputValue = this.accountOtherInfo(acCifResponse.data, dormantInput);

                                                                    let finalVarValue = this.accountOtherInfo(acCifResponse.data, dormantInputs);

                                                                    this.functionForSetInquiry(finalInputValue, finalVarValue);
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({
                                                                        loading: false,
                                                                        errorMessage: error.response.data.message,
                                                                        errorModalBoolean: true
                                                                    })
                                                                })

                                                        })
                                                        .catch((error) => {
                                                            console.log(error);
                                                            this.setState({
                                                                loading: false,
                                                                errorMessage: "Account Get Api Problem!",
                                                                errorModalBoolean: true
                                                            })
                                                        })
                                                    ;
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    this.setState({
                                                        loading: false,
                                                        errorMessage: "Account Get Api Problem!",
                                                        errorModalBoolean: true
                                                    })
                                                })
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            this.setState({
                                                loading: false,
                                                errorMessage: error.response.data.message,
                                                errorModalBoolean: true
                                            })
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.setState({
                                        loading: false,
                                        errorMessage: error.response.data.message,
                                        errorModalBoolean: true
                                    })
                                })


                        } else {
                            this.setState({
                                inputData: {...this.copyJson(inputData)},
                                varValue: {...this.copyJson(inputData)},
                                showValue: true,
                                getData: true,
                            }, this.stopLoadingForLinkedCB);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorMessage: error.response.data.message,
                        errorModalBoolean: true
                    })
                });
        }


    }

    functionForSetInquiry = (finalInputValue, finalVarValue) => {
        CommonApi.setCaseInquiry(finalInputValue, this.props.appId)
            .then((saveResponse) => {
                this.setState({
                    inputData: finalInputValue,
                    varValue: finalVarValue,
                    showValue: true,
                    getData: true,
                }, this.stopLoadingForLinkedCB);
            }).catch((error) => {
            console.log(error);
        })
    };

    accountOtherInfo = (data, inputData) => {
        inputData.fincoreDataUpdatestatementFrequency2 = data.statementStartDay ? data.statementStartDay : null;
        inputData.misavailedTradeServices = this.findByVarNameGetKeyValue(data.tradeServiceAvailed, this.state.tradeServiceList);
        inputData.mispepStatusFreeText10 = data.pepStatus ? data.pepStatus : null;
        inputData.smartIdCardnidVerificationConfirmationIsDocumentVerified = data.isSmartCardVerified ? data.isSmartCardVerified : null;
        inputData.passportNumberplaceOfIssue = this.findByVarNameGetKeyValue(data.passportPlaceOfIssue, this.state.placeOfIssueList);
        switch (data.statementFrequency) {
            case "H":
                inputData.fincoreDataUpdatestatementFrequency1 = "Half Yearly";
                break;
            case "Y":
                inputData.fincoreDataUpdatestatementFrequency1 = "Yearly";
                break;
            case "Q":
                inputData.fincoreDataUpdatestatementFrequency1 = "Quarterly";
                break;
            case "M":
                inputData.fincoreDataUpdatestatementFrequency1 = "Monthly";
                break;
            default:
                inputData.fincoreDataUpdatestatementFrequency1 = null;
        }

        switch (data.statementHolidayStat) {
            case "N":
                inputData.fincoreDataUpdatestatementFrequency3 = "Next Day";
                break;
            case "H":
                inputData.fincoreDataUpdatestatementFrequency3 = "Holiday";
                break;
            case "P":
                inputData.fincoreDataUpdatestatementFrequency3 = "Previous Day";
                break;
            case "S":
                inputData.fincoreDataUpdatestatementFrequency3 = "Skip";
                break;
            default:
                inputData.fincoreDataUpdatestatementFrequency3 = null;
        }

        return inputData;
    };

    commonAPiLoad = () => {
        let remarksArray = [];
        let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
        axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                var status = "";
                var tableArray = [];
                response.data.map((deferal) => {
                    if (deferal.status === "ACTIVE") {
                        status = "Approved By BM"
                    } else {
                        status = deferal.status
                    }
                    tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));

                });
                this.setState({
                    getDeferalList: tableArray
                });
                let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        response.data.map((data) => {

                            remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                        });
                        this.setState({
                            getRemarks: remarksArray
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            });
    };

    stopLoadingForLinkedCB = () => {
        if (this.state.inputData.showLabel) {
            this.setState({
                loading: false
            })
        }
    };

    stopLoading = (value) => {
        console.log(value);
        if (value === "yes") {
            this.setState({
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    dynamicApiCallForNomineeUpdate = (enumType, apiType) => {
        let jsonForm = this.state.oldNomineeData.concat(this.state.existingObjectForNominee, this.state.objectForNominee);
        let apiUrl = backEndServerURL + "/finMasterData/" + apiType;

        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    console.log(jsonForm);
                    for (let i = 0; i < jsonForm.length; i++) {
                        let jsonObject = jsonForm[i] ? jsonForm[i] : {};
                        if (jsonObject.enumType === enumType) {
                            jsonObject.enum = response.data;
                        }
                    }
                    if (enumType === "city") {
                        this.setState({
                            city: response.data
                        });
                    }
                    if (enumType === "state") {
                        this.setState({
                            stateCode: response.data
                        })
                    }
                    if (enumType === "guardianCode") {
                        this.setState({
                            guardianCode: response.data
                        })
                    }
                    if (enumType === "relationCode") {
                        this.setState({
                            relationCode: response.data
                        })
                    }
                    if (enumType === "country") {
                        this.setState({
                            country: response.data
                        })
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    dynamicApiCallDocumentType = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/custom/uniqueDocType/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "documentCodeDocumentType") {
                    this.findByVarNameApiData("photoIddocumentType", response.data)

                } else if (apiType === "documentCodeDocumentTypeDesc") {
                    this.findByVarNameApiData("photoIddocumentTypeDescription", response.data)

                }

            })
            .catch((error) => {
                console.log(error)
            })
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "city") {
                    this.findByVarNameApiData("maillingAdresscity", response.data);
                    this.findByVarNameApiData("homeAddresscity", response.data);
                    this.findByVarNameApiData("workAddresscity", response.data);
                    this.findByVarNameApiData("presentAddresscity", response.data);
                    this.findByVarNameApiData("nreAddresscity", response.data);
                    this.findByVarNameApiData("mailingAddresscity1", response.data);
                    this.findByVarNameApiData("homeAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity1", response.data);
                    this.findByVarNameApiData("workAddresscity2", response.data);

                    this.setState({
                        city: response.data,
                        getCity: true
                    })

                } else if (apiType === "phoneCountryCode") {

                    this.findByVarNameApiData("countryCodecontactNumberphoneNo1", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo2", response.data);
                    this.findByVarNameApiData("countryCodecontactNumberphoneNo3", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "state") {

                    this.findByVarNameApiData("maillingAdressstate", response.data);
                    this.findByVarNameApiData("homeAddressstate", response.data);
                    this.findByVarNameApiData("workAddressstate", response.data);
                    this.findByVarNameApiData("presentAddressstate", response.data);
                    this.findByVarNameApiData("nreAddressstate", response.data);
                    this.findByVarNameApiData("mailingAddressstate1", response.data);
                    this.findByVarNameApiData("homeAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate1", response.data);
                    this.findByVarNameApiData("workAddressstate2", response.data);

                    this.setState({
                        stateCode: response.data,
                        getState: true,
                    })
                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data);
                    this.setState({
                        getnonResident: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data);
                    this.setState({
                        getfreecode7: response.data
                    })
                } else if (apiType === "country") {
                    this.findByVarNameApiData("passportNumbercountryOfIssue", response.data);
                    this.findByVarNameApiData("maillingAdresscountry", response.data);
                    this.findByVarNameApiData("homeAddresscountry", response.data);
                    this.findByVarNameApiData("workAddresscountry", response.data);
                    this.findByVarNameApiData("Customersnationality", response.data);
                    this.findByVarNameApiData("presentAddresscountry", response.data);
                    this.findByVarNameApiData("nreAddresscountry", response.data);
                    this.findByVarNameApiData("mailingAddresscountry1", response.data);
                    this.findByVarNameApiData("homeAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry1", response.data);
                    this.findByVarNameApiData("workAddresscountry2", response.data);
                    this.setState({
                        country: response.data,
                        getCountry: true
                    });
                } else if (apiType === "currency") {

                    this.findByVarNameApiData("workAddressCurrency", response.data);
                    this.findByVarNameApiData("homeAddressCurrency", response.data);
                    // this.findByVarNameApiData("aof1currencyCodeCcy", response.data)
                    this.setState({
                        currency: response.data,
                        getCurrency: true
                    })
                } else if (apiType === "customerStatus") {
                    this.findByVarNameApiData("miscustomerStatusNormalStaffPriorityEtc", response.data);
                    this.setState({
                        customerStatusList: response.data,
                    })
                } else if (apiType === "savingsSchemeCode") {

                    this.setState({
                        savingsSchemeCodeList: response.data,
                    })
                } else if (apiType === "placeOfIssue") {
                    this.findByVarNameApiData("passportNumberplaceOfIssue", response.data);
                    this.setState({
                        placeOfIssueList: response.data
                    })
                } else if (apiType === "despatchMode") {
                    this.findByVarNameApiData("aof1despatchModePrintedEStatementBoth", response.data);
                    this.setState({
                        despatchList: response.data
                    })
                } else if (apiType === "currentSchemeCode") {

                    this.setState({
                        currentSchemeCodeList: response.data,
                    })
                } else if (apiType === "sourceOfFund") {
                    this.findByVarNameApiData("sourceOfFund", response.data);
                    this.findByVarNameApiData("documentsCollectedToEnsureTheSourceOfFund", response.data)

                } else if (apiType === "constitution") {
                    this.findByVarNameApiData("misconstitution", response.data);
                    this.findByVarNameApiData("constitutionCode", response.data);
                    this.setState({
                        constitutionList: response.data
                    })

                } else if (apiType === "monthlyIncome") {
                    this.findByVarNameApiData("customerMonthlyIncome", response.data)


                } else if (apiType === "accountOpen") {
                    this.findByVarNameApiData("howWasAccountOpened", response.data)


                } else if (apiType === "designationCode") {
                    this.findByVarNameApiData("relatedCbTaggingdesignationCode", response.data)

                } else if (apiType === "tradeService") {
                    this.findByVarNameApiData("misavailedTradeServices", response.data);
                    this.setState({
                        tradeServiceList: response.data
                    })

                } else if (apiType === "modOfOperation") {
                    this.findByVarNameApiData("schemeCodeChangemodeOfOperation", response.data);
                    this.setState({
                        despatchmodeList: response.data
                    })

                } else if (apiType === "introducerStatus") {
                    this.findByVarNameApiData("primryiintroducerStatus", response.data);
                    this.setState({
                        introducerStatus: response.data
                    })
                } else if (apiType === "bbkSegmentCode") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)
                } else if (apiType === "guardianCode") {
                    this.findByVarNameApiData("guardianDetailsguardianSelectionGurdian", response.data);
                    this.setState({
                        getGuardianCode: response.data
                    })
                } else if (apiType === "subSegment") {
                    this.findByVarNameApiData("crmMissubSegment", response.data);
                    this.setState({
                        subSegmentList: response.data
                    })

                } else if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data);
                    this.findByVarNameApiData("subSectorCode", response.data);
                    this.setState({
                        subsectorCodeList: response.data
                    })
                } else if (apiType === "sectorCode") {
                    this.findByVarNameApiData("fincoreDataUpdatesectorCode", response.data);
                    this.findByVarNameApiData("sectorCode", response.data);
                    this.setState({
                        sectorCodeList: response.data
                    })
                } else if (apiType === "depositCode") {
                    this.findByVarNameApiData("fincoreDataUpdatedepositCodeFreeCode7", response.data);
                    this.setState({
                        depositCodeList: response.data
                    })
                } else if (apiType === "dispatchMode") {
                    this.findByVarNameApiData("fincoreDataUpdatedispatchMode", response.data);
                    this.setState({
                        dispatchMode: response.data
                    })
                } else if (apiType === "rmCode") {
                    this.findByVarNameApiData("mispriorityMonitoringRmCodeFreeCode3", response.data);
                    this.setState({
                        rmCodeList: response.dat
                    })

                } else if (apiType === "accountStatement") {
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "statementFrequency") {
                    this.findByVarNameApiData("fincoreDataUpdatestatementFrequency", response.data);
                    this.findByVarNameApiData("aof1statement", response.data)
                } else if (apiType === "chequebookWaiverType") {
                    this.findByVarNameApiData("miswaiverTypeForChequeBookWaiver", response.data);
                    this.setState({
                        chequebookWaiverType: response.data
                    })
                } else if (apiType === "branch") {
                    this.findByVarNameApiData("otherBankbranchName", response.data)

                } else if (apiType === "relationCode") {
                    this.findByVarNameApiData("relatedCbTaggingrelationCode", response.data);
                    this.setState({
                        relationCodeList: response.data
                    })
                } else if (apiType === "addressFormat") {
                    this.findByVarNameApiData("maillingAdressaddressFormat", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat", response.data);
                    this.findByVarNameApiData("presentAddressaddressFormat", response.data);
                    this.findByVarNameApiData("mailingAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("homeAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat1", response.data);
                    this.findByVarNameApiData("workAddressaddressFormat2", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "waiverField") {
                    this.findByVarNameApiData("miswaiverFieldFreeCode10", response.data);
                    this.setState({
                        waiverFieldList: response.data
                    })
                } else if (apiType === "ccepCode") {
                    this.findByVarNameApiData("misccepCodeFreeCode9", response.data);
                    this.setState({
                        ccepCodeList: response.data
                    })

                } else if (apiType === "nonResident") {
                    this.findByVarNameApiData("chairmanCertificatenonResident", response.data)

                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("misagentBankingOutletFreeCode1", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                } else if (apiType === "maritalStatus") {
                    this.findByVarNameApiData("basicInformationUpdatemaritalStatus", response.data);
                    this.findByVarNameApiData("basicInformationmaritalStatus", response.data);
                    this.findByVarNameApiData("maritalStatus", response.data);
                    this.setState({
                        maritalStatusList: response.data
                    })

                } else if (apiType === "employmentType") {
                    this.findByVarNameApiData("employerDataprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("etinprofessionEmploymentType", response.data);
                    this.findByVarNameApiData("employerDataemploymentType", response.data);
                    this.findByVarNameApiData("profession", response.data);
                    this.setState({
                        employmentTypeList: response.data
                    })
                } else if (apiType === "relationship") {
                    this.findByVarNameApiData("guardianDetailsrelation", response.data);

                    this.setState({
                        relationshipList: response.data
                    })
                } else if (apiType === "relationType") {
                    this.findByVarNameApiData("relatedCbTaggingrelationType", response.data)

                } else if (apiType === "nomineeRelationCode") {
                    this.findByVarNameApiData("guardianDetailsrelation1", response.data)

                } else if (apiType === "title") {
                    if (fieldName === "titleOld") {
                        let JsonArrayForOther = jsonForOnlyLinkedCB;
                        for (let i = 0; i < JsonArrayForOther.length; i++) {
                            let jsonObjectLast = JsonArrayForOther[i];
                            if (jsonObjectLast.varName === "titleOld") {
                                JsonArrayForOther[i].enum = response.data;
                            }
                        }
                    }
                    this.findByVarNameApiData("titleOld", response.data);
                    this.findByVarNameApiData("mistitle", response.data);
                    this.findByVarNameApiData("relatedCbTaggingtitle", response.data);
                    this.findByVarNameApiData("aCTitle", response.data);
                    this.setState({
                        titleList: response.data
                    })


                } else if (apiType === "documentCode") {
                    this.findByVarNameApiData("photoIddocumentCode", response.data)

                } else if (apiType === "bankName") {
                    this.findByVarNameApiData("otherBankbankName", response.data)


                } else if (apiType === "segment") {
                    this.findByVarNameApiData("misbbkSegmentCodeFreeCode7", response.data)


                } else if (apiType === "phoneOrEmailId") {
                    this.findByVarNameApiData("contactNumberphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberphoneNoEmailId3", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId4", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId1", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId2", response.data);
                    this.findByVarNameApiData("contactNumberEmailphoneNoEmailId", response.data);
                    this.setState({
                        phoneOrEmail: response.data
                    })
                } else if (apiType === "customerOccupation") {

                    this.findByVarNameApiData("customersOccupation", response.data)

                } else if (apiType === "bankRelationType") {

                    this.findByVarNameApiData("guardianDetailsbankRelationType", response.data)

                } else if (apiType === "emailType") {

                    this.findByVarNameApiData("contactNumberEmailemailType1", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType2", response.data);
                    this.findByVarNameApiData("contactNumberEmailemailType", response.data);
                    this.findByVarNameApiData("contactNumberEmailpreferedEmailIdType", response.data);
                    this.setState({
                        phoneEmailType: response.data
                    })
                } else if (apiType === "addressVariety") {

                    this.findByVarNameApiData("howTheAddressOfTheAccountHolderHasBeenVerified", response.data)

                } else if (apiType === "customerBusiness") {

                    this.findByVarNameApiData("whatDoesTheCustomerDoInWhatTypeOfBusineessIsTheCustomerEngaged", response.data)

                }
            })
            .catch((error) => {
                console.log(error);
            })
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false
            });
            if (this.state.buttonType === "approve") {
                // this.functionForFailedCheck().then((response) => {
                //     console.log(response.data);
                //     if (response.data === "Everything OK!") {
                this.functionForApprove()
                //     } else {
                //         this.setState({
                //             title: response.data,
                //             confirmationAlertTwo: true,
                //         });
                //     }
                // })
                // this.functionForApprove()
            } else if (this.state.buttonType === "return") {
                this.functionForReturn()
            }
        } else {
            this.setState({
                disabled: false,
                confirmationAlert: false
            })
        }
    };

    closeConfirmationTwo = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlertTwo: false
            });
            if (this.state.buttonType === "approve") {
                this.functionForApprove()
            }
        } else {
            this.setState({
                disabled: false,
                loading: false,
                confirmationAlertTwo: false
            })
        }
    };

    functionForApprove = () => {
        this.setState({
            loading: true
        });
        if (this.state.inputData.titleChange && this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = "Attachments-for-title-change";
            let files = this.state.fileUploadData.scanningFile;
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {

                    console.log(response);


                })
                .catch((error) => {
                    console.log(error)
                })
        }
        var commentsUrl = backEndServerURL + "/appRemarkSave/" + this.props.appId;
        axios.post(commentsUrl, {remark: this.state.inputData.makerRemarks}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            });
        if (this.state.inputData.projectRelatedDataUpdateADUP || this.state.inputData.dormantAccountActivation) {
            this.state.inputData.cbApproved = false;
            this.state.inputData.accountApproved = false;
            this.state.inputData.signatureApproved = false;
            this.state.inputData.finalApproved = false;
        } else if (this.state.inputData.onlyDormantAccountActivation) {
            this.state.inputData.onlyDormantApproved = false;
            this.state.inputData.signatureApproved = false;
            this.state.inputData.finalApproved = false;
        } else {
            this.state.inputData.cbAndAccountUpdate = false;
            this.state.inputData.signatureApproved = false;
            this.state.inputData.finalApproved = false;
        }
        this.state.inputData.maker_update_all_info_send_to = "CHECKER";
        this.state.inputData.makerRemarks = undefined;
        this.state.inputData.maker_approval = "APPROVED";
        var variableSetUrl = backEndServerURL + "/variables/" + this.props.appId;
        axios.post(variableSetUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    var url = backEndServerURL + "/case/route/" + this.props.appId;
                    axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            this.setState({
                                title: "Successful!",
                                notificationMessage: "Successfully Routed!",
                                alert: true,
                                loading: false,
                                disabled: false
                            });
                            this.props.closeModal()
                        })
                        .catch((error) => {
                            console.log(error);
                            this.setState({
                                loading: false,
                                disabled: false
                            });
                        });
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    disabled: false
                });
                console.log(error)
            });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.forceUpdate();
        let error = false;
        if (this.state.inputData.freeFlag2 !== "linkedCB"  && this.state.inputData.nomineeChange === true) {
            this.state.inputData.employerDataemploymentType = this.state.inputData.employerDataprofessionEmploymentType;
            let jsonForm = MAKERJsonFormForCasaIndividual.concat(MAKERJsonFormForCasaIndividualLast);
            error = MyValidation.defaultValidation(jsonForm.concat(this.state.existingObjectForNominee, this.state.objectForNominee,this.state.oldNomineeData), this.state);
            this.forceUpdate();
        } else if (this.state.inputData.freeFlag2 === "linkedCB") {
            error = MyValidation.defaultValidation(jsonForOnlyLinkedCB.concat(this.state.existingObjectForNominee, this.state.objectForNominee,), this.state);
            this.forceUpdate();
        }


        let fileError = false;
        if (this.state.inputData.titleChange && this.state.fileUploadData.scanningFile !== undefined) {
            fileError = MyValidation.fileValidation(fileUpload, this.state);
            this.forceUpdate();
        }

        if (error === true || fileError === true) {
            return 0;
        }

        this.setState({
            disabled: true
        });

        let percentage = 0;
        if (this.state.inputData.nomineeChange) {
            let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
            if (this.state.inputData["numberOfNominee"]) {
                let sl;
                for (let i = 0; i < this.state.inputData["numberOfNominee"]; i++) {
                    sl = oldNominee + (i + 1);
                    if (this.state.inputData["percentageNominee" + sl] !== undefined) {
                        percentage += parseFloat(this.state.inputData["percentageNominee" + sl]);
                    }
                }
            }
            if (oldNominee) {
                for (let i = 0; i < oldNominee; i++) {
                    if (this.state.inputData["deleteOldNominee" + (i + 1)] !== "Y") {
                        percentage += parseFloat(this.state.inputData["percentageNominee" + (i + 1)]);
                    }
                }
            }
            if (percentage !== parseFloat("100.0") && (this.state.inputData["numberOfNominee"] > 0 || oldNominee > 0)) {
                this.setState({
                    errorModalBoolean: true,
                    errorMessage: "Nominee Percentage should be 100%",
                    disabled: false
                });
                return 0;
            }

        }

        this.setState({
            title: "Do you want to confirm?",
            confirmationAlert: true,
            buttonType: "approve"
        });

    };

    handleSubmitReturn = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(makerRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                title: "Do you want to confirm?",
                confirmationAlert: true,
                buttonType: "return"
            });
        }
    };

    functionForReturn = () => {
        this.setState({
            loading: true,
            disabled: true
        });
        let remarksData = {};
        let inputData = {
            maker_approval: "RETURN"
        };
        remarksData.remark = this.state.inputData.makerRemarks;
        remarksData.map = {...inputData, makerRemarks: undefined};
        let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
        axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            this.setState({
                title: "Successful!",
                notificationMessage: "Successfully Routed!",
                alert: true,
                disabled: false,
                loading: false,
            });
            this.props.closeModal()
        }).catch((error) => {
            this.setState({
                disabled: false,
                loading: false
            });
        });
    };

    onKeyDownForExistingNominee = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        for (let i = 0; i < oldNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true,
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        setTimeout(() => {
                            let x = 18;
                            let someDate = new Date();
                            someDate.setFullYear(someDate.getFullYear() - x);
                            let date = someDate.getDate();
                            let month = someDate.getMonth() + 1;
                            let year = someDate.getFullYear();
                            let convertDate = year + "-" + month + "-" + date;
                            let newDate = new Date(convertDate);
                            let stringToDate = new Date(newDate.toDateString());
                            let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                            if (stringToDate >= inputDate) {
                                this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                                this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                            } else {
                                this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                                this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                                this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                                this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                            }
                            this.forceUpdate()
                        }, 1000);
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        this.forceUpdate();
                        this.setState({
                            loading: false,
                            errorMessage: error.response.data.message,
                            errorModalBoolean: true,
                        })
                    })
            }
        }
    };

    onKeyDownForDedup = (event, data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        let totalNominee = this.state.inputData["numberOfNominee"] !== undefined ? oldNominee + this.state.inputData["numberOfNominee"] : oldNominee;
        for (let i = 0; i < totalNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["search" + (i + 1)] = this.state.inputData["search" + (i + 1)];
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let someDate = new Date();
                        someDate.setFullYear(someDate.getFullYear() - x);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());

                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";

                            // this.state.varValue["nomineeMinor" + i] == "NO";
                        } else {

                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";

                            //this.state.varValue["nomineeMinor" + i] == "YES";
                        }
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        console.log(this.state.inputData);
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })

            }
        }
        if (data === "mispriorityMonitoringRmCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.mispriorityMonitoringRmCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    this.state.varValue.mispriorityMonitoringRmCodeFreeCode3Name = response.data;
                    console.log(response.data);
                    this.state.inputData.mispriorityMonitoringRmCodeFreeCode3ApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorMessage: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
            this.setState({
                loading: true
            });
            this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.varValue.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3RmName = response.data;
                    this.state.inputData.fincoreDataUpdatermCodeWealthManagerCodeFreeCode3Apicall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        errorModalBoolean: true,
                        errorMessage: "Invalid RM Number!",
                        loading: false
                    })

                })
        } else if (data === "primryintroducerCustomerIdCifId") {
            this.setState({
                loading: true
            });
            this.state.inputData.introducerIdApiCall = false;
            this.state.varValue.introducerIdApiCall = false;
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.primryintroducerCustomerIdCifId;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.primryiintroducerNameLastName = response.data.customerName;
                    this.state.varValue.primryiintroducerNameLastName = response.data.customerName;
                    console.log(response.data);

                    this.state.inputData.introducerIdApiCall = true;
                    this.state.varValue.introducerIdApiCall = true;
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorMessage: "Invalid Introducer ID",

                    })
                })
        } else if (data === "guardianDetailsgurdianCifNumberCustomer") {
            this.setState({
                loading: true
            });
            this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "NO";
            this.forceUpdate();
            let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData.guardianDetailsgurdianCifNumberCustomer;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.state.inputData.guardianDetailslastName = response.data.customerName;
                    this.state.varValue.guardianDetailslastName = response.data.customerName;
                    console.log(response.data);


                    this.state.inputData.guardianDetailsgurdianCifNumberCustomerApiCall = "YES";
                    this.forceUpdate();
                    this.setState({
                        varValue: this.state.varValue,
                        loading: false
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorMessage: "Invalid Number"
                    })
                })
        } else if (this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "NID",
            "value": "NID CARD IDENTITY"
        } || this.state.inputData.natioanlIdCarddocumentCodeN === {
            "masterDataName": "documentCode",
            "key": "RSNID",
            "value": "RSNID"
        }) {
            this.state.inputData.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y";
            this.state.varValue.natioanlIdCardnidVerificationConfirmationIsDocumentVerified1 = "Y"
        }

    };

    onKeyDownChange = (data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;

        let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
        for (let i = 0; i < totalNominee; i++) {
            if (data.varName === "search" + (i + 1)) {
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["nomineecifId" + (i + 1)] = "";
                this.state.varValue["nomineecifId" + (i + 1)] = "";
                this.state.inputData["nomineeName" + (i + 1)] = "";
                this.state.inputData["nomineeAddress1" + (i + 1)] = "";
                this.state.inputData["nomineeAddress2" + (i + 1)] = "";
                this.state.inputData["nomineeCityCode" + (i + 1)] = "";
                this.state.inputData["nomineeStateCode" + (i + 1)] = "";
                this.state.inputData["nomineePostalCode" + (i + 1)] = "";
                this.state.inputData["nomineeCountry" + (i + 1)] = "";
                this.state.inputData["percentageNominee" + (i + 1)] = "";
                this.state.inputData["nomineeRelationship" + (i + 1)] = "";
                this.state.varValue["percentageNominee" + (i + 1)] = "";
                this.state.varValue["nomineeRelationship" + (i + 1)] = "";
                this.state.inputData["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeName" + (i + 1)] = "";
                this.state.varValue["nomineeAddress1" + (i + 1)] = "";
                this.state.varValue["nomineeAddress2" + (i + 1)] = "";
                this.state.varValue["nomineeCityCode" + (i + 1)] = "";
                this.state.varValue["nomineeStateCode" + (i + 1)] = "";
                this.state.varValue["nomineePostalCode" + (i + 1)] = "";
                this.state.varValue["nomineeCountry" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.inputData["nomineeMinor" + (i + 1)] = "";
                this.state.varValue["nomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                //  this.forceUpdate()
                setTimeout(() => {
                    let x = 18;
                    let someDate = new Date();
                    someDate.setFullYear(someDate.getFullYear() - x);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    console.log(stringToDate, inputDate);
                    console.log(stringToDate >= inputDate);
                    if (stringToDate >= inputDate || (isNaN(inputDate.getTime()))) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                        // this.state.varValue["nomineeMinor" + i] == "NO";
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }

                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }


        let inputData = this.state.inputData;
        let varValue = this.state.varValue;
        if (data.varName === "homeAddressaddrssType") {

            if (this.state.inputData.homeAddressaddrssType === {
                "masterDataName": "addressType",
                "key": "Mailing",
                "value": "Mailing"
            }) {
                this.state.inputData.homeAddressFieldShow = false;
                this.state.varValue.homeAddressFieldShow = false;
                Functions.sleep(500).then(() => {
                    this.state.inputData.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;
                    this.state.varValue.homeAddresshomeAddressField1 = this.state.inputData.maillingAdressmailingAddressField1;

                    this.state.inputData.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;
                    this.state.varValue.homeAddresshomeAddressField2 = this.state.inputData.maillingAdressmailingAddressField2;

                    this.state.inputData.homeAddresscity = this.state.inputData.maillingAdresscity;
                    this.state.varValue.homeAddresscity = this.state.inputData.maillingAdresscity;

                    this.state.inputData.homeAddressstate = this.state.inputData.maillingAdressstate;
                    this.state.varValue.homeAddressstate = this.state.inputData.maillingAdressstate;

                    this.state.inputData.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;
                    this.state.varValue.homeAddresspostalCode = this.state.inputData.maillingAdresspostalCode;

                    this.state.inputData.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.varValue.homeAddresscountry = this.state.inputData.maillingAdresscountry;
                    this.state.inputData.homeAddressFieldShow = true;
                    this.state.varValue.homeAddressFieldShow = true;
                    this.forceUpdate()
                })
            }


        } else if (data.varName === "nreFlag") {
            this.state.inputData.chairmanCertificatenonResident = this.state.inputData.nreFlag;
            this.state.varValue.chairmanCertificatenonResident = this.state.inputData.nreFlag;
            this.forceUpdate();
        } else if (data.varName === "fincoreDataUpdatesectorCode" || data.varName === "sectorCode") {
            this.state.inputData.sectorCodeApicall = false;
            let codeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + this.state.inputData[data.varName]["key"];//{sectorCode}
            axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.findByVarNameApiData("fincoreDataUpdatesubSectorCode", response.data)
                    this.findByVarNameApiData("subSectorCode", response.data)
                    this.state.inputData.sectorCodeApicall = true;
                    this.forceUpdate()
                })
                .catch((error) => {
                    console.log(error);
                })
        } else if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3" || data.varName === "mispriorityMonitoringRmCodeFreeCode3") {
            let getData = "";
            if (data.varName === "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3") {
                getData = "fincoreDataUpdatermCodeWealthManagerCodeFreeCode3"
            } else {
                getData = "mispriorityMonitoringRmCodeFreeCode3"

            }
            let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + getData;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        errorMessage: "RM Code Is Not Found,Please again Try!",
                        errorModalBoolean: true
                    })
                })
                .catch((error) => {
                    console.log(error)

                })
        } else if (data.varName === "basicInformationUpdatedob") {
            this.state.inputData.basicInformationUpdatedobApiCall = "NO";
            this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
            this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N";
            setTimeout(() => {
                let x = 18;
                let someDate = new Date();
                let numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());

                let inputDate = new Date(this.state.inputData.basicInformationUpdatedob);

                if (stringToDate >= inputDate) {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "N";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "N"
                } else {
                    this.state.inputData.basicInformationUpdatedobApiCall = "YES";
                    this.state.inputData.basicInformationUpdateminorStatusMinorIndicator = "Y";
                    this.state.varValue.basicInformationUpdateminorStatusMinorIndicator = "Y"
                }

                x = 60;
                someDate = new Date();
                numberOfDaysToAdd = x;
                someDate.setFullYear(someDate.getFullYear() - numberOfDaysToAdd);
                date = someDate.getDate();
                month = someDate.getMonth() + 1;
                year = someDate.getFullYear();
                convertDate = year + "-" + month + "-" + date;
                newDate = new Date(convertDate);
                stringToDate = new Date(newDate.toDateString());

                inputDate = new Date(this.state.inputData.basicInformationUpdatedob);
                if (stringToDate >= inputDate) {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "Y";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "Y"
                } else {
                    inputData.basicInformationUpdateseniorCitizenIndicator = "N";
                    varValue.basicInformationUpdateseniorCitizenIndicator = "N"
                }

                this.forceUpdate()

            }, 1000)

        }
    };

    renderForm = () => {
        if (this.state.getData && this.state.inputData.freeFlag2 !== "linkedCB" && this.state.inputData.nomineeChange === true) {
            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, MAKERJsonFormForCasaIndividual, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                    {
                        this.renderNomineeUpdateForm()
                    }
                    {CommonJsonFormComponent.renderJsonForm(this.state, MAKERJsonFormForCasaIndividualLast, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                </>
            )
        } else if (this.state.getData && this.state.inputData.freeFlag2 === "linkedCB") {
            return (
                <>
                    {
                        CommonJsonFormComponent.renderJsonForm(this.state, jsonForOnlyLinkedCB, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
                    }
                </>
            )
        }
    };

    renderNomineeUpdateForm = () => {
        return (
            <>
                {this.renderExistingNomineeForm()}
                <Grid item xs={12}></Grid>
                {this.renderNewNomineeForm()}
                <Grid item xs={6}>
                    {this.renderNomineeNumber()}
                </Grid>
                {this.renderNomineeForm()}
            </>
        )
    };

    renderLabel = () => {
        if (this.state.inputData.accountNumber && this.state.getData) {
            return (
                <>
                    <Label linkedCB={this.state.linkedCB} stopLoading={this.stopLoading} classes={this.props}
                           cbNumber={this.state.inputData.cbNumber}
                           accountNumber={this.state.inputData.accountNumber}
                           crmStatus={this.state.inputData.crmStatus}/>
                    <Grid item xs={12}></Grid>
                    {this.renderSignature()}
                </>
            )
        } else {
            this.renderSignature()
        }
    };

    renderSignature = () => {
        // if (this.state.getData) {
        //     return <SignatureButton accountNumber={this.state.inputData.accountNumber} signatureType="multiple"
        //                             classes={this.props}/>
        // }
        if (this.state.getData) {
            return <a className="btn btn-sm btn-danger" style={{"color": "#ffffff", "margin-top": "15px"}}
                      onClick={() => this.openSignature(this.state.inputData.accountNumber)}>Signature Card View</a>
        }
    };

    openSignature = (accountNumber) => {
        let url = frontEndServerURL + "/signatureCardView/" + accountNumber;
        window.open(url)
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    {this.renderLabel()}
                    <Grid item xs={12}>
                        <br/>
                        {this.renderDownLoadFileLink()}
                        <br/>
                    </Grid>
                    {this.renderForm()}
                </React.Fragment>
            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            console.log(option.title);
            this.state.inputData["numberOfNominee"] = parseInt(this.state.getInitialNewNominee) + parseInt(option.title);
            this.forceUpdate();

            if (option.title > 0) {
                var sl;
                let newSl;
                let objectForNominee = [];
                let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
                for (var i = 0; i < option.title; i++) {
                    newSl = i + 1;
                    sl = oldNominee + parseInt(this.state.getInitialNewNominee) + (i + 1);
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "Nominee  " + sl,
                            "grid": 12,
                        },
                        {
                            "varName": "search" + sl,
                            "type": "select",
                            "enum": [
                                "CIF ID",
                                "INFORMATION",
                            ],
                            "required": true,
                            "onKeyDown": true,
                            "label": "Input CIF ID/INFORMATION ?",
                            "grid": 6,


                        },
                        {
                            "varName": "nomineecifId" + sl,
                            "type": "textApiCall",
                            "label": "CIF ID",
                            "grid": 6,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",
                        },

                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",

                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "required": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],


                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },

                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        /*  {
                                "varName": "nomineenomineephotoIdNo" + i,
                                "type": "text",
                                "label": "NOMINEE PHOTO ID NO.",
                                "grid": 6,
                                "conditional": true,
                                "conditionalVarName": "nomineecifIdApiCall" + i,
                                "conditionalVarValue": true,
                            },
            */
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "text",
                            "label": "D.O.B(YYYY-MM-DD)",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 6,
                            "onKeyDown": true,
                            // "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },


                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            /*    "conditional": true,
                                "conditionalVarName": "search" + sl,
                                "conditionalVarValue": "CIF ID",*/
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            /*  "conditional": true,
                              "conditionalVarName": "search" + sl,
                              "conditionalVarValue": "INFORMATION",*/
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,


                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },
                    )

                }
                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true
                });
            }
        } else {
            let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
            let totalNominee = oldNominee + this.state.inputData["numberOfNominee"];
            for (let i = 0; i < totalNominee; i++) {
                this.state.inputData["nomineeSearch" + (i + 1)] = false;
                this.state.varValue["nomineeSearch" + (i + 1)] = false;
            }
            this.state.inputData["numberOfNominee"] = parseInt(this.state.getInitialNewNominee);
            this.setState({
                objectForNominee: [],
                getNominee: false
            });
            this.forceUpdate();
        }
    };

    renderNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.nomineeChange) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common" style={{"font-size": "15px", "font-weight": "bold"}}>Number
                            Of New Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={this.state.inputData["numberOfNominee"]} options={numberOfNominee}
                                      getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"
                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>
            )
        }
    };

    renderFileUpload = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <Grid item xs={12}>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                </Grid>
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>

                    <div style={{
                        "border-top-style": "ridge",
                        "border-right-style": "ridge",
                        "border-bottom-style": "ridge",
                        "border-left-style": "ridge"
                    }}>
                        <b>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>

            )
        }

    };

    handleSubmitDraft = () => {
        this.setState({
            disabled: true,
            loading: true,
        });
        if (this.state.inputData.titleChange && this.state.fileUploadData.scanningFile !== undefined) {
            let fileUploadPath = backEndServerURL + "/case/upload";
            let types = "Attachments-for-title-change";
            let files = this.state.fileUploadData.scanningFile;
            let formData = new FormData();
            formData.append("appId", this.props.appId);
            formData.append("file", files);
            formData.append("type", types);
            axios({
                method: 'post',
                url: fileUploadPath,
                data: formData,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        let variableSetUrl = backEndServerURL + "/save/" + this.props.appId;
        let data = this.state.inputData;
        data.makerSaveFlag = true;
        axios.post(variableSetUrl, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                console.log(response.data);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Draft!",
                    alert: true,
                    disabled: false,
                    loading: false,
                });
                this.props.closeModal();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    disabled: false,
                    loading: false,
                });
            });
    };

    renderButton = () => {
        if (this.state.getData) {
            return (

                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmitDraft}
                    >Save
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmitReturn}
                    >Return
                    </button>
                </div>

            )
        }
    };

    closeModal = () => {
        this.setState({
            getMappingCropImage: false,
            errorModalBoolean: false,
            imageCropModal: false
        });
        this.renderImageCrop()
    };

    renderExistingNomineeForm = () => {
        if (this.state.getData && this.state.inputData.nomineeChange && this.state.oldNominee && this.state.oldNomineeData.length > 0) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.oldNomineeData, this.updateComponent, this.onKeyDownForExistingNominee, this.onKeyChangeForExistingNominee)
            )

        }
    };

    onKeyChangeForExistingNominee = (data) => {
        let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
        for (let i = 0; i < oldNominee; i++) {
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                setTimeout(() => {
                    let x = 18;
                    let someDate = new Date();
                    someDate.setFullYear(someDate.getFullYear() - x);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate || (isNaN(inputDate.getTime()))) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }
                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }
    };

    renderImageCrop = () => {
        if ((backEndServerURL + "/signaturePhoto/" + this.props.appId + "/signature" !== undefined)) {
            return (

                <img width='100%' src={backEndServerURL + "/signaturePhoto/" + this.props.appId + "/signature"}
                     alt=""/>
            )
        }
    };

    cropImage = (event) => {
        event.preventDefault();
        this.setState({
            imageCropModal: true
        })
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}
                </div>
            )
        }
    };

    renderCropedImage = () => {
        if (this.state.getData) {
            return (

                <button
                    style={{}}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingCropImage}>
                    Signature Card
                </button>

            )
        }
    };

    mappingCropImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingCropImage: true
        })
    };

    closeCropImage = () => {
        this.setState({
            getMappingCropImage: false
        })
    };

    renderSignatureCardCrop = () => {
        if (this.state.getData) {
            return (

                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',

                    }}

                    type='button' value='add more'
                    onClick={this.cropImage}
                >Signature Card Crop
                </button>

            )
        }
    };

    renderSignatureCardDelete = () => {
        if (this.state.getData) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'right',
                    }}
                    type='button' value='add more'
                    onClick={this.handleDeleteSignature}
                >Delete Existing Signature
                </button>
            )
        }
    };

    handleDeleteSignature = () => {
        this.setState(prev => ({
            signatureDeleteButton: !prev.signatureDeleteButton
        }))
    };

    handleSignatureDelete = (inputValue, selectedArray) => {
        console.log(selectedArray);
        this.setState(prev => ({
            inputData: {...inputValue, signatureDeleteFlag: selectedArray},
            signatureDeleteButton: false
        }));
    };

    renderNomineeForm = () => {
        if (this.state.objectForNominee.length > 0 && this.state.getNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    renderNewNomineeForm = () => {
        if (this.state.existingObjectForNominee.length > 0 && this.state.existingNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.existingObjectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChange)
            )
        }
    };

    renderBearerForm = () => {
        if (!this.state.loading) {
            return CommonJsonFormComponent.renderJsonForm(this.state, bearer, this.updateComponent)

        }
    };

    viewCheckList = () => {
        if (this.state.getData && this.state.inputData.titleChange) {
            return (
                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false,
            mandateModal: false
        })
    };

    triggerExistingMandate = (cbNumber) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.triggerExistingMandateDialog(cbNumber)}
            >
                Edit
            </button>
        )
    };

    triggerExistingMandateDialog = (cbNumber,relationCode) => {
        this.setState({
            mandateModal: true,
            existingMandateCb: cbNumber,
            existingRelationCode: relationCode,
        });
    };

    createMandateTableData = (sl, cb, name, relationship, action) => {

        return ([
            sl, cb, name, relationship, action
        ])

    };

    renderMandateData = () => {
        if (this.state.mandateTableArray.length > 0 && !this.state.loading && this.state.getData) {
            return (
                <>
                    <br/>
                    <div>
                        <div style={{"border-style": "groove", "border-width": "1px"}}>
                            <b>Existing Mandate:</b>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Sl", "CB Number", "Customer Name", "Relationship Code", "Action"]}
                                tableData={this.state.mandateTableArray}
                                tableAllign={['left', 'left', 'left', 'left', 'left']}
                            />
                            <br/>
                        </div>
                    </div>
                    <br/>
                </>
            )
        }
    };

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/Attachments-for-title-change/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    documentList: response.data,
                    getDocument: true
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    renderBulkFileForTitleChange = () => {
        if (this.state.getDocument && this.state.documentList.length > 0 && this.state.inputData.titleChange) {
            return (
                this.state.documentList.map((document, index) => {
                    return (
                        <React.Fragment>
                            <a className="btn btn-outline-primary btn-sm"
                               style={{
                                   verticalAlign: 'middle',
                                   marginTop: '12px',
                                   marginRight: '10px',
                               }}
                               target="_blank"
                               href={backEndServerURL + "/file/" + document + "/" + sessionStorage.getItem("accessToken")}>Document {index + 1}</a>

                        </React.Fragment>
                    )
                })
            );
        }
    };

    renderFlowSummeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   customerName={this.state.inputData.customerName}
                                   accountNumber={this.state.inputData.accountNumber}
                                   solId={this.state.inputData.freeFlag3}/>
            )
        }
    };

    renderDownLoadFileLink = () => {
        if (this.state.getData && this.state.getDownloadListFlag) {
            return (this.state.downLoadFileList.map((button) => {
                    return button
                })
            )
        } else {
            return <h5><b>{this.state.fileNotFoundTittle}</b></h5>
        }
    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>
                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>
                    <ThemeProvider theme={theme}>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.errorModalBoolean}>
                            <DialogContent className={classes.dialogPaper}>
                                <ErrorModal title={this.state.errorMessage} closeModal={this.closeModal}
                                            subServiceType={this.props.subServiceType} appId={this.props.appId}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.imageCropModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <ImageCrop subServiceType={this.props.subServiceType} appId={this.props.appId}
                                           closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            open={this.state.checkList}>
                            <DialogContent>
                                <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.getMappingCropImage}>
                            <DialogContent className={classes.dialogPaper}>
                                <AssignedCropImage subServiceType={this.props.subServiceType}
                                                   appId={this.props.appId}
                                                   closeModal={this.closeModal}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.mandateModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <ExistingMandateDetails closeModal={this.checkListClose} appId={this.props.appId}
                                                        cbNumber={this.state.existingMandateCb}
                                                        existingRelationCode={this.state.existingRelationCode}
                                                        saveMode={this.state.inputData.makerSaveFlag !== undefined}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlertTwo}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmationTwo}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.confirmationAlert}>
                            <DialogContent className={classes.dialogPaper}>
                                <ConfirmationModal
                                    closeModal={this.closeConfirmation}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="xl"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.signatureDeleteButton}>
                            <DialogContent className={classes.dialogPaper}>
                                <SignatureCardDelete
                                    editMode={true}
                                    accountNumber={this.state.inputData.accountNumber}
                                    inputData={this.state.inputData}
                                    handleSignatureDelete={this.handleSignatureDelete}
                                    handleDeleteSignature={this.handleDeleteSignature}
                                />
                            </DialogContent>
                        </Dialog>
                    </ThemeProvider>
                    <Grid container spacing={0}>
                        <ThemeProvider theme={theme}>
                            {this.renderNotification()}
                            {this.renderJsonFormFirst()}
                            {this.renderBearerForm()}
                        </ThemeProvider>
                    </Grid>
                    {this.renderDefferalData()}
                    {this.renderMandateData()}
                    <ThemeProvider theme={theme}>
                        {this.renderFileUpload()}
                        {this.renderBulkFileForTitleChange()}
                        <div style={{marginTop: "5px"}}>
                            {this.renderSignatureCardCrop()}
                            &nbsp;
                            {this.renderCropedImage()}
                            &nbsp;
                            {this.renderSignatureCardDelete()}
                        </div>
                        <Grid item xs={12}>
                            <br/>
                            {this.renderFlowSummeryButton()}
                        </Grid>
                        <br/>
                        {this.viewCheckList()}
                        {this.renderRemarksData()}
                        {this.renderRemarks()}
                    </ThemeProvider>
                    {this.renderButton()}
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(VerifyMakerAccountMaintenance);