import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import Table from "../Table/Table.jsx";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CustomerCreditCard from "./CustomerCreditCard";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";


const styles = theme => ({
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${100}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    root: {
        display: 'flex',
        flexWrap: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },

    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

});

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class StatusOfPropagation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            varValue: [],
            getData: false,
            SingleCards: false,

        }
    }

    SingleCards() {
        this.setState({
            SingleCards: true,
        });
    }

    closeSingleCardsModal = () => {
        this.setState({
            SingleCards: false,

        });
    };

    close = () => {
        this.props.closeModal()
    };

    render() {
        const {classes} = this.props;
        return (
            <section>
                <Dialog

                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.SingleCards}
                    onClose={this.closeSingleCardsModal}

                >
                    <DialogContent className={classes.dialogPaper}>

                        <CustomerCreditCard/>


                    </DialogContent>
                </Dialog>
                {/* <Dialog

                    className={classes.modal}
                    open={this.state.SingleCards}
                    fullWidth="true"
                    maxWidth="md"
                    TransitionComponent={Transition}
                    onClose={this.closeSingleCardsModal}
                >

                    <CustomerCreditCard />
                </Dialog>*/}

                <center>
                    <GridContainer>

                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                    <h4 style={{color: "white"}} >Status of Propagation Of Data To Other Systems<a><CloseIcon onClick={this.close}
                                                                                                    style={{
                                                                                                        position: 'absolute',
                                                                                                        right: 10,
                                                                                                        color: "#000000"
                                                                                                    }}/></a></h4>

                                </CardHeader>
                                <CardBody>

                                    <div className={classes.root}>
                                        <Grid container spacing={1}>
                                            <Grid container item xs={12} spacing={5}>
                                                <Grid item xs={12}>

                                                    <Paper className={classes.paper}>

                                                        <Table
                                                            tableHeaderColor="primary"
                                                            tableHead={["Customer Id", "System", "Propagation Status", "Type", "Field", "Effective date", "Ineffective  Date", "Process Date"
                                                            ]}
                                                            tableData={[]}
                                                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                                        />

                                                    </Paper>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </center>
            </section>

        );
    }
}

export default withStyles(styles)(StatusOfPropagation);
