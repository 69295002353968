import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import LabelDetailsModal from "../Maintenance/LabelDetails";
import {Dialog} from "@material-ui/core";

class NocMakerLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            varValue: {},
            inputData: {
                ...this.props.data,
            },
            showValue: true,
            labelGrid: this.props.labelGrid
        }
    }


    componentDidMount() {

    }

    labelDetails = (status) => {
        this.setState({
            labelName: status,
            labelOpen: true
        })
    };

    labelCloseModal = () => {
        this.setState({
            labelOpen: false
        })
    };

    render() {
        const {classes} = this.props.classes;

        return (

            <div style={{
                "border-top-style": "ridge",
                "border-bottom-style": "ridge",
                paddingLeft: this.props.padding === 10 ? "15px" : '5px'
            }}>
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        <Grid item xs={12}>


                        </Grid>


                        <Grid item xs={this.state.labelGrid[0]}>
                            <label><font size="2"><b> Loan Accounts :</b></font></label>

                            {this.state.inputData.loanAccounts}
                        </Grid>
                        <Grid item xs={this.state.labelGrid[1]}>
                            <label><b><font size="2">Loan Type :</font></b> </label>
                            {this.state.inputData.loanType}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[2]}>
                            <label><font size="2"><b> Casa Account :</b></font></label>

                            {this.state.inputData.casaAccount}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[3]}>
                            <label><b><font size="2">Loan Account Balance :</font></b> </label>
                            {this.state.inputData.loanAccountBalance}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[4]}>
                            <label><font size="2"><b> Casa Account Status :</b></font></label>

                            {this.state.inputData.casaAccountStatus}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[5]}>
                            <label><b><font size="2">Daily Interest :</font></b> </label>
                            {this.state.inputData.dailyInterest}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[6]}>
                            <label><font size="2"><b> Loan Account Expiry :</b></font></label>

                            {this.state.inputData.loanAccountExpiry}
                        </Grid>


                        <Grid item xs={this.state.labelGrid[7]}>
                            <label><b><font size="2">Casa Balance :</font></b> </label>
                            {this.state.inputData.casaBalance}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[8]}>
                            <label><font size="2"><b> Early Settlement Fee :</b></font></label>

                            {this.state.inputData.earlySettlementFee}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[9]}>
                            <label><b><font size="2">VAT :</font></b> </label>
                            {this.state.inputData.vat}
                        </Grid>

                        <Grid item xs={this.state.labelGrid[10]}>
                            <label><font size="2"><b> Excise Duty :</b></font></label>

                            {this.state.inputData.exciseDuty}
                        </Grid>


                    </ThemeProvider>
                </Grid>

                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.labelOpen}>
                    <DialogContent className={classes.dialogPaper}>
                        <LabelDetailsModal closeModal={this.labelCloseModal} appId={this.state.appId}
                                           labelName={this.state.labelName} data={this.state.inputData}/>

                    </DialogContent>
                </Dialog>
            </div>

        );
    }


}

export default NocMakerLabel;