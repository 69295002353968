import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import CardHeader from "../../../Card/CardHeader";
import Card from "../../../Card/Card";
import CloseIcon from '@material-ui/icons/Close';
import GridItem from "../../../Grid/GridItem";
import CardBody from "../../../Card/CardBody";
import GridContainer from "../../../Grid/GridContainer";
import FileMapping from "../../CommonComponent/FileMapping";
import FileMappingEdit from "../../CommonComponent/FileMappingEdit";
import ErrorModal from "../../CommonComponent/ErrorModal";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteModal from "../DeleteModal";
import MyValidation from "../../../JsonForm/MyValidation";
import FunctionForGetAcDetails from "../../CommonComponent/FunctionForGetAcDetails";
import CommonApi from "../../Maintenance/common/CommonApi";

const arrayListBond = [

    {label: 'APPLICANT PASSPORT'},
    {label: 'APPLICANT EMPLOYMENT AGREEMENTS'},
    {label: 'APPLICANT WORK PERMITS'},
    {label: 'APPLICANT ETN'},
    {label: 'OTHERS'},


];
const arrayListSanchaypatra = [

    {label: 'APPLICANT BB FORM'},
    {label: 'APPLICANT DEBIT INSTRUCTION'},
    {label: 'APPLICANT NID'},
    {label: 'APPLICANT ETN'},
    {label: 'APPLICANT NOMINEE NID'},


];
let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "CS Remarks",
        "grid": 12
    }];
let commonJson = [
    {
        "varName": "selectTypeOfRequest",
        "type": "select",
        "enum": [
            "BOND",
            "SANCHAYPATRA"
        ],
        "label": "SELECT TYPE OF REQUEST",
        "grid": 2,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "5 Years Bangladesh Sanchaya Patra",
            "3 Months Interest Based Sanchaya Patra",
            "Family Sanchaya Patra",
            "Pensioners Savings Certificate",
        ],
        "conditional": true,
        "conditionalVarName": "selectTypeOfRequest",
        "conditionalVarValue": "SANCHAYPATRA",
        "label": "SELECT TYPE OF PROJECT",
        "grid": 2,
    },
    {
        "varName": "selectTypeOfProject",
        "type": "select",
        "enum": [
            "Wage Earners Development Bond",
            "U.S. Dollar Investment Bond",
            "U.S. Dollar Premium Bond"
        ],
        "conditional": true,
        "conditionalVarName": "selectTypeOfRequest",
        "conditionalVarValue": "BOND",
        "label": "SELECT TYPE OF PROJECT",
        "grid": 2,
    },
    {
        "varName": "selectMaintenanceType",
        "type": "select",
        "enum": [
            "NOMINEE CHANGE",
            "ACES ENROLLMENT",
            "ACCOUNT NUMBER CHANGE",
            "DUPLICATE ISSUANCE",
            "CERTIFICATE"
        ],
        "label": "SELECT MAINTENANCE TYPE",
        "grid": 2,
    }

];


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        transform: 'translateZ(0)',
    },

};


class CSEnrollmentInbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            other: [],
            loading: false,
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            getMappingAllImage: false,
            uploadModal: false,
            accountDataBoolean: false,
            errorModalBoolean: false,
            errorTittle: "",
            uploadedFileName: "",
            countUploadedFileArray: [],
            documentArray: [],
            deleteFile: false,
            fileName: ""
        };
    }

    close = () => {
        this.props.closeModal()
    };

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {
        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {
        if (this.state.getRemarks.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "User Name", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    changeReadOnly = (value) => {
        for(let item of this.props.commonJsonForm){
            if(item.varName === "accountNumber" || item.varName === "amount" || item.varName === "customerName"){
                item.readOnly = value;
            }
        }
    };

    componentDidMount() {
        this.setState({
            loading: true
        });

        let remarksArray = [];

        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.data.bond_checker_approval === "APPROVE") {
                        let jsonForm = commonJson.concat(this.props.commonJsonForm);
                        for (let i = 0; i < jsonForm.length; i++) {
                            jsonForm[i]["readOnly"] = true;
                        }
                    } else {
                        let jsonForm = commonJson.concat(this.props.commonJsonForm);
                        for (let i = 0; i < jsonForm.length; i++) {
                            jsonForm[i]["readOnly"] = false;
                        }
                    }
                    this.changeReadOnly(true);
                    this.functionForFileGet();
                    let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            response.data.map((data) => {
                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            });
                            this.setState({
                                getRemarks: remarksArray
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        });
                    let varValue = response.data;
                    this.setState({
                        getData: true,
                        inputData: varValue,
                        varValue: varValue,
                        showValue: true,
                        loading: false
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({loading: false})
                });
        }
    }

    functionForFileGet = () => {
        let imageUrl = backEndServerURL + "/case/files/e-advice/" + this.props.appId;
        axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let documentArray = [];
                response.data.map((data, index) => {
                    documentArray.push(this.createDocumentsTable(`Document ${index + 1}`, this.documentAction(data)))
                });
                this.setState({
                    countUploadedFileArray: response.data,
                    documentArray: documentArray
                });
                console.log(this.state.countUploadedFileArray)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    documentAction = (name) => {
        return (
            <>
                {this.state.varValue.hide_buttons !== "Y" && <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.deleteDocument(name)}
                >
                    <DeleteIcon/>
                </button>
                }
                &nbsp;
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        verticalAlign: 'middle',
                    }}
                    onClick={() => this.downloadDocument(name)}
                >
                    <GetAppIcon/>
                </button>
            </>
        )
    };

    downloadDocument = (name) => {
        let url = backEndServerURL + "/file/" + name + "/" + sessionStorage.getItem("accessToken");
        window.open(url)
    };

    deleteDocument = (name) => {
        this.setState({
            deleteFile: true,
            confirmationTitle: "Are You Sure You Want to Delete this File!",
            fileName: name
        })
    };

    functionForFileDelete = () => {
        this.setState({
            loading: true,
            deleteFile: false,
        });
        let postData = {};
        postData.appId = this.props.appId;
        postData.fileName = this.state.fileName;

        let imageUrl = backEndServerURL + "/caseFile/delete";
        axios.post(imageUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.functionForFileGet();
                    this.setState({
                        fileName: "",
                        loading: false
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    alert: true,
                    title: "Failed",
                    notificationMessage: "Something went wrong!"
                })
            });
    };

    confirmationDelete = (value) => {
        if (value === "ok") {
            this.functionForFileDelete()
        } else {
            this.setState({
                deleteFile: false,
                fileName: ""
            })
        }
    };
    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let error = MyValidation.defaultValidation(commonJson.concat(this.props.commonJsonForm), this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            if (this.state.accountDataBoolean) {
                this.setState({
                    errorModalBoolean: true,
                    errorTittle: "Invalid Account Number",
                });
                return 0;
            }
            let remarksData = {};
            let data = {...this.state.inputData};
            data.proprietorName = this.returnMaintenanceType();
            remarksData.remark = this.state.inputData.csRemarks;
            remarksData.map = {...data, csRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.changeReadOnly(false);
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true
                });
                this.props.closeModal()
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        }
    };


    returnMaintenanceType = () => {
        let proprietorName;
        switch (this.state.inputData.selectMaintenanceType) {
            case "NOMINEE CHANGE":
                proprietorName = "Nominee change – Others Service";
                break;
            case "ACES ENROLLMENT":
                proprietorName = "ACES Enrolment – Others Services";
                break;
            case "ACCOUNT NUMBER CHANGE":
                proprietorName = "Account Number Change – Others Services";
                break;
            case "DUPLICATE ISSUANCE":
                proprietorName = "Duplicate issuance – Others Services";
                break;
            case "CERTIFICATE":
                proprietorName = "Certificate – Others Services";
                break;
            default:
                proprietorName = this.state.inputData.selectMaintenanceType;
                break;
        }
        return proprietorName;
    };


    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, commonJson.concat(this.props.commonJsonForm), this.updateComponent, this.onBlurFunctionForApiCall, "")
            )
        }
    };

    handleClose = (event) => {
        event.preventDefault();
        let variableSetUrl = backEndServerURL + "/case/close/" + this.props.appId;
        axios.post(variableSetUrl, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    this.changeReadOnly(false);
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true
                    });
                    this.props.closeModal();
                }
            })
            .catch((error) => {
                console.log(error)
            });
    };


    renderButton = () => {
        if (this.state.getData && this.state.varValue.bond_checker_approval !== "APPROVE") {
            return (

                <div>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}
                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',

                        }}

                        type='button' value='add more'
                        onClick={this.handleClose}
                    >Close
                    </button>
                </div>

            )
        }
    };


    renderRemarks = () => {
        if (this.state.getData && this.state.varValue.bond_checker_approval !== "APPROVE") {

            return (

                CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)

            )
        }

    };


    closeUploadModal = () => {
        this.setState({
            uploadModal: false,
            getMappingAllImage: false
        })

    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };
    closeModal = () => {
        this.setState({

            getMappingAllImage: false,
            errorModalBoolean: false

        })
    };

    renderUploadButton = () => {
        if (this.state.getData && this.state.varValue.bond_checker_approval !== "APPROVE") {

            return (
                <button
                    style={{
                        width: 100,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.uploadModal}

                >
                    Upload File
                </button>
            )
        }
    };
    createDocumentsTable = (sl, action) => {
        return (
            [sl, action]
        )
    };
    renderPdfDownload = () => {
        if (this.state.getData && this.state.varValue.bond_checker_approval === "APPROVE" && this.state.countUploadedFileArray.length > 0) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>File Uploaded Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Sl", "Action"]}
                            tableData={this.state.documentArray}
                            tableAllign={['left', 'right']}
                        />
                        <br/>
                    </div>
                </div>

            )
        }
    };

    renderAssignedImage = () => {
        if (this.state.getData && this.state.inputData.previewImage) {
            return (
                <button
                    style={{
                        width: 120,

                    }}
                    className="btn btn-outline-primary btn-sm"

                    onClick={this.mappingAllImage}

                >
                    Preview Document
                </button>
            )
        }
    };

    viewFileName = (file) => {
        if (file !== undefined) {
            this.setState({
                uploadedFileName: file.name
            });
        }
    };

    renderMappingImageModal = () => {
        if (this.state.inputData.selectTypeOfRequest === "BOND") {
            return (
                <FileMapping dropDownOption={arrayListBond}
                             serviceType={this.state.inputData.selectTypeOfRequest}
                             appId={this.props.appId}
                             viewFileName={this.viewFileName}
                             closeModal={this.closeUploadModal}/>
            )
        } else {
            return (
                <FileMapping dropDownOption={arrayListSanchaypatra}
                             serviceType={this.state.inputData.selectTypeOfRequest}
                             appId={this.props.appId}
                             viewFileName={this.viewFileName}
                             closeModal={this.closeUploadModal}/>
            )
        }
    };
    renderMappingImageEditModal = () => {
        if (this.state.inputData.selectTypeOfRequest === "BOND") {
            return (
                <FileMappingEdit dropDownOption={arrayListBond}
                                 serviceType={this.state.inputData.selectTypeOfRequest}
                                 appId={this.props.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        } else {
            return (
                <FileMappingEdit dropDownOption={arrayListSanchaypatra}
                                 serviceType={this.state.inputData.selectTypeOfRequest}
                                 appId={this.props.appId}
                                 closeModal={this.closeUploadModal}/>
            )
        }
    };

    onBlurFunctionForApiCall = (event) => {
        event.preventDefault();
        let inputData = this.state.inputData;
        if (this.state.inputData.source!==undefined && this.state.inputData.source==="FINACLE" &&  this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null && (this.state.inputData.accountNumber).trim() !== "") {
            this.setState({
                loading: true
            });
            FunctionForGetAcDetails.getAccountInfo(this.state.inputData.accountNumber).then((response) => {
                if (this.state.inputData.selectTypeOfRequest === "BOND") {
                    inputData.cbNumber = response.data.cifId;
                    inputData.customerAccountNumber = this.state.inputData.accountNumber;
                    inputData.customerName = response.data.acctName
                } else {
                    inputData.cbNumber = response.data.cifId;
                    inputData.customerName = response.data.acctName;
                    inputData.accountNumber = this.state.inputData.accountNumber
                }
                this.state.inputData.accountNumberApiCall = "YES";
                this.updateComponent();
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    showValue: true,
                    loading: false,
                    accountDataBoolean: false,
                })
            }).catch((error) => {
                if (this.state.inputData.selectTypeOfRequest === "BOND") {
                    inputData.cbNumber = "";
                    inputData.customerAccountNumber = "";
                    inputData.customerName = "";
                } else {
                    inputData.cbNumber = "";
                    inputData.customerName = "";
                    inputData.accountNumber = ""
                }
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: error.response.data.message,
                    accountDataBoolean: true,
                })
            });
        }
        else if (this.state.inputData.source!==undefined && this.state.inputData.source==="ABABIL" &&  this.state.inputData.accountNumber !== undefined && this.state.inputData.accountNumber !== null && (this.state.inputData.accountNumber).trim() !== "") {
            this.setState({
                loading: true
            });
            CommonApi.ababilAccountDetails(this.state.inputData.accountNumber).then((response) => {
                if (this.state.inputData.selectTypeOfRequest === "BOND") {
                    inputData.cbNumber = response.data.cifId;
                    inputData.customerAccountNumber = this.state.inputData.accountNumber;
                    inputData.customerName = response.data.acctName
                } else {
                    inputData.cbNumber = response.data.cifId;
                    inputData.customerName = response.data.acctName;
                    inputData.accountNumber = this.state.inputData.accountNumber
                }
                this.state.inputData.accountNumberApiCall = "YES";
                this.updateComponent();
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    showValue: true,
                    loading: false,
                    accountDataBoolean: false,
                })
            }).catch((error) => {
                if (this.state.inputData.selectTypeOfRequest === "BOND") {
                    inputData.cbNumber = "";
                    inputData.customerAccountNumber = "";
                    inputData.customerName = "";
                } else {
                    inputData.cbNumber = "";
                    inputData.customerName = "";
                    inputData.accountNumber = ""
                }
                this.setState({
                    inputData: inputData,
                    varValue: inputData,
                    loading: false,
                    errorModalBoolean: true,
                    errorTittle: error.response.data.message,
                    accountDataBoolean: true,
                })
            });
        }

    };

    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.uploadModal}>
                        <DialogContent>
                            {this.renderMappingImageModal()}
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        fullWidth="true"
                        maxWidth="xl"
                        open={this.state.getMappingAllImage}>
                        <DialogContent>
                            {this.renderMappingImageEditModal()}
                        </DialogContent>
                    </Dialog>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                    <h4 style={{color: "white"}} >{this.props.category + " " + this.state.varValue.freeFlag3}<a><CloseIcon
                                        onClick={this.close} style={{
                                        position: 'absolute',
                                        right: 10,
                                        color: "#000000"
                                    }}/></a></h4>
                                </CardHeader>
                                <CardBody>
                                    <Grid container>
                                        <ThemeProvider theme={theme}>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="mD"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.errorModalBoolean}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <ErrorModal title={this.state.errorTittle}
                                                                closeModal={this.closeModal}
                                                                subServiceType={this.props.subServiceType}
                                                                appId={this.props.appId}
                                                    />

                                                </DialogContent>
                                            </Dialog>
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="sm"
                                                className={classes.modal}
                                                classes={{paper: classes.dialogPaper}}
                                                open={this.state.deleteFile}>
                                                <DialogContent className={classes.dialogPaper}>
                                                    <DeleteModal title={this.state.confirmationTitle}
                                                                 closeModal={this.confirmationDelete}/>
                                                </DialogContent>
                                            </Dialog>
                                            {this.renderNotification()}
                                            {this.renderJsonForm()}
                                            <Grid item xs={12}>
                                                {this.renderRemarksData()}
                                            </Grid>
                                            <Grid item xs={12}>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderPdfDownload()}
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                {this.renderUploadButton()}
                                            </Grid>
                                            <Grid item xs={1.5}>
                                                {this.renderAssignedImage()}
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.state.inputData.selectTypeOfProject && this.state.uploadedFileName !== "" &&
                                                <p style={{color: "red"}}>{this.state.uploadedFileName}</p>}
                                            </Grid>
                                        </ThemeProvider>
                                    </Grid>
                                    <ThemeProvider theme={theme}>
                                        {this.renderRemarks()}
                                    </ThemeProvider>
                                    {this.renderButton()}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            )
        }
    }
}

export default withStyles(styles)(CSEnrollmentInbox);

