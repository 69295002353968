import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../../../Grid/GridItem.jsx";
import GridContainer from "../../../Grid/GridContainer.jsx";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CloseIcon from '@material-ui/icons/Close';
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core";
import {merchantDeferralChecker} from "../../WorkflowJsonFormRashed";
import DefferalMaping from "../../CommonComponent/DefferalMaping";
import CSImageLinkEdit from "../../CASA/CSImageLinkEdit";
import DefferalMappingImageReadOnly from "../../CommonComponent/DefferalMappingImageReadOnly";
import LocalstorageEncrypt from "../../../JsonForm/LocalstorageEncrypt";
////import LocalstorageEncrypt from "../../../JsonForm/LocalstorageEncrypt";


let jsonFormForMbu = [
    {
        "varName": "mid",
        "type": "text",
        "label": "MID",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "merchantName",
        "type": "text",
        "label": "Merchant Name",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "proprietorName",
        "type": "text",
        "label": "Proprietor Name",
        "grid": 3,
        "readOnly": true,
    },
    {
        "varName": "merchantType",
        "type": "text",
        "label": "Merchant Type",
        "grid": 3,
        "readOnly": true,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

const proprietorDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'NID or Readable Passport'},
    {label: 'Passport Size Photograph'},
    {label: 'E-TIN Certificate'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const partnershipDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'NID or Readable Passport'},
    {label: 'Certified Copy of Partnership Deed'},
    {label: 'Partnership Resolution Letter with Organization Letter'},
    {label: 'Passport Size Photograph'},
    {label: 'E-TIN Certificate'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const llcDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Trade License'},
    {label: 'Memorandum, Certificate Incorporation, Articles of Association & Form XII'},
    {label: 'Certificate of commencement of Business'},
    {label: 'Board Resolution Letter with Power of Attorney or Delegation Related Documents for authorized person to operate banking/financial activities'},
    {label: 'NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares'},
    {label: 'Company E-TIN Certificate'},
    {label: 'Passport Size Photograph'},
    {label: 'EFTN Form'},
    {label: '18-A Form'},
    {label: 'Visiting Card'},


];

const othersDocuments = [

    {label: 'MERCHANT APPROVAL MEMO'},
    {label: 'KYC1'},
    {label: 'KYC2'},
    {label: 'MCAF1'},
    {label: 'MCAF2'},
    {label: 'MCAF3'},
    {label: 'MERCHANT TRANSACTION PROFILE'},
    {label: 'DECLARATION & REQUEST LETTER'},
    {label: 'Certificate of registration'},
    {label: 'List of executive body'},
    {label: 'Certificate of NGO Bureau or Other Regulatory'},
    {label: 'Certificate of Micro Credit Regulatory Authority or Other regulatory'},
    {label: 'Memorandum & Article of Association'},
    {label: 'Board Resolution or Extract regarding the authorized personal with attested sign'},
    {label: 'NID or Passport and Photo (2 copies) of the authorized person and Directors having 20% or more shares'},
    {label: 'EFTN Form'},
    {label: 'Visiting Card'},


];

class DeferralLifeCycleMBU extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: {},
            inputData: {},
            appId: 0,
            varValue: {},
            showValue: false,
            getData: false,
            fileUploadData: {},
            errorArray: {},
            errorMessages: {},
            selectType: "",
            title: "",
            alert: false,
            message: "",
            tableData: [],
            file: {},
            data: {},
            getImageData: false,
            arraygetImage: [],
            notificationMessage: "",
            uploadModal: false,
            getMappingAllImage: false,
            loading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    createTableData = (id, type, dueDate, remarks, approvedBy, status,) => {


        return ([type, dueDate, remarks, approvedBy, status,
        ])

    };
    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    componentDidMount() {

        let variableGetUrl = backEndServerURL + "/variables/" + this.props.appId;

        axios.get(variableGetUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {


                let url = backEndServerURL + "/deferral/infoForClosing/" + this.props.appId;
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        let tableArray = [];
                        console.log(response.data);
                        response.data.deferalList.map((deferal) => {
                            var status;
                            if (deferal.status === "ACTIVE") {
                                status = "Approved"
                            } else if (deferal.status === "APPROVAL_WAITING") {
                                status = "NOT APPROVED"
                            } else {
                                status = "Not Approved";
                            }
                             tableArray.push(this.createTableData(deferal.id, deferal.type,deferal.deferalCategory,deferal.deferalSubCategory,deferal.dueDate, deferal.appliedBy, deferal.applicationDate,deferal.deferalDstId,deferal.deferalRmCode, status))

                        });
                        this.setState({
                            tableData: tableArray,
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER") {
                    let deferralUrl = backEndServerURL + "/deferral/files/" + this.props.appId;
                    axios.get(deferralUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            this.setState({
                                getImageData: true,
                                arraygetImage: response.data,
                            })
                        })
                        .catch((error) => {

                        })
                }

                this.setState({
                    varValue: response.data,
                    inputData: this.copyJson(response.data),
                    showValue: true,
                    getData: true,
                    loading: false
                })

            })
            .catch((error) => {
                console.log(error);
            });


    }

    renderDeferralList = () => {
        if (this.state.getData) {
            return (
                <div>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b style={{padding: '10px'}}>Deferral List:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type","Category","Expire Days", "Expire Date", "Raise By", "Raise Date","DST ID","RM Code", "Status"]}                            tableData={this.state.tableData}
                            tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left']}
                        />

                        <br/>

                    </div>
                </div>


            )
        }
    };


    updateComponent = () => {
        this.forceUpdate();
    };


    handleSubmit = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
            let data = this.state.data;
            let url = backEndServerURL + "/deferral/closeCS/" + this.props.appId;
            axios.post(url, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    this.props.closeModal();
                    this.setState({
                        notificationMessage: "Deferral Close!!",
                        alert: true,
                        loader: false,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loader: false,
                    })
                })
        } else if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER") {

            let url = backEndServerURL + "/deferral/close/" + this.props.appId;
            axios.post(url, {}, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response.data);
                    this.close();
                    this.setState({
                        title: "Successful!",
                        notificationMessage: "Successfully Close!",
                        alert: true

                    })
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    uploadModal = () => {
        this.setState({
            uploadModal: true
        })
    };
    closeUploadModal = (data) => {

        console.log(data);
        this.setState({
            uploadModal: false,
            getMappingAllImage: false,
            data: data
        })
    };
    renderUploadButton = () => {

        if ((LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU")) {
            return (
                <Grid item xs={1}>
                    <button

                        className="btn btn-outline-primary btn-sm"

                        onClick={this.uploadModal}

                    >
                        Upload File
                    </button>
                </Grid>
            )
        }
    };
    mappingAllImage = (event) => {
        event.preventDefault();
        this.setState({
            getMappingAllImage: true
        })
    };


    renderAssignedImage = () => {

        if (LocalstorageEncrypt.encryptStorageFunction.getItem("roles") === "MERCHANT" && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER") {
            if (this.state.getImageData) {
                return this.state.arraygetImage.map((data) => {

                    return (
                        <React.Fragment>
                            <a
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: "middle",
                                    display: "block",
                                    marginTop: 20,
                                }}
                                target="_blank"
                                href={backEndServerURL + "/file/" + data.fineName + "/" + sessionStorage.getItem("accessToken")}
                            >
                                {data.type}
                            </a>
                            &nbsp;&nbsp;
                        </React.Fragment>

                    )

                })

            }
        }


    };


    renderDefferalImageEdit = () => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        <CSImageLinkEdit subServiceType={this.props.subServiceType} appId={this.props.appId}
                                         closeModal={this.closeUploadModal}/>
                    </DialogContent>
                </Dialog>
            )

        } else {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    open={this.state.getMappingAllImage}>
                    <DialogContent>
                        <DefferalMappingImageReadOnly subServiceType={this.props.subServiceType}
                                                      appId={this.props.appId} closeModal={this.closeUploadModal}/>
                    </DialogContent>
                </Dialog>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal()
    };

    renderForm = () => {
        if (this.state.getData && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MBU") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, jsonFormForMbu, this.updateComponent)
            )
        } else if (this.state.getData && LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "MOU_CHECKER") {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, merchantDeferralChecker, this.updateComponent)
            )
        }

    };

    render() {

        const {classes} = this.props;

        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>

                                <h4 style={{color: "white"}} >Deferral Close <a><CloseIcon onClick={this.close} style={{
                                    position: 'absolute',
                                    right: 10,
                                    color: "#000000"
                                }}/></a></h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    <Grid container spacing={3}>
                                        <ThemeProvider theme={theme}>
                                            {this.renderNotification()}
                                            {this.renderForm()}
                                            <Grid item xs={12}>
                                                {this.renderDeferralList()}
                                            </Grid>
                                            <Grid item xs={12}>
                                                {this.renderUploadButton()}
                                            </Grid>
                                            {this.renderAssignedImage()}
                                            <Dialog
                                                fullWidth="true"
                                                maxWidth="xl"
                                                open={this.state.uploadModal}>
                                                <DialogContent>
                                                    <DefferalMaping
                                                        subServiceType={this.props.subServiceType}
                                                        appId={this.props.appId}
                                                        closeModal={this.closeUploadModal}/>
                                                </DialogContent>
                                            </Dialog>
                                            {this.renderDefferalImageEdit()}
                                            <br/>
                                        </ThemeProvider>
                                    </Grid>
                                </div>

                                <br/>

                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        verticalAlign: 'middle',
                                    }}
                                    onClick={this.handleSubmit}

                                >
                                    Submit
                                </button>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }
    }


}

export default withStyles(styles)(DeferralLifeCycleMBU);
