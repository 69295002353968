function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));

    let returnObjectVariables = returnObject;

    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
    }
    return returnObject;
}
function makeConditionalGridObject(object) {
    if (object.hide !== true) {
        let returnObject = JSON.parse(JSON.stringify(object));
        let returnObjectVariables = returnObject;
        for (let i = 0; i < returnObjectVariables.length; i++) {
            if (returnObjectVariables[i]["grid"] === 4) {
                returnObjectVariables[i]["grid"] = 2;
            }
        }
        return returnObject;
    }
}


let AssetOperationForm = [
    {
        "label": "Lap Reference",
        "type": "text",
        "varName": "lapReference",
        "grid": 2,
        "required": true,
    },
    {
        varName: "customerCB",
        type: "textApiCall",
        label: "Customer CB",
        required: true,
        grid: 2,
    },
    {
        varName: "customerName",
        type: "text",
        "readOnly": true,
        label: "Customer Name",
        required: true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
        grid: 2,
    },
    {
        varName: "loanAccounts",
        type: "select",
        label: "Loan Accounts",
        grid: 3,
        required: true,
        "enum": [],
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "customerCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "productName",
        "type": "textApiCall",
        "required": true,
        "label": "Product Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "varName": "casaAccount",
        "type": "textApiCall",
        "required": true,
        "label": "SB Accounts",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        varName: "loanAmt",
        type: "text",
        label: "Loan Amount",
        grid: 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "PO Amount",
        "type": "text",
        "varName": "poAmount",
        "validation": "numeric",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Beneficiary  Name",
        "type": "text",
        "varName": "beneficiaryName",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Beneficiary  A/C Number",
        "type": "text",
        "varName": "beneficiaryAccount",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Bank/NBFIs Name",
        "type": "text",
         "varName": "bankName",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Source Type",
        "type": "select",
        "varName": "sourceType",
        "grid": 2,
        "enum": [
            "DST",
            "Branch",
            "Pay Roll"
        ],
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Branch/DST Team",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "varName": "branchName",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },

    {
        "label": "Pay Order Issue Date",
        "type": "date",
        "varName": "issueDate",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
    {
        "label": "Closing Letter To Be Provided by",
        "type": "text",
        "varName": "toBeProvidedBy",
        "grid": 3,
        "required": true,
        "conditional": true,
        "conditionalVarName": "loanAccountsCBApiCall",
        "conditionalVarValue": "YES",
    },
];

let AssetOperationReadOnlyForm = {};
AssetOperationReadOnlyForm = makeReadOnlyObject(makeConditionalGridObject(JSON.parse(JSON.stringify(
    [...AssetOperationForm]
))));


export {
    AssetOperationForm,
    AssetOperationReadOnlyForm,
};