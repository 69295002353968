import React, {Component} from "react";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import PropTypes from 'prop-types';
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import {buttonStyle, localization, options, tableIcons} from "../../DeliverableManagement/configuration";
import Button from "@material/react-button";

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
                fontSize: '0.8rem'
            }
        },

    }
});

class TableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 0,
            totalRow: 0,
            tableData: [],
            columns: [],
            loader: true,
        };
    }

    componentDidMount() {
        this.setState({
            pageNumber: this.props.page,
            totalRow: this.props.totalRow,
            tableData: this.props.tableData,
            columns: this.props.tableColumns,
            loader: this.props.loader,
        })
    }


    handleChangePage = (pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        if (this.props.handleChangePage !== undefined) {
            this.props.handleChangePage(page)
        }
    };

    renderButton = (props) => {
        if (this.props.tableButton !== undefined) {
            return (
                this.props.tableButton.map((item, index) => {
                    return (
                        <>
                            <Button
                                key={item + index}
                                style={item.style ? item.style : buttonStyle}
                                onClick={(event) => item.triggerFunction(event, props.data)}
                            >
                                {item.name}
                            </Button>
                            &nbsp;
                        </>
                    )
                })
            )
        }
    };

    render() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <CircularProgress style={{marginLeft: '50%'}}/>
                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div>
                            <MaterialTable
                                style={{whiteSpace: this.props.whiteSpace ? this.props.whiteSpace : "nowrap"}}
                                columns={this.state.columns}
                                data={this.state.tableData}
                                title={" "}
                                onRowClick={(e, rowData) => this.props.onRowClick ? this.props.onRowClick(e, rowData) : ""}
                                options={{
                                    ...options,
                                    //selection: this.props.selection,
                                    pageSize: this.props.rowsPerPage,
                                    ...(this.props.maxBodyHeight !== undefined) && {maxBodyHeight: this.props.maxBodyHeight},
                                     
                                }}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[this.props.rowsPerPage]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page)
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            {this.renderButton(props)}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

TableComponent.propTypes = {
    totalRow: PropTypes.number.isRequired,
    tableData: PropTypes.array.isRequired,
    tableColumns: PropTypes.array.isRequired,
    selection: PropTypes.bool.isRequired
};

export default TableComponent;
