import React, {Component} from "react";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import MyValidation from "../../JsonForm/MyValidation";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";

let jsonForm = [
    {
        "label": "Process Type",
        "varName": "processType",
        "type": "select",
        "enum": [
            "MERCHANT ONBOARDING",
            "DEBIT CARD",
            "INSTA DEBIT CARD",
            "WELCOME LETTER",
            "CHEQUE BOOK",
            "CARD CHEQUE BOOK",
            "INSTA CHEQUE BOOK",
            "Gift Card",
            "Reloadable Prepaid Card"
        ],
        "grid": 3,
        "required": true,
    },
    {
        "label": "Vendor Name",
        "varName": "vendorName",
        "type": "text",
        "grid": 3,
        "validation": "string",
        "required": true,
    },
    {
        "label": "Vendor Code",
        "varName": "vendorCode",
        "type": "text",
        "validation": "numeric",
        "grid": 3,
        "required": true,
    },
    {
        "label": "Vendor Email",
        "varName": "vendorEmail",
        "type": "text",
        "validation": "email",
        "grid": 3,
        "required": true,
    }
];
let populateForm = [
    {
        "label": "Process Type",
        "varName": "processType",
        "type": "text",
        "grid": 3,
        "required": true,
        "readOnly": true,
    },
    {
        "label": "Vendor Name",
        "varName": "vendorName",
        "type": "autoCompleteValueReturn",
        "onKeyDown": true,
        "enum": [],
        "grid": 3,
        "validation": "string",
        "required": true,
    },
    {
        "label": "Vendor Email",
        "varName": "vendorEmail",
        "type": "text",
        "multiline": true,
        "grid": 3,
        "required": true,
        "readOnly": true,
    }
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class MailNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            loading: true
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        if (this.props.closeModal !== undefined && this.props.processName !== undefined) {
            this.setState({
                loading: true
            });
            this.state.inputData.processType = this.props.processName;
            this.state.varValue.processType = this.props.processName;

            var url = backEndServerURL + "/getVendorEmailInfo/" + this.props.processName;

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = this.state.inputData;
                        let vendorName = [];
                        let responseData = response.data;
                        for (let item in responseData) {
                            vendorName.push({key: responseData[item].id, value: responseData[item].vendorName});
                        }
                        populateForm[1].enum = vendorName;

                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true,
                            loading: false
                        })
                    } else {
                        this.setState({
                            inputData: {},
                            varValue: {},
                            showValue: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    this.setState({
                        inputData: {},
                        varValue: {},
                        showValue: true,
                        loading: false
                    });
                    console.log(error)
                });
        } else {
            this.setState({
                loading: false
            })
        }
    }

    submitHandler = () => {
        let error = this.props.closeModal !== undefined ? false : MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else if (this.props.closeModal !== undefined) {
            this.props.closeModal(this.state.inputData);
        } else {
            let setUrl = backEndServerURL + "/saveVendorEmailInfo";
            axios.post(setUrl, this.state.inputData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            title: "Successful",
                            notificationMessage: "Master Data Save Successful!",
                            alert: true
                        });
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    onChangeHandler = () => {
        if (this.state.inputData.vendorName !== undefined) {
            this.setState({
                loading: true
            });

            var url = backEndServerURL + "/getVendorEmailInfoByVendorName/" + this.state.inputData.vendorName.key;

            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let inputData = this.state.inputData;
                        inputData.vendorEmail = response.data.vendorEmail;
                        inputData.vendorId = response.data.id;

                        this.setState({
                            inputData: inputData,
                            varValue: inputData,
                            showValue: true,
                            loading: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            this.forceUpdate();
        }
    };


    renderHeader = () => {
        if (this.props.closeModal !== undefined) {
            return (
                <h4 style={{color: "white"}} >
                    Mail Notification Request
                    <a><CloseIcon onClick={() => this.props.closeModal("cancel")} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a>
                </h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Mail Notification Request
                </h4>
            )
        }
    };

    renderJsonForm = () => {
        if (this.props.closeModal !== undefined && this.props.processName !== undefined) {
            return CommonJsonFormComponent.renderJsonForm(this.state, populateForm, this.updateComponent, "", this.onChangeHandler)
        } else {
            return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
        }
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            {this.renderHeader()}
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderJsonForm()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            {this.renderNotification()}

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    onClick={this.submitHandler}
                                >
                                    Submit
                                </button>
                            </Grid>
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }

}

export default withStyles(styles)(MailNotification)