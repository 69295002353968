import React, {Component, createRef} from "react";
import Card from "../../../Card/Card.jsx";
import CardHeader from "../../../Card/CardHeader.jsx";
import CardBody from "../../../Card/CardBody.jsx";
import "../../../../Static/css/RelationShipView.css";
import Grid from "@material-ui/core/Grid";
import {assetOpsBackEndServerURL} from "../../../../Common/Constant";
import axios from "axios";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Notification from "../../../NotificationMessage/Notification";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "../../../Table/Table";
import loader from "../../../../Static/loader.gif";
import MyValidation from "../../../JsonForm/MyValidation";
import SignatureButton from "../../Maintenance/SignatureButton.jsx";
import {lienMarkingJsonForm} from "../../WorkflowjsonFormForAssetOps";
import ConfirmAlert from "../../CommonComponent/ConfirmAlert";
import FlowSummeryButton from "../../CommonComponent/FlowSummeryButtonAwf";
import AssetCommonFunctions from "../AssetCommonFunctions";
import CBNotFound from "../../CASA/CBNotFound";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let csRemarks = [
    {
        "varName": "csRemarks",
        "type": "textArea",
        "label": "Remarks",
        "grid": 12
    }];

class CsLienMarking extends Component {
    constructor() {
        super();
        this.state = {
            appId: 0,
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            fileUploadData: {},
            inputData: {},
            showValue: false,
            varValue: {},
            getData: false,
            title: "",
            notificationMessage: "",
            alert: false,
            getRemarks: [],
            getImageLink: [],
            getImageBoolean: false,
            imageName: [],
            loading: true,
            uploadStatus: "",
            actionType: "",
            confirmAlert: false,
            cbNotFound: false,
            casaAccountApiCall: true,
        };

        this.file = createRef();
        this.functionForCheck = this.functionForCheck.bind(this)
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    copyJson = jsonObject => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (
                clone[prop] === "" ||
                clone[prop] === "null" ||
                clone[prop] === "undefined"
            )
                delete clone[prop];
        return clone;
    };
    emptyValueRemove = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        return clone;
        /* for (let prop in clone)
             if (clone[prop] === '' || clone[prop] === ' '  || clone[prop] === "null"   ||  clone[prop] === "undefined" )
                 delete clone[prop];
         return clone;*/
    };
    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArray = lienMarkingJsonForm;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (jsonObject.varName === searchVarname) {
                lienMarkingJsonForm[i].enum = getValue;
            }
        }


    };
    onBlurFunctionForApiCall = (event, varName, secondVarName) => {
        event.preventDefault();
        if ((varName === "customerCB") && (this.state.inputData.customerCB === undefined || this.state.inputData.customerCB === null || this.state.inputData.customerCB === "")) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {};
            inputData.customerCB = this.state.inputData.customerCB;
            this.setState({
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
                loading: false,
            });
        }
        else if (secondVarName === "customerCB") {
            let inputData = {...this.state.inputData};
            inputData.customerCBApiCall = "NO";
            inputData.loanAccountsCBApiCall = "NO";
            inputData.cbNumber = undefined;
            inputData.loanAccounts = undefined;
            this.setState({
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
                showValue: false,
                loading: true,
            });
            this.forceUpdate();
            let url = assetOpsBackEndServerURL + "/getActiveLoanAccountList/" + this.state.inputData[secondVarName];
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    inputData.cbNumber = this.state.inputData[secondVarName];
                    inputData.customerName = response.data.name;
                    this.findByVarNameApiData("loanAccounts", response.data.accounts);
                    inputData.customerCBApiCall = "YES";
                    this.setState({
                        showValue: true,
                        varValue: this.emptyValueRemove(inputData),
                        inputData: this.emptyValueRemove(inputData),
                        loading: false,
                        casaAccountApiCall: true,
                    })


                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        errorModalBoolean: true,
                        errorTittle: "Invalid Customer  ID",

                    })
                })
        }
        else if (secondVarName === "lienAccount") {
            this.setState({
                loading: true,
            });
            let inputData = {...this.state.inputData};
            this.forceUpdate();
            let getAccountUrl = assetOpsBackEndServerURL + "/getAccountInfo/" + this.state.inputData.lienAccount;
            axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((getAcResponse) => {
                    console.log(getAcResponse)
                    this.setState({
                        inputData: inputData,
                        varValue: this.emptyValueRemove(inputData),
                        loading: false,
                        casaAccountApiCall: true,
                    });
                }).catch((error) => {
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        inputData: inputData,
                        varValue: this.emptyValueRemove(inputData),
                        loading: false,
                        casaAccountApiCall: false,
                    })
            });
        }
    }
    onKeyDownChange = (data) => {
        if (data.varName === "loanAccounts" && (this.state.inputData[data.varName] === undefined || this.state.inputData[data.varName] === null)) {
            console.log(this.state.inputData);
            this.setState({
                loading: true
            });
            let inputData = {...this.state.inputData};
            inputData.loanAccountsCBApiCall = "NO";

            lienMarkingJsonForm.map((field) => {
                if(field.conditional===true){
                    if (inputData[field.conditionalVarName] !== field.conditionalVarValue) {
                        inputData.field=undefined;
                    }
                }
            });
            this.setState({
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
                loading: false,
            });
        }
        else if (data.varName === "loanAccounts" && this.state.inputData[data.varName] !== undefined && this.state.inputData[data.varName] !== null) {
            let inputData = this.emptyValueRemove(this.state.inputData);
            inputData.loanAccountsCBApiCall = "NO";
            this.setState({
                loading: true,
                inputData: inputData,
                varValue: this.emptyValueRemove(inputData),
            })
            let postData = {
                "loanAccounts": this.state.inputData[data.varName],
                "cbNumber": this.state.inputData.cbNumber,
                "loanAccountDetails": "Y",
                "loanGeneralInquiry": "N",
                "exciseDuty": "N",
                "loanAccountInfo": "Y",
                "getCustomAcctDetails": "Y"
            };
            AssetCommonFunctions.getCustLoanAccountDetails(postData)
                .then((custLoanAccountDetailsResponse) => {
                    console.log(custLoanAccountDetailsResponse.data.loanAccountInfoResponse)
                    inputData.productName = custLoanAccountDetailsResponse.data.loanAccountDetailsResponse.responseData.productName;
                    inputData.loanOutstanding = custLoanAccountDetailsResponse.data.loanAccountInfoResponse.closingBal;
                    inputData.lienAccount =  custLoanAccountDetailsResponse.data.loanAccountInfoResponse.operAcctId;
                    inputData.accountNumber = this.state.inputData[data.varName];
                    inputData.casaAccountStatus = custLoanAccountDetailsResponse.data.customAcctDetailsResponse.acctStatus;
                    inputData.loanAccountsCBApiCall = "YES";

                    this.setState({
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(inputData),
                        loading: false,
                        casaAccountApiCall: true,
                })

                })
                .catch((error) => {
                    console.log(error);
                    this.forceUpdate();
                    this.setState({
                        cbNotFound: true,
                        title: error.response.data.message,
                        inputData: this.emptyValueRemove(inputData),
                        varValue: this.emptyValueRemove(inputData),
                        loading: false
                    })
                })
        }
    }
    componentDidMount() {

        if (this.props.appId !== undefined) {

            let url = assetOpsBackEndServerURL + "/variables/" + this.props.appId;

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    let imageUrl = assetOpsBackEndServerURL + "/case/files/" + this.props.appId;
                    axios.get(imageUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            console.log(response.data);
                            this.setState({
                                getImageLink: response.data,
                                getImageBoolean: true,
                                imageName: response.data,
                                showValue: true,
                            })
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                    this.setState({
                        varValue: this.copyJson(response.data),
                        inputData: this.copyJson(response.data),
                        appId: this.props.appId,
                        showValue: true,
                    });


                    let remarksArray = [];
                    let getCommentsUrl = assetOpsBackEndServerURL + "/appRemarkGet/" + this.props.appId;
                    axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {

                            console.log(response.data);
                            response.data.map((data) => {

                                remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                            })
                            this.setState({
                                getRemarks: remarksArray,
                                getData: true,
                                showValue: true,
                                loading: false
                            })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            let start_url = assetOpsBackEndServerURL + "/startCase/cr_case_create_lien_marking";
            axios
                .get(start_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    this.setState({
                        appId: response.data.id,
                        loading: false,
                        getData: true,
                        showValue: true,
                    });


                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        getData: true,
                        showValue: true,
                    });
                });
        }
    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {

        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{width: "100%"}}>
                    <br/>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table

                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                    </div>

                </div>

            )
        }

    };

    renderRemarks = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, csRemarks, this.updateComponent)
    };

    saveHandler = () => {
        let error = MyValidation.defaultValidation(lienMarkingJsonForm, this.state);
        this.forceUpdate();
        let saveDataDeferralRemarksAndRoute = {};
        if (error === true) {
            return 0;
        } else {
            if (this.state.inputData.csRemarks !== undefined && this.state.inputData.csRemarks !== null) {
                saveDataDeferralRemarksAndRoute.remark = this.state.inputData.csRemarks
            }


            let postData = this.state.inputData;
            postData.serviceType = "Lien Marking";
            postData.category = "Lien Marking";
            postData.subCategory = "Lien Marking";
            postData.subServiceType = "Lien Marking";
            postData.csRemarks = undefined;

            console.log("else condition");
            saveDataDeferralRemarksAndRoute.map = postData;
            let caseRouteUrl = assetOpsBackEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.state.appId;
            axios
                .post(caseRouteUrl, saveDataDeferralRemarksAndRoute, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {

                    this.setState({
                        title: "Successful!",
                        notificationMessage: response.data,
                        alert: true,
                        getData: false
                    });
                    if (this.props.appId === undefined) {
                        setTimeout(function () {
                            window.location.reload()
                        }, 1000);
                    } else {
                        this.props.closeModal();
                    }

                })
                .catch(error => {
                    console.log(error);
                });


        }


    };

    renderHeader = () => {
        if (this.props.appId !== undefined) {
            return (
                <h4 style={{color: "white"}} ><b>  Lien Marking</b>
                    <p style={{fontSize: "16px"}}>(<b> Customer
                        Name:</b>{this.state.inputData.customerName}
                        <b> A/C:</b>{this.state.inputData.accountNumber !== "__ERROR__" ? this.state.inputData.accountNumber : ""}
                        <b> CB Number:</b>{this.state.inputData.cbNumber}
                        <b> SOLID:</b>{this.props.solId}  <b> Case
                            ID:</b> {this.props.caseId !== undefined ? this.props.caseId : ""})<a><CloseIcon
                            onClick={this.props.closeModal}
                            style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></p></h4>
            )
        } else {
            return (
                <h4 style={{color: "white"}} >
                    Lien Marking{this.props.appId !== undefined ?
                    <a><CloseIcon onClick={this.props.closeModal} style={{
                        position: 'absolute',
                        right: 10,
                        color: "#000000"
                    }}/></a> : ""}</h4>
            )
        }
    };
    functionForCheck = (value) => {
        console.log(value)
    };

    closeConfirmAlert = (data, appId) => {


        this.setState({

            confirmAlert: false,

        });

        if (data === "NO") {

        } else if (data === "YES" && this.state.actionType === "saveHandler") {

            this.saveHandler()
        }
    };
    validationForHandleConfirm = (actionType) => {
        let error = MyValidation.defaultValidation(csRemarks, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        }
        this.setState({
            appId: this.state.appId,
            confirmAlert: false,
        });
        if (actionType === "saveHandler") {
            let error = MyValidation.defaultValidation(lienMarkingJsonForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            }
            this.setState({
                actionType: actionType,
                confirmAlert: true,
            })
        }
    };

    handleCommon = (event, type) => {
        event.preventDefault();
        // let data=this.validationForHandleConfirm()

        {
            this.validationForHandleConfirm(type)
        }


    };
    renderJsonForm = () => {
        if (this.state.getData) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, lienMarkingJsonForm, this.updateComponent, this.onBlurFunctionForApiCall, this.onKeyDownChange)

            )
        }
    }
    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };
    renderSubmitButton = () => {
        if (this.state.getData && this.state.loading === false && this.state.casaAccountApiCall) {
            return (
                <button
                    className="btn btn-outline-primary btn-sm"
                    style={{
                        marginRight: "10px",
                    }}
                    //onClick={this.saveHandler}
                    onClick={(event) => this.handleCommon(event, "saveHandler")}
                >
                    Submit
                </button>
            )
        }

    }

    renderFlowSUmmeryButton = () => {
        if (this.state.getData) {
            return (
                <FlowSummeryButton cbNumber={this.state.inputData.cbNumber} appId={this.props.appId}
                                   caseId={this.props.caseId}
                                   customerName={this.state.inputData.customerName} accountNumber={this.state.inputData.accountNumber}
                                   serviceType={this.state.inputData.serviceType}
                                   category={this.state.inputData.category}
                                   subCategory={this.state.inputData.subCategory}
                                   solId={this.props.solId}/>
            )
        }
    };

    render() {
        const {classes, onClose} = this.props;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        {
                            this.renderHeader()
                        }
                    </CardHeader>
                    <CardBody>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.loading}>
                                        <DialogContent className={classes.dialogPaper}>

                                            <center>
                                                <img src={loader} alt=""/>
                                            </center>
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.cbNotFound}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <CBNotFound
                                                closeModal={this.closeModalCBNotFound}
                                                title={this.state.title}
                                            />
                                        </DialogContent>
                                    </Dialog>
                                    <Dialog
                                        fullWidth="true"
                                        maxWidth="sm"
                                        className={classes.modal}
                                        classes={{paper: classes.dialogPaper}}
                                        open={this.state.confirmAlert}>
                                        <DialogContent className={classes.dialogPaper}>
                                            <ConfirmAlert validationForHandleConfirm={this.validationForHandleConfirm}
                                                          closeModal={this.closeConfirmAlert}

                                            />

                                        </DialogContent>
                                    </Dialog>
                                    {this.renderJsonForm()}
                                    <br/><br/><br/>
                                    <Grid item xs={12}> {this.state.inputData.lienAccount !== undefined && this.state.inputData.lienAccount !== null ?
                                        <SignatureButton accountNumber={this.state.inputData.lienAccount}  signatureType="multiple"
                                                         classes={this.props}/> : ""} </Grid>



                                </ThemeProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    <Grid item xs={12}>
                                        <br/>
                                        {(this.props.flowSummery === true) && this.renderFlowSUmmeryButton()}
                                    </Grid>
                                    {this.renderRemarksData()}

                                    <Grid item xs={12}>

                                    </Grid>

                                    {this.renderRemarks()}
                                </ThemeProvider>
                            </Grid>
                        </Grid>

                        <Grid item xs="3" style={{marginTop: "10px"}}>
                            {this.renderSubmitButton()}

                        </Grid>
                        {
                            this.renderNotification()
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }

}

export default withStyles(styles)(CsLienMarking);
