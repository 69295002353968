import React, {Component} from "react";
import CloseIcon from "@material-ui/icons/Close";

class MaintenanceUtility extends Component {
    static renderMaintenanceHeader = (freeFlag1, caseId, solId, cbNumber, accountNumber, customerName, close) => {
        return (
            <>
                <h3>
                    <b>Maintenance - {freeFlag1 ? "(" + freeFlag1.replaceAll("^", ",") + ")" : ""}</b>
                </h3>
                <h4 style={{color: "white"}} ><b>Case ID:</b>{caseId !== undefined ? caseId : ""}<b> SOL ID:</b>{solId !== undefined ? solId : ""}
                    <b> CB
                        Number:</b>{cbNumber !== undefined ? cbNumber : ""} <b> A/C
                        Number:</b>{accountNumber !== undefined ? accountNumber : ""} <b> A/C
                        Name:</b>{customerName !== undefined ? customerName : ""}
                    <a><CloseIcon onClick={close} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                </h4>
            </>
        )
    };

    static renderCloserMaintenanceHeader = (freeFlag1, solId, cbNumber, accountNumber, customerName, close) => {
        return (
            <h4 style={{color: "white"}} >
                Service Request - {freeFlag1 ? freeFlag1.replaceAll("^", ",") : ""} <b>SOL
                Id:</b>{solId !== undefined ? solId : ""} <b> CB
                Number:</b>{cbNumber !== undefined ? cbNumber : ""} <b> A/C
                Number:</b>{accountNumber !== undefined ? accountNumber : ""} <b> A/C
                Name:</b>{customerName !== undefined ? customerName : ""}
                <a><CloseIcon onClick={close} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
            </h4>
        )
    };

    static renderFundTransferHeader = (freeFlag1, caseId, solId, cbNumber, accountNumber, customerName, close) => {
        return (
            <>
                <h3>
                    <b>Custom Duty Payment</b>
                </h3>
                <h4 style={{color: "white"}} ><b>Case ID:</b>{caseId !== undefined ? caseId : ""}<b> SOL ID:</b>{solId !== undefined ? solId : ""}
                    <b> CB Number:</b>{cbNumber !== undefined ? cbNumber : ""} <b> A/C
                        Number:</b>{accountNumber !== undefined ? accountNumber : ""} <b> A/C
                        Name:</b>{customerName !== undefined ? customerName : ""}
                    <a><CloseIcon onClick={close} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                </h4>
            </>
        )
    };

    static renderCustomStatusUpdate = (freeFlag1, caseId, solId, cbNumber, accountNumber, customerName, close) => {
        return (
            <>
                <h3>
                    <b>Custom Status Update</b>
                </h3>
                <h4 style={{color: "white"}} ><b>Case ID:</b>{caseId !== undefined ? caseId : ""}<b> SOL ID:</b>{solId !== undefined ? solId : ""}
                    <b> CB Number:</b>{cbNumber !== undefined ? cbNumber : ""} <b> Customer
                        Name:</b>{customerName !== undefined ? customerName : ""}
                    <a><CloseIcon onClick={close} style={{position: 'absolute', right: 10, color: "#000000"}}/></a>
                </h4>
            </>
        )
    };
}

export default MaintenanceUtility