function makeReadOnlyObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        returnObjectVariables[i]["readOnly"] = true;
        returnObjectVariables[i]["required"] = false;
    }
    return returnObject;
}

function changeGridObject(object) {
    let returnObject = JSON.parse(JSON.stringify(object));
    let returnObjectVariables = returnObject;
    for (let i = 0; i < returnObjectVariables.length; i++) {
        if (returnObjectVariables[i]["type"] !== "title") {
            returnObjectVariables[i]["grid"] = 6;
        }
    }
    return returnObject;
}

const fdrMaintenance = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Interest Payment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Deceased FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 3,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"]
    },
    {
        "varName": "acNumber",
        "type": "textApiCall",
        "label": "A/c Number",
        "grid": 2,
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": ['Own AC', "Third Party AC", "Payment to GL AC", "Payorder"]
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "showAcName",
        "conditionalVarValue": true
    },
    {
        "varName": "transferType",
        "type": "select",
        "label": "Transfer Type",
        "enum": [
            "RTGS",
            "BEFTN",
            // "Salary"
        ],
        "grid": 2,
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "label": "Customer Type",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAmount",
        "type": "text",
        "label": "Transfer Amount",
        "grid": 2,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "CASA Link AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "casaLinkAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "casaLinkAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD AC Nominee",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "fdAcNomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "fdAcRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Deceased FDR Encashment',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment Certificate",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Present FDR Encashment Value",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "selectLinkRequestType",
        "type": "select",
        "label": "Select Request Type",
        "enum": [
            "INTEREST CREDIT A/C ID",
            "REPAYMENT A/C ID"
        ],
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link A/C Number",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor or Scheme Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "tenorType",
        "type": "select",
        "enum": [
            "Day",
            "Month"
        ],
        "label": "Tenor Type",
        "grid": 2,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newTenor",
        "type": "select",
        "enum": [],
        "label": "New Tenor",
        "grid": 2,
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showTenorPeriod"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true],
    },
    {
        "varName": "renewal",
        "type": "select",
        "label": "Renewal /Disposal Instruction",
        "grid": 2,
        "enum": [],
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showRenewal"],
        "conditionalArrayValue": ['Tenor or Scheme Change', true],
    },
    {
        "varName": "csmaturity",
        "type": "select",
        "label": "Maturity /Disposal Instruction",
        "grid": 3,
        "enum": [],
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showMaturity"],
        "conditionalArrayValue": ['Tenor or Scheme Change', true],
    },
    {
        "varName": "csfdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "label": "New Scheme Code",
        "readOnly": true,
        "enum": [],
        "grid": 3,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory","csshowSchemeCode"],
        "conditionalArrayValue": ['Tenor or Scheme Change',true],
    },
    {
        "varName": "reIssueFdrWith",
        "type": "select",
        "label": "Re-Issue FDR ",
        "grid": 2,
        "enum": [
            "Amount with Accrued (earned) Interest",
            "Principal Amount"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "S. Tax Reversal",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Debit Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "debitTransactionType",
        "type": "select",
        "enum": [
            "Customer Induced",
            "Bank Induced"
        ],
        "label": "Transaction Type/Subtype",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitPartTransactionType",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitacIdOne",
        "type": "text",
        "label": "A/c. ID ",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTRefCCYAmtOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTransactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTransactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 3,
        "required": true,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Credit Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "creditPartTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditAcIdTwo",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 2,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditRefCCYAmtTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditTransactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditTransactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 3,
        "required": true,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "sTaxAmount",
        "type": "text",
        "label": "Amount",
        "required": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl. Advice (Special)",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Auxiliary Task",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectRequestType",
        "type": "select",
        "label": "Select Request type",
        "enum": [
            "Freeze/Unfreeze Mark",
            "Deceased Marking ",
            "Priority Marking ",
            "SBS Code Update/Correction",
            "RM Code Update",
            "Citytouch Tagging",
            "AMLD/Branch Support",
        ],
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectFreezeType",
        "type": "select",
        "label": "Select Action Type",
        "grid": 3,
        "enum": [
            "Freeze",
            "Unfreeze"
        ],
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory Account Inquiry",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "specificReferanceNumber",
        "type": "text",
        "label": "Specific Referance Number",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "nameOfInstructedBody",
        "type": "select",
        "label": "Name of Instructed Body (Sender Name)",
        "grid": 3,
        "enum": [
            "A",
            "B",
            "C"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "priorityMaking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 3,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "withdrawal",
            "Information",
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "tentetiveCompletionDate",
        "type": "date",
        "label": "Tentetive Completion Date",
        "grid": 3,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FD nominee Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "nomineeNumberOld",
        "type": "text",
        "label": "Existing Nominee",
        "readOnly": true,
        "grid": 2,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "registrationNumber",
        "type": "text",
        "label": "Registration No",
        "grid": 2,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
];

let maintenanceForBm = {};
maintenanceForBm = makeReadOnlyObject(JSON.parse(JSON.stringify(fdrMaintenance)));

let maintenanceForBom = {};
maintenanceForBom = makeReadOnlyObject(changeGridObject(JSON.parse(JSON.stringify(fdrMaintenance))));

let fdrEncashmentMaker = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Interest Payment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Interest Payment"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Deceased FDR Encashment",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Deceased FDR Encashment"
    },
    {
        "varName": "encashmentAcId",
        "type": "text",
        "label": "A/C. ID",
        "required": true,
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentValueDate",
        "type": "date",
        "label": "Closure Value Date",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentWithDrawalAmt",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "required": true,
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentCloseMode",
        "type": "select",
        "enum": ["R","N"],
        "label": "Close Mode",
        "required": true,
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentRepaymentAcId",
        "type": "text",
        "required": true,
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentOperativeAccount",
        "type": "text",
        "label": "Operative Account",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "encashmentExciseDuty",
        "type": "select",
        "label": "Do You Want To Collect Excise Duty",
        "enum": ["Y", "N"],
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "FDR Encashment Certificate",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentCustomerName",
        "type": "text",
        "label": "Customer Name",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentAccountNumber",
        "type": "text",
        "label": "Account Number",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentBranchName",
        "type": "text",
        "readOnly": true,
        "label": "Branch Name",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentPrincipleAmount",
        "type": "text",
        "label": "Principle Amount",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentOpenDate",
        "type": "date",
        "label": "Open Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentMaturityDate",
        "type": "date",
        "label": "Maturity Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentTotalInterest",
        "type": "textApiCall",
        "label": "Total Interest",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentSourceTax",
        "type": "textApiCall",
        "label": "Source Tax Deducted",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentNetInttAmount",
        "type": "text",
        "label": "Net Intt. Amount Credited",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "encashmentExciseDuty",
        "type": "text",
        "label": "Excise Duty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FDR Encashment Certificate"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Present FDR Encashment Value",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "fdrAccountNumber",
        "type": "text",
        "label": "FDR Account Number",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "fdrAccountName",
        "type": "text",
        "label": "FDR Account Name",
        "grid": 6,
        "conditional": true,
        "readOnly": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "fdrOpenDate",
        "type": "date",
        "label": "FDR Open Date",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "principalAmount",
        "type": "text",
        "label": "Principal Amount",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "prematureNetInterest",
        "type": "text",
        "required": true,
        "label": "Premature Net Interest",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "exciseDuty",
        "type": "textApiCall",
        "label": "Excise Duty",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Present FDR Encashment Value"
    },
    {
        "varName": "customerNetPayable",
        "type": "text",
        "label": "Customer Net Payable",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showNetPay"],
        "conditionalArrayValue": ["Present FDR Encashment Value", true]
    },
    {
        "varName": "empty",
        "type": "title",
        "label": "Fund Transfer Details",
        "grid": 12,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"]
    },
    {
        "varName": "inputFundTransferAccountNumber",
        "type": "select",
        "label": "Input Fund Transfer Account Number",
        "grid": 6,
        "enum": [
            'Own AC',
            'Third Party AC',
            'Payment to GL AC',
            'Payorder',
            'EFT Payment',
            'RTGS Payment',
        ],
        // "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["FDR Encashment", "FDR Interest Payment", "Deceased FDR Encashment"]
    },
    {
        "varName": "acNumber",
        "type": "text",
        "label": "A/c Number",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": ['Own AC', "Third Party AC", "Payment to GL AC", "Payorder"]
    },
    {
        "varName": "acName",
        "type": "text",
        "label": "A/c Name",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": ['Own AC', "Third Party AC", "Payment to GL AC", "Payorder"]
    },
    // {
    //     "varName": "transferAccountBalance",
    //     "type": "text",
    //     "label": "Account Balance",
    //     "readOnly": true,
    //     "grid": 6,
    // "conditionalOrValue": true,
    // "conditionalVarName": "inputFundTransferAccountNumber",
    // "conditionalOrList": [
    //     'EFT Payment',
    //     'RTGS Payment',
    // ],
    //     "conditional": true,
    //     "conditionalVarName": "showAccountBalance",
    //     "conditionalVarValue": true
    // },
    {
        "varName": "transferType",
        "type": "select",
        "label": "Transfer Type",
        "enum": [
            "RTGS",
            "BEFTN",
            "Salary"
        ],
        "required": true,
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "customerType",
        "type": "select",
        "enum": [
            "Retail",
            "SME/Corporate"
        ],
        "label": "Customer Type",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "transferAmount",
        "type": "text",
        "label": "Transfer Amount",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "creditAccountNumber",
        "type": "textApiCall",
        "label": "Credit Account Number",
        "grid": 6,
        "required": true,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "creditAccountName",
        "type": "text",
        "label": "Credit Account Name",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["showBeneficiaryDetails", "showCreditAccountName"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "beneficiaryDetails",
        "type": "text",
        "label": "Beneficiary Details",
        "grid": 6,
        "conditionalOrValue": true,
        "conditionalVarName": "inputFundTransferAccountNumber",
        "conditionalOrList": [
            'EFT Payment',
            'RTGS Payment',
        ],
    },
    {
        "varName": "beneficiaryBankName",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Beneficiary Bank Name",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["showBeneficiaryDetails"],
        "conditionalArrayValue": [true],
    },
    {
        "varName": "branchName",
        "type": "select",
        "enum": [],
        "onKeyDown": true,
        "label": "Branch Name",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["showBeneficiaryDetails", "showBranchName"],
        "conditionalArrayValue": [true, true],
    },
    {
        "varName": "routingNumber",
        "type": "text",
        "label": "Routing Number",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["showBeneficiaryDetails", "showRoutingNumber"],
        "conditionalArrayValue": [true, true],
    },
    // {
    //     "varName": "empty",
    //     "type": "title",
    //     "label": "Fund Transfer Details",
    //     "grid": 12,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "creditAccountNumber",
    //     "type": "textApiCall",
    //     "required": true,
    //     "label": "Credit Account Number",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "creditAccountName",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "Credit Account Name",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "showCreditAccountName"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value", true]
    // },
    // {
    //     "varName": "customerType",
    //     "type": "select",
    //     "enum": [
    //         "Retail",
    //         "SME/Corporate"
    //     ],
    //     "label": "Customer Type",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "transferType",
    //     "type": "select",
    //     "required": true,
    //     "label": "Transfer Type",
    //     "enum": [
    //         "RTGS",
    //         "BEFTN",
    //     ],
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "beneficiaryDetails",
    //     "type": "text",
    //     "label": "Beneficiary Details",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "beneficiaryBankName",
    //     "type": "select",
    //     "enum": [],
    //     "required": true,
    //     "onKeyDown": true,
    //     "label": "Beneficiary Bank Name",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory",],
    //     "conditionalArrayValue": ["Present FDR Encashment Value"]
    // },
    // {
    //     "varName": "branchName",
    //     "type": "select",
    //     "enum": [],
    //     "required": true,
    //     "onKeyDown": true,
    //     "label": "Branch Name",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "showBranchName"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value", true]
    // },
    // {
    //     "varName": "routingNumber",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "Routing Number",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "showRoutingNumber"],
    //     "conditionalArrayValue": ["Present FDR Encashment Value", true]
    // },
];
let fdrEncashmentForChecker = {};
fdrEncashmentForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(fdrEncashmentMaker)));
let existingFDRForm = [
    {
        "type": "title",
        "label": "General Info",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [ true]
    },

    {
        "varName": "exsolId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "excifId",
        "type": "text",
        "readOnly": true,
        "label": "CIF ID",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "CCY",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exschemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exacName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exshortName",
        "type": "text",
        "label": "A/c.  SHORT NAME",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exacOpeningDate",
        "type": "date",
        "maxDate": true,
        "required": true,
        "label": "A/C Opening Date",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "type": "title",
        "label": "Interest And Tax",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exaccountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exinterestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exmaturity"],
        "conditionalArrayValue": [true,"Renew Principal Only and Credit Interest to"]
    },

    {
        "varName": "exinterestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exmaturity", "exinterestCreditAccountApiCall"],
        "conditionalArrayValue": [true,"Renew Principal Only and Credit Interest to", "YES"]

    },
    {
        "varName": "extaxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "label": "Tax Category",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exwithHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "label": "With Holding Tax Level",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exwithholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "label": "Withholding Tax Borne By",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exwithholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exvalueDate",
        "type": "date",
        "onKeyDown": true,
        "maxDate": true,
        "label": "Value Date",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exdepositinstallAmount",
        "type": "textApiCall",
        "label": "Deposit/Installment Amount",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exdepositTenure",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Day",
            "Month",
            //"Year",
        ],
        "required": true,
        "label": "Period Type",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exdepositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exshowTenorPeriod"],
        "conditionalArrayValue": [true,true]
    },

    {
        "varName": "exmaturityDate",
        "type": "date",
        "label": "Maturity Date",
        // "required": true,
        "readOnly": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exfdMaturityDateApicall"],
        "conditionalArrayValue": [true,"YES"]
    },

    {
        "varName": "exmaturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exrepaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exmaturity"],
        "conditionalArrayValue": [true,"Encashment at Maturity"]
    },

    {
        "varName": "exrepaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exmaturity", "exrepaymentAccountApiCall"],
        "conditionalArrayValue": [true,"Encashment at Maturity", "YES"]
    },
    {
        "varName": "exupdateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "type": "title",
        "label": "Renewal and Closure",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exautoRenewal",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "onKeyDown": true,
        "label": "AUTO. RENEWAL ? ",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exautoClosure",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. CLOSURE ? ",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exshowautoRenewal"],
        "conditionalArrayValue": [true,"YES"]
    },
    {
        "varName": "exmaturity",
        "type": "select",
        "onKeyDown": true,
        "enum": [
            "Renew both Principal and Interest",
            "Renew Principal Only and Credit Interest to",
            "Encashment at Maturity",
        ],
        "required": true,
        "label": "Maturity/Disposal Instruction",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "type": "title",
        "label": "MIS Code",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exsectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exsubSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": " Sub-Sector Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exsectorCodeApiCall"],
        "conditionalArrayValue": [true,true]
    },
    {
        "varName": "exoccupationCode",
        "type": "select",
        "enum": [],
        "required": true,
        "label": "Occupation Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exfreeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },

    {
        "varName": "exrmCode",
        "type": "textApiCall",
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exrmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exrmCodeApiCall"],
        "conditionalArrayValue": [true,"YES"]

    },
    {
        "varName": "exfreeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exfreeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },
    {
        "varName": "exdepositeType",
        "type": "text",
        "readOnly": true,
        "validateReadOnly": true,
        "required": true,
        "label": "Free Code 7 (Deposit Code)",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly","exdepositeTypeApicall"],
        "conditionalArrayValue": [true,true]
    },
    {
        "type": "title",
        "label": "Payment Instruction",
        "grid": 12,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity"],
        "conditionalArrayValue": [true, "Encashment at Maturity"]
    },
    {
        "varName": "exflowCode",
        "type": "text",
        "label": "Flow Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity"],
        "conditionalArrayValue": [true, "Encashment at Maturity"]
    },
    {
        "varName": "exaccountId",
        "type": "text",
        "label": "A/C ID",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity"],
        "conditionalArrayValue": [true, "Encashment at Maturity"]
    },
    {
        "varName": "examtType",
        "type": "text",
        "label": "Amount Type",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity"],
        "conditionalArrayValue": [true, "Encashment at Maturity"]
    },
    {
        "varName": "examt",
        "type": "text",
        "required": true,
        "label": "Excise Duty Amount",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity","exshowAmtField"],
        "conditionalArrayValue": [true, "Encashment at Maturity",true]
    },
    {
        "varName": "exparticulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exmaturity","exshowAmtField"],
        "conditionalArrayValue": [true, "Encashment at Maturity",true]
    },
    // {
    //     "type": "title",
    //     "label": "Transaction",
    //     "grid": 12,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": [true]
    // },
    // {
    //     "varName": "extranCreationDuring",
    //     "type": "select",
    //     "enum": [
    //         "O",
    //         "V",
    //     ],
    //     "label": "Transaction Creation During (O/V)",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": [true]
    // },
    // {
    //     "varName": "extransactionType",
    //     "type": "text",
    //     "label": "Transaction Type",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": [true]
    // },
    // {
    //     "varName": "exdebitAccountNumber",
    //     "type": "textApiCall",
    //     "onKeyDown": true,
    //     "label": "Debit A/C ID",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": [true]
    // },
    // {
    //     "varName": "exdebitAccountName",
    //     "type": "text",
    //     "readOnly": true,
    //     "label": "Debit A/C Name",
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["changingRenewalMaturityInstructionOnly", "exdebitAccountNumberApiCall"],
    //     "conditionalArrayValue": [true, "YES"],
    //     "grid": 6,
    // },
    {
        "varName": "exetin",
        "type": "text",
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["changingRenewalMaturityInstructionOnly"],
        "conditionalArrayValue": [true]
    },


    // {
    //     "varName": "solIdTwo",
    //     "type": "text",
    //     "label": "SOL ID",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "acIdTwo",
    //     "type": "text",
    //     "label": "A/c ID",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "repaymentAcid",
    //     "type": "text",
    //     "label": "REPAYMENT A/C ID",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "autoClosure",
    //     "type": "checkbox",
    //     "label": "AUTO. CLOSURE ?",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "autoRenewal",
    //     "type": "checkbox",
    //     "label": "AUTO. RENEWAL ?",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "flowCode",
    //     "type": "checkbox",
    //     "label": "FLOW CODE",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "acIdThree",
    //     "type": "text",
    //     "label": "A/C. ID",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "amtType",
    //     "type": "select",
    //     "label": "AMT. TYPE",
    //     "grid": 6,
    //     "enum": [
    //         "Cash",
    //         "Cheque"
    //     ],
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "amt",
    //     "type": "text",
    //     "label": "AMT.",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "particulars",
    //     "type": "text",
    //     "label": "PARTICULARS",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
    // {
    //     "varName": "record",
    //     "type": "select",
    //     "enum": [
    //         "DEL"
    //     ],
    //     "label": "Record",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "changingRenewalMaturityInstructionOnly"],
    //     "conditionalArrayValue": ["Tenor or Scheme Change", true]
    // },
];
let fdrNewForm = [
    {
        "varName": "newFDROpening",
        "type": "checkbox",
        "label": "New FDR Opening",
        "grid": 12,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Tenor or Scheme Change", "S. Tax Reversal"]
    },
];
let maintenanceForMaker = [
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    // {
    //     "varName": "title",
    //     "type": "title",
    //     "label": "FD nominee Change",
    //     "grid": 12,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "FD Nominee Change"
    // },
    // {
    //     "varName": "nomineeNumberOld",
    //     "type": "text",
    //     "label": "Existing Nominee",
    //     "readOnly": true,
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "FD Nominee Change"
    // },
    // {
    //     "varName": "registrationNumber",
    //     "type": "text",
    //     "label": "Registration No",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "FD Nominee Change"
    // },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "FD Nominee Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Link ACC Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "solId",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "A/c. ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Link ACC Change"
    },
    {
        "varName": "selectLinkRequestType",
        "type": "select",
        "label": "Select Request Type",
        "enum": [
            "INTEREST CREDIT A/C ID",
            "REPAYMENT A/C ID"
        ],
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "existingLinkAccount",
        "type": "text",
        "label": "Existing Link A/C Number",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    {
        "varName": "newLinkAccountNumber",
        "type": "text",
        "label": "New Link Account Number",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Link ACC Change',
    },
    // {
    //     "varName": "interestCreditAcId",
    //     "type": "text",
    //     "label": "INTEREST CREDIT A/C ID",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "Link ACC Change"
    // },
    // {
    //     "varName": "repaymentAcId",
    //     "type": "text",
    //     "label": "REPAYMENT A/C ID",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "Link ACC Change"
    // },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Tenor or Scheme Change",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "tenorType",
        "type": "select",
        "enum": [
            "Day",
            "Month"
        ],
        "label": "Tenor Type",
        "grid": 6,
        "onKeyDown": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "newTenor",
        "type": "select",
        "enum": [],
        "label": "New Tenor",
        "grid": 6,
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showTenorPeriod"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true],
    },
    {
        "varName": "renewal",
        "type": "select",
        "label": "Renewal /Disposal Instruction",
        "grid": 6,
        "enum": [],
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showRenewal"],
        "conditionalArrayValue": ['Tenor or Scheme Change', true],
    },
    {
        "varName": "csmaturity",
        "type": "select",
        "label": "Maturity /Disposal Instruction",
        "grid": 6,
        "enum": [],
        "onKeyDown": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "showMaturity"],
        "conditionalArrayValue": ['Tenor or Scheme Change', true],
    },
    {
        "varName": "csfdrSchemeCode",
        "type": "autoCompleteValueReturn",
        "label": "New Scheme Code",
        "enum": [],
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory","csshowSchemeCode"],
        "conditionalArrayValue": ['Tenor or Scheme Change',true],
    },
    {
        "varName": "reIssueFdrWith",
        "type": "select",
        "label": "Re-Issue FDR ",
        "grid": 6,
        "enum": [
            "Amount with Accrued (earned) Interest",
            "Principal Amount"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'Tenor or Scheme Change',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "empty",
        "type": "title",
        "label": "S. Tax Reversal",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Debit Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "debitTransactionType",
        "type": "text",
        "label": "Transaction Type/Subtype",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitPartTransactionType",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitacIdOne",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTRefCCYAmtOne",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTransactionParticularsCodeOne",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "debitTransactionParticularsOne",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "required": true,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Credit Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "creditPartTransactionTypeOne",
        "type": "text",
        "label": "Part Transaction Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditAcIdTwo",
        "type": "text",
        "label": "A/c. ID ",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditRefCCYAmtTwo",
        "type": "text",
        "label": "Ref. CCY/Amt",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditTransactionParticularsCodeTwo",
        "type": "text",
        "label": "Transaction Particulars Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "creditTransactionParticularsTwo",
        "type": "text",
        "label": "Transaction Particulars",
        "grid": 6,
        "required": true,
        "multiline": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": 'S. Tax Reversal',
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "sTaxAmount",
        "type": "text",
        "label": "Amount",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "S. Tax Reversal"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Dupl. Advice (Special)",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Dupl. Advice (Special)"
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Auxiliary Task",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    {
        "varName": "selectRequestType",
        "type": "select",
        "label": "Select Request type",
        "enum": [
            "Freeze/Unfreeze Mark",
            "Deceased Marking ",
            "Priority Marking ",
            "SBS Code Update/Correction",
            "RM Code Update",
            "Citytouch Tagging",
            "AMLD/Branch Support",
        ],
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Auxiliary Task"
    },
    // {
    //     "varName": "solId",
    //     "type": "text",
    //     "label": "SOL ID",
    //     "grid": 6,
    //     "readOnly": true,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "Auxiliary Task"
    // },
    {
        "varName": "selectFreezeType",
        "type": "select",
        "label": "Select Action Type",
        "grid": 6,
        "enum": [
            "Freeze",
            "Unfreeze"
        ],
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    {
        "varName": "accountNumber",
        "type": "text",
        "label": "A/c ID",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    {
        "varName": "freezeCode",
        "type": "text",
        "label": "Freeze Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    {
        "varName": "freezeReasonCode",
        "type": "text",
        "label": "Freeze Reason Code 1",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    {
        "varName": "freezeRemarks",
        "type": "text",
        "label": "Freeze Remarks1",
        "grid": 6,
        "multiline": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Freeze/Unfreeze Mark"]
    },
    // {
    //     "varName": "sectorCode",
    //     "type": "autoCompleteValueReturn",
    //     "label": "Sector Code",
    //     "enum": [],
    //     "onKeyDown": true,
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "selectRequestType"],
    //     "conditionalArrayValue": ["Auxiliary Task", "SBS Code Update/Correction"]
    // },
    // {
    //     "varName": "subSectorCode",
    //     "type": "autoCompleteValueReturn",
    //     "label": "Sub Sector Code",
    //     "enum": [],
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "selectRequestType", "sectorCodeApiCall"],
    //     "conditionalArrayValue": ["Auxiliary Task", "SBS Code Update/Correction", true]
    // },
    // {
    //     "varName": "occupationCode",
    //     "type": "text",
    //     "label": "OCCUPATION CODE",
    //     "grid": 6,
    //     "conditional": true,
    //     "conditionalVarName": "subCategory",
    //     "conditionalVarValue": "Auxiliary Task"
    // },
    // {
    //     "varName": "freeCode3",
    //     "type": "text",
    //     "label": "FREE CODE 3 (RM CODE)",
    //     "grid": 6,
    //     "conditionalArray": true,
    //     "conditionalArrayList": ["subCategory", "selectRequestType"],
    //     "conditionalArrayValue": ["Auxiliary Task", "RM Code Update"]
    // },
    {
        "varName": "emailId",
        "type": "text",
        "label": "Email Address",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging"]
    },
    {
        "varName": "existingPhoneNumber",
        "type": "text",
        "label": "Existing Phone Number",
        "readOnly": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging"],

    },
    {
        "varName": "mobileType",
        "type": "select",
        "label": "Mobile Type",
        "enum": [
            "Local",
            "Overseas"
        ],
        // "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging"],
    },
    {
        "varName": "commPhoneLocalCode",
        "type": "autoCompleteValueReturn",
        "label": "Country Code",
        "enum": [],
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType", "mobileType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging", "Overseas"],
        "required": true,
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "phone",
        "phonePrefix": true,
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType", "mobileType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging", "Local"],
    },
    {
        "varName": "newPhoneNumber",
        "type": "text",
        "label": "New Phone Number",
        "validation": "numeric",
        "required": true,
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "selectRequestType", "mobileType"],
        "conditionalArrayValue": ["Auxiliary Task", "Citytouch Tagging", "Overseas"],
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "title",
        "type": "title",
        "label": "Regulatory Account Inquiry",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "specificReferenceNumber",
        "type": "text",
        "label": "Specific Reference Number",
        "grid": 5,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "nameOfInstructedBody",
        "type": "select",
        "label": "Name of Instructed Body (Sender Name)",
        "grid": 7,
        "enum": [
            "A",
            "B",
            "C"
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "priorityMaking",
        "type": "select",
        "label": "Priority Marking",
        "grid": 5,
        "enum": [
            "143 Letter",
            "Freeze Marking",
            "withdrawal",
            "Information",
        ],
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "tentetiveCompletionDate",
        "type": "date",
        "label": "Tentetive Completion Date",
        "grid": 7,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Regulatory Account Inquiry"
    },
    {
        "varName": "existingFDRSCHEMECODEtoNewFDRSCHEMECODE",
        "type": "checkbox",
        "label": "Existing FDR SCHEME CODE to New FDR SCHEME CODE",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change"
    },
    {
        "varName": "solIdOne",
        "type": "text",
        "label": "SOL ID",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "existingFDRSCHEMECODEtoNewFDRSCHEMECODE"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true]
    },
    {
        "varName": "acIdOne",
        "type": "text",
        "label": "A/c ID",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "existingFDRSCHEMECODEtoNewFDRSCHEMECODE"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true]
    },
    {
        "varName": "targetSchemaCode",
        "type": "text",
        "label": "Target Scheme Code",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "existingFDRSCHEMECODEtoNewFDRSCHEMECODE"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true]
    },
    {
        "varName": "trailMode",
        "type": "checkbox",
        "label": "Trail Mode",
        "grid": 6,
        "conditionalArray": true,
        "conditionalArrayList": ["subCategory", "existingFDRSCHEMECODEtoNewFDRSCHEMECODE"],
        "conditionalArrayValue": ["Tenor or Scheme Change", true]
    },


    {
        "varName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "type": "checkbox",
        "label": "Encashment of existing FDR",
        "grid": 12,
        "conditionalOrValue": true,
        "conditionalVarName": "subCategory",
        "conditionalOrList": ["Tenor or Scheme Change", "S. Tax Reversal"]
    },
    {
        "varName": "encashmentAcId",
        "type": "text",
        "label": "A/C. ID",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentValueDate",
        "type": "date",
        "label": "Closure Value Date",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentWithDrawalAmt",
        "type": "text",
        "label": "Withdrawal Amt. (Principal)",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentCloseMode",
        "type": "select",
        "enum": [
            "FDR01",
            "FDR02",
            "FDR03",
            "FDR04",
            "FDR05",
            "FDR06",
            "FDR07",
            "FDR08",
            "FDR09",
            "FDR10",
            "FDR11",
            "FDR12",
            "FDR13",
            "FDR14",
            "FDR15",
            "FDR16",
            "FDR17",
            "FDR18",
            "FDR19",
            "FDR20",
            "FDR21",
        ],
        "label": "Close Mode",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentRepaymentAcId",
        "type": "text",
        "label": "REPAYMENT A/C ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentOperativeAccount",
        "type": "text",
        "label": "Operative Account",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "encashmentExciseDuty",
        "type": "select",
        "enum": ["Y", "N"],
        "label": "Do You Want To Collect Excise Duty",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "empty",
        "type": "empty",
        "label": "",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "encashmentOfExistingFDRandIssuanceOfNewFDR",
        "conditionalVarValue": true,
    },
    {
        "varName": "changingRenewalMaturityInstructionOnly",
        "type": "checkbox",
        "label": "Changing Renewal/Maturity Instruction Only",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "subCategory",
        "conditionalVarValue": "Tenor or Scheme Change",
    },

];


let newFdrOpeningFormMakerOne = [
    {
        "type": "empty",
        "grid": 12,
    },
    {
        "varName": "debitAccountStatement",
        "type": "submit",
        "label": "Debit Account Statement",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Fixed Deposit Account Opening",
        "grid": 12,
    },
    {
        "type": "title",
        "label": "General Info",
        "grid": 12,
    },

    {
        "varName": "solId",
        "type": "text",
        "readOnly": true,
        "label": " SOL ID",
        "grid": 6,
    },

    {
        "varName": "cifId",
        "type": "text",
        "readOnly": true,
        "label": "CIF ID",
        "grid": 6,
    },

    {
        "varName": "ccy",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "CCY",
        "grid": 6,
    },

    {
        "varName": "schemeCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": "Scheme Code",
        "grid": 6,
    },

    {
        "varName": "acName",
        "type": "text",
        "label": "A/C. Name",
        "grid": 6,
    },
    {
        "varName": "shortName",
        "type": "text",
        "label": "A/c.  SHORT NAME",
        "grid": 6,
    },

    {
        "varName": "acOpeningDate",
        "type": "date",
        "maxDate": true,
        "required": true,
        "label": "A/C Opening Date",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Interest And Tax",
        "grid": 12,
    },
    {
        "varName": "accountPrefInt",
        "type": "text",
        "label": "A/c. Credit Preferential Interest",
        "numeric": true,
        "grid": 6,
    },
    {
        "varName": "interestCreditAccount",
        "type": "textApiCall",
        "label": "Interest Credit A/C ID",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Renew Principal Only and Credit Interest to",

    },

    {
        "varName": "interestCreditName",
        "type": "text",
        "label": "Interest Credit Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "interestCreditAccountApiCall"],
        "conditionalArrayValue": ["Renew Principal Only and Credit Interest to", "YES"],

    },
    {
        "varName": "taxCategory",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "taxCategory",
                "key": "E",
                "value": "E-Customer Tax Deducted at Source eligibility"
            }, {
                "masterDataName": "taxCategory",
                "key": "N",
                "value": "N-No Tax"
            },
            {
                "masterDataName": "taxCategory",
                "key": "T",
                "value": "T-Customer Tax Deducted at Source"
            },
            {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            },

        ],
        "label": "Tax Category",
        "grid": 6,
    },

    {
        "varName": "withHoldingTaxLevel",
        "type": "autoCompleteValueReturn",
        "enum": [
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            },
            {
                "masterDataName": "withHoldingTaxLevel",
                "key": "C",
                "value": "C-Customer Level"
            }, {
                "masterDataName": "withHoldingTaxLevel",
                "key": "S",
                "value": "S-Scheme Level"
            },
        ],
        "label": "With Holding Tax Level",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxBorneBy",
        "type": "autoCompleteValueReturn",
        "enum": [
            /*"None",
            "All",*/
            {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            },
        ],
        "label": "Withholding Tax Borne By",
        "grid": 6,
    },

    {
        "varName": "withholdingTaxPcnt",
        "type": "text",
        "label": "Withholding Tax Pcnt.",
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Scheme Details",
        "grid": 12,
    },
    {
        "varName": "valueDate",
        "type": "date",
        "onKeyDown": true,
        "required": true,
        "maxDate": true,
        "label": "Value Date",
        "grid": 6,
    },

    {
        "varName": "depositinstallAmount",
        "type": "textApiCall",
        "required": true,
        "label": "Deposit/Installment Amount",
        "grid": 6,
    },

    {
        "varName": "depositTenure",
        "type": "select",
        "onKeyDown": true,
        "required": true,
        "enum": [
            "Day",
            "Month",
            //"Year",
        ],
        "label": "Period Type",
        "grid": 6,
    },
    {
        "varName": "depositPeriodMonthsdays",
        "type": "select",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Deposit Period (Months/ Days)",
        "grid": 6,
    },

    {
        "varName": "maturityDate",
        "type": "date",
        "label": "Maturity Date",
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "fdMaturityDateApicall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    {
        "varName": "maturityValue",
        "type": "text",
        "label": "Maturity Value",
        "grid": 6,
    },

    {
        "varName": "repaymentAcId",
        "type": "textApiCall",
        "label": "Repay A/C ID",
        "grid": 6,
        "required": true,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",

    },

    {
        "varName": "repaymentName",
        "type": "textApiCall",
        "label": "Repayment Name",
        "grid": 6,
        "readOnly": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity", "repaymentAccountApiCall"],
        "conditionalArrayValue": ["Encashment at Maturity", "YES"],
    },
    {
        "varName": "updateInterestRate",
        "type": "submit",
        "label": "Update Rate",
        "mt": 15,
        "event": false,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Renewal and Closure",
        "grid": 12,
    },
    {
        "varName": "autoRenewal",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "onKeyDown": true,
        "required": true,
        "label": "AUTO. RENEWAL ? ",
        "grid": 6,
    },
    {
        "varName": "autoClosure",
        "type": "select",
        "enum": [
            "Yes",
            "No",
        ],
        "required": true,
        "label": "AUTO. CLOSURE ? ",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "showautoRenewal",
        "conditionalVarValue": "YES"
    },
    {
        "varName": "maturity",
        "type": "select",
        "onKeyDown": true,
        "required": true,
        "enum": [
            "Renew both Principal and Interest",
            "Renew Principal Only and Credit Interest to",
            "Encashment at Maturity",
        ],
        "required": true,
        "label": "Maturity/Disposal Instruction",
        "grid": 6,
    },

    {
        "type": "title",
        "grid": 12,
        "label": "Nominee",
        "conditional": true,
        "conditionalVarName": "nomineeInformationVisible",
        "conditionalVarValue": "YES"

    },
    {
        "varName": "nomineeType",
        "type": "select",
        "enum": [
            "Nominee Same As Operative Account",
            "New Nominee Assignment",
        ],
        "onKeyDown": true,
        "required": true,
        "label": "Nominee Type",
        "grid": 6,
        // "conditional": true,
        // "conditionalVarName": "nomineeInformationVisible",
        // "conditionalVarValue": "YES"
    },


    {
        "varName": "nomineeExistNot",
        "type": "select",
        "enum": [
            "Y",
            "N",
        ],
        "readOnly": true,
        "label": "Nominee Exist/Not",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "varName": "nomineeRegNo",
        "type": "text",
        "readOnly": true,
        "label": "Registration No.",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },
    {
        "varName": "nomineeName",
        "type": "text",
        "label": "Nominee Name",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeRelationship",
        "type": "text",
        "label": "Relationship",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "percentageNominee",
        "type": "text",
        "label": "Percentage %",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"


    },
    {
        "varName": "nomineeAddress1",
        "type": "text",
        "label": "Address 1 ",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeAddress2",
        "type": "text",
        "label": "Address 2",
        "grid": 6,
        "multiline": true,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },

    {
        "varName": "nomineeCityCode",
        "type": "text",
        "readOnly": true,
        "label": "City Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeStateCode",
        "type": "text",
        "label": "State Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineePostalCode",
        "type": "text",
        "label": "Postal Code",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeCountry",
        "type": "text",
        "label": "Country",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "photoIdNumberRegNo",
        "type": "text",
        "label": "Photo ID Number/Reg No.",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },
    {
        "varName": "nomineeDob",
        "type": "date",
        "label": "DOB",
        "maxDate": true,
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"
    },


    {
        "varName": "nomineeMinor",
        "type": "text",
        "label": "Nominee Minor",
        "grid": 6,
        "readOnly": true,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "Nominee Same As Operative Account"

    },

    {
        "type": "title",
        "label": "Nominee Assign",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "nomineeType",
        "conditionalVarValue": "New Nominee Assignment"
    }

];
let newFdrOpeningFormMakerTwo = [
    {
        "type": "title",
        "label": "MIS Code",
        "grid": 12,
    },
    {
        "varName": "sectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "onKeyDown": true,
        "label": "Sector Code",
        "grid": 6,
    },

    {
        "varName": "subSectorCode",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "required": true,
        "label": " Sub-Sector Code",
        "conditional": true,
        "conditionalVarName": "sectorCodeApiCall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "varName": "occupationCode",
        "type": "select",
        "required": true,
        "enum": [],
        "label": "Occupation Code",
        "grid": 6,
    },

    {
        "varName": "freeCode1SpecialTaxagentOutlet",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Free Code 1 (Special Tax/Agent Outlet)",
        "grid": 6,
    },

    {
        "varName": "rmCode",
        "type": "textApiCall",
        "required": true,
        "label": "Free Code 3 (RM Code)",
        "grid": 6,
    },
    {
        "varName": "rmCodeName",
        "type": "text",
        "label": "RM Code Name",
        "readOnly": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "rmCodeApiCall",
        "conditionalVarValue": "YES",

    },
    {
        "varName": "freeCode6",
        "type": "text",
        "label": "Free Code 6 (Product MIS Code)",
        "grid": 6,
    },
    {
        "varName": "freeText3",
        "type": "text",
        "label": "Free Text 3",
        "grid": 6,
    },
    {
        "varName": "depositeType",
        "type": "text",
        "readOnly": true,
        "label": "Free Code 7 (Deposit Code)",
        "conditional": true,
        "conditionalVarName": "depositeTypeApicall",
        "conditionalVarValue": true,
        "grid": 6,
    },
    {
        "type": "title",
        "label": "Payment Instruction",
        "grid": 12,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "flowCode",
        "type": "text",
        "label": "Flow Code",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    }, {
        "varName": "accountId",
        "type": "text",
        "label": "A/C ID",
        "required": true,
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "amtType",
        "type": "text",
        "label": "Amount Type",
        "grid": 6,
        "conditional": true,
        "conditionalVarName": "maturity",
        "conditionalVarValue": "Encashment at Maturity",
    },
    {
        "varName": "amt",
        "type": "text",
        "label": "Excise Duty Amount",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","showAmtField"],
        "conditionalArrayValue": ["Encashment at Maturity",true]
    },
    {
        "varName": "particulars",
        "type": "text",
        "label": "Particulars",
        "grid": 6,
        "required": true,
        "conditionalArray": true,
        "conditionalArrayList": ["maturity","showAmtField"],
        "conditionalArrayValue": ["Encashment at Maturity",true]
    },
    {
        "type": "title",
        "label": "Transaction",
        "grid": 12,
    },
    {
        "varName": "tranCreationDuring",
        "type": "select",
        "enum": [
            "O",
            "V",
        ],
        "label": "Transaction Creation During (O/V)",
        "grid": 6,
    },
    {
        "varName": "transactionType",
        "type": "text",
        "label": "Transaction Type",
        "grid": 6,
    },
    {
        "varName": "debitAccountNumber",
        "type": "textApiCall",
        "onKeyDown": true,
        "label": "Debit A/C ID",
        "grid": 6,
    },
    {
        "varName": "debitAccountName",
        "type": "text",
        "readOnly": true,
        "label": "Debit A/C Name",
        "conditional": true,
        "conditionalVarName": "debitAccountNumberApiCall",
        "conditionalVarValue": "YES",
        "grid": 6,
    },

    /* {
         "varName": "relationType",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Type ",
         "grid": 6,
     },

     {
         "varName": "relationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Relation Code",
         "grid": 6,
     },

     {
         "varName": "designationCode",
         "type": "autoCompleteValueReturn",
         "enum": [],
         "label": "Designation Code",
         "grid": 6,
     },

     {
         "varName": "cifId",
         "type": "text",
         "label": "Cif. Id",
         "grid": 6,
     },
*/
    {
        "varName": "etin",
        "type": "text",
        "validation": "etin",
        "label": "E-TIN Number",
        "grid": 6,
    },

];

let fdrMaintenanceForChecker = {};
fdrMaintenanceForChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(maintenanceForMaker)));
let newFdrOpeningFormCheckerOne = {};
newFdrOpeningFormCheckerOne = makeReadOnlyObject(JSON.parse(JSON.stringify(newFdrOpeningFormMakerOne)));
let newFdrOpeningFormCheckerTwo = {};
newFdrOpeningFormCheckerTwo = makeReadOnlyObject(JSON.parse(JSON.stringify(newFdrOpeningFormMakerTwo)));
let existingFDRFormChecker = {};
existingFDRFormChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(existingFDRForm)));

let fdrNewFormChecker = {};
fdrNewFormChecker = makeReadOnlyObject(JSON.parse(JSON.stringify(fdrNewForm)));

export {
    fdrMaintenance,
    maintenanceForBm,
    maintenanceForBom,
    maintenanceForMaker,
    fdrMaintenanceForChecker,
    newFdrOpeningFormMakerOne,
    newFdrOpeningFormMakerTwo,
    newFdrOpeningFormCheckerOne,
    newFdrOpeningFormCheckerTwo,
    fdrEncashmentMaker,
    fdrEncashmentForChecker,
    existingFDRForm,
    existingFDRFormChecker,
    fdrNewForm,
    fdrNewFormChecker
}