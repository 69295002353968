const rows = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number'},
    {field: 'account_number', title: 'Account Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch Name'},
    {field: 'Status', title: 'Status'},
    {field: 'Current User', title: 'Current User'},
    {field: 'initUser', title: 'Init User'},
];

const auxiliaryTaskInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'id', title: 'ID'},
    {field: 'requestType', title: 'Request Type'},
    {field: 'forwardTo', title: 'Forward To'},

    {field: 'createdDate', title: 'Created Date'},
    {field: 'createdBy', title: 'Created By'},
    {field: 'modifiedDate', title: 'Action Date'},
    {field: 'modifiedBy', title: 'Action By'},
    {field: 'getAuxiliaryTaskStatus', title: 'Status'},
    {field: 'remarksView', disableClick: true, title: 'View'},

];
const auxiliaryMappingTaskInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'requestType', title: 'Request Type'},
    {field: 'assignedRole', title: 'Assigned Role'},
    {field: 'createdDate', title: 'Creation Date'},
    {field: 'createdBy', title: 'Create By'},
    {field: 'getAuxiliaryTaskStatus', title: 'Status'},
    {field: 'action', disableClick: true, title: 'Action'},

];
const rowsWithGroupInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
 /*   {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number'},
    {field: 'account_number', title: 'Account Number'},
    //{field: 'businessSegment', title: 'Business Segment'},*/
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'returnedBack', title: 'Returned Back'},
    {field: 'initUser', title: 'Init User'},
];
const rowsWithBulkApproval = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number'},
    {field: 'account_number', title: 'Account Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch Name'},
    {field: 'Status', title: 'Status'},
    {field: 'previewImage', title: 'Preview Image'},
];
const rowsWithReturned = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
  /*  {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number'},
    {field: 'account_number', title: 'Account Number'},*/
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'returnedBy', title: 'Returned By'},
    {field: 'initUser', title: 'Init User'},
];
const rowsCS = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
   /* {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},*/
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'initUser', title: 'Init User'},
    {field: 'branch_name', title: 'Branch'}, 
    // {field: 'Status', title: 'Status'},
];
const rowsCSInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
  /*  {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},*/
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'initUser', title: 'Init User'},
    // {field: 'Action', title: 'Action'},
];
const assignPending = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    {field: 'businessSegment', title: 'Business Segement'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'initUser', title: 'Init User'},
    {field: 'Unlock', disableClick: true, title: 'Unlock'},
    {field: 'CaseClose', disableClick: true, title: 'Case Close'},

    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const suspendedRequest = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    {field: 'businessSegment', title: 'Business Segement'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'initUser', title: 'Init User'},
    {field: 'revert', disableClick: true, title: 'Revert'},
];

const makerView = [
    //{id: 'id', numeric: true, disablePadding: true, label: 'SL'},
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    {field: 'businessSegment', title: 'Business Segement'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},


    /*  {id: 'View', numeric: false, disablePadding: false, label: 'Action'}*/
];
const rowsCSWithReturned = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'returnedBy', title: 'Returned By'},
    {field: 'initUser', title: 'Init User'},
];
const rowsMerchantInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Merchant Name'},
    {field: 'freeFlag5', title: 'DBA Name'},
    {field: 'freeFlag6', title: 'MID'},
   // {field: 'tid', title: 'TID'},
    {field: 'account_number', title: 'Account Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
   // {field: 'location', title: 'Location'},
  //  {field: 'dseId', title: 'DSE ID'},
   // {field: 'dseName', title: 'DSE Name'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},

];
const rowsForRoleSwitch = [
    {field: 'SL', title: 'SL'},
    {field: 'userId', title: 'User ID'},
    {field: 'name', title: 'Name'},
    {field: 'email', title: 'Email'},
    {field: 'mobileNumber', title: 'Mobile Number'},
    {field: 'action', title: 'Action'},

];

const rowsForInsuranceMasterDataList = [
    {field: 'SL', title: 'SL'},
    {field: 'insurancType', title: 'Insurance Type'},
    //{field: 'Code', title: 'Code'},
    {field: 'amountCalculationType', title: 'Amount Calculation Type'},
    {field: 'baseValueStart', title: 'Base Value Start'},
    {field: 'baseValueEnd', title: 'Base Value End'},
    {field: 'stampValue', title: 'Stamp Value'},
    {field: 'incrementalAmount', title: 'Incremental Amount'},
    {field: 'incrementalAmountValue', title: 'Incremental Amount Value'},
    {field: 'baseValue', title: 'Base Value'},
   // {field: 'action', title: 'Action'},

];

const rowsForInsuranceMasterDataForBasicList = [
    {title: 'SL', field: 'SL'},
    {title: 'Stamp Category', field: 'stampCategory'},
    {title: 'Value Amount', field: 'valueAmount'},
    {title: 'Stamp Description', field: 'stampDescription'},
    {title: 'Stamp Code', field: 'stampCode'},

   // {field: 'action', title: 'Action'},

];

const rowsForworkplaceUnitList = [
    {field: 'SL', title: 'SL'},
  //  {field: 'region', title: 'REGION'},
  //  {field: 'short_name', title: 'SHORT_NAME'},
   // {field: 'sol_id', title: 'SOL_ID'},
   // {field: 'status', title: 'STATUS'},
    {field: 'unit_name', title: 'UNIT_NAME'},
    {field: 'workplace_type', title: 'WORKPLACE_TYPE'},
  //  {field: 'parent', title: 'PARENT'},
   // {field: 'priority_sol_id', title: 'PRIORITY_SOL_ID'},
  //  {field: 'group_mail', title: 'GROUP_MAIL'},
  //  {field: 'debit_card_in_out_branch', title: 'DEBIT_CARD_IN_OUT_BRANCH'},
  //  {field: 'cms_sol_id', title: 'CMS_SOL_ID'},
    {field: 'branch_type', title: 'BRANCH_TYPE'},
    {field: 'branch_parent', title: 'BRANCH_PARENT'},
    {field: 'action', title: 'Action'},

];
const rowsForDeferral = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'deferralId', title: 'Deferral Id'},
    {field: 'deferralType', title: 'Deferral Type'},
    {field: 'deferralDocType', title: 'Deferral Doc Type'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch'}, 
    {field: 'Status', title: 'Status'},
    {field: 'expire', title: 'Expire Date'},
    {field: 'expireStatus', title: 'Expire Status'},
];
const rowsBranch = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'cb_number', title: 'CB Number/ClientID'},
    {field: 'account_number', title: 'Account/Card Number'},
    //{field: 'businessSegment', title: 'Business Segment'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'Service', title: 'Service'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch Name'},
    {field: 'Status', title: 'Status'},
    {field: 'initUser', title: 'Init User'},
];

const remittanceGroupInbox = [
    {field: 'SL', title: 'SL'},
    {field: 'CaseId', title: 'Case ID'},
    {field: 'customer_name', title: 'Name'},
    {field: 'currency', title: 'Currency'},
    {field: 'account_number', title: 'Account Number'},
    {field: 'amount', title: 'Amount'},
    {field: 'category_type', title: 'Category'},
    {field: 'sub_category_type', title: 'Sub Category'},
    {field: 'reference', title: 'Reference'},
    {field: 'Date', title: 'Date of Activity'},
    {field: 'currentUserName', title: 'Current User'},
    {field: 'branch_name', title: 'Branch Name'},
    {field: 'Status', title: 'Status'},
    {field: 'returnedBack', title: 'Returned Back'},
    {field: 'initUser', title: 'Init User'},
];

export {
    rows,
    rowsWithGroupInbox,
    auxiliaryTaskInbox,
    auxiliaryMappingTaskInbox,
    rowsWithReturned,
    rowsCS,
    rowsCSInbox,
    rowsCSWithReturned,
    rowsMerchantInbox,
    assignPending,
    makerView,
    rowsBranch,
    rowsForDeferral,
    rowsForworkplaceUnitList,
    rowsForRoleSwitch,
    rowsForInsuranceMasterDataList,
    rowsForInsuranceMasterDataForBasicList,
    rowsWithBulkApproval,
    suspendedRequest,
    remittanceGroupInbox
}