import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import CBNotFound from "../CASA/CBNotFound";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let jsonForm = [
    {
        varName: "groupName",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Select Group",
        required: true,
        grid: 2,
    },
    // {
    //     varName: "userName",
    //     type: "select",
    //     enum: [],
    //     label: "Select user",
    //     required: true,
    //     grid: 2,
    // }
];


class AssignGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUserList: [],
            userList: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            varValue: {},
            inputData: {},
            showValue: true,
            alert: false,
            title: "",
            notificationMessage: "",
            loading: true,
            cbNotFound: false
        };
    }


    componentDidMount() {
        let userList = [];
        let getUrl = backEndServerURL + "/GetAllCallCategoryGroup";
        axios.get(getUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    jsonForm[0].enum = response.data
                }
                let url = backEndServerURL + "/GetAllCardDivisionUsers";
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((cardResponse) => {
                        cardResponse.data.map((item) => {
                            userList.push({
                                title: item
                            })
                        });
                        this.setState({
                            userList: userList,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false
                        })
                    });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
    }

    updateComponent = () => {
        this.forceUpdate();
    };


    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    handleSubmit = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let postData = [...new Set(this.state.selectedUserList)];
            let url = backEndServerURL + "/TagUserToCardDivisionTeam/" + this.state.inputData.groupName.key;
            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    this.setState({
                        title: "Successful",
                        notificationMessage: "Group Assign Successful!",
                        loading: false,
                        alert: true
                    });
                    setTimeout(() => {
                        window.location.reload()
                    }, 300)
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false,
                        cbNotFound: true,
                        title: error.response.data.message
                    })
                });
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    handleChangeMultipleSelect = (event, option) => {
        let userList = [];
        if(option.length > 0){
            for(let i = 0; i < option.length; i++){
                userList.push(option[i].title)
            }
        }
        this.setState({
            selectedUserList: userList
        })
    };

    renderUserList = () => {
        return (
            <Grid item xs={3}>
                <Grid item>
                    <label className="input-label-common">Select userName</label>
                </Grid>
                <Grid item>
                    <Autocomplete
                        multiple={true}
                        id="tags-standard"
                        options={this.state.userList}
                        onChange={(event, option) => this.handleChangeMultipleSelect(event, option)}
                        getOptionLabel={option => option.title}
                        size="small"
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined" fullWidth
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    };


    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Assign Call Group
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>
                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    jsonForm,
                                    this.updateComponent
                                )}
                                {this.renderUserList()}
                                {this.state.selectedUserList.length > 0 && <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 20,
                                    }}
                                    onClick={this.handleSubmit}
                                >
                                    Submit
                                </button>}
                            </ThemeProvider>
                            {this.renderNotification()}
                        </Grid>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

export default withStyles(styles)(AssignGroup);
