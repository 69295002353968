import React, {Component} from "react";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import CloseIcon from "@material-ui/icons/Close";
import MyValidation from "../../JsonForm/MyValidation";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Notification from "../../NotificationMessage/Notification";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";

let jsonForm = [
    {
        "label": "Address Field 1",
        "varName": "addressField1",
        "type": "text",
        "multiline": true,
        "grid": 3,
        "required": true,
    },
    {
        "label": "Address Field 2",
        "varName": "addressField2",
        "type": "text",
        "multiline": true,
        "grid": 3,
        "required": true,
    },
    {
        "varName": "city",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "City",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "state",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Division(State)",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "postalCode",
        "type": "text",
        "validation": "numeric",
        "label": "Postal Code",
        "grid": 3,
        "required": true,
    },

    {
        "varName": "country",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Country",
        "grid": 3,
        "required": true,
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class CpvForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputData: {},
            varValue: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            loading: true,
            disabled: false
        };
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    componentDidMount() {
        {
            this.dynamicApiCall("city", "city")
        }
        {
            this.dynamicApiCall("state", "state",)
        }
        {
            this.dynamicApiCall("nationality", "country")
        }
    }

    dynamicApiCall = (fieldName, apiType) => {

        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    if (apiType === "city") {
                        if (jsonForm[2].varName === "city") {
                            jsonForm[2].enum = response.data;
                            console.log(jsonForm[2].enum)
                        }
                    } else if (apiType === "state") {
                        if (jsonForm[3].varName === "state") {
                            jsonForm[3].enum = response.data
                        }
                    } else if (apiType === "country") {
                        if (jsonForm[5].varName === "country") {
                            jsonForm[5].enum = response.data;
                            setTimeout(() => {
                                this.setState({
                                    loading: false
                                })
                            }, 1000)
                        }
                    }

                }
            })
            .catch((error) => {
                console.log(error);
            })

    };

    submitHandler = () => {
        let error = MyValidation.defaultValidation(jsonForm, this.state);
        this.forceUpdate();

        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                disabled: true
            });
            let cpvUrl = backEndServerURL + "/cpvVerifiedUpdate";
            let postData = {...this.state.inputData};
            postData.id = this.props.id;
            axios.post(cpvUrl, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.props.closeModal('success');
                        this.setState({
                            alert: true,
                            loading: false,
                            disabled: false,
                            title: "Successful",
                            notificationMessage: "CPV Verified Successful!"
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.props.closeModal('failed');
                    this.setState({
                        alert: true,
                        loading: false,
                        disabled: false,
                        title: "Failed",
                        notificationMessage: "Unable to Connect with Finacle API!"
                    });
                })
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    renderJsonForm = () => {
        return CommonJsonFormComponent.renderJsonForm(this.state, jsonForm, this.updateComponent)
    };


    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <React.Fragment>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >CPV Verified <a><CloseIcon onClick={this.props.closeModal} style={{
                                position: 'absolute',
                                right: 10,
                                color: "#000000"
                            }}/></a></h4>
                        </CardHeader>
                        <CardBody>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderJsonForm()}
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            {this.renderNotification()}

                            <Grid item xs="3" style={{marginTop: "10px"}}>
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        marginRight: "10px",
                                    }}
                                    disabled={this.state.disabled}
                                    onClick={this.submitHandler}
                                >
                                    Submit
                                </button>
                            </Grid>
                        </CardBody>
                    </Card>
                </React.Fragment>
            );
        }
    }

}

export default withStyles(styles)(CpvForm)