import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import Button from "@material/react-button";
import MaterialTable from "material-table";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {createMuiTheme} from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import axiosDownload from '../FunctionForFileDownload';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {buttonStyle, localization, options, tabActive, tabInactive, tableIcons} from "../configuration";
import UploadFile from "../UploadFile";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MyValidation from "../../JsonForm/MyValidation";
import Notification from "../../NotificationMessage/Notification";
import CBNotFound from "../../workflow/CASA/CBNotFound";

let SearchForm = [
    {
        varName: "accountNumber",
        type: "text",
        label: "Account Number",
        grid: 2,
        'validation': "numeric",
        // required: true
    },
    {
        varName: "constitution",
        type: "autoCompleteValueReturn",
        enum: [],
        label: "Account Type",
        grid: 2,
        // required: true
    },
    {
        varName: "startDate",
        type: "date",
        label: "Start Date",
        grid: 2,
        // required: true
    },

    {
        varName: "endDate",
        type: "date",
        label: "End Date",
        grid: 2,
        // required: true
    }
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px',
            }
        },

    }
});

class WelcomeLetterBranch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            loading: true,
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            searchItem: "",
            priorityStatus: this.props.match.params.priority,
            caseId: "",
            title: "",
            loader: false,
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            cbNotFound: false,
            alert: false,
            showData: true,
            selectedCount: 0,
            pageNumber: 0,
            totalRow: 0,
            rowsPerPage: 100,
            rowClickedId: 0,
            allData: [],
            columns: [
                {title: "B. Name", field: "branchName", export: false},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "status", field: "status"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"}
            ],
            Buttoncolumns: [
                {title: "B. Name", field: "branchName", export: false},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "status", field: "status"},
                {title: "A/c Open Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
            ],
            branchReceived: [
                {title: "B. Name", field: "branchName", export: false},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "status", field: "status"},
                {title: "A/c Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "File Upload", field: "file_upload"}
            ],
            cpvRequest: [
                {title: "B. Name", field: "branchName", export: false},
                {title: "A/c Number", field: "accountNumber"},
                {title: "A/c Type", field: "accountType"},
                {title: "Customer Name", field: "customerName"},
                {title: "Address", field: "address"},
                {title: "City", field: "city"},
                {title: "Mobile", field: "mobile"},
                {title: "status", field: "status"},
                {title: "A/c Date", field: "account_open_date"},
                {title: "Generation Date", field: "generation_date"},
                {title: "Action", field: "cpv_request"}
            ]
        };
    }

    handleItemClick = (e, {name, style}) =>
        this.setState({
            activeItem: name,
            backgroundColor: style
        });

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            loader: true,
            pageNumber: 0
        }, this.fetchDataFromAPI(value));
    };

    dateConverter = (value) => {
        if (value === null || value === undefined) {
            return ' ';
        } else {
            let dateString;
            if (value.length > 10) {
                dateString = value.substr(0, 10);
            } else {
                dateString = value
            }
            return dateString
        }
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, page, status) => {
        // e.preventDefault();

        let inputValue = {...this.state.inputData};
        inputValue.constitution = this.state.inputData.constitution ? this.state.inputData.constitution.value : "";
        let postData = this.removeNullValue(inputValue);
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();

        if (error === true || Object.keys(postData).length === 0) {
            return 0;
        }


        if (status === "All") {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: "All"
            });
        } else {
            this.setState({
                loader: true,
                pageNumber: page,
                activeItem: status
            });
        }

        let inbox_url = backEndServerURL + "/filterBranchWelcomeLetterAccountNumberBetweenDate/" + status + "/" + this.props.match.params.priority + "/" + page;

        axios
            .post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].solId;
                    rowData = {
                        id: response.data.content[i].id,
                        branchName: response.data.content[i].branchName,
                        customerId: response.data.content[i].customerId,
                        accountNumber: response.data.content[i].accountNumber,
                        accountType: response.data.content[i].constitution,
                        title: response.data.content[i].title,
                        customerName: response.data.content[i].accountName,
                        account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                        address: response.data.content[i].addressLine1,
                        city: response.data.content[i].city,
                        mobile: response.data.content[i].phone,
                        status: response.data.content[i].status,
                        generation_date: this.dateConverter(response.data.content[i].generationDate),
                        update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                        delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                        file_upload: this.uploadIcon(response.data.content[i].id),
                        cpv_request: this.cpv(response.data.content[i].id)
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    totalRow: response.data.totalElements,
                    varValue: {...postData},
                    showValue: true,
                    loading: false,
                    loader: false,
                    searchItem: this.state.activeItem,
                });

            })
            .catch(error => {
                console.log(error);
            });
    };

    resetHandler = () => {
        window.location.reload();
    };

    fetchDataFromAPI = (status) => {
        this.setState({
            pageNumber: 0,
            activeItem: status,
        });

        if (this.state.searchItem !== "") {
            this.searchData(this, this.state.pageNumber, status);
        } else {
            this.setState({
                activeItem: status,
                loader: true
            });

            let url;
            if (status === 'All') {
                url = backEndServerURL + "/getBranchAllWelcomeLetter/0/" + this.state.priorityStatus;
            } else {
                url = backEndServerURL + "/getBranchWelcomeLetterStatus/" + status + "/0/" + this.state.priorityStatus;
            }

            axios
                .get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            status: response.data.content[i].status,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                            file_upload: this.uploadIcon(response.data.content[i].id),
                            cpv_request: this.cpv(response.data.content[i].id)
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    };


    handleChangePage = (pageNumber, status) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        this.setState({
            loader: true
        });

        let inbox_url;
        if (status === this.state.searchItem) {
            this.searchData(this, page, status);
        } else if (this.state.activeItem === status) {
            if (status === 'All') {
                inbox_url = backEndServerURL + "/getBranchAllWelcomeLetter/" + page + "/" + this.state.priorityStatus;
            } else {
                inbox_url = backEndServerURL + "/getBranchWelcomeLetterStatus/" + status + "/" + page + "/" + this.state.priorityStatus;
            }

            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            update_file_date: this.dateConverter(response.data.content[i].modifiedDate),
                            delivery_date: this.dateConverter(response.data.content[i].modifiedDate),
                            file_upload: this.uploadIcon(response.data.content[i].id),
                            cpv_request: this.cpv(response.data.content[i].id)
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        activeItem: status,
                        pageNumber: page,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };


    componentDidMount() {

        let apiUrl = backEndServerURL + "/finMasterData/" + "constitution";
        axios.get(apiUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < SearchForm.length; i++) {
                        let jsonObject = SearchForm[i] ? SearchForm[i] : {};
                        if (jsonObject.varName === "constitution") {
                            jsonObject.enum = response.data;
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

        let open_url = backEndServerURL + "/getBranchAllWelcomeLetter/0/" + this.state.priorityStatus;
        axios
            .get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let responseArray = [];
                let rowData;
                let responseRowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    responseRowData = response.data.content[i].solId;
                    rowData = {
                        id: response.data.content[i].id,
                        branchName: response.data.content[i].branchName,
                        customerId: response.data.content[i].customerId,
                        accountNumber: response.data.content[i].accountNumber,
                        accountType: response.data.content[i].constitution,
                        title: response.data.content[i].title,
                        customerName: response.data.content[i].accountName,
                        account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                        address: response.data.content[i].addressLine1,
                        city: response.data.content[i].city,
                        mobile: response.data.content[i].phone,
                        status: response.data.content[i].status,
                        generation_date: this.dateConverter(response.data.content[i].generationDate),
                    };
                    array.push(rowData);
                    responseArray.push(responseRowData);
                }

                this.setState({
                    allData: array,
                    responseData: responseArray,
                    loading: false,
                    loader: false,
                    totalRow: response.data.totalElements,
                });
            })
            .catch(error => {
                console.log(error);
            });

        let defaultDate = new Date();
        let isoDate = defaultDate.toISOString();
        let currentDate = isoDate.substr(0, 10);

        this.setState({
            currentDate: currentDate
        });

    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.priority !== prevProps.match.params.priority) {
            this.setState({
                inputData: {},
                varValue: {},
                selectedDate: {},
                pageNumber: 0,
                activeItem: "All",
                priorityStatus: this.props.match.params.priority,
                loading: true,
                loader: true,
            });
            let open_url = backEndServerURL + "/getBranchAllWelcomeLetter/0/" + this.props.match.params.priorit;
            axios
                .get(open_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let responseArray = [];
                    let rowData;
                    let responseRowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        responseRowData = response.data.content[i].solId;
                        rowData = {
                            id: response.data.content[i].id,
                            branchName: response.data.content[i].branchName,
                            customerId: response.data.content[i].customerId,
                            accountNumber: response.data.content[i].accountNumber,
                            accountType: response.data.content[i].constitution,
                            title: response.data.content[i].title,
                            customerName: response.data.content[i].accountName,
                            account_open_date: this.dateConverter(response.data.content[i].accountOpenDate),
                            address: response.data.content[i].addressLine1,
                            city: response.data.content[i].city,
                            mobile: response.data.content[i].phone,
                            status: response.data.content[i].status,
                            generation_date: this.dateConverter(response.data.content[i].generationDate),
                            file_upload: this.uploadIcon(response.data.content[i].id),
                            cpv_request: this.cpv(response.data.content[i].id)
                        };
                        array.push(rowData);
                        responseArray.push(responseRowData);
                    }

                    this.setState({
                        allData: array,
                        responseData: responseArray,
                        loading: false,
                        loader: false,
                        totalRow: response.data.totalElements,
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    renderHeader = () => {
        return (
            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                <h4 style={{color: "white"}} >Deliverable Management (Welcome Letter
                    - {this.props.match.params.priority === "priority" ? "Priority" : "Non Priority"})</h4>
            </CardHeader>
        )
    };

    uploadIcon = (appId) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.uploadFunction(appId)}
            >

                <CloudUploadIcon/>

            </button>
        )
    };

    cpv = (appId) => {
        return (
            <button
                className="btn btn-outline-primary btn-sm"
                style={{
                    verticalAlign: 'middle',
                }}

                onClick={() => this.uploadFunction(appId)}
            >
                CPV Request
            </button>
        )
    };

    uploadFunction = (appId) => {
        this.setState({
            uploadModal: true,
            rowClickedId: appId
        });
    };


    closeUpload = () => {
        this.setState({
            uploadModal: false
        })
    };

    fileUpload = () => {

        const {classes, onClose} = this.props;
        return (
            <Dialog
                fullWidth="true"
                maxWidth="md"
                className={classes.modal}
                classes={{paper: classes.dialogPaper}}
                open={this.state.uploadModal}>
                <DialogContent className={classes.dialogPaper}>

                    <UploadFile
                        itemId={this.state.rowClickedId}
                        activeItem={this.state.activeItem}
                        closeModal={this.closeUpload}
                        functionForCPV={this.functionForCPV}
                    />
                </DialogContent>
            </Dialog>
        )
    };

    functionForCPV = (value) => {
        if (value === "success") {
            this.setState({
                loader: true,
                uploadModal: false
            });
            this.fetchDataFromAPI('Unconfirmed Address Marked');
            this.functionForNotificationMessage("Success", "File Upload and Status Change Successful!")
        } else {
            this.functionForNotificationMessage("Failed", "File Upload Failed!")
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false,
        })
    };

    render() {
        const {rows, columns} = this.state;
        const {activeItem} = this.state;
        const {classes, onClose} = this.props;
        if (this.state.loading) {

            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>

                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Welcome Letter
                            </h4>
                        </CardHeader>
                        <CardBody>
                            {this.fileUpload()}
                            {this.renderNotification()}
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            display: "block",
                                            height: 30,
                                            marginTop: 15,
                                        }}
                                        onClick={e => {
                                            this.searchData(e, 0, 'All');
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            display: "block",
                                            height: 30,
                                            marginTop: 15,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                        </CardBody>
                    </Card>
                    <Card>
                        {
                            this.renderHeader()
                        }
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.cbNotFound}>
                            <DialogContent className={classes.dialogPaper}>

                                <CBNotFound
                                    closeModal={this.closeModalCBNotFound}
                                    title={this.state.title}
                                />
                            </DialogContent>
                        </Dialog>
                        <CardBody>
                            <div>
                                <React.Fragment>
                                    <div></div>
                                    <div>
                                        <Menu pointing>
                                            <Menu.Item
                                                name="All"
                                                active={activeItem === "All"}
                                                style={
                                                    activeItem === "All"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "All");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Branch Received"
                                                active={activeItem === "Branch Received (Return by courier)"}
                                                style={
                                                    activeItem === "Branch Received (Return by courier)"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                //     onClick={this.handleItemClick}
                                                onClick={e => {
                                                    this.headerSelect(e, "Branch Received (Return by courier)");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Unconfirmed Address Marked"
                                                active={activeItem === "Unconfirmed Address Marked"}
                                                style={
                                                    activeItem === "Unconfirmed Address Marked"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Unconfirmed Address Marked");
                                                }}
                                            />
                                            <Menu.Item
                                                name="Delivered"
                                                active={activeItem === "Delivered"}
                                                style={
                                                    activeItem === "Delivered"
                                                        ? tabActive
                                                        : tabInactive
                                                }
                                                onClick={e => {
                                                    this.headerSelect(e, "Delivered");
                                                }}
                                            />

                                        </Menu>

                                        {/*<Segment>*/}
                                        {this.tableChoice()}
                                        {/*</Segment>*/}
                                    </div>
                                </React.Fragment>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            );
        }
    }

    actionForBranchReceivedToDelivered = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received (Return by courier)") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateWelcomeLetterStatus/Delivered";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        this.fetchDataFromAPI('Branch Received (Return by courier)')
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    };

    functionForNotificationMessage = (title, message) => {
        this.setState({
            title: title,
            notificationMessage: message,
            alert: true,
        })

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    actionForBranchReceivedToUnconfirmedAddressReturn = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received (Return by courier)") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                // let finacleApi_url = backEndServerURL + "/unconfirmedAddressUpdate";
                // axios
                //     .post(finacleApi_url, rowIdArray, {
                //         headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                //     })
                //     .then(response => {
                //         if (response.status === 200) {
                let single_url = backEndServerURL + "/updateWelcomeLetterStatus/Unconfirmed Address Returned";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.functionForNotificationMessage("Successful!", "Status Update Successful");
                            this.fetchDataFromAPI('Branch Received (Return by courier)')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
                //     }
                // })
                // .catch(error => {
                //     this.setState({
                //         cbNotFound: true,
                //         title: "Unable to connect with Finacle API!"
                //     });
                //     console.log(error);
                // });
            }
        }
    };

    actionForUnconfirmedAddressToSendToCPV = (event, rowData, props) => {
        if (this.state.activeItem === "Unconfirmed Address Marked") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];
            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }

                let single_url =
                    backEndServerURL +
                    "/updateWelcomeLetterStatus/CPV Request";
                axios
                    .post(single_url, rowIdArray, {
                        headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                    })
                    .then(response => {
                        if (response.status === 200) {
                            this.fetchDataFromAPI('Unconfirmed Address Marked')
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    };

    actionForBranchReceivedToDownload = (event, rowData, props) => {
        if (this.state.activeItem === "Branch Received (Return by courier)") {
            let allSelectValue = (rowData, props.data);
            if (allSelectValue === undefined) return 0;
            let rowIdArray = [];

            if (allSelectValue != null) {
                for (let i = 0; i < allSelectValue.length; i++) {
                    rowIdArray.push(allSelectValue[i].id);
                }
                let download_url = backEndServerURL + "/generateAllWelcomeLetters/Generate";
                axiosDownload.downloadFile(download_url, 'POST', rowIdArray, `${this.state.currentDate}-welcome-letter.pdf`);
                this.fetchDataFromAPI('Branch Received (Return by courier)')
            }
        }
    };


    tableChoice() {
        if (this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>

                            <CircularProgress style={{marginLeft: '50%'}}/>

                        </div>
                    </MuiThemeProvider>
                </div>
            )
        } else if (this.state.activeItem === "All" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.Buttoncolumns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'All')
                                            }
                                            }
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Branch Received (Return by courier)" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.branchReceived}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Branch Received (Return by courier)')
                                            }
                                            }
                                        />
                                    ),
                                    Actions: props => (
                                        <React.Fragment>
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDelivered(event, rowData, props)}
                                            >
                                                Delivered
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToUnconfirmedAddressReturn(event, rowData, props)}
                                            >
                                                Unconfirmed Address Return
                                            </Button>
                                            &nbsp;
                                            <Button
                                                style={buttonStyle}
                                                onClick={(event, rowData) => this.actionForBranchReceivedToDownload(event, rowData, props)}
                                            >
                                                Download
                                            </Button>
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else if (this.state.activeItem === "Unconfirmed Address Marked" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.cpvRequest}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, "Unconfirmed Address Marked")
                                            }
                                            }
                                        />
                                    ),
                                    // Actions: props => (
                                    //     <Button
                                    //         style={buttonStyle}
                                    //         onClick={(event, rowData) => this.actionForUnconfirmedAddressToSendToCPV(event, rowData, props)}
                                    //     >
                                    //         Send to CPV Request
                                    //     </Button>
                                    // )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        } else if (this.state.activeItem === "Delivered" && !this.state.loader) {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.Buttoncolumns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                                components={{
                                    Pagination: props => (
                                        <TablePagination
                                            {...props}
                                            rowsPerPageOptions={[100]}
                                            count={this.state.totalRow}
                                            page={this.state.pageNumber}
                                            onChangePage={(e, page) => {
                                                this.handleChangePage(page, 'Delivered')
                                            }
                                            }
                                        />
                                    )
                                }}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>

                //new button for microsoft word download
            );
        } else {
            return (
                <div>
                    <MuiThemeProvider theme={themeO}>
                        <div style={{maxWidth: "100%"}}>
                            <MaterialTable
                                columns={this.state.columns}
                                data={this.state.allData}
                                title={""}
                                options={options}
                                localization={localization}
                                icons={tableIcons}
                            />
                        </div>
                    </MuiThemeProvider>
                </div>
            );
        }
    }
}

export default withStyles(styles)(WelcomeLetterBranch);
