import React, {Component} from "react";
import axios from "axios";

class FileDownload extends Component {
    static downloadFile(url, method, data, fileName, type) {
        return new Promise((resolve, reject) => {
            axios({
                url: url,
                method: method,
                data: data,
                responseType: 'blob', // important
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    'content-type': 'application/json'
                }
            }).then((response) => {
                let data = {};
                data = response.data
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                console.log(link)
                link.click();
                resolve(response.data)
            }).catch(err => reject(err))
        })
    }
}

export default FileDownload
