import React, {Component} from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import "../../../Static/css/RelationShipView.css";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from '../../../Common/Functions';
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog} from "@material-ui/core";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import Notification from "../../NotificationMessage/Notification";
import validator from "validator";
import loader from "../../../Static/loader.gif";
import CBNotFound from "../CASA/CBNotFound";
import Table from "../../Table/Table";
import GridItem from "../../Grid/GridItem";
import MyValidationDedup from "../../JsonForm/MyValidationDedup";
import GridContainer from "../../Grid/GridContainer";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "16px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "600",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },

    }
};

class CommonDedup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchData: false,
            cbNotFound: false,
            inputData: {},
            getData: false,
            varValue: {},
            title: "",
            loading: true,
            notificationMessage: "",
            alert: false,
            selectedDate: {},
            showValue: false,
            err: false,
            errorArray: {},
            errorMessages: {},
            disabled: false,
            showNewButton: true,
            renderDedupFrom: false,
            showSignatoryForm: false,
            mandateTableArray: [],
            objectForNewMandate: [],
            handleChangeJoint: 0,
            jointSearchTable: [],
            sdnData: [],
            values: [],
            jointDedupData: {},
            searchTableData: false,
            searchTableSdnData: false,
            AddDeferal: false,
            getDeferalList: [],
            fileUploadData: [],
            phoneCountryCode: [],
            nationalityApiData: [],
            getRemarks: [],
            appId: 0,
            jointDataSaveId: '',
            caseId: 0
        };
    }

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null' || clone[prop] === '-1')
                delete clone[prop];
        return clone;
    };


    createTableData = (sl, cb, name, relationship) => {

        return ([
            sl, cb, name, relationship
        ])

    };


    componentDidMount() {
        this.functionForRenderForm()
    }


    functionForRenderForm = () => {
        if (this.props.numberOfDedup) {
            let objectForNewMandate = [];
            let sl;
            let inputData = this.state.inputData;
            for (let i = 0; i < parseInt(this.props.numberOfDedup); i++) {
                sl = i + 1;
                objectForNewMandate.push(
                    {
                        "varName": "applicant" + i,
                        "type": "titleLeft",
                        "label": this.numberToCharacter(sl) + " Applicant ",
                        "grid": 12,
                    },
                    {
                        "varName": "cbNumber" + i,
                        "type": "textDedup",
                        "label": "Existing CB Number",
                        "errorMessage": "Error",
                        "grid": 2,
                    },
                    // {
                    //     "varName": "accountNumber" + i,
                    //     "type": "textDedup",
                    //     "label": "Account Number",
                    //     "errorMessage": "Error",
                    //     "grid": 2,
                    // },
                    {
                        "varName": "customerName" + i,
                        "type": "textDedup",
                        "label": "Customer Name *",
                        "asteriks": true,
                        "multiline": true,
                        "grid": 2,
                    },
                    {
                        "varName": "nid" + i,
                        "type": "textDedup",
                        "validation": "nid",
                        "label": "NID +",
                        "errorMessage": "Error",
                        "grid": 2,
                    },
                    {
                        "varName": "smartCard" + i,
                        "type": "textDedup",
                        "validation": "smartCard",
                        "label": "Smart Card +",
                        "errorMessage": "Error",
                        "grid": 2,
                    },
                    {
                        "varName": "passport" + i,
                        "type": "textDedup",
                        "validation": "passport",
                        "label": "Passport +",
                        "grid": 2,
                    },
                    {
                        "varName": "registrationNo" + i,
                        "type": "textDedup",
                        "validation": "birthCertificateNumber",
                        "label": "Birth Cer. +",
                        "grid": 2,
                    },
                    {
                        "varName": "drivingLicense" + i,
                        "type": "textDedup",
                        "label": "Driving License",
                        "grid": 2,
                    },
                    {
                        "varName": "dob" + i,
                        "type": "date",
                        "label": "Date Of Birth *",
                        "asteriks": true,
                        "maxDate": true,
                        "grid": 2,
                    },
                    {
                        "varName": "mobileTypeJoint" + i,
                        "type": "select",
                        "enum": [
                            "Local",
                            "Overseas",
                        ],
                        "label": "Mobile Type ",
                        "asteriks": true,
                        "grid": 2,
                    },
                    {
                        "varName": "countryCodeIndividual" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.props.phoneCountryCode,
                        "label": "Country Code ",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,
                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "phonePrefix": true,
                        "validation": "phone",
                        "label": "Mobile Number ",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Local",
                        "grid": 2,
                    },
                    {
                        "varName": "phone" + i,
                        "type": "textDedup",
                        "label": "Mobile Number",
                        "asteriks": true,
                        "conditional": true,
                        "conditionalVarName": "mobileTypeJoint" + i,
                        "conditionalVarValue": "Overseas",
                        "grid": 2,
                    },
                    {
                        "varName": "email" + i,
                        "type": "textDedup",
                        "validation": "email",
                        "multiline": true,
                        "label": "Email",
                        "grid": 2,
                    },
                    {
                        "varName": "tin" + i,
                        "type": "textDedup",
                        "etin": true,
                        "validation": "etin",
                        "label": "E-Tin",
                        "grid": 2,
                    },
                    {
                        "varName": "nationality" + i,
                        "type": "autoCompleteValueReturn",
                        "enum": this.props.nationalityApiData,
                        "label": "Nationality *",
                        "asteriks": true,
                        "grid": 2,
                    },
                    {
                        "type": "empty",
                        "grid": 12,
                    },
                );
                inputData["mobileTypeJoint" + i] = "Local";
                inputData["nationality" + i] = {masterDataName: "country", key: "BD", value: "BANGLADESH"};
            }
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                showValue: true,
                objectForNewMandate: objectForNewMandate,
                handleChangeJoint: this.props.numberOfDedup,
                renderDedupFrom: true,
                loading: false
            });
            this.forceUpdate();
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };


    onKeyDownForNewMandate = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleDedupSearch();
        }
    };


    numberToCharacter = (i) => {
        let j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + "st";
        }
        if (j == 2 && k != 12) {
            return i + "nd";
        }
        if (j == 3 && k != 13) {
            return i + "rd";
        }
        return i + "th";
    };


    renderDedupForm = () => {
        if (this.props.numberOfDedup && this.state.renderDedupFrom && this.state.objectForNewMandate.length > 0) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNewMandate, this.updateComponent, this.onKeyDownForNewMandate)
            )
        }
    };

    renderDedupButton = () => {
        if (this.props.numberOfDedup && this.state.renderDedupFrom && this.state.objectForNewMandate.length > 0) {
            return (
                <GridItem xs={12}>
                    <center>
                        <button className="btn btn-outline-primary btn-sm" onClick={this.handleDedupSearch}>Search
                        </button>
                        &nbsp;
                        <button className="btn btn-outline-primary btn-sm" onClick={this.cancelDedup}>Go Back</button>
                    </center>
                </GridItem>
            )
        }
    };

    trimFunctionInputData = (emptyString) => {

        if (emptyString !== null && emptyString !== undefined && emptyString !== "") {
            let string = emptyString;
            let typeOfString = typeof string;
            if (typeOfString === "string" || typeOfString === "number") {
                return string.trim()
            } else if (typeOfString === "object") {
                return string.key;
            } else {
                return ""
            }


        } else {
            return ""
        }

    };


    emptyStringSet = (jsonObject) => {
        let clone = JSON.parse(JSON.stringify(jsonObject));
        for (let i = 0; i < clone.length; i++) {
            let data = clone[i]["varName"];
            if (clone[i]["varName"] !== undefined) {
                data = data.trim()

            }

            if (this.state.inputData[data] !== null && this.state.inputData[data] !== undefined && !validator.isEmpty(this.trimFunctionInputData(this.state.inputData[data]))) {
                let getData = this.state.inputData[data];
                let typeOfString = typeof getData;

                if (typeOfString === "string" || typeOfString === "number") {
                    this.state.inputData[data] = (this.state.inputData[data]).trim()
                } else if (typeOfString === "object") {
                    this.state.inputData[data] = (this.state.inputData[data]);
                }

            } else {
                this.state.inputData[data] = ""
            }


        }
        return clone;
    };

    handleDedupSearch = () => {
        this.setState({
            showSignatoryForm: false,
        });

        let error = MyValidationDedup.CustomValidationJoint(this.emptyStringSet(this.state.objectForNewMandate), this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true
            });
            let postData = {};
            let objectForJoinAccount = {"customerInfoList": []};
            for (let j = 0; j < parseInt(this.props.numberOfDedup); j++) {
                let dateString;
                if (this.state.inputData["dob" + j] && this.state.inputData["dob" + j] !== -1) {
                    let da = this.state.inputData["dob" + j].split(",");
                    if (da !== "" && da.length > 0) {

                        let monthDateYear = da[0].split("/");
                        let month, date;


                        if (monthDateYear[0].length === 1)
                            month = "0" + monthDateYear[0];
                        else
                            month = monthDateYear[0];
                        if (monthDateYear[1].length === 1)
                            date = "0" + monthDateYear[1];
                        else date = monthDateYear[1];
                        dateString = monthDateYear[2] + "-" + month + "-" + date;

                    } else {
                        dateString = "1000-01-01";
                    }
                } else {
                    dateString = "1000-01-01";
                }

                let nid = "";
                if (this.state.inputData["smartCard" + j] !== undefined && this.state.inputData["smartCard" + j].length > 9) {
                    nid = (this.state.inputData["smartCard" + j]).trim()
                } else {
                    nid = this.state.inputData["nid" + j]
                }

                objectForJoinAccount.customerInfoList.push({
                    "cbNumber": this.trimFunctionInputData((this.state.inputData["cbNumber" + j])),
                    "accountNumber": this.trimFunctionInputData((this.state.inputData["accountNumber" + j])),
                    "nid": this.trimFunctionInputData((nid)),
                    "passport": this.trimFunctionInputData((this.state.inputData["passport" + j])),
                    "customerName": this.trimFunctionInputData((this.state.inputData["customerName" + j])),
                    "dob": dateString,
                    "gender": "",
                    "fathersName": "",
                    "mothersName": "",
                    "nationality": this.trimFunctionInputData((this.state.inputData["nationality" + j])),
                    "email": this.trimFunctionInputData((this.state.inputData["email" + j])),
                    "phone": "88" + this.trimFunctionInputData((this.state.inputData["phone" + j])),
                    "tin": this.trimFunctionInputData((this.state.inputData["tin" + j])),
                    "registrationNo": this.trimFunctionInputData((this.state.inputData["registrationNo" + j])),
                    "mobileTypeJoint": this.trimFunctionInputData((this.state.inputData["mobileTypeJoint" + j])),
                    "countryCode": this.trimFunctionInputData((this.state.inputData["countryCodeIndividual" + j])),
                    // "dSmartCard": this.trimFunctionInputData((this.state.inputData["smartCard" + j])),
                    // "dNid": this.trimFunctionInputData((this.state.inputData["nid" + j])),
                    // "dDrivingLicense": this.trimFunctionInputData((this.state.inputData["drivingLicense" + j])),
                    "constitutionType": "",
                    "sources": ["FINACLE", "ABABIL", "TRANZWARE"]
                })

            }

            postData = objectForJoinAccount;
            let url = backEndServerURL + "/jointDedup/JNT";

            axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                jointDedupData: response.data
                            });
                            let jointDedupSearchTable = [];
                            let sdnTableArray = [];
                            var notFound = 0;
                            for (let i = 0; i < this.state.handleChangeJoint; i++) {
                                let number = i + 1;
                                if (this.state.inputData["cbNumber" + i] && (response.data.highMatchCustomers[i].length === 0 && response.data.mediumMatchCustomers[i].length === 0)) {
                                    this.setState({
                                        cbNotFound: true,
                                        title: this.numberToCharacter(number) + " Applicant CB / Account Not Found",
                                        searchTableSdnData: true,
                                        loading: false,
                                    });
                                    notFound = this.state.handleChangeJoint;
                                }
                            }
                            if (notFound !== this.state.handleChangeJoint) {
                                if (response.data.highMatchCustomers !== null && Array.isArray(response.data.highMatchCustomers)) {
                                    let randomNumber = 0;
                                    response.data.highMatchCustomers.map((dedup, i) => {
                                        let customer = [];
                                        randomNumber = (randomNumber + 1);
                                        dedup.map((dedup) => {
                                            customer.push(this.createIndTableData(randomNumber, dedup.id, dedup.identification_no_mod, dedup.maincustid, dedup.sz_contitution, dedup.sz_source, dedup.sz_full_name, dedup.sz_national_id, dedup.sz_passport, dedup.sz_tin_number, dedup.cust_comu_phone_num, dedup.dt_birth, dedup.email_id, dedup.sz_registration_no, dedup.matched_by, "Higher-Match"));
                                        });

                                        if (response.data.mediumMatchCustomers !== null && Array.isArray(response.data.mediumMatchCustomers)) {
                                            let medium = response.data.mediumMatchCustomers[i];
                                            medium.map((medium) => {
                                                customer.push(this.createIndTableData(randomNumber, medium.id, medium.identification_no_mod, medium.maincustid, medium.sz_contitution, medium.sz_source, medium.sz_full_name, medium.sz_national_id, medium.sz_passport, medium.sz_tin_number, medium.cust_comu_phone_num, medium.dt_birth, medium.email_id, medium.sz_registration_no, medium.matched_by, "Medium-Match"));
                                            })
                                        }
                                        jointDedupSearchTable.push(customer);
                                    });
                                }

                            }

                            if (!this.state.cbNotFound) {
                                if (response.data.sdnResult !== null && Array.isArray(response.data.sdnResult)) {
                                    response.data.sdnResult.map((data, i) => {
                                        let sdnTableList = [];
                                        if (Array.isArray(data)) {
                                            data.map((data) => {
                                                sdnTableList.push(this.createSdnTableData(data.country, data.dob, data.matchType, data.name, data.type));
                                            });
                                            sdnTableArray.push(sdnTableList);
                                        }
                                    });
                                }
                                this.setState({
                                    jointSearchTable: jointDedupSearchTable,
                                    sdnData: sdnTableArray,
                                    searchTableData: true,
                                    searchTableSdnData: true,
                                    loading: false
                                })
                            }
                            this.props.handleDedupSearchData(this.state.jointDedupData, objectForJoinAccount.customerInfoList,this.state.inputData)
                        }
                    }
                )
                .catch((error) => {
                    this.setState({
                        jointSearchTable: [],
                        sdnData: [],
                        searchTableData: false,
                        searchTableSdnData: false,
                        loading: false,
                    })

                });
        }

    };

    cancelDedup = () => {
        for (let i = 0; i < this.state.objectForNewMandate.length; i++) {
            delete this.state.inputData[this.state.objectForNewMandate[i].varName];
        }
        this.setState({
            objectForNewMandate: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            renderDedupFrom: false,
            showNewButton: true,
            showSignatoryForm: false,
            handleChangeJoint: 0,
            jointSearchTable: [],
            sdnData: [],
            searchTableData: false,
            searchTableSdnData: false,
        });
        this.props.closeDedupComponent()
    };

    createIndTableData = (randomNumber, id, uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, phone, dob, email, registration, matchedBy, matchType) => {

        if (accountSource === "TRANZWARE") {
            return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType])
        } else {
            return ([uniqueId, customerId, cbType, accountSource, customerName, nid, passport, tin, matchedBy, matchType,])
        }

    };

    createSdnTableData = (country, dob, matchType, name, type) => {
        return ([name, dob, matchType, type, country])
    };

    searchTableData = () => {
        const {classes} = this.props;
        if (this.state.jointSearchTable.length > 0 && this.state.searchTableData) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <br/>
                        <br/>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <div>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5>Individual Result</h5>

                                        </CardHeader>
                                    </paper>
                                    {this.renderJointSearchtabledata()}
                                    <br/>
                                </div>
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }

    };

    renderJointSearchtabledata = () => {
        if (this.state.jointSearchTable.length > 0 && this.state.searchTableData) {
            return (
                this.state.jointSearchTable.map((tableData, index) => {
                    return (

                        <div>
                            <center>
                                <br/>
                                <Grid item xs={12}>
                                    <h3>{this.numberToCharacter(index + 1) + " Applicant "}</h3>

                                </Grid>
                            </center>
                            <Table
                                tableHovor="yes"
                                tableHeaderColor="primary"
                                tableHead={["Customer ID", "CB No", "CB Type", "Source", "Customer Name", "NID", "Passport", "E-Tin", "Match By", "Match-Type"]}
                                tableData={tableData}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                            />
                        </div>

                    )
                })
            )
        }

    };
    returnJointSdnResultTable = (tableData) => {
        if (tableData.length > 0) {
            return (
                <Table
                    tableHeaderColor="primary"

                    tableHead={["Name", "Date Of Birth", "Match Type", "Type", "Country"]}

                    tableData={tableData}
                    tableAllign={['left', 'left', 'left', 'left', 'left']}
                />
            )
        } else {
            return (
                <div>
                    <center>

                        <h6 style={{
                            color: '#ff1f28'
                        }}>No Match Found</h6>
                        <br/>
                    </center>

                </div>
            )
        }

    };

    renderSdnNonindividualResult = () => {
        if (this.state.sdnData.length > 0 && this.state.searchTableSdnData) {
            return (
                this.state.sdnData.map((tableData, index) => {
                    return (
                        <div>
                            <center>
                                <Grid item xs={12}>
                                    <h5>{this.numberToCharacter(index + 1) + " Applicant "}</h5>
                                </Grid>
                            </center>

                            {this.returnJointSdnResultTable(tableData)}
                        </div>
                    )
                })
            )
        }
    };

    renderSdn = () => {
        const {classes} = this.props;
        if (this.state.sdnData.length > 0 && this.state.searchTableSdnData) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <div>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5>SDN Verification</h5>
                                        </CardHeader>
                                    </paper>
                                    {this.renderSdnNonindividualResult()}
                                    <br/>
                                </div>
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }

    };

    searchTableNidVerification = () => {
        const {classes} = this.props;
        if (this.state.searchTableSdnData && (this.state.sdnData.length > 0 || this.state.jointSearchTable.length > 0)) {
            return (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <div style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }} className={classes.root}>
                                <div>
                                    <paper>
                                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                            <h5>NID/Smart Card Verification</h5>
                                        </CardHeader>
                                    </paper>
                                    <div>
                                        <center>
                                            <h4 style={{color: "white"}} >
                                                <a
                                                    style={{
                                                        verticalAlign: 'left',
                                                        position: "absolute",

                                                        marginTop: "5px",
                                                        marginBottom: "10px",
                                                    }}
                                                    target="_blank"
                                                    href="https://prportal.nidw.gov.bd/partner-portal/login">
                                                    NID/Smart Card </a> <br/><br/>
                                            </h4>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </GridItem>
                </GridContainer>
            )
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        Functions.redirectToLogin(this.state);
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                {this.renderNotification()}
                <Grid container spacing={1}>
                    <ThemeProvider theme={theme}>
                        {this.renderDedupForm()}
                        {this.renderDedupButton()}
                    </ThemeProvider>
                </Grid>
                {this.searchTableData()}
                {this.renderSdn()}
                {this.searchTableNidVerification()}
            </>
        );

    }

}

export default withStyles(styles)(CommonDedup);
