import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../../Common/Functions';
import Notification from "../../../NotificationMessage/Notification";
import CommonJsonFormComponent from "../../../JsonForm/CommonJsonFormComponent";
import {ThemeProvider} from "@material-ui/styles/index";
import Grid from "@material-ui/core/Grid/index";
import theme from "../../../JsonForm/CustomeTheme";
import Table from "../../../Table/Table";
import DialogContent from "@material-ui/core/DialogContent/index";
import loader from "../../../../Static/loader.gif";
import {Dialog} from "@material-ui/core/index";
import Label from "./Label";
import CardBody from "../../../Card/CardBody";
import Card from "../../../Card/Card";
import FileTypeComponent from "../../../JsonForm/FileTypeComponent";
import PreviewButton from "../PreviewButton";
import GridList from "@material-ui/core/GridList";
import PreviewMappingImage from "../casa/PreviewMappingImage";
import MyValidation from "../../../JsonForm/MyValidation";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CBNotFound from "../../CASA/CBNotFound";
import ConfirmationModal from "../../FundTransfer/ConfirmationModal";
import CheckList from "./CheckList";
import {
    existingFDRForm,
    fdrNewForm,
    newFdrOpeningFormMakerOne,
    newFdrOpeningFormMakerTwo
} from "./JsonForFdrMaintenance"
import AccountStatement from "../../fdr/AccountStatement";
import FunctionForGetAcDetails from "../../CommonComponent/FunctionForGetAcDetails";

let makerRemarks = [
    {
        "varName": "makerRemarks",
        "type": "textArea",
        "label": "Maker Remarks",
        "required": true,
        "grid": 12
    }];

var fileUpload = {
    "varName": "scanningFile",
    "type": "file",
    "accept": ".pdf",
    "label": "Upload File",
    "grid": 12
};
const numberOfNominee = [
    {title: '1'},
    {title: '2'},
    {title: '3'},
    {title: '4'},
    {title: '5'},
    {title: '6'},
    {title: '7'},
    {title: '8'},
    {title: '9'},
    {title: '10'},
    {title: '11'},
    {title: '12'},
    {title: '13'},
    {title: '14'},
    {title: '15'},
    {title: '16'},
    {title: '17'},
    {title: '18'},
    {title: '19'},
    {title: '20'}
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    gridList: {
        width: 500,
        height: 450,
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },

};

class FdrMakerApproval extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            getData: false,
            varValue: {},
            showValue: false,
            title: "",
            notificationMessage: "",
            alert: false,
            inputData: {},
            selectedDate: {},
            getDeferalList: [],
            loading: true,
            objectForNominee: [],
            existingObjectForNominee: [],
            existingNominee: false,
            fileUploadData: {},
            getRemarks: [],
            err: false,
            errorArray: {},
            errorMessages: {},
            nomineeNumber: 0,
            getNominee: false,
            type: "successs",
            cbNotFound: false,
            exObjectForNominee: [],
            agentBankingOutletList: [],
            getNewNominee: 0,
            getExNominee: false,
            city: [],
            state: [],
            ccy: [],
            stateCode: [],
            country: [],
            currency: [],
            depositCodeList: [],
            guardianCode: [],
            relationCode: [],
            fdrSchemeCode: [],
            objectForInitialNewNominee: [],
            confirmationAlert: false,
            submitFlag: "",
            confirmation: false,
            disabled: false,
            occupationCode: [],
            subSectorCode: [],
            sectorCode: [],
            checkList: false,
            accountStatement: false,
            phoneNumCountryCodeList: []
        };
    }

    createTableData = (id, type, dueDate, appliedBy, applicationDate, status) => {

        return ([
            type, dueDate, appliedBy, applicationDate, status
        ])

    };

    createRemarksTable = (remarks, name, a, b) => {
        return (
            [remarks, name, a, b]
        )
    };

    renderRemarksData = () => {


        if (this.state.getRemarks.length > 0) {

            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{"border-style": "groove", "border-width": "1px"}}>
                        <b>Remarks Details:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Remarks", "Raised By", "Date", "Role"]}
                            tableData={this.state.getRemarks}
                            tableAllign={['left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>

                </div>

            )
        }
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === "null" || clone[prop] === "undefined")
                delete clone[prop];
        return clone;
    };

    getBankName = () => {
        let url = backEndServerURL + "/finMasterData/custom/otherBanks";
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let bankName = [];
                    for (let bank of response.data) {
                        bankName.push(bank.value)
                    }
                    for (let field of this.props.commonJsonForm) {
                        if (field.varName === "beneficiaryBankName") {
                            field.enum = bankName
                        }
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false
                })
            })
    };

    componentDidMount() {

        this.dynamicApiCall("city", "city");

        this.dynamicApiCall("relationCode", "relationCode");

        this.dynamicApiCall("country", "country");

        this.dynamicApiCall("state", "state");

        this.dynamicApiCall("guardianCode", "guardianCode");

        this.dynamicApiCall("phoneCountryCode", "phoneCountryCode");

        this.dynamicApiCall("currency", "currency");

        this.dynamicApiCall("agentBankingOutlet", "agentBankingOutlet")

        this.dynamicApiCall("nationality", "country");

        this.dynamicApiCall("depositCode", "depositCode");

        this.dynamicApiCall("sectorCode", "sectorCode");

        this.dynamicApiCall("subSectorCode", "subSectorCode");

        this.dynamicApiCall("fdrSchemeCodes", "fdrSchemeCode");

        this.dynamicApiCall("currency", "currency");

        let remarksArray = [];
        let objectForNominee = [];
        if (this.props.appId !== undefined) {
            let url = backEndServerURL + '/variables/' + this.props.appId;
            axios.get(url,
                {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    let inputData = response.data;

                    if (inputData.subCategory === "FD Nominee Change" || inputData.subCategory === "Link ACC Change" || (inputData.subCategory === "Auxiliary Task" && (inputData.selectRequestType === "SBS Code Update/Correction" || inputData.selectRequestType === "RM Code Update"))) {
                        inputData.changingRenewalMaturityInstructionOnly = true;
                        this.renderTenorRelatedApi(response.data.exdepositTenure, undefined, undefined, undefined, "tenorType", false, "exdepositPeriodMonthsdays", "exshowTenorPeriod");
                    }

                    if (response.data.freeFlag2 === "FDR Encashment" && response.data.subCategory !== "FDR Encashment Certificate" && (response.data.transferType === "RTGS" || response.data.transferType === "BEFTN")) {
                        inputData.showBeneficiaryDetails = true;
                        this.functionForGetBankName(response.data.beneficiaryBankName);
                        this.getBankName()
                    }
                    if (response.data.subCategory === "Tenor or Scheme Change") {
                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "tenorType", false, "newTenor", "showTenorPeriod",);

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "tenorPeriod", false, "renewal", "showRenewal");

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "renewal", false, "maturity", "showMaturity");

                        this.renderTenorRelatedApi(response.data.tenorType, response.data.newTenor, response.data.renewal, response.data.csmaturity, "maturity", false, "csfdrSchemeCode", "csshowSchemeCode");

                        this.renderTenorRelatedApi(response.data.exdepositTenure, undefined, undefined, undefined, "tenorType", false, "exdepositPeriodMonthsdays", "exshowTenorPeriod");

                        this.renderTenorRelatedApi(response.data.depositTenure, response.data.depositPeriodMonthsdays, response.data.autoRenewal, response.data.maturity, "tenorPeriod", false, "depositeType", "depositeTypeApicall")
                    }

                    if (parseInt(response.data.numberOfNominee) > 0) {
                        for (let i = 1; i <= parseInt(inputData.numberOfNominee); i++) {
                            let sl = i;
                            objectForNominee.push(
                                {
                                    "varName": "Nominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "search" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "required": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,


                                },
                                {
                                    "varName": "nomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "CIF ID",

                                },

                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "nomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "relationCode",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "percentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "photoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "search" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },


                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,


                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "nomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "guardianCode",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "guardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "nomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "nomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "nomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "city",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "state",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "nomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enumType": "country",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )

                        }
                        this.setState({
                            getNominee: true,
                        })
                    }

                    if (parseInt(response.data.numberOfNewNominee) > 0) {
                        let sl = 0;
                        let existingNominee = [];
                        for (let i = 0; i < parseInt(response.data.numberOfNewNominee); i++) {
                            sl = parseInt(response.data.numberOfOldNominee) + i + 1;
                            existingNominee.push(
                                {
                                    "varName": "exNominee " + i,
                                    "type": "title",
                                    "label": "Nominee  " + sl,
                                    "grid": 12,
                                },
                                {
                                    "varName": "exsearch" + sl,
                                    "type": "select",
                                    "enum": [
                                        "CIF ID",
                                        "INFORMATION",
                                    ],
                                    "required": true,
                                    "onKeyDown": true,
                                    "label": "Input CIF ID/INFORMATION ?",
                                    "grid": 6,
                                },
                                {
                                    "varName": "exnomineecifId" + sl,
                                    "type": "textApiCall",
                                    "label": "CIF ID",
                                    "grid": 6,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "CIF ID",
                                },

                                {
                                    "varName": "exnomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeName" + sl,
                                    "type": "text",
                                    "label": "Nominee Name",
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",

                                },
                                {
                                    "varName": "exnomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "required": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],


                                },
                                {
                                    "varName": "exnomineeRelationship" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.relationCode,
                                    "label": "Relationship",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },
                                {
                                    "varName": "expercentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    required: true,

                                },
                                {
                                    "varName": "expercentageNominee" + sl,
                                    "type": "text",
                                    "label": "Percentage %",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                    required: true,

                                },

                                {
                                    "varName": "exnomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeAddress1" + sl,
                                    "type": "text",
                                    "label": "Address 1 ",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    required: true,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "multiline": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exnomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineePostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "exnomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeCountry" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    required: true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },

                                {
                                    "varName": "exphotoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exphotoIdNumberRegNo" + sl,
                                    "type": "text",
                                    "label": "Photo Id Number/Reg No",
                                    "grid": 6,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineeDob" + sl,
                                    "type": "text",
                                    "label": "D.O.B(YYYY-MM-DD)",
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeDob" + sl,
                                    "type": "date",
                                    "label": "D.O.B",
                                    "grid": 6,
                                    "onKeyDown": true,
                                    "required": true,
                                    "conditional": true,
                                    "conditionalVarName": "exsearch" + sl,
                                    "conditionalVarValue": "INFORMATION",
                                },
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },
                                {
                                    "varName": "exnomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "exguardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                },

                                {
                                    "varName": "exnomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["INFORMATION", true],
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeMinor" + sl,
                                    "type": "select",
                                    "label": "Nominee Minor",
                                    "enum": ["YES", "NO"],
                                    "grid": 6,
                                    "readOnly": true,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "type": "title",
                                    "label": "Guardian Details  " + sl,
                                    "grid": 12,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },
                                {
                                    "varName": "exnomineeGuardiansName" + sl,
                                    "type": "text",
                                    "label": "Guardians Name",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.guardianCode,
                                    "label": "Guardian Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,

                                },
                                {
                                    "varName": "exguardianPhotoId" + sl,
                                    "type": "text",
                                    "label": "Guardian Photo Id",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                },

                                {
                                    "varName": "exnomineeGuardianAddress" + sl,
                                    "type": "text",
                                    "label": "Address 1",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                    "required": true,
                                },
                                {
                                    "varName": "exnomineeGuardianAddress2" + sl,
                                    "type": "text",
                                    "label": "Address 2",
                                    "validationB": "length",
                                    "maxLength": 45,
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "multiline": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCityCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.city,
                                    "label": "City Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianStateCode" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.stateCode,
                                    "label": "State Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianPostalCode" + sl,
                                    "type": "text",
                                    "label": "Postal Code",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },

                                {
                                    "varName": "exnomineeGuardianCountry2" + sl,
                                    "type": "autoCompleteValueReturn",
                                    "enum": this.state.country,
                                    "label": "Country",
                                    "grid": 6,
                                    "conditionalArray": true,
                                    "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                    "conditionalArrayValue": ["CIF ID", true],
                                    "required": true,
                                },
                            )
                        }
                        this.setState({
                            exObjectForNominee: existingNominee,
                            getExNominee: true
                        })
                    }

                    let deferalListUrl = backEndServerURL + "/case/deferral/" + this.props.appId;
                    axios.get(deferalListUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((response) => {
                            var status = "";
                            var tableArray = [];
                            response.data.map((deferal) => {
                                if (deferal.status === "ACTIVE") {
                                    status = "Approved By BM"
                                } else {
                                    status = deferal.status
                                }
                                tableArray.push(this.createTableData(deferal.id, deferal.type, deferal.dueDate, deferal.appliedBy, deferal.applicationDate, status));
                            });
                            this.setState({
                                getDeferalList: tableArray
                            });
                            let getCommentsUrl = backEndServerURL + "/appRemarkGet/" + this.props.appId;
                            axios.get(getCommentsUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                .then((response) => {
                                    response.data.map((data) => {
                                        remarksArray.push(this.createRemarksTable(data.remarks, data.createByUserName, data.applicationRemarksDate, data.createByUserRole))
                                    });
                                    this.setState({
                                        getRemarks: remarksArray
                                    })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        });

                    let getAccountUrl = backEndServerURL + "/getTermDepositAccountFDR/" + response.data.accountNumber;
                    axios.get(getAccountUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                        .then((acResponse) => {
                            if (acResponse.data.nomineeInfoList !== undefined && acResponse.data.nomineeInfoList.length > 0) {
                                inputData.numberOfOldNominee = acResponse.data.nomineeInfoList.length;
                                let sl = 0;
                                let existingNominee = [];
                                for (let i = 0; i < acResponse.data.nomineeInfoList.length; i++) {
                                    sl = i + 1;
                                    existingNominee.push(
                                        {
                                            "varName": "exNominee " + i,
                                            "type": "title",
                                            "label": "Nominee  " + sl,
                                            "grid": 12,
                                        },
                                        {
                                            "varName": "exsearch" + sl,
                                            "type": "select",
                                            "enum": [
                                                "CIF ID",
                                                "INFORMATION",
                                            ],
                                            "required": true,
                                            "onKeyDown": true,
                                            "label": "Input CIF ID/INFORMATION ?",
                                            "grid": 6,


                                        },
                                        {
                                            "varName": "exnomineecifId" + sl,
                                            "type": "textApiCall",
                                            "label": "CIF ID",
                                            "grid": 6,
                                            "required": true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "CIF ID",
                                        },

                                        {
                                            "varName": "exnomineeName" + sl,
                                            "type": "text",
                                            "label": "Nominee Name",
                                            "grid": 6,
                                            "multiline": true,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeName" + sl,
                                            "type": "text",
                                            "label": "Nominee Name",
                                            "grid": 6,
                                            required: true,
                                            "multiline": true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",

                                        },
                                        {
                                            "varName": "exnomineeRelationship" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.relationCode,
                                            "label": "Relationship",
                                            "grid": 6,
                                            "required": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],


                                        },
                                        {
                                            "varName": "exnomineeRelationship" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.relationCode,
                                            "label": "Relationship",
                                            "grid": 6,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                            required: true,

                                        },
                                        {
                                            "varName": "expercentageNominee" + sl,
                                            "type": "text",
                                            "label": "Percentage %",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            required: true,

                                        },
                                        {
                                            "varName": "expercentageNominee" + sl,
                                            "type": "text",
                                            "label": "Percentage %",
                                            "grid": 6,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                            required: true,

                                        },

                                        {
                                            "varName": "exnomineeAddress1" + sl,
                                            "type": "text",
                                            "label": "Address 1 ",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "multiline": true,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeAddress1" + sl,
                                            "type": "text",
                                            "label": "Address 1 ",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            required: true,
                                            "multiline": true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },

                                        {
                                            "varName": "exnomineeAddress2" + sl,
                                            "type": "text",
                                            "label": "Address 2",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "multiline": true,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeAddress2" + sl,
                                            "type": "text",
                                            "label": "Address 2",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "multiline": true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },
                                        {
                                            "varName": "exnomineeCityCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.city,
                                            "label": "City Code",
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeCityCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.city,
                                            "label": "City Code",
                                            "grid": 6,
                                            required: true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },

                                        {
                                            "varName": "exnomineeStateCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.stateCode,
                                            "label": "State Code",
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeStateCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.stateCode,
                                            "label": "State Code",
                                            "grid": 6,
                                            required: true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },

                                        {
                                            "varName": "exnomineePostalCode" + sl,
                                            "type": "text",
                                            "label": "Postal Code",
                                            "grid": 6,
                                            required: true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },
                                        {
                                            "varName": "exnomineePostalCode" + sl,
                                            "type": "text",
                                            "label": "Postal Code",
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },

                                        {
                                            "varName": "exnomineeCountry" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.country,
                                            "label": "Country",
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeCountry" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.country,
                                            "label": "Country",
                                            "grid": 6,
                                            required: true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },

                                        {
                                            "varName": "exphotoIdNumberRegNo" + sl,
                                            "type": "text",
                                            "label": "Photo Id Number/Reg No",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exphotoIdNumberRegNo" + sl,
                                            "type": "text",
                                            "label": "Photo Id Number/Reg No",
                                            "grid": 6,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },
                                        {
                                            "varName": "exnomineeDob" + sl,
                                            "type": "text",
                                            "label": "D.O.B(YYYY-MM-DD)",
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeDob" + sl,
                                            "type": "date",
                                            "label": "D.O.B",
                                            "grid": 6,
                                            "onKeyDown": true,
                                            "required": true,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },
                                        {
                                            "varName": "exdeleteOldNominee" + sl,
                                            "type": "select",
                                            "enum": ["Y", "N"],
                                            "label": "Delete This Nominee",
                                            "required": true,
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exdeleteOldNominee" + sl,
                                            "type": "select",
                                            "enum": ["Y", "N"],
                                            "label": "Delete This Nominee",
                                            "required": true,
                                            "grid": 6,
                                            "conditional": true,
                                            "conditionalVarName": "exsearch" + sl,
                                            "conditionalVarValue": "INFORMATION",
                                        },
                                        {
                                            "varName": "exnomineeMinor" + sl,
                                            "type": "select",
                                            "label": "Nominee Minor",
                                            "enum": ["YES", "NO"],
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeMinor" + sl,
                                            "type": "select",
                                            "label": "Nominee Minor",
                                            "enum": ["YES", "NO"],
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                        },
                                        {
                                            "type": "title",
                                            "label": "Guardian Details  " + sl,
                                            "grid": 12,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                        },
                                        {
                                            "varName": "exnomineeGuardiansName" + sl,
                                            "type": "text",
                                            "label": "Guardians Name",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "multiline": true,
                                            "required": true,
                                        },
                                        {
                                            "varName": "exnomineeGuardianCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.guardianCode,
                                            "label": "Guardian Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "required": true,
                                        },
                                        {
                                            "varName": "exguardianPhotoId" + sl,
                                            "type": "text",
                                            "label": "Guardian Photo Id",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                        },

                                        {
                                            "varName": "exnomineeGuardianAddress" + sl,
                                            "type": "text",
                                            "label": "Address 1",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "multiline": true,
                                            "required": true,
                                        },
                                        {
                                            "varName": "exnomineeGuardianAddress2" + sl,
                                            "type": "text",
                                            "label": "Address 2",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "multiline": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianCityCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.city,
                                            "label": "City Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianStateCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.stateCode,
                                            "label": "State Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianPostalCode" + sl,
                                            "type": "text",
                                            "label": "Postal Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianCountry2" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.country,
                                            "label": "Country",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["INFORMATION", true],
                                            "required": true,
                                        },
                                        {
                                            "varName": "exnomineeMinor" + sl,
                                            "type": "select",
                                            "label": "Nominee Minor",
                                            "enum": ["YES", "NO"],
                                            "grid": 6,
                                            "readOnly": true,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "type": "title",
                                            "label": "Guardian Details  " + sl,
                                            "grid": 12,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },
                                        {
                                            "varName": "exnomineeGuardiansName" + sl,
                                            "type": "text",
                                            "label": "Guardians Name",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "multiline": true,
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.guardianCode,
                                            "label": "Guardian Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "required": true,

                                        },
                                        {
                                            "varName": "exguardianPhotoId" + sl,
                                            "type": "text",
                                            "label": "Guardian Photo Id",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                        },

                                        {
                                            "varName": "exnomineeGuardianAddress" + sl,
                                            "type": "text",
                                            "label": "Address 1",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "multiline": true,
                                            "required": true,
                                        },
                                        {
                                            "varName": "exnomineeGuardianAddress2" + sl,
                                            "type": "text",
                                            "label": "Address 2",
                                            "validationB": "length",
                                            "maxLength": 45,
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "multiline": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianCityCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.city,
                                            "label": "City Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianStateCode" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.stateCode,
                                            "label": "State Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianPostalCode" + sl,
                                            "type": "text",
                                            "label": "Postal Code",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "required": true,
                                        },

                                        {
                                            "varName": "exnomineeGuardianCountry2" + sl,
                                            "type": "autoCompleteValueReturn",
                                            "enum": this.state.country,
                                            "label": "Country",
                                            "grid": 6,
                                            "conditionalArray": true,
                                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                                            "conditionalArrayValue": ["CIF ID", true],
                                            "required": true,
                                        },
                                    )
                                }
                                this.setState({
                                    existingObjectForNominee: existingNominee,
                                    getExistingNominee: true,
                                })
                            } else {
                                inputData.numberOfOldNominee = 0;
                            }
                            let inputs;
                            if (inputData.checker_approval === "RETURN") {
                                inputs = inputData;
                                this.setState({
                                    objectForNominee: objectForNominee,
                                    inputData: inputs,
                                    varValue: this.copyJson(inputs),
                                    showValue: true,
                                    getData: true,
                                });
                            } else {
                                let cbUrl = backEndServerURL + "/cbsCustomerGet/" + inputData.cbNumber;
                                axios.get(cbUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                                    .then((cbResponse) => {
                                        inputs = this.functionForDataMap(JSON.parse(JSON.stringify(acResponse.data)), inputData);
                                        inputs.existingPhoneNumber = cbResponse.data.COMMPH1BLOCK !== undefined ? cbResponse.data.COMMPH1BLOCK.phoneNumLocalCode : "";
                                        console.log(inputs.existingPhoneNumber)
                                        setTimeout(() => {
                                            this.setState({
                                                objectForNominee: objectForNominee,
                                                inputData: inputs,
                                                varValue: this.copyJson(inputs),
                                                showValue: true,
                                                getData: true,
                                            });
                                        }, 1000)
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        this.setState({
                                            loading: false,
                                            cbNotFound: true,
                                            title: error.response.data.message
                                        });
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({
                                loading: false,
                                cbNotFound: true,
                                title: error.response.data.message
                            });
                        })

                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })

                });
        }
    }


    functionForExciseDuty = (amount, valueDate, maturityDate) => {
        return new Promise((resolve, reject) => {
            if (amount !== undefined && valueDate !== undefined && maturityDate !== undefined) {
                let url = backEndServerURL + "/exciseDuty";
                let object = {};
                object.amount = amount;
                object.valueDate = valueDate;
                object.maturityDate = maturityDate;
                axios.post(url, object, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((exciseDutyData) => {
                        resolve(exciseDutyData)
                    }).catch((error) => {
                    reject(error)
                })
            } else {
                resolve({})
            }
        })
    };

    functionForDataMap = (data, inputData) => {

        if (data.nomineeInfoList !== undefined && data.nomineeInfoList.length > 0) {
            inputData.nomineeNumberOld = data.nomineeInfoList.length;
            inputData.registrationNumber = data.nomineeInfoList[0].regNum;
            let sl = 0;
            let nomineeInfo = data.nomineeInfoList;
            // exnomineeDobApiCall
            for (let i = 0; i < nomineeInfo.length; i++) {
                sl = i + 1;
                if (data.nominationList !== undefined && data.nominationList.length > 0) {
                    inputData["exnomineeidnum" + sl] = data.nominationList[i].nomineeidnum;
                    inputData["exnomineeslno" + sl] = data.nominationList[i].nomineeslno;
                }
                if (data.nominationList !== undefined && data.nominationList.length > 0 && data.nominationList[i].nomineecifid !== "") {
                    inputData["exsearch" + sl] = "CIF ID";
                    inputData["exnomineecifIdApiCall" + sl] = true;
                    inputData["exnomineecifId" + sl] = data.nominationList[i].nomineecifid;
                } else {
                    inputData["exsearch" + sl] = "INFORMATION";
                }
                inputData["exdeleteOldNominee" + sl] = "N";
                inputData["exnomineeDob" + sl] = nomineeInfo[i].nomineeBirthDt;
                inputData["exnomineeMinor" + sl] = nomineeInfo[i].nomineeMinorFlg === "Y" ? "YES" : "NO";
                let x = 18;
                let someDate = new Date();
                someDate.setFullYear(someDate.getFullYear() - x);
                let date = someDate.getDate();
                let month = someDate.getMonth() + 1;
                let year = someDate.getFullYear();
                let convertDate = year + "-" + month + "-" + date;
                let newDate = new Date(convertDate);
                let stringToDate = new Date(newDate.toDateString());
                let inputDate = new Date(inputData["exnomineeDob" + sl]);
                if ((stringToDate >= inputDate) || inputData["exnomineeDob" + sl] === "null") {
                    inputData["exnomineeDobApiCall" + sl] = false;
                } else {
                    inputData["exnomineeDobApiCall" + sl] = true;
                }

                inputData["exnomineeName" + sl] = nomineeInfo[i].nomineeName;
                inputData["exexnomineeName" + sl] = nomineeInfo[i].nomineeName;
                inputData["expercentageNominee" + sl] = nomineeInfo[i].nomineePercent;
                inputData["exnomineeGuardiansName" + sl] = nomineeInfo[i].nomineeGuardianName;
                inputData["exnomineeRegNo"] = nomineeInfo[i].regNum;
                inputData["exnomineeRelationship" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].relType, this.state.relationCode);
                inputData["exnomineeAddress1" + sl] = nomineeInfo[i].address1;
                inputData["exnomineeAddress2" + sl] = nomineeInfo[i].address2;
                inputData["exnomineeCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].city, this.state.city);
                inputData["exnomineeStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].stateProv, this.state.state);
                inputData["exnomineePostalCode" + sl] = nomineeInfo[i].postalCode;
                inputData["exnomineeCountry" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].country, this.state.country);
                inputData["exphotoIdNumberRegNo" + sl] = nomineeInfo[i].address3;
                inputData["exnomineeGuardianCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCode, this.state.guardianCode);
                inputData["exguardianPhotoId" + sl] = nomineeInfo[i].nomineeGuardianAddr3;
                inputData["exnomineeGuardianAddress" + sl] = nomineeInfo[i].nomineeGuardianAddr1;
                inputData["exnomineeGuardianAddress2" + sl] = nomineeInfo[i].nomineeGuardianAddr2;
                inputData["exnomineeGuardianCityCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCity, this.state.city);
                inputData["exnomineeGuardianStateCode" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianStateProv, this.state.state);
                inputData["exnomineeGuardianPostalCode" + sl] = nomineeInfo[i].nomineeGuardianPostalCode;
                inputData["exnomineeGuardianCountry2" + sl] = this.findByVarNameGetKeyValue(nomineeInfo[i].nomineeGuardianCountry, this.state.country);
            }
        }
        if (inputData.subCategory === "Tenor or Scheme Change" || inputData.changingRenewalMaturityInstructionOnly === true) {
            inputData.exsolId = inputData.solId;
            inputData.excifId = data.cbNumber;
            inputData.exccy = this.findByVarNameGetKeyValue(data.depositAccount.acctCurrency, this.state.ccy);
            inputData.exschemeCode = this.findByVarNameGetKeyValue(data.depositAccount.schemeCode, this.state.fdrSchemeCode);
            inputData.exacName = data.depositAccount.accountShortName;
            inputData.exshortName = data.depositAccount.accountShortName;
            inputData.exacOpeningDate = data.depositAccount.accountOpenDate;
            inputData.exetin = data.eTinNumber;
            if (data.eTinNumber !== undefined) {
                inputData.exwithholdingTaxPcnt = 10
            } else {
                inputData.exwithholdingTaxPcnt = 15
            }
            inputData.exdepositTenure = parseInt(data.depositAccount.depositTerm_days) > 0 ? "Day" : "Month";
            inputData.exdepositPeriodMonthsdays = parseInt(data.depositAccount.depositTerm_days) > 0 ? data.depositAccount.depositTerm_days : data.depositAccount.depositTerm_months;
            inputData.exshowTenorPeriod = true;
            this.renderTenorRelatedApi(inputData.exdepositTenure, undefined, undefined, undefined, "tenorType", false, "exdepositPeriodMonthsdays", "exshowTenorPeriod");

            this.renderTenorRelatedApi(inputData.exdepositTenure, inputData.exdepositPeriodMonthsdays, undefined, undefined, "tenorPeriod", false, "exdepositeType", "exdepositeTypeApicall");


            inputData.exmaturityDate = data.depositAccount.maturityDt;
            inputData.exmaturityValue = data.depositAccount.maturityAmt_amountValue;
            if (data.depositAccount.repayacctid) {
                inputData.exrepaymentAcId = data.depositAccount.repayacctid;
                inputData.exrepaymentName = this.functionForDebitAccountName(data.depositAccount.repayacctid, "exrepaymentName", "exrepaymentAccountApiCall");
            }
            inputData.exautoClosure = data.depositAccount.autoCloseOnMaturityFlg === "N" ? "No" : "Yes";
            if (inputData.exautoClosure === "No") {
                inputData.exautoRenewal = "Yes";
                inputData.exshowautoRenewal = "YES";
            } else if (inputData.exautoClosure === "Yes") {
                inputData.exautoRenewal = "No"
                inputData.exshowautoRenewal = "YES"
            }
            inputData.exsectorCode = this.findByVarNameGetKeyValue(data.customDataList[0].sectorcode, this.state.sectorCode);
            inputData.exsubSectorCode = this.findByVarNameGetKeyValue(data.customDataList[0].subsectorcode, this.state.subSectorCode);
            inputData.exsectorCodeApiCall = true;
            inputData.exoccupationCode = data.customDataList[0].occupationcode;
            if (data.customDataList[0].rmcode) {
                inputData.exrmCode = data.customDataList[0].rmcode;
                inputData.exrmCodeName = this.functionForGetRmName(data.customDataList[0].rmcode, "exrmCodeName", "exrmCodeApiCall",false);
            }
            inputData.exfreeCode6 = data.customDataList[0].freecode6;
            inputData.exfreeText3 = data.customDataList[0].freetext3;
            inputData.exaccountId = "";
            inputData.extranCreationDuring = "V";
            inputData.extransactionType = "TRANSFER";
            inputData.exflowCode = "TO";
            inputData.examtType = "F";


            // inputData.examt = this.functionForExciseDuty(inputData.exmaturityValue);

            // inputData.exaccountPrefInt = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exinterestCreditName = data.depositAccount.totalIntAmt_amountValue;
            // inputData.extaxCategory = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exwithholdingTaxPcnt = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exvalueDate = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exparticulars = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exdebitAccountNumber = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exdebitAccountName = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exdepositeType = data.depositAccount.totalIntAmt_amountValue; //freeCode7
            // inputData.exfreeCode1SpecialTaxagentOutlet = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exautoRenewal = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exmaturity = data.depositAccount.totalIntAmt_amountValue;
            // inputData.exdepositinstallAmount = data.depositAccount.totalIntAmt_amountValue;


            switch (data.customDataList[0].whtaxlvl) {
                case "A":
                    inputData.exwithHoldingTaxLevel = {
                        "masterDataName": "withHoldingTaxLevel",
                        "key": "A",
                        "value": "A-A/c.Level"
                    };
                    break;
                case "C":
                    inputData.exwithHoldingTaxLevel = {
                        "masterDataName": "withHoldingTaxLevel",
                        "key": "C",
                        "value": "C-Customer Level"
                    };
                    break;
                case "S":
                    inputData.exwithHoldingTaxLevel = {
                        "masterDataName": "withHoldingTaxLevel",
                        "key": "S",
                        "value": "S-Scheme Level"
                    };
                    break;
                default:
                    inputData.exwithHoldingTaxLevel = null;
                    break
            }

            if (data.customDataList[0].wtaxamountscopeflg === "P") {
                inputData.exwithholdingTaxBorneBy = {
                    "masterDataName": "withholdingTaxBorneBy",
                    "key": "P",
                    "value": "Principal A/c.Holder Only"
                };
            } else {
                inputData.exwithholdingTaxBorneBy = null;
            }


            inputData.solIdOne = inputData.requestedSolId;
            inputData.acIdOne = inputData.accountNumber;
            inputData.encashmentAcId = inputData.accountNumber;
            inputData.encashmentValueDate = new Date();
            inputData.encashmentWithDrawalAmt = data.depositAccount.acctBalAmt;
        }
        if (inputData.freeFlag2 === "FDR Encashment") {
            inputData.encashmentValueDate = new Date();
            inputData.encashmentWithDrawalAmt = data.depositAccount.currentDeposit_amountValue;
            inputData.currencyCode = data.depositAccount.acctCurrency;
            inputData.fdrAccountNumber = data.accountNumber;
            inputData.fdrAccountName = data.customerName;
            inputData.fdrOpenDate = data.depositAccount.accountOpenDate;
            inputData.principalAmount = data.depositAccount.currentDeposit_amountValue;
            inputData.encashmentCustomerName = data.customerName;
            inputData.encashmentAccountNumber = data.accountNumber;
            inputData.encashmentPrincipleAmount = data.depositAccount.currentDeposit_amountValue;
            inputData.encashmentOpenDate = data.depositAccount.accountOpenDate;
            inputData.encashmentMaturityDate = data.depositAccount.maturityDt;
            inputData.encashmentTotalInterest = data.depositAccount.totalIntAmt_amountValue;
        }
        return inputData
    };

    dynamicApiCall = (fieldName, apiType) => {
        let codeUrl = backEndServerURL + "/finMasterData/" + apiType;
        axios.get(codeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (apiType === "city") {
                    this.setState({
                        city: response.data
                    })
                }
                if (apiType === "country") {
                    this.setState({
                        country: response.data
                    })
                }
                if (apiType === "state") {
                    this.setState({
                        stateCode: response.data
                    })
                }
                if (apiType === "relationCode") {
                    this.setState({
                        relationCode: response.data
                    })
                }
                if (apiType === "guardianCode") {
                    this.setState({
                        guardianCode: response.data
                    })
                }
                if (apiType === "depositCode") {
                    this.setState({
                        depositCodeList: response.data
                    })
                }
                if (apiType === "phoneCountryCode") {
                    this.findByVarNameApiData("commPhoneLocalCode", response.data);
                    this.setState({
                        phoneNumCountryCodeList: response.data
                    })
                } else if (apiType === "agentBankingOutlet") {
                    this.findByVarNameApiData("freeCode1SpecialTaxagentOutlet", response.data);
                    this.findByVarNameApiData("exfreeCode1SpecialTaxagentOutlet", response.data);
                    this.setState({
                        agentBankingOutletList: response.data
                    })
                }
                if (apiType === "sectorCode") {
                    this.findByVarNameApiData("sectorCode", response.data);
                    this.findByVarNameApiData("exsectorCode", response.data);
                    this.setState({
                        sectorCode: response.data
                    })
                }
                if (apiType === "subSectorCode") {
                    this.findByVarNameApiData("subSectorCode", response.data);
                    this.findByVarNameApiData("exsubSectorCode", response.data);
                    this.setState({
                        subSectorCode: response.data
                    })
                }
                if (apiType === "fdrSchemeCode") {
                    this.findByVarNameApiData("schemeCode", response.data);
                    this.findByVarNameApiData("exschemeCode", response.data);
                    let jsonArrayServiceList = this.props.commonJsonForm;
                    for (let i = 0; i < jsonArrayServiceList.length; i++) {
                        let jsonObjects = jsonArrayServiceList[i];
                        if (jsonObjects.varName === "fdrSchemeCode" || jsonObjects.varName === "csfdrSchemeCode") {
                            this.props.commonJsonForm[i].enum = response.data;
                        }
                    }
                    this.setState({
                        fdrSchemeCode: response.data
                    })
                } else {
                    this.findByVarNameApiData(fieldName, response.data);
                }

                if (apiType === "currency") {
                    this.findByVarNameApiData("ccy", response.data);
                    this.findByVarNameApiData("exccy", response.data)
                    this.setState({
                        ccy: response.data
                    })
                }

            })
            .catch((error) => {
                console.log(error);
            });

        let occupationCodeUrl = backEndServerURL + "/occupationCode/getAll";
        axios.get(occupationCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.findByVarNameApiData("occupationCode", response.data);
                this.findByVarNameApiData("exoccupationCode", response.data);
                this.setState({
                    occupationCode: response.data
                })
            })
            .catch((error) => {
                console.log(error);
            });
    };

    findByVarNameApiData = (searchVarname, getValue) => {
        let jsonArrayTwo = newFdrOpeningFormMakerOne.concat(newFdrOpeningFormMakerTwo, existingFDRForm, this.props.commonJsonForm);
        for (let i = 0; i < jsonArrayTwo.length; i++) {
            let jsonObject = jsonArrayTwo[i];
            if (jsonObject.varName === searchVarname) {
                jsonArrayTwo[i].enum = getValue;
            }
        }
    };

    updateComponent = () => {
        this.forceUpdate();
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type={this.state.type} stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }


    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    close = () => {
        this.props.closeModal();
    };

    closeConfirmation = (value) => {
        if (value === "ok") {
            this.setState({
                confirmationAlert: false,
                disabled: true,
                loading: true,
            });
            if ((this.state.inputData.subCategory === "Regulatory Account Inquiry" || this.state.inputData.subCategory === "Dupl. Advice (Special)") && this.state.fileUploadData.scanningFile !== undefined) {
                let fileUploadPath = backEndServerURL + "/case/upload";
                let types = 'Attachments';
                let files = this.state.fileUploadData.scanningFile;
                let formData = new FormData();
                formData.append("appId", this.props.appId);
                formData.append("file", files);
                formData.append("type", types);
                axios({
                    method: 'post',
                    url: fileUploadPath,
                    data: formData,
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        'content-type': 'multipart/form-data'
                    }
                })
                    .then((response) => {

                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
            this.state.inputData.maker_approval = this.state.submitFlag;
            let remarksData = {};
            remarksData.remark = this.state.inputData.makerRemarks;
            remarksData.map = {...this.state.inputData, makerRemarks: undefined};
            let url = backEndServerURL + "/case/saveDataDeferralRemarksAndRoute/" + this.props.appId;
            axios.post(url, remarksData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
                this.setState({
                    title: "Successful!",
                    notificationMessage: "Successfully Routed!",
                    alert: true,
                    loading: false
                });
                this.props.closeModal();
            }).catch((error) => {
                console.log(error);
                this.setState({
                    loading: false
                })
            });
        } else {
            this.setState({
                confirmationAlert: false,
                disabled: false,
            })
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if ((this.state.inputData.subCategory === "Regulatory Account Inquiry" || this.state.inputData.subCategory === "Dupl. Advice (Special)") && this.state.fileUploadData.scanningFile !== undefined) {
            let fileError = MyValidation.fileValidation(fileUpload, this.state);
            this.forceUpdate();
            if (fileError === true) {
                return 0;
            } else {
                this.state.inputData.maker_update_all_info_send_to = "CHECKER";
                this.setState({
                    submitFlag: "APPROVED",
                    title: "Do you want to confirm?",
                    confirmationAlert: true
                })
            }
        } else if (this.state.inputData.changingRenewalMaturityInstructionOnly === true || this.state.inputData.newFDROpening === true) {
            let allJsonForm = [];
            if (this.state.inputData.changingRenewalMaturityInstructionOnly === true) {
                allJsonForm = [...this.state.exObjectForNominee, ...this.state.existingObjectForNominee]
            }
            if (this.state.inputData.newFDROpening === true) {
                allJsonForm = [...allJsonForm, ...this.state.objectForNominee]
            }
            let errorTwo = false
            if (this.state.inputData.changingRenewalMaturityInstructionOnly) {
                errorTwo = MyValidation.defaultValidation(existingFDRForm, this.state);
            }
            let errorThree = false;
            if (this.state.inputData.newFDROpening === true) {
                errorThree = MyValidation.defaultValidation(newFdrOpeningFormMakerOne.concat(newFdrOpeningFormMakerTwo), this.state)
            }
            let error = MyValidation.defaultValidation(this.props.commonJsonForm.concat(allJsonForm), this.state);
            this.forceUpdate();
            if (error === true || errorTwo === true || errorThree === true) {
                return 0;
            } else {
                let percentage = 0;
                let oldNominee = this.state.inputData.nomineeNumberOld ? parseInt(this.state.inputData.nomineeNumberOld) : 0;
                if (this.state.inputData["numberOfNewNominee"]) {
                    let sl;
                    for (let i = 0; i < this.state.inputData["numberOfNewNominee"]; i++) {
                        sl = oldNominee + (i + 1);
                        if (this.state.inputData["expercentageNominee" + sl] !== undefined) {
                            percentage += parseFloat(this.state.inputData["expercentageNominee" + sl]);
                        }
                    }
                }
                if (oldNominee) {
                    for (let i = 0; i < oldNominee; i++) {
                        if (this.state.inputData["exdeleteOldNominee" + (i + 1)] !== "Y") {
                            percentage += parseFloat(this.state.inputData["expercentageNominee" + (i + 1)]);
                        }
                    }
                }

                let percentageForNewNominee = 0;
                if (this.state.inputData["numberOfNominee"]) {
                    let sl;
                    for (let i = 0; i < this.state.inputData["numberOfNNominee"]; i++) {
                        sl = i + 1;
                        if (this.state.inputData["percentageNominee" + sl] !== undefined) {
                            percentage += parseFloat(this.state.inputData["percentageNominee" + sl]);
                        }
                    }
                }

                if ((percentage !== parseFloat("100.0") && (this.state.inputData["numberOfNewNominee"] > 0 || oldNominee > 0)) || (percentageForNewNominee !== parseFloat("100.0") && this.state.inputData["numberOfNominee"] > 0)) {
                    this.setState({
                        cbNotFound: true,
                        title: "Nominee Percentage should be 100%",
                        disabled: false
                    });
                    return 0;
                } else {
                    this.state.inputData.maker_update_all_info_send_to = "CHECKER";
                    this.setState({
                        submitFlag: "APPROVED",
                        title: "Do you want to confirm?",
                        confirmationAlert: true
                    })
                }
            }
        } else {
            let error = MyValidation.defaultValidation(this.props.commonJsonForm, this.state);
            this.forceUpdate();
            if (error === true) {
                return 0;
            } else {
                if (this.state.inputData.freeFlag2 === "FDR Encashment") {
                    if ((this.state.inputData.subCategory === "FDR Encashment" || this.state.inputData.subCategory === "FDR Interest Payment" || this.state.inputData.subCategory === "Deceased FDR Encashment") && parseInt(this.state.inputData.transferAmount) < 100000) {
                        this.setState({
                            cbNotFound: true,
                            disabled: false,
                            title: "Minimum RTGS Payment is 1 Lac"
                        });
                    } else if ((this.state.inputData.transferType === "RTGS" || this.state.inputData.transferType === "BEFTN") && parseInt(this.state.inputData.transferAmount) >= 50000000) {
                        this.setState({
                            disabled: false,
                            title: "For payment of Tk. 5 crore and above, please take approval from Treasury Division.Did you take the approval ?",
                            confirmation: true,
                        });
                    } else if (this.state.inputData.transferType === "RTGS") {
                        this.setState({
                            title: "Charge and VAT applicable as per Schedule of Charges",
                            confirmation: true,
                        })
                    } else {
                        this.state.inputData.maker_update_all_info_send_to = "CHECKER";
                        this.setState({
                            submitFlag: "APPROVED",
                            title: "Do you want to confirm?",
                            confirmationAlert: true
                        })
                    }
                } else {
                    this.state.inputData.maker_update_all_info_send_to = "CHECKER";
                    this.setState({
                        submitFlag: "APPROVED",
                        title: "Do you want to confirm?",
                        confirmationAlert: true
                    })
                }
            }
        }
    };

    confirmationClose = (value) => {
        if (value === "ok") {
            this.state.inputData.maker_update_all_info_send_to = "CHECKER";
            this.setState({
                loading: false,
                submitFlag: "APPROVED",
                title: "Do you want to confirm?",
                confirmationAlert: true
            })
        } else {
            this.setState({
                loading: false,
                disabled: false,
                confirmation: false
            })
        }
    };

    handleSubmitReturn = (event) => {
        event.preventDefault();

        let error = MyValidation.fileValidation(fileUpload, this.state);
        let errorTwo = MyValidation.defaultValidation(makerRemarks, this.state);

        this.forceUpdate();
        if (error === true || errorTwo === true) {
            return 0;
        } else {
            if ((this.state.inputData.subCategory === "Regulatory Account Inquiry" || this.state.inputData.subCategory === "Dupl. Advice (Special)") && this.state.fileUploadData.scanningFile !== undefined) {
                let error = MyValidation.fileValidation(fileUpload, this.state);
                this.forceUpdate();
                if (error === true) {
                    return 0;
                } else {
                    this.setState({
                        submitFlag: "RETURN",
                        title: "Do you want to confirm?",
                        confirmationAlert: true
                    });
                }
            } else {
                this.setState({
                    submitFlag: "RETURN",
                    title: "Do you want to confirm?",
                    confirmationAlert: true
                })
            }
        }

    };

    stopLoading = (value) => {
        if (value === "yes") {
            console.log("......stop load")
            setTimeout(() => {
                this.setState({
                    loading: false,
                });
            }, 1000)
        } else {
            this.setState({
                getData: false,
                searchData: false,
                loading: false,
                cbNotFound: true,
                title: value.response.data.message
            })
        }
    };

    renderJsonFormFirst = () => {
        if (this.state.getData) {
            return (
                <React.Fragment>
                    <Label cbNumber={this.state.inputData.cbNumber} stopLoading={this.stopLoading}
                           subCategory={this.state.inputData.subCategory}
                           accountNumber={this.state.inputData.accountNumber} classes={this.props}/>
                    <Grid item xs={12}>
                        <br/>
                    </Grid>
                    <Grid container spacing={0}>
                        <PreviewButton appId={this.props.appId} classes={this.props}/>
                    </Grid>
                    <Grid item xs='12'>
                        <br/>
                    </Grid>
                    {CommonJsonFormComponent.renderJsonForm(this.state, this.props.commonJsonForm, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                    {this.renderExistingFdrOpenForm()}
                    {this.state.inputData.subCategory === "Tenor or Scheme Change" && CommonJsonFormComponent.renderJsonForm(this.state, fdrNewForm, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                    {this.renderNewFDROpeningFormOne()}
                </React.Fragment>
            )
        }
    };

    renderExistingFdrOpenForm = () => {
        if (this.state.getData && this.state.inputData.changingRenewalMaturityInstructionOnly === true) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, existingFDRForm, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                    {this.renderExistingNomineeForm()}
                    <Grid item xs={12}></Grid>
                    <Grid item xs={6}>
                        {this.renderNewNomineeNumber()}
                    </Grid>
                    {this.renderAddNewNomineeForm()}
                </>
            )
        }
    };

    renderNewFDROpeningFormOne = () => {
        if (this.state.getData && this.state.inputData.newFDROpening === true) {
            return (
                <>
                    {CommonJsonFormComponent.renderJsonForm(this.state, newFdrOpeningFormMakerOne, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                    <Grid item xs={6}>
                        {this.renderNomineeNumber()}
                    </Grid>
                    {this.renderNomineeForm()}
                    {CommonJsonFormComponent.renderJsonForm(this.state, newFdrOpeningFormMakerTwo, this.updateComponent, this.onBlurApiCall, this.onKeyDownChange)}
                </>
            )
        }
    };

    renderFileUpload = () => {
        if (this.state.getData && (this.state.inputData.subCategory === "Regulatory Account Inquiry" || this.state.inputData.subCategory === "Dupl. Advice (Special)")) {
            return (
                <Grid item xs={12}>
                    {FileTypeComponent.restrictedFile(this.state, this.updateComponent, fileUpload)}
                </Grid>
            )
        }
    };

    renderDefferalData = () => {
        if (this.state.getDeferalList.length > 0) {
            return (
                <div style={{marginTop: "5px"}}>
                    <div style={{
                        "border-style": "groove", "border-width": "1px"
                    }}>
                        <b>Deferral List:</b>
                        <Table
                            tableHovor="yes"
                            tableHeaderColor="primary"
                            tableHead={["Deferral Type", "Expire Date", "Raise By", "Raise Date", "Status"]}
                            tableData={this.state.getDeferalList}
                            tableAllign={['left', 'left', 'left', 'left', 'left']}
                        />
                        <br/>
                    </div>
                </div>
            )
        }
    };

    renderButton = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px", marginBottom: "5px"}}>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmit}
                    >Submit
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        disabled={this.state.disabled}
                        type='button' value='add more'
                        onClick={this.handleSubmitReturn}
                    >Return
                    </button>
                </div>
            )
        }
    };

    renderRemarks = () => {
        if (this.state.getData) {
            return (
                <div style={{marginTop: "5px"}}>
                    {CommonJsonFormComponent.renderJsonForm(this.state, makerRemarks, this.updateComponent)}
                </div>
            )
        }
    };

    viewAttachedImages = () => {
        const {classes} = this.props;
        if (this.state.getData) {
            return <PreviewMappingImage appId={this.props.appId} classes={classes}/>
        }
    };

    functionForGetRmName = (fieldName, varName, flagName,bool) => {
        this.setState({
            loading: true
        });
        this.state.inputData[flagName] = "NO";
        this.state.varValue[flagName] = "NO";
        let url = backEndServerURL + "/finMasterData/wealthManagerCode/" + fieldName;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData[varName] = response.data;
                this.state.varValue[varName] = response.data;
                this.state.inputData[flagName] = "YES";
                this.state.varValue[flagName] = "YES";
                this.forceUpdate();
                if(bool === undefined){
                    this.setState({
                        loading: false,
                        varValue: this.state.varValue,
                    })
                }
                else{
                    this.setState({
                        varValue: this.state.varValue,
                    })
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "Invalid RM Code"
                })
            })
    };

    functionForDebitAccountName = (fieldName, varName, flagName) => {
        this.setState({
            loading: true
        });
        this.state.inputData[flagName] = "NO";
        this.state.varValue[flagName] = "NO";
        this.forceUpdate();
        let urls = backEndServerURL + "/intgr/accountBalanceInfo/" + fieldName;
        axios.get(urls, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.state.inputData[varName] = response.data.name;
                this.state.varValue[varName] = response.data.name;
                this.state.inputData[flagName] = "YES";
                this.state.varValue[flagName] = "YES";
                this.setState({
                    showValue: true,
                    loading: false,
                    getData: true,
                    staticData: true,
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    cbNotFound: true,
                    title: "Invalid Account Number!",
                })
            })
    };

    onBlurApiCall = (event, data) => {
        if (data === "newFDROpening") {
            this.setState({
                loading: true
            })

            let inputData = {...this.state.inputData};
            inputData.tranCreationDuring = "V";
            inputData.flowCode = "TO";
            inputData.amtType = "F";
            inputData.transactionType = "TRANSFER";
            inputData.taxCategory = {
                "masterDataName": "taxCategory",
                "key": "W",
                "value": "W-A/c.Level Withholding Tax"
            };

            inputData.withHoldingTaxLevel = {
                "masterDataName": "withHoldingTaxLevel",
                "key": "A",
                "value": "A-A/c.Level"
            };
            inputData.withholdingTaxBorneBy = {
                "masterDataName": "withholdingTaxBorneBy",
                "key": "P",
                "value": "Principal A/c.Holder Only"
            };
            inputData.freeCode6 = 4
            this.setState({
                inputData: inputData,
                varValue: this.copyJson(inputData),
                loading: false
            })
        }
        else if(data === "encashmentOfExistingFDRandIssuanceOfNewFDR"){
            this.state.inputData.encashmentAcId = this.state.inputData.accountNumber;
            this.state.varValue.encashmentAcId = this.state.inputData.accountNumber;
        }
        else if (data === "exdepositinstallAmount") {
            this.setState({
                loading: true
            })
            this.state.inputData.exshowAmtField = false;
            this.functionForExciseDuty(this.state.inputData.exdepositinstallAmount, this.state.inputData.exvalueDate, this.state.inputData.exmaturityDate).then((exciseDutyData) => {
                this.state.inputData.examt = exciseDutyData.data.amt;
                this.state.varValue.examt = exciseDutyData.data.amt;
                this.state.inputData.exparticulars = exciseDutyData.data.particulars;
                this.state.varValue.exparticulars = exciseDutyData.data.particulars;
                this.state.inputData.exshowAmtField = true;
                this.forceUpdate();
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false
                })
            })
        }
        else if (data === "depositinstallAmount") {
            this.setState({
                loading: true
            })
            this.state.inputData.showAmtField = false;
            this.functionForExciseDuty(this.state.inputData.depositinstallAmount, this.state.inputData.valueDate, this.state.inputData.maturityDate).then((exciseDutyData) => {
                this.state.inputData.amt = exciseDutyData.data.amt;
                this.state.varValue.amt = exciseDutyData.data.amt;
                this.state.inputData.particulars = exciseDutyData.data.particulars;
                this.state.varValue.particulars = exciseDutyData.data.particulars;
                this.state.inputData.showAmtField = true;
                this.forceUpdate();
                this.setState({
                    loading: false
                })
            }).catch((error) => {
                this.setState({
                    loading: false
                })
            })
        } else if (data === "rmCode") {
            this.functionForGetRmName(this.state.inputData.rmCode, "rmCodeName", "rmCodeApiCall");
        } else if (data === "exrepaymentAcId") {
            this.functionForDebitAccountName(this.state.inputData.exrepaymentAcId, "exrepaymentName", "exrepaymentAccountApiCall");
        } else if (data === "exrmCode") {
            this.functionForGetRmName(this.state.inputData.exrmCode, "exrmCodeName", "exrmCodeApiCall");
        } else if (data === "debitAccountNumber") {
            this.functionForDebitAccountName(this.state.inputData.debitAccountNumber, "debitAccountName", "debitAccountNumberApiCall");
        } else if (data === "exdebitAccountNumber") {
            this.functionForDebitAccountName(this.state.inputData.exdebitAccountNumber, "exdebitAccountName", "exdebitAccountNumberApiCall");
        } else if (data === "exinterestCreditAccount") {
            this.functionForDebitAccountName(this.state.inputData.exinterestCreditAccount, "exinterestCreditName", "exinterestCreditAccountApiCall");
        } else if (data === "interestCreditAccount") {
            this.functionForDebitAccountName(this.state.inputData.interestCreditAccount, "interestCreditName", "interestCreditAccountApiCall");
        } else if (data === "repaymentAcId") {
            this.functionForDebitAccountName(this.state.inputData.repaymentAcId, "repaymentName", "repaymentAccountApiCall");
        } else if (data === "exciseDuty") {
            this.state.inputData.showNetPay = false;
            this.setState({
                loading: true
            });
            let principleAmount = this.state.inputData.principalAmount ? this.state.inputData.principalAmount : 0;
            let prematureNetInterest = this.state.inputData.prematureNetInterest ? this.state.inputData.prematureNetInterest : 0;
            let exciseDuty = this.state.inputData.exciseDuty ? this.state.inputData.exciseDuty : 0;
            let finalData = principleAmount + prematureNetInterest - exciseDuty;
            this.state.inputData.customerNetPayable = finalData;
            this.state.inputData.transferAmount = finalData;
            this.state.varValue.customerNetPayable = finalData;
            this.state.inputData.showNetPay = true;
            this.forceUpdate();
            this.setState({
                loading: false
            })
        } else if (data === "creditAccountNumber") {
            this.state.inputData.showCreditAccountName = false;
            let inputData = this.state.inputData;
            this.setState({
                loading: true
            });
            FunctionForGetAcDetails.getAccountInfo(this.state.inputData["creditAccountNumber"]).then((response) => {
                inputData.creditAccountName = response.data.acctName;
                setTimeout(() => {
                    inputData.showCreditAccountName = true;
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        showValue: true,
                        loading: false
                    });
                }, 1000)
            }).catch((error) => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false,
                    title: error.response.data.message,
                    cbNotFound: true,
                })
            })
        }
    };

    renderTenorRelatedApi = (tenorType, tenorPeriod, renewal, maturity, apiType, bool, varName, flagName) => {
        console.log("....api type",apiType,bool)
        let obj = {};
        let jsonArray = newFdrOpeningFormMakerOne.concat(newFdrOpeningFormMakerTwo, existingFDRForm, this.props.commonJsonForm);
        if (tenorType !== undefined && apiType === "tenorType") {
            this.state.inputData[flagName] = false;
            this.setState({
                loading: true
            });

            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getTenor/maintenance";
            obj.tenureType = tenorType;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of jsonArray) {
                        if (item.varName === varName) {
                            item.enum = response.data
                        }
                    }
                    let inputData = this.state.inputData;
                    inputData[flagName] = true;
                    if (bool === undefined) {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false
                        });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                        });
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
        if (tenorPeriod !== undefined && apiType === "tenorPeriod") {
            console.log("...........", flagName, varName);
            this.state.inputData[flagName] = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getRenewal/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of jsonArray) {
                        if (item.varName === varName) {
                            item.enum = response.data.renewal
                        }
                    }
                    let inputData = this.state.inputData;
                    if (response.data.depositCode) {
                        inputData[varName] = response.data.depositCode;
                    }
                    inputData[flagName] = true;
                    if (bool === undefined) {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false
                        });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                        });
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
        if (renewal !== undefined && apiType === "renewal") {
            this.state.inputData[flagName] = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getMaturity/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            obj.autoRenewals = renewal;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    for (let item of jsonArray) {
                        if (item.varName === varName) {
                            item.enum = response.data
                        }
                    }
                    let inputData = this.state.inputData;
                    inputData[flagName] = true;
                    if (bool === undefined) {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false
                        });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                        });
                    }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
        if (maturity !== undefined && apiType === "maturity") {
            this.state.inputData[flagName] = false;
            this.setState({
                loading: true
            });
            let tenorPeriodUrl = backEndServerURL + "/FDRDPSSchemeCode/getSchemeCode/maintenance";
            obj.tenureType = tenorType;
            obj.tenure = tenorPeriod;
            obj.autoRenewals = renewal;
            obj.maturity = maturity;
            axios.post(tenorPeriodUrl, obj, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    // if (bool === undefined) {
                    //     for (let item of this.props.commonJsonForm) {
                    //         if (item.varName === varName) {
                    //             item.enum = response.data
                    //         }
                    //     }
                    let inputData = this.state.inputData;
                    inputData[varName] = response.data;
                    inputData[flagName] = true;
                    if (bool === undefined) {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                            loading: false
                        });
                    } else {
                        this.setState({
                            inputData: inputData,
                            varValue: this.copyJson(inputData),
                        });
                    }
                    // }
                    this.forceUpdate();
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        loading: false
                    })
                })
        }
    };

    functionForGetBankName = (beneficiaryBankName) => {
        let url = backEndServerURL + "/finMasterData/custom/otherBranches/" + beneficiaryBankName;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (response.status === 200) {
                    let branchName = [];
                    for (let item of response.data) {
                        branchName.push(item.value)
                    }
                    for (let field of this.props.commonJsonForm) {
                        if (field.varName === "branchName") {
                            field.enum = branchName
                        }
                    }
                    this.forceUpdate();
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false
                });
                console.log(error)
            });
    };

    suSectorCodeApiCall = (sectorCode, flagName) => {
        this.setState({
            loading: true,
        });
        this.state.inputData[flagName] = false;
        let subSectorCodeUrl = backEndServerURL + "/finMasterData/custom/subSector/" + sectorCode;
        axios.get(subSectorCodeUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                if (this.state.inputData.newFDROpening === true) {
                    for (let item of newFdrOpeningFormMakerTwo) {
                        if (item.varName === "subSectorCode") {
                            item.enum = response.data
                        }
                    }
                }
                for (let item of this.props.commonJsonForm) {
                    if (item.varName === "subSectorCode" || item.varName === "exsubSectorCode") {
                        item.enum = response.data
                    }
                }
                let inputData = this.state.inputData;
                // inputData.sectorCodeApicall = true;
                inputData[flagName] = true;
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false
                });
                this.forceUpdate();
            })
            .catch((error) => {
                console.log(error)
            })
    };

    interestRateApiCall = (schemeCode, depositinstallAmount, depositPeriodMonthsdays, depositTenure, ccy, varName, flagName, value) => {
        if (schemeCode && depositinstallAmount && depositPeriodMonthsdays && depositTenure && ccy) {
            let interestCalculateAPi = backEndServerURL + "/intgr/getFDRRate";
            let data = {};
            data.schemeCode = schemeCode ? schemeCode["key"] : "";
            data.amount = depositinstallAmount;
            data.tenure = depositPeriodMonthsdays;
            data.tenorType = depositTenure;
            data.currency = ccy ? ccy["key"] : "";
            this.setState({
                loading: true,
                staticData: false,
            });
            axios.post(interestCalculateAPi, data, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (value === "exvalueDate") {
                        this.functionForFDMaturityDate(this.state.inputData.exvalueDate, "exfdMaturityDateApicall", "exmaturityDate", this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exdepositTenure)
                    }
                    console.log(response.data);
                    this.state.inputData[varName] = response.data;
                    this.state.varValue[varName] = response.data;
                    this.state.inputData[flagName] = "YES";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        staticData: true,
                    })
                })
                .catch((error) => {
                    console.log(error);
                    this.state.inputData[flagName] = "YES";
                    this.forceUpdate();
                    this.setState({
                        loading: false,
                        title: "Invalid Input !",
                        cbNotFound: true,
                    })
                })
        }
    };

    functionForFDMaturityDate = (valueDate, flagName, varName, tenure, depositTenure) => {
        if (valueDate && tenure && depositTenure) {
            this.state.inputData[flagName] = "NO";
            this.state.varValue[flagName] = "NO";
            this.forceUpdate();
            let someDate = new Date();
            if (valueDate !== undefined && valueDate !== null && valueDate !== -1) {
                someDate = new Date(valueDate);
            }
            let x = tenure;
            let numberOfMonthsToAdd = parseInt(x, 10);
            let dates = "";
            let month = "";
            if (depositTenure === "Day") {
                someDate.setDate(someDate.getDate() + numberOfMonthsToAdd);
                dates = someDate.getDate();
                month = someDate.getMonth() + 1;

            } else {
                someDate.setMonth(someDate.getMonth() + numberOfMonthsToAdd);
                dates = someDate.getDate();
                month = someDate.getMonth() + 1;

            }
            if (month <= 9) {
                month = "0" + month;
            }
            if (dates <= 9) {
                dates = "0" + dates;
            }

            let year = someDate.getFullYear();
            let calculateDate = dates + '-' + month + '-' + year;
            if (calculateDate === "NaN-NaN-NaN") {
                calculateDate = ""
            }
            setTimeout(() => {
                this.state.inputData[varName] = calculateDate;
                this.state.varValue[varName] = calculateDate;
                this.state.inputData[flagName] = "YES";
                this.state.varValue[flagName] = "YES";
                this.forceUpdate()
            }, 100)
        }
    }

    onKeyDownChange = (data) => {
        if (data.varName === "autoRenewal") {
            this.state.inputData.showautoRenewal = "NO"
            this.setState({
                loading: true
            })
            let inputData = {...this.state.inputData}
            if (inputData.autoRenewal === "Yes") {
                inputData.autoClosure = "No"
                inputData.showautoRenewal = "YES"
            } else if (inputData.autoRenewal === "No") {
                inputData.autoClosure = "Yes"
                inputData.showautoRenewal = "YES"
            }
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false
                })
            }, 1000)
        }
        if (data.varName === "exautoRenewal") {
            this.state.inputData.exshowautoRenewal = "NO"
            this.setState({
                loading: true
            })
            let inputData = {...this.state.inputData}
            if (inputData.exautoRenewal === "Yes") {
                inputData.exautoClosure = "No"
                inputData.exshowautoRenewal = "YES"
            } else if (inputData.exautoRenewal === "No") {
                inputData.exautoClosure = "Yes"
                inputData.exshowautoRenewal = "YES"
            }
            setTimeout(() => {
                this.setState({
                    inputData: inputData,
                    varValue: this.copyJson(inputData),
                    loading: false
                })
            }, 1000)
        }
        if (data.varName === "exdepositTenure") {
            this.renderTenorRelatedApi(this.state.inputData.exdepositTenure, undefined, undefined, undefined, "tenorType", undefined, "exdepositPeriodMonthsdays", "exshowTenorPeriod");
        }
        if (data.varName === "depositTenure") {
            this.renderTenorRelatedApi(this.state.inputData.depositTenure, undefined, undefined, undefined, "tenorType", undefined, "depositPeriodMonthsdays", "showdepositPeriodMonthsdays");
        }
        if (data.varName === "tenorType") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.maturity, "tenorType", undefined, "newTenor", "showTenorPeriod",)
        }

        if (data.varName === "exdepositPeriodMonthsdays") {
            this.renderTenorRelatedApi(this.state.inputData.exdepositTenure, this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exautoRenewal, this.state.inputData.exmaturity, "tenorPeriod", undefined, "exdepositeType", "exdepositeTypeApicall")
            this.functionForFDMaturityDate(this.state.inputData.exvalueDate, "exfdMaturityDateApicall", "exmaturityDate", this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exdepositTenure)
        }
        if (data.varName === "depositPeriodMonthsdays") {
            this.renderTenorRelatedApi(this.state.inputData.depositTenure, this.state.inputData.depositPeriodMonthsdays, this.state.inputData.autoRenewal, this.state.inputData.maturity, "tenorPeriod", undefined, "depositeType", "depositeTypeApicall")
            this.functionForFDMaturityDate(this.state.inputData.valueDate, "fdMaturityDateApicall", "maturityDate", this.state.inputData.depositPeriodMonthsdays, this.state.inputData.depositTenure)
        }
        if (data.varName === "newTenor") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "tenorPeriod", undefined, "renewal", "showRenewal")
        }
        if (data.varName === "renewal") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "renewal", undefined, "maturity", "showMaturity")
        }
        if (data.varName === "maturity") {
            this.setState({
                loading: false
            })
            this.state.inputData.showAmtField = false;
            this.functionForExciseDuty(this.state.inputData.depositinstallAmount, this.state.inputData.valueDate, this.state.inputData.maturityDate).then((exciseDutyData) => {
                this.state.inputData.amt = exciseDutyData.data.amt;
                this.state.varValue.amt = exciseDutyData.data.amt;
                this.state.inputData.particulars = exciseDutyData.data.particulars;
                this.state.varValue.particulars = exciseDutyData.data.particulars;
                this.state.inputData.showAmtField = true;
                this.renderTenorRelatedApi(this.state.inputData.depositTenure, this.state.inputData.depositPeriodMonthsdays, this.state.inputData.autoRenewal, this.state.inputData.maturity, "maturity", undefined, "fdrSchemeCode", "showSchemeCode")
            }).catch((error) => {
                this.setState({
                    loading: false
                })
            })
        }
        if (data.varName === "csmaturity") {
            this.renderTenorRelatedApi(this.state.inputData.tenorType, this.state.inputData.newTenor, this.state.inputData.renewal, this.state.inputData.csmaturity, "maturity", undefined, "csfdrSchemeCode", "csshowSchemeCode")
        }
        if (data.varName === "sectorCode") {
            this.suSectorCodeApiCall(this.state.inputData.sectorCode["key"], "sectorCodeApiCall");
        }
        if (data.varName === "exsectorCode") {
            this.suSectorCodeApiCall(this.state.inputData.exsectorCode["key"], "exsectorCodeApiCall");
        }
        if (data.varName === "exmaturity") {
            this.setState({
                loading: true
            })
            this.state.inputData.exshowAmtField = false;
            this.functionForExciseDuty(this.state.inputData.exdepositinstallAmount, this.state.inputData.exvalueDate, this.state.inputData.exmaturityDate).then((exciseDutyData) => {
                this.state.inputData.examt = exciseDutyData.data.amt;
                this.state.varValue.examt = exciseDutyData.data.amt;
                this.state.inputData.exparticulars = exciseDutyData.data.particulars;
                this.state.varValue.exparticulars = exciseDutyData.data.particulars;
                this.state.inputData.exshowAmtField = true;
                this.forceUpdate();
                this.renderTenorRelatedApi(this.state.inputData.exdepositTenure, this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exautoRenewal, this.state.inputData.exmaturity, "maturity", undefined, "exschemeCode", "showexSchemeCode")
            }).catch((error) => {
                this.setState({
                    loading: false
                })
            })
        }
        if (data.varName === "updateInterestRate" || data.varName === "valueDate") {
            this.interestRateApiCall(this.state.inputData.schemeCode, this.state.inputData.depositinstallAmount, this.state.inputData.depositPeriodMonthsdays, this.state.inputData.depositTenure, this.state.inputData.ccy, "interestRate", "interestRateApicall")
            this.functionForFDMaturityDate(this.state.inputData.valueDate, "fdMaturityDateApicall", "maturityDate", this.state.inputData.depositPeriodMonthsdays, this.state.inputData.depositTenure)
        }
        if (data.varName === "exupdateInterestRate" || data.varName === "exvalueDate") {
            this.interestRateApiCall(this.state.inputData.exschemeCode, this.state.inputData.exdepositinstallAmount, this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exdepositTenure, this.state.inputData.exccy, "exinterestRate", "exinterestRateApicall", "exvalueDate")
            this.functionForFDMaturityDate(this.state.inputData.exvalueDate, "exfdMaturityDateApicall", "exmaturityDate", this.state.inputData.exdepositPeriodMonthsdays, this.state.inputData.exdepositTenure)
        }
        if (data.varName === "debitAccountStatement") {
            this.setState({
                accountStatement: true
            })
        }
        if (data.varName === "nomineeType") {
            if (this.state.inputData.nomineeType === "Nominee Same As Operative Account") {
                this.setState({
                    loading: true
                });
                this.state.inputData.nomineeType = null;
                let inputData = {...this.state.inputData};
                inputData.nomineeRegNo = 0;
                inputData.nomineeRelationship = "ZZZ";
                inputData.percentageNominee = "100.00";
                inputData.nomineeAddress1 = "SAME AS LINK A/C";
                inputData.nomineeCityCode = "ZZZ";
                inputData.nomineeStateCode = "ZZZ";
                inputData.nomineePostalCode = "9999";
                inputData.nomineeCountry = "ZZZ";
                inputData.photoIdNumberRegNo = "0";
                inputData.nomineeDob = new Date("1900-01-01T00:00:00.000");
                inputData.nomineeMinor = "N";
                inputData.tranCreationDuring = "V";
                inputData.nomineeName = "SAME AS " + inputData.debitAccountNumber;
                // inputData.sectorCodeApiCall = true;
                // inputData.fdMaturityDateApicall = "YES";
                inputData.nomineeType = "Nominee Same As Operative Account"
                // inputData.taxCategory = {
                //     "masterDataName": "taxCategory",
                //     "key": "W",
                //     "value": "W-A/c.Level Withholding Tax"
                // };
                // inputData.withHoldingTaxLevel = {
                //     "masterDataName": "withHoldingTaxLevel",
                //     "key": "A",
                //     "value": "A-A/c.Level"
                // };
                // inputData.withholdingTaxBorneBy = {
                //     "masterDataName": "withholdingTaxBorneBy",
                //     "key": "P",
                //     "value": "Principal A/c.Holder Only"
                // };
                setTimeout(() => {
                    this.setState({
                        inputData: inputData,
                        varValue: this.copyJson(inputData),
                        loading: false
                    })
                }, 1000)
            }
        }
        if (data.varName === "beneficiaryBankName") {
            this.setState({
                loading: true
            });
            this.state.inputData.showBranchName = false;
            this.state.varValue.showBranchName = false;
            this.state.inputData.showRoutingNumber = false;
            this.state.varValue.showRoutingNumber = false;
            this.forceUpdate();
            let url = backEndServerURL + "/finMasterData/custom/otherBranches/" + this.state.inputData.beneficiaryBankName;
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        let branchName = [];
                        for (let item of response.data) {
                            branchName.push(item.value)
                        }
                        for (let field of this.props.commonJsonForm) {
                            if (field.varName === "branchName") {
                                field.enum = branchName
                            }
                        }
                        this.state.inputData.showBranchName = true;
                        this.state.varValue.showBranchName = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    console.log(error)
                });
        }
        if (data.varName === "branchName") {
            this.setState({
                loading: true
            });
            let routingUrl = backEndServerURL + "/finMasterData/custom/routingNo/" + this.state.inputData.beneficiaryBankName + "/" + this.state.inputData.branchName;
            this.state.inputData.showRoutingNumber = false;
            this.state.varValue.showRoutingNumber = false;
            this.forceUpdate();
            axios.get(routingUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    if (response.status === 200) {
                        this.state.inputData.routingNumber = response.data.toString();
                        this.state.varValue.routingNumber = response.data.toString();
                        this.state.inputData.showRoutingNumber = true;
                        this.state.varValue.showRoutingNumber = true;
                        this.forceUpdate();
                        this.setState({
                            loading: false
                        });
                    }
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    });
                    console.log(error)
                });
        }
    };

    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };

    renderNomineeNumber = () => {
        if (this.state.inputData.subCategory === "FD Nominee Change" || ((this.state.inputData.subCategory === "Tenor or Scheme Change" || this.state.inputData.subCategory === "S. Tax Reversal") && (this.state.inputData.newFDROpening === true && this.state.inputData.nomineeType === "New Nominee Assignment"))) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number Of Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNomineNumber(event, option)}
                                      defaultValue={{title: this.state.inputData["numberOfNominee"]}}
                                      options={numberOfNominee} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"
                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>

            )
        }
    };

    renderNewNomineeNumber = () => {
        if (this.state.getData && this.state.inputData.changingRenewalMaturityInstructionOnly === true) {
            return (
                <React.Fragment>
                    <Grid item>
                        <label className="input-label-common">Number of New Nominee</label>
                    </Grid>
                    <Grid item>
                        <Autocomplete onChange={(event, option) => this.handleChangeNewNomineeNumber(event, option)}
                                      defaultValue={{title: this.state.inputData["numberOfNewNominee"]}}
                                      options={numberOfNominee} getOptionLabel={option => option.title}
                                      renderInput={(params) => <TextField {...params} variant="outlined"
                                                                          style={{paddingRight: 20}} fullWidth/>}
                        />
                    </Grid>
                </React.Fragment>

            )
        }
    };

    handleChangeNomineNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNominee"] = parseInt(option.title);
            this.forceUpdate();
            if (option.title > 0) {
                let sl;
                let objectForNominee = [];
                for (let i = 0; i < parseInt(option.title); i++) {
                    sl = (i + 1);
                    objectForNominee.push(
                        {
                            "varName": "Nominee " + i,
                            "type": "title",
                            "label": "Nominee  " + sl,
                            "grid": 12,
                        },
                        {
                            "varName": "search" + sl,
                            "type": "select",
                            "enum": [
                                "CIF ID",
                                "INFORMATION",
                            ],
                            "required": true,
                            "onKeyDown": true,
                            "label": "Input CIF ID/INFORMATION ?",
                            "grid": 6,
                        },
                        {
                            "varName": "nomineecifId" + sl,
                            "type": "textApiCall",
                            "label": "CIF ID",
                            "grid": 6,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "CIF ID",
                        },

                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",

                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "required": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],


                        },
                        {
                            "varName": "nomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            required: true,

                        },
                        {
                            "varName": "percentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },

                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "photoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "text",
                            "label": "D.O.B(YYYY-MM-DD)",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeDob" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 6,
                            "onKeyDown": true,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "search" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },


                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "nomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "nomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,

                        },
                        {
                            "varName": "guardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "nomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "nomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                        },

                        {
                            "varName": "nomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "nomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },
                    )
                }
                this.setState({
                    objectForNominee: objectForNominee,
                    getNominee: true
                });
            }
        } else {
            this.setState(prev => ({
                objectForNominee: [],
                getNominee: false
            }));
        }
    };

    handleChangeNewNomineeNumber = (event, option) => {
        event.preventDefault();
        if (option !== null) {
            this.state.inputData["numberOfNewNominee"] = parseInt(option.title);
            this.forceUpdate();
            if (option.title > 0) {
                let sl;
                let objectForNominee = [];
                for (let i = 0; i < parseInt(option.title); i++) {
                    sl = parseInt(this.state.inputData.numberOfOldNominee) + (i + 1);
                    objectForNominee.push(
                        {
                            "varName": "exNominee " + i,
                            "type": "title",
                            "label": "Nominee  " + sl,
                            "grid": 12,
                        },
                        {
                            "varName": "exsearch" + sl,
                            "type": "select",
                            "enum": [
                                "CIF ID",
                                "INFORMATION",
                            ],
                            "required": true,
                            "onKeyDown": true,
                            "label": "Input CIF ID/INFORMATION ?",
                            "grid": 6,
                        },
                        {
                            "varName": "exnomineecifId" + sl,
                            "type": "textApiCall",
                            "label": "CIF ID",
                            "grid": 6,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "CIF ID",
                        },

                        {
                            "varName": "exnomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeName" + sl,
                            "type": "text",
                            "label": "Nominee Name",
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",

                        },
                        {
                            "varName": "exnomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "required": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],


                        },
                        {
                            "varName": "exnomineeRelationship" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.relationCode,
                            "label": "Relationship",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },
                        {
                            "varName": "expercentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            required: true,

                        },
                        {
                            "varName": "expercentageNominee" + sl,
                            "type": "text",
                            "label": "Percentage %",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                            required: true,

                        },

                        {
                            "varName": "exnomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeAddress1" + sl,
                            "type": "text",
                            "label": "Address 1 ",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            required: true,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "exnomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "multiline": true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "exnomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "exnomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "exnomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "exnomineePostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "exnomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeCountry" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            required: true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },

                        {
                            "varName": "exphotoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exphotoIdNumberRegNo" + sl,
                            "type": "text",
                            "label": "Photo Id Number/Reg No",
                            "grid": 6,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },
                        {
                            "varName": "exnomineeDob" + sl,
                            "type": "text",
                            "label": "D.O.B(YYYY-MM-DD)",
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeDob" + sl,
                            "type": "date",
                            "label": "D.O.B",
                            "grid": 6,
                            "onKeyDown": true,
                            "required": true,
                            "conditional": true,
                            "conditionalVarName": "exsearch" + sl,
                            "conditionalVarValue": "INFORMATION",
                        },


                        {
                            "varName": "exnomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineecifIdApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["search" + sl, "nomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                        },
                        {
                            "varName": "exnomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "exnomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "exguardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["exINFORMATION", true],
                        },

                        {
                            "varName": "exnomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "exnomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "multiline": true,
                        },

                        {
                            "varName": "exnomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["INFORMATION", true],
                            "required": true,
                        },
                        {
                            "varName": "exnomineeMinor" + sl,
                            "type": "select",
                            "label": "Nominee Minor",
                            "enum": ["YES", "NO"],
                            "grid": 6,
                            "readOnly": true,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "type": "title",
                            "label": "Guardian Details  " + sl,
                            "grid": 12,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },
                        {
                            "varName": "exnomineeGuardiansName" + sl,
                            "type": "text",
                            "label": "Guardians Name",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.guardianCode,
                            "label": "Guardian Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,

                        },
                        {
                            "varName": "exguardianPhotoId" + sl,
                            "type": "text",
                            "label": "Guardian Photo Id",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                        },

                        {
                            "varName": "exnomineeGuardianAddress" + sl,
                            "type": "text",
                            "label": "Address 1",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                            "required": true,
                        },
                        {
                            "varName": "exnomineeGuardianAddress2" + sl,
                            "type": "text",
                            "label": "Address 2",
                            "validationB": "length",
                            "maxLength": 45,
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "multiline": true,
                        },

                        {
                            "varName": "exnomineeGuardianCityCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.city,
                            "label": "City Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianStateCode" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.stateCode,
                            "label": "State Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianPostalCode" + sl,
                            "type": "text",
                            "label": "Postal Code",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },

                        {
                            "varName": "exnomineeGuardianCountry2" + sl,
                            "type": "autoCompleteValueReturn",
                            "enum": this.state.country,
                            "label": "Country",
                            "grid": 6,
                            "conditionalArray": true,
                            "conditionalArrayList": ["exsearch" + sl, "exnomineeDobApiCall" + sl],
                            "conditionalArrayValue": ["CIF ID", true],
                            "required": true,
                        },
                    )
                }
                this.setState({
                    exObjectForNominee: objectForNominee,
                    getExNominee: true
                });
            }
        } else {
            this.setState(prev => ({
                exObjectForNominee: [],
                getExNominee: false
            }));
        }
    };

    onKeyDownForDedup = (event, data) => {
        let totalNominee = this.state.inputData["numberOfNominee"];
        for (let i = 0; i <= totalNominee; i++) {
            if (data === "nomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["nomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["search" + (i + 1)] = this.state.inputData["search" + (i + 1)];
                        this.state.varValue["nomineecifId" + (i + 1)] = this.state.inputData["nomineecifId" + (i + 1)];
                        this.state.inputData["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["nomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["nomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["nomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["nomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["nomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["nomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["nomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["nomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let someDate = new Date();
                        someDate.setFullYear(someDate.getFullYear() - x);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());
                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["nomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        } else {
                            this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        }
                        this.state.inputData["nomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })
            }
        }
    };

    onKeyDownChangeForNominee = (data) => {
        let totalNominee = parseInt(this.state.inputData["numberOfNominee"]);
        console.log(data.varName, totalNominee)
        for (let i = 0; i <= totalNominee; i++) {
            if (data.varName === "search" + (i + 1)) {
                this.state.inputData["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["nomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["nomineecifId" + (i + 1)] = "";
                this.state.varValue["nomineecifId" + (i + 1)] = "";
                this.state.inputData["nomineeName" + (i + 1)] = "";
                this.state.inputData["nomineeAddress1" + (i + 1)] = "";
                this.state.inputData["nomineeAddress2" + (i + 1)] = "";
                this.state.inputData["nomineeCityCode" + (i + 1)] = "";
                this.state.inputData["nomineeStateCode" + (i + 1)] = "";
                this.state.inputData["nomineePostalCode" + (i + 1)] = "";
                this.state.inputData["nomineeCountry" + (i + 1)] = "";
                this.state.inputData["percentageNominee" + (i + 1)] = "";
                this.state.inputData["nomineeRelationship" + (i + 1)] = "";
                this.state.varValue["percentageNominee" + (i + 1)] = "";
                this.state.varValue["nomineeRelationship" + (i + 1)] = "";
                this.state.inputData["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.varValue["nomineeName" + (i + 1)] = "";
                this.state.varValue["nomineeAddress1" + (i + 1)] = "";
                this.state.varValue["nomineeAddress2" + (i + 1)] = "";
                this.state.varValue["nomineeCityCode" + (i + 1)] = "";
                this.state.varValue["nomineeStateCode" + (i + 1)] = "";
                this.state.varValue["nomineePostalCode" + (i + 1)] = "";
                this.state.varValue["nomineeCountry" + (i + 1)] = "";
                this.state.varValue["nomineeDob" + (i + 1)] = "";
                this.state.inputData["nomineeMinor" + (i + 1)] = "";
                this.state.varValue["nomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "nomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                setTimeout(() => {
                    let x = 18;
                    let someDate = new Date();
                    someDate.setFullYear(someDate.getFullYear() - x);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["nomineeDob" + (i + 1)]);
                    if (stringToDate >= inputDate) {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = false;
                    } else {
                        this.state.inputData["nomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["nomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["nomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["nomineeDobApiCall" + (i + 1)] = true;
                    }
                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })


            }
        }
    };

    onKeyDownForNewNominee = (event, data) => {
        let newNominee = this.state.inputData["numberOfNewNominee"] ? this.state.inputData["numberOfNewNominee"] : 0;
        let totalNominee = parseInt(newNominee) + parseInt(this.state.inputData.numberOfOldNominee);
        for (let i = 0; i <= totalNominee; i++) {
            if (data === "exnomineecifId" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["exnomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["exnomineecifIdApiCall" + (i + 1)] = false;
                let url = backEndServerURL + "/cbsCustomerGet/" + this.state.inputData["exnomineecifId" + (i + 1)];
                axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                    .then((response) => {
                        this.state.varValue["exsearch" + (i + 1)] = this.state.inputData["exsearch" + (i + 1)];
                        this.state.varValue["exnomineecifId" + (i + 1)] = this.state.inputData["exnomineecifId" + (i + 1)];
                        this.state.inputData["exnomineeName" + (i + 1)] = response.data.customerName;
                        this.state.inputData["exnomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.inputData["exnomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.inputData["exnomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.inputData["exnomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.inputData["exnomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.inputData["exnomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.inputData["exnomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["exnomineeDob" + (i + 1)] = response.data.birthDate;
                        this.state.varValue["exnomineeName" + (i + 1)] = response.data.customerName;
                        this.state.varValue["exnomineeAddress1" + (i + 1)] = response.data.mailingAddress1;
                        this.state.varValue["exnomineeAddress2" + (i + 1)] = response.data.nomineeAddress2;
                        this.state.varValue["exnomineeCityCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCityCode, this.state.city);
                        this.state.varValue["exnomineeStateCode" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerStateCode, this.state.stateCode);
                        this.state.varValue["exnomineePostalCode" + (i + 1)] = response.data.mailingCustomerPostcode;
                        this.state.varValue["exnomineeCountry" + (i + 1)] = this.findByVarNameGetKeyValue(response.data.mailingCustomerCountry, this.state.country);
                        this.state.varValue["exnomineeDob" + (i + 1)] = response.data.birthDate;
                        let x = 60;
                        let someDate = new Date();
                        someDate.setFullYear(someDate.getFullYear() - x);
                        let date = someDate.getDate();
                        let month = someDate.getMonth() + 1;
                        let year = someDate.getFullYear();
                        let convertDate = year + "-" + month + "-" + date;
                        let newDate = new Date(convertDate);
                        let stringToDate = new Date(newDate.toDateString());
                        let inputDate = new Date(response.data.birthDate);
                        this.state.inputData["exnomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        this.state.varValue["exnomineeDob" + (i + 1)] = (response.data.birthDate).split("T")[0];
                        if (stringToDate >= inputDate) {
                            this.state.inputData["exnomineeMinor" + (i + 1)] = "YES";
                            this.state.varValue["exnomineeMinor" + (i + 1)] = "YES";
                        } else {
                            this.state.inputData["exnomineeMinor" + (i + 1)] = "NO";
                            this.state.varValue["exnomineeMinor" + (i + 1)] = "NO";
                        }
                        this.state.inputData["exnomineecifIdApiCall" + (i + 1)] = true;
                        this.state.varValue["exnomineecifIdApiCall" + (i + 1)] = true;
                        this.forceUpdate();
                        this.setState({
                            showValue: true,
                            getData: true,
                            loading: false
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            loading: false,
                            errorTittle: "Invalid Introducer ID",
                            errorModalBoolean: true,
                        })
                    })
            }
        }
    };

    onKeyDownChangeForNewNominee = (data) => {
        let newNominee = parseInt(this.state.inputData["numberOfNewNominee"]) ? parseInt(this.state.inputData["numberOfNewNominee"]) : 0;
        let totalNominee = newNominee + parseInt(this.state.inputData["numberOfOldNominee"]);
        for (let i = 0; i <= totalNominee; i++) {
            if (data.varName === "exsearch" + (i + 1)) {
                this.state.inputData["exnomineecifIdApiCall" + (i + 1)] = false;
                this.state.varValue["exnomineecifIdApiCall" + (i + 1)] = false;
                this.state.inputData["exnomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["exnomineeDobApiCall" + (i + 1)] = false;
                this.state.inputData["exnomineecifId" + (i + 1)] = "";
                this.state.varValue["exnomineecifId" + (i + 1)] = "";
                this.state.inputData["exnomineeName" + (i + 1)] = "";
                this.state.inputData["exnomineeAddress1" + (i + 1)] = "";
                this.state.inputData["exnomineeAddress2" + (i + 1)] = "";
                this.state.inputData["exnomineeCityCode" + (i + 1)] = "";
                this.state.inputData["exnomineeStateCode" + (i + 1)] = "";
                this.state.inputData["exnomineePostalCode" + (i + 1)] = "";
                this.state.inputData["exnomineeCountry" + (i + 1)] = "";
                this.state.inputData["expercentageNominee" + (i + 1)] = "";
                this.state.inputData["exnomineeRelationship" + (i + 1)] = "";
                this.state.varValue["expercentageNominee" + (i + 1)] = "";
                this.state.varValue["exnomineeRelationship" + (i + 1)] = "";
                this.state.inputData["exnomineeDob" + (i + 1)] = "";
                this.state.varValue["exnomineeDob" + (i + 1)] = "";
                this.state.varValue["exnomineeName" + (i + 1)] = "";
                this.state.varValue["exnomineeAddress1" + (i + 1)] = "";
                this.state.varValue["exnomineeAddress2" + (i + 1)] = "";
                this.state.varValue["exnomineeCityCode" + (i + 1)] = "";
                this.state.varValue["exnomineeStateCode" + (i + 1)] = "";
                this.state.varValue["exnomineePostalCode" + (i + 1)] = "";
                this.state.varValue["exnomineeCountry" + (i + 1)] = "";
                this.state.varValue["exnomineeDob" + (i + 1)] = "";
                this.state.inputData["exnomineeMinor" + (i + 1)] = "";
                this.state.varValue["exnomineeMinor" + (i + 1)] = "";
                this.forceUpdate();
            }
            if (data.varName === "exnomineeDob" + (i + 1)) {
                this.setState({
                    loading: true
                });
                this.state.inputData["exnomineeDobApiCall" + (i + 1)] = false;
                this.state.varValue["exnomineeDobApiCall" + (i + 1)] = false;
                setTimeout(() => {
                    let x = 18;
                    let someDate = new Date();
                    someDate.setFullYear(someDate.getFullYear() - x);
                    let date = someDate.getDate();
                    let month = someDate.getMonth() + 1;
                    let year = someDate.getFullYear();
                    let convertDate = year + "-" + month + "-" + date;
                    let newDate = new Date(convertDate);
                    let stringToDate = new Date(newDate.toDateString());
                    let inputDate = new Date(this.state.inputData["exnomineeDob" + (i + 1)]);
                    console.log(inputDate)
                    console.log(stringToDate)
                    if (stringToDate >= inputDate) {
                        this.state.inputData["exnomineeMinor" + (i + 1)] = "NO";
                        this.state.varValue["exnomineeMinor" + (i + 1)] = "NO";
                        this.state.inputData["exnomineeDobApiCall" + (i + 1)] = false;
                        this.state.varValue["exnomineeDobApiCall" + (i + 1)] = false;
                    } else {
                        this.state.inputData["exnomineeMinor" + (i + 1)] = "YES";
                        this.state.varValue["exnomineeMinor" + (i + 1)] = "YES";
                        this.state.inputData["exnomineeDobApiCall" + (i + 1)] = true;
                        this.state.varValue["exnomineeDobApiCall" + (i + 1)] = true;
                    }
                    this.forceUpdate()
                }, 1000);
                this.setState({
                    showValue: true,
                    getData: true,
                    loading: false
                })
            }
        }
    };

    findByVarNameGetKeyValue = (value, getKeyValue) => {
        let jsonArray = getKeyValue;
        for (let i = 0; i < jsonArray.length; i++) {
            let jsonObject = jsonArray[i];
            if (value === jsonObject.key) {
                return jsonObject;
            }
        }
    };

    renderNomineeForm = () => {
        if ((this.state.inputData.subCategory === "S. Tax Reversal" && this.state.getNominee) || (this.state.inputData.subCategory === "FD Nominee Change" && this.state.getNominee) || (this.state.inputData.subCategory === "Tenor or Scheme Change" && this.state.objectForNominee.length > 0 && this.state.getNominee)) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.objectForNominee, this.updateComponent, this.onKeyDownForDedup, this.onKeyDownChangeForNominee)
            )
        }
    };

    renderExistingNomineeForm = () => {
        if (this.state.existingObjectForNominee.length > 0 && this.state.getExistingNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.existingObjectForNominee, this.updateComponent, this.onKeyDownForNewNominee, this.onKeyDownChangeForNewNominee)
            )
        }
    };

    renderAddNewNomineeForm = () => {
        if (this.state.exObjectForNominee.length > 0 && this.state.getExNominee) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, this.state.exObjectForNominee, this.updateComponent, this.onKeyDownForNewNominee, this.onKeyDownChangeForNewNominee)
            )
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };

    checkListClose = () => {
        this.setState({
            checkList: false,
        })
    };

    openCheckList = () => {
        this.setState({
            checkList: true
        })
    };

    viewCheckList = () => {
        if (this.state.getData && this.props.freeFlag2 !== "FDR Encashment") {
            return (
                <div>
                    <br/>
                    <button
                        className="btn btn-outline-primary btn-sm"
                        style={{
                            verticalAlign: 'right',
                        }}
                        type='button' value='add more'
                        onClick={this.openCheckList}
                    >View Check List
                    </button>
                </div>
            )
        }
    };

    renderMultiTaskLink = () => {
        if (this.state.inputData.subCategory === "Dupl. Advice (Special)") {
            return (
                <>
                    <br/>
                    <button className="btn btn-outline-primary btn-sm">
                        <a
                            className="anchorButton"
                            target="_blank"
                            href="http://citymultitask.thecitybank.com:1010/LogIn.aspx">
                            Multitask Link</a>
                    </button>
                </>
            )
        }
    };

    renderAllDialogue = () => {
        const {classes} = this.props;
        return (
            <>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.cbNotFound}>
                    <DialogContent className={classes.dialogPaper}>
                        <CBNotFound
                            closeModal={this.closeModalCBNotFound}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.checkList}>
                    <DialogContent>
                        <CheckList closeModal={this.checkListClose} inputData={this.state.inputData}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.confirmationAlert}>
                    <DialogContent className={classes.dialogPaper}>
                        <ConfirmationModal
                            closeModal={this.closeConfirmation}
                            title={this.state.title}
                        />
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="xl"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.accountStatement}>
                    <DialogContent className={classes.dialogPaper}>
                        <AccountStatement closeModal={this.closeStatement}
                                          accountNumber={this.state.inputData.debitAccountNumber}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    open={this.state.confirmation}>
                    <DialogContent>
                        <ConfirmationModal closeModal={this.confirmationClose}
                                           title={this.state.title}/>
                    </DialogContent>
                </Dialog>
            </>

        )
    };

    render() {
        Functions.redirectToLogin(this.state);
        return (
            <GridList cellHeight={window.innerHeight} cols={1}>
                <div>
                    <Card>
                        <CardBody>
                            {this.renderAllDialogue()}
                            <Grid container spacing={0}>
                                <ThemeProvider theme={theme}>
                                    {this.renderNotification()}
                                    {this.renderJsonFormFirst()}
                                </ThemeProvider>
                            </Grid>
                            {this.renderDefferalData()}
                            <ThemeProvider theme={theme}>
                                {this.renderFileUpload()}
                                {this.renderRemarksData()}
                                <br/>
                                {this.viewAttachedImages()}
                                <Grid item xs={12}>
                                    {this.renderMultiTaskLink()}
                                </Grid>
                                {this.viewCheckList()}
                                {this.renderRemarks()}
                            </ThemeProvider>
                            {this.renderButton()}
                        </CardBody>
                    </Card>
                </div>
            </GridList>
        )
    }
}

export default withStyles(styles)(FdrMakerApproval);


