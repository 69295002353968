import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import {Menu} from 'semantic-ui-react';
import 'semantic-ui-offline/semantic.min.css';
import {commonInboxTabActive, commonInboxTabInactive} from "../configuration";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import FailedListModal from "../WelcomeLetter/FailedListModal";
import TableComponent from "../../workflow/CommonComponent/TableComponent";
import loader from "../../../Static/loader.gif";
import Forward from "../Forward";


let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "From Date",
        grid: 2,
    },
    {
        varName: "toDate",
        type: "date",
        label: "To Date",
        grid: 2,
    },
    {
        varName: "caseId",
        type: "text",
        validation: "numeric",
        label: "Case ID",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        validation: "numeric",
        label: "Client ID",
        grid: 2,
    },
];

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class OpsdTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDate: 0,
            appData: "",
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            getData: true,
            inputData: {},
            fileUploadData: {},
            responseData: [],
            activeItem: "All",
            backgroundColor: "red",
            showValue: false,
            uploadModal: false,
            merchantModal: false,
            caseId: "",
            title: "",
            mailModal: false,
            selectedRowIdArray: [],
            dataPassArray: [],
            buttonName: "Claim",
            notificationMessage: "",
            alert: false,
            showData: true,
            requestType: "",
            pageNumber: 0,
            totalRow: 0,
            searchItem: "",
            rowsPerPage: 100,
            autoComplete: {},
            selectedCount: 0,
            failedModal: false,
            failedList: [],
            forwardArray: [],
            forwardModal: false,
            loader: true,
            allData: [],
            allColumns: [
                {title: "Case ID", field: "caseId"},
                {title: "Card Type", field: "cardType"},
                {title: "Branch Name", field: "branchName"},
                {title: "Quantity", field: "quantity"},
                {title: "Client Id", field: "clientId"},
                {title: "Card Number", field: "maskedCardNumber"},
                {title: "Status", field: "status"},
                {title: "Batch No", field: "batchNo"},
                {title: "SOL Id", field: "solId"},
                {title: "Tranzware Completed Date", field: "tranzwareCompletedDate"},
                {title: "Dispatch Date", field: "sendToCourierDate"},
                {title: "Courier Name", field: "courierName"},
                {title: "Branch Receiver ID", field: "branchReceiverId"},
                {title: "Receiver Name", field: "receiverName"},
                {title: "Receiver Date & Time", field: "receiverDateAndTime"},
                {title: "Deliver User ID", field: "deliverUserId"},
                {title: "Name", field: "deliverUserName"},
                {title: "Date & Time", field: "deliverDateAndTime"},
                {title: "Date", field: "date"}
            ],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.requestType !== this.props.match.params.requestType) {
            this.setState({
                loader: true
            });
            setTimeout(() => {
                this.setState({
                    requestType: this.props.match.params.requestType === "gift" ? 'Gift Card' : "Reloadable Prepaid Card",
                    allData: [],
                    loader: true,
                    activeItem: "All",
                    pageNumber: 0,
                    totalRow: 0,
                    searchItem: "",
                    rowsPerPage: 100,
                    err: false,
                    errorArray: {},
                    errorMessages: {},
                    selectedDate: {},
                    varValue: {},
                    inputData: {},
                });
                this.forceUpdate();
                this.fetchDataFromAPI("All", undefined, 0, false)
            }, 1000)
        }
    }

    headerSelect = (event, value) => {
        event.preventDefault();
        this.setState({
            activeItem: value,
            pageNumber: 0,
            loader: true
        });
        this.fetchDataFromAPI(value, undefined, 0, false)
    };

    fetchDataFromAPI = (status, url, pageNumber, bool) => {
        let page = 0;
        if (bool !== undefined) {
            page = pageNumber;
        } else {
            page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        }
        if (this.state.searchItem !== "") {
            this.searchData(this, status, page)
        } else {
            this.setState({
                loader: true
            });

            let urlSegment;
            let inbox_url;
            if (url === undefined) {
                if (status === "All") {
                    urlSegment = "getAllRequestBranchWise/" + this.state.requestType;
                } else if (status === "Forward") {
                    urlSegment = "branchWiseForwardedList/" + this.state.requestType;
                } else {
                    urlSegment = "getBranchWiseRequisition/" + this.state.requestType;
                }
            } else {
                urlSegment = url;
            }

            if (status === 'All' || status === "Forward") {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + page;
            } else {
                inbox_url = backEndServerURL + "/" + urlSegment + "/" + status + "/" + page;
            }
            axios
                .get(inbox_url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    let array = [];
                    let rowData;
                    for (let i = 0; i < response.data.content.length; i++) {
                        let res = response.data.content[i];
                        rowData = {
                            id: res.id,
                            caseId: res.caseId,
                            cardType: res.cardType,
                            branchName: res.requestedBranchName,
                            quantity: (res.status === "Tranzware Completed" || res.status === "Send to Courier" || status === "Deliver to Branch" || status === "Branch Received") ? 1 : res.currentQuantity,
                            status: res.status,
                            solId: res.requestedBranchSolId,
                            batchNo: res.batchId,
                            courierName: res.vendorName,
                            clientId: res.clientId,
                            maskedCardNumber: res.maskedCardNumber,
                            date: this.dateConverter(res.bomApprovalDate),
                            tranzwareCompletedDate: this.dateConverter(res.tranzwareCompletedDate),
                            sendToCourierDate: this.dateConverter(res.sendToCourierDate),
                        };
                        array.push(rowData);
                    }

                    this.setState({
                        allData: array,
                        rowsPerPage: response.data.numberOfElements,
                        pageNumber: response.data.number,
                        totalRow: response.data.totalElements,
                        loader: false
                    });
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false
                    })
                });
        }
    };

    handleChangePage = (pageNumber, status, bool) => {
        this.fetchDataFromAPI(status, undefined, pageNumber, false)
    };

    dateConverter = (value) => {
        return value ? value.substr(0, 10) : "";
    };


    componentDidMount() {
        let requestType = this.props.match.params.requestType === "gift" ? 'Gift Card' : "Reloadable Prepaid Card";
        this.setState({
            requestType: requestType,
            loader: false
        });
        this.fetchDataFromAPI("All", "getAllRequestBranchWise/" + requestType, 0, false)
    }

    updateComponent = () => {
        this.forceUpdate();
    };

    open = data => {
        this.setState({
            dataPassArray: data,
            merchantModal: true
        });
    };

    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    searchData = (e, status, page) => {
        let postData = this.removeNullValue(this.state.inputData);
        if (Object.keys(postData).length === 0) {
            return 0;
        }
        this.setState({
            loader: true,
            activeItem: status,
        });
        let inbox_url = backEndServerURL + "/filterGiftCardForBranch/" + this.state.requestType + "/" + status + "/0";
        axios.post(inbox_url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then(response => {
                let array = [];
                let rowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    let res = response.data.content[i];
                    rowData = {
                        id: res.id,
                        caseId: res.caseId,
                        cardType: res.cardType,
                        branchName: res.requestedBranchName,
                        quantity: (res.status === "Tranzware Completed" || res.status === "Send to Courier" || status === "Deliver to Branch" || status === "Branch Received") ? 1 : res.currentQuantity,
                        status: res.status,
                        solId: res.requestedBranchSolId,
                        batchNo: res.batchId,
                        courierName: res.vendorName,
                        clientId: res.clientId,
                        maskedCardNumber: res.maskedCardNumber,
                        date: this.dateConverter(res.bomApprovalDate),
                        tranzwareCompletedDate: this.dateConverter(res.tranzwareCompletedDate),
                        sendToCourierDate: this.dateConverter(res.sendToCourierDate),
                    };
                    array.push(rowData);
                }

                this.setState({
                    allData: array,
                    inputData: {...postData},
                    varValue: {...postData},
                    showValue: true,
                    loader: false,
                    loading: false,
                    rowsPerPage: response.data.numberOfElements,
                    pageNumber: response.data.number,
                    totalRow: response.data.totalElements,
                    searchItem: status,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    closeFailedModal = () => {
        this.setState({
            failedModal: false
        })
    };

    resetHandler = () => {
        window.location.reload();
    };

    renderMenuItem = (name, status) => {
        return (
            <Menu.Item
                name={name}
                active={this.state.activeItem === status}
                style={this.state.activeItem === status ? commonInboxTabActive : commonInboxTabInactive}
                onClick={e => {
                    this.headerSelect(e, status);
                }}
            >
            </Menu.Item>
        )
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >
                            Search {this.state.requestType}
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <Grid container>
                            <ThemeProvider theme={theme}>
                                {CommonJsonFormComponent.renderJsonForm(
                                    this.state,
                                    SearchForm,
                                    this.updateComponent
                                )}
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={e => {
                                        this.searchData(e, 'All', 0);
                                    }}
                                >
                                    Search
                                </button>
                                &nbsp;
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{
                                        height: 30,
                                        marginTop: 15,
                                    }}
                                    onClick={this.resetHandler}
                                >
                                    Reset
                                </button>
                            </ThemeProvider>
                        </Grid>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                        <h4 style={{color: "white"}} >Deliverable Management ({this.state.requestType}) </h4>
                    </CardHeader>
                    <CardBody>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.failedModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <FailedListModal titleHeader="Failed List"
                                                 closeModal={this.closeFailedModal}
                                                 failedList={this.state.failedList}/>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            fullWidth="true"
                            maxWidth="md"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.forwardModal}>
                            <DialogContent className={classes.dialogPaper}>
                                <Forward closeModal={this.closeForwardModal}/>
                            </DialogContent>
                        </Dialog>
                        <div className="deliverable">
                            <React.Fragment>
                                <div>
                                    <Menu pointing>
                                        {this.renderMenuItem("All", "All")}
                                        {this.renderMenuItem("Deliver to Branch", "Deliver to Branch")}
                                        {this.renderMenuItem("Branch Received", "Branch Received")}
                                        {/*{this.renderMenuItem("Forward", "Forward")}*/}
                                        {this.renderMenuItem("Sold", "Sold")}
                                        {this.renderMenuItem("New Requisition", "New Requisition")}
                                    </Menu>
                                    {this.tableChoice()}
                                </div>
                            </React.Fragment>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

    renderUrl = () => {
        this.handleChangePage('-5', this.state.activeItem, true)
    };


    renderTopLabelButton = () => {
        console.log(this.state.activeItem);
        if (this.state.activeItem === "Deliver to Branch") {
            return [
                {
                    name: "Load All Data",
                    triggerFunction: this.renderUrl
                },
                {
                    name: "Branch Received",
                    triggerFunction: this.triggerActionButtonByStatus
                },
            ]
        } else if (this.state.activeItem === "Deliver to Branch") {
            return [
                {
                    name: "Load All Data",
                    triggerFunction: this.renderUrl
                },
                {
                    name: "Summary Report",
                    triggerFunction: this.triggerActionButtonByStatus
                },
            ]
        }
            // else if (this.state.activeItem === "Branch Received") {
            //     return [
            //         {
            //             name: "Load All Data",
            //             triggerFunction: this.renderUrl
            //         },
            //         {
            //             name: "Forward",
            //             triggerFunction: this.branchReceivedToForward
            //         },
            //     ]
        // }
        else {
            return [{
                name: "Load All Data",
                triggerFunction: this.renderUrl
            }]
        }
    };

    branchReceivedToForward = (event, data) => {
        if (this.state.activeItem === "Branch Received") {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    forwardArray: rowIdArray,
                    forwardModal: true
                })
            }
        }
    };

    closeForwardModal = (value) => {
        console.log(value);
        if (value !== undefined) {
            this.setState({
                forwardModal: false,
                loader: true
            });
            let single_url = backEndServerURL + "/setBranchForwardingRequest";
            let postData = {
                forwardTo: value.branchName.key,
                idList: this.state.forwardArray
            };
            axios.post(single_url, postData, {
                headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
            }).then(response => {
                this.fetchDataFromAPI('Branch Received')
            }).catch(error => {
                console.log(error);
                this.setState({
                    loader: false
                })
            });
        } else {
            this.setState({
                forwardModal: false,
            });
        }
    };


    triggerActionButtonByStatus = (event, data) => {
        if (this.state.activeItem === "Deliver to Branch") {
            let rowIdArray = [];
            for (let item of data) {
                rowIdArray.push(item.id)
            }
            if (rowIdArray.length > 0) {
                this.setState({
                    loader: true
                });
                let single_url = backEndServerURL + "/updateGiftCardInventoryStatusById/" + this.state.requestType + "/Branch Received";
                axios.post(single_url, rowIdArray, {
                    headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}
                }).then(response => {
                    this.fetchDataFromAPI('Deliver to Branch')
                }).catch(error => {
                    console.log(error);
                    this.setState({
                        loader: false
                    })
                });

            }
        }
    };


    tableChoice() {
        const {classes} = this.props;
        if (this.state.loader) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loader}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <TableComponent tableData={this.state.allData}
                                tableColumns={this.state.allColumns}
                                loader={this.state.loading} totalRow={this.state.totalRow} page={this.state.pageNumber}
                                whiteSpace={"wrap"} selection={true} handleChangePage={this.handleChangePage}
                                rowsPerPage={this.state.rowsPerPage} tableButton={this.renderTopLabelButton()}
                />
            )
        }
    }
}

export default withStyles(styles)(OpsdTracker);
