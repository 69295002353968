import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from '../../../Common/Functions';
import Notification from "../../NotificationMessage/Notification";
import Table from "../../Table/Table";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Grid from "@material-ui/core/Grid/index";
import {ThemeProvider} from "@material-ui/styles/index";
import theme from "../../JsonForm/CustomeTheme";
import {Dialog} from "@material-ui/core/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import GridList from "@material-ui/core/GridList/index";
import loader from "../../../Static/loader.gif";
import FileMappingReadOnly from "../CommonComponent/FileMappingReadOnly";
import MyValidation from "../../JsonForm/MyValidation";
import WaiverList from "../CommonComponent/WaiverList";
import DeferalList from "../CommonComponent/DeferalList";
import ConfirmAlert from "../CommonComponent/ConfirmAlert";
import DedupResultFunction from "../../DedupResultFunction";
import {CBDataJsonForm} from "../WorkflowJsonForm4";
import MandateAndBeneficiaryResult from "../../MandateAndBeneficiaryResult";
import SdnResultFunction from "../../SdnResultFunction";
import ErrorModal from "../CommonComponent/ErrorModal";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import FlowSummeryButton from "../CommonComponent/FlowSummeryButton";
import AccountStatement from "../fdr/AccountStatement";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material/react-button";
import GridItem from "../../Grid/GridItem.jsx";
import GridContainer from "../../Grid/GridContainer.jsx";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import FullDedupeResult from "../../FullDedupeResult";
import FullSdnDedupeResult from "../../FullSdnDedupeResult";
import FullMandateAndBeneficiaryResult from "../../FullMandateAndBeneficiaryResult";
import CustomerDedupSearch from "../../CustomerDedupSearch";
import InputDTO from "../DedupInput/InputDTO";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    }

};
let bomRemarks = [
    {
        "varName": "bomRemarks",
        "type": "textArea",
        "label": "BOM Remarks",
        "grid": 12
    }]
;

class SdnAndDedupeResultShowing extends React.Component {
    state = {

        message: "",
        appData: {},
        getData: false,
        varValue: [],
        redirectLogin: false,
        title: "",
        notificationMessage: "",
        selectedDate: {},
        alert: false,
        getDeferalList: [],
        inputData: {},
        getImageLink: [],
        getImageBoolean: false,
        imageModalBoolean: false,
        selectImage: "",
        err: false,
        errorArray: {},
        errorMessages: {},
        getRemarks: [],
        getMappingAllImage: false,
        loading: true,
        confirmAlert: false,
        actionType: "",
        errorModalBoolean: false,
        errorTittle: "System Error!",
        customerName: "",
        accountStatement: false
    };
    handleChange = (event) => {
        event.target.name = event.target.value;

    };

    updateComponent = () => {
        this.forceUpdate();
    };


    componentDidMount() {
        this.setState({
            loading: false,
            getData: true,
        })
    }

    closeModal = () => {
        this.setState({
            getMappingAllImage: false,
            errorModalBoolean: false

        })
    };

    renderDedupComponent = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData/* && (this.props.subServiceType !== "INDIVIDUAL" && this.props.subServiceType !== "Individual A/C")*/) {
            return (
                <div style={{marginTop: "15px"}}>
                    <FullDedupeResult merchantDedup={this.props.merchantDedup}   manualDedupeSHow={false} radioButton="NO" category={this.props.category} taggingForm={CBDataJsonForm}
                                         removingTaggingData={this.removingTaggingData}
                                         getTaggingData={this.getTaggingData}
                                         subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };

    renderDedupComponentMandateAndBeneficiary = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <FullMandateAndBeneficiaryResult radioButton="NO" category={this.props.category}
                                                 taggingForm={CBDataJsonForm}
                                                 removingTaggingData={this.removingTaggingData}
                                                 getTaggingData={this.getTaggingData}
                                                 subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };
    renderSdnComponent = () => {
        if (this.props.serviceType === "internet_banking" || (this.props.serviceType === "adc_service" && this.props.freeFlag3 === "city_touch")) {

        } else if (this.state.getData) {
            return (
                <div style={{marginTop: "15px"}}>
                    <FullSdnDedupeResult category={this.props.category} taggingForm={CBDataJsonForm}
                                       removingTaggingData={this.removingTaggingData}
                                       getTaggingData={this.getTaggingData}
                                       subServiceType={this.props.subServiceType} appId={this.props.appId}/>
                </div>

            )
        }
    };


    closeStatement = () => {
        this.setState({
            accountStatement: false
        })
    };
    handleClose = (event) => {
        event.preventDefault();
        this.props.closeModal()
    }

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                    <Dialog
                        fullWidth="true"
                        maxWidth="sm"
                        className={classes.modal}
                        classes={{paper: classes.dialogPaper}}
                        open={this.state.loading}>
                        <DialogContent className={classes.dialogPaper}>

                            <center>
                                <img src={loader} alt=""/>
                            </center>
                        </DialogContent>
                    </Dialog>


                    <Grid item xs={12}>

                        {this.renderDedupComponent()}
                        {this.renderDedupComponentMandateAndBeneficiary()}
                        {this.renderSdnComponent()}
                    </Grid>
                    <center>
                        <button
                            className="btn btn-outline-primary  btn-sm"
                            style={{}}
                            onClick={(event) => this.handleClose(event)}

                        >
                            Close
                        </button>
                    </center>
                </GridItem>
            </GridContainer>


        )
    }

}

export default withStyles(styles)(SdnAndDedupeResultShowing);
