import React, {Component} from "react";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme2";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Card from "../../Card/Card.jsx";
import CardHeader from "../../Card/CardHeader.jsx";
import CardBody from "../../Card/CardBody.jsx";
import axios from "axios";
import {backEndServerURL} from "../../../Common/Constant";
import 'semantic-ui-offline/semantic.min.css';
import MaterialTable from "material-table";
import {createMuiTheme} from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import TablePagination from "@material-ui/core/TablePagination";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../../../Static/loader.gif";
import withStyles from "@material-ui/core/styles/withStyles";
import {buttonStyle, localization, options, tableIcons,} from "../../DeliverableManagement/configuration";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import SubmittedCaseHistory from "../CommonComponent/SubmittedCaseHistory";
import CardMaintenanceRequest from "../CardCallCategory/CardMaintenanceRequest";
import Button from "@material/react-button";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

let themeO = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '10px 5px',
                fontSize: '0.8rem'
            }
        },

    }
});

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};


let SearchForm = [
    {
        varName: "fromDate",
        type: "date",
        label: "Start Date",
        grid: 2,
    },

    {
        varName: "toDate",
        type: "date",
        label: "End Date",
        grid: 2,
    },
    {
        varName: "caseId",
        type: "text",
        label: "Case ID",
        validation: "numeric",
        grid: 2,
    },
    {
        varName: "clientId",
        type: "text",
        validation: "numeric",
        label: "Client ID",
        grid: 2,
    },
    {
        varName: "cardNumber",
        type: "text",
        // validation: "numeric",
        label: "Card Number",
        grid: 2,
    },
    {
        varName: "mobileNumber",
        type: "text",
        validation: "phone",
        label: "Mobile Number",
        grid: 2,
    },
    {
        varName: "status",
        type: "select",
        enum: [
            "Open",
            "Locked",
            "Solved",
            "Cancelled",
            "In progress",
            "Return"
        ],
        validation: "sting",
        label: "Status",
        grid: 2,
    },
    {
        varName: "customerCategory",
        type: "select",
        "onKeyDown": true,
        enum: [
            "Account",
            "AMEX",
            "VISA/Master",
            "Others"
        ],
        label: "CustomerCategory",
        grid: 2,
    },
    {
        varName: "callCategory",
        type: "autoComplete",
        enum: [],
        label: "Call Category",
        grid: 2,
    },
];


class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            err: false,
            errorArray: {},
            errorMessages: {},
            selectedDate: {},
            varValue: {},
            inputData: {},
            autoComplete: {},
            dataPassArray: [],
            allData: [],
            pageNumber: 0,
            totalRow: 0,
            loading: false,
            rowsPerPage: 100,
            showValue: false,
            alert: false,
            history: false,
            appId: 0,
            category: "",
            subCategory: "",
            subServiceType: "",
            sCategory: "",
            accountNumber: "",
            cbNumber: "",
            customerName: "",
            caseId: "",
            solId: "",
            title: "",
            notificationMessage: "",
            columns: [
                {title: 'SL', field: 'sl',},
                {title: 'Case ID', field: 'caseId',},
                {title: 'Client ID', field: 'clientId',},
                {title: 'Mobile Number', field: 'mobileNumber',},
                {title: 'Card Number', field: 'cardNumber',},
                {title: 'Customer Category', field: 'customerCategory',},
                {title: 'Call Category', field: 'callCategory'},
                {title: 'SLA Time', field: 'slaTime'},
                {title: 'SLA Violation Status', field: 'slaViolated'},
                {title: 'Created BY', field: 'createdBy'},
                {title: 'Assign To', field: 'assignTo'},
                {title: 'Date', field: 'date'},
                {title: 'Modified Date', field: 'modifiedDate'},
                {title: 'Priority', field: 'priority'},
                {title: 'Status', field: 'status'},
            ],
        };
    }


    dateConverter = (value) => {

        return value.substr(0, 10);
    };

    componentDidMount() {

    }

    updateComponent = () => {
        this.forceUpdate();
    };


    removeNullValue = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === null || clone[prop] === undefined)
                delete clone[prop];
        return clone;
    };

    renderInboxStatus = (delStatus, inboxStatus, freeFlag4) => {
        let userId = LocalstorageEncrypt.encryptStorageFunction.getItem('username');
        let forwardStatus = "";
        let forwardedBy = "";
        if (freeFlag4) {
            forwardStatus = freeFlag4.split(" ")[0];
            forwardedBy = freeFlag4.split(" ")[1];
        }
        if (forwardStatus === "Forwarded" && userId === forwardedBy) {
            return "FORWARDED"
        }
        if (delStatus === "OPEN" || delStatus === "Locked") {
            return delStatus
        } else if (delStatus === "SAVED") {
            return "In Progress"
        } else if (delStatus === "CLOSED") {
            return inboxStatus
        } else {
            return inboxStatus
        }
    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    fetchDataFromAPI = (event, pageNumber) => {
        let page = Math.sign(pageNumber) !== 1 ? 0 : pageNumber;
        event.preventDefault();
        let input = this.removeNullValue(this.state.inputData);
        if (Object.keys(input).length === 0) {
            return 0;
        }
        let error = MyValidation.defaultValidation(SearchForm, this.state);
        this.forceUpdate();
        if (error === true) {
            return 0;
        } else {
            this.setState({
                loading: true,
                pageNumber: page,
            });
            console.log(input);
            let searchUrl = backEndServerURL + "/filterAppDelegationTable/" + page;
            axios.post(searchUrl, input, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then(response => {
                    if (response.status === 200) {
                        let array = [];
                        let responseArray = [];
                        let rowData;
                        let responseRowData;
                        for (let i = 0; i < response.data.content.length; i++) {
                            responseRowData = response.data.content[i].appId;
                            rowData = {
                                sl: i + 1,
                                initiatedBy: response.data.content[i].initiatedBy,
                                appInitTime: response.data.content[i].appInitTime,
                                currentUserName: response.data.content[i].currentUserName,
                                delInitTime: response.data.content[i].delInitTime,
                                delStatus: response.data.content[i].delStatus,
                                accountNumber: response.data.content[i].accountNumber,
                                cbNumber: response.data.content[i].cbNumber,
                                customerName: response.data.content[i].customerName,
                                id: response.data.content[i].id,
                                solId: response.data.content[i].solId,
                                appId: response.data.content[i].appId,
                                slaTime: response.data.content[i].slaViolation,
                                slaViolated: response.data.content[i].slaViolated,
                                caseId: response.data.content[i].caseId,
                                clientId: response.data.content[i].clientId,
                                mobileNumber: response.data.content[i].mobileNumber,
                                cardNumber: response.data.content[i].cardNumber,
                                customerCategory: response.data.content[i].customerCategory,
                                callCategory: response.data.content[i].callCategory,
                                subServiceType: response.data.content[i].subServiceType,
                                sCategory: response.data.content[i].subCategory,
                                taskTitle: response.data.content[i].taskTitle,
                                createdBy: response.data.content[i].initiatedBy,
                                assignTo: response.data.content[i].currentUserName === "Despatch" ? "X" : response.data.content[i].currentUserName,
                                status: (LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') === "CARD_DIVISION"
                                        || LocalstorageEncrypt.encryptStorageFunction.getItem('workflowGroup') === "CARD_DIVISION_LINE_MANAGER") ?
                                            this.renderInboxStatus(response.data.content[i].delStatus, response.data.content[i].delInboxStatus, response.data.content[i].freeFlag4) :
                                            response.data.content[i].delInboxStatus,
                                priority: response.data.content[i].priority,
                                date: this.dateConverter(response.data.content[i].appInitTime),
                                modifiedDate: this.dateConverter(response.data.content[i].delInitTime),
                            };
                            array.push(rowData);
                            responseArray.push(responseRowData);
                        }

                        this.setState({
                            allData: array,
                            responseData: responseArray,
                            inputData: {...input},
                            varValue: {...input},
                            showValue: true,
                            loading: false,
                            pageNumber: this.state.pageNumber,
                            totalRow: response.data.totalElements,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        inputData: {...input},
                        varValue: {...input},
                        showValue: true,
                        loading: false
                    })
                });
        }
    };

    caseHistory = (e, data) => {
        e.preventDefault();
        console.log(data);
        this.setState({
            appId: data.appId,
            category: data.customerCategory,
            subCategory: data.callCategory,
            subServiceType: data.subServiceType,
            sCategory: data.sCategory,
            accountNumber: data.accountNumber,
            cbNumber: data.cbNumber,
            caseId: data.caseId,
            solId: data.solId,
            customerName: data.customerName,
            history: true,
            dataPassArray: data
        });
    };

    closeHistoryModal = () => {
        this.setState({
            history: false,
            viewModal: false,
        })
    };

    onKeyDownChange = (data) => {
        if (data.varName === "customerCategory" && this.state.inputData.customerCategory) {
            let cCategory = this.state.inputData.customerCategory === "VISA/Master" ? "visaMaster" : this.state.inputData.customerCategory === "Others" ? "other" : this.state.inputData.customerCategory.toLowerCase();
            let url = backEndServerURL + "/getCallCategory/" + cCategory;
            let autoComplete = {};
            axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
                .then((response) => {
                    console.log(response);
                    if (response.status === 200) {
                        autoComplete.callCategory = response.data;
                        this.setState({
                            autoComplete: autoComplete,
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                });

        }
    };

    resetHandler = () => {
        window.location.reload();
    };

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return (
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
            )
        } else {
            return (
                <div>
                    <Card>
                        <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                            <h4 style={{color: "white"}} >
                                Search Card Maintenance
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <Grid container>
                                <ThemeProvider theme={theme}>
                                    {CommonJsonFormComponent.renderJsonForm(
                                        this.state,
                                        SearchForm,
                                        this.updateComponent,
                                        "",
                                        this.onKeyDownChange
                                    )}
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={e => {
                                            this.fetchDataFromAPI(e, '0')
                                        }}
                                    >
                                        Search
                                    </button>
                                    &nbsp;
                                    <button
                                        className="btn btn-outline-primary btn-sm"
                                        style={{
                                            height: 30,
                                            marginTop: 20,
                                        }}
                                        onClick={this.resetHandler}
                                    >
                                        Reset
                                    </button>
                                </ThemeProvider>
                            </Grid>
                            <br/>
                            <div>
                                <MuiThemeProvider theme={themeO}>
                                    <div style={{maxWidth: "100%"}}>
                                        <MaterialTable
                                            style={{whiteSpace: "nowrap"}}
                                            columns={this.state.columns}
                                            data={this.state.allData}
                                            title={" "}
                                            options={{...options, selection: false}}
                                            localization={localization}
                                            icons={tableIcons}
                                            onRowClick={(e, rowData) => {
                                                this.caseHistory(e, rowData)
                                            }}
                                            components={{
                                                Pagination: props => (
                                                    <TablePagination
                                                        {...props}
                                                        rowsPerPageOptions={[this.state.rowsPerPage]}
                                                        count={this.state.totalRow}
                                                        page={this.state.pageNumber}
                                                        onChangePage={(e, page) => {
                                                            this.fetchDataFromAPI(e, page)
                                                        }
                                                        }
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </MuiThemeProvider>
                            </div>
                            <Dialog
                                fullWidth="true"
                                maxWidth="md"
                                className={classes.modal}
                                classes={{paper: classes.dialogPaper}}
                                open={this.state.history}>
                                <DialogContent className={classes.dialogPaper}>
                                    <SubmittedCaseHistory appId={this.state.appId}
                                                          cardMaintenance={true}
                                                          dataPassArray={this.state.dataPassArray}
                                                          category={this.state.category}
                                                          subCategory={this.state.subCategory}
                                                          subServiceType={this.state.subServiceType}
                                                          sCategory={this.state.sCategory}
                                                          caseId={this.state.caseId}
                                                          solId={this.state.solId}
                                                          cbNumber={this.state.cbNumber}
                                                          accountNumber={this.state.accountNumber}
                                                          customerName={this.state.customerName}
                                                          closeIcon={this.closeHistoryModal}
                                                          closeModal={this.closeHistoryModal}
                                                          statusFlag={true}  />
                                </DialogContent>
                            </Dialog>
                        </CardBody>
                    </Card>
                </div>
            );
        }

    }

}

export default withStyles(styles)(Search);
