import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios/index";
import Functions from "../../../Common/Functions";
import React, {Component} from "react";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import withStyles from "@material-ui/core/styles/withStyles";
import theme from "../../JsonForm/CustomeTheme";
import Table from "../../Table/Table";
import Typography from "@material-ui/core/Typography/index";
import Box from "@material-ui/core/Box/index";
import PropTypes from "prop-types";
import CheckIcon from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon/index';
import Notification from "../../NotificationMessage/Notification";

let uploadDeferalForm = [
    {
        "varName": "type",
        "type": "text",
        "label": "Deferal Type",


    },

    {
        "varName": "dueDate",
        "type": "text",
        "label": "Expire Date",


    },
    {
        "varName": "remarks",
        "type": "text",
        "label": "Remarks",


    },
    {
        "varName": "approvedBy",
        "type": "text",
        "label": "Approved By",
        "required": true,


    },
    {
        "varName": "status",
        "type": "text",
        "label": "Status",


    }
];

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginBottom: "3px",
            textDecoration: "none",
            "& small": {
                color: "#142398",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        },
        modal: {
            top: `${10}%`,
            maxWidth: `${80}%`,
            maxHeight: `${100}%`,
            margin: 'auto'

        },
        dialogPaper: {
            overflow: "visible"
        },
        Tab: {
            flexDirection: "row-reverse"
        },
        input: {
            display: 'none',
        },
        button: {
            margin: theme.spacing(1),
        },

    }
};

class UploadDeferal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableData: [],
            name: '',
            errorMessage: " ",
            datas: [],
            title: "",
            messgae: "Successfull!!",
            notificationFlag: false,
            type: "",
            alert: false,
            redirectLogin: false,
            file: {},
            deferralId: '',
            appData: '',
            showValue: '',
            dueDate: '',
            remarks: '',
            approvedBy: '',


        }
    }

    createTableData = (id, type, dueDate, remarks, approvedBy, status,) => {


        return ([type, dueDate, remarks, approvedBy, status,

            <input id="image-file" type="file" onChange={(event) => this.handleChange(event, id)}/>,
            <button
                className="btn btn-outline-primary btn-sm"

                onClick={(event) => this.handleSubmit(id)}>

                <Icon color="secondary" fontSize="small"><CheckIcon/></Icon>

            </button>

        ])

    };
    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="error" stopNotification={this.stopNotification} title="Required!!"
                              message={this.state.notificationMessage}/>
            )
        }

    };

    stopNotification = () => {
        this.setState({
            alert: false
        })
    };


    handleChange = (event, id) => {
        this.state.file[id] = event.target.files[0];
    };

    componentDidMount() {

        let varValue = [];


        let url = backEndServerURL + '/deferral/CSEnd';
        let tableArray = [];
        axios.get(url,
            {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                console.log(response.data);


                response.data.map((upload) => {
                    tableArray.push(this.createTableData(upload.id, upload.type, upload.dueDate, upload.remarks, upload.approvedBy, upload.status));

                });
                this.setState({
                    tableData: tableArray,
                    showValue: true
                });
            })
            .catch((error) => {
                console.log(error);

            });

    }

    handleSubmit = (deferralId) => {

        let formData = new FormData();
        formData.append("file", this.state.file[deferralId]);
        formData.append("deferralId", deferralId);

        axios({
            method: 'post',
            url: backEndServerURL + "/deferral/upload/",
            data: formData,
            headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`},
            headers: {'content-type': 'multipart/form-data'}
        })
            .then((res) => {
                console.log(res);
                this.setState({
                    notificationMessage: "Deferal Uploaded!!",
                    alert: true
                })
            })
            .catch((err) => {
                console.log(err)
            })


    };

    render() {

        const {classes} = this.props;

        {

            Functions.redirectToLogin(this.state)

        }
        return (

            <section>
                <Card>

                    <CardBody>
                        <div>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Deferal Type", "Expire date", "Remarks", "Approved By", "Status", "Upload File", "Submit"]}
                                tableData={this.state.tableData}
                                tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left']}
                            />


                        </div>

                    </CardBody>
                </Card>

            </section>

        );
    }
}

export default withStyles(styles)(UploadDeferal);

