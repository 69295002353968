import Table from "../Table/Table.jsx";
import React, {Component} from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import GridContainer from "../Grid/GridContainer.jsx";
import {Dialog, Grow} from "@material-ui/core";
import Card from "../Card/Card.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardBody from "../Card/CardBody.jsx";
import GenderAddEditDelete from "./GenderAddEditDelete";
import {backEndServerURL} from "../../Common/Constant";
import axios from "axios";
import 'antd/dist/antd.css';
import {notification} from 'antd';
import FormSample from "../JsonForm/FormSample";
import DialogContent from "@material-ui/core/DialogContent";
import Functions from '../../Common/Functions';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    dialogPaper: {
        overflow: "visible"
    }
};
const jsonForm = {
    "variables": [

        {
            "varName": "genderName",
            "type": "text",
            "label": "Gender Name",
            "number": false,
        },
        {
            "varName": "createdBy",
            "type": "text",
            "label": "Created By",
        },
        {
            "varName": "modifiedBy",
            "type": "text",
            "label": "Modified By",
        }


    ],

};

function Transition(props) {

    return <Grow in={true} timeout="auto" {...props} />;
}

class Gender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addGender: false,
            genderName: '',
            editGender: false,
            tableData: [[" ", " "]],
            open: true,
            genderData: [[" ", " "]],
            redirectLogin: false,
        }
    }

    createTableData = (id, genderName, createdBy, modifiedBy, createdDate, modifiedDate, status) => {

        return ([genderName, createdBy, modifiedBy, createdDate, modifiedDate, status,

            <button
                className="btn btn-outline-primary"
                style={{
                    verticalAlign: 'middle',
                }}
                onClick={() => this.editGender(id)}>
                Edit</button>
        ]);

    };
    editGender = (id) => {
        this.setState({
            editGender: true,
            genderId: id
        })
    };

    componentDidMount() {
        let url = backEndServerURL + "/genderMaster/getAll";

        const getAllGender = [];

        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {

                response.data.map((gender) => {

                    getAllGender.push(gender);
                });
                this.setState({
                    tableData: getAllGender
                })


            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 452) {
                    Functions.removeCookie();

                    this.setState({
                        redirectLogin: true
                    })

                }
            });

    }

    addGender() {
        this.setState({
            addGender: true,
        });
    }

    cancelGenderModal = () => {
        this.setState({addGender: false});
    };
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    closeModal = () => {
        this.setState({
            addGender: false,
            editGender: false,

        });
    };
    onAdd = (object) => {
        this.setState({
            addGender: false

        });
        notification.open({
            message: 'Successfully Added'
        });


    };
    onUpdate = (object) => {

        this.setState({
            editGender: false

        });
        notification.open({
            message: 'Successfully Edited'
        });


    };
    getSubmitedForm = (object) => {
        console.log(object.data);
        let objectTable = {};
        let tableArray = [];
        for (let variable in object) {
            let trimData = object[variable].trim();
            if (trimData !== '')
                objectTable[variable] = trimData;
        }

        this.state.tableData.map((gender) => {
            let showable = true;
            for (let variable in objectTable) {
                if (objectTable[variable] !== gender[variable])
                    showable = false;
            }
            if (showable)
                tableArray.push(this.createTableData(gender.id, gender.genderName, gender.createdBy, gender.modifiedBy, gender.createdDate, gender.modifiedDate, gender.status));


        });
        this.setState({
            genderData: tableArray
        })
    };
    renderFilterForm = () => {
        return (
            <FormSample close={this.closeModal} grid="12" buttonName="Filtering" onSubmit={this.getSubmitedForm}
                        jsonForm={jsonForm}/>
        )
    };

    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }
        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.addGender}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <GenderAddEditDelete onAdd={this.onAdd} secondButtonFunction={this.closeModal}
                                             secondButtonName="close" name="Add New Gender"/>
                    </DialogContent>

                </Dialog>
                <Dialog
                    fullWidth="true"
                    maxWidth="md"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.editGender}
                    TransitionComponent={Transition}
                    style={{width: 700}}
                >
                    <DialogContent className={classes.dialogPaper}>
                        <GenderAddEditDelete onUpdate={this.onUpdate} secondButtonFunction={this.closeModal}
                                             secondButtonName="close" name="Edit Gender" id={this.state.genderId}/>
                    </DialogContent>

                </Dialog>


                <GridContainer>

                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Gender List</h4>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {this.renderFilterForm()}
                                    <button
                                        className="btn btn-outline-primary"
                                        style={{
                                            verticalAlign: 'middle',
                                        }}

                                        onClick={() => {
                                            this.setState({
                                                addGender: true
                                            })
                                        }
                                        }
                                    >
                                        Add New Gender
                                    </button>

                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Gender Name", "Created by", "Modified by", "Created Date", "Modified Date", "Status", "Action"]}
                                        tableData={this.state.genderData}
                                        tableAllign={['left', 'left', 'left', 'left', 'left', 'left', 'left', 'left']}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>


                </GridContainer>
            </section>
        );
    }
}

export default withStyles(styles)(Gender);
