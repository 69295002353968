import {backEndServerURL} from "../../../Common/Constant";
import React, {Component} from "react";
import Notification from "../../NotificationMessage/Notification";
import MyValidation from "../../JsonForm/MyValidation";
import axios from "axios";
import {Dialog, DialogContent} from "@material-ui/core";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Grid from "@material-ui/core/Grid";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import withStyles from "@material-ui/core/styles/withStyles";
import loader from "../../../Static/loader.gif";
import theme from "../../JsonForm/CustomeTheme2";
import {ThemeProvider} from "@material-ui/styles";


const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

let populateFormForMaker = [
    {
        varName: "fileName",
        type: "file",
        accept: ".xlsx,.xls",
        label: "Upload File"
    }
];
let companyData = [
    {
        varName: "companyName",
        type: "text",
        label: "Company Name",
        required: true,
        grid: 2,
    },
    {
        varName: "companyCode",
        type: "text",
        label: "Company Code",
        required: true,
        grid: 2,
    },
    {
        type: "empty",
        grid: 12,
    },
];


class CorporatePrepaidCardFileUpload extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fileUploadData: {},
            err: false,
            errorArray: {},
            errorMessages: {},
            title: "",
            notificationMessage: "",
            alert: false,
            failedList: [],
            disabled: false,
            loading: false,
            okay: false,
            inputData: {},
            varvalue: {},
        };
    }

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification
                    type="success"
                    stopNotification={this.stopNotification}
                    title={this.state.title}
                    message={this.state.notificationMessage}
                />
            );
        }
    };

    componentDidMount() {
        this.setState({
            okay: true
        });
    }


    stopNotification = () => {
        this.setState({
            alert: false
        });
    };

    fileUpload = () => {
        let error = MyValidation.fileValidation(populateFormForMaker, this.state);
        let errorFiled = MyValidation.defaultValidation(companyData, this.state)
        this.forceUpdate();
        if (error || errorFiled) {
            return 0;
        }

        if (this.state.fileUploadData.fileName !== undefined) {
            this.setState({
                disabled: true,
                loading: true,
            });
            let url = backEndServerURL + "/uploadCorporatePrepaidCardAndSave";
            const formData = new FormData();
            formData.append("file", this.state.fileUploadData['fileName']);
            formData.append("companyName", this.state.inputData.companyName);
            formData.append("companyCode", this.state.inputData.companyCode);


            axios.post(
                url,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                        "content-type": "multipart/form-data"
                    },
                })
                .then(response => {
                    if (response.data === "successfully uploaded") {
                        this.setState({
                            title: "Successful!",
                            notificationMessage: "File Upload Successful",
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000)
                    } else {
                        this.setState({
                            title: "Failed!",
                            notificationMessage: "Unknown Error",
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                    }
                })
                .catch((error) => {
                    if (error) {
                        this.setState({
                            title: "Failed!",
                            notificationMessage: error.response.data.message,
                            alert: true,
                            disabled: false,
                            loading: false,
                        });
                    }
                });

        }
        else{
            this.setState({
                title: "Failed!",
                notificationMessage: "No File Found!",
                alert: true,
                disabled: false,
                loading: false,
            });
        }
    };


    filePath = () => {
        return process.env.PUBLIC_URL + '/sample upload files/corporate prepaid card.xlsx'
    }

    updateComponent = () => {
        this.forceUpdate();
    };
    renderCompanyData = () => {
        if (this.state.okay) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, companyData, this.updateComponent, "", "")
            )
        }
    }
    renderFileUpload = () => {
        if (this.state.okay) {
            return (
                CommonJsonFormComponent.renderJsonForm(this.state, populateFormForMaker, this.updateComponent)
            )
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <Card>
                <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                    <h4 style={{color: "white"}} >Corporate Prepaid Card File Upload</h4>
                </CardHeader>
                <CardBody>
                    <div>
                        {this.renderNotification()}
                        <Dialog
                            fullWidth="true"
                            maxWidth="sm"
                            className={classes.modal}
                            classes={{paper: classes.dialogPaper}}
                            open={this.state.loading}>
                            <DialogContent className={classes.dialogPaper}>
                                <center>
                                    <img src={loader} alt=""/>
                                </center>
                            </DialogContent>
                        </Dialog>

                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>

                                        <ThemeProvider theme={theme}>
                                            <Grid container spacing={0}>
                                                {this.renderCompanyData()}
                                            </Grid>
                                        </ThemeProvider>
                                                <Grid item xs={4}
                                                  style={{
                                                display: "block",
                                               float: "right"
                                            }}>
                                                    <a href={this.filePath()} download="Sample File">
                                                    <button
                                                        className="btn btn-outline-primary btn-sm"
                                                    >
                                                        Download Sample Report
                                                    </button>
                                                </a>
                                            </Grid>

                                        {this.renderFileUpload()}
                                        <br/>

                                        <div>



                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                                disabled={this.state.disabled}
                                                onClick={this.fileUpload}
                                            >
                                                Submit
                                            </button>
                                        </div>

                            </GridItem>
                        </GridContainer>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default withStyles(styles)(CorporatePrepaidCardFileUpload);
