import React from "react";
import PropTypes from "prop-types";
import {backEndServerURL} from "../../../Common/Constant";
import axios from "axios";
import Functions from "../../../Common/Functions";
import CommonJsonFormComponent from "../../JsonForm/CommonJsonFormComponent";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import Grid from "@material-ui/core/Grid";
import {ThemeProvider} from "@material-ui/styles";
import theme from "../../JsonForm/CustomeTheme";
import {Dialog, withStyles} from "@material-ui/core";
import InwardCs from "./InwardCs";
import loading from "../../../Static/loader.gif";
import TableComponent from "../CommonComponent/TableComponent";
import DialogContent from "@material-ui/core/DialogContent";
import CBNotFound from "../CASA/CBNotFound";
import LocalstorageEncrypt from "../../JsonForm/LocalstorageEncrypt";

const filteringJsonForm = [
    {
        "varName": "iwReferenceNumber",
        "type": "text",
        "label": "Reference Number",
        "grid": 2,
    },
    {
        "varName": "iwAccountCurrency",
        "type": "text",
        "label": "Currency",
        "grid": 2,
    },
    {
        "varName": "iwAmount",
        "type": "text",
        "label": "Amount",
        "grid": 2,
    },
    {
        "varName": "iwRemitter",
        "type": "text",
        "label": "Remitter",
        "grid": 2,
        "multiline": true,
    },
    {
        "varName": "iwBeneficiaryName",
        "type": "text",
        "label": "Beneficiary Name",
        "grid": 2,
        "multiline": true,
    },
    {
        "varName": "iwBeneficiaryAccount",
        "type": "text",
        "label": "Beneficiary Account",
        "grid": 2,
        "multiline": true,
    },
    {
        "varName": "iwPurposeMentionedInTheSWIFT",
        "type": "text",
        "label": "Purpose mentioned in the SWIFT",
        "grid": 2,
        "multiline": true,
    },
    {
        "varName": "iwAdBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "AD Branch",
        "required": true,
        "grid": 2,
    },
    {
        "varName": "iwNonAdBranch",
        "type": "autoCompleteValueReturn",
        "enum": [],
        "label": "Non Branch",
        "required": true,
        "grid": 2,
    },
];
const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "#000",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#000"
        }
    },
    cardTitleWhite: {
        color: "#000",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    modal: {
        top: `${10}%`,
        maxWidth: `${80}%`,
        maxHeight: `${100}%`,
        margin: 'auto'

    },
    Tab: {
        flexDirection: "row-reverse"
    },
    progress: {
        margin: 50,
    },

};

class staticTable extends React.Component {
    state = {
        tableData: [],
        tableColumns: [
            {title: "SL", field: "sl"},
            {title: "Non-AD Branch", field: "nonAdBranch"},
            {title: "AD Branch", field: "adBranch"},
            {title: "Beneficiary Account", field: "accountNumber"},
            {title: "Beneficiary Name", field: "customerName"},
            {title: "Remitter", field: "remitter"},
            {title: "Currency", field: "currency"},
            {title: "Amount", field: "amount"},
            {title: "Purpose mentioned in the SWIFT", field: "purpose"},
            {title: "Nostro Bank", field: "nostroBank"},
            {title: "Reference Number", field: "referenceNumber"},
            {title: "Category", field: "category"},
            {title: "Sub Category", field: "subCategory"},
            {title: "Date of Activity", field: "date"},
        ],
        err: false,
        errorArray: {},
        errorMessages: {},
        errorReturn: {},
        inputData: {},
        getData: false,
        showTable: false,
        loading: true,
        cbNotFound: false,
        title: "",
        totalRow: 0,
        rowsPerPage: 100,
        page: 0,
        appId: 0,
        caseId: 0,
        searchItem: false
    };

    getBranchAll() {
        let branch = [];
        let branchUrl = backEndServerURL + "/workplaceUnit/get/BRANCH";
        axios.get(branchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                response.data.map((item) => {
                    branch.push({key: item.solId, value: item.unitName})
                });
                for (let item of filteringJsonForm) {
                    if (item.varName === "iwNonAdBranch") {
                        item.enum = branch
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }


    componentDidMount() {
        this.getBranchAll();
        let adBranchUrl = backEndServerURL + "/finMasterData/adBranchRemittance";
        axios.get(adBranchUrl, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}}).then((response) => {
            for (let item of filteringJsonForm) {
                if (item.varName === "iwAdBranch") {
                    item.enum = response.data
                }
            }
        }).catch((error) => {
            console.log(error);
        });
        this.functionForFetchData(0);
    }

    functionForFetchData = (page) => {
        this.setState({
            tableData: [],
            getData: false,
            showTable: false,
            loading: true,
        });

        let pageNumber = Math.sign(page) !== 1 ? 0 : page;
        let url = backEndServerURL + "/GetAllRemittanceRequests/" + pageNumber;
        axios.get(url, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                let rowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    let res = response.data.content[i];
                    rowData = {
                        sl: i + 1,
                        category: res.category,
                        caseId: res.caseId,
                        appId: res.appId,
                        subCategory: res.subCategory,
                        referenceNumber: res.freeFlag3,
                        currency: res.currency,
                        nonAdBranch: res.nonAdBranch,
                        adBranch: res.adBranch,
                        nostroBank: res.nostroBank,
                        amount: res.amount,
                        remitter: res.remitter,
                        purpose: res.purpose,
                        customerName: res.customerName,
                        accountNumber: res.accountNumber,
                        date: res.delInitTime.split("T")[0],
                    };
                    tableData.push(rowData);
                }
                this.setState({
                    page: pageNumber,
                    totalRow: response.data.totalElements,
                    tableData: tableData,
                    getData: true,
                    showTable: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: "Something Went Wrong!",
                    showTable: true,
                    loading: false,
                    getData: false
                })
            });
    };


    closeModal = () => {
        this.setState({
            inboxModal: false,
        });
    };

    renderInboxCase = (e, rowData) => {
        if (LocalstorageEncrypt.encryptStorageFunction.getItem("workflowGroup") === "CS") {
            this.setState({
                appId: rowData.appId,
                caseId: rowData.caseId,
                inboxModal: true,
            });
        } else {
            return false;
        }
    };

    updateComponent = () => {
        this.forceUpdate()
    };

    renderFilterForm = () => {
        return (
            CommonJsonFormComponent.renderJsonForm(this.state, filteringJsonForm, this.updateComponent)
        )
    };


    searchHandler = (page) => {
        this.setState({
            loading: true,
            showTable: false,
            getData: false
        });
        let inputData = {...this.state.inputData};
        if (this.state.inputData.iwAdBranch) {
            inputData.iwAdBranch = this.state.inputData.iwAdBranch["value"];
        }
        if (this.state.inputData.iwNonAdBranch) {
            inputData.iwNonAdBranch = this.state.inputData.iwNonAdBranch["value"];
        }
        let url = backEndServerURL + "/FilterInwardRemittanceAppDelegations/New/" + page;
        axios.post(url, this.copyJson(inputData), {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                let tableData = [];
                let rowData;
                for (let i = 0; i < response.data.content.length; i++) {
                    let res = response.data.content[i];
                    rowData = {
                        sl: i + 1,
                        category: res.category,
                        caseId: res.caseId,
                        appId: res.appId,
                        subCategory: res.subCategory,
                        referenceNumber: res.freeFlag3,
                        currency: res.currency,
                        amount: res.amount,
                        remitter: res.remitter,
                        nonAdBranch: res.nonAdBranch,
                        adBranch: res.adBranch,
                        nostroBank: res.nostroBank,
                        purpose: res.purpose,
                        customerName: res.customerName,
                        accountNumber: res.accountNumber,
                        date: res.delInitTime.split("T")[0],
                    };
                    tableData.push(rowData);
                }
                this.setState({
                    page: 0,
                    totalRow: response.data.totalElements,
                    tableData: tableData,
                    getData: true,
                    searchItem: true,
                    showTable: true,
                    loading: false
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    cbNotFound: true,
                    title: "Something Went Wrong!",
                    searchItem: false,
                    getData: true,
                    showTable: true,
                    loading: false,
                })
            });
    };

    copyJson = (jsonObject) => {
        var clone = JSON.parse(JSON.stringify(jsonObject));
        for (var prop in clone)
            if (clone[prop] === '' || clone[prop] === 'undefined' || clone[prop] === 'null')
                delete clone[prop];
        return clone;
    };

    handleChangePage = (pageNumber) => {
        this.setState({
            loading: true,
            getData: false,
            showTable: false
        });
        if (this.state.searchItem) {
            this.searchHandler(pageNumber)
        } else {
            this.functionForFetchData(pageNumber)
        }
    };

    closeModalCBNotFound = () => {
        this.setState({
            cbNotFound: false
        })
    };


    render() {
        const {classes} = this.props;
        {

            Functions.redirectToLogin(this.state)

        }

        return (
            <section>
                <Dialog
                    fullWidth="true"
                    maxWidth="lg"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.inboxModal}>
                    <DialogContent className={classes.dialogPaper}>
                        <InwardCs closeModal={this.closeModal} renderTableData={this.functionForFetchData}
                                  serviceType="Remetance" subServiceType="Inward"
                                  appId={this.state.appId} caseId={this.state.caseId}/>
                    </DialogContent>
                </Dialog>
                <Paper className={classes.root}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CardHeader style={{
                            backgroundColor:'#142398',
                            //color: '#ffffff'
                        }}>
                                <h4 className={classes.cardTitleWhite}>Filter Inward Remittance</h4>
                            </CardHeader>
                            <CardBody>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.loading}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <center>
                                            <img src={loading} alt=""/>
                                        </center>
                                    </DialogContent>
                                </Dialog>
                                <Dialog
                                    fullWidth="true"
                                    maxWidth="sm"
                                    className={classes.modal}
                                    classes={{paper: classes.dialogPaper}}
                                    open={this.state.cbNotFound}>
                                    <DialogContent className={classes.dialogPaper}>
                                        <CBNotFound
                                            closeModal={this.closeModalCBNotFound}
                                            title={this.state.title}
                                        />
                                    </DialogContent>
                                </Dialog>
                                <Grid container spacing={1}>
                                    <ThemeProvider theme={theme}>
                                        {this.renderFilterForm()}
                                        <Grid item xs={2}>
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginTop: "15px"
                                                }}
                                                onClick={() => {
                                                    this.searchHandler(0)
                                                }}
                                            >
                                                Search
                                            </button>
                                            &nbsp;
                                            <button
                                                className="btn btn-outline-primary btn-sm"
                                                style={{
                                                    marginTop: "15px"
                                                }}
                                                onClick={() => window.location.reload()}
                                            >
                                                Reset
                                            </button>
                                        </Grid>
                                        <Grid item xs={12}><br/></Grid>
                                    </ThemeProvider>
                                </Grid>
                                {(this.state.getData && this.state.showTable) &&
                                <TableComponent tableData={this.state.tableData}
                                                onRowClick={this.renderInboxCase}
                                                tableColumns={this.state.tableColumns}
                                                loader={this.state.loading} maxBodyHeight="450px"
                                                totalRow={this.state.totalRow} page={this.state.page}
                                                selection={false} handleChangePage={this.handleChangePage}
                                                rowsPerPage={this.state.rowsPerPage}
                                />}
                            </CardBody>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </section>
        );

    }
}

staticTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(staticTable);
