import React, {Component} from "react";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import loader from "../Static/loader.gif";
import Notification from "./NotificationMessage/Notification";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import logo from "../Static/green-delta.png";
import {backEndServerURL} from "../Common/Constant";
import axios from 'axios';

class LogoutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: "",
            notificationMessage: "",
            alert: false
        }
    }

    renderForceLogout = (username, password) => {
        this.setState({
            loading: true,
        });
        let url = backEndServerURL + "/users/forceLogoutSelf/" + username;
        let postData = {
            username: username,
            password: password
        };
        axios.post(url, postData, {headers: {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}})
            .then((response) => {
                this.setState({
                    loading: false,
                    title: "Successful!",
                    notificationMessage: "Successfully Logout!",
                    alert: true,
                });
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loading: false,
                    title: "Warning!",
                    notificationMessage: "Operation not successful!",
                    alert: true,
                })
            })

    };

    renderNotification = () => {
        if (this.state.alert) {
            return (
                <Notification type="success" stopNotification={this.stopNotification} title={this.state.title}
                              message={this.state.notificationMessage}/>
            )
        }
    };


    stopNotification = () => {
        this.setState({
            alert: false
        })
    };

    render() {
        const {classes} = this.props;
        return (
            <div style={{"width": "370px", "margin": "200px auto"}}>
                <center>
                    <main className={classes.main}>
                        <CssBaseline/>
                        <Paper className={classes.paper}>
                            <img height={120} src={logo}/>
                            <p style={{"color": "red", "margin": "20px 0"}}>You are already logged in.</p>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                style={{
                                    verticalAlign: 'middle',
                                    marginBottom: "50px"
                                }}
                                onClick={() => this.renderForceLogout(this.props.username, this.props.password)}
                            >
                                Logout from all sessions.
                            </button>
                        </Paper>
                    </main>
                </center>
                <Dialog
                    fullWidth="true"
                    maxWidth="sm"
                    className={classes.modal}
                    classes={{paper: classes.dialogPaper}}
                    open={this.state.loading}>
                    <DialogContent className={classes.dialogPaper}>
                        <center>
                            <img src={loader} alt=""/>
                        </center>
                    </DialogContent>
                </Dialog>
                {this.renderNotification()}
            </div>
        )
    }
}

export default LogoutComponent;